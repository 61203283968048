import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton";

class ErrorPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			alert: null
		};

		this.ReportClick = this.ReportClick.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);

		this.cardMediaStyle = {
			height: "76px", marginLeft: "auto", marginRight: "auto", marginTop: 50,
			backgroundSize: "100% 100%", objectFit: "contain"
		};
	}

	componentDidCatch(error, info) {
		console.error("error: " + error.message);
		console.error("errorInfo: " + info.componentStack);
	}

	ReportClick() {

		const { errorMessage, componentStack, url } = this.props;

		var request = {
			Description: errorMessage,
			Detail: componentStack,
			Url: url
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/ErrorReport/Report",
			request,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.ShowMessage("info", "Info", "Error reported.");
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while load file information", typeof e == "object" ? JSON.stringify(e) : e);
			}
		);
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { isLoading, alert } = this.state;
		const logo = require("assets/img/" + window.MainLogo);

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}

				<GridContainer spacing={16} style={{ textAlign: "center" }}>
					<GridItem xs={12}>
						<img src={logo} style={this.cardMediaStyle} alt="logo" />
						<p style={{ fontSize: 18, fontWeight: 500 }}>We're here to help.</p>
					</GridItem>
					<GridItem xs={12}>
						<h3>Sorry, we're looking to what happened.</h3>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: 20, marginBottom: 50 }}>
						<Link to="/">
							<ToolbarButton>Home</ToolbarButton>
						</Link>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<ToolbarButton onClick={this.ReportClick}>Report</ToolbarButton>
					</GridItem>
					<GridItem xs={12}>
						<Link style={{ color: "#F57F29", fontSize: 18, fontWeight: 500, marginRight: 10 }} to="/CreateEFT"> Create EFT </Link>
						<Link style={{ color: "#F57F29", fontSize: 18, fontWeight: 500, marginLeft: 10, marginRight: 10 }} to="/CreateeTransfer"> Create e-Transfer </Link>
						<Link style={{ color: "#F57F29", fontSize: 18, fontWeight: 500, marginLeft: 10 }} to="/CreateWireTransfer"> Create Wire Transfer </Link>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ErrorPage.defaultProps = {
	errorMessage: "Unknown Error Occured",
	componentStack: "",
	url: "/Unknown"
};

ErrorPage.propTypes = {
	errorMessage: PropTypes.string,
	componentStack: PropTypes.string,
	url: PropTypes.string
};

export default ErrorPage;