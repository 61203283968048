import { DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { List } from "@material-ui/icons";
import { chartColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ArrayHelper from "core/ArrayHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { ApproveIcon, ProfileIcon, SubmitOrSendIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, RoleLevel, UserStatus } from "views/Constants/Constant";
import LoanApplicationsChart from "./LoanApplicationsChart";
import LoanApprovalStatusChart from "./LoanApprovalStatusChart";

class LoanApplications extends React.Component {
	constructor(props) {
		super(props);
		var defaultFirstDate = new Date();
		defaultFirstDate.setDate(defaultFirstDate.getDate() + -33);
		var defaultEndDate = new Date();

		this.initialModel = {
			FromDate: defaultFirstDate,
			ToDate: defaultEndDate
		};
		this.state = {
			isAssignDialogOpen: false,
			profileGreenColor: {},
			profileRedColor: {},
			profileYellowColor: {},
			profileBlackColor: {},
			testDataLoan: {
				TotalRequestAmount: null,
				RequestAmountCount: null
			},
			selected: null,
			ApplicationsList: [],
			ApplicantList: [],
			programList: [],
			merchantList: [],
			model: this.initialModel,
			isLoading: false,
			activeIndex: 0,
			trxChartData: {
				labels: ["Loan"],
				datasets: [{
					data: [100],
					backgroundColor: chartColors.red,
					hoverBackgroundColor: chartColors.red
				}],
				text: "",
			},
			creditDebitChartData: {
				labels: ["Line Of Credit"],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}],
				text: ""
			},
			loanChartData: {
				labels: ["Line Of Credit"],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}],
				text: ""
			},
			feeChartData: {
				labels: ["Secured Card"],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(54, 162, 235)",
					hoverBackgroundColor: "rgb(54, 162, 235)"
				}],
				text: ""
			},
			onHoldChartData: {
				labels: ["Application In Progress"],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(54, 162, 235)",
					hoverBackgroundColor: "rgb(54, 162, 235)"
				}],
				text: ""
			},
			approvedChartData: {
				labels: ["Application Approved"],
				datasets: [{
					data: [80],
					backgroundColor: "rgb(54, 162, 235)",
					hoverBackgroundColor: "rgb(54, 162, 235)"
				}],
				text: ""
			},
			rejectedChartData: {
				labels: ["Application Declined"],
				datasets: [{
					data: [90],
					backgroundColor: "rgb(54, 162, 235)",
					hoverBackgroundColor: "rgb(54, 162, 235)"
				}],
				text: " "
			},
			isBackOffice: ClientHelper.IsBackOffice()
		};
		this.ColumnsData = [
			{
				Header: "Assign User",
				accessor: "AssignUserFullName"
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				sortable: false,
				filterable: false,
				type: GridColumnType.Date
			},
			{
				Header: "Partner",
				accessor: "ClientPartner.LegalNameOfBusiness",
			},
			{
				Header: "Loan Type",
				accessor: "LoanType.ParameterDesc",
			},
			{
				Header: "Applicant Name",
				accessor: "Applicant.FullName",
			},
			{
				Header: "Total Amount",
				accessor: "RequestedAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Frequency",
				accessor: "RequestedPaymentFrequency.ParameterDesc",
			},
			{
				Header: "Count",
				accessor: "RequestedPaymentPeriod",
				Cell: row => (
					<div>
						{(row.original.LoanType.ParameterDesc === "Loan") && row.original.RequestedPaymentPeriod}

					</div>
				),
			},
			{
				Header: "Purpose Of Loan",
				accessor: "LoanPurpose.ParameterDesc",
			},
			{
				Header: "Internal Score",
				accessor: "ApprovedInternalScore",
			},
			{
				Header: "External Score",
				accessor: "ExternalScore",
				Cell: row => (
					<div>
						{row.original.TransUnionCreditScore && row.original.TransUnionCreditScore != 0 ? row.original.TransUnionCreditScore : "N/A"}
					</div>
				),
			},
			{
				Header: "Total Principal",
				accessor: "ApprovedAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Interest",
				accessor: "InterestRate",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Fees",
				accessor: "TotalSetupAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Activation Status",
				accessor: "Status.ParameterDesc"
			},
			{
				Header: "Approval Status",
				accessor: "ApprovalStatus.ParameterDesc",
			},
			{
				Header: "Reason",
				accessor: "ApprovalSubStatus.ParameterDesc",
			},
			{
				Header: "Comment",
				accessor: "ApprovalSubStatusComment",
			},
			{
				Header: "Sale Channel",
				accessor: "SaleChannel.ParameterDesc"
			},
			{
				Header: "Updated By",
				accessor: "UpdateUser"
			},
			{
				Header: "Update Date Time",
				accessor: "UpdateDateTime",
				type: GridColumnType.Date
			},
		];
		if (!this.state.isBackOffice) {
			this.ColumnsData.unshift(
				{
					Header: "Actions",
					accessor: "Actions",
					Cell: row => (
						<div>
							<GridButton
								tooltip="Profile"
								Icon={row.original.ApprovalStatus != null
									? row.original.ApprovalStatus.ParameterDesc == "Approved"
										? this.state.profileGreenColor.IconSrcBase64
										: this.state.profileRedColor.IconSrcBase64
									: ProfileIcon
								}
								OnClick={() => {
									this.ProfileOnClick(row.original.Id, row.original.AssignUserId, row.original.ApplicantId);
								}} />
							<GridButton
								tooltip="Approve"
								Icon={ApproveIcon}
								Disabled={row.original.ApprovalStatus.ParameterValue == "1" || row.original.ApprovalStatus.ParameterValue == "2"}
								OnClick={() => {
									this.ApprovalOnClick(row.original.Id, row.original.AssignUserId);
								}} />
							<GridButton
								tooltip="Assign User"
								Icon={SubmitOrSendIcon}
								OnClick={() => {
									this.DialogAssignOpen(row.original.Id, row.original.AssignUserId);
								}} />
						</div>
					),
					sortable: false,
					filterable: false,
					// @ts-ignore
					width: 100
				},
			);
		}


		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };
		this.ApprovalParameterCode = { ParameterCode: "LOSApprovalStatus" };
		this.LoanTermParameterCode = { ParameterCode: "LOSLoanTerm" };
		this.ClientPartnerParameterCode = { ParameterCode: "ClientPartner" };
		this.SalesChannelParameterCode = { ParameterCode: "LOSSalesChannel" };
		this.LoanTypeParameters = { ParameterCode: "LoanType" };

		this.chartColors = {
			red: "rgb(255, 99, 132)",
			orange: "rgb(255, 159, 64)",
			yellow: "rgb(255, 205, 86)",
			green: "rgb(75, 192, 192)",
			blue: "rgb(54, 162, 235)",
			purple: "rgb(153, 102, 255)",
			grey: "rgb(201, 203, 207)"
		};

		this.HandleChange = this.HandleChange.bind(this);
		this.HandleClear = this.HandleClear.bind(this);
		this.SuccessListCallback = this.SuccessListCallback.bind(this);
		this.ErrorListCallback = this.ErrorListCallback.bind(this);
		this.GetAllClient = this.GetAllClient.bind(this);
		this.AssignApplicantToUser = this.AssignApplicantToUser.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Loan Applications");
		this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");

		this.GetAllClient();

	}

	async GetAllClient() {
		const { ExecuteApiPost } = this.props;
		var user = ClientHelper.GetUser();
		var result = await ExecuteApiPost("/coreapi/v1.0/User/Search", { UniqueClientId: user.UniqueClientId, UserStatusList: [ UserStatus.Ok ] });
		var assignUserList = [], isClientStandartUser = null;

		if (result != null) {
			if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser) {
				assignUserList = result
					.filter(x => x.Id == user.Id)
					.map(x => {
						return {
							Id: x.Id,
							FullName: x.FullName
						};
					});

				isClientStandartUser = true;
			} else if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientAdmin) {
				assignUserList = result
					.map(x => {
						return {
							Id: x.Id,
							FullName: x.FullName
						};
					});
			}
		}

		ArrayHelper.SortArray(assignUserList, "FullName");

		this.setState({ assignUserList, isClientStandartUser });

	}

	AssigneeControl(assignUserId) {
		var user = ClientHelper.GetUser();

		if (
			user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser
			&& assignUserId != null
			&& assignUserId != user.Id) {
			this.props.showMessage("warning", "Warning", "You can only perform operations about tasks that are assigned to your user wallet.");

			return false;
		}

		return true;
	}

	ProfileOnClick = (applicationId, assignUserId, applicantId) => {
		//if (this.AssigneeControl(assignUserId)) {
		RouteHelper.Push(this.props.history, "/CreateApplication", "ApplicationData", { ApplicationId: applicationId, ApplicantId: applicantId, EnableApplicantUpdate: false });
		//}
	}

	ApprovalOnClick = (applicationId, assignUserId) => {
		if (this.AssigneeControl(assignUserId)) {
			RouteHelper.Push(this.props.history, "/loanApproval", "loanApproval_Id", applicationId);
		}
	}

	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/Application/Dashboard",
			{
				ApplicationModel: this.state.model,
				FromDate: this.state.model.FromDate,
				ToDate: this.state.model.ToDate
			}
			,
			responseData => {
				console.log("GetApplicant ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.SuccessListCallback(responseData);
					this.setState({ ApplicationsList: responseData.Item.Applications || [], profileGreenColor: responseData.Item.ProfileGreenColor || [], profileYellowColor: responseData.Item.ProfileYellowColor || [], profileRedColor: responseData.Item.ProfileRedColor || [], isLoading: false });
				} else {
					this.setState({ ApplicantList: [], isLoading: false });
				}

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}



	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "UniqueClientId" && value == null) {
			model[name] = 0;
		}
		this.setState({ model });
	}

	HandleClear() {
		this.setState({ model: this.initialModel, selected: null });
	}

	formatNumber = (num) => {
		return " $ " + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	SuccessListCallback(responseData) {
		var arrayData = [];
		var arrayData2 = [];
		var arrayData3 = [];
		var arrayData4 = [];
		var arrayData5 = [];
		var arrayData6 = [];
		var arrayLabel = [];
		var arrayLabel2 = [];
		var arrayLabel3 = [];
		var arrayLabel4 = [];
		var arrayLabel5 = [];
		var arrayLabel6 = [];
		var arrayColor = [];
		var arrayColor2 = [];
		var arrayColor3 = [];
		var arrayColor4 = [];
		var arrayColor5 = [];
		var arrayColor6 = [];
		if (responseData.Item !== null) {
			var colorNames = Object.keys(chartColors);
			var i = 0;

			var colorName = colorNames[i % colorNames.length];
			var newColor = chartColors[colorName];
			arrayColor.push(newColor);
			arrayData.push(responseData.Item.LoanChartData != null ? responseData.Item.LoanChartData.TotalRequestAmount : 0);
			arrayData.push(responseData.Item.LoanChartData != null ? responseData.Item.LoanChartData.RequestAmountCount : 0);

			arrayLabel.push("Tran Amount");
			arrayLabel.push("Total Amount");
			if (responseData.Item.LoanChartData != null) {
				var loanString = responseData.Item.LoanChartData.RequestAmountCount + "   " + this.formatNumber(responseData.Item.LoanChartData.TotalRequestAmount);
			}

			var trxChartObj = {
				labels: arrayLabel,
				datasets: [{
					data: arrayData,
					hoverBackgroundColor: arrayColor,
					backgroundColor: arrayColor
				}],
				text: responseData.Item.LoanChartData != null ? loanString : "0"
			};
			/********************************************************/
			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor2.push(newColor);
			arrayData2.push(responseData.Item.LocChartData != null ? responseData.Item.LocChartData.TotalRequestAmount : 0);
			arrayData2.push(responseData.Item.LocChartData != null ? responseData.Item.LocChartData.RequestAmountCount : 0);

			arrayLabel2.push("Tran Amount");
			arrayLabel2.push("Total Amount");

			if (responseData.Item.LocChartData != null) {
				var lineOfCreditString = responseData.Item.LocChartData.RequestAmountCount + "     " + this.formatNumber(responseData.Item.LocChartData.TotalRequestAmount);
			}

			var creditDebitChartDataObj = {
				labels: arrayLabel2,
				datasets: [{
					data: arrayData2,
					hoverBackgroundColor: arrayColor2,
					backgroundColor: arrayColor2
				}],
				text: responseData.Item.LocChartData != null ? lineOfCreditString : "0"
			};
			/********************************************************/
			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor3.push(newColor);

			arrayData3.push(responseData.Item.SecuredCardChartData != null ? responseData.Item.SecuredCardChartData.TotalRequestAmount : 0);
			arrayData3.push(responseData.Item.SecuredCardChartData != null ? responseData.Item.SecuredCardChartData.RequestAmountCount : 0);

			arrayLabel3.push("Tran Amount");
			arrayLabel3.push("Total Amount");

			if (responseData.Item.SecuredCardChartData != null) {
				var securedCardString = responseData.Item.SecuredCardChartData.RequestAmountCount + "     " + this.formatNumber(responseData.Item.SecuredCardChartData.TotalRequestAmount);
			}
			var feeChartDataObj = {
				labels: arrayLabel3,
				datasets: [{
					data: arrayData3,
					hoverBackgroundColor: arrayColor3,
					backgroundColor: arrayColor3
				}],
				text: responseData.Item.SecuredCardChartData != null ? securedCardString : "0"
			};
			/********************************************************/
			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor4.push(newColor);
			arrayData4.push(responseData.Item.OnHoldChartData != null ? responseData.Item.OnHoldChartData.TotalRequestAmount : 0);
			arrayData4.push(responseData.Item.OnHoldChartData != null ? responseData.Item.OnHoldChartData.RequestAmountCount : 0);

			arrayLabel4.push("Tran Amount");
			arrayLabel4.push("Total Amount");

			if (responseData.Item.OnHoldChartData != null) {
				var onHoldString = responseData.Item.OnHoldChartData.RequestAmountCount + "     " + this.formatNumber(responseData.Item.OnHoldChartData.TotalRequestAmount);
			}

			var onHoldChartDataObj = {
				labels: arrayLabel4,
				datasets: [{
					data: arrayData4,
					hoverBackgroundColor: arrayColor4,
					backgroundColor: arrayColor4
				}],
				text: responseData.Item.OnHoldChartData != null ? onHoldString : "0"
			};

			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor5.push(newColor);
			arrayData5.push(responseData.Item.ApprovedChartData != null ? responseData.Item.ApprovedChartData.TotalRequestAmount : 0);
			arrayData5.push(responseData.Item.ApprovedChartData != null ? responseData.Item.ApprovedChartData.RequestAmountCount : 0);

			arrayLabel5.push("Tran Amount");
			arrayLabel5.push("Total Amount");

			if (responseData.Item.ApprovedChartData != null) {
				var approvedString = responseData.Item.ApprovedChartData.RequestAmountCount + "   " + this.formatNumber(responseData.Item.ApprovedChartData.TotalRequestAmount);
			}

			var approvedChartDataObj = {
				labels: arrayLabel5,
				datasets: [{
					data: arrayData5,
					hoverBackgroundColor: arrayColor5,
					backgroundColor: arrayColor5
				}],
				text: responseData.Item.ApprovedChartData != null ? approvedString : "0"
			};

			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor6.push(newColor);
			arrayData6.push(responseData.Item.RejectedChartData != null ? responseData.Item.RejectedChartData.TotalRequestAmount : 0);
			arrayData6.push(responseData.Item.RejectedChartData != null ? responseData.Item.RejectedChartData.RequestAmountCount : 0);

			arrayLabel6.push("Tran Amount");
			arrayLabel6.push("Total Amount");
			if (responseData.Item.RejectedChartData != null) {
				var rejectedString = responseData.Item.RejectedChartData.RequestAmountCount + "     " + this.formatNumber(responseData.Item.RejectedChartData.TotalRequestAmount);
			}

			var rejectedChartDataObj = {
				labels: arrayLabel6,
				datasets: [{
					data: arrayData6,
					hoverBackgroundColor: arrayColor6,
					backgroundColor: arrayColor6
				}],
				text: responseData.Item.RejectedChartData != null ? rejectedString : "0"
			};
			//***************/
			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			this.setState({
				model,
				trxChartData: responseData.Item.LoanChartData != null && responseData.Item.LoanChartData.RequestAmountCount > 0 ? trxChartObj : { ...this.state.trxChartData, ...{ text: "0" } },
				creditDebitChartData: responseData.Item.LocChartData != null && responseData.Item.LocChartData.RequestAmountCount > 0 ? creditDebitChartDataObj : { ...this.state.creditDebitChartData, ...{ text: "0" } },
				feeChartData: responseData.Item.SecuredCardChartData != null && responseData.Item.SecuredCardChartData.RequestAmountCount > 0 ? feeChartDataObj : { ...this.state.feeChartData, ...{ text: "0" } },
				onHoldChartData: responseData.Item.OnHoldChartData != null && responseData.Item.OnHoldChartData.RequestAmountCount > 0 ? onHoldChartDataObj : { ...this.state.onHoldChartData, ...{ text: "0" } },
				approvedChartData: responseData.Item.ApprovedChartData != null && responseData.Item.ApprovedChartData.RequestAmountCount > 0 ? approvedChartDataObj : { ...this.state.approvedChartData, ...{ text: "0" } },
				rejectedChartData: responseData.Item.RejectedChartData != null && responseData.Item.RejectedChartData.RequestAmountCount > 0 ? rejectedChartDataObj : { ...this.state.rejectedChartData, ...{ text: "0" } }
			});
		}
	}


	ErrorListCallback(error) {
		this.setState({ isLoading: false });
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	SelectedColumnChange = (index, rowInfo, columnInfo) => {
		if (columnInfo.id == "Actions") {
			return;
		}

		const model = this.state.ApplicationsList[index];
		if (model != null && model.Id != null && model.Id > 0 &&
			model.ApprovalStatus != null && !(model.ApprovalStatus.ParameterValue == "1" || model.ApprovalStatus.ParameterValue == "2")) {
			this.ApprovalOnClick(model.Id, model.AssignUserId);
		}
	}

	DialogAssignOpen = (applicationId, assignUserId) => {
		const { isClientStandartUser } = this.state;
		const { showMessage } = this.props;
		var user = ClientHelper.GetUser();

		if (isClientStandartUser && user.Id != assignUserId) {
			showMessage("warning", "Warning", "This is already assigned to other user. You cannot change this.");

			return;
		}

		var { model, assignUserList } = this.state;
		model.ApplicationId = applicationId;

		var assignUserIndex = assignUserId != null
			? assignUserList.map(function (e) { return e.Id; }).indexOf(assignUserId)
			: null;

		this.setState({ model, assignUserIndex, isAssignDialogOpen: true });
	}

	DialogAssignClose = () => {
		var { model } = this.state;
		model.AssignUserId = null;

		this.setState({ model, isAssignDialogOpen: false });
	}

	async AssignApplicantToUser() {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/Application/UpdateAssignUser", { ApplicationId: model.ApplicationId, AssignUserId: model.AssignUserId }, null, null, null, null, true);

		if (result != null) {
			this.setState({ isAssignDialogOpen: false });
			this.Search();
		}
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	render() {

		const { classes, Disabled } = this.props;
		const { alert, isLoading, model,
			selected, isAssignDialogOpen, assignUserList, assignUserIndex, isBackOffice, isClientStandartUser } = this.state;
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.Search },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={isAssignDialogOpen} maxWidth="sm" fullWidth>
					<DialogTitle style={{ background: "white", marginBottom: -30, marginTop: 0 }}  >
						<GenericLabel
							Bold
							Text="Assign User"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent
						style={{ height: "auto", marginTop: 8 }}>
						<div style={{ position: "fixed", top: "auto", left: "auto", width: "555px", zIndex: 1, margin: "-5px -1px" }}>
							<GenericSelectInput
								IsStatic
								Disabled={isClientStandartUser == true}
								Name="AssignUserId"
								LabelText="Assign User"
								StaticData={assignUserList}
								DefaultIndex={isClientStandartUser == true ? 0 : assignUserIndex != -1 || assignUserIndex != null ? assignUserIndex : null}
								KeyValueMember="Id"
								TextValueMember="FullName"
								Value={model.AssignUserId}
								ValueChanged={this.HandleChange} />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.AssignApplicantToUser}>CONFIRM</Button>
						<Button size="sm" onClick={this.DialogAssignClose}>CANCEL</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											{isBackOffice && (
												<GridItem xs={2} >
													<GenericLabel
														Text="Client" />
													<GenericSelectInput
														Name="UniqueClientId"
														Method="POST"
														LabelMd={0}
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														All
														Disabled={!isBackOffice}
														DefaultIndex={isBackOffice ? 0 : -1}
													/>
												</GridItem>
											)}
										</GridContainer>
										<GridContainer>
											<GridItem xs={1}>
												<label style={styles.LabelText}>From Date</label>
												<GridItem style={styles.ComboStyle}>
													<GenericDateInput
														Name="FromDate"
														LabelMd={3}
														//LabelText="From"
														Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : null}
														ValueChanged={this.HandleChange}
														Utc />
												</GridItem>
											</GridItem>
											<GridItem xs={1}>
												<label style={styles.LabelText}>To Date</label>
												<GridItem style={styles.ComboStyle}>
													<GenericDateInput
														Name="ToDate"
														LabelMd={3}
														//LabelText="To"
														Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : null}
														ValueChanged={this.HandleChange}
														Utc />
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Application Channel</label>
												<GridItem >
													<GenericSelectInput
														Name="SaleChannelId"
														LabelMd={3}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.SalesChannelParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.SaleChannelId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Loan Type</label>
												<GridItem style={styles.ComboStyle}>
													<GenericSelectInput
														Name="LoanTypeId"
														LabelMd={3}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.LoanTypeParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterValue2"
														Value={model.LoanTypeId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Partner</label>
												<GridItem style={styles.ComboStyle}>
													<GenericSelectInput
														Name="ClientPartnerId"
														LabelMd={3}
														Method="POST"
														Url="/losapi/v1.0/ClientPartner/GetAll"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="LegalNameOfBusiness"
														Value={model.ClientPartnerId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Term</label>
												<GridItem style={styles.ComboStyle}>
													<GenericNumberInput
														Name="LOSLoanTermId"
														LabelMd={3}
														Value={model.LOSLoanTermId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Status</label>
												<GridItem style={styles.ComboStyle}>
													<GenericSelectInput
														Name="ApprovalStatusId"
														LabelMd={3}
														//LabelText="Program"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														DataRoot="Item"
														Parameter={this.ApprovalParameterCode}
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.ApprovalStatusId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>

							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12} style={{ marginTop: 20 }}>
						<Card>

							<CardHeader color="warning" icon>
								<CardIcon color="warning">
									<List />
								</CardIcon>
								<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
									<b>Loan Applications</b>
								</h4>
							</CardHeader>

							<CardBody>
								<GridItem xs={12}>
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="stretch">

										<GridItem xs={6}>
											<GridContainer
												direction="column"
												justify="flex-start"
												alignItems="stretch"	>

												<LoanApplicationsChart
													list={this.state.programList}
													transactionChartData={this.state.trxChartData}
													creditDebitChartData={this.state.creditDebitChartData}
													feeChartData={this.state.feeChartData}
													leftTitle={"Programs"}
													TitleChart={"Term Loan"}
													TitleChart2={"Line Of Credit"}
													TitleChart3={"Secured Card"}
													centerTitle={"REPORTS"}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
												/>

											</GridContainer>

										</GridItem>
										<GridItem xs={6}>
											<GridContainer
												direction="column"
												justify="flex-start"
												alignItems="stretch"	>

												<LoanApprovalStatusChart
													list={this.state.programList}
													onHoldChartData={this.state.onHoldChartData}
													approvedChartData={this.state.approvedChartData}
													rejectedChartData={this.state.rejectedChartData}
													TitleChart={"Application On Hold"}
													TitleChart2={"Application Approved"}
													TitleChart3={"Application Rejected"}
													leftTitle={"Programs"}
													centerTitle={"REPORT"}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
												/>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
					<Card>
						<CardBody>
							<GenericExpansionPanel Title={"Application Summary"}>
								<GridContainer>
									<GridItem xs={12}>
										<GenericGrid

											Data={this.state.ApplicationsList || []}
											Columns={this.ColumnsData}
											ColumnSelected={this.SelectedColumnChange}
											SelectedIndex={selected}
											IsSorted={false} />


									</GridItem>
								</GridContainer>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				</GridContainer>
			</div >
		);
	}
}

const styles = ({
	LabelText: {
		color: "Black",
		// fontSize: 12,
	},
	ComboStyle: {
		marginLeft: -50
	}
});

LoanApplications.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withStyles(styles)(LoanApplications);