import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericIcon, GenericLabel, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CrudType, ProgramCodes } from "views/Constants/Constant";

class CreateClientCustomerWithFileUpload extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.initialModel = {
			Id: null,
			Binary: null,
			TempFileId: null,
			FileName: null,
			File: null,
			FileSelect: false
		};

		this.state = {
			list: [],
			IsResponseDialog: false,
			model: this.initialModel,
			isLoading: false,
			selected: null,
			bankAccounts: [],
			responseList: [],
			isFileDialogOpen: false,
			isFileResultDialogOpen: false,
			FileResultList: []
		};


	}

	componentDidMount() {
		this.props.setAppLeftTitle("Create Bulk Customer");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		// this.GetCustomerBankAccounts();
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
	}

	HandleClear = () => {
		const { model } = { ...this.state };
		delete model.File;
		delete model.Id;
		delete model.FileName;
		delete model.Binary;
		delete model.TempFileId;
		delete model.FileSelect;

		this.setState({ model });
	}

	HandleSubmitDialog = () => {
		if (!this.Validate())
			return;
		this.setState({ isFileDialogOpen: true });
	}
	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		const { model } = { ...this.state };
		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("fileId", model.TempFileId || 0);
		formData.append("isBinaryOrBase64", model.Binary);
		formData.append("isInsertOrUpdate", model.Id == null ? CrudType.Insert : CrudType.Update);

		Proxy.FileUpload("/bankapi/v1.0/ClientCustomer/BulkUploadInserrt",
			formData,
			(responseData) => {
				if (!responseData.IsSucceeded && responseData.Item < 1) {
					this.setState({ isLoading: false , isFileDialogOpen: false});
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {

					Proxy.POST("/bankapi/v1.0/ClientCustomer/GetFileInformation",
						{ Id: responseData.Item.FileId },
						fileResponseData => {
							if (!fileResponseData.IsSucceeded) {
								this.setState({ isLoading: false, isFileDialogOpen: false });

								this.props.showMessage("error", "An error occurred while load file information", fileResponseData.ErrorDescription);
								return;
							}

							this.setState({ FileResultList: fileResponseData.Item, isFileResultDialogOpen: true, isLoading: false, isFileDialogOpen: false });
							this.HandleClear();
						},
						errorMessage => {
							this.setState({ isLoading: false, isFileDialogOpen: false });
							this.props.showMessage("error", "An error occurred while load file information", errorMessage);
						}
					);
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	FileSelect = (e) => {

		this.setState({ isLoading: true })
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;
		model.File = file;
		model.FileName = file.name;
		model.FileSelect = true;
		model.TempFileId = model.File !== undefined
			? model.File.Id
			: 0;


		this.setState({ model, isLoading: false });
	}


	Validate = () => {
		const model = this.state.model;

		if (!model.File) {
			this.ShowMessage("warning", "Invalid", "Please select file !");
			return;
		}

		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	GetCustomerBankAccounts = () => {
		//GetCustomerBankAccountsOp class
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Account/CustomerAccountProfile",
			{

			},
			responseData => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						bankAccounts: responseData.Item.List || []
					});
				} else {
					this.setState({
						bankAccounts: []
					});
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error !", "An error occurred while requesting data " + error);
			}
		);
	}



	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}
	render() {
		const {  Disabled } = this.props;
		const { alert, model,  isLoading, isFileResultDialogOpen, FileResultList, isFileDialogOpen } = this.state;


		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}

				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />



				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmitDialog, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericTextInput Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
						<GenericTextInput Name="Date" LabelText="Date" Value={Formatter.FormatDate(DateHelper.GetDateTimeLocal())} Disabled={true} />
						<br />
						<GenericLabel Text="Are you sure you want to upload file ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSubmit}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={isFileResultDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="File Upload Process Done" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={() => this.setState({ isFileResultDialogOpen: false })}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={FileResultList}
							Columns={[
								{
									Header: "Line Number",
									accessor: "LineId"
								},
								{
									Header: "Is Rejected",
									accessor: "IsRejected",
									Cell: row => row.value ? "Yes" : "No"
								},
								{
									Header: "Comment",
									accessor: "Comment"
								},
								{
									Header: "CustomerNumber",
									accessor: "CustomerNumber"
								},
								{
									Header: "CustomerTypeCode",
									accessor: "CustomerTypeCode"
								},
								{
									Header: "Email",
									accessor: "Email"
								},
								{
									Header: "PhoneCountryCode",
									accessor: "PhoneCountryCode"
								},
								{
									Header: "PhoneNumber",
									accessor: "PhoneNumber"
								},
								{
									Header: "FirstName",
									accessor: "FirstName"
								},
								{
									Header: "LastName",
									accessor: "LastName"
								},
								{
									Header: "BirthDate",
									accessor: "BirthDate"
								},
								{
									Header: "IdentificationMethod",
									accessor: "IdentificationMethod"
								},
								{
									Header: "IdentificationType",
									accessor: "IdentificationType"
								},
								{
									Header: "IdentificationNo",
									accessor: "IdentificationNo"
								},
								{
									Header: "IdentityExpirementDate",
									accessor: "IdentityExpirementDate",
								},
								{
									Header: "LegalName",
									accessor: "LegalName"
								},
								{
									Header: "BusinessIdNumber",
									accessor: "BusinessIdNumber"
								},
								{
									Header: "BusinessName",
									accessor: "BusinessName",

								},
								{
									Header: "CompanyName",
									accessor: "CompanyName"
								},
								{
									Header: "RegistrationName",
									accessor: "RegistrationName"
								},
								{
									Header: "RetailName",
									accessor: "RetailName"
								},

							]}
							ShowPagination={true}
							SelectedIndex={this.state.rowIndex}
							ProgramCode={ProgramCodes.Prepaid} />
					</DialogContent>
				</GenericDialog>




				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Transaction">
								<CardBody>
									<GridContainer>
										<GridItem xs={3}>
											<GenericTextInput LabelMd={3} Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
										</GridItem>
										<GridItem>
											<GenericLabel Text="Upload File" />
										</GridItem>
										<GridItem>
											<Button size="sm" onClick={this.Upload}>BROWSE</Button></GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>

					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CreateClientCustomerWithFileUpload.propTypes = {
	classes: PropTypes.object.isRequired
};
export default withArtifex(CreateClientCustomerWithFileUpload, {});