import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import StringHelper from "core/StringHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class BinRangeProfile extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			RangeFrom: "00000000000",
			RangeTo: "00000000000",
			BinId: 0,
			BinName: "",
			Bin: {},
			HasBinRange: false,
			IsRecordValid: true,
			Profile: {},
			Index: 0
		};

		this.initialProfileModel = {
			Id: 0,
			IsRecordValid: true,
			ProfileName: ""
		};
		this.state = {
			profileModel: { ...this.initialProfileModel },
			profileList: [],
			profileIndex: -1,
			detailModel: { ...this.initialModel },
			fullDetailList: [],
			detailList: [],
			detailIndex: -1,
			vModel: {},
			hasChangedProfile: false,
			hasChangedDetail: false,
			hasChandedFromList: false,
			isEditProfile: false,
			isEditDetail: false,
			isLoading: false
		};

		this.emptyObject = {};

		this.ColumnsDataProfile = [];

		this.ColumnsDataDetail = [];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Bin Range Profile Defination");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.GetColumnsProfile();
		this.GetColumnsDetail();
	}

	GetColumnsProfile = () => {
		this.ColumnsDataProfile = [
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date,
				width: 200
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			}
		];

		this.forceUpdate();
	}

	GetColumnsDetail = () => {
		this.ColumnsDataDetail = [
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date,
				width: 200
			},
			{
				Header: "Profile Name",
				accessor: "Profile.ProfileName"
			},
			{
				Header: "Bin Id",
				accessor: "BinId"
			},
			{
				Header: "Bin",
				accessor: "Bin"
			},
			{
				Header: "Bin Description",
				accessor: "BinName"
			},
			{
				Header: "Has Bin Range",
				accessor: "HasBinRange",
				type: GridColumnType.YesNo,

			},
			{
				Header: "Range From",
				accessor: "RangeFrom",
				Cell: row => (
					row.value ? StringHelper.PadLeft("0", row.value, 10) : ""
				)
			},
			{
				Header: "Range To",
				accessor: "RangeTo",
				Cell: row => (
					row.value ? StringHelper.PadLeft("0", row.value, 10) : ""
				)
			}
		];

		this.forceUpdate();
	}

	HandleChangeProfile = (name, newValue, data) => {
		const profileModel = { ...this.state.profileModel };
		profileModel[name] = newValue;
		this.setState({ profileModel });
	}

	HandleChangeDetail = (name, newValue, data) => {
		const { profileIndex, detailModel } = this.state;
		if (profileIndex === -1) {
			this.ShowMessage("warning", "Warning", "Please add an profile before!");
			return;
		}

		if (name == "RangeFrom") {
			if (StringHelper.VerifyDigit(newValue))
				detailModel.RangeFrom = newValue;
			else
				return;
		}
		if (name == "RangeTo") {
			if (StringHelper.VerifyDigit(newValue))
				detailModel.RangeTo = newValue;
			else
				return;
		}

		if (name == "BinId") {
			detailModel.BinName = data && data.Description;
			detailModel.Bin = data && data.Bin;
		}


		detailModel[name] = newValue;
		this.setState({ detailModel });
	}

	HandleClear = () => {
		this.setState({ profileModel: { ...this.initialModel }, detailModel: { ...this.initialModel }, profileIndex: -1, detailIndex: -1, isEditProfile: false, isEditDetail: false, hasChandedFromList: false });
	}

	HandleClearProfile = () => {
		this.setState({ profileModel: { ...this.initialModel }, profileIndex: -1, isEditProfile: false });
	}

	HandleClearDetail = () => {
		this.setState({ detailModel: { ...this.initialModel }, detailIndex: -1, isEditDetail: false, hasChandedFromList: false });
	}

	HandleSearch = () => {
		const { profileModel } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/BinRangeProfile/GetAll",
			{
				Id: profileModel.Id,
				ProfileName: profileModel.ProfileName,
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				var profileList = responseData.Item.BinRangeProfileList;
				var fullDetailList = responseData.Item.BinRangeProfileDetailList;

				for (var i = 0; i < profileList.length; i++) {
					for (var j = 0; j < fullDetailList.length; j++) {
						if (this.isEquivalent(profileList[i], fullDetailList[j].Profile)) {
							fullDetailList[j].Index = i;
						}
					}
				}

				this.setState({ profileList, fullDetailList });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		const { profileList, detailList } = this.state;

		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/BinRangeProfile/InsertOrUpdate",
			{
				BinRangeProfileList: profileList,
				BinRangeProfileDetailList: detailList,
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ hasChandedFromList: false });
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	AddProfile = () => {
		const { profileIndex, profileModel, profileList, isEditProfile, hasChangedProfile } = this.state;

		if (!this.AddProfileValidate()) {
			return;
		}

		profileModel.IsRecordValid = true;

		if (isEditProfile && profileIndex > -1) {
			profileList[profileIndex] = profileModel;
		} else {
			profileList.push(profileModel);
		}
		this.setState({ profileList, profileModel: { ...this.initialModel }, hasChangedProfile: !hasChangedProfile, isEditProfile: false });
		this.GetColumnsProfile();
	}

	AddDetail = () => {
		const { detailIndex, detailModel, detailList, isEditDetail, hasChangedDetail, profileModel, profileIndex } = this.state;

		var messages = [];

		if (detailModel.HasBinRange) {
			if (!detailModel.RangeFrom)
				messages.push("Range From cannot be empty!");

			if (!detailModel.RangeTo)
				messages.push("Range To cannot be empty!");

			if (detailModel.RangeFrom == detailModel.RangeTo)
				messages.push("Range From and Range To not equals!");

			else if (Number(detailModel.RangeFrom) > Number(detailModel.RangeTo))
				messages.push("Range From cannot be greater than Range To!");
		}

		if (!detailModel.BinId) {
			messages.push("Enter the Bin In to continue.");
			return;
		}
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		detailModel.IsRecordValid = true;
		detailModel.Profile = profileModel;
		detailModel.Index = profileIndex;

		if (isEditDetail && detailIndex > -1) {
			detailList[detailIndex] = detailModel;
		} else {
			detailList.push(detailModel);
		}
		this.setState({
			detailList, detailModel: { ...this.initialModel }, detailIndex: -1, hasChangedDetail: !hasChangedDetail, isEditDetail: false,
			hasChandedFromList: true
		});
		this.GetColumnsDetail();
	}

	DeleteProfile = () => {
		const { profileList, detailList, profileIndex, hasChangedProfile } = this.state;
		profileList[profileIndex].IsRecordValid = false;
		var deletedDetails = detailList.filter(x => x.Index === profileIndex);
		deletedDetails.forEach(detail => {
			detail.IsRecordValid = false;
		});
		this.setState({ profileList, profileModel: { ...this.initialModel }, hasChangedProfile: !hasChangedProfile, isEditProfile: false, detailList });
		this.GetColumnsProfile();
	}

	DeleteDetail = () => {
		const { detailList, detailIndex, hasChangedDetail } = this.state;
		detailList[detailIndex].IsRecordValid = false;
		this.setState({ detailList, detailModel: { ...this.initialModel }, hasChangedDetail: !hasChangedDetail, isEditDetail: false, hasChandedFromList: true });
		this.GetColumnsDetail();
	}

	RowSelectedProfile = (index) => {
		let { detailList, fullDetailList, detailModel, hasChandedFromList } = this.state;

		if (hasChandedFromList) {
			this.ShowMessage("warning", "There's a change in the list", "Please submit the change!");
			return;
		}

		detailModel = { ...this.initialModel };
		detailModel.Index = index;
		detailList = fullDetailList.filter(k => k.IsRecordValid && k.Index == index);

		this.setState({
			isEditProfile: true, profileModel: { ...this.state.profileList.filter(x => x.IsRecordValid)[index] }, profileIndex: index,
			isEditDetail: false, detailModel, detailIndex: -1, detailList,
		});
	}

	RowSelectedDetail = (index) => {


		var detail = { ...this.state.detailList.filter(x => x.IsRecordValid)[index] };
		if (detail != null) {
			detail.RangeFrom = StringHelper.PadLeft("0", detail.RangeFrom, 10);
			detail.RangeTo = StringHelper.PadLeft("0", detail.RangeTo, 10);
		}

		this.setState({ isEditDetail: true, detailModel: detail, detailIndex: index });
	}

	isEquivalent = (a, b) => {
		// Create arrays of property names
		var aProps = Object.getOwnPropertyNames(a);
		var bProps = Object.getOwnPropertyNames(b);

		// If number of properties is different,
		// objects are not equivalent
		if (aProps.length != bProps.length) {
			return false;
		}

		for (var i = 0; i < aProps.length; i++) {
			var propName = aProps[i];

			// If values of same property are not equal,
			// objects are not equivalent
			if (a[propName] !== b[propName]) {
				return false;
			}
		}

		// If we made it this far, objects
		// are considered equivalent
		return true;
	}

	Validate = () => {
		const { profileModel, vModel, profileList } = this.state;
		var messages = [];

		vModel.ProfileName = profileList.length == 0 && (profileModel.ProfileName == null || profileModel.ProfileName.trim() == "");
		if (vModel.ProfileName) messages.push("Profile Name cannot be empty");

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	AddProfileValidate = () => {
		const { profileModel, vModel } = this.state;
		var messages = [];

		vModel.ProfileName = profileModel.ProfileName == null || profileModel.ProfileName.trim() == "";
		if (vModel.ProfileName) messages.push("Profile Name cannot be empty");

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, vModel, profileModel, detailModel, profileList, detailList, isEditProfile, isEditDetail, profileIndex, detailIndex } = this.state;

		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: profileModel.Id || Disabled },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: !profileModel.Id || Disabled },

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Bin Range Profile"} />
							</CardHeader>
							<CardBody>
								<GridItem>
								</GridItem>
								<GridContainer>
									<GridItem xs={3}>
										<GenericTextInput
											Name="ProfileName"
											LabelText="Profile Name"
											Value={profileModel.ProfileName}
											ValueChanged={this.HandleChangeProfile}
											Required
											IsInvalid={vModel.ProfileName} />
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={8} />
									<GridItem xs={4}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button size="sm" onClick={this.AddProfile} disabled={Disabled}>{isEditProfile ? "EDIT" : "ADD"}</Button>
												<Button size="sm" onClick={this.DeleteProfile} disabled={Disabled || !isEditProfile}>DELETE</Button>
												<Button size="sm" onClick={this.HandleClearProfile} disabled={Disabled}>CLEAR</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={profileList.filter(x => x.IsRecordValid)}
											Columns={this.ColumnsDataProfile}
											RowSelected={this.RowSelectedProfile}
											SelectedIndex={profileIndex}
											HideButton={true}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ opacity: profileIndex === -1 ? 0.4 : 1 }}>
							<CardHeader>
								<GenericTitle text="Bin Range Profile Detail" />
							</CardHeader>
							<CardBody>
								<GridItem>
								</GridItem>
								<GridContainer>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="BinId"
											LabelText="Bin"
											Method="GET"
											Url="/prepaidapi/v1.0/OnusBin/GetAll"
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.Bin} - ${d.Description}`}
											Value={detailModel.BinId}
											ValueChanged={this.HandleChangeDetail}
											CanClear
											All
											Disabled={Disabled || profileIndex === -1} />
									</GridItem>
									<GridItem xs={3}>
										<GenericCheckInput
											Name="HasBinRange"
											LabelText="Has Bin Range"
											Value={detailModel.HasBinRange || false}
											ValueChanged={this.HandleChangeDetail}
											Disabled={Disabled || profileIndex === -1} />
									</GridItem>
									{detailModel.HasBinRange &&
										<>
											<GridItem xs={3}>
												<GenericTextInput
													Name="RangeFrom"
													LabelText="Range From"
													Value={detailModel.RangeFrom}
													ValueChanged={this.HandleChangeDetail}
													Format={"@@@@@@@@@@"}
													Disabled={Disabled || profileIndex === -1} />
											</GridItem>
											<GridItem xs={3}>
												<GenericTextInput
													Name="RangeTo"
													LabelText="Range To"
													Value={detailModel.RangeTo}
													ValueChanged={this.HandleChangeDetail}
													Format={"@@@@@@@@@@@@"}
													Disabled={Disabled || profileIndex === -1} />
											</GridItem>
										</>
									}
								</GridContainer>
								<GridContainer>
									<GridItem xs={8} />
									<GridItem xs={4}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button size="sm" onClick={this.AddDetail} disabled={Disabled || profileIndex === -1}>{isEditDetail ? "EDIT" : "ADD"}</Button>
												<Button size="sm" onClick={this.DeleteDetail} disabled={!isEditDetail || profileIndex === -1}>DELETE</Button>
												<Button size="sm" onClick={this.HandleClearDetail} disabled={Disabled || profileIndex === -1}>CLEAR</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={detailList.filter(x => x.IsRecordValid)}
											Columns={this.ColumnsDataDetail}
											RowSelected={this.RowSelectedDetail}
											SelectedIndex={detailIndex}
											HideButton={true} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

BinRangeProfile.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(BinRangeProfile, {});