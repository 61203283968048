import { Dialog, DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import React from "react";
import { GenericAlert, GenericCheckInput, GenericLabel } from "views/Components/Generic";
import { ClientType } from "views/Constants/Constant.js";
import { MenuCodes, ActionCodes } from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";

class Program extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allPrograms: [],
			isLoading: false,
			checkModels: this.props.paramBankCustomerProgramList,
			CustomDisabled: null
		};
	}



	componentDidMount = () => {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Programs");


		this.setState({ checkModels: this.props.paramBankCustomerProgramList });
		this.LoadProgramsForUi();

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedPrograms, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}
	LoadProgramsForUi = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bank/GetBankPrograms",
			null,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ allPrograms: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, value) => {
		var index = this.state.checkModels.map(x => { return x.BankProgramId; }).indexOf(name);
		if (index != -1) {
			var temp = this.state.checkModels;
			var obj = temp[index];
			obj.HasAuth = value;
			temp.splice(index, 1);
			temp.splice(index, 0, obj);
			this.setState({ checkModels: temp });
		} else {
			var obj = { BankProgramId: name, UniqueClientId: this.props.paramGlobalModel.Id, HasAuth: value, Id: 0 };
			this.setState(prevState => ({
				checkModels: [...prevState.checkModels, obj]
			}));
		}
	}
	UpdatePrograms = () => {
		const global = { ...this.props.paramGlobalModel };
		if (this.state.checkModels.filter(x => x.HasAuth).length < 1) {
			this.ShowMessage("error", "Error", "Select at least one program");
			return;
		}
		if (global == null || global.Id == 0 || global.Id == null) {
			this.ShowMessage("error", "Error", "You must select Client to add or update programs !");
			return;
		}

		var temp = this.state.checkModels;
		temp.forEach(element => {
			element.UniqueClientId = global.Id;
		});

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerProgram/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ checkModels: responseData.Item || [] });
				}
				this.ShowMessageSuccess("success", "Success", "Client programs saved ! ");
				this.props.GetCustomerProgramModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);

	}
	ClearData = () => {
		this.setState({ allPrograms: [] });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessageSuccess = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => {
				this.hideAlert();				
			}} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {

		const { checkModels } = this.state;
		const { Disabled: DisabledProp } = this.props;
		const Disabled = DisabledProp || this.state.CustomDisabled;
		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				<Card className="no-radius">
					<CardBody>
						<GridItem md={12} xs={12}>
							<GridContainer justify="flex-end">
								<GridItem>								
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeParentTab(null, 0)} > Back </Button>
									<Button size="sm" color="black" disabled={Disabled} onClick={() => this.props.handleChangeParentTab(null, 2)} > Next </Button>
								</GridItem>
							</GridContainer>

							<GridContainer>
								{this.props.paramGlobalModel != null && (this.props.paramGlobalModel.ClientType == ClientType.Corporate || this.props.paramGlobalModel.ClientType == ClientType.SalesAgentCorporate || this.props.paramGlobalModel.ClientType == null) && (
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<h4 style={{ background: "black", color: "white", padding: "3px", borderRadius: "4px", textAlign: "center", fontSize: "14px" }}><b> PROGRAMS (CORPORATE CLIENTS) </b></h4>
										{this.state.allPrograms.filter(x => x.IsService == false).map(parent => (
											<div key={String(parent.ProgramCode + parent.Id)}>
												{/* <h4><b>{parent.Description}</b></h4> */}
												<GridContainer key={parent.ProgramCode}>
													{parent.SubList.map(subParent => (
														<GridItem xs={12} sm={6} md={6} lg={6} key={String(subParent.ProgramCode + parent.Id)}>
															<h4 style={{ background: "black", color: "white", padding: "3px", borderRadius: "4px", textAlign: "center", fontSize: "13px" }} >{subParent.Description}</h4>
															<GridContainer key={subParent.Id} >
																{subParent.List != undefined && subParent.List.map(subList => (
																	<GridItem xs={12} sm={6} md={6} lg={6} key={String(subList.ProgramCode + subList.Id)} >
																		<GenericCheckInput Disabled={Disabled} key={subList.Id} Name={subList.Id} LabelText={subList.Description} Value={checkModels.find(x => x.BankProgramId == subList.Id) == null ? false : checkModels.find(x => x.BankProgramId == subList.Id).HasAuth} ValueChanged={this.handleChange} />
																	</GridItem>
																))}
															</GridContainer>
														</GridItem>
													))}
												</GridContainer>
											</div>
										))}
									</GridItem>
								)}

								<GridItem xs={12} sm={6} md={6} lg={6}>
									<h4 style={{ background: "black", color: "white", padding: "3px", borderRadius: "4px", textAlign: "center", fontSize: "14px" }}><b> SERVICES (CORPORATE & PERSONAL CLIENTS) </b></h4>
									{this.state.allPrograms.filter(x => x.IsService == true).map(parent => (
										<div key={String(parent.ProgramCode + parent.Id)}>
											{/* <h4><b>{parent.Description}</b></h4> */}
											<GridContainer key={String(parent.ProgramCode + parent.Id)}>
												{parent.SubList.map(subParent => (
													<GridItem xs={12} sm={6} md={6} lg={6} key={String(subParent.ProgramCode + subParent.Id)}>
														<h4 style={{ background: "black", color: "white", padding: "3px", borderRadius: "4px", textAlign: "center", fontSize: "13px" }} >{subParent.Description}</h4>
														<GridContainer key={subParent.Id} >
															{subParent.List != null && subParent.List.map(subList => (
																<GridItem xs={12} sm={6} md={6} lg={6} key={String(subList.ProgramCode + subList.Id)} >
																	<GenericCheckInput Disabled={Disabled} key={subList.Id} Name={subList.Id} LabelText={subList.Description} Value={checkModels.find(x => x.BankProgramId == subList.Id) == null ? false : checkModels.find(x => x.BankProgramId == subList.Id).HasAuth} ValueChanged={this.handleChange} />
																</GridItem>
															))}
														</GridContainer>
													</GridItem>
												))}
											</GridContainer>
										</div>
									))}
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridContainer justify="flex-end">
							<GridItem style={{ float: "right" }}>
								<Button
									size="sm"
									color="black"
									disabled={Disabled }
									onClick={() => this.UpdatePrograms()} >
									Save
								 </Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}


export default withArtifex(Program, {});