import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { PeriodCodes } from "views/Constants/Constant.js";

class BillRecurring extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customerToken: "0",
			IsPayIndivudalActive: false,
			IsPayRecurringActive: false,
			tempmodel: {},
			FreezePaymentCount: 0

		};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.TotalAmountChanged = this.TotalAmountChanged.bind(this);
		this.GetTooltip = this.GetTooltip.bind(this);
		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
		this.CalculateForFreezeDate = this.CalculateForFreezeDate.bind(this);
	}


	TotalAmountChanged(name, newValue, data) {
		const { model } = this.props.model;
		model.TotalAmount = newValue;
		this.setState({ model: model });
	}

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;
			if (data) {
				if (name == "PeriodId") model.PeriodCode = data.ParameterValue;
				if (name == "WeeklyId") model.WeeklyNumber = data.ParameterValue;
				if (name == "BiWeeklyId") model.BiWeeklyNumber = data.ParameterValue;
				if (name == "MonthlyId") model.MonthlyNumber = data.ParameterValue;
				if (name == "QuarterlyId") model.QuarterlyNumber = data.ParameterValue;

			}
			if (name == "NewClient") {
				if (newValue) {
					model.IsCustomerPopupOpen = true;
					this.setState({ isCustomerAdded: false });
				}
			}

			try {


				const { StartDate: momentStartDate, EndDate: momentEndDate, WeeklyNumber, BiWeeklyNumber, MonthlyNumber, QuarterlyNumber } = model;
				const StartDate = momentStartDate.toDate();
				const EndDate = momentEndDate.toDate();

				if (name == "StartDate" || name == "EndDate" || name == "PeriodId" || name == "WeeklyId" ||
					name == "BiWeeklyId" || name == "MonthlyId" || name == "QuarterlyId" ||
					name == "FreezeStartDate" || name == "FreezeEndDate" || name == "RecurringAmount") {
					if (typeof StartDate == "object" && typeof EndDate == "object" && model.PeriodId) {

						var count;
						var dayDif = (d1, d) => d1 > d ? d - d1 + 7 : d - d1;
						var addDays = (date, days) => { var nd = new Date(date); nd.setDate(date.getDate() + days); return nd; };
						var monthDif = (d1, d2) => Math.round(moment.duration(new Date(d2.getFullYear(), d2.getMonth() + 1, 1) - new Date(d1.getFullYear(), d1.getMonth(), 1)).asMonths());
						var lastDayOfMonth = d => new Date(d.getFullYear(), d.getMonth() + 1, 0);

						switch (model.PeriodCode) {
							case PeriodCodes.Weekly:
								if (WeeklyNumber != null && WeeklyNumber != "") {
									var firstWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), WeeklyNumber));
									if (EndDate - firstWeekPayDate < 0) { count = 0; break; }
									count = Math.floor(moment.duration(EndDate - firstWeekPayDate).asDays() / 7 + 1);
								}
								break;
							case PeriodCodes.BiWeekly:
								if (BiWeeklyNumber != null && BiWeeklyNumber != "") {
									var firstBiWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), BiWeeklyNumber));
									if (EndDate - firstBiWeekPayDate < 0) break;
									count = Math.floor(moment.duration(EndDate - firstBiWeekPayDate).asDays() / 14 + 1);
								}
								break;
							case PeriodCodes.SemiMonthly:
								var sMonthDiff = monthDif(StartDate, EndDate);
								if (sMonthDiff < 1) break;
								if (sMonthDiff == 1) {
									if (StartDate.getDate() > 16) break;
									if (StartDate.getDate() > 1 && EndDate.getDate() < 16) break;
									if (StartDate.getDate() > 1) { count = 1; break; }
									if (StartDate.getDate() == 1 && EndDate.getDate() < 16) { count = 1; break; }
									if (StartDate.getDate() == 1) { count = 2; break; }
								}
								if (sMonthDiff > 1) {
									var smStartDateSkip = (StartDate.getDate() == 1) ? 0 : (StartDate.getDate() <= 16) ? 1 : 2;
									var smEndDateSkip = EndDate.getDate() >= 16 ? 0 : 1;
									count = (2 * sMonthDiff) - smStartDateSkip - smEndDateSkip;
								}
								break;
							case PeriodCodes.Monthly:
								if (MonthlyNumber != null && MonthlyNumber != "") {
									var mMonthDiff = monthDif(StartDate, EndDate);
									if (mMonthDiff < 1) break;
									if (mMonthDiff == 1) {
										if (StartDate.getDate() > MonthlyNumber || EndDate.getDate() < MonthlyNumber) break;
										if (StartDate.getDate() <= MonthlyNumber && EndDate.getDate() >= MonthlyNumber) { count = 1; break; }
									}
									if (mMonthDiff > 1) {
										var mStartDateSkip = StartDate.getDate() > MonthlyNumber ? 1 : 0;
										var mEndDateSkip = EndDate.getDate() < MonthlyNumber ? 1 : 0;
										count = mMonthDiff - mStartDateSkip - mEndDateSkip;
									}
								}
								break;
							case PeriodCodes.MonthlyMonthEnd:
								var meMonthDiff = monthDif(StartDate, EndDate);
								if (meMonthDiff < 1) break;
								if (meMonthDiff == 1) {
									var meLastMonthDay = lastDayOfMonth(EndDate).getDate();
									if (StartDate.getDate() > meLastMonthDay || EndDate.getDate() < meLastMonthDay) break;
									if (StartDate.getDate() <= meLastMonthDay && EndDate.getDate() >= meLastMonthDay) { count = 1; break; }
								}
								if (meMonthDiff > 1) {
									var meStartDateSkip = StartDate.getDate() > lastDayOfMonth(StartDate).getDate() ? 1 : 0;
									var meEndDateSkip = EndDate.getDate() < lastDayOfMonth(EndDate).getDate() ? 1 : 0;
									count = meMonthDiff - meStartDateSkip - meEndDateSkip;
								}
								break;
							case PeriodCodes.Quarterly:
								if (QuarterlyNumber != null && QuarterlyNumber != "") {
									var qbStartDate = addDays(StartDate, -QuarterlyNumber);
									var qStartNewMonth = qbStartDate.getMonth() - (qbStartDate.getMonth() % 3) + 3;
									var qStartDate = new Date(qbStartDate.getFullYear(), qStartNewMonth, QuarterlyNumber);
									var qaEndDate = addDays(EndDate, -QuarterlyNumber + 1);
									var qEndNewMonth = qaEndDate.getMonth() - (qaEndDate.getMonth() % 3);
									var qEndDate = new Date(qaEndDate.getFullYear(), qEndNewMonth, QuarterlyNumber);
									var qDiff = monthDif(qStartDate, qEndDate);
									count = (qDiff + 2) / 3;
								}
								break;
							case PeriodCodes.QuarterlyMonthEnd:
								var qeGetStartMonth = n => n - (n % 3) + 3;
								var qeStartDate = new Date(StartDate.getFullYear(), qeGetStartMonth(StartDate.getMonth()) + 1, 0);
								var qeGetEndMonth = n => n - (n % 3);
								var qeaEndDate = addDays(EndDate, 1);
								var qeEndDate = new Date(qeaEndDate.getFullYear(), qeGetEndMonth(qeaEndDate.getMonth()) + 1, 0);
								var qeDiff = monthDif(qeStartDate, qeEndDate);
								count = (qeDiff + 2) / 3;
								break;
							default:
								break;
						}

						if (count == 0) count = 0;

						model.PaymentCount = count;

						if ((model.FreezeStartDate != "" && model.FreezeStartDate != null)
							&& (model.FreezeEndDate != "" && model.FreezeEndDate != null)) {

							this.CalculateForFreezeDate(model);
							model.PaymentCount = model.FPC;

						}

						if (model.FPC != null && model.FPC != 0)
							count = model.FPC;

						model.PaymentCount = count;

						model.TotalAmount = model.RecurringAmount && model.PaymentCount && model.RecurringAmount * model.PaymentCount;


					}
				}

			}
			catch (error) {
				//	console.log("");
			}
			return model;
		});
	}

	GetTooltip() {
		const { model } = this.props;

		switch (model.PeriodCode) {
			case PeriodCodes.Weekly:
				return "The user will select this option for weekly payment processes. The day of the week that the payment occurs is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on a Wednesday, then the weekly payment will occur every Wednesday).";
			case PeriodCodes.BiWeekly:
				return "The user will select this option for payment processing plans based on every other week. The day of the week that the payment will occurs is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on a Wednesday, then the bi-weekly payment will occur every other Wednesday).";
			case PeriodCodes.Monthly:
				return "The user will select this option for the monthly payments. The payment date for this	option is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on the 12th, then the monthly payment will occur on the 12th of every month).";
			case PeriodCodes.Quarterly:
				return "The user will select this option for quarterly processing of a payment. The payment date for this option is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on the 12th, then the quarterly payment will occur on the 12th day of the first month of a quarter). ";
			default:
				return "";
		}
	}
	CalculateForFreezeDate(model) {
		const { WeeklyNumber, BiWeeklyNumber, MonthlyNumber, QuarterlyNumber } = model;

		const StartDate = model.FreezeStartDate.toDate();
		const EndDate = model.FreezeEndDate.toDate();

		// if (name == "StartDate" || name == "EndDate" || name == "PeriodId" || name == "WeeklyId" ||
		// 	name == "BiWeeklyId" || name == "MonthlyId" || name == "QuarterlyId")

		if (typeof StartDate == "object" && typeof EndDate == "object" && model.PeriodId) {

			var count;
			var dayDif = (d1, d) => d1 > d ? d - d1 + 7 : d - d1;
			var addDays = (date, days) => { var nd = new Date(date); nd.setDate(date.getDate() + days); return nd; };
			var monthDif = (d1, d2) => Math.round(moment.duration(new Date(d2.getFullYear(), d2.getMonth() + 1, 1) - new Date(d1.getFullYear(), d1.getMonth(), 1)).asMonths());
			var lastDayOfMonth = d => new Date(d.getFullYear(), d.getMonth() + 1, 0);

			switch (model.PeriodCode) {
				case PeriodCodes.Weekly:
					if (WeeklyNumber != null && WeeklyNumber != "") {
						var firstWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), WeeklyNumber));
						if (EndDate - firstWeekPayDate < 0) { count = 0; break; }
						count = Math.floor(moment.duration(EndDate - firstWeekPayDate).asDays() / 7 + 1);
					}
					break;
				case PeriodCodes.BiWeekly:
					if (BiWeeklyNumber != null && BiWeeklyNumber != "") {
						var firstBiWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), BiWeeklyNumber));
						if (EndDate - firstBiWeekPayDate < 0) break;
						count = Math.floor(moment.duration(EndDate - firstBiWeekPayDate).asDays() / 14 + 1);
					}
					break;
				case PeriodCodes.SemiMonthly:
					var sMonthDiff = monthDif(StartDate, EndDate);
					if (sMonthDiff < 1) break;
					if (sMonthDiff == 1) {
						if (StartDate.getDate() > 16) break;
						if (StartDate.getDate() > 1 && EndDate.getDate() < 16) break;
						if (StartDate.getDate() > 1) { count = 1; break; }
						if (StartDate.getDate() == 1 && EndDate.getDate() < 16) { count = 1; break; }
						if (StartDate.getDate() == 1) { count = 2; break; }
					}
					if (sMonthDiff > 1) {
						var smStartDateSkip = (StartDate.getDate() == 1) ? 0 : (StartDate.getDate() <= 16) ? 1 : 2;
						var smEndDateSkip = EndDate.getDate() >= 16 ? 0 : 1;
						count = (2 * sMonthDiff) - smStartDateSkip - smEndDateSkip;
					}
					break;
				case PeriodCodes.Monthly:
					if (MonthlyNumber != null && MonthlyNumber != "") {
						var mMonthDiff = monthDif(StartDate, EndDate);
						if (mMonthDiff < 1) break;
						if (mMonthDiff == 1) {
							if (StartDate.getDate() > MonthlyNumber || EndDate.getDate() < MonthlyNumber) break;
							if (StartDate.getDate() <= MonthlyNumber && EndDate.getDate() >= MonthlyNumber) { count = 1; break; }
						}
						if (mMonthDiff > 1) {
							var mStartDateSkip = StartDate.getDate() > MonthlyNumber ? 1 : 0;
							var mEndDateSkip = EndDate.getDate() < MonthlyNumber ? 1 : 0;
							count = mMonthDiff - mStartDateSkip - mEndDateSkip;
						}
					}
					break;
				case PeriodCodes.MonthlyMonthEnd:
					var meMonthDiff = monthDif(StartDate, EndDate);
					if (meMonthDiff < 1) break;
					if (meMonthDiff == 1) {
						var meLastMonthDay = lastDayOfMonth(EndDate).getDate();
						if (StartDate.getDate() > meLastMonthDay || EndDate.getDate() < meLastMonthDay) break;
						if (StartDate.getDate() <= meLastMonthDay && EndDate.getDate() >= meLastMonthDay) { count = 1; break; }
					}
					if (meMonthDiff > 1) {
						var meStartDateSkip = StartDate.getDate() > lastDayOfMonth(StartDate).getDate() ? 1 : 0;
						var meEndDateSkip = EndDate.getDate() < lastDayOfMonth(EndDate).getDate() ? 1 : 0;
						count = meMonthDiff - meStartDateSkip - meEndDateSkip;
					}
					break;
				case PeriodCodes.Quarterly:
					if (QuarterlyNumber != null && QuarterlyNumber != "") {
						var qbStartDate = addDays(StartDate, -QuarterlyNumber);
						var qStartNewMonth = qbStartDate.getMonth() - (qbStartDate.getMonth() % 3) + 3;
						var qStartDate = new Date(qbStartDate.getFullYear(), qStartNewMonth, QuarterlyNumber);
						var qaEndDate = addDays(EndDate, -QuarterlyNumber + 1);
						var qEndNewMonth = qaEndDate.getMonth() - (qaEndDate.getMonth() % 3);
						var qEndDate = new Date(qaEndDate.getFullYear(), qEndNewMonth, QuarterlyNumber);
						var qDiff = monthDif(qStartDate, qEndDate);
						count = (qDiff + 2) / 3;
					}
					break;
				case PeriodCodes.QuarterlyMonthEnd:
					var qeGetStartMonth = n => n - (n % 3) + 3;
					var qeStartDate = new Date(StartDate.getFullYear(), qeGetStartMonth(StartDate.getMonth()) + 1, 0);
					var qeGetEndMonth = n => n - (n % 3);
					var qeaEndDate = addDays(EndDate, 1);
					var qeEndDate = new Date(qeaEndDate.getFullYear(), qeGetEndMonth(qeaEndDate.getMonth()) + 1, 0);
					var qeDiff = monthDif(qeStartDate, qeEndDate);
					count = (qeDiff + 2) / 3;
					break;
				default:
					break;
			}

			if (count == 0||!count) count = 0;

			if (count != 0 && model.PaymentCount != 0) {
				model.FPC = model.PaymentCount - count;
			}
		}
	}
	addCustomerDialogClose() {
		this.ValueChanged("IsCustomerPopupOpen", false);
	}


	CheckComponentDisable = () => {
		const { model, Disabled } = this.props;
		if (Disabled || model.PayIndex > 0) {
			return true;
		}
		return false;
	}


	render() {
		const { model, Disabled,vModel } = this.props;

		return (
			<div>
				<Card style={{ height: "90%", opacity: Disabled ? 0.4 : 1 }}>
					<GenericExpansionPanel Title="Recurring Transaction">
						<CardBody>

							<GridContainer >
								<GridItem xs={4}>
									<GenericTextInput
										LabelText="Payee Wallet Number"
										LabelMd={4}
										inputProps={{ maxLength: 30 }}
										Name="AccountNumber"
										Value={model.AccountNumber == null || undefined ? "" : model.AccountNumber}
										ValueChanged={this.ValueChanged}
										Disabled={this.CheckComponentDisable()}
										Required
										IsInvalid={vModel.AccountNumber}
									/>


									<GenericNumberInput
										LabelMd={4}
										Name="RecurringAmount"
										LabelText="Recurring Amount"
										Value={model.RecurringAmount}
										ValueChanged={this.ValueChanged}
										MaxLength={7}
										Disabled={this.CheckComponentDisable()}
										Prefix="$"
										Required
										IsInvalid={vModel.RecurringAmount}
									/>

									<GenericNumberInput
										Name="TotalAmount"
										LabelMd={4}
										LabelText="Total Amount"
										Value={model.TotalAmount}
										ValueChanged={this.ValueChanged}
										Disabled={true}
										Prefix="$"
									 />

									<GenericTextInput LabelMd={4} LabelText="Period/No. Of Payment" Value={model.PaymentCount} Disabled={true} />

								</GridItem>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericDateInput
												Utc
												LabelMd={4}
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate}
												ValueChanged={this.ValueChanged}
												Disabled={this.CheckComponentDisable()}
												MinDate={DateHelper.GetLocalDateAsUtc()}
												Required
												IsInvalid={vModel.StartDate}
											/>

											<GenericDateInput
												Utc
												LabelMd={4}
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate}
												ValueChanged={this.ValueChanged}
												Disabled={this.CheckComponentDisable()}
												MinDate={model.StartDate || DateHelper.GetLocalDateAsUtc()}
												Required
												IsInvalid={vModel.EndDate}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>

									<ParameterComponent Name="PeriodId" Value={model.PeriodId} LabelText="First Due Date Options" ParameterCode="BillRecurringPeriod" ValueChanged={this.ValueChanged} Disabled={this.CheckComponentDisable()} Sorted={{ Member: "ParameterDesc" }} Required
												IsInvalid={vModel.PeriodId} />

									<GridContainer>
										<GridItem xs={12}>
											{(model.PeriodId && [PeriodCodes.Weekly, PeriodCodes.BiWeekly, PeriodCodes.Monthly, PeriodCodes.Quarterly].some(x => x == model.PeriodCode)) ?
												<div>
													<br />

													<GenericLabel FontSize="12px" TextColor="black" Text="Period Definition" Bold={true} />

													{(model.PeriodCode == PeriodCodes.Weekly) ? <ParameterComponent Name="WeeklyId" Value={model.WeeklyId} LabelText="Weekly" ParameterCode="BillWeekDays" ValueChanged={this.ValueChanged} Disabled={this.CheckComponentDisable()} /> : null}
													{(model.PeriodCode == PeriodCodes.BiWeekly) ? <ParameterComponent Name="BiWeeklyId" Value={model.BiWeeklyId} LabelText="Bi-Weekly" ParameterCode="BillWeekDays" ValueChanged={this.ValueChanged} Disabled={this.CheckComponentDisable()} /> : null}
													{(model.PeriodCode == PeriodCodes.Monthly) ? <ParameterComponent Name="MonthlyId" Value={model.MonthlyId} LabelText="Monthly" ParameterCode="BillMonthDays" ValueChanged={this.ValueChanged} Disabled={this.CheckComponentDisable()} Sorted={{ Member: "ParameterValue2" }} /> : null}
													{(model.PeriodCode == PeriodCodes.Quarterly) ? <ParameterComponent Name="QuarterlyId" Value={model.QuarterlyId} LabelText="Quarterly" ParameterCode="BillMonthDays" ValueChanged={this.ValueChanged} Disabled={this.CheckComponentDisable()} Sorted={{ Member: "ParameterValue2" }} /> : null}

												</div> : null}
										</GridItem>
									</GridContainer>


									<div style={{ display: (model.Id != 0 && model.Id != null) ? "inherit" : "none" }} >

										<GenericDateInput
											Utc
											LabelMd={4}
											Name="FreezeStartDate"
											LabelText="Freeze Start Date"
											Value={model.FreezeStartDate}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											IsFuture={true} />

										<GenericDateInput
											Utc
											LabelMd={4}
											Name="FreezeEndDate"
											LabelText="Freeze End Date"
											Value={model.FreezeEndDate}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											MinDate={model.FreezeStartDate} />

									</div>
								</GridItem>
								<GridItem xs={8}>
									<GenericTextInput LabelMd={2} Name="BillDescription" LabelText="Bill Description" Value={model.BillDescription} ValueChanged={this.ValueChanged} Disabled={this.CheckComponentDisable()} inputProps={{ maxLength: 150 }} />
								</GridItem>
							</GridContainer>
						</CardBody >
					</GenericExpansionPanel>
				</Card >
			</div >
		);
	}
}

BillRecurring.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillRecurring;