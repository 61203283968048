import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
});

class BankActivity extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {};

		this.state = {
			list: [],
			model: this.initialModel,
			vModel: {},
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Payment Wallet Summary Report");
		this.props.setAppCenterTitle("REPORTS");
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: null });
	}

	HandleGetList = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Reporting/SearchBankActivity",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item !== null) {
					const { model } = this.state;
					model.BeginDate = responseData.Item.BeginDate;
					model.EndDate = responseData.Item.EndDate;
					this.setState({ model, list: responseData.Item.TransactionList });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	GetElementValue(obj, path) {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => {
			if (c) {
				if (n.toString().includes("Percent")) {
					return `${c[n].toFixed(1)}%`;
				} else if (n.toString().includes("Amount")) {
					return `$${c[n].toFixed(2)}`;
				}
				return c[n];
			}
		}
		);
	}

	Print = () => {
		const { activeIndex, model, list } = this.state;
		this.setState({ isLoading: true });
		var keyvalueList = [];
		var columns = this.GetColumns();

		if (list.length == 0)
			return;

		list.forEach(function (listElement) {
			var keyValueObject = {};
			columns.forEach(function (gridColumnElement) {
				var valueItem = this.GetElementValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Bank Activity Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "BankActivityReport.pdf";
				a.click();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	Validate = () => {
		const { model, activeIndex, vModel } = this.state;
		var messages = [];

		if (!model.Top) {
			vModel.Top = true;
			messages.push("Top Filter must be select");
		} else {
			vModel.Top = false;
		}

		if (!model.FromDate) {
			vModel.FromDate = true;
			messages.push("From Date must be select");
		} else {
			vModel.FromDate = false;
		}

		if (!model.ToDate) {
			vModel.ToDate = true;
			messages.push("To Date must be select");
		} else {
			vModel.ToDate = false;
		}

		if (activeIndex == 1 && !model.UniqueClientId) {
			vModel.UniqueClientId = true;
			messages.push("Client must be select");
		} else {
			vModel.UniqueClientId = false;
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetColumns = () => {
		const { list } = this.state;

		const columns = [
			{
				Header: "Program",
				accessor: "ProgramName",
				Footer: (
					<span>
						<strong>Total Transactions</strong>
					</span>
				)
			},
			{
				Header: "Debit",
				accessor: "DebitCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.DebitCount)))}</strong>
					</span>
				)
			},
			{
				Header: "Credit",
				accessor: "CreditCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.CreditCount)))}</strong>
					</span>
				)
			},
			{
				Header: "Total",
				accessor: "TotalAmount",
				Cell: row => (
					<div>{Formatter.FormatMoney(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.TotalAmount)))}</strong>
					</span>
				)
			}
		];
		return columns;
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, activeIndex, isLoading, model, vModel, list } = this.state;
		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Print", OnClick: this.Print, Disabled: Disabled || list.length == 0 },
					{ Code: "CreateReport", OnClick: this.HandleGetList, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={2}>
													<GenericSelectInput
														Name="Top"
														LabelMd={4}
														LabelText="Top"
														IsStatic={true}
														StaticData={[{ Value: 10, Text: "10" }, { Value: 20, Text: "20" }, { Value: 30, Text: "30" }, { Value: 40, Text: "40" }, { Value: 50, Text: "50" }, { Value: 100, Text: "100" }, { Value: 200, Text: "200" }]}
														KeyValueMember="Value"
														TextValueMember="Text"
														Value={model.Top}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.Top} />
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="ProgramId"
														LabelMd={5}
														LabelText="Program"
														Method="GET"
														Url="/bankapi/v1.0/Bank/ValidBankPrograms"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														Value={model.ProgramId}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelMd={4}
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
														Value={model.UniqueClientId || ""}
														ValueChanged={this.HandleChange}
														CanClear
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem xs={2}>
													<GenericDateInput
														Name="FromDate"
														LabelMd={4}
														LabelText="From"
														Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.FromDate}
														Utc
														MaxDate={DateHelper.GetLocalDateAsUtc()} />
												</GridItem>
												<GridItem xs={2}>
													<GenericDateInput
														Name="ToDate"
														LabelMd={4}
														LabelText="To"
														Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.ToDate}
														Utc
														MinDate={model.FromDate} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<ReactTable
									className={`${classes.table} ${"-striped -highlight"}`}
									data={list}
									filterable={false}
									columns={this.GetColumns()}
									showPaginationTop={false}
									showPaginationBottom={false}
									minRows={1} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

BankActivity.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(BankActivity);