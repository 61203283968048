import { DialogContent, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import { GridColumnType } from "views/Constants/Constant";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class SchedulerHistory extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Job History"
							Icon={DetailIcon}
							OnClick={() => this.JobHistoryDialogOpen(row.original.SchedulerInfoId)} />
					</div>
				),
				width: 50
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Full Class Name",
				accessor: "FullClassName"
			},
			{
				Header: "Manner of Work",
				accessor: "MannerOfWork"
			},
			{
				Header: "Last Run Date",
				accessor: "LastRunDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Last Run Status",
				accessor: "LastRunStatus"
			},
			{
				Header: "Send Email After Failed",
				accessor: "SendEmailAfterFailed",
				type: GridColumnType.YesNo
			}
		];

		this.columnsJobHistory = [
			{
				Header: "Is Success",
				accessor: "IsSuccess",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSuccess)}>{row.value != null ? Formatter.FormatYesNo(row.value) : ""}</div>)
			},
			{
				Header: "Queue Date Time",
				accessor: "QueueDateTime",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSuccess)}>{Formatter.FormatDateTimeUtc(row.value)}</div>)
			},
			{
				Header: "Run Status",
				accessor: "RunStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSuccess)}>{row.value}</div>)
			},
			{
				Header: "Result Message",
				accessor: "ResultMessage",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSuccess)}>{row.value}</div>)
			},
			{
				Header: "Summary",
				accessor: "Summary",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSuccess)}>{row.value}</div>)
			}
		];

		this.columnsRowStyleColor = {};

		this.state = {
			model: {},
			isJobHistoryDialogOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SYSTEM ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Scheduler History");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	JobHistoryDialogOpen = async (schedulerInfoId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Scheduler/QueueSearch", { SchedulerInfoId: schedulerInfoId });

		this.setState({ listJobHistory: result || [], isJobHistoryDialogOpen: true });
	}

	JobHistoryDialogClose = () => {
		this.setState({ isJobHistoryDialogOpen: false });
	}

	GetColumnsRowStyleColor(isSuccess) {
		this.columnsRowStyleColor = {
			color: isSuccess == true
				? "#3CB371"
				: isSuccess == false
					? "#FB3005"
					: ""
		};

		return this.columnsRowStyleColor;
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Scheduler/Search", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], listJobHistory: [] });
	}

	render() {
		const { model, list, listJobHistory, isJobHistoryDialogOpen } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isJobHistoryDialogOpen} maxWidth="lg" fullWidth onBackdropClick={this.JobHistoryDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GenericGrid
							Data={listJobHistory}
							Columns={this.columnsJobHistory}
							IsSorted={false} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.JobHistoryDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Scheduler History">
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Name="Description"
												LabelText="Description"
												Value={model.Description}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
										</GridItem>
										<GridItem xs={4}>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

SchedulerHistory.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default SchedulerHistory;