import React from 'react';
import Button from "components/CustomButtons/Button.jsx";


class RButton extends React.Component
{
    constructor(props)
    {
        super(props);
    }
    render(){
        return(<Button {...this.props} disabled={this.props.loading == "true"}>
            {this.props.loading == "true" ? 'Loading...' :  this.props.children }
        </Button>);
    }
}
export default RButton;