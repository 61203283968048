import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericTitle } from "views/Components/Generic";
import { ClientType, ProgramCodes } from "views/Constants/Constant";

class MyProfileAddresses extends React.Component {
	render() {
		var { addressList, clientType } = this.props;
		return (
			<Card className="no-radius">
				<CardHeader>
					<GenericTitle text={"Addresses"} />
				</CardHeader>
				<CardBody>
					<GenericGrid
						Data={addressList}
						PageSize={5}
						HideButton={true}
						Columns={[
							{
								Header: "Address Type",
								accessor: "AddressType.ParameterDesc"
							},
							{
								Header: "Location",
								accessor: "Location"
							},
							{
								Header: "Store Name",
								accessor: "Store.StoreName",
								show: clientType == ClientType.Corporate || clientType == ClientType.SalesAgentCorporate
							},
							{
								Header: "Store Code",
								accessor: "Store.StoreCode",
								show: clientType == ClientType.Corporate || clientType == ClientType.SalesAgentCorporate
							},
							{
								Header: "Address Line 1",
								accessor: "Address1"
							},
							{
								Header: "Address Line 2",
								accessor: "Address2"
							},
							{
								Header: "Country",
								accessor: "Country.Name"
							},
							{
								Header: "Province",
								accessor: "Province.Name"
							},
							{
								Header: "City",
								accessor: "City.Name"
							},
							{
								Header: "Postal Code",
								accessor: "ZipCode"
							}
						]}
						ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
				</CardBody>
			</Card>
		);
	}
}

MyProfileAddresses.propTypes = {
	addressList: PropTypes.array,
	clientType: PropTypes.string
};

export default MyProfileAddresses;