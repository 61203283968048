import { DialogContent, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName } from "views/Constants/Constant.Sorted";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class KycReviewNumber extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Number of Review",
				accessor: "ReviewNumber"
			}
		];
		this.columnsDetail = [
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Number of Review",
				accessor: "ReviewNumber"
			}
		];

		this.state = {
			model: {
				UniqueClientId: -1,
				Date: DateHelper.GetDate().subtract(1, "days")
			},
			isDetailDialogOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("REPORTS");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("KYC Review Count");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "MailAddress") {
				model["MailAddressIsValid"] = data.IsValid;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { model, list } = this.state;
		var newModel = list[index];
		newModel.Date = model.Date;

		this.setState({ model: newModel, selected: index, isDetailDialogOpen: true });
	}

	DetailDialogClose = () => {
		this.setState({ isDetailDialogOpen: false });
	}

	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = { ...model };
		request.UniqueClientId = model.UniqueClientId != -1
			? model.UniqueClientId
			: null;

		var result = await ExecuteApiPost("/amlkycapi/v1.0/Kyc/SearchReviewNumberOp", request);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		var { model } = this.state;

		model.UniqueClientId = -1;
		model.Date = DateHelper.GetDate().subtract(1, "days");

		this.setState({ model, list: [] });
	}

	render() {
		const { model, list, selected, isDetailDialogOpen } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isDetailDialogOpen} fullScreen onBackdropClick={this.DetailDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GenericGrid
							Data={model.Detail}
							Columns={this.columnsDetail}
							IsSorted={false} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="KYC Review Number">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												All
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemClient}
												Sorted={SortedName}
												CanClear={false}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="Date"
												LabelText="Date"
												IncludeTime={false}
												MaxDate={DateHelper.GetDate().subtract(1, "days")}
												Value={model.Date}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

KycReviewNumber.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default KycReviewNumber;