import style from "assets/jss/material-dashboard-pro-react/views/createWireTransferStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class BeneficiaryDetail extends Component {

	constructor(props) {
		super(props);

		this.MaxLen = { maxLength: 30 };
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.EmptyObj = {};
		this.CountryObject = { ISO2: "CA" };
		this.SortedCountry = { Member: "OrderIndex" };
		this.SortedCityProvince = { Member: "ParameterDesc" };
		this.AsianCountries = [18,25,32,36,44,101,102,103,109,112,119,132,133,146,150,153,166,173,196,206,214,217,238];
	}

	RenderCountry(d) {
		return d.ISO2 + " - " + d.Name;
	}

	render() {
		const { Disabled,
			CompanyName,
			Name,
			MiddleName,
			LastName,
			AddressLine1,
			AddressLine2,
			ZipCode,
			CityId,
			ProvinceId,
			CountryId,
			IsDomestic,
			BankCountryId,
			BankFinInsId,
			BankName,
			BankBranchId,
			BankTransitNumber,
			AccountNumber,
			BankSwiftCode,
			BankAddressLine1,
			BankAddressLine2,
			BankZipCode,
			BankCityId,
			BankProvinceId,
			BankInstitution,
			CurrencyId,
			IsCurrencyVisible,
			Alias,
			ValueChanged,
			MenuCode,
			IsAsianCountry,
			PhoneNumber
		} = this.props;
		return (
			<GridContainer>
				<GridItem xs={6}>
					<GridContainer style={{ borderRadius: 5 }}>
						<GridItem xs={12}>
							<h4 style={{ color: "black" }}>
								<b>Beneficiary Information</b>
							</h4>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="CompanyName"
								LabelText="Beneficiary Company Name"
								Value={CompanyName}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="Name"
								LabelText="Name"
								Value={Name}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="MiddleName"
								LabelText="Middle Name"
								Value={MiddleName}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="LastName"
								LabelText="Last Name"
								Value={LastName}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="AddressLine1"
								LabelText="Address Line 1"
								Id="beneficiary-street-address1"
								Value={AddressLine1}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="AddressLine2"
								LabelText="Address Line 2"
								Id="beneficiary-street-address2"
								Value={AddressLine2}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ZipCode"
								LabelText="Postal Code"
								Id="beneficiary-postcode"
								Value={ZipCode}
								ValueChanged={ValueChanged}
								Disabled={Disabled} />
						</GridItem>
						<GridItem xs={12}>
							{!MenuCode ?

								<GenericSelectInput
									Name="CountryId"
									LabelText="Country"
									Method="POST"
									Url="/bankapi/v1.0/WireCountry/Search"
									Parameter={this.EmptyObj}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Code"
									Value={CountryId}
									ValueChanged={ValueChanged}
									Sorted={this.SortedCountry}
									RenderItem={this.RenderCountry}
									Disabled={Disabled} />

								:
								<GenericSelectInput
									Name="CountryId"
									LabelText="Country"
									Method="POST"
									Url="/bankapi/v1.0/WireCountry/Search"
									Parameter={this.CountryObject}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Code"
									Value={CountryId}
									ValueChanged={ValueChanged}
									Sorted={this.SortedCountry}
									RenderItem={this.RenderCountry}
									Disabled={Disabled} />

							}
						</GridItem>
						<GridItem xs={12}>
							{MenuCode ?
								<GenericSelectInput
									key={CountryId + "province"}
									Name="ProvinceId"
									LabelText="Province"
									Method="POST"
									Url="/bankapi/v1.0/WireProvince/Search"
									Parameter={{ CountryId: CountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={ProvinceId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
								:
								<GenericSelectInput
									key={CountryId + "province"}
									Name="ProvinceId"
									LabelText="Province"
									Method="POST"
									Url="/bankapi/v1.0/WireProvince/Search"
									Parameter={{ CountryId: CountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={ProvinceId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
							}
						</GridItem>
						<GridItem xs={12}>
							{MenuCode ?
								<GenericSelectInput
									VirtualizedList
									key={"city" + ProvinceId + "" + CountryId}
									Name="CityId"
									LabelText="City"
									Method="POST"
									Url="/bankapi/v1.0/WireCity/Search"
									Parameter={{ ProvinceId: ProvinceId, CountryId: CountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={CityId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
								:
								<GenericSelectInput
									VirtualizedList
									key={"city" + ProvinceId + "" + CountryId}
									Name="CityId"
									LabelText="City"
									Method="POST"
									Url="/bankapi/v1.0/WireCity/Search"
									Parameter={{ ProvinceId: ProvinceId, CountryId: CountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={CityId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
							}
						</GridItem>

						{IsAsianCountry || PhoneNumber != null || this.AsianCountries.includes(CountryId) ?
							<GridItem xs={12}>
								<GenericTextInput
									Name="PhoneNumber"
									LabelText="Phone Number"
									Value={PhoneNumber}
									ValueChanged={ValueChanged}
									Disabled={Disabled}
									inputProps={this.MaxLen} />
							</GridItem> : ""}
					</GridContainer>
				</GridItem>
				<GridItem xs={6}>
					<GridContainer style={{ borderRadius: 5 }}>
						<GridItem xs={12}>
							<h4 style={{ color: "black" }}>
								<b>Beneficiary Bank Information</b>
							</h4>
						</GridItem>

						<GridItem xs={12}>
							<GenericTextInput
								Name="AccountNumber"
								LabelText="Wallet Number"
								Value={AccountNumber == null || undefined ? "" : AccountNumber}
								Format="@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								DisableStateAdornment={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="BankInstitution"
								LabelText="IBAN"
								Format="@@@@ @@@@ @@@@ @@@@ @@@@ @@@@ @@@@ @@@@"
								Value={BankInstitution}
								ValueChanged={ValueChanged}
								Disabled={Disabled} />
						</GridItem>
						<GridItem xs={12}>
							{IsDomestic ?
								<GenericSelectInput
									Name="BankFinInsId"
									LabelText="FIID"
									Method="GET"
									Url="/bankapi/v1.0/FinancialInstitution/GetAll"
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="FinIns"
									Value={IsDomestic ? BankFinInsId : ""}
									ValueChanged={ValueChanged}
									Disabled={Disabled} /> :
								<GenericTextInput
									Name="BankName"
									LabelText="Beneficiary Bank"
									Value={!IsDomestic ? BankName : ""}
									ValueChanged={ValueChanged}
									Disabled={Disabled}
									inputProps={this.MaxLen} />
							}
						</GridItem>
						<GridItem xs={12}>
							{IsDomestic ?
								<GenericSelectInput
									key={BankFinInsId}
									LabelText="Transit"
									Name="BankBranchId"
									Method="POST"
									Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
									Parameter={{ FinancialInstitutionId: BankFinInsId == null || undefined ? 0 : BankFinInsId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="TransNo"
									Value={IsDomestic ? BankBranchId == null || undefined ? "" : BankBranchId : ""}
									ValueChanged={ValueChanged}
									Disabled={Disabled} /> :
								<GenericNumberInput
									NoFormatting={true}
									Name="BankTransitNumber"
									LabelText="Transit"
									Value={!IsDomestic ? BankTransitNumber : ""}
									MaxLength={9}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
							}
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="BankSwiftCode"
								LabelText="Swift Code"
								// Format="@@@@@@@@@@@"
								Value={BankSwiftCode}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="BankAddressLine1"
								LabelText="Address Line 1"
								Id="bank-street-address1"
								Value={BankAddressLine1}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="BankAddressLine2"
								LabelText="Address Line 2"
								Id="bank-street-address2"
								Value={BankAddressLine2}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="BankZipCode"
								Id="bank-postcode"
								LabelText="Postal Code"
								Value={BankZipCode}
								ValueChanged={ValueChanged}
								Disabled={Disabled} />
						</GridItem>
						<GridItem xs={12}>
							{MenuCode ?
								<GenericSelectInput
									Name="BankCountryId"
									LabelText="Country"
									Method="POST"
									Url="/bankapi/v1.0/WireCountry/Search"
									Parameter={this.EmptyObj}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Code"
									Value={BankCountryId}
									ValueChanged={ValueChanged}
									Sorted={this.SortedCountry}
									RenderItem={this.RenderCountry}
									Disabled={Disabled} />
								:
								<GenericSelectInput
									Name="BankCountryId"
									LabelText="Country"
									Method="POST"
									Url="/bankapi/v1.0/WireCountry/Search"
									Parameter={this.EmptyObj}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Code"
									Value={BankCountryId}
									ValueChanged={ValueChanged}
									Sorted={this.SortedCountry}
									RenderItem={this.RenderCountry}
									Disabled={Disabled} />
							}
						</GridItem>
						<GridItem xs={12}>
							{MenuCode ?
								<GenericSelectInput
									key={BankCountryId + "province"}
									Name="BankProvinceId"
									LabelText="Province"
									Method="POST"
									Url="/bankapi/v1.0/WireProvince/Search"
									Parameter={{ CountryId: BankCountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={BankProvinceId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
								:
								<GenericSelectInput
									key={BankCountryId + "province"}
									Name="BankProvinceId"
									LabelText="Province"
									Method="POST"
									Url="/bankapi/v1.0/WireProvince/Search"
									Parameter={{ CountryId: BankCountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={BankProvinceId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
							}
						</GridItem>
						<GridItem xs={12}>
							{MenuCode ?
								<GenericSelectInput
									key={"city" + BankProvinceId + "" + BankCountryId}
									Name="BankCityId"
									LabelText="City"
									Method="POST"
									Url="/bankapi/v1.0/WireCity/Search"
									Parameter={{ ProvinceId: BankProvinceId, CountryId: BankCountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={BankCityId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
								:
								<GenericSelectInput
									key={"city" + BankProvinceId + "" + BankCountryId}
									Name="BankCityId"
									LabelText="City"
									Method="POST"
									Url="/bankapi/v1.0/WireCity/Search"
									Parameter={{ ProvinceId: BankProvinceId, CountryId: BankCountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={BankCityId}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
							}
						</GridItem>
						{!MenuCode ?
							<GridItem xs={12}>
								<GenericTextInput
									Name="Alias"
									LabelText="Remittance Information"
									Value={Alias}
									ValueChanged={ValueChanged}
									Disabled={Disabled}
									inputProps={this.MaxLen} />
							</GridItem> : ""}
						{IsCurrencyVisible &&
							<GridItem xs={12}>
								<GenericSelectInput
									Disabled={true}
									Name="CurrencyId"
									LabelText="Currency"
									Value={CurrencyId || ""}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Currency/ValidCurrencies"
									DefaultIndex={0}
									RenderItem={d => d.Code + " - " + d.Description}
									ValueChanged={ValueChanged}
									DataRoot="Item" />
							</GridItem>}
					</GridContainer>
				</GridItem>
			</GridContainer>
		);
	}
}

BeneficiaryDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	CompanyName: PropTypes.string,
	Name: PropTypes.string,
	MiddleName: PropTypes.string,
	LastName: PropTypes.string,
	AddressLine1: PropTypes.string,
	AddressLine2: PropTypes.string,
	ZipCode: PropTypes.string,
	CityId: PropTypes.number,
	ProvinceId: PropTypes.number,
	CountryId: PropTypes.number,
	IsDomestic: PropTypes.bool,
	BankCountryId: PropTypes.number,
	BankFinInsId: PropTypes.number,
	BankName: PropTypes.string,
	BankBranchId: PropTypes.number,
	BankTransitNumber: PropTypes.string,
	AccountNumber: PropTypes.string,
	BankSwiftCode: PropTypes.string,
	BankAddressLine1: PropTypes.string,
	BankAddressLine2: PropTypes.string,
	BankZipCode: PropTypes.string,
	BankCityId: PropTypes.number,
	BankInstitution: PropTypes.string,
	BankProvinceId: PropTypes.number,
	CurrencyId: PropTypes.number,
	IsCurrencyVisible: PropTypes.bool,
	Alias: PropTypes.string,
};

export default withArtifex(BeneficiaryDetail, style);