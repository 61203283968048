import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  GenericAlert,
  GenericCheckInput,
  GenericNumberInput,
  GenericSelectInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import Button from "components/CustomButtons/Button";
import ClientHelper from "core/ClientHelper";

class SecureCardDetails extends Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined
    };

    this.state = {
		Id: 0,
      model: { ...this.initialModel },
		vModel: {},
      isLoading: false,
    };

    this.parameterProduct = {};

    this.parameterYesNo = {
      ParameterCode: "LOSYesNo",
    };

    this.parameterLOSPaymentFrequency = {
      ParameterCode: "LOSPaymentSchedule",
    };

    this.parameterLOSPaymentMethod = {
      ParameterCode: "LOSPaymentMethod",
    };
    this.SendAgreement = this.SendAgreement.bind(this);
  }

  componentDidMount() {}

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };
  ShowMessage = (type, title, message) => {
    // type:-success->warning->info->error
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };

  SendAgreementDocuSign = () => {
    const applicationModel = this.props.model;

    this.props.TaskPoolFunc.AppendTask(
      Proxy.POST(
        "/losapi/v1.0/EmbeddedSigning/SignNowEmbeddedSinging",
        {
          ApplicationId: applicationModel.Id,
          UniqueClientId: applicationModel.UniqueClientId,
          ApplicantId: applicationModel.ApplicantId,
        },
        (responseData) => {
          if (!responseData.IsSucceeded) {
            // this.DocuSingAccesToken();
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage(
            "success",
            "Success",
            "Agreement Template Has Been Successfully Sent"
          );
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  };

  SendAgreement() {
    const applicationModel = this.props.model;
    this.props.TaskPoolFunc.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/SendAgreement",
        {
          Id: applicationModel.Id,
        },
        (responseData) => {
          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Agreement Template Sent");
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  }

  GetProductParameter = () => {
    this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
    this.parameterProduct.IsNoNameProduct = false;
    return this.parameterProduct;
  };

  RenderItemProduct = (d) => {
    this.renderItemProduct = d.Name;
    if (d.UniqueClient)
      this.renderItemProduct += ` - (${d.UniqueClient.UniqueClientId} - ${d.UniqueClient.ClientName})`;
    return this.renderItemProduct;
  };

  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    if (name === "Customer") {
      if (newValue) {
        model.ClientCustomerId = newValue.Id;
        model.CustomerNumber = newValue.CustomerNumber;
      } else {
        delete model.ClientCustomerId;
        delete model.CustomerNumber;
      }
    }
    if (name === "UniqueClientId") {
      delete model.ClientCustomerId;
      delete model.CustomerNumber;
    }
    if (name === "HasCardUsageLimit" && !newValue) {
      delete model.CardUsagePeriodId;
      delete model.CardUsageLimit;
    }
    model[name] = newValue;
    this.setState({ model });
  };

  render() {
    const { model, Disabled, isApprovedPeriodDisabled, vModel } = this.props;
    return (
      <div>
        <GridContainer>
          {this.state.alert}

          <LoadingComponent Show={this.state.isLoading} />
          <GridContainer>
            <GridItem xs={4}>
              <GenericTitle text="Requested" />
            </GridItem>
            <GridItem xs={4}>
              <GenericTitle text="Approved" />
            </GridItem>
            <GridItem xs={4}>
              <GenericTitle text="Agreement" />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}>
              <GenericNumberInput
                key="RequestedAmount"
                Name="RequestedAmount"
                LabelText="Requested Amount"
                LabelMd={6}
                Prefix="$"
                Value={
                  model.RequestedAmount == null || undefined
                    ? ""
                    : model.RequestedAmount
                }
                ValueChanged={this.props.HandleChange}
                Disabled={true}
              />
            </GridItem>
            <GridItem xs={4}>
              <GenericSelectInput
                Name="LoanFeeDefinitionId"
                LabelText="Fee Profile"
                LabelMd={5}
                IsStatic={true}
                StaticData={this.props.FeeProfileList}
                DataRoot="Item"
                KeyValueMember="Id"
                TextValueMember="Name"
                Value={model.LoanFeeDefinitionId}
                ValueChanged={this.props.HandleChange}
                Required
                IsInvalid={vModel.LoanFeeDefinitionId}
              />
				  </GridItem>
				  

            <GridItem xs={4}>
              <GenericCheckInput
                Name="IsAgreementSigned"
                LabelText="Agreement Signed"
                Value={model.IsAgreementSigned || false}
                ValueChanged={this.props.HandleChange}
                Disabled={this.props.isUserAdmin}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}>
              <GenericSelectInput
                Name="RequestedPaymentFrequencyId"
                LabelText="Requested Payment Frequency"
                LabelMd={6}
                Method="POST"
                IsStatic={true}
                StaticData={this.props.ParameterList.filter(
                  (prm) =>
                    prm.ParameterCode ==
                    this.parameterLOSPaymentFrequency.ParameterCode
                )}
                // Url="/coreapi/v1.0/Parameter/Search"
                // Parameter={this.parameterLOSPaymentFrequency}
                DataRoot="Item"
                KeyValueMember="Id"
                TextValueMember="ParameterDesc"
                Value={model.RequestedPaymentFrequencyId}
                ValueChanged={this.props.HandleChange}
                Disabled={true}
              />
            </GridItem>
				<GridItem xs={4}>
				  <GenericSelectInput
                key={model.UniqueClientId}
                Name="ProductId"
                LabelText="Product"
                Method="POST"
                Url="/prepaidapi/v1.0/Product/SearchNonCreditLoanApproval"
                Parameter={this.GetProductParameter()}
                DataRoot="Item"
                KeyValueMember="Id"
                RenderItem={this.RenderItemProduct}
                Value={model.ProductId}
                ValueChanged={this.props.HandleChange}
                Required
                IsInvalid={vModel.ProductId}
              />
            </GridItem>
            
				
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}>
              <GenericNumberInput
                LabelMd={6}
                Name="SecurityAmount"
                LabelText="Security Amount"
                Prefix="$"
                Value={model.SecurityAmount}
                ValueChanged={this.props.HandleChange}
                Disabled={true}
                Required
                IsInvalid={vModel.SecurityAmount}
              />
            </GridItem>
				<GridItem xs={4}>
              <GenericNumberInput
                Name="ApprovedAmount"
                Prefix="$"
                LabelMd={5}
                LabelText="Approved Amount"
                Value={model.ApprovedAmount}
                ValueChanged={this.props.HandleChange}
                Disabled={Disabled}
                Required
                IsInvalid={vModel.ApprovedAmount}
              />
            </GridItem>
            
            <GridItem xs={4}></GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}>
              {!isApprovedPeriodDisabled && (
                <GenericNumberInput
                  NoFormatting
                  Name="RequestedPaymentPeriod"
                  LabelText="Requested Payment Period"
                  LabelMd={6}
                  Value={model.RequestedPaymentPeriod}
                  ValueChanged={this.props.HandleChange}
                  Disabled={true}
                />
              )}
            </GridItem>
				<GridItem xs={4}>
              <GenericSelectInput
                Name="ApprovedPaymentFrequencyId"
                LabelText="Approved Payment Frequency"
                LabelMd={5}
                Method="POST"
                IsStatic={true}
                StaticData={this.props.ParameterList.filter(
                  (prm) =>
                    prm.ParameterCode ==
                    this.parameterLOSPaymentFrequency.ParameterCode
                )}
                // Url="/coreapi/v1.0/Parameter/Search"
                // Parameter={this.parameterLOSPaymentFrequency}
                DataRoot="Item"
                KeyValueMember="Id"
                TextValueMember="ParameterDesc"
                Value={model.ApprovedPaymentFrequencyId}
                ValueChanged={this.props.HandleChange}
                Disabled={Disabled}
                Required
                IsInvalid={vModel.ApprovedPaymentFrequencyId}
              />
            </GridItem>
            <GridItem xs={4}></GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}></GridItem>
				<GridItem xs={4}>
              <GenericSelectInput
                Name="ApprovedPaymentMethodId"
                LabelText="Interest Calculation Method"
                LabelMd={5}
                Method="POST"
                IsStatic={true}
                StaticData={this.props.ParameterList.filter(
                  (prm) =>
                    prm.ParameterCode ==
                    this.parameterLOSPaymentMethod.ParameterCode
                )}
                // Url="/coreapi/v1.0/Parameter/Search"
                // Parameter={this.parameterLOSPaymentMethod}
                DataRoot="Item"
                KeyValueMember="Id"
                TextValueMember="ParameterDesc"
                Value={model.ApprovedPaymentMethodId}
                ValueChanged={this.props.HandleChange}
                Disabled={Disabled}
                Required
                IsInvalid={vModel.ApprovedPaymentMethodId}
              />
            </GridItem>
            <GridItem xs={4}>
              {model.Applicant.IsCardActive && isApprovedPeriodDisabled && (
                <GridContainer>
                  {this.props.isUserAdmin && (
                    <GridItem xs={2}>
                      <Button
                        disabled={!model.Id}
                        size="sm"
                        color="black"
                        onClick={this.props.UpdateSecureCardDetails}
                      >
                        Update
                      </Button>
                    </GridItem>
                  )}
                  <GridItem xs={4}>
                    <Button
                      disabled={!model.Id}
                      size="sm"
                      color="black"
                      onClick={this.props.AdjustLimit}
                    >
                      Adjust Limit
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}></GridItem>
				<GridItem xs={4}>
              <GenericNumberInput
                Name="ApprovedSecurityAmount"
                Prefix="$"
                LabelMd={5}
                LabelText="Approved Security Amount"
                Value={model.ApprovedSecurityAmount || 0}
                ValueChanged={this.props.HandleChange}
                Disabled={Disabled}
                Required
                IsInvalid={vModel.ApprovedSecurityAmount}
              />
            </GridItem>
            <GridItem xs={4}>
              {model.Applicant.IsCardActive && !isApprovedPeriodDisabled && (
                <GridContainer>
                  {this.props.isUserAdmin && (
                    <GridItem xs={2}>
                      <Button
                        disabled={!model.Id}
                        size="sm"
                        color="black"
                        onClick={this.props.UpdateSecureCardDetails}
                      >
                        Update
                      </Button>
                    </GridItem>
                  )}
                  <GridItem xs={4}>
                    <Button
                      disabled={!model.Id}
                      size="sm"
                      color="black"
                      onClick={this.props.AdjustLimit}
                    >
                      Adjust Limit
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </GridContainer>
			 <GridContainer>
			 <GridItem xs={4}></GridItem>
			 <GridItem xs={4}>
              {!isApprovedPeriodDisabled && (
                <GenericNumberInput
                  NoFormatting
                  Name="ApprovedPaymentPeriod"
                  LabelMd={5}
                  LabelText="Approved Payment Period"
                  Value={model.ApprovedPaymentPeriod}
                  ValueChanged={this.props.HandleChange}
                  Disabled={Disabled || isApprovedPeriodDisabled}
                  Required
                  IsInvalid={vModel.ApprovedPaymentPeriod}
                />
              )}
            </GridItem>
				<GridItem xs={4}></GridItem>
			 </GridContainer>
        </GridContainer>
      </div>
    );
  }
}

SecureCardDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object,
  tempModel: PropTypes.object,
  addressModel: PropTypes.object,
  HandleChange: PropTypes.func,
  LoanApprovalFeeList: PropTypes.array,
  Disabled: PropTypes.bool,
  isApproved: PropTypes.bool,
  isApprovedPeriodDisabled: PropTypes.bool,
  ParameterList: PropTypes.array,
  FeeProfileList: PropTypes.array,
  isUserAdmin: PropTypes.bool,
};

export default withArtifex(SecureCardDetails, {});
