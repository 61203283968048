
import React, { PureComponent } from "react";
import { List } from "react-virtualized";
const NoOptionsMessage = () => {
	return (
		<div style={{ textAlign: "center", font: "inherit", margin: " 12px", color: "#999999" }}>No Options</div>
	);
}
const MenuListStyle = { width: "100%" };



class GenericVirtualizedMenuList extends PureComponent {
	render() {
		const props = this.props;
		const listCompProps = props.children[0]?.props;
		const rows = [...(listCompProps?.children || [])];
		var selectOneLabelComp = listCompProps?.label;
		const rowHeight = 30;

		const groupStyle = listCompProps ? listCompProps.getStyles("group", props) : null;
		const groupHeadingStyle = listCompProps ? listCompProps.getStyles("groupHeading", props) : null;

		if (rows?.length === 0) {
			rows.push(NoOptionsMessage());
		} else {
			rows.unshift(
				<div style={groupHeadingStyle}>
					<div style={groupStyle}>
						{selectOneLabelComp}
					</div>
				</div>
			);
		}
		const rowRenderer = ({ key, parent, index, isScrolling, isVisible, style }) => {
			var content = rows[index];

			return (<div key={key} style={style}>{content}</div>);
		};
		const getHeight = () => rows?.length === 1 ? (rowHeight + 12) : rows?.length < 10 && rows?.length > 0 ? rows.length * rowHeight : 300;

		return (
			<List
				style={MenuListStyle}
				width={900}
				height={getHeight()}
				rowHeight={rowHeight}
				rowCount={rows.length}
				rowRenderer={rowRenderer}
			/>
		);
	}
}
export default GenericVirtualizedMenuList;
