import { DialogContent, DialogTitle } from "@material-ui/core";
import ChequeManagementStyle from "assets/jss/material-dashboard-pro-react/views/ChequeManagementStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import TabsComponent from "views/Components/TabsComponentPagination";
import { Direction, GridColumnType } from "views/Constants/Constant.js";
import ChequeImageDetail from "./ChequeImageDetail";

const styles = ({
	...sweetAlertStyle
});
class ChequeOutgoingFile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				DirectionParameterValue: Direction.Outgoing,
			},
			list: [],
			dailyFileList: [],
			FileId: 0,
			dailyFileDetailList: [],
			selected: null,
			isBackOffice: false,
			openDialog: false,
			totalchqinfomodel: {},
			totalchqdetailinfomodel: {},
			ParentTabIndex: 0,
			CheckNewClient: false,
			alert: "",
			chqinfomodel: {},
			totalvisible: false,
			Titlerecord: ""
		};
	}
	componentDidMount() {
		// var tempuniq = "1" + Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Cheque Outgoing File");
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("CHEQUE MANAGEMENT");
		}
		this.setState({ openDialog: false });
		var { isBackOffice } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		this.setState({ isBackOffice, model });
	}
	handleChangeParentTab = (event, active, tab) => {
		var { FileId, totalvisible } = { ...this.state };
		if (active == 0) {
			FileId = 0;
			this.setState({ FileId, totalvisible: false });
			if (!tab || !this.state.CheckNewClient) {
				this.setState({ ParentTabIndex: active, openDialog: false });
			}
		}
		if (FileId != 0) {
			this.setState({ FileId, totalvisible: true });
			if (!tab || !this.state.CheckNewClient) {
				this.setState({ ParentTabIndex: active });
				Proxy.POST(
					"/chequeapi/v1.0/Cheque/GetCHQOutgoingDailyDetailFileList",
					{
						ClientId: this.state.model.UniqueClientId,
						FileId: this.state.FileId
					},
					this.successDailyDetailFileList,
					this.errorCallback
				);
			}
		}
		else {
			if (FileId == 0 && active == 0) {
			}
			else {
				// this.showErrorMessage("Please File Select!");
				this.ShowMessage("warning", "Please Select File!", "Select a file to continue.");
			}
		}
	}
	handleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;
		this.setState({ model });
	}
	Search = () => {
		this.setState({ isLoading: true });
		var { FileId } = { ...this.state };
		if (FileId == 0) {
			Proxy.POST(
				"/chequeapi/v1.0/Cheque/GetCHQOutgoingDailyFileList",
				this.state.model,
				this.successDailyFileList,
				this.errorCallback
			);
		}
		else {
			Proxy.POST(
				"/chequeapi/v1.0/Cheque/GetCHQOutgoingDailyDetailFileList",
				{
					ClientId: this.state.model.UniqueClientId,
					FileId: this.state.FileId
				},
				this.successDailyDetailFileList,
				this.errorCallback
			);

		}
	}
	successDailyDetailFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.setState({
			dailyFileDetailList: responseData.Item.GetCHQOutgoingDailyDetailFileList == null ? [] : responseData.Item.GetCHQOutgoingDailyDetailFileList.length < 1 ? [] : responseData.Item.GetCHQOutgoingDailyDetailFileList.map(x => {
				return {
					Actions: (
						<div>
							<GridButton
								tooltip="Edit"
								Icon={DetailIcon}
								OnClick={() => { this.OpenEditModal(x); }} />
						</div>
					),
					Id: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileName: x.FileName == null || undefined ? "" : x.FileName,
					Date: x.FileDate == null || undefined ? "" : x.FileDate,
					UniqueClientId: x.UniqueClientId == null || undefined ? "" : x.UniqueClientId,
					ClientId: x.ClientId == null || undefined ? 0 : x.ClientId,
					FileSource: x.FileSource == null || undefined ? "" : x.FileSource,
					SendStatus: x.SendStatus == null || undefined ? "" : x.SendStatus,
					ChequeStatus: x.ChequeStatus == null || undefined ? "" : x.ChequeStatus,
					FileHeaderRecordType: x.FileHeader.RecordType == null || undefined ? "" : x.FileHeader.RecordType,
					RecordName: x.FileChequeDetail == null ? "Credit" : "Cheque",
					FileHeaderStandardLevel: x.FileHeader.StandardLevel == null || undefined ? "" : x.FileHeader.StandardLevel,
					FileHeaderTestFileIndicator: x.FileHeader.TestFileIndicator == null || undefined ? "" : x.FileHeader.TestFileIndicator,
					FileHeaderImmediateDestinationRoutingNumber: x.FileHeader.ImmediateDestinationRoutingNumber == null || undefined ? "" : x.FileHeader.ImmediateDestinationRoutingNumber,
					FileHeaderImmediateOriginRoutingNumber: x.FileHeader.ImmediateOriginRoutingNumber == null || undefined ? "" : x.FileHeader.ImmediateOriginRoutingNumber,
					FileHeaderFileCreationDate: x.FileHeader.FileCreationDate == null || undefined ? "" : x.FileHeader.FileCreationDate,
					FileHeaderFileCreationTime: x.FileHeader.FileCreationTime == null || undefined ? "" : x.FileHeader.FileCreationTime,
					FileHeaderResendIndicator: x.FileHeader.ResendIndicator == null || undefined ? "" : x.FileHeader.ResendIndicator,
					FileHeaderFileIdModifier: x.FileHeader.FileIdModifier == null || undefined ? "" : x.FileHeader.FileIdModifier,
					FileCashLetterHeaderRecordType: x.FileCashLetterHeader.RecordType == null || undefined ? "" : x.FileCashLetterHeader.RecordType,
					FileCashLetterHeaderCollectionTypeIndicator: x.FileCashLetterHeader.CollectionTypeIndicator == null || undefined ? "" : x.FileCashLetterHeader.CollectionTypeIndicator,
					FileCashLetterHeaderDestinationRoutingNumber: x.FileCashLetterHeader.DestinationRoutingNumber == null || undefined ? "" : x.FileCashLetterHeader.DestinationRoutingNumber,
					FileCashLetterHeaderECEInstitutionRoutingNumber: x.FileCashLetterHeader.ECEInstitutionRoutingNumber == null || undefined ? "" : x.FileCashLetterHeader.ECEInstitutionRoutingNumber,
					FileCashLetterHeaderCashLetterBusinessDate: x.FileCashLetterHeader.CashLetterBusinessDate == null || undefined ? "" : x.FileCashLetterHeader.CashLetterBusinessDate,
					FileCashLetterHeaderCashLetterCreationDate: x.FileCashLetterHeader.CashLetterCreationDate == null || undefined ? "" : x.FileCashLetterHeader.CashLetterCreationDate,
					FileCashLetterHeaderCashLetterCreationTime: x.FileCashLetterHeader.CashLetterCreationTime == null || undefined ? "" : x.FileCashLetterHeader.CashLetterCreationTime,
					FileCashLetterHeaderCashLetterRecordTypeIndicator: x.FileCashLetterHeader.CashLetterRecordTypeIndicator == null || undefined ? "" : x.FileCashLetterHeader.CashLetterRecordTypeIndicator,
					FileCashLetterHeaderCashLetterDocumentationTypeIndicator: x.FileCashLetterHeader.CashLetterDocumentationTypeIndicator == null || undefined ? "" : x.FileCashLetterHeader.CashLetterDocumentationTypeIndicator,
					FileCashLetterHeaderCashLetterId: x.FileCashLetterHeader.CashLetterId == null || undefined ? "" : x.FileCashLetterHeader.CashLetterId,
					FileBundleHeaderRecordType: x.FileBundleHeader.RecordType == null || undefined ? "" : x.FileBundleHeader.RecordType,
					FileBundleHeaderBundleBusinessDate: x.FileBundleHeader.BundleBusinessDate == null || undefined ? "" : x.FileBundleHeader.BundleBusinessDate,
					FileBundleHeaderBundleCreationDate: x.FileBundleHeader.BundleCreationDate == null || undefined ? "" : x.FileBundleHeader.BundleCreationDate,
					FileBundleHeaderBundleId: x.FileBundleHeader.BundleId == null || undefined ? "" : x.FileBundleHeader.BundleId,
					FileBundleHeaderBundleSequenceNumber: x.FileBundleHeader.BundleSequenceNumber == null || undefined ? "" : x.FileBundleHeader.BundleSequenceNumber,
					FileCreditRecordType: x.FileCredit == null || undefined ? "" : x.FileCredit.RecordType,
					FileCreditAuxiliaryOnUs: x.FileCredit == null || undefined ? "" : x.FileCredit.AuxiliaryOnUs,
					FileCreditPostingAccountRoutingNumber: x.FileCredit == null || undefined ? "" : x.FileCredit.PostingAccountRoutingNumber,
					FileCreditOnUsAccount: x.FileCredit == null || undefined ? "" : x.FileCredit.OnUsAccount,
					FileCreditItemAmount: x.FileCredit == null || undefined ? "" : x.FileCredit.ItemAmount,
					FileCreditECEInstitutionItemSequenceNumber: x.FileCredit == null || undefined ? "" : x.FileCredit.ECEInstitutionItemSequenceNumber,
					FileCreditDocumentationTypeIndicator: x.FileCredit == null || undefined ? "" : x.FileCredit.DocumentationTypeIndicator,
					FileCreditDebitCreditIndicator: x.FileCredit == null || undefined ? "" : x.FileCredit.DebitCreditIndicator,
					FileChequeDetailRecordType: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.RecordType,
					FileChequeDetailAuxiliaryOnUs: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.AuxiliaryOnUs,
					FileChequeDetailPayorBankRoutingNumber: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.PayorBankRoutingNumber,
					FileChequeDetailPayorBankRoutingCheckDigit: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.PayorBankRoutingCheckDigit,
					FileChequeDetailOnUsAccount: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.OnUsAccount,
					FileChequeDetailItemAmount: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.ItemAmount,
					FileChequeDetailECEInstitutionItemSequenceNumber: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.ECEInstitutionItemSequenceNumber,
					FileChequeDetailDocumentationTypeIndicator: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.DocumentationTypeIndicator,
					FileChequeDetailBOFDIndicator: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.BOFDIndicator,
					FileChequeDetailChequeDetailRecordAddendumCount: x.FileChequeDetail == null || undefined ? "" : x.FileChequeDetail.ChequeDetailRecordAddendumCount,
					FileBundleControlRecordType: x.FileBundleControl.RecordType == null || undefined ? "" : x.FileBundleControl.RecordType,
					FileBundleControlItemsWithinBundleCount: x.FileBundleControl.ItemsWithinBundleCount == null || undefined ? "" : x.FileBundleControl.ItemsWithinBundleCount,
					FileBundleControlBundleTotalAmount: x.FileBundleControl.BundleTotalAmount == null || undefined ? "" : x.FileBundleControl.BundleTotalAmount,
					FileBundleControlImagesWithinBundleCount: x.FileBundleControl.ImagesWithinBundleCount == null || undefined ? "" : x.FileBundleControl.ImagesWithinBundleCount,
					FileCashLetterControlRecordType: x.FileCashLetterControl.RecordType == null || undefined ? "" : x.FileCashLetterControl.RecordType,
					FileCashLetterControlBundleCount: x.FileCashLetterControl.BundleCount == null || undefined ? "" : x.FileCashLetterControl.BundleCount,
					FileCashLetterControlItemsWithinCashLetterCount: x.FileCashLetterControl.ItemsWithinCashLetterCount == null || undefined ? "" : x.FileCashLetterControl.ItemsWithinCashLetterCount,
					FileCashLetterControlImagesWithinBundleCount: x.FileCashLetterControl.ImagesWithinBundleCount == null || undefined ? "" : x.FileCashLetterControl.ImagesWithinBundleCount,
					FileControlRecordType: x.FileControl.RecordType == null || undefined ? "" : x.FileControl.RecordType,
					FileControlCashLetterCount: x.FileControl.CashLetterCount == null || undefined ? "" : x.FileControl.CashLetterCount,
					FileControlTotalRecordCount: x.FileControl.TotalRecordCount == null || undefined ? "" : x.FileControl.TotalRecordCount,
					FileControlTotalItemCount: x.FileControl.TotalItemCount == null || undefined ? "" : x.FileControl.TotalItemCount,
					FileControlFileTotalAmount: x.FileControl.FileTotalAmount == null || undefined ? "" : x.FileControl.FileTotalAmount,




				};
			}), totalchqdetailinfomodel: responseData.Item.GetCHQOutgoingDetailDailyFileCalculation == null ? {} : responseData.Item.GetCHQOutgoingDetailDailyFileCalculation,

			isLoading: false
		});
	}
	OpenEditModal = (item) => {
		this.setState({ isLoading: false });
		var TypeName = "";
		var itemId = 0;
		var Titlerecord = "";
		if (item.FileChequeDetail != null) {

			TypeName = "CH";
			itemId = item.FileChequeDetail.Id;
			Titlerecord = "Cheque Image Information";
		} else {
			TypeName = "CR";
			itemId = item.FileCredit.Id;
			Titlerecord = "Credit Image Information";

		}

		Proxy.POST(
			"/chequeapi/v1.0/Cheque/GetChequeDetailList",
			{
				"RecordId": itemId,
				"TypeName": TypeName
			},

			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var chqinfomodel = responseData.Item.GetChequeDetail;

				this.setState({ isLoading: false, openDialog: true, chqinfomodel, Titlerecord: Titlerecord });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);

	}
	successDailyFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.setState({

			dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item.GetCHQOutgoingDailyFileList.map(x => {
				return {

					Id: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileId: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileName: x.FileName == null || undefined ? "" : x.FileName,
					FileDate: x.FileDate == null || undefined ? "" : x.FileDate,
					FileCrationNumber: x.FileCreationNumber == null || undefined ? "" : x.FileCreationNumber,
					FileSource: x.FileSource == null || undefined ? "" : x.FileSource,
					RecordCount: x.RecordCount == null || undefined ? "" : x.RecordCount,
					RejectedCount: x.RejectedCount == null || undefined ? "" : x.RejectedCount,
					SendStatus: x.SendStatus == null || undefined ? "" : x.SendStatus,
					CreditDebitTotalAmount: x.CreditDebitTotalAmount == null || undefined ? 0 : x.CreditDebitTotalAmount,
					CreditDebitTotalCount: x.CreditDebitTotalCount == null || undefined ? 0 : x.CreditDebitTotalCount,
					ChequeTotalAmount: x.ChequeTotalAmount == null || undefined ? 0 : x.ChequeTotalAmount,
					ChequeTotalCount: x.ChequeTotalCount == null || undefined ? 0 : x.ChequeTotalCount,




				};
			}), isLoading: false
		});

		this.setState({ totalchqinfomodel: responseData.Item.GetCHQOutgoingDailyFileCalculation });

	}
	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="Error" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}



	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	hideAlert = () => {
		this.setState({ alert: null });
	}

	Clear = () => {
		this.setState({
			model: {
				DirectionParameterValue: Direction.Outgoing, dailyFileDetailList: [],
			}, dailyFileList: [], totalchqinfomodel: {}, FileId: 0, totalchqdetailinfomodel: {}
		});


	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}

	render() {
		const { classes } = this.props;
		const { model, dailyFileList, selected, FileId, dailyFileDetailList, isBackOffice, totalchqinfomodel, totalvisible, totalchqdetailinfomodel, Titlerecord } = this.state;

		return (
			<div>

				<GenericDialog open={this.state.openDialog} maxWidth="md" fullWidth onBackdropClick={this.handleClose} >
					<DialogTitle><GenericLabel Text={Titlerecord} FontSize="20px" Bold={true} /></DialogTitle>
					<DialogContent >
						{<ChequeImageDetail
							chqinfomodel={this.state.chqinfomodel}
						/>}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ openDialog: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				{this.state.alert}

				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.Search
					},

					{
						Code: "Clear",
						OnClick: this.Clear,

					},

					{
						Code: "Upload",
						OnClick: this.Clear,

					},

					{
						Code: "View",
						OnClick: this.Clear,

					}


				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className={classes.zerMarPad}>
					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GridContainer spacing={16} >
										<GridItem xs={4}>
											<GenericSelectInput
												Name="Name"
												LabelText="Client Name"
												Value={model.Name || ""}
												DataRoot="Item"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="Name"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{ UniqueClientId: 0 }}
												Disabled={!isBackOffice}
											/>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Unique Client ID"
												Value={model.UniqueClientId || ""}
												DataRoot="Item"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="UniqueClientId"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{ UniqueClientId: 0 }}
												Disabled={!isBackOffice}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model == null || undefined ? null : model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.handleChange}
												MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate}
											/>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model == null || undefined ? null : model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.handleChange}
												MaxDate={DateHelper.GetDate()}
												MinDate={model.FromDate}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Cheque Clearing Details"} />
						</CardHeader>
						<GridContainer>
							<GridItem xs={12}>
								<TabsComponent
									TabIndex={this.state.ParentTabIndex}
									handleChange={(e, a) => this.handleChangeParentTab(e, a, true)}
									tabList={[
										{
											tabButton: "Files",
											tabContent: (
												<GridItem>
													<GenericGrid
														Data={dailyFileList}
														SelectedIndex={selected}
														Columns={[
															{
																Header: "Date",
																accessor: "FileDate",
																type: GridColumnType.Date
															},


															{
																Header: "File Name",
																accessor: "FileName",

															},
															{
																Header: "Debit/Credit Total Amount",
																accessor: "CreditDebitTotalAmount",
																type: GridColumnType.Money,
																ColumnType:GridColumnType.Money
															},
															{
																Header: "Debit/Credit Total Count",
																accessor: "CreditDebitTotalCount",
																Cell: row => (
																	<span>{Formatter.FormatNumber(row.value)}</span>
																)
															},
															{
																Header: "Cheque Total Amount",
																accessor: "ChequeTotalAmount",
																type: GridColumnType.Money,
																ColumnType:GridColumnType.Money
															},
															{
																Header: "Cheque Total Count",
																accessor: "ChequeTotalCount",
																Cell: row => (
																	<span>{Formatter.FormatNumber(row.value)}</span>
																)
															},
															{
																Header: "Status",
																accessor: "SendStatus"
															}

														]}
														RowSelected={index => {

															this.setState({ FileId: dailyFileList[index].FileId, selected: index });
														}} />
												</GridItem>
											)
										},
										{
											tabButton: "File Details",
											tabContent: (
												<GridItem>
													<GenericGrid
														Data={dailyFileDetailList}
														SelectedIndex={selected}
														Columns={[
															{
																Header: "Actions",
																accessor: "Actions",
																sortable: false,
																filterable: false,
																width: 50
																// show: !propIsBackoffice
															},
															{
																Header: "Unique Client Id",
																accessor: "UniqueClientId"
															},
															{
																Header: "Record Name",
																accessor: "RecordName"
															},
															{
																Header: "Status",
																accessor: "SendStatus"
															}
															,
															{
																Header: "Cheque Status",
																accessor: "ChequeStatus"
															},

															{
																Header: "FileHeader.RecordType",
																accessor: "FileHeaderRecordType"
															},
															{
																Header: "FileHeader.StandardLevel",
																accessor: "FileHeaderStandardLevel"
															},
															{
																Header: "FileHeader.Test FileIndicator",
																accessor: "FileHeaderTestFileIndicator"
															},
															{
																Header: "FileHeader.Immediate Destination RoutingNumber",
																accessor: "FileHeaderImmediateDestinationRoutingNumber"
															},
															{
																Header: "FileHeader.Immediate Origin Routing Number",
																accessor: "FileHeaderImmediateOriginRoutingNumber"
															},
															{
																Header: "FileHeader.File CreationDate",
																accessor: "FileHeaderFileCreationDate",
																type: GridColumnType.Date
															},
															{
																Header: "FileHeader.FileCreationTime",
																accessor: "FileHeaderFileCreationTime"
															},
															{
																Header: "FileHeader.ResendIndicator",
																accessor: "FileHeaderResendIndicator"
															},
															{
																Header: "FileHeader.FileIdModifier",
																accessor: "FileHeaderFileIdModifier"
															},
															{
																Header: "FileCashLetterHeader.RecordType",
																accessor: "FileCashLetterHeaderRecordType"
															},
															{
																Header: "FileCashLetterHeader.Collection Type Indicator",
																accessor: "FileCashLetterHeaderCollectionTypeIndicator"
															},
															{
																Header: "FileCashLetterHeader.Destination Routing Number",
																accessor: "FileCashLetterHeaderDestinationRoutingNumber"
															},
															{
																Header: "FileCashLetterHeader.ECE Institution Routing Number",
																accessor: "FileCashLetterHeaderECEInstitutionRoutingNumber"
															},
															{
																Header: "FileCashLetterHeader.CashLetter BusinessDate",
																accessor: "FileCashLetterHeaderCashLetterBusinessDate",
																type: GridColumnType.Date
															},
															{
																Header: "FileCashLetterHeader.CashLetter CreationDate",
																accessor: "FileCashLetterHeaderCashLetterCreationDate",
																type: GridColumnType.Date
															},
															{
																Header: "FileCashLetterHeader.CashLetter CreationTime",
																accessor: "FileCashLetterHeaderCashLetterCreationTime"
															},
															{
																Header: "FileCashLetterHeader.CashLetter RecordType Indicator",
																accessor: "FileCashLetterHeaderCashLetterRecordTypeIndicator"
															},
															{
																Header: "FileCashLetterHeader.CashLetter DocumentationTypeIndicator",
																accessor: "FileCashLetterHeaderCashLetterDocumentationTypeIndicator"
															},
															{
																Header: "FileCashLetterHeader.CashLetter Id",
																accessor: "FileCashLetterHeaderCashLetterId"
															},
															{
																Header: "FileBundleHeader.RecordType",
																accessor: "FileBundleHeaderRecordType"
															},
															{
																Header: "FileBundleHeader.Bundle BusinessDate",
																accessor: "FileBundleHeaderBundleBusinessDate",
																type: GridColumnType.Date
															},
															{
																Header: "FileBundleHeader.Bundle CreationDate",
																accessor: "FileBundleHeaderBundleCreationDate",
																type: GridColumnType.Date
															},
															{
																Header: "FileBundleHeader.Bundle Id",
																accessor: "FileBundleHeaderBundleId"
															},
															{
																Header: "FileBundleHeader.Bundle SequenceNumber",
																accessor: "FileBundleHeaderBundleSequenceNumber"
															},
															{
																Header: "FileCredit.RecordType",
																accessor: "FileCreditRecordType"
															},
															{
																Header: "FileCredit.Auxiliary On-Us",
																accessor: "FileCreditAuxiliaryOnUs"
															},
															{
																Header: "FileCredit.PostingAccount Routing Number",
																accessor: "FileCreditPostingAccountRoutingNumber"
															},
															{
																Header: "FileCredit.On-Us Wallet",
																accessor: "FileCreditOnUsAccount"
															},
															{
																Header: "FileCredit.Item Amount",
																accessor: "FileCreditItemAmount"
															},
															{
																Header: "FileCredit.ECE Institution Item SequenceNumber",
																accessor: "FileCreditECEInstitutionItemSequenceNumber"
															},
															{
																Header: "FileCredit.DocumentationType Indicator",
																accessor: "FileCreditDocumentationTypeIndicator"
															},
															{
																Header: "FileCredit.Debit-Credit Indicator",
																accessor: "FileCreditDebitCreditIndicator"
															},
															{
																Header: "FileChequeDetail.RecordType",
																accessor: "FileChequeDetailRecordType"
															},
															{
																Header: "FileChequeDetail.Auxiliary On-Us",
																accessor: "FileChequeDetailAuxiliaryOnUs"
															},
															{
																Header: "FileChequeDetail.Payor Bank Routing Number",
																accessor: "FileChequeDetailPayorBankRoutingNumber"
															},
															{
																Header: "FileChequeDetail.Payor Bank Routing CheckDigit",
																accessor: "FileChequeDetailPayorBankRoutingCheckDigit"
															},
															{
																Header: "FileChequeDetail.On-Us Wallet",
																accessor: "FileChequeDetailOnUsAccount"
															},
															{
																Header: "FileChequeDetail.Item Amount",
																accessor: "FileChequeDetailItemAmount",
																type: GridColumnType.Money,
																ColumnType:GridColumnType.Money
															},
															{
																Header: "FileChequeDetail.ECE Institution Item SequenceNumber",
																accessor: "FileChequeDetailECEInstitutionItemSequenceNumber"
															},
															{
																Header: "FileChequeDetail.DocumentationType Indicator",
																accessor: "FileChequeDetailDocumentationTypeIndicator"
															},
															{
																Header: "FileChequeDetail.BOFD Indicator",
																accessor: "FileChequeDetailBOFDIndicator"
															},
															{
																Header: "FileChequeDetail.ChequeDetailRecordAddendumCount",
																accessor: "FileChequeDetailChequeDetailRecordAddendumCount"
															},
															{
																Header: "FileBundleControl.RecordType",
																accessor: "FileBundleControlRecordType"
															},
															{
																Header: "FileBundleControl.Items Within Bundle Count",
																accessor: "FileBundleControlItemsWithinBundleCount"
															},
															{
																Header: "FileBundleControl.Bundle Total Amount",
																accessor: "FileBundleControlBundleTotalAmount",
																type: GridColumnType.Money,
																ColumnType:GridColumnType.Money
															},
															{
																Header: "FileBundleControl.Images Within Bundle Count",
																accessor: "FileBundleControlImagesWithinBundleCount"
															},
															{
																Header: "FileCashLetterControl.RecordType",
																accessor: "FileCashLetterControlRecordType"
															},
															{
																Header: "FileCashLetterControl.Bundle Count",
																accessor: "FileCashLetterControlBundleCount"
															},
															{
																Header: "FileCashLetterControl.Items Within CashLetter Count",
																accessor: "FileCashLetterControlItemsWithinCashLetterCount"
															},
															{
																Header: "FileCashLetterControl.Images Within Bundle Count",
																accessor: "FileCashLetterControlImagesWithinBundleCount"
															},
															{
																Header: "FileControl.RecordType",
																accessor: "FileControlRecordType"
															},
															{
																Header: "FileControl.CashLetter Count",
																accessor: "FileControlCashLetterCount"
															},
															{
																Header: "FileControl.TotalRecord Count",
																accessor: "FileControlTotalRecordCount"
															},
															{
																Header: "FileControl.TotalItem Count",
																accessor: "FileControlTotalItemCount"
															},
															{
																Header: "FileControl.FileTotal Amount",
																accessor: "FileControlFileTotalAmount",
																type: GridColumnType.Money,
																ColumnType:GridColumnType.Money
															},

														]}
														RowSelected={index => {

															this.setState({ model: dailyFileDetailList[index], selected: index });
														}} />
												</GridItem>
											)
										}


									]}
								/>
							</GridItem>
						</GridContainer>
						{totalvisible == false ?
							<GridContainer justify="flex-end">
								<GridItem xs={8} />
								<GridItem xs={8}>
									<GridContainer className={classes.containerHeight} justify="flex-end" >
										<GridItem xs={2} className={classes.gridBorder}>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Cheque Amount</p>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Cheque Count</p>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Credit/Debit Amount</p>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Credit/Debit Count</p>
										</GridItem>
									</GridContainer>
									<GridContainer justify="flex-end" >
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold} >
											TOTAL
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter} >

											{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.ChequeTotalAmount == null || undefined ? 0 : typeof totalchqinfomodel.ChequeTotalAmount == "number" ? Formatter.FormatMoney(totalchqinfomodel.ChequeTotalAmount) : totalchqinfomodel.ChequeTotalAmount}
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter} >

											{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.ChequeTotalCount == null || undefined ? 0 : typeof totalchqinfomodel.ChequeTotalCount == "number" ? Formatter.FormatNumber(totalchqinfomodel.ChequeTotalCount) : totalchqinfomodel.ChequeTotalCount}
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

											{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.DebitCreditTotalAmount == null || undefined ? 0 : typeof totalchqinfomodel.DebitCreditTotalAmount == "number" ? Formatter.FormatMoney(totalchqinfomodel.DebitCreditTotalAmount) : totalchqinfomodel.DebitCreditTotalAmount}
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

											{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.DebitCreditTotalCount == null || undefined ? 0 : typeof totalchqinfomodel.DebitCreditTotalCount == "number" ? Formatter.FormatNumber(totalchqinfomodel.DebitCreditTotalCount) : totalchqinfomodel.DebitCreditTotalCount}
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							:
							<GridContainer >
								<GridItem xs={6} />
								<GridItem xs={6}>
									<GridContainer className={classes.containerHeight} justify="flex-end">
										<GridItem xs={2} className={classes.gridBorder}>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Deposit Amount</p>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Deposit Count</p>
										</GridItem>

									</GridContainer>
									<GridContainer justify="flex-end">
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
											TOTAL
										</GridItem>

										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

											{totalchqdetailinfomodel == null || undefined ? 0 : totalchqdetailinfomodel.TotalDepositAmount == null || undefined ? 0 : typeof totalchqdetailinfomodel.TotalDepositAmount == "number" ? Formatter.FormatMoney(totalchqdetailinfomodel.TotalDepositAmount) : totalchqdetailinfomodel.TotalDepositAmount}
										</GridItem>

										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

											{totalchqdetailinfomodel == null || undefined ? 0 : totalchqdetailinfomodel.TotalDepositCount == null || undefined ? 0 : typeof totalchqdetailinfomodel.TotalDepositCount == "number" ? Formatter.FormatNumber(totalchqdetailinfomodel.TotalDepositCount) : totalchqdetailinfomodel.TotalDepositCount}
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						}
					</CardBody>
				</Card>
			</div>
		);
	}
}
ChequeOutgoingFile.propTypes =
	{
		setAppLeftTitle: PropTypes.func,
		setAppCenterTitle: PropTypes.func,
		Disabled: PropTypes.bool,
		menuId: PropTypes.any,
		ApprovalData: PropTypes.any,
		After: PropTypes.any

	};
export default withArtifex(ChequeOutgoingFile, ChequeManagementStyle);
