import { CardMedia, FormControlLabel, Radio } from "@material-ui/core";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Black from "components/Typography/Black";
import { Proxy, withRedux } from "core";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { UserConstants } from "store";
import { GenericAlert, GenericEmailInput, GenericNumberInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { AuthenticationMethod } from "views/Constants/Constant";

class ResetPassword extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			model: {
				Otp: "",
				OtpId: 0,
				NewPassword: "",
				ConfirmNewPassword: "",
				AuthenticationMethod: ""
			},
			alert: "",
			isLoading: false,
		};

		this.cardHeaderStyle = { height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" };
		this.cardMediaStyle = {
			height: "75%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 10,
			backgroundSize: "100% 100%", boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.45)"
		};
		this.cardBodyStyle = { marginTop: 62, width: "83%", marginLeft: "auto", marginRight: "auto" };
		this.googleImageStyle = { height: 50 };
		this.twilioImageStyle = { height: 50 };
	}

	componentDidMount() {
		const { UserContext } = this.props;
		var model = { ...this.state.model };

		if (UserContext.user)
			model.Email = UserContext.user.Email;
		this.setState({ model });
	}

	HandleChange = (name, value) => {
		this.setState(state => {
			var model = state.model || {};
			model[name] = value;
			return { model };
		});
	}

	HandleVerify = () => {
		const { model } = this.state;

		if (!model.Otp || model.Otp.trim() === "") {
			this.ShowMessage("warning", "2FA Code Is Empty", "Enter 2FA Code to verify.");
			return;
		}
		if (model.AuthenticationMethod == null) {
			this.ShowMessage("warning", "Authentication Method Is Empty", "Enter Authentication Method to verify 2FA!");
			return false;
		}
	}

	handleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.ResetPassword();
		}
	}

	handleChangeAuthMethod = event => {
		const { model } = this.state;
		if (!this.ValidateEmail())
			return;

		if (event.target.value == AuthenticationMethod.Google) {
			// this.GetQRCode();
		} else {
			this.SendSMSOTP();
		}
		model.AuthenticationMethod = event.target.value;
		this.setState({ model });
	};

	ResetPassword = () => {
		const { model } = this.state;

		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/User/ResetPassword",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowConfirmMessage("success", "Successful", "The password has been successfully reset.",
					() => {
						if (responseData.Item) {
							this.HandleLogout();

							window.open("/", "_self");
						}
					}
				);
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred during the reset password " + error);
			});
	}

	SendSMSOTP = () => {
		const { model } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/SendSmsOtpForPasswordReset",
			{
				Email: this.state.model.Email,
				OrganizationUniqueId: window.OrganizationUniqueId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				model.OtpId = responseData.OtpId;
				this.setState({ model });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred during the send sms otp " + error);
			}
		);
	}

	HandleLogout = () => {
		localStorage.removeItem("user");

		const { dispatch } = this.props;
		dispatch({ type: UserConstants.LOGOUT });
	}

	ValidateEmail = () => {
		const { model } = this.state;

		if (!model.Email) {
			this.ShowMessage("warning", "E-Mail Is Empty", "Enter E-Mail for us to send the verification SMS!");
			return false;
		}
		return true;
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.Email) {
			this.ShowMessage("warning", "E-Mail Is Empty", "Enter E-Mail to reset password!");
			return false;
		}
		if (!model.NewPassword || model.NewPassword.trim().length === 0) {
			this.ShowMessage("warning", "New Password Is Empty", "Enter new password to reset password!");
			return false;
		}
		if (!model.ConfirmNewPassword || model.ConfirmNewPassword.trim().length === 0) {
			this.ShowMessage("warning", "Confirm New Password Is Empty", "Enter confirm new password to reset password!");
			return false;
		}
		if (model.NewPassword != model.ConfirmNewPassword) {
			this.ShowMessage("warning", "Warning", "The new password and the confirm new password do not match.");
			return false;
		}

		return true;
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })}
			/>
		});
	}

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, UserContext } = this.props;
		const { alert, isLoading, model } = this.state;

		const logo = require("assets/img/" + window.OtpLogo);
		const googleLogo = require("assets/img/" + window.OtpGoogleLogo);
		const twilioLogo = require("assets/img/" + window.OtpTwilio);

		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading} />
				{alert}
				<GridContainer justify="left">
					<GridItem style={{ minWidth: "600px", maxWidth: "656px" }}>
						<form>
							<Card login>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="white" style={this.cardHeaderStyle}>
									<CardMedia className={classes.media} image={logo} style={this.cardMediaStyle} title="" />
								</CardHeader>
								<CardBody style={this.cardBodyStyle}>
									<GridContainer direction="column" justify="flex-start" alignItems="stretch">
										<GridItem>
											<GenericEmailInput
												FloatRight={true}
												LabelMd={5}
												Name="Email"
												LabelText="E-Mail"
												Value={model.Email}
												ValueChanged={this.HandleChange}
												EndIconName="email"
												AutoFocus={true}
												KeyPressed={this.handleKeyPress}
												Disabled={UserContext && UserContext.user && UserContext.user.Email ? true : false} />

											<GridContainer style={{ margin: 10 }} direction="column" justify="space-between" alignItems="center">
												<Black>Select Your 2FA Authentication Method</Black>
												<div>
													<GridContainer direction="row" justify="space-between" alignItems="flex-start">
														<GridItem>
															<GridContainer direction="column" justify="flex-start" alignItems="center">
																<GridItem>
																	<FormControlLabel
																		control={
																			<Radio
																				checked={model.AuthenticationMethod === AuthenticationMethod.Google}
																				value={AuthenticationMethod.Google}
																				name="radio-button"
																				classes={{ checked: classes.radio }}
																				onChange={this.handleChangeAuthMethod}
																			/>
																		}
																		classes={{ label: classes.radioLabel }}
																		label="Google"
																	/>
																</GridItem>
																<GridItem>
																	<img className={classes.img} style={this.googleImageStyle} src={googleLogo} alt="Google" />
																</GridItem>
															</GridContainer>
														</GridItem>
														<GridItem>
															<GridContainer direction="column" justify="flex-start" alignItems="center">
																<GridItem>
																	<FormControlLabel
																		control={
																			<Radio
																				checked={model.AuthenticationMethod === AuthenticationMethod.Twilio}
																				value={AuthenticationMethod.Twilio}
																				name="radio-button"
																				classes={{ checked: classes.radio }}
																				onChange={this.handleChangeAuthMethod}
																			/>
																		}
																		classes={{ label: classes.radioLabel }}
																		label="Text 2FA"
																	/>
																</GridItem>
																<GridItem>
																	<img className={classes.img} style={this.twilioImageStyle} src={twilioLogo} alt="Twilio" />
																</GridItem>
															</GridContainer>
														</GridItem>
													</GridContainer>
												</div>
											</GridContainer>

											<GridContainer>
												<GridItem xs={12}>
													<GenericNumberInput
														FloatRight={true}
														NoFormatting={true}
														ThousandSeparator=""
														Name="Otp"
														LabelMd={5}
														LabelText="Enter 2FA Code"
														Value={model.Otp}
														ValueChanged={this.HandleChange}
														MaxLength={6} />
												</GridItem>
											</GridContainer>

											<GenericTextInput
												FloatRight={true}
												LabelMd={5}
												Name="NewPassword"
												LabelText="Enter New Password"
												IsPassword={true}
												Value={model.NewPassword}
												ValueChanged={this.HandleChange}
												EndIconName="lock_outline"
												KeyPressed={this.handleKeyPress} />

											<GenericTextInput
												FloatRight={true}
												LabelMd={5}
												Name="ConfirmNewPassword"
												LabelText="Confirm New Password"
												IsPassword={true}
												Value={model.ConfirmNewPassword}
												ValueChanged={this.HandleChange}
												EndIconName="lock_outline"
												KeyPressed={this.handleKeyPress} />

											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={5} />
												<GridItem xs={7}>
													<Button color="black" size="lg" block onClick={this.ResetPassword}>
														RESET
													</Button>
												</GridItem>
											</GridContainer>

											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={5} />
												<GridItem xs={7}>
													<div style={{ float: "right" }}>
														<Link style={{ color: window.PageMainColor }} to={"/acc/login"} onClick={this.HandleLogout}> Back to Login </Link>
													</div>
												</GridItem>
												<GridItem style={{ height: 33 }}></GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter} />
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ResetPassword.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRedux(ResetPassword, loginPageStyle);