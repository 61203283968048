import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class PrePersonCardOrder extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
		};

		this.state = {
			addressModel: {},
			model: this.initialModel,
			vModel: {},
			list: [],
			cardList: [],
			isLoading: false
		};

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.emptyObject = {};

		this.maxLength20 = { maxLength: 20 };
		this.maxLength30 = { maxLength: 30 };
		this.maxLength100 = { maxLength: 100 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Number of Card",
				accessor: "NumberOfCard"
			},
			{
				Header: "Address Line 1",
				accessor: "AddressLine1"
			},
			{
				Header: "Address Line 2",
				accessor: "AddressLine2"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Province",
				accessor: "Province"
			},
			{
				Header: "Zip Code",
				accessor: "ZipCode"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "Contact Person",
				accessor: "ContactPerson"
			},
			{
				Header: "Success Emboss Count",
				accessor: "SuccessEmbossCount"
			},
			{
				Header: "Error Emboss Count",
				accessor: "ErrorEmbossCount"
			},
			{
				Header: "Waiting Emboss Count",
				accessor: "WaitingEmbossCount"
			},
			{
				Header: "Is Exported",
				accessor: "IsExported",
				type: GridColumnType.YesNo
			},
			{
				Header: "Export Date Time",
				accessor: "ExportDateTime",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Export File Name",
				accessor: "ExportFileName"
			},
		];

		this.ColumnsCardList = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Pre Person Card Order");	this.getappLeftTitle = "Pre Person Card Order";
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");this.getappCenterTitle = "PREPAID CARD MANAGEMENT";
		this.FetchCanadaPost();
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	FetchCanadaPost = () => {
		this.loadTaskPool.AppendTask(
			Proxy.POST("/coreapi/v1.0/Parameter/Search",
				{
					ParameterCode: "CanadaPostApiKey",
					ParameterValue: "CP",
					ParameterDesc: ""
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						console.log("Canada Post Api key not fetched !");
						return;
					}
					if (responseData.Item == null) {
						return;
					}
					if (responseData.Item[0] == null) {
						return;
					}

					var apikey = responseData.Item[0].ParameterDesc;
					console.log("KEY CP ", apikey);

					var url = window.CanadaPostAddressCompleteUrl;
					var path = url + apikey;

					var pca;
					var addressComplete;

					var temp = this.state.addressModel;
					var CpTemp = this.AddressHandle;
					var CanadaPostRequest=this.SubmitCanadaPostRequest;

					const script = document.createElement("script");
					script.src = path;
					script.async = false;
					document.head.appendChild(script);

					script.onload = function () {
						pca = window.pca;
						addressComplete = window.addressComplete;
						if (pca != null) {
							var fields =
								[
									{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
									{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
									{ element: "city", field: "City", mode: pca.fieldMode.POPULATE },
									{ element: "state", field: "ProvinceName", mode: pca.fieldMode.SEARCH },
									{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
									{ element: "country", field: "CountryName", mode: pca.fieldMode.COUNTRY }
								],
								options = {
									key: apikey
								},
								control = new pca.Address(fields, options);
								CanadaPostRequest();


							control.listen("populate", function (address) {
								console.log(address);
								temp.City = address.City;
								temp.Province = address.ProvinceName;
								temp.Country = address.CountryName;
								temp.AddressLine1 = address.Line1;
								temp.AddressLine2 = address.Line2;
								temp.ZipCode = address.PostalCode;
								CpTemp(temp);
							});
						}
					};
				},
				e => {
					console.log("Canada Post Api key not fetched !");
				}
			));
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.getMenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	AddressHandle = (address) => {
		var model = { ...this.state.model };
		model.AddressLine1 = address.AddressLine1;
		model.AddressLine2 = address.AddressLine2;
		model.City = address.City;
		model.Province = address.Province;
		model.Country = address.Country;
		model.ZipCode = address.ZipCode;
		this.setState({ model });
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		model[name] = newValue;
		this.setState({ model }, () => {
			if (name === "UniqueClientId") {
				this.LoadClientAddress();
			}
		});
	}

	LoadClientAddress = () => {
		const model = { ...this.state.model };
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetClientInfo",
			{ UniqueClientId: model.UniqueClientId },
			(responseData) => {
				if (responseData.IsSucceeded) {
					this.AddressHandle(responseData.Item);
				}
			},
			(error) => {
			}
		);
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1, cardList: [] });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/SearchNoNameCard",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		const model = { ...this.state.model };
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/CreateBulkNoNameCard",
			{
				ProductId: model.ProductId,
				NumberOfCard: model.NumberOfCard,
				CardSendAddress: {
					AddressLine1: model.AddressLine1,
					AddressLine2: model.AddressLine2,
					City: model.City,
					Province: model.Province,
					ZipCode: model.ZipCode,
					Country: model.Country
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/prepaidapi/v1.0/Card/Delete/Id?Id=" + this.state.model.Id,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.HandleSearch();
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.props.showMessage("success", "Succeeded", "Deleted successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	GetCardList = (Id) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetCardSummaryList",
			{
				CardOrderId: Id
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ cardList: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select");

		vModel.ProductId = model.ProductId == null;
		if (vModel.ProductId) messages.push("Product must be select");

		vModel.NumberOfCard = model.NumberOfCard == null;
		if (vModel.NumberOfCard) messages.push("Number of Card must be select");

		vModel.AddressLine1 = model.AddressLine1 == null;
		if (vModel.AddressLine1) messages.push("Address Line 1 must be select");

		vModel.ZipCode = model.ZipCode == null;
		if (vModel.ZipCode) messages.push("Zip Code must be select");

		vModel.City = model.City == null;
		if (vModel.City) messages.push("City must be select");

		vModel.Province = model.Province == null;
		if (vModel.Province) messages.push("Province must be select");

		vModel.Country = model.Country == null;
		if (vModel.Country) messages.push("Country must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		this.GetCardList(model.Id);
		this.setState({ isEdit: true, model, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = true;
		return this.parameterProduct;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { classes, Disabled, ReadOnly } = this.props;
		const { alert, model, vModel, list, cardList, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem md={12} xs={12}>
											<GridContainer>
												<GridItem md={3} xs={12}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Disabled={IsClient}
														DefaultIndex={IsClient ? 0 : -1}
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericSelectInput
														key={model.UniqueClientId}
														Name="ProductId"
														LabelText="Product"
														Method="POST"
														Url="/prepaidapi/v1.0/Product/Search"
														Parameter={this.GetProductParameter()}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ProductName"
														Value={model.ProductId}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.ProductId} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericNumberInput
														Name="NumberOfCard"
														LabelText="Number of Card"
														Value={model.NumberOfCard}
														ValueChanged={this.HandleChange}
														NoFormatting={true}
														MaxLength={3}
														Required
														IsInvalid={vModel.NumberOfCard} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericTextInput
														Name="AddressLine1"
														LabelText="Address Line 1"
														Id="street-address"
														Value={model.AddressLine1}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
														inputProps={this.maxLength100}
														Required
														IsInvalid={vModel.AddressLine1} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericTextInput
														Name="AddressLine2"
														Id="street-address2"
														LabelText="Address Line 2"
														Value={model.AddressLine2}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
														inputProps={this.maxLength100} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericTextInput
														Name="ZipCode"
														LabelText="Postal Code / Zip Code"
														Id="postcode"
														Value={model.ZipCode}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
														inputProps={this.maxLength20}
														Required
														IsInvalid={vModel.ZipCode} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericTextInput
														Name="City"
														LabelText="City"
														Id="city"
														Value={model.City}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
														inputProps={this.maxLength30}
														Required
														IsInvalid={vModel.City} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericTextInput
														Name="Province"
														LabelText="Province / State"
														Id="state"
														Value={model.Province}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
														inputProps={this.maxLength30}
														Required
														IsInvalid={vModel.Province} />
												</GridItem>
												<GridItem md={3} xs={12}>
													<GenericTextInput
														Name="Country"
														Id="country"
														LabelText="Country"
														Value={model.Country}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
														inputProps={this.maxLength30}
														Required
														IsInvalid={vModel.Country} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Card Order List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index} 
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Card List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={cardList}
											Columns={this.ColumnsCardList} 
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</ >
		);
	}
}

PrePersonCardOrder.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(PrePersonCardOrder, {});