import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import { DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericCheckInput, GenericGrid, GenericLabel, GenericNumberInput, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class Input extends React.Component {
	constructor(props) {
		super(props);

		this.GetData = this.GetData.bind(this);
	}

	GetData() {
		const { model } = this.props;

		var data = [];
		if (model.Parameter) {
			for (let obj of JSON.parse(`[${JSON.stringify(model.Parameter, null, "")}]`)) {
				Object.entries(obj).forEach(entry => {
					data.push(
						{
							Key: typeof entry[1] === "object" ? entry[0] : "",
							Name: typeof entry[1] === "object" ? Object.keys(entry[1])[0] : entry[0],
							Value: typeof entry[1] === "object" ? Object.values(entry[1])[0] : entry[1],
							actions: (
								<div>
									<Button
										color='primary'
										justIcon simple
										size="sm"
										tooltip="Delete"
										onClick={() => {
											this.props.handleDeleteParam(entry);
										}}>
										<img alt="Delete" src={DeleteIcon} />
									</Button>
								</div>
							)
						}
					);
				});
			}
		}
		return data;
	}

	render() {
		const { classes, jsonModel, model, paramModel, Disabled } = this.props;

		return (
			<div>
				<GridContainer>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={() => {
								jsonModel.Inputs.findIndex(x => x.Name == model.Name) > -1 ? this.props.handleUpdate() : this.props.handleAdd();
							}}
							fullWidth
							disabled={!model || !model.InputType || !model.Name}>
							{jsonModel.Inputs.findIndex(x => x.Name == model.Name) > -1 ? "Update" : "Add"}
						</Button>
					</GridItem>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleDelete}
							fullWidth
							disabled={!model || !model.InputType || !model.Name}>
							Delete
						</Button>
					</GridItem>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleClear}
							fullWidth
							disabled={!model || !model.InputType || !model.Name}>
							Clear
						</Button>
					</GridItem>
					{((model.InputType == "select" || model.InputType == "radio") && model.Method === "POST") &&
						<GridItem xs={1}>
							<Button
								color='black'
								size="sm"
								onClick={this.props.handleAddParam}
								fullWidth
								disabled={!paramModel || !paramModel.Name || !paramModel.Value}>
								Add Parameter
							</Button>
						</GridItem>
					}
				</GridContainer>
				<GridContainer>
					<GridItem xs={3}>
						<GenericSelectInput
							Name="InputType"
							LabelText="Input Type"
							IsStatic={true}
							StaticData={[{ Value: "text", Text: "Text" }, { Value: "number", Text: "Number" }, { Value: "select", Text: "Select" }, { Value: "date", Text: "Date" }, { Value: "datefilter", Text: "Date Filter" }, { Value: "checkbox", Text: "Checkbox" }, { Value: "radio", Text: "Radio" }, { Value: "client", Text: "Client" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={model.InputType}
							ValueChanged={this.props.handleChange}
						/>
						<GenericTextInput
							Name="Name"
							LabelText="Name"
							Value={model.Name}
							ValueChanged={this.props.handleChange} />
						<GenericTextInput
							Name="LabelText"
							LabelText="Label Text"
							Value={model.LabelText}
							ValueChanged={this.props.handleChange} />

						{model.InputType == "text" &&
							<GridContainer>
								<GridItem xs={4} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="MultiLine"
										LabelText="Multiline"
										Value={model.MultiLine}
										ValueChanged={this.props.handleBooleanChange} />
								</GridItem>
								{model.MultiLine &&
									<GridItem xs={8}>
										<GenericNumberInput
											Name="RowCount"
											LabelText="Row Count"
											Value={model.RowCount}
											ValueChanged={this.props.handleChange}
											MaxLength={6}
											NoFormatting={true}
											ThousandSeparator="" />
									</GridItem>
								}
							</GridContainer>
						}
						{model.InputType == "number" &&
							<div>
								<div style={{ marginTop: 4, marginLeft: 4, marginBottom: -16 }}>
									<GenericCheckInput
										Name="NoFormatting"
										LabelText="No Formatting"
										Value={model.NoFormatting}
										ValueChanged={this.props.handleBooleanChange} />
								</div>
								<GenericTextInput
									Name="ThousandSeparator"
									LabelText="Thousand Separator"
									Value={model.ThousandSeparator}
									ValueChanged={this.props.handleChange} />
								<GenericTextInput
									Name="Prefix"
									LabelText="Prefix"
									Value={model.Prefix}
									ValueChanged={this.props.handleChange} />
								<GenericTextInput
									Name="Suffix"
									LabelText="Suffix"
									Value={model.Suffix}
									ValueChanged={this.props.handleChange} />
							</div>
						}
						<div style={{ margin: 4 }}>
							<GenericCheckInput
								Name="IsRequired"
								LabelText="Is Required"
								Value={model.IsRequired}
								ValueChanged={this.props.handleBooleanChange} />
							<GenericCheckInput
								Name="IsHidden"
								LabelText="Is Hidden"
								Value={model.IsHidden}
								ValueChanged={this.props.handleBooleanChange} />
							<GenericCheckInput
								Name="Disabled"
								LabelText="Disabled"
								Value={model.Disabled}
								ValueChanged={this.props.handleBooleanChange} />
						</div>
					</GridItem>
					<GridItem xs={3}>
						{(model.InputType == "select" || model.InputType == "radio" || model.InputType == "filter") &&
							<div>
								<GenericRadioInput
									Name="Method"
									LabelText="Method"
									IsStatic={true}
									StaticData={[{ Value: "GET", Text: "GET" }, { Value: "POST", Text: "POST" }]}
									KeyValueMember="Value"
									TextValueMember="Text"
									Value={model.Method}
									ValueChanged={this.props.handleChange} />

								<div style={{ marginTop: -12 }}>
									<GenericTextInput
										Name="Url"
										LabelText="Url"
										Value={model.Url}
										ValueChanged={this.props.handleChange} />

									{model.InputType == "filter" &&
										<GenericTextInput
											Name="FilterParameter"
											LabelText="Filter Input Name"
											Value={model.FilterParameter}
											ValueChanged={this.props.handleChange} />
									}
									<GenericTextInput
										Name="DataRoot"
										LabelText="Data Root"
										Value={model.DataRoot}
										ValueChanged={this.props.handleChange} />
									<GenericTextInput
										Name="KeyValueMember"
										LabelText="Key Value Member"
										Value={model.KeyValueMember}
										ValueChanged={this.props.handleChange} />
									<GenericTextInput
										Name="TextValueMember"
										LabelText="Text Value Member"
										Value={model.TextValueMember}
										ValueChanged={this.props.handleChange} />
									<GenericNumberInput
										Name="DefaultIndex"
										LabelText="Default Index"
										Value={model.DefaultIndex}
										ValueChanged={this.props.handleChange}
										MaxLength={2}
										NoFormatting={true}
										ThousandSeparator="" />
								</div>
							</div>
						}
						{model.InputType == "date" &&
							<div style={{ marginTop: -2 }}>
								<GenericTextInput
									Name="MaxDate"
									LabelText="Max Date Input"
									Value={model.MaxDate}
									ValueChanged={this.props.handleChange} />
								<GenericTextInput
									Name="MinDate"
									LabelText="Min Date Input"
									Value={model.MinDate}
									ValueChanged={this.props.handleChange} />
								<div style={{ margin: 4 }}>
									<GenericCheckInput
										Name="IncludeTime"
										LabelText="Include Time"
										Value={model.IncludeTime}
										ValueChanged={this.props.handleBooleanChange} />
									<GenericCheckInput
										Name="IsPast"
										LabelText="Is Past"
										Value={model.IsPast}
										ValueChanged={this.props.handleBooleanChange}
										Disabled={model.IsFuture} />
									<GenericCheckInput
										Name="IsFuture"
										LabelText="Is Future"
										Value={model.IsFuture}
										ValueChanged={this.props.handleBooleanChange}
										Disabled={model.IsPast} />
								</div>
							</div>
						}
					</GridItem>
					{((model.InputType == "select" || model.InputType == "radio") && model.Method === "POST") &&
						<GridItem xs={6}>
							<GridContainer>
								<GridItem xs={12}>
									<div style={{ marginTop: 4, marginLeft: 4, marginBottom: -16 }}>
										<GenericCheckInput
											Name="Inner"
											LabelText="Inner Parameter"
											Value={paramModel.Inner}
											ValueChanged={this.props.handleBooleanChangeParam} />
									</div>
								</GridItem>
								{paramModel.Inner &&
									<GridItem xs={4}>
										<GenericTextInput
											Name="Key"
											LabelText="Key"
											Value={paramModel.Key}
											ValueChanged={this.props.handleChangeParam} />
									</GridItem>
								}
								<GridItem xs={4}>
									<GenericTextInput
										Name="Name"
										LabelText="Name"
										Value={paramModel.Name}
										ValueChanged={this.props.handleChangeParam} />
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Name="Value"
										LabelText="Value"
										Value={paramModel.Value}
										ValueChanged={this.props.handleChangeParam} />
								</GridItem>
								<GridItem xs={12}>
									<GenericLabel Bold={true} FontSize="12px" TextColor="black" Text="Parameter List"></GenericLabel>
									<GenericGrid
										Data={this.GetData()}
										Columns={[
											{
												Header: "Name",
												accessor: "Name"
											},
											{
												Header: "Value",
												accessor: "Value"
											},
											{
												Header: "Actions",
												accessor: "actions",
												resizable: false,
												width: 75
											}
										]}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					}
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						{jsonModel.Inputs &&
							<GenericLabel Bold={true} FontSize="12px" TextColor="black" Text="Input List"></GenericLabel>
						}
						{jsonModel.Inputs &&
							<GenericGrid
								key={jsonModel.Inputs.length}
								Data={jsonModel.Inputs}
								Columns={[
									{
										Header: "Input Type",
										accessor: "InputType"
									},
									{
										Header: "Name",
										accessor: "Name"
									},
									{
										Header: "Label Text",
										accessor: "LabelText"
									},
									{
										Header: "Required",
										accessor: "IsRequired",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 100
									},
									{
										Header: "Hidden",
										accessor: "IsHidden",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 100
									},
									{
										Header: "Disabled",
										accessor: "Disabled",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 100
									}
								]}
								RowSelected={index => {
									var data = jsonModel.Inputs[index];
									this.props.handleSelectInput(data);
								}}
							/>
						}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Input.propTypes = {
	classes: PropTypes.object.isRequired,
	jsonModel: PropTypes.object,
	model: PropTypes.object,
	paramModel: PropTypes.object,
	handleChange: PropTypes.func,
	handleBooleanChange: PropTypes.func,
	handleAdd: PropTypes.func,
	handleUpdate: PropTypes.func,
	handleDelete: PropTypes.func,
	handleClear: PropTypes.func,
	handleChangeParam: PropTypes.func,
	handleBooleanChangeParam: PropTypes.func,
	handleAddParam: PropTypes.func,
	handleDeleteParam: PropTypes.func,
	handleSelectInput: PropTypes.func
};

export default withArtifex(Input, {});
