import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Proxy } from "core";

import cardsDetailStyle from "../../assets/jss/material-dashboard-pro-react/views/cardDetailStyle.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import { MenuItem, Select } from "@material-ui/core";

class PhoneInputComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paramCountryCodeList: [],
			paramAreaCodeList: []
		}
	}
	componentDidMount() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "PhoneCountryCode",
				ParameterValue: "",
				ParameterDesc: ""
			},
			this.countryCodeCallBack
		);

		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "PhoneAreaCode",
				ParameterValue: "",
				ParameterDesc: ""
			},
			this.areaCodeCallBack
		);
	}

	countryCodeCallBack = responseData => {
		let tempParamCountryCodeList = responseData.ParameterList.map(elem => {
			return { Id: elem.Id, Text: elem.ParameterValue };
		});
		this.setState({
			paramCountryCodeList: tempParamCountryCodeList
		});

		if (tempParamCountryCodeList.length > 0) {
			var value = tempParamCountryCodeList[0].Id;

			if (this.props.valueCountryCode != null && tempParamCountryCodeList.filter(d => d.Id == this.props.valueCountryCode).length > 0)
				value = this.props.valueCountryCode;

			if (this.props.onChange != null)
				this.props.onChange(value, this.props.valueAreaCode, this.props.valuePhoneNumber);
		}
	}
	areaCodeCallBack = responseData => {
		let tempParamAreaCodeList = responseData.ParameterList.map(elem => {
			return { Id: elem.Id, Text: elem.ParameterValue, CountryCode: elem.ParameterValue2 };
		});

		this.setState({
			paramAreaCodeList: tempParamAreaCodeList
		});

		if (tempParamAreaCodeList.length > 0) {
			var value1 = tempParamAreaCodeList[0].Id;

			if (this.props.valueAreaCode != null && tempParamAreaCodeList.filter(d => d.Id == this.props.valueAreaCode).length > 0)
				value1 = this.props.valueAreaCode;

			if (this.props.onChange != null)
				this.props.onChange(this.props.valueCountryCode, value1, this.props.valuePhoneNumber);
		}
	}

	render() {
		const { classes, inputProps } = this.props;
		const { paramCountryCodeList, paramAreaCodeList } = this.state;
		return (
			<GridContainer >
				<GridItem xs={3} sm={3} md={3}>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel
							htmlFor="phoneCountryCode-select"
							className={classes.selectLabel}
						></InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={this.props.valueCountryCode}
							onChange={(e) => this.props.onChange(
								this.props.valueWhichPhone,
								e.target.value,
								this.props.valueAreaCode,
								this.props.valuePhoneNumber)}
							inputProps={{
								name: "phoneCountryCode",
								id: "phoneCountryCode-select"
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem
								}}
							>
								Country Code
                            </MenuItem>
							{paramCountryCodeList.map(cou => (
								<MenuItem key={cou.Id} value={cou.Id}
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}>
									{cou.Text}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</GridItem>
				<GridItem xs={3} sm={3} md={3}>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel
							htmlFor="phoneAreaCode-select"
							className={classes.selectLabel}
						>
						</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={this.props.valueAreaCode}
							onChange={(e) => this.props.onChange(
								this.props.valueWhichPhone,
								this.props.valueCountryCode,
								e.target.value,
								this.props.valuePhoneNumber)}
							inputProps={{
								name: "phoneAreaCode",
								id: "phoneAreaCode-select"
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem
								}}
							>
								Area Code
							</MenuItem>
							{paramAreaCodeList.map(are => (
								<MenuItem key={are.Id} value={are.Id}
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}>
									{are.Text}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</GridItem>
				<GridItem xs={6} sm={6} md={6}>
					<CustomInput
						labelText={this.props.inputPlaceHolder}
						id="phoneNumber"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							...inputProps,
							name: "phoneNumber",
							value: this.props.valuePhoneNumber,
							type: "number",
							onChange:
								(e) => this.props.onChange(
									this.props.valueWhichPhone,
									this.props.valueCountryCode,
									this.props.valueAreaCode,
									e.target.value)
						}}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

PhoneInputComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	inputProps: PropTypes.object
};

export default withStyles(cardsDetailStyle)(PhoneInputComponent);
