import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-autocomplete-input/dist/bundle.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericTextInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

const initialState = {
	vModel: {},
	model: {},
	resultLetterTempList: [],
	isBrowse: false,
	isLoading: false,
	editorModelEmail: EditorState.createEmpty()

};
const url = "/lmsapi/v1.0/LoanNotificationTemplate/";

class LoanNotificationTemplateDefinition extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.fileInputRef = React.createRef();

		this.ParamLOSLoanType = {
			ParameterCode: "LOSLoanType"
		};
		this.ParamLOSLoanPurpose = {
			ParameterCode: "LOSLoanPurpose"
		};
		this.ParamLMSAutoCLCParamAction = {
			ParameterCode: "LMSAutoCLCParamAction"
		};
		this.sortedParameterDesc = { Member: "ParameterDesc" };
	}

	/**Begin Life cycle Methods */
	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Collection Notification Template Definition");
		this
			.props
			.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		this.setState({
			editorModelEmail: this.EmptyEditorContent()
		});

		this.GetAllDataModelProperty();
	}

	/**End life cycle Methods */

	/* Begin Standard  Methods  */

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false, state: initialState });
		window
			.location
			.reload();

	}
	HideAlert = () => {
		this.setState({ alert: null });
	}

	IsStringNullOrEmptyOrWhiteSpace = (value) => {

		return typeof value !== "string" || value == null || value.trim().length == 0;
	}
	/* End Standard  Methods  */

	/**  BEGIN Actions */
	OperationCheck = () => {
		var ErrorList = [];
		const data = this.state.model;
		const { vModel } = this.state;
		if (data == null) {
			ErrorList.push("Template parameters not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.LoanTypeId == null || data.LoanTypeId == 0) {
			vModel.LoanTypeId = true;
			ErrorList.push("Loan type must be selected.");
		}
		else {
			vModel.LoanTypeId = false;
		}
		if (data.LoanPurposeId == null || data.LoanPurposeId == 0) {
			vModel.LoanPurposeId = true;
			ErrorList.push("Purpose of the loan must be selected.");
		}
		else {
			vModel.LoanPurposeId = false;
		}
		if (data.AutoCollectionParamActionId == null || data.AutoCollectionParamActionId == 0) {
			vModel.AutoCollectionParamActionId = true;
			ErrorList.push("Action must be selected.");
		}
		else {
			vModel.AutoCollectionParamActionId = false;
		}
		if (this.IsStringNullOrEmptyOrWhiteSpace(data.Name)) {
			vModel.Name = true;
			ErrorList.push("Name must be entered.");
		}
		else {
			vModel.Name = false;
		}
		var letterContent = this.EditorContentToHtml(this.state.editorModelEmail);
		if (letterContent == null || letterContent == "") {
			ErrorList.push("Template content must be defined.");
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		var { model } = this.state;
		model.Content = letterContent;
		this.setState({ model });

		this.ActionSubmit(model);
		this.setState({ vModel });
	}
	ActionSubmit = (model) => {
		this.HideAlert();
		this.setState({ isLoading: true });
		this.Post(model, "SaveTemp");

	}
	ActionSearch = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		this.Post(data, "SearchTemp");

	}
	ActionDelete = () => {
		var ErrorList = [];
		this.HideAlert();
		const data = this.state.model;
		if (data == null || data.Id == null || data.Id == 0) {
			ErrorList.push("Any template not selected.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Select A Record From Templates List", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.Post(data, "DeleteTemp");
	}
	ActionClear = () => {
		this.setState({
			model: {},
			notificationTempList: [],
			alert: null,
			isLoading: false,
			editorModelEmail: this.EmptyEditorContent()
		});
	}

	GetAllDataModelProperty = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/coreapi/v1.0/NotificationTemplate/GetDataModelProperties",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					var temp = responseData.Item.map(x => {
						return { text: x.PropertyDescription, value: x.PropertyName };
					});
					this.setState({ propertyList: temp });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Post = (model, methodName) => {
		this.setState({ alert: null });
		this.setState({ isLoading: true });
		Proxy.POST((url + methodName), model, (responseData) => {
			switch (methodName) {
				case "SearchTemp":
					if (responseData != null && responseData.Item != null && responseData.Item.length > 0) {
						this.ShowMessage("success", "Success", responseData.Item.length + " Record(s) Listed");
						this.setState({ notificationTempList: responseData.Item, isLoading: false });
					}
					this.setState({ alert: null, isLoading: false });
					break;
				case "DeleteTemp":
					if (responseData != null && responseData.Item != null) {
						this.ShowMessage("success", "Success", "The Template Deleted");
						this.setState({ isLoading: false });
					}
					this.setState({ alert: null, isLoading: false, state: initialState });
					this.ActionSearch();
					break;
				default:
					if (responseData != null && responseData.Item != null) {
						this.ShowMessage("success", "Success", `The Templete ${!model.Id ? "Saved" : "Updated"}`);
						this.setState({
							model: {},
							isLoading: false,
							notificationTempList: [],
							editorModelEmail: this.EmptyEditorContent()
						});

					} else {
						this.setState({ alert: null, isLoading: false, state: initialState });
					}
					break;
			}

		}, this.ErrorCallback);
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}
	/* END Actions */

	/** Begin Events */
	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};
		model[name] = newValue;
		this.setState({ model });
	}
	RowSelected = (index) => {
		const list = this.state.notificationTempList;
		this.setState({
			model: list[index],
			editorModelEmail: this.HtmlToEditorContent(list[index].Content)
		});
	}



	/** End Events */

	/**Begin Editor Methods */

	EmptyEditorContent = () => {
		const html = "<p></p><p></p><p></p><p></p><p></p>";
		var contentBlock = htmlToDraft(html);
		var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);
		return editorState;
	}
	EditorContentToHtml = (model) => {
		return draftToHtml(convertToRaw(model.getCurrentContent()));
	}
	EditorContentToText = (model) => {
		var html = draftToHtml(convertToRaw(model.getCurrentContent()));
		var tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";

	}
	HtmlToEditorContent = (html) => {
		var contentBlock = htmlToDraft(html);
		var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);
		return editorState;
	}

	EditorStateChangeEmail = (obj) => {
		this.setState({ editorModelEmail: obj });
		this.EditorContentToText(this.state.editorModelEmail);
	}

	GetFileBase64 = (file, callback) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => callback(reader.result);
		reader.onerror = error => { };
	};

	ImageUploadCallback = file => new Promise(
		(resolve, reject) => this.GetFileBase64(
			file,
			data => resolve({ data: { link: data } })
		)
	);

	/**End Editor Methods */

	render() {
		const { model, alert, editorModelEmail, isLoading, propertyList, vModel } = this.state;

		var ForInsert = !model.Id || model.Id <= 0;
		return (

			<div>
				<LoadingComponent Show={isLoading} />
				<GridItem xs={12}>
					<ButtonToolbar
						ButtonList={[
							{
								Code: "Submit",
								OnClick: this.OperationCheck,
								Disabled: !ForInsert
							}, {
								Code: "Search",
								OnClick: this.ActionSearch,
								Disabled: false
							}, {
								Code: "Update",
								OnClick: this.OperationCheck,
								Disabled: ForInsert
							}, {
								Code: "Delete",
								OnClick: this.ActionDelete,
								Disabled: ForInsert
							}, {
								Code: "Clear",
								OnClick: this.ActionClear,
								Disabled: false
							}
						]}
						menuId={this.props.menuId}
						ApprovalData={this.props.ApprovalData}
						IsPopUp={false} />

				</GridItem>
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true} Title="Main Parameters">
									<GridContainer justify="flex-start" alignItems="flex-start" direction="row">
										<GridContainer>
											<GridItem xs={3}>
												<ParameterComponent
													Name="LoanTypeId"
													LabelText="Loan Type"
													LabelMd={3}
													ParameterCode={this.ParamLOSLoanType.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={model.LoanTypeId}
													ValueChanged={this.HandleChange}
													Disabled={false}
													Required
													IsInvalid={vModel.LoanTypeId} />
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="LoanPurposeId"
													LabelText="Purpose Of Loan"
													LabelMd={5}
													key={model.LoanTypeId + "LoanPurpose"}
													Method="POST" Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
													Parameter={{ LoanTypeId: model.LoanTypeId }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={this.sortedParameterDesc}
													Value={model.LoanPurposeId}
													ValueChanged={this.HandleChange}
													Disabled={false}
													Required
													IsInvalid={vModel.LoanPurposeId} />

											</GridItem>
											<GridItem xs={2}>
												<ParameterComponent
													Name="AutoCollectionParamActionId"
													LabelText="Action"
													LabelMd={4}
													ParameterCode={this.ParamLMSAutoCLCParamAction.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={model.AutoCollectionParamActionId}
													ValueChanged={this.HandleChange}
													Disabled={false}
													Required
													IsInvalid={vModel.AutoCollectionParamActionId}
												/>
											</GridItem>

											<GridItem xs={3}>
												<GenericTextInput
													Disabled={false}
													IsText
													={true}
													Name="Name"
													LabelText="Name"
													Value={model.Name || ""}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.Name}
												/>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>

						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Content">
									<GridItem xs={12}>
										<GridContainer>
											<GridItem
												xs={12}
												style={{
													float: "left",
													textAlign: "end"
												}}>
												<Button
													disabled={false}
													size="sm"
													onClick={() => {
														this.setState({
															editorModelEmail: this.EmptyEditorContent()
														});
													}}>
													Clear
                                                </Button>
												<input
													type="file"
													key={model.FileName}
													style={{
														display: "none"
													}}
													onChange={() => { }}
													ref={() => { }}
													accept="image/*" />
											</GridItem>
										</GridContainer>
										<br />
										<GridContainer>
											<GridItem xs={12}>

												<Editor
													editorState={editorModelEmail}
													toolbarClassName="toolbarClassName"
													wrapperClassName="wrapperClassName"
													editorClassName="editorClassName"
													onEditorStateChange={this.EditorStateChangeEmail}
													toolbar={{ image: { urlEnabled: false, uploadEnabled: true, uploadCallback: this.ImageUploadCallback.bind(this), previewImage: true } }}
													mention={{
														separator: " ",
														trigger: "@",
														suggestions: propertyList,
													}} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">

							<CardBody>
								<GenericExpansionPanel Title="Template List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.notificationTempList}
												Columns
												={[
													{
														Header: "Loan Type",
														accessor: "LoanType.ParameterDesc"
													}, {
														Header: "Purpose Of The Loan",
														accessor: "LoanPurpose.ParameterDesc"
													}, {
														Header: "Action",
														accessor: "AutoCollectionParamAction.ParameterDesc"
													}, {
														Header: "Name",
														accessor: "Name"
													}
												]}
												RowSelected={this.RowSelected} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>
			</div>
		);
	}
}



LoanNotificationTemplateDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(LoanNotificationTemplateDefinition, {});