import DashboardHelper from "core/DashboardHelper";

class MenuAuthorityRightHelper {
	constructor() {
		this.dashboardHelper = new DashboardHelper();
		this.ExecuteApiPost = this.dashboardHelper.ExecuteApiPost;
	}

	/**
	 * @param {string} menuCode
	 * @param {string} menuAuthorityDefinitionCode
	 * @returns {Promise<boolean>}
	 */
	async RightCheck(menuCode, menuAuthorityDefinitionCode) {
		var result = await this.ExecuteApiPost("/coreapi/v1.0/MenuAuthorityRight/GetRight", { MenuCode: menuCode, MenuAuthorityDefinitionCode: menuAuthorityDefinitionCode });

		return result;
	}

	/**
	 * @param {string} menuCode
	 * @param {Array<string>} menuAuthorityDefinitionCodes
	 * @returns {Promise<Array<{Code: string, HasAuthority: boolean}>>}
	 */
	async RightCheckList(menuCode, menuAuthorityDefinitionCodes) {
		var result = await this.ExecuteApiPost("/coreapi/v1.0/MenuAuthorityRight/ListRight", { MenuCode: menuCode, MenuAuthorityDefinitionCodes: menuAuthorityDefinitionCodes });

		return result;
	}
}

export default MenuAuthorityRightHelper;