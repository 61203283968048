/**
 * Convert Base64 URL to File object
 * @param {string} dataurl ex: "data:file/png;base64,......"
 * @param {string} filename
 * @returns {File}
 */
function DataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

/**
 * Convert blob raw data to base64 string
 * @param {Blob} blob
 * @returns {Promise<string>} use with await keyword
 */
function BlobToBase64(blob) {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result.toString();
      resolve(base64data);
    };
  });
}

/**
 * Removes base64 mime tag and get raw base64 string 'data:?/?;base64,....'
 * @param {string} base64
 */
function RemoveBase64MimeTag(base64) {
  return base64.split(",").pop();
}

const FileHelper = {
  DataURLtoFile,
  BlobToBase64,
  RemoveBase64MimeTag,
};

export default FileHelper;
