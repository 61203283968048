import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import ExternalContainer from "layouts/ExternalContainer";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import ClientHelper from "core/ClientHelper";
import { GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

import { CrudType, FileType } from "views/Constants/Constant";
import FileHelper from "core/FileHelper";
import ImageHelper from "core/ImageHelper";
import GenericCardMedia from "views/Components/Generic/GenericCardMedia.jsx";

class CardDesign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			fileInfoModel: {},
			model: {
				UniqueClientId: ClientHelper.IsClient()
					? ClientHelper.GetClientRowId()
					: undefined,
				image: ""
			},
			isOpenkycUploadWindow: null,
			data: [],
			isLoading: false,
			rowIndex: -1,
			modelList: [],
			photoBase64: null,
			openDialog: false,
			cameraLabel: null,
			imgSrc: null
		};
		this.ParameterCardBrand = { ParameterCode: "Brand" };
		this.ParameterCardChipType = { ParameterCode: "CardChipType" };
		this.ParameterCardContactless = { ParameterCode: "CardContactlessColorType" };
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.fileInputRef1 = React.createRef();
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Card Design Tool");
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Card Design");
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	render() {
			const {model } = this.state;
		return (
			<div>
				<GridContainer>
					<GridItem>
						<GenericSelectInput
							Name="UniqueClientId"
							LabelText="Client"
							Method="POST"
							Url="/bankapi/v1.0/BankCustomer/Search"
							Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
							DataRoot="Item"
							KeyValueMember="Id"
							RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
							Value={model.UniqueClientId || ""}
							ValueChanged={this.HandleChange}
							All
							CanClear
							Disabled={!this.isBackOffice}
							DefaultIndex={ClientHelper.IsClient() ? 0 : -1} />
					</GridItem>
				</GridContainer>
			</div >
		);
	}

}

CardDesign.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default (CardDesign);