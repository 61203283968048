import { DialogContent} from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/views/createWireTransferStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import { PrintIcon } from "core/Icons.js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericDialog, GenericDialogActions } from "views/Components/Generic";
import BeneficiaryAmountDetail from "./BeneficiaryAmountDetail.jsx";
import BeneficiaryDetail from "./BeneficiaryDetail.jsx";
class SaveConfirmDialog extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	render() {
		const { Disabled, Model, Template, ValueChanged, HandleConfirm, HandleCancel } = this.props;
		return (
			<GenericDialog open={true} maxWidth="md" onBackdropClick={HandleCancel}>
				<DialogContent>
					<GridContainer>
						<GridItem xs={12}>
							<h4 style={{ color: "black", textAlign: "center" }}>	<b>Transaction Details</b>	</h4>
						</GridItem>
						<GridItem xs={12}>
							<BeneficiaryDetail
								ValueChanged={ValueChanged}
								CompanyName={Template.CompanyName}
								Name={Template.Name}
								MiddleName={Template.MiddleName}
								LastName={Template.LastName}
								AddressLine1={Template.AddressLine1}
								AddressLine2={Template.AddressLine2}
								ZipCode={Template.ZipCode}
								CityId={Template.CityId}
								ProvinceId={Template.ProvinceId}
								CountryId={Template.CountryId}
								IsDomestic={Template.IsDomestic}
								BankCountryId={Template.BankCountryId}
								BankFinInsId={Template.BankFinInsId}
								BankName={Template.BankName}
								BankBranchId={Template.BankBranchId}
								BankTransitNumber={Template.BankTransitNumber}
								AccountNumber={Template.AccountNumber}
								BankSwiftCode={Template.BankSwiftCode}
								BankAddressLine1={Template.BankAddressLine1}
								BankAddressLine2={Template.BankAddressLine2}
								BankZipCode={Template.BankZipCode}
								BankCityId={Template.BankCityId}
								BankProvinceId={Template.BankProvinceId}
								BankInstitution={Template.BankInstitution}
								Alias={Template.Alias}
								Disabled={Disabled}
								PhoneNumber = {Template.PhoneNumber} />
						</GridItem>
						<GridItem xs={1} />
						<GridItem xs={10}>
							<BeneficiaryAmountDetail
								PurposeOfWire={Model.PurposeOfWire}
								CurrencyId={Template.CurrencyId}
								Amount={Model.Amount}
								DateRequest={Model.DateRequest}
								ValueEffective={Model.ValueEffective}
								Disabled={true}
								AmountPrefix={Model.AmountPrefix}>
							</BeneficiaryAmountDetail>
						</GridItem>
						<GridItem xs={6}>
							<img src={PrintIcon} />
							<GenericCheckInput
								Name="DownloadReceiptPDF"
								LabelText={"Download PDF File"}
								IsTextLeft
								Value={Model.DownloadReceiptPDF}
								ValueChanged={ValueChanged} />
						</GridItem>
						<GridItem xs={1} />
					</GridContainer>
				</DialogContent>
				<GenericDialogActions>
					<Button size="sm" onClick={HandleConfirm}>Confirm</Button>
					<Button size="sm" onClick={HandleCancel}>Cancel</Button>
				</GenericDialogActions>
			</GenericDialog>
		);
	}
}

SaveConfirmDialog.propTypes = {
	Model: PropTypes.object.isRequired,
	Template: PropTypes.object.isRequired,
	classes: PropTypes.object,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	HandleConfirm: PropTypes.func,
	HandleCancel: PropTypes.func
};

export default withArtifex(SaveConfirmDialog, style);