import { warningColor } from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const style = {
	iconbuttoncolor: {
		color: warningColor
	},
	tabsHeader: {
		background: warningColor
	},
	root: {
		flexGrow: 1,
	},
	...customSelectStyle,
	...sweetAlertStyle,
	...customCheckboxRadioSwitch,
	...warningColor,
	bankingTitle:{
		color: "#2e333a",
		fontSize: "1.25em !important",
		lineHeight: "1.428571429",
		fontWeight: "400",
		textAlign: "center",
		float: "center",
		background: "#d3d3d3",
		borderRadius: 4,
		borderRadiusInputTopLeft: 7,
		borderRadiusInputTopRight: 7,
		borderRadiusInputBottomLeft: 7,
		borderRadiusInputBottomRight: 7,
		borderColor: "#707070"
	},
	textStyle :{
		textAlign : "center"
	}
};

export default style;