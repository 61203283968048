
import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericGrid, GenericLabel, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction, GridColumnType } from "views/Constants/Constant.js";
class RbcOutgoingResponseReport extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			},
			getTransactionByFileIdModel: {
			},
			dailyFileList: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			isLoading: false,
			errorList: []
		};

		this.ColumnsdailyFileList = [
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File Date",
				accessor: "FileDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "Send Status",
				accessor: "SendStatus"
			},
			{
				Header: "File Creation Number",
				accessor: "FileCreationNumber"
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "Reject Count",
				accessor: "RejectedCount"
			}
		];

		this.ColumnserrorFileList = [
			{
				Header: "Client Number",
				accessor: "ClientNumber",
			},
			{
				Header: "Error Detail Record Type",
				accessor: "ErrorDetailRecordType",
			},
			{
				Header: "Service Types",
				accessor: "ServiceTypes",
			},
			{
				Header: "File Creation Number",
				accessor: "FileCreationNumber",
			},

			{
				Header: "Payment Type",
				accessor: "PaymentType",
			},
			{
				Header: "Error Code",
				accessor: "ErrorCode",
			},
			{
				Header: "Error Message",
				accessor: "ErrorMessage",
			},

		];

		this.ColumnstransactionList = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "RecordType",
				accessor: "RecordType",
				Cell: row => (
					<div>{row.value == "1" ? "Debit detail record" : row.value == "2" ? "Credit detail record" : ""}</div>
				)
			},
			{
				Header: "ClientNumber",
				accessor: "ClientNumber"
			},
			{
				Header: "Service Types",
				accessor: "ServiceTypes",
				Cell: row => (
					<div>{row.value == "001" ? "PAP CAD" : row.value == "002" ? "PAP USD" : row.value == "003" ? "PDS CAD" : row.value == "004" ? "PDS USD" : row.value == "005" ? "PC-PAP-CAD" : row.value == "006" ? "PC-PAP-USD" : row.value == "007" ? "PC-PDS-CAD" : row.value == "008" ? "PC-PDS-USD" : row.value == "009" ? "CPS-CAD" : row.value == "010" ? " CPS-USD" : ""}</div>
				)
			},
			{
				Header: "FileCreationNumber",
				accessor: "FileCreationNumber"
			},
			{
				Header: "PaymentType",
				accessor: "PaymentType",
				Cell: row => (
					<div>{row.value == "C" ? "CREDIT" : row.value == "D" ? "DEBIT" : ""}</div>
				)
			},
			{
				Header: "DetailRecordType",
				accessor: "DetailRecordType"

			},
			{
				Header: "CustomerNumber",
				accessor: "CustomerNumber"
			},
			{
				Header: "CustomerName",
				accessor: "CustomerName"
			},
			{
				Header: "OriginalDueDate",
				accessor: "OriginalDueDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "PaymentNumber",
				accessor: "PaymentNumber"
			},
			{
				Header: "FinancialInstitutionNumber",
				accessor: "FinancialInstitutionNumber"
			},
			{
				Header: "BranchNumber",
				accessor: "BranchNumber"
			},
			{
				Header: "WalletNumber",
				accessor: "AccountNumber"
			},
			{
				Header: "Currency",
				accessor: "Currency",
				Cell: row => (
					<div>{row.value == "CAD" ? "Canadian $" : row.value == "USD" ? "United States $" : ""}</div>
				)
			},
			{
				Header: "Destination Country",
				accessor: "DestinationCountry",
				Cell: row => (
					<div>{row.value == "CAN" ? "Canada" : row.value == "USA" ? "United States" : ""}</div>
				)

			},
			{
				Header: "PaymentAmount",
				accessor: "PaymentAmount",
				type: GridColumnType.Money
			},
			{
				Header: "ElectronicMessage",
				accessor: "ElectronicMessage"
			},
			{
				Header: "PaymentStatus",
				accessor: "PaymentStatus",
				Cell: row => (
					<div>{row.value == " " ? "Valid Payment" : row.value == "E" ? "Reversed Payment/Record" : row.value == "W" ? "Warnings" : row.value == "T" ? "T level errors" : row.value == "R" ? "Rejected Payment/Record" : row.value == "U" ? " Returned Payment/Record" : row.value == "I" ? " In Excess Payments" : row.value == "P" ? "Represented Item" : ""}</div>
				)
			},
			{
				Header: "MatchStatus",
				accessor: "MatchStatus"
			},
			{
				Header: "Error Description",
				accessor: "ErrorCode",
				Cell: row => (
					<div>
						<GridButton
							tooltip={row.value ? "Error" : ""}
							Disabled={row.value == null || row.value == "" ? true : false}
							Icon={DetailIcon}
							OnClick={row.value == null || row.value == "" ? () => { } : () => { this.HandleChangeActiveStatus(row.original); }} />

					</div>
				)
			},
		];
	}


	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) {
			setAppLeftTitle("Rbc Outgoing Response Report");
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("CLEARING & SETTLEMENT");
		}
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	GetDailyFileList = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var model = { ...this.state.model };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/GetEFTRbcOutgoingResponseReportFileList", model, null, null, null, null, null);
		if (responseData != null) {
			this.setState({
				transactionList: [], headerModel: {},
				dailyFileList:responseData.length < 1 ? [] : responseData, isLoading: false
			});
		}
	}
	GetTransactionList = async (Id) => {
		const { ExecuteApiPost } = this.props;
		var headerModel = { ...this.state.headerModel };
		var transactionList = { ...this.state.transactionList };
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel = { ...this.state.model };
		getTransactionByFileIdModel.FileId = Id;
		getTransactionByFileIdModel.UniqueClientId = this.state.model.UniqueClientId == null ? null : this.state.model.UniqueClientId;
		var responseData = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/GetEFTRbcClearingFileDetailsReportByFileId", getTransactionByFileIdModel, null, null, null, null, null);
		if (responseData != null) {
			if (responseData.RbcClearingReportFileHeaderDetail != null) {
				headerModel = responseData.RbcClearingReportFileHeaderDetail == null ? {} : responseData.RbcClearingReportFileHeaderDetail;
				headerModel.FileDate = this.state.fileInfoModel.FileDate;
			}
			else
				headerModel = {};

			if (responseData.EftRbcClearingReportFileDetail != null || responseData.EftRbcClearingReportFileDetail.length > 1) {
				transactionList = responseData.EftRbcClearingReportFileDetail;
			}
			else {
				transactionList = [];
			}
		}
		this.setState({ transactionList, headerModel, isLoading: false });
	}
	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.props.showMessage("warning", "File Information", "FileInformationId can not be null");
		else {
			model.UniqueClientId = tempModel.UniqueClientId;
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetTransactionList(tempModel.Id);

		}
	}

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDateTime(), ToDate: DateHelper.GetDateTime(),
			}
		});
	}

	HandleChangeActiveStatus = (model) => {
		this.setState({ openDialog: true, errorList: [model] });
	}
	DialogClose = () => {
		this.setState({ openDialog: false });
	}
	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading, openDialog, errorList } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<div>
					<GenericDialog open={openDialog} fullScreen={false} maxWidth="md" onBackdropClick={this.DialogClose} fullWidth>
						<DialogTitle>
							<GenericLabel Text="Error Information" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<GenericGrid
								Data={errorList}
								Columns={this.ColumnserrorFileList}
							/>
						</DialogContent>
					</GenericDialog>
				</div >
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={3}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" File Filter"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
								<GenericDateInput
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
							</CardBody>
						</Card>

						<br /> <br />
					</GridItem>
					<GridItem xs={9}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileDate"
											LabelMd={6}
											LabelText="File Date"
											Value={headerModel == null ? "" : headerModel.FileDate ? Formatter.FormatDateUtc(headerModel.FileDate) : ""}
											Disabled={true}
											DateFormat="DD/MMM/YY"
											Utc />
										<GenericNumberInput
											Name="TotalCreditNumber"
											LabelMd={6}
											LabelText="Total Number of Credit"
											Value={headerModel == null ? "" : headerModel.TotalNumbeCredit ? headerModel.TotalNumbeCredit : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelText="Total Number of Debit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalNumberDebit ? headerModel.TotalNumberDebit : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericTextInput
											Name="ClientNumber"
											LabelText="Client Number"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.ClientNumber ? headerModel.ClientNumber : ""}
											Disabled={true} />
									</GridItem>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileDate"
											LabelText="File Time"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.FileDate ? Formatter.FormatTimeUtc(headerModel.FileDate) : ""}
											DateFormat="hh:mm:ss A"
											IncludeTime={false}
											Disabled={true}
											Utc />
										<GenericNumberInput
											Name="TotalAmountCredit"
											LabelText="Total Amount of Credit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountCredit ? headerModel.TotalAmountCredit : ""}
											Prefix="$"
											Disabled={true} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelText="Total Amount of Debit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountDebit ? headerModel.TotalAmountDebit : ""}
											Prefix="$"
											Disabled={true} />

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Daily File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.dailyFileList}
									Columns={this.ColumnsdailyFileList}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex} />
								<br /> <br />
								<h5><b>Transaction List</b></h5> <br />
								<GenericGrid
									Data={this.state.transactionList}
									Columns={this.ColumnstransactionList}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >

		);
	}
}
RbcOutgoingResponseReport.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};
export default withArtifex(RbcOutgoingResponseReport, {});
