import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericNumberInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
class MccGroup extends Component {
  constructor(props) {
    super(props);
    this.initialModel = {
      Id: 0,
      GroupName: "",
    };
    this.state = {
      model: this.initialModel,
      vModel: {},
      list: [],
      isLoading: false,
    };
    this.emptyObject = {};
    this.maxLength50 = { maxLength: 50 };
    this.Columns = [
      {
        Header: "MCC Code",
        accessor: "MccCode",
      },
      {
        Header: "MCC Name",
        accessor: "MccName",
      },
      {
        Header: "Group Name",
        accessor: "GroupName",
      },
      {
        Header: "Group Id",
        accessor: "Id",
      },
      {
        Header: "Insert User",
        accessor: "InsertUser",
      },
      {
        Header: "Insert Date Time",
        accessor: "InsertDateTime",
        type: GridColumnType.DateTime,
        ColumnType: GridColumnType.DateTime,
      },
      {
        Header: "Update User",
        accessor: "UpdateUser",
      },
      {
        Header: "Update Date Time",
        accessor: "UpdateDateTime",
        type: GridColumnType.DateTime,
        ColumnType: GridColumnType.DateTime,
      },
    ];
  }
  componentDidMount() {
    this.props.setAppLeftTitle("MCC Group");
    this.props.setAppCenterTitle("CARD MCC MANAGEMENT");
  }
  handleChange = (name, newValue) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };
  handleClear = () => {
    this.setState({
      model: {
        ...this.initialModel,
      },
      vModel: {},
      isEdit: false,
      index: -1,
    });
  };
  handleSearch = () => {
    this.setState({
      isLoading: true,
    });
    Proxy.POST(
      "/prepaidapi/v1.0/Card/GetMccGroup",
      this.state.model,
      (responseData) => {
        this.setState({
          isLoading: false,
        });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({
            list: responseData.Item,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  handleSubmit = () => {
    if (!this.validate(true)) {
      return;
    }
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/Card/InsertMccGroup",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
          this.handleClear();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  handleUpdate = () => {
    if (!this.validate(false)) {
      return;
    }
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/Card/UpdateMccGroupId",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
			 this.state.model.MccCode = responseData.Item.MccCode;
          this.handleSearch();
          this.handleClear();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  validate = (isSubmit) => {
    const { model, vModel } = this.state;
    var errorList = [];
    const { showValidationErrors } = this.props;
    if (isSubmit) {
      vModel.NewGroupName =
        model.NewGroupName == null ||
        model.NewGroupName == "" ||
        model.NewGroupName == undefined;
      if (vModel.NewGroupName) {
        errorList.push("New Group Name cannot be empty.");
      }
    } else {
      vModel.MccCode =
        model.MccCode == null ||
        model.MccCode == "" ||
        model.MccCode == undefined;
      if (vModel.MccCode) {
        errorList.push("Mcc Code cannot be empty.");
      }
      vModel.Id = model.Id == null || model.Id == 0 || model.Id == undefined;
      if (vModel.Id) {
        errorList.push("Id cannot be empty.");
      }
    }
    if (errorList.length > 0) {
      showValidationErrors(errorList);
      return false;
    }
    return true;
  };
  rowSelected = (index) => {
    let { list } = this.state;
    const tempModel = list[index];
    if (list[index].CardRequestId) {
      tempModel.Id = list[index].Id;
    }
    this.setState({
      isEdit: true,
      model: tempModel,
      index,
    });
  };
  render() {
    const { Disabled } = this.props;
    const { alert, model, list, isLoading, index, vModel } = this.state;
    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              OnClick: this.handleSearch,
              Disabled: Disabled,
            },
            { Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
            { Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled || !model.NewGroupName},
            {
              Code: "Update",
              OnClick: this.handleUpdate,
              Disabled: Disabled || model.Id === 0 || !model.MccCode,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Name="Id"
                            LabelText="Group Name"
                            Method="POST"
                            Url="/prepaidapi/v1.0/Card/GetMccGroup"
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="GroupName"
                            Value={model.Id}
                            ValueChanged={this.handleChange}
                            Required
                            IsInvalid={vModel.Id}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericNumberInput
                            Name="MccCode"
                            LabelText="MCC Code"
                            NoFormatting={true}
                            MaxLength={4}
                            Value={model.MccCode}
                            ValueChanged={this.handleChange}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Add New Group">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericTextInput
                            Name="NewGroupName"
                            LabelText="New Group Name"
                            inputProps={this.maxLength50}
                            Value={model.NewGroupName}
                            ValueChanged={this.handleChange}
                            Disabled={false}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"MCC Group List"} />
              </CardHeader>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GenericGrid
                      Data={list}
                      Columns={this.Columns}
                      RowSelected={this.rowSelected}
                      SelectedIndex={index}
                      PageSize={10}
                      ShowPagination={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
MccGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
};
export default withArtifex(MccGroup, {});