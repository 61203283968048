const genericDateInputStyle = {
	root: {
		border: '1px solid #e9eaec',
		"&:before": {
			border: 'unset !important'
		},
		"&:after": {
			transition: 'unset',
			height: 22,
			border: '1px solid gray !important',
		}
	},
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#888888",
		fontSize: "16px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex",
		transition: "0.3s ease all",		
	}
};

export default genericDateInputStyle;