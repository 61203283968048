import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DetailIcon, EditIcon, DocumentIcon } from "core/Icons";
import PropTypes, { bool, func } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericPage, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction, GridColumnType, ProgramCodes, FinScanSearchResultType } from "views/Constants/Constant";
import BeneficiaryDetail from "views/WireTransfer/CreateWireTransfer/BeneficiaryDetail";

const emptyObject = {};

function Transition(props) {
	return <Slide direction="up" {...props} />;
}
class OutgoingWireTransfers extends GenericPage {

	constructor(props) {
		super(props);
		this.IsBackOffice = ClientHelper.IsBackOffice();
		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName",
			},
			{
				Header: "Organization",
				accessor: "OrganizationUniqueName",
				show: this.IsBackOffice
			},
			{
				Header: "Swift Reference Number",
				accessor: "SwiftReferenceNumber",
			},
			{
				Header: "Transaction Reference Number",
				accessor: "TransactionReferenceNumber",
			},
			{
				Header: "Client User",
				accessor: "InsertUser",
			},
			{
				Header: "Source Wallet Number",
				accessor: "FromAccAccountNumber"
			},
			{
				Header: "To Wallet Number",
				accessor: "ToAccountNumber"
			},
			{
				Header: "To IBAN",
				accessor: "ToIBAN"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "SubStatus",
				accessor: "SubStatus",
				show: this.IsBackOffice
			},
			{
				Header: "Channel",
				accessor: "Channel",
				show: this.IsBackOffice
			},
			{
				Header: "Is Captured",
				accessor: "IsCaptured",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "MessageType",
				accessor: "MessageType"
			},
			{
				Header: "Date of Request",
				accessor: "DateRequest"
			},
			// {
			// 	Header: "Value Effective",
			// 	accessor: "ValueEffective"
			// },
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			// {
			// 	Header: "Fee Amount",
			// 	accessor: "FeeAmount",
			// 	type: GridColumnType.Money,
			// 	ColumnType: GridColumnType.Money
			// },
			{
				Header: "Currency",
				accessor: "CurrencyName"
			},
			{
				Header: "Remittance",
				accessor: "Remittance"
			},
			{
				Header: "Purpose Of Wire",
				accessor: "PurposeOfWire"
			},
			{
				Header: "Beneficiary Company Name",
				accessor: "CompanyName"
			},
			{
				Header: "Country",
				accessor: "CountryFormat"
			}
		];

		this.columnsDetail = [
			{
				Header: "FullName",
				accessor: "FullName"
			},
			{
				Header: "Gender",
				accessor: "Gender"
			},
			{
				Header: "Nationality",
				accessor: "Nationality"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "PlaceOfBirth",
				accessor: "PlaceOfBirth"
			},
			{
				Header: "DateOfBirth",
				accessor: "DateOfBirth"
			},
			{
				Header: "TextInfo",
				accessor: "TextInfo"
			}
		];


		this.defaultTemplateModel = {
			Id: 0,
			IsRecordValid: true,
			IsTemplate: false,
			Alias: null,
			TemplateName: null,
			UniqueClientId: 0,
			UniqueClient: null,
			CompanyName: null,
			Name: null,
			MiddleName: null,
			LastName: null,
			AddressLine1: null,
			AddressLine2: null,
			ZipCode: null,
			CityName: null,
			ProvinceName: null,
			CityId: null,
			City: null,
			ProvinceId: null,
			Province: null,
			CountryId: null,
			Country: null,
			CurrencyId: null,
			Currency: null,
			BankName: null,
			BankInstitution: null,
			BankTransitNumber: null,
			BankBranchName: null,
			BankFinInsId: null,
			BankFinIns: null,
			BankBranchId: null,
			BankBranch: null,
			AccountNumber: null,
			BankSwiftCode: null,
			BankAddressLine1: null,
			BankAddressLine2: null,
			BankZipCode: null,
			BankCityId: null,
			BankCity: null,
			BankProvinceId: null,
			BankProvince: null,
			BankCountryId: null,
			BankCountry: null,
			BankCityName: null,
			BankProvinceName: null,
			IsDomestic: null,
			HasChange: false
		};

		this.defaultModel = {
			ClientId: null,
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			DirectionParamValue: Direction.Outgoing,
			MessageTypeId: null,
			SubStatusId: null,
			StatusId: null,
			AmountFrom: null,
			AmountTo: null,
			StatusParamCode: null,
			SwiftReferenceNumber: null,
			TransactionReferenceNumber: null,
			IsBackOffice: true
		};

		this.state = {
			model: { ...this.defaultModel },
			subStatusList: [],
			tempSubStatusList: [],
			transactionList: [],
			approvalTransactionList: [],
			isLoading: false,
			isBackOffice: false,
			templateDetailModel: {},
			IsDomestic: {},
			detailDialog: false,
			approvalDialog: false,
			isDetailManualVerificationDialogOpen: false,
			isDetailUnverifiedDialogOpen: false,
			watchlistModel: {},
			listListPartialMatches: [],
			wireTransferIdForWatchlist: 0,
			IsCaptured: false
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		var { isBackOffice } = this.state;
		var model = { ...this.state.model };

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			this.columns.splice(1, 1);
		}
		this.setState({ model, isBackOffice });
		this.props.setAppLeftTitle("Wire Outgoing Transfers");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	HandleChange(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "StatusId") {
				if (newValue == -1) {
					model.StatusParamCode = null;
				}
				else if (data && data.ParameterValue == null) {
					model.StatusParamCode = null;
				}
				else if (data) {
					model.StatusParamCode = data.ParameterValue;
				}
			}
			return { model };
		});
	}

	GetData() {
		this.ExecutePostRequest(
			"/bankapi/v1.0/WireTransfer/ClientOutgoingWireTransactionSearch",
			this.state.model,
			responseData => {
				if (responseData.Item == null || responseData.Item.length < 1) {
					responseData.Item = [];
				}
				var approvalTransactionList = [];
				var transactionList = [];
				responseData.Item.forEach(element => {
					if (element.IsApproval) {
						approvalTransactionList.push(element);
					} else {
						transactionList.push(element);
					}
				});
				this.setState({ transactionList, approvalTransactionList });
			}
		);
	}

	ClearModel() {
		const model = { ...this.state.model };
		var clientId = this.state.isBackOffice ? null : model.ClientId;
		var newModel = { ...this.defaultModel };
		newModel.ClientId = clientId;
		this.setState({ model: newModel, transactionList: [], approvalTransactionList: [], wireTransferIdForWatchlist:0 });
	}

	HandleApproval() {
		const { templateDetailModel } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/BackofficeApproveOrDenyOutgoingWireTransaction",
			{
				WireTransferId: templateDetailModel.TransferId,
				BeneficiaryTemplate: templateDetailModel,
				IsApproval: true,
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.HandleClose();

					this.ShowGenericMessage("success", "Create", "Approved successfully.");
					this.CloseDialog(this.GetData);
				}
			});
	}

	HandleReject() {
		const { templateDetailModel } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/BackofficeApproveOrDenyOutgoingWireTransaction",
			{
				WireTransferId: templateDetailModel.TransferId,
				BeneficiaryTemplate: templateDetailModel,
				IsApproval: false,
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.HandleClose();

					this.ShowGenericMessage("success", "Create", "Disapproved successfully.");
					this.CloseDialog(this.GetData);
				}
			});
	}

	HandleClose() {
		this.CloseDialog();
	}

	CloseDialog(func = null) {
		if (func != null) {
			this.setState({ detailDialog: false, approvalDialog: false, isDetailManualVerificationDialogOpen:false, isDetailUnverifiedDialogOpen:false }, func);
		} else {
			this.setState({ detailDialog: false, approvalDialog: false, isDetailManualVerificationDialogOpen:false, isDetailUnverifiedDialogOpen:false });
		}
	}

	HandleOpenDetail(templateId, transferId, isApproval, isCaptured) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/Search",
			{ Id: templateId, IsTemplate: false },
			responseData => {
				if (responseData.Item != null) {
					if (responseData.Item != null && responseData.Item.length > 0) {

						const tempModel = { ...responseData.Item[0] };
						tempModel.IsDomestic = tempModel.BankCountry != null && tempModel.BankCountry.ISO2 == "CA";
						tempModel.TransferId = transferId;
						this.setState({
							templateDetailModel: tempModel,
							IsDomestic: responseData.Item[0].IsDomestic,
							detailDialog: true,
							approvalDialog: isApproval,
							IsCaptured: isCaptured
						});
					}
				} else {
					this.props.showMessage("warning", "Detail!", "Detail not found!");
				}
			});
	}


	TemplateValueChanged(name, newValue, data) {
		const { templateDetailModel } = this.state;


		if (name == "CurrencyId") {
			return;
		}
		// if (name == "CurrencyId" && this.defaultTemplateModel.CurrencyId == null) {
		// 	this.defaultTemplateModel.CurrencyId = newValue;
		// }

		var isTemplate = templateDetailModel.Id != 0;
		if (templateDetailModel[name] != newValue && isTemplate) {
			templateDetailModel.HasChange = true;
		}

		templateDetailModel[name] = newValue;
		if (name == "BankCountryId" && data != null) {
			templateDetailModel.IsDomestic = data.ISO2 == "CA";
		}
		if (name == "BankCountryId" && newValue == null) {
			templateDetailModel.BankCityId = null;
			templateDetailModel.BankProvinceId = null;
		}
		if (name == "CountryId" && newValue == null) {
			templateDetailModel.CityId = null;
			templateDetailModel.ProvinceId = null;
		}
		if (name == "SendEmailAddress" && data != null)
			templateDetailModel["IsSendEmailAddressValid"] = data.IsValid;
		if (name == "SendReceiptPDF" && !newValue)
			templateDetailModel["SendEmailAddress"] = "";
		this.setState({ templateDetailModel });
		//Template
	}

	RenderOrganizationUniqueIdSelect = (d) => {
		if (window.OrganizationUniqueId != 5000) {
			const model = { ...this.state.model };
			model["OrganizationUniqueId"] = d.OrganizationUniqueId;
			this.setState({ model: model });
		}

		return d.OrganizationUniqueId + "-" + d.OrganizationName;
	};

	DetailManualVerificationDialogOpen = (finScanSearchLogId, wireTransferId, isCaptured) => {
		var { wireTransferIdForWatchlist, IsCaptured } = this.state;
		wireTransferIdForWatchlist = wireTransferId;
		IsCaptured = isCaptured;

		this.GetFinScanSearchLogDetailManualVerification(finScanSearchLogId);
		this.setState({ isDetailManualVerificationDialogOpen: true, wireTransferIdForWatchlist, IsCaptured });
	}

	GetFinScanSearchLogDetailManualVerification = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/GetPartialMatchListByFinScanSearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			this.SetListPartialMatches(result);
		} else {
			this.setState({ listListPartialMatches: []});
		}
	}

	DetailManualVerificationDialogClose = () => {
		this.setState({ isDetailManualVerificationDialogOpen: false });
	}

		SetListPartialMatches = (searchResult) => {
		var listListPartialMatches = searchResult;

		for (let i = 0; i < searchResult.length; i++) {
			searchResult[i].JsonResultDataParsed.map(x => {
				listListPartialMatches[i][x.Key] = x.Value;

				return null;
			});
		}

		this.setState({ listListPartialMatches });
	}

	DetailUnverifiedDialogOpen = (finScanSearchLogId, wireTransferId, isCaptured) => {
		var { wireTransferIdForWatchlist, IsCaptured } = this.state;
		wireTransferIdForWatchlist = wireTransferId;
		IsCaptured = isCaptured;
		this.GetFinScanSearchLogDetailUnverified(finScanSearchLogId);
		this.setState({ isDetailUnverifiedDialogOpen: true, wireTransferIdForWatchlist, IsCaptured });
	}

	GetFinScanSearchLogDetailUnverified = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var { watchlistModel } = this.state;

		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLogDetail/GetDetailBySearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {

			watchlistModel = result;

			this.setState({ watchlistModel });
		}
	}

	DetailUnverifiedDialogClose = () => {
		this.setState({ isDetailUnverifiedDialogOpen: false });
	}

	ApproveWatchlistStatus = () => {
		var { wireTransferIdForWatchlist } = this.state;

		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/ApproveWatchlistStatus",
			{
				EftWireId: wireTransferIdForWatchlist
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.HandleClose();
					this.ShowGenericMessage("success", "Create", "Approved successfully.");
					this.CloseDialog(this.GetData);
				}
			});
	}

	RejectWatchlistStatus = () => {
		var { wireTransferIdForWatchlist } = this.state;

		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/RejectWatchlistStatus",
			{
				EftWireId: wireTransferIdForWatchlist
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.HandleClose();

					this.ShowGenericMessage("success", "Create", "Rejected successfully.");
					this.CloseDialog(this.GetData);
				}
			});
	}

	render() {
		const { Disabled } = this.props;
		const { IsLoading, Alert, isDetailManualVerificationDialogOpen, listListPartialMatches, isDetailUnverifiedDialogOpen, watchlistModel } = this.state;
		const { model, isBackOffice, templateDetailModel, detailDialog, approvalDialog, approvalTransactionList, transactionList, IsCaptured } = this.state;

		const renderColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				minWidth: 90,
				Cell: row => (
					<div>
						<GridButton
							tooltip="Beneficiary Detail"
							Disabled={Disabled}
							OnClick={() => this.HandleOpenDetail(row.original.TemplateId, 0, false, row.original.IsCaptured)}
							Icon={DetailIcon}
						/>
						<GridButton
							tooltip="Beneficiary Edit"
							Disabled={Disabled || !row.original.IsApproval}
							OnClick={() => this.HandleOpenDetail(row.original.TemplateId, row.original.WireTransferId, true, row.original.IsCaptured)}
							Icon={EditIcon}
						/>
						{row.original.IsCaptured && 					
							<GridButton
							Disabled={!row.original.IsCaptured}
							tooltip="Watchlist Approve"
							Icon={DetailIcon}
							OnClick={() => row.original.SearchResultParameterValue == FinScanSearchResultType.ListExactMatch
								? this.DetailUnverifiedDialogOpen(row.original.FinScanSearchLogId, row.original.WireTransferId, row.original.IsCaptured) : 
								this.DetailManualVerificationDialogOpen(row.original.FinScanSearchLogId, row.original.WireTransferId, row.original.IsCaptured) }/>					
						}
						<GridButton
							tooltip="PDF File"
							Icon={DocumentIcon}
							Disabled={Disabled || row.original.IsApproval || row.original.Channel != "CF-Wire"}
							OnClick={() =>
								this.props.ExecuteApiFileDownloadWithGenericResponse(
									"/bankapi/v1.0/WireTransfer/GenerateWireTransferPdfFile",
									row.original,
									`${row.original.WireTransferId
									}-${row.original.OrganizationUniqueId == 5001 ? "Pateno" : "DCPayments"} Wire Transfer`,
									"pdf"
								)
							}
						/>
					</div >
				)
			}, ...this.columns];
		return (
			<div>
					<GenericDialog open={isDetailUnverifiedDialogOpen} maxWidth="md" fullWidth onBackdropClick={this.DetailUnverifiedDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Unverified Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Text Info"
								Value={watchlistModel.TextInfo}
								 />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Nationality"
								Value={watchlistModel.Nationality} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Place Of Birth"
								Value={watchlistModel.Country} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Gender"
								Value={watchlistModel.Gender} />
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ApproveWatchlistStatus}>Approve</Button>
						<Button size="sm" onClick={this.RejectWatchlistStatus}>Reject</Button>
						<Button size="sm" onClick={this.DetailUnverifiedDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isDetailManualVerificationDialogOpen} maxWidth="lg" fullWidth onBackdropClick={this.DetailManualVerificationDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GridContainer >
							<GridItem xs={12}>
								<GridContainer spacing={16}>
								<GridItem xs={12}>
									<GenericLabel
										Bold
										Text="Similar Customers List"
										FontSize="16px"
										TextColor="black"
										/>
									<br />
									<br />
									<GenericGrid
										HideButton
										Data={listListPartialMatches}
										Columns={this.columnsDetail} />
								</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ApproveWatchlistStatus}>Approve</Button>
						<Button size="sm" onClick={this.RejectWatchlistStatus}>Reject</Button>
						<Button size="sm" onClick={this.DetailManualVerificationDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				{Alert}
				<LoadingComponent Show={IsLoading} />
				<GenericDialog open={detailDialog} maxWidth="md" onBackdropClick={this.HandleClose}>
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Transaction Details</b></h4>
						<br />
						<BeneficiaryDetail
							CompanyName={templateDetailModel.CompanyName}
							Name={templateDetailModel.Name}
							MiddleName={templateDetailModel.MiddleName}
							LastName={templateDetailModel.LastName}
							AddressLine1={templateDetailModel.AddressLine1}
							AddressLine2={templateDetailModel.AddressLine2}
							ZipCode={templateDetailModel.ZipCode}
							CityId={templateDetailModel.CityId}
							ProvinceId={templateDetailModel.ProvinceId}
							CountryId={templateDetailModel.CountryId}
							IsDomestic={templateDetailModel.BankCountry != null && templateDetailModel.BankCountry.ISO2 == "CA"}
							BankCountryId={templateDetailModel.BankCountryId}
							BankFinInsId={templateDetailModel.BankFinInsId}
							BankName={templateDetailModel.BankName}
							BankBranchId={templateDetailModel.BankBranchId}
							BankTransitNumber={templateDetailModel.BankTransitNumber}
							AccountNumber={templateDetailModel.AccountNumber}
							BankSwiftCode={templateDetailModel.BankSwiftCode}
							BankAddressLine1={templateDetailModel.BankAddressLine1}
							BankAddressLine2={templateDetailModel.BankAddressLine2}
							BankZipCode={templateDetailModel.BankZipCode}
							BankCityId={templateDetailModel.BankCityId}
							BankProvinceId={templateDetailModel.BankProvinceId}
							BankInstitution={templateDetailModel.BankInstitution}
							Alias={templateDetailModel.Alias}
							ValueChanged={this.TemplateValueChanged}
							Disabled={!approvalDialog}
							MenuCode={false} 
							PhoneNumber = {templateDetailModel.PhoneNumber}
							/>
						<br />
					</DialogContent>
					<GenericDialogActions>
						{approvalDialog && !IsCaptured &&
							<Button size="sm" onClick={this.HandleApproval}>
								Approve
							</Button>
						}
						{approvalDialog && !IsCaptured &&
							<Button size="sm" onClick={this.HandleReject}>
								Reject
							</Button>
						}
						<Button size="sm" onClick={this.HandleClose}>
							Close
						</Button>
					</GenericDialogActions>
				</GenericDialog>
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={true}>
							<GridContainer>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericSelectInput
												Disabled={
													Disabled ||
													!this.IsBackOffice
												}
												key={this.state.IsClientComponent}
												Name="OrganizationUniqueId"
												LabelText="Organization"
												Value={model.OrganizationUniqueId || ""}
												DataRoot="Item"
												ValueChanged={this.HandleChange}
												KeyValueMember="OrganizationUniqueId"
												TextValueMember="OrganizationUniqueId"
												//  TaskPoolDomain={this.loadTaskPool}
												Url="/bankapi/v1.0/OrganizationInfo/GetAll"
												Method="POST"
												RenderItem={this.RenderOrganizationUniqueIdSelect}
												Parameter={emptyObject}
											/>
										</GridItem>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="ClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.ClientId || ""}
													ValueChanged={this.HandleChange}
													All
													CanClear
													Disabled={!isBackOffice} />
											</GridItem>
											<GridItem xs={12}>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Utc={true}
													Value={model.FromDate == null || undefined ? "" : model.FromDate}
													ValueChanged={this.HandleChange}
													MaxDate={model.ToDate || DateHelper.GetDate()}
													IncludeTime={false} />
											</GridItem>
											<GridItem xs={12}>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Utc={true}
													Value={model.ToDate == null || undefined ? "" : model.ToDate}
													ValueChanged={this.HandleChange}
													MinDate={model.FromDate == null ? null : model.FromDate}
													MaxDate={DateHelper.GetDate()}
													IncludeTime={false} />
											</GridItem>
											{/* <GridItem xs={12}>
											<GenericTextInput Name="SwiftReferenceNumber" LabelText="Swift Reference Number" Value={model.SwiftReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem> */}
									</GridContainer>
								</GridItem>
								{/* <GridItem xs={4}> */}
								{/* <GridContainer> */}
								{/* <GridItem xs={12}>
											<ParameterComponent
												All
												Name="StatusId"
												Value={model.StatusId}
												LabelText="Status"
												ParameterCode="WireStatus"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												key={"wiresubstatus_" + model.StatusId}
												Name="SubStatusId"
												Value={model.SubStatusId}
												LabelText="Sub-Status"
												ParameterCode="WireSubStatus"
												ParameterValue2={model.StatusParamCode}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem> */}
								{/* <GridItem xs={12}>
											<ParameterComponent
												Name="MessageTypeId"
												Value={model.MessageTypeId}
												LabelText="Message Type"
												ParameterCode="WireMessageType"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem> */}
								{/* </GridContainer> */}
								{/* </GridItem> */}
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountFrom"
												LabelText="Amount From"
												Value={model.AmountFrom == null ? "" : model.AmountFrom}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountTo"
												LabelText="Amount To"
												Value={model.AmountTo == null ? "" : model.AmountTo}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										{/* <GridItem xs={12}>
											<GenericTextInput Name="TransactionReferenceNumber" LabelText="Transaction Reference Number" Value={model.TransactionReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem> */}

									</GridContainer>
								</GridItem>
								<br />
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"BSO Approval List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={approvalTransactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={renderColumns}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
									ShowPagination={true}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={transactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={renderColumns}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
									ShowPagination={true}

								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

OutgoingWireTransfers.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(OutgoingWireTransfers, {});