import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes, { object, func } from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput } from "views/Components/Generic";
import { Direction,ProgramCodes } from "views/Constants/Constant.js";
class ReconciliationeTransferTab extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { ETransferOutgoingSummary,ETransferIncomingSummary } = this.props;
		return (
			<GridContainer>
				<br /><br />
				{/* Outgoing */}
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Outgoing File Value</b>
								<GenericNumberInput
									Name="TotalAmountCredit"
									LabelText="Total Amount Credit"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.TotalAmountCredit ? ETransferOutgoingSummary.TotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="TotalAmountDebit"
									LabelText="Total Amount Debit"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.TotalAmountDebit ? ETransferOutgoingSummary.TotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<b>Response File Value</b>
								<GenericNumberInput
									Name="ResponseTotalAmountCredit"
									LabelText="Response Total Amount Credit"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalAmountCredit ? ETransferOutgoingSummary.ResponseTotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountDebit"
									LabelText="Response Total Amount Debit"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalAmountDebit ? ETransferOutgoingSummary.ResponseTotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountRejected"
									LabelText="Response Total Amount Rejected"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalAmountRejected ? ETransferOutgoingSummary.ResponseTotalAmountRejected : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountError"
									LabelText="Response Total Amount Error"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalAmountError ? ETransferOutgoingSummary.ResponseTotalAmountError : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountPending"
									LabelText="Response Total Amount Pending"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalAmountPending ? ETransferOutgoingSummary.ResponseTotalAmountPending : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAmount"
									LabelText="Total Amount"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.TotalAmount ? ETransferOutgoingSummary.TotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseFileAmount"
									LabelText="Total Response File Amount"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalAmount ? ETransferOutgoingSummary.ResponseTotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="FileGap"
									LabelText="Gap"
									Value={ETransferOutgoingSummary.TotalAmount-ETransferOutgoingSummary.ResponseTotalAmount}
									Disabled={true}
									LabelMd={6}
									Prefix="$"
									EndIconName="blur_on"
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_e_Transfer,Direction.Outgoing)}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Number of Outgoing Transactions</b>
								<GenericNumberInput
									Name="NumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.NumberCreditTransaction ? ETransferOutgoingSummary.NumberCreditTransaction : 0}
									Disabled={true}									
									LabelMd={6} />
								<GenericNumberInput
									Name="NumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.NumberDebitTransaction ? ETransferOutgoingSummary.NumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>							
							<GridItem xs={4}>
								<b>Number of Response Transactions</b>
								<GenericNumberInput
									Name="ResponseNumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseNumberCreditTransaction ? ETransferOutgoingSummary.ResponseNumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseNumberDebitTransaction ? ETransferOutgoingSummary.ResponseNumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberRejectedTransaction"
									LabelText="Number of Rejected Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseNumberRejectedTransaction ? ETransferOutgoingSummary.ResponseNumberRejectedTransaction : 0}
									Disabled={true} 
									LabelMd={6}/>
								<GenericNumberInput
									Name="ResponseNumberErrorTransaction"
									LabelText="Number of Error Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseNumberErrorTransaction ? ETransferOutgoingSummary.ResponseNumberErrorTransaction : 0}
									Disabled={true} 
									LabelMd={6}/>
								<GenericNumberInput
									Name="ResponseNumberPendingTransaction"
									LabelText="Number of Pending Transaction"
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseNumberPendingTransaction ? ETransferOutgoingSummary.ResponseNumberPendingTransaction : 0}
									Disabled={true} 
									LabelMd={6}/>
							</GridItem>							
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalNumberTransaction"
									LabelText="Total Number of Trans."
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.TotalNumber ? ETransferOutgoingSummary.TotalNumber : 0}
									Disabled={true} 
									LabelMd={6}/>
							</GridItem>							
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseNumberTransaction"
									LabelText="Total Response Number of Trans."
									Value={ETransferOutgoingSummary == null ? 0 : ETransferOutgoingSummary.ResponseTotalNumber ? ETransferOutgoingSummary.ResponseTotalNumber : 0}
									Disabled={true} 
									LabelMd={6}/>
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionGap"
									LabelText="Gap"
									Value={ETransferOutgoingSummary.TotalNumber-ETransferOutgoingSummary.ResponseTotalNumber}
									EndIconName="blur_on"
									LabelMd={6}
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_e_Transfer,Direction.Outgoing)}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				{/* Incoming */}
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Incoming File Value</b>
								<GenericNumberInput
									Name="TotalAmountCredit"
									LabelText="Total Amount Credit"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.TotalAmountCredit ? ETransferIncomingSummary.TotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="TotalAmountDebit"
									LabelText="Total Amount Debit"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.TotalAmountDebit ? ETransferIncomingSummary.TotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<b>Response File Value</b>
								<GenericNumberInput
									Name="ResponseTotalAmountCredit"
									LabelText="Response Total Amount Credit"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseTotalAmountCredit ? ETransferIncomingSummary.ResponseTotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountDebit"
									LabelText="Response Total Amount Debit"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseTotalAmountDebit ? ETransferIncomingSummary.ResponseTotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountRejected"
									LabelText="Response Total Amount Rejected"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseTotalAmountRejected ? ETransferIncomingSummary.ResponseTotalAmountRejected : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountError"
									LabelText="Response Total Amount Error"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseTotalAmountError ? ETransferIncomingSummary.ResponseTotalAmountError : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountPending"
									LabelText="Response Total Amount Pending"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseTotalAmountPending ? ETransferIncomingSummary.ResponseTotalAmountPending : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAmount"
									LabelText="Total Amount"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.TotalAmount ? ETransferIncomingSummary.TotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseFileAmount"
									LabelText="Total Response File Amount"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.TotalResponseFileAmount ? ETransferIncomingSummary.TotalResponseAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="FileGap"
									LabelText="Gap"
									Value={ETransferIncomingSummary.TotalAmount-ETransferIncomingSummary.TotalResponseAmount}
									Disabled={true}
									LabelMd={6}
									Prefix="$"
									EndIconName="blur_on"
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_e_Transfer,Direction.Incoming)}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Number of Incoming Transactions</b>
								<GenericNumberInput
									Name="NumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.NumberCreditTransaction ? ETransferIncomingSummary.NumberCreditTransaction : 0}
									Disabled={true}									
									LabelMd={6} />
								<GenericNumberInput
									Name="NumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.NumberDebitTransaction ? ETransferIncomingSummary.NumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>							
							<GridItem xs={4}>
								<b>Number of Response Transactions</b>
								<GenericNumberInput
									Name="ResponseNumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseNumberCreditTransaction ? ETransferIncomingSummary.ResponseNumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseNumberDebitTransaction ? ETransferIncomingSummary.ResponseNumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberRejectedTransaction"
									LabelText="Number of Rejected Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseNumberRejectedTransaction ? ETransferIncomingSummary.ResponseNumberRejectedTransaction : 0}
									Disabled={true} 
									LabelMd={6}/>
								<GenericNumberInput
									Name="ResponseNumberErrorTransaction"
									LabelText="Number of Error Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseNumberErrorTransaction ? ETransferIncomingSummary.ResponseNumberErrorTransaction : 0}
									Disabled={true} 
									LabelMd={6}/>
								<GenericNumberInput
									Name="ResponseNumberPendingTransaction"
									LabelText="Number of Pending Transaction"
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.ResponseNumberPendingTransaction ? ETransferIncomingSummary.ResponseNumberPendingTransaction : 0}
									Disabled={true} 
									LabelMd={6}/>
							</GridItem>							
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalNumberTransaction"
									LabelText="Total Number of Trans."
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.TotalNumberTransaction ? ETransferIncomingSummary.TotalNumber : 0}
									Disabled={true} 
									LabelMd={6}/>
							</GridItem>							
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseNumberTransaction"
									LabelText="Total Response Number of Trans."
									Value={ETransferIncomingSummary == null ? 0 : ETransferIncomingSummary.TotalResponseNumberTransaction ? ETransferIncomingSummary.TotalResponseNumber : 0}
									Disabled={true} 
									LabelMd={6}/>
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionGap"
									LabelText="Gap"
									Value={ETransferIncomingSummary.TotalNumber-ETransferIncomingSummary.TotalResponseNumber}
									EndIconName="blur_on"
									LabelMd={6}
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_e_Transfer,Direction.Incoming)}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>			
			</GridContainer >
		);
	}
}

ReconciliationeTransferTab.propTypes = {
	classes: PropTypes.object,
	ETransferOutgoingSummary:object,
	ETransferIncomingSummary:object,
	openDialog:func
};

export default ReconciliationeTransferTab;