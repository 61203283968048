import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericTextInput, GenericDateInput, GenericGrid} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import CardHeader from "components/Card/CardHeader";
import DateHelper from "core/DateHelper";
import GridButton from "views/Components/GridButton";
import { ApproveIcon } from "core/Icons";
import { GridColumnType } from "views/Constants/Constant.js";

class WireTransferApprovalList extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0
		};

		this.state = {
			list: [],
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			SelectedRowIndex: undefined,
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Wire Transfer Approval List");
		this.props.setAppCenterTitle("WIRE TRANSFER");
	}
	
	handleChange = (name, newValue) => {
		const model = { ...this.state.model };

		model[name] = newValue;

		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {}
		});
	};
	
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/WireTransferWhiteListSearch",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({
						model,
						list: responseData.Item
					});
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	Approve = (wireTransferWhiteListId) => {
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/WireTransferFinScanApprove",
			wireTransferWhiteListId,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.props.showMessage("success", "Success", "Approved Sucessfully");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	RowSelected(index) {
		this.setState({ SelectedRowIndex: index, SelectedRow: this.state.list[index] });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, isLoading, SelectedRowIndex, list } = this.state;

		return (
				<div>
					<LoadingComponent Show={isLoading} />
					{alert}
					<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					<GridContainer spacing={16}>
						<GridItem xs={12}>
							<Card className="no-radius">
								<CardBody>
									<GenericExpansionPanel Title="Main Parameter">
										<GridContainer>
											<GridItem xs={4}>
												<GenericTextInput
													Name="Name"
													LabelText="Name"
													Value={model.Name}
													ValueChanged={this.handleChange} />
												<GenericDateInput
													Name="StartDate"
													LabelText="Start Date"
													Value={model.StartDate ? DateHelper.ToDateInputValueAsUtc(model.StartDate) : ""}
													MaxDate={model.EndOfDate || DateHelper.GetDate()}
													ValueChanged={this.handleChange}
													Utc />
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate ? DateHelper.ToDateInputValueAsUtc(model.ToDate) : ""}
													MinDate={model.ToDate}
													ValueChanged={this.handleChange}
													Utc />
											</GridItem>
										</GridContainer>
									</GenericExpansionPanel>
								</CardBody>
							</Card>
							<Card className="no-radius">
								<CardHeader>
									<GridContainer spacing={16} justify="space-between" alignItems="center">
										<GridItem>
											<CardHeader>
												<h4><b>Wire Transfer Approval List</b></h4>
											</CardHeader>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
												</GridItem>
												<GridItem xs={8} />
											</GridContainer>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list}
														Columns={[
															{
																Header: "Actions",
																accessor: "Actions",
																Cell: row => (
																	<div>
																		<GridButton
																			tooltip="Approve"
																			Icon={ApproveIcon}
																			OnClick={() => { this.Approve(row.original.Id) }}
																			Disabled={row.original.IsApproved == true || row.original.Status != "Waiting"}
																			/>
																	</div>
																),
																sortable: false,
																filterable: false,
																width: 60
															},
															{
																Header: "Status",
																accessor: "Status",
															},
															{
																Header: "Is Approved",
																accessor: "IsApproved",
																type: GridColumnType.YesNo,
																ColumnType: GridColumnType.YesNo
															},
															{
																Header: "Client Name",
																accessor: "ClientName"
															},
															{
																Header: "Name",
																accessor: "Name"
															},
															{
																Header: "Country",
																accessor: "Country"
															},
															{
																Header: "Date Of Birth",
																accessor: "DateOfBirth",
																type: GridColumnType.DateTime,
																ColumnType: GridColumnType.DateTime
															},
															{
																Header: "Record Type",
																accessor: "RecordType"
															},
															{
																Header: "Transaction Date",
																accessor: "TransactionDate",
																type: GridColumnType.DateTime,
																ColumnType: GridColumnType.DateTime
															},
															{
																Header: "Swift Reference Number",
																accessor: "SwiftReferenceNumber"
															},
															{
																Header: "Reference Number",
																accessor: "ReferenceNumber"
															},
															{
																Header: "Amount",
																accessor: "OriginalAmount",
																type: GridColumnType.Money,
																ColumnType: GridColumnType.Money
															},
															{
																Header: "Insert Date Time",
																accessor: "InsertDateTime",
																type: GridColumnType.DateTime,
																ColumnType: GridColumnType.DateTime
															},
															{
																Header: "Insert User",
																accessor: "InsertUser"
															},
															{
																Header: "Update Date Time",
																accessor: "UpdateDateTime",
																type: GridColumnType.DateTime,
																ColumnType: GridColumnType.DateTime
															},
															{
																Header: "Update User",
																accessor: "UpdateUser"
															}
														]}
														RowSelected={this.RowSelected}
														SelectedIndex={SelectedRowIndex}
														IsSorted={false} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div >
		);
	}
}

WireTransferApprovalList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(WireTransferApprovalList, {});
