import { DialogContent, DialogTitle, Slide, Tooltip, Zoom } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import createEtransferRecurringStyle from "assets/jss/material-dashboard-pro-react/views/createEtransferRecurringStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericIcon, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { ETransferDayOfMonthTypes, EtransferEntryType, EtransferFrequencyCodes, EtransferPeriodCodes, EtransferTransactionTypes, ETransferWeekOfMonthTypes, ProgramCodes } from "views/Constants/Constant.js";
import AddNewCustomereTransfer from "views/InteraceTransfer/AddNewCustomereTransfer.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const sortName = { Member: "Name" };
const sortMemo = { Member: "Memo" };
const sortParamValue2 = { Member: "ParameterValue2" };
const ClientParamObj = {
	ProgramCode: ProgramCodes.Bank_Programs_e_Transfer,
	UniqueClientId: undefined
};
const lengthSecurityAnswer = { maxLength: 25 };
const ParameterMaxLength400 = { maxLength: 400 };
const ParameterMaxLengthSecurityQuestion = { maxLength: 40 };

class CreateEtransferRecurring extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customerToken: "0"
		};

		this.isClient = ClientHelper.IsClient();

		this.ValueChanged = this.ValueChanged.bind(this);
		this.GetTooltip = this.GetTooltip.bind(this);
		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
		this.addCustomerSaved = this.addCustomerSaved.bind(this);
		this.GetClientParamObj = this.GetClientParamObj.bind(this);
	}

	componentDidMount() {
		var model = { ...this.props.model };
		if (model != null && model.Id != null && model.Id > 0) {
			this.IsUpdate();
		}
	}

	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === ''
			|| value === '""' || value === '""');
	}

	IsUpdate = () => {
		var model = { ...this.props.model };
		var serviceType = null;
		if (model.NotificationType == null || model.NotificationType.ParameterValue3 == null || model.NotificationType.ParameterValue3 == "") {
			this.props.showMessage("error", "Error", "Customer Notification Type cannot found !");
			return;
		}
		else {
			serviceType = model.NotificationType.ParameterValue3;
		}

		if (serviceType == 0 && (model.PayeeEmail == null || model.PayeeEmail == undefined)) {
			this.props.showMessage("error", "Error", "Customer Email cannot found !");
			return;
		}

		if (serviceType == 1 && (model.PhoneNumber == null || model.PhoneNumber == undefined)) {
			this.props.showMessage("error", "Error", "Customer Phone cannot found !");
			return;
		}

		if(serviceType == 2) {
			if (this.IsNullOrEmpty(model.PhoneNumber)) {
				this.props.showMessage("error", "Error", "Customer Phone Number cannot be null !");
				return;
			}
			
			if (this.IsNullOrEmpty(model.PayeeEmail)) {
				this.props.showMessage("error", "Error", "Customer Email cannot be null !");
				return;
			}
		}

		this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, serviceType);
	}

	FetchInteracGetTransferOptions = (eMail, phoneNumber, serviceType) => {
		var request = {};

		if (serviceType == 0 && (eMail == null || eMail == "")) {
			this.props.showMessage("error", "Error", "Customer Email cannot be null !");
			return;
		}

		if (serviceType == 1 && (phoneNumber == null || phoneNumber == "")) {
			this.props.showMessage("error", "Error", "Customer Phone Number cannot be null !");
			return;
		}
		request.UniqueClientId = this.props.parentModel.UniqueClientId;
		request.DirectDepositHandle = serviceType == 0 ? eMail : phoneNumber;
		request.ServiceType = serviceType;
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetTransferOptions",
			request,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while request " + responseData.ErrorDescription);
					return;
				}
				this.setState({ InteracCustomerTrsnaferOprion: responseData.Item });
				this.props.onModelChange(model => {
					model.HasDDR = responseData.Item;
					return model;
				});
			},
			e => {
				this.props.showMessage("error", "Error", "An error occurred while inserting data ");
			}
		);
	}

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;

			if (name == "SecurityQuestionAnswer") {
				model[name] = newValue.replace(/\s/g, "");
			}

			if (name == "FrequencyType") {

				model.FrequencyCode = data?.ParameterValue ?? "";
				model.PeriodType = "";
				model.PeriodCode = "";

				switch (model.FrequencyCode) {
					case EtransferFrequencyCodes.Daily:
						model.DayOfWeekId = "";
						model.DayOfWeekNumber = "";
						model.DayOfMonthTypeId = "";
						model.DayOfMonthTypeNumber = "";
						model.DayOfMonthId = "";
						model.DayOfMonthNumber = "";
						model.WeekOfMonthTypeId = "";
						model.WeekOfMonthTypeNumber = "";
						model.WeekOfMonthId = "";
						model.WeekOfMonthNumber = "";
						break;
					case EtransferFrequencyCodes.Weekly:
						model.DayOfMonthTypeId = "";
						model.DayOfMonthTypeNumber = "";
						model.DayOfMonthId = "";
						model.DayOfMonthNumber = "";
						model.WeekOfMonthTypeId = "";
						model.WeekOfMonthTypeNumber = "";
						model.WeekOfMonthId = "";
						model.WeekOfMonthNumber = "";
						break;
					case EtransferFrequencyCodes.Monthly:
						model.DayOfWeekId = "";
						model.DayOfWeekNumber = "";
						break;
					default:
						break;
				}
			}

			if (name == "PeriodType") {
				model.PeriodCode = data?.ParameterValue ?? "";
				switch (model.PeriodCode) {
					case EtransferPeriodCodes.DayOfWeek:
						model.DayOfMonthTypeId = "";
						model.DayOfMonthTypeNumber = "";
						model.DayOfMonthId = "";
						model.DayOfMonthNumber = "";
						model.WeekOfMonthTypeId = "";
						model.WeekOfMonthTypeNumber = "";
						model.WeekOfMonthId = "";
						model.WeekOfMonthNumber = "";
						break;
					case EtransferFrequencyCodes.DayOfMonth:
						model.DayOfWeekId = "";
						model.DayOfWeekNumber = "";
						model.WeekOfMonthTypeId = "";
						model.WeekOfMonthTypeNumber = "";
						model.WeekOfMonthId = "";
						model.WeekOfMonthNumber = "";
						break;
					case EtransferFrequencyCodes.WeekOfMonth:
						model.DayOfWeekId = "";
						model.DayOfWeekNumber = "";
						model.DayOfMonthTypeId = "";
						model.DayOfMonthTypeNumber = "";
						model.DayOfMonthId = "";
						model.DayOfMonthNumber = "";
						break;
					default:
						break;
				}
			}

			if (name == "DayOfWeekId") model.DayOfWeekNumber = data?.ParameterValue ?? "";
			if (name == "DayOfMonthTypeId") {
				model.DayOfMonthTypeNumber = data?.ParameterValue ?? "";
				if (model.DayOfMonthTypeNumber != ETransferDayOfMonthTypes.SelectedDay) {
					model.DayOfMonthId = "";
					model.DayOfMonthNumber = "";
				}
			}
			if (name == "DayOfMonthId") model.DayOfMonthNumber = data?.ParameterValue ?? "";
			if (name == "WeekOfMonthTypeId") model.WeekOfMonthTypeNumber = data?.ParameterValue ?? "";
			if (name == "WeekOfMonthId") model.WeekOfMonthNumber = data?.ParameterValue ?? "";

			if (name == "PayeeId") {
				model.PayeeName = data?.Name ?? "";
				model.PayeeEmail = data?.PayeeEmail ?? "";
				model.PhoneNumber = data == null ? null : data.PhoneNumber;
				// model.HasDDR = data == null ? null : data.HasDDR;
				if (model.PayeeId != null && model.TransactionTypeCode == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, model.ServiceType);
			}

			if (name == "NewClient") {
				if (newValue) {
					model.IsCustomerPopupOpen = true;
					this.setState({ isCustomerAdded: false });
				}
			}

			if (name == "IsHaveQuestion") {
				if (newValue == true)
					model.SecurityQuestionText = "";
				else
					model.SecurityQuestion = 0;
			}
			if (name == "TransferMemoId") {
				model.TransferMemo = data?.Memo ?? "";
			}
			if (name == "IsHaveMemo") {
				if (newValue) model.TransferMemo = "";
				model.TransferMemoId = "";
			}
			if (name == "TransactionType") {
				model.TransactionTypeCode = data?.ParameterValue ?? "";
				if (model.TransactionTypeCode == EtransferTransactionTypes.Debit) {
					if (model.SecurityQuestionAnswer != null)
						model.SecurityQuestionAnswer = null;
					if (model.SecurityQuestionText != null)
						model.SecurityQuestionText = null;
					if (model.SecurityQuestion != null)
						model.SecurityQuestion = null;
				}
				if (model.PayeeId != null && model.TransactionTypeCode == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, model.ServiceType);
			}

			const { StartDate, EndDate, FreezeStartDate, FreezeEndDate } = model;

			if (name == "StartDate" || name == "EndDate" || name == "FrequencyType" || name == "PeriodType" ||
				name == "DayOfWeekId" || name == "DayOfMonthTypeId" || name == "DayOfMonthId" ||
				name == "WeekOfMonthTypeId" || name == "WeekOfMonthId" || name == "FreezeStartDate" || name == "FreezeEndDate") {
				var paymentCount = this.GetPaymentCount(StartDate, EndDate, model);
				var freezeCount = this.GetPaymentCount(FreezeStartDate, FreezeEndDate, model);
				var netCount = paymentCount - freezeCount;
				if (netCount == 0) netCount = null;
				model.PaymentCount = netCount;
			}

			if (name == "StartDate" && model.EndDate != null && model.EndDate < newValue)
				model.EndDate = newValue;

			if (name == "NotificationTypeId" && data != null) {
				model.ServiceType = data.ParameterValue3;

				if (model.PayeeId != null && model.TransactionTypeCode == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, model.ServiceType);
			}

			return model;
		});
	}

	/**
	 * @param {moment.Moment} startDate
	 * @param {moment.Moment} endDate
	 * @param {{ DayOfWeekNumber?: any; DayOfMonthTypeNumber?: any; DayOfMonthNumber?: any; WeekOfMonthTypeNumber?: any; WeekOfMonthNumber?: any; FrequencyType?: any; FrequencyCode?: any; PeriodCode?: any; DayOfWeekId?: any; DayOfMonthTypeId?: any; DayOfMonthId?: any; WeekOfMonthTypeId?: any; WeekOfMonthId?: any; }} model
	 */
	GetPaymentCount(startDate, endDate, model) {
		const { DayOfWeekNumber, DayOfMonthTypeNumber, DayOfMonthNumber, WeekOfMonthTypeNumber, WeekOfMonthNumber } = model;

		if (moment.isMoment(startDate) && startDate.isValid() && moment.isMoment(endDate) && endDate.isValid() && model.FrequencyType) {

			const StartDate = startDate.toDate();
			const EndDate = endDate.toDate();

			var count = 0;
			/**
			 * @param {number} d1
			 * @param {number} d
			 */
			var dayDif = (d1, d) => {
				return d1 > d ? d - d1 + 7 : d - d1;
			};
			/**
			 * @param {Date} date
			 * @param {number} days
			 */
			var addDays = (date, days) => {
				var nd = new Date(date); nd.setDate(date.getDate() + days);
				return nd;
			};
			/**
			* @param {Date} d1
			* @param {Date} d2
			*/
			var monthDif = (d1, d2) => {
				var d1Start = new Date(d1.getFullYear(), d1.getMonth(), 1);
				var d2Start = new Date(d2.getFullYear(), d2.getMonth() + 1, 1);
				return Math.round(moment.duration(d2Start.getTime() - d1Start.getTime()).asMonths());
			};
			/**
			 * @param {Date} d
			 */
			var lastDayOfMonth = d => {
				return new Date(d.getFullYear(), d.getMonth() + 1, 0);
			};
			/**
			 * @param {Date} date
			 * @param {number} day
			 * @param {number} num
			 */
			var womCalc = (date, day, num) => {
				if (num == null) {
					var lastDate = lastDayOfMonth(date);
					if (lastDate.getDay() == day) return lastDate;
					return addDays(lastDate, dayDif(lastDate.getDay(), day) - 7);
				}
				var firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
				var resultDate = addDays(firstDate, dayDif(firstDate.getDay(), day) + ((num - 1) * 7));
				if (resultDate.getMonth() != date.getMonth())
					resultDate = addDays(resultDate, -7);
				return resultDate;
			};

			switch (model.FrequencyCode) {
				case EtransferFrequencyCodes.Daily:
					count = moment.duration(EndDate.getTime() - StartDate.getTime()).asDays() + 1;
					break;
				case EtransferFrequencyCodes.Weekly:
					if (model.PeriodCode == EtransferPeriodCodes.DayOfWeek && model.DayOfWeekId) {
						var firstWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), DayOfWeekNumber));
						if (EndDate.getTime() - firstWeekPayDate.getTime() >= 0)
							count = Math.floor(moment.duration(EndDate.getTime() - firstWeekPayDate.getTime()).asDays() / 7 + 1);
					}
					break;
				case EtransferFrequencyCodes.Monthly:
					switch (model.PeriodCode) {
						case EtransferPeriodCodes.DayOfMonth:
							if (model.DayOfMonthTypeId && (DayOfMonthTypeNumber != ETransferDayOfMonthTypes.SelectedDay || model.DayOfMonthId)) {

								var mDayOfMonthNumber = DayOfMonthNumber;
								if (DayOfMonthTypeNumber == ETransferDayOfMonthTypes.FirstDay)
									mDayOfMonthNumber = 1;
								if (DayOfMonthTypeNumber == ETransferDayOfMonthTypes.LastDay)
									mDayOfMonthNumber = 31;

								var mMonthDiff = monthDif(StartDate, EndDate);
								if (mMonthDiff < 1) break;

								var mStartDom = Math.min(lastDayOfMonth(StartDate).getDate(), mDayOfMonthNumber);
								var mEndDom = Math.min(lastDayOfMonth(EndDate).getDate(), mDayOfMonthNumber);
								if (mMonthDiff == 1) {
									if (StartDate.getDate() > mStartDom || EndDate.getDate() < mEndDom) break;
									if (StartDate.getDate() <= mStartDom && EndDate.getDate() >= mEndDom) { count = 1; break; }
								}
								if (mMonthDiff > 1) {
									var mStartDateSkip = StartDate.getDate() > mStartDom ? 1 : 0;
									var mEndDateSkip = EndDate.getDate() < mEndDom ? 1 : 0;
									count = mMonthDiff - mStartDateSkip - mEndDateSkip;
								}
							}
							break;
						case EtransferPeriodCodes.DayOfWeek:
							if (model.DayOfWeekId) {
								var mfirstWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), DayOfWeekNumber));
								if (EndDate.getTime() - mfirstWeekPayDate.getTime() >= 0)
									count = Math.floor(moment.duration(EndDate.getTime() - mfirstWeekPayDate.getTime()).asDays() / 7 + 1);
							}
							break;
						case EtransferPeriodCodes.WeekOfMonth:
							if (model.WeekOfMonthTypeId && model.WeekOfMonthId) {
								var woMonthDiff = monthDif(StartDate, EndDate);
								if (woMonthDiff < 1) break;
								var weekNum = WeekOfMonthTypeNumber == ETransferWeekOfMonthTypes.Last ? null : Number(WeekOfMonthTypeNumber);
								var woStartDom = womCalc(StartDate, WeekOfMonthNumber, weekNum).getDate();
								var woEndDom = womCalc(EndDate, WeekOfMonthNumber, weekNum).getDate();
								if (woMonthDiff == 1) {
									if (StartDate.getDate() > woStartDom || EndDate.getDate() < woEndDom) break;
									if (StartDate.getDate() <= woStartDom && EndDate.getDate() >= woEndDom) { count = 1; break; }
								}
								if (woMonthDiff > 1) {
									var woStartDateSkip = StartDate.getDate() > woStartDom ? 1 : 0;
									var woEndDateSkip = EndDate.getDate() < woEndDom ? 1 : 0;
									count = woMonthDiff - woStartDateSkip - woEndDateSkip;
								}
							}
							break;
						default:
							break;
					}
					break;

				default:
					break;
			}

			return count;
		}

		return 0;
	}

	GetTooltip() {
		const { model } = this.props;

		switch (model.FrequencyCode) {
			case EtransferFrequencyCodes.Daily:
				return "";
			case EtransferFrequencyCodes.Weekly:
				return "0 – Sunday 1 – Monday 2 – Tuesday 3 – Wednesday 4 – Thursday 5 – Friday 6 – Saturday Ex – Every Monday of Week or every third Monday of the month";
			case EtransferFrequencyCodes.Monthly:
				switch (model.PeriodCode) {
					case EtransferPeriodCodes.DayOfMonth:
						return "Allowed values are from 1 to 31, if a month has less then provided days then the transfer will be scheduled on the last day of the month. Ex – Every 20th of Month, Every 31st of Month 30th of Sep or 28th / 29th of Feb depending on leap year. ";
					case EtransferPeriodCodes.DayOfWeek:
						return "0 – First 1 – Second 2 – Third 3 – Fourth 4 – Last Ex – Every 2nd Tuesday of the month ";
					case EtransferPeriodCodes.WeekOfMonth:
						return "0 – Sunday 1 – Monday 2 – Tuesday 3 – Wednesday 4 – Thursday 5 – Friday 6 – Saturday Ex – Every Monday of Week or every third Monday of the month";
					default:
						return "";
				}
			default:
				return "";
		}
	}

	addCustomerDialogClose() {
		this.ValueChanged("IsCustomerPopupOpen", false);
	}

	addCustomerSaved(customerData) {
		if (customerData != null) {
			this.props.onModelChange(model => {
				model.PayeeId = customerData.PayeeId;
				model.PayeeEmail = customerData.PayeeEmail;
				return model;
			});

			var newCustomerToken = this.state.customerToken == "1" ? "0" : "1";
			this.setState({ customerToken: newCustomerToken });
		}

		this.addCustomerDialogClose();
	}

	RenderClientSelect(d) {
		return d.Name;
	}

	GetClientParamObj() {
		const { model } = this.props;
		ClientParamObj.ProgramCode = ProgramCodes.Bank_Programs_e_Transfer;
		ClientParamObj.UniqueClientId = model.UniqueClientId;
		return ClientParamObj;
	}
	RenderAccountSelect(d) {
		const accountNumber = d.Account && d.Account.AccountNumber;
		const accountName = d.Account && d.Account.AccountName;
		const storeCode = d.CustomerStore && d.CustomerStore.StoreCode;
		const storeName = d.CustomerStore && d.CustomerStore.StoreName;

		return `${storeName}(${storeCode}) - ${accountName}(${accountNumber})`;
	}

	render() {
		const { classes, model, vModel, Disabled: DisabledProp, parentModel, CustomerStoreList } = this.props;

		const Selected = parentModel.EntryCode == EtransferEntryType.Recurring;
		const Disabled = DisabledProp || !Selected;

		var periodParamValue2 = null;
		switch (model.FrequencyCode) {
			case EtransferFrequencyCodes.Daily:
				periodParamValue2 = EtransferFrequencyCodes.Daily;
				break;
			case EtransferFrequencyCodes.Weekly:
				periodParamValue2 = "2;3";
				break;
			case EtransferFrequencyCodes.Monthly:
				break;
			default:
				periodParamValue2 = "Nothing";
				break;
		}

		return (
			<div style={{ opacity: Selected ? 1.0 : 0.4 }}>
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={Selected} Title="Recurring Transaction Entry">
							<GridContainer spacing={16}>
								<GridItem xs={6}>
									<GridContainer spacing={16} alignItems="center">

										<GridItem xs={8}>
											{CustomerStoreList != null && CustomerStoreList.length > 0 &&
												<GenericSelectInput
													LabelMd={4}
													key={"CustomerStoreId" + this.state.customerToken + "_" + parentModel.UniqueClientId}
													Name="CustomerStoreId"
													LabelText="Store Wallet"
													IsStatic={true}
													StaticData={CustomerStoreList}
													DataRoot="Item"
													KeyValueMember="CustomerStoreId"
													RenderItem={this.RenderAccountSelect}
													Value={model.CustomerStoreId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || parentModel.IsUpdate}
													Required
													IsInvalid={vModel.CustomerStoreId} />}
										</GridItem>
										<GridItem xs={4}></GridItem>
										<GridItem xs={8}>
											<GenericSelectInput
												LabelMd={4}
												key={"RecurringPayeeId_" + this.state.customerToken + "_" + parentModel.UniqueClientId}
												Name="PayeeId"
												LabelText="Customer Name"
												Method="POST"
												Url="/bankapi/v1.0/ClientCustomer/GetAllMainPayeeCustomer"
												DataRoot="Item"
												Parameter={this.GetClientParamObj()}
												KeyValueMember="PayeeId"
												RenderItem={this.RenderClientSelect}
												Value={model.PayeeId}
												ValueChanged={this.ValueChanged} Disabled={Disabled}
												Sorted={sortName}
												Required
												IsInvalid={vModel.PayeeId} />
										</GridItem>
										<GridItem xs={4}>
											{this.isClient && !parentModel.IsUpdate &&
												<GenericCheckInput
													Name="NewClient"
													LabelText="New Customer"
													Value={model.NewClient}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled} />}
										</GridItem>
										<GridItem xs={8}>
											{model.ServiceType == 0 &&
												<GenericEmailInput
													LabelMd={4}
													Name="PayeeEmail"
													LabelText="Customer Email"
													Value={model.PayeeEmail}
													ValueChanged={this.ValueChanged}
													Disabled={true} />
											}
											{model.ServiceType == 1 &&
												<GenericTextInput
													LabelMd={4}
													Name="PayeePhone"
													LabelText="Customer Phone"
													Value={model.PhoneNumber}
													ValueChanged={this.ValueChanged}
													Disabled={true} />
											}
											{model.ServiceType == 2 &&
    											<>
     										  <GenericEmailInput
                                   LabelMd={4}
                                   Name="PayeeEmail"
                                   LabelText="Customer Email"
                                   Value={model.PayeeEmail}
                                   ValueChanged={this.ValueChanged}
                                   Disabled={true} />
                                  <GenericTextInput
                                  LabelMd={4}
                                  Name="PayeePhone"
                                  LabelText="Customer Phone"
                                  Value={model.PhoneNumber}
                                  ValueChanged={this.ValueChanged}
                                  Disabled={true} />
                                  </>
                                 }
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											<GenericSelectInput
												Name="TransactionType"
												LabelMd={4}
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterValue6"
												Value={model.TransactionType}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.TransactionType} />
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											<GenericDateInput
												Name="StartDate"
												LabelMd={4}
												LabelText="Start Date"
												Value={model.StartDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || parentModel.IsUpdate}
												MinDate={DateHelper.GetDateTimeLocal()}
												Utc
												Required
												IsInvalid={vModel.StartDate} />
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											<GenericDateInput
												Name="EndDate"
												LabelMd={4}
												LabelText="End Date"
												Value={model.EndDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												MinDate={model.StartDate || DateHelper.GetDateTimeLocal()}
												Utc
												Required
												IsInvalid={vModel.EndDate} />
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											<GenericTextInput
												LabelMd={4}
												LabelText="Period/No. Of Payment"
												Value={model.PaymentCount}
												Disabled={true}
												Required
												IsInvalid={vModel.PaymentCount} />
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											<GenericNumberInput
												LabelMd={4}
												Name="RecurringAmount"
												LabelText="Recurring Amount"
												Value={model.RecurringAmount}
												ValueChanged={this.ValueChanged}
												MaxLength={10}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.RecurringAmount} />
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											<GenericNumberInput
												LabelMd={4}
												LabelText="Total Amount"
												Value={model.RecurringAmount && model.PaymentCount && model.RecurringAmount * model.PaymentCount}
												Disabled={true} />
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={8}>
											{model.IsHaveMemo ?
												<GenericSelectInput
													key={"RecurringMemo_" + parentModel.UniqueClientId}
													LabelMd={4}
													Name="TransferMemoId"
													LabelText="Transfer Description"
													Method="POST"
													Url="/bankapi/v1.0/BankInteracETransfer/GetMemosByClientId"
													Parameter={parentModel.UniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Memo"
													Value={model.TransferMemoId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled} Sorted={sortMemo} />
												:
												<GenericTextInput LabelMd={4} Name="TransferMemo" inputProps={ParameterMaxLength400} LabelText="Transfer Description" Value={model.TransferMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
											}
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsHaveMemo"
												LabelText="Get Description From List"
												Value={model.IsHaveMemo}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled} />
										</GridItem>
										{model.Id != null &&
											<GridItem xs={8}>
												<GenericDateInput
													LabelMd={4} Name="FreezeStartDate"
													LabelText="Freeze Start Date"
													Value={model.FreezeStartDate}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled}
													Utc
													Required={parentModel.IsUpdate}
													IsInvalid={vModel.FreezeStartDate} />
											</GridItem>
										}
										{model.Id != null &&
											<GridItem xs={8}>
												<GenericDateInput
													LabelMd={4} Name="FreezeEndDate"
													LabelText="Freeze End Date"
													Value={model.FreezeEndDate}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled}
													Utc
													Required={parentModel.IsUpdate}
													IsInvalid={vModel.FreezeEndDate} />
											</GridItem>
										}
										<GridItem xs={8}>
											<ParameterComponent
												LabelMd={4}
												Name="NotificationTypeId"
												Value={model.NotificationTypeId}
												LabelText="Notification Type"
												ParameterCode="NotificationChannel"
												ParameterValue2="ETRANSFER"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.NotificationTypeId} />
										</GridItem>
										<GridItem xs={4} />
									</GridContainer>
								</GridItem>
								<GridItem xs={6}>
									<GridContainer >
										<GridItem xs={8}>
											<ParameterComponent
												LabelMd={4}
												Name="FrequencyType"
												Value={model.FrequencyType}
												LabelText="Frequency Type"
												ParameterCode="ETransferFrequencyType"
												ParameterValue2="1"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.FrequencyType} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												LabelMd={0}
												Name="PeriodType"
												Value={model.PeriodType}
												TitleLabel="Period Type"
												ParameterCode="ETransferPeriodType"
												ParameterValue2={periodParamValue2}
												key={periodParamValue2}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || (model.FrequencyCode == EtransferFrequencyCodes.Daily)}
												Required={model.FrequencyCode == EtransferFrequencyCodes.Weekly || model.FrequencyCode == EtransferFrequencyCodes.Monthly}
												IsInvalid={vModel.PeriodType} />
										</GridItem>
									</GridContainer>
									<div>
										{(model.PeriodType && [EtransferPeriodCodes.DayOfMonth, EtransferPeriodCodes.DayOfWeek, EtransferPeriodCodes.WeekOfMonth].some(x => x == model.PeriodCode)) ?
											<div>
												<GridContainer alignItems="flex-end">
													<GridItem xs={12}>
														<GenericLabel FontSize="12px" TextColor="black" Text="Period Definition" Bold={true} PaddingLeft="1px" PaddingTop="25px" />
													</GridItem>
													<GridItem xs={8}>
														{(model.PeriodCode == EtransferPeriodCodes.DayOfWeek) ?
															<ParameterComponent
																Name="DayOfWeekId"
																Value={model.DayOfWeekId}
																LabelText="Day of Week"
																ParameterCode="EtransferDayOfWeek"
																ValueChanged={this.ValueChanged}
																Disabled={Disabled}
																Required
																IsInvalid={vModel.DayOfWeekId} />
															: null}
														{(model.PeriodCode == EtransferPeriodCodes.DayOfMonth) ?
															<GridContainer >
																<GridItem xs={8}>
																	<ParameterComponent
																		Name="DayOfMonthTypeId"
																		Value={model.DayOfMonthTypeId}
																		LabelText="Day of Month"
																		ParameterCode="ETransferDayOfMonthType"
																		ValueChanged={this.ValueChanged}
																		Disabled={Disabled}
																		LabelMd={6}
																		Required
																		IsInvalid={vModel.DayOfMonthTypeId} />
																</GridItem>
																<GridItem xs={4}>
																	<ParameterComponent
																		Name="DayOfMonthId"
																		Value={model.DayOfMonthId}
																		TitleLabel="Day of Month"
																		ParameterCode="EtransferDayOfMonth"
																		ValueChanged={this.ValueChanged}
																		Disabled={Disabled || (model.DayOfMonthTypeNumber != ETransferDayOfMonthTypes.SelectedDay)}
																		LabelMd={0}
																		Sorted={sortParamValue2}
																		Required={model.DayOfMonthTypeNumber == ETransferDayOfMonthTypes.SelectedDay}
																		IsInvalid={vModel.DayOfMonthId} />
																</GridItem>
															</GridContainer> : null}
														{(model.PeriodCode == EtransferPeriodCodes.WeekOfMonth) ?
															<GridContainer>
																<GridItem xs={8}>
																	<ParameterComponent
																		Name="WeekOfMonthTypeId"
																		Value={model.WeekOfMonthTypeId}
																		LabelText="Week of Month"
																		ParameterCode="ETransferWeekOfMonthType"
																		ValueChanged={this.ValueChanged}
																		Disabled={Disabled}
																		LabelMd={6}
																		Required
																		IsInvalid={vModel.WeekOfMonthTypeId} />
																</GridItem>
																<GridItem xs={4}>
																	<ParameterComponent
																		Name="WeekOfMonthId"
																		Value={model.WeekOfMonthId}
																		TitleLabel="Week of Month"
																		ParameterCode="EtransferDayOfWeek"
																		ValueChanged={this.ValueChanged}
																		Disabled={Disabled}
																		LabelMd={0}
																		Required
																		IsInvalid={vModel.WeekOfMonthId} />
																</GridItem>
															</GridContainer> : null}
													</GridItem>
													<GridItem xs={1}>
														<Tooltip title={this.GetTooltip()}
															classes={{ tooltip: classes.tooltip }}
															TransitionComponent={Zoom}>
															<GenericIcon>help</GenericIcon>
														</Tooltip>
													</GridItem>
												</GridContainer>
											</div> : null}
									</div>
									<GridContainer>
										<GridItem xs={8}>
											{model.IsHaveQuestion ?
												<GenericSelectInput
													key={"RecurringSecurityQuestion_" + parentModel.UniqueClientId}
													LabelMd={4}
													Name="SecurityQuestion"
													LabelText="Security Question"
													Method="POST"
													Url="/bankapi/v1.0/BankInteracETransfer/GetSecurityQuestionsByClientId"
													Parameter={parentModel.UniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="SecurityQuestion"
													Value={model.SecurityQuestion}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || model.TransactionTypeCode != EtransferTransactionTypes.Credit || model.HasDDR}
													Required={model.TransactionTypeCode == EtransferTransactionTypes.Credit && !model.HasDDR}
													IsInvalid={vModel.SecurityQuestion} />
												:
												<GenericTextInput
													LabelMd={4}
													inputProps={ParameterMaxLengthSecurityQuestion}
													Name="SecurityQuestionText"
													LabelText="Security Question"
													Value={model.SecurityQuestionText}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || model.TransactionTypeCode != EtransferTransactionTypes.Credit || model.HasDDR }
													Required={model.TransactionTypeCode == EtransferTransactionTypes.Credit && !model.HasDDR}
													IsInvalid={vModel.SecurityQuestionText} />
											}
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsHaveQuestion"
												LabelText="Get Security Question From List"
												Value={model.IsHaveQuestion}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || model.TransactionTypeCode != EtransferTransactionTypes.Credit || model.HasDDR } />
										</GridItem>
										<GridItem xs={8}>
											<GenericTextInput
												LabelMd={4}
												Name="SecurityQuestionAnswer"
												Value={model.SecurityQuestionAnswer}
												LabelText="Security Question Answer"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || model.TransactionTypeCode != EtransferTransactionTypes.Credit || model.HasDDR }
												inputProps={lengthSecurityAnswer}
												Required={model.TransactionTypeCode == EtransferTransactionTypes.Credit && !model.HasDDR}
												IsInvalid={vModel.SecurityQuestionAnswer} />
										</GridItem>
										<GridItem xs={4}>

										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<GenericDialog open={model.IsCustomerPopupOpen} fullScreen onBackdropClick={this.addCustomerDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Add New Customer" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<AddNewCustomereTransfer ForInsert CustomerSaved={this.addCustomerSaved} menuId={this.props.addNewCustomerMenuId} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.addCustomerDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div>
		);
	}
}

CreateEtransferRecurring.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	vModel: PropTypes.object,
	parentModel: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool,
	addNewCustomerMenuId: PropTypes.number,
	showMessage: PropTypes.func,
	CustomerStoreList: PropTypes.array
};

export default withStyles(createEtransferRecurringStyle)(CreateEtransferRecurring);