import React, { Component } from "react";
import { withArtifex } from "core";
import GridItem from "components/Grid/GridItem";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import {
  GenericTextInput,
  GenericNumberInput,
  GenericDateInput,
  GenericTitle,
  GenericSelectInput,
  GenericEmailInput,
} from "views/Components/Generic";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import DateHelper from "core/DateHelper";
import Tooltip from "react-tooltip-lite";
import { blackColor } from "assets/jss/material-dashboard-pro-react.jsx";
import ParameterComponent from "views/Components/ParameterComponent";
class AddNewCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.marginLeft2 = { marginLeft: 2 };
    this.emptyObject = {};
    this.maxLength6 = 6;
    this.maxLength20 = 20;
    this.maxcardnumberLength11 = 11;
    this.maxLength25 = 25;
    this.maxLength40 = 40;
    this.maxLength128 = 128;
    this.maxLength100 = { maxLength: 100 };
    this.maxLength20 = { maxLength: 20 };
    this.zIndex10 = { zIndex: 10 };
    this.ParameterIdentityType = { ParameterCode: "IdentityType" };
    this.sortedParameterDesc = { Member: "ParameterDesc" };
    this.ParameterClientType = {
      ParameterCode: "ClientType",
      ParameterValue3: "D",
    };
    this.maxLength30 = { maxLength: 30 };
	 this.maxLength60 = { maxLength: 60 };
  }

  render() {
    const {
      customermodel,
      vModel,
      ReadOnly,
      cardTransferType,
      tempModel,
      IsCorporate,
    } = this.props;

    return (
      <Card
        style={{
          border: "1px solid gray",
          borderRadius: "2px",
          marginBlockEnd: "7px",
          overflow: "inherit",
        }}
      >
        <CardBody>
          <GridContainer spacing={16}>
            <GridItem xs={4}>
              <GenericTitle text={"Customer General Information"} />
              <ParameterComponent
                Name="CardTransferTypeId"
                LabelText="Card Transfer Type"
                ParameterCode="CardTransferType"
                ValueChanged={this.props.HandleChangeCustomer}
                Value={customermodel.CardTransferTypeId}
                IsInvalid={vModel.CardTransferTypeId}
                Required
              />
              {cardTransferType == "Mastercard Send" && (
                <GenericSelectInput
                  Name="ClientTypeId"
                  LabelText="Customer Type"
                  Method="POST"
                  Url="/coreapi/v1.0/Parameter/Search"
                  Parameter={this.ParameterClientType}
                  DataRoot="Item"
                  KeyValueMember="Id"
                  TextValueMember="ParameterDesc"
                  Value={customermodel.ClientTypeId}
                  ValueChanged={this.props.HandleChangeCustomer}
                  Required
                  IsInvalid={vModel.ClientType}
                  AfterFetchCallback={this.props.AfterFetchCallback}
                />
              )}
              {IsCorporate == true && cardTransferType != "Visa Direct" ? (
                <div>
                  <GenericTextInput
                    Name="CompanyLegalName"
                    LabelText="Legal Name"
                    Value={customermodel.CompanyLegalName}
                    ValueChanged={this.props.HandleChangeCustomer}
                    // Disabled={tempModel.isPersonal === true || ReadOnly}
                    Required
                    IsInvalid={vModel.CompanyLegalName}
                    inputProps={this.maxLength60}
                  />
                  <GenericTextInput
                    Name="BusinessName"
                    LabelText="Business Name"
                    Value={customermodel.BusinessName}
                    ValueChanged={this.props.HandleChangeCustomer}
                    // Disabled={tempModel.isPersonal === true || ReadOnly}
                    Required
                    IsInvalid={vModel.BusinessName}
                    inputProps={this.maxLength30}
                  />
                </div>
              ) : (
                //   {(IsCorporate == false || cardTransferType == "Visa Direct") && (
                <div>
                  <GenericTextInput
                    Name="FirstName"
                    LabelText="First Name"
                    Value={customermodel.FirstName}
                    ValueChanged={this.props.HandleChangeCustomer}
                    Required
                    IsText
                    IsInvalid={vModel.FirstName}
                    inputProps={{ maxLength: this.maxLength25 }}
                  />
                  <GenericTextInput
                    Name="MiddleName"
                    LabelText="Middle Name"
                    Value={customermodel.MiddleName}
                    ValueChanged={this.props.HandleChangeCustomer}
                    IsText
                    inputProps={{ maxLength: this.maxLength25 }}
                  />
                  <GenericTextInput
                    Name="LastName"
                    LabelText="Last Name"
                    Value={customermodel.LastName}
                    ValueChanged={this.props.HandleChangeCustomer}
                    Required
                    IsInvalid={vModel.LastName}
                    IsText
                    inputProps={{ maxLength: this.maxLength25 }}
                  />
                </div>
              )}

              <GenericNumberInput
                Name="CustomerNumber"
                LabelText="Customer Number"
                Value={customermodel.CustomerNumber}
                ValueChanged={this.props.HandleChangeCustomer}
                NoFormatting={true}
                MaxLength={this.maxcardnumberLength11}
              />
              {/* <GenericTextInput
								Name="ClientCustomerNumber"
								LabelText="Dc Bank Customer Number"
								Value={customermodel.ClientCustomerNumber}
								Disabled
							/> */}

              {(IsCorporate == false || cardTransferType == "Visa Direct") && (
                <GenericDateInput
                  Name="BirthDateValue"
                  LabelText="Date of Birth"
                  Value={
                    customermodel.BirthDateValue
                      ? DateHelper.ToDateInputValue(
                          customermodel.BirthDateValue
                        )
                      : ""
                  }
                  ValueChanged={this.props.HandleChangeCustomer}
                  IncludeTime={false}
                  IsPast={true}
                  Utc={true}
                  Disabled={false}
                  Required
                  IsInvalid={vModel.BirthDateValue}
                />
              )}
              {cardTransferType == "Visa Direct" && (
                <GenericSelectInput
                  Name="IdType"
                  LabelText="Identification Type"
                  Value={customermodel.IdType || ""}
                  ValueChanged={this.props.HandleChangeCustomer}
                  KeyValueMember="Id"
                  TextValueMember="ParameterDesc"
                  Method="POST"
                  Url="/coreapi/v1.0/Parameter/Search"
                  Parameter={this.ParameterIdentityType}
                  DataRoot="Item"
                  Sorted={this.sortedParameterDesc}
                />
              )}
              {customermodel.IdType && (
                <GenericTextInput
                  Name="IdNumber"
                  LabelText="Identity Number"
                  Value={customermodel.IdNumber}
                  ValueChanged={this.props.HandleChangeCustomer}
                  inputProps={{ maxLength: this.maxLength40 }}
                  Required={customermodel.IdType ? true : false}
                  IsInvalid={vModel.IdNumber}
                />
              )}
              {customermodel.IdType && (
                <GenericDateInput
                  Utc
                  Name="IdExpireDateValue"
                  LabelText="Identity Expire Date"
                  Value={
                    customermodel == null
                      ? ""
                      : DateHelper.ToDateInputValue(
                          customermodel.IdExpireDateValue
                        )
                  }
                  ValueChanged={this.props.HandleChangeCustomer}
                  Disabled={false}
                  Required={customermodel.IdType ? true : false}
                  IsInvalid={vModel.IdExpireDateValue}
                />
              )}
            </GridItem>
            <GridItem xs={4}>
              <GenericTitle text={"Communication"} />
              <GridContainer>
                <GridItem xs={8}>
                  <GenericSelectInput
                    Name="PhoneCountryCode"
                    LabelText="Phone Number"
                    TitleLabel="Country Code"
                    LabelMd={6}
                    Method="POST"
                    Url="/coreapi/v1.0/Country/GetAll"
                    Parameter={this.emptyObject}
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember={"Code"}
                    Value={customermodel.PhoneCountryCode}
                    ValueChanged={this.props.HandleChangeCustomer}
                    Sorted={{ Member: "OrderIndex" }}
                    RenderItem={(d) => "+" + d.Code + " (" + d.Name + ")"}
                    Required
                    // IsInvalid={vModel.PhoneCountryCode}
                  />
                </GridItem>
                <GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
                  <GenericNumberInput
                    IsPhone
                    Name="PhoneNumber"
                    Format="(###) ### ####"
                    InputStyle={this.marginLeft2}
                    LabelMd={0}
                    Value={customermodel.PhoneNumber}
                    ValueChanged={this.props.HandleChangeCustomer}
                    MaxLength={10}
                    Required
                    // IsInvalid={vModel.PhoneNumber}
                  />
                </GridItem>
              </GridContainer>
              <GenericEmailInput
                Name="EMail"
                LabelText="E-Mail Address"
                inputProps={{ maxLength: this.maxLength40 }}
                Value={customermodel.EMail}
                ValueChanged={this.props.HandleChangeCustomer}
                Required
                // IsInvalid={vModel.EMail}
              />
              <GenericTextInput
                Name="WatchListVerificationResult"
                LabelText="WatchList Verification Result"
                Disabled={true}
                Value={customermodel.WatchListVerificationResult}
                // Required
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4} lg={4}>
              <GenericTitle text={"Address Information"} />
              <GridItem>
                <GenericTextInput
                  Name="AddressLine1"
                  LabelText="Address Line 1"
                  Id="street-address"
                  Value={customermodel.AddressLine1}
                  ValueChanged={this.props.HandleChangeCustomer}
                  Disabled={false}
                  inputProps={this.maxLength100}
                  IsInvalid={vModel.Address1}
                />
              </GridItem>
              <GridItem>
                <GenericTextInput
                  Name="AddressLine2"
                  Id="street-address2"
                  LabelText="Address Line 2"
                  Value={customermodel.AddressLine2}
                  ValueChanged={this.props.HandleChangeCustomer}
                  Disabled={false}
                  inputProps={this.maxLength100}
                />
              </GridItem>
              <Tooltip
                content="Please Use the 2 Letter abbreviations"
                styles={this.zIndex10}
                background={blackColor}
                color="white"
              >
                <GridItem>
                  <GenericTextInput
                    Name="PostalCode"
                    LabelText="Postal Code / Zip Code"
                    Id="postcode"
                    Value={customermodel.PostalCode}
                    ValueChanged={this.props.HandleChangeCustomer}
                    Disabled={false}
                    inputProps={this.maxLength20}
                  />
                </GridItem>
              </Tooltip>
              <GridItem>
                <GenericSelectInput
                  Name="CountryId"
                  LabelText="Country"
                  Method="POST"
                  Url="/coreapi/v1.0/Country/GetAll"
                  Parameter={{}}
                  DataRoot="Item"
                  KeyValueMember="Id"
                  TextValueMember="Name"
                  Value={customermodel.CountryId}
                  ValueChanged={this.props.HandleChangeCustomer}
                  Disabled={false}
                  Required
                  IsInvalid={vModel.CountryId}
                />
                <GenericSelectInput
                  key={customermodel.CountryId + "province"}
                  Name="ProvinceId"
                  LabelText="Province"
                  Method="POST"
                  Url="/coreapi/v1.0/Province/Filter"
                  Parameter={{
                    CountryId: customermodel.CountryId,
                  }}
                  DataRoot="Item"
                  KeyValueMember="Id"
                  TextValueMember="Name"
                  Value={customermodel.ProvinceId}
                  ValueChanged={this.props.HandleChangeCustomer}
                  Disabled={false}
                  Required
                  IsInvalid={vModel.ProvinceId}
                />

                <GenericSelectInput
                  key={"city" + customermodel.ProvinceId + ""}
                  Name="CityId"
                  LabelText="City"
                  Method="POST"
                  Url="/coreapi/v1.0/City/Filter"
                  Parameter={{
                    ProvinceId: customermodel.ProvinceId,
                  }}
                  DataRoot="Item"
                  KeyValueMember="Id"
                  TextValueMember="Name"
                  Value={customermodel.CityId}
                  ValueChanged={this.props.HandleChangeCustomer}
                  Disabled={false}
                  Required
                  IsInvalid={vModel.CityId}
                />
              </GridItem>
            </GridItem>
            <GridContainer justify="flex-end">
              <GridItem style={{ float: "right", marginRight: "12px" }}>
                <Button
                  size="sm"
                  color="black"
                  onClick={() => this.props.ClearCustomer()}
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  color="black"
                  onClick={
                    customermodel.Id > 0
                      ? () => this.props.UpdateCustomer()
                      : () => this.props.InsertCustomer()
                  }
                >
                  {customermodel.Id > 0 ? "Update" : "Add"}
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default withArtifex(AddNewCustomer, {});
