import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { RoleLevel } from "views/Constants/Constant";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedFullPath, SortedName } from "views/Constants/Constant.Sorted";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class MenuAuthorityRight extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [];

		this.state = {
			model: {
				ParentMenuId: -2,
				MenuId: -2,
				TypeId: -2
			},
			roleDetails: []
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Menu Authority Right");
		}

		var user = ClientHelper.GetUser();

		if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser) {
			this.setState({ isClientStandardUserOpen: true });
		}

		this.GetRoleList();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "ParentMenuId") {
				model["MenuId"] = null;
				model["MenuAuthorityDefinitionId"] = null;
			} else if (name == "MenuId") {
				model["MenuAuthorityDefinition"] = null;
			} else if (name == "TypeId") {
				model["MenuAuthorityDefinition"] = null;
			}

			return { model, roleDetails: [] };
		});
	}

	CellValueChanged = (name, value, index, isRole) => {
		var { roleDetails } = this.state;

		if (isRole) {
			roleDetails[index].RoleRight = value;
		}

		this.setState({ roleDetails });
		this.GetColumns(roleDetails);
	}

	GetColumns = (roleDetails) => {
		this.columns = [
			{
				Header: "Role Name",
				accessor: "RoleName"
			},
			{
				Header: "Role Level",
				accessor: "RoleGroupAndRoleLevel"
			},
			{
				Header: "Role Right",
				Cell: row => (
					<GenericCheckInput
						Name="RoleRight"
						Value={roleDetails[row.index].RoleRight}
						ValueChanged={(name, value) => this.CellValueChanged(name, value, row.index, true)} />
				)
			}
		];

		this.forceUpdate();
	}

	GetSubMenuParameter = () => {
		const { model } = this.state;
		var result = {
			Id: model.ParentMenuId
		};

		return result;
	}

	GetMenuAuthorityDefinitionParameter = () => {
		const { model } = this.state;
		this.parameterMenuAuthorityDefinition = {
			MenuId: model.MenuId,
			TypeId: model.TypeId
		};

		return this.parameterMenuAuthorityDefinition;
	}

	GetRenderItemAction = (x) => {
		var result = `${x.Action.Code} - ${x.Action.Description}`;

		return result;
	}

	GetRoleList = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var user = ClientHelper.GetUser();
		var result = await ExecuteApiPost(`/coreapi/v1.0/Role/${user.Role.RoleLevel.ParameterValue != RoleLevel.ClientAdmin ? "Search" : "ListForClientAdmin"}`, model);

		this.setState({ roleList: result || [] });
	}

	GetMenuName(menu) {
		var menuName = ResourceHelper.GetForMenu(menu.Name);
		var menuNameAndCode = `${!menuName ? "! " + menu.Name : menuName} - ${menu.Code}`;

		return menuNameAndCode;
	}

	Search = async () => {
		var { model, roleList } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/MenuAuthorityRight/ListRoleById", { MenuAuthorityDefinitionId: model.MenuAuthorityDefinitionId }, null, null, () => this.Validate(false));
		var roleDetails = [];

		if (result != null) {
			roleList
				.map(x => {
					var roleRight = false;

					result.RoleIds
						.filter(y => y === x.Id)
						.map(() => {
							roleRight = true;

							return null;
						});

					roleDetails.push({
						"RoleId": x.Id,
						"RoleName": x.RoleName,
						"RoleGroupAndRoleLevel": `${x.RoleGroupParameterDesc} - ${x.RoleLevelParameterDesc}`,
						"RoleRight": roleRight
					});

					return null;
				});

			model["IsParentMenuId"] = false;
			model["IsMenuId"] = false;
			model["IsTypeId"] = false;
			model["IsMenuAuthorityDefinitionId"] = false;

			this.setState({ roleDetails });
			this.GetColumns(roleDetails);
		}
	}

	Submit = () => {
		const { model, roleDetails } = this.state;
		const { ExecuteApiPost } = this.props;

		ExecuteApiPost("/coreapi/v1.0/MenuAuthorityRight/Submit", {
			MenuAuthorityDefinitionId: model.MenuAuthorityDefinitionId,
			MenuAuthorityRightList: roleDetails.map(x => { return { RoleId: x.RoleId, RoleRight: x.RoleRight }; })
		}, null, null, () => this.Validate(true), null, true);
	}

	Clear = () => {
		this.setState({ model: { ParentMenuId: -2, MenuId: -2, TypeId: -2 }, roleDetails: [] });
	}

	Validate = (isSubmit) => {
		var { model, roleDetails } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ParentMenuId || model.ParentMenuId == -2) {
			errors.push("Parent menu cannot be empty.");
		}
		if (!model.MenuId || model.MenuId == -2) {
			errors.push("Menu cannot be empty.");
		}
		if (!model.TypeId || model.TypeId == -2) {
			errors.push("Type cannot be empty.");
		}
		if (!model.MenuAuthorityDefinitionId) {
			errors.push("Description cannot be empty.");
		}
		if (isSubmit && roleDetails.length == 0) {
			errors.push("Role list cannot be empty.");
		}

		model["IsParentMenuId"] = !model.ParentMenuId || model.ParentMenuId == -2;
		model["IsMenuId"] = !model.MenuId || model.MenuId == -2;
		model["IsTypeId"] = !model.TypeId || model.TypeId == -2;
		model["IsMenuAuthorityDefinitionId"] = !model.MenuAuthorityDefinitionId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, roleDetails, isClientStandardUserOpen } = this.state;
		const { history, Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isClientStandardUserOpen} maxWidth="md" TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel Bold Text="Error" />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text="You cannot open this screen." />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Menu Authority Right">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsParentMenuId}
												Name="ParentMenuId"
												LabelText="Parent Menu"
												LabelMd={4}
												Url="/coreapi/v1.0/Menu/GetMenuListForParentMenu"
												Method="GET"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullPath"
												Sorted={SortedFullPath}
												Value={model.ParentMenuId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												Required
												IsInvalid={model.IsMenuAuthorityDefinitionId}
												key={[model.MenuId, model.TypeId]}
												Name="MenuAuthorityDefinitionId"
												LabelText="Description"
												LabelMd={4}
												Url="/coreapi/v1.0/MenuAuthorityDefinition/Search"
												Method="POST"
												Parameter={this.GetMenuAuthorityDefinitionParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Sorted={SortedName}
												Value={model.MenuAuthorityDefinitionId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsMenuId}
												key={model.ParentMenuId}
												Name="MenuId"
												LabelText="Menu"
												LabelMd={4}
												Url="/coreapi/v1.0/MenuAction/GetMenuListByParentMenuId"
												Method="POST"
												Parameter={this.GetSubMenuParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetMenuName}
												Sorted={SortedName}
												Value={model.MenuId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Required
												IsInvalid={model.IsTypeId}
												Name="TypeId"
												LabelText="Type"
												LabelMd={4}
												ParameterCode="MenuAuthorityDefinitionType"
												Value={model.TypeId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													HideButton
													Data={roleDetails}
													Columns={this.columns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MenuAuthorityRight.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default MenuAuthorityRight;