import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericLabel, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";


const initialState = {
	model: {},
	isLoading: false,
	ReadOnly: false
};
class InternalScorecardTab extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };
		this.LoanPurposeParameterCode = { ParameterCode: "LOSLoanPurpose" };

	}

	componentDidMount() {
	}

	HandleClickClearOk = () => {

	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this definition?", () => this.HandleClickClearOk());
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	render() {
		const { isLoading, } = this.state;
		const { QuestionList } = this.props;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<div style={{ marginLeft: 20 }}>
									<GridContainer
										container
										direction="column"
										justify="flex-start"
										alignItems="stretch">
										{QuestionList != undefined && QuestionList.map((question, i) => {
											return (
												<GridContainer
													container
													direction="column"
													justify="flex-start"
													alignItems="stretch"
												>

													<GridContainer
														container
														direction="row"
														justify="flex-start"
														alignItems="stretch"
													>
														<GridItem xs={1}>
															<GenericLabel
																Text={question.Question.ParameterDesc}
																Bold={true}
															></GenericLabel>
														</GridItem>
														{question.AnswerList != undefined && question.AnswerList.map((answer, j, array) => {
															return (
																<GridItem xs={1}
																	style={{
																		border: "1px Solid #000"
																		// borderBottom: "1px Solid #000",

																	}}>
																	<GenericLabel
																		Text={answer.ParameterDesc}
																		Bold={false}
																	></GenericLabel>
																</GridItem>
															);
														})}
													</GridContainer>


													<GridContainer
														container
														direction="row"
														justify="flex-start"
														alignItems="stretch"
													>
														<GridItem xs={1}>
															<GenericLabel
																Text="Score"
															></GenericLabel>
														</GridItem>
														{question.AnswerList != undefined && question.AnswerList.map((answer, j, array) => {
															return (
																<GridItem xs={1}
																	style={{
																		border: "1px Solid #000"

																	}}>
																	<GenericTextInput
																		Name={question.Question.Id + "-" + answer.Id}
																		LabelMd={0}
																		Value={this.props.AnswerValueList && this.props.AnswerValueList[question.Question.Id + "-" + answer.Id]}
																		ValueChanged={this.props.HandleAnswerChange}
																	></GenericTextInput>
																</GridItem>
															);
														})}
													</GridContainer>

												</GridContainer>
											);
										})}

									</GridContainer>
								</div>
							</CardBody>
						</Card>


					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InternalScorecardTab.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	QuestionList: PropTypes.array,
	AnswerValueList: PropTypes.array,
	HandleChange: PropTypes.func,
	HandleAnswerChange: PropTypes.func
};

export default withArtifex(InternalScorecardTab, {});