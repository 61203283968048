import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ArrayHelper from "core/ArrayHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { MaxLength500, MaxLength6 } from "views/Constants/Constant.MaxLength";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";

class Menu extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Parent",
				accessor: "ParentName"
			},
			{
				Header: "Code",
				accessor: "Code"
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Name After Translate",
				accessor: "NameAfterTranslate"
			},
			{
				Header: "Path",
				accessor: "Path"
			},
			{
				Header: "Url",
				accessor: "Url"
			},
			{
				Header: "Icon",
				accessor: "Icon"
			},
			{
				Header: "Order",
				accessor: "Order"
			},
			{
				Header: "Screen",
				accessor: "ScreenCode"
			}
		];

		this.styleCheckInput = { marginTop: 7, marginLeft: -10 };

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Menu");
		}

		this.GetMenuListForParentMenu();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name === "IsBaseMenu" && value) {
				model.ParentId = null;
				model.Path = "";
				model.Url = "";
				model.IsProgramMenu = null;
			} else if (name === "IsBaseMenu" && !value) {
				model.IsProgramMenu = null;
			} else if (name === "IsGenericScreen" && value) {
				model.Path = "";
			} else if (name === "IsGenericScreen" && !value) {
				model.ScreenId = null;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		var model = list[index];
		model.MenuId = list[index].ParentId;

		this.setState({ model, selected: index });
	}

	GetMenuListForParentMenu = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Menu/GetMenuListForMenu", {});
		var menuList = [];

		if (result != null) {
			result.map(x => {
				menuList.push({
					"MenuId": x.Id,
					"FullPath": x.FullPath
				});

				return null;
			});

			ArrayHelper.SortArray(menuList, "FullPath");
		}

		this.setState({ menuList });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Menu/Search", model);

		model["IsParentId"] = false;
		model["IsScreenId"] = false;
		model["IsCode"] = false;
		model["IsName"] = false;
		model["IsIcon"] = false;
		model["IsOrder"] = false;

		this.setState({ list: result || [] });
	}

	Update = () => {
		if (!this.Validate()) {
			return;
		}

		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			Id: model.Id,
			ParentId: model.ParentId,
			Code: model.Code,
			Name: model.Name,
			Path: model.Path,
			Url: model.Url,
			Icon: model.Icon,
			Order: model.Order,
			ScreenId: model.ScreenId,
			ShowInMenu: model.ShowInMenu,
			IsProgramMenu: model.IsProgramMenu
		};

		ExecuteApiPost("/coreapi/v1.0/Menu/Update", request, null, null, this.Validate, [this.GetMenuListForParentMenu, this.Clear, this.Search], true);
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.IsBaseMenu && model.ParentId == null) {
			errors.push("Parent can not be null.");
		}

		if (model.Id != null && model.Id == model.ParentId) {
			errors.push("Parent menu must not equal itself.");
		}

		if (model.IsGenericScreen && model.ScreenId == null) {
			errors.push("Screen can not be null.");
		}

		if (!model.Code) {
			errors.push("Code can not be null.");
		} else if (model.Code.length !== 6) {
			errors.push("Code length must be 6.");
		}

		if (model.Name == null || model.Name === "") {
			errors.push("Name can not be null.");
		}

		if (model.Icon == null || model.Icon === "") {
			errors.push("Icon can not be null.");
		}

		if (model.Order == null || model.Order === "") {
			errors.push("Order can not be null.");
		}

		model["IsParentId"] = !model.IsBaseMenu && model.ParentId == null;
		model["IsScreenId"] = model.IsGenericScreen && model.ScreenId == null;
		model["IsCode"] = !model.Code;
		model["IsName"] = !model.Name;
		model["IsIcon"] = !model.Icon;
		model["IsOrder"] = !model.Order;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, menuList, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/Menu/Insert", model, null, null, this.Validate, [this.GetMenuListForParentMenu, this.Clear, this.Search], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: this.Update
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/coreapi/v1.0/Menu/Delete", { Id: model.Id }, null, null, null, [this.GetMenuListForParentMenu, this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Menu">
									<GridContainer>
										<GridItem xs={3}>
											<GridItem style={this.styleCheckInput}>
												<GenericCheckInput
													Name="IsBaseMenu"
													LabelText="Is Base Menu"
													Value={model.IsBaseMenu}
													ValueChanged={this.ValueChanged} />
											</GridItem>
											<GenericSelectInput
												IsStatic
												Disabled={model.IsBaseMenu}
												Required={!model.IsBaseMenu}
												IsInvalid={model.IsParentId}
												Name="ParentId"
												LabelText="Parent Menu"
												StaticData={menuList}
												KeyValueMember="MenuId"
												TextValueMember="FullPath"
												Value={model.ParentId}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsName}
												Name="Name"
												LabelText="Name"
												inputProps={MaxLength500}
												Value={model.Name}
												ValueChanged={this.ValueChanged} />
											<GridItem style={this.styleCheckInput}>
												<GenericCheckInput
													Name="IsGenericScreen"
													LabelText="Is Generic Screen"
													Value={model.IsGenericScreen}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Required
												IsInvalid={model.IsCode}
												Name="Code"
												LabelText="Menu Code"
												inputProps={MaxLength6}
												Value={model.Code}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={model.IsBaseMenu || model.IsGenericScreen}
												Name="Path"
												LabelText="Path"
												inputProps={MaxLength500}
												Value={model.Path}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsIcon}
												Name="Icon"
												LabelText="Icon"
												inputProps={MaxLength500}
												Value={model.Icon}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												Disabled={!model.IsGenericScreen}
												Required={model.IsGenericScreen}
												IsInvalid={model.IsScreenId}
												Name="ScreenId"
												LabelText="Screen Code"
												Url="/coreapi/v1.0/Screen/GetScreenList"
												Method="GET"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Code"
												Value={model.ScreenId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GridItem style={this.styleCheckInput}>
												<GenericCheckInput
													Disabled={!model.IsBaseMenu}
													Name="IsProgramMenu"
													LabelText="Is Program Menu"
													Value={model.IsProgramMenu}
													ValueChanged={this.ValueChanged} />
											</GridItem>
											<GenericTextInput
												Disabled={model.IsBaseMenu}
												Name="Url"
												LabelText="Url"
												inputProps={MaxLength500}
												Value={model.Url}
												ValueChanged={this.ValueChanged} />
											<GenericNumberInput
												Required
												NoFormatting
												IsInvalid={model.IsOrder}
												Name="Order"
												LabelText="Order"
												MaxLength={4}
												Value={model.Order}
												ValueChanged={this.ValueChanged} />
											<GridItem style={this.styleCheckInput}>
												<GenericCheckInput
													Name="ShowInMenu"
													LabelText="Show In Menu"
													Value={model.ShowInMenu}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Menu.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default Menu;