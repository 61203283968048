import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericTitle, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { ProgramCodes, GridColumnType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";

class ClientForthLineDefinition extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			isLoading: false
		}

		this.emptyObject = {};
		this.isClient = ClientHelper.IsClient();
		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Emboss Forth Line",
				accessor: "EmbossForthLine"
			},
			{
				Header: "InsertDateTime",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Update DateTime",
				accessor: "UpdateDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Client and Product Base 4th Line Definition");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {}, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/ClientForthLineSearch",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/ClientandProductBaseForthLineInsert",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: "Delete",
				dataModel: "ClientForthLineDefinition",
				value: { Id: this.state.model.Id }
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.props.showMessage("success", "Succeeded", "Deleted successfully!");
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var errorList = [];
		const { showValidationErrors } = this.props;

		vModel.EmbossForthLine = model.EmbossForthLine == null || model.EmbossForthLine == "";
		if (vModel.EmbossForthLine) {
			errorList.push("Emboss Forth Line cannot be empty.");
		}

		vModel.ProductId = model.ProductId == null || model.ProductId == "";
		if (vModel.ProductId) {
			errorList.push("Product cannot be empty.");
		}

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		if (list[index].ClientCustomer) {
			model.ClientCustomerId = list[index].ClientCustomer.Id;
			model.CustomerNumber = list[index].ClientCustomer.CustomerNumber;
		}
		this.setState({ isEdit: true, model, index });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	RenderItemProduct = (d) => {
		this.renderItemProduct = d.Name;
		if (d.UniqueClient)
			this.renderItemProduct += ` - (${d.UniqueClient.UniqueClientId} - ${d.UniqueClient.ClientName})`;
		return this.renderItemProduct;
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;


		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0 }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
											<GridItem xs={4}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem xs={4}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
														Disabled={model.UniqueClientId==null || model.UniqueClientId == undefined}
														IsInvalid={vModel.ClientCustomerId} />
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														key={model.UniqueClientId}
														Name="ProductId"
														LabelText="Product"
														Method="POST"
														Url= "/prepaidapi/v1.0/Product/SearchNonCredit"
														Parameter={this.GetProductParameter()}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemProduct}
														Value={model.ProductId}
														ValueChanged={this.HandleChange}
														Required
														Disabled={model.UniqueClientId==null || model.UniqueClientId == undefined}
														IsInvalid={vModel.ProductId} />
												</GridItem>		
												<GridItem xs={4}>
													<GenericTextInput 
														Name="EmbossForthLine" 
														LabelText="Emboss Forth Line" 
														Value={model.EmbossForthLine} 
														ValueChanged={this.HandleChange} 
														Disabled={model.UniqueClientId==null || model.UniqueClientId == undefined}
														Required
														IsInvalid={vModel.EmbossForthLine} />
												</GridItem>		
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Client and Product Base 4th Line Definition List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ClientForthLineDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientForthLineDefinition, {});