import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid } from "views/Components/Generic";
import { NotificationUsageType } from "views/Constants/Constant";

class NotificationDefinitionDataTable extends React.Component {
	constructor(props) {
		super(props);
	}

	RowSelected = (index) => {
		const model = this.props.list[index];
		this.props.handleSelectModel(model, index);
	}

	render() {
		const { classes, list } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12}>
					<GridContainer spacing={16}>
						<GridItem xs={12} style={{ marginTop: 10 }}>
							<GenericGrid
								Data={list}
								Columns={[
									{
										Header: "Program",
										accessor: "ProgramType.Description"
									},
									{
										Header: "Notification Template Type",
										accessor: "NotificationTemplateTypeId",
										Cell: row => (
											<div>{row.original.NotificationTemplateType ? row.original.NotificationTemplateType.Description : ""}</div>
										),
									},
									{
										Header: "Sms",
										accessor: "SendSms",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 100
									},
									{
										Header: "E-Mail",
										accessor: "SendEmail",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 100
									},
									{
										Header: "Application",
										accessor: "SendApplication",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 100
									},
									{
										Header: "Usage Type",
										accessor: "UsageType",
										Cell: row => {
											var usageType = NotificationUsageType.filter(x => x.Value == row.value);
											return <div>{usageType && usageType.length > 0 ? usageType[0].Text : "-"}</div>;
										},
										filterMethod: (filter, row, column) => {
											var usageType = NotificationUsageType.filter(x => x.Text.toLowerCase().startsWith(filter.value.toLowerCase()));
											return usageType && usageType.length > 0;
										},
										width: 150
									}
								]}
								RowSelected={this.RowSelected}
								ShowPagination={true}
								Sorted={[{ id: "NotificationTemplateType.Description", desc: false }]} />
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		);
	}
}

NotificationDefinitionDataTable.propTypes = {
	classes: PropTypes.object,
	list: PropTypes.array,
	selected: PropTypes.number,
	handleSelectModel: PropTypes.func
};

export default NotificationDefinitionDataTable;
