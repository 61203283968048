
const kycManualPhotoDefinitionStyle = {

	profileHeader: {
		margin: "0px 0px 5px 40px"
	},
	mediaProfile: {
		width: "70%",
		margin: "auto",
		height: 200,
		borderRadius: 20,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2,
	},
	imageProfile: {
		width: 323,
		height: 204,
		margin: "auto",
		borderRadius: 20,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2,
	},
	previewTitle:{
		marginLeft:5
	}
};

export default kycManualPhotoDefinitionStyle;