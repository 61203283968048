import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { GridColumnType } from "views/Constants/Constant";

class AlertSettings extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			UniqueClientId: ClientHelper.IsBackOffice() ? -2 : ClientHelper.GetClientRowId()
		};
		this.alertsettingcolumns = [
			{
				Header: "Date",
				accessor: "AlertSettingDate",
				type: GridColumnType.Date
			},
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Definition Type",
				accessor: "DefinitionType.ParameterDesc"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			}
			,
			{
				Header: "Alert Limit",
				accessor: "AlertLimit"
			}
			,
			{
				Header: "Notification Channel",
				accessor: "NotificationChannelType.ParameterDesc"
			},
			{
				Header: "Phone for Notification",
				accessor: "Phone.PhoneNumber"
			},
			{
				Header: "Email for Notification",
				accessor: "EMailforNotification"
			}

		];
		this.emptyObject = {}
		this.sortedCustomerName = { Member: "Name" };
		this.sortedCustomerStore = { Member: "StoreName" };
		this.parameterCustomerStore = {};

		this.state = {
			alertsettinglist: [],
			model: { ...this.initialModel },
			selected: null,
			isLoading: false,
			IsBackOffice: false,
			isOpenDialog: false,
			detailDialog: false,
			vModel:{}
		};
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Alert Settings");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		const { model } = { ...this.state }
		var { isBackOffice } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}
		this.setState({ model, isBackOffice });
	}

	ValueChanged = (name, value) => {

		const model = this.state.model;
		model[name] = value;

		this.setState({ model });
	}

	RowSelected = (index) => {
		const { alertsettinglist } = this.state;

		this.setState({ model: alertsettinglist[index], selected: index });
	}
	DeleteConfirm = () => {
		const { showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", this.Delete);
	}

	GetParameterCustomerStore = () => {
		const { model } = this.state;

		this.parameterCustomerStore.Id = model.UniqueClientId;

		return this.parameterCustomerStore;
	}
	Search = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryAlertSetting/Search",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ alertsettinglist: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Clear = () => {
		this.setState({ model: { ...this.initialModel }, alertsettinglist: [], selected: null });
	}
	Update = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryAlertSetting/Update",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Delete = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryAlertSetting/Delete",
			{
				"Id": model.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryAlertSetting/Insert",
			model
			,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	GetRenderItemPhoneCountryCode = (x) => {
		this.renderItemPhoneCountryCode = "+" + x.Code + " (" + x.Name + ")";

		return this.renderItemPhoneCountryCode;
	}

	Validate() {
		const { model } = { ...this.state };
		const { showMessageNode } = this.props;
		var messages = [];
		const vModel = { ...this.state.vModel };

		if (model.UniqueClientId == -2) {
			messages.push("Client can not be null.");
			vModel.UniqueClientId = true;
		} else{
			vModel.UniqueClientId = false;
		}

		if (!model.DefinitionTypeId) {
			messages.push("Definition Type can not be null.");
			vModel.DefinitionTypeId = true;
		} else{
			vModel.DefinitionTypeId = false;
		}

		if (!model.AlertLimit) {
			messages.push("Alert Limit  can not be null.");
			vModel.AlertLimit = true;
		} else{
			vModel.AlertLimit = false;
		}
		if (!model.NotificationChannelTypeId) {
			messages.push("Notification Channel can not be null.");
			vModel.NotificationChannelTypeId = true;
		} else{
			vModel.NotificationChannelTypeId = false;
		}

		if (!model.ProductId) {
			messages.push("Product Name  can not be null.");
			vModel.ProductId = true;
		} else{
			vModel.ProductId = false;
		}

		if (!model.EMailforNotification) {
			messages.push("e-Mail Notification   can not be null.");
			vModel.EMailforNotification = true;
		} else{
			vModel.EMailforNotification = false;
		}
		this.setState({ vModel });

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}
	handleClose = () => {

		this.setState({ detailDialog: false, openCheckinDialog: false, openTransferDialog: false, openReceiveDialog: false, openOrderDialog: false });
	}
	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, selected, alertsettingcolumns, alertsettinglist, isLoading, detailDialog, IsBackOffice,vModel } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						Disabled: Disabled,
						OnClick: this.Search
					},
					{
						Code: "Submit",
						Disabled: Disabled || selected != null,
						OnClick: this.Submit
					},
					{
						Code: "Update",
						Disabled: Disabled || selected == null,
						OnClick: this.Update
					},
					{
						Code: "Delete",
						Disabled: Disabled || selected == null,
						OnClick: this.DeleteConfirm
					},
					{
						Code: "Clear",
						Disabled: Disabled,
						OnClick: this.Clear
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Disabled={!ClientHelper.IsBackOffice()}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Sorted={this.sortedCustomerName}
												Value={model.UniqueClientId}
												IsInvalid={vModel.UniqueClientId}
												Required
												ValueChanged={this.ValueChanged} />

											<ParameterComponent
												Name="DefinitionTypeId"
												Value={model.DefinitionTypeId}
												LabelText="Definition Type"
												ParameterCode="InventoryAlertDefinitionType"
												IsInvalid={vModel.DefinitionTypeId}
												Required
												ValueChanged={this.ValueChanged} />

										</GridItem>
										<GridItem xs={3}>

											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ProductId"
												LabelText="Product Name"
												Method="POST"
												Url="/prepaidapi/v1.0/Product/Search"
												Parameter={model}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProductName"
												Value={model.ProductId}
												IsInvalid={vModel.ProductId}
												Required
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Name="AlertLimit"
												LabelText="Alert Limit"
												Value={model.AlertLimit}
												IsInvalid={vModel.AlertLimit}
												Required
												ValueChanged={this.ValueChanged}
											>	</GenericTextInput>
										</GridItem>

										<GridItem xs={6}>
											<ParameterComponent
												Name="NotificationChannelTypeId"
												Value={model.NotificationChannelTypeId}
												LabelText="Notification Channel"
												ParameterCode="InventoryNotificationChannelType"
												IsInvalid={vModel.NotificationChannelTypeId}
												Required
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Name="EMailforNotification"
												LabelText="e-Mail Notification"
												Value={model.EMailforNotification}
												IsInvalid={vModel.EMailforNotification}
												Required
												ValueChanged={this.ValueChanged}
											>	</GenericTextInput>
											<GridContainer>
												<GridItem xs={8}>
													<GenericSelectInput
														Name="PhoneCountryCodeId"
														LabelText="Phone"
														LabelMd={6}
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Code"
														RenderItem={this.GetRenderItemPhoneCountryCode}
														Sorted={this.sortedOrderIndex}
														Value={model.PhoneCountryCodeId}
														ValueChanged={this.ValueChanged} />
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput
														Name="PhoneNumber"
														LabelMd={0}
														NoFormatting={true}
														MaxLength={10}
														Value={model.PhoneNumber}
														ValueChanged={this.ValueChanged} />
												</GridItem>
											</GridContainer>

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<GenericExpansionPanel Title="Alert Settings List">
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={alertsettinglist}
														SelectedIndex={selected}
														Columns={this.alertsettingcolumns}
														RowSelected={this.RowSelected} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

AlertSettings.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default AlertSettings;