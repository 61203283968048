import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, MenuCodes, GridColumnType } from "views/Constants/Constant";

class Memo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			memoModel: {},
			alert: null,
			isLoading: false,
			CustomDisabled: null,
			vModel: {}
		};

		this.ParameterMaxLength100 = { maxLength: 100 };
		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ memoModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Date",
				accessor: "MemoDate",
				type: GridColumnType.Date
			},
			{
				Header: "Memo Detail",
				accessor: "Memo"
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Memo");

		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a client or create new one to add memo. ");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ memoModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedMemo, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}
	OperationCheck = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const data = this.state.memoModel;
		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add memo.");
		}
		if (data == null) {
			return;
		}
		if (data.Memo == null || data.Memo == "") {
			vModel.Memo = true;
			ErrorList.push("Memo must be defined !.");
		} else { vModel.Memo = false; }
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.updateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	updateModel = () => {
		var temp = this.state.memoModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;
		temp.MemoTypeId = this.props.memoTypeId;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerMemo/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Client Memo Updated !" : "Client Memo Saved !");
				this.ClearData();
				this.props.GetCustomerMemoModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Memo must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerMemo/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Client memo deleted !");
				this.ClearData();
				this.props.GetCustomerMemoModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);

	}
	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.memoModel || {};
			model[name] = newValue;
			return { memoModel: model };
		});
	}

	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerMemoList[index];
		this.setState({ memoModel: model });
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ memoModel: model, vModel: {} });
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { Disabled: DisabledProp, paramBankCustomerMemoList } = this.props;
		const { memoModel, vModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => {
							if (this.props.isSalesAgentPersonel)
								this.props.handleChangeParentTab(null, 3);
							else if (this.props.MenuCode == MenuCodes.CallCenterClientMonitor)
								this.props.handleChangeParentTab(null, 4);
							else
								this.props.handleChangeParentTab(null, 5);

						}} > Back </Button>
					</GridItem>
				</GridContainer>
				<GridItem xs={12}>
					{this.state.alert}
					<LoadingComponent Show={this.state.isLoading} />

					<Card className="no-radius">
						<GenericExpansionPanel Title={"Memo"}>
							<CardBody>
								<GridContainer>
									<GridItem xs={8} >

									</GridItem>
									<GridItem xs={4}>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<GenericTextInput
											Disabled={Disabled}
											inputProps={this.ParameterMaxLength100}
											Name="Memo"
											LabelMd={0}
											MultiLine={true}
											RowCount={4}
											Value={memoModel.Memo || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.Memo}
										/>
									</GridItem>
									<GridItem xs={9}>

									</GridItem>
									<GridItem xs={3}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear </Button>
												<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{memoModel.Id > 0 ? "Update" : "Add"} </Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>
					</Card>
					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={paramBankCustomerMemoList}
										PageSize={5}
										HideButton={true}
										Columns={this.CloumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

Memo.propTypes = {
	classes: PropTypes.object,
	memoModel: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerMemoList: PropTypes.array,
	GetCustomerMemoModels: PropTypes.func,
	handleChangeParentTab: PropTypes.func
};


export default withArtifex(Memo, {});