import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericDialog, GenericDialogActions, GenericLabel } from "views/Components/Generic";
import { withArtifex } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import StringHelper from "core/StringHelper";
import { GridColumnType } from "views/Constants/Constant";
import GridButton from "views/Components/GridButton";
import { VerificationIcon, DeleteIcon, DetailIcon } from "core/Icons";
import { FinScanSearchStatus, FinScanSearchResultType } from "views/Constants/Constant";
import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class UnverifiedCustomerList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {


			},
			list: [],
			isLoading: false,
			isVerifyDialogOpen: false,
			isDetailManualVerificationDialogOpen: false,
			isDetailUnverifiedDialogOpen: false
		};
		this.parameterClientType = {
			ParameterCode: "ClientType"
		};
		this.parameterClient = {};
		this.trxTable = React.createRef();
		this.columnsDetail = [
			{
				Header: "FullName",
				accessor: "FullName"
			},
			{
				Header: "Gender",
				accessor: "Gender"
			},
			{
				Header: "Nationality",
				accessor: "Nationality"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "PlaceOfBirth",
				accessor: "PlaceOfBirth"
			},
			{
				Header: "DateOfBirth",
				accessor: "DateOfBirth"
			},
			{
				Header: "TextInfo",
				accessor: "TextInfo"
			}
		];
		this.maxLength40 = { maxLength: 40 };
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Unverified Customer List");
		this.props.setAppCenterTitle("AML & KYC");
		this.setState({ loadingCompleted: true });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "UniqueClientId" && newValue == null) {
			model.UniqueClientId = 0;
		}
		this.setState({ model });
	}
	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	ValueChangedForPopup = (name, value, data) => {
		this.setState(state => {
			var model = state.model;

			model[name] = value;

			if (name == "FromDate") {
				model["ToDate"] = undefined;
			}

			return { model };
		});
	}

	validate = () => {
		const { model, vModel } = this.state;
		var messages = [];
		// if (!model.IsUniqueDataSearchMode && (!model.UniqueClientId || model.UniqueClientId <= 0)) {
		// 	vModel.UniqueClientId = true;
		// 	messages.push("Client Name cannot be null!");
		//} else { vModel.UniqueClientId = false; }
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		return true;
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type} OnConfirm={() => this.setState({ alert: null })}
				/>
		});
	}
	handleGetList = () => {
		if (!this.validate())
			return;

		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
		this.setState({ isLoading: false });
	}
	handleClear = () => {
		this.setState({
			model: {},
			list: [],
			isLoading: false
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	GetClientParameter = () => {
		this.parameterClient.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterClient;
	}
	handlePaginationData = (data) => {
		this.setState({ list: data });
	}

	UpdateFinScanSearchLogStatusForUnverified = async () => {
		const { ExecuteApiPost } = this.props;
		var { model } = this.state;
		if(model.ApproveReason == null || model.ApproveReason == ""){
			this.props.showMessage("error", "Error", "Approve reason must be defined.");
			return;
		}
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/UpdateSearchStatus",
			{ CustomerId: model.CustomerId, SearchStatusParameterValue: FinScanSearchStatus.Verified, ApproveReason: model.ApproveReason }, null, null, null, null, true);

		if (result != null) {
			this.handleGetList();
		}
		this.MemoVerifyDialogClose();
	}

	UpdateFinScanSearchLogStatusToRejected = async () => {
		const { ExecuteApiPost } = this.props;
		var { model } = this.state;
		if(model.ApproveReason == null || model.ApproveReason == ""){
			this.props.showMessage("error", "Error", "Approve reason must be defined.");
			return;
		}
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/UpdateSearchStatus",
			{ FinScanSearchLogId: model.FinScanSearchLogId, SearchStatusParameterValue: FinScanSearchStatus.Rejected, ApproveReason: model.ApproveReason }, null, null, null, null, true);

		if (result != null) {
			this.handleGetList();
			this.handleClear();
		}
		this.MemoRejectDialogClose();
	}

	MemoRejectDialogOpen = (finScanSearchLogId) => {
		var { model } = this.state;
		model.FinScanSearchLogId = finScanSearchLogId;

		this.setState({ model, isMemoRejectDialogOpen: true });
	}

	MemoRejectDialogClose = () => {
		this.setState({ isMemoRejectDialogOpen: false });
	}

	DetailUnverifiedDialogOpen = (finScanSearchLogId) => {
		this.GetFinScanSearchLogDetailUnverified(finScanSearchLogId);
		this.setState({ isDetailUnverifiedDialogOpen: true });
	}

	DetailManualVerificationDialogOpen = (finScanSearchLogId) => {
		this.GetFinScanSearchLogDetailManualVerification(finScanSearchLogId);
		this.setState({ isDetailManualVerificationDialogOpen: true });
	}

	GetFinScanSearchLogDetailUnverified = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLogDetail/GetDetailBySearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			var { model } = this.state;

			model = result;

			this.setState({ model });
		}
	}

	GetFinScanSearchLogDetailManualVerification = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/GetPartialMatchListByFinScanSearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			this.SetListPartialMatches(result);
		} else {
			this.setState({ listListPartialMatches: []});
		}
	}

	SetListPartialMatches = (searchResult) => {
		var listListPartialMatches = searchResult;

		for (let i = 0; i < searchResult.length; i++) {
			searchResult[i].JsonResultDataParsed.map(x => {
				listListPartialMatches[i][x.Key] = x.Value;

				return null;
			});
		}

		this.setState({ listListPartialMatches });
	}

	DetailUnverifiedDialogClose = () => {
		this.setState({ isDetailUnverifiedDialogOpen: false });
	}

	DetailManualVerificationDialogClose = () => {
		this.setState({ isDetailManualVerificationDialogOpen: false });
	}

	MemoVerifyDialogOpen = (customerId) => {
		var { model } = this.state;
		model.CustomerId = customerId;

		this.setState({ model, isMemoVerifyDialogOpen: true });
	}

	MemoVerifyDialogClose = () => {
		this.setState({ isMemoVerifyDialogOpen: false });
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, vModel, isMemoRejectDialogOpen, isDetailUnverifiedDialogOpen, isDetailManualVerificationDialogOpen, listListPartialMatches, isMemoVerifyDialogOpen } = this.state;

		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client Name"
										Value={model == null ? "" : model.UniqueClientId || ""}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Name"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Method="POST"
										Parameter={{
											UniqueClientId: 0,
										}}
										Required={!model.IsUniqueDataSearchMode}
										IsInvalid={vModel.UniqueClientId}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientTypeId"
										LabelText="Customer Type"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterClientType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.ClientTypeId}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										Name="ClientCustomerNumber"
										LabelText="Customer Number"
										Value={model.ClientCustomerNumber}
										ValueChanged={this.handleChange}
										MaxLength={50}
										NoFormatting={true} />
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Name="CustomerName"
										LabelText="Name"
										Value={model.CustomerName}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Unverified Customer List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									ShowPagination={true}
									PageSize={10}
									IsSorted={false}
									ServerSide
									PaginationFetchDataCallback={this.handlePaginationData}
									LoadAtStartup={this.state.loadingCompleted}
									RequestUrl="/amlkycapi/v1.0/FinScanSearchLog/SearchUnverifiedCustomerList"
									RequestMethod="POST"
									PrepareRequest={() => {
										const { model } = this.state;
										if (this.props.MenuCode != null) {
											model.MenuCode = this.props.MenuCode;
										}
										return model;
									}}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											Cell: row => (
												<div>
													<GridButton
														Disabled={row.original.SearchStatusParameterValue == FinScanSearchStatus.Verified}
														tooltip="Verify"
														Icon={VerificationIcon}
														OnClick={() => this.MemoVerifyDialogOpen(row.original.Id)} />
													<GridButton
														Disabled={row.original.SearchStatusParameterValue == FinScanSearchStatus.Verified || row.original.SearchStatusParameterValue == FinScanSearchStatus.Rejected}
														tooltip="Reject"
														Icon={DeleteIcon}
														OnClick={() => this.MemoRejectDialogOpen(row.original.FinScanSearchLogId)} />
													<GridButton
														Disabled={row.original.SearchStatusParameterValue == FinScanSearchStatus.Verified}
														tooltip="Detail"
														Icon={DetailIcon}
														OnClick={() => row.original.SearchResultParameterValue == FinScanSearchResultType.ListExactMatch
															? this.DetailUnverifiedDialogOpen(row.original.FinScanSearchLogId) : 
															this.DetailManualVerificationDialogOpen(row.original.FinScanSearchLogId) }/>
												</div>
											),
											width: 100
										},
										{
											Header: "Client Name",
											accessor: "ClientName",
										},
										{
											Header: "Client Customer No.",
											accessor: "ClientCustomerNumber"
										},
										{
											Header: "First Name",
											accessor: "Name",
											Cell: row => (
												row.value ? StringHelper.Capitalize(row.value, true) : ""
											)
										},
										{
											Header: "Middle Name",
											accessor: "MiddleName",
											Cell: row => (
												row.value ? StringHelper.Capitalize(row.value, true) : ""
											)
										},
										{
											Header: "Last Name",
											accessor: "SurName",
											Cell: row => (
												row.value ? StringHelper.Capitalize(row.value, true) : ""
											)
										},
										{
											Header: "E-Mail",
											accessor: "Email",
											Cell: row => (
												row.value ? String(row.value).toLowerCase() : ""
											),
											width: 150
										},
										{
											Header: "Corporate Name",
											accessor: "CompanyLegalName",
											Cell: row => (
												row.value ? StringHelper.Capitalize(row.value, true) : ""
											)
										},
										{
											Header: "Insert Date",
											accessor: "InsertDateTime",
											type: GridColumnType.DateTime
										},
										{
											Header: "Watchlist Status",
											accessor: "WatchlistStatus"
										},
										{
											Header: "Last Updated User",
											accessor: "UpdateUser"
										},
										{
											Header: "Last Update Date",
											accessor: "UpdateDateTime",
											type: GridColumnType.DateTime
										},
										{
											Header: "Program",
											accessor: "Program",
										},
										{
											Header: "File Name",
											accessor: "FileName",
										},
										{
											Header: "Watchlist Capture Date",
											accessor: "WatchlistCaptureDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Last Review Date",
											accessor: "LastReviewDate",
											type: GridColumnType.DateTime
										}
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

				<GenericDialog open={isMemoRejectDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.MemoRejectDialogClose} TransitionComponent={Transition}>
					<DialogContent style={{ height: "auto", marginTop: 8 }}>
							<GenericTextInput 
								Name="ApproveReason" 
								LabelText="Reject Reason" 
								Value={model.ApproveReason} 
								ValueChanged={this.ValueChangedForPopup} 
								Required
								inputProps={this.maxLength40}
								 />
					</DialogContent>
					<GenericDialogActions style={{ marginTop: 5 }}>
						<Button size="sm" onClick={this.MemoRejectDialogClose}>Close</Button>
						<Button size="sm" onClick={this.UpdateFinScanSearchLogStatusToRejected}>Reject</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isMemoVerifyDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.MemoVerifyDialogClose} TransitionComponent={Transition}>
					<DialogContent style={{ height: "auto", marginTop: 8 }}>
							<GenericTextInput 
								Name="ApproveReason" 
								LabelText="Approve Reason" 
								Value={model.ApproveReason} 
								ValueChanged={this.ValueChangedForPopup} 
								Required
								inputProps={this.maxLength40}
								 />
					</DialogContent>
					<GenericDialogActions style={{ marginTop: 5 }}>
						<Button size="sm" onClick={this.MemoVerifyDialogClose}>Close</Button>
						<Button size="sm" onClick={this.UpdateFinScanSearchLogStatusForUnverified}>Verify</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isDetailUnverifiedDialogOpen} maxWidth="md" fullWidth onBackdropClick={this.DetailUnverifiedDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Unverified Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Text Info"
								Value={model.TextInfo}
								MultiLine />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Nationality"
								Value={model.Nationality} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Place Of Birth"
								Value={model.Country} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Gender"
								Value={model.Gender} />
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailUnverifiedDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isDetailManualVerificationDialogOpen} maxWidth="lg" fullWidth onBackdropClick={this.DetailManualVerificationDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GridContainer >
							<GridItem xs={12}>
								<GridContainer spacing={16}>
								<GridItem xs={12}>
									<GenericLabel
										Bold
										Text="Similar Customers List"
										FontSize="16px"
										TextColor="black"
										/>
									<br />
									<br />
									<GenericGrid
										HideButton
										Data={listListPartialMatches}
										Columns={this.columnsDetail} />
								</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailManualVerificationDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

UnverifiedCustomerList.propTypes = {
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};


export default withArtifex(UnverifiedCustomerList, {});