import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericMultipleSelectInput, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { Direction, GridColumnType, MenuCodes } from "views/Constants/Constant";
import Select from "react-select";
import { Proxy } from "core";

class EftClearingTransactionSearch extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			ClearingStarDate: DateHelper.GetDate(),
			ClearingEndDate: DateHelper.GetDate()
		};

		this.state = {
			model: { ...this.initialModel },
			dailyFileList: [],
			LogicalRecord: [],
			fileInfoModel: {},
			isLoading: false,
			openDialog: false,
			isDownloadDialogOpen: false,
			ExportReportType: null
		};

		this.index = 0;
		this.sortParamValue = { Member: "ParameterValue" };
		this.trxTable = React.createRef();
		this.ColumnsdailyFileList = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip={row.original.ProcessStatus?.ParameterValue ? "Detail" : ""}
							Disabled={row.original.Direction?.ParameterValue == Direction.Incoming && (row.original.LogicalRecordType == "C" || row.original.LogicalRecordType == "D") ? false : row.original.Direction?.ParameterValue == Direction.Outgoing && (row.original.LogicalRecordType == "J" || row.original.LogicalRecordType == "I") ? false : true}
							Icon={DetailIcon}
							OnClick={() => { this.HandleChangeActiveStatus(row.original); }} />
					</div>
				)
			},
			{
				Header: "Logical Record Type",
				accessor: "LogicalRecordType",
				Cell: row => (
					<div>	{
						row.value == "C" ? "Credit" : row.value == "D" ? "Debit" : row.value == "I" ? "Return of Credit" : row.value == "J" ? "Return of Debit" : "-"}</div>

				)
			},
			{
				Header: "Logical Record Count",
				accessor: "LogicalRecordCount",
			},
			{
				Header: "Originator",
				accessor: "OriginatorId",
			},
			{
				Header: "File Creation Number",
				accessor: "FileCreationNumber",
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientId"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Eft Transaction Type",
				accessor: "TransactionType",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Transaction Description",
				accessor: "TransactionDesc"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "TransactionDate",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "PayeeDraweeInstitutionId",
				accessor: "PayeeDraweeInstitutionId"
			},
			{
				Header: "PayeeDraweeAccountNumber",
				accessor: "PayeeDraweeAccountNumber"
			},
			{
				Header: "Item Trace No",
				accessor: "ItemTraceNo",

			},
			{
				Header: "Stored Transaction Type",
				accessor: "StoredTransactionType",
			},
			{
				Header: "Originator Short Name",
				accessor: "OriginatorShortName"
			},
			{
				Header: "Payee Drawee Name",
				accessor: "PayeeDraweeName"
			},
			{
				Header: "Originator Long Name",
				accessor: "OriginatorLongName"
			},
			{
				Header: "Originating Direct Clearer User",
				accessor: "OriginatingDirectClearerUserId"
			},
			{
				Header: "Cross Reference Number",
				accessor: "CrossReferenceNumber"
			},
			{
				Header: "InstitutionId For Returns",
				accessor: "InstitutionIdForReturns"
			},
			{
				Header: "Wallet Number For Returns",
				accessor: "AccountNumberForReturns"
			},
			{
				Header: "Originator Sundry Information",
				accessor: "OriginatorSundryInformation"
			},
			{
				Header: "Originator Direct Clearer SettlementCode",
				accessor: "OriginatorDirectClearerSettlementCode"
			},
			{
				Header: "Invalid Data Element",
				accessor: "InvalidDataElement"
			},
			{
				Header: "File Information",
				accessor: "FileInformation"
			},
			{
				Header: "Direction",
				accessor: "Direction.ParameterDesc"
			},
			{
				Header: "Clearing Date",
				accessor: "ClearingDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Process Status",
				accessor: "ProcessStatus.ParameterDesc"
			},
			{
				Header: "Match Status",
				accessor: "MatchStatus.ParameterDesc"
			},
			{
				Header: "Validation Status",
				accessor: "ValidationStatus.ParameterDesc"
			},
			{
				Header: "ValidationDescription",
				accessor: "ValidationDescription"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionTypeTrnx.ParameterDesc"
			},
			{
				Header: "Eft Processor",
				accessor: "EftProcessor.ParameterDesc"
			},
		];
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle) {
			if (this.props.MenuCode == MenuCodes.Menu_Backoffice_EftClearingTransactionDdt ||
				this.props.MenuCode == MenuCodes.Menu_Client_EftClearingTransactionDdt) {
				setAppLeftTitle("DDT Clearing Transaction Search");
			} else {
				setAppLeftTitle("EFT Clearing Transaction Search");
			}
		}

		if (setAppCenterTitle) {
			if (this.props.MenuCode == MenuCodes.Menu_Backoffice_EftClearingTransactionDdt ||
				this.props.MenuCode == MenuCodes.Menu_Client_EftClearingTransactionDdt) {
				setAppCenterTitle("DDT MANAGEMENT");
			} else if (this.props.MenuCode == MenuCodes.EftClearingTransactionEftClient) {
				setAppCenterTitle("EFT SYSTEM");
			} else {
				setAppCenterTitle("CLEARING & SETTLEMENT");
			}
		}

		this.parameterSearch();
		this.setState({ loadingCompleted: true });


	}

	Validate(isTemplateCreate) {
		const { fileInfoModel } = this.state;

		var messages = [];
		if (fileInfoModel.TransactionType == null || fileInfoModel.TransactionType == "") {
			messages.push("Transaction Type can not be null.");
		}
		if (messages.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ValidateSearchRequest = () => {

		const { model } = this.state;

		var messages = [];

		if (!model.CrossReferenceNumber && !model.AccountNumber) {

			if (!model.Amount && (this.props.MenuCode != MenuCodes.Menu_Client_EftClearingTransactionDdt && this.props.MenuCode != MenuCodes.EftClearingTransactionEftClient)) {
				if (!model.UniqueClientId) {
					messages.push("Client not selected");
				}
			}

			if (!model.ClearingStarDate) {
				messages.push("Clearing Start Date not selected");
			}

			if (!model.ClearingEndDate) {
				messages.push("Clearing End Date not selected");
			}

			if (model.ClearingStarDate && model.ClearingEndDate) {
				var diffMonths = model.ClearingEndDate.diff(model.ClearingStarDate, "days");
				if (diffMonths < 0) {
					messages.push("Clearing End Date must be later than Clearing Start Date");
				}

				if (diffMonths > 30) {
					messages.push("Difference between Clearing Start Date and Clearing End Date cannot be longer than 30 days");
				}
			}
		}

		if (messages.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	HandleClearCancel = () => {
		this.setState({ openDialog: false });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.props.showMessage("warning", "File Information", "FileInformationId can not be null");
		else {
			this.setState({ fileInfoModel: tempModel, model, isLoading: false, rowIndex: index });
		}
	}

	parameterSearch = async () => {
		const { ExecuteApiPost } = this.props;
		var responseData = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "LogicalRecord" });
		this.setState({ LogicalRecord: responseData });
	}

	GetDailyFileList = () => {
		if (!this.ValidateSearchRequest())
			return;

		this.setState(state => ({ trxGridToken: !state.trxGridToken }));

	}

	handleUpdate = async () => {
		if (!this.Validate(true))
			return;

		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var fileInfoModel = { ...this.state.fileInfoModel };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/EftClearingTransactionUpdate", {
			Id: fileInfoModel.Id,
			TransactionType: fileInfoModel.TransactionType,
			LogicalRecordType: fileInfoModel.LogicalRecordType
		});

		if (responseData != null) {
			this.props.showMessage("success", "File Information", "Transaction Type Update");
			this.setState({
				isLoading: false, openDialog: false
			});
			this.GetDailyFileList();
		}
		else {
			this.setState({ isLoading: false });
		}
	}

	returnInsert = async () => {

		this.setState({ isLoading: true });
		var fileInfoModel = { ...this.state.fileInfoModel };

		if (fileInfoModel.StoredTransactionType == null || fileInfoModel.StoredTransactionType == "") {
			this.props.showMessage("warning", "Please fill required fields!", "Return Reason Code can not be null");
			this.setState({ isLoading: false });
			return false;
		}

		const { ExecuteApiPost } = this.props;
		var responseData = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/EftClearingTransactionReturnInsert", {
			Id: fileInfoModel.Id,
			StoredTransactionType: fileInfoModel.StoredTransactionType,
			LogicalRecordType: fileInfoModel.LogicalRecordType
		});

		if (responseData != null) {
			this.props.showMessage("success", "File Information", "Transaction Completed");
			this.setState({
				isLoading: false, openDialog: false
			});
			this.GetDailyFileList();
		}
		else {
			this.setState({ isLoading: false });
		}
	}

	handleChange = (name, newValue, data) => {
		if (name == "ExportReportType") {
			this.setState({
				ExportReportType: newValue
			});
			return;
		}
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "LogicalRecordId") {
			model[name] = newValue.map((x) => x.value);
		}

		this.setState({ model });
	}

	handleFileinfoChange = (name, newValue, data) => {
		const fileInfoModel = { ...this.state.fileInfoModel };
		fileInfoModel[name] = newValue;
		this.setState({ fileInfoModel });
	}

	ClearModel = () => {
		this.index++;
		this.setState({
			model: { ...this.initialModel }, fileInfoModel: {}
		});
	}

	HandleChangeActiveStatus = (model) => {
		this.setState({ openDialog: true, fileInfoModel: model });
	}

	DialogClose = () => {
		this.setState({ openDialog: false });
	}

	RenderTransactionCode(d) {
		const transactionCode = d.ParameterValue;
		const transactionCodeDescription = d.ParameterDesc;

		return `${transactionCode} - ${transactionCodeDescription}`;
	}
	GetExportButtonDialog() {
		return (<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
			<DialogTitle>
				<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
			</DialogTitle>
			<DialogContent style={{ height: 300 }}>
				<GenericRadioInput
					Name="ExportReportType"
					LabelText="Report Type"
					IsStatic={true}
					StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
					KeyValueMember="Value"
					TextValueMember="Text"
					Value={this.state.ExportReportType}
					ValueChanged={this.handleChange}
				/>
				<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
				<GridItem style={{ marginTop: 20 }}>
					<Select
						value={this.state.ExportSelectedColumns}
						onChange={value => this.setState({ ExportSelectedColumns: value })}
						isMulti
						name="columns"
						getOptionLabel={d => d.title}
						options={this.state.GridKeyValueList}
						className="basic-multi-select"
						classNamePrefix="select"
					/>
				</GridItem>
			</DialogContent>
			<GenericDialogActions>
				<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
				<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
			</GenericDialogActions>
		</GenericDialog>
		)
	}

	ExportClick = () => {
		if (!this.ValidateSearchRequest())
			return;
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);
		console.log(this.trxTable);
		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "EFT Clearing Transaction Search";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/BankBmoEFT/EftClearingTransactionExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	handlePaginationData = (data) => {
		this.setState({ dailyFileList: data });
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, openDialog, fileInfoModel } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<div>
					<GenericDialog open={openDialog} maxWidth="lg" onBackdropClick={this.DialogClose} fullWidth>
						<GridContainer justify="space-between" spacing={16}>
							<GridItem xs={12}>
								<Card>
									<CardBody>
										<DialogTitle>
											<GenericLabel Text="Eft Clearing Information" Bold={true} />
										</DialogTitle>
										<DialogContent>
											<GridContainer>
												<GridItem xs={4}>
													{this.props.MenuCode != MenuCodes.Menu_Client_EftClearingTransactionDdt && this.props.MenuCode != MenuCodes.EftClearingTransactionEftClient && <GridItem>
														<GenericSelectInput
															Name="UniqueClientId"
															LabelText="Client"
															Method="POST"
															Url="/bankapi/v1.0/BankCustomer/Search"
															Parameter={{}}
															DataRoot="Item"
															KeyValueMember="Id"
															RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
															Value={fileInfoModel.UniqueClientId || ""}
															ValueChanged={this.handleChange}
															CanClear
															All
															Disabled />
													</GridItem>}
													{this.props.MenuCode != MenuCodes.EftClearingTransactionEftClient && <GridItem>

														<ParameterComponent
															Name="DirectionId"
															LabelText="Direction"
															ParameterCode="Direction"
															ParameterValue2="ETRANSFER"
															Value={fileInfoModel.DirectionId}
															ValueChanged={this.handleChange}
															Disabled
														/>
													</GridItem>}
													<GridItem>
														<ParameterComponent
															Name="EftProcessorId"
															LabelText="Processor"
															ParameterCode="EftProcessor"
															Value={fileInfoModel.EftProcessorId}
															ValueChanged={this.handleChange}
															Disabled
														/>
													</GridItem>
												</GridItem>
												<GridItem xs={4}>
													<GridItem>
														<ParameterComponent
															Name="ProcessStatusId"
															LabelText="Process Status"
															ParameterCode="ProcessStatus"
															Value={fileInfoModel.ProcessStatusId}
															ValueChanged={this.handleChange}
															Disabled
														/>
													</GridItem>
													<GridItem>
														{fileInfoModel.LogicalRecordType == "J" || fileInfoModel.LogicalRecordType == "I" ?
															<GenericSelectInput
																Name="TransactionType"
																LabelText="Reason Code"
																Value={fileInfoModel.TransactionType}
																DataRoot="Item"
																ValueChanged={this.handleFileinfoChange}
																KeyValueMember="ParameterValue"
																TextValueMember="ParameterDesc"
																Url="/coreapi/v1.0/Parameter/Search"
																Method="POST"
																RenderItem={d => d.ParameterValue + " (" + d.ParameterDesc + ")"}
																Parameter={{ ParameterCode: "EFTTransactionType", ParameterValue2: "ReturnReason" }}
															/>
															:
															<GenericSelectInput
																Name="StoredTransactionType"
																LabelText="Return Reason Code"
																Value={fileInfoModel.StoredTransactionType}
																DataRoot="Item"
																ValueChanged={this.handleFileinfoChange}
																KeyValueMember="ParameterValue"
																TextValueMember="ParameterDesc"
																Url="/coreapi/v1.0/Parameter/Search"
																Method="POST"
																RenderItem={d => d.ParameterValue + " (" + d.ParameterDesc + ")"}
																Parameter={{ ParameterCode: "EFTTransactionType", ParameterValue2: "ReturnReason" }}
															/>
														}
													</GridItem>
												</GridItem>
											</GridContainer>
											<br />
											<br />
											<br />
											<br />
										</DialogContent>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
						<GenericDialogActions>
							{(fileInfoModel.LogicalRecordType == "J" || fileInfoModel.LogicalRecordType == "I") && (fileInfoModel.Direction?.ParameterValue == Direction.Outgoing) ? <Button size="sm" onClick={this.handleUpdate}>UPDATE</Button> : (fileInfoModel.LogicalRecordType == "C" || fileInfoModel.LogicalRecordType == "D") && (fileInfoModel.Direction?.ParameterValue == Direction.Incoming) ? <Button size="sm" onClick={this.returnInsert}>RETURN</Button> : ""}
							<Button size="sm" onClick={this.HandleClearCancel}>CANCEL</Button>
						</GenericDialogActions>
					</GenericDialog>
				</div >
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					{this.GetExportButtonDialog()}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											{this.props.MenuCode != MenuCodes.Menu_Client_EftClearingTransactionDdt && this.props.MenuCode != MenuCodes.EftClearingTransactionEftClient && <GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
												/>
											</GridItem>}
											{this.props.MenuCode != MenuCodes.EftClearingTransactionEftClient && <GridItem>
												<ParameterComponent
													Name="DirectionId"
													LabelText="Direction"
													ParameterCode="Direction"
													ParameterValue2="ETRANSFER"
													Value={model.DirectionId}
													ValueChanged={this.handleChange}
												/>
											</GridItem>}
											<GridItem>
												<ParameterComponent
													Name="EftProcessorId"
													LabelText="Processor"
													ParameterCode="EftProcessor"
													Value={model.EftProcessorId}
													ValueChanged={this.handleChange}
												/>
											</GridItem>
											<GridItem >
												<GenericMultipleSelectInput
													TitleLabel={"Logical Record"}
													Name={"LogicalRecordId"}
													Loading={false}
													LabelText={"Logical Record"}
													KeyValueMember="Id"
													SelectValue="Id"
													key={this.index}
													Value={model.LogicalRecordId}
													HandleChange={this.handleChange}
													RenderItem={d => `${d.ParameterDesc}`}
													StaticData={this.state.LogicalRecord}
												/>
											</GridItem>
											{this.props.MenuCode == MenuCodes.EftClearingTransactionEftClient && <GridItem>
												<ParameterComponent
													Name="ProcessStatusId"
													LabelText="Process Status"
													ParameterCode="ProcessStatus"
													Value={model.ProcessStatusId}
													ValueChanged={this.handleChange}
												/>
											</GridItem>}
										</GridItem>
										<GridItem xs={4}>
											{this.props.MenuCode != MenuCodes.EftClearingTransactionEftClient && <GridItem>
												<ParameterComponent
													Name="ProcessStatusId"
													LabelText="Process Status"
													ParameterCode="ProcessStatus"
													Value={model.ProcessStatusId}
													ValueChanged={this.handleChange}
												/>
											</GridItem>}
											<GridItem>
												<GenericDateInput
													Name="ClearingStarDate"
													LabelText="Clearing Start Date"
													Value={model.ClearingStarDate}
													ValueChanged={this.handleChange}
													MaxDate={DateHelper.GetDate()}
													Utc
												/>
												<GenericDateInput
													Name="ClearingEndDate"
													LabelText="Clearing End Date"
													Value={model.ClearingEndDate}
													ValueChanged={this.handleChange}
													MinDate={model.ClearingStarDate}
													MaxDate={DateHelper.GetDate()}
													Utc
												/>
												<GenericNumberInput
													Name="Amount"
													LabelText="Amount"
													Value={model.Amount}
													Prefix="$"
													ValueChanged={this.handleChange}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													Name="CrossReferenceNumber"
													LabelText="Cross Reference Number"
													Value={model == null || undefined ? "" : model.CrossReferenceNumber || ""}
													ValueChanged={this.handleChange}
												/>
												<GenericDateInput
													Name="TransactionDate"
													LabelText="Transaction Date"
													Value={model.TransactionDate}
													ValueChanged={this.handleChange}
													Utc
												/>
												<GenericNumberInput
													NoFormatting={true}
													Name="AccountNumber"
													LabelText="Wallet Number"
													Value={model.AccountNumber}
													ValueChanged={this.handleChange}
													Disabled={Disabled}
													MaxLength={12}
												/>
												<ParameterComponent
													Name="TransactionCodeId"
													LabelText="Transaction Code"
													ParameterCode="EFTTransactionType"
													RenderItem={this.RenderTransactionCode}
													Value={model.TransactionCodeId}
													ValueChanged={this.handleChange}
													Disabled={Disabled}
													Sorted={this.sortParamValue}
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4} />
											<GridItem xs={4} />
											<GridItem xs={4}>
												<GridContainer justify="flex-end">
													<GridItem xs={3} />
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ref={this.trxTable}
													ServerSide
													RequestUrl="/bankapi/v1.0/BankBmoEFT/EftClearingTransactionSearchPaged"
													RequestMethod="POST"
													PrepareRequest={() => {
														const { model } = this.state;
														if (this.props.MenuCode != null) {
															model.MenuCode = this.props.MenuCode;
														}
														return model;
													}}
													PageSize={10}
													ShowPagination={true}
													LoadAtStartup={this.state.loadingCompleted}
													ValidateRequest={this.ValidateSearchRequest}
													Columns={this.ColumnsdailyFileList}
													RowSelected={this.SelectedRowChange}
													SelectedIndex={this.state.rowIndex}
													PaginationFetchDataCallback={this.handlePaginationData} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

EftClearingTransactionSearch.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};
export default withArtifex(EftClearingTransactionSearch, {});