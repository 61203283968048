import "assets/scss/material-dashboard-pro-react.css?v=1.3.0";
import { Proxy } from "core";
import ResourceHelper from "core/ResourceHelper";
import { createHashHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "store";
import App from "./App";

const hist = createHashHistory();
hist.listen(location => {
	console.log(location.pathname); // /home
	Proxy.POST(
		"/coreapi/v1.0/User/InsertUserPageHistory",
		{ Path: location.pathname }
	);
});

const render = () => {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>
		,
		document.getElementById("root")
	);
};

const isHaveAnyResources = ResourceHelper.Any();

if (isHaveAnyResources) {
	ResourceHelper.SetLanguage(null);
	render();
}
else {
	ResourceHelper.SetLanguage(null, render);
}