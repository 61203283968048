import { List } from "@material-ui/core";
import { chartColors, chartGradientColors } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { Bar, Chart } from 'react-chartjs-2';
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import cx from "classnames"

const styles = ({
	circleBoxGrid: {
		width: "14%",
		textAlign: "center"
	},
	circleBox: {
		// display: "inline-block",
		// marginTop: "24px",
		// marginLeft: "auto",
		// marginRight: "auto",
		// marginBottom: "24px"
		"& > canvas": {
			width: "108% !important",
			height: 400
		}
	}, circleBoxMain: {

		"& > canvas": {
			width: "80% !important",
			height: 400
		}
	}
});
class CardBrandStaticsReport extends React.Component {
	constructor(props) {
		super(props);
		this.doughnutHeight = 500;
		this.doughnutWidth = 400;
		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			CreateDateTime: null
		};

		this.HandleRequest = null;
		this.state = {
			model: this.initialModel,
			cardBrand: { ... this.initialDoughnutDataModel },
			cardTransaction: { ... this.initialDoughnutDataModel },
			totalOnhold: { ... this.initialDoughnutDataModel },
			totalReturned: { ... this.initialDoughnutDataModel },
			totalRejected: { ... this.initialDoughnutDataModel },
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1,
		};


		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: true,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			},
			tooltips: { enabled: true }
		};

		this.initialDoughnutDataModel = {
			labels: ["Transactions"],
			datasets: [{
				data: [1],
				backgroundColor: "rgb(255, 99, 132)",
				hoverBackgroundColor: "rgb(255, 99, 132)"
			}],
			text: "0 \t $ 0"
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: { display: false },
			cutoutPercentage: 90,
			animation: { duration: 2000 }
		};

		this.chartColors = {
			red: "rgb(255, 99, 132)",
			orange: "rgb(255, 159, 64)",
			yellow: "rgb(255, 205, 86)",
			green: "rgb(75, 192, 192)",
			blue: "rgb(54, 162, 235)",
			purple: "rgb(153, 102, 255)",
			grey: "rgb(201, 203, 207)"
		};

		this.doughnutRef = React.createRef();

		this.parameterProduct = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsNoNameProduct: false
		};

		this.renderItemUniqueClient = {};

		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};
		this.Columns = [
			{
				Header: "Transaction Date",
				accessor: "Date",
				type: GridColumnType.DateTime
			},
			{
				Header: "Count",
				accessor: "Count"
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Transaction Statistic");
		this.props.setAppCenterTitle("PREPAID MANAGEMENT");

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);
			}
		});
		this.HandleClickSearch();
		this.timer = setInterval(this.HandleSearch, 5000);
	}


	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}
	HandleClickSearch = () => {
		this.setState({ isLoading: true })
		this.HandleSearch();
	}

	HandleSearch = () => {

		if (this.HandleRequest != null)
			return;

		this.HandleRequest = true;

		Proxy.POST(
			"/prepaidapi/v1.0/Card/CardBrandStatisticsReport",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.SuccessListCallback(responseData);
				//this.setState({ list: responseData.Item || [] });
				this.HandleRequest = null;
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}


	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			// @ts-ignore
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined && dataList.datasets.length > 0) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return gradientList;

	}

	SuccessListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.props.showMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item.SummaryGridData })
			this.GenerateDoughnutData(responseData);
		}
	}
	formatNumber = (num) => {
		return " $" + num
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	GenerateDoughnutData(responseData) {
		var arrayData = [];
		var arrayLabel = [];
		var arrayColor = [];

		var colorNames = Object.keys(chartColors);
		var i = 0;

		if (responseData.Item.BrandCountSummaryChartDatas != null) {
			var data = responseData.Item.BrandCountSummaryChartDatas;

			data.forEach(element => {
				i = i + 1;
				var colorName = colorNames[i];
				var newColor = chartColors[colorName];
				arrayColor.push(newColor);
				arrayData.push(element.Count);
				arrayLabel.push(element.Label);
			});

			var totalCardBrandDataObj = {
				labels: arrayLabel,
				datasets: [
					{
						data: arrayData,
						hoverBackgroundColor: arrayColor,
						backgroundColor: arrayColor
					},
				],
				text: "Card Brand"

			};
		}

		arrayData = [];
		arrayLabel = [];
		arrayColor = [];
		i = 0;
		if (responseData.Item.TransactionCodeCountSummaryChartDatas != null) {
			var dataTransaction = responseData.Item.TransactionCodeCountSummaryChartDatas;

			dataTransaction.forEach(element => {
				i = i + 1;
				var colorName = colorNames[i];
				var newColor = chartColors[colorName];
				arrayColor.push(newColor);
				arrayData.push(element.Count);
				arrayLabel.push(element.Label);
			});

			var totalCardTransactionCodeObj = {
				labels: arrayLabel,
				datasets: [
					{
						data: arrayData,
						hoverBackgroundColor: arrayColor,
						backgroundColor: arrayColor
					}
				],
				text: "Transaction Code"
			};
		}


		this.setState({ cardBrand: totalCardBrandDataObj, cardTransaction: totalCardTransactionCodeObj });
	}

	SumValues(...arr) {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetChartText(item1, item2) {
		return item1 + " \t $ " + item2
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	GetElementValue(obj, path) {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => c && c[n]);
	}

	TabIndexChanged(activeIndex) {
		this.setState({ activeIndex });
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, cardTransaction, cardBrand } = this.state;
		var IsClient = ClientHelper.IsClient();
		const { classes } = this.props;

		var brandGradientList = this.getGradientList(cardBrand);
		var txtBrand = cardBrand;
		if (brandGradientList != undefined && cardBrand && txtBrand.datasets.length > 0) {
			// @ts-ignore
			txtBrand.datasets[0].backgroundColor = brandGradientList;
			// @ts-ignore
			txtBrand.datasets[0].hoverBackgroundColor = brandGradientList;
		}

		var carTransactionGradientList = this.getGradientList(cardTransaction);
		var txtCardTransaction = cardTransaction;
		if (carTransactionGradientList != undefined && cardTransaction && txtCardTransaction.datasets.length > 0) {
			// @ts-ignore) {
			// @ts-ignore
			txtCardTransaction.datasets[0].backgroundColor = carTransactionGradientList;
			// @ts-ignore
			txtCardTransaction.datasets[0].hoverBackgroundColor = carTransactionGradientList;
		}

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleClickSearch, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridItem>
									<GridContainer>

										<GridItem xs={3}>
											<ParameterComponent
												Name="BrandId"
												LabelText="Brand Name"
												ParameterCode="Brand"
												Value={model.BrandId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelMd={2}
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ProductId"
												LabelMd={2}
												LabelText="Product"
												Method="POST"
												Url="/prepaidapi/v1.0/Product/Search"
												Parameter={this.GetProductParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProductName"
												Value={model.ProductId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>


										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="CreateDateTime"
												LabelText="Create Date"
												Value={model.CreateDateTime}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>



									</GridContainer>
								</GridItem>
							</CardBody>
						</Card>
						<GridItem xs={12}>
							<Card>
								<CardHeader color="warning" icon>
									<CardIcon color="warning">
										<List />
									</CardIcon>
									<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
										<b>Card Transaction Statistic</b>
									</h4>
								</CardHeader>
								<CardBody>
									<GridItem xs={12}>
										<GridContainer justify="center">
											<GridItem xs={4}>
												<div className={cx(classes.circleBoxMain)}>
													<Bar data={txtBrand} height={this.doughnutHeight} width={this.doughnutWidth} options={this.chartOptions} />

													<br />
												</div>
											</GridItem>
											<GridItem xs={7}>
												<div className={cx(classes.circleBox)}>
													<Bar data={txtCardTransaction} height={this.doughnutHeight} width={1200} options={this.chartOptions} />

													<br />
												</div>
											</GridItem>
										</GridContainer>
									</GridItem>

								</CardBody>
							</Card>
						</GridItem>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Card Transaction Statistics List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												ShowPagination={true}
												PageSize={100}
												Sorted={[{ id: "Date", desc: true }]}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >
			</div >
		);
	}
}

CardBrandStaticsReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardBrandStaticsReport, styles);
