import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import {  EmvScriptPoolInsertType, ProgramCodes } from "views/Constants/Constant";

class EmvScriptPool extends React.Component {
	constructor(props) {
		super(props);

	
		this.maxLength50 = { maxLength: 50 };

		this.FileModel = {
			UniqueClientId: "",
			ClientCustomerId: "",
			CardNumber: "",
			TemplateId: "",

		};

		this.SingleModel = {
			UniqueClientId: "",
			ClientCustomerId: "",
			CardId: "",
			TemplateId: ""
		};


		this.state = {
			alert: null,
			model: {},
			list: [],
			FileCardData: [],
			selected: null,
			isFileChanged: false,
			isLoading: false,
			FileModel: {},
			SingleModel: {},
			IsSingleCardActive: false,
			IsFileCaCardActive: false,
			IsFileCardActive: false,
			IsFileCardListDialogOpen: false
		};
	}

	componentDidMount() {
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.props.setAppLeftTitle("Emv Script Pool");
	}

	FileModelValueChanged = (name, value) => {
		this.setState(state => {
			var FileModel = state.FileModel;
			FileModel[name] = value;
			return { FileModel };
		});
	}

	SingleModelValueChanged = (name, value) => {
		this.setState(state => {
			var SingleModel = state.SingleModel;
			SingleModel[name] = value;
			return { SingleModel };
		});
	}

	ModelValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;

			if (name === "EmvScriptPollType") {
				if (data.ParameterValue == EmvScriptPoolInsertType.Single) {
					state.IsSingleCardActive = true;
					state.IsFileCardActive = false;
				}
				else if (data.ParameterValue == EmvScriptPoolInsertType.File) {
					state.IsFileCardActive = true;
					state.IsSingleCardActive = false;
				}
			}
			model[name] = value;

			return { model };
		});
	}


	RowSelected = (index) => {
		const { list } = this.state;
		this.setState({ model: list[index], selected: index });
	}

	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { FileModel, SingleModel, IsSingleCardActive, IsFileCardActive, FileCardData } = this.state;

		if (IsSingleCardActive) {
			Proxy.POST(
				"/prepaidapi/v1.0/EmvScriptTemplate/InsertEmvScriptSinglePool",
				{
					"TemplateId": SingleModel.TemplateId,
					"CardId": SingleModel.CardId
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.setState({ list: responseData.Item || [], isLoading: false });
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
				}
			);
		}

		else if (IsFileCardActive) {
			Proxy.POST(
				"/prepaidapi/v1.0/EmvScriptTemplate/InsertEmvScriptFilePool",
				{
					MaskedCardList: FileCardData,
					"TemplateId": FileModel.TemplateId
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.setState({ list: responseData.Item || [], isLoading: false });
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
				}
			);
		}
	}



	Clear = () => {
		this.setState({ model: {}, FileModel: {}, SingleModel: {}, list: [], selected: null, isFileChanged: false });
	}


	Validate() {

		const { IsFileCardActive, IsSingleCardActive, IsFileCardListDialogOpen, FileModel, SingleModel } = this.state;
		const { showMessageNode } = this.props;
		var messages = [];

		if (IsFileCardActive) {
			if (!IsFileCardListDialogOpen) {
				if ((FileModel.FileName === undefined || FileModel.FileName == null)
					&& (FileModel.File === undefined || FileModel.File == null)) {
					messages.push("Card file can not be null.");
				}
				if (FileModel.TemplateId == undefined || FileModel.TemplateId == null
					|| FileModel.TemplateId == "")
					messages.push("Template can not be null.");
			}
			if (FileModel.ClientCustomerId == null || FileModel.ClientCustomerId == undefined || FileModel.ClientCustomerId == "")
				messages.push("Customer Name can not be null");

			if (FileModel.UniqueClientId == null || FileModel.UniqueClientId == undefined || FileModel.UniqueClientId == "")
				messages.push("Unique Client Name can not be null");
		}


		else if (IsSingleCardActive) {
			if (SingleModel.ClientCustomerId == null || SingleModel.ClientCustomerId == undefined || SingleModel.ClientCustomerId == "")
				messages.push("Customer Name can not be null");

			if (SingleModel.UniqueClientId == null || SingleModel.UniqueClientId == undefined || SingleModel.UniqueClientId == "")
				messages.push("Unique Client Name can not be null");

			if (SingleModel.TemplateId == undefined || SingleModel.TemplateId == null || SingleModel.TemplateId == "")
				messages.push("Template can not be null.");

			if (SingleModel.CardId == undefined || SingleModel.CardId == null || SingleModel.CardId == "")
				messages.push("Card can not be null.");

		}
		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}


	Search = () => {

		const { SingleModel } = { ...this.state };
		Proxy.POST(
			"/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptSinglePool",
			{
				"ClientCustomerId": SingleModel.ClientCustomerId,
				"UniquClientId": SingleModel.UniquClientId,
				"TemplateId": SingleModel.TemplateId,
				"CardId": SingleModel.CardId
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	render() {
		const { isLoading,SingleModel } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: false,
							OnClick: this.Submit

						},
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search

						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear

						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>

				<Card className="no-radius" style={{ height: "90%" }}>
					<GenericExpansionPanel Title="Filter Panel">
						<CardBody>
							<GridContainer>
								<GridContainer >
									<GridItem xs={6}>

									<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{
													UniqueClientId: ClientHelper.IsClient()
														? ClientHelper.GetClientId()
														: undefined
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={SingleModel.UniqueClientId || ""}
												ValueChanged={this.SingleModelValueChanged}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1} />

										<GenericSelectInput
											key={SingleModel.UniqueClientId}
											Labelmd={2}
											Name="ClientCustomerId"
											LabelText="Customer Name"
											Method="POST"
											Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Name"
											Value={SingleModel.ClientCustomerId}
											Parameter={{
												ProgramCode: ProgramCodes.Prepaid,
												UniqueClientId: SingleModel.UniqueClientId
											}}
											Disabled={false}
											ValueChanged={this.SingleModelValueChanged}
										/>
									</GridItem>
									<GridItem xs={6}>
										<GenericSelectInput
											key={SingleModel.ClientCustomerId}
											Name="CardId"
											LabelText="Card Name"
											Method="POST"
											Url="/prepaidapi/v1.0/Card/CardSearch"
											Parameter={{
												ClientCustomerId: SingleModel.ClientCustomerId,
												CardSearchCriteria: 2
											}}
											DataRoot="Item"
											KeyValueMember="CardId"
											TextValueMember="MaskedCardNumber"
											Value={SingleModel.CardId}
											Disabled={false}
											ValueChanged={this.SingleModelValueChanged}
										/>


										<GenericSelectInput
											Name="TemplateId"
											LabelText="Template"
											Method="POST"
											Url="/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptTemplate"
											DataRoot="Item"
											KeyValueMember="TemplateId"
											TextValueMember="TemplateName"
											Value={SingleModel.TemplateId}
											Disabled={false}
											ValueChanged={this.SingleModelValueChanged}
										/>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>


			</div>
		);

	}
}
EmvScriptPool.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default EmvScriptPool;