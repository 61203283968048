import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import CorporateAuthOwner from "views/Backoffice/BankingClientSetup/ClientSteps/CustomerDetailStep/CorporateAuthOwner";
import CorporateAuthPerson from "views/Backoffice/BankingClientSetup/ClientSteps/CustomerDetailStep/CorporateAuthPerson";
import CorporateDetails from "views/Backoffice/BankingClientSetup/ClientSteps/CustomerDetailStep/CorporateDetails";
import KeyContact from "views/Backoffice/BankingClientSetup/ClientSteps/KeyContact";
import { GenericExpansionPanel } from "views/Components/Generic";
import RegulatoryLicenses from "../RegulatoryLicenses";
import Directors from "../Directors";

class Corporate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

		};
	}

	componentDidMount() {
	}

	render() {
		const { Disabled } = this.props;
		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						<Button size="sm" disabled={Disabled || this.props.paramGlobalModel.Id == null ? true : this.props.paramGlobalModel.Id == 0 ? true : false} color="black" onClick={() => this.props.handleChangeChildTab(null, 1)} > Next </Button>
					</GridItem>
				</GridContainer>
				<GridItem xs={12}>
					<GenericExpansionPanel IsActive={true} Title="Corporate Details" >
						<CorporateDetails
							handleChangeChildTab={this.props.handleChangeChildTab}
							paramBankCustomerCorporate={this.props.paramBankCustomerCorporate}
							paramGlobalModel={this.props.paramGlobalModel}
							setCorporateState={this.props.setCorporateState}
							handleChangeCorporate={this.props.handleChangeCorporate}
							handleChangeCorporatePhoneFax={this.props.handleChangeCorporatePhoneFax}
							handleChangeCorporatePhoneBuss={this.props.handleChangeCorporatePhoneBuss}
							paramCorporateBussinesPhone={this.props.paramCorporateBussinesPhone}
							paramCorporateFaxPhone={this.props.paramCorporateFaxPhone}
							Disabled={this.props.Disabled}
							FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
						/>
					</GenericExpansionPanel>
				</GridItem>
				<GridItem xs={12}>
					<GenericExpansionPanel IsActive={false} Title="Directors" >
						<Directors
							Disabled={this.props.Disabled}
							FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
							paramCustomerProfile={this.props.paramCustomerProfile}
							paramGlobalModel={this.props.paramGlobalModel}
							paramBankCustomerDirectorsList={this.props.paramBankCustomerDirectorsList}
							GetCustomerDirectorsModels={this.props.GetCustomerDirectorsModels}
						/>
					</GenericExpansionPanel>
				</GridItem>
				<GridItem xs={12}>
					<GenericExpansionPanel IsActive={false} Title="Beneficiary Ownership Definitions" >
						<CorporateAuthOwner
							Disabled={this.props.Disabled}
							FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
							paramGlobalModel={this.props.paramGlobalModel}
							GetCustomerCorporateAuthOwnerModels={this.props.GetCustomerCorporateAuthOwnerModels}
							paramBankCustomerCorporateAuthOwnerList={this.props.paramBankCustomerCorporateAuthOwnerList}
						/>
					</GenericExpansionPanel>
				</GridItem>
				<GridItem xs={12}>
					<GenericExpansionPanel IsActive={false} Title="Authorized Signing Officer Information" >
						<CorporateAuthPerson
							Disabled={this.props.Disabled}
							FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
							paramGlobalModel={this.props.paramGlobalModel}
							GetCustomerCorporateAuthPersonModels={this.props.GetCustomerCorporateAuthPersonModels}
							paramBankCustomerCorporateAuthPersonList={this.props.paramBankCustomerCorporateAuthPersonList}
						/>
					</GenericExpansionPanel>
				</GridItem>
				<GridItem xs={12}>
					<GenericExpansionPanel IsActive={false} Title="Contact Person Information" >
						<KeyContact
							Disabled={this.props.Disabled}
							FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
							paramCustomerProfile={this.props.paramCustomerProfile}
							paramGlobalModel={this.props.paramGlobalModel}
							paramBankCustomerKeyContactList={this.props.paramBankCustomerKeyContactList}
							GetCustomerKeyContactModels={this.props.GetCustomerKeyContactModels}
						/>
					</GenericExpansionPanel>
				</GridItem>
				<GridItem xs={12}>
					<GenericExpansionPanel IsActive={false} Title="Regulatory Licenses" >
						<RegulatoryLicenses
							Disabled={this.props.Disabled}
							FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
							paramCustomerProfile={this.props.paramCustomerProfile}
							paramGlobalModel={this.props.paramGlobalModel}
							paramBankCustomerRegulatoryLicenseList={this.props.paramBankCustomerRegulatoryLicenseList}
							GetCustomerRegulatoryLicenses={this.props.GetCustomerRegulatoryLicenses}
						/>
					</GenericExpansionPanel>
				</GridItem>
			</GridContainer>
		);
	}
}

Corporate.propTypes = {
	classes: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerCorporate: PropTypes.object,
	GetCorporateProfile: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	setCorporateState: PropTypes.func,
	handleChangeCorporate: PropTypes.func,
	paramCorporateBussinesPhone: PropTypes.object,
	handleChangeCorporatePhoneBuss: PropTypes.func,
	handleChangeCorporatePhoneFax: PropTypes.func,
	paramCorporateFaxPhone: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramBankCustomerKeyContactList: PropTypes.array,
	paramBankCustomerDirectorsList: PropTypes.array,
	GetCustomerKeyContactModels: PropTypes.func,
	GetCustomerDirectorsModels: PropTypes.func,
	paramBankCustomerRegulatoryLicenseList: PropTypes.array,
	GetCustomerRegulatoryLicenses: PropTypes.func,
	GetCustomerCorporateAuthPersonModels: PropTypes.func,
	GetCustomerCorporateAuthOwnerModels: PropTypes.func,
	paramBankCustomerCorporateAuthOwnerList: PropTypes.array,
	paramBankCustomerCorporateAuthPersonList: PropTypes.array,
	Disabled: PropTypes.bool,
	FetchClientLimitedAuthority: PropTypes.func
};

export default withArtifex(Corporate, {});