import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import DownloadButton from "views/Components/DownloadButton";
import { Proxy } from "core";

class eTransferReconciliationFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				ReconciliationDate: DateHelper.GetDate(),
			},
			summaryModel: {},
			summaryInteracModel: {},
			summaryFiModel: {},
			fiTransactionList: [],
			interacTransactionList: [],
			isLoading: false,
			DownloadButtonControl: false
		};

		this.ColumnsInitiatedByInteracPaymentsFile = [
			{
				"FileId": {
					"value": "FileId",
					"title": "File Id"
				},
				"Currency": {
					"value": "Currency",
					"title": "Currency"
				},
				"FiRefNo": {
					"value": "FiRefNo",
					"title": "Fi Ref No"
				},
				"TranDate": {
					"value": "TranDate",
					"title": "Tran Date",
					"type": "datetimeutc"
				},
				"LocalTranDate": {
					"value": "LocalTranDate",
					"title": "Local Date",
					"type": "datetime"
				},
				"Amount": {
					"value": "Amount",
					"title": "Amount",
					"columntype": "money",
					"type": "money"
				},
				"FiUserId": {
					"value": "FiUserId",
					"title": "FiUserId"
				},
				"UniqueClientId": {
					"value": "UniqueClientId",
					"title": "Unique Client Id"
				},
				"ClientName": {
					"value": "ClientName",
					"title": "Client Name"
				},
				"TransactionType": {
					"value": "TransactionType",
					"title": "Transaction Type"
				},
				"PmtRefNo": {
					"value": "PmtRefNo",
					"title": "Pmt Ref No"
				},
				"InteracTranDate": {
					"value": "InteracTranDate",
					"title": "Interac Tran Date",
					"type": "datetimeutc"
				},
				"InteracLocalTranDate": {
					"value": "InteracLocalTranDate",
					"title": "Interac Local Tran Date",
					"type": "datetimeutc"
				},
				"ChannelIndicator": {
					"value": "ChannelIndicator",
					"title": "Channel Indicator"
				}
			}
		];

		this.ColumnsInitiatedByFiPaymentsFile = [
			{
				"FileId": {
					"value": "FileId",
					"title": "File Id"
				},
				"Currency": {
					"value": "Currency",
					"title": "Currency"
				},
				"FiRefNo": {
					"value": "FiRefNo",
					"title": "Fi Ref No"
				},
				"TranDate": {
					"value": "TranDate",
					"title": "Tran Date",
					"type": "datetimeutc"
				},
				"LocalTranDate": {
					"value": "LocalTranDate",
					"title": "Local Date",
					"type": "datetimeutc"
				},
				"Amount": {
					"value": "Amount",
					"title": "Amount",
					"columntype": "money",
					"type": "money"
				},
				"OrigFiUserId": {
					"value": "OrigFiUserId",
					"title": "Orig Fi User Id"
				},
				"UniqueClientId": {
					"value": "UniqueClientId",
					"title": "Unique Client Id"
				},
				"ClientName": {
					"value": "ClientName",
					"title": "Client Name"
				},
				"TransactionType": {
					"value": "TransactionType",
					"title": "Transaction Type"
				},
				"PmtRefNo": {
					"value": "PmtRefNo",
					"title": "Pmt Ref No"
				},
				"ChannelIndicator": {
					"value": "ChannelIndicator",
					"title": "Channel Indicator"
				}
			}
		];
	}


	componentDidMount() {
		this.props.setAppLeftTitle("Interac e-Transfer / Reconciliation File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	handleChange = (name, newValue) => {
		this.setState(state => {
			var model = state.model;
			model[name] = newValue;
			return { model, DownloadButtonControl: false };
		});
	}

	GetData = async () => {
		if (this.IsValid()) {
			const { ExecuteApiPost } = this.props;
			var { model, DownloadButtonControl } = this.state;

			var responseData = await ExecuteApiPost("/bankapi/v1.0/ClearingSettlement/GetReconciliationFileDetail", model, null, null, null, null, null);

			if (responseData != null) {
				if (responseData.ReconciliationFileDetail != null)
					DownloadButtonControl = true;
				else
					DownloadButtonControl = false;

				this.setState({
					summaryModel: responseData.ReconciliationFileDetail,
					// fiTransactionList: responseData.ReconciliationFiTransactions,
					// interacTransactionList: responseData.ReconciliationInteracTransactions,
					summaryInteracModel: responseData.ReconciliationInteracSummary,
					summaryFiModel: responseData.ReconciliationFiSummary,
					DownloadButtonControl: true
				});
			}
			else {
				this.setState({ DownloadButtonControl: false });
			}
		}
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };
		if (!vmodel || !vmodel.ReconciliationDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Reconciliation Date cannot be null");
			return false;
		}
		else if (typeof vmodel.ReconciliationDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "Reconciliation Date is invalid");
			return false;
		}
		return true;
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	hideLoading = () => { this.setState({ isLoading: false }); }

	handleClear = () => {
		this.setState({ model: { ReconciliationDate: DateHelper.GetDate() }, summaryModel: {}, fiTransactionList: [], interacTransactionList: [], summaryInteracModel: {}, summaryFiModel: {} })
	}

	Clicked = async (contentType) => {
		var { model, summaryModel } = this.state;

		if (model.ReconciliationDate == null || model.ReconciliationDate == undefined) {
			this.props.showMessage("warning", "Please select required field !", "Select a Reconciliation Date !");
			return;
		}

		var mytitle = "Reconciliation.CA000352." + contentType + "." + model.ReconciliationDate.format("YYYY-MM-DD");

		var apiUrl = "";
		var columns = [];
		model.ContentType = contentType;

		if (contentType == "InitiatedByInteracPayments") {
			apiUrl = "DownloadReconciliationInitiatedByInteracPayment";
			columns = this.ColumnsInitiatedByInteracPaymentsFile;
		}
		else if (contentType == "InitiatedByFiPayments") {
			apiUrl = "DownloadReconciliationInitiatedByFiPayment";
			columns = this.ColumnsInitiatedByFiPaymentsFile;
		}
		else {
			this.props.showMessage("error", "Error", "Content type is invalid !");
			return;
		}

		model.FileId = summaryModel.FileId;
		model.jsonFile = {
			colmns: columns,
			format: "CSV",
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClearingSettlement/" + apiUrl,
			model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading, summaryFiModel, summaryInteracModel, DownloadButtonControl } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />
				<GridContainer>
					<GridItem xs={5}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"Filter"} />
							</CardHeader>
							<CardBody>


								<GridContainer>
									<GridItem xs={12}>
										<GenericDateInput
											Utc
											Name="ReconciliationDate"
											LabelText="Reconciliation Date"
											Value={model.ReconciliationDate}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											MaxDate={DateHelper.GetDate()} />
									</GridItem><br /><br /><br />
									<GridItem xs={1} />
									<GridItem xs={5}>
										{DownloadButtonControl &&
											<DownloadButton
												name={1}
												title="Download Reconciliation Initiated By Fi Payments"
												onClick={() => this.Clicked("InitiatedByFiPayments")} />
										}
									</GridItem>
									<GridItem xs={5}>
										{DownloadButtonControl &&
											<DownloadButton
												name={1}
												title="Download Reconciliation Initiated By Interac Payments"
												onClick={() => this.Clicked("InitiatedByInteracPayments")} />
										}
									</GridItem>
									<GridItem xs={1} />
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={7}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericTextInput
											Name="ParticipantId"
											LabelText="ParticipantId"
											LabelMd={6}
											Value={summaryModel.ParticipantId}
											ValueChanged={this.handleChange}
											Disabled={true} />
										<GenericTextInput
											Name="FileType"
											LabelText="Type"
											LabelMd={6}
											Value={summaryModel.FileType}
											ValueChanged={this.handleChange}
											Disabled={true} />
										<GenericTextInput
											Name="RunDate"
											LabelText="Run Date"
											LabelMd={6}
											Value={Formatter.FormatDateTimeUtc(summaryModel.RunDate)}
											ValueChanged={this.handleChange}
											Disabled={true}
											Utc />
										<GenericDateInput
											Name="BeginDate"
											LabelMd={6}
											LabelText="Begin Date"
											Value={summaryModel.BeginDate ? DateHelper.ToDateInputValueAsUtc(summaryModel.BeginDate) : ""}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											Disabled={true}
											Utc />
										<GenericDateInput
											Name="EndDate"
											LabelText="End Date"
											Value={summaryModel.EndDate ? DateHelper.ToDateInputValueAsUtc(summaryModel.EndDate) : ""}
											ValueChanged={this.handleChange}
											LabelMd={6}
											IncludeTime={false}
											Disabled={true}
											Utc />
										<GenericNumberInput
											Name="TotalRecord"
											LabelText="Total Record"
											Value={summaryModel.TotalRecord}
											Disabled={true}
											LabelMd={6}
											DecimalScale={0} />
									</GridItem>
									<GridItem xs={6}>
										<GenericTextInput
											Name="RunNo"
											LabelMd={6}
											LabelText="Run No"
											Value={summaryModel.RunNo}
											ValueChanged={this.handleChange}
											Disabled={true} />
										<GenericTextInput
											Name="Version"
											LabelText="Version"
											LabelMd={6}
											Value={summaryModel.Version}
											Disabled={true} />
										<GenericTextInput
											Name="FileId"
											LabelText="File ID"
											LabelMd={6}
											Value={summaryModel.FileId}
											Disabled={true} />
										<GenericDateInput
											Name="BeginTimedtamp"
											LabelText="Begin Timestamp"
											Value={summaryModel.BeginDate ? DateHelper.ToDateInputValueAsUtc(summaryModel.BeginDate) : ""}
											DateFormat="HH:mm:ss"
											LabelMd={6}
											IncludeTime={false}
											Disabled={true}
											Utc />
										<GenericDateInput
											Name="EndTimedtamp"
											LabelText="End Timestamp"
											Value={summaryModel.EndDate ? DateHelper.ToDateInputValueAsUtc(summaryModel.EndDate) : ""}
											ValueChanged={this.handleChange}
											DateFormat="HH:mm:ss"
											LabelMd={6}
											IncludeTime={false}
											Disabled={true}
											Utc />
										<GenericNumberInput
											Name="TotalAmount"
											LabelText="Total Amount"
											Value={summaryModel.TotalAmount}
											Prefix="$"
											LabelMd={6}
											Disabled={true} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card >
					<CardHeader>
						<GenericTitle text={"Fi Transaction Summary"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalRequestOrSendTransferCount"
									LabelText="Request Or Send TransferCount"
									Value={summaryFiModel.TotalRequestOrSendTransferCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalRequestOrSendTransferAmount"
									LabelText="Request or Send Transfer Amount"
									Value={summaryFiModel.TotalRequestOrSendTransferAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
								<GenericNumberInput
									Name="TotalRequestOrSendTransferFixCount"
									LabelText="Request or SendTransfer Fix Count"
									Value={summaryFiModel.TotalRequestOrSendTransferFixCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalRequestOrSendTransferFixAmount"
									LabelText="Request or Send Transfer Fix Amount"
									Value={summaryFiModel.TotalRequestOrSendTransferFixAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalCompleteTransferCount"
									LabelText="Complete Transfer Count"
									Value={summaryFiModel.TotalCompleteTransferCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalCompleteTransferAmount"
									LabelText="Complete Transfer Amount"
									Value={summaryFiModel.TotalCompleteTransferAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
								<GenericNumberInput
									Name="TotalCompleteTransferFixCount"
									LabelText="Complete Transfer Fix Count"
									Value={summaryFiModel.TotalCompleteTransferFixCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalCompleteTransferFixAmount"
									LabelText="Complete Transfer Fix Amount"
									Value={summaryFiModel.TotalCompleteTransferFixAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalCancelTransferCount"
									LabelText="Cancel Transfer Count"
									Value={summaryFiModel.TotalCancelTransferCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalCancelTransferAmount"
									LabelText="Cancel Transfer Amount"
									Value={summaryFiModel.TotalCancelTransferAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
								<GenericNumberInput
									Name="TotalCancelTransferFixCount"
									LabelText="Cancel Transfer Fix Count"
									Value={summaryFiModel.TotalCancelTransferFixCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalCancelTransferFixAmount"
									LabelText="Cancel Transfer Fix Amount"
									Value={summaryFiModel.TotalCancelTransferFixAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card><br />
				<Card >
					<CardHeader>
						<GenericTitle text={"Interac Transaction Summary"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalDirectDepositTransferCount"
									LabelText="Direct Deposit Transfer Count"
									Value={summaryInteracModel.TotalDirectDepositTransferCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalDirectDepositTransferAmount"
									LabelText="Direct Deposit Transfe rAmount"
									Value={summaryInteracModel.TotalDirectDepositTransferAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
								<GenericNumberInput
									Name="TotalDirectDepositTransferFixCount"
									LabelText="Direct Deposit Transfer Fix Count"
									Value={summaryInteracModel.TotalDirectDepositTransferFixCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalDirectDepositTransferFixAmount"
									LabelText="Direct Deposit Transfer Fix Amount"
									Value={summaryInteracModel.TotalDirectDepositTransferFixAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalDirectWithDrawalTransferCount"
									LabelText="Direct With Drawal Transfer Count"
									Value={summaryInteracModel.TotalDirectWithDrawalTransferCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalDirectWithDrawalTransferAmount"
									LabelText="Direc tWith Drawal Transfer Amount"
									Value={summaryInteracModel.TotalDirectWithDrawalTransferAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
								<GenericNumberInput
									Name="TotalDirectWithDrawalTransferFixCount"
									LabelText="Direct With Drawal Transfer Fix Count"
									Value={summaryInteracModel.TotalDirectWithDrawalTransferFixCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalDirectWithDrawalTransferFixAmount"
									LabelText="Direct With Drawal Transfer Fix Amount"
									Value={summaryInteracModel.TotalDirectWithDrawalTransferFixAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAutoCancelledTransferCount"
									LabelText="Auto Cancelled TransferCount"
									Value={summaryInteracModel.TotalAutoCancelledTransferCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalAutoCancelledTransferAmount"
									LabelText="Auto Cancelled Transfer Amount"
									Value={summaryInteracModel.TotalAutoCancelledTransferAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
								<GenericNumberInput
									Name="TotalAutoCancelledTransferFixCount"
									LabelText="Auto Cancelled Transfer Fix Count"
									Value={summaryInteracModel.TotalAutoCancelledTransferFixCount}
									Disabled={true}
									LabelMd={6}
									DecimalScale={0} />
								<GenericNumberInput
									Name="TotalAutoCancelledTransferFixAmount"
									LabelText="Auto Cancelled Transfer Fix Amount"
									Value={summaryInteracModel.TotalAutoCancelledTransferFixAmount}
									Prefix="$"
									LabelMd={6}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card><br />
				{/* <Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Fi Top 100 Transaction"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={fiTransactionList}
									Columns={[
										{
											Header: "Participant Id",
											accessor: "OrigFiUserId"
										},
										{
											Header: "Currency",
											accessor: "Currency"
										},
										{
											Header: "Fi Reference Number",
											accessor: "FiRefNo"
										},
										{
											Header: "Pmt Reference Number",
											accessor: "PmtRefNo"
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType:GridColumnType.Money
										},
										{
											Header: "ChannellIndicator",
											accessor: "ChannellIndicator"
										},
										{
											Header: "Transanction Date",
											accessor: "TranDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Transanction Local Date",
											accessor: "LocalTranDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType",
											Cell: row => (
												<div>{row.value == "Credit" ? "Money Send" : "Money Request"}</div>
											)
										}
									]}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}  />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
				<br />
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Interac Top 100 Transaction"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={interacTransactionList}
									Columns={[
										{
											Header: "Participant Id",
											accessor: "OrigFiUserId"
										},
										{
											Header: "Currency",
											accessor: "Currency"
										},
										{
											Header: "Fi Reference Number",
											accessor: "FiRefNo"
										},
										{
											Header: "Pmt Reference Number",
											accessor: "PmtRefNo"
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
										},
										{
											Header: "ChannellIndicator",
											accessor: "ChannellIndicator"
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType",
											Cell: row => (
												<div>{row.value == "Credit" ? "Money Send" : "Money Request"}</div>
											)
										},
										{
											Header: "Transaction Date",
											accessor: "TranDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Transanction Local Date",
											accessor: "LocalTranDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Interac Transanction Date",
											accessor: "InteracTranDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Interac Transanction Local Date",
											accessor: "InteracTranLocalDate",
											type: GridColumnType.DateTime
										}
									]}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}  />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
		 */}
			</div>
		);
	}
}

eTransferReconciliationFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	SelectedRowChange: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	menuId: number,
	ApprovalData: array,
	After: func
};

export default withArtifex(eTransferReconciliationFile, {});