import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { PropTypes } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { MenuCodes } from "views/Constants/Constant";
import ResourceHelper from "../../../core/ResourceHelper";

class NotificationSettings extends React.Component {
	constructor(props) {
		super(props);

		this.initialTransactionList = [];
		this.initialAccountList = [];
		this.initialModel = { Id: 0 };

		this.state = {
			alert: false,
			transactionList: [...this.initialTransactionList],
			accountList: [...this.initialAccountList],
			model: { ...this.initialModel },
			isLoading: false
		};
		// preserve the initial state in a new object
		this.baseState = this.state;
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Notification Settings");
		if (this.props.MenuCode == MenuCodes.Notification) {
			this.props.setAppCenterTitle("NOTIFICATIONS");
		} else {
			this.props.setAppCenterTitle("WALLET");
		}
		this.HandleSearch();
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleTransactionChange = (id, name, value) => {
		const { transactionList } = this.state;
		if (transactionList.length > 0) {
			transactionList.filter(x => x.Id == id)[0][name] = value;
			this.setState({ transactionList });
		}
	}

	HandleAccountChange = (id, name, value) => {
		const { accountList } = this.state;
		if (accountList.length > 0) {
			accountList.filter(x => x.Id == id)[0][name] = value;
			this.setState({ accountList });
		}
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			return state;
		});
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/BankingNotification/GetAll",
			{},
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null) {
			responseData.Item.BankAccountList.forEach(bankAccount => {
				bankAccount.AccountName = ResourceHelper.CorrectAccountNames(bankAccount.AccountName);
			})
			responseData.Item.TransactionAlertGroupList.forEach(alert => {
				alert.GroupName = ResourceHelper.CorrectAccountNames(alert.GroupName);
			})
			this.setState(
				{
					model: responseData.Item.BankingNotification,
					transactionList: responseData.Item.TransactionAlertGroupList,
					accountList: responseData.Item.BankAccountList
				}
			);
			this.initialModel = responseData.Item.BankingNotification;
			this.initialTransactionList = responseData.Item.TransactionAlertGroupList;
			this.initialAccountList = responseData.Item.BankAccountList;
		} else {
			this.setState({ model: {}, accountList: [], transactionList: [] });
			this.initialModel = { Id: 0 };
			this.initialAccountList = [];
			this.initialTransactionList = [];
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankingNotification/InsertOrUpdate",
			{
				BankingNotification: this.state.model,
				BankAccountList: this.state.accountList,
				TransactionAlertGroupList: this.state.transactionList
			},
			this.SuccessSubmitCallback,
			this.ErrorSubmitCallback
		);
	}

	SuccessSubmitCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.ShowMessage("success", "Success", "Operation is successfully!");
		this.HandleSearch();
	}

	ErrorSubmitCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}

	Validate = () => {
		const { model, transactionList, accountList } = this.state;

		if (!model.TransactionAlert && transactionList.filter(x => x.IsRecordValid == true).length > 0) {
			this.ShowMessage("warning", "Invalid", "Please select the Transaction Alert");
			return;
		}

		for (let transaction of transactionList.filter(x => x.IsRecordValid == true)) {
			if (transaction.AlertLimit == null) {
				this.ShowMessage("warning", "Invalid", "Please fill the Alert Limit of the " + transaction.GroupName);
				return;
			}
			if (!transaction.SendSms && !transaction.SendEmail && !transaction.SendApplication) {
				this.ShowMessage("warning", "Invalid", "Please select one channel at least !");
				return;
			}
			if (transaction.KeyContactId == null) {
				this.ShowMessage("warning", "Invalid", "Please select the user to send notifications for " + transaction.GroupName);
				return;
			}
		}

		if (!model.Balance && accountList.filter(x => x.IsRecordValid == true).length > 0) {
			this.ShowMessage("warning", "Invalid", "Please select the Transaction Alert");
			return;
		}

		for (let account of accountList.filter(x => x.IsRecordValid == true)) {
			if (account.MinimumBalance == null) {
				this.ShowMessage("warning", "Invalid", "Please fill the Minimum Balance of the " + account.AccountName);
				return;
			}
			if (!account.SendSms && !account.SendEmail && !account.SendApplication) {
				this.ShowMessage("warning", "Invalid", "Please select one channel  at least!");
				return;
			}
			if (account.KeyContactId == null) {
				this.ShowMessage("warning", "Invalid", "Please select the user to send notifications for " + account.AccountName);
				return;
			}
		}

		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isLoading, transactionList, accountList, model } = this.state;

		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16} style={{ marginTop: "24px" }}>
					<GridItem xs={12}>
						{/* <GridContainer>
						<GridItem md={3} xs={12} style={{ marginTop: "14px", paddingLeft: "24px !important" }}>
							<GenericCheckInput
								Name="MoneyTransfer"
								LabelText="Money Transfer Notification"
								Value={model.MoneyTransfer}
								ValueChanged={(n, v) => {
									this.HandleChange(n, v);
								}} />
						</GridItem>
						<GridItem md={9} xs={12} style={{ opacity: !model.MoneyTransfer ? 0.4 : 1 }} >
							<Card >
								<CardBody>
									<GridItem>
										<GenericCheckInput
											Name="MoneyTransferSendSms"
											LabelText="SMS"
											Value={model.MoneyTransferSendSms}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="MoneyTransferSendEmail"
											LabelText="E-Mail"
											Value={model.MoneyTransferSendEmail}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="MoneyTransferSendApplication"
											LabelText="Portal"
											Value={model.MoneyTransferSendApplication}
											ValueChanged={this.HandleChange} />
									</GridItem>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem md={3} xs={12} style={{ marginTop: "14px", paddingLeft: "24px !important" }}>
							<GenericCheckInput
								Name="Card"
								LabelText="Cards"
								Value={model.Card}
								ValueChanged={(n, v) => {
									this.HandleChange(n, v);
								}} />
						</GridItem>
						<GridItem md={9} xs={12} style={{ opacity: !model.Card ? 0.4 : 1 }}>
							<Card >
								<CardBody>
									<GridItem>
										<GenericCheckInput
											Name="CardSendSms"
											LabelText="SMS"
											Value={model.CardSendSms}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="CardSendEmail"
											LabelText="E-Mail"
											Value={model.CardSendEmail}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="CardSendApplication"
											LabelText="Portal"
											Value={model.CardSendApplication}
											ValueChanged={this.HandleChange} />
									</GridItem>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem md={3} xs={12} style={{ marginTop: "14px", paddingLeft: "24px !important" }}>
							<GenericCheckInput
								Name="Payment"
								LabelText="Payments"
								Value={model.Payment}
								ValueChanged={(n, v) => {
									this.HandleChange(n, v);
								}} />
						</GridItem>
						<GridItem md={9} xs={12} style={{ opacity: !model.Payment ? 0.4 : 1 }}>
							<Card >
								<CardBody>
									<GridItem>
										<GenericCheckInput
											Name="PaymentSendSms"
											LabelText="SMS"
											Value={model.PaymentSendSms}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="PaymentSendEmail"
											LabelText="E-Mail"
											Value={model.PaymentSendEmail}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="PaymentSendApplication"
											LabelText="Portal"
											Value={model.PaymentSendApplication}
											ValueChanged={this.HandleChange} />
									</GridItem>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer> */}
						<GridContainer>
							<GridItem xs={3} style={{ marginTop: 14, paddingLeft: "24px !important" }}>
								<GenericCheckInput
									Name="TransactionAlert"
									LabelText="Transaction Alerts"
									Value={model.TransactionAlert}
									ValueChanged={(n, v) => {
										this.HandleChange(n, v);
									}} />
							</GridItem>
							<GridItem xs={9} style={{ opacity: !model.TransactionAlert ? 0.4 : 1 }}>
								<Card>
									<CardHeader>
										<GenericTitle text={"Banking Transaction List "} />
									</CardHeader>
									<CardBody>
										<GridContainer direction="row" alignItems="stretch" justify="space-around">
											<GridItem xs style={{ marginTop: 30 }}>
												{transactionList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: "10%", paddingRight: "10%" }}>
															<GenericCheckInput
																Name="IsRecordValid"
																LabelText={field.GroupName}
																Value={field.IsRecordValid}
																ValueChanged={(n, v) => {
																	this.HandleTransactionChange(field.Id, n, v);
																}}
																Disabled={!model.TransactionAlert} />
														</div>
													);
												})}
											</GridItem>
											<GridItem>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Alert Limit"></GenericLabel>
												</div>
												{transactionList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 0.5 }}>
															<GenericNumberInput
																Name="AlertLimit"
																LabelMd={0}
																Value={field.AlertLimit}
																ValueChanged={(n, v) => this.HandleTransactionChange(field.Id, n, v)}
																Prefix="$"
																MaxLength={12}
																Disabled={!field.IsRecordValid || !model.TransactionAlert} />
														</div>
													);
												})}
											</GridItem>
											<GridItem xs>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Notification Channel"></GenericLabel>
												</div>
												{transactionList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: "12%", paddingRight: "12%", textAlign: "center" }}>
															<GenericCheckInput
																Name="SendSms"
																LabelText="SMS"
																Value={field.SendSms}
																ValueChanged={(n, v) => this.HandleTransactionChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.TransactionAlert} />
															<GenericCheckInput
																Name="SendEmail"
																LabelText="E-Mail"
																Value={field.SendEmail}
																ValueChanged={(n, v) => this.HandleTransactionChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.TransactionAlert} />
															<GenericCheckInput
																Name="SendApplication"
																LabelText="Portal"
																Value={field.SendApplication}
																ValueChanged={(n, v) => this.HandleTransactionChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.TransactionAlert} />
														</div>
													);
												})}
											</GridItem>
											<GridItem xs>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="User"></GenericLabel>
												</div>
												{transactionList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 1, paddingLeft: "12%", paddingRight: "12%" }}>
															<GenericSelectInput
																Name="KeyContactId"
																LabelMd={0}
																Method="POST"
																Url="/bankapi/v1.0/CustomerKeyContact/GetByUniqueClientId"
																Parameter={{}}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={d => `${d.Name} ${d.SurName}`}
																Value={field.KeyContactId}
																ValueChanged={(n, v) => this.HandleTransactionChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.TransactionAlert} />
														</div>
													);
												})}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={3} style={{ marginTop: 14, paddingLeft: "24px !important" }}>
								<GenericCheckInput
									Name="Balance"
									LabelText="Balance Notifications"
									Value={model.Balance}
									ValueChanged={(n, v) => {
										this.HandleChange(n, v);
									}} />
							</GridItem>
							<GridItem xs={9} style={{ opacity: !model.Balance ? 0.4 : 1 }}>
								<Card>
									<CardHeader>
										<GenericTitle text={"Banking Wallet List "} />
									</CardHeader>
									<CardBody>
										<GridContainer direction="row" alignItems="stretch" justify="space-around">
											<GridItem xs style={{ marginTop: 30 }}>
												{accountList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: "10%", paddingRight: "10%" }}>
															<GenericCheckInput
																Name="IsRecordValid"
																LabelText={field.AccountName}
																Value={field.IsRecordValid}
																ValueChanged={(n, v) => {
																	this.HandleAccountChange(field.Id, n, v);
																}}
																Disabled={!model.Balance} />
														</div>
													);
												})}
											</GridItem>
											<GridItem>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Min. Balance Notification"></GenericLabel>
												</div>
												{accountList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 0.4 }}>
															<GenericNumberInput
																Name="MinimumBalance"
																LabelMd={0}
																Value={field.MinimumBalance}
																ValueChanged={(n, v) => this.HandleAccountChange(field.Id, n, v)}
																Prefix="$"
																MaxLength={12}
																Disabled={!field.IsRecordValid || !model.Balance} />
														</div>
													);
												})}
											</GridItem>
											<GridItem xs>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Notification Channel"></GenericLabel>
												</div>
												{accountList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: "12%", paddingRight: "12%", textAlign: "center" }}>
															<GenericCheckInput
																Name="SendSms"
																LabelText="SMS"
																Value={field.SendSms}
																ValueChanged={(n, v) => this.HandleAccountChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.Balance} />
															<GenericCheckInput
																Name="SendEmail"
																LabelText="E-Mail"
																Value={field.SendEmail}
																ValueChanged={(n, v) => this.HandleAccountChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.Balance} />
															<GenericCheckInput
																Name="SendApplication"
																LabelText="Portal"
																Value={field.SendApplication}
																ValueChanged={(n, v) => this.HandleAccountChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.Balance} />
														</div>
													);
												})}
											</GridItem>
											<GridItem xs>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="User"></GenericLabel>
												</div>
												{accountList.map(field => {
													return (
														<div key={field.Id} style={{ paddingTop: 1, paddingLeft: "12%", paddingRight: "12%" }}>
															<GenericSelectInput
																Name="KeyContactId"
																LabelMd={0}
																Method="POST"
																Url="/bankapi/v1.0/CustomerKeyContact/GetByUniqueClientId"
																Parameter={{}}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={d => `${d.Name} ${d.SurName}`}
																Value={field.KeyContactId}
																ValueChanged={(n, v) => this.HandleAccountChange(field.Id, n, v)}
																Disabled={!field.IsRecordValid || !model.Balance} />
														</div>
													);
												})}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
						{/* <GridContainer>
						<GridItem md={3} xs={12} style={{ marginTop: "14px", paddingLeft: "24px !important" }}>
							<GenericCheckInput
								Name="InternationalTransaction"
								LabelText="International Transaction Notifications"
								Value={model.InternationalTransaction}
								ValueChanged={(n, v) => {
									this.HandleChange(n, v);
								}} />
						</GridItem>
						<GridItem md={9} xs={12} style={{ opacity: !model.InternationalTransaction ? 0.4 : 1 }}>
							<Card >
								<CardBody>
									<GridItem>
										<GenericCheckInput
											Name="InternationalTransactionSendSms"
											LabelText="SMS"
											Value={model.InternationalTransactionSendSms}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="InternationalTransactionSendEmail"
											LabelText="E-Mail"
											Value={model.InternationalTransactionSendEmail}
											ValueChanged={this.HandleChange} />
										<GenericCheckInput
											Name="InternationalTransactionSendApplication"
											LabelText="Portal"
											Value={model.InternationalTransactionSendApplication}
											ValueChanged={this.HandleChange} />
									</GridItem>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer> */}
					</GridItem>
				</GridContainer >
			</>
		);
	}
}

NotificationSettings.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(NotificationSettings, {});