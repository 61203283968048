import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";


class BillIndividualList extends Component {

	constructor(props) {
		super(props);

		this.emptyObject = {};
		this.renderItemUniqueClient = {};
		this.sortedName = { Member: "Name" };
		this.isClient = true;


		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			StartDate: DateHelper.GetDate(),
			EndDate: DateHelper.GetDate(),
		};
		this.state = {
			model: {...this.initialModel},
			BillIndividualList: [],
			isLoading: false,
		
		};

		this.GetBillIndividualList = this.GetBillIndividualList.bind(this);
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	Search = () => {
		this.setState({ isLoading: true });
		this.GetBillIndividualList();

	}

	componentDidMount() {

		var isBackOff = ClientHelper.IsBackOffice();

		if (!isBackOff) {
			this.IsClient = true;
		}

		this.props.setAppLeftTitle("Individual Bill List");
		this.props.setAppCenterTitle("BILL PAYMENT");

	}

	GetBillIndividualList() {

		const { model } = this.state;
		Proxy.POST(
			"/bankapi/v1.0/Bill/GetAllIndividualBill",
			{
				"BillerId": model.BillerId,
				"BillerCode": model.BillerCode,
				"UniqueClientId": model.UniqueClientId,
				"StartDate": model.StartDate,
				"EndDate": model.EndDate,
				"ReferenceNumber": model.ReferenceNumber

			},
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({
						isLoading: false,
						BillIndividualList: responseData.Item
					});
				}
				else {
					this.setState({
						isLoading: false,
						BillIndividualList: []
					});
				}
			}
		);

	}

	SelectedRowChange = (index) => {
		var tempModel = { ...this.state.model };
		const data = this.state.BillIndividualList[index];

		tempModel.BillerId = data.BillerId;
		tempModel.BillerCode = data.BillerCode;
		tempModel.UniqueClientId = data.UniqueClientId;

		this.setState({ model: tempModel, tempModel, rowIndex: index });
	}
	Clear = () => {
		this.setState({ model: any });
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;

		return this.renderItemUniqueClient;
	}
	render() {

		const { model } = this.state;
		return (
			<div >

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear

						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<LoadingComponent Show={this.state.isLoading} />

				<Card>
					<GenericExpansionPanel IsActive={true} Title="Filter Panel" >
						<CardBody>
							<GridContainer >
								<GridItem xs={4}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										RenderItem={this.GetRenderItemUniqueClient}
										Sorted={this.sortedName}
										All={true}
										Value={model.UniqueClientId}
										ValueChanged={this.ValueChanged}
										Disabled={this.IsClient}
									/>
									<GenericSelectInput
										key={model.UniqueClientId}
										Name="BillerCode"
										LabelText="Payee Name"
										Method="POST"
										Url="/bankapi/v1.0/Biller/GetAllBiller"
										DataRoot="Item"
										KeyValueMember="BillerCode"
										TextValueMember="BillerName"
										Parameter={{
											UniqueClientId: model.UniqueClientId,
											BillPayType: 1
										}}
										Value={model.BillerCode}
										Disabled={false}
										ValueChanged={this.ValueChanged}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Utc
										Name="StartDate"
										LabelText="From Date"
										Value={model.StartDate ? DateHelper.ToDateInputValueAsUtc(model.StartDate) : ""}
										ValueChanged={this.ValueChanged}
										MaxDate={model.StartDate || DateHelper.GetDate()}
										IncludeTime={false}
									/>

									<GenericDateInput
										Utc
										Name="EndDate"
										LabelText="To Date"
										Value={model.EndDate ? DateHelper.ToDateInputValueAsUtc(model.EndDate) : ""}
										ValueChanged={this.ValueChanged}
										MinDate={model.StartDate}
										MaxDate={DateHelper.GetDate()}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.ValueChanged} />
								</GridItem>
							</GridContainer>

						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={" Individual Bill List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.BillIndividualList}
									Columns={[
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Sent Date",
											accessor: "SentDate",
											type: GridColumnType.DateTimeUtc,
											ColumnType: GridColumnType.DateTimeUtc
										},
										{
											Header: "Settlement Date",
											accessor: "SettlementDate",
											type: GridColumnType.DateTimeUtc,
											ColumnType: GridColumnType.DateTimeUtc
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId"
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Payee Name",
											accessor: "BillerName"
										},
										{
											Header: "Payee Code",
											accessor: "PayeeCode"
										},
										{
											Header: "Payee Wallet No.",
											accessor: "BillAccountNumber"
										},
										{
											Header: "Customer Name",
											accessor: "ClientCustomerName"
										},

										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Fee",
											accessor: "Fee",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Transaction Status",
											accessor: "TelpaySend"
										},
										{
											Header: "Settlement Status",
											accessor: "SettlementStatus"
										},
										{
											Header: "Reference Number",
											accessor: "TransactionReferenceNumber"
										},
										{
											Header: "File Name",
											accessor: "OutgoingFileName"
										},
										{
											Header: "Settlement File Name",
											accessor: "SettlementFileName"
										}
									]}

									SelectedIndex={this.state.rowIndex}
									IsSorted={false}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
									ShowPagination={true} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div >
		);
	}
}

BillIndividualList.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillIndividualList;