import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericTitle, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class PersonalAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("Individual Wallet");
		this.props.setAppCenterTitle("MY PROFILE");
	}
	render() {
		const { model } = this.props;
		return (
			<Card>
				<CardBody>
					<GridContainer spacing={16}>
						<GridItem xs={4} >
							<GenericTitle text={" Individual Wallet"} />
							<GenericSelectInput
								Name="TitleId"
								LabelText="Title"
								Value={model == null ? "" : model.TitleId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST" Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "Title" }}
								DataRoot="Item" />
							<GenericSelectInput
								Name="GenderId"
								LabelText="Gender"
								Value={model == null ? "" : model.GenderId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST" Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "Gender" }}
								DataRoot="Item" />
							<GenericTextInput
								Name="Name"
								LabelText="Name"
								Value={model == null ? "" : model.Name || ""}
								Disabled={true} />
							<GenericTextInput Name="MidName"
								LabelText="MidName"
								Value={model == null ? "" : model.MidName || ""}
								Disabled={true} />
							<GenericTextInput Name="SurName"
								LabelText="Last Name"
								Value={model == null ? "" : model.SurName || ""}
								Disabled={true} />
							<GenericTextInput Name="Initial"
								LabelText="Initial"
								Value={model == null ? "" : model.Initial || ""}
								Disabled={true} />
							<GenericDateInput
								Name="BirthDate"
								LabelText="Date Of Birth"
								Value={model == null ? "" : DateHelper.ToDateInputValue(model.BirthDate || "") || ""}
								Disabled={true} />
							<GenericSelectInput
								Name="OccupationId"
								LabelText="Occupation"
								Value={model == null ? "" : model.OccupationId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST" Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "Occupation" }}
								DataRoot="Item" />
							<GenericSelectInput
								Name="PoliticalExposed"
								LabelText="Political Exposed"
								Value={model == null ? "" : model.PoliticalExposed == null ? "" : String(model.PoliticalExposed)}
								Disabled={true}
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "YesNo" }}
								DataRoot="Item" />
							<GenericSelectInput
								Name="AccountStatusId"
								LabelText="Wallet Status"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "CustomerStatus" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.ClientStatusId}
								Disabled={true} />
						</GridItem>
						<GridItem xs={4} >
							<GenericTitle text={"Communication"} />
							<GridContainer>
								<GridItem xs={8} sm={8} md={8}>
									<GenericSelectInput
										Name="PhoneCountryCodeId"
										LabelText="Primary Phone"
										LabelMd={6}
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember={"Code"}
										Value={model == null ? "" : model.PrimaryPhone == null ? "" : model.PrimaryPhone.PhoneCountryCodeId || ""}
										Disabled={true}
										Sorted={{ Member: "OrderIndex" }}
										RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
									/>
								</GridItem>
								<GridItem xs={4} sm={4} md={4} >
									<GenericNumberInput
										IsPhone
										MaxLength={10}
										Name="PhoneNumber"
										LabelMd={0}
										Value={model == null ? "" : model.PrimaryPhone == null ? "" : model.PrimaryPhone.PhoneNumber || ""}
										Disabled={true} />
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={8} sm={8} md={8}>
									<GenericSelectInput
										Name="PhoneCountryCodeId"
										LabelText="Alternative Phone"
										LabelMd={6}
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember={"Code"}
										Value={model == null ? "" : model.AlternativePhone == null ? "" : model.AlternativePhone.PhoneCountryCodeId || ""}
										Disabled={true}
										Sorted={{ Member: "OrderIndex" }}
										RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
									/>
								</GridItem>
								<GridItem xs={4} sm={4} md={4}>
									<GenericNumberInput
										IsPhone
										MaxLength={10}
										LabelMd={0}
										Name="PhoneNumber"
										Value={model == null ? "" : model.AlternativePhone == null ? "" : model.AlternativePhone.PhoneNumber || ""}
										Disabled={true} />
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={8} sm={8} md={8}>
									<GenericSelectInput
										Name="PhoneCountryCodeId"
										LabelText="Mobile Phone"
										LabelMd={6}
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember={"Code"}
										Value={model == null ? "" : model.MobilePhone == null ? "" : model.MobilePhone.PhoneCountryCodeId || ""}
										Disabled={true}
										Sorted={{ Member: "OrderIndex" }}
										RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
									/>
								</GridItem>
								<GridItem xs={4} sm={4} md={4}>
									<GenericNumberInput
										IsPhone
										MaxLength={10}
										LabelMd={0}
										Name="PhoneNumber"
										Value={model == null ? "" : model.MobilePhone == null ? "" : model.MobilePhone.PhoneNumber || ""}
										Disabled={true} />
								</GridItem>
							</GridContainer>
							<GenericTextInput
								Name="Email"
								LabelText="E-Mail"
								Value={model == null ? "" : model.Email || ""}
								Disabled={true} />
							<GenericSelectInput
								Name="LanguageId"
								LabelText="Preferred Language"
								Value={model == null ? "" : model.LanguageId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "Language", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
							<GenericSelectInput
								Name="SendSms"
								LabelText="Text/SMS Notification"
								Value={model == null ? "" : model.SendSms == null ? "" : String(model.SendSms)}
								Disabled={true}
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
							<GenericSelectInput
								Name="SendEmail"
								LabelText="E-Mail Notification"
								Value={model == null ? "" : model.SendEmail == null ? "" : String(model.SendEmail)}
								Disabled={true}
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
						</GridItem>
						<GridItem xs={12} sm={4} md={4} lg={4}>
							<h5><b>Other</b></h5>
							<GenericSelectInput
								Name="IdentificationMethodId"
								LabelText="Identification Method 1"
								Value={model == null ? "" : model.CustomerIdentification1 == null ? "" : model.CustomerIdentification1.IdentificationMethodId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST" Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "IdentificationMethod", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
							{model && model.CustomerIdentification1 && model.CustomerIdentification1.IdentityVerifyingPersonId != null &&
								<GenericSelectInput
									Disabled={true}
									Name="IdentityVerifyingPersonId"
									LabelText="Verifying Person"
									Value={model == null ? "" : model.CustomerIdentification1 == null ? "" : model.CustomerIdentification1.IdentityVerifyingPersonId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "IdentityVerifyingPerson" }}
									DataRoot="Item" />
							}
							<GenericSelectInput
								Name="IdentityTypeId"
								LabelText="Identification Type 1"
								Value={model == null ? "" : model.CustomerIdentification1 == null ? "" : model.CustomerIdentification1.IdentityTypeId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "IdentityType", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
							<GenericNumberInput
								NoFormatting={true}
								MaxLength={12}
								Name="IdentityNumber"
								LabelText="Identity Number 1"
								Value={model == null ? "" : model.CustomerIdentification1 == null ? "" : model.CustomerIdentification1.IdentityNumber || ""}
								Disabled={true} />
							<GenericTextInput
								Name="IdentityPlaceOfIssue"
								LabelText="Identity Place Of Issue 1"
								Value={model == null ? "" : model.CustomerIdentification1 == null ? "" : model.CustomerIdentification1.IdentityPlaceOfIssue || ""}
								Disabled={true} />
							<GenericDateInput
								Name="IdentityExpireDate"
								LabelText="Identity Expire Date 1"
								Value={model == null ? "" : model.CustomerIdentification1 == null ? "" : DateHelper.ToDateInputValue(model.CustomerIdentification1.IdentityExpireDate || "")}
								Disabled={true} />
							<hr />
							<GenericSelectInput
								Name="IdentificationMethodId"
								LabelText="Identification Method 2"
								Value={model == null ? "" : model.CustomerIdentification2 == null ? "" : model.CustomerIdentification2.IdentificationMethodId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST" Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "IdentificationMethod", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
							{model && model.CustomerIdentification2 && model.CustomerIdentification2.IdentityVerifyingPersonId != null &&
								<GenericSelectInput
									Disabled={true}
									Name="IdentityVerifyingPersonId"
									LabelText="Verifying Person"
									Value={model == null ? "" : model.CustomerIdentification2 == null ? "" : model.CustomerIdentification2.IdentityVerifyingPersonId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "IdentityVerifyingPerson" }}
									DataRoot="Item" />
							}
							<GenericSelectInput
								Name="IdentityTypeId"
								LabelText="Identification Type 2"
								Value={model == null ? "" : model.CustomerIdentification2 == null ? "" : model.CustomerIdentification2.IdentityTypeId || ""}
								Disabled={true}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "IdentityType", ParameterValue: "", ParameterDesc: "" }}
								DataRoot="Item" />
							<GenericNumberInput
								NoFormatting={true}
								MaxLength={12}
								Name="IdentityNumber"
								LabelText="Identity Number 2"
								Value={model == null ? "" : model.CustomerIdentification2 == null ? "" : model.CustomerIdentification2.IdentityNumber || ""}
								Disabled={true} />
							<GenericTextInput
								Name="IdentityPlaceOfIssue"
								LabelText="Identity Place Of Issue 2"
								Value={model == null ? "" : model.CustomerIdentification2 == null ? "" : model.CustomerIdentification2.IdentityPlaceOfIssue || ""}
								Disabled={true} />
							<GenericDateInput
								Name="IdentityExpireDate"
								LabelText="Identity Expire Date 2"
								Value={model == null ? "" : model.CustomerIdentification2 == null ? "" : DateHelper.ToDateInputValue(model.CustomerIdentification2.IdentityExpireDate || "")}
								Disabled={true} />
						</GridItem>

					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

PersonalAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	model: PropTypes.object
};

export default PersonalAccount;