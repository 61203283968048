import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
const notificationStyle = {
	cardBodyColor: {
		backgroundColor: primaryColor,
		color: "#ffffff",
		textAlign: "center"
	},
	iconCss: {
		fontSize: "36px",
		textAlign: "center",
		paddingBottom:"14px",
		paddingTop:"5px"
	},
};

export default notificationStyle;
