import { DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import genericGridStyle from "assets/jss/generic/genericGridStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ArrayHelper from "core/ArrayHelper";
import { ExportToCsv, ExportToExcel, ExportToPdf } from "core/ExportFileHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Select from "react-select";
import ReactTable from "react-table";
import {
  GenericAlert,
  GenericDialog,
  GenericDialogActions,
  GenericLabel,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DocumentType, GridColumnType } from "views/Constants/Constant";
import ResourceHelper from "../../../core/ResourceHelper";

class GenericGrid extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isColumnSelectDialogOpen: false,
      GridKeyValueList: [],
      SelectedGridKeyValueList: [],
      type: 2,
      alert: null,
    };

    this.tableInstance = React.createRef();

    this.ExcludedColumns = [
      "AP",
      "SE",
      "ED",
      "TR",
      "SUS",
      "CHQ",
      "actions",
      "Actions",
    ];
  }

  HandleChange = (selectedOptions) => {
    this.setState({ SelectedGridKeyValueList: selectedOptions });
  };

  OpenDialog = (type) => {
    const { Columns } = this.props;
    var keyvalueList = [];
    Columns.forEach(function (gridColumnElement) {
      if (
        !this.ExcludedColumns.includes(gridColumnElement.accessor) &&
        gridColumnElement.show !== false
      ) {
        var item = {
          value: gridColumnElement.accessor,
          label: gridColumnElement.Header,
          columntype: gridColumnElement.ColumnType,
        };
        keyvalueList.push(item);
      }
    }, this);
    this.setState({
      isColumnSelectDialogOpen: true,
      type: type,
      GridKeyValueList: keyvalueList,
      SelectedGridKeyValueList: keyvalueList,
    });
  };

  GetDataToDownload = () => {
    const { Data } = this.props;
    const { SelectedGridKeyValueList, type } = this.state;

    var keyvalueList = [];

    var data = this.ResolveData(Data);
    data = this.GetData(data);
    data.forEach((listElement) => {
      var keyValueObject = {};
      SelectedGridKeyValueList.forEach((gridColumnElement) => {
        keyValueObject[gridColumnElement.label] = this.GetElementValue(
          listElement,
          gridColumnElement.value
        );
      }, this);
      keyvalueList.push(keyValueObject);
    }, this);

    return keyvalueList;
  };

  HandleDocument = () => {
    this.setState({ isColumnSelectDialogOpen: false });
    const { SelectedGridKeyValueList, type } = this.state;

    if (type == DocumentType.PDF) {
      if (SelectedGridKeyValueList.length > 15) {
        this.ShowMessage(
          "warning",
          "Required fields",
          "PDF document cannot exceed 15 columns"
        );
        this.setState({ isLoading: false });

        return false;
      }
      ExportToPdf(this.GetDataToDownload(), "report");
    } else if (type == DocumentType.Excel) {
      ExportToExcel(this.GetDataToDownload(), "report");
    } else if (type == DocumentType.CSV) {
      ExportToCsv(this.GetDataToDownload(), "report");
    }
  };

  ResolveData = (data) => {
    const { ResolveData } = this.props;

    if (ResolveData) return ResolveData(data);
    else return data;
  };

  GetData = (Data) => {
    const { IsSorted, Sorted } = this.props;
    const newData = [...Data];

    if (IsSorted && Sorted != null) {
      ArrayHelper.SortArray(newData, Sorted[0].id, Sorted[0].desc);
    }

    return newData.map((row) => {
      let newObject = {};
      Object.keys(row).forEach((propertyKey) => {
        newObject[propertyKey] = this.ClearUp(row[propertyKey], propertyKey);
      });
      return newObject;
    });
  };

  ClearUp = (obj, key) => {
    var column = this.props.Columns.find((x) => x.accessor === key);
    if (column) {
      if (typeof obj === "object" && obj != null) {
        // this.ClearUp(obj, key);
      }
      if (column.type === GridColumnType.Money) {
        obj = new Intl.NumberFormat("en-CA", {
          style: "currency",
          currency: "CAD",
        }).format(obj || 0);
      }
      if (column.type === GridColumnType.Percent) {
        obj = new Intl.NumberFormat("en-CA", {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(obj || 0);
      }
      if (column.type === GridColumnType.Date) {
        obj = obj ? Formatter.FormatDate(obj) : null;
      }
      if (column.type === GridColumnType.DateTime) {
        obj = obj ? Formatter.FormatDateTime(obj) : null;
      }
      if (column.type === GridColumnType.DateUtc) {
        obj = obj ? Formatter.FormatDateUtc(obj) : null;
      }
      if (column.type === GridColumnType.DateTimeUtc) {
        obj = obj ? Formatter.FormatDateTimeUtc(obj) : null;
      }
      if (column.type === GridColumnType.Time) {
        obj = obj ? Formatter.FormatTime(obj) : null;
      }
      if (column.type === GridColumnType.YesNo) {
        obj = obj != null ? Formatter.FormatYesNo(obj) : null;
      }
    }
    return obj;
  };

  GetTrProps = (state, rowInfo) => {
    const { SelectedIndex, RowSelected } = this.props;

    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          if (RowSelected) RowSelected(rowInfo.index);
        },
        style: {
          background:
            rowInfo.index === SelectedIndex ? "rgba(0, 0, 0, 0.1)" : "",
          cursor: RowSelected ? "pointer" : "auto",
        },
      };
    } else {
      return {};
    }
  };

  GetTdProps = (state, rowInfo, columnInfo) => {
    const { ColumnSelected } = this.props;

    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          if (ColumnSelected)
            ColumnSelected(rowInfo.index, rowInfo, columnInfo);
        },
      };
    } else {
      return {};
    }
  };

  DefaultFilter = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] != null) {
      return String(row[id])
        .toLowerCase()
        .startsWith(String(filter.value).toLowerCase());
    }
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={this.HideAlert}
        />
      ),
    });
  };

  HideAlert = () => {
    this.setState({ alert: null });
  };

  GetElementValue = (obj, path) => {
    if (obj == null || path == null) return "";
    return [obj].concat(path.split(".")).reduce((c, n) => c && c[n]);
  };

  GetCellDate = (value) => {
    return Formatter.FormatDate(value);
  };

  GetCellDateTime = (value) => {
    return Formatter.FormatDateTime(value);
  };

  GetCellDateUtc = (value) => {
    return Formatter.FormatDateUtc(value);
  };

  GetCellDateTimeUtc = (value) => {
    return Formatter.FormatDateTimeUtc(value);
  };

  GetCellMoney = (value) => {
    return Formatter.FormatMoney(value);
  };

  GetCelPercent = (value) => {
    return Formatter.FormatPercentFee(value);
  };

  GetCellTime = (value) => {
    return Formatter.FormatTime(value);
  };

  GetCellYesNo = (value) => {
    return Formatter.FormatYesNo(value);
  };

  LoadMoreClick = () => {
    var table = this.tableInstance.current;

    if (table)
      this.setState({ LoadMoreClicked: true }, () => {
        this.OnFetchData(table.state, null, true);
      });
  };

  OnFetchData = (state, instance, loadMore) => {
    const {
      LoadAtStartup,
      ValidateRequest,
      PrepareRequest,
      RequestUrl,
      RequestMethod,
    } = this.props;

    if (!LoadAtStartup) return;

    if (ValidateRequest && !ValidateRequest()) return;

    var request = {
      PageNumber: state.page,
      PageSize: state.pageSize,
      CacheGuid: this.state.CacheGuid,
    };
    if (loadMore) {
      state.page += 1;
      request.PageNumber += 1;
    }

    if (PrepareRequest) {
      request = { ...request, ...PrepareRequest() };
    }

    this.setState({ Loading: true });
    if (RequestMethod == "POST") {
      Proxy.POST(
        RequestUrl,
        request,
        (responseData) =>
          this.FetchSuccess(responseData, loadMore, request.PageNumber),
        (errorMessage) => {
          this.setState({ Loading: false });
          this.ShowMessage("error", "Error", errorMessage);
        }
      );
    } else {
      Proxy.GET(
        RequestUrl,
        (responseData) =>
          this.FetchSuccess(responseData, loadMore, request.PageNumber),
        (errorMessage) => {
          this.setState({ Loading: false });
          this.ShowMessage("error", "Error", errorMessage);
        }
      );
    }
  };

  FetchSuccess = (responseData, loadMore, pageNumber) => {
    this.setState({ Loading: false });
    if (!responseData.IsSucceeded) {
      this.ShowMessage("error", "Error", responseData.ErrorDescription);
      return;
    }
    if (responseData.Item.Data != null || !responseData.Item.Data.isUndefined) {
      responseData.Item.Data.forEach(item => {
        if (item.hasOwnProperty("ChannelType")) {
          item.ChannelType = ResourceHelper.CorrectAccountNames(item.ChannelType);
        }
        if (item.hasOwnProperty("Description")) {
          item.Description = ResourceHelper.CorrectAccountNames(item.Description);
        }
        if (item.hasOwnProperty("FeeTypeDesc")) {
          item.FeeTypeDesc = ResourceHelper.CorrectAccountNames(item.FeeTypeDesc);
        }
        if (item.hasOwnProperty("DestinationAccountName")) {
          item.DestinationAccountName = ResourceHelper.CorrectAccountNames(item.DestinationAccountName);
        }
        if (item.hasOwnProperty("SourceAccountName")) {
          item.SourceAccountName = ResourceHelper.CorrectAccountNames(item.SourceAccountName);
        }
      })
    }
    const { PaginationFetchDataCallback, ServerSide } = this.props;
    if (responseData.Item !== null && responseData.Item.Data) {
      if (PaginationFetchDataCallback && ServerSide) {
        if (loadMore) {
          PaginationFetchDataCallback([
            ...this.state.Data,
            ...responseData.Item.Data,
          ]);
        } else {
          PaginationFetchDataCallback([...responseData.Item.Data]);
        }
      }
      this.setState((state) => {
        var data = responseData.Item.Data;
        var pageCount = responseData.Item.TotalPages;

        if (loadMore) {
          data = state.Data.concat(data);
        }

        return {
          Data: data,
          PageCount: pageCount,
          CacheGuid: responseData.Item.CacheGuid,
        };
      });
    } else {
      this.setState({ Data: [] });
    }
  };

  render() {
    const {
      Columns,
      Data: PropData,
      Sorted,
      HideButton,
      PageSize,
      ShowPagination,
      SetTrProps,
      SetTdProps,
      IsSorted,
      ServerSide,
      HideLoadMore,
    } = this.props;
    const {
      isColumnSelectDialogOpen,
      GridKeyValueList,
      SelectedGridKeyValueList,
      alert,
      Loading,
      PageCount,
      Data: StateData,
    } = this.state;

    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    ctx.font = '14px "Roboto", "Helvetica", "Arial", sans-serif';
    Columns.forEach((c) => {
      if (c.minWidth == null) c.minWidth = ctx.measureText(c.Header).width + 30;

      if (c && !c.Cell && c.type) {
        if (c.type == GridColumnType.Date) {
          c.Cell = (row) => (row.value ? this.GetCellDate(row.value) : null);
        } else if (c.type == GridColumnType.DateTime) {
          if (c.style != null) {
            c.Cell = (row) =>
              row.original[`${c.style.column}`] == c.style.condition ? (
                <div style={c.style.style}>
                  {row.value ? this.GetCellDateTime(row.value) : null}
                </div>
              ) : (
                <div>{row.value ? this.GetCellDateTime(row.value) : null}</div>
              );
          } else {
            c.Cell = (row) =>
              row.value ? this.GetCellDateTime(row.value) : null;
          }
        } else if (c.type == GridColumnType.DateUtc) {
          c.Cell = (row) => (row.value ? this.GetCellDateUtc(row.value) : null);
        } else if (c.type == GridColumnType.DateTimeUtc) {
          if (c.style != null) {
            c.Cell = (row) =>
              row.original[`${c.style.column}`] == c.style.condition ? (
                <div style={c.style.style}>
                  {row.value ? this.GetCellDateTimeUtc(row.value) : null}
                </div>
              ) : (
                <div>
                  {row.value ? this.GetCellDateTimeUtc(row.value) : null}
                </div>
              );
          } else {
            c.Cell = (row) =>
              row.value ? this.GetCellDateTimeUtc(row.value) : null;
          }
        } else if (c.type == GridColumnType.Money) {
          if (c.style != null) {
            c.Cell = (row) =>
              row.original[`${c.style.column}`] == c.style.condition ? (
                <div style={c.style.style}>
                  {row.value == 0 ? "" : c.style.prefix[0]}
                  {row.value
                    ? this.GetCellMoney(row.value)
                    : this.GetCellMoney(0)}
                </div>
              ) : (
                <div>
                  {row.value == 0 ? "" : c.style.prefix[1]}
                  {row.value
                    ? this.GetCellMoney(row.value)
                    : this.GetCellMoney(0)}
                </div>
              );
          } else {
            c.Cell = (row) =>
              row.value ? this.GetCellMoney(row.value) : this.GetCellMoney(0);
          }
        } else if (c.type == GridColumnType.Percent) {
          c.Cell = (row) =>
            row.value != null ? this.GetCelPercent(row.value) : null;
        } else if (c.type == GridColumnType.Time) {
          c.Cell = (row) => (row.value ? this.GetCellTime(row.value) : null);
        } else if (c.type == GridColumnType.YesNo) {
          c.Cell = (row) =>
            row.value != null ? this.GetCellYesNo(row.value) : null;
        }
      }
    });

    //#region CheckIdColumn For Sorting
    //Our grid must sort list according to Id column as default.
    //We have to add Id column if the prop column list doesn't have Id column. Otherwise react table couldn't sorting with Id.
    var IdColumn = [
      {
        Header: "Id",
        accessor: "Id",
        show: false,
      },
    ];
    var finalColumns = undefined;
    var found = Columns.find((x) => x.accessor == "Id");
    if (found == null) finalColumns = IdColumn.concat(Columns);
    //#endregion

    const Data = ServerSide ? StateData || [] : PropData || [];

    return (
      <GridContainer
        container
        justify="center"
        alignItems="stretch"
      >
        {alert}

        <GenericDialog open={isColumnSelectDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel Text="Select Column" FontSize="20px" Bold={true} />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={SelectedGridKeyValueList}
                onChange={this.HandleChange}
                isMulti
                name="colors"
                options={GridKeyValueList}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={() => this.HandleDocument()}>
              Get Report
            </Button>
            <Button
              size="sm"
              onClick={() => this.setState({ isColumnSelectDialogOpen: false })}
            >
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>

        <LoadingComponent Show={this.state.isLoading} />

        {!ServerSide && !HideButton && (
          <GridContainer
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <GridItem xs={6}></GridItem>
            <GridItem xs={6}>
              <GridContainer justify="flex-end">
                <GridItem xs={2}>
                  <Button
                    size="sm"
                    onClick={() => this.OpenDialog(DocumentType.PDF)}
                    fullWidth
                  >
                    PDF
                  </Button>
                </GridItem>
                <GridItem xs={2}>
                  <Button
                    size="sm"
                    onClick={() => this.OpenDialog(DocumentType.CSV)}
                    fullWidth
                  >
                    CSV
                  </Button>
                </GridItem>
                <GridItem xs={2}>
                  <Button
                    size="sm"
                    onClick={() => this.OpenDialog(DocumentType.Excel)}
                    fullWidth
                  >
                    Excel
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}

        {ServerSide && !HideLoadMore && (
          <GridContainer justify="center">
            <GridItem xs={4}></GridItem>
            <GridItem xs={4}>
              <Button
                disabled={
                  Loading ||
                  PageCount == null ||
                  PageCount <= 1 ||
                  this.tableInstance.current.state.page + 1 == PageCount
                }
                size="md"
                onClick={this.LoadMoreClick}
                fullWidth
              >
                Load More
              </Button>
            </GridItem>
            <GridItem xs={4}></GridItem>
          </GridContainer>
        )}

        <GridItem xs={12}>
          <div>
            <ReactTable
              ref={this.tableInstance}
              className="-striped -highlight"
              data={Data || []}
              filterable={!ServerSide}
              columns={finalColumns || Columns}
              pageSize={ShowPagination ? undefined : Data ? Data.length : 0}
              defaultPageSize={ShowPagination ? PageSize : undefined}
              defaultFilterMethod={this.DefaultFilter}
              showPaginationTop={ServerSide && !this.state.LoadMoreClicked}
              showPaginationBottom={ShowPagination && !ServerSide}
              minRows={2}
              sorted={IsSorted ? Sorted : undefined}
              sortable={!ServerSide}
              resolveData={this.ResolveData}
              getTrProps={SetTrProps || this.GetTrProps}
              getTdProps={SetTdProps || this.GetTdProps}
              manual={ServerSide}
              onFetchData={this.OnFetchData}
              loading={Loading}
              pages={PageCount}
            />
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

GenericGrid.defaultProps = {
  PageSize: 100,
  ShowPagination: false,
  IsSorted: true,
  Sorted: [{ id: "Id", desc: true }],
};

GenericGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  Columns: PropTypes.array,
  Data: PropTypes.array,
  Sorted: PropTypes.array,
  ResolveData: PropTypes.func,
  RowSelected: PropTypes.func,
  ColumnSelected: PropTypes.func,
  SelectedIndex: PropTypes.number,
  DocumentTitle: PropTypes.string,
  HideButton: PropTypes.bool,
  PageSize: PropTypes.number,
  Model: PropTypes.object,
  ShowPagination: PropTypes.bool,
  SetTrProps: PropTypes.func,
  SetTdProps: PropTypes.func,
  IsSorted: PropTypes.bool,
  ProgramCode: PropTypes.string,
  ServerSide: PropTypes.bool,
  LoadAtStartup: PropTypes.bool,
  ValidateRequest: PropTypes.func,
  PrepareRequest: PropTypes.func,
  RequestUrl: PropTypes.string,
  RequestMethod: PropTypes.oneOf(["POST", "GET"]),
  PaginationFetchDataCallback: PropTypes.func,
  HideLoadMore: PropTypes.bool,
};

export default withStyles(genericGridStyle)(GenericGrid);
