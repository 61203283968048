const digitalVerificationPanelStyle = theme => ({
	statusLabel: {
		textAlign: "center",
	},
	statusBox: {
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "#00000085",
		textAlign: "center",
	},
	tabPageContainer: {
		height: 213
	},
	statusContainer: {
		height: "100%",
		" & > div > div": {
			width: 480
		}
	},
	verificationPanelScoreOnly: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: 110,
	}
});

export default digitalVerificationPanelStyle;
