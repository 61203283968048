import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericLabel, GenericNumberInput } from "views/Components/Generic";

class LoanCalculator extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {},
			vModel: {},
			isCalculationResultOpen: false,
			alert: null,
			FeeTotalSetupAmount: 0,
			isLoading: false,
			FeeProfileCalculateRate: 0,
		};
	}

	componentDidMount() {
		if (this.props.isRateFeeCalculator == true) {
			this.GetLOSMaxFee();
			this.TotalSetupFeeCalculate();
		}
	}
	GetLOSMaxFee = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: 'LOSMaxFeeRate',
				ParameterValue: 0,
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item.length > 0) {
					this.setState({ FeeProfileCalculateRate: responseData.Item[0].ParameterValue2 });
					console.log("GetLOSMaxFeeRate ->> " + this.state.FeeProfileCalculateRate);
				}
			},
			this.ErrorCallback
		);
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;
			return { model };
		});
	}

	Calculate = () => {
		if (!this.ValidateCalculate()) {
			return;
		}

		var { model } = this.state;
		if (model.TotalSetupAmount == undefined || model.TotalSetupAmount == null) {
			model.TotalSetupAmount = 0;
		}
		var rateOfInterest = model.MonthlyInterestRate / 1200;
		var monthlyPayment = (rateOfInterest * model.LoanAmount) / (1 - Math.pow(1 + rateOfInterest, model.Period * -1));

		model.MonthlyPayment = monthlyPayment + model.TotalSetupAmount / model.Period;
		model.TotalPaybackAmount = monthlyPayment * model.Period + model.TotalSetupAmount;
		model.TotalInterest = monthlyPayment * model.Period - model.LoanAmount;
		if (this.props.isRateFeeCalculator == true) {
			model.MaxFee = (model.TotalPaybackAmount / 100) * this.state.FeeProfileCalculateRate;
		}
		this.setState({ isCalculationResultOpen: true });
	}

	Close = () => {
		const { CloseLoanCalculator } = this.props;

		if (CloseLoanCalculator != null) {
			CloseLoanCalculator();
		}
	}

	DialogCalculationResultClose = () => {
		this.setState({ isCalculationResultOpen: false });
	}
	TotalSetupFeeCalculate() {
		let { FeeTotalSetupAmount } = this.state
		let { FeeLoanSetupProfileModel, isRateFeeCalculator } = this.props

		if (isRateFeeCalculator == true && FeeLoanSetupProfileModel != undefined && FeeLoanSetupProfileModel != null) {
			FeeTotalSetupAmount = 0;
			FeeLoanSetupProfileModel.FeeLoanSetupProfileList.map((setupFee, i, array) => {
				FeeTotalSetupAmount += FeeLoanSetupProfileModel.FeeLoanSetupProfileValueList[(FeeLoanSetupProfileModel.Id == undefined ? 0 : FeeLoanSetupProfileModel.Id) + "-" + setupFee.LOSSetupFeeId]
			})
			let { model } = this.state;
			model.TotalSetupAmount = FeeTotalSetupAmount;
			this.setState({ model })
		}
	}

	ValidateCalculate() {
		const { model } = this.state;
		let { vModel } = this.state;
		var messages = [];

		if (!model.LoanAmount) {
			messages.push("Loan amount cannot be empty.");
		} else {
			vModel.LoanAmount = false;
		}
		if (!model.Period) {
			messages.push("Period cannot be empty.");
			vModel.Period = true;
		} else {
			vModel.Period = false;
		}
		if (!model.MonthlyInterestRate) {
			messages.push("Monthly interest rate cannot be empty.");
			vModel.MonthlyInterestRate = true;
		} else {
			vModel.MonthlyInterestRate = false;
		}
		this.setState({ vModel });
		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			this.ShowMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={this.HideAlert}
				/>
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	render() {
		const { model, isCalculationResultOpen, vModel } = this.state;

		return (
			<div>
				{this.state.alert}
				<GenericDialog open={isCalculationResultOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel
							Text="Calculation Result"
							Bold />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={6} >
								<GenericNumberInput
									Disabled
									Name="LoanAmount"
									LabelText="Loan Amount"
									Value={model.LoanAmount}
									LabelMd={5}
									MaxLength={10}
									Prefix="$"
									ValueChanged={this.ValueChanged}
									Required
									IsInvalid={vModel.LoanAmount} />
								<GenericNumberInput
									Disabled
									Name="TotalInterest"
									LabelText="Total Interest"
									Value={model.TotalInterest}
									LabelMd={5}
									MaxLength={10}
									Prefix="$"
									ValueChanged={this.ValueChanged} />
								<GenericNumberInput
									Disabled
									Name="MonthlyInterestRate"
									LabelText="Monthly Interest Rate"
									LabelMd={5}
									MaxLength={10}
									Suffix="%"
									Value={model.MonthlyInterestRate}
									ValueChanged={this.ValueChanged}
									Required
									IsInvalid={vModel.MonthlyInterestRate} />
								<GenericNumberInput
									Disabled
									Name="TotalSetupAmount"
									LabelText="Total Setup Amount"
									Value={model.TotalSetupAmount}
									LabelMd={5}
									MaxLength={10}
									Prefix="$"
									ValueChanged={this.ValueChanged} />
								<GenericNumberInput
									Disabled
									Name="MonthlyPayment"
									LabelText="Monthly Payment"
									Value={model.MonthlyPayment}
									LabelMd={5}
									MaxLength={10}
									Prefix="$"
									ValueChanged={this.ValueChanged} />
								<GenericNumberInput
									Disabled
									Name="TotalPaybackAmount"
									LabelText="Total Payback Amount"
									Value={model.TotalPaybackAmount}
									LabelMd={5}
									MaxLength={10}
									Prefix="$"
									ValueChanged={this.ValueChanged} />
								{this.props.isRateFeeCalculator != true ? "" :
									<GenericNumberInput
										Disabled
										Name="MaxFee"
										LabelText="Maximum Fee"
										Value={model.MaxFee}
										LabelMd={5}
										MaxLength={10}
										Prefix="$"
										ValueChanged={this.ValueChanged} />
								}
								{
									this.props.isRateFeeCalculator != true ? "" :
										this.state.model.MaxFee > this.state.model.TotalSetupAmount ?
											""
											:
											<GenericLabel
												Text={"Total Payback amount cannot be more than %" + this.state.FeeProfileCalculateRate + " of loan amount. Please check your rate and Setup fees"} />
								}
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogCalculationResultClose}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer>
					<GridItem xs={4} />
					<GridItem xs={4}>
						<GenericLabel
							Bold
							Text="GENERIC LOAN CALCULATOR" />
					</GridItem>
					<GridItem xs={4} />
				</GridContainer>
				<GridContainer>
					<GridItem xs={3}>
						<GenericNumberInput
							Name="LoanAmount"
							LabelText="Loan Amount"
							Value={model.LoanAmount}
							LabelMd={6}
							ValueChanged={this.ValueChanged}
							Required
							IsInvalid={vModel.LoanAmount} />
					</GridItem>
					<GridItem xs={3}>
						<GenericNumberInput
							Name="Period"
							LabelText="Period"
							Value={model.Period}
							LabelMd={4}
							ValueChanged={this.ValueChanged}
							Required
							IsInvalid={vModel.Period} />
					</GridItem>
					<GridItem xs={3}>
						<GenericNumberInput
							Name="MonthlyInterestRate"
							LabelText="Monthly Interest Rate"
							Value={model.MonthlyInterestRate}
							LabelMd={8}
							ValueChanged={this.ValueChanged} 
							Required
							IsInvalid={vModel.MonthlyInterestRate} />
					</GridItem>
					<GridItem xs={3}>
						<GenericNumberInput
							Name="TotalSetupAmount"
							LabelText="Total Setup Amount"
							Value={model.TotalSetupAmount}
							LabelMd={8}
							ValueChanged={this.ValueChanged} />
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={5} />
					<GridItem xs={2} style={{ marginTop: 20 }}>
						<Button
							size="sm"
							tooltip="Calculate"
							onClick={this.Calculate}>
							Calculate
						</Button>
					</GridItem>
					<GridItem xs={2} style={{ marginTop: 20 }}>
						<Button
							size="sm"
							tooltip="Close"
							onClick={this.Close}>
							Close
						</Button>
					</GridItem>
					<GridItem xs={5} />
				</GridContainer>
			</div>
		);
	}
}

LoanCalculator.propTypes = {
	CloseLoanCalculator: PropTypes.func,
	isRateFeeCalculator: PropTypes.bool,
	FeeLoanSetupProfileModel: PropTypes.object,

};

export default LoanCalculator;