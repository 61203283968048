import PropTypes from "prop-types";
import React from "react";
import { Treebeard } from "react-treebeard";
import defaultTheme from "react-treebeard/dist/themes/default";
import decorators from "react-treebeard/dist/components/Decorators";

class CustomContainer extends decorators.Container {
	render() {
		const { decorators, node, onClick, style } = this.props;
		return (
			<div onClick={onClick} style={{ height: 31, borderBottom: "1px solid #dedede" }}>
				<decorators.Toggle style={style.toggle} />
				<decorators.Header node={node} style={style.header} />
			</div>
		);
	}
}

class TreeComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		const { data } = this.props;

		this.setState({ data });
	}

	render() {
		const { data } = this.state;

		decorators.Container = CustomContainer;

		defaultTheme.tree.base = {
			...defaultTheme.tree.base,
			backgroundColor: "white",
			color: "grey",
			fontFamily: "Roboto, Helvetica, Arial, sans-serif",
			fontSize: "12px"
		};
		defaultTheme.tree.node.header.title = {
			...defaultTheme.tree.node.header.title,
			lineHeight: "30px"
		};

		return (
			<div>
				<Treebeard
					style={defaultTheme}
					data={data} />
			</div>
		);
	}
}

TreeComponent.propTypes = {
	data: PropTypes.array
};

export default TreeComponent;
