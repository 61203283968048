import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericTextInput} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import Button from "components/CustomButtons/Button";

class ClearCardNumberSearch extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			CardTokenNumber: null,
			ClearCardNumber: null
		};

		this.ParameterMaxLength16 = { maxLength: 16 };

		this.state = {
			list: [],
			vModel: {},
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Clear Card Number Search");
		this.props.setAppCenterTitle("WALLET");
	}
	
	handleChange = (name, newValue) => {
		const model = { ...this.state.model };

		model[name] = newValue;

		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {}
		});
	};
	
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	GetClearCardNumber = () => {
		const model = { ...this.state.model };

		if (model.CardTokenNumber == null || model.CardTokenNumber == "") {
			this.ShowMessage("warning", "Warning", "Card Token Number must be defined!");
			return;
		}

		this.setState({ isLoading: true });

		Proxy.POST(
			"/prepaidapi/v1.0/Wallet/GetClearCardNumber",
			model.CardTokenNumber,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					model.ClearCardNumber = responseData.Item;
					this.setState({
						model,
						list: responseData.Item
					});
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	render() {
		const { Disabled } = this.props;
		const { alert, model, isLoading, vModel } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled
						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel Title=""> 
									<GridContainer>
										<GridItem xs={5}>
											<GenericTextInput
												Name="CardTokenNumber"
												LabelText="Card Token Number"
												Value={model.CardTokenNumber}
												inputProps={this.ParameterMaxLength16}
												IsInvalid={vModel.CardTokenNumber}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={2}>
											<Button size="sm" onClick={this.GetClearCardNumber}>Get Clear Card Number</Button>
										</GridItem>
										<GridItem xs={10}></GridItem>
										<GridItem xs={5}>
											<GenericTextInput
												Name="ClearCardNumber"
												LabelText="Clear Card Number"
												Value={model.ClearCardNumber}
												ValueChanged={this.handleChange}
												Disabled />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ClearCardNumberSearch.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ClearCardNumberSearch, {});
