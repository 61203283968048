import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput } from "views/Components/Generic";
import { ProgramCodes, Direction } from "views/Constants/Constant";

class ReconciliationCDICTab extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { CDICSummary } = this.props;
		return (
			<GridContainer>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Outgoing Transactions</b>
								<GenericNumberInput
									Name="TotalAmountCredit"
									LabelText="Total Amount Credit"
									Value={CDICSummary == null ? 0 : CDICSummary.TotalAmountCredit ? CDICSummary.TotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="TotalAmountDebit"
									LabelText="Total Amount Debit"
									Value={CDICSummary == null ? 0 : CDICSummary.TotalAmountDebit ? CDICSummary.TotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<b>Response File Value</b>
								<GenericNumberInput
									Name="ResponseTotalAmountCredit"
									LabelText="Response Total Amount Credit"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseTotalAmountCredit ? CDICSummary.ResponseTotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountDebit"
									LabelText="Response Total Amount Debit"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseTotalAmountDebit ? CDICSummary.ResponseTotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountRejected"
									LabelText="Response Total Amount Rejected"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseTotalAmountRejected ? CDICSummary.ResponseTotalAmountRejected : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountError"
									LabelText="Response Total Amount Error"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseTotalAmountError ? CDICSummary.ResponseTotalAmountError : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountPending"
									LabelText="Response Total Amount Pending"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseTotalAmountPending ? CDICSummary.ResponseTotalAmountPending : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAmount"
									LabelText="Total Amount"
									Value={CDICSummary == null ? 0 : CDICSummary.TotalAmount ? CDICSummary.TotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseFileAmount"
									LabelText="Total Response File Amount"
									Value={CDICSummary == null ? 0 : CDICSummary.TotalResponseFileAmount ? CDICSummary.TotalResponseAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="FileGap"
									LabelText="Gap"
									Value={CDICSummary.TotalAmount - CDICSummary.TotalResponseAmount}
									Disabled={true}
									LabelMd={6}
									Prefix="$"
									EndIconName="blur_on"
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_CDIC, Direction.Outgoing)}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Number of Outgoing Transactions</b>
								<GenericNumberInput
									Name="NumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.NumberCreditTransaction ? CDICSummary.NumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="NumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.NumberDebitTransaction ? CDICSummary.NumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<b>Number of Response Transactions</b>
								<GenericNumberInput
									Name="ResponseNumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseNumberCreditTransaction ? CDICSummary.ResponseNumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseNumberDebitTransaction ? CDICSummary.ResponseNumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberRejectedTransaction"
									LabelText="Number of Rejected Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseNumberRejectedTransaction ? CDICSummary.ResponseNumberRejectedTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberErrorTransaction"
									LabelText="Number of Error Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseNumberErrorTransaction ? CDICSummary.ResponseNumberErrorTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberPendingTransaction"
									LabelText="Number of Error Transaction"
									Value={CDICSummary == null ? 0 : CDICSummary.ResponseNumberPendingTransaction ? CDICSummary.ResponseNumberPendingTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalNumberTransaction"
									LabelText="Total Number of Trans."
									Value={CDICSummary == null ? 0 : CDICSummary.TotalNumberTransaction ? CDICSummary.TotalNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseNumberTransaction"
									LabelText="Total Response Number of Trans."
									Value={CDICSummary == null ? 0 : CDICSummary.TotalResponseNumberTransaction ? CDICSummary.TotalResponseNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionGap"
									LabelText="Gap"
									Value={CDICSummary.TotalNumber-CDICSummary.TotalResponseNumber}
									EndIconName="blur_on"
									LabelMd={6}
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_CDIC, Direction.Outgoing)}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridContainer >
		);
	}
}

ReconciliationCDICTab.propTypes = {
	classes: PropTypes.object,
	CDICSummary: PropTypes.object,
	openDialog: func
};

export default ReconciliationCDICTab;