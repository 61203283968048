// ##############################
// // // Header styles
// #############################

import {
	containerFluid,
	defaultFont,
	primaryColor,
	defaultBoxShadow,
	infoColor,
	successColor,
	warningColor,
	dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const smallFont = {
	fontSize: "12px"
};

const headerStyle = theme => ({
	appBar: {
		backgroundColor: window.BackgroundColor,
		boxShadow: "none",
		borderBottom: "0",
		marginBottom: "0",
		//width: "100%",
		minWidth: "960px",
		zIndex: "1029",
		color: "white",
		border: "0",
		borderRadius: "0px",
		transition: "all 150ms ease 0s",
		minHeight: "50px",
		display: "block",
		position: "absolute",
		[theme.breakpoints.down("md")]: {
			position: "fixed",
			minWidth: "unset"
		}
	},
	container: {
		...containerFluid,
		minHeight: "50px"
	},
	flex: {
		flex: 1
	},
	title: {
		...defaultFont,
		lineHeight: "30px",
		fontSize: "18px",
		borderRadius: "3px",
		textTransform: "none",
		color: "white",
		paddingTop: "0.625rem",
		paddingBottom: "0.625rem",
		margin: "0 !important",
		"&:hover,&:focus": {
			background: "transparent"
		}
	},
	primary: {
		backgroundColor: primaryColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	info: {
		backgroundColor: infoColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	success: {
		backgroundColor: successColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	warning: {
		backgroundColor: warningColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	danger: {
		backgroundColor: dangerColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	sidebarMinimize: {
		float: "left",
		padding: "0 0 0 15px",
		display: "block",
		color: "#555555"
	},
	sidebarMinimizeRTL: {
		padding: "0 15px 0 0 !important"
	},
	sidebarMiniIcon: {
		width: "12px !important",
		height: "12px !important",
		color: primaryColor
	},
	minimizeButton: {
		paddingTop: "7px",
		height: "24px",
		width: "24px",
		minWidth: "24px"
	},
	headerText: {
		marginLeft: "10px",
		...smallFont
	},
	help: {
		paddingRight: "20px",
		...smallFont
	},
	smallFont: {
		...smallFont
	},
	gridItem: {
		padding: "2px !important"
	},
	logo: {
		paddingTop: "5px",
		paddingLeft: "5px",
		paddingRight: "5px",
		background: "white",
		borderRadius: "5px",
		margin: "5px",
		display: "block",
		position: "relative",
		zIndex: "4",
		"&:after": {
			content: "\"\"",
			position: "absolute",
			bottom: "0",
			height: "1px",
			right: "15px",
			width: "calc(100% - 30px)",
			backgroundColor: "hsla(0,0%,100%,.3)"
		}
	},
	logoMini: {
		transition: "all 300ms linear",
		opacity: 1,
		float: "left",
		textAlign: "center",
		width: "30px",
		display: "inline-block",
		maxHeight: "30px",
		// marginLeft: "22px",
		// marginRight: "18px",
		// marginTop: "7px",
		color: "inherit"
	},
	logoMiniRTL: {
		float: "right",
		marginRight: "30px",
		marginLeft: "26px"
	},
	logoNormal: {
		...defaultFont,
		transition: "all 300ms linear",
		display: "block",
		opacity: "1",
		transform: "translate3d(0px, 0, 0)",
		textTransform: "uppercase",
		fontSize: "18px",
		whiteSpace: "nowrap",
		fontWeight: "400",
		lineHeight: "30px",
		overflow: "hidden",
		"&,&:hover,&:focus": {
			color: "white"
		}
	},
	logoNormalRTL: {
		textAlign: "right"
	},
	logoNormalSidebarMini: {
		opacity: "0",
		transform: "translate3d(-25px, 0, 0)"
	},
	logoNormalSidebarMiniRTL: {
		transform: "translate3d(25px, 0, 0)"
	},
});

export default headerStyle;