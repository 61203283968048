import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BankTransactionStatus, GridColumnType, ProgramCodes, EtransferApplicationTypes } from "views/Constants/Constant.js";
import Select from "react-select";

class UnSettleds extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			SearchEndDate: DateHelper.GetDate(),
			SearchStartDate: DateHelper.GetDate(),
			IsBankingScreen: false
		};

		this.state = {
			checkedValues: [],
			list: [],
			alllist: [],
			fileList: [],
			selectList: [],
			reSubmitSelectList: [],
			deleteList: [],
			model: this.initialModel,
			vModel: {},
			countModel: {},
			isLoading: false,
			mainSearchItem: null,
			LimitToken: false,
			isBackOffice: false,
			cancelRequestModel: {
				SelectedETransfer: {},
				cancellationReason: null
			},
			cancelDialog: false
		};

		this.sortedByDate = [{ id: "TransactionDate", desc: true }];
		this.sortedByFileDate = [{ id: "FileDate", desc: true }];
		this.trxTable = React.createRef();
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		var { model, isBackOffice } = this.state;
		model.IsBankingScreen = this.props.MenuCode == "BETUSP";
		if (setAppLeftTitle)
			setAppLeftTitle("Un-Settled & Un-Processed Transactions");
		if (setAppCenterTitle)
			setAppCenterTitle(model.IsBankingScreen ? "WALLET" : "INTERAC E-TRANSFER");

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
		}
		this.setState({ model, isBackOffice, loadingCompleted: true });

		this.RefreshToolBar();
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, list: [], fileList: [] });
	}

	HandleCheck = (trans) => {
		var selectList = [...this.state.selectList];
		var reSubmitSelectList = [...this.state.reSubmitSelectList];
		var deleteList = [...this.state.deleteList];

		if (trans.BankTransactionStatusParamValue == BankTransactionStatus.Error ||
			trans.BankTransactionStatusParamValue == BankTransactionStatus.InsufficientBalance ||
			trans.BankTransactionStatusParamValue == BankTransactionStatus.BlockedLimit) {
			reSubmitSelectList = this.state.reSubmitSelectList.includes(trans.TransactionEtransferId)
				? this.state.reSubmitSelectList.filter(t => t !== trans.TransactionEtransferId)
				: [...this.state.reSubmitSelectList, trans.TransactionEtransferId];
		}
		if (trans.BankTransactionStatusParamValue == BankTransactionStatus.Unapproved) {
			selectList = this.state.selectList.includes(trans.TransactionEtransferId)
				? this.state.selectList.filter(t => t !== trans.TransactionEtransferId)
				: [...this.state.selectList, trans.TransactionEtransferId];
		}

		deleteList = this.state.deleteList.includes(trans.TransactionEtransferId)
			? this.state.deleteList.filter(t => t !== trans.TransactionEtransferId)
			: [...this.state.deleteList, trans.TransactionEtransferId];
		this.setState(state => ({
			selectList, deleteList, reSubmitSelectList,
			checkedValues: state.checkedValues.includes(trans)
				? state.checkedValues.filter(c => c !== trans)
				: [...state.checkedValues, trans]
		}));
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "MainSearchItemId" && data) {
			this.initialModel[name] = value;
			this.setState({ mainSearchItem: data.ParameterValue });
		}
		this.setState({ model });
	}

	HandleChangeItemCount = (name, value) => {
		const countModel = { ...this.state.countModel };
		countModel[name] = value;
		this.setState({ countModel });
	}
	selectedRowChange = (index) => {
		var transList = [...this.state.alllist];
		const selectFile = this.state.fileList[index];
		var fileTransactions = transList.filter(k => k.FileId == selectFile.FileId);
		this.setState({ list: fileTransactions, rowIndex: index });
	}

	IsValid = () => {
		const { model, vModel } = this.state;

		var searchIsNotSpecific = (model.RefNo == undefined || model.RefNo == null || model.RefNo == "") &&
			(model.PayeeEmail == undefined || model.PayeeEmail == null || model.PayeeEmail == "") &&
			(model.FileName == undefined || model.FileName == null || model.FileName == "");

		model["searchIsNotSpecific"] = searchIsNotSpecific;

		var validateStatus = true;

		if (searchIsNotSpecific) {

			if (typeof model.SearchStartDate != "object" && model.SearchStartDate != "" && model.SearchStartDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
				validateStatus = false;
			}

			if (typeof model.SearchEndDate != "object" && model.SearchEndDate != "" && model.SearchEndDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
				validateStatus = false;
			}

			if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object") && model.SearchEndDate < model.SearchStartDate) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
				validateStatus = false;
			}

			this.setState({ model, vModel });
			if (!validateStatus)
				return false;
		}
		else {
			this.setState({ model, vModel });
		}
		return true;
	}
	HandleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}
	handleSubmit = () => {
		if (this.state.selectList.length > 0)
			this.setState({
				isLoading: false,
				alert: <GenericAlert Title={"Approved"} Message={"Approve selected [" + this.state.selectList.length + "] Unapproved transactions ?"} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleApprovedOK(this.state.selectList)} />
			});
		else {
			this.ShowMessage("warning", "Warning!", "There is not selected any Unapproved transaction!");

		}
	}
	handleReSubmit = () => {
		if (this.state.reSubmitSelectList.length > 0)
			this.setState({
				isLoading: false,
				alert: <GenericAlert Title={"Approved"} Message={"Re-Submit selected [" + this.state.reSubmitSelectList.length + "] record?"} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleReSubmitApprove(this.state.reSubmitSelectList)} />
			});
		else {
			this.ShowMessage("warning", "Warning!", "There is not selected any appropriate transaction for Re-Submit operation!");

		}
	}
	handleReSubmitApprove = (IdList) => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/ReSubmitUnsettledAndUnProcessedTransactionById",
			{ TransactionEtransferIds: IdList },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowErrorMessage("Error =>" + responseData.ErrorDescription);
					return;
				}
				else {
					this.ShowMessage("success", "Re-Submit", "Re-Submit operation completed successfully.");
					this.HandleGetList();
				}
			},
			this.ErrorSendCallback
		);
	}
	handleApprovedOK = (IdList) => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/ApprovedUnsettledAndUnProcessedTransctionById",
			{ TransactionEtransferIds: IdList },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowErrorMessage("Error =>" + responseData.ErrorDescription);
					return;
				}
				else {
					this.ShowMessage("success", "Approved", "Transactions approved operation completed successfully.");
					this.HandleGetList();
				}
			},
			this.ErrorSendCallback
		);
	}

	ErrorSendCallback(error) {
		this.ShowErrorMessage("An error occurred while Approval data =>" + error.message);
	}

	ShowMessage = (type = "info", title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: null })} />, isLoading: false });
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	ShowErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Warning"} Message={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}
	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	showLoading = () => {
		this.setState({ isLoading: true, alert: null });
	}

	handleCancel = (eTransfer) => {
		if (!this.state.isBackOffice) {
			var cancelRequestModel = { ...this.state.cancelRequestModel };
			const selectTransfer = eTransfer;
			cancelRequestModel.SelectedETransfer = selectTransfer;
			this.setState({ cancelRequestModel, cancelDialog: true });
		}
	}

	handleChangeCancelModel = (name, newValue) => {
		const cancelRequestModel = { ...this.state.cancelRequestModel };
		cancelRequestModel.cancellationReason = newValue;
		this.setState({ cancelRequestModel });
	}

	confirmCancel = () => {
		var cancelRequestModel = { ...this.state.cancelRequestModel };
		if (cancelRequestModel.cancellationReason != null && cancelRequestModel.cancellationReason != "") {
			this.hideCancelDialog();
			this.showLoading();

			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/CancelOutgoingEtransfer",
				cancelRequestModel,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.setState({ IsConfirm: false });
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Transaction cancelled successfully");
					this.HandleGetList();
					this.RefreshToolBar();
				},
				error => {
					this.ShowMessage("error", "Error", error);
				});
		}
		else {
			this.ShowMessage("warning", "Warning", "Cancellation Reason cannot be null !");
		}
	}

	hideCancelDialog = () => { this.setState({ cancelDialog: false }); }

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.IsValid())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Interac e-Transfer Unsettled & Unprocessed Transfers";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/BankInteracETransfer/SearchUnSettledExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, checkedValues, isLoading, model, list, fileList, LimitToken, isBackOffice, cancelRequestModel, cancelDialog } = this.state;
		return (
			<div>
				{!isBackOffice && model.IsBankingScreen ?
					<AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={ProgramCodes.Bank_Programs_e_Transfer} IsUseDisableDebitLimit={false} />
					: !isBackOffice && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} />}
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled },
					{ Code: "Re-Submit", OnClick: this.handleReSubmit, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={cancelDialog} maxWidth="sm" fullWidth>
					<DialogTitle>
						<b><h6>Cancel Transaction	</h6></b>
					</DialogTitle>
					<DialogContent>
						<GenericTextInput
							Name="cancellationReason"
							LabelText="Cancellation Reason"
							Value={cancelRequestModel != null ? cancelRequestModel.cancellationReason != null ? cancelRequestModel.cancellationReason : "" : ""}
							ValueChanged={this.handleChangeCancelModel} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.confirmCancel}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ cancelDialog: false, cancelRequestModel: {} })}>No</Button>
					</GenericDialogActions>
				</GenericDialog>

				{/*EXPORT DIALOG*/}
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<ParameterComponent
												Name="PriorityTypeId"
												Value={model.PriorityTypeId}
												LabelText="e-Transfer Type"
												ParameterCode="EtransferType"
												ValueChanged={this.HandleChange} />
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterValue6"
												Value={model.TransactionTypeId}
												ValueChanged={this.HandleChange} />
											<ParameterComponent
												Name="TransactionStatusId"
												Value={model.TransactionStatusId}
												LabelText="Transaction Status"
												ParameterCode="BankTransactionStatus"
												ValueChanged={this.HandleChange} />
											<GenericSelectInput
												Name="TransactionSourceId"
												LabelText="Transaction Source"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{
													ParameterCode: "TransactionSource"
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionSourceId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="PayeeName"
												LabelText={!model.IsBankingScreen ? "Customer Name" : "Contact Name"}
												Value={model.PayeeName}
												ValueChanged={this.HandleChange} />
											<GenericTextInput
												Name="PayeeEmail"
												LabelText={!model.IsBankingScreen ? "Customer e-mail" : "Contact e-mail"}
												Value={model.PayeeEmail}
												ValueChanged={this.HandleChange} />
											<GenericNumberInput
												Name="TransactionAmount"
												LabelText="Amount"
												Value={model.TransactionAmount}
												ValueChanged={this.HandleChange}
												MaxLength={10}
												Prefix="$" />
											{!model.IsBankingScreen &&
												<GenericTextInput
													Name="FileName"
													LabelText="File Name"
													Value={model.FileName}
													ValueChanged={this.HandleChange} />
											}
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="SearchStartDate"
												LabelText="From Date"
												Value={model.SearchStartDate}
												ValueChanged={this.HandleChange}
												MaxDate={model.SearchEndDate || DateHelper.GetDate()}
												Disabled={Disabled}
												Utc />
											<GenericDateInput
												Name="SearchEndDate"
												LabelText="To Date"
												Value={model.SearchEndDate}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												MinDate={model.SearchStartDate || DateHelper.GetDate()}
												Utc />
											<GenericSelectInput
												Name="ApplicationId"
												LabelText="Application Type"
												Value={model === undefined ? "" : model.ApplicationId || ""}
												DataRoot="Item"
												All
												ValueChanged={this.HandleChange}
												KeyValueMember="Value"
												TextValueMember="Text"
												IsStatic
												StaticData={EtransferApplicationTypes || []}
											/>
											<GenericNumberInput
												NoFormatting={true}
												Name="RefNo"
												LabelText="Reference Number"
												Value={model.RefNo}
												ValueChanged={this.HandleChange} />
											<GenericNumberInput
												NoFormatting={true}
												Name="AccountNumber"
												LabelText="Wallet Number"
												MaxLength={9}
												Value={model.AccountNumber || ""}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Un-Settled & Un-Processed Transactions"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ref={this.trxTable}
													PageSize={10}
													ShowPagination={true}
													Sorted={this.sortedByDate}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div style={{ opacity: !row.original.IsEditable ? 0.4 : 1 }}>
																	<GenericCheckInput
																		IsWithButton
																		Grid
																		Value={checkedValues.filter(c => c.TransactionEtransferId == row.original.TransactionEtransferId).length > 0}
																		ValueChanged={(e) => {
																			this.HandleCheck(row.original);
																		}}
																		Disabled={!row.original.IsEditable || isBackOffice} />
																	<GridButton
																		tooltip="Edit"
																		Disabled={!row.original.IsEditable || isBackOffice}
																		Icon={EditIcon}
																		OnClick={() => { RouteHelper.Push(this.props.history, row.original.ProgramCode == ProgramCodes.Bank_Programs_e_Transfer ? "/CreateEtransfer" : "/EtransferFromAccount", "CreateEtransfer_Id", row.original.TransactionId); }} />
																	<GridButton
																		Disabled={row.original.BankTransactionStatusParamValue == BankTransactionStatus.Cancelled || row.original.BankTransactionStatusParamValue == BankTransactionStatus.Rejected || isBackOffice}
																		Icon={DeleteIcon}
																		tooltip="Cancel Transaction"
																		OnClick={() => { this.handleCancel(row.original); }} />
																</div>
															),
															sortable: false,
															filterable: false,
															width: 100,
															show: !isBackOffice
														},
														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateTimeUtc
														},
														{
															Header: "Insert Date",
															accessor: "InsertDate",
															type: GridColumnType.DateTimeUtc
														},
														{
															Header: "Wallet Number",
															accessor: "AccountNumber",
														},

														{
															Header: "Unique Client Id",
															accessor: "ClientNumber",
															show: isBackOffice
														},
														{
															Header: "Client Name",
															accessor: "ClientName",
															show: isBackOffice
														},

														{
															Header: "Customer Number",
															accessor: "ClientCustomerNumber",
															show: !model.IsBankingScreen
														},
														{
															Header: !model.IsBankingScreen ? "Customer Name" : "Contact Name",
															accessor: "CustomerName"
														},
														{
															Header: "Transaction Type",
															accessor: "TransactionType",
															Cell: row => (
																<div>{row.value == "Credit" ? "Money Send" : "Money Request"}</div>
															)
														},
														{
															Header: !model.IsBankingScreen ? "Customer e-mail" : "Contact e-mail",
															accessor: "Email"
														},
														{
															Header: "Amount",
															accessor: "TransactionAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "FeeAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Transaction Status",
															accessor: "TransactionStatus"
														},
														{
															Header: "e-Transfer Type",
															accessor: "ETransferType"
														},
														{
															Header: "Transaction Description",
															accessor: "TransactionMemo"
														},
														{
															Header: "File Name",
															accessor: "FileName",
															show: !model.IsBankingScreen
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource"
														},
														{
															Header: "Store Code",
															accessor: "StoreName"
														},
														{
															Header: "Application",
															accessor: "ApplicationId",
															Cell: row => {
																var rowValue = "";
																EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
																	if (item.Value == row.value)
																		rowValue = item.Text;
																});
																return rowValue;
															}
														},
														{
															Header: "Reference Number",
															accessor: "ParticipantReferenceNumber"
														},
														{
															Header: "Error Description",
															accessor: "ErrorDescription"
														},
														{
															Header: "Customer Store",
															accessor: "CustomerStore"
														},
														{
															Header: "Sender Name",
															accessor: "PayeeName"
														},
														{
															Header: "Sender Email",
															accessor: "PayeeEmail"
														},
														{
															Header: "Receiver Name",
															accessor: "ReceiverName"
														},
														{
															Header: "Receiver Email",
															accessor: "ReceiverEmail"
														},
														{
															Header: "Client Reference Number",
															accessor: "ClientReferenceNumber"
														},
													]}
													ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
													ServerSide
													LoadAtStartup={this.state.loadingCompleted}
													ValidateRequest={this.IsValid}
													PrepareRequest={() => { return this.state.model; }}
													RequestUrl="/bankapi/v1.0/BankInteracETransfer/SearchUnSettledPaged"
													RequestMethod="POST" />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

UnSettleds.propTypes = {
	classes: PropTypes.object
};

export default UnSettleds;
