import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DeleteIcon, DownloadIcon, EditIcon } from "core/Icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

const initialState = {
	model: {
		FileTypeId: null,
		Description: null,
		VersionNumber: null,
	},
	isBrowse: false,
	agreementList: [],
	isLoading: false,
	ReadOnly: false,
	vModel: {}
};
class AgreementTemplate extends Component {
	constructor(props) {
		super(props);
		this.SetFile = this.SetFile.bind(this);
		this.UploadClick = this.UploadClick.bind(this);
		this.FileSelect = this.FileSelect.bind(this);
		this.fileInputRef = React.createRef();
		this.UpdateFile = this.UpdateFile.bind(this);
		this.FileTypeParameterCode = { ParameterCode: "LOSFileType" };
		this.state = initialState;
		this.maxLength20 = { maxLength: 20 };
		this.marginLeft2 = { marginLeft: 2 };
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({ model: { ...row.original }, fileModel: row.original.File })} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
						<GridButton
							tooltip="Download"
							Icon={DownloadIcon}
							OnClick={() => this.DownloadModel(row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Loan Type",
				accessor: "LoanType.ParameterDesc"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				Cell: row => {
					return <div>{typeof row.value === "number" ? row.value : typeof row.value === "boolean" ? row.value === true ? "Yes" : "No" : moment(row.value, moment.ISO_8601, true).isValid() ? Formatter.FormatDateTime(row.value) : row.value}</div>;
				},
			},
			{
				Header: "Version Number",
				accessor: "VersionNumber"
			}
		];

	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppLeftTitle("Agreement Template");
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });

	}

	UploadClick() {
		this.fileInputRef.current.click();
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	FileSelect(e) {
		e.preventDefault();
		var file = e.target.files[0];
		file.FileName = file.name;
		if (!file) return;
		this.setState({ fileModel: file, isBrowse: true });
	}

	SetFile(file, documentModel) {
		this.setState({
			fileModel: { file, documentModel }
		});
	}

	HandleClickClearOk = () => {
		this.HideAlert();
		this.setState({ model: {} });
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this Agreement Template ?", () => this.HandleClickClearOk());
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}


	DownloadModel = (index) => {
		this.setState({ isLoading: true });
		const { showMessage } = this.props;
		Proxy.FileDownloadWithGenericResponse(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			index.File.FileName,
			index.File.FileExtension.ParameterDesc,
			{
				"FileId": index.FileId
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Template Must Be Selected For Delete Operation");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/AgreementTemplate/Delete",
			{ Id: model.Id },
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessageAndRefresh("success", "Success", "Template Deleted");
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/AgreementTemplate/Search",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ agreementList: responseData.Item || [], isLoading: false });
				} else {
					this.setState({ agreementList: [], isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Submit = () => {
		if (!this.ValidateSubmit()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/AgreementTemplate/InsertUpdate",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ model: responseData.Item });
					if (this.state.isBrowse == true) {
						this.UpdateFile(responseData.Item.Id);
						this.setState({ isBrowse: false });
					}
					else {
						this.setState({ isLoading: false });
						this.ShowMessageAndRefresh("success", "Success", "Operation Successfully Completed");
					}
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	UpdateFile = (documentId) => {
		var formData = new FormData();
		formData.append("file", this.state.fileModel, String(this.state.fileModel.FileName));
		formData.append("documentId", String(documentId));
		Proxy.FileUpload(
			"/losapi/v1.0/AgreementTemplate/UploadFile",
			formData,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ model: responseData.Item });
					this.setState({ isLoading: false });
					this.ShowMessageAndRefresh("success", "Success", "Operation Successfully Completed");

				} else {
					this.setState({ isLoading: false });
				}

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	ValidateSubmit = () => {
		var { model, vModel, fileModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.LoanTypeId) {
			errors.push("Loan type cannot be empty.");
			vModel.LoanTypeId = true;
		} else {
			vModel.LoanTypeId = false;
		}

		if (fileModel == null
			|| fileModel.FileName == null) {
			errors.push("File cannot be empty.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		var FileNameValue = "";
		if (this.state.fileModel) {
			FileNameValue = this.state.fileModel.FileName;
		}
		else if (this.state.documentModel) {
			FileNameValue = this.state.documentModel.FileName;
		}

		const { ForInsert, Disabled } = this.props;
		const { vModel } = this.state;
		const { model, alert, isLoading, ReadOnly } = this.state;
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: ForInsert || ReadOnly || Disabled },
					{ Code: "Search", OnClick: this.Search, Disabled: ForInsert || ReadOnly || Disabled },
					{ Code: "Submit", OnClick: this.Submit, Disabled: ForInsert || ReadOnly || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} IsPopUp={ForInsert} />
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true}>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={2} style={{ opacity: model.isGenerateNew ? 0.4 : 1 }}>
												<GenericSelectInput
													key="FileTypeId"
													Name="FileTypeId"
													LabelText="File Type"
													LabelMd={4}
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.FileTypeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.FileTypeId}
													ValueChanged={this.HandleChange}
													Disabled={model.isGenerateNew}
												/>
											</GridItem>
											<GridItem xs={3}>
												<ParameterComponent
													Required
													IsInvalid={vModel.LoanTypeId}
													Name="LoanTypeId"
													LabelText="Loan Type"
													ParameterCode="LOSLoanType"
													Value={model.LoanTypeId}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem xs={2}>
												<GenericTextInput
													InputStyle={{ marginLeft: 12 }}
													key="VersionNumber"
													Name="VersionNumber"
													LabelMd={5}
													LabelText="Version Number"
													Value={model.VersionNumber == null || undefined ? "" : model.VersionNumber}
													ValueChanged={this.HandleChange}
													Disabled={model.isGenerateNew} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={7}>
												<GenericTextInput
													InputStyle={{ marginLeft: 12 }}
													key="Description"
													Name="Description"
													LabelMd={1}
													LabelText="Description"
													Value={model.Description == null || undefined ? "" : model.Description}
													ValueChanged={this.HandleChange}
													Disabled={model.isGenerateNew} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={1} style={{ marginLeft: 2 }}>
												<GenericLabel Text="Upload File (pdf only)" />
											</GridItem>
											<GridItem xs={1}>
												<Button onClick={this.UploadClick} disabled={Disabled} style={{ marginLeft: "16px" }}>Browse</Button>
												<input type="file" style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef}
													accept="application/pdf" />
											</GridItem>
											<GridItem xs={3}>
												<GenericTextInput
													key={this.state.uniq}
													Name="FileName"
													LabelMd={4}
													LabelText="File Name"
													Value={FileNameValue}
													Disabled={true} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridItem xs={12} style={{ marginTop: 10 }}>
									<ReactTable
										className="-striped -highlight"
										data={this.state.agreementList || []}
										filterable={false}
										columns={this.ColumnsData}
										defaultPageSize={50}
										showPaginationTop={false}
										/* showPaginationBottom={Object.keys(m.ag || []).length > 0}  */
										minRows={2}
									/>

								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}
AgreementTemplate.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ApplicantSaved: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(AgreementTemplate, {});