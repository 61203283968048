import ParameterComponent from "views/Components/ParameterComponent";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import React, { Component } from "react";
import LoadingComponent from "views/Components/LoadingComponent";
import GridContainer from "components/Grid/GridContainer.jsx";
import {
    GenericNumberInput,
    GenericTextInput,
    GenericSelectInput,
    GenericTitle,
    GenericDateInput,
    GenericDialog,
    GenericLabel,
    GenericDialogActions,
    GenericGrid,
} from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import GridButton from "views/Components/GridButton";
import { DeleteIcon } from "core/Icons";
import { GridColumnType } from "views/Constants/Constant";
import AlertHelper from "core/AlertHelper";

class OfflineEtransferForReconciliation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isCreateDialogOpen: false,
            isApprovalAlertOpen: false,
            approvalAlert: "",
            model: {},
            dialogModel: {},
            defaultModel: {
                TransferTypeId: 1,
                EntryType: 16093,
                ProgramId: 12,
                MoneyTransferDate: DateHelper.GetDate(),
            },
            gridRefreshToken: false,
            loadAtStartup: false,
        };

        this.settlementErrorsGridRef = React.createRef();
    }

    cancelOfflineETransfer = async (row) => {
        const { ExecuteApiPost, showMessage } = this.props;

        const request = {
            TransactionReferenceNumber: row?.original?.TransactionReferenceNumber,
        };

        const response = await ExecuteApiPost(
            "/bankapi/v1.0/BankInteracETransfer/CancelOfflineEtransferForReconciliation",
            request
        );

        if (response && response.IsSucceeded) {
            showMessage(
                "success",
                `Offline E-Transfer is Cancelled!:  ${row?.original?.TransactionReferenceNumber}`
            );

            this.refreshGridToken();
        }
    };

    initColumns = () => {
        return [
            {
                Header: "Actions",
                accessor: "Actions",
                Cell: (row) => (
                    <div>
                        <GridButton
                            Disabled={this.isRowActionDisabled(row)}
                            tooltip="Cancel"
                            Icon={DeleteIcon}
                            OnClick={() => this.cancelOfflineETransfer(row)}
                        />
                    </div>
                ),
                sortable: false,
                filterable: false,
                width: 70,
                show: true,
            },
            {
                Header: "Transaction Date",
                accessor: "TransactionDate",
                type: GridColumnType.DateTimeUtc,
            },
            {
                Header: "Money Transfer Date",
                accessor: "MoneyTransferDate",
                type: GridColumnType.DateTimeUtc,
            },
            {
                Header: "Client ID",
                accessor: "UniqueClientId",
            },
            {
                Header: "Client Name",
                accessor: "ClientName",
            },
            {
                Header: "Trans. Reference Number",
                accessor: "TransactionReferenceNumber",
            },
            {
                Header: "Transaction Type",
                accessor: "TransactionType",
            },
            {
                Header: "Transaction Direction",
                accessor: "TransactionDirection",
            },
            {
                Header: "Amount",
                accessor: "Amount",
                type: GridColumnType.Money,
                ColumnType: GridColumnType.Money,
            },
            {
                Header: "Transaction Status",
                accessor: "TransactionStatus",
            },
        ];
    };

    isRowActionDisabled = (row) => {
        return !row?.original?.CanBeCancelled;
    };

    refreshGridToken = async () => {
        const { gridRefreshToken } = this.state;

        this.setState({ gridRefreshToken: !gridRefreshToken });
    };

    handleChange = (name, value) => {
        const model = { ...this.state.model };
        model[name] = value;
        this.setState({ model });
    };

    handleChangeDialog = (name, value) => {
        const dialogModel = { ...this.state.dialogModel };
        dialogModel[name] = value;
        this.setState({ dialogModel });
    };

    componentDidMount() {
        const { setAppLeftTitle, setAppCenterTitle } = this.props;
        setAppLeftTitle("E-Transfer for Reconciliation");
        setAppCenterTitle("Interac e-Transfer");
        this.setState({ loadAtStartup: true });
    }

    clear = () => {
        this.setState({ model: {} });
    };

    onValidate = () => {
        const { model } = this.state;
        const { showMessage } = this.props;

        if (!model.TransactionDateFrom) {
            showMessage(
                "warning",
                "Transaction Date From is not selected",
                "Select transaction date to continue."
            );
            return false;
        }

        if (!model.TransactionDateTo) {
            showMessage(
                "warning",
                "Transaction Date To is not selected",
                "Select transaction date to continue."
            );
            return false;
        }

        return true;
    };

    onDialogValidate = () => {
        const { dialogModel } = this.state;
        const { showMessage } = this.props;

        if (!dialogModel.UniqueClientId) {
            showMessage(
                "warning",
                "Client is not selected",
                "Select Client to continue."
            );
            return false;
        }

        if (!dialogModel.TransactionTypeId) {
            showMessage(
                "warning",
                "Transaction Type is not selected",
                "Select Transaction Type to continue."
            );
            return false;
        }

        if (!dialogModel.TransactionAmount) {
            showMessage(
                "warning",
                "Transaction Amount is not entered",
                "Enter Transaction Amount to continue."
            );
            return false;
        }

        //  if (!dialogModel.MoneyTransferDate) {
        //    showMessage(
        //      "warning",
        //      "Money Transfer Date is not selected",
        //      "Enter Money Transfer Date to continue."
        //    );
        //    return false;
        //  }

        if (!dialogModel.Description) {
            showMessage(
                "warning",
                "Description is not entered",
                "Enter Description to continue."
            );
            return false;
        }

        return true;
    };

    onSubmit = async () => {
        if (!this.onDialogValidate()) return;

        const { ExecuteApiPost, showMessage } = this.props;
        const { dialogModel } = this.state;
        const { defaultModel } = this.state;

        const request = {
            UniqueClientId: dialogModel.UniqueClientId,
            TransactionTypeId: dialogModel.TransactionTypeId,
            Description: dialogModel.Description,
            TransactionAmount: dialogModel.TransactionAmount,
            TransactionDate: defaultModel.MoneyTransferDate,
        };

        const response = await ExecuteApiPost(
            "/bankapi/v1.0/BankInteracETransfer/InsertOfflineEtransferForReconciliation",
            request,
            null,
            null,
            null,
            [this.clear],
            false
        );

        if (response && response.IsSucceeded) {
            showMessage(
                "success",
                `An off-line e-transfer transaction created with the following reference number:  ${response.ClientReferenceNumber}`
            );
            this.setState({ isCreateDialogOpen: false });
            this.setState({ dialogModel: {} });
            this.setState({ approvalAlert: "" });
        } else {
            // showMessage("error", response.ErrorDescription);
        }
    };

    onCreate = () => {
        this.setState({ isCreateDialogOpen: true });
    };

    closeCreateDialog = () => {
        this.setState({ isCreateDialogOpen: false });
        this.setState({ dialogModel: {} });
    };

    createDialog = () => {
        const { defaultModel, isCreateDialogOpen, dialogModel } = this.state;

        return (
            <GenericDialog open={isCreateDialogOpen} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <GenericLabel Text="Create Offline E-Transfer" Bold />
                </DialogTitle>
                <DialogContent style={{ height: 300 }}>
                    <GridContainer xs={12} justify="space-between" spacing={16}>
                        <GridItem xs={5}>
                            <GenericSelectInput
                                Name="ProgramId"
                                LabelText="Program"
                                Method="GET"
                                Url="/bankapi/v1.0/Bank/ValidBankProgramsNotification"
                                DataRoot="Item"
                                KeyValueMember="Id"
                                Value={defaultModel.ProgramId}
                                Disabled
                                TextValueMember="Description"
                                Sorted={{ Member: "Description" }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="space-between" xs={12} spacing={16}>
                        <GridItem xs={5}>
                            <ParameterComponent
                                Name="EntryType"
                                LabelText="Entry Type"
                                ParameterCode="EFTEntryType"
                                Required
                                Disabled
                                Value={defaultModel.EntryType}
                            />
                        </GridItem>
                        <GridItem xs={5}>
                            <GenericDateInput
                                Utc
                                Name="MoneyTransferDate"
                                LabelText="Money Transfer Date"
                                Value={DateHelper.GetDate()}
                                MaxDate={DateHelper.GetDateTime()}
                                ValueChanged={this.handleChangeDialog}
                                IncludeTime={false}
                                Disabled
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="space-between" xs={12} spacing={16}>
                        <GridItem xs={5}>
                            <GenericSelectInput
                                Name="UniqueClientId"
                                LabelMd={4}
                                LabelText="Client"
                                Method="POST"
                                Url="/bankapi/v1.0/BankCustomer/Search"
                                Parameter={{}}
                                DataRoot="Item"
                                KeyValueMember="Id"
                                RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                                CanClear
                                ValueChanged={this.handleChangeDialog}
                                Value={dialogModel.UniqueClientId}
                            />
                        </GridItem>
                        <GridItem xs={5}>
                            <GenericNumberInput
                                Name="TransactionAmount"
                                LabelMd={4}
                                LabelText="Transaction Amount"
                                MaxLength={10}
                                Prefix="$"
                                ValueChanged={this.handleChangeDialog}
                                Value={dialogModel.TransactionAmount}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="space-between" xs={12}>
                        <GridItem xs={5}>
                            <GenericSelectInput
                                Name="TransactionTypeId"
                                LabelText="Transaction Type"
                                DataRoot="Item"
                                KeyValueMember="Id"
                                TextValueMember="ParameterDesc"
                                Method="GET"
                                Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
                                ValueChanged={this.handleChangeDialog}
                                Value={dialogModel.TransactionTypeId}
                            />
                        </GridItem>
                        <GridItem xs={5}>
                            <GenericSelectInput
                                Name="TransferTypeId"
                                LabelText="Transfer Type"
                                isStatic={true}
                                KeyValueMember="Id"
                                TextValueMember="Text"
                                Required
                                StaticData={[
                                    {
                                        Id: 1,
                                        Text: "Offline",
                                    },
                                ]}
                                Disabled
                                Value={defaultModel.TransferTypeId}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={5}>
                            <GenericTextInput
                                Required
                                Name="Description"
                                LabelText="Description"
                                MultiLine
                                RowCount={4}
                                ValueChanged={this.handleChangeDialog}
                                Value={dialogModel.Description}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <GenericDialogActions>
                    <Button size="sm" onClick={this.approvalAlert}>
                        Save
                    </Button>
                    <Button size="sm" onClick={this.closeCreateDialog}>
                        Cancel
                    </Button>
                </GenericDialogActions>
            </GenericDialog>
        );
    };

    approvalAlertOnCancel = () => this.setState({ approvalAlert: "" });

    approvalAlert = () => {
        const {
            dialogModel: { TransactionTypeId },
        } = this.state;
        if (!this.onDialogValidate()) return;

        if (TransactionTypeId === 6020) {
            this.setState({
                approvalAlert: AlertHelper.CreateQuestionAlert(
                    "CREATE OFFLINE E-TRANSFER",
                    "The funds are going to be withdrawn from the selected wallet",
                    "warning",
                    this.onSubmit,
                    this.approvalAlertOnCancel
                ),
            });
        }

        if (TransactionTypeId === 6021) {
            this.setState({
                approvalAlert: AlertHelper.CreateQuestionAlert(
                    "CREATE OFFLINE E-TRANSFER",
                    "The funds are going to be deposited into the selected wallet",
                    "warning",
                    this.onSubmit,
                    this.approvalAlertOnCancel
                ),
            });
        }
    };

    render() {
        const { isLoading, model, gridRefreshToken, loadAtStartup, approvalAlert } =
            this.state;
        const { Disabled } = this.props;

        return (
            <div>
                {this.createDialog()}
                {approvalAlert}
                <LoadingComponent Show={isLoading} />
                <ButtonToolbar
                    ButtonList={[
                        {
                            Code: "Create",
                            Disabled: Disabled,
                            OnClick: this.onCreate,
                        },
                        {
                            Code: "Search",
                            Disabled: Disabled,
                            OnClick: this.refreshGridToken,
                            ValidationFunction: this.onValidate,
                        },
                        {
                            Code: "Clear",
                            OnClick: this.clear,
                            Disabled: Disabled,
                        },
                    ]}
                    menuId={this.props.menuId}
                    ApprovalData={this.props.ApprovalData}
                    After={this.props.After}
                />
                <Card className="no-radius">
                    <CardHeader>
                        <GenericTitle text={"Create Offline E-Transfer"} />
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={4}>
                                <GenericDateInput
                                    Utc
                                    Name="TransactionDateFrom"
                                    LabelText="Date From"
                                    Value={
                                        model.TransactionDateFrom
                                            ? DateHelper.ToDateInputValue(model.TransactionDateFrom)
                                            : ""
                                    }
                                    ValueChanged={this.handleChange}
                                    IsFuture={false}
                                    IncludeTime={false}
                                />
                            </GridItem>
                            <GridItem xs={4}>
                                <GenericDateInput
                                    Utc
                                    Name="MoneyTransferDate"
                                    LabelText="Money Transfer Date"
                                    Value={
                                        model.MoneyTransferDate
                                            ? DateHelper.ToMoment(model.MoneyTransferDate)
                                            : ""
                                    }
                                    ValueChanged={this.handleChange}
                                    IsFuture={false}
                                    IncludeTime={false}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={4}>
                                <GenericDateInput
                                    Utc
                                    Name="TransactionDateTo"
                                    LabelText="Date To"
                                    Value={
                                        model.TransactionDateTo
                                            ? DateHelper.ToDateInputValue(model.TransactionDateTo)
                                            : ""
                                    }
                                    MinDate={model.TransactionDateFrom}
                                    ValueChanged={this.handleChange}
                                    IsFuture={false}
                                    IncludeTime={false}
                                />
                            </GridItem>
                            <GridItem xs={4}>
                                <GenericTextInput
                                    Name="TransactionReferenceNumber"
                                    LabelText="Transaction Ref. Number"
                                    Value={model.TransactionReferenceNumber}
                                    ValueChanged={this.handleChange}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={4}>
                                <GenericSelectInput
                                    Name="UniqueClientId"
                                    LabelMd={4}
                                    LabelText="Client"
                                    Method="POST"
                                    Url="/bankapi/v1.0/BankCustomer/Search"
                                    Parameter={{}}
                                    DataRoot="Item"
                                    KeyValueMember="Id"
                                    RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                                    CanClear
                                    ValueChanged={this.handleChange}
                                    Value={model.UniqueClientId}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card className="no-radius">
                    <CardHeader>
                        <GenericTitle text="Transaction List" />
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={4} />
                                    <GridItem xs={4} />
                                    <GridItem xs={4} />
                                </GridContainer>
                                <GridContainer spacing={16}>
                                    <GridItem xs={12}>
                                        <GenericGrid
                                            key={"trxGrid" + gridRefreshToken}
                                            ref={this.settlementErrorsGridRef}
                                            ServerSide
                                            RequestUrl="/bankapi/v1.0/BankInteracETransfer/GetOfflineEtransferForReconciliationRecords"
                                            RequestMethod="POST"
                                            PrepareRequest={() => model}
                                            ValidateRequest={this.onValidate}
                                            ShowPagination
                                            LoadAtStartup={loadAtStartup}
                                            PageSize={20}
                                            Columns={this.initColumns()}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default OfflineEtransferForReconciliation;
