import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import StringHelper from '../../core/StringHelper';

class SecurityQuestion extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			ProgramId: null,
			SecurityQuestion: "",
			SecurityQuestionAnswer: ""
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};


		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };

		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName",
				show: !ClientHelper.IsClient()
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Security Question",
				accessor: "SecurityQuestion"
			},
			{
				Header: "Security Question Answer",
				accessor: "SecurityQuestionAnswer"
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Security Question");
		this.props.setAppCenterTitle("NOTIFICATIONS");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		if (name == "SecurityQuestionAnswer") {
			model[name] = newValue.replace(/\s/g, "");
		}
		else
			model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = (keepUniqueClientId) => {
		var emptyModel = { ...this.initialModel };
		if (keepUniqueClientId === true) {
			emptyModel.UniqueClientId = this.state.model?.UniqueClientId;
		}
		this.setState({ model: emptyModel, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		if (!this.Validate(true)) return;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerSecurityQuestion/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ list: responseData.Item || [] });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = (workflowId, after) => {
		if (!this.Validate()) {
			return;
		}

		var model = { ...this.state.model };

		model.SecurityQuestion = (model.SecurityQuestion || "").trim();
		model.SecurityQuestionAnswer = (model.SecurityQuestionAnswer || "").trim();
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/CustomerSecurityQuestion/InsertOrUpdate",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				if (after) after();
				this.HandleClear(true);
				this.HandleSearch();
				this.ShowMessage("success", "Updated successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}

	HandleDelete = () => {
		this.ShowConfirmMessage("warning", "Delete", "Are you sure to delete this record?", () => {
			this.setState({ isLoading: true, alert: "" });
			Proxy.POST("/bankapi/v1.0/CustomerSecurityQuestion/Delete",
				{ Id: this.state.model.Id },
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", responseData.ErrorDescription);
						return;
					}
					this.HandleClear(true);
					this.HandleSearch();
					this.ShowMessage("success", "Deleted successfully!");
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			);
		});
	}

	HandleSubmit = (workflowId, after) => {
		if (!this.Validate()) {
			return;
		}
		var model = { ...this.state.model };

		model.SecurityQuestion = (model.SecurityQuestion || "").trim();
		model.SecurityQuestionAnswer = (model.SecurityQuestionAnswer || "").trim();

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/CustomerSecurityQuestion/InsertOrUpdate",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (after) after();
				this.HandleClear(true);
				this.HandleSearch();
				this.ShowMessage("success", "Added successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}

	onlyLetterAndDigit = (str) => {
		return StringHelper.VerifiyDigitAndLetter(str);
	}

	Validate = (forSearch) => {
		const { model } = this.state;

		var validKeys = ["http:", "https:", "www.", "javascript", "function", "return"];
		var Messages = [];


		if (!model.UniqueClientId) {
			Messages.push("Client cannot be empty.");
			model.vUniqueClientId = true;
		} else {
			model.vUniqueClientId = false;
		}

		if (!forSearch) {
			if (!model.ProgramId) {
				Messages.push("Program cannot be empty.");
				model.vProgramId = true;
			}

			if (!model.SecurityQuestion) {
				Messages.push("Security Question cannot be empty");
				model.vSecurityQuestion = true;
			}
			if (model.SecurityQuestion.length > 40) {
				Messages.push("Security Question cannot be bigger then 40 character");
				model.vSecurityQuestion = true;
			}
			if (model.SecurityQuestion)
				validKeys.map(x => {
					if (model.SecurityQuestion.toLowerCase().includes(x) && !Messages.includes("Security Question contains invalid character.")) {
						Messages.push("Security Question contains invalid character.");
					}
				});

			if (model.SecurityQuestionAnswer && model.SecurityQuestionAnswer.length > 25) {
				Messages.push("Security Question Answer cannot be bigger then 25 character");
			}

			if (model.SecurityQuestionAnswer) {
				if (model.SecurityQuestionAnswer.length < 3) {
					Messages.push("Security Question Answer cannot be smaller then 3 character");
				}
			}

			if (model.SecurityQuestionAnswer) {
				var check = this.onlyLetterAndDigit(model.SecurityQuestionAnswer);
				if (check == true)
					Messages.push("Security Question Answer contains invalid character.");
				else
					validKeys.map(x => {
						if (model.SecurityQuestionAnswer.toLowerCase().includes(x) && Messages.includes("Security Question Answer contains invalid character.")) {
							Messages.push("Security Question Answer contains invalid character.");
						}
					});
			}
		}
		if (Messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", Messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = { ...this.state.list[index] };
		tempModel.SecurityQuestionAnswer = null;
		this.setState({ isEdit: true, model: tempModel, index });
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id || Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: !model.Id || Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: !model.Id || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters" >
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={model.vUniqueClientId} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="ProgramId"
												LabelText="Program"
												Method="GET"
												Url="/bankapi/v1.0/Bank/ValidBankProgramsForSecurityQuestion"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.ProgramId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={model.vProgramId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="SecurityQuestion"
												LabelText="Security Question"
												Value={model.SecurityQuestion}
												ValueChanged={this.HandleChange}
												inputProps={this.maxLength40}
												Required
												IsInvalid={model.vSecurityQuestion}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="SecurityQuestionAnswer"
												LabelText="Security Question Answer"
												Value={model.SecurityQuestionAnswer}
												ValueChanged={this.HandleChange}
												inputProps={this.maxLength25}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Product List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

SecurityQuestion.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(SecurityQuestion, {});
