
const genericCardMediaStyle = {
	container: {
		display:"flex"
	},
	rotateButton:{
		marginLeft:"auto",
		marginTop:"auto",
		color: "black"
	}
};

export default genericCardMediaStyle;