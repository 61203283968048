import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import NotificationDefinitionAddOrUpdate from "./NotificationDefinitionAddOrUpdate";
import NotificationDefinitionDataTable from "./NotificationDefinitionDataTable";

class NotificationDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = { Id: 0 };

		this.state = {
			list: [],
			model: this.initialModel,
			countModel: {},
			selected: null,
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Notification Definition");
		this.props.setAppCenterTitle("Notifications");
		this.HandleGetList();
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "NotificationTemplateId")
			model.NotificationTemplate = data;
		this.setState({ model });
	}

	HandleBooleanChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		this.setState({ model });
	}

	HandleToggle = (value) => {
		const { checked } = this.state;
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		this.setState({ checked: newChecked });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: null });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
	}

	HandleGetList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/EventNotification/Search",
			{
				Item: this.state.model
			},
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/EventNotification/InsertOrUpdate",
			{
				Item: this.state.model
			},
			this.SuccessSubmitCallback,
			this.ErrorSubmitCallback
		);
	}

	SuccessSubmitCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.ShowMessage("success", "Operation is successfully!");
		this.HandleGetList();
	}

	ErrorSubmitCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/EventNotification/Delete/Id?Id=" + this.state.model.Id,
			this.SuccessDeleteCallback,
			this.ErrorDeleteCallback
		);
	}

	SuccessDeleteCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.ShowMessage("success", "Deleted successfully!");
	}

	ErrorDeleteCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while deleting data", error.message);
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.ProgramTypeId) {
			this.ShowMessage("warning", "Program Type not selected", "Select Program Type to continue.");
			return false;
		}

		if (!model.NotificationTemplateTypeId) {
			this.ShowMessage("warning", "Notification Template Type not selected", "Select Notification Template Type to continue.");
			return false;
		}

		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model } = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled  },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id == 0 }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<NotificationDefinitionAddOrUpdate
									model={this.state.model}
									handleChange={this.HandleChange}
									handleBooleanChange={this.HandleBooleanChange}
									handleToggle={this.HandleToggle} />
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<NotificationDefinitionDataTable
									list={this.state.list}
									selected={this.state.selected}
									handleSelectModel={this.HandleSelectModel} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

NotificationDefinition.propTypes = {
	classes: PropTypes.object
};

export default NotificationDefinition;
