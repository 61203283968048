import createReportStyle from "assets/jss/material-dashboard-pro-react/views/createReportStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import Formatter from "core/Formatter";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import DualListBox from "react-dual-listbox";
import ReactTable from "react-table";
import { GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class MatchingCriteriaDefinition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			getDataList: [],
			summaryModel: {},
			isLoading: false,
			columnListBoxSelected: [],
			columnListBoxOption: [
				{ value: "luna", label: "Moon" },
				{ value: "phobos", label: "Phobos" },
				{ value: "deimos", label: "Deimos" },
				{ value: "io", label: "Io" }
			]
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Matching Criteria Definition");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}
	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	GetData() {
		if (this.state.model.ReceivedDate == null)
			this.showErrorMessage("Received Date can not be empty !!");
		else {
			this.setState({ isLoading: true });
			// "/bankapi/v1.0/ClearingSettlement/GetAllDailySettlementItemsByReceivedDate",
			Proxy.POST(
				"/bankapi/v1.0/BankBmoEFT/SearchEFTClearingDailySettledItems",
				this.state.model,
				this.successSummaryCallback,
				this.errorCallback
			);
		}
	}

	columnListBoxOnChange = (selected) => {
		this.setState({
			columnListBoxSelected: selected
		});
	}
	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null) {
			this.setState({ summaryModel: {}, getDataList: [], isLoading: false });
		} else {
			var summaryModel = responseData.Item.summaryItemResponse;
			this.setState({
				summaryModel,
				getDataList: responseData.Item.DailySettledItems.map(x => {
					return {
						RecordType: x.RecordType == null ? "" : x.RecordType,
						FileType: x.FileType == null ? "" : x.FileType,
						UniqueClientId: x.UniqueClientId == null ? "" : x.UniqueClientId,
						ValueDate: x.ValueDate == null ? "" : x.ValueDate,
						TransactionType: x.TransactionType == null ? "" : x.TransactionType,
						PayeeAccountNo: x.PayeeAccountNumber == null ? "" : x.PayeeAccountNumber,
						CrossReferenceNumber: x.CrossReferenceNumber == null ? "" : x.CrossReferenceNumber,
						PayeeName: x.PayeeDraweeName == null ? "" : x.PayeeDraweeName,
						Amount: x.Amount == null ? "" : x.Amount,
						TransactionId: x.TransactionId == null ? "" : x.TransactionId
					};
				}), isLoading: false
			});
		}
	}
	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}
	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}
	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { model, isLoading, columnListBoxSelected, columnListBoxOption } = this.state;
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={12} sm={6} md={3} >
								<GenericSelectInput
									Name="ProgramType"
									LabelText="Program"
									Value={model === undefined ? "" : model.ProgramType || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Description"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Bank/ValidBankPrograms"
									DataRoot="Item" />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<GridContainer>
							<GridItem xs={6} sm={6} md={6} style={{ textAlign: "center" }}>
								<GenericTitle text={"Transaction Field List"} />
							</GridItem>
							<GridItem xs={6} sm={6} md={6} style={{ textAlign: "center" }}>
								<GenericTitle text={"Selected Matching Criteria"} />
							</GridItem>
						</GridContainer>
					</CardHeader>
					<CardBody>
						<DualListBox
							className={classes.root}
							options={columnListBoxOption}
							selected={columnListBoxSelected}
							onChange={(selected) => this.columnListBoxOnChange(selected)}
						/>

					</CardBody>
				</Card>

				<br />
				<ReactTable
					data={this.state.getDataList}
					filterable={false}
					columns={[
						{
							Header: "Rec. Type",
							accessor: "RecordType"
						},
						{
							Header: "File Type",
							accessor: "FileType"
						},
						{
							Header: "Unique Client Id",
							accessor: "UniqueClientId"
						},
						{
							Header: "Value Date",
							accessor: "ValueDate",
							type: GridColumnType.Date
						},
						{
							Header: "Tran Type",
							accessor: "TransactionType"
						},
						{
							Header: "Payee Wallet No.",
							accessor: "PayeeAccountNo"
						},
						{
							Header: "Cross Reference Number",
							accessor: "CrossReferenceNumber"
						},
						{
							Header: "Payee Name",
							accessor: "PayeeName"
						},
						{
							Header: "Amount",
							accessor: "Amount"
						},
						{
							Header: "Trans. ID",
							accessor: "TransactionId"
						}
					]}
					defaultPageSize={5}
					showPaginationTop={false}
					showPaginationBottom={this.state.getDataList.length > 5 ? true : false}
					minRows={2}
				/>
			</GridContainer>
		);
	}
}

MatchingCriteriaDefinition.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func
};

export default withArtifex(MatchingCriteriaDefinition, createReportStyle);