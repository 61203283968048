import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericDateInput,
  GenericSelectInput,
  GenericAlert,
} from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import { ProgramCodes } from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";
import CustomerComponent from "views/Components/CustomerComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class SecureCardCollection extends React.Component {
  constructor(props) {
    super(props);
    this.emptyObject = {};
    this.sortedName = { Member: "Name" };

    this.emptyObject = {};
    this.sortedName = { Member: "Name" };
    this.initialModel = {
      Id: 0,
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      Customer: null,
      DefaultAccount: null,
      ToDate: null,
      FromDate: null,
      CardTokenNumber: null,
      CustomerNumber: null,
      CustomerId: 0,
      searchIsNotSpecific: true,
      PaymentStatusId: null,
    };

    this.columns = [
      {
        Header: "Process Date",
        accessor: "ProcessDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Customer Number",
        accessor: "CustomerNumber",
      },
      {
        Header: "Is Active",
        accessor: "IsActive",
        type: GridColumnType.YesNo,
      },
      {
        Header: "Transaction Amount",
        accessor: "TransactionAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Collected Amount",
        accessor: "CollectedAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Total Collected Amount",
        accessor: "TotalCollectedAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Payment Status",
        accessor: "PaymentStatus",
      },
      {
        Header: "Scheduled Process Date",
        accessor: "ScheduledProcessDate",
        type: GridColumnType.DateTime,
      },
      {
        Header: "Reference Number",
        accessor: "ReferenceNumber",
      },
      {
        Header: "Insert Date Time",
        accessor: "InsertDateTime",
        type: GridColumnType.DateTime,
      },
      {
        Header: "Insert User",
        accessor: "InsertUser",
      },
      {
        Header: "Update Date Time",
        accessor: "UpdateDateTime",
        type: GridColumnType.DateTime,
      },
      {
        Header: "Update User",
        accessor: "UpdateUser",
      },
      {
        Header: "Minimum Payment",
        accessor: "MinimumPayment",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
    ];

    this.LMSPaymentStatus = {
      ParameterCode: "LMSPaymentStatus",
    };
    this.LMSDailyTransactionType = {
      ParameterCode: "LMSDailyTransactionType",
    };

    this.state = {
      model: this.initialModel,
      isLoading: false,
      checkedAccounts: [],
      ClientTypeParameter: {},
      panelTransactionIsActive: false,
      list: [],
      isBackOffice: ClientHelper.IsBackOffice(),
    };
  }

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Card Transaction Payment Status");
    }
  }

  ValueChanged = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;

    this.setState({ model });
  };

  Search = async () => {
    if (!this.Validate()) return;
    const { ExecuteApiPost } = this.props;
    const model = { ...this.state.model };
    const request = {
      FromDate: model.FromDate,
      ToDate: model.ToDate,
      CustomerNumber:
        model.Customer == null ? null : model.Customer.CustomerNumber,
      UniqueClientId: model.UniqueClientId,
      PaymentStatusId: model.PaymentStatusId,
    };
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/Statement/SecureCardCollection",
      request,
      null,
      null,
      null
    );

    if (result != null) {
      this.setState({ list: result || [] });
    }
  };

  Clear = () => {
    this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
  };
  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };
  Validate = () => {
    var { model } = this.state;
    const { showValidationErrors } = this.props;
    var searchIsNotSpecific = model.searchIsNotSpecific;
    this.setState({ model });
    if (!model.PaymentStatusId && !model.CustomerNumber) {
      if (searchIsNotSpecific) {
        if (!model.FromDate) {
          this.props.showMessage(
            "warning",
            "Please fill required fields!",
            "From Date cannot be empty"
          );
          return false;
        }
        if (!model.ToDate) {
          this.props.showMessage(
            "warning",
            "Please fill required fields!",
            "To Date cannot be empty"
          );
          return false;
        }
        var diffMonths = model.ToDate.diff(model.FromDate, "days");
        if (diffMonths > 30) {
          this.props.showMessage(
            "warning",
            "Please fill required fields!",
            "Difference between From Date and To Date cannot be longer than 30 days"
          );
          return false;
        }
      }
    }
    return true;
  };

  GetRenderItemUniqueClient = (x) => {
    this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
    return this.renderItemUniqueClient;
  };

  handleChange = (name, newValue, data) => {
    const model = {
      ...this.state.model,
    };
    model[name] = newValue;
    this.setState({ model });
  };

  handleChangeForCustomer = (name, newValue) => {
    const model = { ...this.state.model };
    const initialModel = { ...this.initialModel };
    if (name === "Customer") {
      if (newValue != null) {
        model.Customer = newValue;
        if (newValue.Accounts != null && newValue.Accounts.length > 0) {
          model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
        } else {
          model.CustomerNumber = null;
        }
      } else {
        model.Customer = initialModel.Customer;
      }
    }
    this.setState({ model });
  };

  HandleClear = () => {
    this.setState({
      model: this.initialModel,
      filterModel: {},
      selected: null,
      list: [],
    });
  };

  FillCustomer = (customer) => {
    this.handleChangeForCustomer("Customer", customer);
  };

  render() {
    const { model, list, isBackOffice } = this.state;
    const { Disabled } = this.props;

    return (
      <div>
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.Search,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.HandleClear,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={this.state.isLoading} />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Filter">
                  <GridContainer>
                    <GridItem xs={2}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                        Disabled={!isBackOffice}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <ParameterComponent
                        LabelText="Payment Status"
                        Name="PaymentStatusId"
                        LabelMd={4}
                        ParameterCode={this.LMSPaymentStatus.ParameterCode}
                        Value={model.PaymentStatusId}
                        ValueChanged={this.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomerComponent
                        key={model.UniqueClientId}
                        CustomerNumber={
                          model.Customer === null
                            ? null
                            : model.Customer.CustomerNumber
                        }
                        HandleChange={this.handleChange}
                        FillCallback={this.FillCustomer}
                        Programs={[ProgramCodes.Prepaid]}
                        UniqueClientId={model.UniqueClientId}
                        Disabled={!model.UniqueClientId}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <GenericDateInput
                        Name="FromDate"
                        LabelText="From Date"
                        Value={
                          model.FromDate
                            ? DateHelper.ToDateInputValue(model.FromDate)
                            : ""
                        }
                        ValueChanged={this.ValueChanged}
                        MaxDate={model.ToDate || DateHelper.GetDateTimeLocal()}
                        Disabled={Disabled}
                        IncludeTime={false}
                        Utc
                      />
                    </GridItem>

                    <GridItem xs={2}>
                      <GenericDateInput
                        Name="ToDate"
                        LabelText="To Date"
                        Value={
                          model.ToDate
                            ? DateHelper.ToDateInputValue(model.ToDate)
                            : ""
                        }
                        ValueChanged={this.ValueChanged}
                        MinDate={model.FromDate}
                        MaxDate={DateHelper.GetDateLocal()}
                        IncludeTime={false}
                        Utc
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel IsActive Title="Card Transaction Payment Status">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer spacing={16}>
                        <GridItem xs={12}>
                          <GenericGrid
                            Data={list}
                            Columns={this.columns}
                            ShowPagination={true}
                            PageSize={100}
                            IsSorted={false}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SecureCardCollection.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default SecureCardCollection;
