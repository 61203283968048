import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericCheckInput, GenericMultipleSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, GridColumnType } from "views/Constants/Constant.js";
import ParameterComponent from "views/Components/ParameterComponent";

class ClientWebhookProfileDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null
		};

		this.columns = [
			{
				Header: "Is Active",
				accessor: "IsActive",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Webhook Type",
				accessor: "WebHookType"
			},
			{
				Header: "Webhook Url",
				accessor: "WebHookUrl"
			},
			{
				Header: "Webhook Api Path",
				accessor: "WebHookApiPath"
			},
			{
				Header: "Api Key",
				accessor: "EncryptedApiKey"
			},
			{
				Header: "Api Key Name",
				accessor: "ApiKeyName"
			},
			{
				Header: "Is Redis Active",
				accessor: "IsRedisActive",
				Cell: row => (
					<div>
						{row.original.IsRedisActive ? "Yes" : "No"}
					</div>
				)
			},
			{
				Header: "Insert DateTime",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Update DateTime",
				accessor: "UpdateDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			}
		];

		this.maxLength1 = { maxLength: 1 };

		this.state = {
			list: [],
			model: this.initialModel,
			isLoading: false,
			vModel: {},
			IsInsertOp: false,
			GroupTypeList: []
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Generate Client Webhook Profile");
		this.props.setAppCenterTitle("SYSTEM ADMIN");
	}

	parameterSearch = async (groupTypeCode, groupDetailTypeCode) => {
		const { ExecuteApiPost } = this.props;
		this.handleChange("GroupDetailTypeId",{},{});
		this.setState({GroupTypes: []});
		var responseData = await ExecuteApiPost("/coreapi/v1.0/Parameter/GetWebHookGroupParameterList",
		 { GroupTypeCode: groupTypeCode, GroupDetailTypeCode: groupDetailTypeCode });

		this.setState({ GroupTypes: responseData, GroupTypeList: [] });
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		
		model[name] = newValue;
		if(name === "WebHookTypeId"){
			if(data.ParameterValue2 === "Y"){
				model["IsGroupTypeActive"] = true;
				this.parameterSearch(data.ParameterValue3, data.ParameterValue4);
			}
			else{
				model["IsGroupTypeActive"] = false;
			}
		}
		if(name==="GroupDetailTypeId"){
			let groupList = [];
			if(data.action == "select-option"){
				this.state.GroupTypeList.push(data.option.value);
				groupList = this.state.GroupTypeList;
			}
			else if(data.action == "set-value"){
				groupList = newValue.map((x) => x.value);
			}
			else{
				if(data.length > 0){
				  groupList = this.state.GroupTypeList.filter(item => item !== data.option.value);
				}
				else{
					groupList = [];
				}
			}
			this.setState({ GroupTypeList: groupList });
			model[name] = groupList;
		}
		this.setState({ model });
	}

	handleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {}, index: -1, IsInsertOp: false });
	};

	IsValid = (operationType) => {
		const { model, vModel } = this.state;
		var errorList = [];
		const { showValidationErrors } = this.props;

		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == "";
		if (vModel.UniqueClientId) {
			errorList.push("Client cannot be empty.");
		}

		vModel.WebHookTypeId = model.WebHookTypeId == null || model.WebHookTypeId == "";
		if (vModel.WebHookTypeId) {
			errorList.push("Webhook Type cannot be empty.");
		}

		vModel.WebHookUrl = model.WebHookUrl == null || model.WebHookUrl == "";
		if (!model.WebHookUrl) {
			errorList.push("Webhook Url cannot be empty.");
		}

		vModel.WebHookApiPath = model.WebHookApiPath == null || model.WebHookApiPath == "";
		if (vModel.WebHookApiPath) {
			errorList.push("Webhook Api Path cannot be empty.");
		}

		vModel.EncryptedApiKey = (model.EncryptedApiKey == null || model.EncryptedApiKey == "") && operationType != "Insert";
		if (vModel.EncryptedApiKey) {
			errorList.push("Api Key cannot be empty.");
		}

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	HandleSearch = () => {

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bank/GetAllClientWebHookProfile",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({
						model,
						list: responseData.Item,
					});
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	HandleInsertOrUpdate = (operationType) => {

		if (!this.IsValid(operationType))
			return;

		const model = { ...this.state.model };
		model.OperationType = operationType;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bank/InsertOrUpdateClientWebHookProfile",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Succeeded", "Operation is successfully!");
				this.setState({
					model: { ...this.initialModel },
					isLoading: false,
					index: -1
				});
				this.handleClear();
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];

		if(model.GroupTypeCode != null && model.GroupTypeDetailCode != null){
			this.parameterSearch(model.GroupTypeCode,model.GroupTypeDetailCode);
		}
		
		this.setState({ model, index, IsInsertOp: true});
	}

	render() {
		const { Disabled, showConfirmMessageInDelete, ExecuteApiPost } = this.props;
		const { alert, model, list, isLoading, index, vModel, IsInsertOp } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled || model.Id > 0,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || model.Id > 0,
							OnClick: () => this.HandleInsertOrUpdate("Insert")
						},
						{
							Code: "Update",
							Disabled: Disabled || model.Id === 0,
							OnClick: () => this.HandleInsertOrUpdate("Update")
						},
						{
							Code: "Delete",
							Disabled: Disabled || model.Id === 0,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/Bank/InsertOrUpdateClientWebHookProfile", { Id: model.Id, OperationType: "Delete" }, null, null, null, [this.handleClear, this.HandleSearch], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.handleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{
													UniqueClientId: ClientHelper.IsClient()
														? ClientHelper.GetClientId()
														: undefined
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												IsInvalid={vModel.UniqueClientId}
												Required />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Required
												IsInvalid={vModel.WebHookTypeId}
												Name="WebHookTypeId"
												LabelText="Webhook Type"
												ParameterCode="WebHookType"
												Value={model.WebHookTypeId}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												LabelText="Webhook Url"
												Name="WebHookUrl"
												Value={model.WebHookUrl}
												ValueChanged={this.handleChange}
												IsInvalid={vModel.WebHookUrl}
												Required />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												LabelText="Webhook Api Path"
												Name="WebHookApiPath"
												Value={model.WebHookApiPath}
												ValueChanged={this.handleChange}
												IsInvalid={vModel.WebHookApiPath}
												Required />
										</GridItem>
										{IsInsertOp &&
											<GridItem xs={4}>
												<GenericTextInput
													LabelText="Api Key"
													Name="EncryptedApiKey"
													Value={model.EncryptedApiKey}
													ValueChanged={this.handleChange}
													IsInvalid={vModel.EncryptedApiKey}
													Required />
											</GridItem>
										}
										<GridItem xs={4}>
											<GenericTextInput
												LabelText="Api Key Name"
												Name="ApiKeyName"
												Value={model.ApiKeyName}
												ValueChanged={this.handleChange}
												IsInvalid={vModel.ApiKeyName}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												LabelText="Is Active"
												Name="IsActive"
												Value={model.IsActive}
												ValueChanged={this.handleChange} />
										</GridItem>
										{model.IsGroupTypeActive &&
											<GridItem xs={4}>
												<GenericMultipleSelectInput
													key={model.IsGroupTypeActive}
													TitleLabel={"Group Type"}
													Name={"GroupDetailTypeId"}
													LabelText={"Group Type"}
													KeyValueMember="GroupDetailTypeCodeId"
													SelectValue="GroupDetailTypeCodeId"
													Value={model.GroupDetailTypeId}
													RenderItem={d => `${d.ParameterDesc}`}
													HandleChange={this.handleChange}
													StaticData={this.state.GroupTypes}
												/>
											</GridItem>
										}
										<GridItem xs={4}>
											<GenericCheckInput
												LabelText="Is Redis Active"
												Name="IsRedisActive"
												Value={model.IsRedisActive}
												ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ShowPagination={true}
													RowSelected={this.RowSelected}
													SelectedIndex={index}
													PageSize={100}
													ProgramCode={ProgramCodes.Prepaid}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ClientWebhookProfileDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientWebhookProfileDefinition, {});
