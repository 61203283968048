import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { CrudType, FileCode, FileType } from "views/Constants/Constant";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";

class FeeManage extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "Program",
				accessor: "ProgramDescription"
			}
		];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SUPPORT");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Fee Manager");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/FeeManage/Search", model);

		model.IsProgramId = false;

		this.setState({ list: result || [] });
	}

	Submit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { model, isFileChanged } = this.state;
		const { ExecuteApiPost, ExecuteApiFileUpload } = this.props;

		if (isFileChanged) {
			var formData = new FormData();
			formData.append("file", model.File, model.FileName);
			formData.append("fileId", model.TempFileId || 0);
			formData.append("fileCode", FileCode.FeeManage);
			formData.append("isBinaryOrBase64", FileType.Binary);
			formData.append("isInsertOrUpdate", model.Id == null ? CrudType.Insert : CrudType.Update);

			var result = await ExecuteApiFileUpload("/coreapi/v1.0/File/Uploadfile", formData);

			if (result != null) {
				ExecuteApiPost(`/bankapi/v1.0/FeeManage/${model.Id == null ? "Insert" : "Update"}`,
					{ Id: model.Id, ProgramId: model.ProgramId, FileId: result }, null, null, null, [this.Clear, this.Search], true);
			}
		}
		else {
			ExecuteApiPost("/bankapi/v1.0/FeeManage/Update", model, null, null, null, [this.Clear, this.Search], true);
		}
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, isFileChanged: false });
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;
		model.TempFileId = model.FileId || 0;
		model.File = file;
		model.FileName = file.name;
		model.FileSelect = true;

		this.setState({ model, isFileChanged: true });
	}

	Validate() {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ProgramId) {
			errors.push("Program can not be null.");
		}

		if (model.FileName == null
			&& model.File == null) {
			errors.push("Fee manage file can not be null.");
		}

		model["IsProgramId"] = !model.ProgramId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, ExecuteApiFileDownloadWithGenericResponse, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/FeeManage/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Upload",
							Disabled: Disabled,
							OnClick: this.Upload
						},
						{
							Code: "View",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: model.FileId }, model.FileName, model.FileExtension)
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/pdf" />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Fee Manager">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsProgramId}
												Name="ProgramId"
												LabelText="Program"
												Url="/bankapi/v1.0/Bank/ValidBankPrograms"
												Method="GET"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.ProgramId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FeeManage.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileUpload: PropTypes.func,
	ExecuteApiFileDownload: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FeeManage;