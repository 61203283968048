import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { DocumentIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CardComponent from "views/Components/CardComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import DateHelper from "core/DateHelper";

class CardStatementReport extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			Customer: null,
			DefaultAccount: null,
			DefaultCard: null,
			CardId: null,
			ValidFrom: null,
			ValidTo: null,
			StatementStart: DateHelper.GetLocalDateTimeAsUtc(),
			StatementEnd: DateHelper.GetLocalDateAsUtc(),
		};

		this.CardColumns = [
			// {
			// 	Header: "Actions",
			// 	accessor: "Actions",
			// 	Cell: row => (
			// 		<div>
			// 			<GridButton
			// 				tooltip="Edit"
			// 				OnClick={() => {
			// 					props.ExecuteApiFileDownloadWithGenericResponse("/prepaidapi/v1.0/Card/GenerateStatementPdfFile", { CardId: row.original.Id, StatementDate: this.state.model.StatementDate },
			// 						`${row.original.CardNumber}-Statement${Math.floor(Math.random() * Math.floor(999999))}`, "pdf");
			// 				}}
			// 				Icon={DocumentIcon}
			// 			/>
			// 		</div>
			// 	),
			// 	sortable: false,
			// 	filterable: false,
			// 	width: 80
			// },
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				types: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];

		this.columns = [
			{
				Header: "Card Id",
				accessor: "CardId"
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "Merchant",
				accessor: "Merchant"
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode"
			},
			{
				Header: "Original Amount",
				Cell: row => (
					<div>
						{row.original.TransactionAmount + " " + (row.original.TransactionCurrency != null ? row.original.TransactionCurrency.Code : "")}
					</div>
				),
			},
			{
				Header: "Amount",
				Cell: row => (
					<div>
						{row.original.BillingAmount + " " + (row.original.CardholderCurrency != null ? row.original.CardholderCurrency.Code : "")}
					</div>
				),
			},
			{
				Header: "Settlement Amount",
				Cell: row => (
					<div>
						{row.original.SettlementAmount + " " + (row.original.SettlementCurrency != null ? row.original.SettlementCurrency.Code : "")}
					</div>
				),
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.Date
			},
			{
				Header: "Settlement Date",
				accessor: "SettlementDate",
				type: GridColumnType.Date


			},
		];

		this.state = {
			list: [],
			cardlist: [],
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			vModel: {}
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Statement Report");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	};

	LimitDataLoad = data => {
		this.setState({ LimitBalanceData: data, isLoading: false });
	};

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };

		model[name] = newValue;
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				model.ClientCustomerId = newValue.Id;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.DefaultAccount = newValue.Accounts[0];
				}
				const request = { CardLevelCriteria: 1, CustomerId: newValue.Id };
				Proxy.ExecuteGeneral(
					this, "POST",
					"/prepaidapi/v1.0/Card/GetListByCustomerNo",
					request,
					responseData => {
						if (responseData.Item != null && responseData.Item[0] != null) {
							model.DefaultCard = responseData.Item[0];
						} else {
							model.DefaultCard = null;
						}
						this.setState({ model: model });
					}
				);
			} else {
				model.ClientCustomerId = null;
				model.Customer = initialModel.Customer;
				model.DefaultAccount = initialModel.DefaultAccount;
				model.DefaultCard = initialModel.DefaultCard;
			}
		} else if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		}
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};

	addDays = (date, days) => {
		var returnDate = new Date();
		returnDate.setDate(date.getDate() + days);
		return returnDate;
	};
	Validate = () => {
		const { model, vModel } = this.state;
		var errorList = [];

		if (!model.UniqueClientId) {
			errorList.push("Select the Client to continue.");
			vModel.UniqueClientId = true;
		}
		else
			vModel.UniqueClientId = false;

		if (!model.ClientCustomerId) {
			errorList.push("Select the Customer to continue.");
			vModel.ClientCustomerId = true;
		}
		else
			vModel.ClientCustomerId = false;

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ValidateTransactionSearch = () => {
		const { model, vModel } = this.state;
		var errorList = [];

		if (!model.UniqueClientId) {
			errorList.push("Select the Client to continue.");
			vModel.UniqueClientId = true;
		}
		else
			vModel.UniqueClientId = false;

		if (!model.ClientCustomerId) {
			errorList.push("Select the Customer to continue.");
			vModel.ClientCustomerId = true;
		}
		else
			vModel.ClientCustomerId = false;

		vModel.StatementStart = !model.StatementStart;
		if (vModel.StatementStart)
			errorList.push("StatementStart cannot be empty!");

		vModel.StatementEnd = !model.StatementEnd;
		if (vModel.StatementEnd)
			errorList.push("StatementEnd cannot be empty!");
		return true;
	}

	HandleCardTransactionSearch = (model) => {

		if (!this.ValidateTransactionSearch(model))
			return false;

		this.setState({ isLoading: true });
		var request = {
			Criteria: 0,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : model.UniqueClientId,
			CardId: model.CardId,
			StatementStart: model.StatementStart,
			StatementEnd: model.StatementEnd
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionData/SearchStatementCardTransactionSummary",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { model, cardlist } = this.state;
		if (cardlist[index].ClientCustomer) {
			model.ClientCustomerId = cardlist[index].ClientCustomer.Id;
			model.CustomerNumber = cardlist[index].ClientCustomer.CustomerNumber;
			model.Customer = cardlist[index].ClientCustomer;
			model.CardId = cardlist[index].Id;
			model.CardTokenNumber = cardlist[index].CardTokenNumber;
			this.HandleCardTransactionSearch(model);
		}
		this.setState({ isEdit: true, model, index });
	}

	HandleSearch = () => {

		if (!this.Validate())
			return;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetCardSummaryList",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ cardlist: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	FillCard = (card) => {
		this.CardModelChange("Card", card);
	}
	CardModelChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;


		if (name === "Customer") {
			if (value) {
				model.ClientCustomerId = value.Id;
				model.CustomerNumber = value.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (value) {
				model.CardId = value.CardId;
				model.CardTokenNumber = value.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		this.setState({ model: model });
	}
	render() {
		const {  Disabled } = this.props;
		const { alert, model, list, cardlist, isLoading, vModel } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							OnClick: this.HandleSearch,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model })
						},
						{
							Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled
						},
						{
                     Code: "Print",
                     Disabled: Disabled || model.CardTokenNumber == null ,
							OnClick: () =>
								this.props.ExecuteApiFileDownloadWithGenericResponse("/prepaidapi/v1.0/Card/GenerateStatementPdfFile", { CardId:model.CardId, StatementStart: this.state.model.StatementStart,StatementEnd:this.state.model.StatementEnd },
									`${this.state.model.CardTokenNumber.substr(0,6)+("______")+this.state.model.CardTokenNumber.substr(12,15)}-Statement${Math.floor(Math.random() * Math.floor(999999))}`, "pdf"),
						}

					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{
													UniqueClientId: ClientHelper.IsClient()
														? ClientHelper.GetClientId()
														: undefined
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												Required
												IsInvalid={vModel.UniqueClientId}
											/>
										</GridItem>
										<GridItem xs={3}>
											<CustomerComponent
												LabelMd={4}
												CustomerNumber={model.Customer === null ? null : model.Customer.CustomerNumber}
												UniqueClientId={model.UniqueClientId}
												FillCallback={customer => { this.handleChange("Customer", customer); }}
												Programs={[ProgramCodes.Prepaid]}
												Required
												IsInvalid={vModel.ClientCustomerId}
											/>
										</GridItem>
										<GridItem xs={2}>
											<CardComponent
												key={"Card_" + [model.ClientCustomerId]}
												LabelMd={4}
												CardTokenNumber={model.CardTokenNumber}
												HandleChange={this.CardModelChange}
												FillCallback={this.FillCard}
												ClientCustomerId={model.ClientCustomerId}
												UniqueClientId={model.UniqueClientId}
												Disabled={false} />
										</GridItem>
										<GridItem xs={2}>
											<GenericDateInput
												Utc
												LabelMd={3}
												Name="StatementStart"
												LabelText="Start Date"
												Value={model.StatementStart ? DateHelper.ToDateInputValue(model.StatementStart) : ""}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={2}>
											<GenericDateInput
												Utc
												LabelMd={3}
												Name="StatementEnd"
												LabelText="End Date"
												Value={model.StatementEnd ? DateHelper.ToDateInputValue(model.StatementEnd) : ""}
												ValueChanged={this.handleChange}
												MinDate={model.StatementStart}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GridItem>
								<GenericTitle text={"Card List"} />
							</GridItem>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={cardlist}
											Columns={this.CardColumns}
											RowSelected={this.RowSelected}
											SelectedIndex={this.state.rowIndex}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<Card className="no-radius">
					<GridItem>
						<GenericTitle text={"Transaction List"} />
					</GridItem>
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.columns}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

CardStatementReport.propTypes = {
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownload: PropTypes.func,
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardStatementReport, {});
