import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { LOSLoanType } from "views/Constants/Constant";
import { LastPaymentDateAfter } from "views/Constants/Constant.js";

class BorrowerPaymentPlan extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [

			{
				Header: "Installment Date",
				accessor: "InstallmentDateFormatted"
			},
			{
				Header: "Beginning Balance",
				accessor: "BeginningBalanceFormatted"
			},
			{
				Header: "Installment Amount",
				accessor: "InstallmentAmountFormatted"
			},
			{
				Header: "Principal Amount",
				accessor: "PrincipalAmountFormatted"
			},
			{
				Header: "Interest Balance",
				accessor: "InterestBalanceFormatted"
			},
			{
				Header: "Fee Balance",
				accessor: "FeeBalanceFormatted"
			},
			{
				Header: "Ending Balance",
				accessor: "EndingBalanceFormatted"
			},
			{
				Header: "Payment Status",
				accessor: "PaymentStatus.ParameterDesc"
			},
			{
				Header: "Overdue Interest",
				accessor: "LatePaymentAmountFormatted"
			},
			{
				Header: "Overdue Interest Status",
				accessor: "LatePaymentStatus"
			},
			{
				Header: "Remaining Overdue Interest Status",
				accessor: "RemainLatePaymentAmountFormatted"
			},
			{
				Header: "Overdue Interest Day",
				accessor: "LatePaymentDay"
			},
			{
				Header: "Overdue Fixed Fee ",
				accessor: "PenaltyAmountFormatted"
			},
			{
				Header: "Late Fee Status",
				accessor: "PenaltyStatus"
			},
			{
				Header: "Remaining Late Fee",
				accessor: "RemainPenaltyAmountFormatted"
			},
			{
				Header: "Installment Date",
				accessor: "InstallmentDate",
				show: false
			}
		];

		this.emptyObject = {};
		this.parameterApplicantId = {};

		this.sortedInstallmentDate = [{ id: "InstallmentDate", desc: false }];

		this.renderItemApplication = {};

		this.state = {
			model: {
				ApplicantId: -2
			},
			NewApplication: {},
			vModel: {},
			list: [],
			isLoading: false,
			isPaymentDialogOpen: false,
			isAdjustmentDialogOpen: false,
			isAdjustmentAdjustDialogOpen: false
		};
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.renderItemLOCTransaction = {};
		this.NewApplicationValueChanged = this.NewApplicationValueChanged.bind(this);
		this.onBlurevent = this.onBlurevent.bind(this);
		this.DialogAdjustmentAdjustReCalculate = this.DialogAdjustmentAdjustReCalculate.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Personal Loan Payment Status");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}
	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message, callback) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={callback} />
		});
	}
	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name === "ApplicationId") {
				model.Application = data;
			}

			return { model };
		});
	}
	NewApplicationValueChanged = (name, value, data) => {
		if (name == "ApprovedPaymentPeriod") {
			this.PaymentPeriodChange(value);
		}
		this.setState(state => {
			var model = state.NewApplication;
			model[name] = value;
			return { model };
		});
	}
	DialogPaymentCloseCase = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/lmsapi/v1.0/LoanEarlyPayment/MakeEarlyPayment",
			model,
			(responseData) => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ alert: null, isPaymentDialogOpen: false }, () => { this.Search(); });

			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	DialogMakeCollection = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/lmsapi/v1.0/LMSTest/MakeInstallmentCollection",
			{
				Application: {
					Id: model.ApplicationId
				},
				ProcessDate: model.ProcessDate
			},
			(responseData) => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ alert: null, isMakeCollectionDialogOpen: false }, () => { this.Search(); });

			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	// DialogPaymentMoveLegalUp = () => {
	// 	this.setState({ isLoading: true });

	// 	const { model } = this.state;
	// 	const { showMessage } = this.props;

	// 	Proxy.POST(
	// 		"/lmsapi/v1.0/LoanEarlyPayment/MoveLegalFollowUp",
	// 		model,
	// 		(responseData) => {

	// 			this.setState({ isLoading: false });
	// 			if (!responseData.IsSucceeded) {
	// 				this.setState({ isLoading: false });
	// 				showMessage("error", "Error", responseData.ErrorDescription);
	// 				return;
	// 			}
	// 			this.setState({ alert: null, isPaymentDialogOpen: false }, () => { this.Search() });

	// 		},
	// 		(error) => {
	// 			this.setState({ isLoading: false });
	// 			showMessage("error", "An error occurred while requesting data", error.message);
	// 		}
	// 	);
	// }

	DialogPaymentClose = () => {
		this.setState({ isPaymentDialogOpen: false });
	}
	DialogCollectionClose = () => {
		this.setState({ isMakeCollectionDialogOpen: false });
	}
	DialogAdjustmentAdjust = () => {
		this.setState({ isAdjustmentAdjustDialogOpen: true });
	}

	DialogAdjustmentCancel = () => {

		this.setState({ isAdjustmentDialogOpen: false });
	}

	DialogAdjustmentAdjustReCalculate = () => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/LoanAdjustment/MakeAdjustment",
				{
					AdjustedApplicationId: this.state.AdjustmentApplicationDetail.Application.Id,
					FeeTypeId: this.state.NewApplication.FeeTypeId,
					AddFeeAmount: this.state.NewApplication.AddFeeAmount,
					NewApplication: this.state.NewApplication,

				}
				, responseData => {
					console.log("Adjustment ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.setState({
						alert: null,
						isAdjustmentDialogOpen: false,
						isAdjustmentAdjustDialogOpen: false
					}, () => {
						this.Clear();
					});

				}, error => {
					this.ShowMessage("error", "Error", error);
				}));
	}

	DialogAdjustmentAdjustCancel = () => {
		this.setState({ isAdjustmentAdjustDialogOpen: false });
	}

	GetParameterApplicantId = () => {
		const { model } = this.state;
		if (model != null && model.ApplicantId) {
			return { ApplicantId: model.ApplicantId };
		}
		return { ApplicantId: 0 };
	}

	Search = () => {
		if (!this.ValidateSearch()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/lmsapi/v1.0/LoanLedgerCard/GetLoanLedgerCard",
			model.Application && model.Application.LoanType && model.Application.LoanType.ParameterValue === LOSLoanType.LOC ?
				{
					"LOCTransactionId": model.LOCTransactionId,
					"ApplicationId": model.ApplicationId
				} :
				{
					"ApplicationId": model.ApplicationId
				},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var list = responseData.Item.LedgerCardList || [];
				this.setState({ list, isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	Payment = () => {
		if (!this.ValidateSearch()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/lmsapi/v1.0/LoanLedgerCard/GetPaymentCloseCaseByApplicationId",
			{
				"ApplicationId": model.ApplicationId,
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					var response = responseData.Item;

					model.LoanTypeId = response.LoanTypeId;
					model.TotalPayment = response.TotalPayment;
					model.TotalPaymentPeriod = response.TotalPaymentPeriod;
					model.RemainingLatePaymentAmount = response.RemainingLatePaymentAmount;
					model.RemainingPenaltyAmount = response.RemainingPenaltyAmount;
					model.RemainingWaitingInstallmentAmount = response.RemainingWaitingInstallmentAmount;
					model.RemainingPayment = response.RemainingPayment;
					model.RemainingPeriod = response.RemainingPeriod;
					model.EarlyPaymentAmount = response.EarlyPaymentAmount;
					model.EarlyPaymentDiscount = response.EarlyPaymentDiscount;
					model.TotalEarlyPayment = response.TotalEarlyPayment;
				}

				this.setState({ model, isPaymentDialogOpen: true, isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	Adjustment = () => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/LoanAdjustment/GetAdjustmentApplicationDetail", this.state.model.ApplicationId, responseData => {
				console.log("Adjustment ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({
					AdjustmentApplicationDetail: responseData.Item || {},
					isAdjustmentDialogOpen: true
				});
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));

	}
	MakeColletion = () => {
		var model = this.state.model;
		const { showMessage } = this.props;

		if (model == null || model.ApplicationId == null) {
			showMessage("error", "Warning", "Select application first.");
			return;
		}

		this.setState({ isMakeCollectionDialogOpen: true });
	}

	ValidateSearch() {
		const { model, vModel } = this.state;
		const { showMessageNode } = this.props;
		var messages = [];

		if (!model.ApplicantId || model.ApplicantId == -2) {
			vModel.ApplicantId = true;
			messages.push("Applicant cannot be null.");
		}
		else {
			vModel.ApplicantId = false;
		}
		if (!model.ApplicationId) {
			vModel.ApplicationId = true;
			messages.push("Application number cannot be null.");
		}
		else {
			vModel.ApplicationId = false;
		}

		if (model.Application && model.Application.LoanType && model.Application.LoanType.ParameterValue === LOSLoanType.LOC && !model.LOCTransactionId) {

			messages.push("LOC transaction cannot be null.");
		}
		else {
			vModel.Application = false;
		}
		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		return true;
	}

	RenderItemLOCTransaction = (d) => {
		this.renderItemLOCTransaction = `${d.TransactionType.ParameterDesc} - ${d.TransactionAmount}`;
		return this.renderItemLOCTransaction;
	}

	RenderItemApplication = (d) => {
		this.renderItemApplication = `${d.ApplicationNumber} - ${d.LoanType.ParameterDesc}`;
		return this.renderItemApplication;
	}
	PaymentPeriodChange = (value) => {
		let { NewApplication, AdjustmentApplicationDetail } = this.state;
		NewApplication.ApprovedPaymentPeriod = value;
		var request = AdjustmentApplicationDetail.Application;
		request.ApprovedPaymentPeriod = value;
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/LoanApproval/PaymentPeriodChange",
				request,
				responseData => {
					console.log("LoanApproval/PaymentPeriodChange ->> " + responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {

						NewApplication.InterestRate = responseData.Item.InterestRate;

						this.setState({
							NewApplication
						});
					}
				},
				this.ErrorCallback
			));
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}
	onBlurevent(e) {
		this.NewApplicationValueChanged("ApprovedPaymentPeriod", e.target.defaultValue);
	}
	render() {
		const { model, list, NewApplication, isLoading, isPaymentDialogOpen, isAdjustmentDialogOpen, isAdjustmentAdjustDialogOpen, isMakeCollectionDialogOpen, vModel, AdjustmentApplicationDetail } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isPaymentDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel
							Text="PAYMENT"
							Bold />
					</DialogTitle>
					<DialogContent>
						<ParameterComponent
							Disabled
							Name="LoanTypeId"
							LabelText="Loan Type"
							ParameterCode="LOSLoanType"
							Value={model.LoanTypeId}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="TotalPayment"
							LabelText="Total Payment"
							MaxLength={10}
							Prefix="$"
							Value={model.TotalPayment}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="TotalPaymentPeriod"
							LabelText="Total Payment Period"
							NoFormatting={true}
							Value={model.TotalPaymentPeriod}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="Remaining Late Payment Amount"
							LabelText="Remaining Late Payment Amount"
							MaxLength={10}
							Prefix="$"
							Value={model.RemainingLatePaymentAmount}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="RemainingPenaltyAmount"
							LabelText="Remaining Penalty Amount"
							MaxLength={10}
							Prefix="$"
							Value={model.RemainingPenaltyAmount}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="RemainingWaitingInstallmentAmount"
							LabelText="Remain Installment Amount"
							MaxLength={10}
							Prefix="$"
							Value={model.RemainingWaitingInstallmentAmount}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="RemainingPayment"
							LabelText="Remaining Payment"
							MaxLength={10}
							Prefix="$"
							Value={model.RemainingPayment}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="RemainingPeriod"
							LabelText="Remaining Period"
							NoFormatting={true}
							Value={model.RemainingPeriod}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="EarlyPaymentAmount"
							LabelText="Early Payment Amount"
							MaxLength={10}
							Prefix="$"
							Value={model.EarlyPaymentAmount}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="EarlyPaymentDiscount"
							LabelText="Early Payment Discount"
							MaxLength={10}
							Prefix="$"
							Value={model.EarlyPaymentDiscount}
							ValueChanged={this.ValueChanged} />
						<GenericNumberInput
							Disabled
							Name="TotalEarlyPayment"
							LabelText="Total Early Payment"
							MaxLength={10}
							Prefix="$"
							Value={model.TotalEarlyPayment}
							ValueChanged={this.ValueChanged} />
						<br />
						<br />
						<GenericLabel
							Text="Test-Email"
							Bold />
						<br />
						<GenericLabel
							Text="Please check borrower wallet balance. Total amount will be debit to DDT balance. If balance not-sufficent you cannot close case."
							Bold />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogPaymentCloseCase}>CLOSE CASE</Button>
						<Button size="sm" onClick={this.DialogPaymentClose}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isMakeCollectionDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel
							Text="Make Collection"
							Bold />
					</DialogTitle>
					<DialogContent style={{ height: 200 }}>
						<GenericSelectInput
							key={model.ApplicantId}
							Name="ApplicationId"
							LabelText="Application Number"
							Url="/losapi/v1.0/Application/SearchForPaymentAdmin"
							Method="POST"
							Parameter={this.GetParameterApplicantId()}
							DataRoot="Item"
							KeyValueMember="Id"
							RenderItem={this.RenderItemApplication}
							Value={model.ApplicationId}
							ValueChanged={this.ValueChanged}
							Disabled={true} />
						<GenericDateInput
							Name="ProcessDate"
							LabelText="Process Date"
							IncludeTime={false}
							Value={model.ProcessDate ? model.ProcessDate : ""}
							ValueChanged={this.ValueChanged} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogMakeCollection}>Make Collection</Button>
						<Button size="sm" onClick={this.DialogCollectionClose}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isAdjustmentDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel
							Text="Fees from LOS"
							Bold />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={6}>
								<GenericLabel
									Text="Loan"
									Bold />
								<ParameterComponent
									Name="LoanTypeId"
									LabelText="Loan Type"
									ParameterCode="LOSLoanType"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.LoanTypeId : ""}
									Disabled />
								<GenericNumberInput
									Name="RequestedAmount"
									LabelText="Requested Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.RequestedAmount : ""}
									Disabled />
								<GenericNumberInput
									Name="ApprovedAmount"
									LabelText="Approved Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.ApprovedAmount : ""}
									Disabled />
								<GenericNumberInput
									Name="AnnualInterestRate"
									LabelText="Annual Interest Rate"
									MaxLength={10}
									Suffix="%"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.InterestRate : ""}
									Disabled />
								<GenericNumberInput
									Name="ApprovedPaymentPeriod"
									LabelText="Payment Period"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.ApprovedPaymentPeriod : ""}
									Disabled />
								<ParameterComponent
									Name="ApprovedPaymentFrequencyId"
									LabelText="Payment Frequency"
									ParameterCode="LOSPaymentSchedule"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.ApprovedPaymentFrequencyId : ""}
									Disabled />
								<GenericNumberInput
									Name="PaymentAmount"
									LabelText="Payment Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.PaymentAmount : ""}
									Disabled />
								{AdjustmentApplicationDetail == undefined || AdjustmentApplicationDetail.ApplicationApprovedFeeList == null ? "" : AdjustmentApplicationDetail.ApplicationApprovedFeeList.map((setupFee, key) => {
									return (
										<GenericNumberInput
											LabelText={setupFee.LOSSetupFee != null ? setupFee.LOSSetupFee.ParameterDesc : ""}
											Name={setupFee.LOSSetupFee.ParameterDesc}
											Value={setupFee.GivenValue}
											Disabled={true} />
									);
								})}

							</GridItem>
							<GridItem xs={6}>
								<GenericLabel
									Text="Payments"
									Bold />
								<GenericNumberInput
									Name="PrincipalAmount"
									LabelText="Principal Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.ApprovedAmount : ""}
									Disabled />
								<GenericNumberInput
									Name="TotalInterestAmount"
									LabelText="Total Interest Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.TotalInterestAmount : ""}
									Disabled />
								<GenericNumberInput
									Name="TotalExpenses"
									LabelText="Total Expenses"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.TotalApprovedFeeAmount : ""}
									Disabled />
								<GenericNumberInput
									Name="TotalPaybackAmount"
									LabelText="Total Payback Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.TotalPaybackAmount : ""}
									Disabled />
								<ParameterComponent
									Name="SavingsPlan"
									LabelText="Savings Plan"
									ParameterCode="LOSYesNo"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.Application.SavingsPlanId : ""}
									Disabled />

								<GenericNumberInput
									Name="TotalPaybackAmount"
									LabelText="Total Payback Amount"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.TotalPaybackAmount : ""}
									Disabled />
								<GenericNumberInput
									Name="TotalPayment"
									LabelText="Total Payment"
									MaxLength={10}
									Prefix="$"
									Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.TotalPayment : ""}
									Disabled />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogAdjustmentAdjust}>ADJUST</Button>
						<Button size="sm" onClick={this.DialogAdjustmentCancel}>CANCEL</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isAdjustmentAdjustDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel
							Text="ADJUSTMENT"
							Bold />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={8} >
								<GenericNumberInput
									NoFormatting
									key="ApprovedPaymentPeriod"
									Name="ApprovedPaymentPeriod"
									LabelMd={6}
									LabelText="Payment Period"
									Value={NewApplication != undefined ? NewApplication.ApprovedPaymentPeriod : ""}
									Blur={this.onBlurevent} />
							</GridItem>
							<GridItem xs={4}>
								<ParameterComponent
									Name="ApprovedPaymentFrequencyId"
									LabelMd={0}
									ParameterCode="LOSPaymentSchedule"
									Value={NewApplication != undefined ? NewApplication.ApprovedPaymentFrequencyId : ""}
									ValueChanged={this.NewApplicationValueChanged} />
							</GridItem>
						</GridContainer>
						<GenericNumberInput
							Name="AnnualInterestRate"
							LabelText="Annual Interest Rate"
							MaxLength={10}
							Suffix="%"
							Disabled
							Value={NewApplication != undefined ? NewApplication.InterestRate : ""} />
						<GenericNumberInput
							Name="TotalFeeAmount"
							LabelText="Total Fee Amount"
							Disabled
							Value={AdjustmentApplicationDetail != undefined ? AdjustmentApplicationDetail.TotalCurrentFeeAmount : ""} />
						<ParameterComponent
							Name="FeeTypeId"
							LabelText="Fee Type"
							ParameterCode="LOSSetupFee"
							Value={NewApplication != undefined ? NewApplication.FeeTypeId : ""}
							ValueChanged={this.NewApplicationValueChanged} />
						<GenericNumberInput
							Name="AddFeeAmount"
							LabelText="Add Fee Amount"
							MaxLength={10}
							Prefix="$"
							Value={NewApplication != undefined ? NewApplication.AddFeeAmount : ""}
							ValueChanged={this.NewApplicationValueChanged} />
						<GenericDateInput
							Name="FirstPaymentDate"
							IsFuture={true}
							LabelText="First Payment Date"
							Value={NewApplication != undefined && NewApplication.FirstPaymentDate ? DateHelper.ToDateInputValue(NewApplication.FirstPaymentDate) : ""}
							ValueChanged={this.NewApplicationValueChanged}
							IncludeTime={false} />
						<GenericSelectInput
							IsStatic={true}
							StaticData={LastPaymentDateAfter}
							Name="LastPaymentDateAfter"
							Value={NewApplication == undefined ? "" : NewApplication.LastPaymentDateAfter}
							LabelText="Due Term of Statement"
							ValueChanged={this.NewApplicationValueChanged}
							KeyValueMember="Id"
							TextValueMember="Text" />
						<br />
						<br />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogAdjustmentAdjustReCalculate}>RE-CALCULATE</Button>
						<Button size="sm" onClick={this.DialogAdjustmentAdjustCancel}>CANCEL</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridContainer justify="flex-end">
											<GridItem style={{ float: "right", marginRight: 8 }}>
												<Button size="sm" disabled={!model.ApplicationId || model.ApplicationId <= 0} onClick={this.Payment}>
													Early Payment
												</Button>
												<Button size="sm" disabled={!model.ApplicationId || model.ApplicationId <= 0} onClick={this.Adjustment}>
													Adjustment
												</Button>
												<Button size="sm" disabled={!model.ApplicationId || model.ApplicationId <= 0} onClick={this.MakeColletion}>
													Make Collection
												</Button>
											</GridItem>
										</GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="ApplicantId"
												LabelText="Applicant"
												Method="POST"
												Url="/losapi/v1.0/Applicant/GetApplicantListForBorrowerPaymentPlan"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullName"
												Value={model.ApplicantId}
												ValueChanged={this.ValueChanged}
												Required
												IsInvalid={vModel.ApplicantId} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.ApplicantId}
												Name="ApplicationId"
												LabelText="Application"
												Url="/losapi/v1.0/Application/GetApplicationListForBorrowerPaymentPlan"
												Method="POST"
												Parameter={this.GetParameterApplicantId()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemApplication}
												Value={model.ApplicationId}
												ValueChanged={this.ValueChanged}
												Required
												IsInvalid={vModel.ApplicationId} />
										</GridItem>
										{model.Application && model.Application.LoanType && model.Application.LoanType.ParameterValue === LOSLoanType.LOC &&
											<GridItem xs={3}>
												<GenericSelectInput
													key={model.ApplicationId}
													Name="LOCTransactionId"
													LabelText="Transaction"
													Url={"/lmsapi/v1.0/LOCTransaction/GetByApplicationId/Id?Id=" + model.ApplicationId}
													Method="GET"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemLOCTransaction}
													Value={model.LOCTransactionId}
													ValueChanged={this.ValueChanged}
												/>
											</GridItem>
										}
										<GridItem xs={3}>
											<GenericSelectInput
												Name="PartnerId"
												LabelText="Partner"
												Url="/losapi/v1.0/ClientPartner/GetAll"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="LegalNameOfBusiness"
												Value={model.PartnerId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged}
												Utc />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												IncludeTime={false}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged}
												Utc />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Sorted={this.sortedInstallmentDate}
													Columns={this.columns}
													PageSize={10}
													ShowPagination={true} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

BorrowerPaymentPlan.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default BorrowerPaymentPlan;