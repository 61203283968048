/**
 * 
 * @param {Array<object>} array 
 * @param {string} sortingCriteria 
 * 
 */
function SortArray(array, sortingCriteria, isDesc = null) {
	array.sort((a, b) => {
		var nameA = a[sortingCriteria];
		if (nameA && nameA.toUpperCase) {
			nameA = nameA.toUpperCase();
		}

		var nameB = b[sortingCriteria];
		if (nameB && nameB.toUpperCase) {
			nameB = nameB.toUpperCase();
		}

		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		return 0;
	});

	if (isDesc) array.reverse();
}
function IsArraysEqual(array1, array2) {

	if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length)
		return false;

	var arr1 = array1.concat().sort();
	var arr2 = array2.concat().sort();

	for (var i = 0; i < arr1.length; i++) {
		if (
			(Array.isArray(arr1[i]) && Array.isArray(arr2[i]) && !IsArraysEqual(arr1[i], arr2[i]))
			||
			(arr1[i] !== arr2[i])
		)
			return false;
	}
	return true;
}

const ArrayHelper = {
	SortArray,
	IsArraysEqual
};

export default ArrayHelper;