import { withArtifex } from "core";
import React, { Component } from "react";
import { ProgramCodes } from "views/Constants/Constant.js";
import CustomerDefinition from "views/Customer/CustomerDefinition.jsx";

class AddNewCustomerEFT extends Component {
	constructor(props) {
		super(props);

		this.state = {
			LimitToken: false
		};
	}

	componentDidMount() {

	}

	render() {
		return (
			<div>
				<CustomerDefinition
					hideSidebar={this.props.hideSidebar}
					setAppLeftTitle={this.props.setAppLeftTitle}
					setAppCenterTitle={this.props.setAppCenterTitle}
					CustomerSaved={this.props.CustomerSaved}
					ForInsert={this.props.ForInsert}
					Programs={[ProgramCodes.Bank_Programs_EFT]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					Disabled={this.props.Disabled}
					getAppLeftTitle={"EFT SYSTEM"}
	            getAppCenterTitle={"Add New Customer"}
					getMenuCode={"222221"}
					/>
			</div>
		);
	}
}

export default withArtifex(AddNewCustomerEFT, {});