import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ManageInteracCustomIntegrationUrls extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			alert: null,
			isLoading: false,
			list: []
		};

		this.EmptyParameter = {};

		this.ColumnsData = [
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Unique Client ID",
				accessor: "UniqueClient.UniqueClientId"
			},
			{
				Header: "Parent User Name",
				accessor: "ParentUser.UserName"
			},
			{
				Header: "Parent User Email",
				accessor: "ParentUser.UserEmail"
			},
			{
				Header: "API User Name",
				accessor: "ApiUser.UserName"
			},
			{
				Header: "Session Time In Minutes",
				accessor: "SessionTimeInMinutes"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			}
		];
	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("SYSTEM ADMIN");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Generate API Token");

	}

	OperationCheck = () => {
		var ErrorList = [];
		var data = { ...this.state.model };

		if (data.ParentUserId == null || data.ParentUserId == "") {
			ErrorList.push("Parent User must be defined !.");
		}
		if (data.UniqueClientId == null || data.UniqueClientId == "") {
			ErrorList.push("Client must be defined !.");
		}
		if (data.ApiUserId == null || data.ApiUserId == "") {
			ErrorList.push("Api User must be defined !.");
		}
		if (data.SessionTimeInMinutes == null || data.SessionTimeInMinutes == "") {
			ErrorList.push("Session Time In Minutes must be defined !.");
		}

		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.Submit();
	}
	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/ApiUser/GetApiTokens",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ list: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Submit = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/ApiUser/SaveApiToken",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Token Success");
				this.setState({ model: responseData.Item || {} });
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}
	RenderClientNameSelect = (d) => {
		return d.Name + " - " + d.UniqueClientId;
	}
	ParentUserParameter = () => {
		return { UniqueClientId: this.state.model.UniqueClientId };
	}
	ApiUserParameter = () => {
		return { UniqueClientId: this.state.model.UniqueClientId, ParentUserId: this.state.model.ParentUserId };
	}
	RenderUserNameSelect = (d) => {
		return d.FirstName + " " + d.LastName + " (" + d.UserName + ")";
	}
	RenderApiUserNameSelect = (d) => {
		return d.UserName + " (" + d.User.UserName + ")";
	}
	SelectedRowChange = (e, index) => {
		const model = this.state.list[index];
		this.setState({ model: model || {} });
	}
	ClearData = () => {
		this.setState({
			model: {},
			alert: null,
			isLoading: false,
			list: []
		});
	}
	render() {
		const { Disabled } = this.props;
		const { model, list } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Generate Api Token </p></b>
						<GridContainer justify="flex-start">
							<GridItem xs={4}>
								<GenericSelectInput
									Disabled={Disabled}
									Name="UniqueClientId"
									LabelText="Client Name"
									Value={model.UniqueClientId}
									DataRoot="Item"
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Name"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Method="POST"
									Parameter={this.EmptyParameter}
									RenderItem={this.RenderClientNameSelect}
								/>
								<GenericSelectInput
									key={"fe" + model.UniqueClientId}
									Disabled={Disabled}
									Name="ParentUserId"
									LabelText="User Name"
									Value={model.ParentUserId}
									DataRoot="Item"
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Name"
									Url="/coreapi/v1.0/User/GetAllByUniqueClientId"
									Method="POST"
									Parameter={this.ParentUserParameter()}
									RenderItem={this.RenderUserNameSelect}
								/>
								<GenericSelectInput
									key={"fe" + model.UniqueClientId + "de" + model.ParentUserId}
									Disabled={Disabled}
									Name="ApiUserId"
									LabelText="API User Name"
									Value={model.ApiUserId}
									DataRoot="Item"
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Name"
									Url="/coreapi/v1.0/ApiUser/GetApiUser"
									Method="POST"
									Parameter={this.ApiUserParameter()}
									RenderItem={this.RenderApiUserNameSelect}
								/>
								<GenericTextInput
									Disabled={Disabled}
									Name="Description"
									LabelText="Description"
									Value={model.Description || ""}
									ValueChanged={this.handleChange}
								/>
								<GenericNumberInput
									NoFormatting={true}
									Name="SessionTimeInMinutes"
									LabelText="Session Time In Minutes"
									Value={model.SessionTimeInMinutes}
									ValueChanged={this.handleChange}
									MaxLength={10}
								/>
							</GridItem>
							<GridItem xs={8} >
								<GenericTextInput
									LabelMd={2}
									Disabled={true}
									Name="Token"
									LabelText="Token"
									MultiLine={true}
									RowCount={9}
									Value={model.Token || ""}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


				<Card className="no-radius" >
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Generated Tokens </p></b>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={list}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>



			</div>
		);
	}
}

ManageInteracCustomIntegrationUrls.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(ManageInteracCustomIntegrationUrls, {});