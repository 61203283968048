import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, BankingAccountTypes, MenuCodes, SecurityType, GridColumnType } from "views/Constants/Constant.js";

const styles = ({
	...customCheckboxRadioSwitch,
	...customSelectStyle,
	...sweetAlertStyle
});
class Security extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			securityModel: {
				Account: {},
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				UniqueClientId: 0,
				Id: 0
			},
			tempModel: {
				isFixed: false,
				isLetter: false,
				isPrepaid: false
			},
			isLoading: false,
			isSave: true,
			CustomDisabled: null,
			isFixed: false,
			isLetter: false,
			isPrepaid: false,
			vModel: {},
			IsUpdate: true,
			SecurityTransactions: [],
			paramBankCustomerInternalAccountList1: []
		};
	}

	componentDidMount() {
		this.ClearData();
		var securityModel = { ...this.state.securityModel };

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Security");

		const global = { ...this.props.paramGlobalModel };

		if (global.Id != 0) {
			securityModel.UniqueClientId = global.Id;
		}
		this.setState({ securityModel });
		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedSecurity, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
		this.GetCustomerDCBankAccountModels();
	}

	GetCustomerDCBankAccountModels = () => {

		const global = this.props.paramGlobalModel;
		if (global.UniqueClientId != undefined && global.UniqueClientId != "") {
			if (global.Id == null || global.Id == 0) {
				this.showBasicAlert("Error", "UniqueClientId not null", "error");
				return;
			}
			else {
				this.showLoading();
				Proxy.GET(
					"/bankapi/v1.0/Account/GetByCustomerId/Id?Id=" + global.Id,
					responseData => {
						console.log("GetCustomerDCBankAccountModels ->", responseData.IsSucceeded);
						if (!responseData.IsSucceeded) {
							this.showBasicAlert("Error", responseData.ErrorDescription, "error");

							this.hideLoading();

							return;
						}
						if (responseData.Item != null) {
							this.setState({ paramBankCustomerInternalAccountList1: responseData.Item || [] });
							this.hideLoading();

						} else {
							this.setState({ paramBankCustomerInternalAccountList1: [] });
							this.hideLoading();
						}
					},
					error => {

						this.showBasicAlert("Error", error, "error");
						this.hideLoading();

					}
				);
				this.hideLoading();
			}
		}
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var securityModel = state.securityModel;
			var isFixed = state.isFixed;
			var isLetter = state.isLetter;
			var isPrepaid = state.isPrepaid;
			const tempModel = { ...this.state.tempModel };

			if (name == "SecurityTypeId") {
				delete securityModel.Id;
				delete securityModel.SecureAmount;
				delete securityModel.LastLoadingDate;

				tempModel.SecurityTypeParamValue = data != null ? data.ParameterValue : "";
				if (data != null) {
					if (data.ParameterValue == SecurityType.FixedCash) {
						tempModel.isFixed = true;
						tempModel.isLetter = false;
						tempModel.isPrepaid = false;
					}
					else if (data.ParameterValue == SecurityType.LetterofGuarantee) {
						tempModel.isFixed = false;
						tempModel.isLetter = true;
						tempModel.isPrepaid = false;
					}
					else if (data.ParameterValue == SecurityType.Prepaid) {
						tempModel.isFixed = false;
						tempModel.isLetter = false;
						tempModel.isPrepaid = true;
					}
					if (securityModel.AccountId) {
						if (tempModel.isFixed) { isFixed = true; isLetter = false; isPrepaid = false; }
						if (tempModel.isLetter) { isFixed = false; isLetter = true; isPrepaid = false; }
						if (tempModel.isPrepaid) { isFixed = false; isLetter = false; isPrepaid = true; }
					}
				}
				else {
					isFixed = false;
					isLetter = false;
					isPrepaid = false;
				}
			}
			if (name == "AccountId") {
				securityModel.Account = data != null ? data : null;
				securityModel["ProgramId"] = data != null ? data.ProgramId : null;
				securityModel["AvailableLimit"] = data != null ? data.Balance - data.PendingBalance : null;
				securityModel["BankingAccountTypeId"] = data != null ? data.BankingAccountTypeId : null;
				securityModel["LastLoadingDate"] = data != null ? data.LastLoadingDate : null;
				if (securityModel.Id == null) {
					if (tempModel.isFixed) { isFixed = true; isLetter = false; isPrepaid = false; }
					if (tempModel.isLetter) { isFixed = false; isLetter = true; isPrepaid = false; }
					if (tempModel.isPrepaid) { isFixed = false; isLetter = false; isPrepaid = true; }
				}
			}

			if (isLetter && name == "FromDate" && securityModel.FromDate > securityModel.ToDate)
				securityModel.ToDate = securityModel.FromDate;

			securityModel[name] = newValue;
			return { securityModel, tempModel, isFixed, isLetter, isPrepaid };
		});
	}

	handleChangeTempModel = (name, newValue, data) => {
		this.setState(function (state, props) {
			const tempModel = { ...this.state.tempModel };
			const securityModel = { ...this.state.securityModel };
			if (name == "AccountName" && data != null) {
				securityModel["ProgramId"] = data.ProgramId;
				securityModel["AccountId"] = data.Id;
				securityModel["BranchId"] = data.BranchId;
				securityModel["AccountNumber"] = data.AccountNumber;
			}
			tempModel[name] = newValue;
			return { tempModel, securityModel };
		});
	}

	handleAddOrUpdate = () => {
		if (!this.IsValidate())
			return;
		this.SaveOrUpdateInit();

		var securityModel = { ...this.state.securityModel };
		var { isSave } = this.state;
		if (securityModel.Id != null && securityModel.Id > 0)
			isSave = false;

		if (isSave)
			this.setState({
				isSave,
				alert: <GenericAlert Title={"Save"} Message={"Save changes ?"} Type="info" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleAddOrUpdateOK()} />
			});
		else
			this.setState({
				isSave,
				alert: <GenericAlert Title={"Update"} Message={"Update changes ?"} Type="info" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleAddOrUpdateOK()} />
			});
	}

	handleAddOrUpdateOK = () => {
		this.hideAlert();
		this.showLoading();
		var { isSave } = this.state;
		const securityModel = { ...this.state.securityModel };

		Proxy.POST(
			"/bankapi/v1.0/CustomerSecurity/InsertOrUpdate",
			securityModel,
			responseData => {
				this.hideLoading();
				if (!responseData.IsSucceeded) {
					if (isSave)
						this.setState({
							alert: <GenericAlert Title={"Error"} Message={"Save operation has failed : " + responseData.ErrorDescription} Type="error" OnConfirm={() => this.hideAlert()} />
						});
					else
						this.setState({
							alert: <GenericAlert Title={"Error"} Message={"Update operation has failed : " + responseData.ErrorDescription} Type="error" OnConfirm={() => this.hideAlert()} />
						});
					return;
				}
				else {
					if (responseData.IsSucceeded != null) {
						if (isSave) {
							this.showBasicAlert("Save", "Security saved sucessfully", "success");
						}
						else {
							this.showBasicAlert("Update", "Security updated sucessfully", "success");

						}
						this.props.GetCustomerSecurityModels();
						this.ClearData();
					}
					else {
						if (isSave) {
							this.showBasicAlert("Save", "Security save failed", "warning");
						}
						else {
							this.showBasicAlert("Update", "Security update failed", "warning");
						}
					}
				}
			},
			error => {
				if (isSave) {
					this.showBasicAlert("Save", "Security save failed :" + error + "!!!", "error");
				}
				else {
					this.showBasicAlert("Update", "Security update failed :" + error + "!!!", "error");
				}
			}
		);
	}

	handleDelete = () => {
		this.hideAlert();
		this.setState({
			alert: <GenericAlert Title={"Delete"} Message={"Are you sure delete this security ?"} Type="info" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleDeleteOK()} />
		});
	}

	handleDeleteOK = () => {
		var securityModel = { ...this.state.securityModel };

		Proxy.POST("/bankapi/v1.0/CustomerSecurity/Delete",
			securityModel,
			responseData => {
				if (responseData.IsSucceeded) {
					this.ClearData();
					this.props.GetCustomerSecurityModels();
					this.showBasicAlert("Delete", "Security deleted sucessfully", "success");
				}
				else {
					this.showBasicAlert("Error", "An error occurred during the api visit", "error");
				}
			},
			this.ErrorCallback

		);

	}
	ErrorCallback = (error) => {
		this.showBasicAlert("error", "Error", "An error occurred during the api visit" + error);
	}

	SelectedRowChange = (index) => {
		const model = { ...this.props.paramBankCustomerSecurityList[index] };
		var { isFixed, isLetter, isPrepaid, IsUpdate } = { ...this.state };

		var tempModel = { ...this.state };
		tempModel.SecurityTypeParamValue = model.SecurityType.ParameterValue;
		var scrtyDescRecord = this.props.paramBankCustomerSecurityList.filter(t => t.AccountId == model.AccountId && t.SecurityType.Id == model.SecurityTypeId).reverse()[0];

		if (scrtyDescRecord != null && scrtyDescRecord.Id <= model.Id) {
			if (model.SecurityType.ParameterValue == SecurityType.FixedCash) {
				isFixed = true;
				isLetter = false;
				isPrepaid = false;
				IsUpdate = true;
			}
			else if (model.SecurityType.ParameterValue == SecurityType.LetterofGuarantee) {
				isFixed = false;
				isLetter = true;
				isPrepaid = false;
				IsUpdate = true;

			}
			else if (model.SecurityType.ParameterValue == SecurityType.Prepaid) {
				isFixed = false;
				isLetter = false;
				isPrepaid = true;
				IsUpdate = true;
			}

		}
		else {
			isFixed = false;
			isLetter = false;
			isPrepaid = false;
			IsUpdate = false;

		}

		model.FromDate = model.FromDate != null ? DateHelper.ToMoment(model.FromDate) : null;
		model.ToDate = model.ToDate != null ? DateHelper.ToMoment(model.ToDate) : null;

		this.setState({ securityModel: model, tempModel, isFixed, isLetter, isPrepaid, IsUpdate });
	}

	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		const securityModel = { ...this.state.securityModel };
		var model = { Id: 0, UniqueClientId: global.Id, FromDate: DateHelper.GetDate(), ToDate: DateHelper.GetDate(), };
		const tempModel = { ...this.state.tempModel };
		delete tempModel.SecurityTypeId;
		delete tempModel.ProgramId;
		delete tempModel.AccountName;
		delete securityModel.SecureAmount;
		delete securityModel.FromDate;
		delete securityModel.EndDate;
		delete securityModel.LastLoadingDate;
		this.setState({ securityModel: model, tempModel, isSave: true, vModel: {}, IsUpdate: true });
	}

	IsValidate = () => {
		var vModel = this.state.vModel;
		var tempModel = { ...this.state.tempModel };
		var securityModel = { ...this.state.securityModel };
		var messages = [];

		if (securityModel.UniqueClientId == 0) messages.push("Client Id cannot be null !!");
		if (securityModel.SecurityTypeId == null) {
			vModel.SecurityTypeId = true; messages.push("Security Type can not be null !!");
		} else { vModel.SecurityTypeId = false; }

		if (securityModel.AccountId == null) { vModel.AccountId = true; messages.push("Security wallet can not be null !!"); } else { vModel.AccountId = false; }
		if (!securityModel.SecurityTypeId && !securityModel.AccountId) {
			if (tempModel.isFixed && (securityModel.SecureAmount == null || securityModel.SecureAmount == 0.00)) { vModel.SecureAmount = true; messages.push("Secure Amount not be null !!"); } else { vModel.SecureAmount = false; }
			if (tempModel.isLetter) {
				if (securityModel.SecureAmount == null || securityModel.SecureAmount == 0.00) { vModel.SecureAmount = true; messages.push("Secure Amount not be null !!"); } else { vModel.SecureAmount = false; }
				if (securityModel.FromDate == null) { vModel.FromDate = true; messages.push("From date not be null !!"); } else { vModel.FromDate = false; }
				if (securityModel.ToDate == null) { vModel.ToDate = true; messages.push("To date not be null !!"); } else { vModel.ToDate = false; }
			}
			if (tempModel.isPrepaid && (securityModel.SecureAmount == null || securityModel.SecureAmount == 0.00)) { vModel.SecureAmount = true; messages.push("Secure Amount not be null !!"); } else { vModel.SecureAmount = false; }

		}
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;
	}

	SaveOrUpdateInit = () => {
		var { securityModel, tempModel } = this.state;

		if (tempModel.SecurityTypeParamValue == SecurityType.FixedCash || tempModel.SecurityTypeParamValue == SecurityType.Prepaid) {
			delete securityModel.FromDate;
			delete securityModel.ToDate;
			delete securityModel.AvailableLimit;
			delete securityModel.LastLoadingDate;
		}

		if (tempModel.SecurityTypeParamValue == SecurityType.LetterofGuarantee) {
			delete securityModel.AvailableLimit;
			delete securityModel.LastLoadingDate;
		}

	}

	showBasicAlert = (title, message, type) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }
	hideLoading = () => { this.setState({ isLoading: false }); };

	render() {
		const { Disabled: DisabledProp } = this.props;
		const { securityModel, isLoading, vModel, paramBankCustomerInternalAccountList1 } = this.state;
		const { isFixed, isLetter, isPrepaid, IsUpdate } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		var securityAccounts = paramBankCustomerInternalAccountList1.filter(y => y.BankingAccountType && y.BankingAccountType.ParameterValue == BankingAccountTypes.SecurityAccount).map((x) => {
			return {
				Id: x.Id,
				UniqueClientId: x.UniqueClientId,
				AccountName: x.AccountName,
				ProgramId: x.ProgramId,
				BranchId: x.BranchId,
				AccountNumber: x.AccountNumber,
				Balance: x.Balance,
				PendingBalance: x.PendingBalance,
				LastLoadingDate: x.LastLoadingDate,
				BankingAccountTypeId: x.BankingAccountTypeId
			};
		});

		var data = this.props.paramBankCustomerSecurityList == null ? [] : this.props.paramBankCustomerSecurityList.map(d => {
			return {
				Id: d.Id,
				UniqueClientId: d.UniqueClientId,
				AccountId: d.Account == null ? "" : d.Account.Id,
				AccountName: d.Account == null ? "" : d.Account.AccountName == null ? "" : d.Account.AccountName,
				AccountNumber: d.Account == null ? "" : d.Account.AccountNumber == null ? "" : d.Account.AccountNumber,
				TransitNumber: d.Account == null ? "" : d.Account.Branch == null ? "" : d.Account.Branch.TransitNumber == null ? "" : d.Account.Branch.TransitNumber,
				SecurityTypeId: d.SecurityType == null ? "" : d.SecurityType.Id,
				SecurityType: d.SecurityType == null ? "" : d.SecurityType.ParameterDesc,
				Program: d.Program == null ? "" : d.Program.Description,
				ProgramId: d.ProgramId == null ? "" : d.ProgramId,
				FromDate: d.FromDate == null ? null : d.FromDate,
				EndDate: d.ToDate == null ? null : d.ToDate,
				AvailableLimit: d.AvailableLimit == null ? "" : d.AvailableLimit,
				LastLoadingDate: d.LastLoadingDate == null ? null : d.LastLoadingDate,
				SecureAmount: d.SecureAmount == null ? "" : d.SecureAmount,
				InsertDateTime: d.InsertDateTime == null ? "" : d.InsertDateTime
			};
		});
		return (
			<GridContainer spacing={16}>
				<LoadingComponent Show={isLoading} />

				{this.state.alert}
				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeParentTab(null, 2)} > Back </Button>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeParentTab(null, 4)} > Next </Button>
					</GridItem>
				</GridContainer>
				<GridItem xs={12}>
					<Card className="no-radius">
						<GenericExpansionPanel Title={"Security"}>
							<CardBody>
								<GridContainer spacing={16} >
									<GridItem xs={4}>
										<GenericSelectInput
											Disabled={securityModel.Id > 0}
											Name="SecurityTypeId"
											LabelText="Security Type"
											Value={securityModel.SecurityTypeId}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Url="/coreapi/v1.0/Parameter/Search"
											Method="POST"
											Parameter={{ ParameterCode: "SecurityType" }}
											Required
											IsInvalid={vModel.SecurityTypeId}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											Disabled={securityModel.Id > 0}
											IsStatic={true}
											StaticData={securityAccounts}
											Name="AccountId"
											Value={securityModel == null ? "" : securityModel.AccountId == null ? securityModel.Account == null ? "" : securityModel.Account.Id == null ? "" : securityModel.Account.Id : securityModel.AccountId}
											LabelText="Wallet Name"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="AccountName"
											Required
											IsInvalid={vModel.AccountId}
										/>
									</GridItem>
								</GridContainer>
								<br />
								<GridContainer>
									<GridItem xs={4}>
										<Card style={{ height: "90%", opacity: isFixed && securityModel.AccountId ? "" : "0.4" }}>
											<CardHeader>
												<GenericTitle text={"Fixed Cash"} />
											</CardHeader>
											<CardBody>
												<GenericNumberInput
													Name="SecureAmount"
													LabelMd={3}
													LabelText="Secure Amount"
													Value={securityModel == null ? 0 : securityModel.SecureAmount == null ? 0 : securityModel.SecureAmount}
													ValueChanged={this.handleChange}
													Prefix="$"
													Disabled={(isFixed && securityModel.AccountId) ? false : true}
													Required
													IsInvalid={vModel.SecureAmount}
												/>
												<GridContainer>
													<GridItem xs={6}>
														<GenericSelectInput
															Name="FinancialInstitution"
															LabelMd={6}
															LabelText={`${window.Title} Acc.`}
															TitleLabel="FIID"
															Method="GET"
															Url="/bankapi/v1.0/FinancialInstitution/GetAllFinInsOrderByBankNumber"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="FinancialInstitutionId"
															Value={this.state.FinInsId == null ? 46 : this.state.FinInsId}
															ValueChanged={this.handleChangeTempModel}
															Disabled={true} />
													</GridItem>
													<GridItem xs={3}>
														<GenericSelectInput
															TitleLabel="Branch"
															LabelMd={0}
															Name="Branch"
															Method="GET"
															Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="TransitNumber"
															Value={securityModel == null ? "" : securityModel.Account == null ? "" : securityModel.Account.BranchId}
															Disabled={true} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															Name="AccountNumber"
															Value={securityModel == null ? "" : securityModel.AccountNumber == null ? securityModel.Account == null ? "" : securityModel.Account.AccountNumber : securityModel.AccountNumber}
															Disabled={true}
															MaxLength={12} />
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<br /> <br /><br />
									</GridItem>
									<GridItem xs={4}>
										<Card style={{ height: "90%", opacity: isLetter && securityModel.AccountId ? "" : "0.4" }}>
											<CardHeader>
												<GenericTitle text={"Letter of Guarantee"} />
											</CardHeader>
											<CardBody>
												<GenericNumberInput
													Name="SecureAmount"
													LabelText="Secure Amount"
													Value={securityModel == null ? 0 : securityModel.SecureAmount == null ? 0 : securityModel.SecureAmount}
													ValueChanged={this.handleChange}
													Prefix="$"
													Disabled={(isLetter && securityModel.AccountId) ? false : true}
													Required
													IsInvalid={vModel.SecureAmount}
												/>
												<GenericDateInput
													Utc
													Name="FromDate"
													LabelText="From Date"
													Value={securityModel.FromDate}
													ValueChanged={this.handleChange}
													IncludeTime={false}
													MinDate={DateHelper.GetDate()}
													MaxDate={securityModel == null ? null : securityModel.ToDate || null}
													Disabled={(isLetter && securityModel.AccountId) ? false : true}
													Required
													IsInvalid={vModel.FromDate}
												/>
												<GenericDateInput
													Utc
													Name="ToDate"
													LabelText="End Date"
													Value={securityModel.ToDate}
													ValueChanged={this.handleChange}
													IncludeTime={false}
													MinDate={securityModel == null ? null : securityModel.FromDate || DateHelper.GetDate()}
													Disabled={(isLetter && securityModel.AccountId) ? false : true}
													Required
													IsInvalid={vModel.ToDate}
												/>
											</CardBody>
										</Card>
									</GridItem>
									<GridItem xs={4}>
										<Card style={{ height: "90%", opacity: isPrepaid && securityModel.AccountId ? "" : "0.4" }}>
											<CardHeader>
												<GenericTitle text={"Wallet"} />
											</CardHeader>
											<CardBody>
												<GenericNumberInput
													Name="SecureAmount"
													LabelText="Secure Amount"
													Value={securityModel == null ? 0 : securityModel.SecureAmount == null ? 0 : securityModel.SecureAmount}
													ValueChanged={this.handleChange}
													Prefix="$"
													Disabled={(isPrepaid && securityModel.AccountId ? false : true)}
													Required
													IsInvalid={vModel.SecureAmount}
												/>
												<GridContainer>
													<GridItem xs={6}>
														<GenericSelectInput
															Name="FinancialInstitution"
															LabelMd={6}
															LabelText={`${window.Title} Acc.`}
															TitleLabel="FIID"
															Method="GET"
															Url="/bankapi/v1.0/FinancialInstitution/GetAllFinInsOrderByBankNumber"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="FinancialInstitutionId"
															Value={this.state.FinInsId == null ? 46 : this.state.FinInsId}
															ValueChanged={this.handleChangeTempModel}
															Disabled={true} />
													</GridItem>
													<GridItem xs={3}>
														<GenericSelectInput
															TitleLabel="Branch"
															LabelMd={0}
															Name="Branch"
															Method="GET"
															Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="TransitNumber"
															Value={securityModel == null ? "" : securityModel.Account == null ? "" : securityModel.Account.BranchId}
															Disabled={true} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															Name="AccountNumber"
															Value={securityModel == null ? "" : securityModel.AccountNumber == null ? securityModel.Account == null ? "" : securityModel.Account.AccountNumber : securityModel.AccountNumber}
															Disabled={true}
															MaxLength={12} />
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button disabled={securityModel && securityModel.Id} size="sm" color="black" onClick={() => this.handleAddOrUpdate()}>{"Add"} </Button>
										<Button disabled={securityModel && IsUpdate && securityModel.Id ? false : true} size="sm" color="black" onClick={() => this.handleAddOrUpdate()}>{"Update"} </Button>

										<Button disabled={securityModel && IsUpdate && securityModel.Id ? false : true} size="sm" color="black" onClick={() => this.handleDelete()}>{"Delete"} </Button>
										<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>Clear</Button>
									</GridItem>
								</GridContainer>

							</CardBody>
						</GenericExpansionPanel>
					</Card>
					<br />
					<Card className="no-radius">
						<GridContainer>
							<GridItem xs={8}> <h5><b>Security Wallets</b></h5></GridItem>
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={data}
										ShowPagination={true}
										PageSize={50}
										Columns={[

											{
												Header: "Insert Date",
												accessor: "InsertDateTime",
												type: GridColumnType.DateTime
											},
											{
												Header: "Last Loading Date",
												accessor: "LastLoadingDate",
												type: GridColumnType.DateUtc
											},
											{
												Header: "Security Type",
												accessor: "SecurityType"
											},
											{
												Header: "Wallet Name",
												accessor: "AccountName"
											},
											{
												Header: "Program",
												accessor: "Program"
											},
											{
												Header: "Transit Number",
												accessor: "TransitNumber"
											},
											{
												Header: "Wallet Number",
												accessor: "AccountNumber"
											},
											{
												Header: "From Date",
												accessor: "FromDate",
												type: GridColumnType.DateUtc
											},
											{
												Header: "End Date",
												accessor: "EndDate",
												type: GridColumnType.DateUtc
											},
											{
												Header: "Secure Amount",
												accessor: "SecureAmount",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money
											},

											{
												Header: "Available Balance",
												accessor: "AvailableLimit",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money
											},

										]}
										RowSelected={index => {
											this.SelectedRowChange(index);
										}}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

Security.propTypes = {
	classes: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerSecurityList: PropTypes.array,
	paramBankCustomerInternalAccountList: PropTypes.array,
	GetCustomerSecurityModels: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(Security, styles);