import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";

class ApplicationBorrowerReview extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Applicant Name",
				accessor: "ApplicantFullName"
			},
			{
				Header: "Application Number",
				accessor: "ApplicationNumber"
			},
			{
				Header: "Review Period",
				accessor: "ApplicationBorrowerReview.ReviewPeriod.ParameterDesc"
			},
			{
				Header: "Review Type",
				accessor: "ApplicationBorrowerReview.ReviewType.ParameterDesc"
			},
			{
				Header: "Review Alert Report",
				accessor: "ApplicationBorrowerReview.ReviewAlertTime.ParameterDesc"
			},
			{
				Header: "Review Alert Notification to Borrower",
				accessor: "ApplicationBorrowerReview.ReviewAlertNotification.ParameterDesc"
			},
			{
				Header: "Review Result Notification to Borrower",
				accessor: "ApplicationBorrowerReview.ReviewResultNotification.ParameterDesc"
			},
			{
				Header: "Previous Review Date",
				accessor: "ApplicationBorrowerReview.PreviousReviewDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Next Review Date",
				accessor: "ApplicationBorrowerReview.NextReviewDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Review Result",
				accessor: "ApplicationBorrowerReview.ReviewStatus.ParameterDesc"
			}
		];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Review Alert");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/ApplicationBorrowerReview/Search", model);

		this.setState({ model, list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Review Alert">
									<GridContainer>
										<GridItem xs={4}>
											<ParameterComponent
												Name="ReviewPeriodId"
												LabelText="Review Period"
												ParameterCode="LOSReviewPeriod"
												Value={model.ReviewPeriodId}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Name="ReviewStatusId"
												LabelText="Review Result"
												ParameterCode="LOSReviewStatus"
												Value={model.ReviewStatusId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="ReviewTypeId"
												LabelText="Review Type"
												ParameterCode="LOSReviewType"
												Value={model.ReviewTypeId}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Utc
												IsFuture
												Name="FromDate"
												LabelText="Between Date"
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="ReviewAlertTimeId"
												LabelText="Review Alert Report"
												ParameterCode="LOSReviewAlertTime"
												Value={model.ReviewAlertTimeId}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="And"
												LabelMd={4}
												IncludeTime={false}
												MinDate={model.FromDate}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ApplicationBorrowerReview.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default ApplicationBorrowerReview;