import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CardHeader from "components/Card/CardHeader";
import CardComponent from "views/Components/CardComponent.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import HtmlEditorHelper from "core/HtmlEditorHelper";
import { Proxy } from "core";
import {
  GenericGrid,
  GenericEmailInput,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericCheckInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import { TaskPool } from "core/TaskPool";

class AccountNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {
        Id: 0,
        UniqueClientId: null,
        ClientCustomerId: null,
        CustomerNumber: null,
        CardId: null,
        CardTokenNumber: null,
        AccountNumber: null,
        NotificationTemplateId: null,
        NotificationThreshHold: null,
        NotificationAccountChannelTypeId: null,
        Email: null,
        PhoneNumber: null,
        MobilePhoneCountryCodeId: 0,
		  IsActive: true,
      //   ClientWebHookProfileId: null,
      },
      NotificationChannelType: [],
      propertyList: [],
      htmlState: "",
      isLoading: false,
      vModel: {},
    };
    this.ParameterMaxLength64 = { maxLength: 64 };
    this.loadTaskPool = new TaskPool(
      this.handleLoadTaskPoolAppend,
      this.handleLoadTaskPoolCompleted
    );
    this.marginLeft2 = {
      marginLeft: 2,
    };

    this.ColumnsData = [
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: (row) => (row.value ? "Active" : "Inactive"),
      },
      {
        Header: "Unique Client Id",
        accessor: "UniqueClient.UniqueClientId",
      },
      ClientHelper.IsClient()
        ? {
            Header: "Card Holder Name",
            accessor: "Card.EmbossName1",
          }
        : {
            Header: "Wallet Name",
            accessor: "Account.AccountOwnerName",
          },
      ClientHelper.IsClient()
        ? { Header: "Card Number", accessor: "Card.CardTokenNumber" }
        : { Header: "Wallet Number", accessor: "Account.AccountNumber" },
      {
        Header: "Notification ThreshHold",
        accessor: "NotificationThreshHold",
        type: GridColumnType.Money,
      },
      {
        Header: "Client Name",
        accessor: "UniqueClient.ClientName",
      },
      {
        Header: "Notification Channel Type",
        accessor: "NotificationAccountChannelType.ParameterDesc",
      },
      {
        Header: "Notification Template Subject",
        accessor: "NotificationTemplate.Subject",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Phone Number",
        accessor: "MobileNumber",
      },
      // {
      //   Header: "WebHook URL",
      //   accessor: "ClientWebHookProfile.WebHookUrl",
      // },
      {
        Header: "Insert Date Time",
        accessor: "InsertDateTime",
      },
		{
			Header: "Is Active",
			accessor: "IsActive",
			Cell: (row) => (row.value == true ? "Yes" : "No"),
		 },
    ];

    this.ValueChanged = this.ValueChanged.bind(this);
    this.ClearClick = this.ClearClick.bind(this);
    this.HandleSearch = this.HandleSearch.bind(this);
  }

  componentDidMount() {
    const { setAppLeftTitle, setAppCenterTitle } = this.props;
    setAppCenterTitle("PROGRAM");
    setAppLeftTitle("Notification Wallet");
    const { showMessage } = this.props;
    this.setState({ isLoading: true });
    Proxy.POST(
      "/coreapi/v1.0/Parameter/Search",
      {
        ParameterCode: "NotificationAccountChannelType",
      },
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          showMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        this.setState({
          NotificationChannelTypeList: responseData.Item ?? [],
        });
        //  console.log(JSON.stringify(this.state.NotificationChannelTypeList));
      },
      (error) => {
        this.setState({ isLoading: false });
        showMessage("error", "Error", error);
      }
    );
  }

  handleLoadTaskPoolCompleted = () => {
    this.setState({ isLoading: false });
  };

  handleLoadTaskPoolAppend = () => {
    this.setState({ isLoading: true });
  };

  ValueChanged(name, newValue, data) {
    const model = { ...this.state.model };

    if (name == "NotificationTemplateId") {
      if (newValue != null || newValue != undefined) {
        model.NotificationTemplateId = newValue.Id;
        this.SelectedTemplateChange(newValue, data);
      }
    }

    if (name === "UniqueClientId") {
      delete model.ClientCustomerId;
      delete model.CustomerNumber;
      delete model.CardId;
      delete model.CardTokenNumber;
      delete model.NotificationAccountChannelTypeId;
      delete model.NotificationTemplateId;
      this.setState({ htmlState: "" });
      this.setState({ NotificationTemplateList: null });
    }
    if (name === "NotificationAccountChannelTypeId") {
      this.setState({ NotificationTemplateList: null });
      delete model.NotificationTemplateId;
      this.setState({ htmlState: "" });
    }
    if (name === "Customer") {
      if (newValue) {
        model.ClientCustomerId = newValue.Id;
        model.CustomerNumber = newValue.CustomerNumber;
      } else {
        delete model.ClientCustomerId;
        delete model.CustomerNumber;
        delete model.CardId;
        delete model.CardTokenNumber;
      }
    }
    if (name === "Card") {
      if (newValue) {
        model.CardId = newValue.CardId;
        model.CardTokenNumber = newValue.CardTokenNumber;
      } else {
        delete model.CardId;
        delete model.CardTokenNumber;
      }
    }

    model[name] = newValue;

    this.setState({ model });

    if (
      (name == "NotificationAccountChannelTypeId" &&
        model.UniqueClientId != null &&
        model.UniqueClientId != undefined) ||
      (name == "UniqueClientId" &&
        model.NotificationAccountChannelTypeId != null &&
        model.NotificationAccountChannelTypeId != undefined)
    ) {
      const { showMessage } = this.props;

      this.setState({ isLoading: true });
      const requestNotificationTemplate = {
        NotificationAccountChannelTypeId:
          model.NotificationAccountChannelTypeId ?? 0,
        UniqueClientId: model.UniqueClientId,
      };
      Proxy.POST(
        "/coreapi/v1.0/NotificationTemplate/Search",
        requestNotificationTemplate,
        (responseData) => {
          this.setState({ isLoading: false });

          if (!responseData.IsSucceeded) {
            showMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.setState({
            NotificationTemplateList: responseData.Item ?? [],
          });
        },
        (error) => {
          this.setState({ isLoading: false });
          showMessage("error", "Error", error);
        }
      );
    }
  }

  ClearClick() {
	let searchModel = {
      UniqueClientId: this.isBackoffice
        ? null
        : this.state.model.UniqueClientId,
    };
    this.setState({ model: searchModel });


  }

  HandleSearch() {
    const model = this.state.model;
    const { showMessage } = this.props;
    this.setState({ isLoading: true });

    Proxy.POST(
      "/coreapi/v1.0/NotificationAccount/SearchNotificationAccount",
      model,
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          showMessage("error", "Error", responseData.ErrorDescription);
          return;
        }

        this.setState({ NotificationList: responseData.Item ?? [] });
      },
      (error) => {
        this.setState({ isLoading: false });
        showMessage("error", "Error", error);
      }
    );
  }

  FillCustomer = (customer) => {
    this.ValueChanged("Customer", customer);
  };

  FillCard = (card) => {
    this.ValueChanged("Card", card);
  };

  Validate = () => {
    const { model, vModel } = this.state;
    var messages = [];

    var selectedNotificationChannelType = "";

    if (
      this.state.NotificationChannelTypeList != null &&
      this.state.NotificationChannelTypeList != undefined
    ) {
      Object.keys(this.state.NotificationChannelTypeList).map((key, value) => {
        if (
          model.NotificationAccountChannelTypeId ==
          this.state.NotificationChannelTypeList[value].Id
        ) {
          selectedNotificationChannelType =
            this.state.NotificationChannelTypeList[value].ParameterDesc;
        }
      });
    }

    if (selectedNotificationChannelType == "Email") {
      vModel.Email = model.Email == null;
      if (vModel.Email) messages.push("Email is required");
    } else if (selectedNotificationChannelType == "SMS") {
		vModel.MobileNumber = model.PhoneNumber == null || model.MobilePhoneCountryCodeId == null;
      if (vModel.MobileNumber) messages.push("Mobile Number is required");
    }
	//  else if (selectedNotificationChannelType == "Webhook") {
	// 	vModel.ClientWebHookProfileId = model.ClientWebHookProfileId == null;
   //    if (vModel.ClientWebHookProfileId) messages.push("Webhook Profile is required");
   //  }

    vModel.UniqueClientId = model.UniqueClientId == null;
    if (vModel.UniqueClientId) messages.push("Client must be select");

    vModel.ClientCustomerId = model.ClientCustomerId == null;
    if (vModel.ClientCustomerId) messages.push("Customer must be select");

    if (ClientHelper.IsClient()) {
      vModel.CardId = model.CardId == null;
      if (vModel.CardId) messages.push("Card must be select");
    } else {
      vModel.AccountNumber = model.AccountNumber == null;
      if (vModel.AccountNumber) messages.push("Wallet Number must be select");
    }

    vModel.NotificationThreshHold = model.NotificationThreshHold == null;
    if (vModel.NotificationThreshHold)
      messages.push("Notification ThreshHold must be select");

    vModel.NotificationAccountChannelTypeId =
      model.NotificationAccountChannelTypeId == null;
    if (vModel.NotificationAccountChannelTypeId)
      messages.push("Notification Wallet Channel Type must be select");

    if (messages.length > 0) {
      this.props.showMessageNode(
        "warning",
        "Please fill required fields!",
        messages.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    this.setState({ vModel });
    return true;
  };

  SelectedTemplateChange = (value, data) => {
    if (data && data.Template != null && data.Template != "") {
      this.setState({ htmlState: data.Template });
    } else {
      this.setState({ htmlState: "" });
    }
  };

  RenderNotificationTemplate = (d) => {
    return d?.Subject;
  };

  HandleSubmit = () => {
    const { showMessage } = this.props;
    if (this.Validate()) {
      const model = this.state.model;
      this.setState({ isLoading: true });
      Proxy.POST(
        "/coreapi/v1.0/NotificationAccount/InsertOrUpdate",
        model,
        (responseData) => {
          this.setState({ isLoading: false });

          if (!responseData.IsSucceeded) {
            showMessage("error", "Error", responseData.ErrorDescription);

            return;
          }
          showMessage("success", "Success", responseData.ErrorDescription);
          this.HandleSearch();
        },
        (error) => {
          this.setState({ isLoading: false });

          showMessage("error", "Error", error);
        }
      );
    }
  };

  HandleUpdate = () => {
    const { showMessage } = this.props;
    const model = this.state.model;
    this.setState({ isLoading: true });

    Proxy.POST(
      "/coreapi/v1.0/NotificationAccount/InsertOrUpdate",
      model,
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          showMessage("error", "Error", responseData.ErrorDescription);

          return;
        }
        showMessage("success", "Success", responseData.ErrorDescription);
        this.HandleSearch();
      },

      (error) => {
        this.setState({ isLoading: false });

        showMessage("error", "Error", error);
      }
    );
  };

  HandleDelete = () => {
    this.setState({ isLoading: true });
    const { showMessage } = this.props;

    Proxy.GET(
      "/coreapi/v1.0/NotificationAccount/DeleteAccountNotification/Id?Id=" +
        this.state.model.Id,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          showMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        showMessage("success", "Success", responseData.ErrorDescription);
      },
      (error) => {
        this.setState({ isLoading: false });
        showMessage("error", "Error", error);
      }
    );
  };

  SelectedRowChange = (index) => {
    const model = this.state.NotificationList[index];
    this.setState({ model: model });
  };

  render() {
    const { Disabled } = this.props;
    const {
      isLoading,
      alert,
      model,
      vModel,
      NotificationList,
      htmlState,
      NotificationTemplateList,
    } = this.state;

    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <ButtonToolbar
              ButtonList={[
                { Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
                {
                  Code: "Search",
                  OnClick: this.HandleSearch,
                  Disabled: Disabled,
                },
                {
                  Code: "Submit",
                  OnClick: this.HandleSubmit,
                  Disabled: Disabled,
                },
                {
                  Code: "Update",
                  OnClick: this.HandleUpdate,
                  Disabled: Disabled,
                },
                {
                  Code: "Delete",
                  OnClick: this.HandleDelete,
                  Disabled: Disabled,
                },
              ]}
              menuId={this.props.menuId}
              ApprovalData={this.props.ApprovalData}
              After={this.props.After}
            />
            <Card>
              <CardHeader>
                <GenericTitle text={"Filter Panel"} />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4}>
                    <GenericSelectInput
                      Name="UniqueClientId"
                      LabelText="Client"
                      Method="POST"
                      Url="/bankapi/v1.0/BankCustomer/Search"
                      Parameter={{
                        UniqueClientId: ClientHelper.IsClient()
                          ? ClientHelper.GetClientId()
                          : undefined,
                      }}
                      DataRoot="Item"
                      KeyValueMember="Id"
                      RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                      Value={model.UniqueClientId || ""}
                      ValueChanged={this.ValueChanged}
                      CanClear
                      All
                      Disabled={ClientHelper.IsClient()}
                      DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
                      Required
                    />
                  </GridItem>
                  {ClientHelper.IsClient() ? (
                    <GridItem xs={4}>
                      <CustomerComponent
                        key={model.UniqueClientId}
                        LabelMd={4}
                        CustomerNumber={model.CustomerNumber}
                        HandleChange={this.ValueChanged}
                        FillCallback={this.FillCustomer}
                        Programs={[ProgramCodes.Prepaid]}
                        UniqueClientId={model.UniqueClientId}
                        Disabled={!model.UniqueClientId}
                        Required
                        IsInvalid={vModel.ClientCustomerId}
                      />
                    </GridItem>
                  ) : (
                    <GridItem xs={4}>
                      <GenericTextInput
                        LabelMd={4}
                        LabelText="Wallet Number"
                        Name="AccountNumber"
                        Value={
                          model.AccountNumber == null || undefined
                            ? ""
                            : model.AccountNumber
                        }
                        ValueChanged={this.ValueChanged}
                        inputProps={{ maxLength: 30 }}
                        Disabled={Disabled}
                        Required
                        IsInvalid={vModel.AccountNumber}
                      />
                    </GridItem>
                  )}
                  {ClientHelper.IsClient() && (
                    <GridItem xs={4}>
                      <CardComponent
                        key={
                          "Card_" +
                          [model.UniqueClientId, model.ClientCustomerId]
                        }
                        LabelMd={4}
                        CardTokenNumber={model.CardTokenNumber}
                        HandleChange={this.ValueChanged}
                        FillCallback={this.FillCard}
                        ClientCustomerId={model.ClientCustomerId}
                        UniqueClientId={model.UniqueClientId}
                        Disabled={!model.ClientCustomerId}
                        Required
                        IsInvalid={vModel.CardId}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={4}>
                    <GenericNumberInput
                      Name="NotificationThreshHold"
                      LabelMd={4}
                      LabelText="Notification Threshold"
                      Value={model.NotificationThreshHold}
                      ValueChanged={this.ValueChanged}
                      MaxLength={10}
                      Prefix="$"
                      IsInvalid={vModel.NotificationThreshHold}
                      Required
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericSelectInput
                      Name="NotificationAccountChannelTypeId"
                      LabelText="Notification Channel"
                      Value={
                        model === undefined
                          ? ""
                          : model.NotificationAccountChannelTypeId || ""
                      }
                      DataRoot="Item"
                      ValueChanged={this.ValueChanged}
                      KeyValueMember="Id"
                      TextValueMember="ParameterDesc"
                      Parameter={{
                        ParameterCode: "NotificationAccountChannelType",
                      }}
                      Method="POST"
                      Url="/coreapi/v1.0/Parameter/Search"
                      IsInvalid={vModel.NotificationAccountChannelTypeId}
                      Required
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericSelectInput
                      IsStatic
                      StaticData={NotificationTemplateList}
                      Name="NotificationTemplateId"
                      LabelText="Notification Template"
                      DataRoot="Item"
                      KeyValueMember="Id"
                      TextValueMember="Name"
                      RenderItem={this.RenderNotificationTemplate}
                      Value={model.NotificationTemplateId}
                      ValueChanged={this.ValueChanged}
                      Disabled={
                        model.UniqueClientId === null ||
                        model.UniqueClientId === undefined ||
                        model.NotificationAccountChannelTypeId == null ||
                        model.NotificationAccountChannelTypeId == undefined ||
                        NotificationTemplateList == null ||
                        NotificationTemplateList == undefined ||
                        NotificationTemplateList == [] ||
                        NotificationTemplateList.length == 0
                      }
                      IsInvalid={vModel.NotificationTemplateId}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericEmailInput
                      Name="Email"
                      LabelText="E-Mail"
                      inputProps={this.ParameterMaxLength64}
                      Value={model.Email}
                      ValueChanged={this.ValueChanged}
                      Disabled={Disabled}
                      IsInvalid={vModel.Email}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GridContainer>
                      <GridItem xs={8}>
                        <GenericSelectInput
                          Name="MobilePhoneCountryCodeId"
                          LabelText="Mobile Number"
                          TitleLabel="Country Code"
                          LabelMd={6}
                          Method="POST"
                          Url="/coreapi/v1.0/Country/GetAll"
                          Parameter={{}}
                          DataRoot="Item"
                          KeyValueMember="Id"
                          TextValueMember={"Code"}
                          Value={model.MobilePhoneCountryCodeId}
                          ValueChanged={this.ValueChanged}
                          Sorted={{
                            Member: "OrderIndex",
                          }}
                          RenderItem={(d) => "+" + d.Code + " (" + d.Name + ")"}
                          IsInvalid={vModel.MobileNumber}
                        />
                      </GridItem>
                      <GridItem
                        xs={4}
                        style={{
                          paddingRight: 2 + "px !important",
                        }}
                      >
                        <GenericNumberInput
                          IsPhone
                          Name="PhoneNumber"
                          Format="(###) ### ####"
                          InputStyle={this.marginLeft2}
                          LabelMd={0}
                          Value={model.PhoneNumber}
                          ValueChanged={this.ValueChanged}
                          MaxLength={10}
                          IsInvalid={vModel.MobileNumber}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
								<GenericCheckInput
									Name="IsActive"
									LabelText="Is Active"
									Value={model.IsActive}
									ValueChanged={this.ValueChanged}
								/>
                  {/* <GridItem xs={4}>
                    <GenericSelectInput
                      key={model.UniqueClientId}
                      Name="ClientWebHookProfileId"
                      LabelText="WebHook Profile"
                      Method="POST"
                      Url={
                        model.UniqueClientId > 0
                          ? "/coreapi/v1.0/NotificationAccount/GetWebHookListByUniqueClientId"
                          : null
                      }
                      Parameter={model.UniqueClientId}
                      DataRoot="Item"
                      KeyValueMember="Id"
                      TextValueMember="WebHookUrl"
                      Value={model.ClientWebHookProfileId}
                      ValueChanged={this.ValueChanged}
                      IsInvalid={vModel.ClientWebHookProfileId}
                    />
                  </GridItem> */}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <br />
                    <div style={styles.editor}>
                      {HtmlEditorHelper.HtmlContentToReact(htmlState)}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Notification Wallet List"} />
              </CardHeader>
              <CardBody>
                <GridItem xs={12}>
                  <GridItem>
                    <GenericGrid
                      Data={NotificationList}
                      Columns={this.ColumnsData}
                      ShowPagination={true}
                      RowSelected={this.SelectedRowChange}
                    />
                  </GridItem>
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AccountNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  Disabled: PropTypes.bool,
};

const styles = {
  editor: {
    border: "1px solid gray",
    minHeight: "18em",
    height: "auto",
  },
};

export default withArtifex(AccountNotification, {});
