import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, MenuCodes, ProgramCodes, TransactionOrderStatus } from "views/Constants/Constant";

class BillRecurringList extends Component {

	constructor(props) {
		super(props);
		this.emptyObject = {};

		this.renderItemUniqueClient = {};

		this.sortedName = { Member: "Name" };

		this.isClient = ClientHelper.IsClient();
		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			StartDate: DateHelper.GetDate(),
			EndDate: DateHelper.GetDate(),
			BillerId: null,
			BillerCode: "",
			TransactionOrderStatusId: null
		};
		this.state = {
			model: { ...this.initialModel },
			BillRecurringList: [],
			isLoading: false,
		};

		this.GetBillRecurringList = this.GetBillRecurringList.bind(this);
		this.IsCustomer = true;
		this.ProgramCode = "";
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name = "UniqueClientId" && value == null) {
			model[name] = 0;
		}
		this.setState({ model });
	}

	Search = () => {
		if (!this.IsValid())
			return false;
		this.setState({ isLoading: true });
		this.GetBillRecurringList();

	}

	componentDidMount() {
		var isBackOff = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };
		if (!isBackOff) {
			this.isClient = true;
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}

		switch (this.props.MenuCode) {
			case MenuCodes.ClientBillRecurringList:
				this.props.setAppCenterTitle("WALLET");
				this.props.setAppLeftTitle("Client Recurring List");
				this.Url = "/bankapi/v1.0/Bill/GetAllClientBaseRecurringBill";
				this.IsCustomer = false;
				this.ProgramCode = ProgramCodes.Bank_Programs_Bank_Account;
				break;
			case MenuCodes.CustomerBillRecurringList:
				this.props.setAppCenterTitle("BILL PAYMENT");
				this.props.setAppLeftTitle("Customer Recurring List");
				this.Url = "/bankapi/v1.0/Bill/GetAllCustomerBaseRecurringBill";
				this.IsCustomer = true;
				this.ProgramCode = ProgramCodes.BillPayment;
				break;
			default:
				this.props.setAppCenterTitle("BILL PAYMENT");
				this.props.setAppLeftTitle("Recurring Bill List");
				this.Url = "/bankapi/v1.0/Bill/GetAllRecurringBill";
				this.IsCustomer = null;
				break;
		}
		this.setState({ model });
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };

		if (vmodel.StartDate && typeof vmodel.StartDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}

		if (vmodel.EndDate && typeof vmodel.EndDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if (vmodel.StartDate && vmodel.EndDate && vmodel.EndDate < vmodel.StartDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be bigger than End Date");
			return false;
		}

		return true;
	}


	GetBillRecurringList() {

		Proxy.POST(
			this.Url,
			this.state.model,
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({
						isLoading: false,
						BillRecurringList: responseData.Item
					});
				}
				else {
					this.setState({
						isLoading: false,
						BillRecurringList: []
					});
				}
			}
		);

	}

	SelectedRowChange = (index) => {
		const { model } = { ...this.state };
		const data = this.state.BillRecurringList[index];

		model.BillerId = data.BillerId;
		model.BillerCode = data.BillerCode;
		model.UniqueClientId = data.ClientId;
		model.TransactionOrderStatusId = data.TransactionOrderStatusId;
		this.setState({ model, rowIndex: index });
	}


	Clear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	}
	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.CancelModel(model)} />
		});
		this.setState({ isLoading: false });
	}


	CancelDialog = (model) => {
		this.showQuestionMessage("Are you sure you want to cancel the recurring bill? ", model);
	}


	CancelModel = (model) => {
		Proxy.POST("/bankapi/v1.0/Bill/CancelRecurringBill",
			{
				Id: model.BillId
			}
			,
			responseData => {
				this.setState({ isLoading: false, alert: null });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("warning", "Recurring Transaction cannot be canceled.");
					return;
				}
				else {

					this.Search();
					this.ShowMessage("success", "Recurring Transaction  successfully canceled.");
				}
			},
			e => {
				this.setState({ isLoading: false, isRecurringDialogOpen: false });
				this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
			}
		);


	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}


	render() {

		const { model } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div >
				{this.isClient && this.IsCustomer == false &&
					<AccountLimitToolbar Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={ProgramCodes.BillPayment} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
				}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear

						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<LoadingComponent Show={this.state.isLoading} />
				{this.state.alert}

				<Card>
					<GenericExpansionPanel IsActive={true} Title="Filter Panel" >
						<CardBody>
							<GridContainer >
								<GridItem xs={6}>

									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										RenderItem={this.GetRenderItemUniqueClient}
										Sorted={this.sortedName}
										All={true}
										Value={model.UniqueClientId}
										ValueChanged={this.ValueChanged}
										Disabled={this.isClient}

									/>


									<GenericSelectInput
										key={[model.UniqueClientId, this.IsCustomer]}
										Name="BillerCode"
										LabelText="Payee Name"
										Method="POST"
										Url="/bankapi/v1.0/Biller/GetAllBiller"
										DataRoot="Item"
										KeyValueMember="BillerCode"
										TextValueMember="BillerName"
										Parameter={{
											UniqueClientId: model.UniqueClientId,
											IsCustomer: this.IsCustomer,
											BillPayType: 2
										}}
										Value={model.BillerCode}
										Disabled={false}
										ValueChanged={this.ValueChanged}
									/>

									<ParameterComponent
										Name="TransactionOrderStatusId" Value={model.TransactionOrderStatusId}
										LabelText="Transaction Order Status" ParameterCode="TransactionOrderStatus"
										ValueChanged={this.ValueChanged} Sorted={{ Member: "ParameterDesc" }} />
								</GridItem>

								<GridItem xs={6}>
									<GenericDateInput
										Utc
										Name="StartDate"
										LabelText="From Date"
										Value={model.StartDate ? DateHelper.ToDateInputValueAsUtc(model.StartDate) : ""}
										ValueChanged={this.ValueChanged}
										MaxDate={model.EndDate || DateHelper.GetDate()}
										IncludeTime={false} />

									<GenericDateInput
										Utc
										Name="EndDate"
										LabelText="To Date"
										Value={model.EndDate ? DateHelper.ToDateInputValueAsUtc(model.EndDate) : ""}
										ValueChanged={this.ValueChanged}
										MinDate={model.StartDate}
										MaxDate={DateHelper.GetDate()}
									/>
								</GridItem>

							</GridContainer>

						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Recurring Bill List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.BillRecurringList.map(d => {
										return {
											Actions: (
												<div>
													<GridContainer>
														<GridItem xs={6}>
															<GridButton
																Icon={EditIcon}
																Disabled={
																	(d.OrderStatusValue == TransactionOrderStatus.Cancelled ? true : false) ||
																	(d.OrderStatusValue == TransactionOrderStatus.Error ? true : false) ||
																	(d.OrderStatusValue == TransactionOrderStatus.Finished ? true : false)
																}
																OnClick={() => {
																	if (this.IsCustomer)
																		RouteHelper.Push(this.props.history, "/BillDefinition", "BillModel", d);
																	else
																		RouteHelper.Push(this.props.history, "/ClientBillDefinition", "BillModel", d);
																}
																}
															/>

														</GridItem>

														<GridItem xs={6}>

															<GridButton
																Icon={DeleteIcon}
																Disabled={
																	(d.OrderStatusValue == TransactionOrderStatus.Cancelled ? true : false) ||
																	(d.OrderStatusValue == TransactionOrderStatus.Error ? true : false) ||
																	(d.OrderStatusValue == TransactionOrderStatus.Finished ? true : false) ||
																	(d.PayIndex && d.PayIndex > 0)
																}

																OnClick={() => this.CancelDialog(d)}
															/>
														</GridItem>

													</GridContainer>
												</div>
											),
											BillerName: d.BillerName,
											ClientName: d.ClientName,
											PaymentPeriod: d.PaymentPeriod,
											LastCheckDate: d.LastCheckDate,
											BillAccountNumber: d.BillAccountNumber,
											ClientCustomerName: d.ClientCustomerName,
											IsAutoPayPeriod: d.IsAutoPayPeriod,
											AutoPayAmount: d.AutoPayAmount,
											AutoPayPeriod: d.AutoPayPeriod,
											CreateDate: d.CreateDate,
											TelpaySend: d.TelpaySend,
											Amount: d.Amount,
											TotalAmount: d.TotalAmount,
											StartDate: d.StartDate,
											EndDate: d.EndDate,
											PayIndex: d.PayIndex,
											OrderStatusDesc: d.OrderStatusDesc,
											OrderStatusValue: d.OrderStatusValue,
											UniqueClientId: d.UniqueClientId,
											TransactionSource: d.TransactionSource,
											TransactionOrderStatusId: d.TransactionOrderStatusId
										};
									})
									}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: 80,
											show: IsClient
										},
										{
											Header: "Transaction Date",
											accessor: "CreateDate",
											type: GridColumnType.DateTimeUtc,
											ColumnType: GridColumnType.DateTimeUtc
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId"
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Customer Name",
											accessor: "ClientCustomerName",
											show: this.IsCustomer
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Status",
											accessor: "OrderStatusDesc"
										},
										{
											Header: "Payee Name",
											accessor: "BillerName"
										},
										{
											Header: "Payee Wallet No.",
											accessor: "BillAccountNumber"
										},
										{
											Header: "Future Payment Date",
											accessor: "LastCheckDate",
											type: GridColumnType.DateUtc,
										},

										{
											Header: "Start Date",
											accessor: "StartDate",
											type: GridColumnType.DateUtc,
										},
										{
											Header: "End Date",
											accessor: "EndDate",
											type: GridColumnType.DateUtc,
										},
										{
											Header: "Payment Period",
											accessor: "PaymentPeriod"
										},
										{
											Header: "Auto Pay Amount",
											accessor: "AutoPayAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Total Amount",
											accessor: "TotalAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Transaction Source",
											accessor: "TransactionSource",
											Cell: row => (
												<div>
													{row.value == "Manuel" ? "Manuel"
														: row.value == "Sftp" ? "Sftp"
															: row.Value == "FileUpload" ? "File Upload"
																: "Api"
													}


												</div>
											),
										},
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									IsSorted={false}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={this.ProgramCode}
									ShowPagination={true}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div >
		);
	}
}

BillRecurringList.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillRecurringList;