import { DialogActions, withStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import React from "react";

function GenericDialogActions(props) {

	const { children, ...rest } = props;

	return (
		<DialogActions {...rest}>			
			<GridContainer justify={"center"}>			
				{children}			
			</GridContainer>
		</DialogActions>
	);
}

GenericDialogActions.propTypes = DialogActions.propTypes;

export default withStyles({})(GenericDialogActions);