
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { Component } from 'react';
import ButtonToolbar from 'views/Components/ButtonToolbar';
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericMultipleSelectInput, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from 'views/Components/LoadingComponent';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { EtransferTransactionTypes } from "views/Constants/Constant";
class InteracStatusInquriy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			vModel: {},
			responseModel: {},
			getDataList: [],
			isLoading: false,
		};
		this.ColumnsdailyFileList = [
			{
				Header: "First Name",
				accessor: "FirstName",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Last Name",
				accessor: "LastName",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Email",
				accessor: "Email",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Interac Reference Number",
				accessor: "InteracReferenceNumber",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Description",
				accessor: "Description",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Money Request Amount",
				accessor: "MoneyRequestAmount",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
			{
				Header: "Ful Fill Amount",
				accessor: "FulfillAmount",
				Cell: row => (
					<div>{row.value ? row.value : "-"}</div>
				)
			},
		];
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) {
			setAppLeftTitle("Interac Status Inquriy");
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("CLEARING & SETTLEMENT");
		}
		this.setState({ loadingCompleted: true });
	}
	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	ValidateSearchRequest = () => {

		const { model, vModel } = this.state;

		var messages = [];

		if (!model.TransactionType) {
			messages.push("Transaction Type not selected");
			vModel.TransactionType = true;
		}
		else { vModel.TransactionType = false; }

		if (!model.TransactionReferenceNumber && !model.InteracReferenceNumber) {
			messages.push("Transaction Reference Number or Interac Reference Number can not be null");
			vModel.InteracReferenceNumber = true;
			vModel.TransactionReferenceNumber = true;
		}
		else {
			vModel.InteracReferenceNumber = false;
			vModel.TransactionReferenceNumber = false;
		}

		if (messages.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}
	ClearModel = () => {
		this.setState({
			model: {}, vModel: {}, responseModel: {}
		});
	}

	handleSearch = async () => {
		if (!this.ValidateSearchRequest())
			return;
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var model = { ...this.state.model };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/ClearingSettlement/GetETransferInteracStatusInquriy", model, null, null, null, null, null);
		if (responseData != null) {
			this.setState({
				responseModel: responseData, isLoading: false
			});
		}
		else {
			this.setState({ isLoading: false });
		}
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, vModel, responseModel } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="TransactionType"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionType}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.TransactionType} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="TransactionReferenceNumber"
												LabelText="Transaction Reference Number"
												Value={model == null || undefined ? "" : model.TransactionReferenceNumber || ""}
												ValueChanged={this.handleChange}
												Required={!model.InteracReferenceNumber}
												IsInvalid={vModel.TransactionReferenceNumber} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="InteracReferenceNumber"
												LabelText="Interac Reference Number"
												Value={model == null || undefined ? "" : model.InteracReferenceNumber || ""}
												ValueChanged={this.handleChange}
												Required={!model.TransactionReferenceNumber}
												IsInvalid={vModel.InteracReferenceNumber} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						{responseModel.TransactionType &&
							<Card className="no-radius">
								<CardHeader>
									<GridContainer spacing={16} justify="space-between" alignItems="center">
										<GridItem>
											<CardHeader>
												{responseModel.TransactionType == EtransferTransactionTypes.Credit ? < h4 > <b>Credit Transaction</b></h4> : < h4 > <b>Debit Transaction</b></h4>}
											</CardHeader>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Disabled={true}
												LabelText="Transaction Reference Number"
												Value={responseModel == null || undefined ? "" : responseModel.TransactionReferenceNumber || ""} />
											<GenericTextInput
												Disabled={true}
												LabelText="Interac Reference Number"
												Value={responseModel == null || undefined ? "" : responseModel.InteracReferenceNumber || ""} />

											<GenericTextInput
												Disabled={true}
												LabelText="Description"
												Value={responseModel == null || undefined ? "" : responseModel.Description || ""} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Disabled={true}
												LabelText="FirstName"
												Value={responseModel == null || undefined ? "" : responseModel.FirstName || ""} />
											<GenericTextInput
												Disabled={true}
												LabelText="Last Name"
												Value={responseModel == null || undefined ? "" : responseModel.LastName || ""} />
											{/* <GenericTextInput
												Disabled={true}
												LabelText="Email"
												Value={responseModel == null || undefined ? "" : responseModel.Email || ""} /> */}
											<GenericTextInput
												Disabled={true}
												LabelText="Money Request Amount"
												Value={responseModel == null || undefined ? "" : responseModel.MoneyRequestAmount || ""} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Disabled={true}
												LabelText="Full Fill Amount"
												Value={responseModel == null || undefined ? "" : responseModel.FulfillAmount || ""} />
										</GridItem>

									</GridContainer>
								</CardBody>
							</Card>}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

export default InteracStatusInquriy;