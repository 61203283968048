import PropTypes from "prop-types";
import React from "react";
import { GenericSelectInput } from "views/Components/Generic";
import ClientHelper from "core/ClientHelper";

class CustomerStoreComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	RenderStore(d) {
		const storeName = d.StoreName;
		if (d.StoreCode == null){
			return `${storeName}`;
		}
		else {
			return `${storeName}(${d.StoreCode})`;
		}
	}

	render() {
		var { Model, ValueChanged } = this.props;
		return (
			<div>
				{ClientHelper.IsBackOffice() &&
					<GenericSelectInput
						key={ClientHelper.GetClientRowId()}
						Name="StoreId"
						LabelText="Store"
						All={true}
						Method="POST"
						Url="/bankapi/v1.0/CustomerStore/GetByCustomerId"
						Parameter={ClientHelper.GetClientRowId() == null ? 0 : ClientHelper.GetClientRowId()}
						DataRoot="Item"
						KeyValueMember="Id"
						RenderItem = {this.RenderStore}
						TextValueMember="StoreName"
						Value={Model.StoreId}
						ValueChanged={this.ValueChanged}
						Sorted={{ Member: "StoreName" }} />
				}

				{!ClientHelper.IsBackOffice() &&
					<GenericSelectInput
						Name="StoreId"
						LabelText="Store"
						Method="POST"
						Url="/bankapi/v1.0/CustomerStore/GetAll"
						DataRoot="Item"
						KeyValueMember="Id"
						RenderItem = {this.RenderStore}
						TextValueMember="StoreName"
						Value={Model.StoreId}
						ValueChanged={ValueChanged}
						Sorted={{ Member: "StoreName" }}
						All={true} />
				}
			</div>
		);
	}
}

CustomerStoreComponent.propTypes = {
	Model: PropTypes.object,
	ValueChanged: PropTypes.func.isRequired
};

export default CustomerStoreComponent;