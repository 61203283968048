import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { ClientType, CrudType, FileCode, FileType, GridColumnType, InformationType, MenuCodes } from "views/Constants/Constant";
import { MaxLength128, MaxLength20, MaxLength30 } from "views/Constants/Constant.MaxLength";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedOrderIndex, SortedParameterDesc } from "views/Constants/Constant.Sorted";

class InternalBlacklist extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "Client Type",
				accessor: "ClientTypeDescription"
			},
			{
				Header: "Information Type",
				accessor: "InformationTypeDescription"
			},
			{
				Header: "Company Legal Name",
				accessor: "CompanyLegalName"
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "Date of Birth",
				accessor: "DateOfBirth",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Reason",
				accessor: "BlacklistReasonDescription"
			},
			{
				Header: "Phone Country Code",
				accessor: "PhonePhoneCountryCodeName"
			},
			{
				Header: "Phone Number",
				accessor: "PhonePhoneNumber"
			},
			{
				Header: "Id Type 1",
				accessor: "IdType1Description"
			},
			{
				Header: "Id Number 1",
				accessor: "IdNumber1"
			},
			{
				Header: "Id Type 2",
				accessor: "IdType2Description"
			},
			{
				Header: "Id Number 2",
				accessor: "IdNumber2"
			},
			{
				Header: "Tax Number",
				accessor: "TaxNumber"
			},
			{
				Header: "Nick Name",
				accessor: "NickName"
			},
			{
				Header: "Alias 1",
				accessor: "Alias1"
			},
			{
				Header: "Alias 2",
				accessor: "Alias2"
			},
			{
				Header: "Alias 3",
				accessor: "Alias3"
			},
			{
				Header: "External Payment Wallet",
				accessor: "ExternalBankAccount"
			},
			{
				Header: "E-Transfer E-Mail Address",
				accessor: "ETransferEMailAddress"
			},
			{
				Header: "Linked Wallet",
				accessor: "FlinksLinkedAccount"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			}
		];

		this.state = {
			model: {},
			isFileChanged: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			if (this.props.MenuCode == MenuCodes.AML_KYC_DCBankBlacklistPool) {
				setAppLeftTitle(window.Title+" Blacklist Pool");
			}
			else if (this.props.MenuCode == MenuCodes.AML_KYC_ClientBlacklistPool) {
				setAppLeftTitle("Client Blacklist Pool");
			}
			else {
				setAppLeftTitle("Blacklist Pool");
			}
		}

		this.GetClientTypeList();
		this.GetInformationTypeList();
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name === "ClientTypeId") {
				model["isCorporate"] = this.state.corporateId == value;
				model["isPersonal"] = this.state.personalId == value;
				model["isMsbCorporate"] = this.state.msbCorporateId == value;
				model["isMsbPersonal"] = this.state.msbPersonalId == value;

				if (this.state.personalId == value || this.state.msbPersonalId == value) {
					model["CompanyLegalName"] = null;

				}
				else if (this.state.corporateId == value || this.state.msbCorporateId == value) {
					model["Name"] = null;
					model["MiddleName"] = null;
					model["LastName"] = null;
					model["DateOfBirth"] = null;
					model["IdTypeId1"] = null;
					model["IdNumber1"] = null;
					model["IdTypeId2"] = null;
					model["IdNumber2"] = null;
				}
			} else if (name === "InformationTypeId") {
				model["isEmail"] = this.state.emailId == value;
				model["isAccount"] = this.state.accountId == value;

				if (this.state.emailId == value) {
					model["CompanyLegalName"] = null;
					model["Name"] = null;
					model["MiddleName"] = null;
					model["LastName"] = null;
					model["DateOfBirth"] = null;
					model["BlacklistReasonId"] = null;
					model["PhoneCountryCodeId"] = null;
					model["PhoneNumber"] = null;
					model["IdTypeId1"] = null;
					model["IdNumber1"] = null;
					model["IdTypeId2"] = null;
					model["IdNumber2"] = null;
					model["TaxNumber"] = null;
					model["NickName"] = null;
					model["ExternalBankAccount"] = null;
					model["FlinksLinkedAccount"] = null;
					model["CardNumber"] = null;
				} else if (this.state.accountId == value) {
					model["CompanyLegalName"] = null;
					model["Name"] = null;
					model["MiddleName"] = null;
					model["LastName"] = null;
					model["DateOfBirth"] = null;
					model["BlacklistReasonId"] = null;
					model["PhoneCountryCodeId"] = null;
					model["PhoneNumber"] = null;
					model["IdTypeId1"] = null;
					model["IdNumber1"] = null;
					model["IdTypeId2"] = null;
					model["IdNumber2"] = null;
					model["TaxNumber"] = null;
					model["NickName"] = null;
					model["ETransferEMailAddress"] = null;
					model["CardNumber"] = null;
				}
			} else if (name === "ETransferEMailAddress") {
				model["IsETransferEMailAddressValid"] = data.IsValid;
			} else if (name == "IdTypeId1") {
				model["IdTypeCode1"] = data && data.ParameterValue;
				model["IdNumber1"] = "";
			} else if (name == "IdTypeId2") {
				model["IdTypeCode2"] = data && data.ParameterValue;
				model["IdNumber2"] = "";
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;
		var temp = list[index];

		temp.DateOfBirth = temp.DateOfBirth === null
			? null
			: Formatter.FormatDateUtc(temp.DateOfBirth);
		temp.isCorporate = temp.ClientTypeId == this.state.corporateId;
		temp.isPersonal = temp.ClientTypeId == this.state.personalId;
		temp.isMsbCorporate = temp.ClientTypeId == this.state.msbCorporateId;
		temp.isMsbPersonal = temp.ClientTypeId == this.state.msbPersonalId;
		temp.isEmail = temp.InformationTypeId == this.state.emailId;
		temp.isAccount = temp.InformationTypeId == this.state.accountId;
		temp.IsETransferEMailAddressValid = temp.ETransferEMailAddress && temp.ETransferEMailAddress != "";

		this.setState({ model: temp, selected: index });
	}

	GetRenderItemPhoneCountryCode = (x) => {
		var result = "+" + x.Code + " (" + x.Name + ")";

		return result;
	}

	GetClientTypeList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "ClientType" });

		if (result != null) {
			var clientTypeList = [];

			var clientTypeCorporate = result.filter(x => x.ParameterValue == ClientType.Corporate);

			if (clientTypeCorporate.length == 1) {
				var corporateId = clientTypeCorporate[0].Id;
				clientTypeList.push(clientTypeCorporate[0]);
			}

			var clientTypePersonal = result.filter(x => x.ParameterValue == ClientType.Personal);

			if (clientTypePersonal.length == 1) {
				var personalId = clientTypePersonal[0].Id;
				clientTypeList.push(clientTypePersonal[0]);
			}

			var clientTypeMsbCorporate = result.filter(x => x.ParameterValue == ClientType.WireTransferMSBCorparate);

			if (clientTypeMsbCorporate.length == 1) {
				var msbCorporateId = clientTypeMsbCorporate[0].Id;
				clientTypeList.push(clientTypeMsbCorporate[0]);
			}

			var clientTypeMsbPersonal = result.filter(x => x.ParameterValue == ClientType.WireTransferMSBIndividual);

			if (clientTypeMsbPersonal.length == 1) {
				var msbPersonalId = clientTypeMsbPersonal[0].Id;
				clientTypeList.push(clientTypeMsbPersonal[0]);
			}

			this.setState({
				corporateId,
				personalId,
				msbCorporateId,
				msbPersonalId,
				clientTypeList
			});
		}
	}

	GetInformationTypeList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "InformationType" });

		if (result != null) {
			this.setState({
				emailId: result.filter(x => x.ParameterValue === InformationType.Email)[0].Id,
				accountId: result.filter(x => x.ParameterValue === InformationType.AccountNumber)[0].Id,
				informationTypeList: result || []
			});
		}
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/InternalBlacklist/Search", model);

		model.IsClientTypeId = false;
		model.IsCompanyLegalName = false;
		model.IsName = false;
		model.IsLastName = false;
		model.IsDateOfBirth = false;
		model.IsCountryId = false;
		model.IsEmail = false;

		this.setState({ model, list: result || [] });
	}

	SubmitOrUpdate = async (action) => {
		if (!this.Validate()) {
			return;
		}

		const { model, isFileChanged } = this.state;
		const { ExecuteApiFileUpload } = this.props;

		if (isFileChanged) {
			if (model.File != null) {
				var formData = new FormData();
				formData.append("file", model.File, model.FileName);
				formData.append("fileId", model.TempFileId);
				formData.append("fileCode", FileCode.AmlKycInternalBlacklist);
				formData.append("isBinaryOrBase64", FileType.Binary);
				formData.append("isInsertOrUpdate", model.Id == 0 || model.File == null || model.FileId == null ? CrudType.Insert : CrudType.Update);

				var result = await ExecuteApiFileUpload("/coreapi/v1.0/File/UploadFile", formData);

				if (result != null) {
					this.SubmitOrUpdateExecute(action, result);
				}
			} else {
				this.SubmitOrUpdateExecute(action, null);
			}
		} else {
			this.SubmitOrUpdateExecute(action, model.File != null && model.FileId != null ? model.FileId : null);
		}
	}

	SubmitOrUpdateExecute = async (action, fileId) => {
		var { model } = this.state;
		model.FileId = fileId;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`/amlkycapi/v1.0/InternalBlacklist/${action}`, model, null, null, null, [this.Clear, this.Search], true);

		if (result != null) {
			this.setState({ isFileChanged: false });
		}
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, isFileChanged: false });
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;
		model.TempFileId = model.File != null
			? model.File.Id
			: 0;
		model.File = file;
		model.FileName = file.name;

		this.setState({ model, isFileChanged: true });
	}

	Validate() {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ClientTypeId) {
			errors.push("Client type can not be null.");
		}

		if (model.isCorporate
			&& !model.isAccount
			&& !model.isEmail
			&& !model.CompanyLegalName) {
			errors.push("Company legal name can not be null.");
		}

		if (model.isPersonal
			&& !model.isAccount
			&& !model.isEmail
			&& !model.Name) {
			errors.push("Name can not be null.");
		}

		if (model.isPersonal
			&& !model.isAccount
			&& !model.isEmail
			&& !model.LastName) {
			errors.push("Last name can not be null.");
		}

		if (model.isPersonal
			&& !model.isAccount
			&& !model.isEmail
			&& !model.DateOfBirth) {
			errors.push("Date of birth can not be null.");
		}

		if (model.isMsbPersonal && !model.Name) {
			errors.push("Name can not be null.");
		}

		if (model.isMsbPersonal && !model.LastName) {
			errors.push("Last name can not be null.");
		}

		if (model.isMsbCorporate && !model.CompanyLegalName) {
			errors.push("Company legal name can not be null.");
		}

		if ((model.isMsbPersonal || model.isMsbCorporate)
			&& !model.CountryId) {
			errors.push("Country can not be null.");
		}

		if (model.isEmail
			&& (model.ETransferEMailAddress == null
				|| model.ETransferEMailAddress == "")) {
			errors.push("E-Transfer e-mail address can not be null.");
		}

		var interval = DateHelper.GetDate().diff(model.DateOfBirth, "years");
		if (model.DateOfBirth
			&& interval < 18) {
			errors.push("Date of birth must be bigger then 18.");
		}

		if (model.isAccount
			&& (
				(!model.FlinksLinkedAccount
					&& (model.ExternalBankAccount == null || model.ExternalBankAccount == ""))
				|| (!model.ExternalBankAccount
					&& (model.FlinksLinkedAccount == null || model.FlinksLinkedAccount == ""))
			)) {
			errors.push("External Payment wallet or linked wallet must be enter.");
		}
		if (model.ETransferEMailAddress
			&& !model.IsETransferEMailAddressValid) {
			errors.push("E-Transfer e-mail address is not a correct.");
		}

		if (model.IdTypeId1 != null
			&& (model.IdNumber1 == null
				|| model.IdNumber1 == "")) {
			errors.push("Id type 1 is invalid.");
		}

		if (model.IdTypeId2 != null
			&& (model.IdNumber2 == null
				|| model.IdNumber2 == "")) {
			errors.push("Id type 2 is invalid.");
		}

		model["IsClientTypeId"] = !model.ClientTypeId;
		model["IsCompanyLegalName"] = (model.isCorporate || model.isMsbCorporate) && !model.isAccount && !model.isEmail && !model.CompanyLegalName;
		model["IsName"] = (model.isPersonal || model.isMsbPersonal) && !model.isAccount && !model.isEmail && !model.Name;
		model["IsLastName"] = (model.isPersonal || model.isMsbPersonal) && !model.isAccount && !model.isEmail && !model.LastName;
		model["IsDateOfBirth"] = (model.isPersonal || model.isMsbPersonal) && !model.isAccount && !model.isEmail && !model.DateOfBirth;
		model["IsCountryId"] = (model.isMsbPersonal || model.isMsbCorporate) && !model.CountryId;
		model["IsEmail"] = model.isEmail && (model.ETransferEMailAddress == null || model.ETransferEMailAddress == "");

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, clientTypeList, selected } = this.state;
		const { Disabled, ExecuteApiPost, ExecuteApiFileDownloadWithGenericResponse, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => this.SubmitOrUpdate("Insert")
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => this.SubmitOrUpdate("Update")
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/amlkycapi/v1.0/InternalBlacklist/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Upload",
							Disabled: Disabled,
							OnClick: this.Upload
						},
						{
							Code: "View",
							Disabled: Disabled || model.FileId == null,
							OnClick: () => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: model.FileId }, model.FileName, model.FileExtension)
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/pdf" />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Internal Blacklist">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsStatic
												IsInvalid={model.IsClientTypeId}
												Name="ClientTypeId"
												LabelText="Type"
												StaticData={clientTypeList}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.ClientTypeId}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={model.isPersonal || model.isMsbPersonal || model.isEmail || model.isAccount}
												Required={(model.isCorporate || model.isMsbCorporate) && !model.isAccount && !model.isEmail}
												IsInvalid={model.IsCompanyLegalName}
												Name="CompanyLegalName"
												LabelText="Company Legal Name"
												inputProps={MaxLength128}
												Value={model.CompanyLegalName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Required={(model.isPersonal || model.isMsbPersonal) && !model.isAccount && !model.isEmail}
												IsInvalid={model.IsName}
												Name="Name"
												LabelText="Name"
												inputProps={MaxLength30}
												Value={model.Name}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Name="MiddleName"
												LabelText="Middle Name"
												inputProps={MaxLength30}
												Value={model.MiddleName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Required={(model.isPersonal || model.isMsbPersonal) && !model.isAccount && !model.isEmail}
												IsInvalid={model.IsLastName}
												Name="LastName"
												LabelText="Last Name"
												inputProps={MaxLength30}
												Value={model.LastName}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Utc
												Disabled={model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Required={(model.isPersonal || model.isMsbPersonal) && !model.isAccount && !model.isEmail}
												IsInvalid={model.IsDateOfBirth}
												Name="DateOfBirth"
												LabelText="Date of Birth"
												IncludeTime={false}
												Value={model.DateOfBirth}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Disabled={model.isEmail || model.isAccount}
												Name="BlacklistReasonId"
												LabelText="Reason"
												ParameterCode="AmlKycBlacklistReason"
												Value={model.BlacklistReasonId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<ParameterComponent
												Name="InformationTypeId"
												LabelText="Information Type"
												ParameterCode="InformationType"
												Value={model.InformationTypeId}
												ValueChanged={this.ValueChanged} />
											<GridContainer>
												<GridItem xs={8}>
													<GenericSelectInput
														Disabled={model.isEmail || model.isAccount}
														Name="PhoneCountryCodeId"
														LabelText="Phone"
														LabelMd={6}
														Url="/coreapi/v1.0/Country/GetAll"
														Method="POST"
														Parameter={ParameterEmptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Code"
														RenderItem={this.GetRenderItemPhoneCountryCode}
														Sorted={SortedOrderIndex}
														Value={model.PhoneCountryCodeId}
														ValueChanged={this.ValueChanged} />
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput
														Disabled={model.isEmail || model.isAccount}
														IsPhone
														NoFormatting
														Name="PhoneNumber"
														LabelMd={0}
														MaxLength={10}
														Value={model.PhoneNumber}
														ValueChanged={this.ValueChanged} />
												</GridItem>
											</GridContainer>
											<ParameterComponent
												Disabled={model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Name="IdTypeId1"
												LabelText="ID Type"
												ParameterCode="IdentityType"
												Sorted={SortedParameterDesc}
												Value={model.IdTypeId1}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={!model.IdTypeId1 || model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Name="IdNumber1"
												LabelText="ID Number"
												inputProps={MaxLength20}
												Value={model.IdNumber1}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Disabled={model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Name="IdTypeId2"
												LabelText="ID Type"
												ParameterCode="IdentityType"
												Sorted={SortedParameterDesc}
												Value={model.IdTypeId2}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={!model.IdTypeId2 || model.isCorporate || model.isMsbCorporate || model.isEmail || model.isAccount}
												Name="IdNumber2"
												LabelText="ID Number"
												inputProps={MaxLength20}
												Value={model.IdNumber2}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Disabled={model.isEmail || model.isAccount}
												Name="TaxNumber"
												LabelText="Tax Number"
												inputProps={MaxLength30}
												Value={model.TaxNumber}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GridItem style={{ visibility: "Hidden" }}>
												<GenericTextInput Name="Empty" />
											</GridItem>
											<GenericTextInput
												Disabled={model.isEmail || model.isAccount}
												Name="NickName"
												LabelText="Nick Name"
												LabelMd={3}
												inputProps={MaxLength30}
												Value={model.NickName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Name="Alias1"
												LabelText="Alias1"
												LabelMd={3}
												inputProps={MaxLength30}
												Value={model.Alias1}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Name="Alias2"
												LabelText="Alias2"
												LabelMd={3}
												inputProps={MaxLength30}
												Value={model.Alias2}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Name="Alias3"
												LabelText="Alias3"
												LabelMd={3}
												inputProps={MaxLength30}
												Value={model.Alias3}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												Required={model.isMsbPersonal || model.isMsbCorporate}
												IsInvalid={model.IsCountryId}
												Name="CountryId"
												LabelText="Country"
												LabelMd={3}
												Url="/coreapi/v1.0/Country/GetAll"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.CountryId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GridItem style={{ visibility: "Hidden" }}>
												<GenericTextInput Name="Empty" />
											</GridItem>
											<GenericNumberInput
												NoFormatting
												Disabled={model.isEmail}
												Name="ExternalBankAccount"
												LabelText="External Payment Wallet"
												MaxLength={12}
												Value={model.ExternalBankAccount}
												ValueChanged={this.ValueChanged} />
											<GenericEmailInput
												Disabled={model.isAccount}
												Required={model.isEmail}
												IsInvalid={model.IsEmail}
												Name="ETransferEMailAddress"
												LabelText="E-Transfer E-Mail Address"
												inputProps={MaxLength128}
												Value={model.ETransferEMailAddress}
												ValueChanged={this.ValueChanged} />
											<GenericNumberInput
												NoFormatting
												Disabled={model.isEmail}
												Name="FlinksLinkedAccount"
												LabelText="Linked Wallet"
												MaxLength={12}
												Value={model.FlinksLinkedAccount}
												ValueChanged={this.ValueChanged} />
											<GenericNumberInput
												NoFormatting
												Disabled={model.isEmail || model.isAccount}
												Name="CardNumber"
												LabelText="Card Number"
												MaxLength={30}
												Value={model.CardNumber}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InternalBlacklist.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileUpload: PropTypes.func,
	ExecuteApiFileDownload: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default InternalBlacklist;