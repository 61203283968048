import { CardHeader } from '@material-ui/core';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import { BankingAccountTypes, ClientType, FeeProfileType, PriorityRegularType } from "views/Constants/Constant.js";
import Formatter from 'core/Formatter';

class ClientSummaryTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			LimitPropList: [],
			LimitModelList: [],
			LimitProfileModel: {},
			FixedPropList: [],
			PeriodicPropList: [],
			FeeProfileModel: {},
			FixedModelList: [],
			PeriodicModelList: [],
			WeightedModelList: [],
			ProfileType: "",
			FeeTypes: [],
			LimitTypes: [],
			TransactionFeeLabelTitleParameter: "Transaction Fee",
			TransactionFeeParameterValue5: "TXN"
		};
	}

	componentDidMount() {
		if (this.props.contract != null && this.props.contract.Program != null) {
			const contract = this.props.contract;
			this.FetchLimitPropFromParameter(contract.Program.ProgramCode);
			this.FetchFixedFeePropFromParameter(contract.Program.ProgramCode);
			this.FetchLimitProfile(contract.LimitProfileId);
			this.FetchFeeProfile(contract.FeeProfileId);
		}
	}
	FetchFeeProfile = (ProfileId) => {
		if (ProfileId != null && ProfileId != 0) {
			Proxy.GET(
				"/bankapi/v1.0/FeeProfile/GetById/Id?Id=" + ProfileId,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Limit Profile Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({
							FixedModelList: responseData.Item.Fixed || [],
							PeriodicModelList: responseData.Item.Periodic || [],
							FeeProfileModel: responseData.Item.Profile || {},
							WeightedModelList: responseData.Item.Scale || []
						});
						var temp = responseData.Item.Profile;
						temp.FeeProfileTypeStr = temp.FeeProfileType.ParameterValue;
						temp.IsSupportWeighted = temp.Program.IsSupportWeighted;
						this.setState({ FeeProfileModel: temp });
					}
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			);
		}
	}
	FetchLimitProfile = (ProfileId) => {
		if (ProfileId != null && ProfileId != 0) {
			Proxy.GET(
				"/bankapi/v1.0/LimitProfile/GetById/Id?Id=" + ProfileId,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Limit Profile Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({
							LimitModelList: responseData.Item.Limit || [],
							LimitProfileModel: responseData.Item.Profile || {}
						});
					}
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			);
		}
	}
	FetchFixedFeePropFromParameter = (code) => {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "FeeType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: code
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ FixedPropList: responseData.Item || [] });
					const ca = [...new Set(responseData.Item.map(x => x.ParameterValue4))];
					this.setState({ isLoading: false, FeeTypes: ca });
				}
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "FeeType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: "PERIODIC"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ PeriodicPropList: responseData.Item || [] });
				}
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	FetchLimitPropFromParameter = (code) => {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "LimitType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: code
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.setState({ LimitPropList: responseData.Item || [] });
					const ca = [...new Set(responseData.Item.map(x => x.ParameterValue4))];
					this.setState({ isLoading: false, LimitTypes: ca });
				}

			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	hideAlert = () => {
		this.setState({ alert: null });
	}

	render() {
		const { contract, paramGlobalModel, paramBankCustomerCorporate, paramCustomerProfile, paramBankCustomerInternalAccountList, paramBankCustomerExternalAccountList, paramBankCustomerSecurityList } = this.props;
		const { LimitPropList, LimitModelList, LimitProfileModel, FixedPropList, FixedModelList, PeriodicModelList, PeriodicPropList, FeeProfileModel, WeightedModelList, ProfileType } = this.state;
		const { FeeTypes, LimitTypes } = this.state;
		const clientType = paramGlobalModel == null ? "" : paramGlobalModel.ClientType || "";

		const PA = paramBankCustomerInternalAccountList.find(x => x.ProgramId == contract.ProgramId && x.BankingAccountType != null && x.BankingAccountType.ParameterValue == BankingAccountTypes.PrimaryAccount) || {};
		const CA = paramBankCustomerInternalAccountList.find(x => x.ProgramId == contract.ProgramId && x.BankingAccountType != null && x.BankingAccountType.ParameterValue == BankingAccountTypes.SecurityAccount) || {};
		const FA = paramBankCustomerInternalAccountList.find(x => x.ProgramId == contract.ProgramId && x.BankingAccountType != null && x.BankingAccountType.ParameterValue == BankingAccountTypes.FeeAccount) || {};

		const EA = paramBankCustomerExternalAccountList.find(x => x.ProgramId == contract.ProgramId) || {};


		const CM1 = WeightedModelList.find(x => x.LevelId == 1 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.IsCombineScale == true);
		const CM2 = WeightedModelList.find(x => x.LevelId == 2 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.IsCombineScale == true);
		const CM3 = WeightedModelList.find(x => x.LevelId == 3 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.IsCombineScale == true);
		const CM4 = WeightedModelList.find(x => x.LevelId == 4 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.IsCombineScale == true);
		const CM5 = WeightedModelList.find(x => x.LevelId == 5 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.IsCombineScale == true);


		const RG1 = WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG2 = WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG3 = WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG4 = WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG5 = WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);


		const PR1 = WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR2 = WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR3 = WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR4 = WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR5 = WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);


		return (
			<div>
				{/* DEFINITIONS - ACCOUNTS */}
				<Card>
					<CardHeader>
						<GenericTitle text={"Personal Information"} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={4}>
								<GenericSelectInput Name="ClientType" LabelText="Client Type" Value={paramGlobalModel == null ? "" : paramGlobalModel.ClientType || ""} ValueChanged={this.handleChangeGlobal}
									KeyValueMember="ParameterValue" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "ClientType", ParameterValue: "", ParameterDesc: "" }} Disabled={true} DataRoot="Item" />
								<GenericTextInput Disabled={true} LabelText="Unique Client Id" Value={paramGlobalModel == null ? "" : paramGlobalModel.UniqueClientId || ""} />
								{clientType && ((clientType == ClientType.Personal) || (clientType == ClientType.SalesAgentPersonal)) && (
									<GenericTextInput Disabled={true} LabelText="Client Name" Value={paramCustomerProfile == null ? "" : paramCustomerProfile.Name + " " + paramCustomerProfile.SurName || ""} />
								)}
								{clientType && ((clientType == ClientType.Corporate) || (clientType == ClientType.SalesAgentCorporate)) && (
									<GenericTextInput Disabled={true} LabelText="Bussines Name" Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LegalName || ""} />
								)}
							</GridItem>
							<GridItem xs={4}>
								<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Wallet Information"></GenericLabel>
								<GridContainer spacing={16}>
									<GridItem xs={4}></GridItem>
									<GridItem xs={4}>	<GenericLabel Bold={true} TextColor="black" Text="Wallet Number"></GenericLabel>  </GridItem>
									<GridItem xs={4}>	<GenericLabel Bold={true} TextColor="black" Text="Balance"></GenericLabel>  </GridItem>
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={4}>	<GenericLabel TextColor="black" Text="Funding"></GenericLabel>  </GridItem>
									<GridItem xs={4}>
										<GenericTextInput Disabled={true} LabelMd={0} Value={PA.AccountNumber || ""} />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput Disabled={true} LabelMd={0} LabelText="" Value={PA.Balance} />
									</GridItem>
								</GridContainer>								
								<GridContainer spacing={16}>
									<GridItem xs={4}>	<GenericLabel TextColor="black" Text="Security"></GenericLabel>  </GridItem>
									<GridItem xs={4}>
										<GenericTextInput Disabled={true} LabelMd={0} Value={CA.AccountNumber || ""} />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput Disabled={true} LabelMd={0} LabelText="" Value={CA.Balance} />
									</GridItem>
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={4}>	<GenericLabel TextColor="black" Text="Fee"></GenericLabel>  </GridItem>
									<GridItem xs={4}>
										<GenericTextInput Disabled={true} LabelMd={0} Value={FA.AccountNumber || ""} />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput Disabled={true} LabelMd={0} LabelText="" Value={FA.Balance} />
									</GridItem>
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={4}><GenericLabel TextColor="black" Text="External"></GenericLabel>  </GridItem>
									<GridItem xs={4}>
										<GenericTextInput Disabled={true} LabelMd={0} Value={EA.AccountNumber || ""} />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput Disabled={true} LabelMd={0} LabelText="" Value={EA.Balance} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={4}>
								<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Contract Information"></GenericLabel>
								<GenericDateInput Utc Disabled={true} Name="AgreementStartDate" LabelText="Agreement Start Date" Value={contract.AgreementStartDate == null ? "" :  DateHelper.ToDateInputValue(contract.AgreementStartDate) || ""} />
								<GenericDateInput Utc Disabled={true} Name="AgreementEndDate" LabelText="Agreement End Date" Value={contract.AgreementEndDate == null ? "" :  DateHelper.ToDateInputValue(contract.AgreementEndDate) || ""} />
								<GenericSelectInput Disabled={true} Name="SalesPersonUniqueClientId" LabelText="Sales Person" Value={contract.SalesPersonUniqueClientId || ""} DataRoot="Item" KeyValueMember="Id" TextValueMember="Name" Url="/bankapi/v1.0/BankCustomer/Search" Method="POST" Parameter={{ UniqueClientId: 0 }} />
							</GridItem>
						</GridContainer>

					</CardBody>
				</Card>
				<hr />






				{/* FEE PROFILE */}
				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Fee Profile Information "} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={3}>
								<GenericTextInput Name="ProfileName" LabelText="Profile Name" Value={FeeProfileModel.ProfileName || ""} Disabled={true} />
								<GenericDateInput Utc Name="ValidFrom" LabelText="Valid From" Value={FeeProfileModel.ValidFrom == null ? "" :  DateHelper.ToDateInputValue(FeeProfileModel.ValidFrom)} Disabled={true} />
							</GridItem>
							<GridItem xs={3}>
								<GenericSelectInput Disabled={true} Name="ProgramId" LabelText="Program" Value={FeeProfileModel.ProgramId || ""} KeyValueMember="Id" TextValueMember="Description" Method="GET" Url="/bankapi/v1.0/Bank/ValidBankProgramsForFeeProfile" DataRoot="Item" />
								<GenericDateInput  Utc Name="ValidTo" LabelText="Valid To" Value={FeeProfileModel.ValidTo == null ? "" :  DateHelper.ToDateInputValue(FeeProfileModel.ValidTo)} Disabled={true} />
							</GridItem>
							<GridItem xs={3}>
								{FeeProfileModel.IsSupportWeighted && (
									<GenericSelectInput Name="FeeProfileTypeId" LabelText="Fee Profile Type" Value={FeeProfileModel.FeeProfileTypeId}
										KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "FeeProfileType",
											ParameterValue: "",
											ParameterDesc: ""
										}}
										Disabled={true}
										DataRoot="Item" />
								)}
								{!FeeProfileModel.IsSupportWeighted && (
									<GenericSelectInput Name="FeeProfileTypeId" LabelText="Fee Profile Type" Value={FeeProfileModel.FeeProfileTypeId}
										KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "FeeProfileType",
											ParameterValue: "F",
											ParameterDesc: ""
										}}
										Disabled={true}
										DataRoot="Item" />
								)}
							</GridItem>
							<GridItem xs={3}>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card >

				{(FeeProfileModel.FeeProfileTypeStr == FeeProfileType.Fixed && FeeProfileModel.IsSupportWeighted) && (
					<Card className="no-radius" style={{ height: "90%" }}>
						<CardHeader>
							<GenericTitle text={" Transaction Fees"} />
						</CardHeader>
						<CardBody>
							{FeeTypes != null && FeeTypes.filter(x => x == this.state.TransactionFeeLabelTitleParameter).map(x => x != null && (
								<GridItem xs={12} key={x.Id + "FeeType12"} style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px" }}>
									<b><p style={{ paddingTop: "20px", marginLeft: "8px" }}> {x} </p></b>
									<GridContainer>
										{FixedPropList != null && FixedPropList.filter(y => y.ParameterValue4 == x).map(item => (
											<GridItem xs={6} key={item.Id + "FixedPopList12"}>
												<GridContainer>
													<GridItem xs={6}>
														<GenericNumberInput MaxLength={8} LabelMd={6} Name="FeeAmount" LabelText={item.ParameterValue3}
															Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
															Disabled={true}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput MaxLength={8} LabelMd={4} Name="Cost" LabelText="Cost"
															Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
															Disabled={true}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericCheckInput Name="IsCommissionCalculation" LabelText="Commission" LabelMd={6}
															Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).IsCommissionCalculation}
															Disabled={true}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										))}
									</GridContainer>
									<br />
								</GridItem>
							))}
						</CardBody>
					</Card>
				)}

				{((FeeProfileModel.FeeProfileTypeStr == FeeProfileType.CombineWeighted || FeeProfileModel.FeeProfileTypeStr == FeeProfileType.CombineUnWeighted) && (FeeProfileModel.IsSupportWeighted)) && (
					<GridContainer spacing={16}>
						<GridItem xs={6}>
							<Card style={{ height: "90%" }}>
								<CardHeader>
									<GenericTitle text={"Combine Scale Fees "} />
								</CardHeader>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}><b>Level</b></p></GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>From </b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>To</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}>Level 1</p>
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM1.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM1.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={CM1.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={CM1.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM1.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM2.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM2.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={CM2.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={CM2.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM2.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM3.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM3.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={CM3.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={CM3.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM3.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM4.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM4.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={CM4.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={CM4.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM4.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM5.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM5.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={CM5.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={CM5.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM5.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<br />
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				)}
				{((FeeProfileModel.FeeProfileTypeStr == FeeProfileType.Weighed) || (FeeProfileModel.FeeProfileTypeStr == FeeProfileType.UnWeighed)) && (FeeProfileModel.IsSupportWeighted) && (
					<GridContainer spacing={16}>
						<GridItem xs={6}>
							<Card style={{ height: "90%" }}>
								<CardHeader>
									<GenericTitle text={"Transactions Fees - Regular Debit&Credit "} />
								</CardHeader>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}><b>Level</b></p></GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>From </b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>To</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 1</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG1.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG1.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={RG1.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={RG1.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG1.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG2.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG2.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={RG2.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={RG2.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG2.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG3.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG3.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={RG3.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={RG3.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG3.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG4.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG4.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={RG4.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={RG4.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG4.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG5.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG5.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={RG5.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={RG5.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG5.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<br />
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={6}>
							<Card style={{ height: "90%" }}>
								<CardHeader>
									<GenericTitle text={" Transactions Fees - Priority Debit&Credit"} />
								</CardHeader>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Level</b></p></GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>From </b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>To</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 1</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR1.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR1.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={PR1.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={PR1.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR1.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR2.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR2.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={PR2.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={PR2.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR2.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR3.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR3.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={PR3.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={PR3.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR3.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR4.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR4.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={PR4.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={PR4.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR4.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR5.LevelFrom} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR5.LevelTo} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="FeeAmount" LabelText="" Value={PR5.FeeAmount} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="Cost" LabelText="" Value={PR5.Cost} Disabled={true} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR5.IsCommissionCalculation} Disabled={true} />
										</GridItem>
									</GridContainer>
									<br />
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				)}

				<Card className="no-radius" style={{ height: "90%" }}>
					<CardHeader>
						<GenericTitle text={"Other Fees "} />
					</CardHeader>
					<CardBody>
						{FeeTypes != null && FeeTypes.filter(x => x != this.state.TransactionFeeLabelTitleParameter).map(x => x != null && (
							<GridItem xs={12} key={x + "FeeBaseType"} style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px" }}>
								<b><p style={{ paddingTop: "20px", marginLeft: "8px" }}> {x} </p></b>
								<GridContainer>
									{FixedPropList != null && FixedPropList.filter(y => y.ParameterValue4 == x).map(item => (
										<GridItem xs={6} key={item.Id + "FeeSubType1"}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericNumberInput MaxLength={8} LabelMd={6} Name="FeeAmount" LabelText={item.ParameterValue3}
														Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
														Disabled={true}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericNumberInput MaxLength={8} LabelMd={4} Name="Cost" LabelText="Cost"
														Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
														Disabled={true}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericCheckInput Name="IsCommissionCalculation" LabelText="Commission" LabelMd={6}
														Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).IsCommissionCalculation}
														Disabled={true}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									))}
								</GridContainer>
								<br />
							</GridItem>
						))}
					</CardBody>
				</Card>

				<Card className="no-radius" style={{ height: "90%" }}>
					<CardHeader>
						<GenericTitle text={" Periodic Fees"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							{PeriodicPropList != null && PeriodicPropList.map(item => (
								<GridItem xs={6} key={item.Id + "PeriodicFeeProp325"} >
									<GridContainer>
										<GridItem xs={6}>
											<GenericNumberInput MaxLength={8} LabelMd={8} Name="FeeAmount" LabelText={item.ParameterValue3}
												Value={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput MaxLength={8} LabelMd={4} Name="Cost" LabelText="Cost"
												Value={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="Commission"
												Value={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).IsCommissionCalculation}
												Disabled={true}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							))}
						</GridContainer>
					</CardBody>
				</Card>

				<hr />


				{/* LIMIT PROFILE */}
				{(paramGlobalModel.ClientType == ClientType.Personal || paramGlobalModel.ClientType == ClientType.Corporate) && (
					<Card>
						<CardHeader>
							<GenericTitle text={"Limit Profile "} />
						</CardHeader>
						<CardBody>
							<GridItem xs={12} style={{ marginTop: 10 }}>

								<GridContainer>
									<GridItem xs={4}>
										<GenericTextInput Disabled={true} Name="ProfileName" LabelText="Profile Name" Value={LimitProfileModel.ProfileName} />
									</GridItem>
									<GridItem xs={4}>
										<GenericDateInput Utc Disabled={true} Name="ValidFrom" LabelText="Valid From" Value={LimitProfileModel.ValidFrom ?  DateHelper.ToDateInputValue(LimitProfileModel.ValidFrom) : null} />
									</GridItem>
									<GridItem xs={4}>
										<GenericDateInput Utc Disabled={true} Name="ValidTo" LabelText="Valid To" Value={LimitProfileModel.ValidTo ?  DateHelper.ToDateInputValue(LimitProfileModel.ValidTo) : null} />
									</GridItem>
								</GridContainer>
								<hr />
								<GridContainer>
									{LimitTypes != null && LimitTypes.map(x => x != null && (
										<GridItem xs={12} key={x + "LimitBaseType"} style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px" }}>
											<b><p style={{ paddingTop: "20px", marginLeft: "8px" }}> {x} </p></b>
											<GridContainer>
												{LimitPropList != null && LimitPropList.filter(y => y.ParameterValue4 == x && y.ParameterValue != null).map(item => (
													(item.ParameterValue5 != null && item.ParameterValue5 == "C") ? (
														< GridItem xs={6} key={item.Id + "LimitSubType1"} >
															<GridContainer>
																<GridItem xs={8} style={{ paddingLeft: "9px", marginTop: "10px" }}>
																	<GenericLabel Text={item.ParameterValue3}></GenericLabel>
																</GridItem>
																<GridItem xs={4}>
																	<GenericNumberInput Disabled={true} MaxLength={8} Name="LimitCount" LabelText="Count" DecimalScale={0}
																		Value={LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue) == null ? "" : LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue).LimitCount}

																	/>
																</GridItem>
															</GridContainer>
														</GridItem>
													) : (
															<GridItem xs={6} key={item.Id + "LimitSubType2"}>
																<GridContainer>
																	<GridItem xs={8}>
																		<GenericNumberInput Disabled={true} MaxLength={8} LabelMd={8} Name="LimitAmount" LabelText={item.ParameterValue3}
																			Value={LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue) == null ? "" : LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue).LimitAmount} />
																	</GridItem>
																	<GridItem xs={4}>
																		<GenericNumberInput Disabled={true} MaxLength={8} Name="LimitCount" LabelText="Count" DecimalScale={0}
																			Value={LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue) == null ? "" : LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue).LimitCount} />
																	</GridItem>
																</GridContainer>
															</GridItem>
														)
												))}
											</GridContainer>
											<br />
										</GridItem>
									))}
								</GridContainer>
							</GridItem>

						</CardBody>
					</Card>
				)}
			</div>
		);
	}
}

ClientSummaryTab.propTypes = {
	classes: PropTypes.object,
	contract: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramBankCustomerCorporate: PropTypes.object,
	paramBankCustomerExternalAccountList: PropTypes.array,
	paramBankCustomerInternalAccountList: PropTypes.array,
	paramBankCustomerSecurityList: PropTypes.array
};


export default withArtifex(ClientSummaryTab, {});