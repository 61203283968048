import React from "react";

function HeartBeat(){
	return (
		<div>
			Web Site is Online
		</div>
	);
}

export default HeartBeat;