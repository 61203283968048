import PropTypes from "prop-types";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { Component } from "react";
import { GenericDialog, GenericDialogActions, GenericLabel, GenericTextInput } from "views/Components/Generic";
class TraceTransactionAlert extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				Reason: props.Reason
			},
			vModel: {}
		};
		this.marginTop5Style = { marginTop: 5 };
		this.dialogContentStyle = { height: "auto", marginTop: 8 };
		this.titleStyle = { background: "white", marginBottom: -30, marginTop: 0 };

		this.emptyObject = {};
	}
	hideAlert = () => {
		const { PromiseResolve, showAlert } = this.props;
		showAlert(null);
		PromiseResolve(false);
	}
	ValueChanged = (name, newValue, data) => {

		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	Validate = () => {
		const { model } = this.state;
		var vModel = {};
		if (model.Reason && model.Reason.length > 1000) {
			vModel.Reason = true;
		} else {
			vModel.Reason = false;
		}

		this.setState({ vModel });
	}
	HandleTrace = async () => {
		const { PromiseResolve, OnTrace, ForWorkflow, ActionCode, ProgramCode } = this.props;
		const { model } = this.state;

		if (OnTrace) {
			const isAsync = OnTrace.constructor.name === "AsyncFunction";
			if (isAsync) {
				await OnTrace(model, ForWorkflow, ActionCode, ProgramCode);
			} else {
				OnTrace(model, ForWorkflow, ActionCode, ProgramCode);
			}
		}
		PromiseResolve(true);
	}
	render() {
		const { HasTraced } = this.props;
		const { model, vModel } = this.state;
		return (
			<div>
				<GenericDialog open={true} maxWidth="sm" fullWidth>
					<DialogTitle style={this.titleStyle}  >
						<GenericLabel FontSize="16px" TextColor="black" Text="Transaction Trace" Bold={true} />
					</DialogTitle>
					<DialogContent style={this.dialogContentStyle}>
						<GridContainer>
							<GridItem xs={12}>
								<GenericLabel TextColor="black" Text="Trace may take up to 10 business days for completion and fee may apply" />
								<br />
								<br />
								{HasTraced &&
									<GenericLabel TextColor="black" Text="Current Transaction is already traced and cannot be trace again" Bold={true} />
								}
								<GenericTextInput Disabled={HasTraced} LabelMd={2} Name="Reason" LabelText="Reason" Value={model.Reason} ValueChanged={this.ValueChanged} IsInvalid={vModel.Reason} />

							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions style={this.marginTop5Style}>
						<Button disab size="sm" onClick={this.HandleTrace} disabled={HasTraced || vModel.Reason}>Trace</Button>
						<Button size="sm" onClick={this.hideAlert}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

TraceTransactionAlert.propTypes = {
	OnTrace: PropTypes.any,
	PromiseResolve: PropTypes.func,
	HasTraced: PropTypes.any,
	ForWorkflow: PropTypes.any,
	ActionCode: PropTypes.any,
	ProgramCode: PropTypes.any,
	Reason: PropTypes.any,
	showAlert: PropTypes.func
};
export default TraceTransactionAlert;