import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";


class Country extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			Id: 0,
			Name: null
		};

		this.emptyObject = {};


		this.state = {
			model: { ...this.initialModel },
			countryList: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
			IsUpdate: false,
			vModel: {},
		};
		this.columns = [

			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Iso Code",
				accessor: "IsoCode"
			},
			{
				Header: "ISO2",
				accessor: "ISO2"
			}
		];
	}

	componentDidMount() {

	}
	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
		this.props.OnModelChange("Country", null);

	}

	HandleSearch = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCountry/Search", this.state.model);

		if (result != null) {
			this.setState({ countryList: result || [] });
		}
	}

	HandleSubmit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCountry/Insert", this.state.model);

		if (result != null) {
			this.HandleSearch();
		}
	}

	HandleUpdate = async () => {
		if (!this.Validate()) {
			return;
		}
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCountry/Update", this.state.model);

		if (result != null) {
			this.HandleSearch();
		}
	}

	HandleDelete = () => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", () => ExecuteApiPost("/bankapi/v1.0/WireCountry/Delete", { Id: this.state.model.Id }, null, null, null, [this.HandleSearch], true));
	}

	Validate() {
		const { model,vModel } = this.state;
		var messages = [];
		const { showValidationErrors } = this.props;

		vModel.Name=!model.Name;
		if (vModel.Name) {
			messages.push("Name cannot be empty!");
		}
		if (messages.length > 0) {
			showValidationErrors(messages);

			return false;
		}
		return true;
	}

	RowSelected = (index) => {
		var tempModel = this.state.countryList[index];
		this.setState({ isEdit: true, model: tempModel, index });
		this.props.OnModelChange("Country", tempModel.Id);
	}
	render() {
		const { model, countryList, vModel } = this.state;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />

				{this.state.alert}

				<GridContainer spacing={16}>
					<GridItem xs={12}>

						<Card className="no-radius">
							<CardBody>
								<CardHeader>
									<GenericTitle text={"Country List"} />
								</CardHeader>
								<GridContainer justify="flex-end">

									<GridItem justify="flex-end">
										<Button size="sm" onClick={() => this.HandleSubmit()} disabled={model.Id > 0}>
											SUBMIT
							         	</Button>
										<Button size="sm" onClick={() => this.HandleSearch()}>
											SEARCH
							         	</Button>
										<Button size="sm" onClick={() => this.HandleUpdate()} disabled={model.Id == 0}>
											UPDATE
							         	</Button>
										<Button size="sm" onClick={() => this.HandleDelete()} disabled={model.Id == 0} >
											DELETE
							         	</Button>
										<Button size="sm" onClick={() => this.HandleClear()}>
											CLEAR
							         	</Button>
									</GridItem>

								</GridContainer>

								<GridContainer>
									<GridItem xs={12}>
										<GridItem xs={4}>
											<GenericTextInput
												Name="Name"
												Value={model.Name}
												LabelText="Name"
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.Name}
												Required
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
								<br />

								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													HideButton={true}
													Columns={this.columns}
													Data={countryList}
													ShowPagination={true}
													PageSize={10}
													RowSelected={this.RowSelected}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Country.propTypes = {
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,
	ExecuteApiPost: PropTypes.func,
	showConfirmMessage:PropTypes.func,
	showValidationErrors:PropTypes.func
};

export default withArtifex(Country, {});