import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import {
  GenericAlert,
  GenericExpansionPanel,
  GenericGrid,
  GenericLabel,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import {
  GridColumnType,
  MenuCodes,
  ProgramCodes,
  TransactionMoneyTransferType,
} from "views/Constants/Constant";

class FundsTransferFromSubAccountToDCBank extends React.Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      TransactionCode: 0,
    };
    this.maxLenght = 21;

    this.state = {
      vCustomerModel: {},
      vCardModel: {},
      list: [],
      CardModel: this.initialModel,
      CustomerModel: this.initialModel,
      SelectedCustomerItem: "",
      model: this.initialModel,
      isLoading: false,
      selected: null,
      bankAccounts: [],
      cardList: [],
      TransactionType: [],
    };

    this.CardDisactive = true;
    this.CustomerDisactive = true;
    this.IsCustomerAccountSelected = true;

    this.parameterMainCard = {};
    this.parameterUniqueClientId = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientId()
        : undefined,
    };

    this.renderItemUniqueClient = {};
  }

  componentDidMount() {
    this.props.setAppLeftTitle("Funds Transfer From Sub Wallet To DC Bank");
    this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    this.ProgramCode = ProgramCodes.Lender;
    this.state.model.ProgramCode = ProgramCodes.Lender;
    this.CardDisactive = true;
    this.CustomerDisactive = false;
    this.IsCustomerAccountSelected = true;
    this.setState({ model: this.state.model });
  }
  RenderItemUniqueClient = (d) => {
    this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
    return this.renderItemUniqueClient;
  };
  HandleChange = (name, value, data) => {
    const model = { ...this.state.model };
    model[name] = value;
    

    this.setState({ model });
  };
  CustomerModelChange = (name, value, data) => {
    const model = { ...this.state.CustomerModel };
    const model2 = this.state.SelectedCustomerItem;

    var IsTrxDisabled = false;

    if (
      name === "DestinationAccountId" &&
      data !== null &&
      data !== undefined
    ) {
      this.setState({ SelectedCustomerItem: data.AccountName });
      this.IsCustomerAccountSelected = false;
    }
	 
	IsTrxDisabled = false;
    if (!IsTrxDisabled) {
      model[name] = value;
    }

    if (name === "Customer") {
      if (value) {
        model.ClientCustomerId = value.Id;
        model.CustomerNumber = value.CustomerNumber;
      } else {
        delete model.ClientCustomerId;
        delete model.CustomerNumber;
        delete model.CardId;
        delete model.CardTokenNumber;
      }
    }
    if (name === "TransactionCode") {
      model[name] = value;
    }
    this.setState({ CustomerModel: model });
  };

  HandleSelectModel = (model, index) => {
    this.setState({ model, selected: index });
    this.HandleGetList();
  };

  HandleClear = () => {
    this.CardDisactive = true;
    this.CustomerDisactive = true;
    this.IsCustomerAccountSelected = true;
    if (this.ProgramCode == ProgramCodes.Lender) this.CustomerDisactive = false;

    this.setState({
      model: this.initialModel,
      CardModel: this.initialModel,
      CustomerModel: this.initialModel,
    });
  };

  HandleGetList = () => {
    this.setState({ isLoading: true });
    var model = this.CustomerDisactive
      ? this.state.CardModel
      : this.state.CustomerModel;
    var searchRequest = {
      ClientCustomerId: model.ClientCustomerId,
      TransactionTypeId: model.TransactionTypeId,
      ProgramCode: this.ProgramCode,
      SourceAccountId: model.SourceAccountId,
      DestinationAccountId: model.DestinationAccountId,
      CardId: model.CardId,
      ReferenceNumber: model.ReferenceNumber,
    };

    Proxy.POST(
      "/bankapi/v1.0/Transaction/GetAllDcBankFundsTransaction",
      searchRequest,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        if (responseData.Item != null) {
          this.setState({ list: responseData.Item });
        } else {
          this.setState({ list: [] });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };

  HandleSubmit = () => {
    if (!this.Validate()) return;

    this.setState({ isLoading: true });
    this.state.CardModel.ProgramCode = this.ProgramCode;
    this.state.CustomerModel.ProgramCOde = this.ProgramCode;
    Proxy.POST(
      "/bankapi/v1.0/Transaction/MoneySendToDcBankAccount",
      this.CustomerDisactive ? this.state.CardModel : this.state.CustomerModel,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        this.ShowMessage("success", "Success", "Operation is successfully!");
        this.HandleClear();
        this.HandleGetList();
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };

  Validate = () => {
    const { vCustomerModel, vCardModel, CustomerModel, CardModel } = this.state;

    if (this.CardDisactive) {
      if (!CustomerModel.SourceAccountId) {
        vCustomerModel.SourceAccountId = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please select client wallet !"
        );
        return;
      } else {
        vCustomerModel.SourceAccountId = false;
      }
      if (!CustomerModel.ClientCustomerId) {
        vCustomerModel.ClientCustomerId = true;
        this.ShowMessage("warning", "Invalid", "Please select customer !");
        return;
      } else {
        vCustomerModel.ClientCustomerId = false;
      }
      if (!CustomerModel.Amount) {
        vCustomerModel.Amount = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please fill transaction amount !"
        );
        return;
      } else {
        vCustomerModel.Amount = false;
      }
      if (!CustomerModel.DestinationAccountId) {
        vCustomerModel.DestinationAccountId = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please fill Customer wallet !"
        );
        return;
      } else {
        vCustomerModel.DestinationAccountId = false;
      }

      if (!CustomerModel.TransactionTypeId) {
        vCustomerModel.TransactionTypeId = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please fill Transaction Type !"
        );
        return;
      } else {
        vCustomerModel.TransactionTypeId = false;
      }

      if (CardModel.TransactionCode == null) {
        CardModel.TransactionCode = 0;
        this.setState({ CardModel });
      }

      if (vCustomerModel.Description?.length > 21) {
        this.ShowMessage(
          "Warning",
          "Invalid",
          "Description cannot be longer than 30 characters!"
        );
      }
    } else if (!this.CardDisactive) {
      if (!CardModel.SourceAccountId) {
        vCardModel.SourceAccountId = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please select client wallet !"
        );
        return;
      } else {
        vCustomerModel.SourceAccountId = false;
      }
      if (!CardModel.ClientCustomerId) {
        vCardModel.ClientCustomerId = true;
        this.ShowMessage("warning", "Invalid", "Please select customer !");
        return;
      } else {
        vCustomerModel.ClientCustomerId = false;
      }
      if (!CardModel.Amount) {
        vCardModel.Amount = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please fill transaction amount !"
        );
        return;
      } else {
        vCardModel.Amount = false;
      }
      if (!CardModel.CardId) {
        vCardModel.CardId = true;
        this.ShowMessage("warning", "Invalid", "Please fill Card !");
        return;
      } else {
        vCardModel.DestinationAccountId = false;
      }

      if (!CardModel.TransactionTypeId) {
        vCardModel.TransactionTypeId = true;
        this.ShowMessage(
          "warning",
          "Invalid",
          "Please fill Transaction Type !"
        );
        return;
      } else {
        vCardModel.TransactionTypeId = false;
      }

      if (vCardModel.Description?.length > 21) {
        this.ShowMessage(
          "Warning",
          "Invalid",
          "Description cannot be longer than 30 characters!"
        );
      }
      if (CustomerModel.TransactionCode == null) {
        CustomerModel.TransactionCode = 0;
        this.setState({ CustomerModel });
      }
    }

    this.setState({ vCustomerModel });
    return true;
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowConfirmMessage = (type, title, message, onConfirm) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={true}
          OnCancel={() => this.setState({ alert: "" })}
          OnConfirm={onConfirm}
        />
      ),
    });
  };
  GetMainCardParameter = () => {
    this.parameterMainCard.ClientCustomerId = this.state.model.ClientCustomerId;
    this.parameterMainCard.IsActive = true;
    this.parameterMainCard.CardSearchCriteria = 2;
    return this.parameterMainCard;
  };
  FillMainCard = () => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/Card/CardSearch",
      this.GetMainCardParameter(),
      (responseData) => {
        this.setState({ isLoading: false }, () => {
          if (!responseData.IsSucceeded) {
            this.props.showMessage(
              "error",
              "Error",
              responseData.ErrorDescription
            );
            return;
          }
          this.setState({ cardList: responseData.Item }, () => {
            if (responseData.Item == null || responseData.Item.length == 0) {
              this.props.showMessage(
                "warning",
                "Warning",
                "Main card could not found."
              );
            }
          });
        });
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  RenderItemAccount = (d) => {
    this.renderItemAccount = `${d.AccountName} - ${d.AccountNumber}`;
    return this.renderItemAccount;
  };
  FillCustomerForCustomer = (customer) => {
    this.CustomerModelChange("Customer", customer);
  };

  render() {
    const { Disabled } = this.props;
    const {
      alert,
      CustomerModel,
      CardModel,
      list,
      isLoading,
      model,
      bankAccounts,
      vCustomerModel,
      vCardModel,
    } = this.state;

    var sourceAccount = bankAccounts.map((d) => {
      return {
        Id: d.Id,
        CustomerBankAccountId: d.CustomerBankAccountId,
        AccountShortName: d.AccountShortName,
        BankAccountId: d.BankAccountId,
        UniqueClientId: d.UniqueClientId,
        Balance: d.Balance,
        AccountName: d.AccountName,
        TotalBalance: d.TotalBalance,
        AccountNumber: d.AccountNumber,
        AccountNumberStr: d.AccountNumberStr,
        BranchNumber: d.BranchNumber,
        BankNumber: d.BankNumber,
        AccountTypeId: d.AccountTypeId,
        AccountType: d.AccountType,
        ProgramName: d.ProgramName,
        ProgramCode: d.ProgramCode,
        AccountCode: d.AccountCode,
        CurrencyCode: d.CurrencyCode,
      };
    });
    return (
      <div>
        <LoadingComponent Show={isLoading} />

        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
            { Code: "Clear", OnClick: this.HandleClear },
            { Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card
              className="no-radius"
              style={{ opacity: this.CustomerDisactive ? 0.4 : 1 }}
            >
              <GenericExpansionPanel
                Title="Transaction With Customer"
                IsActive={this.CardDisactive}
              >
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Name="UniqueClientId"
                            LabelText="Client"
                            Method="POST"
                            Url="/bankapi/v1.0/BankCustomer/Search"
                            Parameter={{}}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            RenderItem={this.RenderItemUniqueClient}
                            Value={model.UniqueClientId}
                            ValueChanged={this.HandleChange}
                            CanClear
                            Required
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <CustomerComponent
                            key={CustomerModel.UniqueClientId}
                            LabelMd={4}
                            CustomerNumber={CustomerModel.CustomerNumber}
                            HandleChange={this.HandleChange}
                            FillCallback={this.FillCustomerForCustomer}
                            Programs={[ProgramCodes.Prepaid]}
                            UniqueClientId={model.UniqueClientId}
                            Disabled={!model.UniqueClientId}
                            Required
                            IsInvalid={model.ClientCustomerId}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            key={CustomerModel.ClientCustomerId}
                            Name="SourceAccountId"
                            LabelMd={4}
                            LabelText="Customer Wallet"
                            Value={CustomerModel.SourceAccountId}
                            ValueChanged={this.CustomerModelChange}
                            Method="POST"
                            Url={
                              "/bankapi/v1.0/Transaction/GetCustomerAccountsOp"
                            }
                            DataRoot="Item"
                            Parameter={{
                              ProgramCode: this.ProgramCode,
                              ClientCustomerId: CustomerModel.ClientCustomerId,
                            }}
                            KeyValueMember="Id"
                            TextValueMember="Name"
                            RenderItem={this.RenderItemAccount}
                            Disabled={this.CustomerDisactive}
                            Required
                            IsInvalid={vCustomerModel.DestinationAccountId}
                            CanClear={false}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={4}>
                          <ParameterComponent
                            LabelMd={4}
                            Name="TransactionTypeId"
                            LabelText="Transaction Type"
                            ParameterCode="TransactionType"
                            Value={CustomerModel.TransactionTypeId}
                            ValueChanged={this.CustomerModelChange}
                            ParameterValue4="1"
                            Required
                            IsInvalid={vCustomerModel.TransactionTypeId}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericNumberInput
                            Name="Amount"
                            LabelMd={4}
                            LabelText="Amount"
                            Value={CustomerModel.Amount}
                            ValueChanged={this.CustomerModelChange}
                            MaxLength={10}
                            Prefix="$"
                            Required
                            Disabled={this.CustomerDisactive}
                            IsInvalid={vCustomerModel.Amount}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            key={CustomerModel.ClientCustomerId}
                            Name="DestinationAccountId"
                            LabelMd={4}
                            LabelText="To Wallet"
                            Value={CustomerModel.DestinationAccountId}
                            ValueChanged={this.CustomerModelChange}
                            Method="POST"
                            Url={
                              "/bankapi/v1.0/Transaction/GetDCBankCustomerAccounts"
                            }
                            DataRoot="Item"
                            Parameter={{
                              ProgramCode: this.ProgramCode,
                              ClientCustomerId: CustomerModel.ClientCustomerId,
                            }}
                            KeyValueMember="Id"
                            TextValueMember="Name"
                            RenderItem={this.RenderItemAccount}
                            Disabled={this.CustomerDisactive}
                            Required
                            IsInvalid={vCustomerModel.DestinationAccountId}
                            CanClear={false}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={4} >
                          <GenericTextInput
                            Name="Description"
									 LabelText="Description"
                            LabelMd={4}
                            Value={CustomerModel.Description}
                            ValueChanged={this.CustomerModelChange}
                            MultiLine={true}
                            Disabled={this.CustomerDisactive}
                            RowCount={1}
                            inputProps={{ maxLength: this.maxLenght }}
                            IsInvalid={vCardModel.Description}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </GenericExpansionPanel>
            </Card>

            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Transaction List"} />
              </CardHeader>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GenericGrid
                      Data={list}
                      Columns={[
                        {
                          Header: "Reference Number",
                          accessor: "ReferenceNumber",
                        },
                        {
                          Header: "Transaction Date",
                          accessor: "TransactionDate",
                          type: GridColumnType.Date,
                        },
                        {
                          Header: "Settlement Wallet",
                          accessor: "DestinationAccount.AccountNumber",

                        },
                        {
                          Header: "Settlement Wallet Name",
                          accessor: "SourceOwnerName",
                        },
                        {
                          Header: "Customer Wallet",
                          accessor: "SourceAccount.AccountNumber",

                        },
                        {
                          Header: "Customer Wallet Name",
                          accessor: "DestinationOwnerName",
                        },
                        {
                          Header: "Customer  Name",
                          accessor: "CustomerName",
                        },
                        {
                          Header: "Transaction Type",
                          accessor: "TransactionType.ParameterDesc",
                        },
                        {
                          Header: "Transaction Code",
                          accessor: "TransactionCode.TxnName",
                        },
                        {
                          Header: "Amount",
                          accessor: "TransactionAmount",
                          type: GridColumnType.Money,
                          ColumnType: GridColumnType.Money,
                        },
                        {
                          Header: "Description",
                          accessor: "Description",
                        },
                        {
                          Header: "Insert User",
                          accessor: "InsertUser",
                        },
                      ]}
                      Sorted={[{ id: "ReferenceNumber", desc: true }]}
                      ShowPagination={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

FundsTransferFromSubAccountToDCBank.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withArtifex(FundsTransferFromSubAccountToDCBank, {});
