import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { ApproveIcon, CredentialIcon, DeleteIcon, EditIcon, ProfileIcon, SubmitOrSendIcon, WalletIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericIcon, GenericLabel, GenericNumberInput, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { CustomerStatus, MenuCodes, ProgramCodes } from "views/Constants/Constant.js";
import { GetColumns } from "views/Customer/CustomerColumns";
import CustomerDefinition from "views/Customer/CustomerDefinition";
import WalletCustomerDetails from "views/Customer/WalletCustomerDetails";
import { DetailIcon } from "../../core/Icons";

class CustomerList extends Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.isCallCenter = this.props.MenuCode === MenuCodes.CallCenter_CustomerList || this.props.MenuCode === "CALWCL";

		this.initialModel = {
			Id: 0,
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : -1,
			ProgramId: this.ForWallet ? 5 : null
		};

		this.WalletCustomerDetail = {
			accountList: [],
			cardList: []
		};

		this.ForBillPayment = false;
		this.ForWallet = false;

		this.state = {
			alert: null,
			model: { ...this.initialModel },
			selectedCustomer: null,
			rowModel: { ...this.initialModel },
			list: [],
			isLoading: false,
			isUpdateUserDialogOpen: false,
			isWalletCustomerDetailDialogOpen: false,
			isCustomerWalletDetailDialogOpen: false,
			autoDepositInsertDialogOpen: false,
			isConfirmDeleteDialogOpen: false,
			isDownloadDialogOpen: false,
			directDepositRegistrationId: 0,
			gridButtonRightDetail: [],
			customerDetail: { ...this.WalletCustomerDetail }
		};

		this.trxTable = React.createRef();
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.parameterClientType = {
			ParameterCode: "ClientType",
			ParameterValue3: "D"
		};

		this.parameterUserStatus = {
			ParameterCode: "UserStatus",
			ParameterValue2: "1"
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined
		};

		this.parameterClient = {};

		this.renderItemUniqueClient = {};

		const DefinitionColumns = GetColumns(this.props, this.state);

		const ListColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Details"
							Disabled={props.Disabled || this.GetGridButtonDisabled("Details")}
							Icon={ProfileIcon}
							OnClick={() => this.ToggleCustomerDetail(row.original)} />
						{row.original.CustomerStatus && row.original.CustomerStatus.ParameterValue === CustomerStatus.Active &&
							<GridButton
								tooltip="Set as Inactive"
								Disabled={props.Disabled || this.GetGridButtonDisabled("SetAsInactive")}
								Icon={DeleteIcon}
								OnClick={() => { this.HandleChangeActiveStatus(row.original); }} />
						}
						{row.original.CustomerStatus && row.original.CustomerStatus.ParameterValue !== CustomerStatus.Active &&
							<GridButton
								tooltip="Set as Active"
								Disabled={props.Disabled || this.GetGridButtonDisabled("SetAsActive")}
								Icon={ApproveIcon}
								OnClick={() => { this.HandleChangeActiveStatus(row.original); }} />
						}
						<GridButton
							tooltip="Re-send Credentials"
							Disabled={props.Disabled || !row.original.User || this.GetGridButtonDisabled("ResendCredentials")}
							Icon={SubmitOrSendIcon}
							OnClick={() => this.ShowConfirmMessage("warning", "Warning", "Are you sure you want to resend login information? This process will reset the previous password of the user!", () => this.HandleSendUserCredentialEmail(row.original))} />
						<GridButton
							tooltip="Re-send Wallet e-Mail"
							Disabled={props.Disabled || !row.original.User || this.GetGridButtonDisabled("ResendWalletAccountEmail")}
							Icon={WalletIcon}
							OnClick={() => { this.HandleReSendWalletAccountEmail(row.original); }} />
						<GridButton
							tooltip="Re-send Security Wallet e-Mail"
							Disabled={props.Disabled || !row.original.User || !row.original.Programs.includes(ProgramCodes.Lender) ||
								this.GetGridButtonDisabled("ResendSecurityAccountEmail")}
							Icon={CredentialIcon}
							OnClick={() => { this.HandleSendSecurityAccountEmail(row.original); }} />
						<GridButton
							tooltip="Update User Info"
							Disabled={(
								props.Disabled || !row.original.User ||
								this.GetGridButtonDisabled("ResendCredentials")) ||
								this.GetGridButtonDisabled("UpdateUserInfo")}
							Icon={EditIcon}
							OnClick={() => this.ToggleEditUserModel(row.original)} />

						{this.ForWallet &&
							<GridButton
								Disabled={props.Disabled || this.GetGridButtonDisabled("WalletCustomerDetails")}
								tooltip="Wallet Customer Details"
								Icon={DetailIcon}
								OnClick={() => { this.HandleCustomerWalletDialogOpen(row.original); }} />}

						<GridButton
							tooltip="Add AutoDeposit"
							Disabled={props.Disabled || row.original.DirectDepositReferenceNumber != null}
							Icon={ApproveIcon}
							OnClick={() => { this.OpenAutodepositInsert(row.original); }} />
						<GridButton
							tooltip="Delete ADR Record"
							Disabled={props.Disabled || row.original.DirectDepositRegistrationId == null}
							Icon={DeleteIcon}
							OnClick={() => { this.handleDelete(row.original.DirectDepositRegistrationId); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 210
			}];

		this.ColumnsData = [...ListColumns, ...DefinitionColumns];
	}

	handleDelete = (id) => {
		if (id == null || id == 0)
			this.props.showMessage("error", "Error", "Record is invalid !");

		this.setState({ isConfirmDeleteDialogOpen: true, directDepositRegistrationId: id });
	}

	confirmDelete = () => {
		this.hideAlert();
		this.setState({ isLoading: true });

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/DeleteDirectDepositRegistration",
			this.state.directDepositRegistrationId,
			responseData => {
				this.hideAlert();
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Interac User deleted successfully.");
				this.hideAlert();
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle, MenuCode } = this.props;

		switch (MenuCode) {
			case MenuCodes.Bill_CustomerList:
				if (setAppLeftTitle) setAppLeftTitle("Customer List");
				if (setAppCenterTitle) setAppCenterTitle("BILL PAYMENT");
				this.ForBillPayment = true;
				break;
			case MenuCodes.CallCenter_CustomerList:
				if (setAppLeftTitle) setAppLeftTitle("Customer List");
				if (setAppCenterTitle) setAppCenterTitle("CALL CENTER");
				this.GetGridButtonsRight();
				break;
			case "CWLCUL":
			case "BWLCUL":
				if (setAppLeftTitle) setAppLeftTitle("Wallet Customer List");
				this.ForWallet = true;
				break;
			case "CALWCL":
				if (setAppLeftTitle) setAppLeftTitle("Wallet Customer List");
				this.ForWallet = true;
				this.GetWalletGridButtonsRight();
				break;
			default:
				if (setAppLeftTitle) setAppLeftTitle("Customer List");
				if (setAppCenterTitle) setAppCenterTitle("CUSTOMER SYSTEM");
				break;
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleRowChange = (name, newValue, data) => {
		const rowModel = { ...this.state.rowModel };
		rowModel["User"][name] = newValue;
		this.setState({ rowModel });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	ValueChangedForAutodeposit = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;

		if (name == "InteracAccount") {
			model["DirectDepositSourceAccountId"] = data.Id;
		}

		this.setState({ model });
	}

	HandleSearch = () => {
		const { model } = { ...this.state };
		if (this.ForWallet) model.ProgramId = 5;
		this.setState(state => ({ trxGridToken: !state.trxGridToken, loadingCompleted: true, model, alert: null }));
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	HandleCustomerWalletDialogOpen = async (customerData) => {
		const { ExecuteApiPost } = this.props;
		const { customerDetail } = this.state;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/Wallet/Search", { CustomerNumber: customerData.CustomerNumber });
		if (result != null) {
			customerDetail.accountList = result.AccountList;
			customerDetail.cardList = result.CardList;
			this.setState({ customerDetail, isWalletCustomerDetailDialogOpen: true });

		}
	}

	HandleChangeActiveStatus = (model) => {
		this.ShowConfirmMessage("warning", "Warning",
			model.CustomerStatus.ParameterValue === CustomerStatus.Active ?
				"Are you sure you want to change the customer's status to inactive?" :
				"Are you sure you want to change the customer's status to active?", () => {
					this.setState({ alert: null, isLoading: true });
					Proxy.POST(
						"/bankapi/v1.0/ClientCustomer/ChangeActiveStatus",
						{
							UniqueClientId: model.UniqueClientId,
							ClientCustomerId: model.Id
						},
						responseData => {
							this.setState({ isLoading: false });
							if (!responseData.IsSucceeded) {
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
								return;
							}
							if (responseData.Item) {
								this.ShowConfirmMessage("success", "Success", "Operation is successfully!", this.HandleSearch, false);
							} else {
								this.ShowMessage("error", "Error", "An error occurred during the change of the customer's active status");
							}
						},
						this.ErrorCallback
					);
				});
	}

	HandleSendUserCredentialEmail = (model) => {
		this.setState({ alert: null, isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/SendUserCredentialEmail",
			{
				ClientCustomerId: model.Id
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			this.ErrorCallback
		);
	}

	HandleReSendWalletAccountEmail = (model) => {
		this.setState({ alert: null, isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/SendWalletAccountEmail",
			{
				UniqueClientId: model.UniqueClientId,
				ClientCustomerId: model.Id,
				Programs: model.Programs
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			this.ErrorCallback
		);
	}

	HandleSendSecurityAccountEmail = (model) => {
		this.setState({ alert: null, isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/SendSecurityAccountEmail",
			{
				UniqueClientId: model.UniqueClientId,
				ClientCustomerId: model.Id
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			this.ErrorCallback
		);
	}

	ToggleEditUserModel = (rowModel) => {
		this.setState({ isUpdateUserDialogOpen: !this.state.isUpdateUserDialogOpen, rowModel });
	}

	HandleEditUser = () => {
		const { isUpdateUserDialogOpen, rowModel } = this.state;

		this.setState({
			isUpdateUserDialogOpen: !isUpdateUserDialogOpen,
			rowModel,
			isLoading: true
		});

		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/UpdateUser",
			{
				ClientCustomerId: rowModel.Id,
				Email: rowModel.User.Email,
				UserStatusId: rowModel.User.UserStatusId,
				ExpDateOfId: rowModel.User.ExpDateOfId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			this.ErrorCallback
		);
	}

	ToggleCustomerDetail = (rowModel) => {
		this.setState({ isCustomerDetailDialogOpen: !this.state.isCustomerDetailDialogOpen, rowModel });
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExportDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Client Customer List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClientCustomer/ExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.ShowMessage
		);
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	GetModel = () => { return this.state.model; }

	GetClientParameter = () => {
		this.parameterClient.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterClient;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.UniqueClientId) {
			this.ShowMessage("warning", "Client not selected", "Select Client to continue.");
			return false;
		}

		return true;
	}

	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALCUSDETA", "CALCUSDEAC", "CALCUSACTI", "CALCUSRECR", "CALCUSREWA", "CALCUSRESE"]);

		this.setState({ gridButtonRightDetail });
	}

	GetWalletGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALCLTCUD", "CALCLTSAI", "CALCLTSAA", "CALCLTRSC", "CALCLTWAE", "CALCLTSAE", "CALCLTUUI", "CALCLTWCD"]);
		this.setState({ gridButtonRightDetail });
	}

	GetGridButtonDisabled = (action) => {
		const { MenuCode } = this.props;

		if (MenuCode === MenuCodes.CallCenter_CustomerList && action === "Details") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCUSDETA")?.HasAuthority;
		} else if (MenuCode === "CALWCL" && action === "Details") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTCUD")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CustomerList && action === "SetAsInactive") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCUSDEAC")?.HasAuthority;
		} else if (MenuCode === "CALWCL" && action === "SetAsInactive") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTSAI")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CustomerList && action === "SetAsActive") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCUSACTI")?.HasAuthority;
		} else if (MenuCode === "CALWCL" && action === "SetAsActive") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTSAA")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CustomerList && action === "ResendCredentials") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCUSRECR")?.HasAuthority;
		} else if (MenuCode === "CALWCL" && action === "ResendCredentials") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTRSC")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CustomerList && action === "ResendWalletAccountEmail") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCUSREWA")?.HasAuthority;
		} else if (MenuCode === "CALWCL" && action === "ResendWalletAccountEmail") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTWAE")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CustomerList && action === "ResendSecurityAccountEmail") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCUSRESE")?.HasAuthority;
		} else if (MenuCode === "CALWCL" && action === "ResendSecurityAccountEmail") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTSAE")?.HasAuthority;
		}

		if (MenuCode === "CALWCL" && action === "UpdateUserInfo") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTUUI")?.HasAuthority;
		}

		if (MenuCode === "CALWCL" && action === "WalletCustomerDetails") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCLTWCD")?.HasAuthority;
		}
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: null })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm, showCancel = true) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={showCancel} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	ScreenParameter = (menuCode, model) => {
		if (menuCode == "CWLCUL" || menuCode == "BWLCUL" || menuCode == "CALWCL") {
			this.ForWallet = true;
			model.ProgramId = 5;
		}

		if (menuCode === MenuCodes.Bill_CustomerList) {
			this.ForBillPayment = true;
			model.ProgramId = 70;
		}
	}

	OpenAutodepositInsert = (customer) => {
		this.setState({ autoDepositInsertDialogOpen: true, selectedCustomer: customer });
	}

	InsertOrUpdateInteracUser = () => {
		const { model, selectedCustomer } = this.state;

		Proxy.POST("/bankapi/v1.0/ClientCustomer/DirectDepositRegistration",
			{
				ClientCustomerId: selectedCustomer.Id,
				InteracEmail: model.DirectDepositInteracEmail,
				IsDdrActive: true,
				IsUpdate: true,
				WalletAccountId: model.DirectDepositSourceAccountId
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				else{
					this.HandleSearch();
				}
				this.ShowMessage("success", "Success", "Operation successfully.");
				this.HandleClear();
				this.setState({ autoDepositInsertDialogOpen: false });
			},
			e => {
				this.ShowMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}


	hideAlert = () => { this.setState({ isLoading: false, autoDepositInsertDialogOpen: false, isConfirmDeleteDialogOpen: false }); }

	render() {
		const { Disabled, MenuCode } = this.props;
		const { alert, model, rowModel, isLoading, isUpdateUserDialogOpen, isWalletCustomerDetailDialogOpen, isCustomerDetailDialogOpen, ReadOnly, autoDepositInsertDialogOpen, isConfirmDeleteDialogOpen, selectedCustomer } = this.state;

		return (
			<>
				{alert}
				{this.ScreenParameter(MenuCode, model)}
				<LoadingComponent Show={isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: ReadOnly || Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>

					<GenericDialog open={isWalletCustomerDetailDialogOpen} maxWidth="xl" fullWidth>
						<DialogTitle>
							<GenericLabel Text="Wallet Customer Details" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<WalletCustomerDetails accountList={this.state.customerDetail.accountList} cardList={this.state.customerDetail.cardList} />
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={() => this.setState({ isWalletCustomerDetailDialogOpen: false })}>Close</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GenericDialog open={isUpdateUserDialogOpen} maxWidth="md" fullWidth>
						<DialogTitle>
							<GenericLabel Text="Update User Info" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<div style={{ minHeight: 200 }}>
								<GridContainer>
									<GridItem xs={6}>
										<CustomerComponent
											key={"Customer_" + rowModel.UniqueClientId}
											CustomerNumber={rowModel.CustomerNumber}
											HandleChange={this.HandleChange}
											UniqueClientId={rowModel.UniqueClientId}
											Disabled />
									</GridItem>
									{rowModel.User &&
										<>
											<GridItem xs={6}>
												<GenericEmailInput
													Name="Email"
													LabelText="E-Mail"
													Value={rowModel.User.Email}
													ValueChanged={this.HandleRowChange} />
											</GridItem>
											<GridItem xs={6}>
												<GenericSelectInput
													Name="UserStatusId"
													LabelText="User Status"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterUserStatus}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={rowModel.User.UserStatusId}
													ValueChanged={this.HandleRowChange} />
											</GridItem>
											<GridItem xs={6}>
												<GenericDateInput
													Name="ExpDateOfId"
													LabelText="Expire Date"
													MinDate={DateHelper.GetDate()}
													Value={rowModel.User.ExpDateOfId ? DateHelper.ToDateInputValue(rowModel.User.ExpDateOfId) : ""}
													ValueChanged={this.HandleRowChange} />
											</GridItem>
										</>
									}
								</GridContainer>
							</div>
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={() => this.HandleEditUser()}>Update</Button>
							<Button size="sm" onClick={() => this.setState({ isUpdateUserDialogOpen: false })}>Close</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GenericDialog open={isConfirmDeleteDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth>
						<DialogTitle  >
							<GenericLabel FontSize="16px" Text="Delete AutoDeposit Registration" Bold={true} />
						</DialogTitle>
						<DialogContent>
							Do you want to delete this AutoDeposit registration record ?
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={this.confirmDelete}>Confirm</Button>
							<Button size="sm" onClick={() => this.setState({ isConfirmDeleteDialogOpen: false })}>Cancel</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GenericDialog open={autoDepositInsertDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.hideAlert}>
						<DialogContent style={{ height: 250, marginTop: 8 }}>
							<GenericSelectInput
								key={autoDepositInsertDialogOpen}
								Name="InteracAccount"
								LabelText="Interac Wallet"
								Method="POST"
								Url={selectedCustomer == undefined ? "" : "/bankapi/v1.0/Account/GetAccountByCustomerId"}
								Parameter={selectedCustomer == undefined ? null : selectedCustomer.Id}
								DataRoot="Item"
								KeyValueMember="Id"
								RenderItem={d => `${d.Program.Description} - ${d.AccountNumber}`}
								Value={model.InteracAccount}
								ValueChanged={this.ValueChangedForAutodeposit}
								CanClear
							/>
							<br></br>
							<GenericTextInput
								Name="DirectDepositInteracEmail"
								LabelText="Interac Email"
								Value={model.DirectDepositInteracEmail}
								ValueChanged={this.ValueChangedForAutodeposit}
								Required
							/>
						</DialogContent>
						<GenericDialogActions style={{ marginTop: 5 }}>
							<Button size="sm" onClick={() => this.setState({ autoDepositInsertDialogOpen: false })}>Close</Button>
							<Button size="sm" onClick={this.InsertOrUpdateInteracUser}>Add Autodeposit</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GenericDialog open={isCustomerDetailDialogOpen} fullScreen onBackdropClick={this.ToggleCustomerDetail}>
						<DialogTitle>
							<GridContainer justify="space-between" alignItems="center">
								<GridItem>
									<GenericLabel FontSize="16px" TextColor="black" Text="Customer Detail" Bold={true} />
								</GridItem>
								<GridItem>
									<IconButton onClick={this.ToggleCustomerDetail}>
										<GenericIcon>close</GenericIcon>
									</IconButton>
								</GridItem>
							</GridContainer>
						</DialogTitle>
						<DialogContent>
							<CustomerDefinition
								hideSidebar={this.props.hideSidebar}
								setAppLeftTitle={this.props.setAppLeftTitle}
								setAppCenterTitle={this.props.setAppCenterTitle}
								ForUpdate={true}
								Model={{ Id: rowModel.Id, CallCenter: this.isCallCenter, UniqueClientId: rowModel.UniqueClientId }}
								menuId={this.props.menuId}
								ApprovalData={this.props.ApprovalData}
								After={this.props.After}
								Disabled={this.props.Disabled} />
						</DialogContent>
					</GenericDialog>

					<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
						<DialogTitle>
							<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
						</DialogTitle>
						<DialogContent style={{ height: 300 }}>
							<GenericRadioInput
								Name="ExportReportType"
								LabelText="Report Type"
								IsStatic={true}
								StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
								KeyValueMember="Value"
								TextValueMember="Text"
								Value={this.state.ExportReportType}
								ValueChanged={this.ValueChanged} />
							<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
							<GridItem style={{ marginTop: 20 }}>
								<Select
									value={this.state.ExportSelectedColumns}
									onChange={value => this.setState({ ExportSelectedColumns: value })}
									isMulti
									name="columns"
									getOptionLabel={d => d.title}
									options={this.state.GridKeyValueList}
									className="basic-multi-select"
									classNamePrefix="select" />
							</GridItem>
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={this.ExportDownload}>Download</Button>
							<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												All
												Disabled={this.isClient}
												DefaultIndex={this.isClient ? 0 : -1} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="ClientTypeId"
												LabelText="Customer Type"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.parameterClientType}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.ClientTypeId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={4}>
											{!this.ForBillPayment && !this.ForWallet &&
												<GenericSelectInput
													key={model.UniqueClientId}
													Name="ProgramId"
													LabelText="Program"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomer/GetAllPrograms"
													Parameter={this.GetClientParameter()}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Description"
													Value={model.ProgramId}
													ValueChanged={this.HandleChange}
													Sorted={{ Member: "Description" }} />}
											{(this.ForBillPayment || this.ForWallet) &&
												<GenericEmailInput
													Name="Email"
													LabelText="E-Mail"
													Value={model.Email}
													ValueChanged={this.HandleChange} />
											}
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="ClientCustomerNumber"
												LabelText="Customer Number"
												Value={model.ClientCustomerNumber}
												ValueChanged={this.HandleChange}
												MaxLength={50}
												NoFormatting={true} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="CustomerName"
												LabelText="Name"
												Value={model.CustomerName}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={4}>
											{(!this.ForBillPayment && !this.ForWallet) &&
												<GenericEmailInput
													Name="Email"
													LabelText="E-Mail"
													Value={model.Email}
													ValueChanged={this.HandleChange} />
											}
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Client Customer List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												key={"trxGrid" + this.state.trxGridToken}
												ref={this.trxTable}
												PageSize={10}
												ShowPagination={true}
												Columns={this.ColumnsData}
												DocumentTitle="Customer List"
												ServerSide
												LoadAtStartup={this.state.loadingCompleted}
												ValidateRequest={this.Validate}
												PrepareRequest={this.GetModel}
												RequestUrl="/bankapi/v1.0/ClientCustomer/SearchForList"
												RequestMethod="POST"
												IsSorted={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

CustomerList.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CustomerList, {});