import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericTitle, GenericPage, GenericDateInput, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class WireTransferRestrictedCountries extends GenericPage {
	constructor(props) {
		super(props);

		this.emptyCountryBlacklistData = {
			Id: null,
			CountryId: null,
			Country: null,
			CountryCode: "",
			CountryName: "",
			StartDate: null,
			EndDate: null,
		};

		this.state = {
			model: { ...this.emptyCountryBlacklistData },
			selectedRowIndex: null,
			isLoading: false,
			data: []
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Restricted Countries");
		if (setAppCenterTitle)
			setAppCenterTitle("WIRE TRANSFER");
	}

	HandleSubmitClick() {
		if (!this.Validate())
			return;

		const { model } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/SwiftCountryBlacklist/Submit",
			{
				CountryId: model.CountryId,
				StartDate: model.StartDate,
				EndDate: model.EndDate
			},
			() => {
				this.ShowGenericMessage("success", "Submitted", "Restricted Country successfully submitted.");
				this.HandleClearClick(this.HandleSearchClick);
			});
	}

	/**
	 * @param {() => void} fcont
	 */
	HandleClearClick(fcont) {

		this.setState({
			model: { ...this.emptyCountryBlacklistData },
			selectedRowIndex: null,
			data: []
		}, fcont);
	}

	HandleSearchClick() {
		const { model } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/SwiftCountryBlacklist/Search",
			{
				CountryId: model.CountryId,
				StartDate: model.StartDate,
				EndDate: model.EndDate
			},
			responseData => {
				this.setState({ data: responseData.Item });
			});
	}

	HandleUpdateClick() {
		if (!this.Validate())
			return;

		const { model } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/SwiftCountryBlacklist/Update",
			{
				Id: model.Id,
				CountryId: model.CountryId,
				StartDate: model.StartDate,
				EndDate: model.EndDate
			},
			() => {
				this.ShowGenericMessage("success", "Updated", "Restricted Country successfully updated.");
				this.HandleClearClick(this.HandleSearchClick);
			});
	}

	HandleDeleteClick() {
		const { model } = this.state;
		this.ShowGenericMessage("warning", "Restricted Country Deletion Confirmation", "Are you sure to delete selected restricted country?", true, () => {
			this.ExecutePostRequest("/bankapi/v1.0/SwiftCountryBlacklist/Delete",
				model.Id,
				() => {
					this.ShowGenericMessage("success", "Deleted", "Restricted Country successfully deleted.");
					this.HandleClearClick(this.HandleSearchClick);
				});
		});
	}

	Validate() {
		const { model } = this.state;
		const isUpdate = model.Id != null;

		var messages = [];
		if (model.CountryId == null) messages.push("Country is not selected.");

		if (model.StartDate == null) messages.push("Start Date cannot be null.");
		else if (typeof model.StartDate != "object") messages.push("Start Date is invalid.");
		else if (!isUpdate && DateHelper.ToMoment(model.StartDate).startOf("day") < DateHelper.GetDateLocal()) messages.push("Start Date cannot be past.");

		if (model.EndDate == null) messages.push("End Date cannot be null.");
		else if (typeof model.EndDate != "object") messages.push("End Date is invalid.");
		else if (DateHelper.ToMoment(model.EndDate).startOf("day") < DateHelper.GetDateLocal()) messages.push("End Date cannot be past.");
		if (typeof model.StartDate == "object" && typeof model.EndDate == "object" && model.StartDate > model.EndDate) messages.push("Start Date cannot be after End Date.");

		if (messages.length > 0) {
			this.ShowGenericMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	/**
	 * @param {number} index
	 */
	RowSelected(index) {
		const { data } = this.state;
		var selectedRow = data[index];

		// model.Id = selectedRow.Id;
		// model.CountryId = selectedRow.CountryId;
		// model.StartDate = selectedRow.StartDate;
		// model.EndDate = selectedRow.EndDate;
		var newModel = { ...selectedRow };
		newModel.StartDate = DateHelper.ToMoment(newModel.StartDate);
		newModel.EndDate = DateHelper.ToMoment(newModel.EndDate);

		this.setState({ model: newModel, selectedRowIndex: index });
	}

	ValueChanged(name, newValue) {
		const { model } = this.state;
		model[name] = newValue;
		this.setState({ model });
	}

	render() {
		const { Disabled } = this.props;
		const { model, data, selectedRowIndex } = this.state;
		const isUpdate = model.Id != null;
		const { IsLoading, Alert } = this.state;

		return (
			<>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.HandleSubmitClick, Disabled: Disabled || isUpdate },
					{ Code: "Search", OnClick: this.HandleSearchClick, Disabled: Disabled || isUpdate },
					{ Code: "Update", OnClick: this.HandleUpdateClick, Disabled: Disabled || !isUpdate },
					{ Code: "Delete", OnClick: this.HandleDeleteClick, Disabled: Disabled || !isUpdate },
					{ Code: "Clear", OnClick: this.HandleClearClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardHeader>
						<GenericTitle text={"Filter Panel"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput Name="CountryId" LabelText="Country" Value={model.CountryId} ValueChanged={this.ValueChanged} Disabled={Disabled}
									Method="POST" Url="/coreapi/v1.0/Country/GetAll" Parameter={{}} DataRoot="Item"
									KeyValueMember="Id" TextValueMember={"Name"}
								/>
							</GridItem>
							<GridItem xs={4}>
								<GenericDateInput Name="StartDate" LabelText={"Start Date"}
									Value={model.StartDate}
									Utc={true}
									ValueChanged={this.ValueChanged} Disabled={Disabled} />
							</GridItem>
							<GridItem xs={4}>
								<GenericDateInput Name="EndDate" LabelText={"End Date"}
									Value={model.EndDate}
									Utc={true}
									ValueChanged={this.ValueChanged} Disabled={Disabled} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<GenericGrid
					Data={data}
					Columns={[
						{
							Header: "Country Name",
							accessor: "CountryName"
						},
						{
							Header: "Start Date",
							accessor: "StartDate",
							type: GridColumnType.Date
						},
						{
							Header: "End Date",
							accessor: "EndDate",
							type: GridColumnType.Date
						}
					]}
					RowSelected={this.RowSelected}
					SelectedIndex={selectedRowIndex}
				/>
			</>
		);
	}
}

WireTransferRestrictedCountries.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	menuId: PropTypes.number.isRequired,
	ApprovalData: PropTypes.object,
	After: PropTypes.func
};

export default withArtifex(WireTransferRestrictedCountries, {});