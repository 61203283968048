import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankFileStatus, Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class OutgoingSendBulkFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: ""
			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			dailyFileList: [],
			TransactionColumns: [],
			FileColumns: [],
			checkedValues: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			isLoading: false,
			SettlementType: "",
			sendrollbackstatus: true
		};
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Interac e-Transfer / Bulk Files ");
		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Outgoing;
		var TransactionColumns = [];
		var FileColumns = [];
		TransactionColumns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Unique Client Number",
				accessor: "ClientNumber"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType",
				Cell: row => (
					<div>{row.value == "Credit" ? "Money Send" : "Money Request"}</div>
				)
			},
			{
				Header: "Priority Type",
				accessor: "PriorityType"
			},
			{
				Header: "Pay. Name",
				accessor: "PayeeName"
			},
			{
				Header: "Pay. Email",
				accessor: "PayeeEmail"
			},
			{
				Header: "Source Wallet Number",
				accessor: "SourceAccountNumber"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode"
			},
			{
				Header: "Notification Status",
				accessor: "NotificationStatus"
			}
		];

		FileColumns = [
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File Date",
				accessor: "FileDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "File Source",
				accessor: "FileSource"
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "Send Status",
				accessor: "SendStatus"
			}
		];
		this.setState({ getTransactionByFileIdModel, model, TransactionColumns: TransactionColumns, FileColumns: FileColumns });

	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		var { SettlementType } = this.state;
		if (name == "eTransferTypeId")
			SettlementType = data.ParameterDesc;
		model[name] = newValue;
		this.setState({ model, SettlementType });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];


		if (tempModel.FileInformationId == null || tempModel.FileInformationId == undefined) {
			this.showBasicAlert("Error", "FileInformationId can not be null", "error");
			this.setState({ sendrollbackstatus: true });
		}
		else {
			if (tempModel.SendStatusParamValue == BankFileStatus.Pending ||tempModel.SendStatusParamValue == BankFileStatus.Error )
				this.setState({ fileInfoModel: tempModel, isLoading: true, rowIndex: index, sendrollbackstatus: false });
			else
				this.setState({ fileInfoModel: tempModel, isLoading: true, rowIndex: index, sendrollbackstatus: true });

			this.GetTransactionList(tempModel);
		}
	}
	IsValid = () => {
		var model = { ...this.state.model };
		if (typeof model.FromDate != "object" && model.FromDate != "" && model.FromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}
		if (typeof model.ToDate != "object" && model.ToDate != "" && model.ToDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}
		if ((typeof model.FromDate == "object" && typeof model.ToDate == "object") && model.ToDate < model.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}
		return true;
	}
	GetDailyFileList = () => {
		if (!this.IsValid())
			return;
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GeteTransferOutgoingFile",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				if (responseData.Item == null && responseData.Item.length == 0) {
					this.showBasicAlert("Info", "No items for selected search filters !!!", "warning");

					this.setState({
						transactionList: [], headerModel: {},
						dailyFileList: [], isLoading: false
					});
				}
				else {
					this.setState({ dailyFileList: responseData.Item, transactionList: [], fileInfoModel: {}, isLoading: false });
				}

			},
			this.errorCallback
		);
	}

	GetTransactionList = (item) => {

		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GeteTransferBulkFileTransactions",
			item,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				if (responseData.Item == null && responseData.Item.length == 0) {
					this.showBasicAlert("Info", "No items for selected search filters !!!", "warning");

					this.setState({ transactionList: [], headerModel: {}, isLoading: false });
				}
				else {
					this.setState({ transactionList: responseData.Item, isLoading: false });
				}

			},
			this.errorCallback
		);
	}

	Send = () => {

		if (this.state.fileInfoModel.SendStatusParamValue == BankFileStatus.Pending) {
			this.showConfirmAlert("Warning", "Are you sure to send this file ?", "warning", () => {
				if (this.state.fileInfoModel == null || this.state.fileInfoModel == undefined)
					this.showBasicAlert("Warning", "First, select a row in Daily File List.", "warning");
				else {
					if (this.state.fileInfoModel.FileInformationId == null || this.state.fileInfoModel == undefined)
						this.showBasicAlert("Warning", "Selected row has not FileInformationId value !!!", "warning");
					else {
						this.hideAlert();
						this.showLoading();
						Proxy.POST(
							"/bankapi/v1.0/ClearingSettlement/SendEtransferBulkFile",
							{ FileInformationId: this.state.fileInfoModel.FileInformationId },
							responseData => {
								if (!responseData.IsSucceeded)
									this.showBasicAlert("Error", "Send operation error : " + responseData.ErrorDescription + "!!!", "error");
								else {
									this.showBasicAlert("Success", "Send operation is successfully completed.", "success");
									this.GetDailyFileList();
									this.setState({ transactionList: [], headerModel: {}, isLoading: false, sendrollbackstatus: true });

								}
							},
							error => {
								this.showBasicAlert("Error", "Send operation error : " + error + "!!!", "error");
							}
						);
					}
				}
			});
		}
		else {
			this.showBasicAlert("Warning", "File Send Status must be Pending !!!", "warning");
		}

	}

	RollBack = () => {
		if (this.state.fileInfoModel.SendStatusParamValue == BankFileStatus.Pending) {
			this.showConfirmAlert("Warning", "Are you sure to rollback this file ?", "warning", () => {
				if (this.state.fileInfoModel == null || this.state.fileInfoModel == undefined)
					this.showBasicAlert("Warning", "First, select a row in Daily File List.", "warning");
				else {
					if (this.state.fileInfoModel.FileInformationId == null || this.state.fileInfoModel.FileInformationId == undefined)
						this.showBasicAlert("Warning", "Selected row has not FileInformationId value !!!", "warning");
					else {
						Proxy.POST(
							"/bankapi/v1.0/ClearingSettlement/EtransferRollbackBulkSendTransfersFile",
							{ FileInformationId: this.state.fileInfoModel.FileInformationId },
							responseData => {
								if (!responseData.IsSucceeded)
									this.showBasicAlert("Error", "Rollback Error : " + responseData.ErrorDescription + "!!!", "error");
								else {
									this.showBasicAlert("Success", "Rollback operation is successfully completed.", "success");
									this.setState({ transactionList: [], dailyFileList: [], isLoading: false, sendrollbackstatus: true });

									this.GetDailyFileList();
								}
							},
							error => {
								this.showBasicAlert("Error", "Rollback Error : " + error + "!!!", "error");
							}
						);
					}
				}
			});
		}
		else
			this.showBasicAlert("Warning", "File Send Status must be Pending !!!", "warning");
	}
	successCreateFile = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData != null) {
			if (responseData.SuccessCount > 0 || responseData.ErrorCount > 0) {
				var successCount = responseData.SuccessCount + "";
				var errorCount = responseData.ErrorCount + "";
				this.showBasicAlert("Success", "Success Count :" + successCount + ", Error Count : " + errorCount, "success");
			}
			else {
				this.showBasicAlert("Warning", this.state.SettlementType + "=> Settlement Type not have any transaction !!!", "warning");
			}
		}
		this.GetDailyFileList();
	}

	errorCallback = (error) => {
		this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
	}

	showBasicAlert = (title, message, type) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}
	showConfirmAlert = (title, message, type, onConfirm) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} /> });
	}
	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }
	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			},
			transactionList: [],
			dailyFileList: [],
			sendrollbackstatus: true,
			fileInfoModel: []
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, transactionList, FileColumns, TransactionColumns, sendrollbackstatus } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "Send", OnClick: this.Send, Disabled: sendrollbackstatus },
					{ Code: "Rollback", OnClick: this.RollBack, Disabled: sendrollbackstatus },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12} >
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<h4><b>File Filter</b></h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} >
										<GenericDateInput
											Name="FromDate"
											LabelText="From Date"
											Value={model.FromDate == null || undefined ? "" : model.FromDate}
											ValueChanged={this.handleChange}
											MaxDate={model.ToDate || DateHelper.GetDate()}
											IncludeTime={false} 
											Utc />
										<GenericDateInput
											Name="ToDate"
											LabelText="To Date"
											Value={model.ToDate == null || undefined ? "" : model.ToDate}
											ValueChanged={this.handleChange}
											MinDate={model.FromDate == null ? null : model.FromDate}
											MaxDate={DateHelper.GetDate()}
											IncludeTime={false} 
											Utc />

									</GridItem>

									<GridItem xs={12}>
										<GenericSelectInput
											Name="FileStatusId"
											LabelText="File Status"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											All
											Parameter={{
												ParameterCode: "BankFileStatus"
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.FileStatusId}
											ValueChanged={this.handleChange}
										/>
									</GridItem>


								</GridContainer>

							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>

				<Card className="no-radius">
					<CardHeader>
						<h4><b>File List</b></h4>
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={this.state.dailyFileList}
							Columns={FileColumns}
							RowSelected={index => {
								this.SelectedRowChange(index);
							}}
							SelectedIndex={this.state.rowIndex}
							ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
						<br />
						<h5><b>Transaction List</b></h5> <br />
						<GenericGrid
							Data={transactionList.map(d => {
								return {
									ReceiveDate: d.ReceiveDate,
									TransactionId: d.TransactionId,
									TransactionETransferId: d.TransactionETransferId,
									UniqueClientId: d.UniqueClientId,
									ClientName: d.ClientName,
									ClientNumber: d.ClientNumber,
									TransactionCode: d.TransactionCode,
									TransactionStatus: d.TransactionStatus,
									TransactionSource: d.TransactionSource,
									NotificationStatus: d.NotificationStatus,
									TransactionType: d.TransactionType,
									TransactionDate: d.TransactionDate,
									PriorityType: d.PriorityType,
									Amount: d.Amount,
									PayeeName: d.PayeeName,
									PayeeEmail: d.PayeeEmail,
									SourceAccountTransit: d.SourceAccountTransit,
									SourceAccountNumber: d.SourceAccountNumber

								};
							})}
							Columns={TransactionColumns}
							ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
					</CardBody>
				</Card>
			</div >
		);
	}
}

OutgoingSendBulkFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(OutgoingSendBulkFile, {});