import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericCheckInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant.js";

class RefundAdjustment extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			AccountId: 0,
			IsRefundAdjustmentStatus: true,
			FromDate: DateHelper.GetLocalDateAsUtc(),
			ToDate: DateHelper.GetLocalDateAsUtc(),
		};

		this.state = {
			list: [],
			model: this.initialModel,
			isLoading: false,
			selected: null,
			checkedAccounts: [],
			FilterStatus: false,
			transactionlist: [],
			vModel: {}
		};
		this.maxLength10 = { maxLength: 10 };

		this.renderItemAccount = {};

		this.parameterDestAcc = {};

		this.sortedList = [{ id: "ReferenceNumber", desc: true }];

		this.Columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc,
				style: this.RenderStyle()

			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "From Wallet Name",
				accessor: "FromAccountName",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "From Wallet Number",
				accessor: "FromAccountNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "TransactionType",
				accessor: "TransactionDesc",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Currency",
				accessor: "Currency",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
				)
			},

			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				style: this.RenderStyle(),
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Description",
				accessor: "Description",
				width: 200,
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
				)
			}

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Refund Adjustment Screen");
		this.props.setAppCenterTitle("WALLET");
	}
	IsValid = () => {
		var vmodel = { ...this.state.model };

		if (typeof vmodel.FromDate != "object" && vmodel.FromDate != "" && vmodel.FromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}

		if (typeof vmodel.ToDate != "object" && vmodel.ToDate != "" && vmodel.ToDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if ((typeof vmodel.FromDate == "object" && typeof vmodel.ToDate == "object") && vmodel.ToDate < vmodel.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be bigger than To Date");
			return false;
		}


		return true;
	}
	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		if (name == "ReferenceNumber" && value == "") {
			model[name] = undefined;
		}
		else {
			model[name] = value;

			if (name == "ReferenceNumber" && model.ReferenceNumber.length > 17 && !model.Isworkflow) {
				this.GetRefundAdjustmentTransction(model);
			}

			if (name == "CheckAllAccounts" && value == true) {
				const list = this.state.transactionlist;
				this.setState({ checkedAccounts: list });
			} else if (name == "CheckAllAccounts" && value == false) {
				this.setState({ checkedAccounts: [] });
			}

		}

		this.setState({ model });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
		this.HandleGetList();
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, list: [], transactionlist: [], checkedAccounts: [] });
	}

	GetRefundAdjustmentTransction = (model) => {
		this.setState({ isLoading: true });
		model.FilterStatus = false;
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetRefundAdjustmentTransction",
			model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error !", responseData.ErrorDescription);
					return;
				} else {
					this.setState({ transactionlist: responseData.Item || [], list: [], checkedAccounts: [] });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}


	HandleGetList = () => {

		if (!this.IsValid())
			return;

		this.setState({ isLoading: true });
		const model = { ...this.state.model };
		model.FilterStatus = true;
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetRefundAdjustmentTransction",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = (workflowId, after, accessToken) => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });

		const temp = { ...this.state.model };
		var ids = [];
		this.state.checkedAccounts.forEach(element => {
			ids.push(element.Id);
		});

		temp.TransactionIds = ids;

		Proxy.POST(
			"/bankapi/v1.0/Transaction/InsertRefundAdjustment",
			temp,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.HandleClear();
				this.HandleGetList();
				if (after) after();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId,
			accessToken
		);
	}

	Validate = () => {
		const { model } = this.state;
		const vModel = { ...this.state.vModel };

		if (model.ReferenceNumber == null || model.ReferenceNumber == "") {
			this.ShowMessage("warning", "Warning", "Reference Number cannot be null!");
			vModel.ReferenceNumber = true; this.setState({ vModel });
			return;
		}
		else {
			vModel.ReferenceNumber = false;
		}
		this.setState({ vModel });


		if (model.ReferenceNumber.length > 17) {

			if (this.state.checkedAccounts.length == 0 || this.state.checkedAccounts == null) {
				this.ShowMessage("warning", "Warning", "Transaction is not selected!");
				return;
			}

		} else {
			this.ShowMessage("warning", "Warning", "Invalid Reference Number!");
			return;
		}
		return true;
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountName} - ${d.Program.Description}`;
		return this.renderItemAccount;
	}

	GetDestinationAccountParameter = () => {
		this.parameterDestAcc.UniqueClientId = this.state.model.ToClientId;
		this.parameterDestAcc.ProgramId = this.state.model.ProgramId;
		return this.parameterDestAcc;
	}

	ShowMessage = (type = "info", title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}
	HandleCheckCustomerAccounts = (e, x) => {
		const { checkedAccounts } = this.state;
		var temp = true;

		const items = checkedAccounts.map((item, key) => {
			if (item.Id == x.Id) {
				checkedAccounts.splice(key, 1);
				temp = false;
			}
		}
		);

		if (checkedAccounts.length == 0 && temp) { checkedAccounts.push(x); }
		else if (temp) { checkedAccounts.push(x); }

		this.setState({ checkedAccounts });
	}
	RenderStyle = () => {
		return {
			column: "TransactionDesc",
			condition: "Debit",
			style: { fontWeight: "600" },
			prefix: ["+", ""]
		};
	}

	ModelFunction = () => {
		var tempworkflow = { ...this.state.model };
		var ids = [];
		this.state.checkedAccounts.forEach(element => {
			ids.push(element.Id);
		});
		tempworkflow.TransactionIds = ids;
		tempworkflow.transactionlist = this.state.transactionlist;
		return tempworkflow;

	}
	FillData = (data) => {

		var checkedAccounts = [];
		data.Isworkflow = true;
		data.TransactionIds.map((item, key) => {
			var index = data.transactionlist.findIndex(x => x.Id == item);
			if (index > -1)
				checkedAccounts.push(data.transactionlist[index]);
			return null;
		});

		data.FromDate = data.FromDate ? DateHelper.ToMoment(data.FromDate) : null;
		data.ToDate = data.ToDate ? DateHelper.ToMoment(data.ToDate) : null;

		this.setState({ model: data, transactionlist: data.transactionlist, checkedAccounts });
	}
	render() {
		const { Disabled } = this.props;
		const { alert, model, list, transactionlist, isLoading, checkedAccounts, vModel } = this.state;
		var GridDataList = transactionlist == null ? [] : transactionlist.map(d => {
			return {
				AP: (
					<div>
						<GenericCheckInput
							Grid={true}
							Disabled={Disabled}
							Value={checkedAccounts.filter(c => c.Id == d.Id).length > 0}
							ValueChanged={(e) => {
								this.HandleCheckCustomerAccounts(e, d);
							}}
						/>
					</div>
				),
				Id: d.Id,
				Amount: d.Amount,
				TransactionDesc: d.TransactionDesc,
				ReferenceNumber: d.ReferenceNumber,
				Description: d.Description,
				AccountId: d.AccountId,
				AccountName: d.AccountName,
				Currency: d.Currency,
				ToAccountName: d.ToAccountName,
				ToAccountNumber: d.ToAccountNumber,
				FromAccountName: d.FromAccountName,
				FromAccountNumber: d.FromAccountNumber,
				ProgramName: d.ProgramName,
				CurrencySymbol: d.CurrencySymbol,
				TransactionDate: d.TransactionDate
			};
		});


		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled, ModelFunction: this.ModelFunction, FillDataFromModel: this.FillData, ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Transaction">
								<CardBody>
									<GridContainer>
										<GridItem xs={3}>
											<GenericNumberInput
												Name="ReferenceNumber"
												LabelText="Reference Number"
												Value={model.ReferenceNumber}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ReferenceNumber}
												NoFormatting
												Disabled={Disabled}
											/>
										</GridItem>


										<GridItem xs={3}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.HandleChange}
												MaxDate={model.ToDate || DateHelper.GetDateTimeLocal()}
												Disabled={Disabled}
												Utc />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												MinDate={model.FromDate || DateHelper.GetDateTimeLocal()}
												MaxDate={DateHelper.GetDateTimeLocal()}
												Utc />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>


						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Refund Transaction List"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem disabled={this.props.Disabled}>
										<GenericGrid
											Data={GridDataList}
											HideButton={true}

											Columns={[

												{
													Header: "Select",
													accessor: "AP",
													sortable: false,
													filterable: false,
													width: 60,
													Footer: (
														<GenericCheckInput Disabled={Disabled} Grid={true} Name="CheckAllAccounts" LabelText="" Value={model.CheckAllAccounts || false} ValueChanged={this.HandleChange} />
													)
												},
												{
													Header: "Transaction Date",
													accessor: "TransactionDate",
													type: GridColumnType.DateTimeUtc,
													style: this.RenderStyle(),
													width: 100,

												},
												{
													Header: "ReferenceNumber",
													accessor: "ReferenceNumber",
													width: 100,
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
													)
												},
												{
													Header: "From Wallet Name",
													accessor: "FromAccountName",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
													)
												},
												{
													Header: "From Wallet Number",
													accessor: "FromAccountNumber",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
													)
												},
												{
													Header: "TransactionType",
													accessor: "TransactionDesc",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
													)
												},
												{
													Header: "Currency",
													accessor: "Currency",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
													)
												},

												{
													Header: "Amount",
													accessor: "Amount",
													type: GridColumnType.Money,
													style: this.RenderStyle(),
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Description",
													accessor: "Description",
													width: 300,
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionDesc == "Debit" ? 600 : "normal" }}>{row.value}</p>
													)
												},
											]}
										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Transaction List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											Sorted={this.sortedList} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

RefundAdjustment.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(RefundAdjustment, {});
