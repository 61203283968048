import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericGrid, GenericNumberInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class BillPaymentOutgoingFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: ""
			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			dailyFileList: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Bill Payment Outgoing File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Outgoing;
		this.setState({ getTransactionByFileIdModel, model });
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };


		model[name] = newValue;
		this.setState({ model });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.showBasicAlert("Error", "FileInformationId can not be null", "error");
		else {
			model.ClientId = tempModel.ClientId;
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetTransactionList(tempModel.Id);

		}
	}

	GetDailyFileList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bill/GetTelpayOutgoingFileList",
			this.state.model,
			this.successDailyFileList,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	successDailyFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		this.setState({
			transactionList: [], headerModel: {},
			dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item.map(x => {
				return {
					Id: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileName: x.FileName == null || undefined ? "" : x.FileName,
					FileDate: x.FileDate == null || undefined ? "" : x.FileDate,
					UniqueClientId: x.UniqueClientId == null || undefined ? "" : x.UniqueClientId,
					ClientId: x.ClientId == null || undefined ? 0 : x.ClientId,
					FileCrationNumber: x.FileCreationNumber == null || undefined ? "" : x.FileCreationNumber,
					FileSource: x.FileSource == null || undefined ? "" : x.FileSource,
					RecordCount: x.RecordCount == null || undefined ? "" : x.RecordCount,
					RejectedCount: x.RejectedCount == null || undefined ? "" : x.RejectedCount,
					SendStatus: x.SendStatus == null || undefined ? "" : x.SendStatus,
					SendStatusParamValue: x.SendStatusParamValue == null ? "" : x.SendStatusParamValue
				};
			}), isLoading: false
		});
	}

	GetTransactionList = (Id) => {
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.FileInformationId = Id;
		getTransactionByFileIdModel.ClientId = this.state.model.ClientId == null ? null : this.state.model.ClientId;
		Proxy.POST(
			"/bankapi/v1.0/Bill/GetBillOutgoingFileDetailsByFileId",
			getTransactionByFileIdModel,
			this.successTransactionListCallback,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	successTransactionListCallback = (responseData) => {
		var headerModel = { ...this.state.headerModel };
		var transactionList = { ...this.state.transactionList };
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData.Item != null) {
			if (responseData.Item.BillFileHeaderDetail != null) {
				headerModel = responseData.Item.BillFileHeaderDetail == null ? {} : responseData.Item.BillFileHeaderDetail;
				headerModel.FileDate = this.state.fileInfoModel.FileDate;
			}
			else
				headerModel = {};

			if (responseData.Item.BillFileDetail != null || responseData.Item.BillFileDetail.length > 1) {
				transactionList = responseData.Item.BillFileDetail;
			}
			else {
				transactionList = [];
			}
		}
		this.setState({ transactionList, headerModel, isLoading: false });
	}

	CreateFile = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bill/GenerateTelpayOutgoingFile",
			this.state.model,
			this.successCreateFile,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);

	}

	successCreateFile = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData != null) {
			if (responseData.SuccessCount > 0 || responseData.ErrorCount > 0) {
				var successCount = responseData.SuccessCount + "";
				var errorCount = responseData.ErrorCount + "";
				this.showBasicAlert("Success", "Success Count :" + successCount + ", Error Count : " + errorCount, "success");
				this.GetDailyFileList();
			}
			else
				this.showBasicAlert("Warning", "Not have any transaction !!!", "warning");
		}
	}


	Send = () => {

		this.showConfirmAlert("Warning", "Are you sure to send this file ?", "warning", () => {
			if (this.state.fileInfoModel == null || this.state.fileInfoModel == undefined)
				this.showBasicAlert("Warning", "First, select a row in Daily File List.", "warning");
			else {
				if (this.state.fileInfoModel.Id == null || this.state.fileInfoModel == undefined)
					this.showBasicAlert("Warning", "Selected row has not FileInformationId value !!!", "warning");
				else {
					this.hideAlert();
					this.showLoading();
					Proxy.POST(
						"/bankapi/v1.0/Bill/SendTelpayOutgoingFile",
						{ FileInformationId: this.state.fileInfoModel.Id },
						responseData => {
							if (!responseData.IsSucceeded)
								this.showBasicAlert("Error", "Send operation error : " + responseData.ErrorDescription + "!!!", "error");
							else {
								this.showBasicAlert("Success", "Send operation is successfully completed.", "success");
								this.GetDailyFileList();
							}
						},
						error => {
							this.showBasicAlert("Error", "Send operation error : " + error + "!!!", "error");
						}
					);
				}
			}
		});


	}

	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.hideAlert()} /> });
	}

	showConfirmAlert = (title, message, type, onConfirm) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} /> });
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	showLoading = () => { this.setState({ alert: null, isLoading: true }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			}
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<GridContainer>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
							{ Code: "CreateFile", OnClick: this.CreateFile, Disabled: Disabled },
							{ Code: "Send", OnClick: this.Send, Disabled: Disabled },
							{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={4}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" File Filter"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Utc
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate == null || undefined ? "" : model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false} />
								<GenericDateInput
									Utc
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate == null || undefined ? "" : model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate == null ? null : model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false} />

							</CardBody>
						</Card>

					</GridItem>
					<GridItem xs={8}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>

										<GenericDateInput
											Utc
											Name="FileDate"
											LabelMd={6}
											LabelText="File Date"
											Value={headerModel == null || undefined ? "" : headerModel.FileDate ? Formatter.FormatDateUtc(headerModel.FileDate) : ""}
											Disabled={true}
											DateFormat="DD/MMM/YY" />

										<GenericNumberInput
											Name="TotalNumber"
											LabelMd={6}
											LabelText="Total Number"
											Value={headerModel == null || undefined ? "" : headerModel.TotalNumber ? headerModel.TotalNumber : ""}
											Disabled={true}
											DecimalScale={0} />

									</GridItem>
									<GridItem xs={6}>

										<GenericDateInput
											Name="FileDate"
											LabelText="File Time"
											LabelMd={6}
											Value={headerModel == null || undefined ? "" : headerModel.FileDate ? Formatter.FormatDateTimeUtc(headerModel.FileDate) : ""}
											DateFormat="HH:mm:ss"
											IncludeTime={false}
											Utc
											Disabled={true} />

										<GenericNumberInput
											Name="TotalAmount"
											LabelText="Total Amount"
											LabelMd={6}
											Value={headerModel == null || undefined ? "" : headerModel.TotalAmount ? headerModel.TotalAmount : ""}
											Prefix="$"
											Disabled={true} />

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Bill Created File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.dailyFileList}
									Columns={[
										{
											Header: "File Name",
											accessor: "FileName"
										},
										{
											Header: "File Date",
											accessor: "FileDate",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Record Count",
											accessor: "RecordCount"
										},
										{
											Header: "Send Status",
											accessor: "SendStatus"
										}
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
								<br /> <br />
								<h5><b>Transaction List</b></h5> <br />
								<GenericGrid
									Data={this.state.transactionList}
									Columns={[
										{
											Header: "Client Name",
											accessor: "ClientName"
										},

										{
											Header: "Value Date",
											accessor: "ValueDate",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType:GridColumnType.Money
										},

										{
											Header: "Payee Wallet Number",
											accessor: "BillAccountNumber"
										},
										{
											Header: "Pay. Name",
											accessor: "PayeeName"
										},
										{
											Header: "Payee Code",
											accessor: "TransactionBillerCode"
										},
										{
											Header: "Customer Name",
											accessor: "TransactionCustomerName"
										},
										{
											Header: "Reference Number",
											accessor: "TransactionReferenceNumber"
										},
										{
											Header: "Source Wallet Number",
											accessor: "SourceAccountNumber"
										},

									]}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

BillPaymentOutgoingFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(BillPaymentOutgoingFile, {});