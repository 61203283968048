import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import { EditIcon, ProfileIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes } from "views/Constants/Constant.js";

class ActiveWalletList extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			Customer: null,
			DefaultAccount: null,
			DefaultCard: null,

			//detail
			HasDetail: false,
			FirstName: "",
			LastName: "",
			Initial: "",
			BirthDate: null,
			ApplicationType: null,
			WalletBalanceMin: null,
			WalletBalanceMax: null,
			CardBalanceMin: null,
			CardBalanceMax: null
		};

		this.initialDialogModel = {
			IsDialogOpen: false,
			SelectedCard: null
		};

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						{row.original != null && row.original.CardId != null && row.original.CardId != 0 &&
							<GridButton
								tooltip="Modify Card Status"
								Icon={EditIcon}
								OnClick={() => { this.handleShowDialog(row.original, row.index); }} />}

						{ClientHelper.IsClient() &&
							<GridButton
								tooltip="Customer Detail"
								Icon={ProfileIcon}
								OnClick={() => {
									RouteHelper.Push(this.props.history, "/CreateCustomer", "CustomerModel", {
										Id: row.original.ClientCustomerId, CallCenter: false, UniqueClientId: row.original.UniqueClientId
									});
								}} />
						}
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 80
			},
			{
				Header: "Cardholder Name",
				accessor: "CardHolderName"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Card Status",
				accessor: "StatusDescription"
			},
			{
				Header: "Available Balance",
				accessor: "WalletAvailableBalance",
				Cell: row => (Formatter.FormatMoney(row.value))
			},
			{
				Header: "Wallet Number",
				accessor: "WalletAccountNumber"
			},


		];

		this.state = {
			list: [],
			model: this.initialModel,
			lastModel: {},
			dialogModel: this.initialDialogModel,
			isLoading: false,
			vModel: {}
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Status Update");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
	getListByCustomerNo = (request) => {
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetListByCustomerNo",
			request,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					return;
				}
				const model = { ...this.state.model };
				model.DefaultCard = (responseData.Item && responseData.Item[0]) || null;
				this.setState({ model: model, lastModel: request });

			},
			(error) => {
				this.setState({ isLoading: false, lastModel: request });
				this.ShowMessage("error", "Error", error);
			});

	}
	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const dialogModel = { ...this.state.dialogModel };
		const initialModel = { ...this.initialModel };
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.DefaultAccount = newValue.Accounts[0];
				} else {
					this.getListByCustomerNo({ CardLevelCriteria: 1, CustomerId: newValue.Id });
				}


			} else {
				model.Customer = initialModel.Customer;
				model.DefaultAccount = initialModel.DefaultAccount;
				model.DefaultCard = initialModel.DefaultCard;
			}
		} else if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "Detail") {
			model.HasDetail = newValue;
		} else if (name === "ApplicationType") {
			model.ApplicationType = data;
		} else if (name === "SelectedCardStatus") {
			dialogModel.SelectedCard.Status = newValue;
		} else {
			model[name] = newValue;
		}
		this.setState({ model, dialogModel });
	};

	handleClear = () => {
		this.setState({
			model: this.initialModel,
			vModel: {},
			filterModel: {},
			selected: null,
			list: []
		});
	};
	validateSearch = () => {
		const { model, vModel } = this.state;
		var Messages = [];
		if (model.Customer == null) {
			Messages.push("Customer cannot be empty.");
			vModel.CustomerNumber = true;
		}
		else {
			vModel.CustomerNumber = false;
		}

		if (model.UniqueClientId == null) {
			vModel.UniqueClientId = true;
			Messages.push("Client cannot be empty.");
		}
		else {
			vModel.UniqueClientId = false;
		}


		if (Messages.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", Messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;
	}
	handleSearch = (lastModel) => {
		if (!this.validateSearch()) {
			return;
		}
		const model = lastModel || this.state.model;
		this.setState({ isLoading: true });
		const request = {
			ClientCustomerId: model.Customer && model.Customer.Id,
			UniqueClientId: model.UniqueClientId,
			MaxWalletBalance: model.WalletBalanceMax,
			MinWalletBalance: model.WalletBalanceMin,
			MaxCardBalance: model.CardBalanceMax,
			MinCardBalance: model.CardBalanceMin
		};
		Proxy.POST(
			"/prepaidapi/v1.0/Card/ActiveCardWalletSearch",
			request,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ list: responseData.Item || [], lastModel: model });

			},
			(error) => {
				this.setState({ isLoading: false, lastModel: model });
				this.ShowMessage("error", "Error", error);
			}
		);
	};

	handleShowDialog = (data, index) => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen = true;
		dialogModel.SelectedCard = Object.assign({}, data);
		this.setState({ dialogModel });
	};

	handleCancelDialog = () => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen = false;
		dialogModel.SelectedCard = null;
		this.setState({ dialogModel });
	};

	handleSubmitDialog = () => {
		const dialogModel = { ...this.state.dialogModel };
		const lastModel = { ...this.state.lastModel };

		this.setState({ isLoading: true });
		if (dialogModel.SelectedCard == null) {
			dialogModel.IsDialogOpen = false;
			dialogModel.SelectedCard = null;
			return;
		}

		Proxy.POST(
			"/prepaidapi/v1.0/Card/UpdateStatus",
			{
				CardId: dialogModel.SelectedCard.CardId,
				CardStatusId: dialogModel.SelectedCard.Status,
			},
			(responseData) => {
				dialogModel.IsDialogOpen = false;
				dialogModel.SelectedCard = null;
				if (!responseData.IsSucceeded) {
					this.setState({ dialogModel, isLoading: false }, () => {
						this.props.showMessage("error", "ERROR", responseData.ErrorDescription);
					});
					return;
				}
				this.setState({ dialogModel, isLoading: false }, () => {
					this.handleSearch(this.state.model);
					this.ShowMessage("success", "Updated successfully!");
				});
			},
			(error) => {
				this.setState({ isLoading: false }, () => {
					this.ShowMessage(
						"error",
						"An error occurred while requesting data",
						error.message
					);
				});
			}
		);
	};

	render() {
		const { Disabled } = this.props;
		const { model, dialogModel, list, isLoading, alert, vModel } = this.state;
		const dialogBorderStyle = {
			borderColor: primaryColor,
			borderWidth: "7px",
			borderStyle: "Solid",
			overflow: "hidden"
		};
		return (
			<div>
				<LoadingComponent Show={isLoading} />


				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled },
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{
														UniqueClientId: ClientHelper.IsClient()
															? ClientHelper.GetClientId()
															: undefined
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
													Required
													IsInvalid={vModel.UniqueClientId}
												/>
											</GridItem>
											<GridItem>
												<CustomerComponent
													LabelMd={4}
													CustomerNumber={
														model.Customer === null
															? null
															: model.Customer.CustomerNumber
													}
													UniqueClientId={model.UniqueClientId}
													FillCallback={customer => {
														this.handleChange("Customer", customer);
													}}
													Programs={[ProgramCodes.Prepaid]}
													ShowCustomerProgram={true}
													Required
													IsInvalid={vModel.CustomerNumber}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<GridItem>
												<GenericNumberInput
													Name="WalletBalanceMin"
													LabelText="Wallet Balance(Min)"
													LabelMd={6}
													Prefix={"$"}
													MaxLength={30}
													Value={model.WalletBalanceMin}
													ValueChanged={this.handleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericNumberInput
													Name="WalletBalanceMax"
													LabelText="Wallet Balance(Max)"
													LabelMd={6}
													Prefix={"$"}
													MaxLength={30}
													Value={model.WalletBalanceMax}
													ValueChanged={this.handleChange}
												/>
											</GridItem>

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Dialog
							fullWidth={true}
							maxWidth={"sm"}
							open={dialogModel.IsDialogOpen}
							onBackdropClick={this.handleCancelDialog}
							keepMounted={true}
						>
							<DialogContent style={dialogBorderStyle}>
								<DialogTitle style={{ background: primaryColor }}>
									<GridContainer justify="space-between" alignItems="center">
										<GridItem>
											<GenericLabel
												FontSize="16px"
												TextColor="white"
												Text="Change Card Status"
												Bold={true}
											/>
										</GridItem>
									</GridContainer>
								</DialogTitle>
								<GridContainer style={{ "z-index": "2", "position": "fixed", "width": "378px", "padding": "5px" }}>
									<GridItem xs={12}>
										<GenericSelectInput
											Name="SelectedCardStatus"
											LabelText="Card Status"
											Method="POST"
											Url="/prepaidapi/v1.0/CardStatus/GetAll"
											Parameter={{ Criteria: 0 }}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.Description}`}
											Value={dialogModel.SelectedCard == null ? null : dialogModel.SelectedCard.Status}
											ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
								<DialogActions>
									<Button size="sm" onClick={this.handleSubmitDialog}>Submit</Button>
									<Button size="sm" onClick={this.handleCancelDialog}>Cancel</Button>
								</DialogActions>
							</DialogContent>
						</Dialog>

					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ActiveWalletList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(ActiveWalletList, {});
