import { DialogContent, DialogTitle } from "@material-ui/core";
import { CheckCircleOutline as CheckImg } from "@material-ui/icons";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import { PropTypes } from "prop-types";
import React from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton";

class NotificationSettings extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined
		};

		this.state = {
			list: [],
			model: this.initialModel,
			vModel: {},
			countModel: {},
			selected: {
				EmailMapList: [],
				PhoneMapList: [],
				UserMapList: []
			},
			isLoading: false,
			isSmsDialogOpen: false,
			isEmailDialogOpen: false,
			isPortalDialogOpen: false
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.emptyObject = {};

		this.sortName = { Member: "Name" };
		this.sortListName = [{ id: "CustomerName" }];
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Client Notification Settings");

		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("NOTIFICATIONS");
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: { EmailMapList: [], PhoneMapList: [], UserMapList: [] } });
	}

	HandleChangeFilter = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;

		this.setState({ model }, () => {
			if (name === "UniqueClientId" && value > 0) {
				this.HandleSearch();
			}
		});
	}

	HandleChange = (n, v, x) => {
		let { list } = this.state;
		if (list.includes(x)) {
			list.find(c => c === x)[n] = v;
			this.setState({ list });
		}
	}

	HandleChangeEmail = (n, v, x) => {
		let { list, selected } = this.state;
		var sel = list.find(c => c === selected);
		if (sel.EmailMapList.includes(x)) {
			sel.EmailMapList.find(c => c === x)[n] = v;
		}
		this.setState({ list });
	}

	HandleChangePhone = (n, v, x) => {
		let { list, selected } = this.state;
		var sel = list.find(c => c === selected);
		if (sel.PhoneMapList.includes(x)) {
			sel.PhoneMapList.find(c => c === x)[n] = v;
		}
		this.setState({ list });
	}

	HandleChangeUser = (n, v, x) => {
		let { list, selected } = this.state;
		var sel = list.find(c => c === selected);
		if (sel.UserMapList.includes(x)) {
			sel.UserMapList.find(c => c === x)[n] = v;
		}
		this.setState({ list });
	}

	HandleSearch = () => {
		if (!this.ValidateSearch())
			return;

		this.setState({ isLoading: true, list: [] });
		Proxy.POST(
			"/bankapi/v1.0/CustomerEventNotification/GetAll",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		const { model } = this.state;

		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerEventNotification/InsertOrUpdate",
			this.state.list.map(d => {
				return {
					Id: d.Id,
					IsRecordValid: d.IsRecordValid,
					EventNotificationId: d.EventNotificationId,
					UniqueClientId: model.UniqueClientId,
					SendSms: d.SendSms,
					SendEmail: d.SendEmail,
					SendApplication: d.SendApplication,
					EmailMapList: d.EmailMapList.map(q => {
						return {
							Id: q.Id,
							IsRecordValid: q.IsRecordValid,
							Email: q.Email
						};
					}),
					PhoneMapList: d.PhoneMapList.map(q => {
						return {
							Id: q.Id,
							IsRecordValid: q.IsRecordValid,
							PhoneId: q.PhoneId
						};
					}),
					UserMapList: d.UserMapList.map(q => {
						return {
							Id: q.Id,
							IsRecordValid: q.IsRecordValid,
							UserId: q.UserId
						};
					})
				};
			}),
			responseData => {
				this.setState({ isLoading: false, list: [] });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Operation is successfully!");
				this.HandleSearch();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	Validate = () => {
		var ErrorList = [];
		const { model, vModel, list } = this.state;

		var notExistChannelList = list.filter(x => x.IsRecordValid && x.SendSms == false && x.SendEmail == false && x.SendApplication == false);
		for (let notifications of notExistChannelList) {
			this.ShowMessage("warning", "Invalid", `Please select the one notification channel at least for ${notifications.EventNotification.NotificationTemplateType.Description}!`);
			return false;
		}

		var smsList = list.filter(x => x.SendSms == true);
		for (let notifications of smsList) {
			if (notifications.PhoneMapList.filter(x => x.IsRecordValid == true).length == 0) {
				this.ShowMessage("warning", "Invalid", `Please select the one phone at least for ${notifications.EventNotification.NotificationTemplateType.Description}!`);
				return false;
			}
		}

		var emailList = list.filter(x => x.SendEmail == true);
		for (let notifications of emailList) {
			if (notifications.EmailMapList.filter(x => x.IsRecordValid == true).length == 0) {
				this.ShowMessage("warning", "Invalid", `Please select the one e-mail at least for ${notifications.EventNotification.NotificationTemplateType.Description}!`);
				return false;
			}
		}

		var userList = list.filter(x => x.SendApplication == true);
		for (let notifications of userList) {
			if (notifications.UserMapList.filter(x => x.IsRecordValid == true).length == 0) {
				this.ShowMessage("warning", "Invalid", `Please select the one user at least for ${notifications.EventNotification.NotificationTemplateType.Description}!`);
				return false;
			}
		}

		if (!this.isClient && (model.UniqueClientId == null || model.UniqueClientId == 0)) {
			vModel.UniqueClientId = true;
			ErrorList.push("Client must be selected!");
		} else {
			vModel.UniqueClientId = false;
		}

		this.setState({ vModel });

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	ValidateSearch = () => {
		var ErrorList = [];
		const { model, vModel } = this.state;

		if (!this.isClient && (model.UniqueClientId == null || model.UniqueClientId == 0)) {
			vModel.UniqueClientId = true;
			ErrorList.push("Client must be selected!");
		} else {
			vModel.UniqueClientId = false;
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, vModel, list, selected, isSmsDialogOpen, isEmailDialogOpen, isPortalDialogOpen } = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={isSmsDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Notification SMS Phone" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={12} style={{ marginTop: 10 }}>
								<ReactTable
									className="-striped -highlight"
									data={selected.PhoneMapList}
									filterable={false}
									columns={[
										{
											Header: "SMS Phone",
											accessor: "IsRecordValid",
											Cell: row => {
												let foundRecord = selected.PhoneMapList.find(x => x == row.original);
												return (row.original != null &&
													<div>
														<GenericCheckInput
															Grid
															Name="IsRecordValid"
															LabelText={`+${row.original.Phone.PhoneCountryCode && row.original.Phone.PhoneCountryCode.Code} ${row.original.Phone.PhoneNumber}`}
															Value={foundRecord && foundRecord.IsRecordValid}
															ValueChanged={(n, v) => this.HandleChangePhone(n, v, row.original)} />
													</div>
												);
											}
										}
									]}
									defaultPageSize={50}
									showPaginationTop={false}
									showPaginationBottom={Object.keys(selected.PhoneMapList || []).length > 50}
									minRows={2} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={() => this.setState({ isSmsDialogOpen: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isEmailDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Notification E-Mail" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={12} style={{ marginTop: 10 }}>
								<ReactTable
									className="-striped -highlight"
									data={selected.EmailMapList}
									filterable={false}
									columns={[
										{
											Header: "E-Mail",
											accessor: "IsRecordValid",
											Cell: row => {
												let foundRecord = selected.EmailMapList.find(x => x == row.original);
												return (row.original != null &&
													<div>
														<GenericCheckInput
															Grid
															Name="IsRecordValid"
															LabelText={row.original.Email}
															Value={foundRecord && foundRecord.IsRecordValid}
															ValueChanged={(n, v) => this.HandleChangeEmail(n, v, row.original)} />
													</div>
												);
											}
										}
									]}
									defaultPageSize={50}
									showPaginationTop={false}
									showPaginationBottom={Object.keys(selected.EmailMapList || []).length > 50}
									minRows={2} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={() => this.setState({ isEmailDialogOpen: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isPortalDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Notification User" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={12} style={{ marginTop: 10 }}>
								<ReactTable
									className="-striped -highlight"
									data={selected.UserMapList}
									filterable={false}
									columns={[
										{
											Header: "User Name",
											accessor: "IsRecordValid",
											Cell: row => {
												let foundRecord = selected.UserMapList.find(x => x == row.original);
												return (row.original != null &&
													<div>
														<GenericCheckInput
															Grid
															Name="IsRecordValid"
															LabelText={row.original.User.UserName}
															Value={foundRecord && foundRecord.IsRecordValid}
															ValueChanged={(n, v) => this.HandleChangeUser(n, v, row.original)} />
													</div>
												);
											}
										}
									]}
									defaultPageSize={50}
									showPaginationTop={false}
									showPaginationBottom={Object.keys(selected.UserMapList || []).length > 50}
									minRows={2} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={() => this.setState({ isPortalDialogOpen: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GridContainer spacing={16}>
					<GridItem md={12} xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												CanClear
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChangeFilter}
												Required
												IsInvalid={vModel.UniqueClientId}
												Disabled={this.isClient}
												DefaultIndex={this.isClient ? 0 : -1} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="ProgramId"
												LabelText="Program"
												Method="GET"
												Url="/bankapi/v1.0/Bank/ValidBankProgramsNotification"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.ProgramId}
												ValueChanged={this.HandleChangeFilter}
												Sorted={{ Member: "Description" }} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="PhoneId"
												LabelText="Phone"
												Method="GET"
												Url={"/bankapi/v1.0/CustomerKeyContact/GetAllPhoneForNotification"}
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={x => `+${x.PhoneCountryCode.Code} ${x.PhoneNumber}`}
												Value={model.PhoneId}
												ValueChanged={this.HandleChangeFilter} />
										</GridItem>
										<GridItem xs={3}>
											<GenericEmailInput
												inputProps={{ maxLength: 64 }}
												Name="Email"
												LabelText="E-Mail"
												Value={model.Email}
												ValueChanged={this.HandleChangeFilter} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer spacing={16} style={{ marginTop: "24px" }}>
									<GridItem md={12} xs={12}>
										<GridContainer direction="row" alignItems="stretch" justify="space-around">
											<GridItem xs={3}>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Notification"></GenericLabel>
												</div>
												{list.map(field => {
													return (
														<div key={field.Id} style={{ margin: "0 auto" }}>
															<GenericCheckInput
																Name="IsRecordValid"
																LabelText={`${field.EventNotification.NotificationTemplateType.Description} (${field.EventNotification.ProgramType.Description})`}
																Value={field.IsRecordValid}
																ValueChanged={(n, v) => this.HandleChange(n, v, field)} />
														</div>
													);
												})}
											</GridItem>
											<GridItem xs={2}>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Notification Channel"></GenericLabel>
												</div>
												{list.map(field => {
													return (
														<div key={field.Id} style={{ textAlign: "center", opacity: !field.IsRecordValid ? 0.4 : 1 }}>
															<GenericCheckInput
																Name="SendSms"
																LabelText="SMS"
																Value={field.SendSms}
																ValueChanged={(n, v) => this.HandleChange(n, v, field)}
																Disabled={!field.EventNotification.SendSms || !field.IsRecordValid} />
															<GenericCheckInput
																Name="SendEmail"
																LabelText="E-Mail"
																Value={field.SendEmail}
																ValueChanged={(n, v) => this.HandleChange(n, v, field)}
																Disabled={!field.EventNotification.SendEmail || !field.IsRecordValid} />
															<GenericCheckInput
																Name="SendApplication"
																LabelText="Portal"
																Value={field.SendApplication}
																ValueChanged={(n, v) => this.HandleChange(n, v, field)}
																Disabled={!field.EventNotification.SendApplication || !field.IsRecordValid} />
														</div>
													);
												})}
											</GridItem>
											<GridItem xs={1}>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Delivery Type"></GenericLabel>
												</div>
												{list.map(field => {
													return (
														<div key={field.Id} style={{ textAlign: "center", paddingTop: 5, paddingBottom: 5, opacity: !field.IsRecordValid ? 0.4 : 1 }}>
															<ToolbarButton
																color='primary'
																justIcon simple
																size="sm"
																tooltip={"SMS"}
																onClick={() => this.setState({ isSmsDialogOpen: true, selected: field })}
																disabled={!field.IsRecordValid}>
																<CheckImg nativeColor="black" />
															</ToolbarButton>
															<ToolbarButton
																color='primary'
																justIcon simple
																size="sm"
																tooltip={"E-Mail"}
																onClick={() => this.setState({ isEmailDialogOpen: true, selected: field })}
																disabled={!field.IsRecordValid}>
																<CheckImg nativeColor="black" />
															</ToolbarButton>
															<ToolbarButton
																color='primary'
																justIcon simple
																size="sm"
																tooltip={"Portal"}
																onClick={() => this.setState({ isPortalDialogOpen: true, selected: field })}
																disabled={!field.IsRecordValid}>
																<CheckImg nativeColor="black" />
															</ToolbarButton>
														</div>
													);
												})}
											</GridItem>
											<GridItem xs={1}>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Fee SMS"></GenericLabel>
												</div>
												{list.map(field => {
													return (
														<div key={field.Id} style={{ textAlign: "center", paddingTop: 4, paddingBottom: 4 }}>
															<GenericLabel FontSize="14px" TextColor="black" Text={Formatter.FormatMoney(field.SMSFee)}></GenericLabel>
														</div>
													);
												})}
											</GridItem>
											<GridItem xs={1}>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Fee E-Mail"></GenericLabel>
												</div>
												{list.map(field => {
													return (
														<div key={field.Id} style={{ textAlign: "center", paddingTop: 4, paddingBottom: 4 }}>
															<GenericLabel FontSize="14px" TextColor="black" Text={Formatter.FormatMoney(field.EmailFee)}></GenericLabel>
														</div>
													);
												})}
											</GridItem>
											<GridItem xs={1}>
												<div style={{ marginBottom: "8px", textAlign: "center" }}>
													<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Fee Portal"></GenericLabel>
												</div>
												{list.map(field => {
													return (
														<div key={field.Id} style={{ textAlign: "center", paddingTop: 4, paddingBottom: 4 }}>
															<GenericLabel FontSize="14px" TextColor="black" Text={Formatter.FormatMoney(field.ApplicationFee)}></GenericLabel>
														</div>
													);
												})}
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

NotificationSettings.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default NotificationSettings;
