import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { AccountBalance, CheckBox, ExpandMore, List, Timeline } from "@material-ui/icons";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import { Line as LineChart, Pie as PieChart } from "react-chartjs-2";
import ReactTable from "react-table";
import { GenericAlert, GenericLabel, GenericNumberInput, GenericSelectInput, GenericGrid } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DateFilter, ProgramCodes, GridColumnType, EndOfDayPageFilter } from "views/Constants/Constant";
import { Doughnut as DoughnutChart, Chart } from "react-chartjs-2";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react";

class Portal extends React.Component {

	constructor(props) {
		super(props);

		this.chartColors = {
			red: "rgb(255, 99, 132)",
			orange: "rgb(255, 159, 64)",
			yellow: "rgb(255, 205, 86)",
			green: "rgb(75, 192, 192)",
			blue: "rgb(54, 162, 235)",
			purple: "rgb(153, 102, 255)",
			grey: "rgb(201, 203, 207)"
		};

		this.columns = [
			{
				Header: "Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTime,
				style: this.RenderStyle()
			},

			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				style: this.BalanceRenderStyle(),
			},
			{
				Header: "Program Type",
				accessor: "ChannelType",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Received/Sent",
				accessor: "TransactionType",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)

			},
			{
				Header: "Currency",
				accessor: "SourceCurrencyCode",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "From Wallet No",
				accessor: "SourceAccountNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "To Wallet No",
				accessor: "DestinationAccountNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Reference No",
				accessor: "ReferenceNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
		];
		const chartGradientColors = [
			{ begin: "#F57F29", end: "#339900" },
			{ begin: "#F57F29", end: "#339900" },
			{ begin: "#F57F29", end: "#339900" },
			{ begin: "#ceae18", end: "#74A764" },
			{ begin: "#e6ab05", end: "#74A764" },
			{ begin: "#f1a900", end: "#74A764" },
			{ begin: "#ffa600", end: "#C9CBCF" }
		];
		this.txnCountChartData = {
			labels: ["Transactions"],
			datasets: [{
				data: [100],
				backgroundColor: "rgb(255, 99, 132)",
				hoverBackgroundColor: "rgb(255, 99, 132)"
			}],
			text: "\t $ 0"
		};
		this.typeChartData = {
			labels: ["Transactions"],
			datasets: [{
				data: [100],
				backgroundColor: "rgb(255, 99, 132)",
				hoverBackgroundColor: "rgb(255, 99, 132)"
			}],
			text: "\t  0"
		};
		this.doughnutHeight = 280;
		this.doughnutWidth = 240;
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.sortedName = { Member: "Name" };
		this.state = {
			gridUniqueClientId: {},
			totalMonthly: {},
			amountOfCreditTransaction: { ...this.txnCountChartData },
			numberOfDebitTransaction: { ...this.typeChartData },
			numberOfCreditTransaction: { ...this.typeChartData },
			amountOfDebitTransaction: { ...this.txnCountChartData },
			txnCountChartData1: JSON.parse(JSON.stringify(this.txnCountChartData)),
			panel1Active: false,
			panel2Active: false,
			accountModel: { ...this.txnCountChartData },
			totalBalance: { ...this.txnCountChartData },
			pendingbalance: { ...this.txnCountChartData },
			limitModel: {},
			transactionModel: {},
			transactions: [],
			chartModel: {
				DateFilterId: 2
			},
			chartListModel: {
				DateFilterId: 2
			},
			chartList: [],
			settlementModel: {
				DateFilterId: 0
			},
			settlementSummaryList: [],
			lineChartData: {
				labels: [],
				datasets: []
			},
			pieChartData: {
				labels: [],
				datasets: []
			},
			isFetchingAccount: false,
			isFetchingLimit: false,
			isFetchingTransactionDetail: false,
			isFetchingSettlementSummary: false,
			isFetchingLineChart: false,
			isFetchingPieChart: false,
			isLoading: false,
			selectedSettled: null,
			promisePool: []
		};

		this.lineChartOptions = {
			responsive: true,
			title: {
				display: false,
				text: "Chart.js Line Chart"
			},
			hover: {
				mode: "dataset"
			},
			tooltips: {
				displayColors: true,
				backgroundColor: "black",
				enabled: true,
				mode: "label",
				bodyFontSize: 13,
				// bodyFontFamily: "Gamja Flower",
				bodyFontColor: "white",
				yPadding: 5,
				xPadding: 15,
				cornerRadius: 4,
				bodyFontStyle: "bold"
			},
			legend: {
				display: true,
				position: "bottom",
				fullWidth: true,
			},
			layout: {
				padding: {
					left: 8,
					right: 16,
					top: 36,
					bottom: 0
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						scaleLabel: {
							show: true
						}
					}
				],
				yAxes: [
					{
						display: true,
						scaleLabel: {
							show: true,
							labelString: "Value"
						},
						ticks: {
							beginAtZero: true,
							min: 0
						}
					}
				]
			}
		};
		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			},
			//tooltips: { enabled: true }
		};
		this.pieChartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				horizontalAlign: "left",
				verticalAlign: "center",
				labels: {
					boxWidth: 10
				}
			},
			layout: {
				padding: {
					left: 24
				}
			}
		};

		this.FromDate = DateHelper.GetDate();
		this.ToDate = DateHelper.GetDate().add(1, "days");

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.emptyObject = {};
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}
	RenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["+", ""]
		};
	}
	BalanceRenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["", ""]
		};
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: false });
	}

	componentDidMount() {
		//this.getAllProgramTransactionSum();

		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("MAIN PORTAL");
		this.HandleGetAccount(-1);
		this.HandleGetSettlementSummaryList(this.state.settlementModel);
		// if (process.env.NODE_ENV !== "development") {
		// 	this.HandleChangeSettlement("DateFilterId", 2);
		// 	this.HandleGetAccount(this.state.accountModel);
		// 	this.HandleGetLimit(this.state.limitModel);
		// 	this.HandleGetTransactionDetail(this.state.transactionModel);
		// }
		const accountModel = { ...this.state.accountModel };
		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);

			}
		});
		this.setState({ loadingCompleted: true });
	}

	HandleChangeFilterPanel = panel => (event, expanded) => {
		const { lineChartData, chartList } = this.state;
		if (panel == "panel1") {
			this.setState({ panel1Active: expanded ? true : false });
			if (expanded && lineChartData.datasets.length == 0) {
				this.HandleGetChart(this.state.chartModel);
			}
		} else {
			this.setState({ panel2Active: expanded ? true : false });
			if (expanded && chartList.length == 0) {
				this.HandleGetChartList(this.state.chartListModel);
			}
		}
	};

	HandleChangeAccount = (name, value) => {
		const accountModel = { ...this.state.accountModel };
		accountModel[name] = value;
		this.setState({ accountModel });
		if (name == "AccountId") {
			this.HandleGetAccount(accountModel);
		}
		if (name == "UniqueClientId") {
			this.HandleGetAccount(accountModel);
		}
	}

	HandleChangeLimit = (name, value) => {
		const limitModel = { ...this.state.limitModel };
		limitModel[name] = value;
		this.setState({ limitModel });
		if (name == "ProgramId") {
			this.HandleGetLimit(limitModel);
		}
	}

	HandleChangeTransaction = (name, value) => {
		const transactionModel = { ...this.state.transactionModel };
		transactionModel[name] = value;
		this.setState({ transactionModel });
		if (name == "StatusId") {
			this.HandleGetTransactionDetail(transactionModel);
		}
	}
	HandleChangeGrid = (name, value) => {
		const gridUniqueClientId = { ...this.state.gridUniqueClientId };
		gridUniqueClientId[name] = value;
		this.setState({ gridUniqueClientId });
		this.handleSearch();
	}
	HandleChangeSettlement = (name, value) => {
		const settlementModel = { ...this.state.settlementModel };
		settlementModel[name] = value;
		this.setState({ settlementModel });
		this.HandleGetSettlementSummaryList(settlementModel);
	}
	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return gradientList;

	}
	HandleChangeChart = (name, value) => {
		const chartModel = { ...this.state.chartModel };
		chartModel[name] = value;
		this.setState({ chartModel });
		if (name == "ProgramId" || name == "StoreId" || name == "DateFilterId") {
			this.HandleGetChart(chartModel);
		}
	}

	HandleChangeChartList = (name, value) => {
		const chartListModel = { ...this.state.chartListModel };
		chartListModel[name] = value;
		this.setState({ chartListModel });
		if (name == "StatusId" || name == "StoreId" || name == "DateFilterId") {
			this.HandleGetChartList(chartListModel);
		}
	}

	HandleGetAccount = (model) => {
		var URL = "/bankapi/v1.0/Portal/GetAccountForClient";
		if (this.isBackOffice) {
			URL = "/bankapi/v1.0/Portal/GetAccountForBackOffice";
		}

		Proxy.POST(
			URL,
			{
				AccountId: model.AccountId,
				UniqueClientId: model.UniqueClientId
			},
			this.SuccessAccountCallback,
			this.ErrorAccountCallback
		);
	}

	SuccessAccountCallback = (responseData) => {
		var accountModel = { ...this.state.accountModel };
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			var totalBalance = this.GenerateDoughnutData(responseData.Item.TotalBalance, false, responseData.Item.CountTotal, (!this.isBackOffice ? "Total Balance" : "All Client Balance"));
			var pendingbalance = this.GenerateDoughnutData(responseData.Item.PendingBalance, false, responseData.Item.CountPending, (!this.isBackOffice ? "Pending Balance" : "All Clients Pending Balance"));
			// accountModel.Balance = responseData.Item.Balance;
			// accountModel.TotalBalance = responseData.Item.TotalBalance;
			// accountModel.SecureBalance = responseData.Item.SecureBalance;
			// accountModel.SubAccountBalance = responseData.Item.SubAccountBalance;
			this.setState({ accountModel, totalBalance, pendingbalance });
		}
	}
	GenerateDoughnutData = (statisticData, bool, count, label) => {
		// var arrayData = [];
		// var arrayLabel = [];
		// var arrayColor = [];

		// var colorName = "";
		// var newColor = "";

		// var colorNames = Object.keys(this.chartColors);
		// colorName = colorNames[0];
		// newColor = this.chartColors[colorName];
		// arrayColor.push(newColor);
		var trxChartObj = {
			labels: [label],
			datasets: [{
				data: [count == 0 ? 1 : count],
				// hoverBackgroundColor: arrayColor,
				// backgroundColor: arrayColor
			}],
			text: statisticData != null ? this.GetChartText(bool, statisticData) : bool ? 0 : "0 \t $ "
		};
		return trxChartObj;
	}
	GetChartText = (item1, item2) => {
		return item1 ? item2.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : " \t $ " + item2
				.toString()
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}


	ErrorAccountCallback = (error) => {
		this.ShowMessage("error", "Error", error);
	}

	HandleGetLimit = (model) => {
		var URL = "/bankapi/v1.0/Portal/GetAccountLimitForClient";
		if (this.isBackOffice) {
			URL = "/bankapi/v1.0/Portal/GetAccountLimitForBackOffice";
		}
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				URL,
				{
					ProgramId: model.ProgramId
				},
				this.SuccessLimitCallback,
				this.ErrorLimitCallback
			));
	}

	SuccessLimitCallback = (responseData) => {
		var limitModel = { ...this.state.limitModel };
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			limitModel.AvailableLimit = responseData.Item.AvailableLimit;
			limitModel.TotalAvailableLimit = responseData.Item.TotalAvailableLimit;
			this.setState({ limitModel });
		}
	}

	ErrorLimitCallback = (error) => {
		this.ShowMessage("error", "Error", error);
	}

	HandleGetTransactionDetail = (model) => {
		var URL = "/bankapi/v1.0/Portal/GetTransactionDetailForClient";
		if (this.isBackOffice) {
			URL = "/bankapi/v1.0/Portal/GetTransactionDetailForBackOffice";
		}
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				URL,
				{
					StatusId: model.StatusId
				},
				this.SuccessTransactionDetailCallback,
				this.ErrorTransactionDetailCallback
			));
	}

	SuccessTransactionDetailCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ transactions: responseData.Item });
		}
	}

	ErrorTransactionDetailCallback = (error) => {
		this.ShowMessage("error", "Error", error);
	}

	handleSearch = async () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	getAllProgramTransactionSum = async () => {

		const { ExecuteApiPost } = this.props;
		var totalMonthly = { ...this.state.totalMonthly };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/Portal/AllProgramCountTransactionsForBackOffice", {}, null, null, null, null, null);
		if (responseData != null) {

			totalMonthly.Eft = responseData.EFT;
			totalMonthly.Etransfer = responseData.Etransfer;
			totalMonthly.DDT = responseData.DDT;
			totalMonthly.Prepaid = responseData.Prepaid;
			totalMonthly.Lending = responseData.Lending;
			totalMonthly.Wire = responseData.Wire;
			totalMonthly.BillPayment = responseData.BillPayment;
			totalMonthly.MoneyTransfer = responseData.MoneyTransfer;
			this.setState({ isLoading: false, totalMonthly });
		}
		else {
			this.setState({ isLoading: false, totalMonthly });
		}
	}


	HandleGetSettlementSummaryList = (model) => {
		var URL = "/bankapi/v1.0/Portal/GetSettlementSummaryForClient";
		if (this.isBackOffice) {
			URL = "/bankapi/v1.0/Portal/GetSettlementSummaryForBackOffice";
		}
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				URL,
				{
					DateFilterId: model.DateFilterId,
					ProgramId: model.ProgramId,
					UniqueClientId: model.UniqueClientId
				},
				this.SuccessSettlementSummaryListCallback,
				this.ErrorSettlementSummaryListCallback
			));
	}

	SuccessSettlementSummaryListCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		var totalMonthly = { ...this.state.totalMonthly };
		if (responseData.Item !== null) {
			var numberOfDebitTransaction = this.GenerateDoughnutData(responseData.Item?.NumberOfDebitTransaction, true, 0, "Number Of Debit");
			var numberOfCreditTransaction = this.GenerateDoughnutData(responseData.Item?.NumberOfCreditTransaction, true, 0, "Number Of Credit");
			var amountOfDebitTransaction = this.GenerateDoughnutData(responseData.Item?.AmountOfDebitTransaction, false, 0, "Amount Of Debit");
			var amountOfCreditTransaction = this.GenerateDoughnutData(responseData.Item?.AmountOfCreditTransaction, false, 0, "Amount Of Credit");
			totalMonthly.Eft = responseData.Item?.EFT;
			totalMonthly.Etransfer = responseData.Item?.Etransfer;
			totalMonthly.DDT = responseData.Item?.DDT;
			totalMonthly.Prepaid = responseData.Item?.Prepaid;
			totalMonthly.Lending = responseData.Item?.Lending;
			totalMonthly.Wire = responseData.Item?.Wire;
			totalMonthly.BillPayment = responseData.Item?.BillPayment;
			totalMonthly.MoneyTransfer = responseData.Item?.MoneyTransfer;
			this.setState({ numberOfDebitTransaction, numberOfCreditTransaction, amountOfDebitTransaction, amountOfCreditTransaction, totalMonthly });
		}
	}

	ErrorSettlementSummaryListCallback = (error) => {
		this.ShowMessage("error", "Error", error);
	}

	HandleGetChart = (model) => {
		var URL = "/bankapi/v1.0/Portal/GetChartTransactionsForClient";
		if (this.isBackOffice) {
			URL = "/bankapi/v1.0/Portal/GetChartTransactionsForBackOffice";
		}
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				URL,
				{
					ProgramId: model.ProgramId,
					StoreId: model.StoreId,
					DateFilterId: model.DateFilterId
				},
				this.SuccessChartCallback,
				this.ErrorChartCallback
			));
	}

	SuccessChartCallback = (responseData) => {
		const { lineChartData } = this.state;
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		if (responseData.Item !== null) {
			var obj = [];
			var colorNames = Object.keys(this.chartColors);
			var i = 0;
			for (var index in responseData.Item) {
				var item = responseData.Item[index];
				var colorName = colorNames[i % colorNames.length];
				var newColor = this.chartColors[colorName];
				obj.push(
					{
						label: index,
						fill: false,
						backgroundColor: newColor,
						borderColor: newColor,
						data: item
					}
				);
				i++;
			}
			lineChartData.datasets = obj;
			this.setState({ lineChartData });
		}
	}

	ErrorChartCallback = (error) => {
		this.ShowMessage("error", "Error", error);
	}

	HandleGetChartList = (model) => {
		var URL = "/bankapi/v1.0/Portal/GetChartListTransactionsForClient";
		if (this.isBackOffice) {
			URL = "/bankapi/v1.0/Portal/GetChartListTransactionsForBackOffice";
		}
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				URL,
				{
					StatusId: model.StatusId,
					StoreId: model.StoreId,
					DateFilterId: model.DateFilterId
				},
				this.SuccessChartListCallback,
				this.ErrorChartListCallback
			)
		);
	}

	SuccessChartListCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			var pieChartObj = {
				labels: [],
				datasets: []
			};
			var obj = {
				data: [],
				backgroundColor: [],
				hoverBackgroundColor: []
			};
			var colorNames = Object.keys(this.chartColors);
			var i = 0;
			for (var item of responseData.Item.PieChartData) {
				var colorName = colorNames[i % colorNames.length];
				var newColor = this.chartColors[colorName];
				pieChartObj.labels.push(item["ProgramName"]);
				obj.data.push(item["TransactionCount"]);
				obj.backgroundColor.push(newColor);
				obj.hoverBackgroundColor.push(newColor);
				pieChartObj.datasets = [obj];
				i++;
			}
			this.setState({ chartList: responseData.Item.TransactionList, pieChartData: pieChartObj });
		}
	}

	ErrorChartListCallback = (error) => {
		this.ShowMessage("error", "Error", error);
	}

	ReturnRoutes = (ProgramCode) => {
		var arr = [{
			ProgramCode: ProgramCodes.Bank_Programs_EFT,
			Path: "/EFTTransactionSearch",
			Name: "EFTTransactionSearch_Date"
		},
		{
			ProgramCode: ProgramCodes.Bank_Programs_e_Transfer,
			Path: "/eTransferTransactionSearch",
			Name: "eTransferTransactionSearch_Date"
		},
		{
			ProgramCode: ProgramCodes.Bank_Programs_DDT,
			Path: "/DDTTransactionSearch",
			Name: "DDTTransactionSearch_Date"
		},
		{
			ProgramCode: ProgramCodes.Bank_Programs_CDIC,
			Path: "/CDICTransactionSearch",
			Name: "CDICTransactionSearch_Date"
		}];

		return arr.filter(item => item.ProgramCode == ProgramCode)[0];
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}
	GetRenderItemUniqueClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	RenderAccountLimitProgram = (item) => {
		return `${item.Description} ${this.isBackOffice ? "Program" : item.Description == "Payment Wallet" ? "" : "Wallet"}`;
	}

	Validate = () => {
		const { gridUniqueClientId } = this.state;

		if (this.isBackOffice && (gridUniqueClientId == null || gridUniqueClientId < 1)) {
			this.ShowMessage("error", "Error", "Client Can Not Be Null!");
			return false;
		}

		return true;
	}

	render() {
		const { classes } = this.props;
		const { alert, accountModel, limitModel, transactionModel, transactions, settlementModel, gridUniqueClientId, settlementSummaryList, totalMonthly, lineChartData, pieChartData, chartModel, chartListModel, chartList, amountOfDebitTransaction, numberOfCreditTransaction, numberOfDebitTransaction, txnCountChartData1, amountOfCreditTransaction, totalBalance, pendingbalance } = this.state;
		const { isLoading } = this.state;
		var totalGradient = this.getGradientList(totalBalance);
		var totalBackground = totalBalance;
		if (totalGradient != undefined) {
			totalBackground.datasets[0].backgroundColor = totalGradient;
			totalBackground.datasets[0].hoverBackgroundColor = totalGradient;
		}
		var Gradientpending = this.getGradientList(pendingbalance);
		var pendingBackground = pendingbalance;
		if (Gradientpending != undefined) {
			pendingBackground.datasets[0].backgroundColor = Gradientpending;
			pendingBackground.datasets[0].hoverBackgroundColor = Gradientpending;
		}

		var GradientnumberOfDebit = this.getGradientList(numberOfDebitTransaction);
		var numberOfDebitBackground = numberOfDebitTransaction;
		if (GradientnumberOfDebit != undefined) {
			numberOfDebitBackground.datasets[0].backgroundColor = GradientnumberOfDebit;
			numberOfDebitBackground.datasets[0].hoverBackgroundColor = GradientnumberOfDebit;
		}


		var GradientnumberOfCredit = this.getGradientList(numberOfCreditTransaction);
		var numberOfCreditBackground = numberOfCreditTransaction;
		if (GradientnumberOfCredit != undefined) {
			numberOfCreditBackground.datasets[0].backgroundColor = GradientnumberOfCredit;
			numberOfCreditBackground.datasets[0].hoverBackgroundColor = GradientnumberOfCredit;
		}
		var amountdebit = this.getGradientList(amountOfDebitTransaction);
		var backamountdebit = amountOfDebitTransaction;
		if (amountdebit != undefined) {
			backamountdebit.datasets[0].backgroundColor = amountdebit;
			backamountdebit.datasets[0].hoverBackgroundColor = amountdebit;
		}
		var amountcredit = this.getGradientList(amountOfCreditTransaction);
		var backamountcredit = amountOfCreditTransaction;
		if (amountcredit != undefined) {
			backamountcredit.datasets[0].backgroundColor = amountcredit;
			backamountcredit.datasets[0].hoverBackgroundColor = amountcredit;
		}


		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<GridContainer style={{ marginTop: "24px", marginBottom: "24px" }}>
					<GridItem xs={12}>
						<GridContainer justify="space-evenly">
							<GridItem xs={4}>
								<Card>
									<CardHeader icon>
										<CardIcon color="primary">
											<AccountBalance />
										</CardIcon>
										<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
											<b>Wallet & Balance</b>
										</h4>
									</CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={8}>
												{this.isBackOffice ?
													<GenericSelectInput
														All
														Name="UniqueClientId"
														LabelText="Select Client"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Method="POST"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														RenderItem={this.GetRenderItemUniqueClient}
														Sorted={this.sortedName}
														Value={accountModel.UniqueClientId || -1}
														ValueChanged={this.HandleChangeAccount} />
													:
													<GenericSelectInput
														Name="AccountId"
														LabelText={"Select Program"}
														LabelMd={5}
														Method="GET"
														Url="/bankapi/v1.0/Bank/GetClientPrograms"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														Value={accountModel.AccountId || -1}
														ValueChanged={this.HandleChangeAccount}
														RenderItem={this.RenderAccountLimitProgram}
														All={true}
													/>}
											</GridItem>
										</GridContainer>
										<br />
										<GridContainer>
											<GridItem xs={6} className={classes.circleBoxGrid}>
												<div className={classes.circleBox}>
													<DoughnutChart
														id='canvas'
														data={totalBackground} width={this.doughnutWidth} height={this.doughnutHeight} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />{this.isBackOffice ? <h4 style={{ fontWeight: "bold" }} >All Client Balance</h4> : <h4 style={{ fontWeight: "bold" }}>Total Balance</h4>}
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem xs={6} className={classes.circleBoxGrid}>
												<div className={classes.circleBox}>
													<DoughnutChart
														id='canvas'
														data={pendingBackground} width={this.doughnutWidth} height={this.doughnutHeight} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />{this.isBackOffice ? <h4 style={{ fontWeight: "bold" }}>All Clients Pending Balance</h4> : <h4 style={{ fontWeight: "bold" }}>Pending Balance</h4>}
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											{/* <GridItem xs={4} className={classes.circleBoxGrid}>
										<div className={classes.circleBox}>
											<DoughnutChart data={feeBackground} width={250} height={250} options={this.chartOptions} />
										</div>
									</GridItem> */}
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={8}>
								<Card>
									<CardHeader icon>
										<CardIcon color="primary">
											<CheckBox />
										</CardIcon>
										<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
											<b>Settlement Summary</b>
										</h4>
									</CardHeader>

									<GridContainer>
										<GridItem xs={5}>
											<GenericSelectInput
												Name="DateFilterId"
												LabelText="Last"
												LabelMd={3}
												IsStatic={true}
												StaticData={EndOfDayPageFilter}
												KeyValueMember="Id"
												TextValueMember="Text"
												Value={settlementModel.DateFilterId}
												ValueChanged={this.HandleChangeSettlement}
											/>
										</GridItem>
										<GridItem xs={7}>
											{this.isBackOffice ?
												<GenericSelectInput
													All
													Name="UniqueClientId"
													LabelText="Select Client"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Method="POST"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Name"
													RenderItem={this.GetRenderItemUniqueClient}
													Sorted={this.sortedName}
													Value={settlementModel.UniqueClientId || -1}
													ValueChanged={this.HandleChangeSettlement} />
												:
												<GenericSelectInput
													Name="ProgramId"
													LabelText={"Select Program"}
													LabelMd={3}
													Method="GET"
													Url="/bankapi/v1.0/Bank/GetClientPrograms"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													Value={settlementModel.ProgramId || -1}
													ValueChanged={this.HandleChangeSettlement}
													RenderItem={this.RenderAccountLimitProgram}
													All={true}
												/>}
										</GridItem>
									</GridContainer>
									<br />
									<CardBody>
										<GridContainer >
											<GridItem xs={3} className={classes.circleBoxGrid}>
												<div className={classes.circleBox}>
													<DoughnutChart

														id='canvas'
														data={numberOfDebitBackground} width={this.doughnutWidth} height={this.doughnutHeight} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br /><h4 style={{ fontWeight: "bold" }}>No of Debit Trx.</h4>
														</GridItem>
													</GridContainer>
												</div>

											</GridItem>
											<GridItem xs={3} className={classes.circleBoxGrid}>
												<div className={classes.circleBox}>
													<DoughnutChart
														id='canvas'
														data={numberOfCreditBackground} width={this.doughnutWidth} height={this.doughnutHeight} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br /><h4 style={{ fontWeight: "bold" }}>No of Credit Trx.</h4>
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem xs={3} className={classes.circleBoxGrid}>
												<div className={classes.circleBox}>
													<DoughnutChart
														id='canvas'
														data={backamountdebit} width={this.doughnutWidth} height={this.doughnutHeight} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br /><h4 style={{ fontWeight: "bold" }}>Amount of Debit Trx.</h4>
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem xs={3} className={classes.circleBoxGrid}>
												<div className={classes.circleBox}>
													<DoughnutChart
														id='canvas'
														data={backamountcredit} width={this.doughnutWidth} height={this.doughnutHeight} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br /><h4 style={{ fontWeight: "bold" }}>Amount of Credit Trx.</h4>
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>

										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>

						</GridContainer>
					</GridItem>
					<GridItem xs={12}>
						<GridItem xs={4}>
							{this.isBackOffice &&
								<GenericSelectInput
									CanClear={false}
									Name="UniqueClientId"
									LabelText="Select Client"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Method="POST"
									Parameter={this.emptyObject}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									RenderItem={this.GetRenderItemUniqueClient}
									Sorted={this.sortedName}
									Value={gridUniqueClientId.UniqueClientId || -1}
									ValueChanged={this.HandleChangeGrid} />
							}
						</GridItem>
						<Card>
							<CardBody>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									PageSize={10}
									ShowPagination={true}
									Columns={this.columns}
									DocumentTitle={"Report List"}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
									ServerSide
									LoadAtStartup={!this.isBackOffice || this.state.loadingCompleted}
									ValidateRequest={this.Validate}
									PrepareRequest={() => {
										const { gridUniqueClientId } = this.state;

										var UniqueClientId = gridUniqueClientId == null ? null : gridUniqueClientId.UniqueClientId;
										var request = { UniqueClientId: UniqueClientId };
										return request;
									}}
									RequestUrl="/bankapi/v1.0/Portal/GetListTransactionsForClientPaged"
									RequestMethod="POST"
								/>
							</CardBody>
						</Card>
					</GridItem>
					{/* <GridItem xs={12}>
						<GridContainer justify="space-evenly">
							<GridItem xs={4}>
								<Card style={{ height: "99%" }}>
									<CardHeader icon>
										<CardIcon color="primary">
											<AccountBalance />
										</CardIcon>
										<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
											<b>Account & Limit</b>
										</h4>
									</CardHeader>
									<CardBody style={{ paddingTop: 36 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="AccountId"
													LabelText={"Select Program"}
													LabelMd={5}
													Method="GET"
													Url="/bankapi/v1.0/Bank/GetClientPrograms"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													Value={accountModel.AccountId || -1}
													ValueChanged={this.HandleChangeAccount}
													RenderItem={this.RenderAccountLimitProgram}
													All={true}
												/>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="Balance"
													LabelText="Wallet Balance Of Program"
													LabelMd={5}
													Value={accountModel.Balance}
													Prefix="$"
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="TotalBalance"
													LabelText="Total Balance Of All Accounts"
													LabelMd={5}
													Value={accountModel.TotalBalance}
													Prefix="$"
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="SecureBalance"
													LabelText="Total Secure Wallet Balance"
													LabelMd={5}
													Value={accountModel.SecureBalance}
													Prefix="$"
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="SubAccountBalance"
													LabelText="Sub Wallet Balance"
													LabelMd={5}
													Value={accountModel.SubAccountBalance}
													Prefix="$"
													Disabled={true} />
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={8}>
								<Card style={{ height: "99%" }}>
									<CardHeader icon>
										<CardIcon color="primary">
											<List />
										</CardIcon>
										<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
											<b>Transaction Detail</b>
										</h4>
									</CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="StatusId"
													LabelStyle={{ width: "27.6%" }}
													SelectStyle={{ width: "66.4%" }}
													LabelText="Select Status"
													LabelMd={-1}
													IsStatic={true}
													StaticData={[{ Value: 1, Text: "Successful" }, { Value: 2, Text: "Rejected" }, { Value: 3, Text: "Return" }, { Value: 4, Text: "Error" }]}
													KeyValueMember="Value"
													TextValueMember="Text"
													Value={transactionModel.StatusId || -1}
													ValueChanged={this.HandleChangeTransaction}
													All={true}
												/>
											</GridItem>
										</GridContainer>
										{transactions.length === 0 &&
											<GridItem style={{ marginTop: 72, textAlign: "center" }}>
												<GenericLabel Bold={true} FontSize="16px" TextColor="red" Text="No Transaction Details" />
											</GridItem>
										}
										{transactions.length > 0 &&
											<GridContainer style={{ marginTop: 8, marginBottom: 6 }}>
												<GridItem xs={1}>
												</GridItem>
												<GridItem xs={11}>
													<GridContainer>
														<GridItem xs={3} style={{ textAlign: "center" }}>
															<GenericLabel
																Fontsize="12px"
																Textcolor="black"
																Text="Total Number of Debit Transaction" />
														</GridItem>
														<GridItem xs={3} style={{ textAlign: "center" }}>
															<GenericLabel
																Fontsize="12px"
																Textcolor="black"
																Text="Total Amount of Debit Transaction" />
														</GridItem>
														<GridItem xs={3} style={{ textAlign: "center" }}>
															<GenericLabel
																Fontsize="12px"
																Textcolor="black"
																Text="Total Number of Credit Transaction" />
														</GridItem>
														<GridItem xs={3} style={{ textAlign: "center" }}>
															<GenericLabel
																Fontsize="12px"
																Textcolor="black"
																Text="Total Amount of Credit Transaction" />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										}
										{transactions.map(d => (
											<GridContainer style={{ cursor: "pointer" }} onClick={() => {
												var route = this.ReturnRoutes(d.ProgramCode);
												if (route) {
													RouteHelper.Push(this.props.history, route.Path, route.Name, { FromDate: this.FromDate, ToDate: this.ToDate });
												}
											}}>
												<GridItem xs={1} style={{ marginTop: 10 }}>
													<GenericLabel
														Fontsize="12px"
														Textcolor="black"
														Text={d.Program}
														IsPointer={true} />
												</GridItem>
												<GridItem xs={11}>
													<GridContainer>
														<GridItem xs={3}>
															<GenericNumberInput
																Name="NumberDebitTrx"
																LabelMd={0}
																Value={d.NumberDebitTrx}
																ThousandSeparator=""
																DecimalScale={0}
																InputStyle={{ textAlign: "center", cursor: "pointer" }}
																Disabled={true} />
														</GridItem>
														<GridItem xs={3}>
															<GenericNumberInput
																Name="AmountDebitTrx"
																LabelMd={0}
																Value={d.AmountDebitTrx}
																Prefix="$"
																InputStyle={{ textAlign: "center", cursor: "pointer" }}
																Disabled={true} />
														</GridItem>
														<GridItem xs={3}>
															<GenericNumberInput
																Name="NumberCreditTrx"
																LabelMd={0}
																Value={d.NumberCreditTrx}
																ThousandSeparator=""
																DecimalScale={0}
																InputStyle={{ textAlign: "center", cursor: "pointer" }}
																Disabled={true} />
														</GridItem>
														<GridItem xs={3}>
															<GenericNumberInput
																Name="AmountCreditTrx"
																LabelMd={0}
																Value={d.AmountCreditTrx}
																Prefix="$"
																InputStyle={{ textAlign: "center", cursor: "pointer" }}
																Disabled={true} />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										))}
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</GridItem>
				 */}
				</GridContainer>
				{/* <GridContainer style={{ marginTop: "36px", marginBottom: "24px" }}>
					<GridItem xs={12}>
						<Card>
							<CardHeader icon>
								<CardIcon color="primary">
									<CheckBox />
								</CardIcon>
								<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
									<b>Settlement Summary</b>
								</h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GridContainer>
											<GridItem xs={5}>
												<GenericSelectInput
													Name="DateFilterId"
													LabelText="Last"
													LabelMd={4}
													IsStatic={true}
													StaticData={DateFilter}
													KeyValueMember="Id"
													TextValueMember="Text"
													Value={settlementModel.DateFilterId}
													ValueChanged={this.HandleChangeSettlement}
												/>
											</GridItem>
											<GridItem xs={7}>
												<GenericSelectInput
													Name="ProgramId"
													LabelText={"Select Program"}
													LabelMd={5}
													Method="GET"
													Url="/bankapi/v1.0/Bank/GetClientPrograms"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													Value={settlementModel.ProgramId || -1}
													ValueChanged={this.HandleChangeSettlement}
													RenderItem={this.RenderAccountLimitProgram}
													All={true}
												/>
											</GridItem>
										</GridContainer>


									</GridItem>
									<GridItem xs={12} style={{ marginTop: "16px" }}>
										<ReactTable
											className={`${classes.table} ${"-striped -highlight"}`}
											data={settlementSummaryList}
											filterable={false}
											columns={[
												{
													Header: "Client",
													accessor: "FullName"
												},
												{
													Header: "Program",
													accessor: "ProgramName"
												},
												{
													Header: "Date",
													accessor: "SettlementDate",
													type: GridColumnType.Date
												},
												{
													Header: "No of Debit Trx.",
													accessor: "NumberOfDebitTransaction"
												},
												{
													Header: "No of Credit Trx.",
													accessor: "NumberOfCreditTransaction"
												},
												{
													Header: "Value of Debit Trx.",
													accessor: "AmountOfDebitTransaction",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Value of Credit Trx.",
													accessor: "AmountOfCreditTransaction",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												}
											]}
											defaultPageSize={5}
											showPaginationTop={false}
											showPaginationBottom={settlementSummaryList.length > 5 ? true : false}
											minRows={2}
											getTrProps={(state, rowInfo) => {
												if (rowInfo && rowInfo.row) {
													return {
														onClick: e => {
															this.setState({ selectedSettled: rowInfo.index });
															if (settlementModel.DateFilterId === 1)
																this.FromDate = DateHelper.GetDate().add(-3, "days");
															else if (settlementModel.DateFilterId === 2)
																this.FromDate = DateHelper.GetDate().add(-7, "days");

															var page = "/";
															if (rowInfo.row.ProgramName === ProgramCodes.Bank_Programs_EFT)
																page = "/EFTTransactionSearch";
															if (rowInfo.row.ProgramName === ProgramCodes.Bank_Programs_e_Transfer)
																page = "/eTransferTransactionSearch";
															if (rowInfo.row.ProgramName === ProgramCodes.Bank_Programs_CDIC || rowInfo.row.Program === ProgramCodes.Bank_Programs_DDT)
																page = "/CDICTransactionSearch";

															RouteHelper.Push(this.props.history, page, page.substring(1, page.length - 1) + "_Date", { FromDate: this.FromDate, ToDate: this.ToDate, IsSettled: true });
														},
														style: {
															background:
																rowInfo.index === this.state.selectedSettled
																	? "rgba(0, 0, 0, 0.1)"
																	: "",
															cursor: "pointer"
														}
													};
												} else {
													return {};
												}
											}}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer style={{ marginBottom: "24px" }}>
					<GridItem xs={12}>
						<Card>
							<ExpansionPanel
								expanded={this.state.panel1Active}
								onChange={this.HandleChangeFilterPanel("panel1")}
								key="panel1"
								classes={{
									root: classes.expansionPanel,
									expanded: classes.expansionPanelExpanded
								}}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMore />}
									classes={{
										root: classes.expansionPanelSummary,
										expanded: classes.expansionPanelSummaryExpaned,
										content: classes.expansionPanelSummaryContent,
										expandIcon: classes.expansionPanelSummaryExpandIcon
									}}
								>
									<CardHeader icon>
										<CardIcon color="primary">
											<Timeline />
										</CardIcon>
									</CardHeader>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.expansionPanelDetails}>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={2}>
												<GenericSelectInput
													Name="ProgramId"
													LabelText={"Select Program"}
													LabelMd={5}
													Method="GET"
													Url="/bankapi/v1.0/Bank/GetClientPrograms"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													Value={chartModel.ProgramId || -1}
													ValueChanged={this.HandleChangeChart}
													RenderItem={this.RenderAccountLimitProgram}
													All={true}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericSelectInput
													Name="StoreId"
													LabelText="Select Store"
													LabelMd={5}
													Method="POST"
													Url="/bankapi/v1.0/CustomerStore/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="StoreName"
													Value={chartModel.StoreId || -1}
													ValueChanged={this.HandleChangeChart}
													All={true}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericSelectInput
													Name="DateFilterId"
													LabelText="Last"
													IsStatic={true}
													StaticData={DateFilter}
													KeyValueMember="Id"
													TextValueMember="Text"
													Value={chartModel.DateFilterId}
													ValueChanged={this.HandleChangeChart}
												/>
											</GridItem>
										</GridContainer>
										<LineChart data={lineChartData} options={this.lineChartOptions} height={80} />
									</GridItem>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer style={{ marginBottom: "24px" }}>
					<GridItem xs={12}>
						<Card>
							<ExpansionPanel
								expanded={this.state.panel2Active}
								onChange={this.HandleChangeFilterPanel("panel2")}
								key="panel1"
								classes={{
									root: classes.expansionPanel,
									expanded: classes.expansionPanelExpanded
								}}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMore />}
									classes={{
										root: classes.expansionPanelSummary,
										expanded: classes.expansionPanelSummaryExpaned,
										content: classes.expansionPanelSummaryContent,
										expandIcon: classes.expansionPanelSummaryExpandIcon
									}}
								>
									<CardHeader icon>
										<CardIcon color="primary">
											<List />
										</CardIcon>
									</CardHeader>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.expansionPanelDetails}>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={8}>
												<GridContainer>
													<GridItem xs={3}>
														<GenericSelectInput
															Name="StatusId"
															LabelText="Status"
															LabelMd={5}
															Method="POST"
															Url="/coreapi/v1.0/Parameter/Search"
															Parameter={{
																ParameterCode: "BankTransactionStatus"
															}}
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="ParameterDesc"
															Value={chartListModel.StatusId || -1}
															ValueChanged={this.HandleChangeChartList}
															All={true}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericSelectInput
															Name="StoreId"
															LabelText="Select Store"
															LabelMd={5}
															Method="POST"
															Url="/bankapi/v1.0/CustomerStore/GetAll"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="StoreName"
															Value={chartListModel.StoreId || -1}
															ValueChanged={this.HandleChangeChartList}
															All={true}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericSelectInput
															Name="DateFilterId"
															LabelText="Last"
															IsStatic={true}
															StaticData={DateFilter}
															KeyValueMember="Id"
															TextValueMember="Text"
															Value={chartListModel.DateFilterId}
															ValueChanged={this.HandleChangeChartList}
														/>
													</GridItem>
												</GridContainer>
												<GridContainer>
													<ReactTable
														style={{
															marginTop: "32px",
															width: "100%"
														}}
														className={`${classes.table} ${"-striped -highlight"}`}
														data={chartList}
														filterable={false}
														columns={[
															{
																Header: "Program",
																accessor: "ProgramName"
															},
															{
																Header: "Status",
																accessor: "TransactionStatus.ParameterDesc"
															},
															{
																Header: "Customer Name",
																accessor: "FullName"
															},
															{
																Header: "Customer Wallet",
																accessor: "IBAN"
															},
															{
																Header: "Amount",
																accessor: "TransactionAmount",
																type: GridColumnType.Money
															},
															{
																Header: "Entry Type",
																accessor: "EntryType.ParameterDesc"
															},
															{
																Header: "Date",
																accessor: "TransactionDate",
																type: GridColumnType.Date
															}
														]}
														defaultPageSize={5}
														showPaginationTop={false}
														showPaginationBottom={false}
														minRows={4}
													/>
												</GridContainer>
											</GridItem>
											<GridItem xs={4}>
												<PieChart
													data={pieChartData} options={this.pieChartOptions} height={225} width={300}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer >
		 */}

			</div >
		);
	}
}

Portal.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(Portal, dashboardStyle);