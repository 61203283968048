import withStyles from "@material-ui/core/styles/withStyles";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";


const styles = ({
	...customCheckboxRadioSwitch,
	...customSelectStyle,
	...sweetAlertStyle
});

class EFTSettlementAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};

	}


	render() {
		const { eftModel } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={6} md={6}>
					<Card className="no-radius" style={{ height: "90%" }}>
						<CardBody>
							<GenericTitle text="EFT Settlement Wallet" />
							<GridContainer>
								<GridItem xs={6} sm={6} md={6}>
									<GenericSelectInput
										Name="FinInsId"
										LabelText="Wallet Number"
										LabelMd={8}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitution/GetAll"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="FinancialInstitutionId"
										Value={eftModel.FinInsId == null || undefined ? "" : eftModel.FinInsId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<GenericSelectInput
										Name="BranchId"
										LabelMd={0}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="TransitNumber"
										Value={eftModel.TransitNumber == null ? "" : eftModel.TransitNumber}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<GenericNumberInput
										NoFormatting={true}
										LabelMd={0}
										Name="AccountNumber"
										Value={eftModel.AccountNumber == null || undefined ? "" : eftModel.AccountNumber}
										Disabled={true} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Card className="no-radius" style={{ height: "90%" }}>
						<CardBody><br />
							<GridContainer><br />
								<GridItem xs={12} sm={6} md={6}>
									<GenericTextInput
										Name="BankName"
										LabelText="Bank Name"
										Value={eftModel.BankName == null || undefined ? "" : eftModel.BankName}
										Disabled={true} />
									<GenericTextInput
										Name="Branch"
										LabelText="Branch"
										Value={eftModel.Branch == null || undefined ? "" : eftModel.Branch}
										Disabled={true} />
								</GridItem>
								<GridItem xs={12} sm={6} md={6}>
									<GenericTextInput
										Name="Address1"
										LabelText="Address 1"
										Value={eftModel.Address1 == null || undefined ? "" : eftModel.Address1}
										Disabled={true} />
									<GenericTextInput
										Name="Address2"
										LabelText="Address 2"
										Value={eftModel.Address2 == null || undefined ? "" : eftModel.Address2}
										Disabled={true} />
								</GridItem>

							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

EFTSettlementAccount.propTypes = {
	classes: PropTypes.object,
	eftModel: PropTypes.object
};
export default withStyles(styles)(EFTSettlementAccount);