import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericLabel } from "views/Components/Generic";

class GenericCircularProgress extends Component {

	constructor(props) {
		super(props);

		this.style = { width: "80px", height: "80px" };
		this.overlayStyle = { position: "absolute", alignItems: "center", justifyContent: "center", top: "42%", left: "38%" };
	}

	isObject(obj) {
		return obj !== null && typeof obj === "object";
	}

	render() {
		const { TaskPoolDomain } = this.props;
		const staticLoad = {};

		if (TaskPoolDomain && this.isObject(TaskPoolDomain)) {
			var percent = 100 - Math.round((TaskPoolDomain.pool.length * 100) / TaskPoolDomain.taskCount);
			staticLoad.value = percent;
		}

		if (TaskPoolDomain != null && percent > 0) {
			return (
				<div>
					<div style={this.overlayStyle}>
						<GenericLabel FontSize="14px" Bold={true} Text={"%" + percent} />
					</div>
					<div>
						<CircularProgress variant="static" value={staticLoad.value} style={this.style} />
					</div>
				</div>
			);
		}

		return (
			<CircularProgress style={this.style} />
		);
	}
}

GenericCircularProgress.propTypes = {
	TaskPoolDomain: PropTypes.object
};

export default GenericCircularProgress;