import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";



const initialState = {
	model: {
		FileTypeId: null,
		Description: null,
		VersionNumber: null,
	},
	isBrowse: false,
	agreementList: [],
	isLoading: false,
	ReadOnly: false
};
class PartnerCommissionSummaryReport extends Component {
	constructor(props) {
		super(props);
		this.SetFile = this.SetFile.bind(this);
		this.UploadClick = this.UploadClick.bind(this);
		this.FileSelect = this.FileSelect.bind(this);
		this.fileInputRef = React.createRef();
		this.emptyObject = {};

		this.FileTypeParameterCode = { ParameterCode: "LOSFileType" };
		this.state = initialState;
		this.maxLength20 = { maxLength: 20 };
		this.marginLeft2 = { marginLeft: 2 };
		this.ColumnsData = [
			{
				Header: "Date",
				accessor: "Date",
				// Cell: row => (
				// 	<div>
				// 		<Button justIcon simple size="sm" tooltip="Edit"
				// 			disabled={this.props.Disabled} onClick={() => this.setState({ model: { ...row.original }, fileModel: row.original.File })}>
				// 			<img src={EditIcon} />
				// 		</Button>
				// 		<Button justIcon simple size="sm"
				// 			disabled={this.props.Disabled} tooltip="Delete" onClick={() => this.showQuestionMessage("Delete this record !", row.original)}>
				// 			<img src={DeleteIcon} />
				// 		</Button>

				// 		<Button justIcon simple size="sm" tooltip="Download" disabled={this.props.Disabled} onClick={() => this.DownloadModel(row.original)}>
				// 			<img src={DownloadIcon} />
				// 		</Button>
				// 	</div>
				// ),
				sortable: false,
				filterable: false,
			},
			{
				Header: "Loan Type",
				accessor: "Loan Type",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Borrower Name",
				accessor: "Borrower Name",
				// Cell: row => {
				// 	return <div>{typeof row.value === "number" ? row.value : typeof row.value === "boolean" ? row.value === true ? "Yes" : "No" : moment(row.value, moment.ISO_8601, true).isValid() ? Formatter.FormatDateTime(row.value) : row.value}</div>;
				// },
				sortable: false,
				filterable: false,
			},
			{
				Header: "Total Loan Amount",
				accessor: "Total Loan Amount",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Period",
				accessor: "Period",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Fees",
				accessor: "Fees",
				sortable: false,
				filterable: false,

			},
			{
				Header: "Partner Commission",
				accessor: "Partner Commission",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Loan Status",
				accessor: "Loan Status",
				sortable: false,
				filterable: false,
			}

		];

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Partner Commission Summary Report");
		this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });

	}

	UploadClick() {
		this.fileInputRef.current.click();
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	FileSelect(e) {
		e.preventDefault();
		var file = e.target.files[0];
		file.FileName = file.name;
		if (!file) return;
		this.setState({ fileModel: file, isBrowse: true });
	}

	SetFile(file, documentModel) {
		this.setState({
			fileModel: { file, documentModel }
		});
	}

	HandleClickClearOk = () => {
		this.HideAlert();
		this.setState({ model: {} });
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this agreement template ?", () => this.HandleClickClearOk());
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}


	DownloadModel = (index) => {
		this.setState({ isLoading: true });
		const { showMessage } = this.props;
		Proxy.FileDownloadWithGenericResponse(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			index.File.FileName,
			index.File.FileExtension.ParameterDesc,
			{
				"FileId": index.FileId
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("warning", "Warning", "Template must be selected for delete operation.");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/AgreementTemplate/Delete",
			{ Id: model.Id },
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessageAndRefresh("success", "Success", "Template Deleted");
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/AgreementTemplate/Search",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ agreementList: responseData.Item || [], isLoading: false });
				} else {
					this.setState({ agreementList: [], isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Submit = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/AgreementTemplate/InsertUpdate",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ model: responseData.Item });
					if (this.state.isBrowse == true) {

						this.setState({ isBrowse: false });
					}
					else {
						this.setState({ isLoading: false });
						this.ShowMessageAndRefresh("success", "Success", "Operation Successfully Completed");
					}
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}



	render() {


		const { ForInsert, Disabled } = this.props;

		const { model, alert, isLoading, ReadOnly } = this.state;
		return (
			<div>
				
				<LoadingComponent Show={isLoading}/>

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: ForInsert || ReadOnly || Disabled },
					{ Code: "Search", OnClick: this.Search, Disabled: ForInsert || ReadOnly || Disabled },
					{ Code: "Submit", OnClick: this.Submit, Disabled: ForInsert || ReadOnly || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} IsPopUp={ForInsert} />
				<GridContainer>
					{alert}
					<GridItem xs={12} sm={12} md={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12} md={3} style={{ opacity: model.isGenerateNew ? 0.4 : 1 }}>
										<GenericSelectInput
											key={"Id"}
											Name="Id"
											LabelText="Partner Name"
											Method="POST"
											Url="/losapi/v1.0/ClientPartner/GetAll"
											Parameter={this.emptyObject}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="LegalNameOfBusiness"
											Value={model.Id}
											ValueChanged={this.HandleChange}
											Disabled={model.isGenerateNew}
										/>
									</GridItem>
									<GridItem xs={12} md={3}>
										<GenericSelectInput
											key={"Description"}
											Name="Description"
											LabelText="Partner Type"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.FileTypeParameterCode}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.Description == null || undefined ? "" : model.Description}
											ValueChanged={this.HandleChange}
											Disabled={model.isGenerateNew}
										/>
										{/* 											
											<GenericTextInput
												key={"Description"}
												Name="Description"
												LabelMd={5}
												LabelText="Partner Type"
												Value={model.Description == null || undefined ? "" : model.Description}
												ValueChanged={this.HandleChange}
												Disabled={model.isGenerateNew} /> */}
									</GridItem>
									<GridItem xs={12} md={3}>
										<GenericTextInput
											key={"VersionNumber"}
											Name="VersionNumber"
											LabelMd={5}
											LabelText="Partner Unique ID"
											Value={model.VersionNumber == null || undefined ? "" : model.VersionNumber}
											ValueChanged={this.HandleChange}
											Disabled={model.isGenerateNew} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<p style={{ color: "black", fontSize: 12 }}>Partner Commissions</p>
						<Card className="no-radius">
							<CardBody>
								<GridItem xs={12} style={{ marginTop: 10 }}>

									<ReactTable
										className="-striped -highlight"
										data={this.state.agreementList || []}
										filterable={false}
										columns={this.ColumnsData}
										showPaginationTop={false}
										//showPaginationBottom={Object.keys(m.ag || []).length > 0}
										minRows={2}

									/>
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}
PartnerCommissionSummaryReport.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ApplicantSaved: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(PartnerCommissionSummaryReport, {});