import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import LoanCalculator from "views/Components/LoanCalculator";
import { CashLimitUsageType, LOSLoanType, PersonalizedExpDateList } from "views/Constants/Constant.js";


const initialState = {
	model: {
		LoanType: null,
		LoanTypeStr: null,
		LoanPurposeId: null,
		CashAdvanceLimitUsageType: null,
		CashAdvanceLimitUsageTypeStr: null,
		FeeLoanSetupProfileList: [],
		FeeLoanSetupProfileKeyValueList: [],
		FeeLoanSetupProfileValueList: [],
		FeeMonthlyRateProfileList: [],
		FeeMonthlyRateProfileKeyValueList: [],
		FeeMonthlyRateProfileValueList: []
	},
	modelList: [],
	vModel: {},
	isLoading: false,
	ReadOnly: false,
	isFeeCalculateDilogOpen: false,
};
class FeeDefinition extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;

		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };
		this.LoanPurposeParameterCode = { ParameterCode: "LOSLoanPurpose" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.CashLimitUsageType = { ParameterCode: "CashLimitUsageType" };
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({ addressModel: { ...row.original } })} />
						<GridButton
							Disabled={!row.original.IsDeletable}
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.ShowQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Profile Name",
				accessor: "Name"
			},
			{
				Header: "Loan Type",
				accessor: "LoanType.ParameterDesc"
			},
			{
				Header: "LoanPurpose",
				accessor: "LoanPurpose.ParameterDesc"
			}
		];

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Rate & Fee Definition");
		this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		this.getFeeDefinitionList();
	}

	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.model;
		const { vModel } = this.state;

		if (data == null) {
			ErrorList.push("Model not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.LoanTypeId == null || data.LoanTypeId == "") {
			vModel.LoanTypeId = true;
			ErrorList.push("Loan must be defined.");
		}
		else {
			vModel.LoanTypeId = false;
		}
		if (data.LoanPurposeId == null || data.LoanPurposeId == "") {
			vModel.LoanPurposeId = true;
			ErrorList.push("Loan Purpose must be defined.");
		}
		else {
			vModel.LoanPurposeId = false;
		}
		if (!data.Name) {
			vModel.Name = true;
			ErrorList.push("Profile name must be defined.");
		} else {
			vModel.Name = false;
		}
		if (data.DormantCardDuration && Number(data.DormantCardDuration) == 0) {
			ErrorList.push("Dormant Card Day Count cannot be entered zero");
		}
		if (data.InactivityFeeApplyPeriod && Number(data.InactivityFeeApplyPeriod) == 0) {
			ErrorList.push("Inactivity Fee Apply Days cannot be entered zero");
		}
		if ((data.LoanTypeStr == LOSLoanType.LOC || data.LoanTypeStr == LOSLoanType.SecureCard) &&
			(data.PersonalizedExpDate == null || data.PersonalizedExpDate == "")) {
			vModel.PersonalizedExpDate = true;
			ErrorList.push("Card expiration date must be defined.");
		}
		else {
			vModel.PersonalizedExpDate = false;
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		this.UpdateModel();
	}
	UpdateModel = () => {
		const model = this.state.model;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/losapi/v1.0/LoanFeeDefinition/InsertUpdate",
			model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (model.Id > 0) {
					this.ShowMessage("success", "Success", "Fee Definition Updated", this.HandleClickClearOk);

				} else {
					this.ShowMessageAndRefresh("success", "Success", "Fee Definition Saved");
				}

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	DeleteModel = (model) => {
		this.setState({ alert: null, isLoading: true });
		Proxy.GET(
			"/losapi/v1.0/LoanFeeDefinition/Delete/Id?Id=" + model.Id,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessageAndRefresh("success", "Success", "Deleted Successfully");
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	getFeeDefinitionList() {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/LoanFeeDefinition/GetAll",
			{},
			responseData => {
				console.log("getFeeDefinitionList ->", responseData.IsSucceeded);
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {

					var modelList = responseData.Item || [];

					this.setState({
						modelList
					});
				} else {
					modelList = [];
					this.setState({ modelList });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	getFeeProfileDetail(loanFeeDefinitionId) {
		this.setState({ isLoading: true });
		const model = this.state.model;

		model.FeeLoanSetupProfileValueList = [];
		model.FeeMonthlyRateProfileValueList = [];
		Proxy.POST(
			"/losapi/v1.0/FeeProfile/FeeProfileDetailById",
			{
				Id: loanFeeDefinitionId
			},
			responseData => {
				console.log("getFeeProfileDetail ->", responseData.IsSucceeded);
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {

					model.FeeLoanSetupProfileList = responseData.Item.FeeLoanSetupProfile || [];
					model.FeeMonthlyRateProfileList = responseData.Item.FeeMonthlyRateProfile || [];
					model.FeeLoanSetupProfileKeyValueList = responseData.Item.FeeLoanSetupProfileKeyValueList || [];
					model.FeeMonthlyRateProfileKeyValueList = responseData.Item.FeeMonthlyRateProfileKeyValueList || [];
					if (model.FeeLoanSetupProfileKeyValueList.length > 0) {
						model.FeeLoanSetupProfileKeyValueList.forEach(function (element) {
							model.FeeLoanSetupProfileValueList[element.Key] = element.Value;
						});
					}
					if (model.FeeMonthlyRateProfileKeyValueList.length > 0) {
						model.FeeMonthlyRateProfileKeyValueList.forEach(function (element) {
							model.FeeMonthlyRateProfileValueList[element.Key] = element.Value;
						});
					}
					this.setState({
						model
					});
				} else {
					model.FeeLoanSetupProfileList = [];
					model.FeeMonthlyRateProfileList = [];
					model.FeeLoanSetupProfileKeyValueList = [];
					model.FeeMonthlyRateProfileKeyValueList = [];
					model.FeeLoanSetupProfileValueList = [];
					model.FeeMonthlyRateProfileValueList = [];
					this.setState({ model });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	ShowQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.HideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	HandleSetupFeeChange = (name, newValue) => {
		var valueList = this.state.model.FeeLoanSetupProfileValueList || [];
		var model = this.state.model;
		valueList[name] = newValue;
		if (model.FeeLoanSetupProfileKeyValueList == null)
			model.FeeLoanSetupProfileKeyValueList = [];

		var newKeyValueList = model.FeeLoanSetupProfileKeyValueList.filter(function (item) {
			return item.Key !== name;
		});
		newKeyValueList.push({ Key: name, Value: newValue });
		model.FeeLoanSetupProfileKeyValueList = newKeyValueList;

		model.FeeLoanSetupProfileValueList = valueList;
		this.setState({ model });
	}
	HandleMonthlyFeeChange = (name, newValue) => {
		var valueList = this.state.model.FeeMonthlyRateProfileValueList || [];
		var model = this.state.model;
		valueList[name] = newValue;
		if (model.FeeMonthlyRateProfileKeyValueList == null)
			model.FeeMonthlyRateProfileKeyValueList = [];

		var newKeyValueList = model.FeeMonthlyRateProfileKeyValueList.filter(function (item) {
			return item.Key !== name;
		});
		newKeyValueList.push({ Key: name, Value: newValue });
		model.FeeMonthlyRateProfileKeyValueList = newKeyValueList;

		model.FeeMonthlyRateProfileValueList = valueList;
		this.setState({ model });
	}
	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;

		if (name == "LoanTypeId") {
			model.LoanType = data || {};
			model.LoanTypeStr = data && data.ParameterValue;
			model.LoanPurposeId = null;
		}

		if (name == "CashAdvanceLimitUsageTypeId") {
			model.CashAdvanceLimitUsageType = data || {};
			model.CashAdvanceLimitUsageTypeStr = data && data.ParameterValue;
		}


		this.setState({ model });
	}

	HandleClickClearOk = () => {
		this.HideAlert();
		let PurposeOfLoanParamList = this.state.PurposeOfLoanParamList;

		this.setState(initialState, () => {
			this.getFeeDefinitionList();
		});
		if (PurposeOfLoanParamList != null && PurposeOfLoanParamList.length > 0) {
			this.setState({ PurposeOfLoanParamList });
		}

	}
	FeeCalculatorDialogOpenOrClose = () => {
		if (this.state.isFeeCalculateDilogOpen == false) {
			this.setState({ isFeeCalculateDilogOpen: true });
		}
		else {
			this.setState({ isFeeCalculateDilogOpen: false });
		}
	}
	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this application?", () => this.HandleClickClearOk());
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message, callback) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" }, callback)} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	SelectedRowChange = (e, index) => {
		const model = this.state.modelList[index];
		model.LoanTypeStr = model.LoanType == null ? "" : model.LoanType.ParameterValue || "";
		model.CashAdvanceLimitUsageTypeStr = model.CashAdvanceLimitUsageType == null ? "" : model.CashAdvanceLimitUsageType.ParameterValue || "";
		this.setState({ model }, () => {
			this.getFeeProfileDetail(model.Id);
		}
		);
	}
	render() {
		const { model, alert, isLoading, modelList, isFeeCalculateDilogOpen } = this.state;
		const vModel = this.state.vModel;
		var ForInsert = !model.Id || model.Id <= 0;

		return (
			<div>
				{alert}


				<LoadingComponent Show={isLoading} />

				<GenericDialog open={isFeeCalculateDilogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Fee Calculator" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<LoanCalculator
							IsPopUp={true}
							CloseLoanCalculator={this.FeeCalculatorDialogOpenOrClose}
							isRateFeeCalculator={true}
							FeeLoanSetupProfileModel={this.state.model}
						>
						</LoanCalculator>
						<GenericDialogActions>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GridItem xs={12}>
					<ButtonToolbar ButtonList={[
						{ Code: "Submit", OnClick: this.OperationCheck, Disabled: !ForInsert },
						{ Code: "Update", OnClick: this.OperationCheck, Disabled: ForInsert },
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: false }
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} IsPopUp={false} />

				</GridItem>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Main Parameters">
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="flex-start"
									>
										<GridItem xs={3}>
											<GenericTextInput
												Required
												IsInvalid={vModel.Name}
												Name="Name"
												LabelText="Profile Name"
												Value={model.Name}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="LoanTypeId"
												LabelText="Loan Type"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.LoanTypeParameterCode}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.LoanTypeId}
												Required
												IsInvalid={vModel.LoanTypeId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="LoanPurposeId"
												LabelText="Purpose of Loan"
												key={model.LoanTypeId + "LoanPurpose"}
												Method="POST"
												Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
												Parameter={{ LoanTypeId: model.LoanTypeId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.LoanPurposeId}
												Required
												IsInvalid={vModel.LoanPurposeId}
												ValueChanged={this.HandleChange}
												Disabled={!(model != null && model.LoanTypeId != null)}
											/>
										</GridItem>
										{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
											<GridItem xs={3}>
												<GenericNumberInput
													Required
													IsInvalid={vModel.PersonalizedExpDate}
													LabelText="Card Expiration Date"
													Value={model.PersonalizedExpDate}
													ValueChanged={this.HandleChange}
													NoFormatting
													LabelMd={5}
													MaxLength={3}
													Name="PersonalizedExpDate"
													
													/>
											</GridItem>
										)}
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title=" On-going Fees">
									<GridContainer	>

										<GridItem xs={4}>
											<GenericNumberInput
												LabelMd={5}
												MaxLength={10}
												Suffix="%"
												Name="PenaltyRate"
												LabelText="Overdue Payment Rate"
												Value={model.PenaltyRate}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												LabelMd={5}
												MaxLength={10}
												Suffix="%"
												Name="UsingInterestRate"
												LabelText="Purchase Interest Rate"
												Value={model.UsingInterestRate}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												LabelMd={5}
												MaxLength={10}
												Suffix="%"
												Name="CashAdvanceInterestRate"
												LabelText="Cash Advanced Interest Rate"
												Value={model.CashAdvanceInterestRate}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										{/* <GridItem xs={4}>
											<GenericNumberInput
												LabelMd={5}
												MaxLength={10}
												Suffix="%"
												Name="PenaltyRate"
												LabelText="Overdue Fee Rate"
												Value={model.PenaltyRate}
												ValueChanged={this.HandleChange}
											/>
										</GridItem> */}
										<GridItem xs={4}>
											<GenericNumberInput
												LabelMd={5}
												MaxLength={10}
												Prefix="$"
												Name="MinumumPenaltyAmount"
												LabelText=" Minimum Overdue Fee"
												Value={model.MinumumPenaltyAmount}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												NoFormatting
												LabelMd={5}
												MaxLength={3}
												Name="CashGracePeriodDay"
												LabelText="Cash Grace Period Day"
												Value={model.CashGracePeriodDay || 0}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												NoFormatting
												LabelMd={5}
												MaxLength={3}
												Name="SaleGracePeriodDay"
												LabelText="Purchase Grace Period Day"
												Value={model.SaleGracePeriodDay || 21}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>

											{
												model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
													<GenericNumberInput
														LabelMd={5}
														Prefix="$"
														Name="AnnualCardFee"
														LabelText="Annual Card Fee"
														Value={model.AnnualCardFee}
														ValueChanged={this.HandleChange}
													/>
												)}
										</GridItem>
										<GridItem xs={4}>
											{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
												<GenericNumberInput
													LabelMd={5}
													Prefix="$"
													Name="OverdraftFee"
													LabelText="Overdraft Fee"
													Value={model.OverdraftFee}
													ValueChanged={this.HandleChange}
												/>
											)}
										</GridItem>
										<GridItem xs={4}>
											{model != null && (model.LoanTypeStr != LOSLoanType.SecureCard) && (
												<GenericCheckInput
													Name="IsApplyDailyInterestPerTransaction"
													LabelText="Apply Daily Interest to Per Transaction"
													Value={model.IsApplyDailyInterestPerTransaction}
													ValueChanged={this.HandleChange} />
											)}
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius" style={{ display: model.LoanType != null && (model.LoanType.ParameterValue == LOSLoanType.Loan) ? "none" : "inherit" }}>
							<CardBody>
								<GenericExpansionPanel Title="Transaction Fees">
									<GridContainer	>
										{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
											<GridContainer>
												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														MaxLength={10}
														Prefix="$"
														Name="ATMDomesticTransactionFee"
														LabelText="ATM Domestic Transaction Fee"
														Value={model.ATMDomesticTransactionFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														MaxLength={10}
														Prefix="$"
														Name="ATMInternationalTransactionFee"
														LabelText="ATM International Transaction Fee"
														Value={model.ATMInternationalTransactionFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														MaxLength={10}
														Prefix="$"
														Name="ForeignExchangeFee"
														LabelText="International Trx Fixed Fee"
														Value={model.ForeignExchangeFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												{/* <GridItem xs={2}>
													<GenericNumberInput
														LabelMd={8}
														Prefix="$"
														Name="ChargebackFee"
														LabelText="Chargeback Fee"
														Value={model.ChargebackFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem> */}
												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														Prefix="$"
														Name="BillPaymentFee"
														LabelText="Bill Payment Fee"
														Value={model.BillPaymentFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														Prefix="$"
														Name="POSDomesticTransactionFee"
														LabelText="POS Domestic Transaction Fee"
														Value={model.POSDomesticTransactionFee}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														Prefix="$"
														Name="POSInternationalTransactionFee"
														LabelText="POS International Transaction Fee"
														Value={model.POSInternationalTransactionFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
												<GenericNumberInput
													LabelMd={8}
													Prefix="$"
													Name="DeclineFee"
													LabelText="DeclineFee"
													Value={model.DeclineFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericNumberInput
													LabelMd={8}
													MaxLength={10}
													Suffix="%"
													Name="AdministrationFeeRate"
													LabelText="International Trx. Fee Rate"
													Value={model.AdministrationFeeRate}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											</GridContainer>

										)}
										<GridContainer>
												{/* <GridItem xs={3}>
													<GenericNumberInput
														LabelMd={8}
														Prefix="$"
														Name="EtransferFee"
														LabelText="Etransfer Fee"
														Value={model.EtransferFee}
														ValueChanged={this.HandleChange}
													/>
												</GridItem> */}
											{/* <GridItem xs={2}>
												<GenericNumberInput
													LabelMd={8}
													Prefix="$"
													Name="IVRInquiryFee"
													LabelText="IVR Inquiry Fee"
													Value={model.IVRInquiryFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericNumberInput
													LabelMd={8}
													Prefix="$"
													Name="ATMInquiryFee"
													LabelText="ATM Inquiry Fee"
													Value={model.ATMInquiryFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem> */}
											<GridItem xs={3}>
												<GenericNumberInput
													LabelMd={8}
													Prefix="$"
													Name="RealTimeMoneySendFee"
													LabelText="Real Time Money Send Fee"
													Value={model.RealTimeMoneySendFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericNumberInput
													LabelMd={8}
													Prefix="$"
													Name="RealTimeMoneyRequestFee"
													LabelText="Real Time Money Request Fee"
													Value={model.RealTimeMoneyRequestFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
							<Card className="no-radius">
								<CardBody>
									<GenericExpansionPanel Title="Other Fees">
										<GridContainer	>
											<GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													MaxLength={10}
													Prefix="$"
													Name="InactivityFee"
													LabelText="Inactivity Fee"
													Value={model.InactivityFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericNumberInput
													NoFormatting
													LabelMd={7}
													MaxLength={10}
													Name="InactivityFeeApplyPeriod"
													LabelText="Inactivity Fee Apply Days"
													Value={model.InactivityFeeApplyPeriod || 1}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													MaxLength={10}
													Prefix="$"
													Name="CardActivationFee"
													LabelText="Card Activation Fee"
													Value={model.CardActivationFee}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>

											{/* <GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													Prefix="$"
													Name="NotificationFee"
													LabelText="Notification Fee"
													Value={model.NotificationFee}
													ValueChanged={this.HandleChange} />
											</GridItem> */}
											<GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													Prefix="$"
													Name="ReplacementCardFee"
													LabelText="Replacement Card Fee"
													Value={model.ReplacementCardFee}
													ValueChanged={this.HandleChange} />
											</GridItem><GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													Prefix="$"
													Name="DeactivationCardFee"
													LabelText="Deactivation Card Fee "
													Value={model.DeactivationCardFee}
													ValueChanged={this.HandleChange} />
											</GridItem>
											{/* <GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													Prefix="$"
													Name="LiveCustomerServiceFee"
													LabelText="Live Customer Service Fee"
													Value={model.LiveCustomerServiceFee}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													Prefix="$"
													Name="NetworkFee"
													LabelText="Network Fee"
													Value={model.NetworkFee}
													ValueChanged={this.HandleChange} />
											</GridItem> */}
											<GridItem xs={2}>
												<GenericNumberInput
													LabelMd={7}
													Prefix="$"
													Name="LoadHupFee"
													LabelText="LoadHup Fee"
													Value={model.LoadHupFee}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridContainer>
												<GridItem xs={2}>
													<GenericNumberInput
														LabelMd={7}
														Prefix="$"
														Name="MonthlyCardFee"
														LabelText="Monthly Card Fee"
														Value={model.MonthlyCardFee}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem xs={2}>
													<GenericNumberInput
														LabelMd={8}
														NoFormatting
														Name="MonthlyCardFeeApplyDay"
														LabelText="Monthly Card Fee Apply Day"
														Value={model.MonthlyCardFeeApplyDay}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem xs={3}>
													<GenericCheckInput
														Name="IsApplyMonthlyCardFeeAfterFirstTransaction"
														LabelText="Apply Monthly Card Fee After First Transaction"
														Value={model.IsApplyMonthlyCardFeeAfterFirstTransaction}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridContainer>
										</GridContainer>
									</GenericExpansionPanel>
								</CardBody>
							</Card>
						)}

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Limits and Payments">
									<GridContainer
										direction="row">
										<GridItem xs={12}>
											<GridContainer
												direction="row">
												{model != null && (model.LoanTypeStr == LOSLoanType.Loan) && (
													<GridItem xs={3}>

														<GenericNumberInput
															LabelMd={5}
															MaxLength={10}
															Prefix="$"
															Name="MinumumLoanAmount"
															LabelText="Minumum Loan Amount"
															Value={model.MinumumLoanAmount}
															ValueChanged={this.HandleChange}
														/>

													</GridItem>)}
												{model != null && (model.LoanTypeStr == LOSLoanType.Loan) && (
													<GridItem xs={3}>

														<GenericNumberInput
															LabelMd={5}
															MaxLength={10}
															Prefix="$"
															Name="MaximumLoanAmount"
															LabelText="Maximum Loan Amount"
															Value={model.MaximumLoanAmount}
															ValueChanged={this.HandleChange}
														/>

													</GridItem>)}
												<GridItem xs={3}>
													{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
														<GenericNumberInput
															LabelMd={6}
															MaxValue={100}
															Suffix="%"
															Name="MinimumPaymentPercentage"
															LabelText="Minimum Payment Percentage"
															Value={model.MinimumPaymentPercentage}
															ValueChanged={this.HandleChange}
														/>
													)}
												</GridItem>
												<GridItem xs={3}>
													{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
														<GenericNumberInput
															LabelMd={5}
															MaxLength={10}
															Prefix="$"
															Name="MinumumPrePayAmount"
															LabelText="Minimum Payment"
															Value={model.MinumumPrePayAmount}
															ValueChanged={this.HandleChange}
														/>
													)}
												</GridItem>
											</GridContainer>
										</GridItem>
										{model != null && (model.LoanTypeStr == LOSLoanType.LOC || model.LoanTypeStr == LOSLoanType.SecureCard) && (
											<GridItem xs={12}>
												<GridContainer
													direction="row">
													<GridItem xs={3}>
														<GenericNumberInput
															LabelMd={9}
															MaxLength={10}
															Prefix="$"
															Name="MinimumAmountForStatementGeneration"
															LabelText="Min. amount for statement generation"
															Value={model.MinimumAmountForStatementGeneration}
															ValueChanged={this.HandleChange}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															LabelMd={9}
															MaxLength={10}
															Prefix="$"
															Name="MinimumAmountForInterestCalculation"
															LabelText="Min. amount for interest calculation"
															Value={model.MinimumAmountForInterestCalculation}
															ValueChanged={this.HandleChange}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															LabelMd={9}
															MaxValue={100}
															Suffix="%"
															Name="PaymentRateToAssumeMinPaymentPaid"
															LabelText="Min. rate to assume min payment is paid"
															Value={model.PaymentRateToAssumeMinPaymentPaid}
															ValueChanged={this.HandleChange}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															LabelMd={9}
															MaxLength={10}
															Prefix="$"
															Name="PaymentAmountToAssumeMinPaymentPaid"
															LabelText="Min. amount to assume min payment is paid"
															Value={model.PaymentAmountToAssumeMinPaymentPaid}
															ValueChanged={this.HandleChange}
														/>
													</GridItem>
												</GridContainer>
												<GridContainer
													direction="row">
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting
															LabelMd={5}
															MaxLength={3}
															Name="DormantCardDuration"
															LabelText="Dormant Card Day Count"
															Value={model.DormantCardDuration}
															ValueChanged={this.HandleChange}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericSelectInput
															Name="CashAdvanceLimitUsageTypeId"
															LabelText="Cash Advance Limit Type"
															Method="POST"
															Url="/coreapi/v1.0/Parameter/Search"
															Parameter={this.CashLimitUsageType}
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="ParameterDesc"
															Value={model.CashAdvanceLimitUsageTypeId}
															IsInvalid={vModel.CashAdvanceLimitUsageTypeId}
															ValueChanged={this.HandleChange}
															LabelMd={5}
														/>
													</GridItem>
													{model != null && (model.CashAdvanceLimitUsageTypeStr == CashLimitUsageType.Ratio) && (
														<GridItem xs={3}>
															<GenericNumberInput
																LabelMd={5}
																MaxLength={10}
																Suffix="%"
																Name="CashAdvanceLimitRatio"
																LabelText="Cash Advance Limit Rate"
																Value={model.CashAdvanceLimitRatio}
																ValueChanged={this.HandleChange}
															/>
														</GridItem>
													)}
													{model != null && (model.CashAdvanceLimitUsageTypeStr == CashLimitUsageType.Fixed) && (
														<GridItem xs={3}>
															<GenericNumberInput
																LabelMd={7}
																MaxLength={10}
																Prefix="$"
																Name="CashAdvanceLimit"
																LabelText="Cash Advance Limit Amount"
																Value={model.CashAdvanceLimit}
																ValueChanged={this.HandleChange}
															/>
														</GridItem>
													)}
												</GridContainer>
											</GridItem>)}
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius" style={{ display: model.LoanType != null && (model.LoanType.ParameterValue != LOSLoanType.Loan) ? "none" : "inherit" }}>
							<CardBody>
								<GenericExpansionPanel
									Title="Loan Setup Fees">
									<GridItem xs={11}>
										<GridContainer
											direction="row"
											justify="flex-start"
											alignItems="stretch"
										>
											{model.FeeLoanSetupProfileList != undefined && model.FeeLoanSetupProfileList.map((setupFee, i, array) => {
												return (
													<GridItem xs={2} key={i}>
														<GenericNumberInput
															LabelMd={6}
															MaxLength={10}
															Prefix="$"
															LabelText={setupFee.LOSSetupFee.ParameterDesc}
															Name={(model.Id == undefined ? 0 : model.Id) + "-" + setupFee.LOSSetupFeeId}
															Value={model.FeeLoanSetupProfileValueList[(model.Id == undefined ? 0 : model.Id) + "-" + setupFee.LOSSetupFeeId]}
															ValueChanged={this.HandleSetupFeeChange}
														>

														</GenericNumberInput>
													</GridItem>
												);
											})}

										</GridContainer>

									</GridItem>
									<GridItem xs={1}>
										{/* {
											this.state.model != null && this.state.model.Id > 0 ?
												<Button size="sm" color="black" onClick={this.FeeCalculatorDialogOpenOrClose}>Calculate</Button>
												:
												""
										} */}

									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>


						<Card className="no-radius" style={{ display: model.LoanType != null && (model.LoanType.ParameterValue != LOSLoanType.Loan) ? "none" : "inherit" }}>
							<CardBody>
								<GenericExpansionPanel
									Title="Term Rates">
									<GridItem xs={12}>
										<GridContainer
											direction="row"
											justify="flex-start"
											alignItems="stretch"
										>
											{model.FeeMonthlyRateProfileList != undefined && model.FeeMonthlyRateProfileList.map((monthlyFee, i, array) => {
												return (
													<GridItem xs={1} key={i}>
														<GridContainer
															direction="column"
															justify="flex-start"
															alignItems="stretch"
														>
															<GridItem xs={12} style={{
																border: "1px Solid #000"
															}}>
																<GenericLabel
																	Text={monthlyFee.LOSMonthlyRate.ParameterDesc}
																	TextAlign="center"
																	Bold
																	Display="block">
																</GenericLabel>
															</GridItem>
															<GridItem xs={12} style={{
																border: "1px Solid #000"
															}}>
																<GenericNumberInput
																	LabelMd={0}
																	LabelText={""}
																	Name={(model.Id == undefined ? 0 : model.Id) + "-" + monthlyFee.LOSMonthlyRateId}
																	Value={model.FeeMonthlyRateProfileValueList[(model.Id == undefined ? 0 : model.Id) + "-" + monthlyFee.LOSMonthlyRateId]}
																	ValueChanged={this.HandleMonthlyFeeChange}
																></GenericNumberInput>
															</GridItem>
														</GridContainer>
													</GridItem>
												);
											})}

										</GridContainer>

									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={modelList}
									PageSize={5}
									HideButton={true}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
									IsSorted={false}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >
			</div >
		);
	}
}

FeeDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(FeeDefinition, {});