import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateFilterInput, GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class LegalFollowUpThirdPartyTransfer extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			DateFilter: {
				BeginDate: DateHelper.GetDateLocal(),
				EndDate: DateHelper.GetDateLocal()
			}
		};

		this.state = {
			list: [],
			fPaymentHistory: [],
			fCommentHistory: [],
			model: this.initialModel,
			isLoading: false,
		};

		this.ColumnsData = [
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Application Number",
				accessor: "ApplicationNumber"
			},
			{
				Header: "Total Loan",
				accessor: "TotalLoan",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				width: 200
			},
			{
				Header: "Write Offs",
				accessor: "WriteOffs",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				width: 200
			},
			{
				Header: "Principal",
				accessor: "Principal",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				width: 200
			},
			{
				Header: "Interest",
				accessor: "Interest",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				width: 200
			},
			{
				Header: "Fee",
				accessor: "Fee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				width: 200
			},
			{
				Header: "No. of Delayed",
				accessor: "NumberOfDelayDays",
				width: 200
			}
		];

		this.ColumnsDataPaymentHistory = [
			{
				Header: "Last Payment Date",
				accessor: "LastPaymentDate",
				type: GridColumnType.Date
			},
			{
				Header: "Total Waiting Amount",
				accessor: "TotalWaitingAmount",
				type: GridColumnType.Date
			},
			{
				Header: "Minimum Payment",
				accessor: "MinimumPayment",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Last Collected Amount",
				accessor: "LastCollectedAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Last Collected Date",
				accessor: "LastCollectedDate",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];

		this.ColumnsDataCommentHistory = [
			{
				Header: "Call Status",
				accessor: "CallStatus.ParameterDesc"
			},
			{
				Header: "Interviewee",
				accessor: "Interviewee.ParameterDesc"
			},
			{
				Header: "Interview Result",
				accessor: "InterviewResult.ParameterDesc"
			},
			{
				Header: "Requested Call Date",
				accessor: "RequestedCallDate",
				type: GridColumnType.Date
			},
			{
				Header: "Person Name",
				accessor: "PersonName"
			},
			{
				Header: "Promise Date",
				accessor: "PromiseDate",
				type: GridColumnType.Date
			},
			{
				Header: "Collector Note",
				accessor: "CollectorNote"
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Legal Follow Up Third Party Transfer");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, rowIndex: -1, fPaymentHistory: [], fCommentHistory: [] });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/CollectionPool/LegalFollowUpThirdPartyTransferReport",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item.TransferList, paymentHistory: responseData.Item.PaymentHistoryList, commentHistory: responseData.Item.CommentHistoryList });
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	RowSelected = index => {
		var { paymentHistory, commentHistory } = this.state;
		var model = this.state.list[index];
		var fPaymentHistory = paymentHistory.filter(x => x.ApplicationId === model.ApplicationId);
		var fCommentHistory = commentHistory.filter(x => x.ApplicationId === model.ApplicationId);
		this.setState({ fPaymentHistory, fCommentHistory, rowIndex: index });
	};

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, list, fPaymentHistory, fCommentHistory, rowIndex } = this.state;
		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericDateFilterInput
												Name="DateFilter"
												LabelText="Date Filter"
												Model={model.DateFilter}
												BeginDate={model.BeginDate}
												EndDate={model.EndDate}
												ValueChanged={this.HandleChange}
												IncludeTime={false}
												Utc={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Transaction List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												Columns={this.ColumnsData}
												RowSelected={this.RowSelected}
												SelectedIndex={rowIndex}
												ShowPagination={true} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Payment History">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={fPaymentHistory}
												Columns={this.ColumnsDataPaymentHistory}
												ShowPagination={true} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Comment History">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={fCommentHistory}
												Columns={this.ColumnsDataCommentHistory}
												ShowPagination={true} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>
			</>
		);
	}
}

LegalFollowUpThirdPartyTransfer.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(LegalFollowUpThirdPartyTransfer, {});