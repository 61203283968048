import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericRadioInput,GenericGrid,GenericLabel,GenericDialog,GenericDialogActions, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankingAccountTypes, ClientType, ProgramCodes, GridColumnType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button.jsx";

class AccountBalanceSummary extends React.Component {
	constructor(props) {
		super(props);

		this.isBackoffice = ClientHelper.IsBackOffice();
		this.defaultModel = {
			UniqueClientId: 0
		};
		this.state = {
			vModel: {},
			searchModel: { ...this.defaultModel },
			customerTransactions: [],
			isLoading: false,
			ClientTypeParameter: {}
		};
		this.trxTable = React.createRef();
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
	}

	componentDidMount() {
		this.props.setAppCenterTitle("WALLET");

		if (!this.isBackoffice) {
			this.GetClientType();
			var tempDefaultModel = this.defaultModel;
			tempDefaultModel.UniqueClientId = ClientHelper.GetClientRowId();
			this.setState(function (state, props) {
				var model = state.searchModel || {};
				model.UniqueClientId = tempDefaultModel.UniqueClientId;
				return { searchModel: model };
			});
		}
		else {
			this.props.setAppCenterTitle("WALLET");

		}
		this.props.setAppLeftTitle("Wallet Summary Balance");

	}

	RenderAccountTypeParameter = (data) => {
		const parameter = { ...this.state.ClientTypeParameter };
		if (data == null) {
			return "";
		}
		if (data.AccountCode == null || data.AccountCode == "") {
			return data.AccountType;
		}
		if (data.AccountCode != BankingAccountTypes.PrimaryAccount) {
			return data.AccountType;
		}
		if (data.ProgramCode == null || data.ProgramCode == "") {
			return data.AccountType;
		}
		if (data.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
			return data.AccountType;
		}
		if (parameter.ParameterValue == ClientType.Corporate || parameter.ParameterValue == ClientType.SalesAgentCorporate) {
			return "Corporate Wallet";
		} else if (parameter.ParameterValue == ClientType.Personal || parameter.ParameterValue == ClientType.SalesAgentPersonal) {
			return "Individual Wallet";
		} else {
			return data.AccountType;
		}

	}
	GetClientType = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetClientType",
			{},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ ClientTypeParameter: responseData.Item });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	handleChangeSearchModel = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.searchModel || {};
			model[name] = newValue;
			return { searchModel: model };
		});
	}
	PerformSearch = () => {
		if (!this.IsValid())
			return;
		this.BankAccountTransactions();
	}
	IsValid = () => {
		var temp = { ...this.state.searchModel };
		const vModel = this.state.vModel;

		vModel.SearchDate = temp.SearchDate == null || temp.SearchDate == "";
		if (vModel.SearchDate) {
			this.ShowMessage("warning", "Warning", "Date must be selected for search operation !");
			return;
		}

		this.setState({ vModel });
		return true;
	}

	BankAccountTransactions = () => {
		let temp = { ...this.state.searchModel };
		temp.UniqueClientId = temp.UniqueClientId == null ? 0 : temp.UniqueClientId;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/AccountBalanceSummaryList",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.setState({ customerTransactions: [] });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				} else {
					if (responseData.Item != null && responseData.Item.length > 0) {
						this.setState({ customerTransactions: responseData.Item });
					}
					else {
						this.setState({ customerTransactions: [] });
					}
				}
			},
			error => {
				this.setState({ isLoading: false, customerTransactions: [] });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ClearData = () => {
		this.setState({
			alert: null, isLoading: false, searchModel: { ...this.defaultModel }, customerTransactions: [],
		});

	}
	handleChangeFilterPanel = panel => (event, expanded) => {
		this.setState({
			active: expanded ? panel : -1
		});
	};

	RenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["+", ""]
		};
	}
	BalanceRenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["", ""]
		};
	}


	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.IsValid())
			return;

		this.ShowConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			const { searchModel } = this.state;

			// Add jsonFile
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			searchModel["jsonFile"] = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: "Wallet Summary Balance Report"
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/bankapi/v1.0/Transaction/AccountBalanceSummaryReportExportList",
				searchModel,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", errorMessage);
				},
				this.props.showMessage
			);
		});
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	render() {
		const { Disabled } = this.props;
		const { searchModel, vModel, customerTransactions, alert } = this.state;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				{alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
							{ Code: "Search", OnClick: () => this.PerformSearch(), Disabled: Disabled },
							{ Code: "Export", OnClick: () => this.ExportClick(), Disabled: Disabled }
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={" Main Parameters "} />
							</CardHeader>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}></p></b>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GridContainer>
											{(this.isBackoffice) &&
												<GridItem xs={4}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelMd={4}
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
														Value={searchModel.UniqueClientId || ""}
														ValueChanged={this.handleChangeSearchModel}
														CanClear />
												</GridItem>
											}
											<GridItem xs={4}>
												<GenericDateInput
													Name="SearchDate"
													LabelText="Date"
													LabelMd={4}
													MaxDate={DateHelper.GetDate().add(-1, "d")}
													Value={searchModel.SearchDate == null ? "" : DateHelper.ToDateInputValueAsUtc(searchModel.SearchDate) || ""}
													ValueChanged={this.handleChangeSearchModel}
													Utc
													Required
													IsInvalid={vModel.SearchDate}
												/>

											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>


						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Balance List"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={customerTransactions}
											Columns={[
												{
													Header: "Client Unique ID",
													accessor: "ClientUniqueId",
													width: 120,
													show: this.isBackoffice
												},
												{
													Header: "Client",
													accessor: "ClientName",
													width: 120,
													show: this.isBackoffice
												},
												{
													width: 180,
													Header: "Wallet Name",
													accessor: "AccountName",
												},
												{
													Header: "Wallet Number",
													accessor: "AccountNumber",

												},
												{
													Header: "Chequing Wallet",
													accessor: "IsChequingAccount",
													Cell: row => (
														<div>{row.value==1?"Yes":"No"}</div>
													)
												},
												{
													Header: "Wallet Type",
													accessor: "AccountType",
													Cell: row => (
														<div>{this.RenderAccountTypeParameter(row.original)} </div>
													),
												},
												{
													Header: "Total Received Amount",
													accessor: "TotalDebitAmount",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Total Received Count",
													accessor: "TotalDebitCount",

												},
												{
													Header: "Total Sent Amount",
													accessor: "TotalCreditAmount",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Total Sent Count",
													accessor: "TotalCreditCount",

												},

												{
													Header: "End of Day Balance",
													accessor: "TotalBalanceAmount",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},


											]}
											IsSorted={false}
											PageSize={50}
											ShowPagination={true}
											ref={this.trxTable}

										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
				
						<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
							<DialogTitle>
								<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
							</DialogTitle>
							<DialogContent style={{ height: 300 }}>
								<GenericRadioInput
									Name="ExportReportType"
									LabelText="Report Type"
									IsStatic={true}
									StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
									KeyValueMember="Value"
									TextValueMember="Text"
									Value={this.state.ExportReportType}
									ValueChanged={this.ValueChanged}
								/>
								<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
								<GridItem style={{ marginTop: 20 }}>
									<Select
										value={this.state.ExportSelectedColumns}
										onChange={value => this.setState({ ExportSelectedColumns: value })}
										isMulti
										name="columns"
										getOptionLabel={d => d.title}
										options={this.state.GridKeyValueList}
										className="basic-multi-select"
										classNamePrefix="select"
									/>
								</GridItem>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
								<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>

					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

AccountBalanceSummary.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default AccountBalanceSummary;