import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes } from "views/Constants/Constant";

class CardStatus extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false
		}

		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemStatus = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "From Card Status",
				accessor: "FromCardStatusDescription"
			},
			{
				Header: "To Card Status",
				accessor: "ToCardStatusDescription"
			},
			{
				Header: "Is Allowed",
				accessor: "Allow"
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Status Matrix");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (newValue == null) {
			model[name] = 0;
		}

		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardStatus/Search",
			{
				FromCardStatusId: model.FromCardStatusId,
				ToCardStatusId: model.ToCardStatusId,
				IsAllowed: model.IsAllowed
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}


	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardStatus/InsertOrUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardStatus/InsertOrUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}



	HandleDelete = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardStatus/Delete",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}





	Validate = () => {
		const { model } = this.state;

		if (!model.FromCardStatusId) {
			this.props.showMessage("warning", "From Card", "Select the From Card to continue.");
			return false;
		}

		if (!model.ToCardStatusId) {
			this.props.showMessage("warning", "Customer not selected", "Select the To Card to continue.");
			return false;
		}

	return true;
	}

	RenderItemStatus = (d) => {
		this.renderItemStatus = `${d.Description} - ${d.ResponseCode}`;
		return this.renderItemStatus;
	}

	RowSelected = (index) => {
		let { list } = this.state;
		const tempModel = list[index];
		if (list[index].CardRequestId) {
			tempModel.Id = list[index].Id;
		}
		this.setState({ isEdit: true, model: tempModel, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />


				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id == 0 },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || model.Id == 0 },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0 },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Main Panel">
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="FromCardStatusId"
										LabelText="From Card Status"
										Method="POST"
										Url="/prepaidapi/v1.0/CardStatus/GetAll"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.Description}`}
										Value={model.FromCardStatusId}
										ValueChanged={this.HandleChange}
										CanClear
									/>
								</GridItem>

								<GridItem xs={4}>
									<GenericSelectInput
										Name="ToCardStatusId"
										LabelText="To Card Status"
										Method="POST"
										Url="/prepaidapi/v1.0/CardStatus/GetAll"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.Description}`}
										Value={model.ToCardStatusId}
										ValueChanged={this.HandleChange}
										CanClear
									/>

								</GridItem>
								<GridItem xs={4}>
									<GenericCheckInput
										Name="IsAllowed"
										LabelText="Is Allowed"
										Value={model.IsAllowed}
										ValueChanged={this.HandleChange}
									/>
								</GridItem>

							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>



				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={""} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardStatus.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CardStatus, {});