import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericTextInput } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class StatementMessageDisclosure extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Start Date",
				accessor: "StartDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "End Date",
				accessor: "EndDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Disclosure",
				accessor: "Disclosure"
			},
			{
				Header: "Messaging",
				accessor: "Message"
			}
		];

		this.maxLength1000 = { maxLength: 1000 };

		this.validateProperties = [{ "ExpiryDate": "Expiry date" }, { "Disclosure": "Disclosure" }, { "Message": "Message" }];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Statement Message");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	RowSelected = (index) => {
		const list = { ...this.state.list };
		var temp = list[index];

		temp.ExpiryDate = temp.EndDate == null
			? null
			: Formatter.FormatDateUtc(temp.EndDate);

		this.setState({ model: temp, selected: index });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/Statement/SearchMessageDisclosure", model);

		model.IsCode = false;
		model.IsDescription = false;
		model.IsOrder = false;

		this.setState({ model, list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	ClearDisclosure = () => {
		var { model } = this.state;
		model.Disclosure = null;

		this.setState({ model });
	}

	ClearMessage = () => {
		var { model } = this.state;
		model.Message = null;

		this.setState({ model });
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/lmsapi/v1.0/Statement/SaveMessageDisclosure", model, model, this.validateProperties, null, [this.Clear, this.Search], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiPost("/lmsapi/v1.0/Statement/SaveMessageDisclosure", model, model, this.validateProperties, null, [this.Clear, this.Search], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/lmsapi/v1.0/Statement/DeleteMessageDisclosure", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12} >
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={2}>
										<GenericDateInput
											Utc
											IsFuture
											Required
											IsInvalid={model.IsExpiryDate}
											Name="ExpiryDate"
											LabelText="Expiry Date"
											LabelMd={4}
											IncludeTime={false}
											Value={model.ExpiryDate ? Formatter.FormatDateUtc(model.ExpiryDate) : null}
											ValueChanged={this.ValueChanged} />
									</GridItem>
								</GridContainer>
								<GenericExpansionPanel Title="Disclosure">
									<GridContainer>
										<GridItem xs={12}>
											<GenericTextInput
												MultiLine
												Required
												Disabled={model.IsActive == false}
												IsInvalid={model.IsDisclosure}
												Name="Disclosure"
												LabelMd={0}
												RowCount={6}
												inputProps={this.maxLength1000}
												Value={model.Disclosure}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={9}>
										</GridItem>
										<GridItem xs={3}>
											<GridContainer justify="flex-end">
												<GridItem>
													<Button disabled={Disabled} size="sm" color="black" onClick={this.ClearDisclosure}>Clear</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
								<GenericExpansionPanel Title="Messaging">
									<GridContainer>
										<GridItem xs={12}>
											<GenericTextInput
												MultiLine
												Required
												Disabled={model.IsActive == false}
												IsInvalid={model.IsMessage}
												Name="Message"
												LabelMd={0}
												RowCount={6}
												inputProps={this.maxLength1000}
												Value={model.Message}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={9}>
										</GridItem>
										<GridItem xs={3}>
											<GridContainer justify="flex-end">
												<GridItem>
													<Button disabled={Disabled} size="sm" color="black" onClick={this.ClearMessage}>Clear</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

StatementMessageDisclosure.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default StatementMessageDisclosure;