import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import { DocumentIcon } from "core/Icons";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import { GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";

class Statement extends React.Component {
	constructor(props) {
		super(props);
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.columnsStatement = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="PDF File"
							Icon={DocumentIcon}
							OnClick={() =>
								props.ExecuteApiFileDownloadWithGenericResponse("/lmsapi/v1.0/Statement/GenerateStatementPdfFile", row.original,
									`${row.original.Statement.Application.ApplicationNumber}-Statement${Math.floor(Math.random() * Math.floor(999999))}`, "pdf")} />
					</div>
				),
				sortable: false,
				filterable: false
			},
			{
				Header: "Application Number",
				accessor: "Statement.Application.ApplicationNumber"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Statement Period Begin Date",
				accessor: "FormattedBeginDate"
			},
			{
				Header: "Statement Date",
				accessor: "FormattedEndDate"
			},
			{
				Header: "Statement Due Date",
				accessor: "FormattedDueDate"
			},
			{
				Header: "Opening Balance",
				accessor: "OpeningBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Closing Balance",
				accessor: "ClosingBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Minumum Payment Amount",
				accessor: "MinimumPayment",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Payments",
				accessor: "PaymentAmount"
			},
			{
				Header: "Refunds and Credits",
				accessor: "RefundsAndCredits",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Debit Adjustments",
				accessor: "DebitAdjustments",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Purchases",
				accessor: "PurchaseAmount"
			},
			{
				Header: "Cash Advance",
				accessor: "FormattedTotalCashAdvanceAmount"
			},
			{
				Header: "Fees Charged",
				accessor: "FeesCharged",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Interest Charged",
				accessor: "InterestCharged",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
		this.columnsStatementTransaction = [
			{

				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Transaction Date",
				accessor: "FormattedTransactionDate"
			},
			{
				Header: "Description",
				accessor: "Description"
			},

			{
				Header: "Transaction Amount",
				accessor: "FormattedAmount"
			},
			{
				Header: "Transaction Fee and Interest Amount",
				accessor: "FormattedFeeAmount"
			}
		];

		this.emptyObject = {};
		this.parameterApplicantId = {};

		this.renderItemLOCTransaction = {};

		this.sortedApplicantFullName = { Member: "Id", Desc: true };
		this.sortedTranDate = [{ id: "FormattedTransactionDate", desc: true }];


		this.state = {
			vModel: {},
			model: {
				ApplicantId: props.ApplicantId || -2,
				ApplicationId: props.ApplicationId || 0
			},
			list: [],
			isPaymentDialogOpen: false,
			isAdjustmentDialogOpen: false,
			isAdjustmentAdjustDialogOpen: false,
			panelCurrentTermIsActive: false,
			panelTransactionIsActive: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Statement List");
		}

		if (this.props.ApplicantId > 0) {
			this.Search();
		}
	}
	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			var list = state.list;
			var transactionList = state.transactionList;
			var currentTermTransactionList = state.currentTermTransactionList;

			model[name] = value;

			if (name == "ApplicantId") {
				list = [];
				transactionList = [];
				currentTermTransactionList = [];
				model.ApplicationId = null;
			}

			return { model, list, transactionList, currentTermTransactionList };
		});
	}

	GetParameterApplicantId = () => {
		const { model } = this.state;

		this.parameterApplicantId.ApplicantId = model.ApplicantId;

		return this.parameterApplicantId;
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/Statement/StatementSearch", model, null, null, this.ValidateSearch);

		if (result != null) {
			var list = result.StatementList || [];
			var currentTermTransactionList = result.CurrentTermTransaction || [];
			var StatementDate = result.StatementDate;
			var StatementBeginDate = result.StatementBeginDate;
			var LastPaymentDate = result.LastPaymentDate;
			var FormattedCardLimit = result.FormattedCardLimit;
			var WalletAccountBalance = result.WalletAccountBalance;
			var CardNumber = result.CardNumber;
			var CardFinancialId = result.CardFinancialId;
			var CATMCardId = result.CATMCardId;

			this.setState({
				list, transactionList: [],
				currentTermTransactionList, CardNumber, FormattedCardLimit, CardFinancialId, CATMCardId, WalletAccountBalance,
				StatementDate,
				StatementBeginDate,
				LastPaymentDate,
				panelCurrentTermIsActive: true
			});
		}
	}

	Clear = () => {
		this.setState({ model: {}, list: [], transactionList: [], currentTermTransactionList: [], selected: null, panelCurrentTermIsActive: false, panelTransactionIsActive: false });
	}

	ValidateSearch = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;
		var messages = [];

		if (!model.ApplicantId || model.ApplicantId == -2) {
			vModel.ApplicantId = true;
			messages.push("Applicant cannot be empty.");
		}
		else {
			vModel.ApplicantId = false;
		}

		if (!model.ApplicationId) {
			vModel.ApplicationId = true;
			messages.push("Application cannot be empty.");
		}
		else {
			vModel.ApplicationId = false;
		}


		if (messages.length > 0) {
			showValidationErrors(messages);

			return false;
		}

		this.setState({ vModel });

		return true;
	}

	GetStatementTransaction = async (statementId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/Statement/GetStatementTransaction", { Id: statementId });

		this.setState({ transactionList: result || [], panelTransactionIsActive: true });
	}

	SelectedRowChange = (index) => {
		const { list } = this.state;
		const model = list[index];

		if (model && model.Statement) {
			this.GetStatementTransaction(model.Statement.Id);
		}
	}

	MakeCardTransaction = () => {
		const { model, CardFinancialId, CATMCardId } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			CardFinancialId: CardFinancialId || 0,
			CATMCardId: CATMCardId || 0,
			Amount: model.Amount,
			TransactionDate: model.TransactionDate,
			TransactionCode: model.TransactionCode
		};

		ExecuteApiPost("/lmsapi/v1.0/lmstest/MakeCardTransaction", request, null, null, null, [this.Search]);
	}

	MakeDailyStatementProcess = () => {
		const { model, CardFinancialId, CATMCardId } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			CardFinancialId: CardFinancialId,
			CATMCardId: CATMCardId,
			ApplicationId: model.ApplicationId,
			ProcessLocalDate: model.TransactionDate
		};

		ExecuteApiPost("/lmsapi/v1.0/lmstest/SecureCardDailyStatementProcess", request, null, null, null, [this.Search]);
	}

	MakeDailySettlementProcess = () => {
		const { model, CardFinancialId, CATMCardId } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			CardFinancialId: CardFinancialId,
			CATMCardId: CATMCardId,
			ApplicationId: model.ApplicationId,
			ProcessLocalDate: model.TransactionDate
		};

		ExecuteApiPost("/lmsapi/v1.0/lmstest/SecureCardDailySettlementProcess", request, null, null, null, [this.Search]);
	}
	RenderItemApplication = (d) => {
		this.renderItemApplication = `${d.ApplicationNumber} - ${d.LoanType.ParameterDesc}`;
		return this.renderItemApplication;
	}

	render() {
		const { model, vModel, list, transactionList, currentTermTransactionList, panelCurrentTermIsActive, panelTransactionIsActive } = this.state;
		const { Disabled, IsPopUp } = this.props;
		var IsClient = ClientHelper.IsClient();

		return (
			<div>
				{!IsPopUp &&
					<ButtonToolbar
						ButtonList={[
							{
								Code: "Search",
								Disabled: Disabled,
								OnClick: this.Search
							},
							{
								Code: "Clear",
								Disabled: Disabled,
								OnClick: this.Clear
							}
						]}
						menuId={this.props.menuId}
						ApprovalData={this.props.ApprovalData}
						After={this.props.After}
						IsPopUp={IsPopUp} />
				}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.ValueChanged}
												CanClear
												All
												Required
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ApplicantId"
												LabelText="Applicant"
												Method="POST"
												Url={
													model.UniqueClientId
														? "/losapi/v1.0/Applicant/ListForStatement"
														: ""
												}
												Parameter={{ UniqueClientId: model.UniqueClientId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullName"
												Sorted={this.sortedApplicantFullName}
												Value={model.ApplicantId}
												ValueChanged={this.ValueChanged}
												Required
												IsInvalid={vModel.ApplicantId} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.ApplicantId}
												Name="ApplicationId"
												LabelText="Application Number"
												Method="POST"
												Url="/losapi/v1.0/Application/Search"
												Parameter={{
													ApplicantId: this.state.model.ApplicantId
												}}
												DataRoot="Item"
												IsStatic={this.state.model.ApplicantId == null || this.state.model.ApplicantId == 0 ? true : false}
												TaskPoolDomain={this.state.model.ApplicantId > 0 ? this.loadTaskPool : null}
												KeyValueMember="Id"
												LabelMd={5}
												RenderItem={this.RenderItemApplication}
												Value={model.ApplicationId}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												IsInvalid={vModel.ApplicationId}
												Required
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="BeginDate"
												LabelText="From Date"
												IncludeTime={false}
												Value={model.BeginDate}
												ValueChanged={this.ValueChanged}
												Utc />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="EndDate"
												LabelText="To Date"
												IncludeTime={false}
												Value={model.EndDate}
												ValueChanged={this.ValueChanged}
												Utc />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive
									Title="Statement List">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list}
														Columns={this.columnsStatement}
														RowSelected={this.SelectedRowChange} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={panelTransactionIsActive}
									Title="Statement Transactions">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={transactionList}
														Columns={this.columnsStatementTransaction}
														IsSorted={false} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={panelCurrentTermIsActive}
									Title="Current Term Transactions">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={currentTermTransactionList}
														Sorted={this.sortedTranDate}
														Columns={this.columnsStatementTransaction} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						{window.Environment != "Production" && <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={false}
									Title="Simulator">
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="flex-start">
										<GridItem xs={12}>
											<GridContainer
												direction="row"
												justify="flex-start"
												alignItems="flex-start">
												<GridItem xs={3}>
													<GenericTextInput
														LabelMd={5}
														Disabled
														LabelText="Card Number"
														Value={this.state.CardNumber} />
												</GridItem>

												<GridItem xs={3}>
													<GenericNumberInput
														LabelMd={6}
														LabelText="Card Available Balance"
														Value={this.state.WalletAccountBalance}
														Disabled
														AllowNegative>
													</GenericNumberInput>
												</GridItem>


											</GridContainer>
										</GridItem>

										<GridItem xs={12}>
											<GridContainer
												direction="row"
												justify="flex-start"
												alignItems="flex-start">

												<GridItem xs={3}>
													<GenericDateInput
														LabelMd={5}
														Name="StatementBeginDate"
														LabelText="Statement Begin Date"
														IncludeTime={false}
														Utc
														Value={this.state.StatementBeginDate ? DateHelper.ToMoment(this.state.StatementBeginDate) : ""}
														Disabled />
												</GridItem>
												<GridItem xs={3}>
													<GenericDateInput
														LabelMd={6}
														Name="StatementDate"
														LabelText="Statement Date"
														IncludeTime={false}
														Utc
														Value={this.state.StatementDate ? DateHelper.ToMoment(this.state.StatementDate) : ""}
														Disabled />
												</GridItem>
												<GridItem xs={3}>
													<GenericDateInput
														LabelMd={7}
														Name="LastPaymentDate"
														LabelText="Statement Due Date"
														IncludeTime={false}
														Utc
														Value={this.state.LastPaymentDate ? DateHelper.ToMoment(this.state.LastPaymentDate) : ""}
														Disabled />
												</GridItem>
												{/* <GridItem xs={2}>
													<GenericCheckInput
														Name="IsCashAdvance"
														LabelText="Is Cash Advance"
														Value={model.IsCashAdvance || false}
														ValueChanged={this.ValueChanged}
													/>
												</GridItem> */}
											</GridContainer>

										</GridItem>
										<GridItem xs={12}>
											<GridContainer
												direction="row"
												justify="flex-start"
												alignItems="flex-start">

												<GridItem xs={3}>
													<GenericDateInput
														LabelMd={7}
														Utc
														Name="TransactionDate"
														LabelText="Transaction Date"
														MaxDate={this.state.StatementDate ? DateHelper.ToMoment(this.state.StatementDate) : ""}
														MinDate={this.state.StatementBeginDate ? DateHelper.ToMoment(this.state.StatementBeginDate) : DateHelper.GetDate()}
														Value={model.TransactionDate ? DateHelper.ToMoment(model.TransactionDate) : ""}
														ValueChanged={this.ValueChanged}
														IncludeTime={false} />
												</GridItem>
												<GridItem xs={2}>
													<GenericNumberInput
														LabelMd={6}
														MaxLength={9999}
														key="Amount"
														Name="Amount"
														LabelText="Amount"
														Value={model.Amount == null || undefined
															? ""
															: model.Amount}
														ValueChanged={this.ValueChanged} />
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="TransactionCode"
														LabelText="Transaction"
														Method="POST"
														Url="/lmsapi/v1.0/lmstest/BankTransactionCodeSearchForSimulator"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="TransactionCode"
														TextValueMember="TransactionName"
														Value={model.TransactionCode}
														ValueChanged={this.ValueChanged} />
												</GridItem>

											</GridContainer>
										</GridItem>

										<GridItem xs={12}>
											<GridContainer
												direction="column"
												justify="flex-start"
												alignItems="stretch"											>
												<GridItem xs={2}>
													<Button
														fullWidth
														disabled={!(this.state.CardFinancialId > 0 || this.state.CATMCardId > 0)}
														size="sm"
														color="black"
														onClick={this.MakeCardTransaction}>
														Create Credit Transaction
													</Button>
												</GridItem>
												{/* <GridItem xs={2}>
													<Button
														fullWidth
														disabled={model.ApplicationId == null || !(this.state.CardFinancialId > 0 || this.state.CATMCardId > 0)}
														size="sm"
														color="black"
														onClick={this.MakeDailySettlementProcess}>
														Make Settlement
													</Button>
												</GridItem> */}
												<GridItem xs={2}>
													<Button
														fullWidth
														disabled={model.ApplicationId == null || !(this.state.CardFinancialId > 0 || this.state.CATMCardId > 0)}
														size="sm"
														color="black"
														onClick={this.MakeDailyStatementProcess}>
														Create Statement
													</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Statement.propTypes = {
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownload: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	IsPopUp: PropTypes.bool
};

export default Statement;