import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { RoleGroup, RoleLevel } from "views/Constants/Constant";

class UserRoleCopy extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.validateProperties = [{ "RoleGroupId": "Role group" }, { "SourceRoleLevelId": "Source role level" }, { "DestinationRoleLevelId": "Destination role level" },
		{ "SourceRoleId": "Source role" }, { "DestinationRoleId": "Destination role" }];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("User Role Copy");
		}

		var user = ClientHelper.GetUser();

		if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser) {
			this.setState({ isClientStandardUserOpen: true });
		}

		if (this.isClient) {
			this.GetRoleGroupClientId();
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "RoleGroupId") {
				model["RoleGroupParameterValue"] = data.ParameterValue;
				model["SourceRoleLevelId"] = undefined;
				model["SourceRoleId"] = undefined;
				model["DestinationRoleLevelId"] = undefined;
				model["DestinationRoleId"] = undefined;
				model["IsSourceRoleLevel"] = undefined;
				model["IsDestinationRoleLevel"] = undefined;

				this.GetRoleLevel(value);
				this.setState({ listSourceRole: [], listDestinationRole: [] });
			} else if (name == "SourceRoleLevelId") {
				model["SourceRoleLevelDesc"] = data.RoleLevel;
				model["SourceRoleId"] = undefined;
				model["IsSourceRoleId"] = undefined;

				this.GetRole(value, true);

				if (model.RoleGroupParameterValue == RoleGroup.Backoffice) {
					model["DestinationRoleLevelId"] = value;
					model["DestinationRoleLevelDesc"] = data.RoleLevel;
					model["DestinationRoleId"] = undefined;

					this.GetRole(value, false);
				}

			} else if (name == "DestinationRoleLevelId") {
				model["DestinationRoleLevelDesc"] = data.RoleLevel;
				model["DestinationRoleId"] = undefined;

				this.GetRole(value, false);
			}

			return { model };
		});
	}

	GetRoleGroupClientId = async () => {
		const { ExecuteApiPost } = this.props;

		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "RoleGroup", ParameterValue: RoleGroup.Client });
		var roleGroup = undefined;

		if (result != null && result.length == 1) {
			roleGroup = result[0];
		}

		this.setState({ model: { RoleGroupId: roleGroup && roleGroup.Id } });
		this.ValueChanged("RoleGroupId", roleGroup && roleGroup.Id, roleGroup);
	}

	GetRoleLevel = async (roleGroupId) => {
		const { MenuCode, ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/SearchRoleLevel", { RoleGroupId: roleGroupId, MenuCode });

		this.setState({ listSourceRoleLevel: result || [], listDestinationRoleLevel: result || [] });
	}

	GetRole = async (roleLevelId, isSource) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/GetRoleListByRoleLevelId", { RoleLevelId: roleLevelId });

		if (isSource) {
			this.setState({ listSourceRole: result || [] });
		} else {
			this.setState({ listDestinationRole: result || [] });
		}
	}

	Clear = () => {
		var { model } = this.state;

		if (!this.isClient) {
			model.RoleGroupId = undefined;
			this.setState({ model, listSourceRoleLevel: [], listDestinationRoleLevel: [], listSourceRole: [], listDestinationRole: [] });
		} else {
			model.SourceRoleLevelId = undefined;
			model.DestinationRoleLevelId = undefined;

			this.setState({ model, listSourceRole: [], listDestinationRole: [] });
		}
	}

	render() {
		const { model, listSourceRoleLevel, listDestinationRoleLevel, listSourceRole, listDestinationRole } = this.state;
		const { Disabled, ExecuteApiPost } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/Role/Copy", model, model, this.validateProperties, null, null, true)
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="User Role Copy">
									<GridContainer>
										<GridItem xs={4}>
											<ParameterComponent
												Required
												Disabled={this.isClient}
												IsInvalid={model.IsRoleGroupId}
												CanClear={false}
												Name="RoleGroupId"
												LabelText="Role Group"
												LabelMd={3}
												ParameterCode="RoleGroup"
												DefaultParamValue={this.isClient ? RoleGroup.Client : undefined}
												Value={model.RoleGroupId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic
												Required
												IsInvalid={model.IsSourceRoleLevelId}
												CanClear={false}
												Name="SourceRoleLevelId"
												LabelText="Source Role Level"
												LabelMd={3}
												StaticData={listSourceRoleLevel}
												DataRoot="Item"
												KeyValueMember="RoleLevelId"
												TextValueMember="RoleLevel"
												Value={model.SourceRoleLevelId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												IsStatic
												Disabled={model.RoleGroupParameterValue == RoleGroup.Backoffice}
												Required
												IsInvalid={model.IsDestinationRoleLevelId}
												CanClear={false}
												Name="DestinationRoleLevelId"
												LabelText="Destination Role Level"
												LabelMd={3}
												StaticData={listDestinationRoleLevel}
												DataRoot="Item"
												KeyValueMember="RoleLevelId"
												TextValueMember="RoleLevel"
												Value={model.DestinationRoleLevelId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic
												Required
												IsInvalid={model.IsSourceRoleId}
												CanClear={false}
												Name="SourceRoleId"
												LabelText="Source Role"
												LabelMd={3}
												StaticData={listSourceRole}
												DataRoot="Item"
												KeyValueMember="RoleId"
												TextValueMember="RoleName"
												Value={model.SourceRoleId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												IsStatic
												Required
												IsInvalid={model.IsDestinationRoleId}
												CanClear={false}
												Name="DestinationRoleId"
												LabelText="Destination Role"
												LabelMd={3}
												StaticData={listDestinationRole}
												DataRoot="Item"
												KeyValueMember="RoleId"
												TextValueMember="RoleName"
												Value={model.DestinationRoleId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

UserRoleCopy.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default UserRoleCopy;