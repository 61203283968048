import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import StringHelper from '../../core/StringHelper';

class TransferDescription extends React.Component {
	constructor(props) {
		super(props);

		this.isBackoffice = ClientHelper.IsBackOffice();
		this.clientRowId = ClientHelper.GetClientRowId();
		this.clientId = ClientHelper.GetClientId();
		this.initialModel = {
			Id: 0,
			UniqueClientId: this.isBackoffice ? undefined : this.clientRowId,
			NoteTypeId: null,
			Note: ""
		};

		this.state = {
			model: this.initialModel,
			list: [],
			index: -1
		};


		this.parameterUniqueClientId = {
			UniqueClientId: this.isBackoffice ? undefined : this.clientId
		};

		this.renderItemUniqueClient = {};

		this.maxLength400 = { maxLength: 400 };

		this.Columns = [
			{
				Header: "Client",
				accessor: "ClientName",
				show: this.isBackoffice
			},
			{
				Header: "Note Type",
				accessor: "NoteTypeParameterDesc"
			},
			{
				Header: "Note",
				accessor: "Note"
			}

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Transfer Description");
		this.props.setAppCenterTitle("NOTIFICATIONS");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = (softClear) => {
		var newModel = { ...this.initialModel };
		const { model } = this.state;

		if (softClear) {
			newModel.NoteTypeId = model.NoteTypeId;
			newModel.UniqueClientId = model.UniqueClientId;
		}
		this.setState({ model: newModel, index: - 1 });
	}

	HandleSearch = async () => {
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/CustomerNote/Search", this.state.model);
		this.setState({ list: result || [] });
	}



	HandleDelete = async () => {
		if (!await this.props.showConfirmMessage("warning", "Delete", "Are you sure to delete this record?")) return;
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/CustomerNote/Delete", { Id: this.state.model.Id });
		if (result) {

			this.HandleClear(true);
			this.HandleSearch();
			this.props.showMessage("success", "Success", "Deleted successfully!");
		}

	}

	HandleSubmitOrUpdate = async (workflowId, after) => {
		if (!this.Validate()) {
			return;
		}
		var isUpdate = this.state.model.Id > 0;
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/CustomerNote/InsertOrUpdate", this.state.model, null, null, null, null, null, workflowId);

		if (result) {
			if (after) after();
			this.HandleClear(true);
			this.HandleSearch();
			this.props.showMessage("success", "Success", (isUpdate ? "Update" : "Added") + " successfully!");
		}
	}

	onlyLetterAndDigit = (str) => {
		return StringHelper.VerifiyDigitAndLetter(str);
	}

	Validate = () => {
		const { model } = this.state;

		var validKeys = ["http:", "https:", "www.", "javascript", "function", "return"];
		var Messages = [];

		if (!model.UniqueClientId) {
			Messages.push("Client cannot be empty.");
			model.vUniqueClientId = true;
		}
		else {
			model.vUniqueClientId = false;
		}

		if (!model.NoteTypeId) {
			Messages.push("Note Type cannot be empty.");
			model.vNoteTypeId = true;
		}
		else {
			model.vNoteTypeId = false;
		}

		if (!model.Note) {
			Messages.push("Note cannot be empty");
			model.vNote = true;
		}
		else if (model.Note && model.Note.length < 3) {
			Messages.push("Note cannot be smaller than 3 character");
		}
		else if (model.Note) {
			model.vNote = false;
			validKeys.forEach(x => {
				if (model.Note.toLowerCase().includes(x) && !Messages.includes("Note contains invalid character.")) {
					Messages.push("Note contains invalid character.");
					model.vNote = true;
				}
			});
		}
		else {
			model.vNote = false;
		}




		if (Messages.length > 0) {
			this.props.showValidationErrors(Messages);
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ model: tempModel, index });
	}


	render() {
		const { Disabled } = this.props;
		const { alert, model, list, index } = this.state;


		return (
			<div>

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmitOrUpdate, Disabled: model.Id || Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: !model.Id || Disabled },
					{ Code: "Update", OnClick: this.HandleSubmitOrUpdate, Disabled: !model.Id || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters" >
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={!this.isBackoffice}
												DefaultIndex={!this.isBackoffice ? 0 : -1}
												Required
												IsInvalid={model.vUniqueClientId} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="NoteTypeId"
												LabelText="Note Type"
												ParameterCode="CustomerNoteType"
												Value={model.NoteTypeId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={model.vNoteTypeId} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="Note"
												LabelText="Note"
												Value={model.Note}
												ValueChanged={this.HandleChange}
												inputProps={this.maxLength400}
												Required
												IsInvalid={model.vNote}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Transfer Description List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

TransferDescription.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(TransferDescription, {});
