import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import {
  GenericNumberInput,
  GenericTextInput,
  GenericSelectInput,
  GenericRadioInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { LOSLoanType } from "views/Constants/Constant";
import { Proxy, withArtifex } from "core";

const LOSApplicationCardStatus = {
  Cancel: "3",
};

class PaymentAdminAccountCreate extends React.Component {
  constructor(props) {
    super(props);
    this.parameterEmbossType = { ParameterCode: "EmbossType" };
    this.state = {};
    this.maxLength21 = { maxLength: 21 };
  }
  componentDidMount() {
    if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Documents");
  }

  setValueToState = (name, value) => {
    const { ApplicationModel } = this.props;
    if (value == "CustomerNumber") {
      ApplicationModel.Emboss4thLine =
        ApplicationModel.Applicant?.Customer?.ClientCustomerNumber;
    }
    if (value == "Custom") {
      ApplicationModel.Emboss4thLine = "";
    }
    if (value == "CompanyName") {
      this.FillCompanyName();
    }
    if (name == "ExportReportType") {
      ApplicationModel.ExportReportType = value;
    }
    this.setState({ ApplicationModel });
  };
  FillCompanyName = () => {
    const { ApplicationModel } = this.props;

    this.setState({ isLoading: true });

    Proxy.POST(
      "/prepaidapi/v1.0/Prepaid/ClientForthLineSearch",
      { UniqueClientId: ApplicationModel.UniqueClientId },
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          if (
            responseData.Item[0] != null ||
            responseData.Item[0] != undefined
          ) {
            this.companyNameResult = responseData.Item[0];
            ApplicationModel.Emboss4thLine =
              this.companyNameResult.EmbossForthLine;
            this.setState({ ApplicationModel });
          } else {
            this.props.showMessage(
              "error",
              "Error",
              "Emboss4thLine cannot defined for this customer and product"
            );
            return;
          }
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  render() {
    const { ApplicationModel, CurrentCardLimit } = this.props;
    var IsDisabledForCustomer =
      ApplicationModel.Id == 0 ||
      (ApplicationModel &&
        ApplicationModel.Applicant &&
        ApplicationModel.Applicant.Customer);

    return (
      <GridContainer spacing={16}>
        {this.state.alert}
        <LoadingComponent Show={this.state.isLoading} />
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={
                  IsDisabledForCustomer && ApplicationModel.ApplicantHasUser
                }
                onClick={this.props.CreateCustomer}
              >
                Create Customer
              </Button>
            </GridItem>
            <GridItem xs={2}>
              <GenericTextInput
                LabelMd={6}
                Name="Account"
                LabelText="Customer Number"
                Value={
                  ApplicationModel.Applicant &&
                  ApplicationModel.Applicant.Customer != null
                    ? ApplicationModel.Applicant.Customer.CustomerNumber
                    : ""
                }
                ValueChanged={this.props.HandleChange}
                Disabled={true}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={2}>
              <Button
                size="sm"
                fullWidth
                disabled={
                  !IsDisabledForCustomer ||
                  ApplicationModel.Applicant.Customer == null ||
                  ApplicationModel.Applicant.WalletAccount
                }
                color="black"
                onClick={this.props.CreateWalletAccount}
              >
                Create Wallet Account
              </Button>
            </GridItem>
            <GridItem xs={2}>
              <GenericTextInput
                LabelMd={6}
                Name="WalletAccount"
                LabelText="Wallet"
                Value={
                  ApplicationModel.Applicant &&
                  ApplicationModel.Applicant.WalletAccount != null
                    ? ApplicationModel.Applicant.WalletAccount.AccountNumber
                    : ""
                }
                ValueChanged={this.props.HandleChange}
                Disabled={true}
              />
            </GridItem>
            {/* <GridItem xs={3}>
							<GenericNumberInput
								LabelMd={6}
								Name="WalletAccountBalance"
								LabelText="Wallet Balance"
								Prefix="$"
								Value={ApplicationModel.Applicant && ApplicationModel.Applicant.WalletAccount != null ? ApplicationModel.Applicant.WalletAccount.Balance : ""}
								ValueChanged={this.props.HandleChange}
								Disabled={true} />
						</GridItem> */}
          </GridContainer>
        </GridItem>
        {ApplicationModel.LoanType &&
          ApplicationModel.LoanType.ParameterValue != LOSLoanType.Loan && (
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={2}>
                  <Button
                    size="sm"
                    fullWidth
                    disabled={
                      !IsDisabledForCustomer ||
                      ApplicationModel.Applicant.WalletAccount == null ||
                      ApplicationModel.Applicant.SecurityAccount
                    }
                    color="black"
                    onClick={this.props.CreateSecurityAccount}
                  >
                    Create Security Number
                  </Button>
                </GridItem>
                <GridItem xs={2}>
                  <GenericTextInput
                    LabelMd={6}
                    Name="SavingsAccount"
                    LabelText="Security Number"
                    Value={
                      ApplicationModel.Applicant &&
                      ApplicationModel.Applicant.SecurityAccount != null
                        ? ApplicationModel.Applicant.SecurityAccount
                            .AccountNumber
                        : ""
                    }
                    ValueChanged={this.props.HandleChange}
                    Disabled={true}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <GenericNumberInput
                    LabelMd={6}
                    Name="SavingsAccountBalance"
                    LabelText="Security Balance"
                    Prefix="$"
                    Value={
                      ApplicationModel.Applicant &&
                      ApplicationModel.Applicant.SecurityAccount != null
                        ? ApplicationModel.Applicant.SecurityAccount.Balance
                        : ""
                    }
                    ValueChanged={this.props.HandleChange}
                    Disabled={true}
                  />
                </GridItem>
                <GridItem md={2}>
                  <GenericNumberInput
                    Disabled
                    LabelText="Current Card Limit"
                    LabelMd={5}
                    Value={CurrentCardLimit}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
        {ApplicationModel.LoanType &&
          ApplicationModel.LoanType.ParameterValue != LOSLoanType.Loan && (
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={2}>
                  <Button
                    size="sm"
                    fullWidth
                    disabled={ApplicationModel.Id == 0 || ApplicationModel.Applicant.SecurityAccount == null || (ApplicationModel && ApplicationModel.Applicant && ApplicationModel.Applicant.CardNumber)}
                    color="black"
                    onClick={this.props.CreateCard}
                  >
                    Create Card
                  </Button>
                </GridItem>
                <GridItem xs={2}>
                  <GenericTextInput
                    LabelMd={6}
                    Name="CardNumber"
                    LabelText="Card Number"
                    Value={
                      ApplicationModel.Applicant &&
                      ApplicationModel.Applicant.CardNumber
                    }
                    ValueChanged={this.props.HandleChange}
                    Disabled={true}
                  />
                </GridItem>
                <GridItem md={2}>
                  <GenericNumberInput
                    LabelMd={6}
                    Name="AvailableLimit"
                    LabelText="Available Balance"
                    Prefix="$"
                    Value={
                      ApplicationModel.Applicant &&
                      ApplicationModel.Applicant.AvailableLimit
                    }
                    ValueChanged={this.props.HandleChange}
                    Disabled={true}
                    AllowNegative={true}
                  />
                </GridItem>

                <GridItem md={2}>
                  <GenericTextInput
                    Disabled
                    Name="ApplicationCardStatusDesc"
                    LabelText="Card Status"
                    LabelMd={5}
                    Value={ApplicationModel.ApplicationCardStatusDesc}
                  />
                </GridItem>
                <GridItem md={2}>
                  <GenericTextInput
                    Name="CardEmbossName"
                    LabelText="Emboss Name"
                    Disabled={
                      ApplicationModel.Id == 0 ||
                      ApplicationModel.Applicant.SecurityAccount == null
                    }
                    LabelMd={5}
                    Value={
                      ApplicationModel.CardEmbossName ||
                      ApplicationModel.Applicant.FullName
                    }
                    ValueChanged={this.props.HandleChange}
                  />
                </GridItem>
                <GridItem xs={2} />
                <GridItem xs={2} />
                <GridItem md={2}>
                  <GenericSelectInput
                    Name="EmbossType"
                    LabelMd={6}
                    LabelText="Emboss Type"
                    Method="POST"
                    Url="/coreapi/v1.0/Parameter/Search"
                    Parameter={this.parameterEmbossType}
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember="ParameterDesc"
                    ValueChanged={this.props.HandleChange}
                    Value={ApplicationModel.EmbossType}
                    Disabled={ApplicationModel.Id == 0 || ApplicationModel.Applicant.SecurityAccount == null || (ApplicationModel && ApplicationModel.Applicant && ApplicationModel.Applicant.CardNumber)}
                    DefaultIndex={1}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <GenericSelectInput
                    Name="InstantEmbossBranchId"
                    LabelText="Card Perso Office"
                    LabelMd={6}
                    Method="POST"
                    Url="/prepaidapi/v1.0/Card/GetInstantCardBranchList"
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember="Description"
                    ValueChanged={this.props.HandleChange}
                    Value={ApplicationModel.InstantEmbossBranchId}
                    Disabled={ApplicationModel.Id == 0 || ApplicationModel.Applicant.SecurityAccount == null || (ApplicationModel && ApplicationModel.Applicant && ApplicationModel.Applicant.CardNumber) || ApplicationModel.IsMass}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
        {ApplicationModel.EmbossType == 3300 ? (
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={2}></GridItem>
              <GridItem xs={2}>
                <GenericTextInput
                  Name="Emboss4thLine"
                  LabelText="Emboss 4th Line"
                  inputProps={this.maxLength21}
                  Value={ApplicationModel.Emboss4thLine}
                  ValueChanged={this.props.HandleChange}
                  Disabled={ApplicationModel.ExportReportType != "Custom"}
                />
              </GridItem>
              <GridItem xs={3}>
                <GenericRadioInput
                  Name="ExportReportType"
                  LabelText="4th Line"
                  IsStatic={true}
                  StaticData={[
                    { Value: "CustomerNumber", Text: "Customer Number" },
                    { Value: "CompanyName", Text: "Company Name" },
                    { Value: "Custom", Text: "Custom" },
                  ]}
                  KeyValueMember="Value"
                  TextValueMember="Text"
                  Value={ApplicationModel.ExportReportType}
                  ValueChanged={this.setValueToState}
                  Disabled={false}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : null}
        {ApplicationModel.LoanType &&
        ApplicationModel.LoanType.ParameterValue != LOSLoanType.Loan &&
        ApplicationModel.ApplicationCardParameterValue !=
          LOSApplicationCardStatus.Cancel &&
        !ApplicationModel.Applicant.IsCardActive ? (
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={2}>
                <Button
                  size="sm"
                  fullWidth
                  disabled={
                    ApplicationModel.Id == 0 ||
                    !(
                      ApplicationModel &&
                      ApplicationModel.Applicant &&
                      ApplicationModel.Applicant.CardNumber
                    )
                  }
                  color="black"
                  onClick={this.props.ActivateCard}
                >
                  Activate Card
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : (
          ApplicationModel.LoanType &&
          ApplicationModel.LoanType.ParameterValue != LOSLoanType.Loan && (
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={2}>
                  <Button
                    fullWidth
                    size="sm"
                    disabled={
                      ApplicationModel.Id == 0 ||
                      ApplicationModel.Applicant == null ||
                      ApplicationModel.Applicant.SecurityAccount == null ||
                      ApplicationModel.Applicant.CardNumber == ""
                    }
                    color="black"
                    onClick={this.props.DebtInquiry}
                  >
                    Debt Inquiry
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          )
        )}
      </GridContainer>
    );
  }
}

PaymentAdminAccountCreate.propTypes = {
  classes: PropTypes.object,
  ApplicationModel: PropTypes.object,
  CreateCustomer: PropTypes.func,
  CreateSecurityAccount: PropTypes.func,
  CreateWalletAccount: PropTypes.func,
  CreateCard: PropTypes.func,
  ActivateCard: PropTypes.func,
  HandleChange: PropTypes.func,
};

export default withArtifex(PaymentAdminAccountCreate, {});
