import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericCheckInput, GenericNumberInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, BankingAccountTypes, ClientType, MenuCodes, ProgramCodes, BankingAccountType } from "views/Constants/Constant.js";
import { CardHeader } from "@material-ui/core";

class DcBank extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			internalModel: {},
			branchModel: {},
			CustomDisabled: null,
			vModel: {},
		};
		this.renderItemBranch = {};
		this.ParameterBankingAccountFunding = { ParameterCode: "BankingAccountType",ParameterValue2: "IsClient", ParameterValue4: "Account" };
		this.ParameterBankingAccount = { ParameterCode: "BankingAccountType", ParameterValue2: "IsClient" };
		this.ParameterBankingAccountOnly = { ParameterCode: "BankingAccountType", ParameterValue4: "Account" };
		this.Parameterwidth33 = { width: "33.6%" };
		this.Parameterwidth65 = { width: "65.7%" };
		this.ParameterAccountStatus = { ParameterCode: "AccountStatus" };
		this.ParameterIntendUseOfAccount = { ParameterCode: "IntendUseOfAccount" };
		this.ParameterFeeAccountMinBalance = [{ Value: 15000, Text: "15000" }, { Value: 10000, Text: "10000" }, { Value: 5000, Text: "5000" }];
		this.ParameterEmptyObject = {};
		this.ParameterMaxLength80 = { maxLength: 80 };
		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ internalModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original, "account")} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Wallet Type",
				accessor: "BankingAccountType.ParameterDesc",
				Cell: row => (
					<div>{this.RenderAccountTypeParameter(row.original)} </div>
				)
			},
			{
				Header: "Currency",
				accessor: "Currency.Code"
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
			{
				Header: "Transit",
				accessor: "Branch.TransitNo"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber",
				Cell: row => (
					props.paramGlobalModel.IsExceptClientSetup == true ? this.FilterIdentityNumber(row.value) : row.value
				)
			},
			{
				Header: "Full Wallet Number",
				accessor: "IBAN",
				Cell: row => (
					props.paramGlobalModel.IsExceptClientSetup == true ? this.FilterIdentityNumber(row.value) : row.value
				)
			},
			{
				Header: "Balance",
				accessor: "Balance",
				Cell: row => (
					<div>{(row.original.Balance == null || row.original.Balance == "") ? Formatter.FormatMoney(0) : Formatter.FormatMoney(row.original.Balance)}</div>
				)
			},
			{
				Header: "Pending Balance",
				accessor: "PendingBalance",
				Cell: row => (
					<div>{(row.original.PendingBalance == null || row.original.PendingBalance == "") ? Formatter.FormatMoney(0) : Formatter.FormatMoney(row.original.PendingBalance)}</div>
				)
			},
			{
				Header: "Total Balance",
				accessor: "TotalBalance",
				Cell: row => (
					<div>{row.original.Balance == null || row.original.Balance == "" ? Formatter.FormatMoney(0) : Formatter.FormatMoney(row.original.Balance + (row.original.PendingBalance == null || row.original.PendingBalance == "" ? 0 : row.original.PendingBalance))}</div>
				)
			},
			{
				Header: "Set Wallet Number",
				accessor: "IsExistingSystemAccount",
				Cell: row => (
					<div>{row.original.IsExistingSystemAccount == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Chequing Wallet",
				accessor: "IsChequingAccount",
				Cell: row => (
					<div>{row.original.IsChequingAccount == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Wallet Status",
				accessor: "AccountStatus.ParameterDesc"
			},
			{
				Header: "Intend Use Of Wallet",
				accessor: "IntendUseOfAccount.ParameterDesc"
			},
			{
				Header: "Store",
				accessor: "Store",
				Cell: row => {
					const store = row.value;
					const value = store != null ? store.StoreCode + " - " + store.StoreName : "";
					return (<div>{value}</div>);
				}
			},
		];
	}
	RenderAccountTypeParameter = (data) => {
		const global = { ...this.props.paramGlobalModel };
		if (data == null) {
			return "";
		}
		if (data.BankingAccountType == null) {
			return "";
		}
		if (data.BankingAccountType.ParameterValue == null || data.BankingAccountType.ParameterValue == "") {
			return "";
		}
		if (data.BankingAccountType.ParameterValue != BankingAccountTypes.PrimaryAccount) {
			return data.BankingAccountType.ParameterDesc;
		}
		if (data.Program == null || data.Program.ProgramCode == null) {
			return data.BankingAccountType.ParameterDesc;
		}
		if (data.Program.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
			return data.BankingAccountType.ParameterDesc;
		}
		if (global.ClientType == ClientType.Corporate || global.ClientType == ClientType.SalesAgentCorporate) {
			return "Corporate Wallet";
		} else if (global.ClientType == ClientType.Personal || global.ClientType == ClientType.SalesAgentPersonal) {
			return "Individual Wallet";
		} else {
			return data.BankingAccountType.ParameterDesc;
		}
	}
	RenderCurrencySelect = (d) => {
		return d.Code + " - " + d.Description;
	}
	RenderAccountTypeSelect = (d) => {
		const global = { ...this.props.paramGlobalModel };

		if (d.ParameterValue != BankingAccountTypes.PrimaryAccount) {
			return d.ParameterDesc;
		}

		if (global.ClientType == ClientType.Corporate || global.ClientType == ClientType.SalesAgentCorporate) {
			return "Corporate Wallet";
		} else if (global.ClientType == ClientType.Personal || global.ClientType == ClientType.SalesAgentPersonal) {
			return "Individual Wallet";
		} else {
			return d.ParameterDesc;
		}
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("DC Wallets");

		const global = { ...this.props.paramGlobalModel };
		if (global.Id == 0) {
			console.log("You must select a customer or create new one to add wallet. ");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ internalModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedDcAccounts, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}
	OperationCheck = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const data = this.state.internalModel;
		const global = { ...this.props.paramGlobalModel };
		if (global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a customer or create new one to add create wallet.");
		}
		if (data == null) {
			return;
		}

		if (data.IsExistingSystemAccount == true) {
			if (data.AccountNumber == null || data.AccountNumber == "") {
				vModel.AccountNumber = true;
				ErrorList.push("Wallet Number must be defined !.");
			} else { vModel.AccountNumber = false; }
		}
		if (data.BranchId == null || data.BranchId == "") {
			vModel.BranchId = true;
			ErrorList.push("Transit must be defined !.");
		} else { vModel.BranchId = false; }
		if (data.CurrencyId == null || data.CurrencyId == "") {
			vModel.CurrencyId = true;
			ErrorList.push("Currency must be defined !.");
		} else { vModel.CurrencyId = false; }
		if (data.AccountName == null || data.AccountName == "") {
			vModel.AccountName = true;
			ErrorList.push("Wallet Name must be defined !.");
		} else { vModel.AccountName = false; }
		if (data.ProgramId == null || data.ProgramId == "") {
			vModel.ProgramId = true;
			ErrorList.push("Program must be defined !.");
		} else { vModel.ProgramId = false; }
		if (data.AccountStatusId == null || data.AccountStatusId == "") {
			vModel.AccountStatusId = true;
			ErrorList.push("Wallet Status must be defined !.");
		} else { vModel.AccountStatusId = false; }
		if (data.IntendUseOfAccountId == null || data.IntendUseOfAccountId == "") {
			vModel.IntendUseOfAccountId = true;
			ErrorList.push("Intend Use Of Wallet must be defined !.");
		} else { vModel.IntendUseOfAccountId = false; }
		if (data.BankingAccountTypeId == null || data.BankingAccountTypeId == "") {
			vModel.BankingAccountTypeId = true;
			ErrorList.push("Wallet Type must be defined !.");
		} else { vModel.BankingAccountTypeId = false; }
		if (data.BankingAccountTypeStr == BankingAccountType.StoreAccount) {
			if (data.StoreId == null || data.StoreId <= 0) {
				vModel.StoreId = true;
				ErrorList.push("Store must be defined !.");
			} else { vModel.StoreId = false; }
		}
		if (data.IsFeeAccount == true && (data.FeeAccountMinBalance == null || data.FeeAccountMinBalance == "")) {
			vModel.FeeAccountMinBalance = true;
			ErrorList.push("Fee Wallet Min Balance must be defined !.");
		} else { vModel.FeeAccountMinBalance = false; }
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
		// this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?", () => {
		// 	this.hideAlert();
		// 	this.SendToWorkflow();
		// });
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	FilterIdentityNumber = (number) => {
		if (number == null) return "";

		var regexp = new RegExp("(.*)(.{4})");
		var onEk = regexp.exec(number)[1].replace(/([^ ])/g, "*");
		var sonEk = regexp.exec(number)[2];

		return onEk + sonEk;
	}
	SendToWorkflow = () => {
		var data = this.state.internalModel;
		const global = this.props.paramGlobalModel;

		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		data.UniqueClientId = global.Id;
		data.WorkflowUniqueClientId = global.UniqueClientId;
		data.WorkflowClientType = global.ClientType;
		data.WorkflowClientName = global.CustomerName;

		var jsonData = JSON.stringify(data);

		var requestData = {
			MenuCode: MenuCodes.DCBankAccount_Approve,
			ActionCode: ActionCodes.Submit,
			JsonData: jsonData,
			RowId: data.Id
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
			requestData,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Request successfully sent for approval");
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred while sending approve request " + typeof e == "object" ? JSON.stringify(e) : e);
			}
		);
	}
	UpdateModel = () => {
		var temp = this.state.internalModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Account/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Client Wallet Updated ! " : "Client Wallet Saved ! ");
				this.ClearData();
				this.props.GetCustomerDCBankAccountModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Client wallet must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/Account/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Client wallet deleted !");
				this.ClearData();
				this.props.GetCustomerDCBankAccountModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.internalModel || {};
			const branch = state.branchModel || {};
			model[name] = newValue;
			if (name == "ProgramId" && data != null) {
				model.ProgramCode = data == null ? "" : data.ProgramCode;
				model.BankingAccountTypeId = null;
				model.FeeAccountMinBalance = null;
				model.CurrencyId = null;
				model.BankingAccountTypeStr = "";
				model.IsFeeAccount = false;
			}
			if (name === "FinancialInstitutionId" && data != null) {
				branch.FinancialInstitutionDescription = data == null ? "" : data.Description;
				model.BankNumber = data == null ? "" : data.FinancialInstitutionId;
			}
			if (name === "BranchId" && data != null) {
				branch.FinancialInstitutionBranchDescription = data == null ? "" : data.Description;
				branch.FinancialInstitutionBranchAddress1 = data == null ? "" : data.Address1;
				branch.FinancialInstitutionBranchAddress2 = data == null ? "" : data.Address2;
				model.BranchNumber = data == null ? "" : data.TransitNumber;
				model.BranchId = data.Id;
			}
			if (name == "BankingAccountTypeId" && data != null) {
				if (data.ParameterValue == BankingAccountTypes.FeeAccount) {
					model.IsFeeAccount = true;
				} else {
					model.IsFeeAccount = false;
				}
				model.FeeAccountMinBalance = null;
				model.BankingAccountTypeStr = data == null ? "" : data.ParameterValue;
				if (model.Id < 1) {
					model.CurrencyId = null;
				}

			}
			return { internalModel: model, branchModel: branch };
		});
	}
	SelectedRowChange = (e, index) => {
		var model = this.props.paramBankCustomerInternalAccountList[index];
		if (model != null) {
			model.ProgramCode = model.Program == null ? "" : model.Program.ProgramCode;
		}
		this.setState({ internalModel: model });
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ internalModel: model, branchModel: model, vModel: {}, storeModel: {} });
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
	}
	showQuestionMessage = (message, model, name) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	GetRender = (x) => {
		this.renderItemBranch = x.TransitNumber + " (" + x.BranchName + ")";

		return this.renderItemBranch;
	}
	render() {
		const { Disabled: DisabledProp, paramBankCustomerContractList, paramBankCustomerInternalAccountList, paramGlobalModel } = this.props;
		const { internalModel, vModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;
		var clientPrograms = paramBankCustomerContractList == null ? [] : paramBankCustomerContractList.map(d => {
			return {
				Id: d.Id,
				ProgramId: d.ProgramId,
				ProgramName: d.Program.Description,
				ProgramCode: d.Program.ProgramCode
			};
		});
		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						{this.props.paramBankCustomerInternalAccountList.length < 1 && (
							<GenericLabel Text="Define at Least 1 Funding Wallet to Proceed" />
						)}
						<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeParentTab(null, 1)} > Back </Button>
						<Button size="sm" color="black" disabled={Disabled || (this.props.paramBankCustomerInternalAccountList.length < 1)} onClick={() => this.props.handleChangeChildTab(null, 1)} > Next </Button>
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<GenericExpansionPanel Title={`${window.Title} Wallets`}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GridContainer>
										<GridItem xs={6}>
											<GenericSelectInput
												Disabled={internalModel == null ? Disabled : internalModel.Id > 0 ? true : Disabled}
												Name="ProgramId"
												IsStatic={true}
												StaticData={clientPrograms}
												LabelText="Program"
												Value={internalModel.ProgramId || ""}
												ValueChanged={this.handleChange}
												KeyValueMember="ProgramId"
												TextValueMember="ProgramName"
												Required
												IsInvalid={vModel.ProgramId}
											/>
										</GridItem>
										<GridItem xs={6}>
											{(internalModel.ProgramCode == ProgramCodes.BANK_PROGRAMS_Card_Transfers) ? (

												<GenericSelectInput
													key={"3" + this.props.paramGlobalModel == null ? "5" : this.props.paramGlobalModel.ClientType + "" + internalModel.ProgramId}
													Disabled={Disabled}
													Name="BankingAccountTypeId"
													LabelText="Wallet Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterBankingAccountFunding}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={internalModel.BankingAccountTypeId || ""}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.BankingAccountTypeId}
												/>
											) : (
													internalModel.ProgramCode == ProgramCodes.Bank_Programs_Bank_Account ? (
														<GenericSelectInput
															key={"3" + this.props.paramGlobalModel == null ? "5" : this.props.paramGlobalModel.ClientType + "" + internalModel.ProgramId}
															Disabled={Disabled}
															Name="BankingAccountTypeId"
															LabelText="Wallet Type"
															Method="POST"
															Url="/coreapi/v1.0/Parameter/Search"
															Parameter={this.ParameterBankingAccountOnly}
															DataRoot="Item"
															RenderItem={this.RenderAccountTypeSelect}
															KeyValueMember="Id"
															TextValueMember="ParameterDesc"
															Value={internalModel.BankingAccountTypeId || ""}
															ValueChanged={this.handleChange}
															Required
															IsInvalid={vModel.BankingAccountTypeId}
														/>
													) : (
															<GenericSelectInput
																key={"3" + this.props.paramGlobalModel == null ? "5" : this.props.paramGlobalModel.ClientType + "" + internalModel.ProgramId}
																Disabled={internalModel?.BankingAccountType?.ParameterValue == BankingAccountType.StoreAccount ? true : Disabled}
																Name="BankingAccountTypeId"
																LabelText="Wallet Type"
																Method="POST"
																Url="/coreapi/v1.0/Parameter/Search"
																Parameter={this.ParameterBankingAccount}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Value={internalModel.BankingAccountTypeId || ""}
																ValueChanged={this.handleChange}
																Required
																IsInvalid={vModel.BankingAccountTypeId}
															/>
														))}

										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={6}>
											<GenericSelectInput
												TitleLabel="Fins. Ins. No."
												LabelStyle={this.Parameterwidth33}
												SelectStyle={this.Parameterwidth65}
												LabelMd={-1}
												Name="FinancialInstitutionRowId"
												LabelText="Wallet No"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={46}
												ValueChanged={this.handleChange}
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												TitleLabel="Transit"
												Method="GET"
												LabelMd={0}
												Name="BranchId"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												Value={internalModel.BranchId || ""}
												ValueChanged={this.handleChange}
												RenderItem={this.GetRender}
												Disabled={Disabled || internalModel.Id > 0}
												Required
												IsInvalid={vModel.BranchId}
											/>
										</GridItem>
										<GridItem xs={3}>
											{(paramGlobalModel.IsExceptClientSetup == true) ? (
												<GenericTextInput
													LabelMd={0}
													Name="AccountNumber"
													Value={this.FilterIdentityNumber(internalModel.AccountNumber)}
													ValueChanged={this.handleChange}
													Disabled={true}
												/>
											) : (
													<GenericTextInput
														LabelMd={0}
														InputStyle={{ marginLeft: 3 }}
														inputProps={{ maxLength: 12 }}
														Value={internalModel.AccountNumber || ""}
														ValueChanged={this.handleChange}
														Disabled={true}
													/>
												)}

										</GridItem>
									</GridContainer>
									<br />
									<GridContainer>
										{(internalModel.Id <= 0) && (
											<GridItem xs={3}>
												<GenericCheckInput
													Disabled={Disabled || internalModel.Id > 0}
													Name="IsExistingSystemAccount"
													LabelText="Is Set Wallet Number"
													Value={internalModel.IsExistingSystemAccount || false}
													ValueChanged={this.handleChange}
												/>
											</GridItem>
										)}
										{internalModel.IsExistingSystemAccount == true && (
											<GridItem xs={6}>
												<GenericNumberInput
													Disabled={Disabled || internalModel.Id > 0 || internalModel.Id > 0}
													NoFormatting={true}
													Name="AccountNumber"
													LabelText="Set Wallet Number"
													LabelMd={6}
													InputStyle={{ marginLeft: 3 }}
													MaxLength={15}
													Value={internalModel.AccountNumber || ""}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.AccountNumber}
												/>
											</GridItem>
										)}
										<GridItem xs={12}></GridItem>
										<GridItem xs={3}>
											<GenericCheckInput
												Disabled={Disabled}
												Name="IsChequingAccount"
												LabelText="Is Chequing Wallet"
												Value={internalModel.IsChequingAccount || false}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									{(internalModel.BankingAccountTypeStr == BankingAccountType.StoreAccount || (internalModel.Id > 0 && internalModel.StoreId > 0)) &&
										<GenericSelectInput
											key={"StoreId" + internalModel.UniqueClientId}
											Name="StoreId"
											LabelText="Store"
											Method="POST"
											Url="/bankapi/v1.0/CustomerStore/GetByUniqueClientRowId"
											Parameter={{ UniqueClientId: internalModel.UniqueClientId, IsUniqueClientRowId: true }}
											DataRoot="Item"
											KeyValueMember="Id"
											Value={internalModel.StoreId}
											ValueChanged={this.handleChange}
											RenderItem={(d) => { return d.StoreCode + " - " + d.StoreName; }}
											Required
											IsInvalid={vModel.StoreId}
											Disabled={Disabled || internalModel.Id > 0} />}
									{(internalModel.BankingAccountTypeStr == BankingAccountTypes.PrimaryAccount || internalModel.BankingAccountTypeStr == BankingAccountTypes.FeeAccount) ? (
										<GenericSelectInput
											key={internalModel.BankingAccountTypeId + ""}
											Disabled={Disabled || internalModel.Id > 0}
											Name="CurrencyId"
											LabelText="Currency"
											Value={internalModel.CurrencyId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="Description"
											Method="GET"
											Url="/bankapi/v1.0/Currency/ValidCurrenciesOnlyCad"
											RenderItem={this.RenderCurrencySelect}
											DataRoot="Item"
											Required
											IsInvalid={vModel.CurrencyId}
										/>
									) : (
											<GenericSelectInput
												Disabled={Disabled || internalModel.Id > 0}
												Name="CurrencyId"
												LabelText="Currency"
												Value={internalModel.CurrencyId || ""}
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="Description"
												Method="GET"
												Url="/bankapi/v1.0/Currency/ValidCurrencies"
												RenderItem={this.RenderCurrencySelect}
												DataRoot="Item"
												Required
												IsInvalid={vModel.CurrencyId}
											/>
										)}

									<GenericTextInput
										inputProps={this.ParameterMaxLength80}
										Disabled={Disabled}
										Name="AccountName"
										LabelText="Wallet Name"
										Value={internalModel.AccountName || ""}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.AccountName}
									/>
									<GenericSelectInput
										Disabled={Disabled}
										Name="AccountStatusId"
										LabelText="Wallet Status"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterAccountStatus}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={internalModel.AccountStatusId || ""}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.AccountStatusId}
									/>
									<GenericSelectInput
										Disabled={Disabled}
										Name="IntendUseOfAccountId"
										LabelText="Intended Use of Wallet"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterIntendUseOfAccount}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={internalModel.IntendUseOfAccountId || ""}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.IntendUseOfAccountId}
									/>
								</GridItem>
								<GridItem xs={2}>
									{((internalModel != null && internalModel.FeeAccountMinBalance != null) || (internalModel.IsFeeAccount == true)) && (
										<GenericSelectInput
											Disabled={Disabled}
											Name="FeeAccountMinBalance"
											LabelText="Fee Wallet Min. Balance"
											Value={internalModel.FeeAccountMinBalance || ""}
											ValueChanged={this.handleChange}
											IsStatic={true}
											StaticData={this.ParameterFeeAccountMinBalance}
											KeyValueMember="Value"
											TextValueMember="Text"
											Required
											IsInvalid={vModel.FeeAccountMinBalance}
										/>
									)}
								</GridItem>
							</GridContainer>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => { this.OperationCheck(); }}>{internalModel.Id > 0 ? "Update" : "Add"}</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={paramBankCustomerInternalAccountList}
									PageSize={5}
									HideButton={true}
									Columns={this.CloumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}

DcBank.propTypes = {
	classes: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerInternalAccountList: PropTypes.array,
	GetCustomerDCBankAccountModels: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	paramBankCustomerContractList: PropTypes.array
};


export default withArtifex(DcBank, {});