import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withRedux } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericLabel, GenericNumberInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import RouteHelper from "core/RouteHelper";
import { InteracRedirectURL } from "views/Constants/Constant";

class InteracCardHolderPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			model: {}, vModel: {},
			DCLogo: {}, HRLogo: {},
			InteracUrlData: {}
		};

		this.cardHeaderStyle = { height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" };
		this.cardMediaStyle = {
			height: "100%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 10,
			backgroundSize: "100% 100%"
		};
		this.cardBodyStyle = { marginTop: 62, width: "83%", marginLeft: "auto", marginRight: "auto" };
	}

	componentDidMount() {

	}

	DefaultImageClick = (url, Redirect) => {
		if (url == null || url == "") {
			this.ShowMessage("error", "Error", "Redirect url must be valid.");
			return;
		}
		var urldata = RouteHelper.Read(InteracRedirectURL.LocalStorageKey) || {};

		if (Redirect) {
			var uri = url + "?";
			if (urldata.ETID != null && urldata.ETID != "") {
				uri += `ETID=${urldata.ETID}`;
			}
			if (urldata.RQETID != null && urldata.RQETID != "") {
				uri += `RQETID=${urldata.RQETID}`;
			}
			if (urldata.src != null && urldata.src != "") {
				uri += `&src=${urldata.src}`;
			}
			if (urldata.fiID != null && urldata.fiID != "") {
				uri += `&fiID=${urldata.fiID}`;
			}
			if (urldata.cuID != null && urldata.cuID != "") {
				uri += `&cuID=${urldata.cuID}`;
			}
			if (urldata.lang != null && urldata.lang != "") {
				uri += `&lang=${urldata.lang}`;
			}
			window.location.href = uri;
		} else {
			RouteHelper.Push(this.props.history, url, InteracRedirectURL.LocalStorageKey, urldata);
		}
	}
	Validate = () => {
		let { vModel, model } = this.state;
		vModel.CustomerNumber = model.CustomerNumber == null || model.CustomerNumber == "";
		if (vModel.CustomerNumber) {
			this.ShowMessage("warning", "Warning", "Customer Number cannot be null");
			return false;
		}
		return true;
	}
	PerformSubmitAction = () => {
		this.setState({ isLoading: true });
		if (!this.Validate()) return;
		Proxy.POST(
			"/bankapi/v1.0/InteracRedirectUrlMap/GetClientUrl",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.DefaultImageClick(responseData.Item.RedirectUrl, true);
				} else {
					this.ShowMessage("error", "Error", "Defined URL for the Customer not found!");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	handleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.PerformSubmitAction();
		}
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert, vModel } = this.state;
		const logo = require("assets/img/" + window.LoginLogo);




		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading} />
				{alert}
				<GridContainer justify="center">
					<GridItem style={{ minWidth: "600px", maxWidth: "656px", marginTop: "200px" }}>
						<form>
							<Card login>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="white" style={this.cardHeaderStyle}>
									<CardMedia className={classes.media} image={logo} style={this.cardMediaStyle} title="" />
								</CardHeader>
								<CardBody style={this.cardBodyStyle}								>
									<GridContainer direction="column" justify="flex-start" alignItems="stretch">
										<GridItem>
											<GenericNumberInput
												FloatRight={true}
												LabelMd={5}
												NoFormatting={true}
												Name="CustomerNumber" LabelText="Customer Number"
												Value={model.CustomerNumber} ValueChanged={this.handleChange}
												KeyPressed={this.handleKeyPress}
												Required
												IsInvalid={vModel.CustomerNumber} />

											<br />
											<GridItem style={{ marginTop: "30px", marginBottom: "10px", marginLeft: "30px" }}>
												<GenericLabel Text="Please enter your customer number to fulfill the money request." />
											</GridItem>
											<br />
											<GridContainer
												container
												direction="row"
												justify="flex-start"
												alignItems="flex-start"											>
												<GridItem md={5}></GridItem>
												<GridItem md={7}>
													<Button color="primary" size="lg" block onClick={() => this.PerformSubmitAction()}>
														Perform Transaction
													</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracCardHolderPage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRedux(InteracCardHolderPage, loginPageStyle);