import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ClientSetupIdExpireDateList extends Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			Id: 0,
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined,
		};

		this.state = {
			alert: null,
			model: { ...this.initialModel },
			list: [],
			isLoading: false
		};

		this.parameterClientType = {
			ParameterCode: "ClientType",
			ParameterValue3: "D"
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined
		};

		this.parameterCustomerName = {};

		this.renderItemUniqueClient = {};
		this.renderItemClientCustomer = {};

		this.ColumnsData = [
			{
				Header: "Client Name",
				accessor: "ClientName",
				width: 200
			},
			{
				Header: "ClientType",
				accessor: "ClientType",
				width: 200
			},
			{
				Header: "Beneficary Owner",
				accessor: "BeneficaryOwner",
				width: 200
			},
			{
				Header: "Authorized Signing Officer",
				accessor: "AuthorizedSigningOfficer",
				width: 200
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "ID Expire Date",
				accessor: "IdentityExpireDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Number of Remaining Days",
				accessor: "NumberOfRemainingDays"
			},
			{
				Header: "B.O ID Expire Date",
				accessor: "IdentityExpireDate2",
				type: GridColumnType.DateUtc
			},
			{
				Header: "B.O Number of Remaining Days",
				accessor: "NumberOfRemainingDays2"
			}
		];

		this.sortName = { Member: "Name" };
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Client Setup ID Expire Date List");
		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("CLIENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		this.setState({ model });
	}

	HandleSearch = () => {
		this.setState({ alert: null, isLoading: true });
		Proxy.POST("/bankapi/v1.0/CustomerIdentification/SearchExpiresSoon",
			this.state.model,
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({ list: responseData.Item, isLoading: false });
				}
				else {
					this.setState({ list: [], isLoading: false });
				}
			},
			this.ErrorCallback
		);
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: null })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm, showCancel = true) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={showCancel} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { model, alert, isLoading, ReadOnly } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: ReadOnly || Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										{!this.isClient &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													All
													Disabled={this.isClient}
													DefaultIndex={this.isClient ? 0 : -1} />
											</GridItem>}
										<GridItem xs={3}>
											<GenericDateInput
												Name="IdentityExpireDate"
												LabelText="ID Expire Date"
												Value={model.IdentityExpireDate ? DateHelper.ToDateInputValue(model.IdentityExpireDate) : ""}
												ValueChanged={this.HandleChange}
												IncludeTime={false}
												IsFuture={true}
												Utc={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												Name="NumberOfRemainingDays"
												LabelMd={5}
												LabelText="Number of Remaining Days"
												Value={model.NumberOfRemainingDays}
												ValueChanged={this.HandleChange}
												MaxLength={3}
												NoFormatting={true} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="ID Expire Date List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.list}
												Columns={this.ColumnsData}
												SelectedIndex={this.state.rowIndex}
												IsSorted={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ClientSetupIdExpireDateList.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientSetupIdExpireDateList, {});