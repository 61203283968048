import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class FreeProductProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			vModel:{},
			model: {},
			profileList: [],
			isClient: ClientHelper.IsClient(),
		};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.setState({ model: { ...row.original } })} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Execution Type",
				accessor: "ExecutionType.ParameterDesc"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType.ParameterDesc"
			},
			{
				Header: "Discount Percentage",
				accessor: "DiscountPercentage.ParameterDesc"
			},
			{
				Header: "Transaction Name",
				accessor: "UniqueOperationCode.Description"
			},
			{
				Header: "Profile Status",
				accessor: "ProfileStatus.ParameterDesc"
			},
			{
				Header: "Start Date",
				accessor: "StartDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Expire Date",
				accessor: "ExpireDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			}
		];

		this.ParameterTransactionType = { ParameterCode: "TransactionType" };
		this.ParameterDiscountPercentage = { ParameterCode: "FreeProductDiscountPercentage" };
		this.ParameterExecutionType = { ParameterCode: "CampaignExecutionType" };
		this.ParameterMaxLen50 = { maxLength: 50 };

	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Free Product Profile");
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}

	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];
		if (!model.ProfileName) {
			errors.push("Profile Name can not be null.");
			vModel.ProfileName = true;
		} else { vModel.ProfileName = false; }

		this.setState({ vModel });
		if (errors.length > 0) {
			showValidationErrors(errors);
			return false;
		}
		return true;
	}

	UpdateModel = () => {
		if (!this.Validate()) {
			return;
		}
		var temp = this.state.model;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignFreeProductProfile/Update",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", temp.Id > 0 ? "Free Product Profile Updated ! " : "Free Product Profile Saved ! ");
				this.SearchProfiles();
				this.ClearData();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SearchProfiles = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignFreeProductProfile/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ profileList: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.props.showMessage("error", "Error", "Free product profile must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignFreeProductProfile/Delete",
			{ Id: this.state.model.Id },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Free product profile deleted !");
				this.ClearData();
				this.SearchProfiles();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (index) => {
		const temp = this.state.profileList[index];
		this.setState({ model: temp });
	}
	ClearData = () => {
		this.setState({
			alert: null,
			isLoading: false,
			model: {},
			vModel:{},
			profileList: [],
		});
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}
	render() {
		const { Disabled } = this.props;
		const { isLoading, model, profileList, vModel } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.UpdateModel, Disabled: Disabled || model.Id > 0 || !this.state.isClient },
					{ Code: "Update", OnClick: this.UpdateModel, Disabled: Disabled || !model.Id > 0 || !this.state.isClient },
					{ Code: "Search", OnClick: this.SearchProfiles, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
					{ Code: "Delete", OnClick: () => this.props.showConfirmMessage("warning", "Warning", "Delete this Free Product Profile ?", this.DeleteModel), Disabled: Disabled || !model.Id > 0 || !this.state.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Profile Definition"} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							{!this.state.isClient && (
								<GridItem xs={3} >
									<GenericSelectInput
										Name="UniqueClientId"
										LabelMd={4}
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemUniqueClient}
										Value={model.UniqueClientId}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={this.state.isClient}
										DefaultIndex={this.state.isClient ? 0 : -1}
									/>
								</GridItem>
							)}
							<GridItem xs={3} >
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									Name="ProgramId"
									LabelText="Program"
									LabelMd={4}
									Value={model.ProgramId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLen50}
									Name="ProfileName"
									LabelText="Profile Name"
									Value={model.ProfileName || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.ProfileName}
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericTextInput
									Disabled={Disabled}
									Name="ProfileCode"
									LabelText="Profile Code"
									Value={model.ProfileCode || ""}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
							<GridItem xs={3} >

							</GridItem>
						</GridContainer>
						<GridContainer spacing={16}>
							<GridItem xs={2}>
								<GenericDateInput
									Name="StartDateTime"
									LabelText="Start Date"
									Value={model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
									LabelMd={6}
								/>
							</GridItem>
							<GridItem xs={1} >
								<GenericDateInput
									Name="StartDateTime"
									LabelMd={0}
									DateFormat={false}
									IncludeTime={true}
									ViewMode="time"
									Value={model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericDateInput
									Name="ExpireDateTime"
									LabelText="Expire Date"
									LabelMd={6}
									Value={model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
								/>
							</GridItem>
							<GridItem xs={1} >
								<GenericDateInput
									Name="ExpireDateTime"
									LabelMd={0}
									DateFormat={false}
									IncludeTime={true}
									ViewMode="time"
									Value={model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Profile Details"} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={3} >
								<GenericSelectInput
									LabelText="Execution Type"
									Disabled={Disabled || model.Id > 0}
									Name="ExecutionTypeId"
									Value={model.ExecutionTypeId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterExecutionType}
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericSelectInput
									LabelText="Discount Percentage"
									Disabled={Disabled}
									Name="DiscountPercentageId"
									Value={model.DiscountPercentageId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterDiscountPercentage}
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericSelectInput
									LabelText="Transaction Type"
									Disabled={Disabled}
									Name="TransactionTypeId"
									Value={model.TransactionTypeId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="GET"
									Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
									Parameter={this.ParameterTransactionType}
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericNumberInput
									Disabled={Disabled}
									MaxValue={20}
									DecimalScale={0}
									Name="NumberOfTransaction"
									LabelText="Number Of Transaction"
									Value={model.NumberOfTransaction}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={3} >
								<GenericSelectInput
									key={"xncd" + model.ProgramId}
									LabelText="Free Product Operation"
									Disabled={Disabled}
									Name="UniqueOperationCodeId"
									Value={model.UniqueOperationCodeId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="POST"
									Url="/bankapi/v1.0/UniqueOperationCode/FilterByPogramId"
									Parameter={{ Id: model.ProgramId }}
									DataRoot="Item"
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Profile List"} />
					</CardHeader>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={profileList}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div>
		);
	}
}

FreeProductProfile.propTypes = {
	classes: PropTypes.object,
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func
};

export default withArtifex(FreeProductProfile, {});