import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { MaxLength150, MaxLength500 } from "views/Constants/Constant.MaxLength";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName, SortedOrderIndex, SortedStoreName } from "views/Constants/Constant.Sorted";

class OnlineSupport extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Client",
				accessor: "CustomerName"
			},
			{
				Header: "Store",
				accessor: "CustomerStoreStoreName"
			},
			{
				Header: "Problem Group",
				accessor: "ProblemGroupParameterDesc"
			},
			{
				Header: "Priority",
				accessor: "PriorityParameterDesc"
			},
			{
				Header: "E-Mail",
				accessor: "MailAddress"
			},
			{
				Header: "Office Phone Country Code",
				accessor: "OfficePhonePhoneCountryCodeName"
			},
			{
				Header: "Office Phone Number",
				accessor: "OfficePhonePhoneNumber"
			},
			{
				Header: "Mobile Country Code",
				accessor: "MobilePhonePhoneCountryCodeName"
			},
			{
				Header: "Mobile Number",
				accessor: "MobilePhonePhoneNumber"
			},
			{
				Header: "Problem Description",
				accessor: "ProblemDescription"
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			}
		];

		this.customerUniqueClientId = ClientHelper.IsBackOffice() ? -2 : ClientHelper.GetClientRowId();

		this.state = {
			model: {
				CustomerUniqueClientId: this.customerUniqueClientId
			}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("Support");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Online Support");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "MailAddress") {
				model["MailAddressIsValid"] = data.IsValid;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;
		var model = list[index];
		model.MailAddressIsValid = true;

		this.setState({ model, selected: index });
	}

	GetParameterCustomerStore = () => {
		const { model } = this.state;

		var result = {
			Id: model.CustomerUniqueClientId
		};

		return result;
	}

	GetRenderItemClient = (x) => {
		var result = `${x.Name} - ${x.UniqueClientId}`;

		return result;
	}

	GetRenderItemOfficePhone = (x) => {
		var result = "+" + x.Code + " (" + x.Name + ")";

		return result;
	}

	GetRenderItemMobilePhone = (x) => {
		var result = "+" + x.Code + " (" + x.Name + ")";

		return result;
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/OnlineSupport/Search", model);

		model.IsProblemGroupId = false;
		model.IsPriorityId = false;
		model.IsMailAddress = false;
		model.IsOfficePhoneCountryCodeId = false;
		model.IsOfficePhonePhoneNumber = false;
		model.IsMobilePhoneCountryCodeId = false;
		model.IsMobilePhonePhoneNumber = false;
		model.IsProblemDescription = false;

		this.setState({ model, list: result || [] });
	}

	Clear = () => {
		this.setState({ model: { CustomerUniqueClientId: this.customerUniqueClientId }, list: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (model.CustomerUniqueClientId == -2) {
			errors.push("Client cannot be null.");
		}
		if (!model.ProblemGroupId) {
			errors.push("Problem group cannot be null");
		}
		if (!model.PriorityId) {
			errors.push("Priority cannot be null");
		}
		if (!model.MailAddress) {
			errors.push("Mail address cannot be null");
		}
		if (!model.MailAddressIsValid || model.MailAddressIsValid == false) {
			errors.push("Mail address is not a correct.");
		}
		if (!model.OfficePhoneCountryCodeId) {
			errors.push("Office phone country code cannot be null");
		}
		if (!model.OfficePhonePhoneNumber) {
			errors.push("Office phone phone number cannot be null");
		}
		if (!model.MobilePhoneCountryCodeId) {
			errors.push("Mobile phone country code cannot be null");
		}
		if (!model.MobilePhonePhoneNumber) {
			errors.push("Mobile phone number cannot be null");
		}
		if (!model.ProblemDescription) {
			errors.push("Problem description cannot be null");
		}

		model["IsCustomerUniqueClientId"] = model.CustomerUniqueClientId == -2;
		model["IsProblemGroupId"] = !model.ProblemGroupId;
		model["IsPriorityId"] = !model.PriorityId;
		model["IsMailAddress"] = !model.MailAddress;
		model["IsOfficePhoneCountryCodeId"] = !model.OfficePhoneCountryCodeId;
		model["IsOfficePhonePhoneNumber"] = !model.OfficePhonePhoneNumber;
		model["IsMobilePhoneCountryCodeId"] = !model.MobilePhoneCountryCodeId;
		model["IsMobilePhonePhoneNumber"] = !model.MobilePhonePhoneNumber;
		model["IsProblemDescription"] = !model.ProblemDescription;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/OnlineSupport/Insert", model, null, null, this.Validate, [this.Clear, this.Search], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/OnlineSupport/Update", model, null, null, this.Validate, [this.Clear, this.Search], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/coreapi/v1.0/OnlineSupport/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Online Support">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsCustomerUniqueClientId}
												Disabled={!ClientHelper.IsBackOffice()}
												Name="CustomerUniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemClient}
												Sorted={SortedName}
												Value={model.CustomerUniqueClientId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												key={model.CustomerUniqueClientId}
												Name="CustomerStoreId"
												LabelText="Store"
												Url="/bankapi/v1.0/CustomerAddress/GetNotNullStoreByCustomerId"
												Method="POST"
												Parameter={this.GetParameterCustomerStore()}
												DataRoot="Item"
												KeyValueMember="StoreId"
												TextValueMember="StoreName"
												Sorted={SortedStoreName}
												Value={model.CustomerStoreId}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Required
												IsInvalid={model.IsProblemGroupId}
												Name="ProblemGroupId"
												LabelText="Problem Group"
												ParameterCode="ProblemGroup"
												Value={model.ProblemGroupId}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Required
												IsInvalid={model.IsPriorityId}
												Name="PriorityId"
												LabelText="Priority"
												ParameterCode="OnlineSupportPriority"
												Value={model.PriorityId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericEmailInput
												Required
												IsInvalid={model.IsMailAddress}
												Name="MailAddress"
												LabelText="E-Mail"
												inputProps={MaxLength150}
												Value={model.MailAddress}
												ValueChanged={this.ValueChanged} />
											<GridContainer>
												<GridItem xs={7}>
													<GenericSelectInput
														Required
														IsInvalid={model.IsOfficePhoneCountryCodeId}
														Name="OfficePhoneCountryCodeId"
														LabelText="Office Phone"
														LabelMd={7}
														Url="/coreapi/v1.0/Country/GetAll"
														Method="POST"
														Parameter={ParameterEmptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Code"
														RenderItem={this.GetRenderItemOfficePhone}
														Sorted={SortedOrderIndex}
														Value={model.OfficePhoneCountryCodeId}
														ValueChanged={this.ValueChanged} />
												</GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														IsPhone
														NoFormatting
														Required
														IsInvalid={model.IsOfficePhonePhoneNumber}
														Name="OfficePhonePhoneNumber"
														LabelMd={0}
														MaxLength={10}
														Value={model.OfficePhonePhoneNumber}
														ValueChanged={this.ValueChanged} />
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={7}>
													<GenericSelectInput
														Required
														IsInvalid={model.IsMobilePhoneCountryCodeId}
														Name="MobilePhoneCountryCodeId"
														LabelText="Mobile"
														LabelMd={7}
														Url="/coreapi/v1.0/Country/GetAll"
														Method="POST"
														Parameter={ParameterEmptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Code"
														RenderItem={this.GetRenderItemOfficePhone}
														Sorted={SortedOrderIndex}
														Value={model.MobilePhoneCountryCodeId}
														ValueChanged={this.ValueChanged} />
												</GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														IsPhone
														NoFormatting
														Required
														IsInvalid={model.IsMobilePhonePhoneNumber}
														Name="MobilePhonePhoneNumber"
														LabelMd={0}
														MaxLength={10}
														Value={model.MobilePhonePhoneNumber}
														ValueChanged={this.ValueChanged} />
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												MultiLine
												Required
												IsInvalid={model.IsProblemDescription}
												Name="ProblemDescription"
												LabelText="Problem Description"
												RowCount={7}
												inputProps={MaxLength500}
												Value={model.ProblemDescription}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

OnlineSupport.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default OnlineSupport;