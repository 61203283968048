import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateFilterInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDialog, GenericLabel, GenericRadioInput, GenericDialogActions } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import Formatter from "core/Formatter";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import { Proxy } from "core";
import Button from "components/CustomButtons/Button";
import LoadingComponent from "views/Components/LoadingComponent";


class LimitAdjustmentHistory extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Request Date",
				accessor: "RequestDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Application Number",
				accessor: "ApplicationNumber"
			},
			{
				Header: "Applicant Name",
				accessor: "ApplicantName"
			},
			{
				Header: "Loan Type",
				accessor: "LoanType"
			},
			{
				Header: "Previous Card Limit",
				accessor: "PreviousCardLimit",
				type: GridColumnType.Money
			},
			{
				Header: "New Card Limit",
				accessor: "NewCardLimit",
				type: GridColumnType.Money
			},
			{
				Header: "Previous Security Amount",
				accessor: "SecurityBalance",
				type: GridColumnType.Money
			},
			{
				Header: "Added Security Amount",
				accessor: "AddedSecurityAmount",
				Cell: row => (
					row.original.AddedSecurityAmount == 0
						? "N/A"
						: Formatter.FormatMoney(row.original.AddedSecurityAmount)
				)
			},
			{
				Header: "New Security Amount",
				accessor: "NewSecurityAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Review Date",
				accessor: "ReviewDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Reject Reason",
				accessor: "RejectReason"
			}
		];

		this.lmAdjHisTable = React.createRef();

		this.emptyObject = {};

		this.state = {
			model: {
				DateFilter: {}
			},
			isLoading: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Limit Adjustment History");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/LimitAdjustmentHistory/Search", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: { DateFilter: {} }, list: [] });
	}

	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}

	ExportClick = () => {
		var lmAdjHisColumns = [];
		this.lmAdjHisTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				lmAdjHisColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: lmAdjHisColumns, ExportSelectedColumns: lmAdjHisColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });
		const temp = { ...this.state.model };
		var jsonColList = [];
		var lmAdjHisColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			lmAdjHisColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(lmAdjHisColumns);

		var mytitle = "Limit Adjustment History";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			ApplicantId: temp.ApplicantId,
			StatusId: temp.StatusId,
			DateFilter: temp.DateFilter,
			jsonFile: temp.jsonFile
		};
		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/lmsapi/v1.0/LimitAdjustmentHistory/ExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { model, list, isLoading } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{
							Code: "Export",
							OnClick: this.ExportClick,
							Disabled: Disabled
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Limit Adjustment History">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="ApplicantId"
												LabelText="Applicant Name"
												Url="/lmsapi/v1.0/LimitAdjustmentHistory/ApplicantList"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ApplicantName"
												Value={model.ApplicantId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="StatusId"
												LabelText="Status"
												ParameterCode="LmsLimitAdjustmentHistoryStatus"
												Value={model.StatusId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateFilterInput
												Name="DateFilter"
												LabelText="Request Date"
												IncludeTime={false}
												Model={model.DateFilter}
												BeginDate={model.BeginDate}
												EndDate={model.EndDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													ref={this.lmAdjHisTable}
													PageSize={10}
													ShowPagination={true}
													Columns={this.columns}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

LimitAdjustmentHistory.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default LimitAdjustmentHistory;