import { Dialog, DialogContent } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import Recaptcha from "react-recaptcha";
import { UserConstants } from "store";
import { GenericAlert, GenericCircularProgress, GenericEmailInput, GenericTextInput, GenericTitle, GenericLabel, GenericSelectInput, GenericNumberInput } from "views/Components/Generic";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracConfirmPaymentOperation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			model: {}
		};
	}

	componentDidMount() {
		var user = ClientHelper.GetUser();
		if (!user) {
			RouteHelper.Push(this.props.history, "/InteracConfirm/Login", "InteracData", {});
		}

		var data = RouteHelper.Read("InteracData");
		this.setState({ model: data || {} });
		console.log(data);


		this.FetchRequest();
	}

	
	Submit = () => {

		if (this.state.model == null) {
			this.ShowMessage("error", "Error", "Wallet must be selected !");
			return;
		}
		if (this.state.model.AccountId == null || this.state.model.AccountId == 0) {
			this.ShowMessage("error", "Error", "Wallet must be selected !");
			return;
		}

		var data = RouteHelper.Read("InteracData");
		data.AccountId = this.state.model.AccountId;

		console.log(data);
		RouteHelper.Push(this.props.history, "/InteracConfirm/Review", "InteracData", data);
	}


	FetchRequest = () => {
		var data = RouteHelper.Read("InteracData");
		if (data == null) {
			console.log("InteracData null");
			return;
		}

		if (!data.IsValidUrl) {
			console.log("InteracData URL not VALID");
			return;
		}

		if (data.IsMoneySend) {
			this.FetchGetIncomingTransfers();
		}
		if (data.IsMoneyRequest) {

		}
	}

	FetchGetIncomingTransfers = (model) => {
		var data = RouteHelper.Read("InteracData");
		var temp = {};
		temp.participantUserId = data.pID;
		temp.transferReferenceNumber = data.src;

		console.log("GetIncomingTransfers Request");
		console.log(temp);

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/GetIncomingTransfers",
			temp,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				console.log("GetIncomingTransfers");
				console.log(responseData);

				if (responseData.Item != null) {
					var data = RouteHelper.Read("InteracData");
					data.GetIncomingTransfersResponse = responseData.Item;
					localStorage.setItem("InteracData", JSON.stringify(data));

					this.FetchAuthenticateTransfer();
				} else {
					console.log("GetIncomingTransfers response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	FetchAuthenticateTransfer = () => {
		var data = RouteHelper.Read("InteracData");
		var temp = {};
		temp.participantUserId = data.pID;
		temp.transferReferenceNumber = data.src;
		temp.securityAnswer = "DC";

		console.log("AuthenticateTransfer Request");
		console.log(temp);

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/AuthenticateTransfer",
			temp,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				console.log("AuthenticateTransfer");
				console.log(responseData);

				if (responseData.Item != null) {
					var data = RouteHelper.Read("InteracData");
					data.AuthenticateTransferResponse = responseData.Item;
					localStorage.setItem("InteracData", JSON.stringify(data));
				} else {
					console.log("AuthenticateTransfer response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	FetchDeclineTransfer = () => {
		var data = RouteHelper.Read("InteracData");
		var temp = {};
		temp.participantUserId = data.pID;
		temp.transferReferenceNumber = data.src;

		temp.declineReason = "Reject from DC";

		console.log("DeclineTransfer Request");
		console.log(temp);

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/DeclineTransfer",
			temp,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				console.log("DeclineTransfer");
				console.log(responseData);

				if (responseData.Item != null) {
					var data = RouteHelper.Read("InteracData");
					data.DeclineTransferResponse = responseData.Item;
					localStorage.setItem("InteracData", JSON.stringify(data));
				} else {
					console.log("DeclineTransfer response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	HandleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.Submit();
		}
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert } = this.state;

		return (
			<div className={classes.container}>
				{alert}
				<LoadingComponent Show={isLoading} />
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={7}>
						<Card>
							<CardHeader>
							</CardHeader>
							<CardBody>
								<GridContainer container direction="column" justify="flex-start" alignItems="stretch" style={{ padding: "25px" }}>
									<GenericTitle text="Deposit Interac e-Transfer" />
									<br /><br />
									<GridItem>
										<GenericLabel
											Bold={false}
											FontSize="15px"
											TextColor="black"
											Text="Please select the wallet where you would like to deposit your money." />
									</GridItem>
									<br />
									<GridItem>
										<GenericNumberInput
											Disabled={true}
											Prefix="$ "
											Name="Amount"
											LabelText="Transaction Amount"
											Value={model.Amount}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericSelectInput
											Name="AccountId"
											LabelText="To Wallet"
											Method="POST"
											Url="/bankapi/v1.0/InteracConfirmPayment/GetInteractLoadAccount"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="AccountName"
											Value={model.AccountId}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<br />
									<GridItem>
										<GridContainer>
											<GridItem md={3}>
												<Button color="primary" size="md" block onClick={() => this.FetchDeclineTransfer()}>
													Decline Payment
												</Button>
											</GridItem>
											<GridItem md={6}></GridItem>
											<GridItem md={3}>
												<Button color="primary" size="md" block onClick={() => this.Submit()}>
													SUBMIT >
												</Button>
												<Button color="primary" size="sm" block onClick={() => this.FetchAuthenticateTransfer()}>
													Auth >
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracConfirmPaymentOperation.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(InteracConfirmPaymentOperation, loginPageStyle);