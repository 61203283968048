import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/riskRatingStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import ArrayHelper from "core/ArrayHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import RiskRatingQuestionaries from "views/Backoffice/BankingClientSetup/ClientSteps/RiskRatingQuestionaries";
import { GenericAlert, GenericDateInput, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, OperationType, RiskLightRating } from "views/Constants/Constant.js";

class RiskRating extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			alert: null,
			commentModel: {},
			currentRisk: {Level : "", Style: null},
			BankCustomerData : [],
			vModel: {},
			list: [],
			operationType: OperationType.DEFAULT,
			selected: -1
		};

		this.ParameterRiskLevel = { ParameterCode : "AmlKycRiskLevel" }

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.SelectedRowChangeComment(row.index)} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={props.Disabled}
							OnClick={() => this.HandleDeleteComment(row.original.Id)}
						/>
					</>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Date",
				accessor: "MemoDate",
				type: GridColumnType.Date,
				width: 150,
				sortable: false,
				filterable: false
			},
			{
				Header: "Update User",
				accessor: "Action",
				width: 300,
				sortable: false,
				filterable: false,
				Cell: row => (<>{this.HandleGetInspector(row.index)}</>)
			},
			{
				Header: "Comment",
				accessor: "Memo",
				sortable: false,
				filterable: false
			},
			{
				Header: "Risk Rating",
				accessor: "MemoRiskLevel",
				Cell: row =>(<>{row.value == "H" ? "High Risk" : row.value == "M" ? "Medium Risk" : row.value == "L" ? "Low Risk" : ""}</>),
				width: 200,
				sortable: false,
				filterable: false
			}
		];
	}

	componentDidMount() {
		//this.props.hideSidebar();
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Risk Rating");

		if (this.props.paramGlobalModel.Id) {
			this.HandleGetList();
		}
		this.HandleGetDataAndRisk();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.paramGlobalModel.Id !== this.props.paramGlobalModel.Id) {
			this.HandleOperationType(OperationType.DEFAULT);
			this.HandleGetList();
		}
		if (prevProps.paramBankCustomerMemoList !== this.props.paramBankCustomerMemoList){
			this.HandleGetDataAndRisk();
		}
	}

	HandleGetDataAndRisk() {
		const { paramBankCustomerMemoList, classes } = this.props;
		if (paramBankCustomerMemoList != null && paramBankCustomerMemoList.length > 0) {
			 const newData = Array.from(paramBankCustomerMemoList);
			 ArrayHelper.SortArray(newData, "MemoDate", true);

			 let style, level;
			 const firstMemo = newData[0];

			 if (firstMemo.MemoRiskLevel == null || firstMemo.MemoRiskLevel === undefined) {
				  style = classes.noRatingStyle;
				  level = "No Risk";
			 } else {
				  switch (firstMemo.MemoRiskLevel) {
						case 'H':
							 style = classes.redRatingStyle;
							 level = "High Risk";
							 break;
						case 'M':
							 style = classes.yellowRatingStyle;
							 level = "Medium Risk";
							 break;
						case 'L':
							 style = classes.greenRatingStyle;
							 level = "Low Risk";
							 break;
						default:
							 style = classes.noRatingStyle;
							 level = "No Risk";
							 break;
				  }
			 }
			 this.setState({ 
				  BankCustomerData: newData, 
				  currentRisk: { Style: style, Level: level }
			 });
		} else {
			 this.setState({ 
				  BankCustomerData: [],
				  currentRisk: { Style: classes.noRatingStyle, Level: "No Risk" }
			 });
		}
	}

	HandleGetInspector(index)
	{
		const model = this.state.BankCustomerData[index];
		return model.UpdateUser == null ? model.InsertUser : model.UpdateUser;
	}
  
	HandleOperationType = (value) => {
		this.setState({ operationType: value });
	}

	HandleGetList = () => {
		this.setState({ isLoading: true });
		if (this.props.paramGlobalModel.Id && this.props.clientType) {
			Proxy.POST("/bankapi/v1.0/CustomerRiskRating/GetSummaryInfo",
				{
					UniqueClientId: this.props.paramGlobalModel.Id,
					ClientType: this.props.clientType,
					ReviewDate: this.props.model.ReviewDate
				},
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ list: responseData.Item.RatingSummaryList, ratingSummaryInfo: responseData.Item.RatingSummaryInfo });
						this.props.callbackFromParent(responseData.Item);
					} else {
						this.setState({ list: [] });
					}
				},
				error => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "An error occurred while requesting data", error);
				}
			);
		}
	}

	HandleSubmit = () => {
		Proxy.POST("bankapi/v1.0/BankCustomer/Update",
			this.props.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error);
			}
		);
	}

	SumValues(...arr) {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	HandleChangeComment = (name, newValue) => {
		this.setState(function (state, props) {
			var model = { ...state.commentModel } || {};
			model[name] = newValue;
			return { commentModel: model };
		});
	}

	ClearComment = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ commentModel: model, vModel: {}, selected: -1 });
	}

	OperationCheckComment = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const data = this.state.commentModel;
		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add memo.");
		}
		if (data == null) {
			return;
		}
		if (data.Memo == null || data.Memo == "") {
			vModel.Memo = true;
			ErrorList.push("Memo must be defined!");
		} 
		else { 
			vModel.Memo = false; 
		}
		if(data.MemoRiskLevel == null || data.MemoRiskLevel == ""){
			vModel.MemoRiskLevel = true;
			ErrorList.push("Risk rating must be selected!");
		}
		else{
			vModel.MemoRiskLevel = false;
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.InsertOrUpdateComment();
	}

	InsertOrUpdateComment = () => {
		var temp = this.state.commentModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;
		temp.MemoTypeId = this.props.memoTypeId;
		temp.MemoDate = temp.MemoDate == null ? DateHelper.GetDateLocal() : temp.MemoDate;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerMemo/Update",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ClearComment();
				this.props.GetCustomerMemoModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleDeleteComment = (Id) => {
		this.setState({ isLoading: true });
		Proxy.GET("/bankapi/v1.0/CustomerMemo/Delete/Id?Id=" + Id,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ClearComment();
				this.props.GetCustomerMemoModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	SelectedRowChangeComment = (index) => {
		const model = this.state.BankCustomerData[index];
		this.setState({ selected: index, commentModel: model });
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { classes, paramGlobalModel, Disabled } = this.props;
		const { commentModel, currentRisk, operationType, list, ratingSummaryInfo, vModel } = this.state;

		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<GridContainer className={classes.card}>
							<GridItem xs={12}>
								{operationType === OperationType.DEFAULT &&
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
                      				  Name="MemoRiskLevel"
                      				  LabelText="Risk rating"
                      				  Required
											  Value={commentModel?.MemoRiskLevel == null ? "" : commentModel.MemoRiskLevel}
											  ValueChanged={this.HandleChangeComment}
                      				  Parameter={this.ParameterRiskLevel}
											  KeyValueMember="ParameterValue"
											  TextValueMember="ParameterDesc"
											  Method="POST"
											  Url="/coreapi/v1.0/Parameter/Search"
											  DataRoot="Item"
											  IsInvalid={vModel.MemoRiskLevel}
                      				/>
											{/* <GridContainer className={classes.card2}>
												<GridItem className={classes.goToButton}>
													<Button
														onClick={() => this.HandleOperationType(OperationType.INSERT)}
														fullWidth
														disabled={Disabled || paramGlobalModel.Id == 0}
													>
														Go To Questionnaire
													</Button>
												</GridItem>
											</GridContainer> */}
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Disabled={Disabled}
												Name="MemoDate"
												LabelMd={5}
												LabelText="Risk Review Date"
												Value={commentModel.MemoDate ? DateHelper.ToDateInputValue(commentModel.MemoDate) : DateHelper.GetDateLocal()}
												MaxDate={DateHelper.GetDateLocal()}
												ValueChanged={this.HandleChangeComment} />
										</GridItem>
										<GridItem xs={3}>
											{this.props.model.InsertUserId &&
												<GenericSelectInput
													key={this.props.model.InsertUserId}
													Name="UserId"
													LabelMd={3}
													LabelText="User"
													Method="POST"
													Url="/coreapi/v1.0/dynamic/do"
													Parameter={{
														operation: "GetByParameter",
														dataModel: "User",
														value: {
															Id: this.props.model.InsertUserId
														}
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.FirstName} ${d.LastName}`}
													Value={this.props.model.InsertUserId}
													Disabled={true} />
											} 
										</GridItem>
										<GridItem xs={3}>
											<GridContainer justify="flex-end" className={classes.card2}>
												<GridItem xs={8}>
													<div className={classes.grayRatingStyle}><GenericLabel FontSize="14px" TextColor="black" Text="Overall Inherent Risk Rating"></GenericLabel></div>
												</GridItem>
												{/* <GridItem xs={4}>
													{ratingSummaryInfo && ratingSummaryInfo.RiskLightRating == RiskLightRating.Green ?
														<div className={classes.greenRatingStyle}><GenericLabel FontSize="18px" TextColor="black" Text={`Low Risk (Score: ${ratingSummaryInfo.RiskRatingScore})`}></GenericLabel></div>
														: ratingSummaryInfo && ratingSummaryInfo.RiskLightRating == RiskLightRating.Yellow ?
															<div className={classes.yellowRatingStyle}><GenericLabel FontSize="18px" TextColor="black" Text={`Medium Risk (Score: ${ratingSummaryInfo.RiskRatingScore})`}></GenericLabel></div>
															: ratingSummaryInfo && ratingSummaryInfo.RiskLightRating == RiskLightRating.Red ?
																<div className={classes.redRatingStyle}><GenericLabel FontSize="18px" TextColor="black" Text={`High Risk (Score: ${ratingSummaryInfo.RiskRatingScore})`}></GenericLabel></div>
																: ratingSummaryInfo && ratingSummaryInfo.RiskLightRating == RiskLightRating.Black ?
																	<div className={classes.blackRatingStyle}><GenericLabel FontSize="18px" TextColor="white" Text={`Stop (Score: ${ratingSummaryInfo.RiskRatingScore})`}></GenericLabel></div>
																	: <div className={classes.noRatingStyle}><GenericLabel FontSize="14px" TextColor="black" Text="No Risk"></GenericLabel></div>}
												</GridItem> */}
												<GridItem xs={4}>
													<div className={currentRisk.Style}><GenericLabel FontSize="14px" TextColor="black" Text={currentRisk.Level}></GenericLabel></div>
												</GridItem>
											</GridContainer>
										</GridItem>
										{/* <GridContainer>
											<GridItem xs={12}>
												<ReactTable
													style={{
														border: "2px solid black"
													}}
													className={`${classes.table} ${"-striped -highlight"}`}
													data={list}
													filterable={false}
													columns={[
														{
															Header: "Categories of Risk Considered",
															accessor: "QuestionTypeName",
															Cell: row => (
																<strong>{row.value}</strong>
															),
															Footer: (
																<span>
																	<strong>Total Affirmations</strong>
																</span>
															)
														},
														{
															Header: "Number of Affirmation",
															columns: [
																{
																	Header: "Green (Low Risk)",
																	accessor: "LowRisk",
																	Cell: row => (
																		<div style={{ textAlign: "center" }}><strong>{Formatter.FormatNumber(row.value)}</strong></div>
																	),
																	Footer: (
																		<span>
																			<div style={{ textAlign: "center" }}>
																				<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.LowRisk)))}</strong>
																			</div>
																			<div style={{ margin: "5px -5px", border: "1.2px solid black" }} />
																		</span>
																	),
																	width: 200
																},
																{
																	Header: "Yellow (Medium Risk)",
																	accessor: "MediumRisk",
																	Cell: row => (
																		<div style={{ textAlign: "center" }}><strong>{Formatter.FormatNumber(row.value)}</strong></div>
																	),
																	Footer: (
																		<span>
																			<div style={{ textAlign: "center" }}>
																				<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.MediumRisk)))}</strong>
																			</div>
																			<div style={{ margin: "5px -5px", border: "1.2px solid black" }} />
																			<div style={{ textAlign: "center" }}>
																				<GenericNumberInput
																					Disabled={Disabled}
																					LabelMd={6}
																					LabelText="Override"
																					Name="MediumOverrideFactor"
																					MaxLength={2}
																					Value={this.props.model ? this.props.model.MediumOverrideFactor : 1}
																					ValueChanged={this.props.handleChange} />
																			</div>
																		</span>
																	),
																	width: 200
																},
																{
																	Header: "Red (High Risk)",
																	accessor: "HighRisk",
																	Cell: row => (
																		<div style={{ textAlign: "center" }}><strong>{Formatter.FormatNumber(row.value)}</strong></div>
																	),
																	Footer: (
																		<span>
																			<div style={{ textAlign: "center" }}>
																				<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.HighRisk)))}</strong>
																			</div>
																			<div style={{ margin: "5px -5px", border: "1.2px solid black" }} />
																			<div style={{ textAlign: "center" }}>
																				<GenericNumberInput
																					Disabled={Disabled}
																					LabelMd={6}
																					LabelText="Override"
																					Name="HighOverrideFactor"
																					MaxLength={2}
																					Value={this.props.model ? this.props.model.HighOverrideFactor : 1}
																					ValueChanged={this.props.handleChange} />
																			</div>
																		</span>
																	),
																	width: 200
																},
																{
																	Header: "Black (Stop)",
																	accessor: "Stop",
																	Cell: row => (
																		<div style={{ textAlign: "center" }}><strong>{Formatter.FormatNumber(row.value)}</strong></div>
																	),
																	Footer: (
																		<span>
																			<div style={{ textAlign: "center" }}>
																				<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.Stop)))}</strong>
																			</div>
																			<div style={{ margin: "5px -5px", border: "1.2px solid black" }} />
																			<div style={{ textAlign: "center" }}>
																				<GenericNumberInput
																					Disabled={Disabled}
																					LabelMd={6}
																					LabelText="Override"
																					Name="StopOverrideFactor"
																					MaxLength={2}
																					Value={this.props.model ? this.props.model.StopOverrideFactor : 1}
																					ValueChanged={this.props.handleChange} />
																			</div>
																		</span>
																	),
																	width: 200
																}
															]
														}
													]}
													defaultPageSize={5}
													showPaginationTop={false}
													showPaginationBottom={false}
													minRows={1}
													getTdProps={() => ({
														style: {
															border: "1px solid black"
														}
													})}
													getTheadGroupTrProps={() => {
														return {
															style: {
																background: "#f8ab72",
															}
														};
													}}
													getTheadGroupThProps={() => {
														return {
															style: {
																border: "1px solid black"
															}
														};
													}}
													getThProps={() => {
														return {
															style: {
																border: "1px solid black"
															}
														};
													}}
													getTheadThProps={(state, rowInfo, column, instance) => {
														return {
															style: column.id == "LowRisk" ? {
																background: "limegreen",
																border: "1px solid black"
															} : column.id == "MediumRisk" ? {
																background: "yellow",
																border: "1px solid black"
															} : column.id == "HighRisk" ? {
																background: "red",
																border: "1px solid black"
															} : column.id == "Stop" ? {
																background: "black",
																color: "white",
																border: "1px solid black"
															} : {
																				background: "#f8ab72",
																				border: "1px solid black"
																			}
														};
													}}
													getTfootTdProps={() => {
														return {
															style: {
																border: "1px solid black",
																height: "77px"
															}
														};
													}}
												/>
											</GridItem>
										</GridContainer> */}
										<GridContainer className={classes.card2}>
											<GridItem xs={12}>
												<Card className="no-radius">
													<CardHeader>
														<GenericTitle text={"Comments"} />
													</CardHeader>
													<CardBody>
														<GridContainer>
															<GridItem xs={12}>
																<GenericTextInput
																	Name="Memo"
																	Disabled={Disabled}
																	LabelMd={0}
																	MultiLine={true}
																	RowCount={5}
																	Value={commentModel.Memo}
																	ValueChanged={this.HandleChangeComment}
																	Required
																	IsInvalid={vModel.Memo} />
															</GridItem>
															<GridItem xs={9} />
															<GridItem xs={3}>
																<GridContainer justify="flex-end">
																	<GridItem>
																		<Button disabled={Disabled} size="sm" color="black" onClick={this.ClearComment}>	Clear </Button>
																		<Button disabled={Disabled} size="sm" color="black" onClick={this.OperationCheckComment}>{commentModel.Id > 0 ? "Update" : "Add"} </Button>
																	</GridItem>
																</GridContainer>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>
												<Card className="no-radius">
													<CardBody>
														<GridItem xs={12}>
															<GridItem>
																<GenericGrid
																	Data={this.state.BankCustomerData}
																	PageSize={5}
																	HideButton={true}
																	Columns={this.ColumnsData}
																	RowSelected={this.SelectedRowChangeComment}
																	SelectedIndex={this.state.selected}
																	IsSorted={false}
																	Sorted={[{ id: "MemoDate", desc: true }]}  />
															</GridItem>
														</GridItem>
													</CardBody>
												</Card>
											</GridItem>
										</GridContainer>
									</GridContainer>
								}
								{operationType !== OperationType.DEFAULT &&
									<RiskRatingQuestionaries
										Disabled={this.props.Disabled}
										uniqueClientId={paramGlobalModel.Id}
										clientType={this.props.clientType}
										customerRiskRatingAgreementId={this.props.model.CustomerRiskRatingAgreementId}
										reviewDate={this.props.model.ReviewDate}
										MediumOverrideFactor={this.props.model.MediumOverrideFactor}
										HighOverrideFactor={this.props.model.HighOverrideFactor}
										StopOverrideFactor={this.props.model.StopOverrideFactor}
										handleOperationType={this.HandleOperationType}
										handleGetList={this.HandleGetList}
										showMessage={this.ShowMessage} />
								}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

RiskRating.propTypes = {
	classes: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerMemoList: PropTypes.array,
	paramBankCustomerRiskRating: PropTypes.object,
	handleChange: PropTypes.func,
	GetCustomerMemoModels:PropTypes.func,
	callbackFromParent: PropTypes.func,
	clientType: PropTypes.string,
	model: PropTypes.object
};

export default withStyles(styles)(RiskRating);