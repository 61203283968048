import withStyles from "@material-ui/core/styles/withStyles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
	grayRatingStyle: {
		background: "gray",
		padding: 10,
		border: "1px solid black",
		textAlign: "center"
	},
	orangeRatingStyle: {
		background: primaryColor,
		padding: 10,
		color: "white",
		borderRadius: "5px"
	}
});

class RiskRating extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {};

		this.state = {
			perAccList: [],
			corpAccList: [],
			model: this.initialModel,
			vModel: {},
			isLoading: false,
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Risk Rating Summary");
		this.props.setAppCenterTitle("REPORTS");
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleBooleanChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: null });
	}

	HandleGetList = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Reporting/SearchRiskRating",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			this.setState({
				model,
				perAccList: responseData.Item.PersonalAccountList,
				corpAccList: responseData.Item.CorporateAccountList
			});
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	GetElementValue = (obj, path) => {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => {
			if (c) {
				if (n.toString().includes("Percent")) {
					return `${c[n].toFixed(1)}%`;
				}
				return c[n];
			}
		});
	}

	Print = () => {
		if (this.state.perAccList.length > 0)
			this.PrintPersonalAccountList();
	}

	PrintPersonalAccountList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.perAccList.forEach(function (listElement) {
			var keyValueObject = {};
			this.GetPersonalColumns().forEach(function (gridColumnElement) {
				var valueItem = this.GetElementValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Risk Rating Personal Wallets Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "RiskRatingPersonalAccountsReport.pdf";
				a.click();
				if (this.state.corpAccList.length > 0)
					this.PrintCorporateAccountList();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	PrintCorporateAccountList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.corpAccList.forEach(function (listElement) {
			var keyValueObject = {};
			this.GetCorporateColumns().forEach(function (gridColumnElement) {
				var valueItem = this.GetElementValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Risk Rating Corporate Wallets Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "RiskRatingCorporateAccountsReport.pdf";
				a.click();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		if (model.QuarterFilter) {
			if (!model.Year) {
				vModel.Year = true;
				messages.push("Year must be select");
			} else {
				vModel.Year = false;
			}

			if (!model.Quarter1 && !model.Quarter2 && !model.Quarter3 && !model.Quarter4) {
				vModel.Quarter = true;
				messages.push("Quarter must be select");
			} else {
				vModel.Quarter = false;
			}
		} else if (model.DateFilter) {
			if (!model.FromDate) {
				vModel.FromDate = true;
				messages.push("From Date must be select");
			} else {
				vModel.FromDate = false;
			}

			if (!model.ToDate) {
				vModel.ToDate = true;
				messages.push("To Date must be select");
			} else {
				vModel.ToDate = false;
			}
		} else {
			this.ShowMessage("warning", "No filter not selected", "Select any filter to continue.");
			return false;
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetPersonalColumns = () => {
		const { model, perAccList } = this.state;

		const columns = [
			{
				Header: "Individual Wallets",
				accessor: "RiskGroup",
				Footer: (
					<span>
						<strong>Total Individual Wallet</strong>
					</span>
				)
			}
		];

		if (model.Quarter1) {
			columns.push(
				{
					Header: "Q1",
					accessor: "Q1RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q1RatingCount))}</strong>
						</span>
					)
				},
				{
					Header: "Q1%",
					accessor: "Q1RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(perAccList.map(d => d.Q1RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter2) {
			columns.push(
				{
					Header: "Q2",
					accessor: "Q2RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q2RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.CumulativeQuarter2) {
			columns.push(
				{
					Header: "CQ2",
					accessor: "CQ2RatingCount",
					Cell: (row =>
						<span>
							{row.original.Q1RatingCount + row.original.Q2RatingCount}
						</span>
					),
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q1RatingCount)) + this.SumValues(perAccList.map(d => d.Q2RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter2) {
			columns.push(
				{
					Header: "Q2%",
					accessor: "Q2RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(perAccList.map(d => d.Q2RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter3) {
			columns.push(
				{
					Header: "Q3",
					accessor: "Q3RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q3RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.CumulativeQuarter3) {
			columns.push(
				{
					Header: "CQ3",
					accessor: "CQ3RatingCount",
					Cell: (row =>
						<span>
							{row.original.Q1RatingCount + row.original.Q2RatingCount + row.original.Q3RatingCount}
						</span>
					),
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q1RatingCount)) + this.SumValues(perAccList.map(d => d.Q2RatingCount)) + this.SumValues(perAccList.map(d => d.Q3RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter3) {
			columns.push(
				{
					Header: "Q3%",
					accessor: "Q3RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(perAccList.map(d => d.Q3RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter4) {
			columns.push(
				{
					Header: "Q4",
					accessor: "Q4RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q4RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.CumulativeQuarter4) {
			columns.push(
				{
					Header: "CQ4",
					accessor: "CQ4RatingCount",
					Cell: (row =>
						<span>
							{row.original.Q1RatingCount + row.original.Q2RatingCount + row.original.Q3RatingCount + row.original.Q4RatingCount}
						</span>
					),
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.Q1RatingCount)) + this.SumValues(perAccList.map(d => d.Q2RatingCount)) + this.SumValues(perAccList.map(d => d.Q3RatingCount)) + this.SumValues(perAccList.map(d => d.Q4RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter4) {
			columns.push(
				{
					Header: "Q4%",
					accessor: "Q4RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(perAccList.map(d => d.Q4RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.DateFilter) {
			columns.push(
				{
					Header: "Between Date",
					accessor: "RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(perAccList.map(d => d.RatingCount))}</strong>
						</span>
					)
				},
				{
					Header: "Between Date%",
					accessor: "RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(perAccList.map(d => d.RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		return columns;
	}

	GetCorporateColumns = () => {
		const { model, corpAccList } = this.state;

		const columns = [
			{
				Header: "Corporate Wallets",
				accessor: "RiskGroup",
				Footer: (
					<span>
						<strong>Total Corporate Wallets</strong>
					</span>
				)
			}
		];

		if (model.Quarter1) {
			columns.push(
				{
					Header: "Q1",
					accessor: "Q1RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q1RatingCount))}</strong>
						</span>
					)
				},
				{
					Header: "Q1%",
					accessor: "Q1RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(corpAccList.map(d => d.Q1RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter2) {
			columns.push(
				{
					Header: "Q2",
					accessor: "Q2RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q2RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.CumulativeQuarter2) {
			columns.push(
				{
					Header: "CQ2",
					accessor: "CQ2RatingCount",
					Cell: (row =>
						<span>
							{row.original.Q1RatingCount + row.original.Q2RatingCount}
						</span>
					),
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q1RatingCount)) + this.SumValues(corpAccList.map(d => d.Q2RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter2) {
			columns.push(
				{
					Header: "Q2%",
					accessor: "Q2RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(corpAccList.map(d => d.Q2RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter3) {
			columns.push(
				{
					Header: "Q3",
					accessor: "Q3RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q3RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.CumulativeQuarter3) {
			columns.push(
				{
					Header: "CQ3",
					accessor: "CQ3RatingCount",
					Cell: (row =>
						<span>
							{row.original.Q1RatingCount + row.original.Q2RatingCount + row.original.Q3RatingCount}
						</span>
					),
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q1RatingCount)) + this.SumValues(corpAccList.map(d => d.Q2RatingCount)) + this.SumValues(corpAccList.map(d => d.Q3RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter3) {
			columns.push(
				{
					Header: "Q3%",
					accessor: "Q3RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(corpAccList.map(d => d.Q3RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter4) {
			columns.push(
				{
					Header: "Q4",
					accessor: "Q4RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q4RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.CumulativeQuarter4) {
			columns.push(
				{
					Header: "CQ4",
					accessor: "CQ4RatingCount",
					Cell: (row =>
						<span>
							{row.original.Q1RatingCount + row.original.Q2RatingCount + row.original.Q3RatingCount + row.original.Q4RatingCount}
						</span>
					),
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.Q1RatingCount)) + this.SumValues(corpAccList.map(d => d.Q2RatingCount)) + this.SumValues(corpAccList.map(d => d.Q3RatingCount)) + this.SumValues(corpAccList.map(d => d.Q4RatingCount))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Quarter4) {
			columns.push(
				{
					Header: "Q4%",
					accessor: "Q4RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(corpAccList.map(d => d.Q4RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.DateFilter) {
			columns.push(
				{
					Header: "Between Date",
					accessor: "RatingCount",
					Footer: (
						<span>
							<strong>{this.SumValues(corpAccList.map(d => d.RatingCount))}</strong>
						</span>
					)
				},
				{
					Header: "Between Date%",
					accessor: "RatingCountPercent",
					Cell: row => (
						<span>
							{Formatter.FormatPercent(row.value)}
						</span>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatPercent(this.SumValues(corpAccList.map(d => d.RatingCountPercent)))}</strong>
						</span>
					)
				}
			);
		}
		return columns;
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isLoading, model, vModel, perAccList, corpAccList } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Print", OnClick: this.Print, Disabled: Disabled || (perAccList.length == 0 && corpAccList.length == 0) },
					{ Code: "CreateReport", OnClick: this.HandleGetList, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={6}>
													<GridItem style={{ opacity: model.DateFilter ? 0.4 : 1 }}>
														<GenericCheckInput
															Name="QuarterFilter"
															LabelText="Quarter"
															Value={model.QuarterFilter}
															ValueChanged={this.HandleChange}
															Disabled={model.DateFilter} />
													</GridItem>
													<GridContainer>
														<GridItem xs={4} style={{ opacity: !model.QuarterFilter ? 0.4 : 1 }} >
															<GenericSelectInput
																Name="Year"
																LabelText="Year"
																Method="POST"
																Url="/coreapi/v1.0/Parameter/Search"
																Parameter={{
																	ParameterCode: "Year"
																}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={{
																	Member: "ParameterDesc",
																	Desc: true
																}}
																Value={model.Year}
																ValueChanged={this.HandleChange}
																Disabled={!model.QuarterFilter}
																Required={model.QuarterFilter}
																IsInvalid={vModel.Year} />
														</GridItem>
														<GridItem xs={8}>
															<GridItem style={{ opacity: !model.QuarterFilter ? 0.4 : 1, marginTop: 4 }}>
																<GenericCheckInput
																	Name="Quarter1"
																	LabelText="Q1"
																	Value={model.Quarter1}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Quarter2"
																	LabelText="Q2"
																	Value={model.Quarter2}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Quarter3"
																	LabelText="Q3"
																	Value={model.Quarter3}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Quarter4"
																	LabelText="Q4"
																	Value={model.Quarter4}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="CumulativeQuarter2"
																	LabelText="CQ2"
																	Value={model.CumulativeQuarter2}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="CumulativeQuarter3"
																	LabelText="CQ3"
																	Value={model.CumulativeQuarter3}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="CumulativeQuarter4"
																	LabelText="CQ4"
																	Value={model.CumulativeQuarter4}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
															</GridItem>
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem xs={4}>
													<GridItem style={{ opacity: model.QuarterFilter ? 0.4 : 1 }}>
														<GenericCheckInput
															Name="DateFilter"
															LabelText="Date"
															Value={model.DateFilter}
															ValueChanged={this.HandleChange}
															Disabled={model.QuarterFilter}
															Required={!model.QuarterFilter}
															IsInvalid={vModel.DateFilter} />
													</GridItem>
													<GridContainer>
														<GridItem xs={6} style={{ opacity: !model.DateFilter ? 0.4 : 1 }}>
															<GenericDateInput
																Name="FromDate"
																LabelText="From"
																Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
																ValueChanged={this.HandleChange}
																Disabled={!model.DateFilter}
																Required={model.DateFilter}
																IsInvalid={vModel.FromDate} />
														</GridItem>
														<GridItem xs={6} style={{ opacity: !model.DateFilter ? 0.4 : 1 }}>
															<GenericDateInput
																Name="ToDate"
																LabelText="To"
																Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
																ValueChanged={this.HandleChange}
																Disabled={!model.DateFilter}
																Required={model.DateFilter}
																IsInvalid={vModel.ToDate} />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<GenericTitle text={"Wallet Summary By Risk Rating"} />
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "16px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							className={`${classes.table} ${"-striped -highlight"}`}
							data={perAccList}
							filterable={false}
							columns={this.GetPersonalColumns()}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
							getTheadTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
							getTfootTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
						/>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "36px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							className={`${classes.table} ${"-striped -highlight"}`}
							data={corpAccList}
							filterable={false}
							columns={this.GetCorporateColumns()}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
							getTheadTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
							getTfootTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
						/>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

RiskRating.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(RiskRating);