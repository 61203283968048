import { cardBoxShadow, drawerWidth } from "assets/jss/material-dashboard-pro-react.jsx";

const isLeftMenu = window.MenuBar === "left";

const common = {
	backgroundColor: "white",
	height: 37,
	zIndex: 3
};

const buttonToolbarStyle = theme => ({
	main: {
		...cardBoxShadow,
		backgroundColor: "#e4e4e4",
		fontWeight: 700,
		textTransform: "uppercase",
		borderRadius: "6px",
		marginTop: "8px",
		marginBottom: "5px",
		marginRight: "2px",
		zIndex: 2
	},
	normal: {
		...common,
		left: 23,
		right: 41
	},
	fixed: {
		...common,
		position: "fixed",
		width: `calc(100% - ${drawerWidth + 95}px - ${isLeftMenu ? "30vh" : "0px"})`,
		[theme.breakpoints.down("md")]: {
			position: "absolute",
			width: "1340px"
		}
	},
	paddingBottom: {
		paddingBottom: 40,
		paddingTop: isLeftMenu ? 10 : 0
	}
});

export default buttonToolbarStyle;