import ErrorPage from "error/ErrorPage";
import PropTypes from "prop-types";
import React, { Component } from "react";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
			error: {},
			info: {}
		};
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true, error, info });
		console.error("error: " + error.message);
		console.error("errorInfo: " + info.componentStack);
	}

	render() {
		const { hasError, error, info } = this.state;
		const { location, children } = this.props;

		if (hasError) {
			// You can render any custom fallback UI
			return <ErrorPage errorMessage={error.message} componentStack={info.componentStack} url={location ? location.pathname : null} />;
		}
		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	location: PropTypes.object
};

export default ErrorBoundary;