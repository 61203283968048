import { DialogContent, DialogTitle,Slide } from "@material-ui/core";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon ,PayIcon} from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericDateInput,
  GenericDialog,
  GenericDialogActions,
  GenericExpansionPanel,
  GenericGrid,
  GenericLabel,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericRadioInput
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType ,Direction} from "views/Constants/Constant.js";
import { Proxy } from "core";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import RouteHelper from "core/RouteHelper";

class eTransferUnmatchedTransactions extends React.Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      TransactionDate: undefined,
      TransactionTypeId: -1,
      FundDirection: undefined,
      FundDirectionId: -1,
    };

    this.directionDataSource = [
      { Key: "Incoming", Value: Direction.Incoming },
      { Key: "Outgoing", Value: Direction.Outgoing },
    ];

    this.initColumns();
    this.initExportColumns();
    this.initSettlementDetailColumns();
	 
    this.state = {
      model: this.initialModel,
      isLoading: false,
      isMatchingDialogOpen: false,
      isSettlementDetailDialogOpen: false,
      isPayRedirect: false,
      selectedMatchingModelRow: {
        InteracRefNo: undefined,
        TransactionReferenceNumber: undefined,
      },
      matchingModel: {
        InteracRefNo: undefined,
        TransactionReferenceNumber: undefined,
      },
      gridRefreshToken: false,
      loadAtStartup: false,
      exportReportType: "PDF",
      isDownloadDialogOpen: false,
      exportSelectedColumns: this.exportColumns,
      gridSettlementDetailRefreshToken: false,
      selectedRow: undefined,
    };

    this.settlementErrorsGridRef = React.createRef();
    this.settlementDetailGridRef = React.createRef();
  }

  initColumns() {
    this.columns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (row) => (
          <div>
            <GridButton
              Disabled={this.isSettlementDetailRowActionDisabled(row)}
              tooltip="Edit"
              Icon={EditIcon}
              OnClick={() => {
                this.openSettlementDetailDialog(row.original);
              }}
            />

            <GridButton
              Disabled={this.isPayRowActionDisabled(row)}
              tooltip="Pay"
              Icon={PayIcon}
              OnClick={() => {
					RouteHelper.Push(
            this.props.history,
            "/CreateWireTransfer",
            "row",
            row
          ); 
              }}
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 70,
        show: true,
      },
      {
        Header: "Participant ID",
        accessor: "ParticipantId",
      },
      {
        Header: "Financial Ins. Name",
        accessor: "FIName",
      },
      {
        Header: "Is Onus Record",
        accessor: "IsOnusRecord",
      },
      {
        Header: "Total No. of Credits",
        accessor: "TotalNumberOfCredits",
      },
      {
        Header: "Credit Balance",
        accessor: "CreditBalance",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Total No. of Debits",
        accessor: "TotalNumberOfDebits",
      },
      {
        Header: "Debit Balance",
        accessor: "DebitBalance",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Balance Difference",
        accessor: "BalanceDifference",
		  type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Legacy Debits",
        accessor: "LegacyDebits",
		  type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Legacy Credits",
        accessor: "LegacyCredits",
		  type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Total Debits",
        accessor: "TotalDebits",
		  type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Total Credits",
        accessor: "TotalCredits",
		  type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Net Balance",
        accessor: "NetBalance",
		  type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
    ];
  }

  initExportColumns() {
    var exportColumns = [];

    this.columns.forEach((c) => {
      if (
        c.accessor != "Actions" &&
        c.accessor != "ED" &&
        (c.show == undefined || c.show)
      ) {
        var exportColumn = {};
        exportColumn.value = c.accessor;
        exportColumn.title = c.Header;
        if (c.type) {
          exportColumn.type = c.type;
        }

        exportColumns.push(exportColumn);
      }
    }, this);

    this.exportColumns = exportColumns;
  }

  initSettlementDetailColumns() {
    this.settlementDetailColumns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (row) => (
          <div>
            <GridButton
              Disabled={false}
              tooltip="Edit"
              Icon={EditIcon}
              OnClick={() => {
					 this.openMatchingDialog();
                this.setState({
                  selectedMatchingModelRow: row,
                  matchingModel: {
                    InteracRefNo: row.original.InteracRefNo,
                    TransactionReferenceNumber:
                      row.original.TransactionReferenceNumber,
                  },
                });
              }}
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 70,
        show: true,
      },
      {
        Header: "Participant Id",
        accessor: "ParticipantId",
      },
      {
        Header: "Participant Name",
        accessor: "ParticipantName",
      },
      {
        Header: "Settlement Date",
        accessor: "TransactionDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Client Id",
        accessor: "ClientId",
      },
      {
        Header: "Client Name",
        accessor: "ClientName",
      },
      {
        Header: "Customer Store",
        accessor: "CustomerStore",
      },
      {
        Header: "Store Code",
        accessor: "CustomerStoreCode",
      },
      {
        Header: "Interac Reference Number",
        accessor: "InteracRefNo",
      },
      {
        Header: "Request Reference Number",
        accessor: "TransactionReferenceNumber",
      },
      {
        Header: "Transaction Type",
        accessor: "TransactionType",
      },
      {
        Header: "Transaction Direction",
        accessor: "Direction",
      },
      {
        Header: "Bridge Status",
        accessor: "TransactionStatus",
      },
      {
        Header: "Settlement File Flag",
        accessor: "SettlementFileFlag",
      },
      {
        Header: "Amount",
        accessor: "Amount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
    ];
  }

  componentDidMount() {
    this.props.setAppLeftTitle("E-Transfer Transactions Settlement by FI");
    this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
    this.setState({ loadAtStartup: true });
  }

  clearSearchCriteria = () => {
    this.setState({ model: this.initialModel });
  };

  setValueToModel = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;
    this.setState({ model });
  };

  setValueToState = (name, value) => {
    this.setState({ [name]: value });
  };

  refreshGridToken = async () => {
    const { gridRefreshToken } = this.state;

    this.setState({ gridRefreshToken: !gridRefreshToken });
  };

  saveMatchingModel = async () => {
    const { matchingModel } = this.state;

    if (this.isMatchingModelValid()) {
      this.showLoading();

      Proxy.POST(
        "/bankapi/v1.0/ClearingSettlement/MatchETransferTransactionManually",
        matchingModel,
        (responseData) => {
          if (!responseData.IsSucceeded) {
            console.log("Error!", responseData);
            this.hideLoading();
            this.showMessage("error", "Error", responseData.ErrorDescription);
            return;
          }

          this.showMessage(
            "success",
            "Success",
            "Operation Successfully Completed!"
          );

          this.refreshGridToken();
          this.closeMatchingDialog();
        },
        (error) => {
          console.log("Error!", error);
          this.hideLoading();
          this.showMessage("error", "Error", error);
        }
      );
    }
  };

  showMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  showLoading() {
    this.setState({ isLoading: true });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  openMatchingDialog() {
    this.setState({ isMatchingDialogOpen: true });
  }

  closeMatchingDialog = () => {
    this.setState({ isMatchingDialogOpen: false });
  };

  onValidate = () => {
    const { model } = this.state;

    if (!model.TransactionDate) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement Date is invalid"
      );
      return false;
    }

    return true;
  };

  isMatchingModelValid = () => {
    const { matchingModel } = this.state;
    if (
      matchingModel.TransactionReferenceNumber &&
      !matchingModel.InteracRefNo
    ) {
      this.props.showMessage(
        "warning",
        "Please fill Interac Reference Number field!",
        "Interac Reference is empty"
      );
      return false;
    }

    if (
      !matchingModel.TransactionReferenceNumber &&
      matchingModel.InteracRefNo
    ) {
      this.props.showMessage(
        "warning",
        "Please fill Request Reference Number field!",
        "Request Reference is empty"
      );
      return false;
    }
    return true;
  };

  isRowActionDisabled = (row) => {
    
     return (
       row.original.TransactionReferenceNumber !== undefined &&
       row.original.InteracRefNo !== "" &&
       row.original.InteracRefNo !== undefined &&
       row.original.TransactionReferenceNumber !== ""
     );
  };

  isPayRowActionDisabled = (row) => {
    return (
      row !== undefined &&
      row.original !== undefined &&
      row.original.IsTransferEnabled ===false
    );
  };

  isSettlementDetailRowActionDisabled = (row) => {
     
     return (
       row !== undefined &&
       row.original !== undefined &&
       row.original.IsReviewRequired ===false
     );
  };

  openExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: true,
    });
  };

  closeExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: false,
    });
  };

  isExportRequestValid = () => {
    const { model } = this.state;

    if (model.TransactionDate == null || model.TransactionDate == undefined) {
      this.showMessage(
        "warning",
        "Please select required field !",
        "Select a Settlement Date !"
      );
      return false;
    }

    return true;
  };

  setExportSelection = (value) => {
    this.setState({ exportSelectedColumns: value });
  };

  getSelectedExportColumns = () => {
    const { exportSelectedColumns } = this.state;

    var columns = {};

    exportSelectedColumns.forEach((c) => {
      columns[c.value] = {
        value: c.value,
        title: c.title,
        columntype: c.columntype,
        type: c.type,
      };
    });

    var result = [];
    result.push(columns);

    return result;
  };

  openSettlementDetailDialog = (row) => {
    const { gridSettlementDetailRefreshToken } = this.state;

    this.setState({
      isSettlementDetailDialogOpen: true,
      selectedRow: row,
      gridSettlementDetailRefreshToken: !gridSettlementDetailRefreshToken,
    });
  };

  closeSettlementDetailDialog = () => {
    this.setState({
      isSettlementDetailDialogOpen: false,
    });
  };

  onExport = () => {
    if (this.isExportRequestValid()) {
      const { model, exportReportType } = this.state;

      const exportModel = { ...model, jsonFile: {} };

      var exportColumns = this.getSelectedExportColumns() ?? [];

      exportModel.jsonFile = {
        colmns: exportColumns,
        format: exportReportType,
        title: "Etransfer Transactions",
      };

      this.showLoading();

      Proxy.DownloadGeneratedFile(
        "/bankapi/v1.0/BankInteracETransfer/GetETransferSettlementMatchingErrorsExcelDownload",
        exportModel,
        (responseData) => {
          this.hideLoading();

          if (!responseData.IsSucceeded) {
            this.showMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
        },
        (errorMessage) => {
          this.hideLoading();
          this.showMessage("error", "Error", errorMessage);
        },
        this.showMessage
      );
    }
  };

  getSettlementDetailDialog = () => {
    const {
      isSettlementDetailDialogOpen,
      gridSettlementDetailRefreshToken,
      loadAtStartup,
      selectedRow,
		model
    } = this.state;

    return (
      <GenericDialog
        open={isSettlementDetailDialogOpen}
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogContent>
          <GridContainer spacing={16}>
            <GenericGrid
              key={"trxGrid" + gridSettlementDetailRefreshToken}
              ref={this.settlementDetailGridRef}
              ServerSide
              RequestUrl="/bankapi/v1.0/ClearingSettlement/GetETransferUnSettledTransactionsPaged"
              RequestMethod="POST"
              PrepareRequest={() => {
                return {
                  FinancialInstitutionId: selectedRow.FIID,
                  TransactionDate: model.TransactionDate,
                };
              }}
              ValidateRequest={this.onValidate}
              ShowPagination={true}
              LoadAtStartup={loadAtStartup}
              PageSize={20}
              Columns={this.settlementDetailColumns}
            />
          </GridContainer>
        </DialogContent>
        <GenericDialogActions>
          <GridContainer justify="flex-end">
            <Button size="sm" onClick={this.closeSettlementDetailDialog}>
              CANCEL
            </Button>
          </GridContainer>
        </GenericDialogActions>
      </GenericDialog>
    );
  };
 
 
  render() {
    const { menuId, ApprovalData, After } = this.props;
    const {
      alert,
      isLoading,
      model,
      isMatchingDialogOpen,
      matchingModel,
      selectedMatchingModelRow,
      gridRefreshToken,
      loadAtStartup,
      exportReportType,
      isDownloadDialogOpen,
      exportSelectedColumns,
    } = this.state;

    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Clear", OnClick: this.clearSearchCriteria },
            {
              Code: "Search",
              OnClick: this.refreshGridToken,
              ModelFunction: () => model,
              FillDataFromModel: (model) => this.setState({ model }),
              ValidationFunction: this.onValidate,
            },
            { Code: "Export", OnClick: this.openExportDialog },
          ]}
          menuId={menuId}
          ApprovalData={ApprovalData}
          After={After}
        />
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Search Parameters">
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericDateInput
                        Name="TransactionDate"
                        LabelText="Settlement Date"
                        ValueChanged={this.setValueToModel}
                        Value={
                          model.TransactionDate
                            ? Formatter.FormatDateUtc(model.TransactionDate)
                            : ""
                        }
                        Utc
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Name="TransactionTypeId"
                        LabelText="Transaction Type"
                        Method="GET"
                        Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
                        DataRoot="Item"
                        KeyValueMember="Id"
                        TextValueMember="ParameterValue6"
                        Value={model.TransactionTypeId}
                        ValueChanged={this.setValueToModel}
                        All
                        CanClear
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        IsStatic
                        Name="FundDirectionId"
                        LabelText="Fund Direction"
                        StaticData={this.directionDataSource}
                        KeyValueMember="Value"
                        TextValueMember="Key"
                        Value={model.FundDirectionId}
                        ValueChanged={this.setValueToModel}
                        All
                        CanClear
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Transactions List"} />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer spacing={16}>
                      <GridItem xs={12}>
                        <GenericGrid
                          key={"trxGrid" + gridRefreshToken}
                          ref={this.settlementErrorsGridRef}
                          ServerSide
                          RequestUrl="/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsSummaryByFI"
                          RequestMethod="POST"
                          PrepareRequest={() => {
                            return model;
                          }}
                          ValidateRequest={this.onValidate}
                          ShowPagination={true}
                          LoadAtStartup={loadAtStartup}
                          PageSize={20}
                          Columns={this.columns}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GenericDialog open={isMatchingDialogOpen} maxWidth="sm" fullWidth>
          <DialogTitle>
            <GenericLabel
              Text="Interac Reference Number Matching"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <GridContainer spacing={16}>
              <GridItem xs={12}>
                <GenericTextInput
                  Name="InteracRefNo"
                  LabelText="Interac Reference Number"
                  Value={matchingModel.InteracRefNo}
                  Disabled={
                    selectedMatchingModelRow &&
                    selectedMatchingModelRow.original &&
                    selectedMatchingModelRow.original.InteracRefNo !==
                      undefined &&
                    selectedMatchingModelRow.original.InteracRefNo !== ""
                  }
                  ValueChanged={(name, value) => {
                    matchingModel[name] = value;
                    this.setState({ matchingModel });
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <GenericTextInput
                  Name="TransactionReferenceNumber"
                  LabelText="Request Reference Number"
                  Value={matchingModel.TransactionReferenceNumber}
                  Disabled={
                    selectedMatchingModelRow &&
                    selectedMatchingModelRow.original &&
                    selectedMatchingModelRow.original
                      .TransactionReferenceNumber !== undefined &&
                    selectedMatchingModelRow.original
                      .TransactionReferenceNumber !== ""
                  }
                  ValueChanged={(name, value) => {
                    matchingModel[name] = value;
                    this.setState({ matchingModel });
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <GenericDialogActions>
            <GridContainer justify="flex-end">
              <Button size="sm" onClick={this.saveMatchingModel}>
                SAVE
              </Button>
              <Button size="sm" onClick={this.closeMatchingDialog}>
                CANCEL
              </Button>
            </GridContainer>
          </GenericDialogActions>
        </GenericDialog>
        <GenericDialog open={isDownloadDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel
              Text="Select Report Type"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GenericRadioInput
              Name="exportReportType"
              LabelText="Report Type"
              IsStatic={true}
              StaticData={[
                { Value: "PDF", Text: "PDF" },
                { Value: "CSV", Text: "CSV" },
                { Value: "EXCEL", Text: "EXCEL" },
              ]}
              KeyValueMember="Value"
              TextValueMember="Text"
              Value={exportReportType}
              ValueChanged={this.setValueToState}
            />
            <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={exportSelectedColumns}
                onChange={this.setExportSelection}
                isMulti
                name="exportSelectedColumns"
                getOptionLabel={(d) => d.title}
                options={this.exportColumns}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.onExport}>
              Download
            </Button>
            <Button size="sm" onClick={this.closeExportDialog}>
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>
        {this.getSettlementDetailDialog()}
      </div>
    );
  }
}

eTransferUnmatchedTransactions.propTypes = {
  classes: PropTypes.object,
};

export default eTransferUnmatchedTransactions;
