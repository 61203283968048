import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import { EditIcon, SubmitOrSendIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, RoleGroup, RoleLevel } from "views/Constants/Constant";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class UserDetail extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Update User Info"
							Icon={EditIcon}
							OnClick={() => this.CellEditButtonClick(row.original.Id)} />

						<GridButton
							tooltip="Resend Login Info"
							Icon={SubmitOrSendIcon}
							OnClick={() => this.ResetPasswordConfirm(row.original.Id)} />
					</div>
				),
				width: 100,
				sortable: false,
				filterable: false,
			},
			{
				Header: "User Name",
				accessor: "UserName"
			},
			{
				Header: "User Role",
				accessor: "RoleRoleName"
			},
			{
				Header: "Name",
				accessor: "FirstName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "ID Type",
				accessor: "IdTypeParameterDesc"
			},
			{
				Header: "ID Number",
				accessor: "IdNumber"
			},
			{
				Header: "Exp. Date of ID",
				accessor: "ExpDateOfId",
				type: GridColumnType.Date
			},
			{
				Header: "User Exp. Date",
				accessor: "UserExpDate",
				type: GridColumnType.Date
			},
			{
				Header: "User Status",
				accessor: "UserStatusParameterDesc"
			} ,
			 {
				Header: "OrganizationUniqueId",
				accessor: "OrganizationUniqueId",
				show: window.OrganizationUniqueId != 5000 ? false : true,
			 }
		];

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.uniqueClientId = ClientHelper.GetClientId();

		this.emptyObject = {};
		this.parameterRole = {};

		this.state = {
			model: {
				UniqueClientId: this.isBackOffice ? undefined : this.uniqueClientId
			},
			roleLevelIdClientAdmin: null,
			isClientStandardUserOpen: false
		};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle) {
			setAppLeftTitle("User");
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("User Admin");
		}

		var user = ClientHelper.GetUser();

		if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser) {
			this.setState({ isClientStandardUserOpen: true });
		} else if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientAdmin) {
			this.GetRoleLevelClientAdmin();
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "RoleGroupId" && data != null) {
				model["IsRoleGroupBackoffice"] = data.ParameterValue == RoleGroup.Backoffice;

				if (data.ParameterValue == RoleGroup.Backoffice) {
					model.UniqueClientId = null;
				}
			}

			return { model };
		});
	}

	GetParameterRole = () => {
		const { model } = this.state;

		this.parameterRole.RoleGroupId = model.RoleGroupId || null;

		return this.parameterRole;
	}

	GetRenderItemClientSelect(d) {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	CellEditButtonClick = (userId) => {
		const { history } = this.props;

		RouteHelper.Push(history, "/NewUserDefinition", "NewUserDefinition_Id", userId);
	}

	ResetPasswordConfirm = (userId) => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;

		showConfirmMessage("warning", "Warning", "Are you sure you want to resend login information? This process will reset the previous password of the user!",
			() => ExecuteApiPost("/coreapi/v1.0/User/ResetPasswordCredential", { Id: userId }, null, null, null, null, true));
	}

	GetRoleLevelClientAdmin = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "RoleLevel", ParameterValue: "ClientStandardUser" });
		var roleLevelIdClientAdmin = -1;

		if (result != null && result.length == 1) {
			roleLevelIdClientAdmin = result[0].Id;
		}

		this.setState({ roleLevelIdClientAdmin });
	}

	Search = async () => {
		const { model, roleLevelIdClientAdmin } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = model;

		request.RoleGroupId = request.RoleGroupId != -1
			? request.RoleGroupId
			: null;
		request.RoleLevelId = roleLevelIdClientAdmin;
		request.RoleId = request.RoleId != -1
			? request.RoleId
			: null;

		var result = await ExecuteApiPost("/coreapi/v1.0/User/Search", request);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		const { RoleGroupId } = this.state.model;
		var model = {};

		model.UniqueClientId = this.isBackOffice ? undefined : this.uniqueClientId;

		if (!ClientHelper.IsBackOffice()) {
			model.RoleGroupId = RoleGroupId;
		}

		this.setState({ model, list: [] });
	}

	render() {
		const { model, list, isClientStandardUserOpen } = this.state;
		const { history, Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear,
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isClientStandardUserOpen} maxWidth="md" TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel Bold Text="Error" />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text="You cannot open this screen." />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="User Details">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<ParameterComponent
													All
													Disabled={!this.isBackOffice}
													Name="RoleGroupId"
													LabelText="User Type"
													ParameterCode="RoleGroup"
													DefaultParamValue={this.isBackOffice ? undefined : RoleGroup.Client}
													Value={model.RoleGroupId || -1}
													ValueChanged={this.ValueChanged} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													CanClear
													Disabled={!this.isBackOffice || model.IsRoleGroupBackoffice}
													Name="UniqueClientId"
													LabelText="Client"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Method="POST"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.GetRenderItemClientSelect}
													DefaultIndex={!this.isBackOffice || model.IsRoleGroupBackoffice ? 0 : null}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													All
													key={model.RoleGroupId}
													Name="RoleId"
													LabelText="User Role"
													Url="/coreapi/v1.0/Role/GetRoleListByRoleGroupId"
													Method="POST"
													Parameter={this.GetParameterRole()}
													DataRoot="RoleList"
													KeyValueMember="Id"
													TextValueMember="RoleName"
													Value={model.RoleId || -1}
													ValueChanged={this.ValueChanged} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="UserName"
													LabelText="User Name"
													Value={model.UserName}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={list}
									Columns={this.columns}
									IsSorted={false} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

UserDetail.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default UserDetail;