import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericDateInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import moment from "moment";
class SetPinLogOp extends Component {
  constructor(props) {
    super(props);
    this.initialModel = {
      UniqueId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      FromDate: null,
      ToDate: null,
    };
    this.state = {
      model: this.initialModel,
      vModel: {},
      list: [],
      isLoading: false,
    };
    this.emptyObject = {};
    this.Columns = [
      {
        Header: "Client",
        accessor: "Client",
      },
      {
        Header: "Total Number of Calls",
        accessor: "TotalCount",
      },
      {
        Header: "Total Number of Successful Calls",
        accessor: "SuccessfulCount",
      },
      {
        Header: "Total Number of Unsuccessful Calls",
        accessor: "UnSuccessfulCount",
      },
    ];
  }
  componentDidMount() {
    this.props.setAppLeftTitle("Set Pin Op Report");
    this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
  }
  HandleChange = (name, newValue) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };
  HandleClear = () => {
    this.setState({
      model: { ...this.initialModel },
      vModel: {},
      isEdit: false,
      index: -1,
    });
  };
  HandleSearch = () => {
    if (!this.Validate()) {
      return;
    }
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/CardStatus/GetSetPinLog",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ list: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  Validate = () => {
    const { model, vModel } = this.state;
    var errorList = [];
    const { showValidationErrors } = this.props;
    vModel.FromDate =
      model.FromDate == null ||
      model.FromDate == "" ||
      model.FromDate == undefined;
    if (vModel.FromDate) {
      errorList.push("From Date cannot be empty.");
    }
    vModel.ToDate =
      model.ToDate == null || model.ToDate == "" || model.ToDate == undefined;
    if (vModel.ToDate) {
      errorList.push("To Date cannot be empty.");
    }
    if (errorList.length > 0) {
      showValidationErrors(errorList);
      return false;
    }
    return true;
  };
  render() {
    const { Disabled } = this.props;
    const { alert, model, list, isLoading, index, vModel } = this.state;
    var IsClient = ClientHelper.IsClient();
	 var endDate = (model.FromDate instanceof moment) ? model.FromDate.clone() : "";
	 var fromDate = (model.ToDate instanceof moment) ? model.ToDate.clone() : "";
    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Disabled={IsClient}
                            Name="UniqueId"
                            LabelText="Client"
                            Url="/bankapi/v1.0/BankCustomer/Search"
                            Method="POST"
                            Parameter={{}}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            RenderItem={(d) =>
                              `${d.UniqueClientId} - ${d.Name}`
                            }
                            TextValueMember="Name"
                            Value={model.UniqueId}
                            ValueChanged={this.HandleChange}
                            CanClear
                            All
                            Required
                            DefaultIndex={IsClient ? 0 : -1}
                          />
                          <GenericDateInput
                            Utc
                            Name="FromDate"
                            LabelText="Start Date"
                            Value={
                              model.FromDate
                                ? DateHelper.ToDateInputValue(model.FromDate)
                                : ""
                            }
                            ValueChanged={this.HandleChange}
									 MinDate={fromDate != "" ? fromDate.add(-30, "days") : undefined}
                            MaxDate={
                              model.ToDate 
                            }
                          />
                          <GenericDateInput
                            Utc
                            Name="ToDate"
                            LabelText="End Date"
                            Value={
                              model.ToDate
                                ? DateHelper.ToDateInputValue(model.ToDate)
                                : ""
                            }
                            ValueChanged={this.HandleChange}
                            MinDate={model.FromDate}
                            MaxDate={endDate != "" ? endDate.add(30, "days") : undefined}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"API Call Report"} />
              </CardHeader>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GenericGrid
                      Data={list}
                      Columns={this.Columns}
                      SelectedIndex={index}
                      ShowPagination={true}
                      PageSize={100}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
SetPinLogOp.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
};
export default withArtifex(SetPinLogOp, {});