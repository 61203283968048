import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import { GridColumnType, IdentificationMethod } from "views/Constants/Constant";
import Select from "react-select";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent.jsx";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class IdVerificationTracking extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Applicant Name",
				accessor: "ApplicantName"
			},
			{
				Header: "Email Address",
				accessor: "EmailAddress"
			},
			{
				Header: "Verification Type",
				accessor: "IdentityVerificationTypeParameterDesc"
			},
			{
				Header: "Verification Status",
				accessor: "VerificationStatus"
			},
			{
				Header: "Verification Date",
				accessor: "VerificationDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Person Verified",
				accessor: "PersonVerified"
			}
		];

		this.columnsEmail = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Remove Email Address"
							Icon={DeleteIcon}
							OnClick={() => this.EmailAddressRemoveFromList(row.original.Id, row.original.EmailAddress)} />
					</div>
				),
				width: 50
			},
			{
				Header: "Email Address",
				accessor: "EmailAddress"
			}
		];

		this.idVerTraTable = React.createRef();

		this.state = {
			model: {},
			listEmailAddress: [],
			isLoading: false,
			isEmailAddressListDialogOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("ID Verification Tracking");
		}

		this.GetIdentityVerificationType();
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "EmailAddress") {
				model["IsEmailAddressValid"] = data.IsValid;
			}

			return { model };
		});
	}

	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}

	GetIdentityVerificationType = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "IdentificationMethod" });
		var listVerificationType = [];

		if (result != null) {
			result
				.filter(x => x.ParameterValue == IdentificationMethod.CanadaPostVerification
					|| x.ParameterValue == IdentificationMethod.InPersonIDVerification
					|| x.ParameterValue == IdentificationMethod.Other)
				.map(x => {
					listVerificationType.push(x);

					return null;
				});
		}

		this.setState({ listVerificationType });
	}

	EmailAddressListDialogOpen = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "IdVerificationTrackingEmailAddress" });
		var listEmailAddress = [];

		if (result != null) {
			result.map(x => {
				listEmailAddress = listEmailAddress.concat({
					Id: x.Id,
					EmailAddress: x.ParameterValue
				});

				return null;
			});
		}

		this.setState({ listEmailAddress, isEmailAddressListDialogOpen: true });
	}

	EmailAddressListDialogClose = () => {
		this.setState({ isEmailAddressListDialogOpen: false });
	}

	EmailAddressAddToListAndInsert = async () => {
		var { model, listEmailAddress } = this.state;
		const { showMessage } = this.props;
		var emailAddressObject = { "EmailAddress": model.EmailAddress };

		if (listEmailAddress.find(x => x.EmailAddress == model.EmailAddress)) {
			showMessage("warning", "Warning", "This email address already added.");

			return;
		}

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/IdVerificationTracking/InsertEmailAddress", { EmailAddress: model.EmailAddress });

		if (result == null) {
			return;
		}

		listEmailAddress = listEmailAddress.concat(emailAddressObject);

		model.EmailAddress = null;
		model.IsEmailAddressValid = null;

		this.setState({ model, listEmailAddress });
	}

	EmailAddressRemoveFromList = async (emailAddressId, emailAddress) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/IdVerificationTracking/DeleteEmailAddress", { EmailAddressId: emailAddressId });

		console.log("Ema: " + emailAddressId);

		if (result == null) {
			return;
		}

		var { listEmailAddress } = this.state;
		var newlistEmailAddress = [];

		listEmailAddress
			.filter(x => x.EmailAddress != emailAddress)
			.map(x => {
				newlistEmailAddress = newlistEmailAddress.concat(x);

				return null;
			});

		this.setState({ listEmailAddress: newlistEmailAddress });
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/IdVerificationTracking/Search", model);

		this.setState({ list: result || [] });
	}

	ExportClick = () => {
		var idVerTraColumns = [];
		this.idVerTraTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				idVerTraColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: idVerTraColumns, ExportSelectedColumns: idVerTraColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };
		

		var jsonColList = [];

		var idVerTraColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			idVerTraColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(idVerTraColumns);

		var mytitle = "ID Verification Tracking";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			VerificationTypeId: temp.VerificationTypeId,
			FromDate: temp.FromDate,
			ToDate: temp.ToDate,
			jsonFile:temp.jsonFile
		};
		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/losapi/v1.0/IdVerificationTracking/ExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	showLoading = () => { this.setState({  isLoading: true }); }
	hideLoading = () => { this.setState({  isLoading: false }); }

	Clear = () => {
		this.setState({ model: { AmPmId: 1 }, list: [], selected: null });
	}

	ValidateUpdateReportTime = () => {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (model.ReportTimeHour == null) {
			errors.push("Hour cannot be null.");
		}

		if (model.ReportTimeMinute == null) {
			errors.push("Minute cannot be null.");
		}

		if (model.ReportTimeHour > 12) {
			errors.push("Hour cannot be greater than 12.");
		}

		if (model.ReportTimeMinute > 59) {
			errors.push("Hour cannot be greater than 59.");
		}

		model["IsReportTimeHour"] = model.ReportTimeHour == null;
		model["IsReportTimeMinute"] = model.ReportTimeMinute == null;


		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list,isLoading, listVerificationType, listEmailAddress, isEmailAddressListDialogOpen } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				{alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{
							Code: "Export",
							OnClick: this.ExportClick,
							Disabled: Disabled
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isEmailAddressListDialogOpen} maxWidth="lg" onBackdropClick={this.EmailAddressListDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel Bold Text="Email Address List" FontSize="20px" />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={9}>
								<GenericEmailInput
									Name="EmailAddress"
									LabelText="Email Address"
									Value={model.EmailAddress}
									ValueChanged={this.ValueChanged} />
							</GridItem>
							<GridItem xs={3}>
								<Button
									disabled={!model.IsEmailAddressValid}
									size="sm"
									onClick={this.EmailAddressAddToListAndInsert}>
									Add
								</Button>
							</GridItem>
							<GridItem xs={12}>
								<GenericGrid
									Data={listEmailAddress}
									Columns={this.columnsEmail} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.EmailAddressListDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="ID Verification Tracking">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic
												Name="VerificationTypeId"
												LabelText="Verification Type"
												StaticData={listVerificationType}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.VerificationTypeId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												IncludeTime={false}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={2}>
											<Button
												size="sm"
												onClick={this.EmailAddressListDialogOpen} >
												Email Address List
											</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													ref={this.idVerTraTable}
													PageSize={10}
													ShowPagination={true}
													Columns={this.columns}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

IdVerificationTracking.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default IdVerificationTracking;