import { Proxy } from "core";
import { ResourceType } from "views/Constants/Constant";

const resourceText = "ResourceData";
const resourceTextForMenu = "ResourceDataForMenu";

/**
 * @param {number} languageId
 * @param {function} afterLanguageSet
 */
function SetLanguage(languageId, afterLanguageSet = null) {
	Proxy.POST(
		"/coreapi/v1.0/Resource/GetByLanguageId",
		languageId ,
		responseData => {
			if (!responseData.IsSucceeded) {
				console.error(responseData.ErrorDescription);
				return;
			}
			responseData.Item.forEach(item => {
				item.Description = CorrectAccountNames(item.Description);
			})
			localStorage.setItem(resourceText, JSON.stringify(responseData.Item));
			if (responseData.Item != null) {
				localStorage.setItem(resourceTextForMenu, JSON.stringify(responseData.Item.filter(x => x.Type == ResourceType.Menu)));
			}
			if (afterLanguageSet)
				afterLanguageSet();
		},
	);
}

/**
 * @param {string} input
 */
function CorrectAccountNames(input) {
	var output;
	if (input != null && typeof input == "string" && !input.includes("Accounting")) {
		output = input.replaceAll("Accounts - From To Accounting Transaction", "Wallet - From To Accounting Transaction");
		output = input.replaceAll("Bank Account", "Payment Wallet");
		output = output.replaceAll("Account", "Wallet");
		output = output.replaceAll("Banking", "Wallet");
		output = output.replaceAll("ACCOUNTS", "WALLET");
		output = output.replaceAll("ACCOUNT", "WALLET");
		output = output.replaceAll("account", "wallet");
		output = output.replaceAll("banking", "wallet");
		output = output.replaceAll("bank account", "payment wallet");
	}else {
		output = input;
	}
	return output;
}

/**
 * @returns {Array<{Code: string, Description: string}>}
 */
function GetAll() {
	var loc = localStorage.getItem(resourceText) || "[]";
	var all = JSON.parse(loc);
	return all || [];
}

/**
 * @param {string} code
 * @returns {string}
 */
function Get(code) {
	var data = GetAll().find(x => x.Code == code) || { Code: code, Description: "" };
	var desc = data.Description || "";
	return desc || `! ${code}`;
}

/**
 * @returns {Array<{Code: string, Description: string}>}
 */
function GetAllForMenu() {
	var loc = localStorage.getItem(resourceTextForMenu) || "[]";
	var all = JSON.parse(loc);
	return all || [];
}

/**
 * @param {string} code
 * @returns {string}
 */
function GetForMenu(code) {
	var data = GetAllForMenu().find(x => x.Code == code) || { Code: code, Description: "" };
	var desc = data.Description || "";
	return desc || `! ${code}`;
}
/**
 * @param {string} name
 * @returns {Array}
 */
function GetCodeByName(name) {
	var list = GetAllForMenu().filter(x => x.Description.startsWith(name));
	return list;
}

function Any() {
	var loc = localStorage.getItem(resourceText);
	return loc != null;
}

const ResourceHelper = {
	SetLanguage,
	Get,
	GetAll,
	GetAllForMenu,
	GetForMenu,
	GetCodeByName,
	CorrectAccountNames,
	Any
};

export default ResourceHelper;