import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import React from "react";

class DashboardHelper {
	constructor(handleAlertChange, taskpoolDomain) {
		this.ShowMessage = this.ShowMessage.bind(this);
		this.HideAlert = this.HideAlert.bind(this);
		this.ShowMessage = this.ShowMessage.bind(this);
		this.ShowConfirmMessage = this.ShowConfirmMessage.bind(this);
		this.ShowValidationErrors = this.ShowValidationErrors.bind(this);
		this.ShowMessageAfterOperation = this.ShowMessageAfterOperation.bind(this);
		this.ShowConfirmMessageInDelete = this.ShowConfirmMessageInDelete.bind(
			this
		);
		this.ExecuteApiGet = this.ExecuteApiGet.bind(this);
		this.ExecuteApiPost = this.ExecuteApiPost.bind(this);
		this.ExecuteApiFileUpload = this.ExecuteApiFileUpload.bind(this);
		this.ExecuteApiImageUpload = this.ExecuteApiImageUpload.bind(this);
		this.ExecuteApiFileDownload = this.ExecuteApiFileDownload.bind(this);
		this.ExecuteApiFileDownloadWithGenericResponse = this.ExecuteApiFileDownloadWithGenericResponse.bind(
			this
		);

		this.handleAlertChange = handleAlertChange || (() => null);
		this.taskpool = taskpoolDomain || (() => null);
	}
	HideAlert() {
		this.handleAlertChange(null);
	}

	async ExecuteApiGet(
		url,
		validateObject,
		validateProperties,
		validateFunction,
		afterMethods,
		showSuccessfulMessage
	) {
		var errors = [];

		if (validateFunction && !validateFunction()) {
			return;
		}

		if (validateObject && validateProperties) {
			errors = [];

			validateProperties.map(x => {
				var key = Object.keys(x)[0];
				if (!validateObject[key]) {
					validateObject[`Is${key}`] = true;

					errors.push(`${x[key]} cannot be empty.`);
				} else {
					validateObject[`Is${key}`] = false;
				}

				return null;
			});

			if (errors.length > 0) {
				this.ShowValidationErrors(errors);

				return;
			}
		}

		var request = Proxy.GET(
			url,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (showSuccessfulMessage) {
					this.ShowMessageAfterOperation();
				}

				afterMethods &&
					afterMethods.map(x => {
						x();

						return null;
					});
			},
			error => {
				this.ShowMessage(
					"error",
					"An error occurred while sending data",
					error.message
				);
			}
		);
		this.taskpool && this.taskpool.AppendTask && this.taskpool.AppendTask(request);
		var result = await request;
		return result.Item;
	}

	async ExecuteApiPost(
		url,
		parameter,
		validateObject,
		validateProperties,
		validateFunction,
		afterMethods,
		showSuccessfulMessage,
		workflowId
	) {
		var errors = [];

		if (validateFunction && !validateFunction()) {
			return;
		}

		if (validateObject && validateProperties) {
			errors = [];

			validateProperties.map(x => {
				var key = Object.keys(x)[0];
				if (!validateObject[key]) {
					validateObject[`Is${key}`] = true;

					errors.push(`${x[key]} cannot be empty.`);
				} else {
					validateObject[`Is${key}`] = false;
				}

				return null;
			});

			if (errors.length > 0) {
				this.ShowValidationErrors(errors);

				return;
			}
		}

		var request = Proxy.POST(
			url,
			parameter,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (showSuccessfulMessage) {
					this.ShowMessageAfterOperation();
				}

				afterMethods &&
					afterMethods.map(x => {
						x();

						return null;
					});
			},
			error => {
				this.ShowMessage(
					"error",
					"An error occurred while sending data",
					error.message
				);
			},
			workflowId
		);
		this.taskpool && this.taskpool.AppendTask && this.taskpool.AppendTask(request);
		var result = await request;
		return result.Item;
	}

	async ExecuteApiFileUpload(
		url,
		formData,
		afterMethods,
		showSuccessfulMessage
	) {
		var request = Proxy.FileUpload(
			url,
			formData,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (showSuccessfulMessage) {
					this.ShowMessageAfterOperation();
				}

				afterMethods &&
					afterMethods.map(x => {
						x();

						return null;
					});
			},
			error => {
				this.ShowMessage(
					"error",
					"An error occurred while sending data",
					error.message
				);
			}
		);
		this.taskpool && this.taskpool.AppendTask && this.taskpool.AppendTask(request);
		var result = await request;
		return result.Item;
	}

	async ExecuteApiImageUpload(
		url,
		formData,
		afterMethods,
		showSuccessfulMessage
	) {
		var request = Proxy.FileUpload(
			url,
			formData,
			responseData => {
				console.log(responseData.IsSucceeded);
				if (responseData.IsSucceeded == true) {
					this.ShowMessage("success", "Success", "Upload Image");
					return;
				}
				else if (responseData.IsSucceeded == false) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (showSuccessfulMessage) {
					this.ShowMessageAfterOperation();
				}

				afterMethods &&
					afterMethods.map(x => {
						x();

						return null;
					});
			},
			error => {
				this.ShowMessage(
					"error",
					"An error occurred while sending data",
					error.message
				);
			}
		);
		this.taskpool && this.taskpool.AppendTask && this.taskpool.AppendTask(request);
		var result = await request;
		return result;
	}

	ExecuteApiFileDownload = (url, fileId, fileName, fileExtension) => {
		var request = Proxy.FileDownload(
			url,
			fileName,
			fileExtension,
			fileId,
			() => { },
			error => {
				this.ShowMessage(
					"error",
					"An error occurred while sending data",
					error.message
				);
			}
		);
		this.taskpool && this.taskpool.AppendTask && this.taskpool.AppendTask(request);
	};

	async ExecuteApiFileDownloadWithGenericResponse(
		url,
		fileId,
		fileName,
		fileExtension
	) {
		var request = Proxy.FileDownloadWithGenericResponse(
			url,
			fileName,
			fileExtension,
			fileId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			error => {
				this.ShowMessage(
					"error",
					"An error occurred while sending data",
					error.message
				);
			}
		);
		this.taskpool && this.taskpool.AppendTask && this.taskpool.AppendTask(request);
		var result = await request;
		return result;
	}

	/**
	 * @return {Promise<boolean>} Returns true when clicked.
	 */
	ShowMessage(type, title, message) {
		return new Promise(resolve => {
			var wrappedHideAlert = () => {
				this.HideAlert();
				resolve(true);
			};
			this.handleAlertChange(
				AlertHelper.CreateAlert(title, message, type, wrappedHideAlert)
			);
		});
	}

	/**
	 * @return {Promise<boolean>}  Returns true when clicked confirm, otherwise false.
	 */
	ShowConfirmMessage(type, title, message, onConfirm) {
		return new Promise(resolve => {
			this.handleAlertChange(
				AlertHelper.CreateQuestionAlert(
					title,
					message,
					type,
					e => {
						onConfirm && onConfirm(e);
						this.HideAlert();
						resolve(true);
					},
					() => {
						this.HideAlert();
						resolve(false);
					}
				)
			);
		});
	}

	ShowValidationErrors(errors) {
		if (errors.length > 9) {
			var moreWarning = errors.length - 8;
			errors = errors.slice(0, 8);
			errors.push("And " + moreWarning + " more warnings.");
		}

		return this.ShowMessage(
			"error",
			"Please Fill Required Fields",
			errors.map((x, i) => React.createElement("div", { key: i }, x))
		);
	}

	ShowMessageAfterOperation() {
		return this.ShowMessage("success", "Operation Successfully Completed");
	}

	ShowConfirmMessageInDelete(method) {
		return this.ShowConfirmMessage(
			"warning",
			"Warning",
			"Are you sure want to delete?",
			method
		);
	}
}

export default DashboardHelper;