import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import {GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import Select from "react-select";

class TspTokenPage extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			SearchEndDate: DateHelper.GetLocalDateAsUtc(),
			SearchStartDate: DateHelper.GetLocalDateAsUtc(),
			ClientId: 0,
			IsBankingScreen: false,
			searchIsNotSpecific: true
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			tempModel: {},
			getDataList: [],
			summaryModel: {},
			isLoading: false,
			isBackOffice: false,
			selectList: [],
			checkedValues: [],
			cancelRequestModel: {
				SelectedETransfer: {},
				cancellationReason: null
			},
			cancelDialog: false,
			vIndividualModel: {},
			LimitToken: false,
			remindModel: {},
			remindDialog: false
		};

		this.sortedByDate = [{ id: "TransactionDate", desc: true }];
		this.maxLength400 = { maxLength: 400 };
		this.isClient = ClientHelper.IsClient();
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.isBankingScreen = this.props.MenuCode == "BETOUT";
		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Tsp / Mdes Tokens");

		var model = { ...this.state.model };
		var initialModel = this.initialModel;

		model.IsBankingScreen = this.props.MenuCode == "BETOUT";
		var isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			initialModel.ClientId = model.ClientId;
			this.props.setAppCenterTitle(model.IsBankingScreen ? "WALLET" : "Mdes Token");
		}
		else {
			this.props.setAppCenterTitle("Mdes Token");
		}
		this.RefreshToolBar();
		this.setState({ model, isBackOffice, loadingCompleted: true });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleChangeCancelModel = (name, newValue) => {
		const cancelRequestModel = { ...this.state.cancelRequestModel };
		cancelRequestModel.cancellationReason = newValue;
		this.setState({ cancelRequestModel });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetData() { this.setState(state => ({ trxGridToken: !state.trxGridToken })); this.GetSummaryData(); }

	GetSummaryData = () => {
		/*if (!this.Validate())
			return;
*/
		Proxy.POST(
			"/tspapi/v1.0/MdesToken/GetAllTokenByCliendIdWithParameters",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ summaryModel: responseData.Item });
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;

		var searchIsNotSpecific = (model.ClientId == undefined )
			
		model["searchIsNotSpecific"] = searchIsNotSpecific;

		var validateStatus = true;

		if (searchIsNotSpecific) {

			if (!model.ClientId) {
				this.props.showMessage("warning", "Please fill required fields!", "Select Client to continue");
				vModel["ClientId"] = true;
				validateStatus = false;
			}

			if (typeof model.SearchStartDate != "object" && model.SearchStartDate != "" && model.SearchStartDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
				validateStatus = false;
			}
			if (typeof model.SearchEndDate != "object" && model.SearchEndDate != "" && model.SearchEndDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
				validateStatus = false;
			}
			if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object") &&
				DateHelper.ToMoment(model.SearchEndDate) < DateHelper.ToMoment(model.SearchStartDate)) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
				validateStatus = false;
			}

			this.setState({ model, vModel });
			if (!validateStatus)
				return false;
		}
		else {
			vModel["ClientId"] = false;
			this.setState({ model, vModel });
		}
		return true;
	}

	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.TransactionList == null || responseData.Item.TransactionList.length < 1) {
			this.setState({ summaryModel: {}, cancelRequestModel: null, getDataList: [], isLoading: false });
		} else {
			this.setState({
				cancelRequestModel: { SelectedETransfer: {}, cancellationReason: null },
				getDataList: responseData.Item.TransactionList == null ? [] : responseData.Item.TransactionList, isLoading: false
			});
		}
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			isLoading: false, cancelDialog: false, remindDialog: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleClear = () => {
		this.setState({
			model: this.initialModel,
			getDataList: [],
			summaryModel: {}
		});
	}

	hideAlert() { this.setState({ alert: null }); }
	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ alert: null, isLoading: false }); }
	hideDialog = () => { this.setState({ cancelDialog: false, remindDialog: false }); }

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Tsp / Token List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/tspapi/v1.0/ClearingSettlement/GetETransferOutgoingTransfersExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, vModel } = this.state;

		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Summary Information"}>
						<CardBody>
							<GridContainer>
							   <GridItem xs={4}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{
											UniqueClientId: ClientHelper.IsClient()
												? ClientHelper.GetClientId()
												: undefined
										}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={ClientHelper.IsClient()}
										DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="ValidFrom"
										LabelText="From Date"
										LabelMd={4}
										IsFuture={false}
										MaxDate={DateHelper.GetLocalDateAsUtc()}
										Value={model.ValidFrom == null ? "" : DateHelper.ToDateInputValue(model.ValidFrom) || undefined}
										ValueChanged={this.handleChange}
										Utc
										IsInvalid={vModel.ValidFrom}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="ValidTo"
										LabelText="To Date"
										LabelMd={4}
										IsFuture={false}
										MinDate={model.ValidFrom}
										Value={model.ValidTo == null ? "" : DateHelper.ToDateInputValue(model.ValidTo) || ""}
										ValueChanged={this.handleChange}
										Utc
										IsInvalid={vModel.ValidTo}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Token List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									PageSize={10}
									ShowPagination={false}
									Columns={[
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Customer Name",
											accessor: "CustomerName"
										},
										{
											Header: "Masked Card Number",
											accessor: "CardTokenNumber"
										},
										{
											Header: "Is Activated",
											accessor: "IsActivated",
											type: GridColumnType.YesNo,
											ColumnType: GridColumnType.YesNo
										},
										{
											Header: "Activation DateTime",
											accessor: "ActivationDateTime",
											type: GridColumnType.Datetime,
											ColumnType: GridColumnType.Datetime
										},
										{
											Header: "Is Deactivated",
											accessor: "IsDeactivated",
											type: GridColumnType.YesNo,
											ColumnType: GridColumnType.YesNo
										},
										{
											Header: "Deactivation DateTime",
											accessor: "DeactivationDateTime",
											type: GridColumnType.Datetime,
											ColumnType: GridColumnType.Datetime
										},
										{
											Header: "Masked Token Number",
											accessor: "TokenMasked"
										},
										{
											Header: "Token Expiry Month",
											accessor: "TokenExpiryMonth"
										},
										{
											Header: "Token Expiry Year",
											accessor: "TokenExpiryYear"
										},
										{
											Header: "Token Device Name",
											accessor: "TokenDeviceName"
										},
										{
											Header: "Address Line1",
											accessor: "AddressLine1"
										},
										{
											Header: "Address Line2",
											accessor: "AddressLine2"
										},
										{
											Header: "Country",
											accessor: "Country"
										},
										{
											Header: "Country Subdivision",
											accessor: "CountrySubdivision"
										},
										{
											Header: "City",
											accessor: "City"
										},
										{
											Header: "Postal Code",
											accessor: "PostalCode"
										},
										{
											Header: "Form Factor",
											accessor: "FormFactor"
										},
										{
											Header: "Error Code",
											accessor: "ErrorCode"
										},
										{
											Header: "Error Description",
											accessor: "ErrorDescription"
										},
										{
											Header: "Number Of Active Tokens",
											accessor: "NumberOfActiveTokens"
										},
										{
											Header: "Insert DateTime",
											accessor: "InsertDateTime",
											type: GridColumnType.Datetime,
											ColumnType: GridColumnType.Datetime
										},
										{
											Header: "Insert User",
											accessor: "InsertUser"
										},
										{
											Header: "Update DateTime",
											accessor: "UpdateDateTime",
											type: GridColumnType.Datetime,
											ColumnType: GridColumnType.Datetime
										},
										{
											Header: "Update User",
											accessor: "UpdateUser"
										}
									]}
									Sorted={this.sortedByDate}
									ProgramCode={ProgramCodes.Tsp}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									ValidateRequest={this.Validate}
									PrepareRequest={() => { return this.state.model; }}
									RequestUrl="/tspapi/v1.0/MdesToken/GetTokenWithParameter"
									RequestMethod="POST" />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

TspTokenPage.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(TspTokenPage, {});