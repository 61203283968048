import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApprovalRejected extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			model: {}
		};
		this.paramApprovalSubStatus = { ParameterCode: "LOSApprovalSubStatus", ParameterValue2: "2" };
		this.emptyObject = {};


	}

	componentDidMount() {

	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	GetUserName() {
		var userJson = localStorage.getItem("user");
		var user = userJson ? JSON.parse(userJson) : null;

		if (!user || !user.SessionId)
			return null;
		return user.UserName;
	}

	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	render() {
		const { model } = this.props;

		return (
			<GridContainer>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<GridItem xs={12} style={{ marginTop: 10 }}>
					<GridItem xs={12}>
						<GenericSelectInput
							Name="ApprovalSubStatusId"
							LabelText="Rejection Reason"
							Method="POST"
							IsStatic={true}
							StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.paramApprovalSubStatus.ParameterCode
								&& prm.ParameterValue2 == this.paramApprovalSubStatus.ParameterValue2)}
							// Url="/coreapi/v1.0/Parameter/Search"
							// Parameter={this.paramApprovalSubStatus}
							DataRoot="Item"
							KeyValueMember="ParameterValue"
							TextValueMember="ParameterDesc"
							Value={model.ApprovalSubStatusId}
							ValueChanged={this.props.HandleChange}
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericTextInput
							Name="ApprovalSubStatusComment"
							LabelText="Additional Comment"
							MultiLine={true}
							Value={model == null || undefined ? "" : model.ApprovalSubStatusComment}
							ValueChanged={this.props.HandleChange}
						/>
					</GridItem>
				</GridItem>
				<GridContainer
					direction="row"
					justify="flex-start"
					alignItems="center"
					style={{ marginTop: 20 }}>
					<GridItem xs={4}>
						<GenericLabel Text={"User : " + this.GetUserName()} Bold={true} />
					</GridItem>
					<GridItem xs={4}>
						<GenericLabel Text={"Date : " + Formatter.FormatDate(DateHelper.GetDateTimeLocal())} Bold={true} />
					</GridItem>
				</GridContainer>
			</GridContainer>

		);
	}

}

LoanApprovalRejected.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	HandleChange: PropTypes.func,
	ParameterList: PropTypes.array,
};

export default withArtifex(LoanApprovalRejected, {});