import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericCheckInput, GenericTextInput } from "views/Components/Generic";

class General extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { classes, model, code, Disabled,vModel } = this.props;
		return (
			<div>

				<GridContainer>
					<GridItem xs={3}>
						<GenericTextInput
							inputProps={{ maxLength: 6 }}
							Name="Code"
							LabelText="Code"
							Value={code}
							ValueChanged={this.props.handleChangeScreen}
							Required
							IsInvalid={vModel.Code} 
							/>
					</GridItem>
					<GridItem xs={3}>
						<GenericTextInput
							Name="DataModel"
							LabelText="Data Model"
							Value={model.DataModel}
							ValueChanged={this.props.handleChange}/>
					</GridItem>
					<GridItem xs={3}>
						<GenericTextInput
							Name="HeaderText"
							LabelText="Header Text"
							Value={model.HeaderText}
							ValueChanged={this.props.handleChange} 
							Required
							IsInvalid={vModel.HeaderText} 
							/>
					</GridItem>
					<GridItem xs={3}>
						<GenericTextInput
							Name="HeaderTableText"
							LabelText="Table Text"
							Value={model.HeaderTableText}
							ValueChanged={this.props.handleChange}/>
					</GridItem>
					<GridItem xs={3}>
						<GenericTextInput
							Name="LeftTitle"
							LabelText="Left Title"
							Value={model.LeftTitle}
							ValueChanged={this.props.handleChange}
							Required
							IsInvalid={vModel.LeftTitle} 
							/>
					</GridItem>
					<GridItem xs={3}>
						<GenericTextInput
							Name="CenterTitle"
							LabelText="Center Title"
							Value={model.CenterTitle}
							ValueChanged={this.props.handleChange}
							Required
							IsInvalid={vModel.CenterTitle} 
							/>
					</GridItem>
					<GridItem xs={3}>
						<div style={{ margin: 4 }}>
							<GenericCheckInput
								Name="IsNotFillFromGrid"
								LabelText="Is Not Fill From Grid"
								Value={model.IsNotFillFromGrid}
								ValueChanged={this.props.handleBooleanChange} />
						</div>
					</GridItem>
					<GridItem xs={3}>
						<div style={{ margin: 4 }}>
							<GenericCheckInput
								Name="DocumentButtons"
								LabelText="Is Show Document Buttons"
								Value={model.DocumentButtons}
								ValueChanged={this.props.handleBooleanChange} />
						</div>
					</GridItem>
					<GridItem xs={3}>
						<div style={{ margin: 4 }}>
							<GenericCheckInput
								Name="ShowPagination"
								LabelText="Show Pagination"
								Value={model.ShowPagination}
								ValueChanged={this.props.handleBooleanChange} />
						</div>
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

General.propTypes = {
	classes: PropTypes.object.isRequired,
	code: PropTypes.object,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleChangeScreen: PropTypes.func,
	handleBooleanChange: PropTypes.func
};

export default withArtifex(General, {});
