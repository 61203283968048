import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericDateInput, GenericSelectInput } from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import { ProgramCodes, } from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import CustomerComponent from "views/Components/CustomerComponent";

class DailyInterestPoolMonitoring extends React.Component {
	constructor(props) {
		super(props);
		this.maxLength19 = { maxLength: 19 };

		this.emptyObject = {};
		this.sortedName = { Member: "Name" };
		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientRowId()
				: undefined
		};


		this.columns = [
			{
				Header: "Days",
				accessor: "DayNumber"
			 },
			 {
				Header: "Date",
				accessor: "FormattedProcessDate"
			 },
			 {
				Header: "Purchase Debt Subject to Interest",
				accessor: "FormattedSaleDebt"
			 },
			 {
				Header: "Purchase Interest",
				accessor: "SaleInterestAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			 },
			 {
				Header: "Purchase Interest Rate",
				accessor: "SaleInterestRate"
			 },
			 {
				Header: "Cash Advance Debt Subject to Interest",
				accessor: "FormattedCashAdvanceDebt"
			 },
			 {
				Header: "Cash Advance Interest",
				accessor: "CashAdvanceInterestAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			 },
			 {
				Header: "Cash Advance Interest Rate",
				accessor: "CashAdvanceInterestRate"
			 }
		];

		this.state = {
			model: this.initialModel,
			isLoading: false,
			checkedAccounts: [],
			ClientTypeParameter: {},
			panelTransactionIsActive: false,
			list: []
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Daily Interest Pool Monitoring");
		}
	}

	Search = async () => {
		if (!this.Validate()) return;
		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.model };
		var result = await ExecuteApiPost(
			"/lmsapi/v1.0/Statement/GetStatementDailySalesAndInterestData",
			model,
			null,
			null,
			null
		);


		if (result != null) {
			this.setState({ list: result || [] });
		}
	};

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
	};

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];
		if (model.UniqueClientId == undefined || model.UniqueClientId == null || model.UniqueClientId <= 0) {
			errors.push("Client must be select!");
		}
		if (model.ApplicantId == undefined || model.ApplicantId == null || model.ApplicantId <= 0) {
			errors.push("Applicant must be select!");
		}
		if (model.StatementId == undefined || model.StatementId == null || model.StatementId <= 0) {
			errors.push("Statement must be select!");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	};

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	};

	handleChange = (name, newValue, data) => {
		const model = {
			...this.state.model,
		};
		model[name] = newValue;
		this.setState({ model });
	};
	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: [],
		});
	};
	render() {
		const { model, ReadOnly, list, panelTransactionIsActive, isBackOffice } = this.state;
		const { Disabled } = this.props;
		var IsClient = ClientHelper.IsClient();

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search,
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.HandleClear,
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												All
												Required
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ApplicantId"
												LabelText="Applicant"
												Method="POST"
												Url={
													model.UniqueClientId
														? "/losapi/v1.0/Applicant/GetAll"
														: ""
												}
												Parameter={{ UniqueClientId: model.UniqueClientId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullName"
												Value={model.ApplicantId}
												ValueChanged={this.handleChange}
												Required />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.ApplicantId}
												Name="StatementId"
												LabelText="Statement"
												Method="POST"
												Url={
													model.ApplicantId
														? "/lmsapi/v1.0/Statement/GetStatementNameByApplicant"
														: ""
												}
												Parameter={{ ApplicantId: model.ApplicantId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="StatementName"
												Value={model.StatementId}
												ValueChanged={this.handleChange}
												Required />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive
									Title="Daily Interest Pool Monitoring">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list}
														Columns={this.columns}
														ShowPagination={true}
														PageSize={100}
														IsSorted={false}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

DailyInterestPoolMonitoring.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
};

export default DailyInterestPoolMonitoring;
