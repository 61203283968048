import withStyles from "@material-ui/core/styles/withStyles";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
// @ts-ignore
import { Chart, Doughnut as DoughnutChart, Pie as PieChart } from "react-chartjs-2";

const styles = ({
	circleBoxGrid: {
		width: "100%",
		textAlign: "center",
		marginRight: 0
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class FundingSummaryChart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			}
		};

		this.pieChartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			}
		};

		this.getGradientList = this.getGradientList.bind(this);
	}

	componentDidMount() {
		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);
			}
		});
	}

	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			// @ts-ignore
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return;
	}

	render() {
		const { classes, unFundedApprovedChartData, approvedFundedOneMonthChartData, approvedFundedTwoMonthsChartData, pieChartData } = this.props;

		var unFundedGradientList = this.getGradientList(unFundedApprovedChartData);
		var unFundedBackground = unFundedApprovedChartData;
		if (unFundedGradientList != undefined) {
			unFundedBackground.datasets[0].backgroundColor = unFundedGradientList;
			unFundedBackground.datasets[0].hoverBackgroundColor = unFundedGradientList;
		}

		var fundedOneMonthGradientList = this.getGradientList(approvedFundedOneMonthChartData);
		var fundedOneMonthBackground = approvedFundedOneMonthChartData;
		if (fundedOneMonthGradientList != undefined) {
			fundedOneMonthBackground.datasets[0].backgroundColor = fundedOneMonthGradientList;
			fundedOneMonthBackground.datasets[0].hoverBackgroundColor = fundedOneMonthGradientList;
		}

		var fundedTwoMonthsGradientList = this.getGradientList(approvedFundedTwoMonthsChartData);
		var fundedTwoMonthsBackground = approvedFundedTwoMonthsChartData;
		if (fundedTwoMonthsGradientList != undefined) {
			fundedTwoMonthsBackground.datasets[0].backgroundColor = fundedTwoMonthsGradientList;
			fundedTwoMonthsBackground.datasets[0].hoverBackgroundColor = fundedTwoMonthsGradientList;
		}

		return (
			<Card style={{ height: "90%" }}>
				<CardBody>
					<CardHeader color="warning" icon>
						<CardIcon color="warning">
							<p style={{ color: "white" }}>
								<b>Approved Loans</b>
							</p>
						</CardIcon>
					</CardHeader>
					<GridContainer>
						<GridItem xs={3} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart
									// @ts-ignore
									id='canvas'
									data={unFundedBackground} width={180} height={170} options={this.chartOptions} />
								<h4>Un-Funded Approved Loans</h4>
							</div>
						</GridItem>
						<GridItem xs={3} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={fundedOneMonthBackground} width={180} height={170} options={this.chartOptions} />
								<h4>Approved Funded Loans</h4>
							</div>
						</GridItem>
						<GridItem xs={3} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={fundedTwoMonthsBackground} width={180} height={170} options={this.chartOptions} />
								<h4>Approved Funded Loans</h4>
							</div>
						</GridItem>
						<GridItem xs={3} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<PieChart data={pieChartData} width={180} height={170} options={this.pieChartOptions} />
								<h4>Funding Status View</h4>
							</div>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

FundingSummaryChart.propTypes = {
	classes: PropTypes.object,
	columns: PropTypes.array,
	unFundedApprovedChartData: PropTypes.object,
	approvedFundedOneMonthChartData: PropTypes.object,
	approvedFundedTwoMonthsChartData: PropTypes.object,
	pieChartData: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

// @ts-ignore
export default withStyles(styles)(FundingSummaryChart);