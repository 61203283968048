import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {  GenericDateInput, GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, GridColumnType, MenuCodes } from "views/Constants/Constant.js";

class OverlimitCardList extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			ValidFrom: DateHelper.GetLocalDateAsUtc(),
			ValidTo: DateHelper.GetLocalDateAsUtc(),
			IsUniqueDataSearch: null,
		};

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.Date
			},
			{
				Header: "Transaction Time",
				accessor: "TransactionTime",
			},
			{
				Header: "Transcation Source",
				accessor: "TranscationSource",
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Transaction Identifier",
				accessor: "TransactionIdentifier"
			},
			{
				Header: "Mti",
				accessor: "Mti"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			}, {
				Header: "Customer Name",
				accessor: "CustomerName"
			}, {
				Header: "Customer Number",
				accessor: "CustomerNumber"
			}, {
				Header: "F43 Name",
				accessor: "F43Name"
			}, {
				Header: "Authorization Amount",
				accessor: "AuthorizationAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}, {
				Header: "Settlement Amount",
				accessor: "SettlementAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}, {
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
		];

		this.state = {
			list: [],
			vModel: {},
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
		};
	}

	componentDidMount() {

		if (this.props.MenuCode == MenuCodes.CallCenter_CardTransaction) {
			this.props.setAppLeftTitle("Potential Chargeback");
			this.props.setAppCenterTitle("CALL CENTER");
		} else {
			this.props.setAppLeftTitle("Potential Chargeback");
			this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}
	}


	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		}
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};

	HandleSearch = () => {


		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		var request = {
			Criteria: 0,
			FromDate: model.ValidFrom,
			ToDate: model.ValidTo,
		}
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/Wallet/GetOverLimitCardList",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item
				});
			}
		);
	};


	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, vModel } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericDateInput
													Name="ValidFrom"
													LabelText="From Date"
													LabelMd={4}
													IsFuture={false}
													MaxDate={DateHelper.GetLocalDateAsUtc()}
													Value={model.ValidFrom == null ? "" : DateHelper.ToDateInputValue(model.ValidFrom) || undefined}
													ValueChanged={this.handleChange}
													Utc
													Required={!model.IsUniqueDataSearch}
													IsInvalid={vModel.ValidFrom}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ValidTo"
													LabelText="To Date"
													LabelMd={4}
													IsFuture={false}
													MinDate={model.ValidFrom}
													Value={model.ValidTo == null ? "" : DateHelper.ToDateInputValue(model.ValidTo) || ""}
													ValueChanged={this.handleChange}
													Utc
													Required={!model.IsUniqueDataSearch}
													IsInvalid={vModel.ValidTo}
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid}
													ShowPagination={true}
													PageSize={100}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

OverlimitCardList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(OverlimitCardList, {});
