import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import {VerificationIcon, DeleteIcon, EditIcon, SubmitOrSendIcon, SuspiciousIcon, TraceIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import RouteHelper from "core/RouteHelper";
import TransactionTraceHelper from "core/TransactionTraceHelper";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ActionCodes, ApprovalStatus, BankTransactionStatus, Direction, GridColumnType, MenuCodes, ProgramCodes } from "views/Constants/Constant.js";

class Transactions extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.clientRowId = ClientHelper.GetClientRowId();
		this.clientId = ClientHelper.GetClientId();
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.isSalesPerson = ClientHelper.IsSalesPerson();

		this.initialModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientRowId,
			MainSearchItemId: -1,
			TransactinDateFrom: DateHelper.GetDate(),
			TransactinDateTo: DateHelper.GetDate()
		};

		this.state = {
			model: this.initialModel,
			isLoading: false,
			mainSearchItem: this.props.MainSearchItem,
			selected: null,
			LimitToken: false,
			suspiciousDialog: false,
			gridButtonRightDetail: []
		};

		this.trxTable = React.createRef();

		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.sortParamDesc = { Member: "ParameterDesc" };
		this.trxSorted = [{ "id": "TransactionDate", "desc": true }];
		this.traceHelper = new TransactionTraceHelper(this);




		
		this.renderItemStoreName = {};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle, MenuCode } = this.props;
		const { model } = this.state;

		if (MenuCode == MenuCodes.Backoffice_SalesPersonEftTransactionSearch) {
			if (setAppCenterTitle) setAppCenterTitle("SALES PERSON");
			if (setAppLeftTitle) setAppLeftTitle("Transaction Search");
		} else if (MenuCode == MenuCodes.CallCenterEFTTransactions) {
			if (setAppCenterTitle) setAppCenterTitle("CALL CENTER");
			if (setAppLeftTitle) setAppLeftTitle("EFT Transaction Search");
			this.GetGridButtonsRight();
		} else {
			if (setAppCenterTitle) setAppCenterTitle("EFT SYSTEM");
			if (setAppLeftTitle) setAppLeftTitle("EFT Transactions");
		}

		var data = RouteHelper.ReadAndClear("EFTTransactionSearch_Date");

		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				parameterCode: "BankTransactionStatus",
				parameterValue: "AA"
			},
			responseData => {
				if (responseData.Item != null) {
					if (responseData.Item != null && responseData.Item.length > 0) {
						model.AwaitingApproval = responseData.Item[0].Id;
					}
				}
			}
		);

		if (data) {
			model.TransactinDateFrom = data.FromDate;
			model.TransactinDateTo = data.ToDate;
			model.IsSettled = data.IsSettled;
			this.setState({ model });
			this.HandleGetList();
		}

		this.RefreshToolBar();
		this.setState({ loadingCompleted: true });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel });
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "MainSearchItemId" && data)
			this.setState({ mainSearchItem: data.ParameterValue });
		if (name == "FinancialInstitutionId") {
			model.FinancialInstitutionBranchId = null;
		}
		if (name == "UniqueClientId" && value == null) {
			model[name] = 0;
		}
		this.setState({ model });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	HandleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "EFT Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/BankBmoEFT/SearchEFTTransactionsExcelDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	HandleSend = model => {
		this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?", () => {

			var jsonData = JSON.stringify(model.Id);

			var requestData = {
				MenuCode: MenuCodes.Eft_SubmitApprove,
				ActionCode: ActionCodes.Submit,
				JsonData: jsonData,
				RowId: model.Id
			};

			this.setState({ alert: "", isLoading: true });
			Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
				requestData,
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Request successfully sent for approval");
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", "An error occurred while sending approve request " + errorMessage);
				}
			);
		});
	}

	HandleApproveOrDeny = (TransactionId,isApproved) => {

		if(isApproved) {
			this.ShowConfirmMessage("question", "Approval Confirmation", "Are you sure you want to approve this transaction?", () => {
				var requestData = {
					transactionId: TransactionId,
					isApproved: isApproved,
				};
	
				this.setState({ alert: "", isLoading: true });
				Proxy.POST("/bankapi/v1.0/BankBmoEFT/ClientEFTMakerCheckerApproveOrDeny",
					requestData,
					responseData => {
						this.setState({ isLoading: false });

						this.HandleGetList();

						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", "Error", responseData.ErrorDescription);
							return;
						}
						this.ShowMessage("success", "Success", "Transaction successfully approved");
					},
					errorMessage => {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", "An error occurred while sending approve request " + errorMessage);
					}
				);
			});
		} else {
			this.ShowConfirmMessage("question", "Rejection Confirmation", "Are you sure you want to reject this transaction?", () => {
				var requestData = {
					transactionId: TransactionId,
					isApproved: isApproved,
				};
	
				this.setState({ alert: "", isLoading: true });
				Proxy.POST("/bankapi/v1.0/BankBmoEFT/ClientEFTMakerCheckerApproveOrDeny",
					requestData,
					responseData => {
						this.setState({ isLoading: false });
						this.HandleGetList();
	
						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", "Error", responseData.ErrorDescription);
							return;
						}
						this.ShowMessage("success", "Success", "Transaction successfully rejected");
					},
					errorMessage => {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", "An error occurred while sending reject request " + errorMessage);
					}
				);
			});
		}
		
	}

	HandleTrace = model  => {

		if(model.Direction.ParameterValue == Direction.Outgoing){
			this.traceHelper.InsertTransactionTraceWithAlert(model.Id);
		}
		else{
		this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?", () => {

			var jsonData = JSON.stringify(model.Id);

			var requestData = {
				MenuCode: MenuCodes.Eft_TraceApprove,
				ActionCode: ActionCodes.Submit,
				JsonData: jsonData,
				RowId: model.Id
			};

			this.setState({ alert: "", isLoading: true });
			Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
				requestData,
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Request successfully sent for approval");
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", "An error occurred while sending approve request " + errorMessage);
				}
			);
		});
		}		
	}

	HandleSuspicious = model => {
		this.setState({ sdTransactionId: model.Id, suspiciousDialog: true });
	}

	HandleSuspiciousConfirm = () => {
		const { sdCancelMemo, sdCancelReasonId, sdTransactionId } = this.state;

		this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?", () => {

			var data = {
				TransactionId: sdTransactionId,
				CancelReasonId: sdCancelReasonId,
				CancelMemo: sdCancelMemo
			};

			var jsonData = JSON.stringify(data);

			var requestData = {
				MenuCode: MenuCodes.Eft_SuspiciousApprove,
				ActionCode: ActionCodes.Submit,
				JsonData: jsonData,
				RowId: data.TransactionId
			};

			this.setState({ alert: "", isLoading: true });
			Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
				requestData,
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.setState({ suspiciousDialog: false, sdCancelReasonId: "", sdCancelMemo: "" });
					this.ShowMessage("success", "Success", "Request successfully sent for approval");
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", "An error occurred while sending approve request " + errorMessage);
				}
			);
		});
	}

	HandleCancel = model => {
		this.ShowConfirmMessage("warning", "Cancel Confirmation", "Are you sure to cancel this transaction ?", () => {
			this.setState({ isLoading: true });
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/CancelEftTransactionById",
				{ TransactionId: model.Id },
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.ShowMessage("success", "Success", "Transaction successfully cancelled");
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", "An error occurred while cancelling transaction " + errorMessage);
				}
			);
		});
	}

	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALEFTSEND", "CALEFTEDIT", "CALEFTCANC", "CALEFTTRAC", "CALEFTSUSP"]);

		this.setState({ gridButtonRightDetail });
	}

	ShowMessage = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} /> });
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.ReferenceNumber && !model.AccountNumber && !model.FileName) {

			if (!model.MainSearchItemId) {
				this.ShowMessage("warning", "Main Search Item not selected", "Select Main Search Item to continue");
				return false;
			}

			if (!model.TransactionAmount && (!model.FinancialInstitutionId || !model.FinancialInstitutionBranchId)) {
				if (!model.UniqueClientId) {
					this.ShowMessage("warning", "Client not selected", "Select Client to continue");
					return false;
				}
			}

			if (!model.TransactinDateFrom) {
				this.ShowMessage("warning", "From Date not selected", "Select From Date to continue");
				return false;
			}

			if (!model.TransactinDateTo) {
				this.ShowMessage("warning", "To Date not selected", "Select To Date to continue");
				return false;
			}

			var diffMonths = model.TransactinDateTo.diff(model.TransactinDateFrom, "days");
			if (diffMonths < 0) {
				this.ShowMessage("warning", "To Date must be later than From Date", "To Date must be later than From Date");
				return false;
			}

			if (diffMonths > 30) {
				this.ShowMessage("warning", "Difference between From Date and To Date cannot be longer than 30 days", "Difference between From Date and To Date cannot be longer than 30 days");
				return false;
			}
		}

		return true;
	}

	ClearSuspiciousDialog = () => {
		this.setState({ suspiciousDialog: false, sdCancelMemo: "", sdCancelReasonId: "", sdTransactionId: null });
	}
	RenderItemStoreName = (d) => {
		this.renderItemStoreName = `${d.StoreName} - (${d.StoreCode})`;
		return this.renderItemStoreName;
	}
	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, LimitToken, suspiciousDialog, sdCancelReasonId, sdCancelMemo, gridButtonRightDetail } = this.state;

		var IsClient = !this.isSalesPerson && this.isClient;
		var IsCallCenter = this.props.MenuCode === MenuCodes.CallCenterEFTTransactions;

		return (
			<div>
				{IsClient && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_EFT} />}
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: IsClient ? this.clientId : undefined }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={Disabled || IsClient} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="TransactionStatusId"
												LabelText="Transaction Status"
												All
												ParameterCode="BankTransactionStatus"
												ParameterValueExcludes={["IC", "AA"]} // Hide Incoming
												Value={model.TransactionStatusId}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="PayeeName"
												LabelText="Customer Name"
												Value={model.PayeeName}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="EFTTypeId"
												LabelText="EFT Type"
												Value={model.EFTTypeId}
												ParameterCode="EFTType"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="FinancialInstitutionId"
												LabelText="Financial Institution"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${String(d.FinancialInstitutionId ?? "").padStart(3, "0")} - ${d.Description}`}
												Value={model.FinancialInstitutionId || -1}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												All={true} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.FinancialInstitutionId}
												Name="FinancialInstitutionBranchId"
												LabelText="Transit"
												Method="POST"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
												Parameter={{ FinancialInstitutionId: model.FinancialInstitutionId }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${String(d.TransitNumber ?? "").padStart(4, "0")} - ${d.Description}`}
												Value={model.FinancialInstitutionBranchId || -1}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												Sorted={{ Member: "TransitNumber" }}
												All={true} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												NoFormatting={true}
												Name="AccountNumber"
												LabelText="Wallet Number"
												Value={model.AccountNumber}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												MaxLength={12} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="StoreId"
												LabelText="Store"
												Method="POST"
												Url="/bankapi/v1.0/CustomerStore/GetByUniqueClientRowId"
												Parameter={{ UniqueClientId: model.UniqueClientId, IsUniqueClientRowId: true }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemStoreName}
												Value={model.StoreId}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												All={true} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionTypeId}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="TransactionAmount"
												LabelText="Amount"
												Value={model.TransactionAmount}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												MaxLength={10}
												Prefix="$" />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="TransactinDateFrom"
												LabelText="From Date"
												Value={model.TransactinDateFrom ? Formatter.FormatDateUtc(model.TransactinDateFrom) : ""}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												MaxDate={model.TransactinDateTo || DateHelper.GetDate()}
												Utc />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="TransactionCodeId"
												LabelText="EFT Transaction Code"
												ParameterCode="EFTTransactionType"
												Value={model.TransactionCodeId}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} Sorted={this.sortParamDesc} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="TransactionSourceId"
												LabelText="Source"
												Value={model.TransactionSourceId}
												ParameterCode="TransactionSource"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="TransactinDateTo"
												LabelText="To Date"
												Value={model.TransactinDateTo ? Formatter.FormatDateUtc(model.TransactinDateTo) : ""}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												MinDate={model.TransactinDateFrom}
												Utc />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="ReferenceNumber"
												LabelText="Reference Number"
												Value={model.ReferenceNumber}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="DirectionId"
												LabelText="Direction"
												Value={model.DirectionId}
												ParameterCode="Direction"
												ParameterValue2="ETRANSFER" // Burası None gelmesin diye bu şekilde
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										{this.isBackOffice ?
											<GridItem xs={4}>
												<ParameterComponent
													Name="EftProcessorId"
													LabelText="Eft Processor"
													Value={model.EftProcessorId}
													ParameterCode="EftProcessor"
													ValueChanged={this.HandleChange}
													Disabled={Disabled} />
											</GridItem> : null}
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<CardHeader>
									<GridContainer spacing={16} justify="space-between" alignItems="center">
										<GridItem>
											<CardHeader>
												<h4><b>Transaction List</b></h4>
											</CardHeader>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									PageSize={10}
									ShowPagination={true}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											Cell: row => (
												<div>
													<GridButton
														tooltip="Send"
														Disabled={!row.original.IsEditable || row.original.TransactionStatus.ParameterValue != BankTransactionStatus.Unapproved ||
															!gridButtonRightDetail.find(x => x.Code == "CALEFTSEND")?.HasAuthority}
														Icon={SubmitOrSendIcon}
														OnClick={() => { this.HandleSend(row.original); }} />

													<GridButton
														tooltip="Edit"
														Disabled={Disabled || !row.original.IsEditable || row.original.Direction.ParameterValue != Direction.Outgoing ||
															!gridButtonRightDetail.find(x => x.Code == "CALEFTEDIT")?.HasAuthority}
														Icon={EditIcon}
														OnClick={() => { RouteHelper.Push(this.props.history, "/CreateEFT", "CreateEFT_Id", row.original.Id); }} />

													<GridButton
														tooltip="Cancel"
														Disabled={Disabled || row.original.Direction == null || row.original.Direction.ParameterValue != Direction.Outgoing || row.original.TransactionStatus.ParameterValue == BankTransactionStatus.Sent || row.original.TransactionStatus.ParameterValue == BankTransactionStatus.Cancelled ||
															!gridButtonRightDetail.find(x => x.Code == "CALEFTCANC")?.HasAuthority}
														Icon={DeleteIcon}
														OnClick={() => { this.HandleCancel(row.original); }} />

													<GridButton
														tooltip="Trace"
														 Disabled={Disabled || row.original.Direction == null //|| row.original.Direction.ParameterValue != Direction.Incoming || !gridButtonRightDetail.find(x => x.Code == "CALEFTTRAC")?.HasAuthority
														}
														Icon={TraceIcon}
														OnClick={() => { this.HandleTrace(row.original); }} />

													<GridButton
														tooltip="Suspicious"
														Disabled={Disabled || (row.original.SuspiciousStatus != null && row.original.SuspiciousStatus != "") ||
															!gridButtonRightDetail.find(x => x.Code == "CALEFTSUSP")?.HasAuthority}
														Icon={SuspiciousIcon}
														OnClick={() => { this.HandleSuspicious(row.original); }} />
												</div>
											),
											sortable: false,
											filterable: false,
											width: 200,
											show: IsCallCenter
										},
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Merchant Clearing Date",
											accessor: "SettlementDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Trace Status",
											accessor: "TraceStatus",
											show: IsCallCenter,
											// Cell: row => {
											// 	switch (row.value) {
											// 		case ApprovalStatus.Waiting:
											// 		case ApprovalStatus.Approved:
											// 		case ApprovalStatus.Completed:
											// 			return "Trace";

											// 		default:
											// 			return "";
											// 	}
											// }
										},
										{
											Header: "Suspicious Status",
											accessor: "SuspiciousStatus",
											show: IsCallCenter,
											Cell: row => {
												switch (row.value) {
													case ApprovalStatus.Waiting:
														return "Waiting Review";
													case ApprovalStatus.Approved:
													case ApprovalStatus.Completed:
														return "Review Result Approved";
													case ApprovalStatus.Rejected:
														return "Review Result Rejected";

													default:
														return "";
												}
											}
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId",
											show: this.isSalesPerson || this.isBackOffice,
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Client Name",
											accessor: "UniqueClientName",
											show: this.isSalesPerson || this.isBackOffice,
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Name",
											accessor: "ClientName",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Financial Institution",
											accessor: "FinancialInstitution.Description",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Financial Institution Branch",
											accessor: "FinancialInstitutionBranch.Description",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Wallet Number",
											accessor: "AccountNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Amount",
											accessor: "TransactionAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Fee Amount",
											accessor: "FeeAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Transaction Status",
											accessor: "TransactionStatus.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Number",
											accessor: "ClientNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transit Number",
											accessor: "TransitNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Eft Processor",
											accessor: "EftProcessor",
											show: this.isBackOffice,
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Settlement Type",
											accessor: "PriorityType.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "EFT Transaction Code",
											accessor: "EFTTransactionType.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "File Id",
											accessor: "FileId",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "File Name",
											accessor: "FileName",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Store",
											accessor: "CustomerStore",
											Cell: row => row.value ?? "-",
											width: 150
										},
										{
											Header: "Transaction Memo",
											accessor: "TransactionMemo",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Return Transaction Type",
											accessor: "ReturnTransactionType",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Original Transaction Date",
											accessor: "OriginalTransactionDate",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Institution Id",
											accessor: "PayeeInstitutionId",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Wallet",
											accessor: "PayeeAccount",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Item Trace Number",
											accessor: "ItemTraceNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Original Transaction Type",
											accessor: "OriginalTransactionType",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Institution Id For Returns",
											accessor: "InstitutionIdForReturns",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Wallet Number For Returns",
											accessor: "AccountNumberForReturns",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Logical Record Count",
											accessor: "LogicalRecordCount",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Payee Drawee Institution Id",
											accessor: "PayeeDraweeInstitutionId",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Originator Id",
											accessor: "OriginatorId",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "File Creation Number",
											accessor: "FileCreationNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Payee Drawee Wallet Number",
											accessor: "PayeeDraweeAccountNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Clearing Amount",
											accessor: "Amount",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Originator Short Name",
											accessor: "OriginatorShortName",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Payee Drawee Name",
											accessor: "PayeeDraweeName",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Originator Long Name",
											accessor: "OriginatorLongName",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Sequence In Segment",
											accessor: "SequenceInSegment",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Group Number",
											accessor: "GroupNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Cross Reference Number",
											accessor: "CrossReferenceNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "File Information",
											accessor: "FileInformation",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Clearing Date",
											accessor: "ClearingDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Process Status",
											accessor: "ProcessStatus",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Match Status",
											accessor: "MatchStatus",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Validation Status",
											accessor: "ValidationStatus",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Validation Description",
											accessor: "ValidationDescription",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Recommit Date",
											accessor: "RecommitDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Reject File Sent Date",
											accessor: "RejectFileSentDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Item Trace No",
											accessor: "ItemTraceNo",
											Cell: row => row.value ?? "-"

										},
										{
											Header: "Stored Transaction Type",
											accessor: "StoredTransactionType",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Return Date",
											accessor: "ReturnDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Invalid Data Element",
											accessor: "InvalidDataElement",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Originator Direct Clearer Settlement Code",
											accessor: "OriginatorDirectClearerSettlementCode",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Originator Sundry Information",
											accessor: "OriginatorSundryInformation",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Originating Direct Clearer User Id",
											accessor: "OriginatingDirectClearerUserId",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Return Code",
											accessor: "ReturnCode",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Return Indicator",
											accessor: "ReturnIndicator",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Source",
											accessor: "TransactionSource.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Client User",
											accessor: "InsertUser",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Reference Number",
											accessor: "ReferenceNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Trace Reject Reason",
											accessor: "TraceRejectReason",
											show: IsCallCenter,
										},
										{
											Header: "Suspicious Reject Reason",
											accessor: "SuspiciousRejectReason",
											show: IsCallCenter,
										}
									]}
									Sorted={this.trxSorted}
									ProgramCode={ProgramCodes.Bank_Programs_EFT}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									ValidateRequest={this.Validate}
									PrepareRequest={() => {
										// Only call center needs suspicious and trace columns datas (For performance)
										const { model } = this.state;
										model.IsCallCenter = this.props.MenuCode == MenuCodes.CallCenterEFTTransactions;
										return model;
									}}
									RequestUrl="/bankapi/v1.0/BankBmoEFT/SearchEFTTransactionsPaged"
									RequestMethod="POST"
								/> 
							</CardBody>
						</Card>
						
						{ !this.isBackOffice &&
						<Card className="no-radius">
							<CardBody>
								<CardHeader>
									<h4><b>EFT Approval List</b></h4>
								</CardHeader>
								<GridItem xs={12}>
									<GridItem>
									<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									PageSize={10}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											Cell: row => (
												<div>
													<GridButton
														tooltip="Approve"
														Icon={VerificationIcon}
														OnClick={() => { this.HandleApproveOrDeny(row.original.Id, true); }} />
													<GridButton
														tooltip="Deny"
														Icon={DeleteIcon}
														OnClick={() => { this.HandleApproveOrDeny(row.original.Id, false); }} />
												</div>
											),
											sortable: false,
											filterable: false,
										},
										{
											Header: "Requested By",
											accessor: "InsertUser",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Transaction Status",
											accessor: "TransactionStatus.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Name",
											accessor: "ClientName",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Financial Institution",
											accessor: "FinancialInstitution.Description",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Financial Institution Branch",
											accessor: "FinancialInstitutionBranch.Description",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Customer Wallet Number",
											accessor: "AccountNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Amount",
											accessor: "TransactionAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Fee Amount",
											accessor: "FeeAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Customer Number",
											accessor: "ClientNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transit Number",
											accessor: "TransitNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Eft Processor",
											accessor: "EftProcessor",
											show: this.isBackOffice,
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Settlement Type",
											accessor: "PriorityType.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "EFT Transaction Code",
											accessor: "EFTTransactionType.ParameterDesc",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Memo",
											accessor: "TransactionMemo",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Cross Reference Number",
											accessor: "CrossReferenceNumber",
											Cell: row => row.value ?? "-"
										},
										{
											Header: "Transaction Reference Number",
											accessor: "ReferenceNumber",
											Cell: row => row.value ?? "-"
										},
									]}
									Sorted={this.trxSorted}
									ProgramCode={ProgramCodes.Bank_Programs_EFT}
									ServerSide
									HideLoadMore
									LoadAtStartup={this.state.loadingCompleted}
									PrepareRequest={() => {


										const { model } = this.state;
										return {
											CacheGuid: null,
											UniqueClientId: model.UniqueClientId,
											MainSearchItemId: -1,
											transactionStatusId: model.AwaitingApproval
										};
									}}
									RequestUrl="/bankapi/v1.0/BankBmoEFT/SearchEFTTransactionsPaged"
									RequestMethod="POST"
								/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
						}
					</GridItem>
				</GridContainer>
				<GenericDialog open={suspiciousDialog} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Suspicious Transaction" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 200 }}>
						<ParameterComponent Name="sdCancelReasonId" LabelText="Suspicious Reason" Value={sdCancelReasonId} ValueChanged={this.ValueChanged} ParameterCode={"TransactionCancelReason"} />
						<GenericTextInput Name="sdCancelMemo" LabelText="Suspicious Memo" Value={sdCancelMemo} ValueChanged={this.ValueChanged} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSuspiciousConfirm}>SAVE</Button>
						<Button size="sm" onClick={this.ClearSuspiciousDialog}>CANCEL</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

Transactions.propTypes = {
	classes: PropTypes.object
};

export default Transactions;