import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { EditIcon } from "core/Icons";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericEmailInput, GenericGrid, GenericLabel, GenericRadioInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton";

class SFTPUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			isLoading: true,
			userLists: [],
			filteredUserLists: [],
			sftpList: [],
			customerList: [],
			openDialog: false,
			openPassword: false,
			openChangePassword: false,
			rowIndex: -1,
			isPasswordDisabled: false,
			maskStatus: false,
			promiseList: []
		};
		this.props.setAppLeftTitle("SFTP User");
		this.props.setAppCenterTitle("USER ADMIN");
	}

	componentDidMount() {

		var promises = [];
		promises.push(this.GetSftpList());
		promises.push(this.GetCustomerList());
		promises.push(this.GetUserList());

		Promise.all(promises).finally(a => {
			this.setState({ filteredUserLists: this.state.userLists, isLoading: false });
		});
	}


	SelectedRowChange = (index) => {
		const sftp = { ...this.state.sftpList[index] };

		sftp.BankCustomer = sftp.UniqueClientId;
		sftp.UserList = sftp.User.Id;

		this.setState({ model: sftp, rowIndex: index, isPasswordDisabled: false });
	}

	GetSftpList = (uid = 0, uniqId = 0) => {
		return Proxy.POST(
			"/coreapi/v1.0/Sftp/GetAllSftpUsers",
			{
				UserId: uid,
				UniqueClientId: uniqId
			},
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({ sftpList: responseData.Item });
				}
				else {
					this.setState({ sftpList: [] });
				}
			},
			this.errorCallback
		);
	}
	GetCustomerList = (uid = 0) => {
		return Proxy.POST(
			"/bankapi/v1.0/BankCustomer/Search",
			{ UniqueClientId: uid, },
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({ customerList: responseData.Item });
				}
				else {
					this.setState({ customerList: [] });
				}
			},
			this.errorCallback
		);
	}

	GetUserList = (uid = 0) => {
		return Proxy.POST(
			"/coreapi/v1.0/User/GetAllByUniqueClientId",
			{ UniqueClientId: uid },
			(responseData) => {

				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}
				if (responseData.Item != null) {
					this.setState({ userLists: responseData.Item });
				} else {
					this.setState({ userLists: [] });
				}
			},
			(error) => {
				console.log("Error!");
				return;
			}
		);
	}
	FilterUserList = (uniqId = 0) => {
		var filteredUserLists = this.state.userLists;

		if (uniqId != 0) {
			filteredUserLists = filteredUserLists.filter((a) => {
				return a.UniqueClientId == uniqId;
			});
		}


		this.setState({ filteredUserLists });
	}
	handleChange = (name, newValue, pureValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		if (name == "PhoneNumber") {
			model["PureNumber"] = pureValue;
		}

		if (name == "BankCustomer") {

			let uid = newValue;
			if (newValue == -1) {
				uid = 0;
			}
			this.FilterUserList(uid);
			this.GetSftpList(0, model.BankCustomer);
		}


		if (name == "UserName") {
			this.state.rowIndex = -1;

		}
		if (name == "UserList") {
			let temp = newValue;
			if (temp == -1) {
				temp = 0;
			}
			model.UserId = temp;
			this.GetSftpList(newValue);

		}

		this.setState({ model });
		if (name == "PhoneCountryCodeId") {
			this.GetCountry(newValue);
		}
	}



	handleClickSendOk = () => {
		this.hideAlert();
		var model = { ...this.state.model };
		var newModel = {};
		newModel.SftpUsername = model.UserName;
		newModel.NotificationAddress = model.NotificationType == 1 ? model.NotificationAddress : "+" + model.CountryCode + model.PureNumber;
		newModel.NotificationType = model.NotificationType;
		Proxy.POST(
			"/coreapi/v1.0/Sftp/SendSftpCredentals",
			newModel,
			this.successSendCallback,
			this.errorCallback
		).finally(() => {
			this.setState({ isLoading: false });
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleSubmit = () => {
		const model = { ...this.state.model };
		const sftpList = { ...this.state.sftpList };
		var messages = [];

		if (!model.UserName) messages.push("UserName is empty !!!");
		if (!model.UserList) messages.push("Please select a user!!!");

		if (model.UserList) {
			for (let x in sftpList) {

				if (model.UserName == sftpList[x].Username) {
					messages.push("'" + sftpList[x].Username + "' is already exist !!! Please change and try again.");
					break;

				}
			}
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else {
			this.showQuestionAlert("Are you sure you want to create new SFTP user for selected user?", "Save");
		}

	}
	handleEdit = () => {
		var messages = [];
		const model = { ...this.state.model };

		if (model.NewPassword2 != model.NewPassword) messages.push("Please enter same password to required fields!!!");

		if (messages.length > 0)
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		else
			this.showQuestionAlert("Are you sure you want to change SFTP user password for selected user?", "Edit");
	}

	handleDelete = () => {
		const rowIndex = this.state.rowIndex;
		if (!rowIndex || rowIndex == -1) {
			this.setState({
				alert: <GenericAlert Title={"Warning"} MessageNode={"Please select a SFTP user from grid."} Type={"warning"} OnConfirm={() => this.hideAlert()} />
			});
		}
		else {
			this.showQuestionAlert("Are you sure you want to delete '" + this.state.sftpList[rowIndex].UserName + "' SFTP user ?", "Delete");
		}
	}

	handleClear = () => {
		this.showQuestionAlert("This will erase the information you entered on the screen. Are you sure you want to do this?", "Clear");
	}

	handleClickSaveOk = () => {
		this.setState({ isLoading: true });
		this.hideAlert();
		var model = { ...this.state.model };
		var newModel = {};
		newModel.SftpUsername = model.UserName;
		newModel.SftpUserId = model.UserList;
		Proxy.POST(
			"/coreapi/v1.0/Sftp/CreateSftpUser",
			newModel,
			this.successSaveCallback,
			this.errorCallback
		).finally(() => {
			this.setState({ isLoading: false });
		});
	}

	handleClickEditOk = () => {
		this.setState({ isLoading: true });
		this.hideAlert();
		var model = { ...this.state.model };
		let params = {};
		params.SftpUsername = model.UserName;
		params.SftpPassword = model.NewPassword;
		Proxy.POST(
			"/coreapi/v1.0/Sftp/SetSftpPassword",
			params,
			this.successEditCallback,
			this.errorCallback
		).finally(() => {
			this.setState({ isLoading: false });
		});
	}

	handleClickDeleteOk = () => {
		this.setState({ isLoading: true });
		this.hideAlert();
		let params = {};
		params.SftpUsername = this.state.model.UserName;
		Proxy.POST(
			"/coreapi/v1.0/Sftp/DeleteSftpUser/",
			params,
			this.successDeleteCallback,
			this.errorCallback
		).finally(() => {
			this.setState({ isLoading: false });
		});
	}


	handleClickClearOk = () => {
		this.hideAlert();
		this.setState({ model: any, rowIndex: null });
	}

	successSaveCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.GetSftpList();
		this.insertSuccessCallback(responseData);
		this.showSuccessMessage("Saved succesfully !!!");
	}


	successEditCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}

		this.GetSftpList();
		this.showSuccessMessage("Edited succesfully !!!");
	}

	successDeleteCallback = () => {
		this.handleClickClearOk();
		this.GetSftpList();
		this.showSuccessMessage("SFTP User deleted successfully !!!");
	}
	successSendCallback = () => {
		this.showSuccessMessage("Send Successfully !!!");
	}
	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	insertSuccessCallback = (responseData) => {
		var model = { ...this.state.model };
		model.newSftpPassword = responseData.Item.SftpPassword;
		if (responseData.Item != null)
			this.setState({ model, openPassword: true, });
	}


	showQuestionAlert = (message, title) => {
		if (title == "Save") {
			this.setState({
				isLoading: false,
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickSaveOk()} />
			});
		}
		if (title == "Edit") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickEditOk()} />
			});
		}
		if (title == "Delete") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickDeleteOk()} />
			});
		}

		if (title == "Clear") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickClearOk()} />
			});
		}
		if (title == "Send") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickSendOk()} />
			});
		}
	}

	showSuccessMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Success"} MessageNode={message} Type={"success"} OnConfirm={() => this.hideAlert()} />
		});
	}

	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} MessageNode={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert = () => {
		this.setState({ alert: null });
	}
	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	handleClose = () => {
		this.setState({ openDialog: false, openPassword: false, openChangePassword: false });
	}
	handleSend = () => {

		if (!this.state.rowIndex || this.state.rowIndex <= -1) {
			this.setState({
				alert: <GenericAlert Title={"Warning"} MessageNode={"Please select a SFTP user from grid."} Type={"warning"} OnConfirm={() => this.hideAlert()} />
			});
		}
		else {
			this.setState({ openDialog: true });
		}


	}
	handleBooleanChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		if (name == "NotificationType") {
			model.NotificationAddress = "";
		}
		this.setState({ model });

	}

	handleSendClick = () => {
		const model = { ...this.state.model };
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const maskStatus = this.state.maskStatus;

		var messages = [];
		if (model.NotificationType == undefined) {
			model.NotificationType = 1;
			this.setState({ model });
		}

		if (model.NotificationType == 2) {
			if (!maskStatus && (!model.PhoneNumber || model.PhoneNumber.length > 0)) messages.push("Please fill the Phone Number !!!");
			if (!model.PhoneCountryCodeId) messages.push("Please fill the Phone Country !!!");
		}
		if (model.NotificationType == 1 && !model.NotificationAddress) {
			messages.push("Notification Address is empty !!!");
		}

		if ((model.NotificationType == 1) && !emailRex.test(model.NotificationAddress)) messages.push("Please enter a valid Email address!!!");
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else
			return this.state.openDialog && this.showQuestionAlert("Are you sure you want to send this credentials via " + (model.NotificationType == 1 ? "Email" : "Sms") + " ?", "Send");
	}
	OpenEditModal = (item) => {
		const model = { ...this.state.model };
		model.UserName = item.UserName;

		this.setState({ model, openChangePassword: true });
	}

	GetCountry = (id) => {
		Proxy.POST(
			"/coreapi/v1.0/Country/GetAll",
			{},
			responseData => {
				const model = { ...this.state.model };
				if (responseData.Item != null && responseData.Item.length > 0) {
					model.CountryCode = responseData.Item.find((a) => {
						return a.Id == id;
					}).Code;
					this.setState({ model });
				}
			},
			this.errorCallback
		);
	}
	render() {
		const { classes, Disabled } = this.props;
		const { model, isLoading, alert, filteredUserLists } = this.state;

		var data = !this.state.sftpList ? [] : this.state.sftpList.map(item => {
			return {
				Actions: (
					<div>
						<ToolbarButton
							justIcon simple
							size="sm"
							tooltip="Change Password"
							onClick={() => this.OpenEditModal(item)}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				FirstName: item.User.FirstName ?? "",
				LastName: item.User.LastName ?? "",
				UserEmail: item.User.UserEmail ?? "",
				UserName: item.UserName ?? "",
				ServerFullPath: item.ServerFullPath ?? ""
			};
		});

		return (
			<div>


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.handleDelete, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Send", OnClick: this.handleSend, Disabled: Disabled }
				]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<GridContainer>
					<GenericDialog open={this.state.openDialog} onBackdropClick={this.handleClose} fullWidth={true} >
						<DialogTitle className={classes.bgPrimary}>
							<GenericLabel Text="SEND SFTP CREDENTIALS" FontSize="20px" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<GridContainer justify="space-between">
								<GridItem xs={12} >
									{model.NotificationType == undefined || model.NotificationType == true
										?
										<GenericEmailInput
											inputProps={{ maxLength: 100 }}
											Name="NotificationAddress"
											LabelText="Notification Address"
											Disabled={model.isAccount}
											Value={this.state.model.NotificationAddress}
											ValueChanged={this.handleChange} />
										:
										<GridContainer>
											<GridItem xs={7}>
												<GenericSelectInput
													Name="PhoneCountryCodeId"
													LabelText="Phone Number"
													TitleLabel="Country Code"
													LabelMd={4}
													LabelStyle={{ width: "72%", marginLeft: "-2px", marginBottom: "2px" }}
													SelectStyle={{ width: "28%" }}
													Method="POST"
													Url="/coreapi/v1.0/Country/GetAll"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember={"Code"}
													Value={model.PhoneCountryCodeId ?? ""}
													ValueChanged={this.handleChange}
													Sorted={{ Member: "OrderIndex" }}
													RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
												/>
											</GridItem>
											<GridItem xs={5} style={{ paddingRight: 2 + "px !important" }}>
												<GenericTextInput
													Format="(###) ### ####"
													Name="PhoneNumber"
													LabelMd={0}
													Value={model.PhoneNumber ?? ""}
													ValueChanged={this.handleChange}
													IsCompleted={(bool) => {
														this.setState({ maskStatus: bool });
													}} />
											</GridItem>
										</GridContainer>
									}
									<br /><br />
									<GridItem>
										<GridContainer>
											<GridItem xs={12} >
												<GenericRadioInput
													Name="NotificationType"
													LabelText="Select Notification Type"
													IsStatic={true}
													StaticData={[
														{ Value: 1, Text: "Email" },
														{ Value: 2, Text: "Sms" }
													]}
													KeyValueMember="Value"
													TextValueMember="Text"
													Value={model.NotificationType || 1}
													ValueChanged={this.handleBooleanChange} />
												<Button justify="flex-end" size="sm" onClick={this.handleSendClick} >SEND</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridItem>
							</GridContainer>
						</DialogContent>
						<GenericDialogActions>
							<Button onClick={this.handleClose}>Close</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GenericDialog open={this.state.openPassword} onBackdropClick={this.handleClose} fullWidth={true} >
						<DialogTitle className={classes.bgPrimary}>
							<GenericLabel Text="SFTP CREDENTIALS PASSWORD" FontSize="18px" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<GridContainer>
								<GridItem xs={12}>
									<GenericLabel Text="Please copy generated sftp password from below!" TextColor="black" FontSize="12px" />
									<GenericTextInput
										inputProps={{ maxLength: 100 }}
										Name="newSftpPassword"
										LabelText="New Sftp Password"
										Value={this.state.model.newSftpPassword} />
								</GridItem>
							</GridContainer>
						</DialogContent>
						<GenericDialogActions>
							<Button onClick={this.handleClose}>Close
							</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GenericDialog open={this.state.openChangePassword} onBackdropClick={this.handleClose} fullWidth={true} >
						<DialogTitle className={classes.bgPrimary}>
							<GenericLabel Text="SFTP CHANGE PASSWORD" FontSize="18px" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<GridContainer>
								<GridItem xs={12}>
									<GenericLabel Text="Please set new password from below and click submit!" TextColor="black" FontSize="14px" />
									<br />
									<GenericTextInput
										inputProps={{ maxLength: 100 }}
										Name="NewPassword"
										LabelText="New Password"
										Value={this.state.model.NewPassword}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										inputProps={{ maxLength: 100 }}
										Name="NewPassword2"
										LabelText="Again New Password"
										Value={this.state.model.NewPassword2}
										ValueChanged={this.handleChange} />
									<GridContainer>
										<GridItem xs={6} />
										<GridItem xs={6}>
											<GridContainer justify="flex-end">
												<GridItem>
													<Button size="sm" onClick={this.handleEdit} >Submit</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</DialogContent>
						<GenericDialogActions>
							<Button onClick={this.handleClose}>Close
							</Button>
						</GenericDialogActions>

					</GenericDialog>
					<GridItem xs={12}>
						<Card className={classes.zerMarPad}>
							<CardBody>
								<GridContainer style={{}}>
									<GridItem xs={4}>
										<h5><b>{this.state.columnTitle1}</b></h5>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													All
													Name="BankCustomer"
													IsStatic={true}
													StaticData={this.state.customerList}
													LabelText="Client Name"
													Value={model.BankCustomer}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember={"Code"}
													RenderItem={d => d.Name} />

												<GenericSelectInput
													All
													IsStatic={true}
													StaticData={filteredUserLists}
													Name="UserList"
													Value={model.UserList}
													LabelText="Users"
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="UserName"
													Sorted={{ Member: "UserName" }} />

											</GridItem>
										</GridContainer>
										<GenericTextInput
											Name="UserName"
											LabelText="SFTP Username"
											ValueChanged={this.handleChange}
											Value={this.state.model.UserName} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<br />
					</GridItem>
				</GridContainer >
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"SFTP List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={data}
									Columns={[
										// {
										// 	Header: "Actions",
										// 	accessor: "Actions",
										// 	sortable: false,
										// 	filterable: false,
										// 	width: 50
										// },
										{
											Header: "First Name",
											accessor: "FirstName",
											Cell: row => (
												<div>{row.value == null ? "" : row.value}</div>
											)
										},
										{
											Header: "Last Name",
											accessor: "LastName",
											Cell: row => (
												<div>{row.value == null ? "" : row.value}</div>
											)
										},
										{
											Header: "User E-Mail",
											accessor: "UserEmail"
										},
										{
											Header: "SFTP User Name",
											accessor: "UserName"
										},
										{
											Header: "Server Full Path",
											accessor: "ServerFullPath"
										}
										//todo add 
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >
		);
	}
}

SFTPUser.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	CustomerSaved: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(SFTPUser, {});	