import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { FeeProfileType, PriorityRegularType, TxnEffect } from "views/Constants/Constant.js";

const emptyObject = {};
class FeeProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			ProfileModel: {},
			FixedModelList: [],
			FixedPropList: [],
			PeriodicModelList: [],
			PeriodicPropList: [],
			ReadOnlyData: {},
			isLoading: false,
			FeeTypes: [],
			WeightedModelList: [],
			TransactionFeeLabelTitleParameter: "Transaction Fee",
			TransactionFeeParameterValue5: "TXN"
		};
		this.ClearData = this.ClearData.bind(this);
		this.DeleteRecord = this.DeleteRecord.bind(this);
		this.UpdateRecord = this.UpdateRecord.bind(this);
		this.FetchData = this.FetchData.bind(this);
		this.errorDefinitionCallback = this.errorDefinitionCallback.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.Validate = this.Validate.bind(this);
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Fee Profile");
		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("FEE PROFILES");

		var routeObject = RouteHelper.ReadAndClear("FeeProfileDefinition");	
		this.monitorMode = routeObject?.MonitorMode;
		
		var feeId = routeObject?.Id;
		if (feeId) {
			this.setState({ isLoading: true });
			Proxy.GET(
				"/bankapi/v1.0/FeeProfile/GetById/Id?Id=" + feeId,
				this.successGetByIdCallback,
				this.errorDefinitionCallback
			);
		}
	}

	successGetByIdCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null) {
			this.setState({
				FixedModelList: responseData.Item.Fixed || [],
				PeriodicModelList: responseData.Item.Periodic || [],
				WeightedModelList: responseData.Item.Scale || [],
			});
			var temp = responseData.Item.Profile;
			temp.FeeProfileTypeStr = temp.FeeProfileType.ParameterValue;
			temp.IsSupportWeighted = temp.Program.IsSupportWeighted;
			this.setState({ ProfileModel: temp });

			this.setState({ isLoading: false });
			this.FetchPropFromParameter(responseData.Item.Profile.ProgramId);
		}
	}

	FetchPropFromParameter = (programId) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/FeeProfile/GetFeeTypesByProgramId",
			programId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ FixedPropList: responseData.Item || [] });
					const ca = [...new Set(responseData.Item.map(x => x.ParameterValue4))];
					this.setState({ isLoading: false, FeeTypes: ca });
				}

			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });
			}
		);
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "FeeType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: "PERIODIC"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ PeriodicPropList: responseData.Item || [] });
				}

				this.setState({ isLoading: false });
			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });
			}
		);

	}

	Validate() {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const dataFixed = this.state.FixedModelList;
		const propsFixed = this.state.FixedPropList;
		const dataPeriodic = this.state.PeriodicModelList;
		const propsPeriodic = this.state.PeriodicPropList;
		const profile = { ...this.state.ProfileModel };

		const WeightedModelList = this.state.WeightedModelList;
		if (profile.OrganizationUniqueId == null || profile.OrganizationUniqueId == "") {
			vModel.OrganizationUniqueId = true;
			ErrorList.push("OrganizationUniqueId must be filled");
		} else { vModel.OrganizationUniqueId = false; }
		if (profile.ProgramId == null || profile.ProgramId == "") {
			vModel.ProgramId = true;
			ErrorList.push("Program must be filled");
		} else { vModel.ProgramId = false; }
		if (profile.ProfileName == null || profile.ProfileName == "") {
			vModel.ProfileName = true;
			ErrorList.push("Profile name must be filled");
		} else { vModel.ProfileName = false; }
		if (profile.ValidFrom == null || profile.ValidFrom == "") {
			vModel.ValidFrom = true;
			ErrorList.push("Valid from data must be filled");
		} else { vModel.ValidFrom = false; }
		if (profile.ValidTo == null || profile.ValidTo == "") {
			vModel.ValidTo = true;
			ErrorList.push("Valid to date must be filled");
		} else { vModel.ValidTo = false; }

		if (profile.FeeProfileTypeId == null || profile.FeeProfileTypeId == "") {
			vModel.FeeProfileTypeId = true;
			ErrorList.push("Fee Profile Type must be defined !");
		} else { vModel.FeeProfileTypeId = false; }
		this.setState({ vModel });
		// if (profile.FeeProfileTypeStr == FeeProfileType.Fixed) {
		// 	propsFixed.forEach(element => {
		// 		const temp = dataFixed.find(x => x.FeeType != null && x.FeeType.ParameterValue == element.ParameterValue);
		// 		if (element.ParameterValue6 != "O") {
		// 			if (temp == null || temp.FeeAmount == null) {
		// 				ErrorList.push("Fee Amount not defined for " + element.ParameterValue3);
		// 			}
		// 			if (temp == null || temp.Cost == null) {
		// 				ErrorList.push("Fee Cost not defined for " + element.ParameterValue3);
		// 			}
		// 		}
		// 	});
		// } else {
		// 	// other fixed fee types (each profile type )
		// 	propsFixed.filter(x => x.ParameterValue5 != this.state.TransactionFeeParameterValue5).forEach(element => {
		// 		const temp = dataFixed.find(x => x.FeeType != null && x.FeeType.ParameterValue == element.ParameterValue);
		// 		if (element.ParameterValue6 != "O") {
		// 			if (temp == null || temp.FeeAmount == null) {
		// 				ErrorList.push("Fee Amount not defined for " + element.ParameterValue3);
		// 			}
		// 			if (temp == null || temp.Cost == null) {
		// 				ErrorList.push("Fee Cost not defined for " + element.ParameterValue3);
		// 			}
		// 		}
		// 	});
		// }
		if (profile.FeeProfileTypeStr == FeeProfileType.CombineUnWeighted || profile.FeeProfileTypeStr == FeeProfileType.CombineWeighted) {
			WeightedModelList.forEach(element => {
				if (element == null || element.LevelFrom == null || element.LevelFrom == "") {
					ErrorList.push("Level from fields must be filled");
				}
				if (element == null || element.LevelTo == null || element.LevelTo == "") {
					ErrorList.push("Level to fields must be filled");
				}
				if (element == null || element.FeeAmount == null || element.FeeAmount == "") {
					ErrorList.push("Amount fields must be filled");
				}
			});

			if (WeightedModelList.length < 5) {
				ErrorList.push("All combine levels must be filled");
			}
		}
		if (profile.FeeProfileTypeStr == FeeProfileType.Weighed || profile.FeeProfileTypeStr == FeeProfileType.UnWeighed) {
			WeightedModelList.forEach(element => {
				if (element == null || element.LevelFrom == null || element.LevelFrom == "") {
					ErrorList.push("Level from fields must be filled");
				}
				if (element == null || element.LevelTo == null || element.LevelTo == "") {
					ErrorList.push("Level to fields must be filled");
				}
				if (element == null || element.FeeAmount == null || element.FeeAmount == "") {
					ErrorList.push("Amount fields must be filled");
				}
			});

			if (WeightedModelList.length < 10) {
				ErrorList.push("All weighted levels must be filled");
			}
		}

		// every profile type has periodic fee 
		// propsPeriodic.forEach(element => {
		// 	const temp = dataPeriodic.find(x => x.FeeType != null && x.FeeType.ParameterValue == element.ParameterValue);
		// 	if (element.ParameterValue6 != "O") {
		// 		if (temp == null || temp.FeeAmount == null) {
		// 			ErrorList.push("Fee Amount not defined for " + element.ParameterValue3);
		// 		}
		// 		if (temp == null || temp.Cost == null) {
		// 			ErrorList.push("Fee Cost not defined for " + element.ParameterValue3);
		// 		}
		// 	}
		// });

		// check interval
		var interval = moment.duration(DateHelper.ToMoment(profile.ValidTo).diff(DateHelper.GetDate())).asDays();
		if (interval < 30) {
			ErrorList.push("Limit profile must be valid In next 30 days ! (Check Valid Date)");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
 
		return true;
	}

	OperationCheck = (workflowId, after, accessToken) => {
		if (this.Validate())
			this.UpdateRecord(workflowId, after, accessToken);
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	handleListItemChangeFixed = (name, newValue, code) => {
		const array = this.state.FixedModelList;
		const item = array.find(x => x.FeeType != null && x.FeeType.ParameterValue == code);
		if (item == null) {
			const feetype = { ParameterValue: code };
			const temp = { Id: 0, LimitProfileId: this.state.ProfileModel.Id, FeeType: feetype };
			temp[name] = newValue;
			array.push(temp);
		} else {
			item[name] = newValue;
		}

		this.setState({ FixedModelList: array });
	}

	handleListItemChangePeriodic = (name, newValue, code) => {
		const array = this.state.PeriodicModelList;
		const item = array.find(x => x.FeeType != null && x.FeeType.ParameterValue == code);
		if (item == null) {
			const feetype = { ParameterValue: code };
			const temp = { Id: 0, LimitProfileId: this.state.ProfileModel.Id, FeeType: feetype };
			temp[name] = newValue;
			array.push(temp);
		} else {
			item[name] = newValue;
		}

		this.setState({ PeriodicModelList: array }, () => console.log("PeriodicModelList ", this.state.PeriodicModelList));
	}
	handleListItemChangeScaled = (name, newValue, level, priority) => {
		const array = [...this.state.WeightedModelList];
		const item = array.find(x => x.LevelId == level && x.PriorityType != null && x.PriorityType.ParameterValue == priority);
		if (item == null) {
			const data = {
				LevelId: level,
				TxnEffect: { ParameterValue: PriorityRegularType.None },
				PriorityType: { ParameterValue: priority },
				Id: 0
			};
			data[name] = newValue;
			array.push(data);
		} else {
			item[name] = newValue;
		}
		this.setState({ WeightedModelList: array });
	}
	handleListItemChangeCombined = (name, newValue, level) => {
		const array = [...this.state.WeightedModelList];
		const item = array.find(x => x.LevelId == level && x.IsCombineScale == true);
		if (item == null) {
			const data = {
				TxnEffect: { ParameterValue: TxnEffect.None },
				PriorityType: { ParameterValue: PriorityRegularType.None },
				LevelId: level,
				IsCombineScale: true,
				Id: 0
			};
			data[name] = newValue;
			array.push(data);
		} else {
			item[name] = newValue;
		}
		this.setState({ WeightedModelList: array });
	}
	DeleteRecord(workflowId, after, accessToken) {
		this.setState({ isLoading: true });
		const header = { ...this.state.ProfileModel };
		if (header != null && header.Id != 0 && header.Id != null && header.Id >= 0) {

			this.setState({ isLoading: true });
			Proxy.GET(
				"/bankapi/v1.0/FeeProfile/Delete/Id?Id=" + header.Id,
				responseData => this.handleDelete(responseData, after),
				this.errorDefinitionCallback,
				workflowId,
				accessToken
			);
		} else {
			this.ShowMessage("info", "Invalid", "Please select profile first !");
		}

	}
	UpdateRecord(workflowId, after, accessToken) {
		this.setState({ isLoading: true });
		const request = {};
		const profile = { ...this.state.ProfileModel };

		request.Profile = profile;
		request.Periodic = this.state.PeriodicModelList;
		request.Fixed = this.state.FixedModelList;
		request.Scale = this.state.WeightedModelList;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/FeeProfile/Update",
			request,
			responseData => this.handleUpdate(responseData, after),
			this.errorDefinitionCallback,
			workflowId,
			accessToken
		);

	}
	FetchData() {
		this.setState({ isLoading: true });
		const header = { ...this.state.ProfileModel };
		if (header.Id != 0 && header.Id != null) {
			this.setState({ isLoading: true });
			Proxy.GET(
				"/bankapi/v1.0/FeeProfileFixedDetail/GetById/Id?Id=" + header.Id,
				this.handleResponse,
				this.errorDefinitionCallback
			);
		}
	}
	handleResponse(responseData) {
		this.setState({ isLoading: false });
		console.log("getall", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			if (responseData.Item != null) {
				this.setState({ FixedModelList: responseData.Item || [] });
			}

		}
		this.setState({ isLoading: false });
	}
	handleUpdate(responseData, after) {
		this.setState({ isLoading: false });
		console.log("update", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			if (responseData.Item != null) {
				this.setState({
					FixedModelList: responseData.Item.Fixed || [],
					PeriodicModelList: responseData.Item.Periodic || [],
					WeightedModelList: responseData.Item.Scale || []
				});
				if (after) after();
				var temp = responseData.Item.Profile;
				temp.FeeProfileTypeStr = temp.FeeProfileType.ParameterValue;
				temp.IsSupportWeighted = temp.Program.IsSupportWeighted;
				this.setState({ ProfileModel: temp }, () => console.log("profile", this.state.ProfileModel));
				this.ShowMessage("success", "Success", "Changes Saved Sucesfully.");
			}
		}
		this.setState({ isLoading: false });
	}
	handleDelete(responseData, after) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		}
		if (after) after();
		this.ShowMessage("success", "Success", "Deleted !");
		this.ClearData();
	}
	errorDefinitionCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	handleChangeProfile = (name, newValue, data) => {
		const model = { ...this.state.ProfileModel };
		if (name == "ProgramId" && data != null) {
			this.FetchPropFromParameter(newValue);
			const temp = this.state.ReadOnlyData;
			temp.Description = data.Description;
			this.setState({ ReadOnlyData: temp });

			model.IsSupportWeighted = data.IsSupportWeighted;
			model.FeeProfileTypeStr = null;
		}
		if (name == "FeeProfileTypeId" && data != null) {
			model.FeeProfileTypeStr = data.ParameterValue;
			this.setState({
				WeightedModelList: [],
				FixedModelList: []
			});
		}
		model[name] = newValue;
		this.setState({ ProfileModel: model });
	}
	ClearData() {
		var temp = { Id: 0 };
		this.setState({
			FixedModelList: [],
			FixedPropList: [],
			PeriodicPropList: [],
			ProfileModel: temp,
			PeriodicModelList: [],
			ReadOnlyData: {},
			FeeTypes: [],
			WeightedModelList: [],
			vModel: {}
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	showConfirmMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.UpdateRecord()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({ alert: null });
		this.setState({ isLoading: false });
	}
	RenderOrganizationUniqueIdSelect = (d) => {
		if (window.OrganizationUniqueId != 5000) {
		  const ProfileModel = { ...this.state.ProfileModel };
		  ProfileModel["OrganizationUniqueId"] = d.OrganizationUniqueId;
		  this.setState({ ProfileModel: ProfileModel });
		}
  
		return d.OrganizationUniqueId + "-" + d.OrganizationName;
	 };
	 
	render() {
		const { FixedModelList, FixedPropList, PeriodicPropList, ProfileModel, PeriodicModelList, ReadOnlyData, isLoading, FeeTypes, WeightedModelList, vModel } = this.state;
		const { Disabled: DisabledProb } = this.props;
		var Disabled = DisabledProb || this.monitorMode;
		const CM1 = WeightedModelList.find(x => x.LevelId == 1 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.IsCombineScale == true);
		const CM2 = WeightedModelList.find(x => x.LevelId == 2 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.IsCombineScale == true);
		const CM3 = WeightedModelList.find(x => x.LevelId == 3 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.IsCombineScale == true);
		const CM4 = WeightedModelList.find(x => x.LevelId == 4 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.IsCombineScale == true);
		const CM5 = WeightedModelList.find(x => x.LevelId == 5 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.IsCombineScale == true);

		const RG1 = WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG2 = WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG3 = WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG4 = WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG5 = WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);

		const PR1 = WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR2 = WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR3 = WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR4 = WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR5 = WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);



		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />

				<ButtonToolbar ButtonList={[
					{
						Code: "Delete",
						RowId: this.state.ProfileModel.Id,
						OnClick: this.DeleteRecord, Disabled: Disabled || !ProfileModel.Id > 0,
						ModelFunction: () => {
							return {
								Profile: this.state.ProfileModel,
								Periodic: this.state.PeriodicModelList,
								Fixed: this.state.FixedModelList,
								Scale: this.state.WeightedModelList
							};
						},
						FillDataFromModel: model => {
							if (model.Profile)
								this.FetchPropFromParameter(model.Profile.ProgramId);
							this.setState({
								ProfileModel: model.Profile,
								PeriodicModelList: model.Periodic,
								FixedModelList: model.Fixed,
								WeightedModelList: model.Scale
							});
						}
					},
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{
						DisableWorkflow: true,
						Code: "Submit",
						RowId: this.state.ProfileModel.Id,
						OnClick: this.OperationCheck, Disabled: Disabled || ProfileModel.Id > 0,
						ModelFunction: () => {
							return {
								Profile: this.state.ProfileModel,
								Periodic: this.state.PeriodicModelList,
								Fixed: this.state.FixedModelList,
								Scale: this.state.WeightedModelList
							};
						},
						FillDataFromModel: model => {
							if (model.Profile)
								this.FetchPropFromParameter(model.Profile.ProgramId);
							this.setState({
								ProfileModel: model.Profile,
								PeriodicModelList: model.Periodic,
								FixedModelList: model.Fixed,
								WeightedModelList: model.Scale
							});
						},
						ValidationFunction: this.Validate
					},
					{
						DisableWorkflow: true,
						Code: "Update",
						RowId: this.state.ProfileModel.Id,
						OnClick: this.OperationCheck, Disabled: Disabled || !ProfileModel.Id > 0,
						ModelFunction: () => {
							return {
								Profile: this.state.ProfileModel,
								Periodic: this.state.PeriodicModelList,
								Fixed: this.state.FixedModelList,
								Scale: this.state.WeightedModelList
							};
						},
						FillDataFromModel: model => {
							if (model.Profile)
								this.FetchPropFromParameter(model.Profile.ProgramId);
							this.setState({
								ProfileModel: model.Profile,
								PeriodicModelList: model.Periodic,
								FixedModelList: model.Fixed,
								WeightedModelList: model.Scale
							});
						},
						ValidationFunction: this.Validate
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Profile Information "}>
						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={3}>
									<GenericTextInput Name="ProfileName" LabelText="Profile Name" Value={ProfileModel.ProfileName || ""} ValueChanged={this.handleChangeProfile} Disabled={Disabled} Required
										IsInvalid={vModel.ProfileName} />
									<GenericDateInput Utc Name="ValidFrom" LabelText="Valid From" Value={ProfileModel.ValidFrom == null ? "" : Formatter.FormatDateUtc(ProfileModel.ValidFrom)} ValueChanged={this.handleChangeProfile} Disabled={Disabled} Required
										IsInvalid={vModel.ValidFrom} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput Disabled={ProfileModel.Id > 0 ? true : Disabled} Name="ProgramId" LabelText="Program" Value={ProfileModel.ProgramId || ""} ValueChanged={this.handleChangeProfile} KeyValueMember="Id" TextValueMember="Description" Method="GET" Url="/bankapi/v1.0/Bank/ValidBankProgramsForFeeProfile" DataRoot="Item" Required
										IsInvalid={vModel.ProgramId} />
									<GenericDateInput Utc IsFuture={true} MinDate={ProfileModel.ValidFrom || [null]} Name="ValidTo" LabelText="Valid To" Value={ProfileModel.ValidTo == null ? "" : Formatter.FormatDateUtc(ProfileModel.ValidTo)} ValueChanged={this.handleChangeProfile} Disabled={Disabled} Required
										IsInvalid={vModel.ValidTo} />
								</GridItem>
								<GridItem xs={3}>
									{ProfileModel.IsSupportWeighted && (
										<GenericSelectInput Name="FeeProfileTypeId" LabelText="Fee Profile Type" Value={ProfileModel.FeeProfileTypeId}
											ValueChanged={this.handleChangeProfile}
											KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={{
												ParameterCode: "FeeProfileType",
												ParameterValue: "",
												ParameterDesc: ""
											}}
											Disabled={ProfileModel.Id > 0 ? true : Disabled}
											DataRoot="Item"
											Required
											IsInvalid={vModel.FeeProfileTypeId}
										/>
									)}
									{!ProfileModel.IsSupportWeighted && (
										<GenericSelectInput Name="FeeProfileTypeId" LabelText="Fee Profile Type" Value={ProfileModel.FeeProfileTypeId}
											ValueChanged={this.handleChangeProfile}
											KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={{
												ParameterCode: "FeeProfileType",
												ParameterValue: "F",
												ParameterDesc: ""
											}}
											Disabled={ProfileModel.Id > 0 ? true : Disabled}
											DataRoot="Item"
											Required
											IsInvalid={vModel.FeeProfileTypeId}
										/>
									)}
								</GridItem>
								<GridItem xs={3}>
								<GenericSelectInput
                            IsTextBold
                            key={this.state.IsClientComponent}
                            Name="OrganizationUniqueId"
                            LabelText="Organization"
                            Value={ProfileModel.OrganizationUniqueId || ""}
                            DataRoot="Item"
                            ValueChanged={this.handleChangeProfile}
                            KeyValueMember="OrganizationUniqueId"
                            TextValueMember="OrganizationUniqueId"
                            //  TaskPoolDomain={this.loadTaskPool}
                            Url="/bankapi/v1.0/OrganizationInfo/GetAll"
                            Method="POST"
                            RenderItem={this.RenderOrganizationUniqueIdSelect}
                            Parameter={emptyObject}
                            Required
                            IsInvalid={vModel.OrganizationUniqueId}
                          />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card >

				{((ProfileModel.FeeProfileTypeStr == FeeProfileType.Fixed && ProfileModel.IsSupportWeighted) || (ProfileModel.ProgramId == 12)) && (
					<Card className="no-radius" style={{ height: "90%" }} key={ProfileModel.ProgramId + "a" + ProfileModel.FeeProfileTypeId + "e"}>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Transaction Fees </p></b>
						<CardBody>
							{FeeTypes != null && FeeTypes.filter(x => x == this.state.TransactionFeeLabelTitleParameter).map(x => x != null && (
								<GridItem xs={12} sm={12} md={12} key={x.key} style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px" }}>
									<b><p style={{ paddingTop: "20px", marginLeft: "8px" }}> {x} </p></b>
									<GridContainer>
										{FixedPropList != null && FixedPropList.filter(y => y.ParameterValue4 == x && ((ProfileModel.ProgramId == 12 && ProfileModel.FeeProfileTypeStr != FeeProfileType.Fixed) ? y.ParameterValue.endsWith("RealTime") : true)).map(item => (
											<GridItem xs={6} key={item.Id}>
												<GridContainer>
													<GridItem xs={6}>
														<GenericNumberInput DecimalScale={4} MaxLength={8} LabelMd={6} Name="FeeAmount" LabelText={item.ParameterValue3}
															Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
															ValueChanged={(name, value) => this.handleListItemChangeFixed(name, value, item.ParameterValue)}
															Disabled={Disabled}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput DecimalScale={4} MaxLength={8} LabelMd={4} Name="Cost" LabelText="Cost"
															MaxValue={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
															Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
															ValueChanged={(name, value) => this.handleListItemChangeFixed(name, value, item.ParameterValue)}
															Disabled={Disabled}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericCheckInput Name="IsCommissionCalculation" LabelText="Commission" LabelMd={6}
															Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).IsCommissionCalculation}
															ValueChanged={(name, value) => this.handleListItemChangeFixed(name, value, item.ParameterValue)}
															Disabled={Disabled}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										))}
									</GridContainer>
									<br />
								</GridItem>
							))}
						</CardBody>
					</Card>
				)}

				{((ProfileModel.FeeProfileTypeStr == FeeProfileType.CombineWeighted || ProfileModel.FeeProfileTypeStr == FeeProfileType.CombineUnWeighted) && (ProfileModel.IsSupportWeighted)) && (
					<GridContainer spacing={16}>
						<GridItem xs={6}>
							<Card style={{ height: "90%" }}>
								<p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Combine Scale Fees </b></p>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}><b>Level</b></p></GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Count From </b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>To</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}>Level 1</p>
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM1.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 1)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM1.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 1)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={CM1.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 1)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={CM1.Cost} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 1)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM1.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 1)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM2.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 2)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM2.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 2)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={CM2.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 2)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={CM2.Cost} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 2)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM2.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 2)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM3.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 3)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM3.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 3)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={CM3.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 3)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={CM3.Cost} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 3)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM3.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 3)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM4.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 4)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM4.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 4)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={CM4.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 4)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={CM4.Cost} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 4)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM4.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 4)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM5.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 5)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM5.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 5)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={CM5.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 5)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={CM5.Cost} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 5)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM5.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeCombined(name, value, 5)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<br />
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				)}
				{((ProfileModel.FeeProfileTypeStr == FeeProfileType.Weighed) || (ProfileModel.FeeProfileTypeStr == FeeProfileType.UnWeighed)) && (ProfileModel.IsSupportWeighted) && (
					<GridContainer spacing={16}>
						<GridItem xs={6} >
							<Card style={{ height: "90%" }}>
								<p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Charge Per Transaction - Regular Debit&Credit</b></p>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}><b>Level</b></p></GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Count From </b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>To</b></p>
										</GridItem>
										<GridItem xs={2}>
											<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 1</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG1.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG1.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={RG1.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={RG1.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG1.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG2.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG2.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={RG2.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={RG2.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG2.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG3.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG3.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={RG3.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={RG3.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG3.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG4.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG4.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={RG4.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={RG4.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG4.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG5.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG5.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={RG5.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={RG5.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG5.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Regular)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<br />
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={6} >
							<Card style={{ height: "90%" }}>
								<p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Charge Per Transaction - Priority Debit&Credit</b></p>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Level</b></p></GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Count From </b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>To</b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
										</GridItem>
										<GridItem xs={2} >
											<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 1</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR1.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR1.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={PR1.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={PR1.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR1.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 1, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR2.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR2.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={PR2.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={PR2.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR2.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 2, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR3.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR3.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={PR3.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={PR3.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR3.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 3, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR4.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR4.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={PR4.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={PR4.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR4.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 4, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR5.LevelFrom} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput MaxLength={8} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR5.LevelTo} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="FeeAmount" LabelText="" Value={PR5.FeeAmount} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} Name="Cost" LabelText="" Value={PR5.Cost} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR5.IsCommissionCalculation} ValueChanged={(name, value) => this.handleListItemChangeScaled(name, value, 5, PriorityRegularType.Priority)} Disabled={Disabled} />
										</GridItem>
									</GridContainer>
									<br />
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				)}

				<Card className="no-radius" style={{ height: "90%" }}>
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}>Other Fees - {ReadOnlyData.Description} </p></b>
					<CardBody>
						{FeeTypes != null && FeeTypes.filter(x => x != this.state.TransactionFeeLabelTitleParameter).map(x => x != null && (
							<GridItem xs={12} key={x.key} style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px" }}>
								<b><p style={{ paddingTop: "20px", marginLeft: "8px" }}> {x} </p></b>
								<GridContainer>
									{FixedPropList != null && FixedPropList.filter(y => y.ParameterValue4 == x).map(item => (
										<GridItem xs={6} key={item.Id}>
											<GridContainer>
												<GridItem xs={6} >
													<GenericNumberInput DecimalScale={4} MaxLength={8} LabelMd={6} Name="FeeAmount" LabelText={item.ParameterValue3}
														Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
														ValueChanged={(name, value) => this.handleListItemChangeFixed(name, value, item.ParameterValue)}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem xs={3} >
													<GenericNumberInput DecimalScale={4} MaxLength={8} LabelMd={4} Name="Cost" LabelText="Cost"
														MaxValue={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
														Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
														ValueChanged={(name, value) => this.handleListItemChangeFixed(name, value, item.ParameterValue)}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem xs={3} >
													<GenericCheckInput Name="IsCommissionCalculation" LabelText="Commission" LabelMd={6}
														Value={FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : FixedModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).IsCommissionCalculation}
														ValueChanged={(name, value) => this.handleListItemChangeFixed(name, value, item.ParameterValue)}
														Disabled={Disabled}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									))}
								</GridContainer>
								<br />
							</GridItem>
						))}
					</CardBody>
				</Card>

				<Card className="no-radius" style={{ height: "90%" }}>
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}>Periodic Fees</p></b>
					<CardBody>
						<GridContainer>
							{PeriodicPropList != null && PeriodicPropList.map(item => (
								<GridItem xs={6} key={item.Id}>
									<GridContainer>
										<GridItem xs={6} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} LabelMd={8} Name="FeeAmount" LabelText={item.ParameterValue3}
												Value={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
												ValueChanged={(name, value) => this.handleListItemChangePeriodic(name, value, item.ParameterValue)}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3} >
											<GenericNumberInput DecimalScale={4} MaxLength={8} LabelMd={4} Name="Cost" LabelText="Cost"
												MaxValue={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
												Value={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
												ValueChanged={(name, value) => this.handleListItemChangePeriodic(name, value, item.ParameterValue)}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3} >
											<GenericCheckInput Name="IsCommissionCalculation" LabelText="Commission"
												Value={PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : PeriodicModelList.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).IsCommissionCalculation}
												ValueChanged={(name, value) => this.handleListItemChangePeriodic(name, value, item.ParameterValue)}
												Disabled={Disabled}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							))}
						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

FeeProfile.propTypes = {
	classes: PropTypes.object,
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	FeeType: PropTypes.func
};


export default withArtifex(FeeProfile, {});