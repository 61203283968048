const primaryColor = window.PageMainColor;
const warningColor = "#F57F29";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999";

export { primaryColor, warningColor, dangerColor, successColor, infoColor, roseColor, grayColor };

