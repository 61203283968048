import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class FinScanWatchlistFile extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "Version",
				accessor: "Version"
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			}
		];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Watchlist File");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;

			model[name] = value;

			if (name == "FromDate") {
				model["ToDate"] = null;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanFile/Search", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiFileDownloadWithGenericResponse } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "View",
							Disabled: Disabled || model.FileId == undefined,
							OnClick: () => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: model.FileId }, model.FileName, model.FileExtension)
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}  >
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												LabelMd={2}
												IsFuture={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												LabelMd={2}
												IsFuture={false}
												MinDate={model.FromDate}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FinScanWatchlistFile.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FinScanWatchlistFile;