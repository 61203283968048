import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericDateInput,
  GenericNumberInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { GridColumnType } from "views/Constants/Constant";
class UnprocessedPaymentList extends Component {
  constructor(props) {
    super(props);
    this.initialModel = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      FromDate: "",
      ToDate: "",
      ReferenceNumber: "",
      AccountNumber: "",
      CardTokenNumber: "",
    };
    this.state = {
      model: this.initialModel,
      vModel: {},
      list: [],
      isLoading: false,
    };
    this.parameterUniqueClientId = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientId()
        : undefined,
    };
    this.maxLength16 = { maxLength: 16 };
    this.emptyObject = {};
    this.Columns = [
      {
        Header: "Client",
        accessor: "ClientName",
      },
      {
        Header: "Reference Number",
        accessor: "ReferenceNumber",
      },
      {
        Header: "Transaction Source",
        accessor: "TransactionSource",
      },
      {
        Header: "Wallet Number",
        accessor: "WalletAccountNumber",
      },
      {
        Header: "Amount",
        accessor: "Amount",
        type: GridColumnType.Money,
      },
      {
        Header: "Card Token Number",
        accessor: "CardTokenNumber",
      },
      {
        Header: "Insert Date Time",
        accessor: "InsertDateTime",
        type: GridColumnType.Date,
      },
    ];
  }
  componentDidMount() {
    this.props.setAppLeftTitle("Unprocessed Payment Transaction List");
    this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
  }
  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };
  HandleClear = () => {
    this.setState({
      model: { ...this.initialModel },
      vModel: {},
      isEdit: false,
      index: -1,
    });
  };
  HandleSearch = () => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/Card/GetUnprocessedPaymentTransactionList",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ list: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  RowSelected = (index) => {
    let { list } = this.state;
    const tempModel = list[index];
    if (list[index].CardRequestId) {
      tempModel.Id = list[index].Id;
    }
    this.setState({ isEdit: true, model: tempModel, index });
  };
  render() {
    const { Disabled } = this.props;
    const { alert, model, list, isLoading, index, vModel } = this.state;
    var IsClient = ClientHelper.IsClient();
    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Disabled={IsClient}
                            Name="UniqueClientId"
                            LabelText="Client"
                            Url="/bankapi/v1.0/BankCustomer/Search"
                            Method="POST"
                            Parameter={{}}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            RenderItem={(d) =>
                              `${d.UniqueClientId} - ${d.Name}`
                            }
                            TextValueMember="Name"
                            Value={model.UniqueClientId}
                            ValueChanged={this.HandleChange}
                            CanClear
                            All
                            Required
                            DefaultIndex={IsClient ? 0 : -1}
                          />
                          <GenericNumberInput
                            Name="ReferenceNumber"
                            LabelText="Reference Number"
                            LabelMd={4}
                            NoFormatting={true}
                            Value={model.ReferenceNumber}
                            ValueChanged={this.HandleChange}
                            IsInvalid={vModel.ReferenceNumber}
                            MaxLength={20}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericNumberInput
                            Name="WalletAccountNumber"
                            LabelText="Wallet Number"
                            LabelMd={4}
                            NoFormatting={true}
                            Value={model.WalletAccountNumber}
                            ValueChanged={this.HandleChange}
                            IsInvalid={vModel.AccountNumber}
                            MaxLength={11}
                          />
                          <GenericTextInput
                            Name="CardTokenNumber"
                            LabelText="Card Token Number"
                            LabelMd={4}
                            Value={model.CardTokenNumber}
                            ValueChanged={this.HandleChange}
                            IsInvalid={vModel.CardTokenNumber}
                            inputProps={this.maxLength16}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericDateInput
                            Utc
                            Name="FromDate"
                            LabelText="Start Date"
                            Value={
                              model.FromDate
                                ? DateHelper.ToDateInputValue(model.FromDate)
                                : ""
                            }
                            ValueChanged={this.HandleChange}
                          />
                          <GenericDateInput
                            Utc
                            Name="ToDate"
                            LabelText="End Date"
                            Value={
                              model.ToDate
                                ? DateHelper.ToDateInputValue(model.ToDate)
                                : ""
                            }
                            ValueChanged={this.HandleChange}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Transaction List"} />
              </CardHeader>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GenericGrid
                      Data={list}
                      Columns={this.Columns}
                      RowSelected={this.RowSelected}
                      SelectedIndex={index}
							 PageSize={10}
							 ShowPagination={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
UnprocessedPaymentList.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
};
export default withArtifex(UnprocessedPaymentList, {});