import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericNumberInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";


class CardSwitchTransaction extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,
			ProgramCode: "",
			StartDate: DateHelper.GetLocalDateAsUtc(),
			EndDate: DateHelper.GetLocalDateAsUtc(),
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {},
			trxGridToken: false
		};

		this.emptyObject = {};

		this.maxLength6 = { maxLength: 6 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};
		this.renderItemCard = {};
		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Create Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Application NetWork",
				accessor: "ApplicationNetWork"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "External Reference Number",
				accessor: "ExternalReferenceNumber"
			},
			{
				Header: "Card Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCodeDesc"
			},
			{
				Header: "Available Balance",
				accessor: "AvailableBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
			},
			{
				Header: "Amount",
				accessor: "BillingAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
			},
			{
				Header: "Entry Type",
				accessor: "TransactionEntryTypeDesc"
			},
			{
				Header: "Merchant Clearing Amount",
				accessor: "SettlementAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionTypeDesc"
			},
			{
				Header: "Merchant",
				accessor: "Merchant"
			},
			{
				Header: "Mti",
				accessor: "Mti"
			},
			{
				Header: "Processing Code",
				accessor: "ProcessingCode"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Transaction Fee",
				accessor: "TransactionFee"
			},
			{
				Header: "Transaction Description",
				accessor: "TransactionDescription"
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode"
			},
			{
				Header: "Response",
				accessor: "ResponseDescription"
			},
			{
				Header: "Error Code",
				accessor: "ErrorCode"
			},
			{
				Header: "Error Description",
				accessor: "ErrorDescription"
			},

			{
				Header: "Pos Country",
				accessor: "PosCountry"
			},
			{
				Header: "Merchant Clearing Date",
				accessor: "SwitchSettlemntDate"
			},

			{
				Header: "Mcc",
				accessor: "Mcc"
			},
			{
				Header: "NetTraceId",
				accessor: "NetTraceId"
			},
			{
				Header: "Authorzation Code",
				accessor: "AuthorzationCode"
			},
			{
				Header: "Orginal Amount",
				accessor: "OrginalAmount"
			},
			{
				Header: "Transaction Currency",
				accessor: "TransactionCurrency"
			},
			{
				Header: "Billing Currency",
				accessor: "BillingCurrency"
			},
			{
				Header: "Client",
				accessor: "ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},

			{
				Header: "Emboss Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money
			},
			{
				Header: "Has Online Pin",
				accessor: "HasOnlinePin",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Has Cvv",
				accessor: "HasCvv",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Has Cvv2",
				accessor: "HasCvv2",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Is Sent To Provision",
				accessor: "IsSentToProvision",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Is Succeded",
				accessor: "IsSucceded",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Is Financial",
				accessor: "IsFinancialTransaction",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Network AVS Address",
				accessor: "NetworkAVSAddress"
			},
			{
				Header: "Transaction Type Id",
				accessor: "TransactionTypeId"
			},
			{
				Header: "Provision Transaction Type Id",
				accessor: "ProvisionTransactionTypeId"
			},
			{
				Header: "Is Reversed",
				accessor: "IsReversed",
				Cell: row => (
					<div>{row.value == true ? "Yes" : "No"}</div>
				)
			},
			{
				Header: "Reversed Date",
				accessor: "ReversedDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Reversal Type",
				accessor: "ReversalType"
			},
			{
				Header: "System Settlement Status",
				accessor: "SettlementStatusId"
			},
			{
				Header: "Cvv Check Result Id",
				accessor: "CvvCheckResultId"
			},
			{
				Header: "Cvv2 Check Result Id",
				accessor: "Cvv2CheckResultId"
			},
			{
				Header: "Pin Check Result Id",
				accessor: "PinCheckResultId"
			},
			{
				Header: "Cavv Check Result Id",
				accessor: "CavvCheckResultId"
			},
			{
				Header: "Clearing Match Status Id",
				accessor: "ClearingMatchStatusId"
			},
			{
				Header: "Currency Rate",
				accessor: "CurrencyRate"
			},
			{
				Header: "Mobile Wallet Type",
				accessor: "MobileWalletType"
			}
		];

		this.Url = "/prepaidapi/v1.0/Card/CardSwitchTransactions";

		this.sortParamValue = { Member: "ParameterValue" };
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Settlement Transaction Search");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		if (name === "CardId" && data) {
			model.EmbossName = data.CardEmbossName;
		}

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		if (!this.Validate()) {
			return;
		}

		const model = { ...this.state.model };
		model.ProgramCode = this.ProgramCode;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/CardSwitchTransactions",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}
	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		var errorList = [];

		if (!model.IsHasCustomer) {
			vModel.StartDate = !model.StartDate;
			if (vModel.StartDate)
				errorList.push("StartDate cannot be empty!");

			vModel.EndDate = !model.EndDate;
			if (vModel.EndDate)
				errorList.push("EndDate cannot be empty!");
		}
		else if (model.IsHasCustomer) {
			vModel.UniqueClientId = !model.UniqueClientId;
			if (vModel.UniqueClientId) {
				errorList.push("Select the Client to continue.");
			}
			vModel.ClientCustomerId = !model.ClientCustomerId;
			if (vModel.ClientCustomerId) {
				errorList.push("Select the Customer to continue.");
			}
			vModel.CardId = !model.CardId;
			if (vModel.CardId) {
				errorList.push("Select the Card to continue.");
			}
		}
		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}

		return true;
	}
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}
	RenderItemCard = (d) => {
		this.renderItemCard = `${d.MaskedCardNumber} - ${d.CardEmbossName}`;
		return this.renderItemCard;
	}





	GetModel = () => { return this.state.model; }

	render() {
		const { Disabled } = this.props;
		const { alert, model, vModel, list, isLoading } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={6}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelMd={4}
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													Disabled={IsClient}
													DefaultIndex={IsClient ? 0 : -1}
												/>
											</GridItem>

											<GridItem xs={6}>

												<GenericNumberInput
													NoFormatting
													Name="Bin"
													LabelText="Bin"
													MaxLength={6}
													Value={model.Bin}
													ValueChanged={this.HandleChange} />
											</GridItem>

											<GridItem xs={6}>
												<GenericNumberInput
													NoFormatting
													Name="Mti"
													LabelText="Mti"
													Value={model.Mti}
													ValueChanged={this.HandleChange} />
											</GridItem>

											<GridItem xs={6}>
												<GenericDateInput
													Utc
													Name="StartDate"
													LabelText="Start Date"
													Value={model.StartDate ? DateHelper.ToDateInputValue(model.StartDate) : ""}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={6}>
												<GenericDateInput
													Utc
													Name="EndDate"
													LabelText="End Date"
													Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
													ValueChanged={this.HandleChange}
													MinDate={model.StartDate}
												/>
											</GridItem>

										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Card Switch Transactions"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={true}
											PageSize={100} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

CardSwitchTransaction.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CardSwitchTransaction, {});