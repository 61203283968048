import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericDialog,
  GenericDialogActions,
  GenericExpansionPanel,
  GenericLabel,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankingAccountTypes } from "views/Constants/Constant";

class TransferBetweenClientAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {},
      vModel: {},
      readOnlyData: {},
      isLoading: false,
      isConfirmDialogOpen: false,
      reloadData: false,
      transactionResponse: {},
      isResponseDialogOpen: false,
    };
  }
  componentDidMount() {
    //this.props.hideSidebar();
    this.props.setAppLeftTitle("Transfer  To Client Wallet");
    this.props.setAppCenterTitle("WALLET");
  }
  PerformTransaction = () => {
    const model = { ...this.state.model };
    let vModel = { ...this.state.vModel };
    var ErrorList = [];

    vModel.SourceAccountId =
      model.SourceAccountId == null || model.SourceAccountId == "";
    if (vModel.SourceAccountId) {
      ErrorList.push("Please select source wallet !");
    }

    vModel.DestinationAccount =
      model.DestinationAccount == null || model.DestinationAccount == "";
    if (vModel.DestinationAccount) {
      ErrorList.push("Please select receiver wallet !");
    }

    vModel.Amount = model.Amount == null || model.Amount == "";
    if (vModel.Amount) {
      ErrorList.push("Please fill transaction amount !");
    }

    if (
      this.state.readOnlyData?.Destination == null ||
      this.state.readOnlyData?.Destination?.AccountNumber == null
    ) {
      //	ErrorList.push("Please select valid wallet !");
      //vModel.DestinationAccount = true;
    }

    this.setState({ vModel });
    if (ErrorList.length > 0) {
      this.props.showMessageNode(
        "warning",
        "Please fill required fields!",
        ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    this.FetchClientAccount(model.DestinationAccount, model.SourceAccountId);
  };

  SendTransaction = () => {
    //GetCustomerBankAccountProfileOp class
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Transaction/TransferBetweenClientAccountsWithFee",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }

        if (responseData.Item != null) {
          this.setState({
            isConfirmDialogOpen: false,
            model: {},
            readOnlyData: {},
            reloadData: !this.state.reloadData,
          });
          this.setState({
            transactionResponse: responseData.Item || {},
            isResponseDialogOpen: true,
          });
          this.props.showMessage(
            "success",
            "Success",
            "Transaction Completed !"
          );
        } else {
          this.props.showMessage("error", "Error !", "An error occurred !");
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage(
          "error",
          "Error !",
          "An error occurred while requesting data " + error
        );
      }
    );
  };
  FetchClientAccount = (destinationAccount, sourceAccount) => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Transaction/GetClientAccountByAccountNumber",
      {
			AccountNumber: destinationAccount,
        SourceAccountId: sourceAccount,
      },
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          var temp = { ...this.state.readOnlyData };
          temp.Destination = {};
          this.setState({ readOnlyData: temp });
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }

        const model = this.state.model;
        model.FeeAmount = responseData.Item.FeeAmount;
        this.setState({ model });

        var temp = { ...this.state.readOnlyData };
        temp.Destination = responseData.Item || {};
        this.setState({ readOnlyData: temp });

        this.setState({ isConfirmDialogOpen: true });
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage(
          "error",
          "Error !",
          "An error occurred while requesting data " + error
        );
      }
    );
  };
  handleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    var temp = { ...this.state.readOnlyData };
    model[name] = newValue;
    if (name == "SourceAccountId" && data != null) {
      temp.SourceBalance = data.Balance;
      temp.SourceName = data.AccountName;
      temp.SourceType = data.AccountType;
      model.SourceAccountType = data.AccountCode;
      model.ProgramId = data.ProgramId;
      model.PoolAccountId = data.PoolAccountId;
      model.Amount = 0;
    }

    this.setState({ model: model, readOnlyData: temp });
  };
  ClearData = () => {
    this.setState({ isLoading: false });
    this.setState({
      alert: null,
      model: {},
      readOnlyData: {},
      vModel: {},
      transactionResponse: {},
      isResponseDialogOpen: false,
      isConfirmDialogOpen: false,
    });
  };

  render() {
    const { Disabled } = this.props;
    const {
      model,
      isConfirmDialogOpen,
      readOnlyData,
      vModel,
      isResponseDialogOpen,
      transactionResponse,
    } = this.state;
    return (
      <div>
        {this.state.alert}

        <LoadingComponent Show={this.state.isLoading} />

        <ButtonToolbar
          ButtonList={[
            {
              Code: "Clear",
              OnClick: () => this.ClearData(),
              Disabled: Disabled,
            },
            {
              Code: "Submit",
              OnClick: () => this.PerformTransaction(),
              Disabled: Disabled,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />

        <Card className="no-radius">
          <GenericExpansionPanel>
            <CardBody>
              <GridItem xs={12}>
                <GridContainer justify="flex-start">
                  <GridItem xs={12}>
                    <GenericTitle
                      text={"Please Select the Transfer Wallets"}
                    />
                  </GridItem>
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        key={this.state.reloadData + ""}
                        Name="SourceAccountId"
                        Disabled={Disabled}
                        Method="POST"
                        Url="/bankapi/v1.0/Account/GetClientSourceAccounts"
                        Parameter={{}}
                        DataRoot="Item"
                        LabelText="From Wallet or Wallet"
                        Value={model.SourceAccountId || ""}
                        KeyValueMember="Id"
                        TextValueMember="AccountName"
                        ValueChanged={this.handleChange}
                        Required
                        IsInvalid={vModel.SourceAccountId}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      {readOnlyData.SourceName != null && (
                        <Card>
                          <CardBody>
                            <p
                              style={{
                                textColor: "#000",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Balance :{" "}
                              {Formatter.FormatMoney(
                                readOnlyData.SourceBalance
                              )}
                            </p>
                            <p
                              style={{
                                textColor: "#000",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Wallet Name : {readOnlyData.SourceName}
                            </p>
                            <p style={{ textColor: "#000", fontSize: "12px" }}>
                              {" "}
                              Wallet Type : {readOnlyData.SourceType}{" "}
                            </p>
                          </CardBody>
                        </Card>
                      )}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericNumberInput
                        Required
                        IsInvalid={vModel.DestinationAccount}
                        NoFormatting={true}
                        MaxLength={14}
                        Name="DestinationAccount"
                        LabelText="Receiver Wallet Number"
                        Value={model.DestinationAccount}
                        ValueChanged={this.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      {/* {(readOnlyData?.Destination == null || readOnlyData?.Destination.AccountNumber == null || readOnlyData?.Destination.AccountNumber == "") ? (
												<>
													{( model.DestinationAccount != null && model.DestinationAccount.length > 6) && (
														<p style={{ textColor: "#000", fontSize: "13px", fontWeight: "bold", marginTop: "7px" }}> Invalid Account</p>

													)}
												</>
											) : (
													<Card>
														<CardBody>
															<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Client : {`${readOnlyData?.Destination?.ClientName} (${readOnlyData?.Destination?.UniqieClientId})`}</p>
															<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet : {`${readOnlyData?.Destination?.AccountName} (${readOnlyData?.Destination?.AccountNumber})`} </p>
														</CardBody>
													</Card>
												)} */}
                    </GridItem>
                  </GridContainer>

                  <GridItem xs={12} style={{ marginTop: "20px" }}>
                    <GenericTitle
                      text={"Please Enter Transaction Information"}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericNumberInput
                      MaxLength={10}
                      Prefix="$ "
                      Name="Amount"
                      LabelText="Transaction Amount"
                      Value={model.Amount}
                      ValueChanged={this.handleChange}
                      Required
                      IsInvalid={vModel.Amount}
                    />
                    <GenericTextInput
                      inputProps={{ maxLength: 100 }}
                      Name="Explanation"
                      LabelText="Explanation"
                      Value={model.Explanation || ""}
                      ValueChanged={this.handleChange}
                    />
                  </GridItem>
                </GridContainer>

                <GenericDialog open={isConfirmDialogOpen} maxWidth="lg">
                  <DialogTitle>Transaction Confirmation</DialogTitle>
                  <DialogContent>
                    <GridItem>
                      <GenericTextInput
                        Name=""
                        LabelText="From Wallet"
                        Value={readOnlyData.SourceName}
                        Disabled={true}
                      />
                      <hr />
                      Receiver Information
                      <GenericTextInput
                        Name=""
                        LabelText="To Client"
                        Value={`${
                          readOnlyData?.Destination?.ClientName || ""
                        } (${readOnlyData?.Destination?.UniqieClientId || ""})`}
                        Disabled={true}
                      />
                      <GenericTextInput
                        Name=""
                        LabelText="To Wallet"
                        Value={`${
                          readOnlyData?.Destination?.AccountName || ""
                        } (${readOnlyData?.Destination?.AccountNumber || ""})`}
                        Disabled={true}
                      />
                      <GenericNumberInput
                        Prefix="$ "
                        Name=""
                        LabelText="Amount"
                        Value={model.Amount}
                        Disabled={true}
                      />
                      <GenericNumberInput
                        Prefix="$ "
                        Name=""
                        LabelText="Fee Amount"
                        Value={model.FeeAmount}
                        Disabled={true}
                      />
                      <GenericTextInput
                        Name=""
                        LabelText="Description"
                        Value={model.Explanation || ""}
                        Disabled={true}
                      />
                      <GenericLabel
                        Text="Are you sure you want to enter this transaction ?"
                        Bold={true}
                      />
                    </GridItem>
                  </DialogContent>
                  <GenericDialogActions>
                    <Button size="sm" onClick={() => this.SendTransaction()}>
                      Transfer
                    </Button>
                    <Button
                      size="sm"
                      onClick={() =>
                        this.setState({ isConfirmDialogOpen: false })
                      }
                    >
                      Cancel
                    </Button>
                  </GenericDialogActions>
                </GenericDialog>

                <GenericDialog open={isResponseDialogOpen} maxWidth="lg">
                  <DialogTitle>Transaction Response</DialogTitle>
                  <DialogContent>
                    <GridItem>
                      <GenericTextInput
                        Name=""
                        LabelText="Referance Number"
                        Value={transactionResponse?.ReferenceNumber}
                        Disabled={true}
                      />
                      <GenericTextInput
                        Name=""
                        LabelText="From Wallet"
                        Value={transactionResponse?.SourceAccount}
                        Disabled={true}
                      />
                      <hr />
                      Receiver Information
                      <GenericTextInput
                        Name=""
                        LabelText="To Client"
                        Value={`${transactionResponse?.DestinationClient}`}
                        Disabled={true}
                      />
                      <GenericTextInput
                        Name=""
                        LabelText="To Wallet"
                        Value={`${transactionResponse?.DestinationAccount} `}
                        Disabled={true}
                      />
                      <GenericNumberInput
                        Prefix="$ "
                        Name=""
                        LabelText="Amount"
                        Value={transactionResponse?.Amount}
                        Disabled={true}
                      />
                      <GenericNumberInput
                        Prefix="$ "
                        Name=""
                        LabelText="Fee Amount"
                        Value={transactionResponse?.FeeAmount}
                        Disabled={true}
                      />
                      <GenericTextInput
                        MultiLine={true}
                        RowCount={4}
                        Name=""
                        LabelText="Description"
                        Value={transactionResponse?.Explanation || ""}
                        Disabled={true}
                      />
                    </GridItem>
                  </DialogContent>
                  <GenericDialogActions>
                    <Button
                      size="sm"
                      onClick={() => {
                        this.setState({ isResponseDialogOpen: false });
                        this.ClearData();
                      }}
                    >
                      Cancel
                    </Button>
                  </GenericDialogActions>
                </GenericDialog>
              </GridItem>
            </CardBody>
          </GenericExpansionPanel>
        </Card>
      </div>
    );
  }
}

TransferBetweenClientAccounts.propTypes = {
  classes: PropTypes.object,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
};

export default withArtifex(TransferBetweenClientAccounts, {});
