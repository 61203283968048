import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import StringHelper from "core/StringHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import { GenericLabel } from "views/Components/Generic";

class GenericColorInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayColorPicker: false
		};
	}

	HandleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	}

	render() {
		const { classes, LabelText, Name, Value, DefaultValue, ValueChanged, Disabled, LabelMd } = this.props;
		const defaultCompValue = "#FFFFFF";

		const styles = ({
			color: {
				width: "36px",
				height: "14px",
				borderRadius: "2px",
				background: Value || DefaultValue || defaultCompValue,
			},
			swatch: {
				padding: "3px",
				borderRadius: "1px",
				boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
				display: "inline-flex",
				cursor: "pointer",
			},
			popover: {
				position: "absolute",
				zIndex: "2",
			},
			cover: {
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px",
			},
			label: {
				cursor: "pointer",
				paddingLeft: "0",
				color: "#888888",
				fontSize: "16px",
				lineHeight: "1.428571429",
				fontWeight: "400",
				display: "inline-flex",
				transition: "0.3s ease all",
			}
		});

		return (
			<div style={{ marginTop: 4 }}>
				<GridContainer direction="row" justify="flex-start" alignItems="flex-end">
					{LabelMd != 0 &&
						<GridItem zs={LabelMd == undefined ? 4 : LabelMd}>
							<GenericLabel Text={LabelText} htmlFor={Name} style={styles.label} />
						</GridItem>
					}
					<GridItem xs={LabelMd == undefined ? 8 : 12 - LabelMd}>
						<div style={styles.swatch} onClick={this.HandleClick}>
							<div style={styles.color} />
						</div>
						{this.state.displayColorPicker ? <div style={styles.popover}>
							<div style={styles.cover} onClick={this.HandleClick} />
							<SketchPicker
								color={StringHelper.IsValidColor(Value) ? Value : defaultCompValue}
								presetColors={[]}
								onChange={color => {
									if (color && color.hex) {
										if (StringHelper.IsValidColor(color.hex)) {
											if (ValueChanged)
												ValueChanged(Name, color.hex);
										}
										else {
											if (ValueChanged)
												ValueChanged(Name, defaultCompValue);
										}
									}
									else {
										if (ValueChanged)
											ValueChanged(Name, defaultCompValue);
									}
								}} />
						</div> : null}
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

GenericColorInput.propTypes = {
	ValueChanged: PropTypes.func,
	Value: PropTypes.any,
	DefaultValue: PropTypes.any,
	Name: PropTypes.string,
	LabelText: PropTypes.string,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool
};

export default GenericColorInput;