import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/views/createWireTransferStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericTextInput, GenericSelectInput, GenericIcon, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericNumberInput } from "views/Components/Generic";
class FileResultDialog extends Component {

	constructor(props) {
		super(props);
		this.columns = [
			{
				Header: "Sender Name",
				accessor: "SenderName"
			},
			{
				Header: "Sender Address Line",
				accessor: "SenderAddr1"
			},
			{
				Header: "Sender Address Line",
				accessor: "SenderAddr2"
			},
			{
				Header: "Beneficiary Name",
				accessor: "BeneficiaryName"
			},
			{
				Header: "Sender Address Line",
				accessor: "BeneficiaryAddr1"
			},
			{
				Header: "Sender Address Line",
				accessor: "BeneficiaryAddr2"
			},
			{
				Header: "Country",
				accessor: "DestCountry"
			},
			{
				Header: "Amount",
				accessor: "PaymentAmount"
			},
			{
				Header: "Currency",
				accessor: "PaymentCurrency"
			},
			{
				Header: "CAD Equivalent",
				accessor: "CADEquivalent"
			}
		];
	}

	componentDidMount() {

	}

	render() {
		const { FileResultList, HandleConfirm, HandleCancel, CurrencyId, ShowResult,Model } = this.props;
		const hasAction = HandleConfirm != null;
		// var totalAmount = 0;
		// if (FileResultList != null && FileResultList != undefined) {
		// 	FileResultList.forEach(element => {
		// 		totalAmount += element.CADEquivalent;
		// 	});
		// }
		var renderColumns = [...this.columns];
		if (ShowResult) {
			renderColumns.push({
				Header: "Result",
				accessor: "RejectDescription"
			});
		}

		return (
			<GenericDialog open={true} maxWidth="lg" fullWidth>
				<DialogTitle>
					<GridContainer justify="space-between" alignItems="center">
						<GridItem>
							<GenericLabel FontSize="16px" TextColor="black" Text="File Upload Process Done" Bold={true} />
						</GridItem>
						<GridItem>
							<IconButton onClick={() => this.setState({ isFileResultDialogOpen: false })}>
								<GenericIcon>close</GenericIcon>
							</IconButton>
						</GridItem>
					</GridContainer>
				</DialogTitle>
				<DialogContent>
					<GridContainer>
						<GridItem xs={12}>
							<GenericGrid
								Columns={renderColumns}
								Data={FileResultList} />
						</GridItem>
						<GridItem xs={4}>
							<GenericNumberInput
								Disabled={true}
								LabelText="Total Amount (CAD)"
								LabelMd={6}
								Value={Model && Model.TotalAmount} />
						</GridItem>
					</GridContainer>
				</DialogContent>
				{hasAction && <GenericDialogActions>
					<Button size="sm" onClick={HandleConfirm} disabled={ShowResult}>Confirm</Button>
					<Button size="sm" onClick={HandleCancel}>Cancel</Button>
				</GenericDialogActions>
				}
			</GenericDialog>
		);
	}
}

FileResultDialog.propTypes = {
	Model: PropTypes.object.isRequired,
	ShowResult: PropTypes.bool.isRequired,
	CurrencyId: PropTypes.number.isRequired,
	classes: PropTypes.object,
	Disabled: PropTypes.bool,
	HandleConfirm: PropTypes.func,
	HandleCancel: PropTypes.func,
	FileResultList: PropTypes.array,
};

export default withArtifex(FileResultDialog, style);