import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DownloadIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateFilterInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ReportDownload extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			DateFilter: {
				BeginDate: DateHelper.GetLocalDateAsUtc(),
				EndDate: DateHelper.GetLocalDateAsUtc()
			},
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined
		};

		this.gridSortParameter = [{ id: "CreatedDate", desc: true }];

		this.state = {
			list: [],
			model: { ...this.initialModel },
			vModel: {},
			isLoading: false,
			selected: -1,
			clients : []
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined
		};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "AP",
				Cell: d => (
					<GridButton
						tooltip="Download"
						Icon={DownloadIcon}
						OnClick={() => { this.DownloadIcon(d.original); }}
						Disabled={d.original.FileName === ""} />
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Created Date",
				accessor: "CreatedDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Requested By",
				accessor: "RequestedBy",
			},
			{
				Header: "Report Name",
				accessor: "ReportName"
			},
			
			{
				Header: "File Name",
				accessor: "FileGuid"
			},
			{
				Header: "Document Type",
				accessor: "DocumentType",
				width: 200
			},
			{
				Header: "Report Type",
				accessor: "ReportType",
			},
			{
				Header: "Begin Date",
				accessor: "BeginDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "End Date",
				accessor: "EndDate",
				type: GridColumnType.DateTimeUtc
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Download Report");
		this.props.setAppCenterTitle("Reports");

		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/Search",
			this.parameterUniqueClientId,
			(responseData) =>{
				if(!responseData.IsSucceeded){
					this.props.showMessage("error", "Error", "Cannot fetch client data");
					return;
				}
				if(!this.isClient){
					this.setState({
						clients: [{Id : 0, Name: "Backoffice", UniqueClientId: 0} ,...responseData.Item]
					});
				}
				else{
					const modelTmp = { ...this.state.model };
					const selectedClient = responseData.Item[0];
					modelTmp.UniqueClientId = selectedClient.Id;
					this.setState({
						clients: responseData.Item,
						model : modelTmp
					});
				}
			},
			(error) =>{
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, selected: -1 });
	}

	HandleSearch = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/ScheduleReportProcess/GetAll",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	DownloadIcon = (model) => {
		window.location.href = Proxy.GetEnvironmentUrl("/coreapi/v1.0/File/DownloadGeneratedFile") + "?Guid=" + model.FileGuid;
	}

	Validate() {
		const { model, vModel } = this.state;

		var messages = [];

		if (typeof model.DateFilter.BeginDate != "object" && (model.DateFilter.BeginDate == "" || model.DateFilter.BeginDate == null)) {
			messages.push("Begin Date is invalid!");
		}

		if (typeof model.DateFilter.EndDate != "object" && (model.DateFilter.EndDate == "" || model.DateFilter.EndDate == null)) {
			messages.push("End Date is invalid!");
		}

		if (model.UniqueClientId == null) {
			messages.push("Client must be selected!");
			vModel.UniqueClientId = true;
		} else {
			vModel.UniqueClientId = false;
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, vModel, list } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericDateFilterInput
												Name="DateFilter"
												LabelText="Date Filter"
												Model={model.DateFilter}
												BeginDate={model.DateFilter.BeginDate}
												EndDate={model.DateFilter.EndDate}
												ValueChanged={this.HandleChange}
												IncludeTime={false}
												Utc />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsStatic
												Name="UniqueClientId"
												LabelText="Client"
												StaticData={this.state.clients}
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												All
												Disabled={this.isClient}
												IsInvalid={vModel.UniqueClientId} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Transaction List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												Columns={this.ColumnsData}
												ShowPagination={true}
												Sorted={this.gridSortParameter} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

ReportDownload.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(ReportDownload, {});