import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { ETransferIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CardComponent from "views/Components/CardComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton.jsx";
import { ProgramCodes, SwitchNetworkType } from "views/Constants/Constant";
import ClientHelper from '../../../core/ClientHelper';
import ClearingTransactionData from "./ClearingTransactionData";


class ManuelProvisionMatching extends Component {
	constructor(props) {
		super(props);

		this.state = {
			detailModel: {},
			model: {},
			list: [],
			MatchingList: [],
			isLoading: false,
			isDialogOpen: false,
			ClearingModel: {}
		}


		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength40 = { maxLength: 40 };

		this.parameterNetworkId = { ParameterCode: "SwitchNetworkType" };
		this.parameterProduct = {};

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};
		this.NetworkType = {};

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Manual Provision Matching");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}
	HandleDeleteMatch = (data, index) => {
		var match = this.state.MatchingList;
		match.splice(index, 1);
		this.setState({ MatchingList: match });
		return this.state;

	}


	IsShow = () => {
		if (this.NetworkType == SwitchNetworkType.Moneris)
			return false;

		return true;
	}


	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		if (name === "TransactionData") {
			if (newValue) {
				var match = [];
				var findindex = match.findIndex(x => x.TransactionDataId == newValue.Id);

				if (findindex == -1) {
					match.push({
						"ClearingId": model.Id,
						"TransactionDataId": newValue.Id,
						"AccountNumber": newValue.AccountNumber,
						"CustomerNumber": newValue.CustomerNumber,
						"ClientName": newValue.ClientName,
						"TransactionCardNumber": newValue.MaskedCardNumber,
						"ClearingDate": model.ClearingDate,
						"Direction": model.Direction,
						"ProcessingCode": model.ProcessingCode,
						"RRN": model.RRN,
						"IssuerAcqueirerFlag": model.IssuerAcqueirerFlag
					});
				}
				else {
					this.props.showMessage("warning", "Multiple Transaction Data",
						"You can only choose one transaction..");
				}
			}
			this.setState({ MatchingList: match });
		}
		if (name == "NetworkId") {
			this.NetworkType = data.ParameterValue;
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: {}, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		if (!this.Validate()) {
			return false;
		}
		const model = { ...this.state.model };
		this.setState({ isLoading: true });

		if (this.NetworkType == SwitchNetworkType.MasterCard)
			this.Url = "/prepaidapi/v1.0/MastercardClearing/GetAllMastercardTransactionByMatching";
		else if (this.NetworkType == SwitchNetworkType.Visa)
			this.Url = "/prepaidapi/v1.0/VisaClearingTransaction/GetAllVisaTransactionByMatching";
		else if (this.NetworkType == SwitchNetworkType.Moneris)
			this.Url = "/prepaidapi/v1.0/MonerisSettlement/GetAllMonerisTransactionByMatching";


		Proxy.POST
			(
				this.Url,
				model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							list:
								responseData.Item.map(x => {
									return {
										CHQ: (
											<div>
												<ToolbarButton
													justIcon simple
													size="sm"
													tooltip="Match"
													onClick={() => this.setState({ isDialogOpen: true, ClearingModel: x })}>
													<img src={ETransferIcon} />
												</ToolbarButton>
											</div>
										),
										IssuerAcqueirerFlag: x.IssuerAcqueirerFlag == null || undefined ? "" : x.IssuerAcqueirerFlag,
										CardNumber: x.CardNumber == null || undefined ? "" : x.CardNumber,
										OrginalAmount: x.OrginalAmount == null || undefined ? "" : x.OrginalAmount,
										SettlementAmount: x.SettlementAmount == null || undefined ? "" : x.SettlementAmount,
										RRN: x.RRN == null || undefined ? "" : x.RRN,
										ProcessingCode: x.ProcessingCode == null || undefined ? "" : x.ProcessingCode,
										Direction: x.Direction == null || undefined ? "" : x.Direction,
										ClearingDate: x.ClearingDate == null || undefined ? 0 : x.ClearingDate,
										ValidationStatus: x.ValidationStatus == null || undefined ? 0 : x.ValidationStatus,
										ARN: x.ARN == null || undefined ? 0 : x.ARN,
										Id: x.Id == null || undefined ? 0 : x.Id

									}
								})
						});
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)
	}


	HandleSubmit = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/ManuelProvisionMatching",
			{ "MatchingList": this.state.MatchingList },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: {}, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.NetworkId) {
			this.props.showMessage("warning", "Network not selected", "Select the network type to continue.");
			return false;
		}
		return true;
	}

	TransactionDataCloseDialog = () => {
		this.setState({ isDialogOpen: false });
		return this.state;
	}
	FillTransactionData = (customer) => {
		this.HandleChange("TransactionData", customer);
	}
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}
	render() {
		const {  Disabled } = this.props;
		const { alert, model, list,  isLoading, index } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<ClearingTransactionData
					onStateChange={this.TransactionDataCloseDialog}
					FillCallback={this.FillTransactionData}
					IsDialogOpen={this.state.isDialogOpen}
					ClearingData={this.state.ClearingModel}
				/>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>

												<GridItem xs={4}>
													{this.IsShow() &&
														<GenericTextInput
															Name="ARN"
															LabelMd={4}
															LabelText="ARN"
															Format={"(@) (@@@@@@) (@@@@) (@@@@@@@@@@@) (@)"}
															inputProps={this.maxLength40}
															Value={model.ARN}
															ValueChanged={this.HandleChange} />
													}
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="NetworkId"
														LabelText="Network Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterNetworkId}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.NetworkId}
														ValueChanged={this.HandleChange} />
												</GridItem>

												<GridItem xs={4}>
													<GenericDateInput
														Name="ClearingDate"
														LabelText="Clearing Date"
														Value={model.ClearingDate}
														ValueChanged={this.HandleChange}
														Disabled={false}
														Utc />
												</GridItem>

												<GridItem xs={4}>
													<GenericTextInput
														Name="RRN"
														LabelMd={4}
														LabelText="RRN"
														inputProps={this.maxLength12}
														Value={model.RRN}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridContainer>

											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Disabled={IsClient}
														DefaultIndex={IsClient ? 0 : -1} />
												</GridItem>
												<GridItem xs={4}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
														Disabled={!model.UniqueClientId}
													// Required
													// IsInvalid={vModel.ClientCustomerId}
													/>
												</GridItem>
												<GridItem xs={4}>
													<CardComponent
														key={"Card_" + [model.UniqueClientId, model.ClientCustomerId]}
														LabelMd={4}
														CardTokenNumber={model.CardTokenNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCard}
														ClientCustomerId={model.ClientCustomerId}
														UniqueClientId={model.UniqueClientId}
														Disabled={!model.ClientCustomerId}
													// Required
													// IsInvalid={vModel.CardId}
													/>
												</GridItem>
											</GridContainer>


											<GridContainer>



											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Clearing List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={[
												{
													Header: "Match",
													accessor: "CHQ",
													sortable: false,
													filterable: false,
													width: 50
												},
												{
													Header: "Issuer Acquirer Flag",
													accessor: "IssuerAcqueirerFlag"
												},
												{
													Header: "Card Number",
													accessor: "CardNumber"
												},
												{
													Header: "Original Amount",
													accessor: "OrginalAmount"
												},
												{
													Header: "Settlement Amount",
													accessor: "SettlementAmount"
												},
												{
													Header: this.IsShow() ? "RRN" : "ReferenceNumber",
													accessor: "RRN"
												},
												{
													Header: "Processing Code",
													accessor: "ProcessingCode"
												},
												{
													Header: "Direction",
													accessor: "Direction"
												},
												{
													Header: "Clearing Date",
													accessor: "ClearingDate"
												},
												{
													Header: "Validation Status",
													accessor: "ValidationStatus"
												},
												{
													Header: "Arn",
													accessor: "ARN",
													show: this.IsShow()

												},
											]}
											SelectedIndex={index}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>


					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ManuelProvisionMatching.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(ManuelProvisionMatching, {});