import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericTitle, GenericExpansionPanel, GenericGrid,  GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { MenuCodes, GridColumnType } from "views/Constants/Constant.js";


function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class CustomerRightsList extends Component {
	constructor(props) {
		super(props);

		this.isBackoffice = ClientHelper.IsBackOffice();

		this.initialModel = {
			Id: 0,
			ClientCustomerId: this.props.ClientCustomerId,
			CustomerName: "",
			UniqueClientId: 0,
			IsClientCustomerPayee: this.props.IsClientCustomerPayee,
			ProgramCode: this.props.ProgramCode
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			list: [],
			isPersonal: null,
			isLoading: false,
			selected: -1,
			alert: null
		};

		this.ColumnsData = [
			{
				Header: "Customer Insert Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Client Name",
				accessor: "UniqueClient",
				width: 160,
				Cell: row => ( <p>{row.value.ClientName}</p>)
			},
			{
				Header: "EFT Program Right",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("EFT") ? "Granted" : ""}</p>)
			},
			{
				Header: "Etransfer Program Right",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("ETRANSFER") ? "Granted" : ""}</p>)
			},
			{
				Header: "Bill Payment Program Right",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("BILL") ? "Granted" : ""}</p>)
			},
			{
				Header: "Wallet Program Right",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("WALLET") ? "Granted" : ""}</p>)
			},
			{
				Header: "DDT Program Right",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("DDT") ? "Granted" : ""}</p>)
			},
			{
				Header: "CDIC Program Rights",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("CDIC") ? "Granted" : ""}</p>)
			},
			{
				Header: "Lender Program Rights",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("LENDER") ? "Granted" : ""}</p>)
			},
			{
				Header: "Card Transfer Program Rights",
				accessor: "Programs",
				Cell: row => ( <p>{row.value.includes("CRDTRANSFER") ? "Granted" : ""}</p>)
			},
			{
				Header: "Money Transfer Program Right",
				accessor: "None" //TODO Developement not completed yet
			},
		];
	}

	componentDidMount() {
		
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Customer Program Rights");
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("CUSTOMER SYSTEM");
		}
	}

	HandleChange = (name, value, data) => {
		var { model, vModel } = this.state;

		model[name] = value;
		this.setState({ model, vModel });
	}

	HandleSearch = () => {
		if (this.state.model.UniqueClientId == 0)
		{
			
			this.ShowMessage("warning", "Error", "A client must be selected!");
			return 
		}
		this.setState({ isLoading: true });
		console.log(this.state.model)
		Proxy.POST("/bankapi/v1.0/ClientCustomer/Search",
			this.state.model,
			responseData => {
				console.log(responseData);
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage(type, title, message, onConfirm, isShowCancel = true) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={isShowCancel} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	render() {
		const { alert, model, vModel, list, isLoading } = this.state;
		let IsCallCenter = this.props.MenuCode === MenuCodes.ClientAccountActivities;

		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}
				
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: () => this.HandleSearch(), Disabled: false }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										{(IsCallCenter || this.isBackoffice) &&
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.UniqueClientId}
												CanClear />
										</GridItem>
										}
										<GridItem xs={4}>
											<GenericTextInput
												Name="CustomerName"
												LabelText="Customer Name"
												Value={model.CustomerName}
												ValueChanged={this.HandleChange}
												IsInvalid={vModel.CustomerName} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<br />
						<GenericGrid 
							Data={list}
							Columns={this.ColumnsData}
							SelectedIndex={this.state.selected}
							PageSize={5}
							ShowPagination={true}
							HideButton={true} />
						<br />
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

CustomerRightsList.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CustomerRightsList, {});
