import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class OutgoingCreateBulkFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: "",
				CheckAllTransactions: false,
				eTransferTypeId: 0
			},
			vModel: {},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			checkedValues: [],
			isLoading: false,
			SettlementType: ""
		};
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Interac e-Transfer / Create Bulk File");
		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Outgoing;

		this.setState({ getTransactionByFileIdModel, model });

	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		var transactionList = [...this.state.transactionList];

		var { SettlementType } = this.state;
		if (name == "eTransferTypeId")
			SettlementType = data.ParameterDesc;
		model[name] = newValue;

		if (name == "CheckAllTransactions" && newValue == true) {
			const list = this.state.transactionList;
			if (list.length > 0) {
				this.setState({ checkedValues: list, model: model });
			} else {
				model[name] = false;
			}
		} else if (name == "CheckAllTransactions" && newValue == false) {
			this.setState({ checkedValues: [], model: model });
		}

		if (name == "eTransferTypeId")
			transactionList = [];

		this.setState({ model: model, SettlementType, transactionList });
	}

	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}
	IsValid = () => {
		var { model, vModel } = this.state;
		if (typeof model.FromDate != "object" && model.FromDate != "" && model.FromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}
		if (typeof model.ToDate != "object" && model.ToDate != "" && model.ToDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}
		if ((typeof model.FromDate == "object" && typeof model.ToDate == "object") && model.ToDate < model.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}
		if (model.eTransferTypeId == null || model.eTransferTypeId <= 0) {
			this.props.showMessage("warning", "Please fill required fields!", "Settlement Type cannot be null !");
			vModel.eTransferTypeId = true;
			this.setState({ vModel });
			return false;
		}
		return true;
	}
	GetTransactionList = () => {
		if (!this.IsValid())
			return;
		this.showLoading();
		const model = { ...this.state.model };
		model["CheckAllTransactions"] = false;
		this.setState({ checkedValues: [], model });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GeteTransferBulkFileTransactions",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				if (responseData.Item == null && responseData.Item.length == 0) {
					this.showBasicAlert("Info", "No items for selected search filters !!!", "warning");

					this.setState({
						transactionList: [], headerModel: {}, isLoading: false
					});
				}
				else {
					this.setState({ transactionList: responseData.Item, isLoading: false });
				}
			},
			this.errorCallback
		);
	}

	CreateFile = () => {
		if (this.state.checkedValues == null || this.state.checkedValues.length == 0)
			this.showBasicAlert("Warning", "Transaction List cannot be empty in creation process !!!", "warning");
		else {
			this.showLoading();
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/CreateETransferBulkFile",
				this.state.checkedValues,
				this.successCreateFile,
				error => {
					this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
				}
			);
		}
	}
	successCreateFile = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData != null) {
			if (responseData.Item.length > 0) {

				this.showBasicAlert("Success", "The File created successfully Success Count :" + responseData.Item.length, "success");
				this.GetTransactionList();
			}
			else {
				this.showBasicAlert("Warning", this.state.SettlementType + "=> Create File  not have any transaction !!!", "warning");
			}
		}
	}

	errorCallback = (error) => {
		this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
	}

	showBasicAlert = (title, message, type) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }
	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			transactionList: [],
			checkedValues: []
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, transactionList, checkedValues, vModel } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.GetTransactionList,
						Disabled: Disabled
					},
					{
						Code: "CreateFile",
						OnClick: this.CreateFile,
						Disabled: checkedValues.length == 0 ? true : false
					},
					{
						Code: "Clear",
						OnClick: this.ClearModel,
						Disabled: Disabled
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card >
					<CardHeader>
						<h4><b>Transaction Filter</b></h4>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									Name="ClientId"
									LabelText="Client"
									Method="POST"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Parameter={{}}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
									Value={model.ClientId || ""}
									ValueChanged={this.handleChange}
									CanClear />
								<GenericSelectInput
									Name="eTransferTypeId"
									LabelText="Settlement Type"
									Value={model == null || undefined ? "" : model.eTransferTypeId || ""}
									DataRoot="Item"
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Url="/coreapi/v1.0/Parameter/Search"
									Method="POST"
									Parameter={{
										ParameterCode: "EtransferType",
										"ParameterValue2": "ETRANSFER"
									}}
									Required
									IsInvalid={vModel.eTransferTypeId} />
							</GridItem>
							<GridItem xs={4}>
								<GenericDateInput
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate == null || undefined ? "" : model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false} 
									Utc />
								<GenericDateInput
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate == null || undefined ? "" : model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false}
									Utc />

							</GridItem>
						</GridContainer>
						<br />
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<h5><b>Transaction List</b></h5> <br />
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={transactionList.map(d => {
								return {
									Actions: (
										<div>
											<GenericCheckInput
												Grid={true}
												Value={checkedValues.filter(c => c.TransactionId == d.TransactionId).length > 0}
												ValueChanged={(e) => {
													this.HandleCheck(e, d);
												}}

											/>
										</div>
									),
									ReceiveDate: d.ReceiveDate,
									TransactionId: d.TransactionId,
									TransactionETransferId: d.TransactionETransferId,
									UniqueClientId: d.UniqueClientId,
									ClientName: d.ClientName,
									ClientNumber: d.ClientNumber,
									TransactionCode: d.TransactionCode,
									TransactionStatus: d.TransactionStatus,
									TransactionSource: d.TransactionSource,
									NotificationStatus: d.NotificationStatus,
									TransactionType: d.TransactionType,
									TransactionDate: d.TransactionDate,
									PriorityType: d.PriorityType,
									Amount: d.Amount,
									PayeeName: d.PayeeName,
									PayeeEmail: d.PayeeEmail,
									SourceAccountTransit: d.SourceAccountTransit,
									SourceAccountNumber: d.SourceAccountNumber

								};
							})}
							Columns={[
								{
									Header: "Actions",
									accessor: "Actions",
									sortable: false,
									filterable: false,
									width: 60,
									Footer: (
										<GenericCheckInput Grid={true} Name="CheckAllTransactions" LabelText="" Value={model.CheckAllTransactions || false} ValueChanged={this.handleChange} />
									)
								},
								{
									Header: "Transaction Date",
									accessor: "TransactionDate",
									type: GridColumnType.DateUtc
								},
								{
									Header: "Unique Client Number",
									accessor: "ClientNumber",
									Footer: (
										<span>
											<strong>All Transactions</strong>{" "}
										</span>
									)
								},
								{
									Header: "Client Name",
									accessor: "ClientName"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType",
									Cell: row => (
										<div>{row.value == "Credit" ? "Money Send" : "Money Request"}</div>
									)
								},
								{
									Header: "Priority Type",
									accessor: "PriorityType"
								},
								{
									Header: "Pay. Name",
									accessor: "PayeeName"
								},
								{
									Header: "Pay. Email",
									accessor: "PayeeEmail"
								},
								{
									Header: "Source Wallet Number",
									accessor: "SourceAccountNumber"
								},

								{
									Header: "Amount",
									accessor: "Amount",
									type: GridColumnType.Money,
									ColumnType: GridColumnType.Money
								},
								{
									Header: "Transaction Status",
									accessor: "TransactionStatus"
								},
								{
									Header: "Transaction Source",
									accessor: "TransactionSource"
								},
								{
									Header: "Notification Status",
									accessor: "NotificationStatus"
								}
							]}
							ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
					</CardBody>
				</Card >

			</div >
		);
	}
}

OutgoingCreateBulkFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(OutgoingCreateBulkFile, {});