import { primaryColor, cardBoxShadow, drawerWidth } from "assets/jss/material-dashboard-pro-react.jsx";

const isLeftMenu = window.MenuBar === "left";

const common = {
	backgroundColor: "white",
	height: 48,
	zIndex: 3
};

const dynamicToolBarStyle = theme => ({
	main: {
		...cardBoxShadow,
		backgroundColor: "#e4e4e4",
		fontWeight: 700,
		borderRadius: "6px",
		marginTop: isLeftMenu ? "18px" : "8px",
		marginBottom: "5px",
		marginRight: "2px",
		zIndex: 10,
		paddingBottom: 5
	},
	normal: {
		...common,
		left: 23,
		right: 41
	},
	fixed: {
		...common,
		position: "fixed",
		width: `calc(100% - ${drawerWidth + 95}px - ${isLeftMenu ? "30vh" : "0px"})`,
		[theme.breakpoints.down("md")]: {
			position: "absolute",
			width: "1340px"
		}
	},
	paddingBottom: {
		paddingBottom: 43
	},
	icon: {
		backgroundColor: primaryColor,
		color: "white",
		borderRadius: "6px"
	},
	appbaritemright: {
		background: "#fcfcfc",
		color: "#2e333a",
		fontSize: "12px",
		lineHeight: "1.428571429",
		fontWeight: 400,
		borderColor: "#707070",
		textAlign: "left",
		marginTop: "10px",
		float: "right",
		paddingLeft: "15px",
		paddingRight: "15px"
	},
	appbaritemleft: {
		fontSize: "12px",
		textAlign: "unset",
		marginTop: "10px",
		paddingLeft: "10px !important",
		fontWeight: 400,
	},
	appbar: {
		paddingBottom: 5,
		height: "auto",
		color: "#2e333a",
		fontSize: "0.8em",
		lineHeight: "1.428571429",
		fontWeight: "400",
		textAlign: "left",
		...cardBoxShadow,
		backgroundColor: "#e4e4e4",
		borderRadius: "6px",
		marginTop: "5px",
		marginLeft: "2px",
		marginRight: "2px"
	}
});

export default dynamicToolBarStyle;
