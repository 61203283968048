import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { TaskPool } from "core/TaskPool";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericGrid, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class PaymentAdminApplicationNoteHistory extends Component {
	constructor(props) {
		super(props);

		this.loadTaskPool = new TaskPool(this.props.handleLoadTaskPoolAppend, this.props.handleLoadTaskPoolCompleted);

		this.initialModel = {
			Id: 0,
			ApplicationId: this.props.ApplicationModel.Id,
			Note: null
		};

		this.state = {
			list: [],
			model: { ...this.initialModel },
			vModel: {},
			rowIndex: -1,
			isLoading: false,
			alert: null,
		};

		this.emptyObject = {};

		this.ColumnsData = [
			{
				Header: "Insert User",
				accessor: "InsertUser",
				width: 150
			},
			{
				Header: "Update User",
				accessor: "UpdateUser",
				width: 150
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTimeUtc,
				width: 150
			},
			{
				Header: "Update Date Time",
				accessor: "UpdateDateTime",
				type: GridColumnType.DateTimeUtc,
				width: 150
			},
			{
				Header: "Note",
				accessor: "Note"
			}
		];
	}

	componentDidMount() {
		if (this.state.model.ApplicationId > 0)
			this.HandleSearch();
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			isEdit: false,
			rowIndex: -1
		});
	};

	HandleSearch = () => {
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/lmsapi/v1.0/ApplicationNoteHistory/GetByApplicationId/Id?Id=" + this.state.model.ApplicationId,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({ list: responseData.Item });
					}
				},
				error => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			));
	}

	HandleSubmit = () => {
		const { model } = this.state;
		if (!this.Validate())
			return;

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/lmsapi/v1.0/ApplicationNoteHistory/InsertOrUpdate",
				model,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.props.showMessage("success", "Success", "Operation Successfully Completed");
					this.HandleClear();
					this.HandleSearch();
				},
				error => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			));
	}

	HandleDelete = () => {
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/lmsapi/v1.0/ApplicationNoteHistory/Delete/Id?Id=" + this.state.model.Id,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.props.showMessage("success", "Success", "Operation Successfully Completed");
				},
				error => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			));
	}

	Validate = () => {
		const { model, vModel } = this.state;

		if (!model.Note) {
			this.props.showMessage("warning", "Warning", "Enter note to continue.");
			vModel.Note = true;
			return false;
		} else {
			vModel.Note = false;
		}
		return true;
	}

	RowSelected = index => {
		var model = this.state.list[index];

		var now = moment(new Date().getTime());
		var changedDate = moment.utc(model.UpdateDateTime ? model.UpdateDateTime : model.InsertDateTime);
		var duration = moment.duration(now.diff(changedDate));
		var minutes = duration.asMinutes();
		if (minutes <= 15)
			this.setState({ isEdit: true, model, rowIndex: index });
		else
			this.HandleClear();
	};

	render() {
		const { Disabled } = this.props;
		const { alert, model, vModel, isLoading, list } = this.state;

		return (
			<>
				<GridContainer spacing={16}>

					{alert}

					<LoadingComponent Show={isLoading} />

					<GridItem xs={12}>
						<GridContainer>
							<GridItem xs={12}>
								<GenericTextInput
									Name="Note"
									LabelText="Note"
									Value={model.Note}
									ValueChanged={this.HandleChange}
									MultiLine={true}
									RowCount={3}
									Required={true}
									IsInvalid={vModel.Note}
									Disabled={Disabled} />
							</GridItem>
						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem style={{ float: "right" }}>
								<Button disabled={Disabled} size="sm" color="black" onClick={this.HandleSubmit}>{model.Id > 0 ? "Edit" : "Add"}</Button>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<GenericGrid
									Data={list}
									Columns={this.ColumnsData}
									RowSelected={this.RowSelected}
									HideButton={true} />
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer >
			</>
		);
	}
}

PaymentAdminApplicationNoteHistory.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(PaymentAdminApplicationNoteHistory, {});