import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import DownloadButton from "views/Components/DownloadButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";

class UserGuideView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("Support");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Downloads");
		}

		this.List();
	}

	Clicked = (index) => {
		const { result } = this.state;
		const { ExecuteApiFileDownloadWithGenericResponse } = this.props;

		ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/UserGuide/Download", { Id: result[index].Id, FileId: result[index].FileId }, result[index].Header, result[index].FileExtension);
	}

	List = async () => {
		const { ExecuteApiPost } = this.props;

		var result = await ExecuteApiPost("/coreapi/v1.0/UserGuide/List", {});

		this.setState({ isLoading: true });

		var list = [];

		if (result != null) {
			for (var i = 0; i < result.length; i += 3) {
				list.push(
					<GridContainer alignItems="stretch" justify="space-evenly" key={i}>
						<GridItem xs={3} />
						<GridItem xs={2} >
							<DownloadButton
								name={i}
								title={result[i].Header}
								onClick={this.Clicked} />
						</GridItem>
						{
							i + 1 < result.length
								?
								(
									<GridItem xs={2}>
										<DownloadButton
											name={i + 1}
											title={result[i + 1].Header}
											onClick={this.Clicked} />
									</GridItem>
								)
								:
								(
									<GridItem xs={2} />
								)
						}
						{
							i + 2 < result.length
								?
								(
									<GridItem xs={2} >
										<DownloadButton
											name={i + 2}
											title={result[i + 2].Header}
											onClick={this.Clicked} />
									</GridItem>
								)
								:
								(
									<GridItem xs={2} />
								)
						}
						<GridItem xs={3} />
					</GridContainer>
				);
			}
		}

		this.setState({ result: result || [], list, isLoading: false });
	}

	render() {
		const { list, isLoading } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								{
									list
								}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

UserGuideView.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func
};

export default UserGuideView;