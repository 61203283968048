import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericGrid, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class KycManualCreditValidation extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Type",
				accessor: "Type.ParameterDesc"
			},
			{
				Header: "Count",
				accessor: "Count"
			},
			{
				Header: "High Cred",
				accessor: "HighCred"
			},
			{
				Header: "Cred Limit",
				accessor: "CredLimit"
			},
			{
				Header: "Balance",
				accessor: "Balance"
			},
			{
				Header: "Past Due",
				accessor: "PastDue"
			},
			{
				Header: "Payment",
				accessor: "Payment"
			},
			{
				Header: "Available",
				accessor: "Available"
			},
		];

		this.sortedType = [{ id: "Type.ParameterValue", desc: false }];
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.state = {
			isLoading: false

		};
	}

	componentDidMount() {

	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	ShowMessage = (type, title, message) => {
		type = "info";
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	HandleInguiryAction(value) {
		let data = { Id: value };
		this.setState({ isLoading: true });

		
		Proxy.POST(
			"/amlkycapi/v1.0/KycManualApplication/CheckTransCreditHistory",
			data,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.GetCreditHistory(data.Id, responseData.Item);

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	render() {
		var { model, Id } = this.props;
		var { isLoading } = this.state;


		return (
			<div>
				<LoadingComponent Show={isLoading} TaskPoolDomain={this.loadTaskPool} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<GridContainer >
							<GridItem xs={4}>
								<GenericTextInput
									Disabled
									LabelText="Bankruptcies"
									Value={model.Bankruptcies} />
								<GenericTextInput
									Disabled
									LabelText="Banking Closed For Cause"
									Value={model.BankingClosedForCause} />
								<GenericTextInput
									Disabled
									LabelText="Collection Inquiries"
									Value={model.CollectionInquiries} />
								<GenericTextInput
									Disabled
									LabelText="Trade Last Opened"
									Value={model.TradeLastOpened} />
								<ParameterComponent
									Disabled
									LabelText="Id Mismatch Alert"
									ParameterCode="LMSIdMismatchAlert"
									Value={model.IdMismatchAlertId} />
								<GenericTextInput
									Disabled
									LabelText="Factors"
									Value={model.Factors} />
							</GridItem>
							<GridItem xs={4}>
								<GenericTextInput
									Disabled
									LabelText="Legal Items"
									Value={model.LegalItems} />
								<GenericTextInput
									Disabled
									LabelText="Registered Items"
									Value={model.RegisteredItems} />
								<GenericTextInput
									Disabled
									LabelText="Inquiries Within Six Months"
									Value={model.InquiriesWithinSixMonths} />
								<GenericTextInput
									Disabled
									LabelText="Current Negative Trade"
									Value={model.CurrentNegativeTrade} />
								<ParameterComponent
									Disabled
									LabelText="High Risk Fraud Alert"
									ParameterCode="LMSHighRiskFraudAlert"
									Value={model.HighRiskFraudAlertId} />
							</GridItem>
							<GridItem xs={4}>
								<GenericTextInput
									Disabled
									LabelText="Collections"
									Value={model.Collections} />
								<GenericTextInput
									Disabled
									LabelText="Inquiries"
									Value={model.Inquiries} />
								<GenericTextInput
									Disabled
									LabelText="Trade First Opened"
									Value={model.TradeFirstOpened} />
								<GenericTextInput
									Disabled
									LabelText="Paid Trade"
									Value={model.PaidTrade} />
								<GenericTextInput
									Disabled
									LabelText="Credit Vision Risk Score"
									Value={model.CreditVisionRiskScore} />
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} style={{ mt: 15 }}>
						<br />
						{Id > 0 &&
							<GridContainer direction="row" justify="center" alignItems="flex-start">
								<GridItem xs={6}></GridItem>
								<GridItem xs={6}>
									<GridContainer justify="flex-end">
										<GridItem xs={3}>
											<Button size="sm" onClick={() => { this.HandleInguiryAction(Id); }} disabled={this.props.Disabled} fullWidth>Credit History Check</Button>
										</GridItem>

									</GridContainer>
								</GridItem>
							</GridContainer>
						}
						<GenericGrid
							HideButton
							Data={model.CreditHistoryDetails || []}
							Columns={this.columns}
							Sorted={this.sortedType}
							RowSelected={index => {
								this.SelectedRowChange(0, index);
							}}
						/>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

KycManualCreditValidation.propTypes = {
	model: PropTypes.object,
	Id: PropTypes.number,
	GetCreditHistory: PropTypes.func
};

export default KycManualCreditValidation;