import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import { ApproveIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { SwitchNetworkType, TransactionType } from "views/Constants/Constant.js";

class ClearingTransactionData extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			Customer: null,
			DefaultAccount: null,
			DefaultCard: null,
			ValidFrom: null,
			ValidTo: null
		};

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={ApproveIcon}
							tooltip="Select Transaction Data"
							OnClick={() => { this.HandleSelectModel(row.original, row.index); }}/>
						
					</div>
				)
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber",
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
			},

			{
				Header: "Currency",
				accessor: "CurrencyCode",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == TransactionType.Debit ? 600 : "normal" }}>{row.value}</p>
				)
			},

			{
				Header: "Wallet Name",
				accessor: "AccountName",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == TransactionType.Debit ? 600 : "normal" }}>{row.value}</p>
				)
			},

			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Billing Amount",
				accessor: "BillingAmount"
			},

		];

		this.state = {
			list: [],
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			}
		};
	}

	componentDidMount() {
	}


	HandleSelectModel = (model, index) => {
		var clearingData = this.props.ClearingData;
		this.setState({ selected: index, isLoading: true });

		const request = { TransactionDataId: model.Id, ClearingId: clearingData.Id, NetworkValue: clearingData.NetworkValue };

		if (clearingData.NetworkValue == SwitchNetworkType.MasterCard)
			this.Url = "/prepaidapi/v1.0/MastercardClearing/ManuelProvisionMatching";
		else if (clearingData.NetworkValue == SwitchNetworkType.Visa)
			this.Url = "/prepaidapi/v1.0/VisaClearingTransaction/ManuelProvisionMatching"

		Proxy.POST(
			this.Url,
			request,
			responseData => {
				if (responseData.IsSucceded) {
					this.ShowMessage("success", "Matching Transaction successfully done.");
					this.setState({ isLoading: false });
					this.DialogClose();
				} else {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
				}
			});
	};


	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.DefaultAccount = newValue.Accounts[0];
				}
				const request = { CardLevelCriteria: 1, CustomerId: newValue.Id };
				Proxy.ExecuteGeneral(
					this, "POST",
					"/prepaidapi/v1.0/Card/GetListByCustomerNo",
					request,
					responseData => {
						if (responseData.Item != null && responseData.Item[0] != null) {
							model.DefaultCard = responseData.Item[0];
						} else {
							model.DefaultCard = null;
						}
						this.setState({ model: model });
					}
				);
			} else {
				model.Customer = initialModel.Customer;
				model.DefaultAccount = initialModel.DefaultAccount;
				model.DefaultCard = initialModel.DefaultCard;
			}
		} else if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		}
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};

	addDays = (date, days) => {
		var returnDate = new Date();
		returnDate.setDate(date.getDate() + days);
		return returnDate;
	};

	HandleSearch = () => {
		const model = { ...this.state.model };
		const cardData = { ...this.props.ClearingData };

		const request = {
			FromDate: model.ValidFrom,
			ToDate: model.ValidTo,
			ClientCustomerId: cardData.ClientCustomerId,
			CardId: cardData.Id
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionData/SearchClearingTransactionSummary",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};

	DialogClose = () => {
		this.props.onStateChange();
	}
	render() {
		const { ClearingData } = this.props;
		const { alert, model, list, isLoading } = this.state;
	

		return (
			<div>


				<LoadingComponent Show={isLoading} />


				{alert}

				<GenericDialog open={this.props.IsDialogOpen} maxWidth="lg" fullWidth keepMounted={true}>
					<DialogTitle><GenericLabel FontSize="16px" Text="Transaction Data" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<Card>
									<CardBody>
										<GenericExpansionPanel>
											<GridContainer>
												<GridItem xs={4}>
													<GridItem>
														<GenericTextInput
															Name="CardNumber"
															IsText
															LabelText="Card Number"
															Value={ClearingData.MaskedCardNumber}
															ValueChanged={this.handleChange}
															Disabled={true}
														/>
													</GridItem>

												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name={"ValidFrom"}
														LabelText="Valid From"
														Value={Formatter.FormatDate(model.ValidFrom)}
														ValueChanged={this.handleChange}
													/>
												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name={"ValidTo"}
														LabelText="Valid To"
														Value={Formatter.FormatDate(model.ValidTo)}
														ValueChanged={this.handleChange}
													/>
												</GridItem>
											</GridContainer>
										</GenericExpansionPanel>
									</CardBody>
								</Card>
								<Card className="no-radius">
									<CardBody>
										<GridContainer>
											<GridItem xs={12}>
												<GridContainer spacing={16}>
													<GridItem xs={12}>
														<GenericGrid Data={list} Columns={this.columns} ShowPagination={true} PageSize={20} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSearch}>Search</Button>
						<Button size="sm" onClick={this.DialogClose}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

ClearingTransactionData.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default ClearingTransactionData;
