// ##############################
// // // LoginPage view styles
// #############################

import genericRadioInputStyle from "assets/jss/generic/genericRadioInputStyle.jsx";
import { container, defaultFont, primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const loginPageStyle = theme => ({
	container: {
		...container,
		zIndex: "4",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "100px"
		},
	},
	defaultFont: {
		root: {
			color: primaryColor + " !important"
		},
		...defaultFont,
		color: primaryColor + " !important",
	},
	cardTitle: {
		color: "#000000"
	},

	textCenter: {
		textAlign: "center"
	},

	justifyContentCenter: {
		justifyContent: "center !important"
	},
	customButtonClass: {
		"&,&:focus,&:hover": {
			color: "#FFFFFF"
		},
		marginLeft: "5px",
		marginRight: "5px"
	},
	inputAdornment: {
		marginRight: "18px"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	cardHidden: {
		opacity: "0",
		transform: "translate3d(0, -60px, 0)"
	},
	cardHeader: {
		marginBottom: "20px"
	},
	socialLine: {
		padding: "0.9375rem 0"
	},
	...sweetAlertStyle,
	...genericRadioInputStyle
});

export default loginPageStyle;
