import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, AddressType, ClientType, MenuCodes } from "views/Constants/Constant.js";
import CanadaPostAddressComponent from "views/Components/CanadaPostAddressComponent";

class Address extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addressModel: {},
			storeModel: {},
			isLoading: false,
			alert: null,
			CustomDisabled: null,
			vModel: {}
		};


		this.ParameterAddressType = { ParameterCode: "AddressType" };
		this.ParameterAddressTypeIndv = { ParameterCode: "AddressType", ParameterValue3: "P" };
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ addressModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Address Type",
				accessor: "AddressType.ParameterDesc"
			},
			{
				Header: "Location",
				accessor: "Location"
			},
			{
				Header: "Store Name",
				accessor: "Store.StoreName",
				show: props.paramGlobalModel.ClientType == ClientType.Corporate || props.paramGlobalModel.ClientType == ClientType.SalesAgentCorporate
			},
			{
				Header: "Store Code",
				accessor: "Store.StoreCode",
				show: props.paramGlobalModel.ClientType == ClientType.Corporate || props.paramGlobalModel.ClientType == ClientType.SalesAgentCorporate
			},
			{
				Header: "Address Line 1",
				accessor: "Address1"
			},
			{
				Header: "Address Line 2",
				accessor: "Address2"
			},
			{
				Header: "Country",
				accessor: "Country.Name"
			},
			{
				Header: "Province",
				accessor: "Province.Name"
			},
			{
				Header: "City",
				accessor: "City.Name"
			},
			{
				Header: "Postal Code",
				accessor: "ZipCode"
			}
		];

		this.lengthAddress = { maxLength: 280 };
		this.lengthZipCode = { maxLength: 40 };
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Address");

		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a client or create new one to add address.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ addressModel: model, storeModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedAddress, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}

	OperationCheck = () => {

		var ErrorList = [];
		const vModel = this.state.vModel;
		const data = this.state.addressModel;
		const store = this.state.storeModel;
		const global = { ...this.props.paramGlobalModel };
		if (global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add Address.");
		}

		if (global.ClientType == "" || global.ClientType == null) {
			ErrorList.push("You must select the client type to add Address.");
		}

		if (data == null) {
			return;
		}
		if ((store.StoreCode != null) && (store.StoreName == null || store.StoreName == "")) {
			vModel.StoreName = true;
			ErrorList.push("Store Name must be defined !.");
		} else { vModel.StoreName = false; }
		if (data.AddressTypeId == null || data.AddressTypeId == "") {
			vModel.AddressTypeId = true;
			ErrorList.push("Address type must be defined !.");
		} else { vModel.AddressTypeId = false; }

		if (data.Address1 == null || data.Address1 == "") {
			vModel.Address1 = true;
			ErrorList.push("Address1 must be defined !.");
		} else { vModel.Address1 = false; }

		if (data.ZipCode == null || data.ZipCode == "") {
			vModel.ZipCode = true;
			ErrorList.push("Zip Code must be defined !.");
		} else { vModel.ZipCode = false; }

		if (data.Location == null || data.Location == "") {
			vModel.Location = true;
			ErrorList.push("Location must be defined !.");
		} else { vModel.Location = false; }

		if (data.CountryId == null || data.CountryId == "") {
			vModel.CountryId = true;
			ErrorList.push("Country must be defined !.");
		} else { vModel.CountryId = false; }

		if (data.ProvinceId == null || data.ProvinceId == "") {
			vModel.ProvinceId = true;
			ErrorList.push("Province must be defined !.");
		} else { vModel.ProvinceId = false; }

		if (data.CityId == null || data.CityId == "") {
			vModel.CityId = true;
			ErrorList.push("City must be defined !.");
		} else { vModel.CityId = false; }
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	UpdateModel = () => {
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		var temp = this.state.addressModel;
		temp.Store = this.state.storeModel;
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerAddress/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item.InteracErrorList != null && responseData.Item.InteracErrorList.length > 0) {
					var operationResult = temp.Id > 0 ? "Client Profile Updated !" : "Client Profile Saved ! ";
					this.ShowMessageNode("warning", operationResult + "But Interac Update Integration Error", responseData.Item.InteracErrorList);
				}
				else
					this.ShowMessage("success", "Success", temp.Id > 0 ? "Client Address Updated Successfully !" : "Client Address Saved Successfully ! ");

				this.ClearData();
				this.props.GetCustomerAddressModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Client Address Must Be Selected For The Delete Operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerAddress/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Client Address Deleted Successfully!");
				this.ClearData();
				this.props.GetCustomerAddressModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerAddressList[index];
		this.setState({ addressModel: model || {}, storeModel: model == null ? {} : model.Store || {} });
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ addressModel: model, storeModel: model, vModel: {} });
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.addressModel || {};
			if (name == "AddressTypeId" && data != null) {
				var store = { ...this.state.storeModel };
				if (data.ParameterValue == AddressType.StoreAdress) {
					store.StoreCode = "0";
				} else {
					store.StoreCode = null;
				}
				this.setState({ storeModel: store });
			}
			if (name == "CountryId") {
				model.ProvinceId = 0;
				model.CityId = 0;
			}
			if (name == "ProvinceId") {
				model.CityId = 0;
			}

			model[name] = newValue;
			return { addressModel: model };
		});
	}

	handleChangeStore = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.storeModel || {};
			model[name] = newValue;
			return { storeModel: model };
		});
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { Disabled: DisabledProp, paramGlobalModel, paramBankCustomerAddressList } = this.props;
		const { addressModel, storeModel, vModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						{(this.props.paramBankCustomerAddressList != null && this.props.paramBankCustomerAddressList.filter(x => x.AddressType != null && x.AddressType.ParameterValue == AddressType.MainAddress).length == 0) && (
							<GenericLabel Text="Define Main Address to Proceed" />
						)}
						<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeChildTab(null, 0)} > Back </Button>
						<Button size="sm" color="black" disabled={Disabled
							|| this.props.paramBankCustomerAddressList.length < 1 ||
							(this.props.paramBankCustomerAddressList != null && this.props.paramBankCustomerAddressList.filter(x => x.AddressType != null && x.AddressType.ParameterValue == AddressType.MainAddress).length == 0)} onClick={() => {
								this.props.handleChangeParentTab(null, 1);
							}} > Next </Button>
					</GridItem>
				</GridContainer>

				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={8}>
								<h5><b> Address </b></h5>
							</GridItem>
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer> 
							<GridItem xs={4}>
								{(paramGlobalModel.ClientType == ClientType.Corporate || paramGlobalModel.ClientType == ClientType.SalesAgentCorporate) && (
									<GenericSelectInput
										Name="AddressTypeId"
										LabelText="Address Type"
										Value={addressModel == null ? "" : addressModel.AddressTypeId || ""}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterAddressType}
										Disabled={Disabled || ( addressModel.Id > 0 && storeModel.StoreName)}
										DataRoot="Item"
										Required
										IsInvalid={vModel.AddressTypeId}
									/>
								)}
								{(paramGlobalModel.ClientType == ClientType.Personal || paramGlobalModel.ClientType == ClientType.SalesAgentPersonal) && (
									<GenericSelectInput
										Name="AddressTypeId"
										LabelText="Address Type"
										Value={addressModel == null ? "" : addressModel.AddressTypeId || ""}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterAddressTypeIndv}
										Disabled={Disabled}
										DataRoot="Item"
										Required
										IsInvalid={vModel.AddressTypeId}
									/>
								)}
								{(paramGlobalModel.ClientType == ClientType.Corporate || paramGlobalModel.ClientType == ClientType.SalesAgentCorporate) && (storeModel != null && storeModel.StoreCode != null) && (
									<div>
										<GenericTextInput
											Disabled={Disabled}
											Name="StoreName"
											LabelText="Store Name"
											Value={storeModel.StoreName || ""}
											ValueChanged={this.handleChangeStore}
											Required
											IsInvalid={vModel.StoreName}
										/>
										<GenericTextInput
											Disabled={true}
											Name="StoreCode"
											LabelText="Store Code"
											Value={storeModel.StoreCode || ""}
											ValueChanged={this.handleChangeStore}
										/>
									</div>
								)}
								<GenericTextInput
									Disabled={Disabled}
									Name="Location"
									LabelText="Location"
									Id="label"
									Value={addressModel == null ? "" : addressModel.Location || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.Location}
								/>
							</GridItem>


							<CanadaPostAddressComponent
								vModel={this.state.vModel}
								GridXs={4}
								GridCount={2}
								addressModel={this.state.addressModel}
								Disabled={Disabled}
								handleChange={this.handleChange}
							/>


						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem>
								<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
								<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{addressModel.Id > 0 ? "Update" : "Add"}</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={paramBankCustomerAddressList}
									PageSize={5}
									HideButton={true}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}
Address.propTypes = {
	classes: PropTypes.object,
	addressModel: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramBankCustomerAddressList: PropTypes.array,
	GetCustomerAddressModels: PropTypes.func,
	paramBankCustomerStoreList: PropTypes.array,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func
};
export default withArtifex(Address, {});