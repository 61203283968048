import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ChequeOrderDataTable from "./ChequeOrderDataTable";

const styles = ({
	...sweetAlertStyle
});
class ChequeOrder extends React.Component {
	constructor(props) {
		super(props);

		var user = ClientHelper.GetUser();

		this.initialModel = {
			ClientTypeId: (user.UniqueClient && user.UniqueClient.ClientTypeId) || null,
			isBackOffice: true
		};

		this.state = {
			model: this.initialModel,
			list: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
		};

		this.HandleCheck = this.HandleCheck.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Cheque Orders");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");


		var model = { ...this.state.model };

		model.isBackOffice = ClientHelper.IsBackOffice();
		if (!model.isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
			model.Name = model.UniqueClientId;
		}
		this.setState({ model });
	}

	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;

		this.setState({ model });
	}
	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}


	Submit = () => {
		// "/chequeapi/v1.0/Cheque/ChequeOrderAll", ////bu printer icin
		this.setState({ isLoading: true, alert: null })
		Proxy.POST(
			"/chequeapi/v1.0/Cheque/ChequeOrderTransActionUpdate",
			this.state.checkedValues,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var listItems = [];
				if (responseData.Item != null) {
					listItems = responseData.Item.map((number) =>

						<div key={number.Id}>
							{number.ApprovedDate == null ?
								number.UniqueClientId + "==>" + number.Name + "==>" + Formatter.FormatDateUtc(number.ChequeOrderDate) + "==>Error"
								:
								number.UniqueClientId + "==>" + number.Name + "==>" + Formatter.FormatDateUtc(number.ChequeOrderDate) + "==>Succeses"
							}
						</div>
					);

				}

				this.ShowMessage("warning", "Cheque Approved List Successfully ", "");
				this.Clear();
				this.Search();

				// this.ShowMessage("success", "Operation is successfully!",responseData.ErrorDescription);
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}



	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}


	Search = () => {

		this.setState({ isLoading: true ,checkedValues: []});

		const { model } = this.state;
		if (!model.isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}

		Proxy.POST(
			"/chequeapi/v1.0/Cheque/SearchChequeOrderList",
			this.state.model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ list: responseData.Item.ChequeOrderList || [], isLoading: false, totalresult: responseData.Item.ChequeDetailInfo });
				}


			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Clear = () => {
		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;
 
		this.setState({ model: this.initialModel, list: [], selected: null, checkedValues: [], totalresult: {} });
	}
	Validate() {
		const { model, list } = this.state;

		if (!model.Code) {
			this.ShowMessage("warning", "Action code not entered", "Enter action code to continue.");
			return false;
		}

		if (!model.Description) {
			this.ShowMessage("warning", "Action description not entered", "Enter action description to continue.");
			return false;
		}

		if (!model.Order) {
			this.ShowMessage("warning", "Action order not entered", "Enter action order to continue.");
			return false;
		}

		var isActionCodeUnique = true;
		list
			.filter(x => x.Code === model.Code)
			.map(() => {
				isActionCodeUnique = false;
			});
		if (!isActionCodeUnique) {
			this.ShowMessage("warning", "Action code must be unique", "Action code has already been inserted.");
			return false;
		}

		var isActionOrderUnique = true;
		list
			.filter(x => x.Order == model.Order)
			.map(() => {
				isActionOrderUnique = false;
			});
		if (!isActionOrderUnique) {
			this.ShowMessage("warning", "Action order must be unique", "Action order has already been inserted.");
			return false;
		}

		return true;
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null, isLoading: false })}
					OnConfirm={onConfirm}
				/>,
			isLoading: true
		});
	}
	render() {
		const { model, list, selected } = this.state;
		const { Disabled } = this.props;

		return (
			<div>

				<LoadingComponent Show={this.state.isLoading} />


				{this.state.alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || Object.keys(this.state.checkedValues).length === 0,
							OnClick: () => this.ShowConfirmMessage("warning", "Warning", "Are you sure you want to approve the cheque?", this.Submit),
						},
						{
							Code: "Clear",
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Main Parameters"}>
								<CardBody>

									<GridContainer>
										<GridItem xs={4}>

											<GenericSelectInput
												Name="Name"
												LabelText="Client Name"
												Value={model.Name || ""}
												DataRoot="Item"
												ValueChanged={this.ValueChanged}
												KeyValueMember="Id"
												TextValueMember="Name"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{ UniqueClientId: 0 }}
												Disabled={!model.isBackOffice}


											/>

											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Unique Client ID"
												Value={model.UniqueClientId || ""}
												DataRoot="Item"
												ValueChanged={this.ValueChanged}
												KeyValueMember="Id"
												TextValueMember="UniqueClientId"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{ UniqueClientId: 0 }}
												Disabled={!model.isBackOffice}

											/>
										</GridItem>

										<GridItem xs={4}  >
											<GenericDateInput
												Name="SearchStartDate"
												LabelText="From Date"
												Value={model == null || undefined ? null : model.SearchStartDate == null || undefined ? "" : model.SearchStartDate}
												ValueChanged={this.ValueChanged}
												MaxDate={model.SearchEndDate == null ? DateHelper.GetDate() : model.ToDate}
												Utc

											/>
											<GenericDateInput
												Name="SearchEndDate"
												LabelText="To Date"
												Value={model == null || undefined ? null : model.SearchEndDate == null || undefined ? "" : model.SearchEndDate}
												ValueChanged={this.ValueChanged}
												MaxDate={DateHelper.GetDate()}
												MinDate={model.SearchStartDate}
												Utc
											/>
										</GridItem>

									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						{<Card >
							<CardBody>
								<ChequeOrderDataTable
									checkedValues={this.state.checkedValues}
									list={this.state.list}
									totalresult={this.state.totalresult}
									handleCheck={this.HandleCheck}
								/>
							</CardBody>
						</Card>}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ChequeOrder.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,

};

export default withArtifex(ChequeOrder, styles);