import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DeleteIcon, UploadIcon } from "core/Icons";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankTransactionStatus, Direction, EtransferApplicationTypes, ETransferMoneyRequestStatus, ETransferMoneySendStatus, GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import Select from "react-select";
import moment from "moment";
class eTransferOutgoingTransfers extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			SearchEndDate: DateHelper.GetDate(),
			SearchStartDate: DateHelper.GetDate(),
			DirectionParameterValue: Direction.Outgoing,
			TransactionStatus: BankTransactionStatus.Completed,
			ClientId: 0,
			IsBankingScreen: false,
			searchIsNotSpecific: true
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			tempModel: {},
			getDataList: [],
			summaryModel: {},
			isLoading: false,
			isBackOffice: false,
			selectList: [],
			checkedValues: [],
			cancelRequestModel: {
				SelectedETransfer: {},
				cancellationReason: null
			},
			cancelDialog: false,
			vIndividualModel: {},
			LimitToken: false,
			remindModel: {},
			remindDialog: false
		};

		this.sortedByDate = [{ id: "TransactionDate", desc: true }];
		this.maxLength400 = { maxLength: 400 };
		this.isClient = ClientHelper.IsClient();
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.isBankingScreen = this.props.MenuCode == "BETOUT";
		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Interac e-Transfer / Outgoing Transactions");

		var model = { ...this.state.model };
		var initialModel = this.initialModel;

		model.DirectionParameterValue = Direction.Outgoing;
		model.IsBankingScreen = this.props.MenuCode == "BETOUT";
		var isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			initialModel.ClientId = model.ClientId;
			this.props.setAppCenterTitle(model.IsBankingScreen ? "WALLET" : "INTERAC E-TRANSFER");
		}
		else {
			this.props.setAppCenterTitle("INTERAC E-TRANSFER");
		}
		this.RefreshToolBar();
		this.setState({ model, isBackOffice, loadingCompleted: true });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleChangeCancelModel = (name, newValue) => {
		const cancelRequestModel = { ...this.state.cancelRequestModel };
		cancelRequestModel.cancellationReason = newValue;
		this.setState({ cancelRequestModel });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetData() { this.setState(state => ({ trxGridToken: !state.trxGridToken })); this.GetSummaryData(); }

	GetSummaryData = () => {
		if (!this.Validate())
			return;

		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetEtransferOutInSummaryData",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ summaryModel: responseData.Item });
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;

		var searchIsNotSpecific = (model.RefNo == undefined || model.RefNo == null || model.RefNo == "") &&
			(model.InteracRefNo == undefined || model.InteracRefNo == null || model.InteracRefNo == "") &&
			(model.PayeeEmail == undefined || model.PayeeEmail == null || model.PayeeEmail == "");

		model["searchIsNotSpecific"] = searchIsNotSpecific;

		var validateStatus = true;

		if (searchIsNotSpecific) {

			if (!model.ClientId) {
				this.props.showMessage("warning", "Please fill required fields!", "Select Client to continue");
				vModel["ClientId"] = true;
				validateStatus = false;
			}

			if (typeof model.SearchStartDate != "object" && model.SearchStartDate != "" && model.SearchStartDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
				validateStatus = false;
			}
			if (typeof model.SearchEndDate != "object" && model.SearchEndDate != "" && model.SearchEndDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
				validateStatus = false;
			}
			if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object") &&
				DateHelper.ToMoment(model.SearchEndDate) < DateHelper.ToMoment(model.SearchStartDate)) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
				validateStatus = false;
			}

			this.setState({ model, vModel });
			if (!validateStatus)
				return false;
		}
		else {
			vModel["ClientId"] = false;
			this.setState({ model, vModel });
		}
		return true;
	}

	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.TransactionList == null || responseData.Item.TransactionList.length < 1) {
			this.setState({ summaryModel: {}, cancelRequestModel: null, getDataList: [], isLoading: false });
		} else {
			this.setState({
				cancelRequestModel: { SelectedETransfer: {}, cancellationReason: null },
				getDataList: responseData.Item.TransactionList == null ? [] : responseData.Item.TransactionList, isLoading: false
			});
		}
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			isLoading: false, cancelDialog: false, remindDialog: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleClear = () => {
		this.setState({
			model: this.initialModel,
			getDataList: [],
			summaryModel: {}
		});
	}

	hideAlert() { this.setState({ alert: null }); }
	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ alert: null, isLoading: false }); }
	hideDialog = () => { this.setState({ cancelDialog: false, remindDialog: false }); }

	handleCancel = (eTransfer) => {
		if (!this.state.isBackOffice) {
			var cancelRequestModel = { ...this.state.cancelRequestModel };
			const selectTransfer = eTransfer;
			cancelRequestModel.SelectedETransfer = selectTransfer;
			if (cancelRequestModel.SelectedETransfer.BankTransactionStatusParamValue == BankTransactionStatus.Sent)
				this.setState({ cancelRequestModel, cancelDialog: true });
			else
				this.ShowMessage("warning", "Warning", "Selected transaction status is not Sent !!!");
		}
	}

	confirmCancel = () => {
		var cancelRequestModel = { ...this.state.cancelRequestModel };
		if (cancelRequestModel.cancellationReason != null && cancelRequestModel.cancellationReason != "") {
			this.hideDialog();
			this.showLoading();

			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/CancelOutgoingEtransfer",
				cancelRequestModel,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.setState({ IsConfirm: false });
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Transfer cancelled successfuly");
					this.GetData();
				},
				error => {
					this.ShowMessage("error", "Error", error);
				});
		}
		else {
			this.ShowMessage("warning", "Warning", "Cancellation Reason can not be null !!!");
		}

	}

	handleRemind = (eTransfer) => {
		var { remindModel } = this.state;

		if (eTransfer.BankTransactionStatusParamValue == BankTransactionStatus.Sent) {

			if (eTransfer.TransactionTypeId == null || eTransfer.TransactionTypeId == undefined || eTransfer.TransactionTypeId <= 0) {
				this.ShowMessage("error", "Error", "Transaction Type information of line is invalid !");
				return;
			}
			else {
				remindModel.TransactionTypeId = eTransfer.TransactionTypeId;
			}

			if (eTransfer.ParticipantReferenceNumber == null || eTransfer.ParticipantReferenceNumber == undefined || eTransfer.ParticipantReferenceNumber == "") {
				this.ShowMessage("error", "Error", "Transfer Reference Number information of line is invalid !");
				return;
			}
			else {
				remindModel.TransferReferenceNumber = eTransfer.ParticipantReferenceNumber;
			}

			this.setState({ remindModel, remindDialog: true });
		}
		else
			this.ShowMessage("warning", "Warning", "Selected transaction status is not Sent !!!");
	}

	confirmRemind = () => {
		var { remindModel } = this.state;
		this.hideDialog();
		this.showLoading();

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/RemindEtransferTransaction",
			remindModel,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Transfer reminded successfully");
			},
			error => {
				this.ShowMessage("error", "Error", error);
			});
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Interac e-Transfer Outgoing Transfers";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClearingSettlement/GetETransferOutgoingTransfersExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading, isBackOffice, cancelDialog, cancelRequestModel, LimitToken, remindModel, remindDialog, vModel } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				{this.isClient && <AccountLimitToolbar key={"_" + LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} IsUseDisableDebitLimit={false} />}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					// { Code: "Cancel", OnClick: this.handleCancel, Disabled: (isBackOffice || (cancelRequestModel == null || cancelRequestModel.SelectedETransfer == null)) },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				{/* CANCEL DIALOG */}
				<GenericDialog open={cancelDialog} maxWidth="sm" fullWidth>
					<DialogTitle>
						<b><h6>Cancel Transfer	</h6></b>
					</DialogTitle>
					<DialogContent>
						<GenericTextInput
							inputProps={this.maxLength400}
							Name="cancellationReason"
							LabelText="Cancellation Reason"
							Value={cancelRequestModel != null ? cancelRequestModel.cancellationReason != null ? cancelRequestModel.cancellationReason : "" : ""}
							ValueChanged={this.handleChangeCancelModel} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.confirmCancel}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ cancelDialog: false, cancelRequestModel: {} })}>No</Button>
					</GenericDialogActions>
				</GenericDialog>
				{/* REMIND DIALOG */}
				<GenericDialog open={remindDialog} maxWidth="sm" fullWidth>
					<DialogTitle>
						<b><h6>Remind Transfer</h6></b>
					</DialogTitle>
					<DialogContent>
						Do you want to remind this transfer ?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.confirmRemind}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ remindDialog: false, remindModel: {} })}>No</Button>
					</GenericDialogActions>
				</GenericDialog>
				{/*EXPORT DIALOG*/}
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Summary Information"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										CanClear
										Disabled={!isBackOffice}
										Required={model.searchIsNotSpecific == true}
										IsInvalid={vModel.ClientId} />
									<GenericDateInput
										Name="SearchStartDate"
										LabelText="Start Date"
										Value={model.SearchStartDate}
										ValueChanged={this.handleChange}
										MaxDate={model.SearchEndDate || DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="SearchEndDate"
										LabelText="End Date"
										Value={model.SearchEndDate}
										ValueChanged={this.handleChange}
										MinDate={model.SearchStartDate}
										IncludeTime={false}
										Utc />
									<GenericSelectInput
										Name="TransactionTypeId"
										LabelText="Transaction Type"
										Value={model === undefined ? "" : model.TransactionTypeId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterValue6"
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
									/>
									<GenericSelectInput
										Name="TransactionStatusId"
										LabelText="Transaction Status"
										Value={model == null || undefined ? "" : model.TransactionStatusId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Url="/coreapi/v1.0/Parameter/Search"
										Method="POST"
										Parameter={{
											ParameterCode: "BankTransactionStatus",
											"ParameterValue": "",
											"ParameterValue2": "",
											"ParameterDesc": ""
										}}
									/>
									<GenericSelectInput
										Name="ApplicationId"
										LabelText="Application Type"
										Value={model === undefined ? "" : model.ApplicationId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Value"
										TextValueMember="Text"
										IsStatic
										StaticData={EtransferApplicationTypes || []}
									/>
									<GenericNumberInput
										NoFormatting={true}
										Name="RefNo"
										LabelText="Reference Number"
										Value={model.RefNo}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="InteracRefNo"
										LabelText="Interac Reference Number"
										Value={model.InteracRefNo}
										ValueChanged={this.handleChange} />
									<GenericNumberInput
										NoFormatting={true}
										Name="AccountNumber"
										LabelText="Wallet Number"
										MaxLength={9}
										Value={model.AccountNumber || ""}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								<GridItem xs={1}></GridItem>
								<GridItem xs={7}>
									<Card>
										<CardBody>
											<GridContainer>
												<GridItem xs={6}>
													<GenericNumberInput
														Name="TotalNoOfCredit"
														LabelText="Total No. of Money Send"
														LabelMd={6}
														Value={summaryModel == null ? "" : summaryModel.TotalNoOfCredit ? summaryModel.TotalNoOfCredit : ""}
														Disabled={true}
														DecimalScale={0} />
													<GenericNumberInput
														Name="TotalNoOfDebit"
														LabelText="Total No. of Money Request"
														LabelMd={6}
														Value={summaryModel == null ? "" : summaryModel.TotalNoOfDebit ? summaryModel.TotalNoOfDebit : ""}
														Disabled={true}
														DecimalScale={0} />
												</GridItem>
												<GridItem xs={1}></GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														Name="CreditTotal"
														LabelText="Money Send Total"
														Value={summaryModel == null ? "" : summaryModel.CreditTotal ? summaryModel.CreditTotal : ""}
														Disabled={true}
														Prefix="$" />
													<GenericNumberInput
														Name="DebitTotal"
														LabelText="Money Request Total"
														Value={summaryModel == null ? "" : summaryModel.DebitTotal ? summaryModel.DebitTotal : ""}
														Disabled={true}
														Prefix="$" />
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Outgoing Transfers"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									PageSize={10}
									ShowPagination={true}
									Columns={[{
										Header: "Actions",
										accessor: "Actions",
										Cell: d => (
											<div>
												<GridButton
													Disabled={d.original.BankTransactionStatusParamValue == BankTransactionStatus.Completed}
													Icon={DeleteIcon}
													tooltip="Cancel Transfer"
													OnClick={() => { this.handleCancel(d.original); }} />

												<GridButton
													Disabled={d.original.BankTransactionStatusParamValue != BankTransactionStatus.Sent || (d.original.InteracStatusParamValue != ETransferMoneySendStatus.TransferAvailable && d.original.InteracStatusParamValue != ETransferMoneySendStatus.TransferInitiated && d.original.InteracStatusParamValue != ETransferMoneyRequestStatus.Available)}
													Icon={UploadIcon}
													tooltip="Remind Transfer"
													OnClick={() => { this.handleRemind(d.original); }} />
											</div>
										),
										sortable: false,
										filterable: false,
										width: 70
									},
									{
										Header: "Transaction Date",
										accessor: "TransactionDate",
										type: GridColumnType.DateTimeUtc
									},
									{
										Header: "Settlement Date",
										accessor: "SettlementDate",
										type: GridColumnType.DateTimeUtc
									},
									{
										Header: "Wallet Number",
										accessor: "AccountNumber",
									},
									{
										Header: "Unique Client Id",
										accessor: "ClientNumber",
										show: isBackOffice
									},
									{
										Header: "Client Name",
										accessor: "ClientName",
										show: isBackOffice
									},
									{
										Header: !this.isBankingScreen ? "Customer Name" : "Contact Name",
										accessor: "SenderName"
									},
									{
										Header: "Customer Number",
										accessor: "ClientCustomerNumber",
										show: !this.isBankingScreen
									},
									{
										Header: "Transfer Type",
										accessor: "TransactionType"
									},
									{
										Header: !this.isBankingScreen ? "Customer EMail" : "Contact EMail",
										accessor: "CustomerEmail"
									},
									{
										Header: "Amount",
										accessor: "TransactionAmount",
										type: GridColumnType.Money,
										ColumnType: GridColumnType.Money
									},
									{
										Header: "Fee Amount",
										accessor: "FeeAmount",
										type: GridColumnType.Money,
										ColumnType: GridColumnType.Money
									},
									{
										Header: "Settlement Status",
										accessor: "SettlementStatus"
									},
									{
										Header: "Transfer Status",
										accessor: "BankTransactionStatus"
									},
									{
										Header: "Interac Status",
										accessor: "InteracStatus"
									},
									{
										Header: "Transaction Description",
										accessor: "TransactionMemo"
									},
									{
										Header: "Settlement Type",
										accessor: "SettlementType"
									},
									{
										Header: "Deferred",
										accessor: "IsDefered",
										Cell: row => row.value == true ? "Yes" : "No"
									},
									{
										Header: "Expire Date",
										accessor: "ExpireDate",
										type: GridColumnType.DateTimeUtc
									},
									{
										Header: "Interac Ref. Number",
										accessor: "TransferReferenceNumber"
									},
									{
										Header: "Response Reference Number",
										accessor: "RequestReferenceNumber"
									},
									{
										Header: "Insert Date",
										accessor: "RequestDate",
										type: GridColumnType.DateTimeUtc
									},
									{
										Header: "Transaction Source",
										accessor: "TransactionSource"
									},
									{
										Header: "Store Code",
										accessor: "StoreName"
									},
									{
										Header: "Application",
										accessor: "ApplicationId",
										Cell: row => {
											var rowValue = "";
											EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
												if (item.Value == row.value)
													rowValue = item.Text;
											});
											return rowValue;
										}
									},
									{
										Header: "Insert User",
										accessor: "InsertUser" 
									},
									{
										Header: "Reference Number",
										accessor: "ParticipantReferenceNumber"
									},
									{
										Header: "Error Description",
										accessor: "ErrorDesc"
									},
									{
										Header: "Customer Store",
										accessor: "CustomerStore"
									},
									{
										Header: "Sender Email",
										accessor: "SenderEmail",
									 },
									 {
										Header: "Receiver Name",
										accessor: "ReceiverName",
									 },
									 {
										Header: "Receiver Email",
										accessor: "ReceiverEmail",
									 },
									 {
										Header: "Client Reference Number",
										accessor: "ClientRefNo",
									 },
									]}
									Sorted={this.sortedByDate}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									ValidateRequest={this.Validate}
									PrepareRequest={() => { return this.state.model; }}
									RequestUrl="/bankapi/v1.0/ClearingSettlement/GetETransferOutgoingTransfersPaged"
									RequestMethod="POST" />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eTransferOutgoingTransfers.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eTransferOutgoingTransfers, {});