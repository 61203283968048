import { withStyles } from "@material-ui/core";
import genericNumberInputStyle from "assets/jss/generic/genericNumberInputStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PropTypes from "prop-types";
import React from "react";
import TextInput from "react-autocomplete-input";

class GenericTextAutoInput extends React.PureComponent {
	render() {
		const { LabelText, Name, Value, ValueChanged, Disabled,
			Blur, LabelMd, inputProps, LabelStyle, AutoCompleteDisable, MaxOptions,
			Options, Regex, RequestOnlyIfNoOptions, SpaceRemovers, Spacer, Trigger, OnRequestOptions, Required, IsInvalid } = this.props;

		return (
			<CustomInput
				LabelMd={LabelMd}
				LabelStyle={LabelStyle}
				InputStyle={{ display: "block", paddingRight: "3px" }}
				labelText={LabelText}
				Required={Required}
				IsInvalid={IsInvalid}
				formControlProps={{ fullWidth: true }}
				inputProps={{
					...inputProps,
					value: Value != null ? Value : "",
					onChange: e => {
						if (ValueChanged)
							ValueChanged(Name, e);
					},
					disabled: Disabled,
					inputComponent: TextInput,
					inputProps: {
						disabled: AutoCompleteDisable,
						options: Options,
						maxOptions: MaxOptions,
						regex: Regex,
						requestOnlyIfNoOptions: RequestOnlyIfNoOptions,
						spaceRemovers: SpaceRemovers,
						spacer: Spacer,
						trigger: Trigger,
						onRequestOptions: OnRequestOptions
					},
					onBlur: Blur
				}}
			/>
		);
	}
}

GenericTextAutoInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.func,
	Value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	LabelText: PropTypes.string,
	Name: PropTypes.string,
	Disabled: PropTypes.bool,
	AutoFocus: PropTypes.bool,
	KeyPressed: PropTypes.func,
	KeyDown: PropTypes.func,
	KeyUp: PropTypes.func,
	Blur: PropTypes.func,
	LabelMd: PropTypes.number,
	inputProps: PropTypes.object,
	LabelStyle: PropTypes.object,
	AutoCompleteDisable: PropTypes.bool,
	Regex: PropTypes.string,
	RequestOnlyIfNoOptions: PropTypes.bool,
	SpaceRemovers: PropTypes.any,
	Spacer: PropTypes.string,
	Trigger: PropTypes.string,
	MaxOptions: PropTypes.number,
	Options: PropTypes.array,
	OnRequestOptions: PropTypes.func,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool
};

GenericTextAutoInput.defaultProps = {
	AutoCompleteDisable: false,
	Regex: "^[a-zA-Z0-9_\\-]+$",
	RequestOnlyIfNoOptions: true,
	SpaceRemovers: "[',', '.', '?', '!']",
	Spacer: " ",
	Trigger: "@"
};

export default withStyles(genericNumberInputStyle)(GenericTextAutoInput);