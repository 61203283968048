import React from "react";
import { withRouter } from "react-router";
import GenericScreen from "views/Components/Generic/GenericScreen";

class DynamicComponent extends React.Component {

	constructor(props) {
		super(props);

		this.state = {};

		if (this.props.comp) {
			this.state = { Comp: this.props.comp };
		}
		else if (!this.props.screenId) {
			import(/* webpackMode: "eager" */ `../${this.props.route}`)
				.then(C => {
					this.setState({ Comp: C.default });
				})
				.catch(error => {
					console.error("error: " + error);
				});
		}
	}

	componentDidMount() {
		if (this.props.screenId) {
			this.setState({ Comp: GenericScreen });
		}
	}

	render() {
		if (!this.state.Comp) return "nope";
		return (<this.state.Comp {...this.props} />);
	}
}

export default withRouter(DynamicComponent);