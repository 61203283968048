import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle, GenericCheckInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes, EmbossType } from "views/Constants/Constant";

class CardRenewal extends Component {
	constructor(props) {
		super(props);
		this.parameterEmbossType = { ParameterCode: "EmbossType" };

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,
			IsNoNameCard: false,
			CardIssuingReasonTypeId: 0,
			EmbossStatusId: 0,
			ProductId: 0,
			IsMass: true
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {}
		};

		this.emptyObject = {};

		this.maxLength21 = { maxLength: 21 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Is No Name Card",
				accessor: "IsNoNameCard",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Card Id",
				accessor: "CardId"
			},

			{
				Header: "Client",
				accessor: "ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Emboss Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Issuing Reason Type",
				accessor: "IssuingReasonType",
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Personalized Card Renewal");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}

		if (name == "CardIssuingReasonTypeId")
			model.CardIssuingReasonType = newValue;

		if (name == "CardId" && data)
			model.EmbossName = data.CardEmbossName;

		if (name === "EmbossType" && newValue!=EmbossType.InstantId) {
			model.IsMass=true;
			model.InstantEmbossBranchId = undefined;
		}
		if (name === "EmbossType" && newValue==EmbossType.InstantId) {
			model.IsMass=false;
		}

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardRequest/GetAllCardRequest",
			{
				CardIssuingReasonTypeId: model.CardIssuingReasonTypeId,
				IsMainCard: model.IsMainCard,
				UniqueClientId: model.UniqueClientId || 0,
				ClientCustomerId: model.ClientCustomerId,
				ProductId: model.ProductId,
				EmbossName1: model.EmbossName,
				IsNoNameCard: model.IsNoNameCard,
				MaskedCardNumber: model.MaskedCardNumber
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/UpdateCardRenew",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/RenewCard",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	Validate = () => {
		const { model, vModel } = this.state;
		var errorList = [];

		if (!model.UniqueClientId) {
			errorList.push("Select the Client to continue.");
			vModel.UniqueClientId = true;
		}
		else
			vModel.UniqueClientId = false;


		if (!model.CardId) {
			errorList.push("Select the Card Number to continue.");
			vModel.CardId = true;
		}
		else
			vModel.CardId = false;

		if (!model.ClientCustomerId) {
			errorList.push("Select the Customer to continue.");
			vModel.ClientCustomerId = true;
		}
		else
			vModel.ClientCustomerId = false;

		if (!model.ProductId) {
			errorList.push("Select the Product to continue.");
			vModel.ProductId = true;

		}
		else
			vModel.ProductId = false;

		if (!model.EmbossName) {
			errorList.push("Enter the Emboss Name to continue.");
			vModel.EmbossName = true;

		}
		else
			vModel.EmbossName = false;

		if (!model.CardIssuingReasonTypeId) {
			errorList.push("Enter the Issuing Reason Type to continue.");
			vModel.CardIssuingReasonTypeId = true;
		}
		else
			vModel.CardIssuingReasonTypeId = false;

		vModel.EmbossType = model.EmbossType == null;
		if (vModel.EmbossType) errorList.push("Emboss Type must be select");

		vModel.InstantEmbossBranchId = model.InstantEmbossBranchId == null && model.EmbossType == EmbossType.InstantId ;
		if (vModel.EmbossType) errorList.push("Card Perso Office must be select");


		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { list } = this.state;
		const tempModel = list[index];
		if (list[index].Id) {
			tempModel.Id = list[index].Id;
			tempModel.EmbossName = list[index].EmbossName1;
		}
		this.setState({ isEdit: true, model: tempModel, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = this.state.model.IsNoNameCard;
		return this.parameterProduct;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || model.IsNoNameCard || model.Id <= 0 },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0 || model.IsNoNameCard }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
												<GenericCheckInput
													Name="IsNoNameCard"
													LabelText="Is No Name Card"
													Value={model.IsNoNameCard || false}
													ValueChanged={this.HandleChange}
													Disabled={Disabled} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelMd={4}
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													Disabled={IsClient}
													DefaultIndex={IsClient ? 0 : -1}
													Required
													IsInvalid={vModel.UniqueClientId}
												/>
											</GridItem>

											<GridItem xs={4}>
												<GenericSelectInput
													key={[model.UniqueClientId, model.IsNoNameCard]}
													Name="ProductId"
													LabelMd={4}
													LabelText="Product"
													Method="POST"
													Url="/prepaidapi/v1.0/Product/Search"
													Parameter={this.GetProductParameter()}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ProductName"
													Value={model.ProductId}
													ValueChanged={this.HandleChange}
													Disabled={model.Id > 0 ? true : false}
													Required
													IsInvalid={vModel.ProductId}
												/>
											</GridItem>

											{!model.IsNoNameCard &&
												<GridItem xs={4}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
														Disabled={model.Id > 0 ? true : false}
														Required
														IsInvalid={vModel.ClientCustomerId}
													/>
												</GridItem>
											}
											{!model.IsNoNameCard &&

												<GridItem xs={4}>
													<GenericSelectInput
														key={model.ClientCustomerId}
														Name="CardId"
														LabelMd={4}
														LabelText="Masked Card Number"
														Method="POST"
														Url="/prepaidapi/v1.0/Card/CardSearch"
														Parameter={{
															ClientCustomerId: model.ClientCustomerId,
															CardSearchCriteria: 2
														}}
														DataRoot="Item"
														RenderItem={d => `${d.CardEmbossName} - ${d.MaskedCardNumber}`}
														KeyValueMember="CardId"
														TextValueMember="MaskedCardNumber"
														Value={model.CardId}
														ValueChanged={this.HandleChange}
														Disabled={model.Id > 0 ? true : false}
														Required
														IsInvalid={vModel.CardId}
													/>
												</GridItem>
											}

											<GridItem xs={4}>
												<ParameterComponent
													Name="CardIssuingReasonTypeId"
													LabelMd={4}
													LabelText="Issuing Reason Type"
													ParameterCode="CardIssuingReasonType"
													ParameterValue3="1"
													Value={model.CardIssuingReasonTypeId}
													ValueChanged={this.HandleChange}
													Disabled={false}
													Required
													IsInvalid={vModel.CardIssuingReasonTypeId}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericTextInput
													Name="EmbossName"
													LabelMd={4}
													LabelText="Emboss Name"
													inputProps={this.maxLength21}
													IsText={true}
													Value={model.EmbossName}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.EmbossName}
												/>
											</GridItem>

											{model.IsNoNameCard &&
												<GridItem xs={4}>
													<GenericTextInput
														Name="MaskedCardNumber"
														LabelMd={4}
														Format={"@@@@@@******@@@@"}
														LabelText="Masked Card Number"
														inputProps={this.maxLength26}
														IsText={true}
														Value={model.MaskedCardNumber}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.MaskedCardNumber}
													/>
												</GridItem>
											}

											<GridItem xs={4}>
												<GenericSelectInput
													Name="EmbossType"
													LabelText="Emboss Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterEmbossType}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													ValueChanged={this.HandleChange}
													Value={model.EmbossType}
													Required
													IsInvalid={vModel.EmbossType}
													DefaultIndex={1}
													/>
											</GridItem>

											<GridItem xs={4}>
													<GenericSelectInput
														Name="InstantEmbossBranchId"
														LabelText="Card Perso Office"
														Method="POST"
														Url="/prepaidapi/v1.0/Card/GetInstantCardBranchList"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														ValueChanged={this.HandleChange}
														Value={model.InstantEmbossBranchId}
														Disabled={model.IsMass}
														Required
														IsInvalid={vModel.InstantEmbossBranchId}
													/>
												</GridItem>

										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Personalized Card Renewal List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={true}
											PageSize={100}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardRenewal.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CardRenewal, {});