import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";

const loadingPlaceholder=<div style={{ height: "100%" }} />;
const containerPlaceholder=<div style={{ height: "400px" }} />;
const MapHoc = withScriptjs(
	withGoogleMap(props => {
		const { MapContainerRef, children, ...others } = props;
		return (
			<GoogleMap
				mapTypeId={"roadmap"}
				ref={MapContainerRef}
				{...others}
			>
				{children}
			</GoogleMap>
		);
	}));

const GenericMapContainer = (props) => {

	const { ApiKey, ...others } = props;
	return (
		<MapHoc
			googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${ApiKey}&v=3.exp&libraries=geometry,drawing,places`}
			loadingElement={loadingPlaceholder}
			containerElement={containerPlaceholder}
			{...others}
		/>);
};


export default GenericMapContainer;