import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericGrid, GenericInput, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { BankProgramType, ExpressionType, PropertyType } from "views/Constants/Constant";
import { MaxLength500 } from "views/Constants/Constant.MaxLength";
import { SortedParameterValue, SortedPropertyName } from "views/Constants/Constant.Sorted";

class EftRoutingRule extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Code",
				accessor: "Code"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Program",
				accessor: "ProgramDescription"
			},
			{
				Header: "Class Definition",
				accessor: "ClassDefinitionDescription"
			},
			{
				Header: "EFT Processor",
				accessor: "EftProcessorParameterDesc"
			},
			{
				Header: "Priority Order",
				accessor: "PriorityOrder"
			}
		];

		this.callerModuleEftCoreId = null;
		this.programEftId = null;

		this.parameterProgramCodeEft = { ProgramCode: BankProgramType.EFT };

		this.state = {
			model: {
				VerificationList: []
			},
			list: [],
			criteriaList: []
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SYSTEM ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("EFT Routing Rule");
		}

		this.GetCallerModuleEftCore();
		this.GetExpressionType();
		this.GetProgramEftId();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "ProgramId") {
				model.ProgramId = value || 0;
				model.ClassDefinitionId = undefined;
			} else if (name == "ClassDefinitionId") {
				if (value != null) {
					this.GetPropertyDefinition();
				}
				else {
					return { model, criteriaList: [] };
				}
			}

			return { model };
		});
	}

	ValueChangedDeleteButton = (index) => {
		this.setState(state => {
			var criteriaList = state.criteriaList;

			criteriaList.splice(index, 1);

			return { criteriaList };
		});
	}

	ValueChangedCriteria = (name, value, data, index) => {
		this.setState(state => {
			var criteriaList = state.criteriaList;
			var item = criteriaList[index], i;

			if (name == "PropertyDefinitionId") {
				if (value == null) {
					criteriaList.splice(index, 1);

					return { criteriaList };
				}

				if (data != null && item != null && data.PropertyType != null) {
					const { showMessage } = this.props;

					if (data.PropertyDependencyId != null && criteriaList.filter(x => x.PropertyDefinitionId == data.PropertyDependencyId).length == 0) {
						showMessage("warning", "Warning", `You cannot select this property. You must before add ${data.PropertyDependency.Description} which is dependency property.`);

						return;
					}

					if (state.propertyDefinitionList.filter(x => x.PropertyDependencyId == data.PropertyDefinitionId) != 0) {
						for (i = 0; i < criteriaList.length; i++) {
							if (i != index && criteriaList[i].PropertyDefinitionId == data.PropertyDefinitionId) {
								showMessage("warning", "Warning", "You cannot select this property. It's dependency property and added before.");

								return;
							}
						}
					}

					if (criteriaList.filter(x => x.PropertyDefinitionId == data.PropertyDefinitionId) != 0 && data.PropertyDependencyId != null) {
						showMessage("warning", "Warning", "You cannot select this property. It has a dependency property and added before.");

						return;
					}

					item.DataModelProperty = {
						PropertyType: data.PropertyType
					};
					item.ViewData = this.GenerateViewData(data);
				}

				if (!item) {
					item = {};
				}

				item.Value = null;
				item.ExpressionTypeId = undefined;
				item.PropertyName = data.PropertyName;
				item.PropertyDependencyId = data.PropertyDependencyId;
			} else if (name == "Value") {
				if (value == null) {
					item[name] = null;

					return { criteriaList };
				}

				for (i = 0; i < criteriaList.length; i++) {
					if (i != index && criteriaList[i].PropertyDependencyId == criteriaList[index].PropertyDefinitionId) {
						criteriaList[i].ViewData.Key = data.Id;

						var propertyName = criteriaList[index].PropertyName;
						var parameter = {};
						parameter[propertyName] = data.Id;
						criteriaList[i].ViewData["Parameter"] = parameter;

						break;
					}
				}
			}

			item[name] = value;

			return { criteriaList };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;
		var model = {}, criteriaList = [], listItem = list[index];

		model.ClassDefinitionId = listItem.ClassDefinitionId;

		this.setState({ model });

		model.Id = listItem.EftRoutingRuleId;
		model.ProgramId = listItem.ProgramId;
		model.EftProcessorId = listItem.EftProcessorId;
		model.PriorityOrder = listItem.PriorityOrder;
		model.Description = listItem.Description;

		this.GetPropertyDefinition(listItem.ClassDefinitionId);

		listItem.CriteriaList.map(x => {
			criteriaList.push({
				Id: x.EftRoutingRuleCriteriaId,
				PropertyDefinitionId: x.PropertyDefinitionId,
				ExpressionTypeId: x.ExpressionTypeId,
				Value: x.Value,
				DataModelProperty: {
					PropertyType: x.PropertyType
				},
				ViewData: this.GenerateViewData(x, listItem.CriteriaList)
			});

			return null;
		});

		this.setState({ model, criteriaList, selected: index });
	}

	GetRenderItemPropertyDefinition = (x) => {
		var render = `${x.Description}`;

		if (x.PropertyPath != null) {
			render += ` - ${x.PropertyPath}`;
		}

		return render;
	}

	GetParameterProgramIdAndCallerModuleId = () => {
		const { model } = this.state;
		var result = {
			ProgramId: model.ProgramId,
			CallerModuleId: this.callerModuleEftCoreId
		};

		return result;
	}

	GetParameterClassDefinitionId = () => {
		const { model } = this.state;

		var result = {
			Id: model.ClassDefinitionId
		};

		return result;
	}

	GetCriteriaIndex = (index) => {
		index = index + 1;

		return index;
	}

	GenerateViewData = (data, criteriaListFromApi = null) => {
		var propertyType = data.PropertyType.ParameterValue;
		var propertyData = data.PropertyData;

		if (propertyType == PropertyType.Select) {
			var temp = JSON.parse(propertyData);
			if (temp == null) {
				temp = {};
			} else if (data.PropertyDependencyId != null) {
				var { criteriaList } = this.state;

				if (criteriaListFromApi != null) {
					criteriaList = criteriaListFromApi;
				}

				var dependencyProperty = null;

				for (var index = 0; index < criteriaList.length; index++) {
					if (criteriaList[index].PropertyDefinitionId == data.PropertyDependencyId) {
						dependencyProperty = criteriaList[index];

						break;
					}
				}

				if (dependencyProperty == null) {
					return {};
				}

				temp.key = data.Id || dependencyProperty.Value;

				var propertyName = dependencyProperty.PropertyName;
				var parameter = {};
				parameter[propertyName] = data.Id || dependencyProperty.Value || 0;
				temp["Parameter"] = parameter;
			}

			temp.Name = "Value";
			temp.InputType = PropertyType.Select;
			temp.CanClear = false;

			return temp;
		}

		if (propertyType == PropertyType.Decimal) {
			return {
				Name: "Value",
				InputType: PropertyType.Decimal,
				Prefix: "$ ",
				MaxLength: 7
			};
		}

		if (propertyType == PropertyType.Date) {
			return {
				Name: "Value",
				InputType: PropertyType.Date,
				IncludeTime: false,
				Utc: true,
				IsFormatDate: true
			};
		}

		if (propertyType == PropertyType.Int) {
			return {
				Name: "Value",
				InputType: PropertyType.Number,
				NoFormatting: true
			};
		}

		return {
			Name: "Value",
			InputType: propertyType
		};
	}

	GetCallerModuleEftCore = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "CallerModule", ParameterValue: "EftCore" });

		if (result != null) {
			this.callerModuleEftCoreId = result[0].Id;
			model.CallerModuleId = result[0].Id;

			this.setState({ model });
		}
	}

	GetPropertyDefinition = async (classDefinitonId) => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/PropertyDefinition/SearchByClassDefinitionId", { Id: model.ClassDefinitionId || classDefinitonId });

		this.setState({ propertyDefinitionList: result || [] });
	}

	GetExpressionType = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "ExpressionType" });
		var expressionTypeList = [], expressionTypeEqualList = [];

		if (result != null) {
			result
				.filter(x => x.ParameterValue == ExpressionType.Equal)
				.map(x => {
					expressionTypeEqualList.push(x);

					return null;
				});
			result
				.filter(x => x.ParameterValue != ExpressionType.Like)
				.map(x => {
					expressionTypeList.push(x);

					return null;
				});
		}

		this.setState({ expressionTypeList, expressionTypeEqualList });
	}

	GetProgramEftId = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/ClassDefinitionProgramMap/SearchProgram", { ProgramCode: BankProgramType.EFT });

		if (result != null && result.length == 1) {
			model.ProgramId = result[0].Id;
			this.programEftId = result[0].Id;
		}

		this.setState({ model, listProgram: result || [] });
	}

	AddCriteria = () => {
		var { criteriaList } = this.state;

		criteriaList.push({
			Id: 0,
			ExpressionTypeId: 0,
			ViewData: null
		});

		this.setState({ criteriaList });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/EftRoutingRule/Search", model);

		model.IsClassDefinitionId = false;
		model.IsEftProcessorId = false;
		model.IsPriorityOrder = false;
		model.IsDescription = false;

		this.setState({ model, list: result || [] });
	}

	SubmitOrUpdate(actionName) {
		const { model, criteriaList } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			EftRoutingRuleId: model.Id,
			ProgramId: model.ProgramId,
			ClassDefinitionId: model.ClassDefinitionId,
			EftProcessorId: model.EftProcessorId,
			PriorityOrder: model.PriorityOrder,
			Description: model.Description,
			CriteriaList: criteriaList.map(x => {
				return {
					EftRoutingRuleCriteriaId: x.Id,
					PropertyDefinitionId: x.PropertyDefinitionId,
					ExpressionTypeId: x.ExpressionTypeId,
					Value: x.Value
				};
			})
		};

		ExecuteApiPost(`/bankapi/v1.0/EftRoutingRule/${actionName}`, request, null, null, this.Validate, [this.Clear, this.Search], true);
	}

	Clear = () => {
		var model = {};

		model.CallerModuleId = this.callerModuleEftCoreId;
		model.ProgramId = this.programEftId;

		this.setState({ model, list: [], criteriaList: [], selected: null });
	}

	Validate = () => {
		const { model, criteriaList } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ClassDefinitionId) {
			errors.push("Class definition cannot be null.");
		}
		if (!model.EftProcessorId) {
			errors.push("EFT processor cannot be null.");
		}
		if (!model.PriorityOrder) {
			errors.push("Priority order cannot be null.");
		}
		if (!model.Description) {
			errors.push("Description cannot be null.");
		}
		if (criteriaList.length == 0
			|| criteriaList.filter(x => x.PropertyDefinitionId == null || x.ExpressionTypeId == null || x.Value == null).length != 0) {
			errors.push("Criteria can not be null.");
		}

		model["IsClassDefinitionId"] = !model.ClassDefinitionId;
		model["IsEftProcessorId"] = !model.EftProcessorId;
		model["IsPriorityOrder"] = !model.PriorityOrder;
		model["IsDescription"] = !model.Description;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, listProgram, expressionTypeList, expressionTypeEqualList, criteriaList, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => this.SubmitOrUpdate("Insert")
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => this.SubmitOrUpdate("Update")
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/EftRoutingRule/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: () => this.Clear()
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Program" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GenericSelectInput
											Disabled
											IsStatic
											Name="ProgramId"
											LabelText="Program"
											StaticData={listProgram}
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.ProgramId}
											ValueChanged={this.ValueChanged} />
										<GenericNumberInput
											NoFormatting
											Required
											IsInvalid={model.IsPriorityOrder}
											Name="PriorityOrder"
											LabelText="Priority Order"
											Value={model.PriorityOrder}
											ValueChanged={this.ValueChanged} />
									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											Required
											IsInvalid={model.IsClassDefinitionId}
											key={model.ProgramId}
											Name="ClassDefinitionId"
											LabelText="Class Definition"
											Url="/coreapi/v1.0/ClassDefinitionProgramMap/SearchClassDefinition"
											Method="POST"
											Parameter={this.GetParameterProgramIdAndCallerModuleId()}
											DataRoot="Item"
											KeyValueMember="ClassDefinitionId"
											TextValueMember="ClassDefinitionName"
											Value={model.ClassDefinitionId}
											ValueChanged={this.ValueChanged} />
										<GenericTextInput
											Required
											IsInvalid={model.IsDescription}
											Name="Description"
											LabelText="Description"
											inputProps={MaxLength500}
											Value={model.Description}
											ValueChanged={this.ValueChanged} />
									</GridItem>
									<GridItem xs={4}>
										<ParameterComponent
											Required
											IsInvalid={model.IsEftProcessorId}
											Name="EftProcessorId"
											LabelText="EFT Processor"
											ParameterCode="EftProcessor"
											Value={model.EftProcessorId}
											ValueChanged={this.ValueChanged} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Criteria" />
							</CardHeader>
							<CardBody >
								<GridContainer>
									<GridItem xs={10}>
										<GridContainer justify="flex-end">
											<GridItem style={{ float: "right" }}>
												<Button
													disabled={model.ProgramId == null || model.ClassDefinitionId == null}
													size="sm"
													color="black"
													onClick={this.AddCriteria}>
													+ Criteria
													</Button>
											</GridItem>
										</GridContainer>
										{
											criteriaList && criteriaList.map((item, index) => {
												return (
													<GridContainer key={index}>
														<GridItem xs={1} style={{ marginTop: "5px" }}>
															<Button
																size="sm"
																color="black"
																onClick={() => this.ValueChangedDeleteButton(index)}>
																Delete
															</Button>
														</GridItem>
														<GridItem xs={1} style={{ marginTop: "10px" }}>
															<GenericLabel
																Text={"Criteria " + this.GetCriteriaIndex(index)} />
														</GridItem>
														<GridItem xs={3}>
															<GenericSelectInput
																key={model.ClassDefinitionId}
																Name="PropertyDefinitionId"
																LabelMd={0}
																Url="/coreapi/v1.0/PropertyDefinition/SearchByClassDefinitionId"
																Method="POST"
																Parameter={this.GetParameterClassDefinitionId()}
																DataRoot="Item"
																KeyValueMember="PropertyDefinitionId"
																RenderItem={this.GetRenderItemPropertyDefinition}
																Sorted={SortedPropertyName}
																Value={item.PropertyDefinitionId}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
														<GridItem xs={3} style={{ display: !item.IsUniqueClientId ? "initial" : "none" }}>
															<GenericSelectInput
																IsStatic
																Name="ExpressionTypeId"
																LabelText="Expression Type"
																StaticData={expressionTypeList}
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={SortedParameterValue}
																Value={item.ExpressionTypeId}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
														<GridItem xs={3} style={{ display: item.IsUniqueClientId ? "initial" : "none" }} >
															<GenericSelectInput
																IsStatic
																Name="ExpressionTypeId"
																LabelText="Expression Type"
																StaticData={expressionTypeEqualList}
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={SortedParameterValue}
																Value={item.ExpressionTypeId}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
														<GridItem xs={3}>
															<GenericInput
																key={item.PropertyDefinitionId}
																LabelMd={0}
																ViewData={item.ViewData}
																Model={model}
																Value={item.Value}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
													</GridContainer>
												);
											})
										}
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem >
				</GridContainer >
			</div >
		);
	}
}

EftRoutingRule.propTypes = {
	ExecuteApiPost: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default EftRoutingRule;