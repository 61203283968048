import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { EFTEntryType } from "views/Constants/Constant";
import CreateEFTIndividual from "views/EFT/CreateEFT/CreateEFTIndividual";

class EftSuspiciousApprove extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			model: {

			},
			IndividualModel: {
				IsCustomerPopupOpen: false
			}
		};

		this.SubmitClick = this.SubmitClick.bind(this);
		this.Nothing = this.Nothing.bind(this);
		this.FillData = this.FillData.bind(this);
		this.GetModel = this.GetModel.bind(this);
		this.ValueChanged = this.ValueChanged.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	Nothing() {

	}

	ValueChanged(name, newValue) {
		this.setState({ [name]: newValue });
	}

	SubmitClick(workflowId, after, accessToken) {
		const { Id, CancelReasonId, CancelMemo } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankBmoEFT/SuspiciousApproval",
			{ TransactionId: Id, ReasonId: CancelReasonId, Memo: CancelMemo },
			fileResponseData => {
				this.setState({ isLoading: false });

				if (!fileResponseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred", fileResponseData.ErrorDescription);
					return;
				}

				if (after) after();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred", errorMessage);
			},
			workflowId,
			accessToken
		);
	}

	GetModel() {
		const { model } = this.state;
		return model;
	}

	FillData(data) {
		this.setState({ isLoading: true, CancelReasonId: data.CancelReasonId, CancelMemo: data.CancelMemo });
		Proxy.POST("/bankapi/v1.0/BankBmoEFT/GetByIdRequest",
			{ Item: data.TransactionId },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var eftData = responseData.Item;
				var transactionData = eftData.Transaction;

				this.setState(function (state) {
					var model = state.model || {};
					var IndividualModel = state.IndividualModel || {};

					model.UniqueClientId = transactionData.UniqueClientId;

					model.EntryType = transactionData.EntryTypeId;
					model.EntryCode = transactionData.EntryType ?? "";
					model.EFTType = eftData.EFTTypeId;

					model.IsUpdate = true;
					IndividualModel.Id = transactionData.Id;
					IndividualModel.PayeeId = eftData.PayeeId;
					IndividualModel.PayeeName = eftData.PayeeName;
					IndividualModel.PayeerFinancialInstitutionRowId = eftData.FinancialInstitutionId;
					IndividualModel.PayeerFinancialInstitutionBranchRowId = eftData.FinancialInstitutionBranchId;
					IndividualModel.PayeerAccountNumber = eftData.AccountNumber;
					IndividualModel.PayeerFinancialInstitutionId = eftData.FinancialInstitution?.FinIns;
					IndividualModel.PayeerTransitNumber = eftData.FinancialInstitutionBranch?.TransitNo;
					IndividualModel.Amount = transactionData.TransactionAmount;
					IndividualModel.TransactionDate = transactionData.TransactionDate ? DateHelper.ToMoment(transactionData.TransactionDate) : null;
					IndividualModel.TransferMemo = eftData.TransferMemo;
					IndividualModel.TransactionType = transactionData.TransactionTypeId;
					IndividualModel.TransactionTypeCode = transactionData.TransactionType ?? "";
					IndividualModel.TransactionCode = eftData.EFTTransactionTypeId;

					return { model, IndividualModel, Id: eftData.TransactionId };
				});
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", errorMessage);
			}
		);
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={this.hideAlert} /> });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { model, isLoading, Id, CancelReasonId, CancelMemo, alert } = this.state;
		const parentModel = { EntryCode: EFTEntryType.Individual, UniqueClientId: model.UniqueClientId };
		const { Disabled } = this.props;

		return (
			<>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: true, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: Id }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<ParameterComponent Name="CancelReasonId" LabelText="Suspicious Reason" Value={CancelReasonId} ValueChanged={this.ValueChanged} ParameterCode={"TransactionCancelReason"} Disabled={Disabled} />
								<GenericTextInput Name="CancelMemo" LabelText="Suspicious Memo" Value={CancelMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<CreateEFTIndividual Disabled={true} model={this.state.IndividualModel} onModelChange={this.Nothing} parentModel={parentModel} vModel={{}} />
			</>
		);
	}
}

EftSuspiciousApprove.propTypes = {
	Disabled: PropTypes.bool
};

export default withArtifex(EftSuspiciousApprove, {});