import { withStyles } from "@material-ui/core";
import digitalVerificationPanelStyle from "assets/jss/material-dashboard-pro-react/views/digitalVerificationPanelStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Formatter from "core/Formatter";
import StringHelper from "core/StringHelper";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import KycManualDigitalVerificationStatusPanel from "views/AmlKyc/ManualKycApplication/KycManualDigitalVerificationStatusPanel";
import { GenericCheckInput, GenericLabel, GenericTextInput } from "views/Components/Generic";
import TabsComponent from "views/Components/TabsComponent";

class DigitalVerificationPanel extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};
		this.tempObject = {};
		this.statusWrapperStyle = {
			position: "relative",
			top: "-45px",
			right: "-490px",
			padding: "0px 5px",
			width: 500,
		};
		this.statusGridItemContainerStyle = { position: "relative" };
		this.checkBoxGroupStyle = { marginTop: 4 };
		this.placeholderNA = "N/A";
		this.styleMargin10 = { margin: "10px" };
	}
	GetTabList = () => {
		var tabList = [
			{
				tabButton: "Id Information",
				tabContent: this.VerificationPanelIdInformationTab()
			},
			{
				tabButton: "Address Information",
				tabContent: this.VerificationPanelAddressInformation()
			},
			{
				tabButton: "Score",
				tabContent: this.VerificationPanelScore()
			},
		];
		if (!this.props.ShowOnlyCard) {
			tabList.push(
				{
					tabButton: "Other Information",
					tabContent: this.VerificationPanelOtherInformation()
				});
		}
		return tabList;
	}
	VerificationPanel() {
		return (
			<GridContainer direction="column">
				<GridItem xs={12}>
					<TabsComponent
						tabList={this.GetTabList()}
					/>
				</GridItem>
			</GridContainer>
		);
	}
	VerificationPanelScoreOnly() {

		const { VerificationModel, classes } = this.props;
		var objectIndex = 4;
		var propertyCount = 2;

		var avgScore = 0;
		var remoteAvgScore = VerificationModel?.AvgScore > 0 ? (VerificationModel?.AvgScore * 100) : 0;
		var idQualityScore = VerificationModel?.IdQuality >= 0 ? (VerificationModel?.IdQuality * 100) : 0;

		avgScore += idQualityScore / propertyCount;
		avgScore += remoteAvgScore / propertyCount;

		avgScore = remoteAvgScore || Math.floor(avgScore);
		var resultScore = !VerificationModel ? null : avgScore;


		var colorAndMsg = this.GetColorAndTextByRatio(resultScore);
		var statusBoxStyle = {
			backgroundColor: colorAndMsg.backgroundColor,
			width: "100%",
		};
		var comp = (
			<div className={classes.verificationPanelScoreOnly} >

				<div style={this.styleMargin10}><GenericLabel FontSize={20} Text={`Average Score: ${resultScore ? "%" + (resultScore) : "N/A"}`} /></div>

				{/* {this.VerificationResultComponent(resultScore, "")} */}
				<div className={classes.statusBox} style={statusBoxStyle}>
					<GenericLabel Text={colorAndMsg.message} />
				</div>

			</div>
		);
		this.tempObject[objectIndex] = comp;
		return this.tempObject[objectIndex];
	}
	VerificationPanelIdInformationTab() {
		var objectIndex = 0;
		const { classes, VerificationModel } = this.props;

		var avgScore = 0;
		var propertyCount = 8;
		var scorePerProperty = 100 / propertyCount;

		if (VerificationModel?.FirstName) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.LastName) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.CardIdType) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.CardIdNumber) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.CardIdExpDate) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.CardPlaceOfIssue) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.CardCountry) {
			avgScore += scorePerProperty;
		}
		if (VerificationModel?.CardProvince) {
			avgScore += scorePerProperty;
		}
		avgScore = Math.floor(avgScore);
		var resultScore = !VerificationModel ? null : avgScore;

		var comp = (<>
			<div className={classes.tabPageContainer}>
				<GridContainer xs={12} direction="column" className={classes.statusContainer}>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="First Name"
							Value={VerificationModel?.FirstName || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Last Name"
							Value={VerificationModel?.LastName || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="ID Type"
							Value={VerificationModel?.CardIdType || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="ID Number"
							Value={VerificationModel?.CardIdNumber || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="ID Exp Date"
							Value={VerificationModel?.CardIdExpDate && Formatter.FormatDate(VerificationModel.CardIdExpDate) || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Place of Issue"
							Value={VerificationModel?.CardPlaceOfIssue || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Issue Date"
							Value={VerificationModel?.CardIssueDate && Formatter.FormatDate(VerificationModel.CardIssueDate) || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Birth Date"
							Value={VerificationModel?.CardBirthDate && Formatter.FormatDate(VerificationModel.CardBirthDate) || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
				</GridContainer>
				{this.VerificationResultComponent(resultScore, "Information Matching Result")}
			</div>
		</>);

		this.tempObject[objectIndex] = comp;
		return this.tempObject[objectIndex];
	}
	VerificationPanelAddressInformation() {
		var objectIndex = 1;
		const { classes, VerificationModel } = this.props;

		var addressLine1 = "";
		if (VerificationModel?.IdStreet?.length > 0) {
			addressLine1 += VerificationModel?.IdStreet;
		}
		if (VerificationModel?.IdStreetNumber?.length > 0) {
			if (addressLine1.length > 0) addressLine1 += ", ";
			addressLine1 += VerificationModel?.IdStreetNumber;
		}

		var addressLine2 = VerificationModel?.IdUnit ?? "";


		var avgScore = 0;
		var propertyCount = 6;
		var scorePerProperty = 100 / propertyCount;
		var multiplierPerProperty = (scorePerProperty / 100);

		if (VerificationModel?.AddressLine1 && addressLine1) {
			avgScore += StringHelper.GetSimilarity(VerificationModel?.AddressLine1, addressLine1) * multiplierPerProperty;
		}
		if (VerificationModel?.AddressLine2 && addressLine2) {
			avgScore += StringHelper.GetSimilarity(VerificationModel?.AddressLine2, addressLine2) * multiplierPerProperty;
		}
		if (VerificationModel?.PostalCode && VerificationModel?.IdPostalCode) {
			avgScore += StringHelper.GetSimilarity(VerificationModel?.PostalCode, VerificationModel?.IdPostalCode) * multiplierPerProperty;
		}
		if (VerificationModel?.Country && VerificationModel?.IdCountry) {
			avgScore += StringHelper.GetSimilarity(VerificationModel?.Country, VerificationModel?.IdCountry) * multiplierPerProperty;
		}
		if (VerificationModel?.Province && VerificationModel?.IdProvince) {
			avgScore += StringHelper.GetSimilarity(VerificationModel?.Province, VerificationModel?.IdProvince) * multiplierPerProperty;
		}
		if (VerificationModel?.City && VerificationModel?.IdCity) {
			avgScore += StringHelper.GetSimilarity(VerificationModel?.City, VerificationModel?.IdCity) * multiplierPerProperty;
		}
		avgScore = Math.floor(avgScore);

		var resultScore = !VerificationModel || ("" + addressLine1 + addressLine2)?.length == 0 ? null : avgScore;
		var comp = (<>
			<div className={classes.tabPageContainer}>
				<GridContainer direction="column" className={classes.statusContainer}>

					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Address Line 1"
							Value={addressLine1 || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Address Line 2"
							Value={addressLine2 || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Postal Code"
							Value={VerificationModel?.IdPostalCode || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Country"
							Value={VerificationModel?.IdCountry || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Province"
							Value={VerificationModel?.IdProvince || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="City"
							Value={VerificationModel?.IdCity || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
				</GridContainer>
				{this.VerificationResultComponent(resultScore, "Information Matching Result", this.placeholderNA)}
			</div>
		</>);
		this.tempObject[objectIndex] = comp;
		return this.tempObject[objectIndex];
	}
	VerificationPanelScore() {

		const { classes, VerificationModel, ShowOnlyCard } = this.props;
		var objectIndex = 2;
		var propertyCount = ShowOnlyCard ? 2 : 6;

		var avgScore = 0;
		var remoteAvgScore = VerificationModel?.AvgScore > 0 ? (VerificationModel?.AvgScore * 100) : 0;
		var idQualityScore = VerificationModel?.IdQuality >= 0 ? (VerificationModel?.IdQuality * 100) : 0;
		var photoQualityScore = VerificationModel?.PhotoQuality >= 0 ? (VerificationModel?.PhotoQuality * 100) : 0;
		var idFacePhotoMatchScore = VerificationModel?.IdFacePhotoMatch >= 0 ? (VerificationModel?.IdFacePhotoMatch * 100) : 0;
		var nameScore = VerificationModel?.NameConfidence >= 0 ? (VerificationModel?.NameConfidence * 100) : 0; //StringHelper.GetSimilarity(model?.FirstName, VerificationModel?.FirstName);
		//var lastNameScore = StringHelper.GetSimilarity(model?.LastName, VerificationModel?.LastName);
		var birtdateScore = VerificationModel?.BirthdateMatch >= 0 ? (VerificationModel?.BirthdateMatch * 100) : 0; //StringHelper.GetSimilarity(model?.BirthDate && Formatter.FormatDate(model?.BirthDate), VerificationModel?.CardBirthDate && Formatter.FormatDate(VerificationModel?.CardBirthDate));
		var addressScore = (VerificationModel?.CanadaPostScore / 2) + ((VerificationModel?.AddressConfidence >= 0 ? (VerificationModel?.AddressConfidence * 100) : 0) / 2);


		avgScore += idQualityScore / propertyCount;
		if (!ShowOnlyCard) {
			avgScore += photoQualityScore / propertyCount;
			avgScore += idFacePhotoMatchScore / propertyCount;
			avgScore += nameScore / propertyCount;
			avgScore += birtdateScore / propertyCount;
			avgScore += addressScore / propertyCount;
		}
		else {
			avgScore += remoteAvgScore / propertyCount;
			avgScore = remoteAvgScore || avgScore;
		}


		avgScore = Math.floor(avgScore);
		var resultScore = !VerificationModel ? null : avgScore;

		var comp = (<>
			<div className={classes.tabPageContainer}>
				<GridContainer direction="column" className={classes.statusContainer}>

					<GridItem xs={2}>
						<GenericTextInput
							LabelText="ID Quality"
							Value={(VerificationModel?.IdQuality >= 0 && "%" + (idQualityScore)) || ""}
							Disabled={true}
						/>
					</GridItem>
					{!ShowOnlyCard &&
						<>
							<GridItem xs={2}>
								<GenericTextInput
									LabelText="Photo Quality"
									Value={(VerificationModel?.PhotoQuality >= 0 && "%" + (photoQualityScore)) || ""}
									Disabled={true}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericTextInput
									LabelText="ID Face Photo Match"
									Value={(VerificationModel?.IdFacePhotoMatch >= 0 && "%" + (idFacePhotoMatchScore)) || ""}
									Disabled={true}
								/>
							</GridItem>

							<GridItem xs={2}>
								<GenericTextInput
									LabelText="Name Match"
									Value={(VerificationModel?.AvgScore >= 0 && "%" + (nameScore)) || ""}
									Disabled={true}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericTextInput
									LabelText="Date of Birth Match"
									Value={(VerificationModel?.AvgScore >= 0 && "%" + (birtdateScore)) || ""}
									Disabled={true}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericTextInput
									LabelText="Address Match"
									Value={(VerificationModel?.AvgScore >= 0 && "%" + (addressScore)) || ""}
									Disabled={true}
								/>
							</GridItem>
						</>
					}
					{ShowOnlyCard &&
						<GridItem xs={2}>
							<GenericTextInput
								LabelText="ID Match Score"
								Value={(VerificationModel?.AvgScore >= 0 && "%" + (remoteAvgScore)) || ""}
								Disabled={true}
							/>
						</GridItem>
					}
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Average Score"
							Value={(VerificationModel?.AvgScore >= 0 && "%" + (avgScore)) || ""}
							Disabled={true}
						/>
					</GridItem>
				</GridContainer>
				{this.VerificationResultComponent(resultScore, "Verification Status")}
			</div>
		</>);
		this.tempObject[objectIndex] = comp;
		return this.tempObject[objectIndex];
	}
	VerificationPanelOtherInformation() {

		const { classes, VerificationModel } = this.props;
		var objectIndex = 3;
		var comp = (<>
			<div className={classes.tabPageContainer}>
				<GridContainer direction="column" className={classes.statusContainer}>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="IP Address"
							Value={VerificationModel?.IpAddress || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="IP Country"
							Value={VerificationModel?.IpCountry || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="IP Province"
							Value={VerificationModel?.IpProvince || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="IP City"
							Value={VerificationModel?.IpCity || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="IP Postal Code"
							Value={VerificationModel?.IpPostalCode || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Referer"
							Value={VerificationModel?.Referer || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="User Agent"
							Value={VerificationModel?.UserAgent || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="User Type"
							Value={VerificationModel?.UserType || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="ISP"
							Value={VerificationModel?.Isp || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>
					<GridItem xs={2}>
						<GenericTextInput
							LabelText="Organization"
							Value={VerificationModel?.Organization || (VerificationModel && this.placeholderNA) || ""}
							Disabled={true}
						/>
					</GridItem>

					<GridItem xs={2}>
						<GridContainer>
							<GridItem xs={6}>
								<GenericTextInput
									LabelText="Latitude"
									Value={VerificationModel?.IpLocationLatitude || (VerificationModel && this.placeholderNA) || ""}
									Disabled={true}
								/>
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									LabelText="Longitude"
									Value={VerificationModel?.IpLocationLongitude || (VerificationModel && this.placeholderNA) || ""}
									Disabled={true}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={2}>
						<GridContainer style={this.checkBoxGroupStyle}>
							<GridItem xs={4}>
								<GenericCheckInput
									LabelText="Anonymous"
									Value={VerificationModel?.IsAnonymous}
									Disabled={true} />
							</GridItem>
							<GridItem xs={4}>
								<GenericCheckInput
									LabelText="Anonymous Vpn"
									Value={VerificationModel?.IsAnonymousVpn}
									Disabled={true} />
							</GridItem>
							<GridItem xs={4}>
								<GenericCheckInput
									LabelText="Anonymous Hosting"
									Value={VerificationModel?.IsAnonymousHosting}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		</>);
		this.tempObject[objectIndex] = comp;
		return this.tempObject[objectIndex];
	}

	VerificationTitleText = (titleText) => {
		const { classes } = this.props;
		return (
			<div className={classes.statusLabel}>
				<GenericLabel Text={titleText} />
			</div>
		);
	}
	VerificationStatusBox = (message) => {
		const { classes } = this.props;
		return (
			<div className={classes.statusBox} style={this.statusBoxStyle}>
				<GenericLabel Text={message} />
			</div>
		);
	}

	GetColorAndTextByRatio = (ratio, defaultMessage) => {

		var message = "Not verified yet";

		var backgroundColor = "#b5b5b5cc";

		if (ratio > 70) {
			backgroundColor = "#27e32dcc";
			message = "High";
		} else if (ratio > 40) {
			backgroundColor = "#e3a126cc";
			message = "Medium";
		} else if (ratio > 0 || ratio === 0) {
			backgroundColor = "#ff2a00cc";
			message = "Low";
		}
		else {
			message = defaultMessage ?? message;
		}

		return { message, backgroundColor };
	}
	VerificationResultComponent = (ratio, titleText, customMessage) => {
		this.statusBoxStyle = {
			marginBottom: 18,
			height: 22
		};
		var colorAndMsg = this.GetColorAndTextByRatio(ratio, customMessage);
		this.statusBoxStyle.backgroundColor = colorAndMsg.backgroundColor;
		return (
			<GridContainer style={this.statusWrapperStyle}>
				<GridItem xs={12}>
					{this.VerificationTitleText(titleText)}
				</GridItem>
				<GridItem xs={12}>
					{this.VerificationStatusBox(colorAndMsg.message)}

				</GridItem>
			</GridContainer >
		);
	}

	render() {
		const { Model } = this.props;
		const { ShowOnlyCard, ScoreOnly } = this.props;
		return (
			<KycManualDigitalVerificationStatusPanel
				key={Model?.IdentityPhotoId}
				ExecuteApiPost={this.props.ExecuteApiPost}
				IdentityPhotoId={Model?.IdentityPhotoId}
				IdentityBackPhotoId={Model?.IdentityPhotoBackId}
				ProfilePhotoId={Model?.UserPhotoId}
				Gender={Model?.genderValue}
				ShowOnlyCard={ShowOnlyCard}
				ScoreOnly={ScoreOnly}
				LabelMd={ScoreOnly ? 8 : 5}>
				{ScoreOnly ? this.VerificationPanelScoreOnly() : this.VerificationPanel()}
			</KycManualDigitalVerificationStatusPanel>
		);
	}
}

DigitalVerificationPanel.propTypes = {
	ExecuteApiPost: PropTypes.any.isRequired,
	ShowOnlyCard: PropTypes.bool,
	ScoreOnly: PropTypes.bool,
	Model: PropTypes.shape({
		IdentityPhotoId: PropTypes.number,
		IdentityPhotoBackId: PropTypes.number,
		UserPhotoId: PropTypes.number,
		Gender: PropTypes.oneOf(["M", "F"])
	}).isRequired,
	VerificationModel: PropTypes.shape({
		AddressConfidence: PropTypes.number,
		AddressLine1: PropTypes.any,
		AddressLine2: PropTypes.any,
		AvgScore: PropTypes.number,
		BirthdateMatch: PropTypes.number,
		CardBirthDate: PropTypes.func,
		CardCountry: PropTypes.any,
		CardIdExpDate: PropTypes.func,
		CardIdNumber: PropTypes.any,
		CardIdType: PropTypes.any,
		CardIssueDate: PropTypes.func,
		CardPlaceOfIssue: PropTypes.any,
		CardProvince: PropTypes.any,
		City: PropTypes.any,
		Country: PropTypes.any,
		FirstName: PropTypes.any,
		IdCity: PropTypes.any,
		IdCountry: PropTypes.any,
		IdFacePhotoMatch: PropTypes.number,
		IdPostalCode: PropTypes.any,
		IdProvince: PropTypes.any,
		IdQuality: PropTypes.number,
		IdStreet: PropTypes.string,
		IdStreetNumber: PropTypes.string,
		IdUnit: PropTypes.string,
		IpAddress: PropTypes.string,
		IpCity: PropTypes.string,
		IpCountry: PropTypes.string,
		IpLocationLatitude: PropTypes.string,
		IpLocationLongitude: PropTypes.string,
		IpPostalCode: PropTypes.string,
		IpProvince: PropTypes.string,
		IsAnonymous: PropTypes.any,
		IsAnonymousHosting: PropTypes.any,
		IsAnonymousVpn: PropTypes.any,
		Isp: PropTypes.string,
		LastName: PropTypes.any,
		NameConfidence: PropTypes.number,
		Organization: PropTypes.string,
		PhotoQuality: PropTypes.number,
		PostalCode: PropTypes.any,
		Province: PropTypes.any,
		Referer: PropTypes.string,
		UserAgent: PropTypes.string,
		UserType: PropTypes.string,
		CanadaPostScore: PropTypes.number,
	}).isRequired
};

export default withStyles(digitalVerificationPanelStyle)(DigitalVerificationPanel);