import { FormControl, InputLabel, withStyles } from "@material-ui/core";
import genericSelectInputStyle from "assets/jss/generic/genericSelectInputStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ResourceHelper from "core/ResourceHelper.js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";

const groupStyles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
};

class GenericFilterSelectInput extends Component {

	constructor(props) {
		super(props);

		this.state = {
			Data: [],
			isLoading: false
		};

		this.FetchDataCallback = this.FetchDataCallback.bind(this);
		this.ErrorCallback = this.ErrorCallback.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { Url, Parameter } = this.props;
		if (JSON.stringify(Parameter) !== JSON.stringify(prevProps.Parameter)) {
			this.setState({ isLoading: true });
			Proxy.POST(Url, Parameter, this.FetchDataCallback, this.ErrorCallback);
		}
	}
	handleChange(selectedOption) {
		const { Name, KeyValueMember, ValueChanged, StaticData } = this.props;
		const { Data } = this.state;
		const data = StaticData || Data;

		this.setState({ selectedOption: selectedOption });
		if (ValueChanged) {
			var newValue = selectedOption != null ? selectedOption.value : null;
			ValueChanged(Name, newValue, data.find(x => x[KeyValueMember] == newValue));
		}
	}
	FetchDataCallback(response) {
		const { DataRoot } = this.props;

		this.setState({ Data: response[DataRoot] || [], isLoading: false });
	}

	ErrorCallback(error) {
		console.log(error);
		this.setState({ isLoading: false });
	}
	Map(data) {
		if (data == null || data.length == 0)
			return;

		const { KeyValueMember, TextValueMember, RenderItem, All, MultiLanguage } = this.props;

		var returnData = data.map(d => {
			var label = d[TextValueMember];
			if (RenderItem)
				label = RenderItem(d);
			else if (MultiLanguage)
				label = ResourceHelper.Get(label);

			return { value: d[KeyValueMember], label: label };
		});
		if (All == true) {
			returnData.unshift({ value: -1, label: "All" });
		}
		return returnData;
	}

	GetStyles() {
		const { Required, IsInvalid } = this.props;

		var borderColor = "#e9eaec";
		if (IsInvalid) {
			borderColor = window.InvalidFieldColor;
		}
		else if (Required) {
			borderColor = window.RequiredFieldColor;
		}

		return {
			control: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					border: `1px solid ${borderColor}`,
					borderRadius: "unset",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					},
					fontWeight: "400"
				}),
			valueContainer: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						background: "unset",
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important",
						padding: "unset"
					},
					padding: "unset"
				}),
			indicatorsContainer: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					}
				}),
			option: (base) => (
				{
					...base,
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					}
				}),
			container: (base) => ({
				...base
			}),
		};
	}

	render() {

		const { classes, LabelText, Name, Value, KeyValueMember, TextValueMember, Disabled,
			StaticData, LabelMd, TitleLabel, LabelStyle, SelectStyle, RenderItem, CanClear } = this.props;
		const { Data } = this.state;
		const data = StaticData || Data;

		var tempSelectedOption = {};
		if (Value != null) {
			if (Value == -1) {
				tempSelectedOption.value = -1;
				tempSelectedOption.label = "All";
			} else {
				if (data.find(x => x[KeyValueMember] == Value) == undefined) {
					tempSelectedOption.value = -2;
					tempSelectedOption.label = (TitleLabel == null || TitleLabel == "") ? "Select One" : TitleLabel;
				} else {
					tempSelectedOption.value = Value;
					tempSelectedOption.label = RenderItem ? RenderItem(data.find(x => x[KeyValueMember] == Value)) : data.find(x => x[KeyValueMember] == Value)[TextValueMember];
				}
			}
		}

		var lastData = this.Map(StaticData || Data);
		const formatGroupLabel = () => (
			<div style={groupStyles}>
				<span>{TitleLabel == null || undefined || "" ? LabelText : TitleLabel}</span>
			</div>
		);

		const groupedOptions = [
			{
				label: (TitleLabel == null || TitleLabel == "") ? LabelText : TitleLabel,
				options: this.Map(StaticData || Data),
			}
		];

		return (
			<FormControl fullWidth className={classes.selectFormControl}>
				<GridContainer
					direction="row" justify="flex-start"
					alignItems="flex-end" style={{ marginTop: 6 }}				>
					{LabelText != undefined && LabelText != "" &&
						<GridItem xs={LabelMd == undefined ? 4 : (LabelMd == -1 ? 0 : LabelMd)} style={LabelStyle}>
							<InputLabel htmlFor={Name} className={classes.selectLabel} >
								{LabelText}
							</InputLabel>
						</GridItem>
					}
					<GridItem xs={LabelMd == undefined ? 8 : (LabelMd == -1 ? 0 : 12 - LabelMd)} style={SelectStyle}>
						<div>
							<Select
								formatGroupLabel={formatGroupLabel}
								placeholder={TitleLabel == null || undefined || "" ? "Select One" : TitleLabel}
								isDisabled={Disabled}
								styles={this.GetStyles()}
								value={Value != null ? tempSelectedOption : null}
								onChange={this.handleChange}
								options={lastData != null && lastData.length > 0 ? groupedOptions : lastData}
								isClearable={CanClear}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										primary25: "rgb(246, 246, 246)",
										primary: "black",
										primary50: "rgb(246, 246, 246)",
										primary75: "rgb(246, 246, 246)"
									},
								})}
							/>
						</div>
					</GridItem>
				</GridContainer>
			</FormControl>
		);
	}
}

GenericFilterSelectInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.any,
	Value: PropTypes.any,
	Name: PropTypes.string,
	LabelText: PropTypes.string,
	KeyValueMember: PropTypes.string,
	TextValueMember: PropTypes.string,
	Url: PropTypes.string,
	Parameter: PropTypes.object,
	DataRoot: PropTypes.string,
	Disabled: PropTypes.bool,
	RenderItem: PropTypes.func,
	All: PropTypes.bool,
	LabelMd: PropTypes.number,
	TitleLabel: PropTypes.string,
	LabelStyle: PropTypes.object,
	SelectStyle: PropTypes.object,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool,
	CanClear: PropTypes.bool
};

GenericFilterSelectInput.defaultProps = {
	LabelMd: 4,
	CanClear: true
};

export default withStyles(genericSelectInputStyle)(GenericFilterSelectInput);