import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import { ProgramCodes } from "views/Constants/Constant.js";
import GridButton from "views/Components/GridButton";

class AssignDDTAccount extends React.Component {
	constructor(props) {
		super(props);

		this.emptyModel = {
			Id: null,
			Country: null,
			UniqueClientId: null,
			Customer: null,
			SelectedAccountId: null,
			SelectedCardId: null,
			AccountList: [],
		};

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Modify"
							OnClick={() => { this.handleSelect(row.original, row.index); }}
							Icon={EditIcon} 
						/>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 80
			},
			{
				Header: "Cardholder Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Masked Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Balance",
				accessor: "CardBalance"
			},
			{
				Header: "Limit",
				accessor: "CardUsageLimit"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Wallet Program",
				accessor: "AccountProgram"
			}
		];

		this.state = {
			alert: null,
			vModel: {},
			list: [],
			model: this.emptyModel,
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Assign Reportable Wallet To Customer");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const dialogModel = { ...this.state.dialogModel };
		const emptyModel = { ...this.emptyModel };

		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.AccountList = newValue.Accounts;
				} else {
					model.AccountList = [];
				}
			} else {
				model.Customer = emptyModel.Customer;
				model.AccountList = [];
			}
		} else if (name === "UniqueClientId") {
			if (ClientHelper.IsClient() && emptyModel.UniqueClientId == null && newValue != null) {
				this.emptyModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else {
			model[name] = newValue;
		}

		this.setState({ model });
	};

	handleClear = () => {
		this.setState({
			model: this.emptyModel,
			alert: null,
			list: []
		});
	};

	handleSearch = () => {
		if (!this.validateSearch()) {
			return;
		}
		let model = { ...this.state.model };
		this.setState({ isLoading: true });
		const request = {
			UniqueClientId: model.UniqueClientId,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id
		};
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetCardSummaryList",
			request,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	};
	handleSubmit = () => {
		if (!this.validateSubmit()) {
			return;
		}
		this.setState({ isLoading: true });
		const { model } = this.state;
		const request = {
			UniqueClientId: model.UniqueClientId,
			CardId: model.SelectedCardId == null ? null : model.SelectedCardId,
			BankAccountId: model.SelectedAccountId
		};
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/UpdateCardAccount",
			request,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				model.SelectedCardId = null;
				model.SelectedAccountId = null;
				this.setState({ model }, () => { this.handleSearch(); });

				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	};

	handleSelect = (data, index) => {
		const { model } = this.state;
		var clonedData = Object.assign({}, data);
		model.SelectedCardId = clonedData.Id;
		model.SelectedAccountId = clonedData.AccountId;
		model.Update = true;
		this.setState({ model });
	};

	validateSearch = () => {
		var errorList = [];
		const { model } = this.state;
		let vModel = { ...this.state.vModel };
		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == 0;
		if (vModel.UniqueClientId) errorList.push("Client must be selected!");
		vModel.Customer = model.Customer == null || model.Customer.Id == null || model.Customer.Id == 0;
		if (vModel.Customer) errorList.push("Customer must be selected!");
		this.setState({ vModel });
		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	validateSubmit = () => {
		var errorList = [];
		const { model } = this.state;
		let vModel = { ...this.state.vModel };
		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == 0;
		if (vModel.UniqueClientId) errorList.push("Client must be selected!");
		vModel.Customer = model.Customer == null || model.Customer.Id == null || model.Customer.Id == 0;
		if (vModel.Customer) errorList.push("Customer must be selected!");
		vModel.SelectedCardId = model.SelectedCardId == null || model.SelectedCardId == 0;
		if (vModel.SelectedCardId) errorList.push("Card must be selected!");
		vModel.SelectedAccountId = model.SelectedAccountId == null || model.SelectedAccountId == 0;
		if (vModel.SelectedAccountId) errorList.push("New wallet must be selected!");
		this.setState({ vModel });
		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	render() {
		const { Disabled } = this.props;
		const { model, vModel, alert, list, isLoading } = this.state;
		return (
			<React.Fragment>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled, ValidationFunction: this.validateSearch },
						{ Code: "Update", OnClick: this.handleSubmit, Disabled: (Disabled || model.SelectedCardId == null || model.SelectedCardId == 0 || model.Update != true), ValidationFunction: this.validateSubmit },
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
						{ Code: "Submit", OnClick: this.handleSubmit, Disabled: (Disabled || model.SelectedCardId == null || model.SelectedCardId == 0 || model.Update), ValidationFunction: this.validateSubmit },

					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={3}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : null }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
													Required
													IsInvalid={vModel.UniqueClientId} />
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<CustomerComponent
												CustomerNumber={model.Customer == null ? null : model.Customer.CustomerNumber}
												UniqueClientId={model.UniqueClientId}
												FillCallback={customer => { this.handleChange("Customer", customer, null); }}
												Programs={[ProgramCodes.Prepaid]}
												Disabled={model.UniqueClientId == null}
												ShowCustomerProgram={true}
												LabelMd={5}
												Required
												IsInvalid={vModel.Customer} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.Customer && model.Customer.Id}
												Name="SelectedCardId"
												LabelText="Card Number"
												Method="POST"
												Url="/prepaidapi/v1.0/Card/GetCardSummaryList"
												Parameter={{
													UniqueClientId: model.UniqueClientId || 0,
													ClientCustomerId: model.Customer == null ? 0 : (model.Customer.Id || 0)
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.EmbossName1} - ${d.CardNumber}`}
												Value={model.SelectedCardId || 0}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.SelectedCardId}
												Disabled={model.Update || model.Customer == null}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GridItem>
												<GenericSelectInput
													key={model.Customer && model.Customer.Id}
													Name="SelectedAccountId"
													LabelText="Wallet"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomer/SearchBankAccount"
													Parameter={{
														UniqueClientId: model.UniqueClientId,
														ClientCustomerId: model.Customer == null ? null : model.Customer.Id,
														Programs: [ProgramCodes.Bank_Programs_CDIC, ProgramCodes.Prepaid]
													}}
													DataRoot="Item"
													KeyValueMember="AccountId"
													RenderItem={d => `${d.AccountNumber} - ${d.AccountName}`}
													Value={model.SelectedAccountId}
													ValueChanged={this.handleChange}
													Disabled={model.Customer == null}
													Required
													IsInvalid={vModel.Account}
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</React.Fragment>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
}

AssignDDTAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(AssignDDTAccount, {});
