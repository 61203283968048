import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import React, { Component } from "react";
import { Proxy, withArtifex } from "core";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericNumberInput, GenericTextInput, GenericGrid, GenericSelectInput, GenericDialog, GenericDialogActions, GenericLabel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import CardHeader from "components/Card/CardHeader";
import { GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";
import GridButton from "views/Components/GridButton";
import { RowDetailIcon, DeleteIcon } from "core/Icons";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import AlertHelper from "core/AlertHelper";

class MastercardTransactionSearch extends Component {
	constructor(props) {
		super(props);
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.sortParamDesc = { Member: "ParameterDesc" };
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			getDataList: [],
			isLoading: false,
			isRetrievePaymentDialogOpen: false,
			responseForRetrievePayment: {},
			responseForCancelPayment: {}
		};
		this.Columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: d => (
					<div>
						<GridButton
							Icon={RowDetailIcon}
							tooltip="Retrieve Payment"
							OnClick={() => this.openRetrievePaymentDialog(d.original)} />
						<GridButton
							Icon={DeleteIcon}
							tooltip="Cancel Payment"
							OnClick={() => this.ShowQuestionMessage("Cancel this payment !", d.original) } />
					</div>
					//this.openCancelPaymentDialog(d.original.PaymentId)
				),
				sortable: false,
				filterable: false,
				width: 70
			},
			{
				Header: "Client Name",
				accessor: "UniqueClientName",
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionState",
			},
			{
				Header: "Name",
				accessor: "ClientCustomerName"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "SurName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "CAD Amount",
				accessor: "CadAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Rate",
				accessor: "BuyingRateOfExchangeAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Original Amount",
				accessor: "TransactionAmount"				
			},
			{
				Header: "Sender Wallet",
				accessor: "SourceAccount"
			},
			{
				Header: "Destination Wallet",
				accessor: "DestinationAccount"
			},
			{
				Header: "Client Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "TransactionReferenceNumber",
				accessor: "TransactionReferenceNumber"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Created At",
				accessor: "MastercardCreatedAt",
			},
			{
				Header: "Status Timestamp",
				accessor: "StatusTimestamp",
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientUniqueClientId",
				show: this.isBackOffice
			},
			{
				Header: "Unique Client Name",
				accessor: "UniqueClientName",
				show: this.isBackOffice
			},
			{
				Header: "Email",
				accessor: "Email"
			},

			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},

			{
				Header: "Wallet Number",
				accessor: "AccountNumber",
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName",
			},
			{
				Header: "WalletOwnerName",
				accessor: "AccountOwnerName",
			},
			{
				Header: "Direction",
				accessor: "Direction",
			},
			{
				Header: "Trace Id",
				accessor: "TraceId",
			},
			{
				Header: "Client Reference Number",
				accessor: "ClientReferenceNumber"
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Bank Code",
				accessor: "BankCode"
			},
			{
				Header: "Cross Border Reference Number",
				accessor: "ClientReferenceNumber"
			},
			{
				Header: "PaymentId",
				accessor: "PaymentId"
			}
		];
		this.trxTable = React.createRef();
		
		this.emptyObject = {};
		this.sortedCustomerName = { Member: "Name" };
	}
	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) {
			setAppLeftTitle("Transaction Search");
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("CARD TRANSFER");
		}
		this.setState({ loadingCompleted: true });
	}

	handleSearch = async () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var model = { ...this.state.model };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/MastercardXBorder/MastercardTransactionSearch", model, null, null, null, null, null);
		if (responseData != null) {
			this.setState({
				getDataList: responseData.length < 1 ? [] : responseData, isLoading: false
			});
		}
		else {
			this.setState({ isLoading: false });
		}
	}
	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	handleClear = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(), ToDate: DateHelper.GetDate(),
			},
			isRetrievePaymentDialogOpen: false
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	ShowQuestionMessage = (message, model) => {
		this.setState({
			alert: AlertHelper.CreateQuestionAlert("Delete", message, "warning", e => {
				this.cancelPayment(model);
				this.HideAlert();
			}, () => {
				this.HideAlert();
			})
		});
	}

	openRetrievePaymentDialog = (model) => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/MastercardXBorder/RetrievePayment",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ responseForRetrievePayment: responseData.Item.RetrievePayment,  isRetrievePaymentDialogOpen: true});
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	 };

	 cancelPayment = (model) => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/MastercardXBorder/CancelPayment",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Operation was successfuly!");
				this.setState({ responseForCancelPayment: responseData.Item });
				this.handleSearch();
				
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	 };
	render() {
		const { Disabled, classes } = this.props;
		const { model, isLoading, getDataList, isRetrievePaymentDialogOpen, responseForRetrievePayment, responseForCancelPayment} = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Main Parameters">
								<CardBody>
									<GridContainer>
										<GridItem xs={5}>

											{ClientHelper.IsBackOffice() &&
												< GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Method="POST"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Name"
													Sorted={this.sortedCustomerName}
													Value={model.UniqueClientId}
													ValueChanged={this.handleChange} />}
											<GenericNumberInput
												Name="Amount"
												LabelText="Amount"
												Value={model ? model == null ? "" : model.Amount == null ? "" : model.Amount : ""}
												ValueChanged={this.handleChange}
												Prefix="$"
											/>
											<GenericSelectInput
												Name="CurrencyId"
												LabelText="Currency"
												Url="/bankapi/v1.0/Currency/ValidCurrencies"
												Method="GET"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.CurrencyId}
												ValueChanged={this.handleChange} />
											<ParameterComponent
												Name="TransactionTypeId"
												LabelText="Type"
												ParameterCode="TransactionType"
												Value={model.TransactionTypeId}
												ValueChanged={this.handleChange}
												Sorted={this.sortParamDesc}
											/>
											<GenericTextInput
												Name="PaymentId"
												LabelText="Payment ID"
												Value={model == null ? "" : model.PaymentId ? model.PaymentId : ""}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={1} />
										<GridItem xs={5}>
											<GenericDateInput
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate ? DateHelper.ToDateInputValueAsUtc(model.StartDate) : ""}
												ValueChanged={this.handleChange}
												Utc
												MaxDate={model.EndDate || DateHelper.GetDate()}
											/>
											<GenericDateInput
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate ? DateHelper.ToDateInputValueAsUtc(model.EndDate) : ""}
												ValueChanged={this.handleChange}
												Utc
												MinDate={model.StartDate}
												MaxDate={DateHelper.GetDate()}
											/>
											<GenericTextInput
												Name="Name"
												LabelText="Name"
												Value={model == null ? "" : model.Name ? model.Name : ""}
												ValueChanged={this.handleChange} />
											<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer><br />
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
											</GridItem>
											<GridItem xs={8} />
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ServerSide
													RequestUrl="/bankapi/v1.0/MastercardXBorder/MastercardTransactionSearch"
													RequestMethod="POST"
													PrepareRequest={() => { return model; }}
													PageSize={10}
													ShowPagination={true}
													LoadAtStartup={this.state.loadingCompleted}
													Data={getDataList}
													Columns={this.Columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<GenericDialog open={isRetrievePaymentDialogOpen} maxWidth="md" fullWidth>
							<DialogTitle>
								<GenericLabel Text="Transaction Information" Bold={true} />
							</DialogTitle>
							<DialogContent>															
								<GenericTextInput
									Name="TransactionState"
									LabelText="Transaction Status"
									Value={responseForRetrievePayment.status}
									Disabled={true}
								/>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={() => this.setState({ isRetrievePaymentDialogOpen: false })}>Close</Button>
							</GenericDialogActions>
						</GenericDialog>

					</GridItem>
				</GridContainer>

			</div>
		);
	}
}

export default withArtifex(MastercardTransactionSearch, {});