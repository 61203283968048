import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class AssignDDTAccount extends React.Component {
	constructor(props) {
		super(props);

		this.emptyModel = {
			Id: null,
			Country: null,
			UniqueClientId: null,
			Customer: null,
			SelectedAccount: null,
			SelectedFindType: null,
			SelectedFindValue: null,
			SelectedCard: null,
			AccountList: [],
		};
		this.columns = [

			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Modify"
							Icon={EditIcon}
							OnClick={() => { this.handleSelect(row.original, row.index); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 80
			},
			{
				Header: "Client Name",
				accessor: "AccountOwner"
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomer.CustomerNumber"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "Wallet Number",
				accessor: "DDTNumber"
			},
			{
				Header: "Issued Date",
				accessor: "IssuedDate",
				type: GridColumnType.Date,
				ColumnType: GridColumnType.Date
			},
			{
				Header: "Expire Date",
				accessor: "ExpireDate",
				type: GridColumnType.Date,
				ColumnType: GridColumnType.Date
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "E-Mail",
				accessor: "Email"
			}
		];

		this.state = {
			alert: null,
			list: [],
			lastFilterModel: this.emptyModel,
			model: this.emptyModel,
			isLoading: false,
			vModel: {
				UniqueClientId:false,
				SelectedFindType:false,
				SelectedFindValue:false,
				Customer:false
			}
		};

		this.DDTFindType = [
			{
				Value: 2,
				Label: "From Wallet Id"
			},
			{
				Value: 3,
				Label: "From Wallet Number"
			}];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Assign Unreportable Wallet To Customer");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const emptyModel = { ...this.emptyModel };

		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.AccountList = newValue.Accounts;
				} else {
					model.AccountList = [];
				}
			} else {
				model.Customer = emptyModel.Customer;
				model.AccountList = [];
			}
		} else if (name === "UniqueClientId") {
			if (ClientHelper.IsClient() && emptyModel.UniqueClientId == null && newValue != null) {
				this.emptyModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		}

		model[name] = newValue;


		this.setState({ model });
	};

	handleClear = () => {
		this.setState({
			model: this.emptyModel,
			alert: null,
			list: []
		});
	};

	loadAccount = (action) => {
		const { model } = this.state;
		const request = {
			UniqueClientId: model.UniqueClientId,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id,
			Programs: [ProgramCodes.Bank_Programs_DDT, ProgramCodes.Bank_Programs_CDIC]
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/bankapi/v1.0/ClientCustomer/SearchBankAccount",
			request,
			responseData => {
				model.AccountList = responseData.Item;
				this.setState({ model }, action);
			}
		);
	}

	handleSearch = () => {
		if (!this.validateSearch()) {
			return;
		}

		this.loadAccount(() => {
			const { model } = this.state;
			this.search(model);
		});
	};

	search = (model) => {
		const request = {
			IsClientCustomerIdAssigned: true,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id
		};

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/DDT/DdtSearch", request,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item.DDTList });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleSubmit = () => {

		if (!this.validateSubmit()) {
			return;
		}
		this.setState({ isLoading: true });
		const { model } = this.state;
		const request = {
			DdtFindType: model.SelectedFindType,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id,
			CustomerNumber: model.SelectedFindType == 1 ? model.SelectedFindValue : null,
			AccountId: model.SelectedFindType == 2 ? model.SelectedFindValue : null,
			AccountNumber: model.SelectedFindType == 3 ? model.SelectedFindValue : null,
		};

		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/AssignClientCustomerIdForDdtAccount",
			request,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.handleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	};

	handleSelect = (data, index) => {
		const { model } = this.state;
		var clonedData = Object.assign({}, data);
		model.SelectedCard = clonedData;
		model.SelectedAccount = clonedData.AccountId;
		this.setState({ model });
	};

	validateSearch = () => {
		var errorList = [];
		const { model } = this.state;
		let vModel = { ...this.state.vModel };
		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == 0;
		if (vModel.UniqueClientId) errorList.push("Client must be selected!");
		vModel.Customer = model.Customer == null || model.Customer.Id == null || model.Customer.Id == 0;
		if (vModel.Customer) errorList.push("Customer must be selected!");
		this.setState({ vModel });
		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	validateSubmit = () => {
		var errorList = [];
		const { model } = this.state;
		let vModel = { ...this.state.vModel };
		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == 0;

		if(vModel.UniqueClientId) {
		errorList.push("Client must be selected!");}
		
		vModel.Customer = model.Customer == null || model.Customer.Id == null || model.Customer.Id == 0;
		if(vModel.Customer){
		 errorList.push("Customer must be selected!");}

		vModel.SelectedFindType = model.SelectedFindType == null || model.SelectedFindType == 0;
		if(vModel.SelectedFindType) {
		errorList.push("Wallet Find Method must be selected!");}

		if(!vModel.SelectedFindType) {
			vModel.SelectedFindValue = model.SelectedFindValue == null || model.SelectedFindValue == "";
			if (vModel.SelectedFindValue) {
			errorList.push("Wallet Find Value must be enter!");}
		}

		this.setState({ vModel });
		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	render() {
		const { Disabled } = this.props;
		const { model, vModel, alert, list, isLoading } = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />


				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled, ValidationFunction: this.validateSearch },
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
						{ Code: "Submit", OnClick: this.handleSubmit, Disabled: (Disabled || model.Customer == null || model.Customer.Id == 0), ValidationFunction: this.validateSubmit },

					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : null }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												Required
												IsInvalid={vModel.UniqueClientId} />
										</GridItem>
										<GridItem xs={4}>
											<CustomerComponent
												CustomerNumber={model.Customer == null ? null : model.Customer.CustomerNumber}
												UniqueClientId={model.UniqueClientId}
												FillCallback={customer => { this.handleChange("Customer", customer, null); }}
												Programs={[ProgramCodes.Prepaid]}
												Disabled={model.UniqueClientId == null}
												ShowCustomerProgram={true}
												Required
												IsInvalid={vModel.Customer} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="SelectedFindType"
												LabelText="Wallet Find Method"
												IsStatic={true}
												StaticData={this.DDTFindType}
												KeyValueMember="Value"
												RenderItem={d => `${d.Label}`}
												Value={model.SelectedFindType}
												ValueChanged={this.handleChange}
												Disabled={!(model.Customer && model.Customer.Id)}
												Required
												IsInvalid={vModel.SelectedFindType} />
										</GridItem>
										<GridItem xs={4}>
											{model.Customer && model.Customer.Id && model.SelectedFindType != null &&
												<GenericNumberInput
													Name="SelectedFindValue"
													LabelText={model.SelectedFindType == null ?
														"Wallet" : (model.SelectedFindType == 1 ?
															"Customer Number" : (model.SelectedFindType == 2 ?
																"DDT Id" : "DDT Number"))}
													MaxLength={30}
													NoFormatting={true}
													Value={model.SelectedFindValue}
													ValueChanged={this.handleChange}
													Disabled={!(model.Customer && model.Customer.Id)}
													Required
													IsInvalid={vModel.SelectedFindValue} />}
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
}

AssignDDTAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(AssignDDTAccount, {});
