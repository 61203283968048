import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericNumberInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericCheckInput } from "views/Components/Generic";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName } from "views/Constants/Constant.Sorted";
import { GridColumnType } from "views/Constants/Constant";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";


class ClientAutoDepositProfileDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Source Wallet Number",
				accessor: "SourceAccount.AccountNumber"
			},
			{
				Header: "Destination Wallet Number",
				accessor: "DestinationAccount.AccountNumber"
			},
			{
				Header: "Is Active",
				accessor: "IsActive",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Monday Limit",
				accessor: "MondayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Tuesday Limit",
				accessor: "TuesdayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Wednesday Limit",
				accessor: "WednesdayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Thursday Limit",
				accessor: "ThursdayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Friday Limit",
				accessor: "FridayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Saturday Limit",
				accessor: "SaturdayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Sunday Limit",
				accessor: "SundayLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];

		this.initialModel = {
			Id: 0,
			CurrencyId: 0
		};

		this.state = {
			model: this.initialModel,
			isEdit: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("CLIENT");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Client AutoLimit Profile Definition");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if(name == "SourceAccountId"){
				model["CurrencyId"] = data.CurrencyId;
			}

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		var result = await ExecuteApiPost("/bankapi/v1.0/ClientAutoDepositProfile/Search", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false });
	}

	
	GetRenderItemAccount = (x) => {
		const { model } = this.state;
		this.renderItemAccount = `${x.AccountNumber} - ${x.AccountName} - ${x.CurrencyCode}`;
		return this.renderItemAccount;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		model.CurrencyId = model.DestinationAccount.CurrencyId;
		this.setState({ isEdit: true, model, index });
		console.log(model);
	}

	HandleSubmit = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: this.state.isEdit ? "Update" : "Insert",
				dataModel: "Bank.ClientAutoDepositProfile",
				value: this.state.model
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.Search();
					this.Clear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: "Delete",
				dataModel: "Bank.ClientAutoDepositProfile",
				value: { Id: this.state.model.Id }
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.props.showMessage("success", "Succeeded", "Deleted successfully!");
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	render() {
		const { model, list, isLoading } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{ 
							Code: "Submit", 
							OnClick: this.HandleSubmit, 
							Disabled: Disabled || model.Id > 0
						},
						{ 
							Code: "Update", 
							OnClick: this.HandleSubmit, 
							Disabled: Disabled || model.Id === 0
						},
						{ 
							Code: "Delete", 
							OnClick: this.HandleDelete, 
							Disabled: Disabled || model.Id === 0  
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Client Auto Deposit Profile Definition">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												RenderItem={this.RenderItemUniqueClient}
												DataRoot="Item"
												KeyValueMember="Id"
												Sorted={SortedName}
												CanClear={false}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="SourceAccountId"
												LabelText="Source Wallet"
												Method="POST"
												Url="/bankapi/v1.0/Account/GetSettlementAccounts"
												DataRoot="Item"
												KeyValueMember="AccountId"
												TextValueMember="AccountNumber"
												RenderItem={this.GetRenderItemAccount}
												Sorted={{ Member: "AccountNumber" }}
												Value={model.SourceAccountId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.UniqueClientId && model.CurrencyId}
												Name="DestinationAccountId"
												LabelText="Destination WalletId"
												Method="POST"
												Url={model.SourceAccountId > 0 && model.UniqueClientId > 0  ? "/bankapi/v1.0/Account/GetClientAccountsByCurrency" : ""}
												Parameter={{
													UniqueClientId: model.UniqueClientId,
													CurrencyId: model.CurrencyId
												}}
												DataRoot="Item"
												KeyValueMember="AccountId"
												TextValueMember="AccountNumber"
												RenderItem={this.GetRenderItemAccount}
												Sorted={{ Member: "AccountNumber" }}
												Disabled={!(model.SourceAccountId > 0 && model.UniqueClientId > 0)}
												Value={model.DestinationAccountId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="MondayLimit"
												LabelText="Monday Limit"
												Value={model.MondayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="TuesdayLimit"
												LabelText="Tuesday Limit"
												Value={model.TuesdayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="WednesdayLimit"
												LabelText="Wednesday Limit"
												Value={model.WednesdayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="ThursdayLimit"
												LabelText="Thursday Limit"
												Value={model.ThursdayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="FridayLimit"
												LabelText="Friday Limit"
												Value={model.FridayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="SaturdayLimit"
												LabelText="Saturday Limit"
												Value={model.SaturdayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="SundayLimit"
												LabelText="Sunday Limit"
												Value={model.SundayLimit}
												Prefix="$"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsActive"
												LabelText="Is Active"
												Value={model.IsActive}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													IsSorted={false} 
													RowSelected={this.RowSelected}
													ShowPagination={true}
													PageSize={20}
													/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ClientAutoDepositProfileDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default ClientAutoDepositProfileDefinition;