// ##############################
// // // Standart Appbar View styles
// #############################

 const constantToolbarTextStyle = {	
	appbar:{
		padding:"0px",
		height:"50px",
		background: "#d3d3d3",
		borderRadius:4,
      borderRadiusInputTopLeft:7,
      borderRadiusInputTopRight:7,
      borderRadiusInputBottomLeft:7,
		borderRadiusInputBottomRight:7		
	},
	appbarleft:{
		marginLeft:"10px",
		margin: "0",
		padding:"0",
		textAlign:"left",
		color:"#2e333a",
		fontSize: "14px !important",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex"
	},
	appbarmiddle:{
		margin: "0",
		padding:"0",
		textAlign:"center",
		color:"#2e333a",
		fontSize: "17px !important",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex"
	},
	appbarright:{
		marginRight:"4px",
		margin: "0",
		padding:"0",
		textAlign:"right",
		color:"#2e333a",
		fontSize: "11px !important",
		lineHeight: "1.428571429",
		fontWeight: "400"
	},
	appbaritemright:{
		background: "#fcfcfc",
		borderRadius:4,
      borderRadiusInputTopLeft:7,
      borderRadiusInputTopRight:7,
      borderRadiusInputBottomLeft:7,
		borderRadiusInputBottomRight:7,
		color:"#2e333a",
		fontSize: "1em",
		lineHeight: "1.428571429",
		fontWeight: "400",
		borderColor:"#707070",
		textAlign:"center"
	},
	appbaritemleft:{
		fontSize: "1em",
		textAlign:"right"
	},
	appbar2:{
		height:"50px",
		marginTop:"10px",
		background: "#d3d3d3",
		borderRadius:4,
      borderRadiusInputTopLeft:7,
      borderRadiusInputTopRight:7,
      borderRadiusInputBottomLeft:7,
		borderRadiusInputBottomRight:7,
		color:"#2e333a",
		fontSize: "0.8em",
		lineHeight: "1.428571429",
		fontWeight: "400",
		textAlign:"left"
	}
 };
 
 export default constantToolbarTextStyle;
 