import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-autocomplete-input/dist/bundle.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

const initialState = {
	model: {
		LoanTypeId: null,
		LoanPurposeId: null,
		LoanResultLetterTypeId: null
	},
	vModel: {},
	applicantModel: {},
	resultLetterMapList: [],
	applicationList: [],
	isBrowse: false,
	isLoading: false,
	isLetterTempActive: false
};
const url = "/losapi/v1.0/ApplicationResultLetter/";

class ApplicationResultLetterApplicantMap extends Component {

	constructor(props) {
		super(props);
		this.state = initialState;
		this.ParamLOSLoanType = {
			ParameterCode: "LOSLoanType"
		};
		this.ParamLOSLoanPurpose = {
			ParameterCode: "LOSLoanPurpose"
		};
		this.ParamLOSResultLetterType = {
			ParameterCode: "LOSResultLetterType"
		};
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.renderItemApplication = {};

		this.sortedFullName = { Member: "FullName" };
	}

	/**Begin Life cycle Methods */
	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Application Result Letter");
		this
			.props
			.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
	}

	/**End life cycle Methods */

	/* Begin Standard  Methods  */

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false, state: initialState });
		window
			.location
			.reload();

	}
	HideAlert = () => {
		this.setState({ alert: null });
	}

	/* End Standard  Methods  */

	/**  BEGIN Actions */
	OperationCheck = () => {
		var ErrorList = [];
		const data = this.state.model;
		const { vModel } = this.state;
		if (data == null) {
			ErrorList.push("Letter parameters not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		if (data.ApplicationResultLetterTemplateId == null || data.ApplicationResultLetterTemplateId == 0) {
			vModel.ApplicationResultLetterTemplateId = true;
			ErrorList.push("Letter template must be selected.");
		}
		else {
			vModel.ApplicationResultLetterTemplateId = false;
		}
		if (data.ApplicantId == null || data.ApplicantId == 0) {
			vModel.ApplicantId = true;
			ErrorList.push("Applicant must be selected.");
		}
		else {
			vModel.ApplicantId = false;
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		this.ActionSubmit();

	}

	ActionSubmit = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		await ExecuteApiPost("/losapi/v1.0/ApplicationResultLetter/SaveResultLetter", model, null, null, null, [this.ActionClear, this.ActionSearch], true);

	}
	ActionSearch = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		this.Post(data, "SearchResultLetter");

	}
	ActionDelete = () => {
		var ErrorList = [];
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		if (data == null || data.Id == null || data.Id == 0) {
			ErrorList.push("Any letter template not selected.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please select a record from letter templates list.", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.Post(data, "DeleteResultLetter");

	}
	ActionClear = (actionSearch) => {
		if (!actionSearch) {
			this.setState({ model: {}, resultLetterMapList: [], alert: null, isLoading: false, state: initialState });
		} else {
			this.setState({ model: {}, resultLetterMapList: [], alert: null, isLoading: false, state: initialState }, () => { this.ActionSearch(); });
		}
	}
	Post = (model, methodName) => {
		this.setState({ alert: null });
		this.setState({ isLoading: true });
		Proxy.POST((url + methodName), model, (responseData) => {
			switch (methodName) {
				case "SearchResultLetter":
					if (responseData != null && responseData.Item != null) {
						this.setState({ resultLetterMapList: responseData.Item, isLoading: false });
					}
					break;
				case "SearchLetterTemp":
					if (responseData != null && responseData.Item != null && responseData.Item.length > 0) {
						this.setState({ letterTempList: responseData.Item, isLoading: false });
					}
					break;
				case "DeleteResultLetter":
					if (responseData != null && responseData.Item != null) {
						this.ShowMessage("success", "Success", "Letter Template Deleted");
						this.setState({ isLoading: false });
					}
					this.setState({ alert: null, isLoading: false, state: initialState }, () => { this.ActionClear(true); });
					break;
				default:
					if (responseData != null && responseData.Item != null) {
						this.ShowMessage("success", "Success", "Result Letter Saved");
						this.setState({ model: {}, resultLetterTempList: [] });

					} else {
						this.setState({ alert: null, isLoading: false, state: initialState });
					}
					break;
			}

			this.setState({ isLoading: false });

		}, this.ErrorCallback);
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}
	/* END Actions */

	/**  BEGIN Methods */
	getResultLetterTempList = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		this.Post(data, "SearchLetterTemp");

	}
	HandleGetApplicant = (id) => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Applicant/Search", {
				Id: id
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item && responseData.Item.length > 0) {
					var { applicantModel } = this.state;

					Object.assign(applicantModel, responseData.Item[0]);

					this.setState({
						applicantModel
					}, () => this.GetApplicationList(responseData.Item[0].Id));

				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	GetApplicationList(applicantId) {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Application/Search", {
				ApplicantId: applicantId
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({
					applicationList: responseData.Item || []
				});
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	HandleGetApplication = (id) => {
		let { model } = this.state;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Application/GetById", {
				Id: id
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.LoanTypeId = responseData.Item.Application.LoanTypeId;
					model.LoanPurposeId = responseData.Item.Application.LoanPurposeId;
					model.ApplicationId = responseData.Item.Application.Id;

					this.setState({ model: model });
				}
			}, this.ErrorCallback));
	}

	/* END Methods */

	/** Begin Events */
	HandleChange = (name, newValue, data) => {

		const model = {
			...this.state.model
		};

		if (name == "ApplicantId") {
			this.setState({
				model
			}, () => {
				this.HandleGetApplicant(newValue);
			});
		}
		if (name == "ApplicationId") {
			this.HandleGetApplication(newValue);
		}
		model[name] = newValue;
		this.state.isLetterTempActive = !(model.LoanTypeId == null || model.LoanPurposeId == null || model.LoanResultLetterTypeId == null);
		this.setState({ model });
		(this.state.isLetterTempActive && (this.state.letterTempList == null || this.state.letterTempList.length == 0)) && this.getResultLetterTempList();
	}
	RowSelected = (index) => {
		const list = this.state.resultLetterMapList;
		this.setState({ model: list[index] });
	}
	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	RenderItemApplication = (d) => {
		this.renderItemApplication = `${d.ApplicationNumber} - ${d.LoanType.ParameterDesc}`;
		return this.renderItemApplication;
	}

	/** End Events */

	render() {
		const { model, isLetterTempActive, letterTempList, applicationList, alert, isLoading, vModel } = this.state;

		var ForInsert = !model.Id || model.Id <= 0;
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.OperationCheck,
							Disabled: !ForInsert
						}, {
							Code: "Search",
							OnClick: this.ActionSearch,
							Disabled: false
						}, {
							Code: "Update",
							OnClick: this.OperationCheck,
							Disabled: ForInsert
						}, {
							Code: "Delete",
							OnClick: this.ActionDelete,
							Disabled: ForInsert
						}, {
							Code: "Clear",
							OnClick: this.ActionClear,
							Disabled: false
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					IsPopUp={false} />
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true} Title="Main Parameters">
									<GridContainer justify="flex-start" alignItems="flex-start" direction="row">
										<GridContainer>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="ApplicantId"
													LabelText="Applicant Name"
													LabelMd={4}
													Method="POST"
													Url="/losapi/v1.0/Applicant/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FullName"
													Value={model.ApplicantId}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.ApplicantId}
													Sorted={this.sortedFullName} />
											</GridItem>
											<GridItem xs={3}>
												<ParameterComponent
													Name="LoanTypeId"
													LabelText="Loan Type"
													LabelMd={4}
													ParameterCode={this.ParamLOSLoanType.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={model.LoanTypeId}
													ValueChanged={this.HandleChange}
													Disabled={true} />
											</GridItem>
											<GridItem xs={3}>
												<ParameterComponent
													Name="LoanResultLetterTypeId"
													LabelText="Letter For"
													LabelMd={4}
													ParameterCode={this.ParamLOSResultLetterType.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={model.LoanResultLetterTypeId}
													ValueChanged={this.HandleChange}
													Disabled={false} />

											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={3}>
												<GenericSelectInput
													IsStatic={true}
													StaticData={applicationList}
													Name="ApplicationId"
													LabelText="Application Number"
													KeyValueMember="Id"
													RenderItem={this.RenderItemApplication}
													Value={model.ApplicationId}
													ValueChanged={this.HandleChange}
													Disabled={false} />
											</GridItem>

											<GridItem xs={3}>
												<ParameterComponent
													Name="LoanPurposeId"
													LabelText="Purpose Of The Loan"
													LabelMd={4}
													ParameterCode={this.ParamLOSLoanPurpose.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={model.LoanPurposeId}
													ValueChanged={this.HandleChange}
													Disabled={true} />
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													IsStatic={true}
													StaticData={letterTempList}
													Name="ApplicationResultLetterTemplateId"
													LabelText="Letter Template"
													KeyValueMember="Id"
													TextValueMember="Description"
													Value={model.ApplicationResultLetterTemplateId}
													ValueChanged={this.HandleChange}
													Disabled={!isLetterTempActive}
													Required
													IsInvalid={vModel.ApplicationResultLetterTemplateId} />
											</GridItem>
										</GridContainer>

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Result Letter List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.resultLetterMapList}
												Columns
												={[
													{
														Header: "Applicant Name",
														accessor: "Application.Applicant.FullName"
													},
													{
														Header: "Application Number",
														accessor: "Application.ApplicationNumber"
													},
													{
														Header: "Loan Type",
														accessor: "LoanType.ParameterDesc"
													}, {
														Header: "Purpose Of The Loan",
														accessor: "LoanPurpose.ParameterDesc"
													}, {
														Header: "Letter For",
														accessor: "LoanResultLetterType.ParameterDesc"
													}, {
														Header: "E-Mail Address",
														accessor: "Application.Applicant.Email"
													}, {
														Header: "Letter Template Description",
														accessor: "ApplicationResultLetterTemplate.Description"
													}
												]}
												RowSelected={this.RowSelected} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}
ApplicationResultLetterApplicantMap.propTypes = {
	classes: PropTypes.object.isRequired,
	ExecuteApiPost: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ApplicationResultLetterApplicantMap, {});
