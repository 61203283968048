// ##############################
// // // Manage Cards Tabs View styles
// #############################

const manageCardTabStyle = {	
	table:{
		margin:"0px 0px !important",
		padding: "0x 8px !important",
		position: "relative",
		fontSize: "12px !important",
		color:"#2e333a",lineHeight: "1.42857143",
		borderBottom: "none",
		verticalAlign: "center"
	}
 };
 
 export default manageCardTabStyle;
 