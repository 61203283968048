import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import {
	GenericAlert,
	GenericDateInput,
	GenericEmailInput,
	GenericGrid,
	GenericNumberInput,
	GenericSelectInput,
	GenericTextInput
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, MenuCodes } from "views/Constants/Constant.js";
import DateHelper from "../../../../core/DateHelper";

class RegulatoryLicenses extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			regulatoryLicense: {},
			CustomDisabled: null,
			vModel: {}
		};
		this.ParameterEmptyObject = {};
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength100 = { maxLength: 100 };
		this.ParameterLicenseStatus = { ParameterCode: "LicenseStatus" };
		this.ParameterLicenseRegistrationType = { ParameterCode: "LicenseRegistrationType" };

		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ regulatoryLicense: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={ this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Name Of License",
				accessor: "NameOfLicense"
			},
			{
				Header: "License Type",
				accessor: "LicenseRegistrationType.ParameterDesc"
			},
			{
				Header: "License Status",
				accessor: "LicenseStatus.ParameterDesc"
			},
			{
				Header: "License Number",
				accessor: "LicenseNumber"
			},
			{
				Header: "Expiration Date",
				accessor: "ExpirationDate"
			}
		];

	}


	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Regulatory Licenses");


		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a client or create new one to add Regulatory Licenses.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ regulatoryLicense: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedKeyContact, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}
	OperationCheck = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const global = { ...this.props.paramGlobalModel };
		const model = this.state.regulatoryLicense;

		if (global == null || global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add.");
		}
		if (model == null) {
			return;
		}
		if (model.NameOfLicense == null || model.NameOfLicense == "") {
			vModel.NameOfLicense = true;
			ErrorList.push("NameOfLicense must be defined !.");
		} else { vModel.NameOfLicense = false; }
		if (model.LicenseStatusId == null || model.LicenseStatusId == "") {
			vModel.LicenseStatusId = true;
			ErrorList.push("LicenseStatus must be defined !.");
		} else { vModel.LicenseStatus = false; }
		if (model.LicenseRegistrationTypeId == null || model.LicenseRegistrationTypeId == "") {
			vModel.LicenseRegistrationTypeId = true;
			ErrorList.push("LicenseRegistrationType must be defined !.");
		} else { vModel.LicenseRegistrationType = false; }
		if (model.LicenseNumber == null || model.LicenseNumber == "") {
			vModel.LicenseNumber = true;
			ErrorList.push("LicenseNumber must be defined !.");
		} else { vModel.LicenseNumber = false; }
		if (model.ExpirationDate == null || model.ExpirationDate == "") {
			vModel.ExpirationDate = true;
			ErrorList.push("ExpirationDate must be defined !.");
		} else { vModel.ExpirationDate = false; }
		if (model.ExpirationDate < new Date()) {
			vModel.ExpirationDate = true;
			ErrorList.push("License expired !");
		} else { vModel.ExpirationDate = false; }

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ regulatoryLicense: model, vModel: {} });
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.regulatoryLicense || {};
			model[name] = newValue;
			return { regulatoryLicense: model };
		});
	}
	UpdateModel = () => {
		var temp = this.state.regulatoryLicense;

		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerRegulatoryLicense/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "RegulatoryLicense Updated Successfully !" : "RegulatoryLicense  Saved  Successfully! ");
				this.ClearData();
				this.props.GetCustomerRegulatoryLicenses();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "RegulatoryLicense Must Be Selected For The Delete Operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerRegulatoryLicense/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "RegulatoryLicense Deleted Successfully !");
				this.ClearData();
				this.props.GetCustomerRegulatoryLicenses();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerRegulatoryLicenseList[index];
		this.setState({
			regulatoryLicense: model
		});
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { regulatoryLicense, header, isLoading, vModel } = this.state;
		const { Disabled: DisabledProp, paramBankCustomerRegulatoryLicenseList} = this.props;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<GridItem xs={12}>
					<LoadingComponent Show={isLoading} />

					<Card className="no-radius">
						{header &&
							<CardHeader >
								<h6><b>Regulatory Licenses</b></h6>
							</CardHeader>
						}
						<CardBody>
							<GridItem xs={12}>
								<GridContainer spacing={16}>
									<GridItem xs={6}>
										<GenericTextInput
											Disabled={Disabled}
											Name="NameOfLicense"
											inputProps={this.ParameterMaxLength30}
											LabelText="Name of License"
											Value={regulatoryLicense.NameOfLicense || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.NameOfLicense}/>
										<GenericSelectInput
											CanClear
											Required
											Name="LicenseRegistrationTypeId"
											LabelText="License Type"
								            Value={regulatoryLicense == null ? "" : regulatoryLicense.LicenseRegistrationTypeId}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterLicenseRegistrationType}
											Disabled={Disabled}
											DataRoot="Item" />
										<GenericSelectInput
											CanClear
											Name="LicenseStatusId"
											LabelText="License Status"
											Value={regulatoryLicense.LicenseStatusId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterLicenseStatus}
											Disabled={Disabled}
											DataRoot="Item" />
									</GridItem>
									<GridItem xs={6}>
										<GenericTextInput
											Disabled={Disabled}
											Name="LicenseNumber"
											inputProps={this.ParameterMaxLength30}
											LabelText="License number"
											Value={regulatoryLicense.LicenseNumber || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.LicenseNumber}/>
										<GenericDateInput
											Utc
											Disabled={Disabled}
											Name="ExpirationDate"
											LabelText="Expiration Date"
											Value={regulatoryLicense.ExpirationDate == null ? "" : DateHelper.ToDateInputValue(regulatoryLicense.ExpirationDate)}
											ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{regulatoryLicense.Id > 0 ? "Update" : "Add"}</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>

					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={paramBankCustomerRegulatoryLicenseList}
										PageSize={5}
										HideButton={true}
										Columns={this.CloumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>

				</GridItem>
			</GridContainer>
		);
	}
}

RegulatoryLicenses.propTypes = {
	classes: PropTypes.object,
	regulatoryLicense: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerRegulatoryLicenseList: PropTypes.array,
	paramBankCustomerExternalAccountList: PropTypes.array,
	GetCustomerRegulatoryLicenses: PropTypes.func
};


export default withArtifex(RegulatoryLicenses, {});