import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput, GenericTextInput } from "views/Components/Generic";


class Tcr5 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength2 = { maxLength: 2 };
		this.maxLength5 = { maxLength: 5 };
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	render() {

		const { model } = this.props;
		return (
			<div >

				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionIdentifier"
									LabelMd={6}
									LabelText="Transaction Identifier"
									inputProps={this.maxLength12}
									Value={model && model.TransactionIdentifier}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="AuthorizedAmount"
									LabelMd={6}
									LabelText="Authorized Amount"
									inputProps={this.maxLength17}
									Value={model && model.AuthorizedAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AuthorizationCurrencyCode"
									LabelMd={6}
									LabelText="Authorization Currency Code"
									inputProps={this.maxLength3}
									Value={model && model.AuthorizationCurrencyCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AuthorizationResponseCode"
									LabelMd={6}
									LabelText="Authorization Response Code"
									inputProps={this.maxLength2}
									Value={model && model.AuthorizationResponseCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ValidationCode"
									LabelMd={6}
									LabelText="ValidationCode"
									inputProps={this.maxLength4}
									Value={model && model.ValidationCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ExcludedTransactionIdentifierReason"
									LabelMd={6}
									LabelText="Excluded Transaction Identifier Reason"
									inputProps={this.maxLength1}
									Value={model && model.ExcludedTransactionIdentifierReason}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="CRSProcessingCode"
									LabelMd={6}
									LabelText="CRSProcessing Code"
									inputProps={this.maxLength1}
									Value={model && model.CRSProcessingCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ChargebackRightsIndicator"
									LabelMd={6}
									LabelText="Chargeback Rights Indicator"
									inputProps={this.maxLength2}
									Value={model && model.ChargebackRightsIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MultipleClearingSequenceCount"
									LabelMd={6}
									LabelText="Multiple Clearing Sequence Count"
									inputProps={this.maxLength5}
									Value={model && model.MultipleClearingSequenceCount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MultipleClearingSequenceNumber"
									LabelMd={6}
									LabelText="Multiple Clearing Sequence Number"
									inputProps={this.maxLength5}
									Value={model && model.MultipleClearingSequenceNumber}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="MarketSpecificAuthDataIndicator"
									LabelMd={6}
									LabelText="Market Specific Auth Data Indicator"
									inputProps={this.maxLength5}
									Value={model && model.MarketSpecificAuthDataIndicator}
									ValueChanged={this.HandleChange} />
							</GridItem>



							<GridItem xs={4}>

								<GenericNumberInput
									Name="TotalAuthorizedAmount"
									LabelMd={6}
									LabelText="Total Authorized Amount"
									inputProps={this.maxLength17}
									Value={model && model.TotalAuthorizedAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="InformationIndicator"
									LabelMd={6}
									LabelText="Information Indicator"
									inputProps={this.maxLength1}
									Value={model && model.InformationIndicator}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="MerchantTelephoneNumber"
									LabelMd={6}
									LabelText="Merchant Telephone Number"
									inputProps={this.maxLength1}
									Value={model && model.MerchantTelephoneNumber}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AdditionalDataIndicator"
									LabelMd={6}
									LabelText="Additional Data Indicator"
									inputProps={this.maxLength1}
									Value={model && model.AdditionalDataIndicator}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="MerchantVolumeIndicator"
									LabelMd={6}
									LabelText="Merchant Volume Indicator"
									inputProps={this.maxLength2}
									Value={model && model.MerchantVolumeIndicator}
									ValueChanged={this.HandleChange} />

							</GridItem>


							<GridItem xs={4}>

								<GenericTextInput
									Name="ElectronicCommerceGoodsIndicator"
									LabelMd={6}
									LabelText="Electronic Commerce Goods Indicator"
									inputProps={this.maxLength2}
									Value={model && model.ElectronicCommerceGoodsIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MerchantVerificationValue"
									LabelMd={6}
									LabelText="Merchant Verification Value"
									inputProps={this.maxLength10}
									Value={model && model.MerchantVerificationValue}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="InterchangeFeeAmount"
									LabelMd={6}
									LabelText="Interchange Fee Amount"
									inputProps={this.maxLength12}
									Value={model && model.InterchangeFeeAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="InterchangeFeeSign"
									LabelMd={6}
									LabelText="Interchange Fee Sign"
									inputProps={this.maxLength1}
									Value={model && model.InterchangeFeeSign}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="SourceToBaseExchangeRate"
									LabelMd={6}
									LabelText="Source To Base Exchange Rate"
									DecimalScale={6}
									inputProps={this.maxLength8}
									Value={model && model.SourceToBaseExchangeRate}
									ValueChanged={this.HandleChange} />
									
								<GenericNumberInput
									Name="BaseToDestinationExchangeRate"
									LabelMd={6}
									DecimalScale={6}
									LabelText="BaseToDestination Exchange Rate"
									inputProps={this.maxLength8}
									Value={model && model.BaseToDestinationExchangeRate}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="OptionalIssuerISAAmount"
									LabelMd={6}
									LabelText="Optional Issuer ISA Amount"
									inputProps={this.maxLength12}
									Value={model && model.OptionalIssuerISAAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ProductID"
									LabelMd={6}
									LabelText="ProductID"
									inputProps={this.maxLength2}
									Value={model && model.ProductID}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="ProgramID"
									LabelMd={6}
									LabelText="ProgramID"
									inputProps={this.maxLength2}
									Value={model && model.ProgramID}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="CVV2ResultCode"
									LabelMd={6}
									LabelText="CVV2 Result Code"
									inputProps={this.maxLength1}
									Value={model && model.CVV2ResultCode}
									ValueChanged={this.HandleChange} />
							</GridItem>


						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

Tcr5.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default Tcr5;