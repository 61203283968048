import React, { Component } from "react";

class GenericTitle extends Component {
	render() {
		const { text, TextAlign } = this.props;

		return (
			<h4 
				style={{ 
					color: "black",
					textAlign: TextAlign, 
				}}>
				<b>{text || ""}</b>
			</h4>
		);
	}
}

export default GenericTitle;