import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import Select from "react-select";
import { DialogTitle, DialogContent } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

class AccountMovements extends Component {

	constructor(props) {
		super(props);

		this.state = {
			model: {},
			isLoading: false,
			alert: null,
			hasChanged: false,
		};
		this.sortName = { Member: "Name" };
		this.sortListName = [{ id: "CustomerName" }];
		this.emptyObject = {};

		this.trxTable = React.createRef();

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTime,
				style: this.RenderStyle()

			},
			{
				Header: "Channel Type",
				accessor: "ChannelType",
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value}</div>
				)
			},
			{
				Header: "Received / Sent",
				accessor: "TransactionType",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value}</p>
				)
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				style: this.RenderStyle(),
				ColumnType: GridColumnType.Money
			},
			{
				width: 200,
				Header: "From Wallet Number",
				accessor: "SourceAccountNumber",
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value}</div>
				)
			},
			{
				Header: "From Wallet",
				accessor: "SourceAccountName",
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value}</div>
				)
			},

			{
				width: 230,
				Header: "To Wallet",
				accessor: "DestinationAccountName",
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value}</div>
				)
			},
			{
				Header: "To Wallet Number",
				accessor: "DestinationAccountNumber",
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value ?? " - "}</div>
				)
			},
			{
				Header: "Destination Customer Name",
				accessor: "DestinationCustomerName",
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value}</div>
				)
			},


			{
				width: 300,
				Header: "Description",
				accessor: "SummaryDescription",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>  {row.value}</p>
				)
			},

			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>  {row.value}</p>
				)
			}

		];


	}
	componentDidMount() {
		this.props.setAppLeftTitle("Wallet Activities");
		this.props.setAppCenterTitle("WALLET");
		this.ProgramCode = ProgramCodes.Prepaid;
		this.state.model.ProgramCode = ProgramCodes.Prepaid;

		this.setState({ loadingCompleted: true });

	}
	IsValid = () => {
		var vmodel = { ...this.state.model };



		if (!vmodel.IsUniqueDataSearch && !vmodel.ClientCustomerId) {
			this.props.showMessage("warning", "Please fill required fields!", "Customer Name is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.FromDate != "object" && vmodel.FromDate != "" && !vmodel.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From  Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.ToDate != "object" && vmodel.ToDate != "" && !vmodel.ToDate) {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && (typeof vmodel.FromDate == "object" && typeof vmodel.ToDate == "object") && vmodel.ToDate < vmodel.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be greater than To Date!");
			return false;
		}


		return true;
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "ReferenceNumber") {
			model.IsUniqueDataSearch = newValue.length > 0;
		}
		this.setState({ model: model });
	}
	ClearData = () => {
		this.setState({ isLoading: false });
		this.setState({
			alert: null,
			model: {},
			isLoading: false,
		});
	}
	RenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["+", ""]
		};
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	HandleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.IsValid())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Wallet Movements Transactions Report";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/Transaction/AccountMovementTransactionExport",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}


	render() {
		const { model, hasChanged } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Select Wallet"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												key={"Customer_" + hasChanged}
												Name="ClientCustomerId"
												LabelText="Customer Name"
												Method="POST"
												Url="/bankapi/v1.0/ClientCustomer/SearchNameValue"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.ClientCustomerId}
												ValueChanged={this.handleChange}
												Sorted={this.sortName}
												Required={!model.IsUniqueDataSearch} />
											<GenericSelectInput
												Name="ChannelType"
												LabelText="Channel Name"
												Value={model.ChannelType || ""}
												ValueChanged={this.handleChange}
												KeyValueMember="ParameterValue"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{ ParameterCode: "ChannelNameType" }}
												DataRoot="Item"
												Sorted={{ Member: "ParameterDesc" }}
											/>
											{/* <GenericSelectInput
												key={this.ProgramCode}
												LabelMd={4}
												Name="ClientCustomerId"
												LabelText="Customer Name"
												Method="POST"
												Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
												DataRoot="Item"
												Parameter={{ ProgramCode: this.ProgramCode, UniqueClientId: ClientHelper.GetClientRowId() }}
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.ClientCustomerId}
												ValueChanged={this.handleChange}
												Required
											/> */}

										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.handleChange}
												MaxDate={model.FromDate || DateHelper.GetDateTimeLocal()}
												Disabled={Disabled}
												Utc
												Required={!model.IsUniqueDataSearch} />

											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												MinDate={model.FromDate}
												MaxDate={DateHelper.GetDateLocal()}
												Utc
												Required={!model.IsUniqueDataSearch} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer>
								</CardBody >
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ref={this.trxTable}
													IsSorted={false}
													ShowPagination={true}
													PageSize={10}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
													ServerSide
													LoadAtStartup={this.state.loadingCompleted}
													ValidateRequest={this.IsValid}
													PrepareRequest={() => { return this.state.model; }}
													RequestUrl="/bankapi/v1.0/Transaction/AccountMovementTransactionPaged"
													RequestMethod="POST"
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

AccountMovements.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};


export default withArtifex(AccountMovements, {}); 