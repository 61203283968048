import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericGrid, GenericLabel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CrudType } from "views/Constants/Constant";

class UploadCfifFile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			alert: null,
			isLoading: false,
			isFileChanged: false,
			FinancialInstitutionList: [],
			FinancialInstitutionBranchList: [],
			ProcessedItemCount: 0
		};

		this.fileInputRef = React.createRef();

		this.ColumnsDataInst = [
			{
				Header: "Operation Type",
				accessor: "CrudType"
			},
			{
				Header: "Financial Institution Id",
				accessor: "FinancialInstitutionId"
			},
			{
				Header: "Bank Name",
				accessor: "Description"
			}
		];
		this.ColumnsDataInstBranch = [
			{
				Header: "Operation Type",
				accessor: "CrudType"
			},
			{
				Header: "Branch Name",
				accessor: "Description"
			},
			{
				Header: "City",
				accessor: "CityName"
			},
			{
				Header: "Address",
				accessor: "Address1"
			},
			{
				Header: "Zip Code",
				accessor: "ZipCode"
			}
		];

	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("SYSTEM ADMIN");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("CFIF File Upload");

	}

	OperationCheck = () => {
		var ErrorList = [];
		var data = { ...this.state.model.File };

		if	(data.File == null || data.FileName == null || data.FileName == ""){
			ErrorList.push("Select the CFIF File");
		}	


		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.FileUpload();
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}


	FileUpload = () => {
		var formData = new FormData();
		var model = { ...this.state.model.File };
		formData.append("file", model.File, model.FileName);

		this.setState({ isLoading: true });
		Proxy.FileUpload(
			"/bankapi/v1.0/BankUtility/UploadCfifFile",
			formData,
			responseData => {

				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.setState({ ProcessedItemCount: responseData.Item.ProcessedItemCount });
					if (responseData.Item.ModifiedInstitution != null) {
						responseData.Item.ModifiedInstitution.forEach(element => element.CrudType = CrudType.Update);
					}
					if (responseData.Item.AddedInstitution != null) {
						responseData.Item.AddedInstitution.forEach(element => element.CrudType = CrudType.Insert);
					}
					if (responseData.Item.ModifiedInstitutionBranch != null) {
						responseData.Item.ModifiedInstitutionBranch.forEach(element => element.CrudType = CrudType.Update);
					}
					if (responseData.Item.AddedInstitutionBranch != null) {
						responseData.Item.AddedInstitutionBranch.forEach(element => element.CrudType = CrudType.Insert);
					}

					var inst = responseData.Item.ModifiedInstitution.concat(responseData.Item.AddedInstitution);
					var brach = responseData.Item.ModifiedInstitutionBranch.concat(responseData.Item.AddedInstitutionBranch);
					this.setState({ FinancialInstitutionList: inst });
					this.setState({ FinancialInstitutionBranchList: brach });
				}

				this.setState({ isLoading: false });
				this.props.showMessage("success", "Success", "File Processed Successfully");

			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var cf = this.state.model.File;
		if (cf == null) {
			var temp = {
				TempFileId: 0,
				File: file,
				FileName: file.name
			};
			this.handleChange("File", temp);
		} else {
			cf.TempFileId = cf.Id;
			cf.File = file;
			cf.FileName = file.name;

			this.handleChange("File", cf);
		}
		this.setState({ isFileChanged: true });
	}

	ClearData = () => {
		this.setState({
			model: {},
			alert: null,
			isLoading: false,
			isFileChanged: false,
			FinancialInstitutionList: [],
			FinancialInstitutionBranchList: [],
			ProcessedItemCount: 0
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, FinancialInstitutionList, FinancialInstitutionBranchList } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Upload CFIF File </p></b>
						<GridItem>
							<GridContainer justify="flex-start">
								<GridItem>
									<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef.current.click()} >Browse File</Button>
									<input type="file" key={model.FileName} style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="file_extension|audio/*|video/*|image/*|media_type" />
								</GridItem>
								<GridItem style={{ paddingTop: 10 }}>
									<GenericLabel
										Bold={true}
										FontSize="12px"
										TextColor="Black"
										Text={model.File == null ? "" : model.File.FileName}
									/>
								</GridItem>
							</GridContainer>
							<br />
							<GridContainer justify="flex-start">
								<GridItem style={{ paddingTop: 10 }}>
									<GenericLabel
										Bold={true}
										FontSize="12px"
										TextColor="Black"
										Text={this.state.ProcessedItemCount > 0 ? `Processed Item Count: ${this.state.ProcessedItemCount}` : ""}
									/>
								</GridItem>
								<GridItem style={{ paddingTop: 10 }}>
									<GenericLabel
										Bold={true}
										FontSize="12px"
										TextColor="Black"
										Text={this.state.ProcessedItemCount > 0 ? `Modified Institution Count: ${this.state.FinancialInstitutionList.length}` : ""}
									/>
								</GridItem>
								<GridItem style={{ paddingTop: 10 }}>
									<GenericLabel
										Bold={true}
										FontSize="12px"
										TextColor="Black"
										Text={this.state.ProcessedItemCount > 0 ? `Modified Institution Branch Count: ${this.state.FinancialInstitutionBranchList.length}` : ""}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					</CardBody>
				</Card>

				<Card className="no-radius" >
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Modified Institutions    </p></b>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									ShowPagination={true}
									PageSize={100}
									Data={FinancialInstitutionList}
									Columns={this.ColumnsDataInst}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

				<Card className="no-radius" >
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Modified Institution Branches  </p></b>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									ShowPagination={true}
									PageSize={100}
									Data={FinancialInstitutionBranchList}
									Columns={this.ColumnsDataInstBranch}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div>
		);
	}
}

UploadCfifFile.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(UploadCfifFile, {});