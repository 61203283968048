import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { FeeProfileType, PriorityRegularType, GridColumnType } from "views/Constants/Constant";

class AgentCommissionDefinition extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ProfileModel: {},
			isLoading: false,
			DefinedProfiles: [],
			vModel: {},
			FeeProfileModel: {},
			FixedPropList: [],
			PeriodicPropList: [],
			FixedModelList: [],
			PeriodicModelList: [],
			WeightedModelList: []
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Sales Person / Agent Commission");
		this.props.setAppCenterTitle("PROFILES");
		this.FetchDefinedProfiles();
	}
	OperationCheck = () => {
		var ErrorList = [];
		const model = { ...this.state.ProfileModel };
		const vModel = this.state.vModel;

		if (model.Id <= 0 || model.Id == null) {
			if (model.ProgramId == null || model.ProgramId == "") {
				vModel.ProgramId = true;
				ErrorList.push("Program must be selected !");
			} else { vModel.ProgramId = false; }
		}

		if (model.FeeProfileId == null || model.FeeProfileId == "") {
			vModel.FeeProfileId = true;
			ErrorList.push("Fee profile must be selected !");
		} else { vModel.FeeProfileId = false; }
		if (model.ProfileName == null || model.ProfileName == "") {
			vModel.ProfileName = true;
			ErrorList.push("Profile name must be selected !");
		} else { vModel.ProfileName = false; }
		if (model.PaymentMethodId == null || model.PaymentMethodId == "") {
			vModel.PaymentMethodId = true;
			ErrorList.push("Payment method must be selected !");
		} else { vModel.PaymentMethodId = false; }
		if (model.PaymentPeriod == null || model.PaymentPeriod == "") {
			vModel.PaymentPeriod = true;
			ErrorList.push("Payment period must be selected !");
		} else { vModel.PaymentPeriod = false; }
		if (model.CommissionStartDate == null || model.CommissionStartDate == "") {
			vModel.CommissionStartDate = true;
			ErrorList.push("Commission Start Date must be selected !");
		} else { vModel.CommissionStartDate = false; }
		if (model.CommissionEndDate == null || model.CommissionEndDate == "") {
			vModel.CommissionEndDate = true;
			ErrorList.push("Commission End Date must be selected !");
		} else { vModel.CommissionEndDate = false; }
		if (model.FirstMonthAgreementRevenue == null || model.FirstMonthAgreementRevenue == "") {
			vModel.FirstMonthAgreementRevenue = true;
			ErrorList.push("First Month Agreement Revenue must be selected !");
		} else { vModel.FirstMonthAgreementRevenue = false; }
		if (model.NextMonthAgreementRevenue == null || model.NextMonthAgreementRevenue == "") {
			vModel.NextMonthAgreementRevenue = true;
			ErrorList.push("Next Month Agreement Revenue must be selected !");
		} else { vModel.NextMonthAgreementRevenue = false }

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		this.SubmitProfile();
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	SubmitProfile = () => {
		this.setState({ isLoading: true });

		var temp = { ...this.state.ProfileModel };
		temp.MaxPaymentRevenue = 0;

		Proxy.POST(
			"/bankapi/v1.0/FeeProfileAgentCommission/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ ProfileModel: responseData.Item || {} });
				}

				this.setState({ isLoading: false });
				this.FetchDefinedProfiles();
			},
			error => {
				this.showErrorMessage(error);
				this.setState({ isLoading: false });
			}
		);
	}
	DeleteProfile = () => {
		const profile = { ...this.state.ProfileModel };
		if (profile == null || profile.Id < 0) {
			this.showErrorMessage("Profile must be selected ! ");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/FeeProfileAgentCommission/Delete/Id?Id=" + profile.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				this.ClearData();
				this.showSuccessMessage("Profile Deleted ");
				this.setState({ isLoading: false });
				this.FetchDefinedProfiles();
			},
			error => {
				this.showErrorMessage(error);
				this.setState({ isLoading: false });
			}
		);
	}
	FetchDefinedProfiles = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/FeeProfileAgentCommission/GetAll",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ DefinedProfiles: responseData.Item || [] });
				}

				this.setState({ isLoading: false });
			},
			error => {
				this.showErrorMessage(error);
				this.setState({ isLoading: false });
			}
		);
	}
	FetchDataFixedFee = (ProfileId) => {
		if (ProfileId != 0 && ProfileId != null) {
			this.setState({ isLoading: true });
			this.setState({
				FeeProfileModel: {},
				FixedPropList: [],
				PeriodicPropList: [],
				FixedModelList: [],
				PeriodicModelList: [],
				WeightedModelList: []
			});

			Proxy.GET(
				"/bankapi/v1.0/FeeProfile/GetById/Id?Id=" + ProfileId,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.showErrorMessage(responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({
							FixedModelList: responseData.Item.Fixed || [],
							PeriodicModelList: responseData.Item.Periodic || [],
							WeightedModelList: responseData.Item.Scale || [],
							FeeProfileModel: responseData.Item.Profile || {}
						});
					}

					this.setState({ isLoading: false });
				},
				error => {
					this.showErrorMessage(error);
					this.setState({ isLoading: false });
				}
			);
		}
	}
	FetchPropFromParameter = (code) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "FeeType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: code
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ FixedPropList: responseData.Item || [] });
				}

				this.setState({ isLoading: false });
			},
			error => {
				this.ShowMessage(error);
				this.setState({ isLoading: false });
			}
		);
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "FeeType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: "PERIODIC"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ PeriodicPropList: responseData.Item || [] });
				}

				this.setState({ isLoading: false });
			},
			error => {
				this.showErrorMessage(error);
				this.setState({ isLoading: false });
			}
		);

	}
	ClearData = () => {
		this.setState({
			ProfileModel: {},
			isLoading: false,
			alert: null,
			selected: null,
			FixedPropList: [],
			PeriodicPropList: [],
			FixedModelList: [],
			PeriodicModelList: [],
			WeightedModelList: [],
			FeeProfileModel: {}
		});
		this.setState({ isLoading: false });
	}
	handleChangeProfile = (name, newValue, data) => {
		const model = { ...this.state.ProfileModel };
		model[name] = newValue;
		if (name == "FeeProfileId" && data != null) {
			const code = data.Program.ProgramCode;
			this.FetchPropFromParameter(code);
			this.FetchDataFixedFee(newValue);
		}
		this.setState({ ProfileModel: model });
	}
	SelectedRowChange = (e, index) => {
		const model = this.state.DefinedProfiles[index];
		this.setState({ ProfileModel: model });

		if (model != null && model.FeeProfile != null && model.FeeProfile.Program != null && model.FeeProfile.Program.ProgramCode != null) {
			const code = model.FeeProfile.Program.ProgramCode;
			this.FetchPropFromParameter(code);
			const profileId = model.FeeProfile.Id;
			this.FetchDataFixedFee(profileId);
		}
	}
	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	showDeleteMessage = () => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message="Delete this profile ?" Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteProfile()} />
		});
		this.setState({ isLoading: false });
	}
	showSuccessMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Success" Message={message} Type="success" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	showErrorMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Error" Message={message} Type="error" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	render() {
		const { Disabled } = this.props;
		const { ProfileModel, FeeProfileModel, DefinedProfiles, FixedModelList, FixedPropList, PeriodicPropList, PeriodicModelList, WeightedModelList, vModel } = this.state;
		const Fixed = FixedModelList.filter(x => x.IsCommissionCalculation == true);
		const Periodic = PeriodicModelList.filter(x => x.IsCommissionCalculation == true);
		const FFF = FixedPropList.filter(x => Fixed.some(y => y.FeeType != null && y.FeeType.ParameterValue == x.ParameterValue));
		const PPP = PeriodicPropList.filter(x => Periodic.some(y => y.FeeType != null && y.FeeType.ParameterValue == x.ParameterValue));


		const CM1 = WeightedModelList.find(x => x.LevelId == 1 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.IsCombineScale == true);
		const CM2 = WeightedModelList.find(x => x.LevelId == 2 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.IsCombineScale == true);
		const CM3 = WeightedModelList.find(x => x.LevelId == 3 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.IsCombineScale == true);
		const CM4 = WeightedModelList.find(x => x.LevelId == 4 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.IsCombineScale == true);
		const CM5 = WeightedModelList.find(x => x.LevelId == 5 && x.IsCombineScale == true) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.IsCombineScale == true);


		const RG1 = WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG2 = WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG3 = WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG4 = WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);
		const RG5 = WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Regular) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType.ParameterValue == PriorityRegularType.Regular);


		const PR1 = WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 1 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR2 = WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 2 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR3 = WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 3 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR4 = WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 4 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);
		const PR5 = WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType != null && x.PriorityType.ParameterValue == PriorityRegularType.Priority) == null ? {} : WeightedModelList.find(x => x.LevelId == 5 && x.PriorityType.ParameterValue == PriorityRegularType.Priority);


		var programId = 0;
		if (ProfileModel.Id > 0 && ProfileModel.FeeProfile != null) {
			programId = ProfileModel.FeeProfile.ProgramId;
		} else {
			programId = ProfileModel.ProgramId;
		}

		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					{this.state.alert}

					<LoadingComponent Show={this.state.isLoading} />

					<ButtonToolbar ButtonList={[
						{ Code: "Get", OnClick: () => this.FetchDefinedProfiles(), Disabled: Disabled },
						{ Code: "Delete", OnClick: () => this.showDeleteMessage(), Disabled: Disabled || !(ProfileModel.Id > 0) },
						{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
						{ Code: "Submit", OnClick: () => this.OperationCheck(), Disabled: Disabled }
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					<Card className="no-radius">
						<GenericExpansionPanel Title={"Main Parameters "}>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={3} >
										<GenericSelectInput Disabled={ProfileModel.Id > 0 ? true : false} Name="ProgramId" LabelText="Program" Value={programId} ValueChanged={this.handleChangeProfile} KeyValueMember="Id" TextValueMember="Description" Method="GET" Url="/bankapi/v1.0/Bank/ValidBankProgramsForFeeProfile" DataRoot="Item" Required
											IsInvalid={vModel.ProgramId} />
										<GenericSelectInput Disabled={ProfileModel.Id > 0 ? true : false} Value={ProfileModel.FeeProfileId || ""} Method="POST" LabelText="Fee Profile" key={programId} Name="FeeProfileId" Url="/bankapi/v1.0/FeeProfile/GetByPRogramId" Parameter={{ ProgramId: programId }} DataRoot="Item" KeyValueMember="Id" TextValueMember="ProfileName" ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.FeeProfileId} />
										<GenericTextInput Name="ProfileName" LabelText="Profile Name" Value={ProfileModel.ProfileName || ""} ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.ProfileName} />
									</GridItem>
									<GridItem xs={3} >
										<GenericSelectInput Name="PaymentMethodId" LabelText="Payment Method" Value={ProfileModel.PaymentMethodId} ValueChanged={this.handleChangeProfile} KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search" Parameter={{ ParameterCode: "PaymentMethod", ParameterValue: "", ParameterDesc: "" }} DataRoot="Item" Required
											IsInvalid={vModel.PaymentMethodId} />
										<GenericNumberInput NoFormatting={true} Name="PaymentPeriod" LabelText="Payment Period" Value={ProfileModel.PaymentPeriod || ""} ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.PaymentPeriod} />
									</GridItem>
									<GridItem xs={3} >
										<GenericDateInput LabelMd={7} MinDate={ProfileModel.CommissionStartDate || ""} Name="CommissionStartDate" LabelText="Commission Start Date" Value={ProfileModel.CommissionStartDate == null ? "" : DateHelper.ToDateInputValue(ProfileModel.CommissionStartDate)} ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.CommissionStartDate} />
										<GenericDateInput LabelMd={7} IsFuture={true} Max Name="CommissionEndDate" LabelText="Commission End Date" Value={ProfileModel.CommissionEndDate == null ? "" : DateHelper.ToDateInputValue(ProfileModel.CommissionEndDate)} ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.CommissionEndDate} />
									</GridItem>
									<GridItem xs={3} >
										<GenericNumberInput MaxLength={3} MaxValue={100} LabelMd={8} Name="FirstMonthAgreementRevenue" LabelText="% First Month Agreement Revenue" Value={ProfileModel.FirstMonthAgreementRevenue} ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.FirstMonthAgreementRevenue} />
										<GenericNumberInput MaxLength={3} MaxValue={100} LabelMd={8} Name="NextMonthAgreementRevenue" LabelText="% Next Month Agreement Revenue" Value={ProfileModel.NextMonthAgreementRevenue} ValueChanged={this.handleChangeProfile} Required
											IsInvalid={vModel.NextMonthAgreementRevenue} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>
					</Card >

					{FeeProfileModel != null && FeeProfileModel.FeeProfileType != null && FeeProfileModel.FeeProfileType.ParameterValue != null && FeeProfileModel.FeeProfileType.ParameterValue == FeeProfileType.Fixed && (
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Fee Details"}>

								<CardBody>
									<GridContainer spacing={16}>
										{FFF != null && FFF.map(item => (
											<GridItem xs={6} key={item.Id}>
												<GridContainer>
													<GridItem xs={9} >
														<GenericNumberInput MaxLength={7} LabelMd={9} Name="FeeAmount" LabelText={item.ParameterValue3}
															Value={Fixed.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : Fixed.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
															Disabled={true}
														/>
													</GridItem>
													<GridItem xs={3} >
														<GenericNumberInput MaxLength={7} LabelMd={6} Name="Cost" LabelText="Cost"
															Value={Fixed.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : Fixed.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
															Disabled={true}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										))}
										{PPP != null && PPP.map(item => (
											<GridItem xs={6} key={item.Id}>
												<GridContainer>
													<GridItem xs={9} >
														<GenericNumberInput MaxLength={7} LabelMd={9} Name="FeeAmount" LabelText={item.ParameterValue3}
															Value={Periodic.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : Periodic.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).FeeAmount}
															Disabled={true}
														/>
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput MaxLength={7} LabelMd={6} Name="Cost" LabelText="Cost"
															Value={Periodic.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue) == null ? "" : Periodic.find(x => x.FeeType != null && x.FeeType.ParameterValue == item.ParameterValue).Cost}
															Disabled={true}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										))}
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card >
					)}



					{FeeProfileModel != null && FeeProfileModel.FeeProfileType != null && FeeProfileModel.FeeProfileType.ParameterValue != null && FeeProfileModel.FeeProfileType.ParameterValue != FeeProfileType.Fixed && (
						<Card className="no-radius">
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Fee Details </p></b>
							<CardBody>
								<GridContainer spacing={16}>
									{FeeProfileModel != null && FeeProfileModel.IsCombineScale != null && FeeProfileModel.IsCombineScale == true && (
										<GridContainer spacing={16}>
											<GridItem xs={6} >
												<Card style={{ height: "90%" }}>
													<p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Combine Scale Fees </b></p>
													<CardBody>
														<GridContainer spacing={16}>
															<GridItem xs={2}> <p style={{ paddingTop: "20px" }}><b>Level</b></p></GridItem>
															<GridItem xs={2}>
																<p style={{ paddingTop: "20px" }}><b>From </b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>To</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}>Level 1</p>
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM1.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2}>
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM1.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={CM1.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={CM1.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM1.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
															<GridItem xs={2}>
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM2.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM2.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={CM2.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={CM2.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2}>
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM2.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
															<GridItem xs={2}>
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM3.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM3.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={CM3.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={CM3.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM3.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM4.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM4.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={CM4.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={CM4.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM4.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={CM5.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={CM5.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={CM5.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={CM5.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={CM5.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<br />
													</CardBody>
												</Card>
											</GridItem>
										</GridContainer>
									)}
									{FeeProfileModel != null && FeeProfileModel.IsCombineScale != null && FeeProfileModel.IsCombineScale == false && (
										<GridContainer spacing={16}>
											<GridItem xs={6} >
												<Card style={{ height: "90%" }}>
													<p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Transactions Fees - Regular Debit&Credit</b></p>
													<CardBody>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}><b>Level</b></p></GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>From </b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>To</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 1</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG1.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG1.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={RG1.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={RG1.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG1.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG2.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG2.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={RG2.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={RG2.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG2.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG3.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG3.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={RG3.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={RG3.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG3.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG4.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG4.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={RG4.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={RG4.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG4.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={RG5.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={RG5.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={RG5.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={RG5.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2}>
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={RG5.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<br />
													</CardBody>
												</Card>
											</GridItem>
											<GridItem xs={6} >
												<Card style={{ height: "90%" }}>
													<p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Transactions Fees - Priority Debit&Credit</b></p>
													<CardBody>
														<GridContainer spacing={16}>
															<GridItem xs={2}> <p style={{ paddingTop: "20px", paddingLeft: "10px" }}><b>Level</b></p></GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>From </b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>To</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Fee</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Cost</b></p>
															</GridItem>
															<GridItem xs={2} >
																<p style={{ paddingTop: "20px" }}><b>Commission</b></p>
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 1</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR1.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR1.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2}>
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={PR1.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={PR1.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR1.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2}> <p style={{ paddingTop: "20px" }}>Level 2</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR2.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR2.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={PR2.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={PR2.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR2.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 3</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR3.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR3.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={PR3.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={PR3.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR3.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 4</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR4.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR4.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={PR4.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={PR4.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR4.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<GridContainer spacing={16}>
															<GridItem xs={2} > <p style={{ paddingTop: "20px" }}>Level 5</p></GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelFrom" DecimalScale={0} LabelText="" Value={PR5.LevelFrom} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="LevelTo" DecimalScale={0} LabelText="" Value={PR5.LevelTo} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="FeeAmount" LabelText="" Value={PR5.FeeAmount} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericNumberInput MaxLength={6} Name="Cost" LabelText="" Value={PR5.Cost} Disabled={true} />
															</GridItem>
															<GridItem xs={2} >
																<GenericCheckInput Name="IsCommissionCalculation" LabelText="" Value={PR5.IsCommissionCalculation} Disabled={true} />
															</GridItem>
														</GridContainer>
														<br />
													</CardBody>
												</Card>
											</GridItem>
										</GridContainer>
									)}
								</GridContainer>
							</CardBody>
						</Card>
					)}

					<Card className="no-radius">
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Defined Profiles </p></b>
						<CardBody>
							<GridItem md={12} xs={12}>
								<GenericGrid
									Data={DefinedProfiles}
									PageSize={50}
									HideButton={true}
									Columns={[
										{
											Header: "Profile Name",
											accessor: "ProfileName"
										},
										{
											Header: "Fee Profile",
											accessor: "FeeProfile.ProfileName"
										},
										{
											Header: "Program",
											accessor: "FeeProfile.Program.Description"
										},
										{
											Header: "Commission Start Date",
											accessor: "CommissionStartDate",
											type: GridColumnType.Date
										},
										{
											Header: "Commission End Date",
											accessor: "CommissionEndDate",
											type: GridColumnType.Date
										},
										{
											Header: "First Month Agreement Revenue",
											accessor: "FirstMonthAgreementRevenue",
											Cell: row => (
												<div>% {(row.value)}</div>
											)
										},
										{
											Header: "Next Month Agreement Revenue",
											accessor: "NextMonthAgreementRevenue",
											Cell: row => (
												<div>% {(row.value)}</div>
											)
										}
									]}
									RowSelected={index => {
										this.setState({ selected: index });
										this.SelectedRowChange(null, index);
									}}
									SelectedIndex={this.state.selected}
								/>
							</GridItem>
						</CardBody>
					</Card >

				</GridItem>
			</GridContainer >
		);
	}
}

AgentCommissionDefinition.propTypes = {
	classes: PropTypes.object
};


export default withArtifex(AgentCommissionDefinition, {});