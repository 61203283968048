import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericDialog, GenericIcon } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, ClientType, CrudType, FeeProfileType, FileCode, FileType, MenuCodes, ProgramCodes, SalesChannel, GridColumnType } from "views/Constants/Constant";
import WireSenderInformation from "views/WireTransfer/Sender/WireSenderInformation";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";

import { IconButton, DialogTitle } from "@material-ui/core";
class ContractDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			contractModel: {},
			isFileChanged: false,
			CustomDisabled: null,
			vModel: {},
			isWireSenderPopupOpen: false,
			wireSenderModel: {},
			HasWireSender: false,
			RenderWirePopup: false
		};

		this.fileInputRef = React.createRef();


		this.ParameterFee = {};
		this.ParameterProgramId = {};
		this.ParameterId = {};
		this.ParameterSalesPerson = {
			UniqueClientId: 0,
			ClientTypes: [ClientType.SalesAgentPersonal, ClientType.SalesAgentCorporate]
		};

		this.ParameterSalesChannel = { ParameterCode: "SalesChannel" };
		this.ParameterSalesChannelDirect = { ParameterCode: "SalesChannel", ParameterValue2: "D" };
		this.ParameterNotificationPeriod = [{ Value: 30, Text: "30" }, { Value: 60, Text: "60" }, { Value: 90, Text: "90" }, { Value: 120, Text: "120" }, { Value: 150, Text: "150" }];
		this.ParameterRenewalType = { ParameterCode: "RenewalType" };
		this.ParameterContractStatus = { ParameterCode: "ContractStatus" };
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.ParameterIntendUseOfAccount = { ParameterCode: "IntendUseOfAccount" };
		this.ParameterFeeAccountMinBalance = [{ Value: 15000, Text: "15000" }, { Value: 10000, Text: "10000" }, { Value: 5000, Text: "5000" }];
		this.ParameterEmptyObject = {};
		this.ParameterMaxLength100 = { maxLength: 100 };
		this.ParameterMaxLength50 = { maxLength: 50 };
		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ contractModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Agreement Start Date",
				accessor: "AgreementStartDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Agreement End Date",
				accessor: "AgreementEndDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Sales Channel",
				accessor: "SalesChannelType.ParameterDesc"
			},
			{
				Header: "Renewal Type",
				accessor: "RenewalType.ParameterDesc"
			},
			{
				Header: "Last Renewal Date",
				accessor: "LastRenewalDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Notification Period",
				accessor: "NotificationPeriod"
			},
			{
				Header: this.props.isSalesAgentPersonel ? "Status" : "Contract Status",
				accessor: "ContractStatus.ParameterDesc"
			},
			{
				Header: "Closed Date",
				accessor: "ClosedDate",
				type: GridColumnType.DateUtc
			}
		];

	}


	ParameterProgramIdFunction = () => {
		this.ParameterProgramId.ProgramId = this.state.contractModel == null ? 0 : this.state.contractModel.ProgramId;
		return this.ParameterProgramId;
	}
	ParameterIdFunction = () => {
		this.ParameterId.Id = this.state.contractModel == null ? 0 : this.state.contractModel.ProgramId;
		return this.ParameterId;
	}
	ParameterFeeProfileFunction = () => {
		var temp = {
			ProgramId: this.state.contractModel == null ? 0 : this.state.contractModel.ProgramId,
			ClientType: this.props.paramGlobalModel == null ? 0 : this.props.paramGlobalModel.ClientType
		};
		this.ParameterFee = temp;
		return this.ParameterFee;
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
		{
			this.props.setAppLeftTitle(this.props.isSalesAgentPersonel ? "Rate Definition" : "Contract Details");
			this.getappLeftTitle = this.props.isSalesAgentPersonel ? "Rate Definition" : "Contract Details";
		}

		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a client or create new one to add contract person.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ contractModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedContracts, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}
	OperationCheck = () => {
		var ErrorList = [];
		const global = { ...this.props.paramGlobalModel };
		const data = this.state.contractModel;
		const vModel = this.state.vModel;
		if (global == null || global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add contract.");
		}
		if (data == null) {
			return;
		}
		if (data.ProgramId == null || data.ProgramId == "" || data.ProgramId == 0) {
			vModel.ProgramId = true;
			ErrorList.push("Program must be defined !.");
		} else { vModel.ProgramId = false; }
		if (data.RenewalTypeId == null || data.RenewalTypeId == "" || data.RenewalTypeId == 0) {
			vModel.RenewalTypeId = true;
			ErrorList.push("Renewal Type must be defined !.");
		} else { vModel.RenewalTypeId = false; }
		if (data.ContractStatusId == null || data.ContractStatusId == "" || data.ContractStatusId == 0) {
			vModel.ContractStatusId = true;
			ErrorList.push(this.props.isSalesAgentPersonel ? "Status" : "Contract Status" + " must be defined !.");
		} else { vModel.ContractStatusId = false; }
		if (global.MenuCode != MenuCodes.Backoffice_SalesAgentSetup) {
			if (data.SalesChannelTypeId == null || data.SalesChannelTypeId == "" || data.SalesChannelTypeId == 0) {
				vModel.SalesChannelTypeId = true;
				ErrorList.push("Sales Channel Type must be defined !.");
			} else { vModel.SalesChannelTypeId = false; }
		}

		if (data.SalesChannelTypeId == null || data.SalesChannelTypeId == "" || data.SalesChannelTypeId == 0) {
			vModel.SalesChannelTypeId = true;
		} else {
			vModel.SalesChannelTypeId = false;
			if (data.SalesPersonUniqueClientId == null || data.SalesPersonUniqueClientId == "" || data.SalesPersonUniqueClientId == 0) {
				vModel.SalesPersonUniqueClientId = true;
			} else { vModel.SalesPersonUniqueClientId = false; }
		}

		if (!this.props.isSalesAgentPersonel) {
			if (data.ProgramTypeStr != ProgramCodes.DigitalIdVerification && (data.LimitProfileId == null || data.LimitProfileId == "" || data.LimitProfileId == 0)) {
				vModel.LimitProfileId = true;
				ErrorList.push("Limit Profile must be defined !.");
			} else { vModel.LimitProfileId = false; }
			if (data.FeeProfileId == null || data.FeeProfileId == "" || data.FeeProfileId == 0) {
				vModel.FeeProfileId = true;
				ErrorList.push("Fee Profile must be defined !.");
			} else { vModel.FeeProfileId = false; }
		}
		if (global.ClientType == ClientType.SalesAgentCorporate || global.ClientType == ClientType.SalesAgentPersonal) {
			if (data.CommissionFeeProfileId == null || data.CommissionFeeProfileId == "" || data.CommissionFeeProfileId == 0) {
				vModel.CommissionFeeProfileId = true;
				ErrorList.push("Commission Profile must be defined !.");
			} else { vModel.CommissionFeeProfileId = false; }
		}
		if (data.NotificationPeriod == null || data.NotificationPeriod == "" || data.NotificationPeriod == 0) {
			vModel.NotificationPeriod = true;
			ErrorList.push("Notification Period must be defined !.");
		} else { vModel.NotificationPeriod = false; }
		if (data.Id > 0) {
			if (data.LastRenewalDate != null) {
				data.LastRenewalDate = DateHelper.ToMoment(data.LastRenewalDate);
				if (!data.LastRenewalDate.isValid()) {
					ErrorList.push("Last Renewal Date must be valid !.");
				}
			}
		}

		if (data.AgreementStartDate == null || data.AgreementStartDate == "") {
			vModel.AgreementStartDate = true;
			ErrorList.push("Agreement Start Date must be defined !.");
		} else {
			vModel.AgreementStartDate = false;
			data.AgreementStartDate = DateHelper.ToMoment(data.AgreementStartDate);
			if (!data.AgreementStartDate.isValid()) {
				ErrorList.push("Agreement Start Date must be valid !.");
			}
		}

		if ((data.ProgramTypeStr != ProgramCodes.Bank_Programs_Bank_Account) && data.AgreementEndDate == null || data.AgreementEndDate == "") {
			vModel.AgreementEndDate = true;
			ErrorList.push("Agreement End Date must be defined !.");
		} else {
			vModel.AgreementEndDate = false;
			data.AgreementEndDate = DateHelper.ToMoment(data.AgreementEndDate);
			if (!data.AgreementEndDate.isValid()) {
				ErrorList.push("Agreement End Date must be valid !.");
			}
		}

		if (data.IsETransferActive == true) {
			if (data.InteracEmail == null || data.InteracEmail == "") {
				vModel.InteracEmail = true;
				ErrorList.push("E-mail must be defined !");
			} else { vModel.InteracEmail = false; }
			if (data.InteracEmail != null && !this.ValidateEmail(data.InteracEmail)) {
				ErrorList.push("E-mail must be valid !");
			}
		}


		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		// this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?", () => {
		// 	this.hideAlert();
		// 	this.SendToWorkflow();
		// });

		if (data != null && data.ContractFile != null && this.state.isFileChanged == true) {
			this.FileUpload();
		} else if (data != null && data.ContractFile == null) {
			this.UpdateModel();
		} else if (data != null && data.ContractFile != null && this.state.isFileChanged == false) {
			this.UpdateModel();
		} else {
			console.log("Contract Save -> ", "Invalid Operation ");
		}

	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
		this.setState({ isLoading: false });
	}
	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var cf = this.state.contractModel.ContractFile;
		if (cf == null) {
			var temp = {
				TempFileId: 0,
				File: file,
				FileName: file.name
			};
			this.handleChange("ContractFile", temp);
		} else {
			cf.TempFileId = cf.Id;
			cf.File = file;
			cf.FileName = file.name;

			this.handleChange("ContractFile", cf);
		}
		this.setState({ isFileChanged: true });
	}

	DownloadFile = () => {
		var file = this.state.contractModel.ContractFile;
		if (file == null || file.FileName == null || file.FileName == "") {
			console.log("DownloadFile ->", "File name NULL");
			return;
		}
		if (file == null || file.FileExtension == null || file.FileExtension == "" || file.FileExtension.ParameterDesc == null) {
			console.log("DownloadFile ->", "File Extension NULL");
			return;
		}
		if (file == null || this.state.contractModel.ContractFileId == null || this.state.contractModel.ContractFileId == 0) {
			console.log("DownloadFile ->", "File ID NULL");
			return;
		}
		if (this.state.isFileChanged) {
			console.log("DownloadFile ->", "File changed can not download");
			return;
		}

		this.setState({ isLoading: true });
		Proxy.FileDownloadWithGenericResponse(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			this.state.contractModel.ContractFile.FileName,
			this.state.contractModel.ContractFile.FileExtension.ParameterDesc,
			{
				"FileId": this.state.contractModel.ContractFileId
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	FileUpload = () => {

		var temp = this.state.contractModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}

		var formData = new FormData();
		var model = { ...this.state.contractModel.ContractFile };
		formData.append("file", model.File, model.FileName);
		formData.append("fileId", model.TempFileId || 0);
		formData.append("fileCode", FileCode.Contract);
		formData.append("isBinaryOrBase64", FileType.Binary);
		formData.append("isInsertOrUpdate", temp.ContractFileId > 0 ? CrudType.Update : CrudType.Insert);

		this.setState({ isLoading: true });
		Proxy.FileUpload(
			"/coreapi/v1.0/File/Uploadfile",
			formData,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				temp.ContractFileId = responseData.Item;
				Proxy.POST(
					"/bankapi/v1.0/CustomerContract/Update",
					temp,
					responseData2 => {
						if (!responseData2.IsSucceeded) {
							this.ShowMessage("error", "Error", responseData2.ErrorDescription);
							return;
						}
						this.ShowMessage("success", "Success", temp.Id > 0 ? "Client Contract Updated ! " : "Client Contract Saved ! ");
						this.ClearData();
						this.props.GetCustomerContractModels();
					},
					error => {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", error);
					}
				);

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	SendToWorkflow = () => {
		var data = this.state.contractModel;
		const global = this.props.paramGlobalModel;

		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		data.UniqueClientId = global.Id;
		data.WorkflowUniqueClientId = global.UniqueClientId;
		data.WorkflowClientType = global.ClientType;
		data.WorkflowClientName = global.CustomerName;

		var jsonData = JSON.stringify(data);

		var requestData = {
			MenuCode: MenuCodes.Contract_Approve,
			ActionCode: ActionCodes.Submit,
			JsonData: jsonData,
			RowId: data.Id
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
			requestData,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Request successfully sent for approval");
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred while sending approve request " + typeof e == "object" ? JSON.stringify(e) : e);
			}
		);
	}
	UpdateModel = () => {
		var temp = this.state.contractModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerContract/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Client Contract Updated ! " : "Client Contract Saved ! ");
				this.ClearData();
				this.props.GetCustomerContractModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);

	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Client contract must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerContract/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Client contract deleted !");
				this.ClearData();
				this.props.GetCustomerContractModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.contractModel || {};
			if (name == "SalesChannelTypeId" && data != null) {
				if (data.ParameterValue == SalesChannel.SalesPerson) {
					model.IsSalesChannel = true;
				} else {
					model.IsSalesChannel = false;
					model.SalesChannelTypeId = null;
				}
			}
			if (name == "ProgramId") {
				model.IsMsbClient = false;
				model.LimitProfileId = null;
				model.FeeProfileId = null;
				model.CommissionFeeProfileId = null;
				if (data != null) {
					model.ProgramTypeStr = data.ProgramCode;
					if (model.ProgramTypeStr == ProgramCodes.Bank_Programs_Bank_Account)
						this.HandleGetWireSenderInfo();
				}

			}
			if (name == "IsETransferActive" && newValue == false) {
				model.IsDdrActive = false;
			}
			if (name == "FeeProfileId" && data != null) {
				if (data.FeeProfileType != null && data.FeeProfileType.ParameterValue == FeeProfileType.UnWeighed) {
					model.IsUnWeighedFeeProfile = true;
				} else {
					model.IsUnWeighedFeeProfile = false;
				}

				if (data.FeeProfileType != null && data.FeeProfileType.ParameterValue == FeeProfileType.CombineUnWeighted) {
					model.IsCombineUnWeighedFeeProfile = true;
				} else {
					model.IsCombineUnWeighedFeeProfile = false;
				}

				model.AggrementPriorityTransactionCount = null;
				model.AggrementRegularTransactionCount = null;
				model.AggrementTransactionCount = null;

			}


			model[name] = newValue;
			return { contractModel: model };
		});

	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerContractList[index];
		if (model != null && model.Program != null) {
			model.ProgramTypeStr = model.Program.ProgramCode;
		}
		this.setState({ contractModel: model });
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ contractModel: model, isFileChanged: false, vModel: {} });
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	addWireSenderDialogClose = () => {
		this.setState({ IsWireSenderPopupOpen: false, RenderWirePopup: false });
	}

	HandleGetWireSenderInfo = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/WireTransferSender/GetByUniqueClientId",
			{ Id: this.props.paramGlobalModel.Id },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "test", responseData.ErrorDescription);
					return;
				}
				this.setState({ wireSenderModel: responseData.Item || {}, HasWireSender: responseData.Item != null, RenderWirePopup: true });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	render() {
		const { Disabled: DisabledProp, paramGlobalModel, paramBankCustomerContractList } = this.props;
		const { contractModel, isFileChanged, vModel, IsWireSenderPopupOpen, wireSenderModel, HasWireSender, RenderWirePopup } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;
		return (

			<>
				{(contractModel != null && contractModel.ProgramTypeStr == ProgramCodes.Bank_Programs_Bank_Account) && RenderWirePopup &&
					<GenericDialog open={IsWireSenderPopupOpen} maxWidth="lg" onBackdropClick={this.addWireSenderDialogClose} fullWidth keepMounted={true}>
						<DialogTitle>
							<GridContainer justify="space-between" alignItems="center">
								<GridItem>
									<GenericLabel FontSize="16px" TextColor="black" Text="Wire Sender Information" Bold={true} />
								</GridItem>
								<GridItem>
									<IconButton onClick={this.addWireSenderDialogClose}>
										<GenericIcon>close</GenericIcon>
									</IconButton>
								</GridItem>
							</GridContainer>
						</DialogTitle>
						<DialogContent>
							<WireSenderInformation
								SenderModel={wireSenderModel}
								ClientInfo={paramGlobalModel}
								showMessage={this.props.showMessage}
								GetData={this.HandleGetWireSenderInfo}
								addWireSenderDialogClose={this.addWireSenderDialogClose}
								getAppLeftTitle={this.getappLeftTitle}
								getappCenterTitle={this.getappCenterTitle}
							/>
						</DialogContent>
					</GenericDialog>
				}
				<GridContainer spacing={16}>
					{this.state.alert}
					<LoadingComponent Show={this.state.isLoading} />
					<GridContainer justify="flex-end">
						<GridItem>
							{this.props.paramBankCustomerContractList < 1 && (
								<GenericLabel Text="Define at Least 1 Contract to Proceed" />
							)}
							<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeParentTab(null, 0)} > Back </Button>
							<Button size="sm" color="black" disabled={Disabled || this.props.paramBankCustomerContractList < 1} onClick={() => {
								this.props.handleChangeParentTab(null, 2);
								this.props.handleChangeChildTab(null, 0);
							}
							} > Next </Button>
						</GridItem>
					</GridContainer>

					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer spacing={16}>
										<GridItem xs={4}>
											<GridItem>
												{(paramGlobalModel.ClientType == ClientType.Personal) && (
													<GenericSelectInput
														Disabled={Disabled || contractModel != null && contractModel.Id > 0}
														Name="ProgramId"
														LabelText="Program"
														Value={contractModel.ProgramId || ""}
														ValueChanged={this.handleChange}
														KeyValueMember="Id"
														TextValueMember="Description"
														Method="GET"
														Url={"/bankapi/v1.0/Bank/ValidBankProgramsIndividual"}
														DataRoot="Item"
														Required
														IsInvalid={vModel.ProgramId}
													/>
												)}
												{(paramGlobalModel.ClientType == ClientType.Corporate || paramGlobalModel.ClientType == ClientType.SalesAgentPersonal) && (
													<GenericSelectInput
														Disabled={Disabled || contractModel != null && contractModel.Id > 0}
														Name="ProgramId"
														LabelText="Program"
														Value={contractModel.ProgramId || ""}
														ValueChanged={this.handleChange}
														KeyValueMember="Id"
														TextValueMember="Description"
														Method="GET"
														Url="/bankapi/v1.0/Bank/ValidBankProgramsCorporate"
														DataRoot="Item"
														Required
														IsInvalid={vModel.ProgramId}
													/>
												)}

											</GridItem>
											<GridItem>
												<GenericDateInput
													Utc
													Disabled={Disabled}
													Name="AgreementStartDate"
													LabelText="Agreement Start Date"
													MaxDate={(contractModel != null && contractModel.ProgramTypeStr != ProgramCodes.Bank_Programs_Bank_Account && contractModel.AgreementEndDate) && DateHelper.ToMoment(contractModel.AgreementEndDate)}
													Value={contractModel.AgreementStartDate == null ? "" : DateHelper.ToDateInputValue(contractModel.AgreementStartDate) || ""}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.AgreementStartDate}
												/>
											</GridItem>
											{(contractModel != null && contractModel.ProgramTypeStr != ProgramCodes.Bank_Programs_Bank_Account) && (
												<GridItem>
													<GenericDateInput
														Utc
														MinDate={contractModel.AgreementStartDate && DateHelper.ToMoment(contractModel.AgreementStartDate)}
														Disabled={Disabled}
														Name="AgreementEndDate"
														LabelText="Agreement End Date"
														Value={contractModel.AgreementEndDate == null ? "" : DateHelper.ToDateInputValue(contractModel.AgreementEndDate) || ""}
														ValueChanged={this.handleChange}
														Required
														IsInvalid={vModel.AgreementEndDate} />
												</GridItem>
											)}
											{paramGlobalModel.MenuCode != MenuCodes.Backoffice_SalesAgentSetup && (
												<GridItem>
													{(paramGlobalModel.ClientType == ClientType.SalesAgentPersonal || paramGlobalModel.ClientType == ClientType.SalesAgentCorporate) && (
														<GenericSelectInput
															Disabled={Disabled}
															Name="SalesChannelTypeId"
															LabelText="Sales Channel"
															Value={contractModel.SalesChannelTypeId || ""}
															ValueChanged={this.handleChange}
															KeyValueMember="Id"
															TextValueMember="ParameterDesc"
															Method="POST"
															Url="/coreapi/v1.0/Parameter/Search"
															Parameter={this.ParameterSalesChannelDirect}
															DataRoot="Item"
															Required
															IsInvalid={vModel.SalesChannelTypeId}
														/>
													)}
													{(paramGlobalModel.ClientType == ClientType.Personal || paramGlobalModel.ClientType == ClientType.Corporate) && (
														<GenericSelectInput
															Disabled={Disabled}
															Name="SalesChannelTypeId"
															LabelText="Sales Channel"
															Value={contractModel.SalesChannelTypeId || ""}
															ValueChanged={this.handleChange}
															KeyValueMember="Id"
															TextValueMember="ParameterDesc"
															Method="POST"
															Url="/coreapi/v1.0/Parameter/Search"
															Parameter={this.ParameterSalesChannel}
															DataRoot="Item"
															Required
															IsInvalid={vModel.SalesChannelTypeId}
														/>
													)}
												</GridItem>
											)}
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Disabled={Disabled}
													Name="NotificationPeriod"
													LabelText="Notification Period"
													IsStatic={true}
													StaticData={this.ParameterNotificationPeriod}
													KeyValueMember="Value"
													TextValueMember="Text"
													Value={contractModel.NotificationPeriod}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.NotificationPeriod}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Disabled={Disabled}
													Name="RenewalTypeId"
													LabelText="Renewal Type"
													Value={contractModel.RenewalTypeId}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterRenewalType}
													DataRoot="Item"
													Required
													IsInvalid={vModel.RenewalTypeId}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Utc
													Disabled={Disabled || (contractModel != null && contractModel.Id > 0) == false}
													MinDate={(contractModel && contractModel.ProgramTypeStr != ProgramCodes.Bank_Programs_Bank_Account && contractModel.AgreementEndDate) ? DateHelper.ToMoment(contractModel.AgreementEndDate).toDate() : null}
													Name="LastRenewalDate"
													LabelText="Last Renewal Date"
													Value={contractModel.LastRenewalDate == null ? "" : DateHelper.ToDateInputValue(contractModel.LastRenewalDate) || ""}
													ValueChanged={this.handleChange} />
											</GridItem>
											<GridItem>
												{contractModel != null && ((contractModel.SalesChannelType != null && contractModel.SalesChannelType.ParameterValue == SalesChannel.SalesPerson) || (contractModel.IsSalesChannel == true)) && (
													<GenericSelectInput
														Disabled={Disabled}
														Name="SalesPersonUniqueClientId"
														LabelText="Sales Person"
														Value={contractModel.SalesPersonUniqueClientId || ""}
														DataRoot="Item"
														ValueChanged={this.handleChange}
														KeyValueMember="Id"
														TextValueMember="Name"
														Url="/bankapi/v1.0/BankCustomer/Search" Method="POST"
														Parameter={this.ParameterSalesPerson}
														Required
														IsInvalid={vModel.SalesPersonUniqueClientId}
													/>
												)}
											</GridItem>
											{paramGlobalModel.MenuCode != MenuCodes.Backoffice_SalesAgentSetup && (
												<GridItem>
													{(contractModel != null && contractModel.ProgramTypeStr == ProgramCodes.Bank_Programs_Bank_Account) && (
														<GenericCheckInput
															Disabled={Disabled}
															Name="IsMsbClient"
															LabelText="Msb Client"
															Value={contractModel.IsMsbClient || false}
															ValueChanged={this.handleChange}
														/>
													)}
												</GridItem>
											)}
											{contractModel != null && contractModel.ProgramTypeStr == ProgramCodes.Bank_Programs_Bank_Account &&
												<>
													<GridItem>
														<GridContainer justify="flex-start">
															<GridItem xs={6} style={{ marginLeft: "-2px" }}>
																<GenericCheckInput
																	Disabled={true}
																	Name="HasWireSender"
																	LabelText="Wire Sender Information"
																	Value={HasWireSender || false}
																	ValueChanged={this.handleChange}
																/>
															</GridItem>
															<GridItem xs={2} style={{ marginLeft: "-75px" }}>
																<Button size="sm" onClick={() => this.setState({ IsWireSenderPopupOpen: true, RenderWirePopup: true })}>{!HasWireSender ? "Create Sender" : "Update Sender"}</Button>
															</GridItem>
														</GridContainer>
													</GridItem>
												</>
											}
											{/* { paramGlobalModel.MenuCode != MenuCodes.Backoffice_SalesAgentSetup && (
											<GridItem>
												<GenericCheckInput
													Disabled={Disabled}
													Name="IsETransferActive"
													LabelText="Register Customer to Interac"
													Value={contractModel.IsETransferActive || false}
													ValueChanged={this.handleChange}
												/>
												{(contractModel.IsETransferActive == true) && (
													<>
														<GenericCheckInput
															Disabled={Disabled}
															Name="IsDdrActive"
															LabelText="Activate DDR"
															Value={contractModel.IsDdrActive || false}
															ValueChanged={this.handleChange}
														/>
														<GenericEmailInput
															Disabled={Disabled}
															inputProps={this.ParameterMaxLength100}
															Name="InteracEmail"
															LabelText="e-Transfer e-Mail"
															Value={contractModel.InteracEmail || ""}
															ValueChanged={this.handleChange}
															Required
															IsInvalid={vModel.InteracEmail}
														/>

													</>
												)}
											</GridItem>
										)} */}
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Disabled={Disabled}
													Name="ContractStatusId"
													LabelText={this.props.isSalesAgentPersonel ? "Status" : "Contract Status"}
													Value={contractModel.ContractStatusId || ""}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST" Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterContractStatus}
													DataRoot="Item"
													Required
													IsInvalid={vModel.ContractStatusId}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Disabled={Disabled}
													Method="POST"
													LabelMd={4}
													LabelText="Fee Profile"
													key={contractModel == null ? "fee" : contractModel.ProgramId + "fee" || "f"}
													Name="FeeProfileId"
													Url="/bankapi/v1.0/FeeProfile/GetByProgramId"
													Parameter={this.ParameterFeeProfileFunction()}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ProfileName"
													Value={contractModel.FeeProfileId || ""}
													ValueChanged={this.handleChange}
													Required={this.props.isSalesAgentPersonel ? false : true}
													IsInvalid={this.props.isSalesAgentPersonel ? false : vModel.FeeProfileId}
												/>

												{(paramGlobalModel.ClientType == ClientType.SalesAgentCorporate || paramGlobalModel.ClientType == ClientType.SalesAgentPersonal) && (
													<GenericSelectInput
														Disabled={Disabled}
														Method="POST"
														LabelMd={4}
														LabelText="Commission Profile"
														key={contractModel == null ? "comm" : contractModel.ProgramId + "comm" || "c"}
														Name="CommissionFeeProfileId"
														Url="/bankapi/v1.0/FeeProfileAgentCommission/GetByProgramId"
														Parameter={this.ParameterIdFunction()}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ProfileName"
														Value={contractModel.CommissionFeeProfileId || ""}
														ValueChanged={this.handleChange}
														Required
														IsInvalid={vModel.CommissionFeeProfileId}
													/>
												)}
												{((contractModel != null && contractModel.IsUnWeighedFeeProfile) || (contractModel != null && contractModel.AggrementPriorityTransactionCount != null)) && (
													<GenericNumberInput
														Disabled={Disabled}
														MaxLength={8}
														DecimalScale={0}
														LabelMd={4}
														Name="AggrementPriorityTransactionCount"
														LabelText="Aggrement Priority Transaction Count"
														Value={contractModel.AggrementPriorityTransactionCount || ""}
														ValueChanged={this.handleChange} />
												)}
												{((contractModel != null && contractModel.IsUnWeighedFeeProfile) || (contractModel != null && contractModel.AggrementRegularTransactionCount != null)) && (
													<GenericNumberInput
														Disabled={Disabled}
														MaxLength={8}
														DecimalScale={0}
														LabelMd={4}
														Name="AggrementRegularTransactionCount"
														LabelText="Aggrement Regular Transaction Count"
														Value={contractModel.AggrementRegularTransactionCount || ""}
														ValueChanged={this.handleChange} />
												)}
												{((contractModel != null && contractModel.IsCombineUnWeighedFeeProfile) || (contractModel != null && contractModel.AggrementTransactionCount != null)) && (
													<GenericNumberInput
														Disabled={Disabled}
														MaxLength={8}
														DecimalScale={0}
														LabelMd={4}
														Name="AggrementTransactionCount"
														LabelText="Aggrement Transaction Count"
														Value={contractModel.AggrementTransactionCount || ""}
														ValueChanged={this.handleChange} />
												)}
											</GridItem>
											{!this.props.isSalesAgentPersonel &&
												< GridItem >
													<GenericSelectInput
														Disabled={Disabled || contractModel.ProgramTypeStr == ProgramCodes.DigitalIdVerification}
														Method="POST"
														LabelMd={4}
														LabelText="Limit Profile"
														key={contractModel == null ? "lim" : contractModel.ProgramId + "lim" || "l"}
														Name="LimitProfileId"
														Url="/bankapi/v1.0/LimitProfile/GetByProgramId"
														Parameter={this.ParameterProgramIdFunction()}
														DataRoot="Item" KeyValueMember="Id"
														TextValueMember="ProfileName"
														Value={contractModel.LimitProfileId || ""}
														ValueChanged={this.handleChange}
														Required
														IsInvalid={vModel.LimitProfileId}
													/>

												</GridItem>}
											<GridItem>
												<GridContainer justify="flex-start">
													<GridItem>
														<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef.current.click()} >Browse File</Button>
														<input type="file" key={contractModel.FileName} style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="application/pdf" />
													</GridItem>
													<GridItem>
														<Button disabled={Disabled || isFileChanged || (contractModel.ContractFile == null ? true : contractModel.ContractFile.FileName == null ? true : false)} size="sm" onClick={() => this.DownloadFile()}>View File</Button>
													</GridItem>
													<GridItem style={{ paddingTop: 10 }}>
														<GenericLabel
															Bold={true}
															FontSize="12px"
															TextColor="Black"
															Text={contractModel.ContractFile == null ? "" : contractModel.ContractFile.FileName}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</GridItem>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>Clear</Button>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{contractModel.Id > 0 ? "Update" : "Add"}</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>

					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={paramBankCustomerContractList}
										PageSize={5}
										HideButton={true}
										Columns={this.CloumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				</GridContainer >
			</>
		);
	}
}

ContractDetails.propTypes = {
	classes: PropTypes.object,
	contractModel: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerContractList: PropTypes.array,
	GetCustomerContractModels: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	showMessage: PropTypes.func
};


export default withArtifex(ContractDetails, {});