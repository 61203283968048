import { primaryColor,defaultFont } from "assets/jss/material-dashboard-pro-react.jsx";

const genericRadioInputStyle = {
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#888888",
		fontSize: "12px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex",
		transition: "0.3s ease all"
	},
	radioLabel: {
		cursor: "pointer",
		paddingLeft: "0",
		fontSize: "12px",
		color: "#000 !important",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex",
		transition: "0.3s ease all"
	},
	radio: {
		color: primaryColor + "!important"
	},
	radioChecked: {
		width: "18px",
		height: "18px",
		border: "1px solid " + primaryColor,
		borderRadius: "50%"
	},
	radioUnchecked: {
		width: "0px",
		height: "0px",
		padding: "8px",
		border: "1px solid rgba(0, 0, 0, .54)",
		borderRadius: "50%"
	},
	labelRoot: {
		...defaultFont,
		color: "#000 !important",
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "1.42857",
		//  top: "10px",
		position: "static"
	},
	
	FormControlRadio:{
		color: "red",
	"& span": {
		paddingTop: "0px",
		paddingBottom: "0px"
	}}
};

export default genericRadioInputStyle;
