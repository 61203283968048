import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import {
  GenericAlert,
  GenericGrid,
  GenericLabel,
  GenericSelectInput,
  GenericTextInput,
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import {
  ActionCodes,
  AddressType,
  ClientType,
  MenuCodes,
} from "views/Constants/Constant.js";

class CanadaPostAddressComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {},
      isLoading: false,
      alert: null,
      ProvinceList: [],
      CityList: [],
    };

    this.ParameterAddressType = { ParameterCode: "AddressType" };
    this.ParameterAddressTypeIndv = {
      ParameterCode: "AddressType",
      ParameterValue3: "P",
    };
    this.lengthAddress = { maxLength: 280 };
    this.lengthZipCode = { maxLength: 40 };
  }

  componentDidMount() {
    this.LoadCanadaPost();
    this.modelUpdate();
  }
  modelUpdate = () => {
    const { addressModel } = this.props;
    if (addressModel?.CountryId > 0) {
      this.getProvince(addressModel.CountryId);
      if (addressModel?.ProvinceId > 0) {
        this.getCity(addressModel.ProvinceId);
      }
    }
  };
  LoadCanadaPost = () => {
    Proxy.POST(
      "/coreapi/v1.0/Parameter/Search",
      {
        ParameterCode: "CanadaPostApiKey",
        ParameterValue: "CP",
        ParameterDesc: "",
      },
      (responseData) => {
        if (!responseData.IsSucceeded) {
          console.log("Canada Post Api key not fetched !");
          return;
        }
        if (responseData.Item == null) {
          console.log("Canada Post Api key not fetched !");
          return;
        }
        if (responseData.Item[0] == null) {
          console.log("Canada Post Api key not fetched !");
          return;
        }
        var apikey = responseData.Item[0].ParameterDesc;
        console.log("KEY CP ", apikey);

        var url = window.CanadaPostAddressCompleteUrl;
        var path = url + apikey;

        var pca;

        var handleChangeCP = this.props.handleChange;
        var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
        var CanadaPostRequest = this.SubmitCanadaPostRequest;

        const script = document.createElement("script");
        script.src = path;
        script.async = false;
        document.head.appendChild(script);
        script.onload = function () {
          pca = window.pca;
          if (pca != null) {
            var fields = [
                {
                  element: "street-address",
                  field: "Line1",
                  mode: pca.fieldMode.SEARCH,
                },
                {
                  element: "street-address2",
                  field: "Line2",
                  mode: pca.fieldMode.POPULATE,
                },
                {
                  element: "postcode",
                  field: "PostalCode",
                  mode: pca.fieldMode.SEARCH,
                },
              ],
              options = {
                key: apikey,
              },
              control = new pca.Address(fields, options);
            CanadaPostRequest();

            control.listen("populate", function (address) {
              var temp = {};
              temp.Address1 = address.Line1;
              temp.Address2 = address.Line2;
              temp.ZipCode = address.PostalCode;

              handleChangeCP("Address1", address.Line1);
              handleChangeCP("Address2", address.Line2);
              handleChangeCP("ZipCode", address.PostalCode);

              CanadaPostSubmit(address);
            });
          }
        };
      },
      (e) => {
        console.log("Canada Post Api key not fetched !");
      }
    );
  };
  SubmitCanadaPostAddressModel = (model) => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/coreapi/v1.0/Country/CanadaPost",
      model,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        this.setState({ isLoading: false });
        var address = {};
        if (responseData.Item != null) {
          address.CountryId = responseData.Item.CountryId;
          address.ProvinceId = responseData.Item.ProvinceId;
          address.CityId = responseData.Item.CityId;
        } else {
          address.CountryId = 0;
          address.ProvinceId = 0;
          address.CityId = 0;
        }
        this.props.handleChange("CountryId", address.CountryId);
        this.props.handleChange("ProvinceId", address.ProvinceId);
        this.props.handleChange("CityId", address.CityId);
        this.modelUpdate();
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };
  SubmitCanadaPostRequest = async () => {
    const model = { ...this.state.model };
    var requestdata = {
      UniqueClientId: null,
      MenuCode: this.props.MenuCode,
      AppCenterTitle: "CanadaPostAddress",
      AppLeftTitle: "CanadaPostAddress",
    };
    // var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
    Proxy.POST(
      "/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
      requestdata,
      (responseData) => {
        if (responseData.Item != null) {
        }
      }
    );
  };
  ShowMessage = (type, title, message) => {
    // type:-success->warning->info->error
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };
  getProvince = (CountryId) => {
    Proxy.POST(
      "/coreapi/v1.0/Province/Filter",
      { CountryId: CountryId },
      (responseData) => {
        if (!responseData.IsSucceeded) {
          console.log(responseData.ErrorDescription);
          return;
        }
        if (responseData.Item == null) {
          console.log("Response Item Null!");
          return;
        }
        this.setState({ ProvinceList: responseData.Item });
      },
      (e) => {
        console.log(e);
      }
    );
  };
  getCity = (ProvinceId) => {
    Proxy.POST(
      "/coreapi/v1.0/City/Filter",
      { ProvinceId: ProvinceId },
      (responseData) => {
        if (!responseData.IsSucceeded) {
          console.log(responseData.ErrorDescription);
          return;
        }
        if (responseData.Item == null) {
          console.log("Response Item Null!");
          return;
        }
        this.setState({ CityList: responseData.Item });
      },
      (e) => {
        console.log(e);
      }
    );
  };
  RenderLeftSide = (model, Disabled) => {
    return (
      <>
        <GenericTextInput
          Disabled={Disabled}
          inputProps={this.lengthAddress}
          Name="Address1"
          Id="street-address"
          LabelText="Address Line 1"
          Value={model == null ? "" : model.Address1 || ""}
          ValueChanged={this.props.handleChange}
          Required={this.props.isAddressOptional == false ? false : true}
          IsInvalid={
            this.props.isAddressOptional == false
              ? false
              : this.props.vModel.Address1
          }
        />
        <GenericTextInput
          Disabled={Disabled}
          inputProps={this.lengthAddress}
          Name="Address2"
          Id="street-address2"
          LabelText="Address Line 2"
          Value={model == null ? "" : model.Address2 || ""}
          ValueChanged={this.props.handleChange}
        />

        <GenericSelectInput
          Name="CountryId"
          LabelText="Country"
          Method="POST"
          Url="/coreapi/v1.0/Country/GetAll"
          Parameter={{}}
          DataRoot="Item"
          KeyValueMember="Id"
          TextValueMember="Name"
          Value={model.CountryId}
          ValueChanged={(name, newValue, data) => {
            this.props.handleChange(name, newValue, data);
            this.setState({ ProvinceList: [] }, () =>
              this.getProvince(newValue)
            );
          }}
          Disabled={Disabled}
          Required={this.props.isCountryOptional == false ? false : true}
          IsInvalid={
            this.props.isCountryOptional == false
              ? false
              : this.props.vModel.CountryId
          }
        />
      </>
    );
  };
  RenderRightSide = (model, Disabled) => {
    return (
      <>
        {model.CountryId > 0 && this.state.ProvinceList.length < 1 ? (
          <GenericTextInput
            Required={this.props.isProvinceOptional == false ? false : true}
            IsInvalid={
              this.props.isProvinceOptional == false
                ? false
                : this.props.vModel.ProvinceId
            }
            Name="NewProvince"
            LabelText="Province"
            Value={model.NewProvince}
            ValueChanged={this.props.handleChange}
          />
        ) : (
          <GenericSelectInput
            Name="ProvinceId"
            LabelText="Province"
            IsStatic
            StaticData={this.state.ProvinceList}
            DataRoot="Item"
            KeyValueMember="Id"
            TextValueMember="Name"
            Value={model.ProvinceId}
            ValueChanged={(name, newValue, data) => {
              this.props.handleChange(name, newValue, data);
              this.setState({ CityList: [] }, () => this.getCity(newValue));
            }}
            Disabled={Disabled}
            Required={this.props.isProvinceOptional == false ? false : true}
            IsInvalid={
              this.props.isProvinceOptional == false
                ? false
                : this.props.vModel.ProvinceId
            }
          />
        )}
        {(model.ProvinceId > 1 && this.state.CityList.length < 1) ||
        (model.CountryId > 0 && this.state.ProvinceList.length < 1) ? (
          <GenericTextInput
            Required={this.props.isProvinceOptional == false ? false : true}
            IsInvalid={
              this.props.isProvinceOptional == false
                ? false
                : this.props.vModel.ProvinceId
            }
            Name="NewCity"
            LabelText="City"
            Value={model.NewCity}
            ValueChanged={this.props.handleChange}
          />
        ) : (
          <GenericSelectInput
            Name="CityId"
            LabelText="City"
            IsStatic
            StaticData={this.state.CityList}
            DataRoot="Item"
            KeyValueMember="Id"
            TextValueMember="Name"
            Value={model.CityId}
            ValueChanged={this.props.handleChange}
            Disabled={Disabled}
            Required={this.props.isCityOptional == false ? false : true}
            IsInvalid={
              this.props.isCityOptional == false
                ? false
                : this.props.vModel.CityId
            }
          />
        )}

        <GenericTextInput
          Disabled={Disabled}
          inputProps={this.lengthZipCode}
          Name="ZipCode"
          Id="postcode"
          LabelText={
            this.props.zipCodeLabelText == null
              ? "Zip Code"
              : this.props.zipCodeLabelText
          }
          Value={model == null ? "" : model.ZipCode || ""}
          ValueChanged={this.props.handleChange}
          Required={this.props.isZipCodeOptional == false ? false : true}
          IsInvalid={
            this.props.isZipCodeOptional == false
              ? false
              : this.props.vModel.ZipCode
          }
        />
      </>
    );
  };
  render() {
    const { Disabled, GridXs, GridCount, addressModel } = this.props;
    return (
      <>
        {this.state.alert}
        <LoadingComponent Show={this.state.isLoading} />

        {GridCount == 0 && (
          <>
            {this.RenderLeftSide(addressModel, Disabled)}
            {this.RenderRightSide(addressModel, Disabled)}
          </>
        )}
        {GridCount == 1 && (
          <GridItem xs={GridXs}>
            {this.RenderLeftSide(addressModel, Disabled)}
            {this.RenderRightSide(addressModel, Disabled)}
          </GridItem>
        )}
        {GridCount == 2 && (
          <>
            <GridItem xs={GridXs}>
              {this.RenderLeftSide(addressModel, Disabled)}
            </GridItem>
            <GridItem xs={GridXs}>
              {this.RenderRightSide(addressModel, Disabled)}
            </GridItem>
          </>
        )}
      </>
    );
  }
}

CanadaPostAddressComponent.propTypes = {
  classes: PropTypes.object,
};

export default withArtifex(CanadaPostAddressComponent, {});
