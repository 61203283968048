import { DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import kycManualDigitalVerificationStatusPanelStyle from "assets/jss/material-dashboard-pro-react/views/kycManualDigitalVerificationStatusPanelStyle.jsx";
import cx from "classnames";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericCardMedia, GenericDialog, GenericDialogActions, GenericFlipContainer, GenericLabel } from "views/Components/Generic";


const IdCardImage = require("assets/img/id-card.png");
const IdCardBackImage = require("assets/img/id-card-back.png");

const ProfileMale = require("assets/img/profile-male.png");
const ProfileFemale = require("assets/img/profile-female.png");
class KycManualDigitalVerificationStatusPanel extends React.PureComponent {
	constructor(props) {
		super(props);
		this.emptyObject = {};
		this.state = {
			frontLoaded: false,
			backLoaded: false
		};
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleImageOnClick = (name) => {
		const { base64Front, base64Back, base64Profile } = this.state;
		const { Gender } = this.props;
		var profileImage = Gender == "F" ? ProfileFemale : ProfileMale;
		var previewImages = [];
		if (name == "Front" || name == "Back") {
			previewImages.push(base64Front || IdCardImage);
			previewImages.push(base64Back || IdCardBackImage);
		}
		if (name == "Profile") {
			previewImages.push(base64Profile || profileImage);
		}

		this.setState({ previewImages, openDialog: true });
	}
	handleImageOnLoaded = (name, base64) => {

		var state = {};
		if (name == "Front") {
			state.base64Front = this.props.IdentityPhotoId;//base64;
			state.frontLoaded = true;

		}
		if (name == "Back") {
			state.base64Back = this.props.IdentityBackPhotoId;//base64;
			state.backLoaded = true;

		}
		if (name == "Profile") {
			state.base64Profile = this.props.ProfilePhotoId;//base64;
		}
		this.setState(state);
	}
	handleImageOnFetch = (id) => {
		this.setState({ backLoaded: false, frontLoaded: false });
	}

	handleDialogClose = () => {
		this.setState({ openDialog: false });
	}
	ImagePreviewComponent = () => {
		const { openDialog, previewImages } = this.state;
		const { classes, ExecuteApiPost } = this.props;

		return (<GenericDialog open={openDialog} onBackdropClick={this.handleDialogClose} fullScreen>
			<DialogTitle >
				<GenericLabel Text="Preview" FontSize="18px" Bold={true} />
			</DialogTitle>
			<DialogContent>
				<GridContainer justify="center" spacing={32} direction="row">

					{previewImages && previewImages.map((id, index, array) => {
						return (
							<GridItem xs={array.length == 1 ? 3 : 6}>
								<GenericCardMedia
									ExecuteApiPost={ExecuteApiPost}
									className={classes.mediaPreview}
									key={(id || index).toString().substr(-10)}
									ImageFileId={id}
									Image={id}
								/>
							</GridItem>
						);
					}
					)}

				</GridContainer>
			</DialogContent>
			<GenericDialogActions>
				<Button onClick={this.handleDialogClose}>Close</Button>
			</GenericDialogActions>
		</GenericDialog >);
	}

	GetCardFront = () => {
		const { classes, IdentityPhotoId, ExecuteApiPost, ScoreOnly } = this.props;
		const { frontLoaded } = this.state;
		var mediaCardFrontStyle = cx({
			[classes.mediaCard]: true,
			[classes.mediaCardScoreOnly]: ScoreOnly,
			[classes.mediaCardPlaceholder]: !frontLoaded
		});
		return (<GenericCardMedia
			Name="Front"
			Width={300}
			ExecuteApiPost={ExecuteApiPost}
			className={mediaCardFrontStyle}
			ImageFileId={IdentityPhotoId}
			ImagePlaceholder={IdCardImage}
			OnLoaded={this.handleImageOnLoaded}
			OnClick={this.handleImageOnClick}
			OnFetch={this.handleImageOnFetch}
		/>);
	}
	GetCardBack = () => {
		const { classes, IdentityBackPhotoId, ExecuteApiPost, ScoreOnly } = this.props;
		const { backLoaded } = this.state;
		var mediaCardBackStyle = cx({
			[classes.mediaCard]: true,
			[classes.mediaCardScoreOnly]: ScoreOnly,
			[classes.mediaCardPlaceholder]: !backLoaded
		});
		return (<GenericCardMedia
			Name="Back"
			Width={300}
			ExecuteApiPost={ExecuteApiPost}
			className={mediaCardBackStyle}
			ImageFileId={IdentityBackPhotoId}
			ImagePlaceholder={IdCardBackImage}
			OnLoaded={this.handleImageOnLoaded}
			OnClick={this.handleImageOnClick}
			OnFetch={this.handleImageOnFetch}
		/>);
	}
	render() {
		const { children, classes, Gender, ProfilePhotoId, ExecuteApiPost, Header, ShowOnlyCard, LabelMd } = this.props;
		var profileImage = Gender == "F" ? ProfileFemale : ProfileMale;
		return (
			<div className={classes.mainWrapper}>
				<this.ImagePreviewComponent />
				<GridContainer direction="column">
					<GridItem xs={12}>
						<GenericLabel FontSize={12} className={classes.profileHeader} Text={Header} />
					</GridItem>
					<GridItem xs={12}>
						<GridContainer direction="row" justify="flex-start">
							{ShowOnlyCard ?
								<GridItem xs={LabelMd}>
									<GridContainer>
										<GridItem xs={6}>
											<h6 className={classes.profileHeader}>Card Front Photo</h6>
											{this.GetCardFront()}
										</GridItem>
										<GridItem xs={6}>
											<h6 className={classes.profileHeader}>Card Back Photo</h6>
											{this.GetCardBack()}
										</GridItem>
									</GridContainer>
								</GridItem>
								:
								<>
									<GridItem xs={3} >
										<GenericFlipContainer
											Front={this.GetCardFront()}
											Back={this.GetCardBack()}
										>
											<h6 className={classes.cardHeader}>Card Photo</h6>
										</GenericFlipContainer>
									</GridItem>
									<GridItem xs={2}>
										<h6 className={classes.profileHeader}>Profile Photo</h6>
										<GenericCardMedia
											Name="Profile"
											Width={150}
											ExecuteApiPost={ExecuteApiPost}
											className={classes.mediaProfile}
											ImageFileId={ProfilePhotoId}
											ImagePlaceholder={profileImage}
											OnClick={this.handleImageOnClick}
											OnLoaded={this.handleImageOnLoaded}
										/>
									</GridItem>
								</>
							}
							<GridItem xs={12 - LabelMd}>
								{children}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}


KycManualDigitalVerificationStatusPanel.defaultProps = {
	LabelMd: 5
};
KycManualDigitalVerificationStatusPanel.propTypes = {
	ExecuteApiPost: PropTypes.any.isRequired,
	IdentityPhotoId: PropTypes.any,
	Header: PropTypes.node,
	IdentityBackPhotoId: PropTypes.any,
	ProfilePhotoId: PropTypes.any,
	children: PropTypes.any,
	Gender: PropTypes.oneOf(["M", "F"]),
	ShowOnlyCard: PropTypes.bool,
	ScoreOnly: PropTypes.bool,
	LabelMd: PropTypes.number,
};

export default withStyles(kycManualDigitalVerificationStatusPanelStyle)(KycManualDigitalVerificationStatusPanel);