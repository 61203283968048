import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { ApproveIcon, EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BankTransactionStatusParameterDesc, GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class UnSettleds extends React.Component {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.clientRowId = ClientHelper.GetClientRowId();

		this.initialModel = {
			ToDate: DateHelper.GetDate(),
			FromDate: DateHelper.GetDate(),
			UniqueClientId: this.isBackOffice ? undefined : this.clientRowId
		};

		this.state = {
			checkedValues: [],
			approvedList: [],
			selectList: [],
			deleteList: [],
			model: this.initialModel,
			countModel: {},
			isLoading: false,
			LimitToken: false
		};

		this.trxTable = React.createRef();

		this.sortParamDesc = { Member: "ParameterDesc" };
		this.trxSorted = [{ "id": "TransactionDate", "desc": true }];

		this.HandleClear = this.HandleClear.bind(this);
		this.HandleCheck = this.HandleCheck.bind(this);
		this.HandleChange = this.HandleChange.bind(this);
		this.HandleChangeItemCount = this.HandleChangeItemCount.bind(this);
		this.Validate = this.Validate.bind(this);

		this.HandleGetList = this.HandleGetList.bind(this);
		this.ErrorSendCallback = this.ErrorSendCallback.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle)
			setAppLeftTitle("Un-Settled & Un-Processed Transactions");
		if (setAppCenterTitle)
			setAppCenterTitle("EFT SYSTEM");

		this.RefreshToolBar();
		this.setState({ loadingCompleted: true });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear() {
		this.setState({ model: this.initialModel });
	}

	HandleCheck(trans) {
		var selectList = [...this.state.selectList];
		var deleteList = [...this.state.deleteList];

		if (trans.TransactionStatus == BankTransactionStatusParameterDesc.Unapproved)
			selectList = this.state.selectList.includes(trans.TransactionEFTId)
				? this.state.selectList.filter(t => t !== trans.TransactionEFTId)
				: [...this.state.selectList, trans.TransactionEFTId];

		deleteList = this.state.deleteList.includes(trans.TransactionEFTId)
			? this.state.deleteList.filter(t => t !== trans.TransactionEFTId)
			: [...this.state.deleteList, trans.TransactionEFTId];
		this.setState(state => ({
			selectList, deleteList,
			checkedValues: state.checkedValues.includes(trans)
				? state.checkedValues.filter(c => c !== trans)
				: [...state.checkedValues, trans]
		}));
	}

	HandleChange(name, value, data) {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "MainSearchItemId")
			model.MainSearchItem = data;
		this.setState({ model });
	}

	HandleChangeItemCount(name, value) {
		const countModel = { ...this.state.countModel };
		countModel[name] = value;
		this.setState({ countModel });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	HandleGetList() {
		this.setState(state => ({ selectList: [], checkedValues: [], deleteList: [], trxGridToken: !state.trxGridToken }));
	}

	handleApproved = (trans) => {
		var approvedList = [...this.state.approvedList];
		approvedList = [];
		approvedList.push(trans);
		this.setState({
			approvedList,
			isLoading: false,
			alert: AlertHelper.CreateQuestionAlert("Approved", "Approve transaction ?", "warning", () => this.handleApprovedOK(approvedList), this.hideAlert)
		});
	}

	handleSubmit = () => {
		if (this.state.selectList.length > 0)
			this.setState({
				isLoading: false,
				alert: AlertHelper.CreateQuestionAlert("Approved", "Approve selected [" + this.state.selectList.length + "] Unapproved transactions ?", "warning", () => this.handleApprovedOK(this.state.selectList), this.hideAlert)
			});
		else {
			this.ShowErrorMessage("You did not select any unapproved transaction!");
		}
	}

	handleApprovedOK = (IdList) => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/ApprovedUnsettledAndUnProcessedTransctionById",
			{ TransactionEFTIds: IdList },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowErrorMessage("Error =>" + responseData.ErrorDescription);
					return;
				}
				else {
					this.ShowMessage("success", "Approved", "Transactions approved operation completed successfully.");
				}
			},
			this.ErrorSendCallback
		);
	}

	handleDelete = () => {
		if (this.state.deleteList.length > 0) {
			this.setState({
				isLoading: false,
				alert: AlertHelper.CreateQuestionAlert("Warning", "Are  you sure  you want to  delete  and cancel  the selected  transactions?", "warning", this.handleDeleteOK, this.hideAlert)
			});
		}
		else {
			this.ShowErrorMessage(" Deleted List must not be null !! ");
		}
	}

	handleDeleteOK = () => {
		this.showLoading();

		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/DeleteUnsettledAndUnProcessedTransactionById",
			{ TransactionEFTIds: this.state.deleteList },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowErrorMessage("Error =>" + responseData.ErrorDescription);
					return;
				}
				else {
					this.ShowMessage("success", "Delete", "Transactions delete operation completed successfully.");
				}
			},
			this.ErrorSendCallback
		);
	}

	ErrorSendCallback(error) {
		this.ShowErrorMessage("An error occurred while sending data =>" + error.message);
	}

	ShowMessage(type, title, message) {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert)
		});
	}

	ShowErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert("Warning", message, "warning", this.hideAlert)
		});
	}

	Validate() {
		const { model } = this.state;

		return true;
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "EFT Un-Settled & Un-Processed Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/BankBmoEFT/SearchUnSettledUnProcessEFTTransactionsExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	showLoading = () => {
		this.setState({ isLoading: true, alert: null });
	}

	hideLoading = () => {
		this.setState({ isLoading: false });
	}

	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model } = this.state;

		return (
			<div>
				{!this.isBackOffice && <AccountLimitToolbar Program={ProgramCodes.Bank_Programs_EFT} />}
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled || model === this.initialModel },
					{ Code: "Delete", OnClick: this.handleDelete, Disabled: Disabled || model === this.initialModel },
					{ Code: "Update", OnClick: this.HandleClear, Disabled: true || model === this.initialModel },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.HandleChange}
													CanClear
													Disabled={!this.isBackOffice}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="EFTTypeId"
													LabelText="EFT Type"
													Value={model.EFTTypeId}
													ParameterCode="EFTType"
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionTypeId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="TransactionCodeId"
													LabelText="EFT Transaction Code"
													ParameterCode="EFTTransactionType"
													Value={model.TransactionCodeId}
													ValueChanged={this.HandleChange}
													Disabled={Disabled} Sorted={this.sortParamDesc}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionStatusId"
													LabelText="Transaction Status"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "BankTransactionStatus",
														ParameterValue3: "EFT"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionStatusId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													Name="PayeeName"
													LabelText="Customer Name"
													Value={model.PayeeName}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericNumberInput
													NoFormatting={true}
													Name="AccountNumber"
													LabelText="Customer Wallet"
													Value={model.PayeeAccount}
													ValueChanged={this.HandleChange}
													MaxLength={12}
												/>
											</GridItem>
											<GridItem>
												<GenericNumberInput
													Name="TransactionAmount"
													LabelText="Amount"
													Value={model.TransactionAmount}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionSourceId"
													LabelText="Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "TransactionSource"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionSourceId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericTextInput Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.HandleChange} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericNumberInput
													NoFormatting={true}
													Name="TransactionId"
													LabelText="Transaction ID"
													Value={model.TransactionId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="StoreId"
													LabelText="Store"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "CustomerStore"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.StoreId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Value={model.FromDate ? DateHelper.ToDateInputValueAsUtc(model.FromDate) : ""}
													ValueChanged={this.HandleChange}
													MaxDate={model.ToDate || DateHelper.GetDate()}
													Utc
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate ? DateHelper.ToDateInputValueAsUtc(model.ToDate) : ""}
													ValueChanged={this.HandleChange}
													MinDate={model.FromDate || DateHelper.GetDate()}
													Utc
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>

											</GridItem>
											<GridItem xs={4} />
											<GridItem xs={4}>
												<GridContainer justify="flex-end">
													<GridItem xs={3}>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ref={this.trxTable}
													PageSize={10}
													ShowPagination={true}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div>
																	<GenericCheckInput
																		IsWithButton
																		Grid
																		Value={this.state.checkedValues.filter(c => c.TransactionId == row.original.TransactionId).length > 0}
																		ValueChanged={() => { this.HandleCheck(row.original); }}
																		Disabled={!row.original.IsEditable} />

																	<GridButton
																		tooltip="Approve"
																		Disabled={!row.original.IsEditable || row.original.TransactionStatus != BankTransactionStatusParameterDesc.Unapproved}
																		Icon={ApproveIcon}
																		OnClick={() => { this.handleApproved(row.original.TransactionEFTId); }} />

																	<GridButton
																		tooltip="Edit"
																		Disabled={!row.original.IsEditable}
																		Icon={EditIcon}
																		OnClick={() => { RouteHelper.Push(this.props.history, "/CreateEFT", "CreateEFT_Id", row.original.TransactionId); }} />
																</div>
															),
															sortable: false,
															filterable: false,
															width: 110
														},
														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "Unique Client Id",
															accessor: "ClientNumber",
															show: this.isBackOffice,
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Client Name",
															accessor: "ClientName",
															show: this.isBackOffice,
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Customer Name",
															accessor: "Name",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transaction Type",
															accessor: "TransactionType",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Financial Institution",
															accessor: "FinancialInstitution.Description",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Financial Institution Branch",
															accessor: "FinancialInstitutionBranch.Description",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "EFT Transaction Code",
															accessor: "TransactionCodeName",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Customer Wallet Number",
															accessor: "AccountNumber",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Amount",
															accessor: "TransactionAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "FeeAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "EFT Type",
															accessor: "EFTTransactionType",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transit Number",
															accessor: "FinancialInstitutionBranch.TransitNumber",
															Cell: row => Formatter.PadLeft(row.value, 5, "0")
														},
														{
															Header: "Transaction Status",
															accessor: "TransactionStatus",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "File Name",
															accessor: "FileName",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Client User",
															accessor: "InsertUser",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transaction Reference Number",
															accessor: "TransactionReferenceNumber",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Store",
															accessor: "CustomerStore",
															width: 150
														}
													]}
													Sorted={this.trxSorted}
													ProgramCode={ProgramCodes.Bank_Programs_EFT}
													ServerSide
													LoadAtStartup={this.state.loadingCompleted}
													ValidateRequest={this.Validate}
													PrepareRequest={() => { return this.state.model; }}
													RequestUrl="/bankapi/v1.0/BankBmoEFT/SearchUnSettledUnProcessEFTTransactionsPaged"
													RequestMethod="POST"
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

UnSettleds.propTypes = {
	classes: PropTypes.object
};

export default UnSettleds;
