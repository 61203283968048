import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import ToolBar from "views/Components/DynamicToolbar";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericRadioInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, Portals, ProgramCodes } from "views/Constants/Constant";

class DdtAccountActivities extends Component {
	constructor(props) {
		super(props);

		this.isBackOff = ClientHelper.IsBackOffice();
		this.clientId = ClientHelper.GetClientRowId();
		this.isSalesPerson = ClientHelper.IsSalesPerson();
		this.cId = ClientHelper.GetClientId();
		this.IsClient = ClientHelper.IsClient();
		this.trxTable = React.createRef();
		this.channelNameParemeter = {
			ParameterCode: "ChannelNameType",
			"ParameterValue2": "DDT"
		};

		this.columns = [
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId",
				show: this.isBackOff || this.isSalesPerson
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: this.isBackOff || this.isSalesPerson
			},
			{
				Header: "Transaction Date",
				accessor: "Date",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Channel Name",
				accessor: "ChannelName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Email",
				accessor: "Email"
			},
			{
				Header: "Sender",
				accessor: "Sender"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "DDT Number",
				accessor: "DdtNumber"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Interac Ref. Number",
				accessor: "InteracReferenceNumber"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Source",
				accessor: "Source"
			}
		];

		this.initialModel = {
			FromDate: DateHelper.GetLocalDateAsUtc(),
			ToDate: DateHelper.GetLocalDateAsUtc(),
			ClientId: this.isBackOff ? undefined : this.clientId
		};

		this.state = {
			model: this.initialModel,
			toolBarModel: {},
			isLoading: false,
			poolModel: {}
		};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) setAppLeftTitle("DDT Wallets Activities");
		if (setAppCenterTitle) setAppCenterTitle("DDT MANAGEMENT");
		this.props.setPortal(Portals.DDT);
		this.GetToolbarData();

		this.setState({ loadingCompleted: true });
	}

	HandleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		var { model } = this.state;
		var ClientId = model.ClientId == null ? null : model.ClientId;
		model = this.initialModel;
		model.ClientId = ClientId;

		this.setState({ model, trxGridToken: !this.state.trxGridToken });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetToolbarData = async () => {
		const { ExecuteApiGet } = this.props;
		var { model, poolModel, toolBarModel } = this.state;
		var responseData = await ExecuteApiGet("/bankapi/v1.0/Ddt/GetAllDDTCDICNumberAndBalance");

		poolModel.FinInsId = responseData == null || undefined ? "" : responseData.CDICPoolAccountInfo == null || undefined ? "" : responseData.CDICPoolAccountInfo.FinInsId == null || undefined ? "" : responseData.CDICPoolAccountInfo.FinInsId;
		model.PrimaryBranchId = responseData == null || undefined ? "" : responseData.DDTPoolAccountInfo == null || undefined ? "" : responseData.DDTPoolAccountInfo.BranchId == null || undefined ? "" : responseData.DDTPoolAccountInfo.BranchId;
		model.PrimaryAccountNumber = responseData == null || undefined ? "" : responseData.DDTPoolAccountInfo == null || undefined ? "" : responseData.DDTPoolAccountInfo.AccountNumber == null || undefined ? "" : responseData.DDTPoolAccountInfo.AccountNumber;

		toolBarModel.TotalDDTNumber = responseData == null || undefined ? 0 : responseData.TotalDDTNumber == null || undefined ? 0 : responseData.TotalDDTNumber;
		toolBarModel.TotalDDTBalance = responseData == null || undefined ? "" : responseData.DDTPoolAccountInfo == null || undefined ? "" : responseData.DDTPoolAccountInfo.Balance == null || undefined ? "" : responseData.DDTPoolAccountInfo.Balance;
		toolBarModel.TotalCDICNumber = responseData == null || undefined ? 0 : responseData.TotalCDICNumber == null || undefined ? 0 : responseData.TotalCDICNumber;
		toolBarModel.TotalCDICBalance = responseData == null || undefined ? "" : responseData.CDICPoolAccountInfo == null || undefined ? "" : responseData.CDICPoolAccountInfo.Balance == null || undefined ? "" : responseData.CDICPoolAccountInfo.Balance;
		this.setState({ model, toolBarModel, poolModel, isLoading: false });
	}

	GetData = () => { this.setState(state => ({ trxGridToken: !state.trxGridToken })); }

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		temp.jsonFile = {
			title: "Ddt Wallet Activities",
			format: this.state.ExportReportType,
			colmns: jsonColList,
			model: {
				DateFilter: { BeginDate: this.state.model.FromDate, EndDate: this.state.model.ToDate }
			}
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/Ddt/SearchDdtAccountActivitiesExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.ShowMessage
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}

	render() {
		const { Disabled, classes } = this.props;
		const { model, poolModel, toolBarModel, isLoading } = this.state;

		return (
			<div>

				{this.state.alert}

				<LoadingComponent Show={isLoading} />

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged} />
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select" />
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				{!this.isBackOff && !this.isSalesPerson &&
					<ToolBar
						IsNumber
						ItemList={[
							{ Label: "Total No. of DDT", Value: toolBarModel.TotalDDTNumber + "" },
							{ Label: "Total No. of DDT - CDIC", Value: toolBarModel.TotalCDICNumber + "" },
							{ Label: "DDT Wallet Balance", Value: toolBarModel.TotalDDTBalance },
							{ Label: "DDT - CDIC Wallet Balance", Value: toolBarModel.TotalCDICBalance }
						]} />
				}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters">
									<GridContainer>
										<GridItem xs={4} >
											<GenericSelectInput
												Name="ClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: this.IsClient ? this.cId : undefined }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.ClientId || ""}
												ValueChanged={this.HandleChange}
												CanClear
												All
												Disabled={!this.isBackOff} />
											{!this.state.isBackOff &&
												<GridContainer  >
													<GridItem className={classes.GridItemFreePadding} xs={6}>
														<GenericSelectInput
															Name="FinInsId"
															LabelText="DDT Main Acc."
															LabelMd={8}
															Method="GET"
															Url="/bankapi/v1.0/FinancialInstitution/GetAll"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="FinancialInstitutionId"
															Value={poolModel.FinInsId == null || poolModel.FinInsId == "" ? 46 : poolModel.FinInsId}
															Disabled={true} />
													</GridItem>
													<GridItem className={classes.GridItemFreePadding} xs={3}>
														<GenericSelectInput
															Name="PrimaryBranchId"
															LabelMd={0}
															TitleLabel="Branch No."
															Method="GET"
															Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="TransitNo"
															Value={model.PrimaryBranchId == null ? "" : model.PrimaryBranchId}
															ValueChanged={this.HandleChange}
															Disabled={!this.isBackOff} />
													</GridItem>
													<GridItem className={classes.GridItemFreePadding} xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															Name="PrimaryAccountNumber"
															LabelMd={0}
															Value={model.PrimaryAccountNumber == null || undefined ? "" : model.PrimaryAccountNumber}
															ValueChanged={this.HandleChange}
															MaxLength={12}
															Disabled={true} />
													</GridItem>
												</GridContainer>
											}
											<GenericNumberInput
												NoFormatting={true}
												Name="CustomerNumber"
												LabelText="Customer Number"
												Value={model.CustomerNumber == null || undefined ? "" : model.CustomerNumber}
												ValueChanged={this.HandleChange}
											/>
											<GridContainer>
												<GridItem className={classes.GridItemFreePadding} xs={6}>
													<GenericSelectInput
														Name="FinInsId"
														LabelMd={8}
														LabelText="DDT Number"
														Method="GET"
														Url="/bankapi/v1.0/FinancialInstitution/GetAll"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="FinancialInstitutionId"
														Value={poolModel.FinInsId == null || poolModel.FinInsId == "" ? 46 : poolModel.FinInsId}
														ValueChanged={this.HandleChange}
														Disabled={true} />
												</GridItem>
												<GridItem className={classes.GridItemFreePadding} xs={3}>
													<GenericSelectInput
														Name="FinInsBranchId"
														LabelMd={0}
														TitleLabel="Branch No."
														Method="GET"
														Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="TransitNo"
														Value={model.FinInsBranchId == null ? "" : model.FinInsBranchId}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem className={classes.GridItemFreePadding} xs={3}>
													<GenericNumberInput
														NoFormatting={true}
														Name="AccNumber"
														LabelMd={0}
														Value={model.AccNumber == null || undefined ? "" : model.AccNumber}
														ValueChanged={this.HandleChange}
														MaxLength={12}
														Disabled={model.FinInsBranchId == null} />
												</GridItem>
											</GridContainer>


										</GridItem>
										<GridItem xs={4} >

											<GenericSelectInput
												Name="TransactionSourceId"
												LabelText="Source"
												Value={model.TransactionSourceId == null || undefined ? "" : model.TransactionSourceId}
												DataRoot="Item"
												ValueChanged={this.HandleChange}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{
													ParameterCode: "TransactionSource",
													"ParameterValue": "",
													"ParameterValue3": "",
													"ParameterDesc": ""
												}} />

											<GenericSelectInput
												Name="ChannelNameTypeId"
												LabelText="Channel Name"
												Value={model.ChannelNameTypeId || ""}
												ValueChanged={this.HandleChange}
												KeyValueMember="Id"
												TextValueMember="ParameterValue"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.channelNameParemeter}
												DataRoot="Item"
												Sorted={{ Member: "ParameterDesc" }} />

											<GenericNumberInput
												NoFormatting={true}
												Name="ReferenceNumber"
												Value={model.ReferenceNumber == null || undefined ? "" : model.ReferenceNumber}
												ValueChanged={this.HandleChange}
												MaxLength={18}
												LabelText={"Reference Number"} />

											<GenericTextInput
												NoFormatting={true}
												Name="InteracReferenceNumber"
												Value={model.InteracReferenceNumber == null || undefined ? "" : model.InteracReferenceNumber}
												ValueChanged={this.HandleChange}
												MaxLength={12}
												LabelText={"Interac Reference Number"} />
										</GridItem>
										<GridItem xs={4} >
											<GenericDateInput
												Name="FromDate"
												LabelText="Start Date"
												Value={model == null || undefined ? "" : model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : ""}
												ValueChanged={this.HandleChange}
												IncludeTime={false}
												MaxDate={model.ToDate == null ? DateHelper.GetDateLocal() : model.ToDate}
												Utc />
											<GenericDateInput
												Name="ToDate"
												LabelText="End Date"
												Value={model == null || undefined ? "" : model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : ""}
												ValueChanged={this.HandleChange}
												IncludeTime={false}
												MinDate={model.FromDate}
												MaxDate={DateHelper.GetLocalDateAsUtc()}
												Utc />
											<GenericNumberInput
												ThousandSeparator=","
												Name="Amount"
												LabelText="Amount"
												Value={model.Amount == null || undefined ? "" : model.Amount}
												ValueChanged={this.HandleChange} />

										</GridItem>
									</GridContainer><br />
								</GenericExpansionPanel >
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Transaction List"} />
							</CardHeader>
							<CardBody>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									ShowPagination={true}
									PageSize={10}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									PrepareRequest={() => { return this.state.model; }}
									RequestUrl="/bankapi/v1.0/Ddt/SearchDdtAccountActivitiesPaged"
									RequestMethod="POST"
									Columns={this.columns}
									ProgramCode={ProgramCodes.Bank_Programs_DDT} />
								<br /> <br />
							</CardBody>
						</Card>
						<br />
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

DdtAccountActivities.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	setPortal: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(DdtAccountActivities, {});