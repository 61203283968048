import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class ClientPartnerDdtList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ClientPartnerDdtListModel: {},
			isLoading: false,
			alert: null
		};

		this.ColumnsData = [
			{
				Header: "Action",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({
								ClientPartnerDdtListModel: {
									...row.original
								}
							})} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			}, {
				Header: "Partner Name",
				accessor: "PartnerName"
			}, {
				Header: "DDT Number",
				accessor: "DdtNumber"
			}, {
				Header: "DDT Owner Name",
				accessor: "DdtOwnerName"
			}, {
				Header: "DDT Balance",
				accessor: "DdtBalance"
			}, {
				Header: "Total Paid Amount",
				accessor: "TotalPaidAmount"
			}, {
				Header: "Remaining No. of Payment",
				accessor: "RemainingNoOfPayment"
			}, {
				Header: "Total Remaining Loan Amount",
				accessor: "TotalRemainingLoanAmount"
			}, {
				Header: "Delay Status",
				accessor: "DelayStatus"
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");

		var model = {
			Id: 0
		};
		this.setState({ ClientPartnerDdtListModel: model, ClientPartnerAccountDefinitionAndDetailsList: this.props.ClientPartnerAccountDefinitionAndDetailsList });
	}

	OperationCheck = () => {

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	UpdateModel = () => { }

	DeleteModel = (model) => { }
	SelectedRowChange = (e, index) => { }
	ClearData = () => { }

	handleChange = (name, newValue, data) => { }
	FilterIdentityNumber = (number) => { }
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert
				Title="Delete"
				Message={message}
				Type="warning"
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	render() {
		const { Disabled: DisabledProp, ClientPartnerAccountDefinitionAndDetailsList } = this.props;
		const { ClientPartnerDdtListModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<div>
				{this.state.alert}


				<LoadingComponent Show={this.state.isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Partner DDT">
							<GridContainer>
								<GridContainer >
									<GridItem xs={12} sm={6} md={3}>
										<GenericSelectInput
											Name="FinInsId"
											LabelText="DDT Number"
											LabelMd={5}
											Method="GET"
											Url="/bankapi/v1.0/FinancialInstitution/GetAll"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="FinancialInstitutionId"
											Value={ClientPartnerDdtListModel.FinancialInstitutionId}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<GenericSelectInput
											Name="FinInsBranchId"
											key={ClientPartnerDdtListModel.FinInsId}
											LabelMd={0}
											Method="GET"
											Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="TransitNumber"
											Value={ClientPartnerDdtListModel.FinInsBranchId == null
												? ""
												: ClientPartnerDdtListModel.FinInsBranchId}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<GenericNumberInput
											NoFormatting={true}
											LabelMd={0}
											Name="AccountNumber"
											Value={ClientPartnerDdtListModel.AccNumber == null || undefined
												? ""
												: ClientPartnerDdtListModel.AccNumber}
											Disabled={true} />
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={6} md={3}>
										<GenericSelectInput
											Name="FinInsBranchId"
											key={ClientPartnerDdtListModel.DdtOwnerName}
											Method="GET"
											LabelText="DDT Owner Name"
											LabelMd={5}
											Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="TransitNumber"
											Value={ClientPartnerDdtListModel.FinInsBranchId == null || undefined
												? ""
												: ClientPartnerDdtListModel.FinInsBranchId}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<GenericSelectInput
											Name="FinInsBranchId"
											key={ClientPartnerDdtListModel.DdtOwnerName}
											Method="GET"
											LabelText="Payment Channel"
											LabelMd={5}
											Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="TransitNumber"
											Value={ClientPartnerDdtListModel.FinInsBranchId == null || undefined
												? ""
												: ClientPartnerDdtListModel.FinInsBranchId}
											Disabled={true} />
									</GridItem>
								</GridContainer>
								<GridContainer></GridContainer>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button
											disabled={Disabled}
											size="sm"
											color="black"
											onClick={() => this.OperationCheck()}>{ClientPartnerDdtListModel.Id > 0
												? "Update"
												: "Add"}</Button>

										<Button
											disabled={Disabled}
											size="sm"
											color="black"
											onClick={() => this.ClearData()}>
											Clear
                                        </Button>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Partner DDT List">
							<GridItem md={12} xs={12}>
								<GridItem>
									<GenericGrid
										Data={ClientPartnerAccountDefinitionAndDetailsList}
										PageSize={5}
										HideButton={true}
										Columns={this.ColumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}} />
								</GridItem>
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
			</div>
		);
	}
}

ClientPartnerDdtList.propTypes = {
	classes: PropTypes.object,
	ClientPartnerDdtListModel: PropTypes.object,
	ClientPartnerModel: PropTypes.object,
	ClientPartnerAccountDefinitionAndDetailsList: PropTypes.array,
	GetClientPartnerAccountDefinitionAndDetailsModels: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	SetFile: PropTypes.func
};

export default withArtifex(ClientPartnerDdtList, {});
