import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import {
  DeleteIcon,
  EditIcon,
  SubmitOrSendIcon,
  SuspiciousIcon,
  TraceIcon,
  UploadIcon,
} from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericDateInput,
  GenericDialog,
  GenericDialogActions,
  GenericExpansionPanel,
  GenericGrid,
  GenericLabel,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericRadioInput,
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import {
  ActionCodes,
  ApprovalStatus,
  BankTransactionStatus,
  Direction,
  EtransferApplicationTypes,
  ETransferMoneyRequestStatus,
  ETransferMoneySendStatus,
  GridColumnType,
  MenuCodes,
  ProgramCodes,
} from "views/Constants/Constant.js";

class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.isClient = ClientHelper.IsClient();
    this.clientRowId = ClientHelper.GetClientRowId();
    this.clientId = ClientHelper.GetClientId();
    this.isBackOffice = ClientHelper.IsBackOffice();
    this.isSalesPerson = ClientHelper.IsSalesPerson();

    this.isCallCenter =
      props.MenuCode === MenuCodes.CallCenterEtransferTransactions;

    this.initialModel = {
      UniqueClientId:
        this.isBackOffice || this.isSalesPerson ? undefined : this.clientRowId,
      MainSearchItemId: -1,
      SearchEndDate: DateHelper.GetDate(),
      SearchStartDate: DateHelper.GetDate(),
      RefNo: undefined,
      InteracRefNo: undefined,
      ResponseReferenceNumber: undefined,
      PayeeEmail: undefined,
      TransactionId: undefined,
      jsonFile: undefined,
      searchIsNotSpecific: true,
      SettlementStatusId: undefined,
      SettlementDate: undefined,
    };

    this.state = {
      model: this.initialModel,
      countModel: {},
      isLoading: false,
      mainSearchItem: this.props.MainSearchItem,
      LimitToken: false,
      suspiciousDialog: false,
      vIndividualModel: {},
      gridButtonRightDetail: [],
    };

    this.trxTable = React.createRef();

    this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

    this.sortedByDate = [{ id: "TransactionDate", desc: true }];
    this.sortedByFileDate = [{ id: "FileDate", desc: true }];

    this.renderItemStoreName = {};
  }

  componentDidMount() {
    const { setAppLeftTitle, setAppCenterTitle, MenuCode } = this.props;

    if (
      MenuCode == MenuCodes.Backoffice_SalesPersonETransferTransactionSearch
    ) {
      if (setAppLeftTitle) setAppLeftTitle("Transaction Search");
      if (setAppCenterTitle) setAppCenterTitle("SALES PERSON");
    } else if (MenuCode == MenuCodes.CallCenterEtransferTransactions) {
      if (setAppLeftTitle) setAppLeftTitle("e-Transfer Transaction Search");
      if (setAppCenterTitle) setAppCenterTitle("CALL CENTER");
      this.GetGridButtonsRight();
    } else {
      if (setAppLeftTitle) setAppLeftTitle("e-Transfer Transactions");
      if (setAppCenterTitle) setAppCenterTitle("INTERAC E-TRANSFER");
    }

    var data = RouteHelper.ReadAndClear("eTransferTransactionSearch_Date");

    if (data) {
      const { model } = this.state;
      model.SearchStartDate =
        typeof data.FromDate === "string"
          ? new Date(data.FromDate)
          : data.FromDate;
      model.SearchEndDate =
        typeof data.ToDate === "string" ? new Date(data.ToDate) : data.ToDate;
      model.IsSettled = data.IsSettled;
      this.setState({ model });
      this.HandleGetList();
    }

    this.RefreshToolBar();
    this.setState({ loadingCompleted: true });
  }

  RefreshToolBar = () => {
    this.setState({ LimitToken: !this.state.LimitToken });
  };

  HandleClear = () => {
    this.setState({ model: this.initialModel });
  };

  HandleChange = (name, value, data) => {
    const model = { ...this.state.model };
    model[name] = value;
    var searchIsNotSpecific =
      (model.RefNo == undefined || model.RefNo == null || model.RefNo == "") &&
      (model.InteracRefNo == undefined ||
        model.InteracRefNo == null ||
        model.InteracRefNo == "") &&
      (model.ResponseReferenceNumber == undefined ||
        model.ResponseReferenceNumber == null ||
        model.ResponseReferenceNumber == "") &&
      (model.PayeeEmail == undefined ||
        model.PayeeEmail == null ||
        model.PayeeEmail == "") &&
      (model.TransactionId == undefined ||
        model.TransactionId == null ||
        model.TransactionId == 0);

    model["searchIsNotSpecific"] = searchIsNotSpecific;

    if (name == "MainSearchItemId" && data)
      this.setState({ mainSearchItem: data.ParameterValue });

    if (name == "MainSearchItemId" && value == null) {
      model[name] = -1;
    }
    if (name == "PriorityTypeId" && value == null) {
      model[name] = -1;
    }
    if (name == "TransactionSourceId" && value == null) {
      model[name] = -1;
    }
    if (name == "TransactionTypeId" && value == null) {
      model[name] = -1;
    }
    if (name == "StoreId" && value == null) {
      model[name] = -1;
    }
    this.setState({ model });
  };

  ValueChanged = (name, value) => {
    this.setState({ [name]: value });
  };

  HandleGetList = () => {
    this.setState((state) => ({ trxGridToken: !state.trxGridToken }));
  };

  ExportClick = () => {
    var trxColumns = [];
    this.trxTable.current.props.Columns.forEach((c) => {
      if (
        c.accessor != "Actions" &&
        c.accessor != "ED" &&
        (c.show == undefined || c.show)
      ) {
        trxColumns.push({
          value: c.accessor,
          title: c.Header,
          columntype: c.ColumnType,
          type: c.type,
        });
      }
    }, this);

    this.setState({
      isDownloadDialogOpen: true,
      GridKeyValueList: trxColumns,
      ExportSelectedColumns: trxColumns,
    });
  };

  ExcelDownload = () => {
    if (!this.state.ExportReportType) {
      this.ShowMessage(
        "warning",
        "Export Report Type not selected",
        "Select Export Report Type to continue"
      );
      return;
    }

    if (
      this.state.ExportReportType == "PDF" &&
      this.state.ExportSelectedColumns.length > 15
    ) {
      this.ShowMessage(
        "warning",
        "PDF document cannot exceed 15 columns",
        "PDF document cannot exceed 15 columns"
      );
      return;
    }

    this.setState({ isDownloadDialogOpen: false });

    if (!this.Validate()) return;

    const temp = { ...this.state.model };

    var jsonColList = [];

    var trxColumns = {};
    this.state.ExportSelectedColumns.map((c) => {
      trxColumns[c.value] = {
        value: c.value,
        title: c.title,
        columntype: c.columntype,
        type: c.type,
      };
    });
    jsonColList.push(trxColumns);

    var mytitle = "Etransfer Transactions";

    temp.jsonFile = {
      colmns: jsonColList,
      format: this.state.ExportReportType,
      title: mytitle,
    };

    this.setState({ isLoading: true });
    Proxy.DownloadGeneratedFile(
      "/bankapi/v1.0/BankInteracETransfer/SearchETransferTransactionsExcelDownload",
      temp,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
      },
      (errorMessage) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", errorMessage);
      },
      this.props.showMessage
    );
  };

  HandleSend = (model) => {
    this.ShowConfirmMessage(
      "warning",
      "Workflow Confirmation",
      "Are you sure to send it for workflow confirmation ?",
      () => {
        var jsonData = JSON.stringify(model.Id);

        var requestData = {
          MenuCode: MenuCodes.Etransfer_SubmitApprove,
          ActionCode: ActionCodes.Submit,
          JsonData: jsonData,
          RowId: model.Id,
        };

        this.setState({ alert: "", isLoading: true });
        Proxy.POST(
          "/coreapi/v1.0/WorkflowData/InsertWorkflowData",
          requestData,
          (responseData) => {
            this.setState({ isLoading: false });

            if (!responseData.IsSucceeded) {
              this.ShowMessage("error", "Error", responseData.ErrorDescription);
              return;
            }
            this.ShowMessage(
              "success",
              "Success",
              "Request successfully sent for approval"
            );
          },
          (errorMessage) => {
            this.setState({ isLoading: false });
            this.ShowMessage(
              "error",
              "Error",
              "An error occurred while sending approve request " + errorMessage
            );
          }
        );
      }
    );
  };

  HandleTrace = (model) => {
    this.ShowConfirmMessage(
      "warning",
      "Workflow Confirmation",
      "Are you sure to send it for workflow confirmation ?",
      () => {
        var jsonData = JSON.stringify(model.Id);

        var requestData = {
          MenuCode: MenuCodes.Etransfer_TraceApprove,
          ActionCode: ActionCodes.Submit,
          JsonData: jsonData,
          RowId: model.Id,
        };

        this.setState({ alert: "", isLoading: true });
        Proxy.POST(
          "/coreapi/v1.0/WorkflowData/InsertWorkflowData",
          requestData,
          (responseData) => {
            this.setState({ isLoading: false });

            if (!responseData.IsSucceeded) {
              this.ShowMessage("error", "Error", responseData.ErrorDescription);
              return;
            }
            this.ShowMessage(
              "success",
              "Success",
              "Request successfully sent for approval"
            );
          },
          (errorMessage) => {
            this.setState({ isLoading: false });
            this.ShowMessage(
              "error",
              "Error",
              "An error occurred while sending approve request " + errorMessage
            );
          }
        );
      }
    );
  };

  HandleSuspicious = (model) => {
    this.setState({ sdTransactionId: model.Id, suspiciousDialog: true });
  };

  HandleSuspiciousConfirm = () => {
    const { sdCancelMemo, sdCancelReasonId, sdTransactionId } = this.state;

    this.ShowConfirmMessage(
      "warning",
      "Workflow Confirmation",
      "Are you sure to send it for workflow confirmation ?",
      () => {
        var data = {
          TransactionId: sdTransactionId,
          CancelReasonId: sdCancelReasonId,
          CancelMemo: sdCancelMemo,
        };

        var jsonData = JSON.stringify(data);

        var requestData = {
          MenuCode: MenuCodes.Etransfer_SuspiciousApprove,
          ActionCode: ActionCodes.Submit,
          JsonData: jsonData,
          RowId: data.TransactionId,
        };

        this.setState({ alert: "", isLoading: true });
        Proxy.POST(
          "/coreapi/v1.0/WorkflowData/InsertWorkflowData",
          requestData,
          (responseData) => {
            this.setState({ isLoading: false });

            if (!responseData.IsSucceeded) {
              this.ShowMessage("error", "Error", responseData.ErrorDescription);
              return;
            }

            this.setState({
              suspiciousDialog: false,
              sdCancelReasonId: "",
              sdCancelMemo: "",
            });
            this.ShowMessage(
              "success",
              "Success",
              "Request successfully sent for approval"
            );
          },
          (errorMessage) => {
            this.setState({ isLoading: false });
            this.ShowMessage(
              "error",
              "Error",
              "An error occurred while sending approve request " + errorMessage
            );
          }
        );
      }
    );
  };

  HandleCancel = (model) => {
    this.ShowConfirmMessage(
      "warning",
      "Cancel Confirmation",
      "Are you sure to cancel this transaction ?",
      () => {
        this.setState({ isLoading: true });
        Proxy.POST(
          "/bankapi/v1.0/BankInteracETransfer/CancelEtransferTransactionById",
          { TransactionId: model.Id, Reason: "Cancelled By Call Center" },
          (responseData) => {
            this.setState({ isLoading: false });

            if (!responseData.IsSucceeded) {
              this.ShowMessage("error", "Error", responseData.ErrorDescription);
              return;
            }

            this.ShowMessage(
              "success",
              "Success",
              "Request successfully sent for approval"
            );
          },
          (errorMessage) => {
            this.setState({ isLoading: false });
            this.ShowMessage(
              "error",
              "Error",
              "An error occurred while sending approve request " + errorMessage
            );
          }
        );
      }
    );
  };

  HandleRemind = (model) => {
    this.ShowConfirmMessage(
      "warning",
      "Remind Transfer",
      "Do you want to remind this transfer ?",
      () => {
        this.setState({ isLoading: true });
        Proxy.POST(
          "/bankapi/v1.0/BankInteracETransfer/RemindEtransferTransaction",
          {
            TransactionTypeId: model.TransactionTypeId,
            TransferReferenceNumber: model.ParticipantReferenceNumber,
          },
          (responseData) => {
            this.setState({ isLoading: false });

            if (!responseData.IsSucceeded) {
              this.ShowMessage("error", "Error", responseData.ErrorDescription);
              return;
            }

            this.ShowMessage(
              "success",
              "Success",
              "Transfer reminded successfully"
            );
          },
          (errorMessage) => {
            this.setState({ isLoading: false });
            this.ShowMessage("error", "Error", errorMessage);
          }
        );
      }
    );
  };

  GetGridButtonsRight = async () => {
    const { MenuCode } = this.props;
    var gridButtonRightDetail =
      await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, [
        "CALETSSEND",
        "CALETSEDIT",
        "CALETSCANC",
        "CALETSTRAC",
        "CALETSSUSP",
        "CALETSREMN",
      ]);

    this.setState({ gridButtonRightDetail });
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowConfirmMessage(type, title, message, onConfirm) {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={true}
          OnCancel={() => this.setState({ alert: "" })}
          OnConfirm={onConfirm}
        />
      ),
    });
  }

  Validate = () => {
    const { model } = this.state;

    var searchIsNotSpecific = model.searchIsNotSpecific;
    this.setState({ model });

    if (searchIsNotSpecific) {
      if (!model.UniqueClientId) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Select Client to continue"
        );
        return false;
      }

      if (
        typeof model.SearchStartDate != "object" &&
        model.SearchStartDate != "" &&
        model.SearchStartDate != null
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Start Date is invalid"
        );
        return false;
      }
      if (
        typeof model.SearchEndDate != "object" &&
        model.SearchEndDate != "" &&
        model.SearchEndDate != null
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "End Date is invalid"
        );
        return false;
      }
      if (!model.SearchStartDate) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "From Date cannot be empty"
        );
        return false;
      }
      if (!model.SearchEndDate) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "To Date cannot be empty"
        );
        return false;
      }
      var diffMonths = model.SearchEndDate.diff(model.SearchStartDate, "days");
      if (diffMonths < 0) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Start Date cannot be bigger than End Date"
        );
        return false;
      }

      if (diffMonths > 30) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Difference between Start Date and End Date cannot be longer than 30 days"
        );
        return false;
      }
    }
    return true;
  };
  showErrorMessage(message) {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={"Error"}
          Message={message}
          Type="warning"
          ShowCancel={false}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }

  ClearSuspiciousDialog = () => {
    this.setState({
      suspiciousDialog: false,
      sdCancelMemo: "",
      sdCancelReasonId: "",
      sdTransactionId: null,
    });
  };
  RenderItemStoreName = (d) => {
    this.renderItemStoreName = `${d.StoreName} - (${d.StoreCode})`;
    return this.renderItemStoreName;
  };
  render() {
    const { Disabled } = this.props;
    const {
      alert,
      isLoading,
      model,
      LimitToken,
      suspiciousDialog,
      sdCancelReasonId,
      sdCancelMemo,
      gridButtonRightDetail,
    } = this.state;

    return (
      <div>
        {this.isClient && (
          <AccountLimitToolbar
            key={LimitToken}
            Program={ProgramCodes.Bank_Programs_e_Transfer}
          />
        )}

        <LoadingComponent Show={isLoading} />

        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
            {
              Code: "Search",
              OnClick: this.HandleGetList,
              Disabled: Disabled,
              ModelFunction: () => model,
              FillDataFromModel: (model) => this.setState({ model }),
              ValidationFunction: this.Validate,
            },
            { Code: "Export", OnClick: this.ExportClick, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Main Parameter">
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{
                          UniqueClientId: this.isClient
                            ? this.clientId
                            : undefined,
                        }}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.HandleChange}
                        CanClear
                        Required={model.searchIsNotSpecific}
                        Disabled={
                          Disabled ||
                          this.isClient ||
                          !model.searchIsNotSpecific
                        }
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <ParameterComponent
                        Name="SettlementStatusId"
                        LabelText="Settlement Status"
                        Value={model.SettlementStatusId}
                        ParameterCode="ETransferSettlementStatus"
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                        All={true}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericTextInput
                        Name="PayeeName"
                        LabelText="Customer Name"
                        Value={model.PayeeName}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericNumberInput
                        NoFormatting={true}
                        Name="TransactionId"
                        LabelText="Transaction ID"
                        Value={model.TransactionId}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <ParameterComponent
                        Name="TransactionStatusId"
                        LabelText="Transaction Status"
                        Value={model.TransactionStatusId}
                        ParameterCode="BankTransactionStatus"
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                        All={true}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <ParameterComponent
                        Name="PriorityTypeId"
                        LabelText="e-Transfer Type"
                        Value={model.PriorityTypeId}
                        ParameterCode="EtransferType"
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericTextInput
                        Name="PayeeEmail"
                        LabelText="Customer E-Mail"
                        Value={model.PayeeEmail}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Name="TransactionTypeId"
                        LabelText="Transaction Type"
                        Method="GET"
                        Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
                        DataRoot="Item"
                        KeyValueMember="Id"
                        TextValueMember="ParameterValue6"
                        Value={model.TransactionTypeId}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        key={model.UniqueClientId}
                        Name="StoreId"
                        LabelText="Store"
                        Method="POST"
                        Url="/bankapi/v1.0/CustomerStore/GetByUniqueClientRowId"
                        Parameter={{
                          UniqueClientId: model.UniqueClientId,
                          IsUniqueClientRowId: true,
                        }}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={this.RenderItemStoreName}
                        Value={model.StoreId}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                        All={true}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericTextInput
                        Name="TransactionAmount"
                        LabelText="Amount"
                        Value={model.TransactionAmount}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericDateInput
                        Name="SearchStartDate"
                        LabelText="From Date"
                        Value={
                          model.SearchStartDate
                            ? Formatter.FormatDateUtc(model.SearchStartDate)
                            : ""
                        }
                        ValueChanged={this.HandleChange}
                        MaxDate={
                          model.SearchEndDate || DateHelper.GetDate()
                        }
                        Disabled={Disabled || !model.searchIsNotSpecific}
                        Required={model.searchIsNotSpecific}
                        Utc
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <ParameterComponent
                        Name="TransactionSourceId"
                        LabelText="Source"
                        Value={model.TransactionSourceId}
                        ParameterCode="TransactionSource"
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericTextInput
                        Name="FileName"
                        LabelText="File Name"
                        Value={model.FileName}
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericDateInput
                        Name="SearchEndDate"
                        LabelText="To Date"
                        Value={
                          model.SearchEndDate
                            ? Formatter.FormatDateUtc(model.SearchEndDate)
                            : ""
                        }
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled || !model.searchIsNotSpecific}
                        MinDate={model.SearchStartDate}
                        Required={model.searchIsNotSpecific}
                        Utc
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericNumberInput
                        NoFormatting={true}
                        Name="AccountNumber"
                        LabelText="Wallet Number"
                        MaxLength={9}
                        Value={model.AccountNumber || ""}
                        ValueChanged={this.HandleChange}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Name="ApplicationId"
                        LabelText="Application Type"
                        Value={
                          model === undefined ? "" : model.ApplicationId || ""
                        }
                        DataRoot="Item"
                        All
                        ValueChanged={this.HandleChange}
                        KeyValueMember="Value"
                        TextValueMember="Text"
                        IsStatic
                        StaticData={EtransferApplicationTypes || []}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericDateInput
                        Name="SettlementDate"
                        LabelText="Settlement Date"
                        Value={
                          model.SettlementDate
                            ? Formatter.FormatDateUtc(model.SettlementDate)
                            : ""
                        }
                        ValueChanged={this.HandleChange}
                        Utc
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <ParameterComponent
                        Name="DirectionId"
                        LabelText="Direction"
                        Value={model.DirectionId}
                        ParameterCode="Direction"
                        ParameterValue2="ETRANSFER" // Burası None gelmesin diye bu şekilde
                        ValueChanged={this.HandleChange}
                        Disabled={Disabled}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericNumberInput
                        NoFormatting={true}
                        Name="RefNo"
                        LabelText="Reference Number"
                        Value={model.RefNo}
                        ValueChanged={this.HandleChange}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericTextInput
                        Name="InteracRefNo"
                        LabelText="Interac Reference Number"
                        Value={model.InteracRefNo}
                        ValueChanged={this.HandleChange}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericTextInput
                        Name="ResponseReferenceNumber"
                        LabelText="Response Reference Number"
                        Value={model.ResponseReferenceNumber}
                        ValueChanged={this.HandleChange}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Transaction List"} />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={4}></GridItem>
                      <GridItem xs={4} />
                      <GridItem xs={4} />
                    </GridContainer>
                    <GridContainer spacing={16}>
                      <GridItem xs={12}>
                        <GenericGrid
                          key={"trxGrid" + this.state.trxGridToken}
                          ref={this.trxTable}
                          PageSize={10}
                          ShowPagination={true}
                          Columns={[
                            {
                              Header: "Actions",
                              accessor: "Actions",
                              Cell: (row) => (
                                <div>
                                  <GridButton
                                    tooltip="Send"
                                    Disabled={
                                      Disabled ||
                                      !row.original.IsEditable ||
                                      row.original.SubmitStatus
                                        .ParameterValue !=
                                        BankTransactionStatus.Unapproved ||
                                      !gridButtonRightDetail.find(
                                        (x) => x.Code == "CALETSSEND"
                                      )?.HasAuthority
                                    }
                                    Icon={SubmitOrSendIcon}
                                    OnClick={() => {
                                      this.HandleSend(row.original);
                                    }}
                                  />

                                  <GridButton
                                    tooltip="Edit"
                                    Disabled={
                                      Disabled ||
                                      !row.original.IsEditable ||
                                      this.isClient ||
                                      this.isSalesPerson ||
                                      row.original.Direction.ParameterValue !=
                                        Direction.Outgoing ||
                                      row.original.SubmitStatus
                                        .ParameterValue !=
                                        BankTransactionStatus.Pending ||
                                      !gridButtonRightDetail.find(
                                        (x) => x.Code == "CALETSEDIT"
                                      )?.HasAuthority
                                    }
                                    Icon={EditIcon}
                                    OnClick={() => {
                                      RouteHelper.Push(
                                        this.props.history,
                                        row.original.Program.ProgramCode ==
                                          ProgramCodes.Bank_Programs_e_Transfer
                                          ? "/CreateEtransfer"
                                          : "/BankingBackofficeCreateEtransferx",
                                        "CreateEtransfer_Id",
                                        row.original.Id
                                      );
                                    }}
                                  />

                                  <GridButton
                                    tooltip="Cancel"
                                    Disabled={
                                      Disabled ||
                                      row.original.Direction == null ||
                                      row.original.Direction.ParameterValue !=
                                        Direction.Outgoing ||
                                      row.original.SubmitStatus
                                        .ParameterValue ==
                                        BankTransactionStatus.Completed ||
                                      row.original.SubmitStatus
                                        .ParameterValue ==
                                        BankTransactionStatus.Cancelled ||
                                      !gridButtonRightDetail.find(
                                        (x) => x.Code == "CALETSCANC"
                                      )?.HasAuthority
                                    }
                                    Icon={DeleteIcon}
                                    OnClick={() => {
                                      this.HandleCancel(row.original);
                                    }}
                                  />

                                  <GridButton
                                    tooltip="Trace"
                                    Disabled={
                                      Disabled ||
                                      row.original.Direction == null ||
                                      row.original.Direction.ParameterValue !=
                                        Direction.Incoming ||
                                      !gridButtonRightDetail.find(
                                        (x) => x.Code == "CALETSTRAC"
                                      )?.HasAuthority
                                    }
                                    Icon={TraceIcon}
                                    OnClick={() => {
                                      this.HandleTrace(row.original);
                                    }}
                                  />

                                  <GridButton
                                    tooltip="Suspicious"
                                    Disabled={
                                      Disabled ||
                                      (row.original.SuspiciousStatus != null &&
                                        row.original.SuspiciousStatus != "") ||
                                      !gridButtonRightDetail.find(
                                        (x) => x.Code == "CALETSSUSP"
                                      )?.HasAuthority
                                    }
                                    Icon={SuspiciousIcon}
                                    OnClick={() => {
                                      this.HandleSuspicious(row.original);
                                    }}
                                  />

                                  <GridButton
                                    tooltip="Remind Transfer"
                                    Disabled={
                                      Disabled ||
                                      row.original.Direction == null ||
                                      row.original.Direction.ParameterValue !=
                                        Direction.Outgoing ||
                                      row.original
                                        .BankTransactionStatusParamValue !=
                                        BankTransactionStatus.Sent ||
                                      (row.original.InteracStatusParamValue !=
                                        ETransferMoneySendStatus.TransferAvailable &&
                                        row.original.InteracStatusParamValue !=
                                          ETransferMoneySendStatus.TransferInitiated &&
                                        row.original.InteracStatusParamValue !=
                                          ETransferMoneyRequestStatus.Available) ||
                                      !gridButtonRightDetail.find(
                                        (x) => x.Code == "CALETSREMN"
                                      )?.HasAuthority
                                    }
                                    Icon={UploadIcon}
                                    OnClick={() => {
                                      this.HandleRemind(row.original);
                                    }}
                                  />
                                </div>
                              ),
                              sortable: false,
                              filterable: false,
                              width: 200,
                              show: this.isCallCenter,
                            },
                            {
                              Header: "Transaction Date",
                              accessor: "TransactionDate",
                              type: GridColumnType.DateTimeUtc,
                            },
                            {
                              Header: "Trace Status",
                              accessor: "TraceStatus",
                              show: this.isCallCenter,
                              Cell: (row) => {
                                switch (row.value) {
                                  case ApprovalStatus.Waiting:
                                  case ApprovalStatus.Approved:
                                  case ApprovalStatus.Completed:
                                    return "Trace";

                                  default:
                                    return "";
                                }
                              },
                            },
                            {
                              Header: "Suspicious Status",
                              accessor: "SuspiciousStatus",
                              show: this.isCallCenter,
                              Cell: (row) => {
                                switch (row.value) {
                                  case ApprovalStatus.Waiting:
                                    return "Waiting Review";
                                  case ApprovalStatus.Approved:
                                  case ApprovalStatus.Completed:
                                    return "Review Result Approved";
                                  case ApprovalStatus.Rejected:
                                    return "Review Result Rejected";

                                  default:
                                    return "";
                                }
                              },
                            },
                            {
										Header: "Merchant Clearing Date",
                              accessor: "SettlementDate",
                              type: GridColumnType.DateTimeUtc,
                            },
                            {
                              Header: "Wallet Number",
                              accessor: "AccountNumber",
                            },
                            {
                              Header: "Unique Client Id",
                              accessor: "ClientUniqueClientId",
                            },
                            {
                              Header: "Client Name",
                              accessor: "ClientName",
                            },
                            {
                              Header: "Customer Number",
                              accessor: "CustomerNumber",
                            },
                            {
                              Header: "Customer Name",
                              accessor: "PayeeName",
                            },
                            {
                              Header: "Transaction Type",
                              accessor: "TransactionType.ParameterDesc",
                              Cell: (row) => (
                                <div>
                                  {row.value == "Credit"
                                    ? "Money Send"
                                    : "Money Request"}
                                </div>
                              ),
                            },
                            {
                              Header: "Direction",
                              accessor: "Direction.ParameterDesc",
                            },
                            {
                              Header: "Customer E-Mail",
                              accessor: "EMailAddress",
                            },
                            {
                              Header: "Amount",
                              accessor: "TransactionAmount",
                              type: GridColumnType.Money,
                              ColumnType: GridColumnType.Money,
                            },
                            {
                              Header: "Fee Amount",
                              accessor: "FeeAmount",
                              type: GridColumnType.Money,
                              ColumnType: GridColumnType.Money,
                            },
                            {
                              Header: "System Settlement Status",
                              accessor: "SettlementStatus",
                            },
                            {
                              Header: "Transaction Status",
                              accessor: "SubmitStatus.ParameterDesc",
                            },
                            {
                              Header: "Settlement Type",
                              accessor: "PriorityType.ParameterDesc",
                            },
                            {
                              Header: "Transaction Description",
                              accessor: "TransactionMemo",
                            },
                            {
                              Header: "File Id",
                              accessor: "FileId",
                            },
                            {
                              Header: "File Name",
                              accessor: "FileName",
                            },
                            {
                              Header: "Transaction Source",
                              accessor: "TransactionSource.ParameterDesc",
                            },
                            {
                              Header: "Customer Store",
                              accessor: "CustomerStore",
                            },
                            {
                              Header: "Store Code",
                              accessor: "StoreCode",
                            },
                            {
                              Header: "Application",
                              accessor: "ApplicationId",
                              Cell: (row) => {
                                var rowValue = "";
                                EtransferApplicationTypes &&
                                  EtransferApplicationTypes.forEach((item) => {
                                    if (item.Value == row.value)
                                      rowValue = item.Text;
                                  });
                                return rowValue;
                              },
                            },
                            {
                              Header: "Reference Number",
                              accessor: "ReferenceNumber",
                            },
                            {
                              Header: "Interac Reference Number",
                              accessor: "InteracReferenceNumber",
                            },
                            {
                              Header: "Response Reference Number",
                              accessor: "RequestReferenceNumber",
                            },
                            {
                              Header: "Error Description",
                              accessor: "ErrorDesc",
                            },
                            {
                              Header: "Trace Reject Reason",
                              accessor: "TraceRejectReason",
                              show: this.isCallCenter,
                            },
                            {
                              Header: "Suspicious Reject Reason",
                              accessor: "SuspiciousRejectReason",
                              show: this.isCallCenter,
                            },
									 {
										Header: "Sender Email",
										accessor: "SenderEmail",
									 },
									 {
										Header: "Receiver Name",
										accessor: "ReceiverName",
									 },
									 {
										Header: "Receiver Email",
										accessor: "ReceiverEmail",
									 },
									 {
										Header: "Client Reference Number",
										accessor: "ClientRefNo",
									 },
                          ]}
                          Sorted={this.sortedByDate}
                          ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
                          ServerSide
                          LoadAtStartup={this.state.loadingCompleted}
                          ValidateRequest={this.Validate}
                          PrepareRequest={() => {
                            // Only call center needs suspicious and trace columns datas (For performance)
                            const { model } = this.state;
                            model.IsCallCenter = this.isCallCenter;
                            return model;
                          }}
                          RequestUrl="/bankapi/v1.0/BankInteracETransfer/SearchETransferTransactionsPaged"
                          RequestMethod="POST"
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GenericDialog open={suspiciousDialog} maxWidth="md" fullWidth>
          <DialogTitle>
            <GenericLabel Text="Suspicious Transaction" Bold={true} />
          </DialogTitle>
          <DialogContent style={{ height: 200 }}>
            <ParameterComponent
              Name="sdCancelReasonId"
              LabelText="Suspicious Reason"
              Value={sdCancelReasonId}
              ValueChanged={this.ValueChanged}
              ParameterCode={"TransactionCancelReason"}
            />
            <GenericTextInput
              Name="sdCancelMemo"
              LabelText="Suspicious Memo"
              Value={sdCancelMemo}
              ValueChanged={this.ValueChanged}
            />
          </DialogContent>
          <GenericDialogActions>
            <GridContainer justify="flex-end">
              <Button size="sm" onClick={this.HandleSuspiciousConfirm}>
                SAVE
              </Button>
              <Button size="sm" onClick={this.ClearSuspiciousDialog}>
                CANCEL
              </Button>
            </GridContainer>
          </GenericDialogActions>
        </GenericDialog>

        <GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel
              Text="Select Report Type"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GenericRadioInput
              Name="ExportReportType"
              LabelText="Report Type"
              IsStatic={true}
              StaticData={[
                { Value: "PDF", Text: "PDF" },
                { Value: "CSV", Text: "CSV" },
                { Value: "EXCEL", Text: "EXCEL" },
              ]}
              KeyValueMember="Value"
              TextValueMember="Text"
              Value={this.state.ExportReportType}
              ValueChanged={this.ValueChanged}
            />
            <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={this.state.ExportSelectedColumns}
                onChange={(value) =>
                  this.setState({ ExportSelectedColumns: value })
                }
                isMulti
                name="columns"
                getOptionLabel={(d) => d.title}
                options={this.state.GridKeyValueList}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.ExcelDownload}>
              Download
            </Button>
            <Button
              size="sm"
              onClick={() => this.setState({ isDownloadDialogOpen: false })}
            >
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>
      </div>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.object,
};

export default Transactions;
