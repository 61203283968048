import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withArtifex } from "core";
import React from "react";
import { GenericColorInput, GenericGrid, GenericLabel, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import { CrudType, FileCode, FileType, GridColumnType, LoanLedgerCardIconType } from "views/Constants/Constant";

const styles = ({
	pictureSrc: {
		width: "100%",
		height: "100%",
		maxWidth: 128
	}
});

class LedgerCardCompanyIconDetail extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "Logo Type",
				accessor: "IconTypeDescription"
			},
			{
				Header: "Logo Name",
				accessor: "FileName"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Color Of Line",
				accessor: "ColorOfLine1"
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			}
		];

		this.maxLength100 = { maxLength: 100 };

		this.state = {
			model: {},
			list: []
		};
	}

	componentDidMount() {
		this.Search();
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "IconTypeId") {
				model["IsStatementLogo"] = data && data.ParameterValue == LoanLedgerCardIconType.StatementLogo;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;
		var model = list[index];

		model["IsStatementLogo"] = model.IconTypeValue == LoanLedgerCardIconType.StatementLogo;

		this.setState({ model: list[index], selected: index });
	}

	IsFileChangedToFalse = () => {
		this.setState({ isFileChanged: false });
	}

	Search = async () => {
		const { ExecuteApiPost, UniqueClientId } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/SearchDetail", { UniqueClientId });

		this.setState({ list: result || [] });
	}

	Submit = async () => {
		var controlResult = await this.ValidateAndCheckBeforeSubmitAndUpdate();

		if (!controlResult) {
			return;
		}

		const { model, isFileChanged } = this.state;
		const { ExecuteApiPost, ExecuteApiFileUpload, LoanLedgerCardIconId, UniqueClientId } = this.props;
		var request = {};

		if (isFileChanged) {
			var formData = new FormData();
			formData.append("file", model.File, model.FileName);
			formData.append("fileId", model.TempFileId || 0);
			formData.append("fileCode", FileCode.LedgerCardLogo);
			formData.append("isBinaryOrBase64", FileType.Base64);
			formData.append("isInsertOrUpdate", model.Id == null ? CrudType.Insert : CrudType.Update);

			var result = await ExecuteApiFileUpload("/coreapi/v1.0/File/UploadFile", formData);

			if (result != null) {
				request = {
					LoanLedgerCardIconId: LoanLedgerCardIconId,
					LoanLedgerCardIconDetailId: model.Id,
					UniqueClientId: UniqueClientId,
					IconTypeId: model.IconTypeId,
					FileId: result,
					Description: model.Description,
					ColorOfLine1: model.ColorOfLine1
				};

				ExecuteApiPost(`/lmsapi/v1.0/LoanLedgerCardIcon/${model.Id == null ? "Insert" : "UpdateDetail"}`, request, null, null, null, [this.IsFileChangedToFalse, this.Clear, this.Search], true);
			}
		}
		else {
			request = {
				LoanLedgerCardIconDetailId: model.Id,
				IconTypeId: model.IconTypeId,
				Description: model.Description,
				ColorOfLine1: model.ColorOfLine1
			};

			ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/UpdateDetail", request, null, null, null, [this.Clear, this.Search], true);
		}
	}

	Update = async () => {
		var controlResult = await this.ValidateAndCheckBeforeSubmitAndUpdate();

		if (!controlResult) {
			return;
		}

		const { model, isFileChanged } = this.state;
		const { ExecuteApiPost, ExecuteApiFileUpload, LoanLedgerCardIconId, UniqueClientId } = this.props;
		var request = {};

		if (isFileChanged) {
			var formData = new FormData();
			formData.append("file", model.File, model.FileName);
			formData.append("fileId", model.TempFileId || 0);
			formData.append("fileCode", FileCode.LedgerCardLogo);
			formData.append("isBinaryOrBase64", FileType.Base64);
			formData.append("isInsertOrUpdate", CrudType.Update);

			var result = await ExecuteApiFileUpload("/coreapi/v1.0/File/UploadFile", formData);

			if (result != null) {
				request = {
					LoanLedgerCardIconId: LoanLedgerCardIconId,
					LoanLedgerCardIconDetailId: model.Id,
					UniqueClientId: UniqueClientId,
					IconTypeId: model.IconTypeId,
					FileId: result,
					Description: model.Description,
					ColorOfLine1: model.ColorOfLine1
				};

				ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/UpdateDetail", request, null, null, null, [this.IsFileChangedToFalse, this.Clear, this.Search], true);
			}
		}
		else {
			request = {
				LoanLedgerCardIconDetailId: model.Id,
				IconTypeId: model.IconTypeId,
				Description: model.Description,
				ColorOfLine1: model.ColorOfLine1
			};

			ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/UpdateDetail", request, null, null, null, [this.Clear, this.Search], true);
		}
	}

	ValidateAndCheckBeforeSubmitAndUpdate = async () => {
		if (!this.Validate()) {
			return false;
		}

		const { model } = this.state;
		const { ExecuteApiPost, UniqueClientId } = this.props;

		var requestOfCheck = {
			LoanLedgerCardIconDetailId: model.Id,
			UniqueClientId: UniqueClientId,
			IconTypeId: model.IconTypeId,
		};
		var resultOfCheck = await ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/Check", requestOfCheck);

		if (!resultOfCheck) {
			return false;
		}

		return true;
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, isFileChanged: false, isAddLogoDialogOpen: true });
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];

		if (!file) {
			return;
		}

		var { model } = this.state;
		model.TempFileId = model.FileId || 0;
		model.File = file;
		model.FileName = file.name;

		this.setState({ model, isFileChanged: true });
	}

	Validate() {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.IconTypeId) {
			errors.push("Icon type cannot be null.");
		}

		if (model.IsStatementLogo && !model.Description) {
			errors.push("Description cannot be null.");
		}

		if (model.IsStatementLogo && !model.ColorOfLine1) {
			errors.push("Color of line cannot be null.");
		}

		if (model.FileName == null
			&& model.File == null) {
			errors.push("Logo file can not be null.");
		}

		model["IsIconTypeId"] = !model.IconTypeId;
		model["IsDescription"] = model.IsStatementLogo && !model.Description;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected } = this.state;
		const { classes, ClientName, Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<GridContainer direction="row" justify="space-between">
					<GridItem>
						<ToolbarButton
							disabled={Disabled || selected != null}
							size="sm"
							tooltip="Submit"
							onClick={this.Submit} >
							Submit
							</ToolbarButton>
						<ToolbarButton
							disabled={Disabled}
							size="sm"
							tooltip="Search"
							onClick={this.Search} >
							Search
						</ToolbarButton>
						<ToolbarButton
							disabled={Disabled || selected == null}
							size="sm"
							tooltip="Update"
							onClick={this.Update} >
							Update
							</ToolbarButton>
						<ToolbarButton
							disabled={Disabled || selected == null}
							size="sm"
							tooltip="Delete"
							onClick={() => showConfirmMessageInDelete(() => ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/DeleteDetail", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))}>
							Delete
						</ToolbarButton>
						<ToolbarButton
							size="sm"
							tooltip="Clear"
							onClick={this.Clear}>
							Clear
						</ToolbarButton>
					</GridItem>
					<GridItem style={{ alignItems: "flex-end" }}>
						<GenericLabel
							Text={ClientName} />
					</GridItem>
				</GridContainer>
				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="image/*" />
				< Card>
					<CardBody>
						<GridContainer>
							<GridItem xs={3}>
								<ParameterComponent
									Required
									IsInvalid={model.IsIconTypeId}
									Name="IconTypeId"
									LabelText="Logo Type"
									ParameterCode="LoanLedgerCardIconType"
									Value={model.IconTypeId}
									ValueChanged={this.ValueChanged} />
							</GridItem>
							<GridItem xs={2}>
								<Button size="sm" onClick={() => this.fileInputRef.current.click()} style={{ marginTop: 8 }}>Browse</Button>
							</GridItem>
							<GridItem xs={2} style={{ display: model.FileDataBase64 ? "inherit" : "none" }}>
								{
									model.FileDataBase64 &&
									<img
										src={model.FileDataBase64}
										className={classes.pictureSrc}
										alt={model.FileName}
									/>
								}
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={3} style={{ display: model.IsStatementLogo ? "inherit" : "none" }}>
								<GenericTextInput
									Required
									IsInvalid={model.IsDescription}
									Name="Description"
									LabelText="Description"
									inputProps={this.maxLength100}
									Value={model.Description}
									ValueChanged={this.ValueChanged} />
							</GridItem>
							<GridItem xs={2} >
								<GridContainer justify="center" alignItems="flex-start" direction="column" style={{ display: model.IsStatementLogo ? "inherit" : "none" }}>
									<GenericColorInput
										Name="ColorOfLine1"
										LabelText="Color of Line"
										Value={model.ColorOfLine1}
										ValueChanged={this.ValueChanged} />
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card >
				< Card>
					<CardBody>
						<GenericGrid
							Data={list}
							Columns={this.columns}
							SelectedIndex={selected}
							RowSelected={this.RowSelected}
							IsSorted={false} />
					</CardBody>
				</Card>
			</div>
		);
	}
}

LedgerCardCompanyIconDetail.propTypes = {
};

export default withArtifex(LedgerCardCompanyIconDetail, styles);