import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes, EmbossType } from "views/Constants/Constant.js";
import kycManualPhotoDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/kycManualPhotoDefinitionStyle.jsx";
import GenericCardMedia from "views/Components/Generic/GenericCardMedia.jsx";

const galerydefault = require("assets/img/galerydefault-image.png");


class CreateMainCard extends Component {
	constructor(props) {
		super(props);
		this.parameterEmbossType = { ParameterCode: "EmbossType" };

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,
			IsMass: true,
			StoredImage: ""
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			fileInfoModel: {},
			list: [],

			isLoading: false
		};

		this.emptyObject = {};

		this.companyNameResult = {};

		this.maxLength21 = { maxLength: 21 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.renderItemAccount = {};

		this.renderItemProduct = {};

		this.parameterProduct = {};

		this.parameterDefaultAccount = {};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Emboss Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Emboss 4th Line",
				accessor: "EmbossName2"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Create Personalized Card");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleChange = async (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		const { ExecuteApiPost } = this.props;

		var responseData = await ExecuteApiPost("/prepaidapi/v1.0/Product/GetProductImage", { Id: model.ProductId, UniqueClientId: model.UniqueClientId });
		var fileInfoModel = { ...this.state.fileInfoModel }

		if (responseData == null || responseData == undefined) {
			fileInfoModel.StoredImage = galerydefault;
			this.setState({ model });
		}

		if (responseData != null || responseData != undefined) {
		var cardNumberValue = document.getElementById("cardNumber");
		if(cardNumberValue){
			var cardNumber = cardNumberValue.textContent = "5032 9334 3764 9846";
			cardNumber = cardNumberValue.style.position = "absolute";
			cardNumber = cardNumberValue.style.left = "20px";
			cardNumber = cardNumberValue.style.bottom = "55px";
			cardNumber = cardNumberValue.style.color = "white";
			cardNumber = cardNumberValue.style.fontSize = "15px";
		}
		var cardNameValue = document.getElementById("cardName");
		if(cardNameValue){
			var cardName = cardNameValue.textContent = "Alexander Theodore";
			cardName = cardNameValue.style.position = "absolute";
			cardName = cardNameValue.style.left = "20px";
			cardName = cardNameValue.style.bottom = "20px";
			cardName = cardNameValue.style.color = "white";
			cardName = cardNameValue.style.fontSize = "15px";
		}   
		var cardDateValue = document.getElementById("cardDate");
		if(cardDateValue){
			var cardDate = cardDateValue.textContent = "09/19";
			cardDate = cardDateValue.style.position = "absolute";
			cardDate = cardDateValue.style.left = "160px";
			cardDate = cardDateValue.style.bottom = "30px";
			cardDate = cardDateValue.style.color = "white";
			cardDate = cardDateValue.style.fontSize = "15px";
		}

    if (responseData.CardChipTypeId == 11100) {
		var documentryValue = document.getElementById("cardChipType");
		if(documentryValue){
			var documentry = documentryValue.style.width = "50px";
			documentry = documentryValue.style.height = "40px";
			documentry = documentryValue.style.left = "20px";
			documentry = documentryValue.style.bottom = "80px";
			documentry = documentryValue.style.position = "absolute";
			documentry = documentryValue.style.borderRadius = "5px";
			documentry = documentryValue.style.backgroundColor = "white";
		}
    }
	 else {
		var documentryValue = document.getElementById("cardChipType");
		if(documentryValue){
			var documentry = documentryValue.style.width = "";
			documentry = documentryValue.style.height = "";
			documentry = documentryValue.style.left = "";
			documentry = documentryValue.style.bottom = "";
			documentry = documentryValue.style.position = "";
			documentry = documentryValue.style.borderRadius = "";
			documentry = documentryValue.style.backgroundColor = "";
		}
    }

    if (responseData.CardBrandId == 3400) {
		var documentry2Value = document.getElementById("cardBrandId");
		if(documentry2Value){
			var documentry2 = documentry2Value.style.width = "80px";
			documentry2 = documentry2Value.style.height = "37px";
			documentry2 = documentry2Value.style.left = "235px";
			documentry2 = documentry2Value.style.bottom = "15px";
			documentry2 = documentry2Value.style.position = "absolute";
			documentry2 = documentry2Value.style.borderRadius = "10px";
			documentry2 = documentry2Value.style.backgroundColor = "white";
		}
    }
	 else if (responseData.CardBrandId == 3403) {
		var documentry2Value = document.getElementById("cardBrandId");
		if(documentry2Value){
			var documentry2 = documentry2Value.style.width = "48px";
			documentry2 = documentry2Value.style.height = "48px";
			documentry2 = documentry2Value.style.left = "265px";
			documentry2 = documentry2Value.style.bottom = "15px";
			documentry2 = documentry2Value.style.position = "absolute";
			documentry2 = documentry2Value.style.borderRadius = "10px";
			documentry2 = documentry2Value.style.backgroundColor = "white";
		}
    }
	 else if (responseData.CardBrandId == 3401) {
		var documentry2Value = document.getElementById("cardBrandId");
		if(documentry2Value){
			var documentry2 = documentry2Value.style.width = "80px";
			documentry2 = documentry2Value.style.height = "55px";
			documentry2 = documentry2Value.style.left = "235px";
			documentry2 = documentry2Value.style.bottom = "15px";
			documentry2 = documentry2Value.style.position = "absolute";
			documentry2 = documentry2Value.style.borderRadius = "30px";
			documentry2 = documentry2Value.style.backgroundColor = "white";
		}
    }
	 else {
		var documentry2Value = document.getElementById("cardBrandId");
		if(documentry2Value){
			var documentry2 = documentry2Value.style.width = "";
			documentry2 = documentry2Value.style.height = "";
			documentry2 = documentry2Value.style.left = "";
			documentry2 = documentry2Value.style.bottom = "";
			documentry2 = documentry2Value.style.position = "";
			documentry2 = documentry2Value.style.borderRadius = "";
			documentry2 = documentry2Value.style.backgroundColor = "";
		}
    }

    if (responseData.CardContactlessColorTypeId == 11102) {
		var documentry3Value = document.getElementById("cardContactlessColorType");
		if(documentry3Value){
			var documentry3 = documentry3Value.style.position = "absolute";
			documentry3 = documentry3Value.style.width = "40px";
			documentry3 = documentry3Value.style.height = "40px";
			documentry3 = documentry3Value.style.left = "275px";
			documentry3 = documentry3Value.style.bottom = "100px";
			documentry3 = documentry3Value.style.borderRadius = "40px";
			documentry3 = documentry3Value.style.backgroundColor = "black";
		}
    }
	 else if (responseData.CardContactlessColorTypeId == 11104) {
		var documentry3Value = document.getElementById("cardContactlessColorType");
		if(documentry3Value){
			var documentry3 = documentry3Value.style.position = "absolute";
			documentry3 = documentry3Value.style.width = "40px";
			documentry3 = documentry3Value.style.height = "40px";
			documentry3 = documentry3Value.style.left = "275px";
			documentry3 = documentry3Value.style.bottom = "100px";
			documentry3 = documentry3Value.style.borderRadius = "40px";
			documentry3 = documentry3Value.style.backgroundColor = "white";
		}
    }
	 else {
		var documentry3Value = document.getElementById("cardContactlessColorType");
		if(documentry3Value){
			var documentry3 = documentry3Value.style.position = "";
			documentry3 = documentry3Value.style.width = "";
			documentry3 = documentry3Value.style.height = "";
			documentry3 = documentry3Value.style.left = "";
			documentry3 = documentry3Value.style.bottom = "";
			documentry3 = documentry3Value.style.borderRadius = "";
			documentry3 = documentry3Value.style.backgroundColor = "";
		}
    }

    if(responseData.BackgroundColorId == 11105 || responseData.BackgroundColorId == 11109 || responseData.BackgroundColorId == 11107){
      let cardName = document.getElementById("cardName").style.color = "black";
      let cardNumber = document.getElementById("cardNumber").style.color = "black";
      let cardDate = document.getElementById("cardDate").style.color = "black";
    }
	 else if(responseData.BackgroundColorId == 11106 || responseData.BackgroundColorId == 11110 || responseData.BackgroundColorId == 11108){
      let cardName = document.getElementById("cardName").style.color = "white";
      let cardNumber = document.getElementById("cardNumber").style.color = "white";
      let cardDate = document.getElementById("cardDate").style.color = "white";
    }
			this.setState({ model, fileInfoModel: responseData });
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		if (name === "HasCardUsageLimit" && !newValue) {
			delete model.CardUsagePeriodId;
			delete model.CardUsageLimit;
		}
		if (name === "EmbossType" && newValue != EmbossType.InstantId) {
			model.IsMass = true;
			model.InstantEmbossBranchId = undefined;
		}
		if (name === "EmbossType" && newValue == EmbossType.InstantId) {
			model.IsMass = false;
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, fileInfoModel: {}, vModel: {}, isEdit: false, index: -1 });

		var cardNumberValue = document.getElementById("cardNumber");
		if(cardNumberValue){
			var cardNumber = cardNumberValue.textContent = "";
			cardNumber = cardNumberValue.style.position = "";
			cardNumber = cardNumberValue.style.left = "";
			cardNumber = cardNumberValue.style.bottom = "";
			cardNumber = cardNumberValue.style.color = "";
			cardNumber = cardNumberValue.style.fontSize = "";
		}
		var cardNameValue = document.getElementById("cardName");
		if(cardNameValue){
			var cardName = cardNameValue.textContent = "";
			cardName = cardNameValue.style.position = "";
			cardName = cardNameValue.style.left = "";
			cardName = cardNameValue.style.bottom = "";
			cardName = cardNameValue.style.color = "";
			cardName = cardNameValue.style.fontSize = "";
		}	
		var cardDateValue = document.getElementById("cardDate");
		if(cardDateValue){
			var cardDate = cardDateValue.textContent = "";
			cardDate = cardDateValue.style.position = "";
			cardDate = cardDateValue.style.left = "";
			cardDate = cardDateValue.style.bottom = "";
			cardDate = cardDateValue.style.color = "";
			cardDate = cardDateValue.style.fontSize = "";
		}

		var cardChipTypeValue =  document.getElementById("cardChipType");
		if(cardChipTypeValue){
			var documentry = cardChipTypeValue.style.width = "";
			documentry = cardChipTypeValue.style.height = "";
			documentry = cardChipTypeValue.style.left = "";
			documentry = cardChipTypeValue.style.bottom = "";
			documentry = cardChipTypeValue.style.position = "";
			documentry = cardChipTypeValue.style.borderRadius = "";
			documentry = cardChipTypeValue.style.backgroundColor = "";
		}

		var cardBrandIdValue = document.getElementById("cardBrandId");
		if(cardBrandIdValue){
			var documentry2 = cardBrandIdValue.style.width = "";
			documentry2 = cardBrandIdValue.style.height = "";
			documentry2 = cardBrandIdValue.style.left = "";
			documentry2 = cardBrandIdValue.style.bottom = "";
			documentry2 = cardBrandIdValue.style.position = "";
			documentry2 = cardBrandIdValue.style.borderRadius = "";
			documentry2 = cardBrandIdValue.style.backgroundColor = "";
		}

		var colorTypeValue = document.getElementById("cardContactlessColorType");
		if(colorTypeValue){
			var documentry3 = colorTypeValue.style.position = "";
			documentry3 = colorTypeValue.style.width = "";
			documentry3 = colorTypeValue.style.height = "";
			documentry3 = colorTypeValue.style.left = "";
			documentry3 = colorTypeValue.style.bottom = "";
			documentry3 = colorTypeValue.style.borderRadius = "";
			documentry3 = colorTypeValue.style.backgroundColor = "";
		}

	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetCardSummaryList",
			{
				IsMainCard: model.IsMainCard,
				UniqueClientId: model.UniqueClientId || 0,
				ClientCustomerId: model.ClientCustomerId,
				ProductId: model.ProductId,
				EmbossName: model.EmbossName,
				IsNoNameCard: false,
				ProductType: "P"
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/CreateCard",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleClear();
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select");

		vModel.ClientCustomerId = model.ClientCustomerId == null;
		if (vModel.ClientCustomerId) messages.push("Customer must be select");

		vModel.ProductId = model.ProductId == null;
		if (vModel.ProductId) messages.push("Product must be select");

		vModel.EmbossName = (model.EmbossName == null || model.EmbossName.trim() == "");
		if (vModel.EmbossName) messages.push("Emboss Name cannot be empty");

		vModel.CardUsagePeriodId = model.HasCardUsageLimit && model.CardUsagePeriodId == null;
		if (vModel.CardUsagePeriodId) messages.push("Card Usage Period must be select");

		vModel.CardUsageLimit = model.HasCardUsageLimit && (model.CardUsageLimit == null || model.CardUsageLimit == 0);
		if (vModel.CardUsageLimit) messages.push("Card Usage Limit cannot be empty");

		vModel.DefaultAccountId = model.DefaultAccountId == null;
		if (vModel.DefaultAccountId) messages.push("Default Wallet must be select");

		vModel.EmbossType = model.EmbossType == null;
		if (vModel.EmbossType) messages.push("Emboss Type must be select");

		vModel.InstantEmbossBranchId = model.InstantEmbossBranchId == null && model.EmbossType == EmbossType.InstantId;
		if (vModel.EmbossType) messages.push("Card Perso Office must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	RenderItemProduct = (d) => {
		this.renderItemProduct = d.Name;
		if (d.UniqueClient)
			this.renderItemProduct += ` - (${d.UniqueClient.UniqueClientId} - ${d.UniqueClient.ClientName})`;
		return this.renderItemProduct;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		if (list[index].ClientCustomer) {
			model.ClientCustomerId = list[index].ClientCustomer.Id;
			model.CustomerNumber = list[index].ClientCustomer.CustomerNumber;
		}
		model.EmbossName = model.EmbossName1;
		this.setState({ isEdit: true, model, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	GetDefaultAccountParameter = () => {
		this.parameterDefaultAccount.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterDefaultAccount.ClientCustomerId = this.state.model.ClientCustomerId;
		return this.parameterDefaultAccount;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	ValidateForCompanyName = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.ClientCustomerId = model.ClientCustomerId == null;
		if (vModel.ClientCustomerId) messages.push("Customer must be select");

		vModel.ProductId = model.ProductId == null;
		if (vModel.ProductId) messages.push("Product must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	FillCompanyName = () => {
		const { model } = this.state;
		if (!this.ValidateForCompanyName()) {
			return;
		}
		this.setState({ isLoading: true });

		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/ClientForthLineSearch",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					if (responseData.Item[0] != null || responseData.Item[0] != undefined) {
						this.companyNameResult = responseData.Item[0];
						model.Emboss4thLine = this.companyNameResult.EmbossForthLine;
						this.setState({ model });
					}
					else {
						this.props.showMessage("error", "Error", "Emboss4thLine cannot defined for this customer and product");
						return;
					}
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	setValueToState = (name, value) => {
		const { model } = this.state;
		if (value == "CustomerNumber") {
			model.Emboss4thLine = model.CustomerNumber;
		}
		if (value == "Custom") {
			model.Emboss4thLine = "";
		}
		if (value == "CompanyName") {
			this.FillCompanyName();
		}
		if (name == "ExportReportType") {
			model.ExportReportType = value;
		}
		this.setState({ model });

	};

	render() {
		const { classes, Disabled, ExecuteApiPost } = this.props;
		const { alert, model, vModel, list, isLoading, index, fileInfoModel } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Disabled={IsClient}
														DefaultIndex={IsClient ? 0 : -1}
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem xs={3}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
														Required
														IsInvalid={vModel.ClientCustomerId} />
												</GridItem>
												<GridItem xs={3}>
													{
														model.IsMass ?
															<GenericSelectInput
																key={model.UniqueClientId}
																Name="ProductId"
																LabelText="Product"
																Method="POST"
																Url="/prepaidapi/v1.0/Product/SearchNonCredit"
																Parameter={this.GetProductParameter()}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={this.RenderItemProduct}
																Value={model.ProductId}
																ValueChanged={this.HandleChange}
																Required
																IsInvalid={vModel.ProductId} />
															:
															<GenericSelectInput
																key={model.IsMass}
																Name="ProductId"
																LabelText="Product"
																Method="POST"
																Url="/prepaidapi/v1.0/Product/ApprovedSearchNonCredit"
																Parameter={this.GetProductParameter()}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={this.RenderItemProduct}
																Value={model.ProductId}
																ValueChanged={this.handleChange}
																Required
																IsInvalid={vModel.ProductId} />
													}

												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="EmbossName"
														LabelText="Emboss Name"
														inputProps={this.maxLength21}
														IsText={true}
														Value={model.EmbossName}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.EmbossName} />
												</GridItem>
												<GridItem xs={3} style={{ marginTop: 8 }}>
													<GenericCheckInput
														Name="HasCardUsageLimit"
														LabelText="Has Card Usage Limit"
														Value={model.HasCardUsageLimit || false}
														ValueChanged={this.HandleChange}
														Disabled={Disabled} />
												</GridItem>
												<GridItem xs={3} style={{ opacity: !model.HasCardUsageLimit ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="CardUsagePeriodId"
														LabelText="Card Usage Period"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterPeriod}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.CardUsagePeriodId}
														ValueChanged={this.HandleChange}
														Disabled={!model.HasCardUsageLimit}
														Required={model.HasCardUsageLimit}
														IsInvalid={vModel.CardUsagePeriodId} />
												</GridItem>
												<GridItem xs={3} style={{ opacity: !model.HasCardUsageLimit ? 0.4 : 1 }}>
													<GenericNumberInput
														Name="CardUsageLimit"
														LabelText="Card Usage Limit"
														Prefix="$"
														MaxLength={12}
														Value={model.CardUsageLimit}
														ValueChanged={this.HandleChange}
														Disabled={!model.HasCardUsageLimit}
														Required={model.HasCardUsageLimit}
														IsInvalid={vModel.CardUsageLimit} />
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="Emboss4thLine"
														LabelText="Emboss 4th Line"
														inputProps={this.maxLength21}
														Value={model.Emboss4thLine}
														ValueChanged={this.HandleChange}
														Disabled={model.ExportReportType != "Custom"}
														IsInvalid={vModel.Emboss4thLine} />
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={3}>
													<GenericSelectInput
														key={model.ClientCustomerId}
														Name="DefaultAccountId"
														LabelText="Assigned Wallet"
														Method="POST"
														Url="/bankapi/v1.0/ClientCustomer/SearchBankAccount"
														Parameter={this.GetDefaultAccountParameter()}
														DataRoot="Item"
														KeyValueMember="AccountId"
														RenderItem={this.RenderItemAccount}
														Value={model.DefaultAccountId}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.DefaultAccountId} />
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="EmbossType"
														LabelText="Emboss Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterEmbossType}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														ValueChanged={this.HandleChange}
														Value={model.EmbossType}
														Required
														IsInvalid={vModel.EmbossType}
														DefaultIndex={1}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="InstantEmbossBranchId"
														LabelText="Card Perso Office"
														Method="POST"
														Url="/prepaidapi/v1.0/Card/GetInstantCardBranchList"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														ValueChanged={this.HandleChange}
														Value={model.InstantEmbossBranchId}
														Disabled={model.IsMass}
														Required
														IsInvalid={vModel.InstantEmbossBranchId}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericRadioInput
														Name="ExportReportType"
														LabelText="4th Line"
														IsStatic={true}
														StaticData={[{ Value: "CustomerNumber", Text: "Customer Number" }, { Value: "CompanyName", Text: "Company Name" }, { Value: "Custom", Text: "Custom" }]}
														KeyValueMember="Value"
														TextValueMember="Text"
														Value={model.ExportReportType}
														ValueChanged={this.setValueToState}
														Disabled={model.CustomerNumber == null || model.CustomerNumber == undefined}
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
											<GridItem xs={3}>
												<GenericNumberInput
														Name="CreditLimit"
														LabelText="Credit Limit"
														Prefix="$"
														MaxLength={12}
														Value={model.CreditLimit}
														ValueChanged={this.HandleChange}
														Required={false}
														IsInvalid={vModel.CreditLimit} />
												</GridItem>
											</GridContainer>
											{
												model.IsMass ?
													<GridItem>
													</GridItem>
													:
													< GridContainer >
														<GridItem xs={4}>
															<br></br>
															<br></br>

															<div id="parent" style={{ position: "relative", width: 323, height: 204 }}>
																<GenericCardMedia
																	ExecuteApiPost={ExecuteApiPost}
																	className={classes.imageProfile}
																	ImageFileId={vModel.Id}
																	Image={fileInfoModel.StoredImage}
																	ImagePlaceholder={galerydefault}
																/>
																<div id="cardNumber"></div>
																<div id="cardName"></div>
																<div id="cardDate"></div>
																<div id="cardChipType"></div>
																<div id="cardBrandId"></div>
																<div id="cardContactlessColorType"></div>
															</div>
														</GridItem>
													</GridContainer>

											}
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Personalized Card List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</ >
		);
	}
}

CreateMainCard.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CreateMainCard, kycManualPhotoDefinitionStyle);