import React, { Component } from "react";
import { withArtifex } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { GenericTitle, GenericTextInput, GenericNumberInput, GenericSelectInput, GenericExpansionPanel, GenericGrid, GenericDateInput } from "views/Components/Generic";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";

class PaymentCard extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.month = [{ Id: "01", Name: "01" }, { Id: "02", Name: "02" }, { Id: "03", Name: "03" }, { Id: "04", Name: "04" }, { Id: "05", Name: "05" }, { Id: "06", Name: "06" }, { Id: "07", Name: "07" }, { Id: "08", Name: "08" }, { Id: "09", Name: "09" }, { Id: "10", Name: "10" }, { Id: "11", Name: "11" }, { Id: "12", Name: "12" }];
		this.year = [{ Id: "2020", Name: "20" }, { Id: "2021", Name: "21" }, { Id: "2022", Name: "22" }, { Id: "2023", Name: "23" }, { Id: "2024", Name: "24" }, { Id: "2025", Name: "25" }, { Id: "2026", Name: "26" }, { Id: "2027", Name: "27" }, { Id: "2028", Name: "28" }, { Id: "2029", Name: "29" }, { Id: "2030", Name: "30" }, { Id: "2031", Name: "31" }];
		this.maxlenght4 = 4;
	}
	RenderCurrencySelect = (d) => {
		return d.Code + " - " + d.Description;
	}

	render() {
		const { cardModel, vModel, cardTransferType } = this.props;
		return (
			<React.Fragment>
				<GridContainer>
					<GridItem xs={12}>
						<Card style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px", overflow: "inherit" }}>
							<CardBody>
								<GenericExpansionPanel Title="Payment Card Information" >
									<GridItem xs={4}>
										<GenericTextInput
											Name="CardEmbossName"
											LabelText="Name On The Card"
											Value={cardModel.CardEmbossName}
											ValueChanged={this.props.HandleChangeCard}
											Required
											IsInvalid={vModel.CardEmbossName}
											IsText
										/>
										<GenericNumberInput
											NoFormatting
											Name="CardTokenNumber"
											LabelText="Card Number"
											MaxLength={16}
											Value={cardModel.CardTokenNumber}
											ValueChanged={this.props.HandleChangeCard}
											Required
											IsInvalid={vModel.CardTokenNumber}
										/>
										<GenericSelectInput
											Name="CurrencyCode"
											LabelText="Currency"
											Value={cardModel.CurrencyCode}
											ValueChanged={this.props.HandleChangeCard}
											KeyValueMember="Id"
											TextValueMember="Description"
											Method="GET"
											Url="/bankapi/v1.0/Currency/ValidCurrencies"
											RenderItem={this.RenderCurrencySelect}
											DataRoot="Item"
											Required
											IsInvalid={vModel.CurrencyCode}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GridContainer>
											<GridItem xs={8}>
												<GenericSelectInput
													LabelMd={6}
													SelectStyle={{ width: "15.7%" }}
													Name="ExpirationMonth"
													LabelText="Expiration Month"
													StaticData={this.month}
													KeyValueMember="Id"
													TextValueMember="Name"
													Value={cardModel.ExpirationMonth}
													ValueChanged={this.props.HandleChangeCard}
													Required
													IsInvalid={vModel.ExpirationMonth}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													SelectStyle={{ width: "15.7%" }}
													LabelMd={0}
													Name="ExpirationYear"
													StaticData={this.year}
													KeyValueMember="Id"
													TextValueMember="Name"
													Value={cardModel.ExpirationYear}
													ValueChanged={this.props.HandleChangeCard}
													Required
													IsInvalid={vModel.ExpirationYear}
												/>
											</GridItem>
										</GridContainer>
										{/* <GenericTextInput
											Name="CardName"
											LabelText="Card Name"
											Value={cardModel.CardName}
											ValueChanged={this.props.HandleChangeCard}
											Required
											IsInvalid={vModel.CardName}
										/> */}
										<GenericTextInput
											Name="SecurityCode"
											LabelText="Security Code"
											Value={cardModel.SecurityCode}
											ValueChanged={this.props.HandleChangeCard}
											inputProps={{ maxLength: this.maxlenght4 }}
											Required
											IsInvalid={vModel.SecurityCode}
										/>
										{cardTransferType === "Mastercard Send" &&(
											<GenericTextInput
											Name="BankCode"
											LabelText="Bank Code"
											Value={cardModel.BankCode}
											ValueChanged={this.props.HandleChangeCard}
											Required
											IsInvalid={vModel.BankCode}
											/>
										)}
										
									</GridItem>
								</GenericExpansionPanel>
								<GridContainer justify="flex-end">
									<GridItem style={{ float: "right" }}>
										<Button size="sm" color="black" onClick={() => this.props.ClearCard()}>	Clear	</Button>
										<Button size="sm" color="black" onClick={cardModel.CardId > 0 ? () => this.props.UpdateCard() : () => this.props.InsertCard()} >
											{cardModel.CardId > 0 ? "Update" : "Add"}</Button>
									</GridItem>
								</GridContainer>
								<br />
								<br />
								<br />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<br />
				{/* <GridContainer>
					<GridItem xs={12}>
						<Card style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px", overflow: "inherit" }}>
							<CardBody> */}
				{/* <GenericExpansionPanel Title="Card Transaction Response" > */}
				{/* <GridItem xs={4}>
										<GenericNumberInput
											NoFormatting
											Name="FingerPrint"
											LabelText="Finger Print"
											MaxLength={16}
											Value={cardModel.FingerPrint}
											ValueChanged={this.props.HandleChangeCard} />
										<GenericNumberInput
											NoFormatting
											Name="Bin"
											LabelText="BIN Number"
											MaxLength={16}
											Value={cardModel.Bin}
											ValueChanged={this.props.HandleChangeCard} />
										<GenericNumberInput
											NoFormatting
											Name="LastFour"
											LabelText="Last Four"
											MaxLength={4}
											Value={cardModel.LastFour}
											ValueChanged={this.props.HandleChangeCard} />
										<GenericTextInput
											Name="Brand"
											LabelText="Brand Visa"
											Value={cardModel.Brand}
											ValueChanged={this.props.HandleChangeCard} />
									</GridItem> */}
				{/* <GridItem xs={4}>
										<GenericTextInput
											Name="CardType"
											LabelText="Card Type"
											Value={cardModel.CardType}
											ValueChanged={this.props.HandleChangeCard} />
										<GenericSelectInput
											Name="CurrencyId"
											LabelText="Currency"
											Url="/bankapi/v1.0/Currency/ValidCurrencies"
											Method="GET"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={cardModel.CurrencyId}
											ValueChanged={this.props.HandleChangeCard} />
										<GenericDateInput
											Name="InsertDateTime"
											LabelText="Created at"
											IncludeTime={false}
											Value={Formatter.FormatDate(cardModel.InsertDateTime)}
											ValueChanged={this.props.HandleChangeCard} />
										<GenericDateInput
											Name="Updated"
											LabelText="Updated at"
											IncludeTime={false}
											Value={cardModel.DateOfBirth}
											ValueChanged={this.props.HandleChangeCard} />
									</GridItem> */}
				{/* </GenericExpansionPanel> */}
				{/* <br />
								<br />
								<br /> */}
				{/* </CardBody>
						</Card>
					</GridItem>
				</GridContainer> */}
				<br />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Payment Card List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.props.cardList}
												Columns={this.props.ColumnsCardData}
												SelectedIndex={this.state.rowIndex}
												IsSorted={false}
												RowSelected={index => {
													this.props.SelectedCardRowChange(0, index);
												}}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</React.Fragment>

		);
	}
}

export default withArtifex(PaymentCard, {});