import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const genericExpansionPanelStyle = theme => ({
	root: {
		flexGrow: 1
	},
	expansionPanel: {
		boxShadow: "none",
		"&:before": {
			display: "none !important"
		}
	},
	expansionPanelExpanded: {
		margin: "0"
	},
	expansionPanelSummary: {
		minHeight: "auto !important",
		backgroundColor: "transparent",
		padding: "10px 0px 10px 5px",
		borderTopLeftRadius: "3px",
		borderTopRightRadius: "3px",
		color: "#3C4858",
		"&:hover": {
			color: primaryColor
		}
	},
	expansionPanelSummaryExpaned: {
		color: primaryColor,
		"& $expansionPanelSummaryExpandIcon": {
			[theme.breakpoints.up("md")]: {
				top: "auto !important"
			},
			transform: "rotate(180deg)",
			[theme.breakpoints.down("sm")]: {
				top: "10px !important"
			}
		}
	},
	expansionPanelSummaryContent: {
		margin: "0 !important"
	},
	expansionPanelSummaryExpandIcon: {
		[theme.breakpoints.up("md")]: {
			top: "auto !important"
		},
		transform: "rotate(0deg)",
		color: "#000",
		[theme.breakpoints.down("sm")]: {
			top: "10px !important"
		}
	},
	expansionPanelSummaryExpandIconExpanded: {},
	title: {
		fontSize: "15px",
		fontWeight: "bolder",
		marginTop: "0",
		marginBottom: "0",
		color: "inherit"
	},
	expansionPanelDetails: {
		padding: "5px 0px 5px"
	}
});

export default genericExpansionPanelStyle;
