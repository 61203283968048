import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericEmailInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DefaultBankInformation } from "views/Constants/Constant.js";

class DDTCustomerInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {}
		};

		this.maxLength20 = { maxLength: 20 };
		this.maxLength30 = { maxLength: 30 };
		this.maxLength100 = { maxLength: 100 };
		this.statusIdParameter = {
			ParameterCode: "AccountStatus",
			"ParameterValue": "",
			ParameterValue2: "DDT",
			"ParameterDesc": ""
		};

		this.FetchCanadaPost = this.FetchCanadaPost.bind(this);
	}

	componentDidMount() {
		this.FetchCanadaPost();
	}

	FetchCanadaPost = () => {
		var parent = this;

		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);

				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;

				var pca;

				var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;

				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					pca = window.pca;
					if (pca != null) {
						var fields =
							[
								{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
								{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
								{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
							],
							options = {
								key: apikey
							},
							control = new pca.Address(fields, options);
							CanadaPostRequest();

						control.listen("populate", function (address) {
							CanadaPostSubmit(address);
							parent.setState(state => {
								state.model.AddressLine1 = address.Line1;
								state.model.AddressLine2 = address.Line2;
								state.model.ZipCode = address.PostalCode;
								return state;
							});
						});
					}
				};
			},
			e => {
				console.log("Canada Post Api key not fetched !");
			}
		);
	}

	SubmitCanadaPostAddressModel = (model) => {
		const { handleChange } = this.props;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Country/CanadaPost",
			model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					handleChange("AddressLine1", this.state.model.AddressLine1);
					handleChange("AddressLine2", this.state.model.AddressLine2);
					handleChange("ZipCode", this.state.model.ZipCode);
					handleChange("CountryId", responseData.Item.CountryId);
					handleChange("ProvinceId", responseData.Item.ProvinceId);
					handleChange("CityId", responseData.Item.CityId);
				} else {
					handleChange("CountryId", 0);
					handleChange("ProvinceId", 0);
					handleChange("CityId", 0);
				}
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.getMenuCode,
			AppCenterTitle:this.props.getAppCenterTitle,
			AppLeftTitle:this.props.getAppLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: AlertHelper.CreateAlert(title, message, type, () => {
				this.setState({ alert: "" });
			})
		});
	}

	render() {
		const { DDTModel, handleChange, PhoneModel, propModel, vModel } = this.props;
		const { isLoading } = this.state;

		return (
			<>
				{this.state.alert}

				<LoadingComponent Show={isLoading} />

				<GridContainer>
					<GridContainer>
						<GridItem xs={6}>
							<GenericSelectInput
								Name="FinInsId"
								LabelText="DDT Number"
								LabelMd={8}
								Method="GET"
								Url="/bankapi/v1.0/FinancialInstitution/GetAll"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="FinancialInstitutionId"
								Value={DefaultBankInformation.FinancialInstitutionId}
								Disabled={true} />
						</GridItem>
						<GridItem xs={3}>
							<GenericSelectInput
								Name="FinInsBranchId"
								key={propModel.FinInsId}
								LabelMd={0}
								Method="GET"
								Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="TransitNumber"
								Value={propModel.FinInsBranchId == null || undefined ? "" : propModel.FinInsBranchId}
								Disabled={true} />
						</GridItem>
						<GridItem xs={3}>
							<GenericNumberInput
								NoFormatting={true}
								LabelMd={0}
								Name="AccountNumber"
								Value={propModel.AccNumber == null || undefined ? "" : propModel.AccNumber}
								Disabled={true} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="CustomerNumber"
								LabelText="CustomerNumber"
								Value={propModel.CustomerNumber == null || undefined ? "" : propModel.CustomerNumber}
								ValueChanged={handleChange}
								Disabled={true} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericNumberInput
								NoFormatting={true}
								LabelText="SIN"
								Name="SIN"
								Value={DDTModel.SIN == null || undefined ? "" : DDTModel.SIN}
								ValueChanged={handleChange} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericSelectInput
								Name="StatusId"
								LabelText="DDT Status"
								Value={DDTModel.StatusId == null || undefined ? "" : DDTModel.StatusId}
								DataRoot="Item"
								ValueChanged={handleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								CanClear={false}
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.statusIdParameter} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="Name"
								LabelText="First Name"
								IsText
								Value={DDTModel.Name == null || undefined ? "" : DDTModel.Name}
								ValueChanged={handleChange}
								Required={true}
								IsInvalid={vModel.Name} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="MiddleName"
								LabelText="Middle Name"
								IsText
								Value={DDTModel.MiddleName == null || undefined ? "" : DDTModel.MiddleName}
								ValueChanged={handleChange} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="LastName"
								LabelText="Last Name"
								IsText
								Value={DDTModel.LastName == null || undefined ? "" : DDTModel.LastName}
								ValueChanged={handleChange}
								Required={true}
								IsInvalid={vModel.LastName} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericDateInput
								Name="DateOfBirth"
								LabelText="Date of Birth"
								Value={DDTModel.DateOfBirth ? DateHelper.ToDateInputValue(DDTModel.DateOfBirth) : ""}
								ValueChanged={handleChange}
								IncludeTime={false}
								IsPast={true}
								Utc={true} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={8}>
							<GenericSelectInput
								Name="PhoneCountryCodeId"
								LabelText="Phone Number"
								TitleLabel="Country Code"
								LabelMd={6}
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								Parameter={{}}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember={"Code"}
								Value={PhoneModel.PhoneCountryCodeId == null || undefined ? "" : PhoneModel.PhoneCountryCodeId}
								ValueChanged={handleChange}
								Sorted={{ Member: "OrderIndex" }}
								RenderItem={d => "+" + d.Code + " (" + d.Name + ")"} />
						</GridItem>
						<GridItem xs={4}>
							<GenericNumberInput
								IsPhone
								LabelMd={0}
								Name="PhoneNumber"
								Value={PhoneModel.PhoneNumber == null || undefined ? "" : PhoneModel.PhoneNumber}
								ValueChanged={handleChange}
								MaxLength={12} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericEmailInput
								Name="Email"
								LabelText="E-Mail"
								Value={DDTModel.Email == null || undefined ? "" : DDTModel.Email}
								ValueChanged={handleChange}
								EndIconName=""
								Required={true}
								IsInvalid={vModel.Email} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="AddressLine1"
								LabelText="Address Line 1"
								Id="street-address"
								Value={DDTModel.AddressLine1}
								ValueChanged={handleChange}
								inputProps={this.maxLength100}
								Required={true}
								IsInvalid={vModel.AddressLine1} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="AddressLine2"
								Id="street-address2"
								LabelText="Address Line 2"
								Value={DDTModel.AddressLine2}
								ValueChanged={handleChange}
								inputProps={this.maxLength100} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ZipCode"
								LabelText="Postal Code"
								Id="postcode"
								Value={DDTModel.ZipCode}
								ValueChanged={handleChange}
								inputProps={this.maxLength20}
								Required={true}
								IsInvalid={vModel.ZipCode} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericSelectInput
								Name="CountryId"
								LabelText="Country"
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								Parameter={{}}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Value={DDTModel.CountryId}
								ValueChanged={handleChange}
								Required={true}
								IsInvalid={vModel.CountryId} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericSelectInput
								key={DDTModel.CountryId + "province"}
								Name="ProvinceId"
								LabelText="Province"
								Method="POST"
								Url="/coreapi/v1.0/Province/Filter"
								Parameter={{ CountryId: DDTModel.CountryId }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Value={DDTModel.ProvinceId}
								ValueChanged={handleChange}
								Required={true}
								IsInvalid={vModel.ProvinceId} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12}>
							<GenericSelectInput
								key={"city" + DDTModel.ProvinceId + ""}
								Name="CityId"
								LabelText="City"
								Method="POST"
								Url="/coreapi/v1.0/City/Filter"
								Parameter={{ ProvinceId: DDTModel.ProvinceId }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Value={DDTModel.CityId}
								ValueChanged={handleChange}
								Required={true}
								IsInvalid={vModel.CityId} />
						</GridItem>
					</GridContainer>
				</GridContainer>
			</>
		);
	}
}

DDTCustomerInfo.propTypes = {
	classes: PropTypes.object,
	DDTModel: PropTypes.object,
	handleChange: PropTypes.func,
	PhoneModel: PropTypes.object,
	propModel: PropTypes.object,
	vModel: PropTypes.object,
	getAppLeftTitle:PropTypes.string,
	getAppCenterTitle:PropTypes.string,
	getMenuCode:PropTypes.string
};

export default withArtifex(DDTCustomerInfo, {});