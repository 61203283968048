import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const languageSelectorStyle = {
	selectFormControl: {
		margin: "1px 1px 1px 0px !important",
		"& > div": {
			"&:before": {
				borderBottomWidth: "1px !important",
				borderBottomColor: "#D2D2D2 !important"
			},
			marginTop: "0px",
			fontSize: "0.6rem",
			"&:after": {
				borderBottomColor: primaryColor + "!important"
			},
			"& > div": {
				"& > svg": {
					color: "white"
				}
			}
		}
	}
};

export default languageSelectorStyle;