import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput } from "views/Components/Generic";
import { Direction, ProgramCodes } from "views/Constants/Constant.js";
class ReconciliationDDTTab extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { DDTSummary } = this.props;
		return (
			<GridContainer>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Outgoing Transactions</b>
								<GenericNumberInput
									Name="TotalAmountCredit"
									LabelText="Total Amount Credit"
									Value={DDTSummary == null ? 0 : DDTSummary.TotalAmountCredit ? DDTSummary.TotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="TotalAmountDebit"
									LabelText="Total Amount Debit"
									Value={DDTSummary == null ? 0 : DDTSummary.TotalAmountDebit ? DDTSummary.TotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<b>Response File Value</b>
								<GenericNumberInput
									Name="ResponseTotalAmountCredit"
									LabelText="Response Total Amount Credit"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseTotalAmountCredit ? DDTSummary.ResponseTotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountDebit"
									LabelText="Response Total Amount Debit"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseTotalAmountDebit ? DDTSummary.ResponseTotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountRejected"
									LabelText="Response Total Amount Rejected"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseTotalAmountRejected ? DDTSummary.ResponseTotalAmountRejected : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountError"
									LabelText="Response Total Amount Error"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseTotalAmountError ? DDTSummary.ResponseTotalAmountError : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountPending"
									LabelText="Response Total Amount Pending"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseTotalAmountPending ? DDTSummary.ResponseTotalAmountPending : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAmount"
									LabelText="Total Amount"
									Value={DDTSummary == null ? 0 : DDTSummary.TotalAmount ? DDTSummary.TotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseFileAmount"
									LabelText="Total Response File Amount"
									Value={DDTSummary == null ? 0 : DDTSummary.TotalResponseFileAmount ? DDTSummary.TotalResponseAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="FileGap"
									LabelText="Gap"
									Value={DDTSummary.TotalAmount-DDTSummary.TotalResponseAmount}
									Disabled={true}
									LabelMd={6}
									Prefix="$"
									EndIconName="blur_on"
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_DDT, Direction.Outgoing)}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Number of Outgoing Transactions</b>
								<GenericNumberInput
									Name="NumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.NumberCreditTransaction ? DDTSummary.NumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="NumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.NumberDebitTransaction ? DDTSummary.NumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<b>Number of Response Transactions</b>
								<GenericNumberInput
									Name="ResponseNumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseNumberCreditTransaction ? DDTSummary.ResponseNumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseNumberDebitTransaction ? DDTSummary.ResponseNumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberRejectedTransaction"
									LabelText="Number of Rejected Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseNumberRejectedTransaction ? DDTSummary.ResponseNumberRejectedTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberErrorTransaction"
									LabelText="Number of Error Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseNumberErrorTransaction ? DDTSummary.ResponseNumberErrorTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberPendingTransaction"
									LabelText="Number of Pending Transaction"
									Value={DDTSummary == null ? 0 : DDTSummary.ResponseNumberPendingTransaction ? DDTSummary.ResponseNumberPendingTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalNumberTransaction"
									LabelText="Total Number of Trans."
									Value={DDTSummary == null ? 0 : DDTSummary.TotalNumberTransaction ? DDTSummary.TotalNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseNumberTransaction"
									LabelText="Total Response Number of Trans."
									Value={DDTSummary == null ? 0 : DDTSummary.TotalResponseNumberTransaction ? DDTSummary.TotalResponseNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionGap"
									LabelText="Gap"
									Value={DDTSummary.TotalNumber-DDTSummary.TotalResponseNumber}
									EndIconName="blur_on"
									LabelMd={6}
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_DDT, Direction.Outgoing)}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridContainer >
		);
	}
}

ReconciliationDDTTab.propTypes = {
	classes: PropTypes.object,
	DDTSummary: PropTypes.object,
	openDialog: func
};

export default ReconciliationDDTTab;