import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {  GenericExpansionPanel,  GenericGrid,  GenericSelectInput,  GenericAlert,} from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import CustomerComponent from "views/Components/CustomerComponent";
import { GridColumnType } from "views/Constants/Constant";

class CardOwingBalanceReport extends React.Component {
  constructor(props) {
    super(props);
    this.initialModel = {
      Id: 0,
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      Customer: null,
      DefaultAccount: null,
      CardTokenNumber: null,
      CustomerNumber: null,
      CustomerId: 0,
    };

    this.columns = [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Debit Inquiry",
        accessor: "DebitInquiry",
		  type: GridColumnType.Money,
		  ColumnType: GridColumnType.Money
      },
		{
			Header: "Card Status",
			accessor: "ApplicationCardStatus",
		 },
		 {
			Header: "Fee Profile",
			accessor: "FeeProfile",
		 },
		 {
			Header: "Statement Day Of Month",
			accessor: "StatementDayOfMonth",
		 },
		 {
			Header: "Statement Date",
			accessor: "StatementDate",
			type: GridColumnType.Date,
			ColumnType: GridColumnType.Date,
		 },
    ];

    this.state = {
      model: this.initialModel,
      isLoading: false,
      checkedAccounts: [],
      ClientTypeParameter: {},
      panelTransactionIsActive: false,
      list: [],
      isBackOffice: ClientHelper.IsBackOffice(),
    };
  }

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Card Owing Balance Report");
    }
  }

  ValueChanged = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;

    this.setState({ model });
  };

  Search = async () => {
    if (!this.Validate()) return;
    const { ExecuteApiPost } = this.props;
    const model = { ...this.state.model };
    const request = {
      CustomerNumber:
        model.Customer == null ? null : model.Customer.CustomerNumber,
      UniqueClientId: model.UniqueClientId,
      CustomerId: model.CustomerId,
    };
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/PaymentAdmin/CardOwingBalanceReport",
      request,
      null,
      null,
      null
    );

    if (result != null) {
      this.setState({ list: result || [] });
    }
  };

  Clear = () => {
    this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
  };
  Validate = () => {
    var { model } = this.state;
	 const { showValidationErrors } = this.props;
    this.setState({ model });
	 var messages = [];
    if (!model.CustomerNumber) {
    }
	 if (model.UniqueClientId == undefined || model.UniqueClientId == null) {
		messages.push("Client cannot be empty.");
	}
	if (messages.length > 0) {
		showValidationErrors(messages);
		return false;
	}
    return true;
  };

  handleChange = (name, newValue) => {
    const model = {
      ...this.state.model,
    };
    model[name] = newValue;
    this.setState({ model });
  };

  handleChangeForCustomer = (name, newValue) => {
    const model = { ...this.state.model };
    const initialModel = { ...this.initialModel };
    if (name === "Customer") {
      if (newValue != null) {
        model.Customer = newValue;
        if (newValue.Accounts != null && newValue.Accounts.length > 0) {
          model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
        } else {
          model.CustomerNumber = null;
        }
      } else {
        model.Customer = initialModel.Customer;
      }
    }
    this.setState({ model });
  };

  HandleClear = () => {
    this.setState({
      model: this.initialModel,
      filterModel: {},
      selected: null,
      list: [],
    });
  };

  FillCustomer = (customer) => {
    this.handleChangeForCustomer("Customer", customer);
  };

  render() {
    const { model, list, isBackOffice } = this.state;
    const { Disabled } = this.props;

    return (
      <div>
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.Search,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.HandleClear,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={this.state.isLoading} />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Filter">
                  <GridContainer>
                    <GridItem xs={2}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
								Required
                        Disabled={!isBackOffice}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomerComponent
                        key={model.UniqueClientId}
                        CustomerNumber={
                          model.Customer === null
                            ? null
                            : model.Customer.CustomerNumber
                        }
                        HandleChange={this.handleChange}
                        FillCallback={this.FillCustomer}
                        Programs={[ProgramCodes.Prepaid]}
                        UniqueClientId={model.UniqueClientId}
                        Disabled={!model.UniqueClientId}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel
                  IsActive
                  Title="Card Owing Balance Report"
                >
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer spacing={16}>
                        <GridItem xs={12}>
                          <GenericGrid
                            Data={list}
                            Columns={this.columns}
                            ShowPagination={true}
                            PageSize={100}
                            IsSorted={false}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CardOwingBalanceReport.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default CardOwingBalanceReport;
