import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {  GenericExpansionPanel,  GenericGrid,  GenericSelectInput,  GenericAlert,  GenericDialog,  GenericLabel,  GenericTextInput,  GenericDialogActions,  GenericCheckInput,} from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import CustomerComponent from "views/Components/CustomerComponent";
import { RowDetailIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

class CreditBureauReporting extends React.Component {
  constructor(props) {
    super(props);
    this.emptyObject = {};
    this.sortedName = { Member: "Name" };
    this.initialModel = {
      Id: 0,
      UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
      Customer: null,
      CustomerNumber: null,
		AddToReport:null,
    };

    this.state = {
      model: this.initialModel,
      isDetailDialogOpen: false,
      CustomerNumber: null,
      CustomerName: null,
      AddToReport: null,
      isLoading: false,
      checkedAccounts: [],
      list: [],
      isBackOffice: ClientHelper.IsBackOffice(),
    };
    this.checkStyle = { marginLeft: "300px" };
    this.linkDivStyle = { float: "right" };

    this.columns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (d) => (
          <div>
            <GridButton
              Icon={RowDetailIcon}
              tooltip="Include Or Exclude"
              OnClick={() =>
                this.openDetailDialog(d.original.CustomerNumber, d.original.CustomerName, d.original.AddToReport)
              }
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 70,
      },
      {
        Header: "Add To Report",
        accessor: "AddToReport",
        Cell: (row) => <div>{row.value == true ? "Yes" : "No"}</div>,
      },
      {
        Header: "Customer Name",
        accessor: "CustomerNumber",
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
    ];

    this.LMSPaymentStatus = {
      ParameterCode: "LMSPaymentStatus",
    };
  }

  openDetailDialog = (customerNumber, customerName, addToReport) => {
    this.setState({
      isDetailDialogOpen: true,
      CustomerNumber: customerNumber,
      CustomerName: customerName,
      AddToReport: addToReport,
    });
  };

  UserDetailDialogOpen = (AddToReport) => {
    this.setState({ AddToReport, isUserDetailDialogOpen: true });
  };

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Credit Bureau Reporting");
    }
  }

  ValueChanged = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;

    this.setState({ model });
  };

  Search = async () => {
    if (!this.Validate()) return;
    const { ExecuteApiPost } = this.props;
    const model = { ...this.state.model };
    const request = {
      CustomerNumber: model.Customer == null ? null : model.Customer.CustomerNumber,
      UniqueClientId: model.UniqueClientId,
      AddToReport: model.AddToReport,
    };
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/PaymentAdmin/ApplicationCardAddToReport",
      request,
      null,
      null,
      null
    );

    if (result != null) {
      this.setState({ list: result || [] });
    }
  };

  Update = async (customerNumber, addToReport) => {
    if (!this.Validate()) return;
    const { ExecuteApiPost } = this.props;
    const model = { ...this.state.model };
    var req = this.Search();
    const request = {
      CustomerNumber: customerNumber,
      AddToReport: addToReport,
    };
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/PaymentAdmin/UpdateApplicationCardAddToReport",
      request,
      null,
      null,
      null
    );

    if (result != null) {
      this.setState({ list: result || [] });
      this.setState({ isDetailDialogOpen: false });
		this.Search();
    }
  };

  Clear = () => {
    this.setState({
      model: {},
      list: [],
      selected: null,
      checkedAccounts: [],
      isDetailDialogOpen: false,
    });
  };
  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };
  Validate = () => {
    var { model } = this.state;
    const { showValidationErrors } = this.props;
    var searchIsNotSpecific = model.searchIsNotSpecific;
    this.setState({ model });

    return true;
  };

  handleChange = (name, newValue, data) => {
    const model = {
      ...this.state.model,
    };
    if (name === "AddToReportUpdate") {
      this.setState({ AddToReport: newValue });
    }
    model[name] = newValue;
    this.setState({ model });
  };

  handleChangeForCustomer = (name, newValue) => {
    const model = { ...this.state.model };
    const initialModel = { ...this.initialModel };
    if (name === "Customer") {
      if (newValue != null) {
        model.Customer = newValue;
        if (newValue.Accounts != null && newValue.Accounts.length > 0) {
          model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
        } else {
          model.CustomerNumber = null;
        }
      } else {
        model.Customer = initialModel.Customer;
      }
    }
    this.setState({ model });
  };

  HandleClear = () => {
    this.setState({
      model: this.initialModel,
      filterModel: {},
      selected: null,
      list: [],
    });
  };

  FillCustomer = (customer) => {
    this.handleChangeForCustomer("Customer", customer);
  };

  render() {
    const { model, list, isBackOffice, isDetailDialogOpen } = this.state;
    const { Disabled } = this.props;

    return (
      <div>
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Submit",
              Disabled: Disabled,
              OnClick: this.Update,
            },
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.Search,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.HandleClear,
            },
            {
              Code: "Update",
              Disabled: Disabled,
              OnClick: () => this.Update(),
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={this.state.isLoading} />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Filter">
                  <GridContainer>
                    <GridItem xs={2}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                        Disabled={!isBackOffice}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomerComponent
                        key={model.UniqueClientId}
                        CustomerNumber={
                          model.Customer === null
                            ? null
                            : model.Customer.CustomerNumber
                        }
                        HandleChange={this.handleChange}
                        FillCallback={this.FillCustomer}
								Programs={[ProgramCodes.Prepaid]}
                        UniqueClientId={model.UniqueClientId}
                        Disabled={!model.UniqueClientId}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <GenericDialog open={isDetailDialogOpen} maxWidth="md" fullWidth>
              <DialogTitle>
                <GenericLabel Text="Include Exclude Report" Bold={true} />
              </DialogTitle>
              <DialogContent>
                <GenericTextInput
                  Name="CustomerNumber"
                  LabelText="Customer Number"
                  Value={this.state.CustomerNumber}
                  Disabled={true}
                />
                <GenericTextInput
                  Name="CustomerName"
                  LabelText="Customer Name"
                  Value={this.state.CustomerName}
                  Disabled={true}
                />
                <div style={this.checkStyle}>
                  <GenericCheckInput
                    LabelText="Add To Report"
                    Name="AddToReportUpdate"
                    Value={this.state.AddToReport}
                    Disabled={false}
                    ValueChanged={this.handleChange}
                  />
                </div>
                <br />
              </DialogContent>
              <GenericDialogActions>
                <Button
                  size="sm"
                  onClick={() =>
                    this.Update(
                      this.state.CustomerNumber,
                      this.state.AddToReport
                    )
                  }
                >
                  Submit
                </Button>
                <Button
                  size="sm"
                  onClick={() => this.setState({ isDetailDialogOpen: false})}
                >
                  Close
                </Button>
              </GenericDialogActions>
            </GenericDialog>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel IsActive Title="Credit Bureau Reporting">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer spacing={16}>
                        <GridItem xs={12}>
                          <GenericGrid
                            Data={list}
                            Columns={this.columns}
                            ShowPagination={true}
                            PageSize={100}
                            IsSorted={false}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CreditBureauReporting.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default CreditBureauReporting;
