import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class CloseAccountApprove extends Component {

	constructor(props) {
		super(props);

		this.state = {
			accountData: {},
			isLoading: false,
			accountId: 0
		};

		this.GetAccountInfo = this.GetAccountInfo.bind(this);
		this.CloseAccount = this.CloseAccount.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.ShowConfirmMessage = this.ShowConfirmMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	GetAccountInfo(accountId) {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/Account/GetAccountInfo", accountId,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ accountData: responseData.Item, accountId });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching wallet data.", errorMessage);
			});
	}

	CloseAccount(workflowId, after, accessToken) {
		const { accountId } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/Account/CloseAccount", accountId,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (after) after();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching wallet data.", errorMessage);
			}, workflowId, accessToken);
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { accountData, isLoading, alert, accountId } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Submit", OnClick: this.CloseAccount, Disabled: Disabled,
						ModelFunction: () => { return { Id: accountId }; },
						FillDataFromModel: model => { this.GetAccountInfo(model.Id); },
						RowId: accountId
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<GridContainer>
									<GridItem xs={6}>
										<GenericSelectInput
											Disabled={true}
											Name="ProgramId"
											LabelText="Program"
											Value={accountData.ProgramId}
											KeyValueMember="Id"
											TextValueMember="Description"
											Method="GET"
											Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile"
											DataRoot="Item" />
									</GridItem>
									<GridItem xs={6}>
										<ParameterComponent
											Disabled={true}
											Name="BankingAccountTypeId"
											LabelText="Wallet Type"
											ParameterCode="BankingAccountType"
											ParameterValue2="SettlementAccount"
											Value={accountData.BankingAccountTypeId} />
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={6}>
										<GenericSelectInput
											TitleLabel="Fins. Ins. No."
											LabelStyle={{ width: "33.6%" }}
											SelectStyle={{ width: "65.7%" }}
											LabelMd={-1}
											Name="FinancialInstitutionRowId"
											LabelText="Wallet No"
											Method="GET"
											Url="/bankapi/v1.0/FinancialInstitution/GetAll"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={46}
											Disabled={true} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											TitleLabel="Transit"
											Method="POST"
											LabelMd={0}
											Name="FinancialInstitutionBranchId"
											Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
											Parameter={{ FinancialInstitutionId: 46 }}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={accountData.BranchId}
											Disabled={true} />
									</GridItem>
									<GridItem xs={3}>
										<GenericTextInput
											LabelMd={0}
											Name="AccountNumber"
											Value={accountData.AccountNumber}
											Disabled={true} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={4}>
								<GenericTextInput
									Disabled={true}
									Name="AccountName"
									LabelText="Wallet Name"
									Value={accountData.AccountName} />
								<ParameterComponent
									Disabled={true}
									Name="AccountStatusId"
									LabelText="Wallet Status"
									ParameterCode="AccountStatus"
									Value={accountData.AccountStatusId} />
								<ParameterComponent
									Disabled={true}
									Name="IntendUseOfAccountId"
									LabelText="Intended Use of Wallet"
									ParameterCode="IntendUseOfAccount"
									Value={accountData.IntendUseOfAccountId} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</div>
		);
	}
}

CloseAccountApprove.propTypes = {
	Disabled: PropTypes.bool
};

export default withArtifex(CloseAccountApprove, {});