import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const tabComponentStyle = theme => ({
	root: {
		marginTop: "5px",
		paddingLeft: "0",
		marginBottom: "0",
		overflow: "visible !important",
		minHeight: "24px"
	},
	flexContainer: {
		[theme.breakpoints.down("xs")]: {
			display: "flex",
			flexWrap: "wrap"
		},
		marginBottom: "5px"
	},
	displayNone: {
		display: "none !important"
	},
	fixed: {
		overflowX: "auto"
	},
	pills: {
		float: "left",
		position: "relative",
		display: "block",
		borderRadius: "6px",
		minWidth: "100px",
		textAlign: "center",
		transition: "all .3s",
		// padding: "10px 15px",
		color: "#555555",
		//height: "32px",
		minHeight: "24px",
		opacity: "1",
		maxWidth: "100%",
		margin: "0 2px",
		backgroundColor: "#e4e4e4"
	},
	pillsWithIcons: {
		borderRadius: "4px"
	},
	tabIcon: {
		width: "30px",
		height: "30px",
		display: "block",
		margin: "15px 0"
	},
	labelContainer: {
		padding: "0!important",
		color: "inherit"
	},
	label: {
		lineHeight: "24px",
		textTransform: "uppercase",
		fontSize: "12px",
		fontWeight: "500",
		position: "relative",
		display: "block",
		color: "inherit",
		paddingLeft: "4px",
		paddingRight: "4px"
	},
	contentWrapper: {
		marginTop: "5px",
		border: "1px #e4e4e4 solid",
		padding: "3px",
		borderRadius: "6px",
		paddingLeft: "6px",
		paddingRight: "6px"
	},
	primary: {
		"&,&:hover": {
			color: "#FFFFFF",
			backgroundColor: primaryColor,
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)"
		}
	},
	alignCenter: {
		alignItems: "center",
		justifyContent: "center"
	}
});

export default tabComponentStyle; 