import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import GridButton from "views/Components/GridButton";
import { SubmitOrSendIcon } from "core/Icons";

class FeeDemands extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			vModel: {},
			list: [],
			isLoading: false
		};

	}
	componentDidMount() {
		this.props.setAppLeftTitle("Fee Demands");
		this.props.setAppCenterTitle("WALLET");
	}

	GetFeeDemands = () => {


		var ErrorList = [];
		const vModel = this.state.vModel;
		const data = this.state.model;


		if (data.UniqueClientId == null || data.UniqueClientId == 0) {
			vModel.UniqueClientId = true;
			ErrorList.push("Client must be selected !");
		} else { vModel.UniqueClientId = false; }


		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/FeeDemands/GetFeeDemands",
			this.state.model,
			responseData => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}


	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type} OnConfirm={() => this.setState({ alert: null })}
				/>
		});
	}
	handleClear = () => {
		this.setState({ model: {}, list: [], isLoading: false });
	}
	HandleColumnSubmit = (Item) => {
		if (Item && Item.Status == false) {
			const { showConfirmMessage } = this.props;
			let model = this.state.model;
			model.Id = Item.Id;
			this.setState({ model }, () => { showConfirmMessage("warning", "Warning", "Are you sure you want to charge fee?", this.Submit); });
		}
	}
	Submit = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/FeeDemands/ManuelMontlyPeriodicFee",
			{ FeeDemandId: this.state.model.Id },
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Operation Successfully Completed");
				this.GetFeeDemands();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}
	render() {
		const { Disabled } = this.props;
		const { model, isLoading, vModel } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetFeeDemands, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Required
										IsInvalid={vModel.UniqueClientId}
										Name="UniqueClientId"
										LabelText="Client Name"
										Value={model == null ? "" : model.UniqueClientId || ""}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Name"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Method="POST"
										Parameter={{ UniqueClientId: 0, SaleTypesId: 0 }} />
									<GenericSelectInput
										Name="ProgramId"
										LabelText="Program"
										Method="POST"
										DataRoot="Item"
										Value={model.ProgramId}
										Url="/bankapi/v1.0/Bank/GetBankProgramsUnsorted"
										ValueChanged={this.handleChange}
										Parameter={{}}
										KeyValueMember="Id"
										TextValueMember="Description" />
									<ParameterComponent
										Name="PeriodId"
										LabelText="Period"
										ParameterCode="Period"
										Value={model.PeriodId}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="BeginDate"
										LabelText="Start Date"
										Value={model.BeginDate == null ? "" : DateHelper.ToDateInputValueAsUtc(model.BeginDate) || ""}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="EndDate"
										LabelText="End Date"
										Value={model.EndDate == null ? "" : DateHelper.ToDateInputValueAsUtc(model.EndDate) || ""}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text="Fee Demands" />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.list}
									Columns={[
										{
											Header: "Actions",
											accessor: "actions",
											Cell: row => (
												<div>
													<GridButton
														tooltip="Submit"
														Icon={SubmitOrSendIcon}
														Disabled={Disabled || (row.original && row.original.Status)}
														OnClick={() => this.HandleColumnSubmit(row.original)} />

												</div>
											),
											sortable: false,
											filterable: false,
											width: 65
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Unique Client ID",
											accessor: "UniqueClientId"
										},
										{
											Header: "Program",
											accessor: "Program"
										},
										{
											Header: "Charge Operation",
											accessor: "ChargeOperation"
										},
										{
											Header: "Fee Type",
											accessor: "FeeType"
										},
										{
											Header: "Charge Date",
											accessor: "ChargeDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Period",
											accessor: "Period"
										},
										{
											Header: "Status",
											accessor: "Status",
											Cell: row => (
												row.value != true ? "Failed" : "Success"
											)
										},
										{
											Header: "Detail",
											accessor: "ErrorDescription"
										},
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >
		);
	}
}

FeeDemands.propTypes = {
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default FeeDemands;