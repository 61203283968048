import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericTextInput, GenericTitle, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import CardComponent from "views/Components/CardComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class DisputeReport extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,

		};

		this.state = {
			detailModel: {},
			disputeModel: {},
			model: {
			},
			list: [],
			isLoading: false,
			isDialogOpen: false
		}


		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength40 = { maxLength: 40 };
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Pgm Code",
				accessor: "PgmCode"
			},
			{
				Header: "ARN",
				accessor: "ARN"
			},
			{
				Header: "Issuer Acquirer Flag",
				accessor: "IssuerAcquirerFlag"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Settlement Amount",
				accessor: "SettlementAmount"
			},
			{
				Header: "Dispute Amount",
				accessor: "DisputeAmount"
			},
			{
				Header: "Settlement Currency",
				accessor: "SettlementCurrency"
			},
			{
				Header: "Start Date",
				accessor: "StartDate",
				type: GridColumnType.DateUtc
			},

			{
				Header: "Resolve Date",
				accessor: "ResolveDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Chargeback Status",
				accessor: "ChargebackStatusDesc"
			},
			{
				Header: "Chargeback Reason Code",
				accessor: "ChargebackReasonCodeDesc"
			},
			{
				Header: "SecondChargeback Reason Code",
				accessor: "SecondChargebackReasonCodeDesc"
			},
			{
				Header: "Second Chargeback Status",
				accessor: "SecondChargebackStatusDesc"
			},

			{
				Header: "Representment Status",
				accessor: "RepresentmentStatusDesc"
			},

			{
				Header: "Retrieval Request Status",
				accessor: "RetrievalRequestStatusDesc"
			},

			{
				Header: "Retrieval Request Reason Code",
				accessor: "RetrievalRequestReasonCodeDesc"
			},

			{
				Header: "Retrieval Response Status",
				accessor: "RetrievalResponseStatusDesc"
			},


		];

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Dispute Report");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST
			(
				"/prepaidapi/v1.0/ClearingDispute/Search",
				model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							list: responseData.Item
						});
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)

	}



	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	DetailsModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.detailModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { detailModel: model };
		});
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading } = this.state;

		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>

												<GridItem xs={4}>
													<ParameterComponent
														Name="IssuerAcquirerFlagId"
														LabelText="Issuer Acquirer Flag"
														ParameterCode="IssuerAcquirerFlag"
														Value={model.IssuerAcquirerFlagId}
														ValueChanged={this.HandleChange}
														Disabled={false}
													/>
												</GridItem>
												<GridItem xs={4}>
													<ParameterComponent
														Name="PgmCodeId"
														LabelText="Pgm Code"
														ParameterCode="PgmCode"
														Value={model.PgmCodeId}
														ValueChanged={this.HandleChange}
														Disabled={false}
													/>
												</GridItem>
												<GridItem xs={4}>
													<GenericTextInput
														Name="ARN"
														LabelMd={4}
														LabelText="ARN"
														Format={"(@) (@@@@@@) (@@@@) (@@@@@@@@@@@) (@)"}
														inputProps={this.maxLength40}
														Value={model.ARN}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridContainer>

											<GridContainer>
												<GridItem xs={12}>
													<GridContainer>
														<GridItem xs={4}>
															<GenericSelectInput
																Name="UniqueClientId"
																LabelText="Client"
																Method="POST"
																Url="/bankapi/v1.0/BankCustomer/Search"
																Parameter={this.parameterUniqueClientId}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={this.RenderItemUniqueClient}
																Value={model.UniqueClientId}
																ValueChanged={this.HandleChange}
																CanClear
																Disabled={IsClient}
																DefaultIndex={IsClient ? 0 : -1} />
														</GridItem>
														<GridItem xs={4}>
															<CustomerComponent
																key={model.UniqueClientId}
																LabelMd={4}
																CustomerNumber={model.CustomerNumber}
																HandleChange={this.HandleChange}
																FillCallback={this.FillCustomer}
																Programs={[ProgramCodes.Prepaid]}
																UniqueClientId={model.UniqueClientId}
																Disabled={!model.UniqueClientId}
															// Required
															// IsInvalid={vModel.ClientCustomerId}
															/>
														</GridItem>
														<GridItem xs={4}>
															<CardComponent
																key={"Card_" + [model.UniqueClientId, model.ClientCustomerId]}
																LabelMd={4}
																CardTokenNumber={model.CardTokenNumber}
																HandleChange={this.HandleChange}
																FillCallback={this.FillCard}
																ClientCustomerId={model.ClientCustomerId}
																UniqueClientId={model.UniqueClientId}
																Disabled={!model.ClientCustomerId}
															// Required
															// IsInvalid={vModel.CardId}
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Dispute List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
										// RowSelected={this.RowSelected}
										// SelectedIndex={index}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

DisputeReport.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(DisputeReport, {});