import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import {
  GenericDateInput,
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
} from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class FeeBasedTransactionReport extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: "Report Date",
        accessor: "ReportDate",
        type: GridColumnType.Date,
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Customer Number",
        accessor: "CustomerNumber",
      },
      {
        Header: "Credit Card Interest",
        accessor: "CreditCardInterest",
        type: GridColumnType.Money,
      },
      {
        Header: "Inactivity Fee",
        accessor: "InactivityFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Monthly Card Fee",
        accessor: "MonthlyCardFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Overdue Fee",
        accessor: "OverdueFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Overdraft Fee",
        accessor: "OverdraftFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Card Activation Fee",
        accessor: "CardActivationFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Domestic Transaction Fee",
        accessor: "DomesticTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Domestic Atm Transaction Fee",
        accessor: "DomesticAtmTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "International Transaction Fee",
        accessor: "InternationalTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "International Atm Transaction Fee",
        accessor: "InternationalAtmTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Decline Fee",
        accessor: "DeclineFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Card Issuing Fee",
        accessor: "CardIssuingFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Card Renewal Fee",
        accessor: "CardRenewalFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Card ReEmboss Fee",
        accessor: "CardReEmbossFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Annual Card Fee",
        accessor: "AnnualCardFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Balance Inquiry Fee",
        accessor: "BalanceInquiryFee",
        type: GridColumnType.Money,
      },
      {
        Header: "ATM Domestic Transaction Fee",
        accessor: "ATMDomesticTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "POS Domestic Transaction Fee",
        accessor: "POSDomesticTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "ATM International Transaction Fee",
        accessor: "ATMInternationalTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "POS International Transaction Fee",
        accessor: "POSInternationalTransactionFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Foreign Exchange Fee",
        accessor: "ForeignExchangeFee",
        type: GridColumnType.Money,
      },
      {
        Header: "ETransfer Fee",
        accessor: "ETransferFee",
        type: GridColumnType.Money,
      },
      {
        Header: "Bill Payment Fee",
        accessor: "BillPaymentFee",
        type: GridColumnType.Money,
      },
    ];

    this.uniqueClientId = ClientHelper.GetClientRowId();

    this.emptyObject = {};
    this.parameterUniqueClientId = {};

    this.sortedFullName = { Member: "FullName" };

    this.validateProperties = [
      { ToDate: "End Date" },
      { FromDate: "Start Date" },
		{ UniqueClientId: "Client" },
    ];

    this.state = {
      model: {
        UniqueClientId: this.uniqueClientId,
      },
    };
  }

  componentDidMount() {
    const { setAppLeftTitle, setAppCenterTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Fee Based Transaction Report");
    }
  }

  ValueChanged = (name, value) => {
    this.setState((state) => {
      var model = state.model;
      model[name] = value;

      return { model };
    });
  };

  GetParameterUniqueClientId = () => {
    const { model } = this.state;

    this.parameterUniqueClientId.UniqueClientId = model.UniqueClientId;

    return this.parameterUniqueClientId;
  };

  Search = async () => {
    const { model } = this.state;
    const { ExecuteApiPost } = this.props;
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/FeeAndCodeBasedTransaction/SearchFeeBasedTransactionReport",
      model,
      model,
      this.validateProperties
    );

    this.setState({ list: result || [] });
  };

  Clear = () => {
    this.setState({ model: { UniqueClientId: this.uniqueClientId }, list: [] });
  };

  render() {
    const { Disabled } = this.props;
    const { model, list } = this.state;
	 var IsClient = ClientHelper.IsClient();

    return (
      <div>
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.Search,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.Clear,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Fee Based Transaction Report">
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Disabled={IsClient}
                        Name="UniqueClientId"
                        LabelText="Client"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Method="POST"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        TextValueMember="Name"
                        Value={model.UniqueClientId}
                        ValueChanged={this.ValueChanged}
								CanClear
								All
								Required
								DefaultIndex={IsClient ? 0 : -1}
                      />
                      <GenericDateInput
                        Utc
                        Name="FromDate"
                        LabelText="Start Date"
                        Value={model.StartDate}
                        ValueChanged={this.ValueChanged}
                        Required={true}
                      />
                      <GenericDateInput
                        Utc
                        Name="ToDate"
                        LabelText="End Date"
                        Value={model.EndDate}
                        ValueChanged={this.ValueChanged}
                        Required={true}
                      />
                    </GridItem>
                    <GridItem xs={4}></GridItem>
                    <GridItem xs={4}></GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardBody>
                <GenericGrid
                  Data={list}
                  Columns={this.columns}
                  ShowPagination={true}
                  PageSize={100}
                  Sorted={[{ id: "CustomerName", desc: false }]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

FeeBasedTransactionReport.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  Disabled: PropTypes.bool,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default FeeBasedTransactionReport;
