const genericTextInputStyle = {
	button: {
		cursor: "pointer",
		"&:hover": {
			background: "rgba(0, 0, 0, 0.15)"
		},
		borderRadius: "50%"
	},
	// tooltip: {
	// 	background: "#fff",
	// 	color: "white",
	// 	// zIndex: 3
	// }


};

export default genericTextInputStyle;