import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericTitle, GenericDateInput, GenericDialog, GenericGrid, GenericDialogActions, GenericEmailInput, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { MenuCodes } from "views/Constants/Constant.js";
import DateHelper from "core/DateHelper";
import moment from "moment";
import { ClientType } from "views/Constants/Constant.js";


function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class CustomerContactList extends Component {
	constructor(props) {
		super(props);

		this.isBackoffice = ClientHelper.IsBackOffice();

		this.initialModel = {
			Id: 0,
			ClientCustomerId: this.props.ClientCustomerId,
			IsClientCustomerPayee: this.props.IsClientCustomerPayee,
			ProgramCode: this.props.ProgramCode,
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			phoneModel: {},
			list: [],
			IsContactPopUpOpen: false,
			isPersonal: null,
			isLoading: false,
			selected: -1,
			alert: null,
			MustBeCountryCanada: false
		};

		this.maxLength20 = { maxLength: 20 };
		this.maxLength30 = { maxLength: 30 };
		this.maxLength64 = { maxLength: 64 };
		this.maxLength100 = { maxLength: 100 };
		this.marginLeft2 = { marginLeft: 2 };

		this.emptyObject = {};

		this.parameterClientType = {
			ParameterCode: "ClientType",
			ParameterValue3: "D"
		};

		this.parameterLanguage = {
			ParameterCode: "Language"
		};

		this.parameterCountry = {};
		this.parameterProvince = {};

		this.sortedOrderIndex = { Member: "OrderIndex" };
		this.sortedParameterDesc = { Member: "ParameterDesc" };

		this.renderItemPhoneCode = {};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip = "Edit"
							Disabled = { props.Disabled }
							Icon = { EditIcon }
							OnClick = {() => { this.SelectedRowChange(row.index); }} />
						<GridButton
							tooltip = "Delete"
							Disabled = { props.Disabled }
							Icon = { DeleteIcon }
							OnClick = {() => { this.HandleDelete(row.original.Id); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Contact Name",
				accessor: "AliasName"
			},
			{
				Header: "Customer Type",
				accessor: "ClientType.ParameterDesc"
			},
			{
				Header: "E-Mail",
				accessor: "Email"
			},
			{
				Header: "Phone Number",
				accessor: "Phone.PhoneNumber",
				Cell: row => (
					row.original.Phone && row.original.Phone.PhoneCountryCode &&
					<div>{"+" + row.original.Phone.PhoneCountryCode.Code + " " + row.original.Phone.PhoneNumber}</div>
				)
			},
			{
				Header: "Address Line 1",
				accessor: "Address1"
			},
			{
				Header: "Address Line 2",
				accessor: "Address2"
			},
			{
				Header: "City",
				accessor: "City.Name"
			},
			{
				Header: "Province",
				accessor: "Province.Name"
			},
			{
				Header: "Postal Code",
				accessor: "ZipCode"
			},
			{
				Header: "Country",
				accessor: "Country.Name"
			}
		];
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle) setAppLeftTitle("CONTACT LIST");this.getappLeftTitle = "CONTACT LIST";
		if (setAppCenterTitle) setAppCenterTitle("WALLET");this.getappCenterTitle = "WALLET";
		this.FetchCanadaPost();
		this.GetCanadaCountry();
	}
	
	ContactPopUpCallback = () => { this.setState({ IsContactPopUpOpen: !this.state.IsContactPopUpOpen }); }

	FetchCanadaPost = () => {
		var parent = this;

		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);

				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;

				var pca;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;
				var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;

				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					pca = window.pca;
					if (pca != null) {
						var fields =
							[
								{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
								{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
								{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
							],
							options = {
								key: apikey
							},
							control = new pca.Address(fields, options);
							CanadaPostRequest();
						control.listen("populate", function (address) {
							CanadaPostSubmit(address);
							parent.setState(state => {
								state.model.Address1 = address.Line1;
								state.model.Address2 = address.Line2;
								state.model.ZipCode = address.PostalCode;
								return state;
							});
						});
					}
				};
			},
			e => {
				console.log("Canada Post Api key not fetched !");
			}
		);
	}

	SubmitCanadaPostAddressModel = (model) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Country/CanadaPost",
			model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				var address = { ...this.state.model };
				if (responseData.Item != null) {
					address.CountryId = responseData.Item.CountryId;
					address.ProvinceId = responseData.Item.ProvinceId;
					address.CityId = responseData.Item.CityId;
				} else {
					address.CountryId = 0;
					address.ProvinceId = 0;
					address.CityId = 0;
				}
				this.setState({ model: address });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	HandleChange = (name, value, data) => {
		var { model, vModel } = this.state;

		if (name == "ClientTypeId") {
			if (data.ParameterValue == ClientType.Personal) {
				this.setState({ isPersonal: true });
				delete model.CompanyName;
				delete model.TradeName;
			}
			if (data.ParameterValue == ClientType.Corporate) {
				this.setState({ isPersonal: false });
				delete model.FirstName;
				delete model.MiddleName;
				delete model.LastName;
				delete model.BirthDate;
			}
		}

		if (name == "Email")
			vModel.Email = !data.IsValid;

		if (name == "CountryId")
			vModel.CountryCode = data != null ? data.IsoCode2 : "";

		model[name] = value;
		this.setState({ model, vModel });
	}

	HandleChangePhone = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.phoneModel || {};
			model[name] = newValue;
			return { phoneModel: model };
		});
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		console.log(this.state.model)
		Proxy.POST("/bankapi/v1.0/ClientCustomerPayee/Search",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleDelete = (Id) => {
		this.setState({ isLoading: true });
		Proxy.GET("/bankapi/v1.0/ClientCustomerPayee/Delete/Id?Id=" + Id,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, selected: -1 }, this.HandleSearch);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleSubmit = () => {
		const { model, phoneModel } = this.state;

		if (!this.Validate())
			return;

		var CheckOld = true;
		if (model.BirthDate)
			CheckOld = this.CheckOld();

		if (CheckOld) {
			model.Phone = phoneModel;
			this.setState({ isLoading: true });
			Proxy.POST("/bankapi/v1.0/ClientCustomerPayee/InsertOrUpdate",
				model,
				responseData => {
					this.setState({ isLoading: false });
					this.Cancel();
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ model: { ...this.initialModel }, selected: -1 }, this.HandleSearch);
					}
				},
				error => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				});
		}
		else {
			this.ShowMessage("error", "Error", "Customer age must be bigger then 18 !");
		}
	}

	CheckOld = () => {
		var interval = moment.duration(DateHelper.GetDate() - DateHelper.ToMoment(this.state.model.BirthDate)).asYears();
		if (interval < 18) {
			return false;
		}
		return true;
	}

	GetCanadaCountry = () => {
		const { model } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/Country/GetAll",
			this.emptyObject,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null && responseData.Item.length > 0) {
					model.CountryId = responseData.Item.filter(x => x.Code == "1" && x.IsoCode == "CAN")[0].Id;
					this.setState({ model, MustBeCountryCanada: true });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	RenderItemPhoneCode = (d) => {
		this.renderItemPhoneCode = "+" + d.Code + " (" + d.Name + ")";
		return this.renderItemPhoneCode;
	}

	SelectedRowChange = (index) => {
		var model = this.state.list[index];
		this.setState({ selected: index, model, phoneModel: model.Phone });

		if (model.ClientType.ParameterValue == ClientType.Personal) {
			this.setState({ isPersonal: true });
			delete model.CompanyName;
			delete model.TradeName;
		}
		if (model.ClientType.ParameterValue == ClientType.Corporate) {
			this.setState({ isPersonal: false });
			delete model.FirstName;
			delete model.MiddleName;
			delete model.LastName;
			delete model.BirthDate;
		}

		this.setState({IsContactPopUpOpen: true});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage(type, title, message, onConfirm, isShowCancel = true) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={isShowCancel} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	Validate = () => {
		const { model, vModel, phoneModel, isPersonal } = this.state;
		var messages = [];

		vModel.ClientTypeId = model.ClientTypeId == null;
		if (vModel.ClientTypeId) messages.push("Customer Type must be select");

		if (model.Email == null || model.Email == "") {
			vModel.Email = true;
			if (vModel.Email) messages.push("E-Mail cannot be empty");
		}
		else {
			if (vModel.Email) messages.push("E-Mail is invalid");
		}

		if (isPersonal) {
			vModel.FirstName = (model.FirstName == null || model.FirstName == "");
			if (vModel.FirstName) messages.push("First Name cannot be empty");

			vModel.LastName = (model.LastName == null || model.LastName == "");
			if (vModel.LastName) messages.push("Last Name cannot be empty");
		} else {
			vModel.CompanyName = (model.CompanyName == null || model.CompanyName == "");
			if (vModel.CompanyName) messages.push("Company Name cannot be empty");

			vModel.TradeName = (model.TradeName == null || model.TradeName == "");
			if (model.TradeName == null || model.TradeName == "") messages.push("Legal Name cannot be empty");
		}

		if (phoneModel.PhoneNumber) {
			vModel.PhoneNumber = (phoneModel.PhoneNumber.toString().length != 10);
			if (vModel.PhoneNumber) messages.push("Phone Number must be 10 Digits !.");
		}

		vModel.CountryId = model.CountryId == null;
		if (vModel.CountryId) messages.push("Address Country cannot be empty.");

		// vModel.ProvinceId = model.ProvinceId == null;
		// if (vModel.ProvinceId) messages.push("Province must be select");

		// vModel.CityId = model.CityId == null;
		// if (vModel.CityId) messages.push("City must be select");

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	GetCountryParameter = () => {
		this.parameterCountry.CountryId = this.state.model.CountryId
		return this.parameterCountry;
	}

	GetProvinceParameter = () => {
		this.parameterProvince.ProvinceId = this.state.model.ProvinceId
		return this.parameterProvince;
	}

	Cancel = () => {
		this.setState({
			model: { ...this.initialModel },
			selected: -1,
			IsContactPopUpOpen: false
		});
	};

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	render() {
		const { ReadOnly } = this.props;
		const { alert, model, vModel, phoneModel, list, isLoading, isPersonal, IsContactPopUpOpen,  MustBeCountryCanada } = this.state;
		let IsCallCenter = this.props.MenuCode === MenuCodes.ClientAccountActivities;

		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}
				
				<GridContainer>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
								{ Code: "Search", OnClick: () => this.HandleSearch(), Disabled: false }
							]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={" Filter Panel "} />
							</CardHeader>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}></p></b>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GridContainer>
											{(IsCallCenter || this.isBackoffice) &&
												<GridItem xs={4}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelMd={4}
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
														Value={model.UniqueClientId || ""}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.UniqueClientId}
														CanClear />
												</GridItem>
											}
											<GridItem xs={4}>
												<GenericSelectInput
													Name="AliasName"
													LabelMd={4}
													LabelText="Contact Name"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomerPayee/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="AliasName"
													RenderItem={d => `${d.AliasName}`}
													Value={model.AliasName || ""}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.AliasName}
													CanClear />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<br />
						<GenericGrid 
							Data={list}
							Columns={this.ColumnsData}
							SelectedIndex={this.state.selected}
							PageSize={5}
							ShowPagination={true}
							HideButton={true} />
						<br />
					</GridItem>
				</GridContainer>
				<GenericDialog open={IsContactPopUpOpen} onBackdropClick={this.ContactPopUpCallback} maxWidth="lg" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Edit Contact" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12}>
								<GenericTitle text="General Information" />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="ClientTypeId"
									LabelText="Customer Type"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterClientType}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={model.ClientTypeId}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.ClientTypeId}
								/>
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="PreferedLanguageId"
									LabelText="Preferred Language"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterLanguage}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={model.PreferedLanguageId}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
								/>
							</GridItem>
							{!isPersonal &&
								<>
									<GridItem xs={6} style={{ opacity: isPersonal ? 0.4 : 1 }}>
										<GenericTextInput
											Name="CompanyName"
											LabelText="Company Name"
											Value={model.CompanyName}
											ValueChanged={this.HandleChange}
											Disabled={isPersonal || ReadOnly}
											Required
											IsInvalid={vModel.CompanyName} />
									</GridItem>
									<GridItem xs={6} style={{ opacity: isPersonal ? 0.4 : 1 }}>
										<GenericTextInput
											Name="TradeName"
											LabelText="Legal Name"
											Value={model.TradeName}
											ValueChanged={this.HandleChange}
											Disabled={isPersonal || ReadOnly}
											Required
											IsInvalid={vModel.TradeName} />
									</GridItem>
								</>
							}
							{isPersonal &&
								<>
									<GridItem xs={6} style={{ opacity: !isPersonal ? 0.4 : 1 }}>
										<GenericTextInput
											Name="FirstName"
											LabelText="First Name"
											Value={model.FirstName}
											ValueChanged={this.HandleChange}
											Disabled={!isPersonal || ReadOnly}
											Required
											IsInvalid={vModel.FirstName} />
									</GridItem>
									<GridItem xs={6} style={{ opacity: !isPersonal ? 0.4 : 1 }}>
										<GenericTextInput
											Name="MiddleName"
											LabelText="Middle Name"
											Value={model.MiddleName}
											ValueChanged={this.HandleChange}
											Disabled={!isPersonal || ReadOnly} />
									</GridItem>
									<GridItem xs={6} style={{ opacity: !isPersonal ? 0.4 : 1 }}>
										<GenericTextInput
											Name="LastName"
											LabelText="Last Name"
											Value={model.LastName}
											ValueChanged={this.HandleChange}
											Disabled={!isPersonal || ReadOnly}
											Required
											IsInvalid={vModel.LastName} />
									</GridItem>
									<GridItem xs={6} style={{ opacity: !isPersonal ? 0.4 : 1 }}>
										<GenericDateInput
											Name="BirthDate"
											LabelText="Date of Birth"
											Value={model.BirthDate ? DateHelper.ToDateInputValue(model.BirthDate) : ""}
											ValueChanged={this.HandleChange}
											IncludeTime={false}
											IsPast={true}
											Utc={true}
											Disabled={!isPersonal || ReadOnly} />
									</GridItem>
								</>
							}
							<GridItem />
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<GenericTitle text="Communication Information" />
							</GridItem>
							<GridItem xs={6}>
								<GenericEmailInput
									Name="Email"
									LabelText="E-Mail"
									Value={model.Email}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									inputProps={this.maxLength64}
									Required
									IsInvalid={vModel.Email} />
							</GridItem>
							<GridItem xs={6}>
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											Name="PhoneCountryCodeId"
											LabelText="Phone Number"
											TitleLabel="Country Code"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={this.emptyObject}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={phoneModel.PhoneCountryCodeId}
											ValueChanged={this.HandleChangePhone}
											Sorted={this.sortedOrderIndex}
											RenderItem={this.RenderItemPhoneCode}
											Disabled={ReadOnly} />
									</GridItem>
									<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
										<GenericNumberInput
											IsPhone
											Name="PhoneNumber"
											InputStyle={this.marginLeft2}
											LabelMd={0}
											Value={phoneModel.PhoneNumber}
											ValueChanged={this.HandleChangePhone}
											MaxLength={10}
											Disabled={ReadOnly} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="Address1"
									LabelText="Address Line 1"
									Id="street-address"
									Value={model.Address1}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									inputProps={this.maxLength100} />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="Address2"
									Id="street-address2"
									LabelText="Address Line 2"
									Value={model.Address2}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									inputProps={this.maxLength100} />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="ZipCode"
									LabelText="Postal Code / Zip Code"
									Id="postcode"
									Value={model.ZipCode}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									inputProps={this.maxLength20} />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="CountryId"
									LabelText="Country"
									Method="POST"
									Url="/coreapi/v1.0/Country/GetAll"
									Parameter={this.emptyObject}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={model.CountryId}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly || MustBeCountryCanada}
									Required
									IsInvalid={vModel.CountryId} />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									key={model.CountryId + "province"}
									Name="ProvinceId"
									LabelText="Province"
									Method="POST"
									Url="/coreapi/v1.0/Province/Filter"
									Parameter={this.GetCountryParameter()}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={model.ProvinceId}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly} />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									key={"city" + model.ProvinceId + ""}
									Name="CityId"
									LabelText="City"
									Method="POST"
									Url="/coreapi/v1.0/City/Filter"
									Parameter={this.GetProvinceParameter()}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={model.CityId}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSubmit}> Update </Button>
						<Button size="sm" onClick={this.Cancel}> Cancel	</Button>
					</GenericDialogActions>
				</GenericDialog>
			</>
		);
	}
}

CustomerContactList.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CustomerContactList, {});