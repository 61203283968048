import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import LoadingComponent from "views/Components/LoadingComponent";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { Proxy } from "core";
import { ProgramCodes } from "views/Constants/Constant";
import CustomerComponent from "views/Components/CustomerComponent";
import {
  GenericEmailInput,
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
} from "views/Components/Generic";
class InstrumentList extends React.Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      Id: 0,
      Customer: null,
      DefaultAccount: null,
      CardId: null,
      Email: "",
      IsEmailValid: true,
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
    };

    this.columnsList = [
      {
        Header: "Client Customer Name",
        accessor: "ClientCustomerName",
      },
      {
        Header: "First Name",
        accessor: "FirstName",
      },
      {
        Header: "Middle Name",
        accessor: "MiddleName",
      },
      {
        Header: "Last Name",
        accessor: "LastName",
      },
		{
			Header: "Nick Name",
			accessor: "Nickname",
		},
      {
        Header: "Email",
        accessor: "EMail",
      },
      {
        Header: "Wallet Name",
        accessor: "AccountName",
      },
      {
        Header: "Wallet Number",
        accessor: "AccountNumber",
      },
      {
        Header: "Wallet Owner Name",
        accessor: "AccountOwnerName",
      },
      {
        Header: "Identity Type",
        accessor: "IdType",
      },
      {
        Header: "Identity Number",
        accessor: "IdNumber",
      },
      {
        Header: "Expiration Year",
        accessor: "ExpirationYear",
      },
      {
        Header: "Expiration Month",
        accessor: "ExpirationMonth",
      },
      {
        Header: "Card Token Number",
        accessor: "CardTokenNumber",
      },
      {
        Header: "Masked Card Number",
        accessor: "MaskedCardNumber",
      },
      {
        Header: "Payment Instrument Id",
        accessor: "PaymentInstrumentId",
      },
      {
        Header: "Bin",
        accessor: "Bin",
      },
      {
        Header: "Last Four",
        accessor: "LastFour",
      },
      {
        Header: "Brand",
        accessor: "Brand",
      },
      {
        Header: "ClientRegistredId",
        accessor: "ClientRegistredId",
      },
      {
        Header: "Currency",
        accessor: "Currency",
      },
      {
        Header: "Country",
        accessor: "Country",
      },
    ];

    this.parameterUniqueClientId = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientId()
        : undefined,
    };

    this.emptyObject = {};
    this.renderItemUniqueClient = {};

    this.state = {
      vModel: {},
      model: this.initialModel,
      isLoading: false,
      list: [],
    };
  }

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("CARD TRANSFER");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Instrument List");
    }
  }

  Search = async () => {
    const { showMessage } = this.props;
	 const model = { ...this.state.model };
    this.setState({ isLoading: true });
	 var request = {
		CustomerNumber: model.Customer == null ? null : model.Customer.CustomerNumber,
		UniqueClientId: model.UniqueClientId == null ? null : model.UniqueClientId,
		Email: model.Email
	};
    Proxy.POST(
      "/bankapi/v1.0/VisaDirect/RegisteredClientsSearchAccount",
      request,
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          showMessage("error", "Error", responseData.ErrorDescription);
          return;
        }

        this.setState({ list: responseData.Item ?? [] });
      },
      (error) => {
        this.setState({ isLoading: false });
        showMessage("error", "Error", error);
      }
    );
  };

  Clear = () => {
    this.setState({
      model: this.initialModel,
      list: []
    });
  };

  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    if (name === "Customer") {
      if (newValue != null) {
        model.Customer = newValue;
        if (newValue.Accounts != null && newValue.Accounts.length > 0) {
          model.DefaultAccount = newValue.Accounts[0];
        }
      } else {
        model.Customer = this.initialModel.Customer;
        model.DefaultAccount = this.initialModel.DefaultAccount;
      }
    }
    model[name] = newValue;
    this.setState({ model });
  };

  RenderItemUniqueClient = (d) => {
    this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
    return this.renderItemUniqueClient;
  };

  SelectedRowChange = (index) => {
    const { list } = this.state;
    const model = list[index];
  };

  render() {
    const { isLoading, model, vModel, list } = this.state;
    const { Disabled, IsPopUp } = this.props;
    var IsClient = ClientHelper.IsClient();
    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {!IsPopUp && (
          <ButtonToolbar
            ButtonList={[
              {
                Code: "Search",
                Disabled: Disabled,
                OnClick: this.Search,
              },
              {
                Code: "Clear",
                Disabled: Disabled,
                OnClick: this.Clear,
              },
            ]}
            menuId={this.props.menuId}
            ApprovalData={this.props.ApprovalData}
            After={this.props.After}
            IsPopUp={IsPopUp}
          />
        )}
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={3}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
								LabelMd={3}
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={this.parameterUniqueClientId}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={this.RenderItemUniqueClient}
                        Value={model.UniqueClientId}
                        ValueChanged={this.HandleChange}
                        CanClear
                        Disabled={IsClient}
                        DefaultIndex={IsClient ? 0 : -1}
                        Required
                        IsInvalid={vModel.UniqueClientId}
                      />
                    </GridItem>
						  <GridItem xs={1}/>
                    <GridItem xs={3}>
                      <CustomerComponent
                        LabelMd={3}
                        CustomerNumber={
                          model.Customer === null
                            ? null
                            : model.Customer.CustomerNumber
                        }
                        UniqueClientId={model.UniqueClientId}
                        FillCallback={(customer) => {
                          this.HandleChange("Customer", customer);
                        }}
                        Programs={[ProgramCodes.BANK_PROGRAMS_Card_Transfers]}
                      />
                    </GridItem>
						  <GridItem xs={1}/>
                    <GridItem xs={3}>
                      <GenericEmailInput
                        LabelMd={3}
                        Name="Email"
                        LabelText="E-Mail"
                        Value={model.Email}
                        ValueChanged={this.HandleChange}
                        EndIconName="email"
                      />
                    </GridItem>
                    <GridItem xs={3}></GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel IsActive Title="Instrument List">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer spacing={16}>
                        <GridItem xs={12}>
                          <GenericGrid
                            Data={list}
                            Columns={this.columnsList}
                            RowSelected={this.SelectedRowChange}
									 ShowPagination={true}
									 PageSize={10}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

InstrumentList.propTypes = {
  ExecuteApiPost: PropTypes.func,
  ExecuteApiFileDownload: PropTypes.func,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
  IsPopUp: PropTypes.bool,
};

export default InstrumentList;