import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericDateInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import CustomerComponent from "views/Components/CustomerComponent";

class LoanAgingAndDelinquentPayment2 extends React.Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      UniqueClientId: this.uniqueClientId,
      ReportDate: DateHelper.GetDate(),
      CustomerNumber: "",
    };

    this.state = {
      list: [],
      vCustomerModel: {},
      CustomerModel: this.initialModel,
      SelectedCustomerItem: "",
      model: this.initialModel,
      vModel: {},
      isLoading: false,
    };

    this.emptyObject = {};

    this.sortName = { Member: "Name" };

    this.sortedNumber = [{ id: "CustomerNumber", desc: false }];

	 this.uniqueClientId = ClientHelper.GetClientRowId();
	 this.reportDate = DateHelper.GetDate();
    this.ColumnsData = [
      {
        Header: "Client Name",
        accessor: "ClientName",
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Customer Number",
        accessor: "CustomerNumber",
      },
      {
        Header: "Wallet Number",
        accessor: "WalletAccountNumber",
      },
      {
        Header: "Report Date",
        accessor: "ReportDate",
        type: GridColumnType.Date,
        ColumnType: GridColumnType.Date,
      },
      {
        Header: "Application Number",
        accessor: "ApplicationNumber",
      },
		{
			Header: "Last Card Status",
			accessor: "LastCardStatusId",
		 },
      {
        Header: "Statement Begin Date",
        accessor: "StatementBeginDate",
        type: GridColumnType.Date,
        ColumnType: GridColumnType.Date,
      },
      {
        Header: "Statement End Date",
        accessor: "StatementEndDate",
        type: GridColumnType.Date,
        ColumnType: GridColumnType.Date,
      },
      {
        Header: "Security Wallet Balance",
        accessor: "SecurityAccountBalance",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Card Limit",
        accessor: "CardLimit",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Current Available Limit",
        accessor: "CurrentAvailableLimit",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Total Debt Inquiry",
        accessor: "TotalDebtInquiry",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Last Closing Balance",
        accessor: "LastClosingBalance",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },

      {
        Header: "0-30 days",
        accessor: "FirstThirtyDays",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "30-60 days",
        accessor: "SecondThirtyDays",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "60-90 days",
        accessor: "ThirthThirtyDays",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "90-120 days",
        accessor: "FourthThirtyDays",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "120+ days",
        accessor: "ThirtyDaysRestOf",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Over Payment",
        accessor: "OverPayment",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
    ];
  }

  componentDidMount() {
    this.props.setAppLeftTitle("Loan Aging & Delinquent Payment Report");
    this.props.setAppCenterTitle("Collection Transactions");
  }

  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };

  CustomerModelChange = (name, value, data) => {
    const model = { ...this.state.CustomerModel };
	 const vmodel = {...this.state.model}
    const model2 = this.state.SelectedCustomerItem;

    var IsTrxDisabled = false;

    if (
      name === "DestinationAccountId" &&
      data !== null &&
      data !== undefined
    ) {
      this.setState({ SelectedCustomerItem: data.AccountName });
      this.IsCustomerAccountSelected = false;
    }

    if (!IsTrxDisabled) {
      model[name] = value;
    }

    if (name === "Customer") {
      if (value) {
        model.ClientCustomerId = value.Id;
        model.CustomerNumber = value.CustomerNumber;


		  model[name] = value.CustomerNumber;
		  this.setState({ model });
      } else {
        delete model.ClientCustomerId;
        delete model.CustomerNumber;
        delete model.CardId;
        delete model.CardTokenNumber;
      }
    }
    this.setState({ CustomerModel: {UniqueClientId: this.uniqueClientId, ReportDate: vmodel.ReportDate, CustomerNumber:  model.CustomerNumber }, model: { UniqueClientId: this.uniqueClientId, ReportDate: vmodel.ReportDate, CustomerNumber:  model.CustomerNumber}});
  };

  FillCustomerForCustomer = (customer) => {
    this.CustomerModelChange("Customer", customer);
  };

  HandleClear = () => {
	this.setState({ model: { UniqueClientId: this.uniqueClientId,}, CustomerModel: {UniqueClientId: this.uniqueClientId, CustomerNumber: ""},vModel: {}, selected: null });
  };

  HandleSearch = () => {
    const isValidated = this.Validate();
    if (isValidated) {
      this.setState({ isLoading: true });
      Proxy.POST(
        "/losapi/v1.0/CollectionPool/LoanAgingAndDelinquentPaymentReport2",
        this.state.model,
        this.SuccessListCallback,
        this.ErrorListCallback
      );
    }
  };

  SuccessListCallback = (responseData) => {
    this.setState({ isLoading: false });
    if (!responseData.IsSucceeded) {
      this.ShowMessage("error", "Error", responseData.ErrorDescription);
      return;
    }
    if (responseData.Item !== null) {
      this.setState({ list: responseData.Item });
    }
  };

  ErrorListCallback = (error) => {
    this.setState({ isLoading: false });
    this.ShowMessage("error", "Error", error);
  };

  Validate = () => {
    const { model, vModel } = this.state;
    var messages = [];

    vModel.ReportDate = model.ReportDate == null;
    if (vModel.ReportDate) messages.push("Report date must be selected!");

    if (messages.length > 0) {
      this.ShowMessageNode(
        "warning",
        "Please fill required fields!",
        messages.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    this.setState({ vModel });
    return true;
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  render() {
    const { Disabled } = this.props;
    const { alert, isLoading, model, list, vCustomerModel, CustomerModel } =
      this.state;
    var IsClient = ClientHelper.IsClient();
    return (
      <>
        <LoadingComponent Show={isLoading} />

        {alert}

        <ButtonToolbar
          ButtonList={[
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
            { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={3}>
							<GenericSelectInput
									Disabled={IsClient}
									Name="UniqueClientId"
									LabelText="Client"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Method="POST"
									Parameter={{}}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
									TextValueMember="Name"
									Value={model.UniqueClientId}
									ValueChanged={this.HandleChange}
									CanClear
									All
									Required
									DefaultIndex={IsClient ? 0 : -1}
								/>
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomerComponent
                        key={CustomerModel.UniqueClientId}
                        LabelMd={4}
                        CustomerNumber={CustomerModel.CustomerNumber}
                        HandleChange={this.CustomerModelChange}
                        FillCallback={this.FillCustomerForCustomer}
                        Programs={[ProgramCodes.Prepaid, ProgramCodes.Lender]}
                        UniqueClientId={CustomerModel.UniqueClientId}
                        Disabled={false}
                        Required={false}
                        IsInvalid={vCustomerModel.ClientCustomerId}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <GenericDateInput
                        Name="ReportDate"
                        LabelText="Report Date"
                        Value={model.ReportDate}
                        ValueChanged={this.HandleChange}
                        MaxDate={DateHelper.GetDate()}
                        Utc
                        Required
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Transaction List">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenericGrid
                        Data={list}
                        Columns={this.ColumnsData}
                        ShowPagination={true}
                        Sorted={this.sortedNumber}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

LoanAgingAndDelinquentPayment2.propTypes = {
  classes: PropTypes.object,
};

export default withArtifex(LoanAgingAndDelinquentPayment2, {});
