import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Black from "components/Typography/Black.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { GenericAlert,  GenericDialog, GenericDialogActions, GenericLabel, GenericNumberInput } from "views/Components/Generic";
import { AuthenticationMethod } from "views/Constants/Constant";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracConfirmOtp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			isShowQRCode: false,
			model: {
				VerificationCode: ""
			},
			authMethod: null,
			rememberAuthValue: true,
			QRCode: "",
			OtpId: 0
		};
	}

	componentDidMount() {
		console.log(RouteHelper.Read("InteracData"));
		var user = ClientHelper.GetUser();
		if (user && user.AuthenticationMethod) {
			this.setState({ authMethod: user.AuthenticationMethod.ParameterValue });
			if (user.AuthenticationMethod.ParameterValue == AuthenticationMethod.Twilio) {
				this.SendSMSOTP();
			}
		}
		if (!user) {
			RouteHelper.Push(this.props.history, "/InteracConfirm/Login");
		}
	}

	ValueChanged = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}



	HandleChangeAuthMethod = (event) => {
		this.setState({ authMethod: event.target.value });
		if (event.target.value === AuthenticationMethod.Google) {
			// this.GetQRCode();
		} else {
			this.SendSMSOTP();
		}
	}

	Validate = () => {
		const { model, authMethod } = this.state;

		if (!authMethod) {
			this.ShowMessage("warning", "Authentication Method is not selected", "Select Authentication Method to continue.");
			return false;
		}

		if (!model.VerificationCode) {
			this.ShowMessage("warning", "Verification Code is empty", "Enter the Verification Code to continue.");
			return false;
		}

		return true;
	}

	Routing = () => {
		var user = ClientHelper.GetUser();
		if (user == null)
			RouteHelper.Push(this.props.history, "/InteracConfirm/Login");
		else {
			RouteHelper.Push(this.props.history, "/InteracConfirm/Process");
		}
	}

	GetQRCode = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/GetAuthenticatorRegisterLink",
			{},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.AuthenticatorQrLink !== null) {
					this.setState({ QRCode: responseData.AuthenticatorQrLink, isShowQRCode: true });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	SendSMSOTP = () => {
		var user = ClientHelper.GetUser();
		if (user == null) {
				return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/SendSmsOtp",
			{
				MethodName: "Login",
				UserId: user ? user.Id : null,
				OrganizationUniqueId: window.OrganizationUniqueId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ OtpId: responseData.OtpId });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Verify = () => {
		const { authMethod } = this.state;

		if (!this.Validate())
			return;

		if (authMethod == AuthenticationMethod.Google) {
			this.AuthenticatorVerify();
		} else {
			this.TwilioVerify();
		}
	}

	AuthenticatorVerify = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/VerifyAuthenticatorOtp",
			{
				Code: this.state.model.VerificationCode,
				OrganizationUniqueId: window.OrganizationUniqueId

			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var user = ClientHelper.GetUser();
				user.SessionId = responseData.DCBankAccessToken;
				localStorage.setItem("user", JSON.stringify(user));

				this.Routing();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	TwilioVerify = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/VerifySmsOtp", {
			OtpId: this.state.OtpId,
			Otp: this.state.model.VerificationCode,
			OtpMethod: "Login"
		},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.Routing();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}


	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	HandleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.Verify();
		}
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert, isShowQRCode, QRCode } = this.state;

		const logo = require("assets/img/" + window.OtpLogo);
		const googleLogo = require("assets/img/" + window.OtpGoogleLogo);
		const twilioLogo = require("assets/img/" + window.OtpTwilio);
		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading} />
				<GenericDialog open={isShowQRCode}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Two Factor Authentication" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<div>
							<p>To use an authenticator app go through the following steps:</p>
							<ol className={classes.list}>
								<li>
									<p>
										Download a two-factor authenticator app like Microsoft Authenticator for
										<a href="https://go.microsoft.com/fwlink/?Linkid=825071"> Windows Phone</a>,
										<a href="https://go.microsoft.com/fwlink/?Linkid=825072"> Android</a> and
										<a href="https://go.microsoft.com/fwlink/?Linkid=825073"> iOS</a> or
																Google Authenticator for
										<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"> Android</a> and
										<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"> iOS</a>.
									</p>
								</li>
								<li>
									<p>Scan the QR Code into your two factor authenticator app. Spaces and casing do not matter.</p>
									<div id="qrCode"></div>
									<div id="qrCodeData" data-url="@Model.AuthenticatorUri"></div>
								</li>
								<li>
									<p>
										Once you have scanned the QR code or input the key above, your two factor authentication app will provide you
										with a unique code. Enter the code in the confirmation box below.
									</p>
								</li>
							</ol>
						</div>
						{QRCode.length > 0 &&
							<GridItem>
								<img style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}
									src={QRCode}
									width="200"
									height="200"
									alt="qrcode"
								/>
							</GridItem>
						}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isShowQRCode: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				{alert}
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={7}>
						<Card login>
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`}
								color="white"
								style={{ height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" }}>
								<CardMedia
									className={classes.media}
									image={logo}
									style={{
										height: "75%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 10,
										backgroundSize: "100% 100%", boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.45)"
									}}
									title=""
								/>
							</CardHeader>

							<CardBody>
								<Grid container
									direction="column"
									justify="space-between"
									alignItems="center">
									<div style={{ marginTop: 40 }}>
										<Black>Select Your 2FA Authentication Method</Black>
									</div>

									<div style={{ width: "50%", marginRight: "auto", marginLeft: "auto" }}>

										<Grid container
											direction="row"
											justify="space-between"
											alignItems="flex-start">
											<Grid item>
												<Grid container
													direction="column"
													justify="flex-start"
													alignItems="center">
													<Grid item>
														<FormControlLabel
															control={
																<Radio
																	checked={this.state.authMethod === AuthenticationMethod.Google}
																	value={AuthenticationMethod.Google}
																	name="radio-button"
																	classes={{ checked: classes.radio }}
																	onChange={this.HandleChangeAuthMethod}
																/>
															}
															classes={{
																label: classes.radioLabel
															}}
															label="Google"
														/>
													</Grid>
													<Grid item>
														<img className={classes.img} style={{ height: 50 }} src={googleLogo} />
													</Grid>
												</Grid>
											</Grid>
											<Grid item>
												<Grid container
													direction="column"
													justify="flex-start"
													alignItems="center">
													<Grid item>
														<FormControlLabel
															control={
																<Radio
																	checked={this.state.authMethod === AuthenticationMethod.Twilio}
																	value={AuthenticationMethod.Twilio}
																	name="radio-button"
																	classes={{ checked: classes.radio }}
																	onChange={this.HandleChangeAuthMethod}
																/>
															}
															classes={{
																label: classes.radioLabel
															}}
															label="Text 2FA"
														/>
													</Grid>
													<Grid item>
														<img className={classes.img} style={{ height: 50 }} src={twilioLogo} />
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</div>

									<div style={defStyles.divBorder}>
										<Grid container style={{ marginBottom: 5 }}
											direction="column"
											justify="flex-start"
											alignItems="center">
											<Black>Enter initial code or verification code</Black>
											<Grid item container
												direction="row"
												justify="center"
												alignItems="flex-start"
												spacing={16}>
												<Grid item style={{ width: "50%" }}>
													<GenericNumberInput
														LabelMd={3}
														NoFormatting={true}
														ThousandSeparator=""
														Name="VerificationCode"
														LabelText="Code"
														Value={model.VerificationCode}
														ValueChanged={this.ValueChanged}
														AutoFocus={true}
														MaxLength={6}
														KeyPressed={this.HandleKeyPress} />
												</Grid>
											</Grid>
										</Grid>
									</div>

								</Grid>

							</CardBody>
							<CardFooter style={{ marginTop: "-16px" }} className={classes.justifyContentCenter}>
								<GridContainer
									container
									direction="column"
									justify="flex-start"
									alignItems="stretch"
								>
									<GridItem md={12} style={{ width: "68%", marginLeft: "auto", marginRight: "auto" }}>
										<div >
											<Button color="primary" size="lg"
												block
												onClick={() => this.Verify()}
												disabled={!model.VerificationCode || model.VerificationCode.length < 6}>
												SUBMIT
											</Button>
										</div>
									</GridItem>
									<GridItem>
										<Grid container style={{ marginBottom: 5 }}											>
											<Grid item container
												direction="row"
												justify="center"
												alignItems="flex-start"
												spacing={16}>
												<Grid item style={{ width: "69%" }}>
													<div style={{ float: "right" }}>
														<Link style={{ color: "#F57F29" }} to={"/InteracConfirm/Login"}> Back to login page. </Link>
													</div>
												</Grid>
											</Grid>
										</Grid>
									</GridItem>
								</GridContainer>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}
const defStyles = {
	divBorder: {
		marginTop: 20,
		width: "65%",
		borderRadius: "5px 5px 5px 5px",
		border: "1px solid #000000"
	},
	border: {
		borderRadius: "5px 5px 5px 5px",
		border: "1px solid #000000"
	}

};
InteracConfirmOtp.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(InteracConfirmOtp, loginPageStyle);