import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericDialog, GenericLabel, GenericRadioInput, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, EtransferApplicationTypes, ProgramCodes } from "views/Constants/Constant";
import Select from "react-select";
import Button from "components/CustomButtons/Button";
import { DialogTitle, DialogContent } from "@material-ui/core";

class eTransferTraceTransactions extends Component {
	constructor(props) {
		super(props);

		this.InitialModel = {
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
		}
		this.state = {
			model: { ...this.InitialModel },
			list: [],
			isLoading: false,
			isDownloadDialogOpen : false,
			ExportReportType: null
		};
		this.trxTable = React.createRef();
		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",

				Cell: row => (
					Formatter.FormatDateUtc(row.value)
				)
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientNumber"
			},
			{
				Header: "Client Name",
				accessor: "UniqueClientName"
			},
			{
				Header: "Payee Name",
				accessor: "PayeeName"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Application",
				accessor: "ApplicationId",
				Cell: row => {
					var rowValue = "";
					EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
						if (item.Value == row.value)
							rowValue = item.Text;
					});
					return rowValue;
				}
			},
		];
	}
	componentDidMount() {
		this.props.setAppLeftTitle("e-Transfer Trace Transactions");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		this.setState({ loadingCompleted: true });
	}

	handleChange = (name, newValue) => {
		if(name == "ExportReportType"){
			this.setState({
				ExportReportType : newValue
			});
			return;
		}
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	IsValid = () => {
		var model = { ...this.state.model };
		if (typeof model.FromDate != "object" && model.FromDate != "" && model.FromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}
		if (typeof model.ToDate != "object" && model.ToDate != "" && model.ToDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}
		if ((typeof model.FromDate == "object" && typeof model.ToDate == "object") && model.ToDate < model.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}
		return true;
	}

	handleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}
	
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	handleClear = () => {
		this.setState({
			model: { ...this.InitialModel },
			list: [],
		});
	}

	GetExportButtonDialog() {
		return( <GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.handleChange}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
						/>
					</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
		)
	}

	ExportClick = () =>{
		if(!this.IsValid()){
			return;
		}
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);
		console.log(this.trxTable);
		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {	
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "e-Transfer Trace Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClearingSettlement/ETransferTraceTransactionExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								{this.GetExportButtonDialog()}
								<GridItem xs={3}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear />
									<GenericSelectInput
										Name="TransactionTypeId"
										LabelText="Transaction Type"
										Value={model === undefined ? "" : model.TransactionTypeId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From"
										Value={model == null || undefined ? "" : model.FromDate ? DateHelper.ToDateInputValueAsUtc(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate || DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="ToDate"
										LabelText="To"
										Value={model == null || undefined ? "" : model.ToDate ? DateHelper.ToDateInputValueAsUtc(model.ToDate) : ""}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate == null ? null : model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false}
										Utc />

								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="ApplicationId"
										LabelText="Application Type"
										Value={model === undefined ? "" : model.ApplicationId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Value"
										TextValueMember="Text"
										IsStatic
										StaticData={EtransferApplicationTypes || []}
									/>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Trace Transactions List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									ServerSide
									RequestUrl= "/bankapi/v1.0/ClearingSettlement/GetETransferTraceTransactionsPaged"
									RequestMethod="POST"
									PrepareRequest={() => { return model; }}
									ValidateRequest={this.IsValid}
									ShowPagination={true}
									LoadAtStartup={this.state.loadingCompleted}
									PageSize={20}
									Columns={this.columns}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eTransferTraceTransactions.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eTransferTraceTransactions, {});