import { Close, Lock, LockOpen, Search } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import { GenericLabel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TreeComponent from "views/Components/TreeComponent";

const userAuthStyle = {
	pLeft0: {
		paddingLeft: "0px !important"
	},
	pRight0: {
		paddingRight: "0px !important"
	}
}

class UserAuthorityDefinitionTreeAndDataTable extends React.Component {
	constructor(props) {
		super(props);

		this.styleMarginBottom15 = { marginBottom: "15px" };
		this.styleMarginTop5 = { marginTop: 8 };
		this.styleMarginTop_10 = { marginTop: -10 };

		this.state = {
			model: props.model,
			isLoading: false,
		};
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	GridCellClicked = (row) => {
		this.setState({ isLoading: true });

		var { model } = this.state;

		if (row.value === "VM") {

			var vd = model.viewDeniedIcons[row.index];
			vd.icon = vd.name === "search" ? <Close /> : <Search />;
			vd.name = vd.name === "search" ? "close" : "search";

			var oc = model.openCloseIcons[row.index];
			oc.icon = vd.name === "search" ? <LockOpen /> : <Lock />;
			oc.name = vd.name === "search" ? "lockOpen" : "lock";

			this.setState({ model, isLoading: false });

			return;
		}

		this.setState({ isLoading: false });
	}

	GetAuthories() {
		const { model } = this.state;

		var menuRightList = [];
		for (var i in model.openCloseIcons) {
			menuRightList.push({
				MenuId: model.openCloseIcons[i].menuId,
				OpenOrClose: model.openCloseIcons[i].name,
				ViewOrDenied: model.viewDeniedIcons[i].name
			});
		}

		return {
			MenuRightList: menuRightList,
			SelectedRoleLevelId: model.selectedRoleLevelId,
			SelectedRoleId: model.selectedRoleId
		};
	}

	GetReactTableStyle() {
		return { style: { maxHeight: "unset" } };
	}

	GetRowGroupProps() {
		return { style: { height: 31, borderBottom: "1px solid #dedede" } };
	}

	GetCellProps() {
		return { style: { paddingLeft: 0, paddingRight: 0 } };
	}

	render() {
		const { model, isLoading } = this.state;
		const { classes } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<GridContainer spacing={16}>
					<GridItem xs={4} className={classes.pRight0}>
						<div style={this.styleMarginBottom15}>
							<GenericLabel
								Bold
								Fontsize="12px"
								Textcolor="black"
								Text="Menu Tree" />
						</div>
						<TreeComponent data={model.subMenus} />
					</GridItem>
					<GridItem xs={3} className={classes.pLeft0}>
						<ReactTable
							style={this.styleMarginTop5}
							data={model.list}
							filterable={false}
							sortable={false}
							resizable={false}
							showPagination={false}
							defaultPageSize={model.list.length}
							getTableProps={this.GetReactTableStyle}
							getTrGroupProps={this.GetRowGroupProps}
							getTdProps={this.GetCellProps}
							columns={[
								{
									Header: "Open/Close",
									accessor: "OC",
									Cell: row => (
										<div style={this.styleMarginTop_10}>
											<Button
												justIcon
												disabled
												color='white'
												size="sm"
												tooltip="Open/Close" >
												{model.openCloseIcons[row.index].icon}
											</Button>
										</div>
									)
								},
								{
									Header: "Is Viewable",
									accessor: "VM",
									Cell: row => (
										<div style={this.styleMarginTop_10}>
											<Button
												justIcon
												color='white'
												size="sm"
												tooltip="View/Denied"
												onClick={() => this.GridCellClicked(row)} >
												{model.viewDeniedIcons[row.index].icon}
											</Button>
										</div>
									)
								}
							]} />
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}
}

UserAuthorityDefinitionTreeAndDataTable.propTypes = {
	model: PropTypes.object,
	onRef: PropTypes.func
};

export default withArtifex(UserAuthorityDefinitionTreeAndDataTable, userAuthStyle);