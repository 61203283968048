import React from "react";
import { withStyles } from "@material-ui/core";
import genericMapSearchboxStyle from "assets/jss/generic/genericMapSearchboxStyle.jsx";
const { compose, withProps } = require("recompose");
const { withScriptjs } = require("react-google-maps");
const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const loadingPlaceholder = <div style={{ height: "100%" }} />;
const containerPlaceholder = <div style={{ height: "400px" }} />;

const GenericMapSearchboxHoc =
	compose(
		React.memo,
		withScriptjs
	)((props) => {

		const { MapSearchboxRef, inputProps, classes, ...otherProps } = props;

		return (<div data-standalone-searchbox="">
			<StandaloneSearchBox
				ref={MapSearchboxRef}
				{...otherProps}
			>
				<input
					type="text"
					placeholder="Search ATM Location"
					className={classes.input}
					{...inputProps}
				/>
			</StandaloneSearchBox>
		</div>);
	});


const GenericMapSearchbox = (props) => {

	const { ApiKey  } = props;
	return (
		<GenericMapSearchboxHoc
			googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${ApiKey}&v=3.exp&libraries=geometry,drawing,places`}
			loadingElement={loadingPlaceholder}
			containerElement={containerPlaceholder}
			key={props.ApiKey}
			{...props}
		/>);
};
export default withStyles(genericMapSearchboxStyle)(GenericMapSearchbox);
