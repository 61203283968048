// @ts-nocheck
import { DialogContent, DialogTitle } from "@material-ui/core";
import { blackColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import Tooltip from "react-tooltip-lite";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericNumberInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class MastercardClearingDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1,
			isLoading: false,
			PdsData: [],
			ChipData: [],
			isPdsDialogOpen: false,
			isChipDialogOpen: false
		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };
		this.ColumnsPds = [
			{
				Header: "Pds Tag",
				accessor: "PdsTag"
			},
			{
				Header: "Pds Value",
				accessor: "PdsValue"
			},
		];


		this.ColumnsIpt = [
			{
				Header: "Data Tag",
				accessor: "DataTag"
			},
			{
				Header: "Data Value",
				accessor: "DataValue"
			},
		];
	}

	componentDidMount() {
		if (this.props.KnownCustomer)
			this.HandleClickByKeyPressed();
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}



	HandleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.HandleClickByKeyPressed();
		}
	}

	HandleClickByKeyPressed = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/ClientCustomer/SearchForComponent",
			{
				CustomerNumber: this.props.CustomerNumber,
				Programs: this.props.Programs,
				UniqueClientId: this.props.UniqueClientId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
					if (responseData.Item.length === 1) {
						this.HandleSelectModel(responseData.Item[0], 0);
					}
				} else {
					this.setState({ dialogData: [] });
				}

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleClick = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/ClientCustomer/SearchForComponent",
			{
				CustomerNumber: this.props.CustomerNumber,
				Programs: this.props.Programs,
				UniqueClientId: this.props.UniqueClientId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleClearClick = () => {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			model: {},
			selected: -1
		}, () => {
			this.props.FillCallback(null);
		});
	}


	HandleSelectModel = (model, index) => {
		this.setState({ selected: index });
		this.props.FillCallback(model); this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1
		});
	};

	HandleChip = () => {
		this.setState({ isLoading: true, isChipDialogOpen: true });
		Proxy.POST("/prepaidapi/v1.0/MastercardClearing/GetAllMastercardChipData",
			{
				Id: this.props.model.Id,
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						ChipData: responseData.Item, isLoading: false
					});

				} else {
					this.setState({ ChipData: [], isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});

	}
	HandlePds = () => {

		this.setState({ isLoading: true, isPdsDialogOpen: true });
		Proxy.POST("/prepaidapi/v1.0/MastercardClearing/GetAllMastercardPdsData",
			{
				Id: this.props.model.Id,
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						PdsData: responseData.Item, isLoading: false
					});
				} else {
					this.setState({ PdsData: [], isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	RowSelected = (index) => {
		const model = this.state.dialog.dialogData[index];
		this.HandleSelectModel(model, index);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	CloseSelectCallback = () => {
		this.setState({ isChipDialogOpen: false, isPdsDialogOpen: false });
	}

	render() {
		const { model } = this.props;
		const { isLoading,  ChipData, PdsData } = this.state;

		return (
			<>
				<LoadingComponent Show={isLoading} />

				<GenericDialog open={this.state.isPdsDialogOpen} onBackdropClick={this.CloseSelectCallback} maxWidth="md" fullWidth keepMounted={true}>
					<DialogTitle><GenericLabel FontSize="16px" Text="Pds Data" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<GenericGrid
							Data={PdsData}
							Columns={this.ColumnsPds}
						// RowSelected={this.RowSelected}
						// SelectedIndex={index}
						/>

					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CloseSelectCallback}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={this.state.isChipDialogOpen} onBackdropClick={this.CloseSelectCallback} maxWidth="md" fullWidth keepMounted={true} >
					<DialogTitle><GenericLabel FontSize="16px" Text="Chip Data" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<GenericGrid
							Data={ChipData}
							Columns={this.ColumnsIpt}
						// RowSelected={this.RowSelected}
						// SelectedIndex={index}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CloseSelectCallback}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GridContainer>
					<GridItem xs={4}>
						{/* <Tooltip content="Transaction Amount" styles={{ zIndex: 10 }} background={blackColor} color={"white"}> */}
						<GenericTextInput
							Name="Mti"
							LabelText="Mti"
							Value={model.Mti}
							inputProps={this.maxLength4}
							ValueChanged={this.HandleChange}
						/>
						{/* </Tooltip> */}


						<Tooltip content="Primary Wallet Number (PAN)" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De002"
								LabelText="De002"
								Value={model.De002}
								inputProps={this.maxLength19}
								ValueChanged={this.HandleChange} />
						</Tooltip>



						<Tooltip content="Processing Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De003"
								LabelText="De003"
								Value={model.De003}
								inputProps={this.maxLength6}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Transaction Amount" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericNumberInput
								Name="De004"
								LabelText="De004"
								Value={model.De004}
								inputProps={this.maxLength17}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Reconciliation Amount" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericNumberInput
								Name="De005"
								LabelText="De005"
								Value={model.De005}
								inputProps={this.maxLength17}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Cardholder Billing Amount" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De014"
								LabelText="De014"
								Value={model.De014}
								inputProps={this.maxLength4}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Sequence Number" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De023"
								LabelText="De023"
								Value={model.De023}
								inputProps={this.maxLength3}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Function Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De024"
								LabelText="De024"
								Value={model.De024}
								inputProps={this.maxLength3}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Message Reason Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De025"
								LabelText="De025"
								Value={model.De025}
								inputProps={this.maxLength4}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor Business Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De026"
								LabelText="De026"
								Value={model.De026}
								inputProps={this.maxLength4}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor Business Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericNumberInput
								Name="De030_1"
								LabelText="De030 S-1"
								Value={model.De030_1}
								inputProps={this.maxLength17}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Original Reconciliation Amount" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericNumberInput
								Name="De030_2"
								LabelText="De030 S-2"
								Value={model.De030_2}
								inputProps={this.maxLength17}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Mixed Use" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericNumberInput
								tooltipProps={{ content: "Mixed Use" }}
								Name="De031_1"
								LabelText="De031 S-1"
								Value={model.De031_1}
								inputProps={this.maxLength1}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Acquirer’s BIN" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De031_2"
								LabelText="De031 S-2"
								Value={model.De031_2}
								inputProps={this.maxLength6}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Julian Processing Date" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De031_3"
								LabelText="De031 S-3"
								Value={model.De031_3}
								inputProps={this.maxLength4}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Acquirer’s Sequence Number" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De031_4"
								LabelText="De031 S-4"
								Value={model.De031_4}
								inputProps={this.maxLength11}
								ValueChanged={this.HandleChange} />
						</Tooltip>


						<Tooltip content="Check Digit Numeric" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								tooltipProps={{ content: "Check Digit Numeric" }}
								Name="De031_5"
								LabelText="De031 S-5"
								Value={model.De031_5}
								inputProps={this.maxLength1}
								ValueChanged={this.HandleChange} />
						</Tooltip>

					</GridItem>
					<GridItem xs={4}>
						<Tooltip content="Acquiring Institution ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De032"
								LabelText="De032"
								Value={model.De032}
								inputProps={this.maxLength11}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Forwarding Institution ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De033"
								LabelText="De033"
								Value={model.De033}
								inputProps={this.maxLength11}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Retrieval Reference Number" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								tooltipProps={{ content: "Retrieval Reference Number" }}
								Name="De037"
								LabelText="De037"
								Value={model.De037}
								inputProps={this.maxLength12}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Approval Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								tooltipProps={{ content: "Approval Code" }}
								Name="De038"
								LabelText="De038"
								Value={model.De038}
								inputProps={this.maxLength6}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor Terminal ID" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De041"
								LabelText="De041"
								Value={model.De041}
								inputProps={this.maxLength8}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De042"
								LabelText="De042"
								Value={model.De042}
								inputProps={this.maxLength17}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De043_1"
								LabelText="De043 S-1"
								Value={model.De043_1}
								inputProps={this.maxLength22}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor Street Address" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De043_2"
								LabelText="De043 S-2"
								Value={model.De043_2}
								inputProps={this.maxLength45}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor City" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De043_3"
								LabelText="De043 S-3"
								Value={model.De043_3}
								inputProps={this.maxLength13}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor Postal (ZIP) Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De043_4"
								LabelText="De043 S-4"
								Value={model.De043_4}
								inputProps={this.maxLength10}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Acceptor State, Province, or Region Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De043_5"
								LabelText="De043 S-5"
								Value={model.De043_5}
								inputProps={this.maxLength3}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<GridContainer >
							<GridItem xs={8}>
								<GenericLabel style={{ marginTop: 10 }}
									Text="Additional Data" />
							</GridItem>
							<GridItem xs={4}>
								<Button size="sm" onClick={this.HandlePds}>Details</Button>
							</GridItem>
						</GridContainer>

						<Tooltip content="Transaction Currency Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De049"
								LabelText="De049"
								Value={model.De049}
								inputProps={this.maxLength3}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Reconciliation Currency Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De050"
								LabelText="De050"
								Value={model.De050}
								inputProps={this.maxLength3}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<GridContainer >
							<GridItem xs={8}>
								<Tooltip content="Integrated Circuit Card (ICC) System-Related Data" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
									<GenericTextInput
										Name="De055"
										LabelMd={6}
										LabelText="De055"
										Value={model.De055}
										inputProps={this.maxLength256}
										ValueChanged={this.HandleChange} />
								</Tooltip>
							</GridItem>
							<GridItem xs={4}>
								<Button size="sm" onClick={this.HandleChip}>Details</Button>
							</GridItem>
						</GridContainer>
						<Tooltip content="Transaction Life Cycle ID" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De063"
								LabelText="De063"
								Value={model.De063}
								inputProps={this.maxLength16}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Message Number" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De071"
								LabelText="De071"
								Value={model.De071}
								inputProps={this.maxLength8}
								ValueChanged={this.HandleChange} />
						</Tooltip>

					</GridItem>
					<GridItem xs={4}>

						<Tooltip content="Data Record" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De072"
								LabelText="De072"
								Value={model.De072}
								inputProps={this.maxLength999}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Action Date" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De073"
								LabelText="De073"
								Value={model.De073}
								inputProps={this.maxLength6}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Transaction Destination Institution ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De093"
								LabelText="De093"
								Value={model.De093}
								inputProps={this.maxLength11}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Transaction Originator Institution ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De094"
								LabelText="De094"
								Value={model.De094}
								inputProps={this.maxLength15}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Card Issuer Reference Data" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De095"
								LabelText="De095"
								Value={model.De095}
								inputProps={this.maxLength10}
								ValueChanged={this.HandleChange} />
						</Tooltip>

						<Tooltip content="Receiving Institution ID Code" styles={{ zIndex: 10 }} background={blackColor} color={"white"}>
							<GenericTextInput
								Name="De100"
								LabelText="De100"
								Value={model.De100}
								inputProps={this.maxLength11}
								ValueChanged={this.HandleChange} />
						</Tooltip>


						<ParameterComponent
							Name="DirectionId"
							LabelText="Direction"
							ParameterCode="Direction"
							Value={model.DirectionId}
							ValueChanged={this.HandleChange}
							Disabled={true}
						/>
						<ParameterComponent
							Name="ProcessStatusId"
							LabelText="Process Status"
							ParameterCode="ProcessStatus"
							Value={model.ProcessStatusId}
							ValueChanged={this.HandleChange}
						/>
						<ParameterComponent
							Name="EodStatusId"
							LabelText="Eod Status"
							ParameterCode="EodStatus"
							Value={model.EodStatusId}
							ValueChanged={this.HandleChange}
						/>
						<ParameterComponent
							Name="ClearingStatusId"
							LabelText="Clearing Status"
							ParameterCode="ClearingStatus"
							Value={model.ClearingStatusId}
							ValueChanged={this.HandleChange}
						/>
						<ParameterComponent
							Name="ValidationStatusId"
							LabelText="Validation Status"
							ParameterCode="ValidationStatus"
							Value={model.ValidationStatusId}
							Disabled={true}
							ValueChanged={this.HandleChange}
						/>
						<ParameterComponent
							Name="IssuerAcquirerFlagId"
							LabelText="Issuer Acquirer Flag"
							ParameterCode="IssuerAcquirerFlag"
							Value={model.IssuerAcquirerFlagId}
							ValueChanged={this.HandleChange}
							Disabled={false}
						/>
						<GenericTextInput
							Name="ValidationDescription"
							LabelText="Validation Description"
							Value={model.ValidationDescription}
							ValueChanged={this.HandleChange} />

						<GenericDateInput
							Name="EodDate"
							LabelText="Eod Date"
							Value={model.EodDate}
							Disabled={true}
							ValueChanged={this.HandleChange} />

						<GenericDateInput
							Name="ClearingDate"
							LabelText="Clearing Date"
							Value={model.ClearingDate}
							Disabled={true}
							ValueChanged={this.HandleChange} />
						<GenericCheckInput
							Name="IsReversal"
							LabelText="Is Reversal"
							Value={model.IsReversal}
							Disabled={true}
							ValueChanged={this.HandleChange}
						/>

					</GridItem>

				</GridContainer>


			</>
		);
	}
}

MastercardClearingDetail.propTypes = {
	initialModel: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool,
	IsDialogOpen: PropTypes.any,
	classes: PropTypes.object.isRequired,
};

export default withArtifex(MastercardClearingDetail, {});