const genericLabelStyle = {
	label: {
		paddingLeft: "0",
		color: "#888888",
		fontSize: "16px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex",
		transition: "0.3s ease all"
	}
};

export default genericLabelStyle;