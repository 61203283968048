import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTextInput, GenericSelectInput, GenericDateInput, GenericNumberInput, GenericCheckInput, GenericPage } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, CardTransferTypes, GridColumnType, EFTTransactionTypes } from "views/Constants/Constant";
import StringHelper from "core/StringHelper.js";
import ParameterComponent from 'views/Components/ParameterComponent';
class MastercardCreateCardTransfer extends GenericPage {

	constructor(props) {
		super(props);
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.defaultClientModel = {
			IsCorporate: false,
			LegalName: "",
			Name: "",
			MiddleName: "",
			LastName: "",
			AddressLine1: "",
			AddressLine2: "",
			ZipCode: "",
			CityName: "",
			ProvinceName: "",
			CountryName: ""
		};

		this.defaultReceiverModel = {
			IsCorporate: true,
			RecipientCompanyName: "",
			Name: "",
			MiddleName: "",
			SurName: "",
			AddressLine1: "",
			AddressLine2: "",
			ZipCode: "",
			CityName: "",
			ProvinceName: "",
			CountryName: "",
			CountryCode: "",
			Email: "",
			BirthDate: null
		};

		this.marginLeft2 = { marginLeft: 2 };

		this.Columns = [
			{
				Header: "Customer Name",
				accessor: "ClientCustomerName",
			},
			{
				Header: "Source Wallet",
				accessor: "SourceAccount",
			},
			{
				Header: "Destination Wallet",
				accessor: "DestinationAccount",
			},
			{
				Header: "Currency",
				accessor: "Currency",
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			// {
			// 	Header: "Transfer Reason",
			// 	accessor: "TransferReason",
			// },
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			// {
			// 	Header: "Status",
			// 	accessor: "Status",
			// },
			// {
			// 	Header: "Error Code",
			// 	accessor: "ErrorCode",
			// }
			{
				Header: "State",
				accessor: "TransactionState"
			}
		];

		this.uniqueClientId = ClientHelper.GetClientRowId();
		this.EmptyObj = {};

		this.MaxLen = { maxLength: 30 };
		this.MaxLen35 = { maxLength: 35 };
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.SortedCountry = { Member: "OrderIndex" };
		this.SortedCityProvince = { Member: "ParameterDesc" };

		this.defaultModel = {
			// Detail
			DateRequest: DateHelper.GetDateTime(),
			ValueEffective: DateHelper.GetDateTime(),
			TotalAmount: 0,
			Amount: 0,
			CurrencyId: null,
			PurposeOfWire: "",
			AccountId: null,
			HasSubSender: false,
			FileId: null,
			SendEmailAddress: "",
			IsSendEmailAddressValid: false,
			AmountPrefix: "$"
		};

		this.defaultStateModel = {
			IsClearDialogOpen: false,
			SaveConfirmDialog: false,
			IsSubmitTemplate: false,
			FileResultDialog: false,
			Alert: null,
			IsMsbClient: false,

		};

		this.defaultFileModel = {
			File: null,
			FileName: ""
		};


		this.state = {
			isFileDialogOpen: false,
			vModel: {},
			Model: {},
			trxResponse: {},
			cardList: [],
			isLoading: false,
			ClientInfo: { ...this.defaultClientModel },
			ReceiverInfo: { ...this.defaultReceiverModel },

			StateModel: { ...this.defaultStateModel },
			Url: null,
			CurrencyCode: null,
			CountryCode: null,
			isB2P: false,
			OriginalAmount: null,
			BidRate: 0
		};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Create Cross Border Transfer");
		if (setAppCenterTitle)
			setAppCenterTitle("CARD TRANSFER");


		this.LoadTemplateList(() => {
			this.LoadClientInfo(this.LoadCustomerContract);
		});
		this.FetchCanadaPost();

		this.setState({ loadingCompleted: true });
	}

	RenderCountry(d) {
		return d.ISO2 + " - " + d.Name;
	}

	FetchCanadaPost() {
		var parent = this;

		this.ExecutePostRequest("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			},
			responseData => {
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);
				var FillBankAddressFields = this.FillBankAddressFields;
				var FillBeneficiaryAddressFields = this.FillBeneficiaryAddressFields;
				// @ts-ignore
				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;
				var CanadaPostRequest = this.SubmitCanadaPostRequest;

				var pca;
				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					// @ts-ignore
					pca = window.pca;
					if (pca != null) {
						var fields = [
							{ element: "bank-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "bank-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "bank-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];
						var options = {
							key: apikey
						};
						var control = new pca.Address(fields, options);

						control.listen("populate", function (address) {
							FillBankAddressFields(address);
						});

						var fields2 = [
							{ element: "beneficiary-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "beneficiary-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "beneficiary-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];
						CanadaPostRequest();

						var control2 = new pca.Address(fields2, options);

						control2.listen("populate", function (address) {
							FillBeneficiaryAddressFields(address);
						});
					}
				};
			});
	}

	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = {

			UniqueClientId: null,
			MenuCode: this.props.MenuCode,
			AppCenterTitle: this.getappCenterTitle,
			AppLeftTitle: this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null) {

				}
			},
		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}

	FillBankAddressFields(model, continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireCountry/CanadaPost",
			model,
			responseData => {
				const { Template } = this.state;
				if (responseData.Item != null) {
					Template.BankCountryId = responseData.Item.CountryId;
					Template.BankProvinceId = responseData.Item.ProvinceId;
					Template.BankCityId = responseData.Item.CityId;
					Template.IsDomestic = model.CountryIso2 == "CA";
				} else {
					Template.BankCountryId = 0;
					Template.BankProvinceId = 0;
					Template.BankCityId = 0;
				}
				Template.BankAddressLine1 = model.Line1;
				Template.BankAddressLine2 = model.Line2;
				Template.BankZipCode = model.PostalCode;
				this.setState({ Template }, continueWith);
			}
		);
	}

	FillBeneficiaryAddressFields(model, continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireCountry/CanadaPost",
			model,
			responseData => {
				const { Template } = this.state;
				if (responseData.Item != null) {
					Template.CountryId = responseData.Item.CountryId;
					Template.ProvinceId = responseData.Item.ProvinceId;
					Template.CityId = responseData.Item.CityId;
				} else {
					Template.CountryId = 0;
					Template.ProvinceId = 0;
					Template.CityId = 0;
				}
				Template.AddressLine1 = model.Line1;
				Template.AddressLine2 = model.Line2;
				Template.ZipCode = model.PostalCode;
				this.setState({ Template }, continueWith);
			}
		);
	}

	LoadTemplateList(continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/Search", {},
			(responseData) => {
				this.setState({ TemplateList: responseData.Item || [] }, continueWith);
			});
	}

	LoadClientInfo(continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireTransferSender/GetSenderInfo", {},
			(responseData) => {
				this.setState({ ClientInfo: responseData.Item }, continueWith);
			});
	}

	LoadReceiverInfo(clientId) {
		this.ExecutePostRequest("/bankapi/v1.0/MastercardXBorder/GetClientById", clientId,
			(responseData) => {
				this.setState({ ReceiverInfo: responseData.Item });
			});
	}

	LoadCustomerContract(continueWith = null) {
		this.ExecuteGetRequest("/bankapi/v1.0/CustomerContract/GetByCustomerId/Id?Id=",
			(responseData) => {
				const { StateModel } = this.state;
				var isSmbClient = false;
				if (responseData.Item != null) {
					responseData.Item.forEach(element => {
						if (element.Program != null && element.Program.ProgramCode == ProgramCodes.Bank_Programs_Bank_Account) {
							isSmbClient = element.IsMsbClient == true;
						}
					});
				}
				StateModel.IsMsbClient = isSmbClient;
				this.setState({ StateModel }, continueWith);
			});
	}

	ValueChanged = (name, newValue, data) => {
		const { Model, ReceiverInfo } = this.state;
		Model[name] = newValue;

		if (name == "TransactionType" && data?.ParameterValue == EFTTransactionTypes.Debit) {
			this.setState({ Url: "/bankapi/v1.0/MastercardXBorder/CreatePaymentReverse" });
		}
		if (name == "TransactionType" && data?.ParameterValue == EFTTransactionTypes.Credit) {
			this.setState({ Url: "/bankapi/v1.0/MastercardXBorder/CreatePaymentForward" });
		}
		if (name == "PaymentTransferTypeId" && data?.ParameterValue == "B2P") {
			this.setState({ isB2P: true });
		}
		if (name == "PaymentTransferTypeId" && data?.ParameterValue == "B2B") {
			this.setState({ isB2P: false });
		}
		if (name == "CustomerNumber") {
			this.LoadReceiverInfo(data.Id);
		}
		if (name == "CurrencyId") {
			this.setState({ CountryCode: data.Description.substring(0, 3), BidRate: data.RateTierBidRate });
		}

		if (name == "Amount") {
			var originalAmount = newValue * this.state.BidRate;
			Model["OriginalAmount"] = originalAmount;
		}

		this.setState({ Model });
	}

	handleSearch = async () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	HandleSubmit = async () => {
		const Model = { ...this.state.Model };
		const url = { ...this.state.Url };

		this.setState({ IsLoading: true });

		Proxy.POST(this.state.Url,
			Model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData != null) {
					this.handleSearch();
					this.setState({ IsLoading: false, isFileDialogOpen: false });
					this.Clear();
				}
				this.ShowGenericMessage("success", "Success", "Operation was successfuly !!!");
			},
			error => {
				this.ShowGenericMessage("error", "Error", error);
			});
	}

	GetRenderItemAccount = (x) => {
		this.renderItemAccount = `${x.AccountNumber} - ${x.AccountName}`;
		return this.renderItemAccount;
	}

	Validate = () => {
		const { Model, vModel, ReceiverInfo, CurrencyCode, CountryCode, ClientInfo, isB2P } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (Model.CurrencyId == null || Model.CurrencyId == "") {
			vModel.CurrencyId = true;
			errors.push("Currency must be defined !");
		} else { vModel.CurrencyId = false; }

		if (Model.Amount == null || Model.Amount == "") {
			vModel.Amount = true;
			errors.push("Amount must be defined !");
		} else { vModel.Amount = false; }

		if (Model.TransactionType == null || Model.TransactionType == "") {
			vModel.TransactionType = true;
			errors.push("Transaction Type must be defined !");
		} else { vModel.TransactionType = false; }

		if (Model.PaymentTransferTypeId == null || Model.PaymentTransferTypeId == "") {
			vModel.PaymentTransferTypeId = true;
			errors.push("Payment Transfer Type must be defined !");
		} else { vModel.PaymentTransferTypeId = false; }

		if (Model.AccountId == null || Model.AccountId == "") {
			vModel.AccountId = true;
			errors.push("From Wallet must be defined !");
		} else { vModel.AccountId = false; }

		if (Model.CustomerNumber == null || Model.CustomerNumber == "") {
			vModel.CustomerNumber = true;
			errors.push("Customer must be defined !");
		} else { vModel.CustomerNumber = false; }

		if ((Model.ReceivingBankName == null || Model.ReceivingBankName == "") && ((CountryCode == "KOR") || (CountryCode == "USA"))) {
			vModel.ReceivingBankName = true;
			errors.push("Receiving Bank Name must be defined !");
		} else { vModel.ReceivingBankName = false; }

		if ((Model.BankCode == null || Model.BankCode == "") && (CountryCode == "LKA")) {
			vModel.BankCode = true;
			errors.push("Bank Code must be defined !");
		} else { vModel.BankCode = false; }

		if (ClientInfo.AddressLine1 == null || ClientInfo.AddressLine1 == "") {
			vModel.AddressLine1 = true;
			errors.push("Sender Address Line 1 must be defined !");
		} else { vModel.AddressLine1 = false; }

		if ((ClientInfo.CityName == null || ClientInfo.CityName == "") && (CountryCode == "PHL")) {
			vModel.CityName = true;
			errors.push("Sender City must be defined !");
		} else { vModel.CityName = false; }

		if ((ClientInfo.ProvinceName == null || ClientInfo.ProvinceName == "") && (CountryCode == "PHL")) {
			vModel.ProvinceName = true;
			errors.push("Sender Province must be defined !");
		} else { vModel.ProvinceName = false; }

		if (ClientInfo.CountryName == null || ClientInfo.CountryName == "") {
			vModel.CountryName = true;
			errors.push("Sender Country must be defined !");
		} else { vModel.CountryName = false; }

		if ((ReceiverInfo.RecipientCompanyName == null || ReceiverInfo.RecipientCompanyName == "") && !isB2P) {
			vModel.RecipientCompanyName = true;
			errors.push("Recipient Company Name must be defined !");
		} else { vModel.RecipientCompanyName = false; }

		if ((ReceiverInfo.Name == null || ReceiverInfo.Name == "") && isB2P) {
			vModel.Name = true;
			errors.push("Recipient Name must be defined !");
		} else { vModel.Name = false; }

		if ((ReceiverInfo.SurName == null || ReceiverInfo.SurName == "") && isB2P) {
			vModel.SurName = true;
			errors.push("Recipient LastName must be defined !");
		} else { vModel.SurName = false; }

		if (ReceiverInfo.AddressLine1 == null || ReceiverInfo.AddressLine1 == "") {
			vModel.ReceiverAddressLine1 = true;
			errors.push("Recipient Address Line 1 must be defined !");
		} else { vModel.ReceiverAddressLine1 = false; }

		if ((ReceiverInfo.CityName == null || ReceiverInfo.CityName == "") && (CountryCode == "USA")) {
			vModel.ReceiverCityName = true;
			errors.push("Recipient City must be defined !");
		} else { vModel.ReceiverCityName = false; }

		if ((ReceiverInfo.ZipCode == null || ReceiverInfo.ZipCode == "") && (CountryCode == "USA")) {
			vModel.ReceiverZipCode = true;
			errors.push("Recipient Zip Code must be defined !");
		} else { vModel.ReceiverZipCode = false; }

		if ((ReceiverInfo.ProvinceName == null || ReceiverInfo.ProvinceName == "") && (CountryCode == "USA")) {
			vModel.ReceiverProvinceName = true;
			errors.push("Recipient Province must be defined !");
		} else { vModel.ReceiverProvinceName = false; }

		if (ReceiverInfo.CountryName == null || ReceiverInfo.CountryName == "") {
			vModel.ReceiverCountryName = true;
			errors.push("Recipient Country must be defined !");
		} else { vModel.ReceiverCountryName = false; }

		if ((Model.SourceOfIncome == null || Model.SourceOfIncome == "") && (CountryCode == "KOR")) {
			vModel.SourceOfIncome = true;
			errors.push("Source of Income must be defined !");
		} else { vModel.SourceOfIncome = false; }

		if ((Model.PurposeOfTransaction == null || Model.PurposeOfTransaction == "") && ((CountryCode == "IND") || (CountryCode == "KOR"))) {
			vModel.PurposeOfTransaction = true;
			errors.push("Purpose of Transaction must be defined !");
		} else { vModel.PurposeOfTransaction = false; }

		if ((Model.PayerPayeeRelationship == null || Model.PayerPayeeRelationship == "") && (CountryCode == "KOR")) {
			vModel.PayerPayeeRelationship = true;
			errors.push("Payer Payee Relationship must be defined !");
		} else { vModel.PayerPayeeRelationship = false; }

		if ((Model.RecipientBankAccountType == null || Model.RecipientBankAccountType == "") && (CountryCode == "USA")) {
			vModel.RecipientBankAccountType = true;
			errors.push("Recipient Payment Wallet Type must be defined !");
		} else { vModel.RecipientBankAccountType = false; }

		if ((Model.AccountNumber == null || Model.AccountNumber == "") && (CountryCode == "LKA" || CountryCode == "IND" || CountryCode == "KOR" ||
			CountryCode == "USA" || CountryCode == "PHL")) {
			vModel.AccountNumber = true;
			errors.push("Wallet Number must be defined !");
		} else { vModel.AccountNumber = false; }

		if ((Model.BankSwiftCode == null || Model.BankSwiftCode == "") && (CountryCode == "LKA" || CountryCode == "IND" || CountryCode == "KOR" ||
			CountryCode == "USA" || CountryCode == "PHL")) {
			vModel.BankSwiftCode = true;
			errors.push("Bank Swift Code must be defined !");
		} else { vModel.BankSwiftCode = false; }

		if ((Model.BankInstitution == "" || Model.BankInstitution == null) && ((CountryCode == "AUT" || CountryCode == "BEL" || CountryCode == "CYP" || CountryCode == "EST" || CountryCode == "FIN" || CountryCode == "FRA" ||
			CountryCode == "DEU" || CountryCode == "GRC" || CountryCode == "IRL" || CountryCode == "ITA" || CountryCode == "LVA" || CountryCode == "LTU" || CountryCode == "LUX" ||
			CountryCode == "MLT" || CountryCode == "NLD" || CountryCode == "PRT" || CountryCode == "SVK" || CountryCode == "SVN" || CountryCode == "ESP" || CountryCode == "MCO" ||
			CountryCode == "SMR" ||
			CountryCode == "CZE" || CountryCode == "POL" || CountryCode == "GBR"))) {
			vModel.BankInstitution = true;
			errors.push("IBAN must be defined !");
		} else { vModel.BankInstitution = false; }

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}
		this.setState({ isFileDialogOpen: true });
		return true;
	}

	CloseFile = () => {
		this.setState({ isFileDialogOpen: false });
	}

	RenderCurrencySelect = (d) => {
		return d.Code + " - " + d.Description + "-" + d.RateTierBidRate;
	}

	RenderTransactionTypeSelect = (d) => {
		return d.ParameterDesc == "Debit" ? "Payment Reverse" : "Payment Forward";
	}

	Clear = async () => {
		const { Model, vModel, ReceiverInfo } = this.state;
		this.setState({ Model: {}, vModel: {}, isFileDialogOpen: false, ReceiverInfo: {} });
	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, Alert, IsLoading, vModel, CountryCode } = this.state;
		const { Model, ClientInfo, ReceiverInfo, isFileDialogOpen } = this.state;
		return (
			<div>
				{Alert}
				<LoadingComponent Show={isLoading || IsLoading} />
				<ButtonToolbar
					ButtonList={[
						{ Code: "Clear", OnClick: this.Clear, Disabled: Disabled },
						{ Code: "Submit", OnClick: this.Validate, Disabled: Disabled },
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}></ButtonToolbar>
				<br />
				<Card>
					<GenericExpansionPanel Title={"Cross Border Transfer Transaction Entry"}>
						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={4}>
									<GridContainer style={{ borderRadius: 5 }}>
										<GridItem xs={12}>
											<h4 style={{ color: "black" }}>
												<b>Sender Information</b>
											</h4>
										</GridItem>
										<GridItem xs={12}>
											<GenericSelectInput
												Name="AccountId"
												LabelText="From Wallet"
												Method="POST"
												Url="/bankapi/v1.0/VisaDirect/GetAllAccountByUniqueClient"
												DataRoot="Item"
												KeyValueMember="AccountId"
												Parameter={{}}
												TextValueMember="AccountNumber"
												RenderItem={this.GetRenderItemAccount}
												Sorted={{ Member: "AccountNumber" }}
												Value={Model.AccountId}
												ValueChanged={this.ValueChanged}
												Required
												IsInvalid={vModel.AccountId}
											/>
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Legal Name Of Company"
												Value={ClientInfo.LegalName}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Name"
												Value={ClientInfo.Name}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Middle Name"
												Value={ClientInfo.MiddleName}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Last Name"
												Value={ClientInfo.LastName}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Address Line 1"
												Value={ClientInfo.AddressLine1}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Address Line 2"
												Value={ClientInfo.AddressLine2}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Postal Code"
												Value={ClientInfo.ZipCode}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="City"
												Value={ClientInfo.CityName}
												Disabled={true} /></GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Province"
												Value={ClientInfo.ProvinceName}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Country"
												Value={ClientInfo.CountryName}
												Disabled={true} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GridItem xs={12}>
										<h4 style={{ color: "black" }}>
											<b>Recipient Information</b>
										</h4>
									</GridItem>
									<GridItem xs={12}>
										<GenericSelectInput
											Name="CustomerNumber"
											LabelText="Customer Name"
											Method="POST"
											Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
											DataRoot="Item"
											Parameter={{ ProgramCode: ProgramCodes.BANK_PROGRAMS_Card_Transfers, UniqueClientId: this.uniqueClientId, CardTransferType: CardTransferTypes.MastercardSend, isCorporate: this.props.isB2P }}
											KeyValueMember="CustomerNumber"
											TextValueMember="Name"
											Value={Model.CustomerNumber == null || undefined ? "" : Model.CustomerNumber}
											ValueChanged={this.ValueChanged}
											Required
											IsInvalid={vModel.CustomerNumber}
										/>
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Recipient Company Name"
											Value={ReceiverInfo.RecipientCompanyName}
											Disabled={true} />
									</GridItem>
									{!ReceiverInfo.IsCorporate &&
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Name"
												Value={ReceiverInfo.Name}
												Disabled={true} />
										</GridItem>
									}
									{!ReceiverInfo.IsCorporate &&
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Middle Name"
												Value={ReceiverInfo.MiddleName}
												Disabled={true} />
										</GridItem>
									}
									{!ReceiverInfo.IsCorporate &&
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Last Name"
												Value={ReceiverInfo.SurName}
												Disabled={true} />
										</GridItem>
									}
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Address Line 1"
											Value={ReceiverInfo.AddressLine1}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Address Line 2"
											Value={ReceiverInfo.AddressLine2}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Postal Code"
											Value={ReceiverInfo.ZipCode}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="City"
											Value={ReceiverInfo.CityName}
											Disabled={true} /></GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Province"
											Value={ReceiverInfo.ProvinceName}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Country"
											Value={ReceiverInfo.CountryName}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Country Code"
											Value={ReceiverInfo.CountryCode}
											Disabled={true} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											LabelText="Email"
											Value={ReceiverInfo.Email}
											Disabled={true} />
									</GridItem>
								</GridItem>
								<GridItem xs={4}>
									<GridItem xs={12}>
										<h4 style={{ color: "black" }}>
											<b>Recipient Bank Information</b>
										</h4>
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="AccountNumber"
											LabelText="Wallet Number"
											Value={Model.AccountNumber == null || undefined ? "" : Model.AccountNumber}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											DisableStateAdornment={true}
											IsInvalid={vModel.AccountNumber}
											Required={CountryCode == "USA" || CountryCode == "PHL" || CountryCode == "LKA" || CountryCode == "INR" || CountryCode == "KOR"}
										/>
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="BankInstitution"
											LabelText="IBAN"
											Value={Model.BankInstitution}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											IsInvalid={vModel.BankInstitution}
											Required={CountryCode != "USA" && CountryCode != "PHL" && CountryCode != "LKA" && CountryCode != "INR" && CountryCode != "KOR"} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="ReceivingBankName"
											LabelText="Receiving Bank Name"
											Value={Model.ReceivingBankName}
											ValueChanged={this.ValueChanged}
											IsInvalid={vModel.ReceivingBankName}
											Required={CountryCode == "USA" || CountryCode == "KOR"} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="BankSwiftCode"
											LabelText="Swift Code"
											Value={Model.BankSwiftCode}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											inputProps={this.MaxLen}
											IsInvalid={vModel.BankSwiftCode}
											Required={CountryCode == "USA" || CountryCode == "PHL" || CountryCode == "LKA" || CountryCode == "INR" || CountryCode == "KOR"} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="BankCode"
											LabelText="Bank Code"
											Value={Model.BankCode}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											inputProps={this.MaxLen}
											IsInvalid={vModel.BankCode}
											Required={CountryCode == "LKA"} />
									</GridItem>
									<GridItem xs={12}>
										<h4 style={{ color: "black" }}>
											<b>Transfer Detail</b>
										</h4>
									</GridItem>
									<GridItem xs={12}>
										<GenericSelectInput
											Name="CurrencyId"
											LabelText="Currency"
											Value={Model.CurrencyId}
											ValueChanged={this.ValueChanged}
											KeyValueMember="Id"
											TextValueMember="Description"
											Method="GET"
											Url="/bankapi/v1.0/VisaDirect/GetCurrencyByClient"
											RenderItem={this.RenderCurrencySelect}
											DataRoot="Item"
											Required
											IsInvalid={vModel.CurrencyId}
										/>
									</GridItem>
									<GridItem xs={12}>
										<ParameterComponent
											Name="PaymentTransferTypeId"
											LabelText="Payment Transfer Type"
											ParameterCode="PaymentTransferType"
											Value={Model.PaymentTransferTypeId}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											ValueChanged={this.ValueChanged}
											Required
											IsInvalid={vModel.PaymentTransferTypeId}
										/>
									</GridItem>
									<GridItem xs={12}>
										<GenericNumberInput
											Name="Amount"
											LabelText="CAD Amount"
											Value={Model.Amount}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											Prefix="$"
											MaxLength={10}
											Required
											IsInvalid={vModel.Amount} />
									</GridItem>
									<GridItem xs={12}>
										<GenericNumberInput
											Name="OriginalAmount"
											LabelText="Original Amount"
											Value={Model.OriginalAmount}
											Disabled={true}
											MaxLength={10}
											IsInvalid={vModel.OriginalAmount} />
									</GridItem>
									<GridItem xs={12}>
										<ParameterComponent
											Name="PurposeOfTransaction"
											LabelText="Purpose Of Transaction"
											ParameterCode="PurposeOfPayment"
											Value={Model.PurposeOfTransaction}
											KeyValueMember="ParameterDesc"
											TextValueMember="ParameterDesc"
											ValueChanged={this.ValueChanged}
											IsInvalid={vModel.PurposeOfTransaction}
											Required={CountryCode == "INR" || CountryCode == "KOR"}
										/>
									</GridItem>
									<GridItem xs={12}>
										<ParameterComponent
											Name="TransactionType"
											LabelText="Transaction Type"
											ParameterCode="TransactionType"
											ParameterValue4="1"
											Value={Model.TransactionType}
											ValueChanged={this.ValueChanged}
											RenderItem={this.RenderTransactionTypeSelect}
											Required
											IsInvalid={vModel.TransactionType}
										/>
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="RecipientBankAccountType"
											LabelText="Recipient Wallet Type"
											Value={Model.RecipientBankAccountType}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											inputProps={this.MaxLen}
											IsInvalid={vModel.RecipientBankAccountType}
											Required={CountryCode == "USA"} />
									</GridItem>
									<GridItem xs={12}>
										<GenericTextInput
											Name="PayerPayeeRelationship"
											LabelText="Payer Payee Relationship"
											Value={Model.PayerPayeeRelationship}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											inputProps={this.MaxLen}
											IsInvalid={vModel.PayerPayeeRelationship}
											Required={CountryCode == "KOR"} />
									</GridItem>
									<GridItem xs={12}>
										<ParameterComponent
											Name="SourceOfIncome"
											LabelText="Source Of Income"
											ParameterCode="SourceOfIncome"
											Value={Model.SourceOfIncome}
											KeyValueMember="ParameterDesc"
											TextValueMember="ParameterDesc"
											ValueChanged={this.ValueChanged}
											IsInvalid={vModel.SourceOfIncome}
											Disabled={Disabled}
											Required={CountryCode == "KOR"}
										/>
									</GridItem>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericSelectInput
							Name="CustomerNumber"
							LabelText="Customer Name"
							Method="POST"
							Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
							DataRoot="Item"
							Parameter={{ ProgramCode: ProgramCodes.BANK_PROGRAMS_Card_Transfers, UniqueClientId: this.uniqueClientId, CardTransferType: this.cardTransferType }}
							KeyValueMember="CustomerNumber"
							TextValueMember="Name"
							Value={Model.CustomerNumber}
							Disabled={true}
						/>
						<ParameterComponent
							Name="PaymentTransferTypeId"
							LabelText="Payment Transfer Type"
							ParameterCode="PaymentTransferType"
							Value={Model.PaymentTransferTypeId}
							KeyValueMember="Id"
							TextValueMember="ParameterDesc"
							Disabled={true}
						/>
						<GenericSelectInput
							Name="CurrencyId"
							LabelText="Currency"
							Value={Model.CurrencyId}
							KeyValueMember="Id"
							TextValueMember="Description"
							Method="GET"
							Url="/bankapi/v1.0/Currency/ValidCurrencies"
							RenderItem={this.RenderCurrencySelect}
							DataRoot="Item"
							Disabled={true}
						/>
						<GenericNumberInput
							Name="Amount"
							LabelText="CAD Amount"
							Value={Model.Amount}
							Prefix="$"
							Disabled={true}
						/>
						<GenericNumberInput
							Name="OriginalAmount"
							LabelText="Original Amount"
							Value={Model.OriginalAmount}
							Disabled={true}
						/>
						<ParameterComponent
							Name="TransactionType"
							LabelText="TransactionType"
							ParameterCode="TransactionType"
							ParameterValue4="1"
							Value={Model.TransactionType}
							RenderItem={this.RenderTransactionTypeSelect}
							Disabled={true}
						/>
						<br />
						<GenericLabel Text="Are you sure you want to enter this transaction ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.HandleSubmit()}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

MastercardCreateCardTransfer.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func
};

export default withArtifex(MastercardCreateCardTransfer, {});