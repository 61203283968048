import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class CardKeyProfile extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			isLoading: false
		}

		this.emptyObject = {};

		this.maxLength49 = { maxLength: 49 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength4 = { maxLength: 4 };
		this.maxLength64 = { maxLength: 64 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "AcKeyUnderLmk",
				accessor: "AcKeyUnderLmk"
			},
			{
				Header: "AcKeyKcv",
				accessor: "AcKeyKcv"
			},
			{
				Header: "EncKeyUnderLmk",
				accessor: "EncKeyUnderLmk"
			},
			{
				Header: "EncKeyKcv",
				accessor: "EncKeyKcv"
			},
			{
				Header: "MacKeyUnderLmk",
				accessor: "MacKeyUnderLmk"
			},
			{
				Header: "MacKeyKcv",
				accessor: "MacKeyKcv"
			},
			{
				Header: "DcvvKeyUnderLmk",
				accessor: "DcvvKeyUnderLmk"
			},
			{
				Header: "DcvvKeyKcv",
				accessor: "DcvvKeyKcv"
			},
			{
				Header: "CvkKeyUnderLmk",
				accessor: "CvkKeyUnderLmk"
			},
			{
				Header: "CvkKeyKcv",
				accessor: "CvkKeyKcv"
			},
			{
				Header: "CavvkKeyUnderLmk",
				accessor: "CavvkKeyUnderLmk"
			},
			{
				Header: "CavvkKeyKcv",
				accessor: "CavvkKeyKcv"
			},
			{
				Header: "ZekUnderLmk",
				accessor: "ZekAcUnderLmk"
			},
			{
				Header: "ZekKcv",
				accessor: "ZekAcKcv"
			},
			{
				Header: "PgkUnderLmk",
				accessor: "PgkUnderLmk"
			},
			{
				Header: "PgkKcv",
				accessor: "PgkKcv"
			},
			{
				Header: "ArpcKeyUnderLmk",
				accessor: "ArpcKeyUnderLmk"
			},
			{
				Header: "ArpcKeyKcv",
				accessor: "ArpcKeyKcv"
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Key Profile");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {}, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardKeyProfile/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: this.state.isEdit ? "Update" : "Insert",
				dataModel: "CardKeyProfile",
				value: this.state.model
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
					this.HandleClear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: "Delete",
				dataModel: "CardKeyProfile",
				value: { Id: this.state.model.Id }
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.props.showMessage("success", "Succeeded", "Deleted successfully!");
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var errorList = [];
		const { showValidationErrors } = this.props;

		vModel.ProfileName = model.ProfileName == null || model.ProfileName == "";
		if (vModel.ProfileName) {
			errorList.push("Profile Name cannot be empty.");
		}

		vModel.DcvvKeyUnderLmk = model.DcvvKeyUnderLmk == null || model.DcvvKeyUnderLmk == "";
		if (vModel.DcvvKeyUnderLmk) {
			errorList.push("DcvvKeyUnderLmk cannot be empty.");
		}

		vModel.DcvvKeyKcv = model.DcvvKeyKcv == null || model.DcvvKeyKcv == "";
		if (!model.DcvvKeyKcv) {
			errorList.push("DcvvKeyKcv cannot be empty.");
		}

		vModel.ProfileName = model.ProfileName == null || model.ProfileName == "";
		if (vModel.CvkKeyUnderLmk) {
			errorList.push("CvkKeyUnderLmk cannot be empty.");
		}

		vModel.CvkKeyKcv = model.CvkKeyKcv == null || model.CvkKeyKcv == "";
		if (vModel.CvkKeyKcv) {
			errorList.push("CvkKeyKcv cannot be empty.");
		}

		vModel.CavvkKeyUnderLmk = model.CavvkKeyUnderLmk == null || model.CavvkKeyUnderLmk == "";
		if (vModel.CavvkKeyUnderLmk) {
			errorList.push("CavvkKeyUnderLmk cannot be empty.");
		}

		vModel.CavvkKeyKcv = model.CavvkKeyKcv == null || model.CavvkKeyKcv == "";
		if (vModel.CavvkKeyKcv) {
			errorList.push("CavvkKeyKcv cannot be empty.");
		}

		vModel.ZekAcUnderLmk = model.ZekAcUnderLmk == null || model.ZekAcUnderLmk == "";
		if (vModel.ZekAcUnderLmk) {
			errorList.push("ZekUnderLmk cannot be empty.");
		}

		vModel.ZekAcKcv = model.ZekAcKcv == null || model.ZekAcKcv == "";
		if (vModel.ZekAcKcv) {
			errorList.push("ZekKcv cannot be empty.");
		}

		vModel.PgkUnderLmk = model.PgkUnderLmk == null || model.PgkUnderLmk == "";
		if (vModel.PgkUnderLmk) {
			errorList.push("PgkUnderLmk cannot be empty.");
		}

		vModel.PgkKcv = model.PgkKcv == null || model.PgkKcv == "";
		if (!model.PgkKcv) {
			errorList.push("PgkKcv cannot be empty.");
		}


		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		if (list[index].ClientCustomer) {
			model.ClientCustomerId = list[index].ClientCustomer.Id;
			model.CustomerNumber = list[index].ClientCustomer.CustomerNumber;
		}
		this.setState({ isEdit: true, model, index });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;


		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0 },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0 },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0 },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3}>
													<GenericTextInput
														Name="ProfileName"
														LabelText="Profile Name"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.ProfileName}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.ProfileName}
													/>
												</GridItem>
												<GridItem xs={8} />
												<GridItem xs={3}>
													<GenericTextInput
														Name="DcvvKeyUnderLmk"
														LabelText="DcvvKeyUnderLmk"
														inputProps={this.maxLength49}
														Value={model.DcvvKeyUnderLmk}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.DcvvKeyUnderLmk}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="DcvvKeyKcv"
														LabelText="DcvvKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.DcvvKeyKcv}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.DcvvKeyKcv}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="CvkKeyUnderLmk"
														LabelText="CvkKeyUnderLmk"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.CvkKeyUnderLmk}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.CvkKeyUnderLmk}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="CvkKeyKcv"
														LabelText="CvkKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength6}
														Value={model.CvkKeyKcv}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.CvkKeyKcv}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="CavvkKeyUnderLmk"
														LabelText="CavvkKeyUnderLmk"
														inputProps={this.maxLength49}
														Value={model.CavvkKeyUnderLmk}
														LabelMd={4}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.CavvkKeyUnderLmk}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="CavvkKeyKcv"
														LabelText="CavvkKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength6}
														Value={model.CavvkKeyKcv}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.CavvkKeyKcv}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="ZekAcUnderLmk"
														LabelText="ZekUnderLmk"
														inputProps={this.maxLength49}
														Value={model.ZekAcUnderLmk}
														LabelMd={4}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.ZekAcUnderLmk}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="ZekAcKcv"
														LabelText="ZekKcv"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.ZekAcKcv}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.ZekAcKcv}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="PgkUnderLmk"
														LabelText="PgkUnderLmk"
														inputProps={this.maxLength64}
														Value={model.PgkUnderLmk}
														LabelMd={4}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.PgkUnderLmk}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="PgkKcv"
														LabelText="PgkKcv"
														LabelMd={4}
														inputProps={this.maxLength16}
														Value={model.PgkKcv}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.PgkKcv}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="AcKeyUnderLmk"
														LabelText="AcKeyUnderLmk"
														inputProps={this.maxLength49}
														Value={model.AcKeyUnderLmk}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="AcKeyKcv"
														LabelText="AcKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.AcKeyKcv}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="EncKeyUnderLmk"
														LabelText="EncKeyUnderLmk"
														inputProps={this.maxLength49}
														Value={model.EncKeyUnderLmk}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="EncKeyKcv"
														LabelText="EncKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.EncKeyKcv}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="MacKeyUnderLmk"
														LabelText="MacKeyUnderLmk"
														inputProps={this.maxLength49}
														Value={model.MacKeyUnderLmk}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="MacKeyKcv"
														LabelText="MacKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength49}
														Value={model.MacKeyKcv}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>

												<GridItem xs={3}>
													<GenericTextInput
														Name="ArpcKeyUnderLmk"
														LabelText="ArpcKeyUnderLmk"
														LabelMd={4}
														inputProps={this.maxLength64}
														Value={model.ArpcKeyUnderLmk}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="ArpcKeyKcv"
														LabelText="ArpcKeyKcv"
														LabelMd={4}
														inputProps={this.maxLength16}
														Value={model.ArpcKeyKcv}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Card Key Profile List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardKeyProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CardKeyProfile, {});