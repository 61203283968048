import React from "react";
import PropTypes from "prop-types";
// used for making the prop types of this component
import Formatter from "core/Formatter";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// generic components
import { GenericTitle, GenericPage, GenericCheckInput, GenericGrid } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { withArtifex } from "core";
import DDTListStyle from "../../../assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import { GridColumnType } from "views/Constants/Constant";

class ChequeWireTransactionsDataTable extends GenericPage {
	constructor(props) {
		super(props);
		this.columns = [

			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 60
			},
			{
				Header: "Client Name",
				accessor: "Name"
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "Funding Wallet",
				accessor: "FundingAccount"
			},
			{
				Header: "Request From",
				accessor: "RequestFrom"
			},
			{
				Header: "Date Of Request",
				accessor: "Date",
				type: GridColumnType.Date

			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective"
			},
			{
				Header: "BeneFiciary Name",
				accessor: "BeneFiciaryName"
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},

			{
				Header: "Order Status",
				accessor: "OrderStatus"
			}

		];
		this.state = {

			openDialog: false,
			alert: "",
			isLoading: false,
			wiretrsmodel: {},
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
	}


	SuccessSendCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.setState({ openDialog: true });

		this.ShowGenericMessage("success", "Operation is successfully!");

	}

	HandleClose () {
		this.setState({
			openDialog: false
		});
	}


	render() {
		const { IsLoading, Alert } = this.state;
		const { list, checkedValues } = this.props;


		return (
			<div >
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<GridContainer>
					<GridItem md={12} xs={12}>
						<GridContainer>
							<GridItem md={4} xs={12}>
								<GenericTitle text={"Pendings Total"} />
							</GridItem>
							<GridItem md={4} xs={12} />

						</GridContainer>
						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<GenericGrid
									Data={list.map(d => {
										return {
											Actions: (
												<div>
													<GenericCheckInput
														Grid
														Value={checkedValues.filter(c => c.Id == d.Id).length > 0}
														ValueChanged={(e) => {
															this.props.HandleCheck(e, d);
														}}
														Disabled={d.OrderStatus == "Approved"}

													/>
												</div>
											),
											UniqueClientId: d.UniqueClientId,
											Date: d.Date,
											FundingAccount: d.FundingAccount,
											Amount: d.Amount,
											RequestFrom: d.RequestFrom,
											ValueEffective: d.ValueEffective,
											BeneFiciaryName: d.BeneFiciaryName,
											Status: d.Status,
											Currency: d.Currency


										};
									})}
									Columns={this.columns}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ChequeWireTransactionsDataTable.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object,
	checkedValues: PropTypes.array,
	list: PropTypes.array,
	HandleCheck: PropTypes.func,
	HandleSend: PropTypes.func
};
export default withArtifex(ChequeWireTransactionsDataTable, DDTListStyle);
