import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import TabsComponent from "views/Components/TabsComponent";
import { ActionCodes, ClientType, MenuCodes } from "views/Constants/Constant.js";
import RiskRating from "./RiskRating";

class RiskRatingTabs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: {
				ReviewDate: DateHelper.GetDate(),
				MediumOverrideFactor: 1,
				HighOverrideFactor: 1,
				StopOverrideFactor: 1
			},
			CustomDisabled: null
		};
	}

	componentDidMount() {
		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedRiskRating, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}

	SetListCallback = (responseData) => {
		const { model } = this.state;
		model.ReviewDate = responseData.ReviewDate;
		model.CustomerRiskRatingAgreementId = responseData.CustomerRiskRatingAgreementId;
		model.InsertUserId = responseData.InsertUserId;
		model.MediumOverrideFactor = responseData.MediumOverrideFactor;
		model.HighOverrideFactor = responseData.HighOverrideFactor;
		model.StopOverrideFactor = responseData.StopOverrideFactor;
		this.setState({ list: responseData, model });
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { list, model } = this.state;
		const { Disabled: DisabledProp } = this.props;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<GridContainer justify="flex-end">
						<GridItem style={{ float: "right" }}>
							<Button disabled={Disabled} size="sm" color="black" onClick={() => {
								if (this.props.paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) {
									this.props.handleChangeParentTab(null, 2);
								} else {
									this.props.handleChangeParentTab(null, 3);
								}
							}
							} > Back </Button>
							<Button disabled={Disabled} size="sm" color="black"
								onClick={() => {
									if (!model.ReviewDate) {
										this.ShowMessage("warning", "Review Date not selected", "Select Review Date to continue.");
										return false;
									}
									if (this.props.paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) {
										this.props.handleChangeParentTab(null, 4);
									} else {
										this.props.handleChangeParentTab(null, 5);
									}
								}}>
								Next
							</Button>
						</GridItem>
					</GridContainer>
					<TabsComponent
						tabList={[
							{
								tabButton: "Individual",
								tabContent: (
									<RiskRating
										Disabled={Disabled}
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}
										paramBankCustomerMemoList={this.props.paramBankCustomerMemoList}
										memoTypeId={this.props.memoTypeId}
										GetCustomerMemoModels={this.props.GetCustomerMemoModels}
										handleChange={this.HandleChange}
										hideSidebar={this.props.hideSidebar}
										setAppLeftTitle={this.props.setAppLeftTitle}
										setAppCenterTitle={this.props.setAppCenterTitle}
										callbackFromParent={this.SetListCallback}
										clientType={ClientType.Personal}
										model={model}
									/>
								),
								Hidden: this.props.paramGlobalModel.ClientType == "" ? false : this.props.paramGlobalModel.ClientType == ClientType.Corporate ? true : this.props.paramGlobalModel.ClientType == ClientType.SalesAgentCorporate ? true : false
							},
							{
								tabButton: "Corporate",
								tabContent: (
									<RiskRating
										Disabled={Disabled}
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}
										paramBankCustomerMemoList={this.props.paramBankCustomerMemoList}
										memoTypeId={this.props.memoTypeId}
										GetCustomerMemoModels={this.props.GetCustomerMemoModels}
										handleChange={this.HandleChange}
										hideSidebar={this.props.hideSidebar}
										setAppLeftTitle={this.props.setAppLeftTitle}
										setAppCenterTitle={this.props.setAppCenterTitle}
										callbackFromParent={this.SetListCallback}
										clientType={ClientType.Corporate}
										model={model}
									/>
								),
								Hidden: this.props.paramGlobalModel.ClientType == "" ? false : this.props.paramGlobalModel.ClientType == ClientType.Personal ? true : this.props.paramGlobalModel.ClientType == ClientType.SalesAgentPersonal ? true : false
							}
						]}
					/>
				</GridItem>
			</GridContainer>

		);
	}
}

RiskRatingTabs.propTypes = {
	classes: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerMemoList: PropTypes.array,
	paramCustomerProfile: PropTypes.object,
	GetCustomerMemoModels: PropTypes.func,
	handleChange: PropTypes.func,
	hideSidebar: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	FetchClientLimitedAuthority: PropTypes.func
};

export default withArtifex(RiskRatingTabs, {});
