import PropTypes from "prop-types";
import React, { Component } from "react";
import Card from "components/Card/Card.jsx";
import { Proxy, withArtifex } from "core"; import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert } from "views/Components/Generic";

class ChequeTransactionImageDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {

			chqtransaction: {}
		};
		this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount() {
		// var tempuniq = Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
		var { chqtransaction } = this.props;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/chequeapi/v1.0/Cheque/ChequeTransactionImageDetail",

			{
				AccountNumber: chqtransaction.AccountNumber,
				ReferenceNumber: chqtransaction.ReferenceNumber

			},
			this.successImageData,
			this.errorCallback
		);

		this.setState({ openDialog: false });
	}
	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	successImageData = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.setState({
			chqtransaction: responseData.Item
			, isLoading: false
		});


	}
	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="Error" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert = () => {
		this.setState({ alert: null });
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	render() {
		const { chqtransaction } = this.state;
		/* height: 160px; */
		return (

			<div>

				{
					chqtransaction !== null ?


						<Card login  >
							<div style={{ padding: "15px",border:"solid #bfb6b6 1px" }}>

								<img
									src={chqtransaction.ImageFrontData}
									style={{ width: "100%", height: "200px" }} />

							</div>
							<div style={{ padding: "15px",border:"solid #bfb6b6 1px" }}>								<img
									src={chqtransaction.ImageRearData}
									style={{ width: "100%", height: "200px" }}
								/>

							</div>
						</Card>
						: <Card login  >


						</Card>
				}





			</div>
		);
	}
}

ChequeTransactionImageDetail.propTypes = {
	classes: PropTypes.object,
	chqtransaction: PropTypes.object,
	handleChange: PropTypes.func,
	PhoneModel: PropTypes.object,
	propModel: PropTypes.object
};

export default withArtifex(ChequeTransactionImageDetail, {});