import { DialogContent, DialogTitle } from "@material-ui/core";
import DDTListStyle from "assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper.js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import { ClientType, GridColumnType, ProgramCodes } from "views/Constants/Constant";
import CDICCorporateAccount from "./CDICCorporateAccount";
import CDICPersonalAccount from "./CDICPersonalAccount";

class CDICList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPersonal: false,
			cdicModel: {},
			openDialog: false
		};
		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.successIndividualCallBack = this.successIndividualCallBack.bind(this);
		this.successCorporateCallBack = this.successCorporateCallBack.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
	}

	handleOpen(item) {
		if (item.CustomerBusinessType == ClientType.Personal) {
			this.setState({ isPersonal: true });
		}
		if (item.CustomerBusinessType == ClientType.Corporate) {
			this.setState({ isPersonal: false });
		}
		RouteHelper.Push(this.props.history, "/CreateCustomer", "CustomerModel", { Id: item.CdicId, CDIC: true });
	}

	successIndividualCallBack(responseData) {
		this.setState({ openDialog: true, cdicModel: responseData.Item == null || undefined ? "" : responseData.Item.CdicIndividualInfo == null || undefined ? "" : responseData.Item.CdicIndividualInfo });
	}

	successCorporateCallBack(responseData) {
		this.setState({ openDialog: true, cdicModel: responseData.Item == null || undefined ? "" : responseData.Item.CdicCorporateInfo = null || undefined ? "" : responseData.Item.CdicCorporateInfo });
	}

	errorCallback(error) {
		this.handleClose();
		this.setState({
			alert: <GenericAlert Title={"Save"} Message={"An error occurred during the api visit" + error} Type={"info"} ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert = () => { this.setState({ alert: null }); }
	handleClose = () => { this.setState({ openDialog: false }); }

	render() {
		const { classes, propCDICTotalInfo, propIsBackoffice } = this.props;
		var data = this.props.propCDICList == null || undefined ? [] : this.props.propCDICList.map(item => {
			return {
				Actions: (
					<div>
						<GridButton
							tooltip="Detail"
							Icon={DetailIcon}
							OnClick={() => this.handleOpen(item)} />
					</div>
				),
				Id: item.CdicId == null || undefined ? "" : item.CdicId,
				CustomerNumber: item.CdicId == null || undefined ? "" : item.CdicId,
				CustomerType: item.CustomerBusinessTypeDesc == null ? "" : item.CustomerBusinessTypeDesc == "Business" ? "Corporate" : item.CustomerBusinessTypeDesc,
				TransitNumber: item.TransitNumber == null || undefined ? "" : item.TransitNumber,
				CDICNumber: item.AccountNumber == null || undefined ? "" : item.AccountNumber,
				IssuedDate: item.IssuedDate == null || undefined ? "" : item.IssuedDate,
				ExpireDate: item.ExpireDate == null || undefined ? "" : item.ExpireDate,
				Status: item.AccountStatusDesc == null || undefined ? "" : item.AccountStatusDesc,
				UniqueClientId: item.UniqueClientId == null || item.UniqueClientId == 0 ? "" : item.UniqueClientId,
				AccountOwner: item.AccountOwner == null ? "" : item.AccountOwner,
				NameofAccountOwner: item.AccountOwnerName == null || undefined ? "" : item.AccountOwnerName,
				Balance: item.AccountBalance == null || undefined ? null : item.AccountBalance
			};
		});
		return (
			<div>
				<GenericDialog open={this.state.openDialog} maxWidth="md" onBackdropClick={this.handleClose}>
					<DialogTitle >
						{this.state.isPersonal == true &&
							<GenericLabel Text="CDIC Individual Wallet" TextColor="white" FontSize="25px" Bold={true} />
						}
						{this.state.isPersonal == false &&
							<GenericLabel Text="CDIC Corporate Wallet" TextColor="white" FontSize="25px" Bold={true} />
						}

					</DialogTitle>
					<DialogContent>
						{this.state.isPersonal == true &&
							<CDICPersonalAccount
								propModel={this.state.cdicModel} />
						}
						{this.state.isPersonal == false &&
							<CDICCorporateAccount
								propModel={this.state.cdicModel} />
						}
					</DialogContent>

					<GenericDialogActions>
						<Button size="sm" onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericGrid
					Data={data}
					Columns={[
						{
							Header: "Actions",
							accessor: "Actions",
							sortable: false,
							filterable: false,
							width: 60
						},
						{
							Header: "Customer Type",
							accessor: "CustomerType"
						},
						{
							Header: "Unique Client Id",
							accessor: "UniqueClientId",
							show: propIsBackoffice
						},
						{
							Header: "Client Name",
							accessor: "AccountOwner",
							show: propIsBackoffice
						},
						{
							Header: "Customer Number",
							accessor: "CustomerNumber"
						},
						{
							Header: "Transit Number",
							accessor: "TransitNumber"
						},
						{
							Header: "DDT - CDIC Number",
							accessor: "CDICNumber"
						},
						{
							Header: "Issued Date",
							accessor: "IssuedDate",
							type: GridColumnType.Date
						},
						{
							Header: "Expire Date",
							accessor: "ExpireDate",
							type: GridColumnType.Date
						},
						{
							Header: "Status",
							accessor: "Status"
						},
						{
							Header: "Name of Wallet Owner",
							accessor: "NameofAccountOwner"
						},
						{
							Header: "Balance",
							accessor: "Balance",
							type: GridColumnType.Money,
							ColumnType:GridColumnType.Money
						}
					]}
					ProgramCode={ProgramCodes.Bank_Programs_CDIC} />
				<GridContainer >
					<GridItem xs={6} />
					<GridItem xs={6}>
						<GridContainer className={classes.containerHeight} justify="flex-end">
							<GridItem xs={2} className={classes.gridBorder}>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Active</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Inactive</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Total</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Balance</p>
							</GridItem>
						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
								TOTAL
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propCDICTotalInfo == null || undefined ? 0 : propCDICTotalInfo.Active == null || undefined ? 0 : typeof propCDICTotalInfo.Active == "number" ? Formatter.FormatNumber(propCDICTotalInfo.Active) : propCDICTotalInfo.Active}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propCDICTotalInfo == null || undefined ? 0 : propCDICTotalInfo.Inactive == null || undefined ? 0 : typeof propCDICTotalInfo.Inactive == "number" ? Formatter.FormatNumber(propCDICTotalInfo.Inactive) : propCDICTotalInfo.Inactive}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propCDICTotalInfo == null || undefined ? 0 : propCDICTotalInfo.Total == null || undefined ? 0 : typeof propCDICTotalInfo.Total == "number" ? Formatter.FormatNumber(propCDICTotalInfo.Total) : propCDICTotalInfo.Total}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propCDICTotalInfo == null || undefined ? 0 : propCDICTotalInfo.Balance == null || undefined ? 0 : typeof propCDICTotalInfo.Balance == "number" ? Formatter.FormatMoney(propCDICTotalInfo.Balance) : propCDICTotalInfo.Balance}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CDICList.propTypes = {
	classes: PropTypes.object,
	propCDICList: PropTypes.array,
	propCDICTotalInfo: PropTypes.object,
	propIsBackoffice: PropTypes.bool
};

export default withArtifex(CDICList, DDTListStyle);