import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import { OperationType } from "views/Constants/Constant";

const styles = theme => ({
	...sweetAlertStyle
});

class CommissionFeeProfileDataTable extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem md={12} xs={12}>
					<GridContainer>
						<GridItem xs={12} sm={4} />
						<GridItem xs={12} sm={8}>
							<GridContainer justify="flex-end">
								<GridItem md={2} xs={12}>
									<Button
										size="sm"
										onClick={this.props.handleGetList}
										fullWidth
									>
										Get
									</Button>
								</GridItem>
								<GridItem md={2} xs={12}>
									<Button
										size="sm"
										onClick={() => this.props.handleOperationType(OperationType.INSERT)}
										fullWidth
									>
										New
									</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
					<br />
					<GridContainer spacing={16}>
						<GridItem xs={12}>
							<ReactTable
								data={this.props.list.map(d => {
									return {
										Id: d.Id,
										ProfileId: d.ProfileId,
										ProfileName: d.ProfileName,
										UseStandartRate: d.UseStandartRate === true ? "Yes" : "No",
										actions: (
											<div>
												<Button
													color='primary'
													justIcon simple
													size="sm"
													tooltip="Edit"
													onClick={() => {
														this.props.handleOperationType(OperationType.UPDATE, d);
													}}>
													<img src={EditIcon} />
												</Button>
											</div>
										)
									};
								})}
								filterable
								columns={[
									{
										Header: "Profile Id",
										accessor: "Id"
									},
									{
										Header: "Profile Id",
										accessor: "ProfileId"
									},
									{
										Header: "Profile Name",
										accessor: "ProfileName"
									},
									{
										Header: "Use Standart Rate",
										accessor: "UseStandartRate"
									},
									{
										Header: "Actions",
										accessor: "actions",
										sortable: false,
										filterable: false,
										resizable: false
									}
								]}
								defaultPageSize={50}
								showPaginationTop={false}
								showPaginationBottom={Object.keys(this.props.list).length > 0}
								minRows={2}
							/>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		);
	}
}

CommissionFeeProfileDataTable.propTypes = {
	classes: PropTypes.object,
	list: PropTypes.array,
	handleGetList: PropTypes.func,
	handleOperationType: PropTypes.func
};

export default withStyles(styles)(CommissionFeeProfileDataTable);
