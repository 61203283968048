import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicationNetworth extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			alert: null
		};
		this.LOSDepositInParameterCode = { ParameterCode: "LOSDepositIn" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.ParameterPaymentRate = { ParameterCode: "LOSPaymentRate" };
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");
	}


	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}


	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ otherFinancialModel: model });
	}

	DeleteModel = (model) => {

	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.otherFinancialModel || {};
			model[name] = newValue;
			return { otherFinancialModel: model };
		});
	}
	render() {
		const { Disabled, ApplicationModel } = this.props;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}


				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer
					direction="row"
					justify="flex-start"
					alignItems="stretch"
				>
					<GridItem xs={3}>
						<GridContainer
							direction="column"
							justify="flex-start"
							alignItems="stretch"
						>
							<GridItem xs={12}>
								<GenericTitle text={"Asset Definition"} />


							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthAsset1"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthAsset1 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthAsset2"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthAsset2 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthAsset3"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthAsset3 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={3}>
						<GridContainer
							direction="column"
							justify="flex-start"
							alignItems="stretch"
						>
							<GridItem xs={12}>

								<GenericTitle text={"Liabilities"} />
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthLiability1"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthLiability1 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthLiability2"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthLiability2 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthLiability3"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthLiability3 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={3}>
						<GridContainer
							direction="column"
							justify="flex-start"
							alignItems="stretch"
						>
							<GridItem xs={12}>

								<GenericTitle text={"Estimated Values"} />
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthEstimatedValue1"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthEstimatedValue1 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthEstimatedValue2"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthEstimatedValue2 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									LabelMd={0}
									LabelText={""}
									Name="NetworthEstimatedValue3"
									Value={ApplicationModel == null ? "" : ApplicationModel.NetworthEstimatedValue3 || ""}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
								>
								</GenericTextInput>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={3}>
						<GridItem xs={12}>
							<GridContainer
								direction="column"
								justify="flex-start"
								alignItems="stretch"
							>
								<GridItem xs={12}>

									<GenericTitle text={"Start Date of Ownership"} />
								</GridItem>
								<GridItem xs={12}>
									<GenericDateInput
										LabelMd={0}
										LabelText={""}
										Name="NetworthOwnershipDate1"
										Value={ApplicationModel == null ? "" : DateHelper.ToDateInputValue(ApplicationModel.NetworthOwnershipDate1) || ""}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
									>
									</GenericDateInput>
								</GridItem>
								<GridItem xs={12}>
									<GenericDateInput
										LabelMd={0}
										LabelText={""}
										Name="NetworthOwnershipDate2"
										Value={ApplicationModel == null ? "" : DateHelper.ToDateInputValue(ApplicationModel.NetworthOwnershipDate2) || ""}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
									>
									</GenericDateInput>
								</GridItem>
								<GridItem xs={12}>
									<GenericDateInput
										LabelMd={0}
										LabelText={""}
										Name="NetworthOwnershipDate3"
										Value={ApplicationModel == null ? "" : DateHelper.ToDateInputValue(ApplicationModel.NetworthOwnershipDate3) || ""}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
									>
									</GenericDateInput>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridItem>

				</GridContainer>
			</GridContainer>
		);
	}
}

LoanApplicationNetworth.propTypes = {
	classes: PropTypes.object,
	ApplicationModel: PropTypes.object,
	HandleChange: PropTypes.func,
	ApplicationOtherFinancialModel: PropTypes.object
};
export default withArtifex(LoanApplicationNetworth, {});