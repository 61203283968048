import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CardFindType, GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class AssignCard extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsNoNameCard: true,
			IsClientCustomerIdAssigned: true
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			isLoading: false
		}

		this.emptyObject = {};

		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				types: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Assign Card");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetCardSummaryList",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/AssignClientCustomerIdForNoNameCard",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select");

		vModel.ClientCustomerId = model.ClientCustomerId == null;
		if (vModel.ClientCustomerId) messages.push("Customer must be select");

		vModel.CardFindType = model.CardFindType == null;
		if (vModel.CardFindType) messages.push("Card Find Type must be select");

		vModel.CardId = model.CardFindType === 1 && model.CardId == null;
		if (vModel.CardId) messages.push("Card Id must be select");

		vModel.ClearCardNumber = model.CardFindType === 2 && model.ClearCardNumber == null;
		if (vModel.ClearCardNumber) messages.push("Card Number must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		if (list[index].ClientCustomer) {
			model.ClientCustomerId = list[index].ClientCustomer.Id;
			model.CustomerNumber = list[index].ClientCustomer.CustomerNumber;
		}
		this.setState({ isEdit: true, model, index });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, vModel, list, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Disabled={IsClient}
														DefaultIndex={IsClient ? 0 : -1}
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem xs={3}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
														Required
														IsInvalid={vModel.ClientCustomerId} />
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="CardFindType"
														LabelText="Card Find Type"
														IsStatic={true}
														StaticData={CardFindType}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Text"
														Value={model.CardFindType}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.CardFindType} />
												</GridItem>
												<GridItem xs={3}>
													{model.CardFindType === 1 &&
														<GenericNumberInput
															Name="CardId"
															LabelText="Card Id"
															Value={model.CardId}
															ValueChanged={this.HandleChange}
															NoFormatting={true}
															MaxLength={16}
															Required
															IsInvalid={vModel.CardId} />
													}
													{model.CardFindType === 2 &&
														<GenericTextInput
															Name="ClearCardNumber"
															LabelText="Clear Card Number"
															Format={"@@@@ @@@@ @@@@ @@@@"}
															inputProps={this.maxLength19}
															Value={model.ClearCardNumber}
															ValueChanged={this.HandleChange}
															Required
															IsInvalid={vModel.ClearCardNumber} />
													}
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														Name="CardTokenNumber"
														LabelText="Card Token Number"
														Format={"@@@@ @@@@ @@@@ @@@@"}
														inputProps={this.maxLength19}
														Value={model.CardTokenNumber}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Assigned Card List" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</ >
		);
	}
}

AssignCard.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(AssignCard, {});