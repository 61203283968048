import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import { Close, Lock, LockOpen, Search } from "@material-ui/icons";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import TabsComponent from "views/Components/TabsComponent";
import { RoleGroup, RoleLevel } from "views/Constants/Constant";
import UserAuthorityDefinitionTreeAndDataTable from "./UserAuthorityDefinitionTreeAndDataTable";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class UserAuthorityDefinition extends React.Component {

	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.state = {
			model: {},
			listRoleLevel: [],
			listRole: [],
			isClientStandardUserOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("User Authority Definitions");
		}

		var user = ClientHelper.GetUser();

		if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser) {
			this.setState({ isClientStandardUserOpen: true });
		}

		if (this.isClient) {
			this.GetRoleGroupClientId();
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			var listRole = state.listRole;
			var listRoleLevel = state.listRoleLevel;

			if (name == "RoleGroupId") {
				model["RoleLevelId"] = undefined;
				model["RoleId"] = undefined;

				if (value) {
					this.GetRoleLevel(value);
				} else {
					listRoleLevel = [];
					listRole = [];
				}
			} else if (name == "RoleLevelId") {
				model["RoleLevelDesc"] = data && data.RoleLevel;
				model["RoleId"] = undefined;

				if (value) {
					this.GetRole(value);
				} else {
					listRole = [];
				}
			} else if (name == "RoleId") {
				model["ParentMenuList"] = [];
				model["SubMenuList"] = [];
				model["tabList"] = undefined;
			}

			return { model, listRole, listRoleLevel };
		});
	}

	GetParameterRole = () => {
		const { model } = this.state;

		var result = {
			RoleGroupId: model.RoleGroupId
		};

		return result;
	}

	GetRoleGroupClientId = async () => {
		const { ExecuteApiPost } = this.props;

		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "RoleGroup", ParameterValue: RoleGroup.Client });
		var roleGroupId = undefined;

		if (result != null && result.length == 1) {
			roleGroupId = result[0].Id;
		}

		this.setState({ model: { RoleGroupId: roleGroupId } });
		this.ValueChanged("RoleGroupId", roleGroupId);
	}

	GetMenuList = async () => {
		const { RoleLevelId } = this.state.model;
		const { ExecuteApiPost, showMessage } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Menu/GetMenuListForAuthorityTabAndDetail", { RoleLevelId: RoleLevelId });
		var { model } = this.state;
		model.tabList = [];

		if (result != null && result.MenuList != null
			&& result.MenuList.length != 0) {
			model.ParentMenuList = result.MenuList;
			model.SubMenuList = result.FlatRoutes;
			this.setState({ model });
			this.GetMenuRightList(model);

			return;
		}

		this.setState({ model });
		showMessage("warning", "Warning", model.RoleLevelDesc + " role level base menu did not found.");
	}

	GetMenuRightList = async (model) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/MenuRight/GetMenuRightList", { RoleId: model.RoleId });
		var tabList = [];

		if (result != null) {
			var parentMenuList = model.ParentMenuList,
				subMenuList = model.SubMenuList,
				menuRightList = result;

			for (var i = 0; i < parentMenuList.length; i++) {
				var modelForTreeAndDataTable = {},
					list = [],
					openCloseIcons = [],
					viewDeniedIcons = [],
					subMenusFiltered = this
						.GetMenusFilteredByParentId(
							[{ Id: parentMenuList[i].Id }],
							0,
							[],
							subMenuList),
					subMenusForTree = this
						.GetMenusForTree(
							subMenusFiltered,
							0),
					menusOrderFromTree = this
						.GetMenusOrderFromTree(subMenusFiltered);

				for (var index in menusOrderFromTree) {
					if (menusOrderFromTree[index] == null) {
						continue;
					}

					list.push({
						OC: "OC",
						VM: "VM"
					});
					var menuId = menusOrderFromTree[index].Id.Id,
						isAdded = false;
					for (var indexForMenuRight in menuRightList) {
						var menuRight = menuRightList[indexForMenuRight];
						if (menuRight.MenuId === menuId) {
							if (menuRight.HasAuthority) {
								openCloseIcons.push({
									icon: <LockOpen />,
									name: "lockOpen",
									menuId: menuId
								});
							} else {
								openCloseIcons.push(this.GetDefaultOpenCloseIcon(menuId));
							}

							if (menuRight.HasRead) {
								viewDeniedIcons.push({
									icon: <Search />,
									name: "search",
									menuId: menuId
								});
							} else {
								viewDeniedIcons.push(this.GetDefaultViewDeniedIcon(menuId));
							}
							isAdded = true;
							break;
						}
					}

					if (!isAdded) {
						openCloseIcons.push(this.GetDefaultOpenCloseIcon(menuId));
						viewDeniedIcons.push(this.GetDefaultViewDeniedIcon(menuId));
					}
				}

				modelForTreeAndDataTable.list = list;
				modelForTreeAndDataTable.openCloseIcons = openCloseIcons;
				modelForTreeAndDataTable.viewDeniedIcons = viewDeniedIcons;
				modelForTreeAndDataTable.subMenus = subMenusForTree;
				modelForTreeAndDataTable.selectedParentMenuId = parentMenuList[i].Id;
				modelForTreeAndDataTable.selectedRoleLevelId = model.RoleLevelId;
				modelForTreeAndDataTable.selectedRoleId = model.RoleId;
				modelForTreeAndDataTable.menusOrderFromTree = menusOrderFromTree;

				tabList.push({
					tabButton: ResourceHelper.GetForMenu(parentMenuList[i].Name) || "! " + parentMenuList[i].Name,
					tabContent: (
						<UserAuthorityDefinitionTreeAndDataTable
							onRef={ref => (this.child = ref)}
							model={modelForTreeAndDataTable} />
					)
				}
				);
			}
		}

		model.tabList = tabList;
		this.setState({ model });
	}

	GetRoleLevel = async (roleGroupId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/SearchRoleLevel", { RoleGroupId: roleGroupId });

		this.setState({ listRoleLevel: result || [] });
	}

	GetRole = async (roleLevelId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/GetRoleListByRoleLevelId", { RoleLevelId: roleLevelId });

		this.setState({ listRole: result || [] });
	}

	Search = () => {
		if (!this.ValidateSearch()) {
			return;
		}

		this.GetMenuList();
	}


	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		const { ExecuteApiPost } = this.props;

		ExecuteApiPost("/coreapi/v1.0/MenuRight/Submit", this.child.GetAuthories(), null, null, null, [this.GetMenuList], true);
	}

	GetMenusForTree(menuList, parentId) {
		return menuList
			.filter(x => x.ParentId === parentId)
			.map(x => {
				return {
					name: x.Name,
					id: x.Id.Id,
					toggled: true,
					children: this.GetMenusForTree(menuList, x.Id.Id)
				};
			});
	}

	GetMenusFilteredByParentId(menuIds, menuIndex, menuList, allMenu) {
		if (menuIds.length > menuIndex) {
			var menuId = menuIds[menuIndex],
				resultFilterByParentId = allMenu.filter(x => x.ParentId == menuId.Id),
				index;

			for (index in allMenu) {
				if (allMenu[index].Id == menuId.Id) {
					menuList.push({
						Id: menuId,
						Name: ResourceHelper.GetForMenu(allMenu[index].Name) || "! " + allMenu[index].Name,
						ParentId: allMenu[index].ParentId
					});
					break;
				}
			}
			for (index in resultFilterByParentId) {
				menuIds.push({
					Id: resultFilterByParentId[index].Id
				});
			}

			menuIndex += 1;
			this.GetMenusFilteredByParentId(menuIds, menuIndex, menuList, allMenu);
		}

		return menuList;
	}

	GetMenusOrderFromTree(menuList) {
		var orderedLikeTree = [];
		orderedLikeTree.push(menuList[0]);

		for (var i = 0; i < orderedLikeTree.length; i++) {
			if (i === 0) {
				menuList
					.filter(x => x.ParentId === menuList[i].Id.Id)
					.map(x => orderedLikeTree.push(x));
			}
			else {
				var temp = [],
					j = 0;

				for (; j <= i; j++) {
					temp.push(orderedLikeTree[j]);
				}
				for (var t = 0; t < menuList.length; t++) {
					if (menuList[t].ParentId === orderedLikeTree[i].Id.Id) {
						temp.push(menuList[t]);
					}
				}
				for (; j < orderedLikeTree.length; j++) {
					temp.push(orderedLikeTree[j]);
				}
				orderedLikeTree = temp;
			}
		}
		return orderedLikeTree;
	}

	GetDefaultOpenCloseIcon(menuId) {
		return {
			icon: <Lock />,
			name: "lock",
			menuId: menuId
		};
	}

	GetDefaultViewDeniedIcon(menuId) {
		return {
			icon: <Close />,
			name: "close",
			menuId: menuId
		};
	}

	Clear = () => {
		var { model, listRoleLevel } = this.state;

		if (this.isClient) {
			var roleGroupId = model.RoleGroupId;
			model = {
				RoleGroupId: roleGroupId
			};
		} else {
			model = {};
			listRoleLevel = [];
		}

		this.setState({ model, listRoleLevel, listRole: [] });
	}

	Validate = () => {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.RoleId) {
			errors.push("Role can not be null.");
		}
		if (!model.tabList || model.tabList.length == 0) {
			errors.push("Menu list is empty");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	ValidateSearch() {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.RoleGroupId) {
			errors.push("Role group can not be null.");
		}
		if (!model.RoleLevelId) {
			errors.push("Role level can not be null.");
		}
		if (!model.RoleId) {
			errors.push("Role can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, listRoleLevel, listRole, isClientStandardUserOpen } = this.state;
		const { history, Disabled } = this.props;

		var tabList = [];
		for (var index in model.tabList) {
			tabList.push({
				tabButton: model.tabList[index].tabButton,
				tabContent: model.tabList[index].tabContent
			});
		}

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isClientStandardUserOpen} maxWidth="md" TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel Bold Text="Error" />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text="You cannot open this screen." />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="User Authority Definitions">
									<GridContainer>
										<GridItem xs={4}>
											<ParameterComponent
												Disabled={this.isClient}
												Name="RoleGroupId"
												LabelText="Role Group"
												LabelMd={3}
												ParameterCode="RoleGroup"
												Value={model.RoleGroupId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic
												Name="RoleLevelId"
												LabelText="Role Level"
												LabelMd={2}
												StaticData={listRoleLevel}
												DataRoot="Item"
												KeyValueMember="RoleLevelId"
												TextValueMember="RoleLevel"
												Value={model.RoleLevelId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic
												Name="RoleId"
												LabelText="Role"
												LabelMd={2}
												StaticData={listRole}
												DataRoot="Item"
												KeyValueMember="RoleId"
												TextValueMember="RoleName"
												Value={model.RoleId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ display: model.RoleGroupId == null || !model.ParentMenuList || model.ParentMenuList.length == 0 ? "none" : "inherit" }}>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<TabsComponent
											tabList={tabList} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

UserAuthorityDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default UserAuthorityDefinition;