import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedCode, SortedName } from "views/Constants/Constant.Sorted";

class KycScenarioMenuActionDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Parent Menu",
				accessor: "ParentMenuName",
				Cell: row => (
					<div>{ResourceHelper.GetForMenu(row.value)}</div>
				)
			},
			{
				Header: "Sub Menu",
				accessor: "SubMenuName",
				Cell: row => (
					<div>{ResourceHelper.GetForMenu(row.value)}</div>
				)
			},
			{
				Header: "Action",
				accessor: "ActionDescription"
			}
		];

		this.state = {
			model: {
				ParentMenuId: -2
			}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("KYC Scenario Menu Action Definition");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "ParentMenuId") {
				model["SubMenuId"] = null;
				model["ActionId"] = null;
			} else if (name == "SubMenuId") {
				model["ActionId"] = null;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	GetSubMenuParameter = () => {
		const { model } = this.state;
		var result = {
			Id: model.ParentMenuId
		};

		return result;
	}

	GetRenderItemAction = (x) => {
		var result = `${x.Action.Code} - ${x.Action.Description}`;

		return result;
	}

	GetMenuName(menu) {
		var menuName = ResourceHelper.GetForMenu(menu.Name);
		var menuNameAndCode = `${!menuName ? "! " + menu.Name : menuName} - ${menu.Code}`;

		return menuNameAndCode;
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycScenarioMenuActionDefinition/Search", model, null, null, this.ValidateSearch);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: { ParentMenuId: -2 }, list: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ParentMenuId
			|| model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (!model.ActionId) {
			errors.push("Action can not be null.");
		}

		model["IsParentMenuId"] = !model.ParentMenuId || model.ParentMenuId == -2;
		model["IsSubMenuId"] = !model.SubMenuId;
		model["IsActionId"] = !model.ActionId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	ValidateSearch = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (model.ParentMenuId != null
			&& model.ParentMenuId != -2
			&& !model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}

		model["IsParentMenuId"] = false;
		model["IsSubMenuId"] = model.ParentMenuId != null && model.ParentMenuId != -2 && !model.SubMenuId;
		model["IsActionId"] = false;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/amlkycapi/v1.0/KycScenarioMenuActionDefinition/Insert", { MenuId: model.SubMenuId, ActionId: model.ActionId }, null, null, this.Validate, [this.Clear, this.Search], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiPost("/amlkycapi/v1.0/KycScenarioMenuActionDefinition/Update", { Id: model.Id, MenuId: model.SubMenuId, ActionId: model.ActionId }, null, null, this.Validate, [this.Clear, this.Search], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/amlkycapi/v1.0/KycScenarioMenuActionDefinition/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="KYC Scenario Menu Action Definition">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsParentMenuId}
												Name="ParentMenuId"
												LabelText="Parent Menu"
												LabelMd={3}
												Url="/coreapi/v1.0/Menu/GetMenuListForParentMenu"
												Method="GET"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullPath"
												Sorted={SortedName}
												Value={model.ParentMenuId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsSubMenuId}
												key={model.ParentMenuId}
												Name="SubMenuId"
												LabelText="Sub Menu"
												LabelMd={2}
												Url="/coreapi/v1.0/MenuAction/GetMenuListByParentMenuId"
												Method="POST"
												Parameter={this.GetSubMenuParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetMenuName}
												Sorted={SortedName}
												Value={model.SubMenuId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsActionId}
												key={model.SubMenuId}
												Name="ActionId"
												LabelText="Action"
												LabelMd={2}
												Url="/coreapi/v1.0/MenuAction/GetByMenuId"
												Method="POST"
												Parameter={model.SubMenuId}
												DataRoot="Item"
												KeyValueMember="ActionId"
												RenderItem={this.GetRenderItemAction}
												Sorted={SortedCode}
												Value={model.ActionId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

KycScenarioMenuActionDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default KycScenarioMenuActionDefinition;