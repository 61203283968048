import { DialogContent, DialogTitle, Tooltip, Zoom } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DocumentIcon, ProfileIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import LoanCalculator from "views/Components/LoanCalculator";
import TabsComponent from "views/Components/TabsComponentPagination";
import { ActionCodes, GridColumnType, LOSApprovalStatus, LOSLoanType, MenuCodes, EmbossType } from "views/Constants/Constant";
import ApplicantDefinition from "views/LOS/ApplicantDefinition/ApplicantDefinition.jsx";
import LedgerCard from "views/LOS/LedgerCard/LedgerCard.jsx";
import LoanDetails from "views/LOS/LoanApproval/LoanApprovalDetails/LoanDetails.jsx";
import LocDetails from "views/LOS/LoanApproval/LoanApprovalDetails/LocDetails.jsx";
import SecureCardDetails from "views/LOS/LoanApproval/LoanApprovalDetails/SecureCardDetails.jsx";
import LoanApprovalFundingMethod from "views/LOS/LoanApproval/LoanApprovalFundingMethod.jsx";
import LoanApprovalInformation from "views/LOS/LoanApproval/LoanApprovalInformation.jsx";
import LoanApprovalOnHold from "views/LOS/LoanApproval/LoanApprovalOnHold.jsx";
import LoanApprovalRejected from "views/LOS/LoanApproval/LoanApprovalRejected";
import PaymentAdminAccountCreate from "views/LOS/LoanApproval/PaymentAdminAccountCreate.jsx";
import PaymentAdminApplicationNoteHistory from "views/LOS/LoanApproval/PaymentAdminApplicationNoteHistory.jsx";
import PaymentAdminCardAction from "views/LOS/LoanApproval/PaymentAdminCardAction.jsx";
import PaymentAdminDocument from "views/LOS/LoanApproval/PaymentAdminDocument.jsx";


const ConstVerification = {
	isIdVerification: null,
	isAddressVerification: null,
	isCreditHistoryVerification: null,
	isBlackListVerification: null,
	isDocumentVerification: null,
	isInPersonVerification: null,
	isSecurityAccountHaveBalance: null,
	isWatchListVerification: null
};
const ConstAccountModel = {
	FinancialInstitutionId: 0,
	FinancialInstitutionBranchId: 0,
	AccountNumber: 0
};
const LoanTypeTitle = {
	SecureCard: "Secure Card",
	Loan: "Loan",
	Loc: "Line of Credit"
};
const initialState = {
	panelActivationStatus: {
		panelFilterActive: true,
		panelInformationActive: false,
		panelLoanDetailActive: false,
		panelApprovalDocumentActive: false,
		panelAccountDetailActive: false,
		panelCardActionActive: false,
		panelApplicationNoteActive: false,
		panelStatementActive: false,
		panelCurrentTermTransactionActive: false
	}
};
class LoanApproval extends Component {
	constructor(props) {
		super(props);
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.emptyObject = {};

		this.GetApplicationDocumentModels = this.GetApplicationDocumentModels.bind(this);
		this.CreateCustomer = this.CreateCustomer.bind(this);
		this.CreateSecurityAccount = this.CreateSecurityAccount.bind(this);
		this.CreateWalletAccount = this.CreateWalletAccount.bind(this);
		this.CreateCard = this.CreateCard.bind(this);
		this.GetLoanApprovalParameterList = this.GetLoanApprovalParameterList.bind(this);
		this.ApplicantDialogOpenOrClose = this.ApplicantDialogOpenOrClose.bind(this);
		this.HandleChangeFilterPanel = this.HandleChangeFilterPanel.bind(this);
		this.GenerateStatementPdfFile = this.GenerateStatementPdfFile.bind(this);
		this.ActivateCard = this.ActivateCard.bind(this);
		this.SetFile = this
			.SetFile
			.bind(this);
		this.renderItemApplication = {};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Disabled={this.props.Disabled}
							tooltip="Edit"
							Icon={DocumentIcon}
							OnClick={() => {
								this.SelectedRow(row.original);
							}} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 60
			},
			{
				Header: "TransactionType",
				accessor: "TransactionTypeId",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
				sortable: false,
				filterable: false,
			},
		];

		this.ColumnsDataLOCTrx = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Disabled={this.props.Disabled}
							tooltip="Edit"
							Icon={DocumentIcon}
							OnClick={() => {
								this.setState({ isLedgerCardDialogOpen: true });
							}} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 60
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType.ParameterDesc"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
				}
			},
		];
		this.columnsCurrentTermTransactionList = [
			{

				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Transaction Date",
				accessor: "FormattedTransactionDate"
			},
			{
				Header: "Description",
				accessor: "Description"
			},

			{
				Header: "Transaction Amount",
				accessor: "FormattedAmount"
			},
			{
				Header: "Transaction Fee and Interest Amount",
				accessor: "FormattedFeeAmount"
			}
		];
		this.ParameterCodeList = ["LOSOccupation", "LOSDocumentType", "LOSPayOption"
			, "LOSYesNo", "LOSLoanPurpose", "LOSPaymentSchedule", "LOSRateOption"
			, "LOSPaymentMethod", "LOSLoanTranferType", "LMSPaymentDay"
			, "LOSSalary", "LOSAddrTime", "LOSOccupation", "EntityType"
			, "PaymentFrequency", "PaymentMethod", "PaymentOption", "LOSEmplTime"
			, "LOSDepositIn", "LOSApprovalSubStatus", "LOSVerifiedStatus", "IdentityVerifyingPerson", "LOSMaritalStatus"];

		this.sortedClient = { Member: "Name" };

		this.ParameterList = [];
		this.ApplicantList = [];
		this.FeeProfileList = [];
		this.FeeProfileListFiltered = [];
		this.CountryList = [];
		this.state = {
			panelActivationStatus: Object.assign({}, initialState.panelActivationStatus),
			model: {
				Id: 0,
				ApplicantId: 0,
				Applicant: {},
				CardEmbossName: "",
				IsMass: true
			},
			vModel: {},
			Score: {
				score: null,
				color: "#FF5733"
			},
			SystemResult: "",
			isRejectedDialogOpen: false,
			isOnHoldDialogOpen: false,
			isLedgerCardDialogOpen: false,
			isLoanCalculatorDialogOpen: false,
			LoanApprovalDocumentsList: [],
			LoanApprovalFeeList: [],
			isBrowse: false,
			grandtotal: 0,
			checkerDisabled: {
				secureCardDisabled: false,
				loanDisabled: false,
				locDisabled: false,
			},
			isApproved: false,
			Verification: {
				isIdVerification: null,
				isAddressVerification: null,
				isCreditHistoryVerification: null,
				isBlackListVerification: null,
				isDocumentVerification: null,
				isInPersonVerification: null,
				isSecurityAccountHaveBalance: null,
				isWatchListVerification: null
			},
			isApplicantDialogOpen: false,
			agreementList: [],
			modelRow: {},
			isLoading: false,
			isUserAdmin: false,
			isLoanApprovalScreen: false,
			ReadOnly: true,
			AccountModel: { FinancialInstitutionId: 0, FinancialInstitutionBranchId: 0, AccountNumber: 0 },
			ParentTabIndex: 0,
			LOCTransactions: [],
			isMainSubmitDisabled: true,
			isApprovedPeriodDisabled: false,
			operationType: null,
			paramApplicationDocumentList: [],
			isDebtInquiryDialogOpen: false,
			isAdjustLimitDialogOpen: false
		};
		this.Applicant = {};
		this.maxLength20 = { maxLength: 20 };
		this.marginLeft2 = { marginLeft: 2 };
		this.statemetColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={DocumentIcon}
							OnClick={(index) => { this.GenerateStatementPdfFile(row.original); }} />
					</div>
				),
				sortable: false,
				filterable: false
			},
			{
				Header: "Application Number",
				accessor: "Statement.Application.ApplicationNumber"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Statement Period Begin Date",
				accessor: "FormattedBeginDate"
			},
			{
				Header: "Statement Date",
				accessor: "FormattedEndDate"
			},
			{
				Header: "Statement Due Date",
				accessor: "FormattedDueDate"
			},
			{
				Header: "Opening Balance",
				accessor: "OpeningBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Closing Balance",
				accessor: "ClosingBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Minumum Payment Amount",
				accessor: "MinimumPayment",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Payments",
				accessor: "PaymentAmount"
			},
			{
				Header: "Refunds and Credits",
				accessor: "RefundsAndCredits",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Debit Adjustments",
				accessor: "DebitAdjustments",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Purchases",
				accessor: "PurchaseAmount"
			},
			{
				Header: "Cash Advance",
				accessor: "FormattedTotalCashAdvanceAmount"
			},
			{
				Header: "Fees Charged",
				accessor: "FeesCharged",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Interest Charged",
				accessor: "InterestCharged",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
		this.LedgerCardColumns = [

			{
				Header: "Installment Date",
				accessor: "InstallmentDateFormatted"
			},
			{
				Header: "Beginning Balance",
				accessor: "BeginningBalanceFormatted"
			},
			{
				Header: "Installment Amount",
				accessor: "InstallmentAmountFormatted"
			},
			{
				Header: "Principal Amount",
				accessor: "PrincipalAmountFormatted"
			},
			{
				Header: "Interest Balance",
				accessor: "InterestBalanceFormatted"
			},
			{
				Header: "Fee Balance",
				accessor: "FeeBalanceFormatted"
			},
			{
				Header: "Ending Balance",
				accessor: "EndingBalanceFormatted"
			},
			{
				Header: "Payment Status",
				accessor: "PaymentStatus.ParameterDesc"
			},
			{
				Header: "Overdue Interest",
				accessor: "LatePaymentAmountFormatted"
			},
			{
				Header: "Overdue Interest Status",
				accessor: "LatePaymentStatus"
			},
			{
				Header: "Remaining Overdue Interest Status",
				accessor: "RemainLatePaymentAmountFormatted"
			},
			{
				Header: "Overdue Interest Day",
				accessor: "LatePaymentDay"
			},
			{
				Header: "Overdue Fixed Fee ",
				accessor: "PenaltyAmountFormatted"
			},
			{
				Header: "Late Fee Status",
				accessor: "PenaltyStatus"
			},
			{
				Header: "Remaining Late Fee",
				accessor: "RemainPenaltyAmountFormatted"
			},
			{
				Header: "Installment Date",
				accessor: "InstallmentDate",
				show: false
			}
		];
		this.sortedInstallmentDate = [{ id: "InstallmentDate", desc: false }];
		this.FixedId = 0;
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle, MenuCode } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle(`${MenuCode == MenuCodes.Lms_PaymentAdmin ? "LOAN MANAGEMENT SYSTEM" : "LOAN ORIGINATOR SYSTEM"}`);
		}
		if (setAppLeftTitle) {
			setAppLeftTitle(`${MenuCode == MenuCodes.Lms_PaymentAdmin ? "Payment Admin" : "Loan Approval"}`);
		}

		this.GetUserRole();

		var applicantId = RouteHelper.ReadAndClear("LmsPaymentAdminApplicantId");
		var { model } = this.state;
		model.ApplicantId = applicantId;
		if (applicantId)
			this.setState({ model });
		var Id = RouteHelper.ReadAndClear("loanApproval_Id");
		if (Id)
			this.HandleGetApplication(Id);

		this.GetLoanApprovalParameterList(MenuCode == MenuCodes.PaymentAdminLenderProgram || MenuCode == MenuCodes.PaymentAdminCallCenter ? -1 : null);
		this.GetFixedId();
	}
	componentWillMount() {
		this.setState({ panelActivationStatus: Object.assign({}, initialState.panelActivationStatus) });
	}
	GenerateStatementPdfFile = (selectedModel) => {

		this.setState({ isLoading: true });
		let data = selectedModel;
		const { showMessage } = this.props;
		Proxy.FileDownloadWithGenericResponse(
			"/lmsapi/v1.0/Statement/GenerateStatementPdfFile",
			selectedModel.Statement.Application.ApplicationNumber + "-Statement-" + Math.floor(Math.random() * Math.floor(999999)),
			"pdf",
			data,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("An error occurred while downloading file " + error);
			});
	}
	GetLoanApprovalParameterList(uniqueClientId) {
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/LoanApproval/GetLoanApprovalUiParameter",
				{
					"ParameterList": this.ParameterCodeList,
					"MenuCode": this.props.MenuCode,
					"UniqueClientId": uniqueClientId
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ParameterList = responseData.Item.ParameterList;
					this.ApplicantList = responseData.Item.ApplicantList;
					this.CountryList = responseData.Item.CountryList;
					this.FeeProfileList = responseData.Item.FeeProfileList || [];
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}

	GetFixedId = () => {
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/coreapi/v1.0/Parameter/Search",
				{
					"ParameterCode": "LOSPaymentMethod",
					"ParameterValue": "F"
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.FixedId = responseData?.Item[0]?.Id;
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}

	LoanApprovalApprove = (workflowId, after) => {
		if (this.state.model.ApprovalStatusId == 1 && !this.ValidateClientSubmit())
			return;			
		var model = { ...this.state.model };
		
		Proxy.POST(
			"/losapi/v1.0/LoanApproval/Approve",
			{
				Application: model,
				ApplicationFeeList: this.state.LoanApprovalFeeList,
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.ErrorCode != "0") {
					this.hideAlert();
					if (after) after();
					window.location.reload();
				} else {
					this.ShowMessageConfirm("success", "Success", "Operation Successfully Completed", () => {
						this.hideAlert();
						if (after) after();
						window.location.reload();
					});
				}
			},
			error => {

				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}

	LoanApprovalReject = (workflowId, after) => {
		if (this.state.model.ApprovalStatusId == 1 && !this.ValidateClientSubmit())
			return;
		var model = { ...this.state.model };
		Proxy.POST(
			"/losapi/v1.0/LoanApproval/Reject",
			{
				Application: model,
				ApplicationFeeList: this.state.LoanApprovalFeeList,
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.ErrorCode != "0") {
					this.hideAlert();
					if (after) after();
					window.location.reload();
				} else {
					this.ShowMessageConfirm("success", "Success", "Operation Successfully Completed", () => {
						this.hideAlert();
						if (after) after();
						window.location.reload();
					});
				}
			},
			error => {

				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}

	LoanApprovalOnHold = (workflowId, after) => {
		if (this.state.model.ApprovalStatusId == 1 && !this.ValidateClientSubmit())
			return;
		var model = { ...this.state.model };
		Proxy.POST(
			"/losapi/v1.0/LoanApproval/Onhold",
			{
				Application: model,
				ApplicationFeeList: this.state.LoanApprovalFeeList,
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessageConfirm("success", "Success", "Operation Successfully Completed", () => {
					this.hideAlert();
					if (after) after();
					window.location.reload();
				});

			},
			error => {

				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	SelectedRow(row) {
		this.setState({ modelrow: row });
		this.LedgerCardDialogOpenOrClose();
	}

	ShowMessageConfirm = (type, title, message, confirm) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={confirm} />
		});
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	handleOperationType = (value) => {
		this.setState({ operationType: value });
	}

	UpdateModel = () => {
		const model = this.state.model;
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/LoanApproval/InsertUpdate",
				{
					Application: model,
					ApplicationFeeList: this.state.LoanApprovalFeeList,
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ model: responseData.Item.Application, isLedgerCardDialogOpen: false });
						this.ShowMessage("success", "Success", "Applications Updated");
					}
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}

	GetUserRole() {
		var userJson = localStorage.getItem("user");
		var user = userJson ? JSON.parse(userJson) : null;

		if (!user || !user.SessionId)
			return null;
		if (this.props.MenuCode == MenuCodes.Lms_PaymentAdmin) {
			this.setState({ isUserAdmin: true });
		}
		else if (this.props.MenuCode == MenuCodes.LOS_LoanApproval) {
			this.setState({ isLoanApprovalScreen: true });
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		if (name == "ApplicantId") {
			model["Id"] = null;
			if (newValue > 0) {
				var panelActivationStatus = this.state.panelActivationStatus;
				panelActivationStatus.panelInformationActive = true;
				this.HandleClickClearOk();
				this.setState({ panelActivationStatus });
			}
		}

		if (name == "Id" && newValue > 0) {
			this.HandleClickClearOk();
			this.HandleGetApplication(newValue);
		}

		if (name == "ApprovedPaymentPeriod" && newValue != "") {
			this.PaymentPeriodChange(newValue);
		}
		if (name == "LoanFeeDefinitionId" && newValue != "") {
			this.FeeProfileChange(newValue);
		}
		if (name == "SecurityQuestionId") {
			model["SecurityQuestionText"] = data?.Id;
		}
		if (name == "UniqueClientIdForBackoffice") {
			this.GetLoanApprovalParameterList(newValue);
		}
		if (name === "EmbossType" && newValue!=EmbossType.InstantId) {
			model.IsMass=true;
			model.InstantEmbossBranchId = undefined;
		}
		if (name === "EmbossType" && newValue==EmbossType.InstantId) {
			model.IsMass=false;
		}

		this.setState({ model });
	}

	PaymentPeriodChange = (value) => {
		let { model } = this.state;
		model.ApprovedPaymentPeriod = value;
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/LoanApproval/PaymentPeriodChange",
				model,
				responseData => {
					console.log("LoanApproval/PaymentPeriodChange ->> " + responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {

						model.InterestRate = responseData.Item.InterestRate;

						this.setState({
							model
						});
					}
				},
				this.ErrorCallback
			));
	}

	FeeProfileChange = (value) => {
		let { model } = this.state;
		model.LoanFeeDefinitionId = value;
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/LoanApproval/FeeProfileChange",
				model,
				responseData => {
					console.log("LoanApproval/FeeProfileChange ->> " + responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						model.TotalSetupAmount = responseData.Item.reduce((a, b) => +a + +b.GivenValue, 0);
						this.setState({
							LoanApprovalFeeList: responseData.Item,
							model
						});
					}
				},
				this.ErrorCallback
			));
	}

	HandleGetApplication = (id) => {
		let { model, Score, SystemResult, LoanApprovalFeeList, Verification, isApproved, ParentTabIndex } = this.state;
		this.setState({ LoanApprovalFeeList: [] });	
				
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/Application/GetById",
				{ Id: id },
				responseData => {					
					console.log("Application/GetById ->> " + responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						var uniqueClientIdForBackoffice = model.UniqueClientIdForBackoffice;

						model = responseData.Item.Application;
						model.UniqueClientIdForBackoffice = uniqueClientIdForBackoffice;
						model.ApplicationCardId = responseData.Item.ApplicationCardId;
						model.ApplicationCardStatusDesc = responseData.Item.ApplicationCardStatusDesc;
						model.CurrentCardLimit = responseData.Item.CurrentCardLimit;
						model.WalletBalance = responseData.Item.WalletBalance;
						model.SecurityBalance = responseData.Item.SecurityBalance;
						model.ApplicationCardParameterValue = responseData.Item.ApplicationCardParameterValue;
						model.CardEmbossName = responseData.Item.Application.Applicant.CardEmbossName;
						model.SecurityBalance = responseData.Item.SecurityBalance;
						Score.score = model.InternalScore;
						SystemResult = model.LoanApprovalParameter != null ? model.LoanApprovalParameter.FlexDesc : "!No data found";

						if (model != null && model.ApprovalStatus != null && model.ApprovalStatus.ParameterValue != LOSApprovalStatus.Saved) {
							LoanApprovalFeeList = responseData.Item.ApprovalFeeList;
						} else {
							LoanApprovalFeeList = responseData.Item.ApplicationFeeList;
						}
						if (model.ApprovalStatus != null && model.ApprovalStatus.ParameterValue == LOSApprovalStatus.Approved) {
							isApproved = true;
						}
						Verification.isAddressVerification = responseData.Item.AddressVerificationResult;
						Verification.isIdVerification = responseData.Item.IdVerificationResult;
						Verification.isTransUnionIdVerification = responseData.Item.TransUnionIdVerification;
						Verification.isBlackListVerification = responseData.Item.InternalBlacklistResult;
						Verification.isDocumentVerification = responseData.Item.Application.DocumentStatus;
						Verification.isCreditHistoryVerification = responseData.Item.CreditHistoryVerificationResult;
						Verification.isInPersonVerification = responseData.Item.InPersonVerificationResult;
						Verification.isSecurityAccountHaveBalance = responseData.Item.Application.Applicant && responseData.Item.Application.Applicant.SecurityAccount
							&& responseData.Item.Application.Applicant.SecurityAccount.Balance >= responseData.Item.Application.ApprovedSecurityAmount ? true : false;
						Verification.isWatchListVerification = true;
						model.ApprovedInternalScore = responseData.Item.Application.InternalScore;
						model.ApprovedDocumentStatus = responseData.Item.Application.DocumentStatus;
						model.ApprovedInternalBlacklistStatus = responseData.Item.InternalBlacklistResult;
						model.ApprovedIdVerificationStatus = responseData.Item.AddressVerificationResult;
						model.ApprovedAddressVerificationStatus = responseData.Item.AddressVerificationResult;
						model.ApprovedInPersonVerificationStatus = responseData.Item.InPersonVerificationResult;
						model.TransUnionIdVerification = responseData.Item.TransUnionIdVerification;
						var isApprovedPeriodDisabled = false;
						if (model.LoanType.ParameterValue == LOSLoanType.Loan) {
							ParentTabIndex = 2;

						}
						else if (model.LoanType.ParameterValue == LOSLoanType.LOC) {
							ParentTabIndex = 1;
							isApprovedPeriodDisabled = true;
							model.ApprovedPaymentPeriod = 1;
						}
						else if (model.LoanType.ParameterValue == LOSLoanType.SecureCard) {
							ParentTabIndex = 0;
							isApprovedPeriodDisabled = true;
							model.ApprovedPaymentPeriod = 1;
						}

						if (!model.ApprovedPaymentMethodId) {
							model.ApprovedPaymentMethodId = this.FixedId;
						}

						if (!model.LastPaymentDateAfter) {
							model.LastPaymentDateAfter = 21;
						}
						
						 this.FeeProfileListFiltered = this.FeeProfileList
							.filter(x => x.LoanTypeParameterValue == model.LoanType?.ParameterValue
								&& x.LoanPurposeId == model.LoanPurposeId); 

						this.setState({
							isApprovedPeriodDisabled: isApprovedPeriodDisabled,
							model: model,
							Score, isApproved,
							paramApplicationAddressList: responseData.Item.ApplicationAddressList,
							LoanApprovalDocumentsList: responseData.Item.ApplicationDocumentList,
							paramApplicationDocumentList: responseData.Item.ApplicationDocumentList,
							LoanApprovalFeeList, Verification, SystemResult,
							ParentTabIndex,
							LOCTransactions: responseData.Item.LOCTransactions,
							StatementList: responseData.Item.StatementListResponse != null
								&& responseData.Item.StatementListResponse.StatementList != null
								? responseData.Item.StatementListResponse.StatementList : [],
							LedgerCardList: responseData.Item.LoanLedgerCardResponse != null &&
								responseData.Item.LoanLedgerCardResponse.LedgerCardList != null
								? responseData.Item.LoanLedgerCardResponse.LedgerCardList : [],
							CurrentTermTransactionList: responseData.Item.CurrentTermTransactions || []
						}, () => this.GetUser(model.Applicant));
						//this.VerificationList("DocumentStatus", model.DocumentStatus);
					}
				},
				this.ErrorCallback
			));
	}

	GetUser = (applicant) => {
		const { model } = this.state;
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/coreapi/v1.0/User/GetUserByEmail?Email=" + applicant.Email,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					model.ApplicantHasUser = responseData.User != null;
					this.setState({ model });
				},
				this.ErrorCallback
			)
		);
	}

	VerificationList = (field, check) => {
		const temp = this.state.Verification;
		if (field == "DocumentStatus") {
			temp.isDocumentVerification = check;
		}
		if (field == "AddressStatus") {
			temp.isAddressVerification = check;
		}
		if (field == "CreditHistoryVerification") {
			temp.isCreditHistoryVerification = check;
		}
		if (field == "BlackListVerification") {
			temp.isBlackListVerification = check;
		}
		this.setState({ Verification: temp });
	}

	
	HandleClickClearOk = () => {
		const modelFromState = this.state.model;
		this.HideAlert();
		this.setState(
			{
				model: { Id: 0, ApplicantId: 0, Applicant: {}, UniqueClientIdForBackoffice: modelFromState.UniqueClientIdForBackoffice },
				LoanApprovalFeeList: [],
				SystemResult: "",
				LoanApprovalDocumentsList: [],
				Verification: ConstVerification,
				isUserAdmin: this.props.MenuCode == MenuCodes.Lms_PaymentAdmin,
				isLoanApprovalScreen: this.props.MenuCode == MenuCodes.LOS_LoanApproval,
				AccountModel: ConstAccountModel,
				isMainSubmitDisabled: true
			});
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this loan approval?", () => this.HandleClickClearOk());
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	RejectedDialogOpenOrClose = () => {
		if (this.state.isRejectedDialogOpen == false) {
			this.setState({ isRejectedDialogOpen: true });
		}
		else {
			this.setState({ isRejectedDialogOpen: false });
		}
	}
	GetSubMenuParameter = () => {
		const { model } = this.state;
		this.Applicant.ApplicantId = model.ApplicantId;

		return this.Applicant;
	}
	OnHoldDialogOpenOrClose = () => {
		if (this.state.isOnHoldDialogOpen == false) {
			this.setState({ isOnHoldDialogOpen: true });
		}
		else {
			this.setState({ isOnHoldDialogOpen: false });
		}
	}
	LoanCalculatorDialogOpenOrClose = () => {
		if (this.state.isLoanCalculatorDialogOpen == false) {
			this.setState({ isLoanCalculatorDialogOpen: true });
		}
		else {
			this.setState({ isLoanCalculatorDialogOpen: false });
		}
	}
	LedgerCardDialogOpenOrClose = () => {
		if (this.state.isLedgerCardDialogOpen == false) {
			this.setState({ isLedgerCardDialogOpen: true });
		}
		else {
			this.setState({ isLedgerCardDialogOpen: false });
		}
	}
	ApplicantDialogOpenOrClose = () => {
		if (this.state.model.ApplicantId != null && this.state.isApplicantDialogOpen == false) {
			this.setState({ isApplicantDialogOpen: true });
		}
		else if (this.state.isApplicantDialogOpen == true) {
			this.setState({ isApplicantDialogOpen: false });
		}
		else {
			this.setState({ isApplicantDialogOpen: false });
		}
	}

	TransferLoanOp = (refNum) => {
		if (refNum)
			this.GetLOCTransaction();
	}

	GetLOCTransaction = () => {
		const { model } = this.state;
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/lmsapi/v1.0/LOCTransaction/GetByApplicationId/Id?Id=" + model.Id,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({ LOCTransactions: responseData.Item });
					} else {
						this.setState({ LOCTransactions: [] });
					}
				},
				this.ErrorCallback
			));
	}

	DeleteModel = (model) => {
	}

	Search = () => {
	}

	SubmitApprovalStatusDialog = () => {
		if (this.state.model.Id > 0 &&
			this.state.model.ApprovalSubStatusId != null &&
			this.state.model.ApprovalSubStatusId > 0 &&
			this.state.model.ApprovalStatusId != null &&
			this.state.model.ApprovalStatusId > 0) {
			this.setState({ isMainSubmitDisabled: false });
		}
	}

	handleChangeParentTab = (event, active, tab) => {
		if (!tab) {
			this.setState({ ParentTabIndex: active });
		}
		return;
	}

	ValidateClientSubmit = () => {
		const { model } = this.state;
		let { vModel } = this.state;
		var ErrorList = [];
		if (model == null) {
			ErrorList.push("Model not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (model.ApplicantId == null || model.ApplicantId == 0) {
			ErrorList.push("Applicant must be defined.");
			vModel.ApplicantId = true;
		} else {
			vModel.ApplicantId = false;
		}
		if (model.LoanTypeId == null || model.LoanTypeId == "") {
			ErrorList.push("Loan type must be defined.");
			vModel.LoanTypeId = true;
		} else {
			vModel.LoanTypeId = false;
		}
		if (this.state.operationType === "Reject") {
			this.setState({ vModel });
			return true;
		}
		if (model.LoanFeeDefinitionId == null || model.LoanFeeDefinitionId == "") {
			ErrorList.push("Fee profile must be defined.");
			vModel.LoanFeeDefinitionId = true;
		} else {
			vModel.LoanFeeDefinitionId = false;
		}
		if (this.state.ParentTabIndex == 2 && !model.LoanPurposeId) {
			ErrorList.push("Loan purpose must be defined.");
			vModel.LoanPurposeId = true;
		} else {
			vModel.LoanPurposeId = false;
		}
		if (model.ApprovedAmount == null || model.ApprovedAmount == "") {
			ErrorList.push("Approved amount must be defined.");
			vModel.ApprovedAmount = true;
		} else {
			vModel.ApprovedAmount = false;
		}
		// if ((this.state.ParentTabIndex == 0) && model.ApprovedSecurityAmount != 0 && (model.ApprovedSecurityAmount == null || model.ApprovedSecurityAmount == "")) {
		// 	ErrorList.push("Approved security amount must be defined.");
		// 	vModel.ApprovedSecurityAmount = true;
		// } else {
		// 	vModel.ApprovedSecurityAmount = false;
		// }
		if (!model.ApprovedPaymentFrequencyId) {
			ErrorList.push("Approved payment frequency must be defined.");
			vModel.ApprovedPaymentFrequencyId = true;
		} else {
			vModel.ApprovedPaymentFrequencyId = false;
		}
		if (this.state.ParentTabIndex < 2 && !model.ApprovedPaymentMethodId) {
			ErrorList.push("Approved payment method must be defined.");
			vModel.ApprovedPaymentMethodId = true;
		} else {
			vModel.ApprovedPaymentMethodId = false;
		}
		if (model.ApprovedPaymentPeriod == null || model.ApprovedPaymentPeriod == "") {
			ErrorList.push("Approved payment period must be defined.");
			vModel.ApprovedPaymentPeriod = true;
		} else {
			vModel.ApprovedPaymentPeriod = false;
		}
		if (this.state.ParentTabIndex > 0 && !model.PaymentOptionFeeId) {
			ErrorList.push("Fee payment type must be defined.");
			vModel.PaymentOptionFeeId = true;
		} else {
			vModel.PaymentOptionFeeId = false;
		}
		if (this.state.ParentTabIndex == 2 && (model.FirstPaymentDate == null || model.FirstPaymentDate == "")) {
			ErrorList.push("First payment date must be defined.");
			vModel.FirstPaymentDate = true;
		} else {
			vModel.FirstPaymentDate = false;
		}
		if (this.state.ParentTabIndex == 2 && (model.LoanTransferTypeId == null || model.LoanTransferTypeId == "")) {
			ErrorList.push("Loan transfer type must be defined.");
			vModel.LoanTransferTypeId = true;
		} else {
			vModel.LoanTransferTypeId = false;
		}
		if ((this.state.ParentTabIndex == 0) && model.SecurityAmount != 0 && (model.SecurityAmount == null || model.SecurityAmount == "")) {
			ErrorList.push("Security amount must be defined.");
			vModel.SecurityAmount = true;
		} else {
			vModel.SecurityAmount = false;
		}
		if (this.state.ParentTabIndex != 2 && (model.StatementDayId == null || model.StatementDayId == "")) {
			ErrorList.push("Statement date must be defined.");
			vModel.StatementDayId = true;
		} else {
			vModel.StatementDayId = false;
		}
		if (this.state.ParentTabIndex != 2 && (model.LastPaymentDateAfter == null || model.LastPaymentDateAfter == "")) {
			ErrorList.push("Last payment date after must be defined.");
			vModel.LastPaymentDateAfter = true;
		} else {
			vModel.LastPaymentDateAfter = false;
		}
		if (this.state.ParentTabIndex == 2 && (model.TotalSetupAmount == null || model.TotalSetupAmount == "")) {
			ErrorList.push("Total setup amount must be defined.");
			vModel.TotalSetupAmount = true;
		} else {
			vModel.TotalSetupAmount = false;
		}
		if (this.state.ParentTabIndex == 2 && (model.InterestRate == null || model.InterestRate == "")) {
			ErrorList.push("Annual interest rate must be defined.");
			vModel.InterestRate = true;
		} else {
			vModel.InterestRate = false;
		}
		this.setState({ vModel });
		if (model.ApprovalStatus != null && model.ApprovalStatus.ParameterValue == LOSApprovalStatus.Approved) {
			this.ShowMessage("warning", "Warning", "It's already approved.");
			return false;
		}
		if (!this.state.Verification.isDocumentVerification) {
			this.ShowMessage("warning", "Warning", "Document not verified.");
			return false;
		}
		if (model.IsAgreementSigned != true) {
			ErrorList.push("Agreement must be signed.");
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ApplicationStatusDialogOpenOrClose(type) {
		// if ( this.state.model.Id != null && this.state.model.Id > 0) {
		switch (type) {
			case "Approve": {
				if (!this.ValidateClientSubmit())
					return;
				this.setState({ ApprovalStatusId: 1, isMainSubmitDisabled: false });
				this.LoanApprovalApprove();
				break;
			}
			case "Reject": {
				this.setState({ isRejectedDialogOpen: !this.state.isRejectedDialogOpen });
				break;
			}
			case "On Hold": {
				this.setState({ isOnHoldDialogOpen: !this.state.isOnHoldDialogOpen });
				break;
			}
			default: break;
		}
		//}

		this.handleOperationType(type);
	}

	RenderItemApplication = (d) => {
		this.renderItemApplication = `${d.ApplicationNumber} - ${d.LoanType.ParameterDesc}`;
		return this.renderItemApplication;
	}

	SetFile(file, documentModel) {
		this.setState({
			fileModel: {
				file,
				documentModel
			}
		});
	}

	GetApplicationDocumentModels() {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/ApplicationDocument/GetByApplicationId", {
				ApplicationId: this.state.model.Id
			}, responseData => {
				console.log("PaymentAdminDocument ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({
						paramApplicationDocumentList: responseData.Item || []
					});
				} else {
					this.setState({ paramApplicationDocumentList: [] });
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	CreateCustomer() {
		var model = this.state.model;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/PaymentAdmin/CreateCustomer", {
				Id: model.ApplicantId
			}, responseData => {
				console.log("CreateCustomer ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.Applicant.WalletAccount = responseData.Item.WalletAccount;
					model.Applicant.WalletAccountId = responseData.Item.WalletAccountId;
					model.Applicant.Customer = responseData.Item.Customer;
					model.Applicant.CustomerId = responseData.Item.CustomerId;
					this.setState({ model }, () => this.GetUser(model.Applicant));
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	CreateSecurityAccount() {
		var model = this.state.model;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/PaymentAdmin/CreateSecurityAccount", {
				ApplicantId: model.ApplicantId,
				ApplicationId: model.Id
			}, responseData => {
				console.log("CreateSecurityAccount ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.Applicant.SecurityAccount = responseData.Item.SecurityAccount;
					model.Applicant.SecurityAccountId = responseData.Item.SecurityAccountId;
					this.setState({
						model
					});
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
		this.setState({
			model
		});
	}
	CreateWalletAccount() {
		var model = this.state.model;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/PaymentAdmin/CreateWalletAccount", {
				ApplicantId: model.ApplicantId,
				ApplicationId: model.Id
			}, responseData => {
				console.log("CreateWalletAccount ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.Applicant.WalletAccount = responseData.Item.WalletAccount;
					model.Applicant.WalletAccountId = responseData.Item.WalletAccountId;
					this.setState({
						model
					});
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
		this.setState({
			model
		});
	}
	CreateCard() {
		var model = this.state.model;
		if (model.Applicant.WalletAccountId == null) {
			this.ShowMessage("warning", "Warning", "Wallet must be defined!");
			return;
		}
		if (model.Applicant.SecurityAccount.Balance < model.ApprovedSecurityAmount) {
			this.ShowMessage("warning", "Warning", "The security balance must be greater than the approved security amount for the secure card.");
			return;
		}
		if (model.EmbossType == undefined ) {
			this.ShowMessage("warning", "Warning", "Emboss Type must be select");
			return;
		}

		if (model.EmbossType == EmbossType.InstantId && model.InstantEmbossBranchId == undefined ) {
			this.ShowMessage("warning", "Warning", "Card Perso Office must be select");
			return;
		}

		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/PaymentAdmin/CreateCard", {
				ApplicationId: model.Id,
				CardEmbossName: model.CardEmbossName,
				EmbossType: model.EmbossType,
				InstantEmbossBranchId: model.InstantEmbossBranchId,
				Emboss4thLine: model.Emboss4thLine
			}, responseData => {
				console.log("CreateCard ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.Applicant.CardNumber = responseData.Item.CardNumber;
					model.Applicant.CardFinancialId = responseData.Item.CardFinancialId;
					model.Applicant.CardLimit = responseData.Item.CardLimit;
					model.Applicant.AvailableLimit = responseData.Item.AvailableLimit;
					model.Applicant.CATMCardId = responseData.Item.CATMCardId;
					model.Applicant.IsCardActive = responseData.Item.IsCardActive;
					model.CardEmbossName = responseData.Item.CardEmbosName;
					model.ApplicationCardStatusDesc = responseData.Item.ApplicationCardStatusDesc;
					model.InstantEmbossBranchId = responseData.Item.InstantEmbossBranchId;
					this.setState({
						model
					});
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	ActivateCard() {
		var model = this.state.model;
		// if (model.Applicant.CATMCardId == null || model.Applicant.CardFinancialId == null) {
		// 	this.ShowMessage("warning", "Warning", "Card must be created.");
		// 	return;
		// }
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/PaymentAdmin/ActivateCard", model.Id, responseData => {
				console.log("ActivateCard ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Card Activated");
				model.Applicant.IsCardActive = true;
				model.ApplicationCardStatusDesc = responseData.Item?.ApplicationCardStatusDesc;

				this.setState({
					model
				});

			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}

	DebtInquiryDialogOpen = async () => {
		var { model } = this.state;
		var { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/PaymentAdmin/DebtInquiryGet", { ApplicationId: model.Id });

		if (result != null) {
			model.DebtInquiry = result[0].DebtInquiry;
			model.BucketBalance = result[0].BucketBalance;

			this.setState({ model, isDebtInquiryDialogOpen: true });
		}
	}

	DebtInquiryDialogClose = () => {
		this.setState({ isDebtInquiryDialogOpen: false });
	}

	AdjustLimitDialogOpen = async () => {
		var { model } = this.state;
		var { vModel } = this.state;

		model.NewCardLimit = null;
		model.AdditionalSecurityAmount = 0;
		vModel.NewCardLimit = false;
		vModel.AdditionalSecurityAmount = false;

		this.setState({ model, vModel, isAdjustLimitDialogOpen: true });
	}

	AdjustLimitDialogClose = () => {
		this.setState({ isAdjustLimitDialogOpen: false });
	}

	AdjustLimitConfirm = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;

		if (!this.ValidateAdjustLimit()) {
			return;
		}

		var request = {
			LimitAdjustmentHistoryEntity: {
				ApplicationCardId: model.ApplicationCardId,
				PreviousCardLimit: model.WalletBalance,
				NewCardLimit: model.NewCardLimit,
				PreviousSecurityAmount: model.SecurityBalance,
				NewSecurityAmount: model.AdditionalSecurityAmount
			},
			InsertWorkflowRequest: {
				MenuCode: MenuCodes.Lms_LimitAdjustmentForWorkflow,
				ActionCode: ActionCodes.Submit,
				JsonData: JSON.stringify(model)
			}
		};
		var result = await ExecuteApiPost("/lmsapi/v1.0/LimitAdjustmentHistory/Insert", request);

		if (result != null) {
			this.ShowMessage("success", "Success", "Adjust limit successfully sent for approval.");
		}

		this.AdjustLimitDialogClose();
	}

	ValidateAdjustLimit = () => {
		var { model } = this.state;
		let { vModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.NewCardLimit) {
			errors.push("New card limit cannot be empty.");
		}

		if (model.AdditionalSecurityAmount != 0 && !model.AdditionalSecurityAmount) {
			errors.push("Additional security amount cannot be empty.");
		}

		if(model.NewCardLimit > model.CurrentCardLimit){
			if(model.NewCardLimit > model.SecurityBalance +  model.AdditionalSecurityAmount ){
				const addBalance = 	model.NewCardLimit - (model.SecurityBalance + model.AdditionalSecurityAmount);
				errors.push("You should add " + addBalance + "$ to your additional security amount.");
			}
		}

		vModel.NewCardLimit = !model.NewCardLimit;
		vModel.AdditionalSecurityAmount = model.AdditionalSecurityAmount != 0 && !model.AdditionalSecurityAmount;

		this.setState(vModel);

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	HandleChangeFilterPanel(name, expanded) {
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = true;
		currentPanelActivation[name] = expanded ? true : false;

		this.setState({
			panelActivationStatus: currentPanelActivation
		});
	}

	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	UpdateLoanDetails = () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			ApplicationId: model.Id,
			LoanFeeDefinitionId: model.LoanFeeDefinitionId
		};

		ExecuteApiPost("/lmsapi/v1.0/PaymentAdmin/UpdateLoanDetails", request, null, null, null, null, true);
	}

	UpdateLocDetails = () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			ApplicationId: model.Id,
			LoanFeeDefinitionId: model.LoanFeeDefinitionId
		};

		ExecuteApiPost("/lmsapi/v1.0/PaymentAdmin/UpdateLocDetails", request, null, null, null, null, true);
	}

	UpdateSecureCardDetails = () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			ApplicationId: model.Id,
			LoanFeeDefinitionId: model.LoanFeeDefinitionId
		};

		ExecuteApiPost("/lmsapi/v1.0/PaymentAdmin/UpdateSecureCardDetails", request, null, null, null, null, true);
	}

	render() {
		const { classes, MenuCode, Disabled } = this.props;
		const { model, vModel, alert, isLoading, isLedgerCardDialogOpen, isLoanCalculatorDialogOpen, isRejectedDialogOpen, isOnHoldDialogOpen, isApplicantDialogOpen,
			isApproved, StatementList, LedgerCardList, CurrentTermTransactionList, isDebtInquiryDialogOpen, isAdjustLimitDialogOpen } = this.state;
		const ForInsert = !(model.Id != null && model.Id > 0);

		return (
			<div>

				<LoadingComponent Show={isLoading} TaskPoolDomain={this.loadTaskPool} />

				<GenericDialog fullScreen open={isApplicantDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="Applicant" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<ApplicantDefinition
							IsPopUp={true}
							ApplicantModel={this.state.model.Applicant}
							ApprovalDisabled={true}
							ExecuteApiPost={this.props.ExecuteApiPost}
						>
						</ApplicantDefinition>
						<GenericDialogActions>
							<Button size="sm" onClick={this.ApplicantDialogOpenOrClose}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GenericDialog fullWidth maxWidth="md" open={isLedgerCardDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="Ledger Card" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<LedgerCard
							model={this.state.model}
							ApplicationId={model.Id}
							IsPopUp={true}
						/>
						<GenericDialogActions>
							<Button size="sm" onClick={this.LedgerCardDialogOpenOrClose}>Close</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GenericDialog open={isLoanCalculatorDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Loan Calculator" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<LoanCalculator
							IsPopUp={true}
							CloseLoanCalculator={this.LoanCalculatorDialogOpenOrClose}
						>
						</LoanCalculator>
						<GenericDialogActions>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GenericDialog maxWidth="sm" fullWidth open={isOnHoldDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="On-Hold Status" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<LoanApprovalOnHold
							model={this.state.model}
							HandleChange={this.HandleChange}
							ParameterList={this.ParameterList}
						/>
						<GenericDialogActions>
							<Button size="sm" disabled={ForInsert}
								onClick={() => {
									//this.state.model.ApprovalStatusId = 3;
									this.OnHoldDialogOpenOrClose();
									this.SubmitApprovalStatusDialog();
									this.LoanApprovalOnHold();
								}
								}>Submit</Button>
							<Button size="sm" onClick={this.OnHoldDialogOpenOrClose}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GenericDialog maxWidth="sm" fullWidth open={isRejectedDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="Reject Status" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<LoanApprovalRejected
							model={this.state.model}
							HandleChange={this.HandleChange}
							ParameterList={this.ParameterList}
						/>
						<GenericDialogActions>
							<Button size="sm" disabled={ForInsert}
								onClick={() => {
									this.setState({ ApprovalStatusId: 2 });
									this.RejectedDialogOpenOrClose();
									this.SubmitApprovalStatusDialog();
									this.LoanApprovalReject();
								}
								}>Submit</Button>
							<Button size="sm" onClick={this.RejectedDialogOpenOrClose}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GenericDialog maxWidth="sm" open={isDebtInquiryDialogOpen}>
					<DialogTitle>
						<GenericLabel Bold Text="Debt Inquiry" />
					</DialogTitle>
					<DialogContent >
						<GenericNumberInput
							Disabled
							AllowNegative
							Name="DebtInquiry"
							LabelText="Total Debt"
							Value={model.DebtInquiry} />
						<GenericNumberInput
							Disabled
							AllowNegative
							Name="BucketBalance"
							LabelText="Over Payment"
							Value={model.BucketBalance} />
						<GenericDialogActions>
							<Button size="sm" onClick={this.DebtInquiryDialogClose}>OK</Button>
							<Button size="sm" onClick={this.DebtInquiryDialogClose}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<GenericDialog fullWidth maxWidth="md" open={isAdjustLimitDialogOpen}>
					<DialogTitle>
						<GenericLabel Bold Text="Adjust Limit" />
					</DialogTitle>
					<DialogContent >
						<GridContainer>
							<GridItem xs={6}>
								<GenericNumberInput
									Disabled
									LabelText="Current Security Amount"
									LabelMd={6}
									Value={model.SecurityBalance} />
								<GenericNumberInput
									Required
									IsInvalid={vModel.AdditionalSecurityAmount}
									Disabled={this.props.ApprovalData}
									Name="AdditionalSecurityAmount"
									LabelText="Additional Security Amount"
									LabelMd={6}
									Value={model.AdditionalSecurityAmount}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={6}>
								<GenericNumberInput
									Disabled
									LabelText="Current Card Limit"
									LabelMd={6}
									Value={model.CurrentCardLimit} />
								<GenericNumberInput
									Required
									IsInvalid={vModel.NewCardLimit}
									Disabled={this.props.ApprovalData}
									Name="NewCardLimit"
									LabelText="New Card Limit"
									LabelMd={6}
									Value={model.NewCardLimit}
									ValueChanged={this.HandleChange} />
							</GridItem>
						</GridContainer>
						<GenericDialogActions>
							<Button size="sm" onClick={this.AdjustLimitConfirm}>Confirm</Button>
							<Button size="sm" onClick={this.AdjustLimitDialogClose}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear },
					{ Code: "Search", OnClick: this.Search, Disabled: ForInsert }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					{alert}
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelFilterActive}
									Title={"Filter Panel"}
									Name={"panelFilterActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridItem xs={12}  >
										<GridContainer>
											{
												(MenuCode == MenuCodes.PaymentAdminLenderProgram || MenuCode == MenuCodes.PaymentAdminCallCenter) &&
												<GridItem xs={3}>
													<GenericSelectInput
														CanClear
														Required={MenuCode == MenuCodes.PaymentAdminLenderProgram || MenuCode == MenuCodes.PaymentAdminCallCenter}
														Name="UniqueClientIdForBackoffice"
														LabelText="Client"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Method="POST"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.GetRenderItemClient}
														Sorted={this.sortedClient}
														Value={model.UniqueClientIdForBackoffice}
														ValueChanged={this.HandleChange} />
												</GridItem>
											}
											<GridItem xs={3} style={{ opacity: model.isGenerateNew ? 0.4 : 1 }}>
												<GenericSelectInput
													Name="ApplicantId"
													LabelText="Applicant"
													Method="POST"
													IsStatic={true}
													StaticData={this.ApplicantList}
													TaskPoolDomain={this.loadTaskPool}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FullName"
													Value={model.ApplicantId}
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
													Required
													IsInvalid={vModel.ApplicantId}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													key={model.ApplicantId}
													Name="Id"
													LabelText="Application Number"
													Method="POST"
													Url={
														this.props.MenuCode == MenuCodes.LOS_LoanApproval ?
															"/losapi/v1.0/Application/SearchForLoanApproval" :
															this.props.MenuCode == MenuCodes.Lms_PaymentAdmin ?
																"/losapi/v1.0/Application/SearchForPaymentAdmin" :
																"/losapi/v1.0/Application/Search"
													}
													Parameter={{
														ApplicantId: this.state.model.ApplicantId
													}}
													DataRoot="Item"
													IsStatic={this.state.model.ApplicantId == null || this.state.model.ApplicantId == 0 ? true : false}
													TaskPoolDomain={this.state.model.ApplicantId > 0 ? this.loadTaskPool : null}
													KeyValueMember="Id"
													LabelMd={6}
													RenderItem={this.RenderItemApplication}
													Value={model.Id}
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
												/>
											</GridItem>
											<GridItem xs={1}>
												<Tooltip title={"Applicant Detail"}
													classes={{ tooltip: classes.tooltip }}
													TransitionComponent={Zoom}>
													<Button
														justIcon
														simple
														onClick={this.ApplicantDialogOpenOrClose}
														size="lg"
														tooltip="Applicant Detail"
														disabled={model.Applicant == null}>
														<img
															style={{
																width: 35,
																height: 35,
																marginTop: -10
															}}
															alt=""
															src={ProfileIcon} />
													</Button>
												</Tooltip>
											</GridItem>
											{MenuCode == MenuCodes.LOS_LoanApproval &&
												<GridItem xs={5}>
													<GridContainer direction="row" justify="flex-end" alignItems="flex-start">
														<GridItem style={{ float: "right" }} xs={2}>
															<Button onClick={() => this.ApplicationStatusDialogOpenOrClose("Approve")} fullWidth color="success" disabled={(model.ApprovalStatusId > 0 && model.ApprovalStatusId <= 3) || isApproved || ForInsert} >Approve</Button>
														</GridItem>
														<GridItem xs={2}>
															<Button onClick={() => this.ApplicationStatusDialogOpenOrClose("Reject")} fullWidth color="youtube" disabled={(model.ApprovalStatusId > 0 && model.ApprovalStatusId <= 3) || isApproved || ForInsert}  >Reject</Button>
														</GridItem>
														<GridItem xs={2}>
															<Button color="warning" onClick={() => this.ApplicationStatusDialogOpenOrClose("On Hold")} fullWidth disabled={(model.ApprovalStatusId > 0 && model.ApprovalStatusId <= 3) || isApproved || ForInsert} >On Hold</Button>
														</GridItem>
													</GridContainer>
												</GridItem>
											}
										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
				</GridContainer>
				<Card className="no-radius"
					style={{
						marginTop: 170
					}} >
					<CardBody>
						<GenericExpansionPanel
							IsActive={this.state.panelActivationStatus.panelInformationActive}
							Title={"Information"}
							Name={"panelInformationActive"}
							ValueChanged={this.HandleChangeFilterPanel}>
							<GridItem xs={12}>
								<LoanApprovalInformation
									model={this.state.model}
									GetClientPartnerAddressModels={null}
									HandleChange={this.HandleChange}
									SystemResult={this.state.SystemResult}
									Score={this.state.Score}
									Verification={this.state.Verification}
									IsUserAdmin={this.state.isUserAdmin}
									ParameterList={this.ParameterList}
									CountryList={this.CountryList}
								/>
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius" >
					<CardBody>
						<GenericExpansionPanel
							IsActive={this.state.panelActivationStatus.panelLoanDetailActive}
							Title={"Loan Details"}
							Name={"panelLoanDetailActive"}
							ValueChanged={this.HandleChangeFilterPanel}>
							<GridContainer>
								<GridItem xs={12}>
									<TabsComponent
										TabIndex={this.state.ParentTabIndex}
										handleChange={(e, a) => this.handleChangeParentTab(e, a, true)}
										tabList={[
											{
												tabButton: "SECURE CARD",
												tabContent: (
													<div>
														<Card className="no-radius" >
															<CardBody>
																<GenericExpansionPanel
																	Title="">
																	<GridItem xs={12}>
																		<SecureCardDetails
																			key={model.SavingsPlanId}
																			model={this.state.model}
																			vModel={this.state.vModel || {}}
																			IsCalculatorVisible={false}
																			GrandTotal={this.state.model != null ? this.state.model.TotalSetupAmount : null}
																			GetClientPartnerAddressModels={null}
																			LoanApprovalFeeList={this.state.LoanApprovalFeeList || []}
																			HandleChange={this.HandleChange}
																			Disabled={this.state.model.Id == 0 || MenuCode === MenuCodes.Lms_PaymentAdmin}
																			isApprovedPeriodDisabled={this.state.isApprovedPeriodDisabled}
																			TaskPoolFunc={this.loadTaskPool}
																			ParameterList={this.ParameterList}
																			FeeProfileList={this.FeeProfileListFiltered}
																			isUserAdmin={this.state.isUserAdmin}
																			AdjustLimit={this.AdjustLimitDialogOpen}
																			UpdateSecureCardDetails={this.UpdateSecureCardDetails}
																		/>
																	</GridItem>
																</GenericExpansionPanel>
															</CardBody>
														</Card>
														<Card className="no-radius" >
															<CardBody>
																<GenericExpansionPanel Title="Statements">
																	<GridItem xs={12}>
																		<LoanApprovalFundingMethod
																			vModel={this.state.vModel || {}}
																			model={this.state.model}
																			HandleChange={this.HandleChange}
																			isUserAdmin={this.state.isUserAdmin}
																			Title={LoanTypeTitle.SecureCard}
																			CheckerDisabled={Disabled == true ? true : this.state.checkerDisabled.secureCardDisabled}
																			Disabled={!this.state.model.Id || this.state.model.Id <= 0}
																			TransferLoanOp={this.TransferLoanOp}
																			ParentTabIndex={this.state.ParentTabIndex}
																			ParameterList={this.ParameterList}
																		/>
																	</GridItem>
																</GenericExpansionPanel>
															</CardBody>
														</Card>
													</div>
												)
											},
											{
												tabButton: "LOC",
												tabContent: (
													<div>
														<Card className="no-radius" >
															<CardBody>
																<GenericExpansionPanel
																	Title="">
																	<GridItem xs={12}>
																		<LocDetails
																			model={this.state.model}
																			vModel={this.state.vModel || {}}
																			IsCalculatorVisible={true}
																			GrandTotal={this.state.model != null ? this.state.model.TotalSetupAmount : null}
																			LoanApprovalFeeList={this.state.LoanApprovalFeeList || []}
																			HandleChange={this.HandleChange}
																			Disabled={this.state.model.Id == 0 || MenuCode === MenuCodes.Lms_PaymentAdmin}
																			ParentTabIndex={this.state.ParentTabIndex}
																			TaskPoolFunc={this.loadTaskPool}
																			ParameterList={this.ParameterList}
																			FeeProfileList={this.FeeProfileListFiltered}
																			isUserAdmin={this.state.isUserAdmin}
																			UpdateLocDetails={this.UpdateLocDetails}
																		/>
																	</GridItem>
																</GenericExpansionPanel>
															</CardBody>
														</Card>
														<Card className="no-radius" >
															<CardBody>
																<GenericExpansionPanel Title="Funding Method">
																	<GridItem xs={12}>
																		<LoanApprovalFundingMethod
																			model={this.state.model}
																			vModel={this.state.vModel || {}}
																			HandleChange={this.HandleChange}
																			Disabled={this.state.model.Id == 0}
																			isUserAdmin={this.state.isUserAdmin}
																			Title={LoanTypeTitle.Loc}
																			CheckerDisabled={Disabled}
																			TransferLoanOp={this.TransferLoanOp}
																			ParentTabIndex={this.state.ParentTabIndex}
																		/>
																	</GridItem>
																</GenericExpansionPanel>
															</CardBody>
														</Card>
													</div>
												)
											},
											{
												tabButton: "TERM LOAN",
												tabContent: (
													<div>
														<Card className="no-radius" >
															<CardBody>
																<GenericExpansionPanel
																	Title="">
																	<GridItem xs={12}>
																		<LoanDetails
																			model={this.state.model}
																			vModel={this.state.vModel || {}}
																			IsCalculatorVisible={true}
																			GrandTotal={this.state.model != null ? this.state.model.TotalSetupAmount : null}
																			GetClientPartnerAddressModels={null}
																			LoanApprovalFeeList={this.state.LoanApprovalFeeList || []}
																			HandleChange={this.HandleChange}
																			LedgerCardDialog={this.LedgerCardDialogOpenOrClose}
																			LoanCalculatorDialog={this.LoanCalculatorDialogOpenOrClose}
																			Disabled={this.state.model.Id == 0 || MenuCode === MenuCodes.Lms_PaymentAdmin}
																			isApproved={this.state.isApproved}
																			TaskPoolFunc={this.loadTaskPool}
																			ParameterList={this.ParameterList}
																			FeeProfileList={this.FeeProfileListFiltered}
																			isUserAdmin={this.state.isUserAdmin}
																			UpdateLoanDetails={this.UpdateLoanDetails}
																		/>
																	</GridItem>
																</GenericExpansionPanel>
															</CardBody>
														</Card>
														<Card className="no-radius" >
															<CardBody>
																<GenericExpansionPanel Title="Funding Method">
																	<GridItem xs={12}>
																		<LoanApprovalFundingMethod
																			model={this.state.model}
																			vModel={this.state.vModel || {}}
																			HandleChange={this.HandleChange}
																			isUserAdmin={this.state.isUserAdmin}
																			Title={LoanTypeTitle.Loan}
																			CheckerDisabled={Disabled == true ? true : this.state.checkerDisabled.loanDisabled}
																			Disabled={!this.state.model.Id || this.state.model.Id <= 0}
																			TransferLoanOp={this.TransferLoanOp}
																			ParentTabIndex={this.state.ParentTabIndex}
																		/>
																	</GridItem>
																</GenericExpansionPanel>
															</CardBody>
														</Card>
													</div>
												)
											}
										]}
									/>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius" >
					<CardBody>
						<GenericExpansionPanel
							IsActive={this.state.panelActivationStatus.panelApprovalDocumentActive}
							Title={"Documents"}
							Name={"panelApprovalDocumentActive"}
							ValueChanged={this.HandleChangeFilterPanel}>
							<GridItem xs={12}>
								<PaymentAdminDocument
									TaskPoolFunc={this.loadTaskPool}
									SetFile={this.SetFile}
									ApplicationModel={this.state.model}
									ApplicationDocumentList={this.state.paramApplicationDocumentList}
									GetApplicationDocumentModels={this.GetApplicationDocumentModels}
									Disabled={ForInsert}
									ParameterList={this.ParameterList} />
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				{
					this.state.isUserAdmin && <Card className="no-radius" >
						<CardBody>
							<GenericExpansionPanel
								IsActive={this.state.panelActivationStatus.panelAccountDetailActive}
								Title={"Wallet Details"}
								Name={"panelAccountDetailActive"}
								ValueChanged={this.HandleChangeFilterPanel}>
								<GridItem xs={12}>
									<PaymentAdminAccountCreate
										ApplicationModel={this.state.model}
										CurrentCardLimit={this.state.model.CurrentCardLimit}
										CreateCustomer={this.CreateCustomer}
										CreateSecurityAccount={this.CreateSecurityAccount}
										CreateWalletAccount={this.CreateWalletAccount}
										CreateCard={this.CreateCard}
										ActivateCard={this.ActivateCard}
										DebtInquiry={this.DebtInquiryDialogOpen}
										HandleChange={this.HandleChange}
										Disabled={ForInsert}
									/>
								</GridItem>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				}
				{
					(!(model.Applicant.CATMCardId == null && model.Applicant.CardFinancialId == null && model.Applicant.CardNumber) && this.state.isUserAdmin) && model.LoanType?.ParameterValue != LOSLoanType.Loan && <Card className="no-radius" >
						<CardBody>
							<GenericExpansionPanel
								IsActive={this.state.panelActivationStatus.panelCardActionActive}
								Title={"Card Actions"}
								Name={"panelCardActionActive"}
								ValueChanged={this.HandleChangeFilterPanel}>
								<GridItem xs={12}>
									<PaymentAdminCardAction
										ExecuteApiPost={this.props.ExecuteApiPost}
										ApplicationModel={this.state.model}
										HandleChange={this.HandleChange}
										CancelProduct={() => { }}
										Disabled={ForInsert}
										history={this.props.history}
									/>
								</GridItem>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				}
				{
					this.state.isUserAdmin && model.LoanType
					&& model.LoanType.ParameterValue != LOSLoanType.Loan
					&& <Card className="no-radius" >
						<CardBody>
							<GenericExpansionPanel
								IsActive={this.state.panelActivationStatus.panelStatementActive}
								Title={"Statement List"}
								Name={"panelStatementActive"}
								ValueChanged={this.HandleChangeFilterPanel}>
								<GridItem xs={12}>
									<GenericGrid
										Data={StatementList}
										//Sorted={this.sortedStatementDate} 
										Columns={this.statemetColumns}
									/>
								</GridItem>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				}
				{
					this.state.isUserAdmin && model.LoanType
					&& model.LoanType.ParameterValue != LOSLoanType.Loan
					&& <Card className="no-radius" >
						<CardBody>
							<GenericExpansionPanel
								IsActive={this.state.panelActivationStatus.panelCurrentTermTransactionActive}
								Title={"Current Term Transaction List"}
								Name={"panelCurrentTermTransactionActive"}
								ValueChanged={this.HandleChangeFilterPanel}>
								<GridItem xs={12}>
									<GenericGrid
										Data={CurrentTermTransactionList}
										Columns={this.columnsCurrentTermTransactionList}
									/>
								</GridItem>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				}
				{
					this.state.isUserAdmin && model.LoanType
					&& model.LoanType.ParameterValue == LOSLoanType.Loan
					&& <Card className="no-radius" >
						<CardBody>
							<GenericExpansionPanel
								IsActive={this.state.panelActivationStatus.panelStatementActive}
								Title={"Personal Loan Payment Status List"}
								Name={"panelStatementActive"}
								ValueChanged={this.HandleChangeFilterPanel}>
								<GridItem md={12} xs={12}>
									<GenericGrid
										Data={LedgerCardList}
										Sorted={this.sortedInstallmentDate}
										Columns={this.LedgerCardColumns}
									/>
								</GridItem>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				}
				{
					(this.state.isUserAdmin || this.state.isLoanApprovalScreen) && <Card className="no-radius" >
						<CardBody>
							<GenericExpansionPanel
								IsActive={this.state.panelActivationStatus.panelApplicationNoteActive}
								Title={"Note History"}
								Name={"panelApplicationNoteActive"}
								ValueChanged={this.HandleChangeFilterPanel}>
								<GridItem xs={12}>
									<PaymentAdminApplicationNoteHistory
										key={this.state.model.Id}
										TaskPoolFunc={this.loadTaskPool}
										ApplicationModel={this.state.model}
										showMessage={this.ShowMessage}
										handleLoadTaskPoolAppend={this.handleLoadTaskPoolAppend}
										handleLoadTaskPoolCompleted={this.handleLoadTaskPoolCompleted}
										Disabled={ForInsert} />
								</GridItem>
							</GenericExpansionPanel>
						</CardBody>
					</Card>
				}
			</div >
		);
	}
}

LoanApproval.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(LoanApproval, {});