import { DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericPage, GenericTitle, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction,GridColumnType } from "views/Constants/Constant.js";
import MessageDetail from "views/WireTransfer/CreateWireTransfer/MessageDetail";


class UnSettledWireTransfer extends GenericPage {

	constructor(props) {


		super(props);

		this.sortedName = { Member: "Name" };
		this.emptyObject = {};
		this.renderItemUniqueClient = {};
		this.renderItemAccount = {};

		this.columns = [
			{
				Header: "Beneficiary Name",
				accessor: "BeneficiaryName"
			},
			{
				Header: "Benefiary Wallet Number",
				accessor: "BeneficiaryAccountNumber"
			},
			{
				Header: "Swift Reference Number",
				accessor: "SwiftReferenceNumber",
			},
			{
				Header: "Date of Request",
				accessor: "DateRequest",
				Cell: row => (
					<div>{Formatter.FormatDateUtc(row.value)}</div>
				)
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective",
				Cell: row => (
					<div>{Formatter.FormatDateUtc(row.value)}</div>
				)
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{ 
				Header: "Remittance",
				accessor: "Remittance"
			},
			{
				Header: "Beneficiary Identifier",
				accessor: "BeneficiaryIdentifier"
			},
			
			{
				Header: "Bank Opearation Code",
				accessor: "BankOperationCode"
			},
			{
				Header: "Sender Wallet Number",
				accessor: "SenderAccountNumber"
			},
			{
				Header: "Sender Swift Code",
				accessor: "SenderSwiftCode"
			},
			{
				Header: "Sender Name",
				accessor: "SenderName"
			},
			{
				Header: "Sender Address",
				accessor: "SenderAddress"
			},
			{
				Header: "Sender Additional Information",
				accessor: "SenderAdditionalInformation"
			},
			{
				Header: "Ordering Institution Swift Code",
				accessor: "OrderingInstitutionSwiftCode"
			},
			{
				Header: "Ordering Institution Party Identifier",
				accessor: "OrderingInstitutionPartyIdentifier"
			},
			{
				Header: "Ordering Institution Party Address",
				accessor: "OrderingInstitutionAddress"
			},
			{
				Header: "Intermediary Name",
				accessor: "IntermediaryName"
			},
			{
				Header: "Beneficiary Bank Party Identifier",
				accessor: "BeneficiaryBankPartyIdentifier"
			},
			{
				Header: "Details Of Charges",
				accessor: "DetailsOfCharges"
			},
			{
				Header: "Sender Party Identifier Wallet",
				accessor: "WireTransferMessageSenderParty.Account"
			},
			{
				Header: "Sender Party Identifier Code",
				accessor: "WireTransferMessageSenderParty.Code"
			},
			{
				Header: "Sender Party Identifier CountryCode",
				accessor: "WireTransferMessageSenderParty.CountryCode"
			},
			{
				Header: "Sender Party Identifier Identifier",
				accessor: "WireTransferMessageSenderParty.Identifier"
			},


		];



		this.defaultMessageModel = {
			CustomerName: null,
			AccountNumber: null,
			DateOfRequest: null,
			CreationDate: null,
			ValueDate: null,
			BeneficiaryCompanyName: null,
			BeneficiaryFirstName: null,
			BeneficiaryLastName: null,
			BeneficiaryAddress: null,
			BeneficiaryAddress2: null,
			CustomerAddress: null,
			BeneficiaryCountry: null,
			BeneficiaryCity: null,
			BeneficiaryProvince: null,
			BeneficiaryPostalCode: null,
			BeneficiaryBankCountry: null,
			BeneficiaryBankPostalCode: null,
			BeneficiaryBankInstitution: null,
			Remittance: null,
			BeneficiaryAccountNumber: null,
			IntermediarySwiftCode: null,
			Amount: null,
			Currency: null,
			PurposeOfWire: null,
			Reference: null,
			BeneficiaryBank: null,
			BeneficiaryBankAddress: null,
			BeneficiaryBankAddress2: null,
			BeneficiaryBankCity: null,
			BeneficiaryBankProvince: null,
			TransitNumber: null,
		};

		this.defaultModel = {
			ClientId: null,
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			DirectionParamValue: Direction.Incoming,
			MessageTypeId: null,
			SubStatusId: null,
			StatusId: null,
			AmountFrom: null,
			AmountTo: null,
			StatusParamCode: null,
			AccountId: null,
			UniqueClientId: null,
			SwiftReferenceNumber: null,
			TransactionReferenceNumber: null

		};

		this.state = {
			model: { ...this.defaultModel },
			subStatusList: [],
			tempSubStatusList: [],
			transactionList: [],
			approvalTransactionList: [],
			isLoading: false,
			isBackOffice: false,
			messageDetailModel: {},
			IsDomestic: {},
			detailDialog: false,
			approvalDialog: false,
			matchDialog: false
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		this.props.setAppLeftTitle("Un-Settled Transactions");
		this.props.setAppCenterTitle("Wire Transfer");
	}

	HandleChange(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "StatusId") {
				if (newValue == -1) {
					model.StatusParamCode = null;
				}
				else if (data.ParameterValue == null) {
					model.StatusParamCode = null;
				}
				else {
					model.StatusParamCode = data.ParameterValue;
				}
			}
			return { model };
		});
	}


	HandleMessageChange(name, newValue) {
		const { messageDetailModel } = this.state;
		messageDetailModel[name] = newValue;
		this.setState({ messageDetailModel });
	}

	GetData() {
		this.ExecutePostRequest(
			"/bankapi/v1.0/WireTransfer/UnSettledIncomingTransactionSearch",
			this.state.model,
			responseData => {
				if (responseData.Item == null || responseData.Item.length < 1) {
					responseData.Item = [];
				}

				this.setState({ transactionList: responseData.Item });
			}
		);
	}

	ClearModel() {
		const model = { ...this.state.model };
		var clientId = this.state.isBackOffice ? null : model.ClientId;
		var newModel = { ...this.defaultModel };
		newModel.ClientId = clientId;
		this.setState({ model: newModel, transactionList: [] });
	}


	HandleClose() {
		this.CloseDialog();
	}

	CloseDialog() {
			this.setState({ detailDialog: false, approvalDialog: false, matchDialog: false, model: this.defaultModel, messageDetailModel: this.defaultMessageModel });
	}

	HandleOpenDetail(templateId, transferId, isApproval) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/SearchMessage",
			{ Id: templateId },
			responseData => {
				const tempModel = { ...responseData.Item };
				tempModel.TransferId = transferId;
				tempModel.TempOrderingInstitutionSwiftCode = tempModel.OrderingInstitutionSwiftCode;
				this.setState({
					messageDetailModel: tempModel,
					detailDialog: true,
					approvalDialog: isApproval
				});
			});
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	}

	GetRenderItemAccount = (x) => {
		this.renderItemAccount = `${x.AccountNumber} - ${x.AccountName}`;
		return this.renderItemAccount;
	}

	HandleMatchDialog = () => {
		this.setState({ matchDialog: false });
	}

	render() {
		const { Disabled } = this.props;
		const { IsLoading, Alert } = this.state;
		const { model, messageDetailModel,  detailDialog,transactionList } = this.state;



		const renderTransactionColumns = [
			 ...this.columns];
		return (
			<div >
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<GenericDialog open={detailDialog} maxWidth="xl" onBackdropClick={this.HandleClose}>
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Transaction Details</b></h4>
						<br />
						<MessageDetail
							CustomerName={messageDetailModel.CustomerName}
							AccountNumber={messageDetailModel.AccountNumber}
							DateOfRequest={messageDetailModel.DateOfRequest}
							CreationDate={messageDetailModel.CreationDate}
							ValueDate={messageDetailModel.ValueDate}
							BeneficiaryName={messageDetailModel.BeneficiaryName}
							BeneficiaryAddress={messageDetailModel.BeneficiaryAddress}
							BeneficiaryAddress2={messageDetailModel.BeneficiaryAddress2}
							CustomerAddress={messageDetailModel.CustomerAddress}
							BeneficiaryCountry={messageDetailModel.BeneficiaryCountry}
							BeneficiaryCity={messageDetailModel.BeneficiaryCity}
							BeneficiaryProvince={messageDetailModel.BeneficiaryProvince}
							BeneficiaryPostalCode={messageDetailModel.BeneficiaryPostalCode}
							BeneficiaryBankCountry={messageDetailModel.BeneficiaryBankCountry}
							BeneficiaryBankPostalCode={messageDetailModel.BeneficiaryBankPostalCode}
							BeneficiaryBankInstitution={messageDetailModel.BeneficiaryBankInstitution}
							Remittance={messageDetailModel.Remittance}
							BeneficiaryAccountNumber={messageDetailModel.BeneficiaryAccountNumber}
							IntermediarySwiftCode={messageDetailModel.IntermediarySwiftCode}
							Amount={messageDetailModel.Amount}
							Currency={messageDetailModel.Currency}
							PurposeOfWire={messageDetailModel.PurposeOfWire}
							Reference={messageDetailModel.Reference}
							BeneficiaryBank={messageDetailModel.BeneficiaryBank}
							BeneficiaryBankAddress={messageDetailModel.BeneficiaryBankAddress}
							BeneficiaryBankAddress2={messageDetailModel.BeneficiaryBankAddress2}
							BeneficiaryBankCity={messageDetailModel.BeneficiaryBankCity}
							BeneficiaryBankProvince={messageDetailModel.BeneficiaryBankProvince}
							TransitNumber={messageDetailModel.TransitNumber}
							BeneficiaryIdentifier={messageDetailModel.BeneficiaryIdentifier}
							BeneficiaryBankPartyIdentifier={messageDetailModel.BeneficiaryBankPartyIdentifier}
							OrderingInstitutionSwiftCode={messageDetailModel.OrderingInstitutionSwiftCode}
							TempOrderingInstitutionSwiftCode={messageDetailModel.TempOrderingInstitutionSwiftCode}

							SenderName={messageDetailModel.SenderName}
							SenderAccountNumber={messageDetailModel.SenderAccountNumber}
							SenderSwiftCode={messageDetailModel.SenderSwiftCode}
							SenderAddress={messageDetailModel.SenderAddress}
							SenderAdditionalInformation={messageDetailModel.SenderAdditionalInformation}

							OrderingInstitutionPartyIdentifier={messageDetailModel.OrderingInstitutionPartyIdentifier}
							OrderingInstitutionAddress={messageDetailModel.OrderingInstitutionAddress}

							LastUser={messageDetailModel.LastUser}
							SubStatus={messageDetailModel.SubStatus}

							ValueChanged={this.HandleMessageChange}
							Disabled={true} />
						<br />
					</DialogContent>
				
				</GenericDialog>

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={true}>
							<GridContainer>
								<GridItem xs={3}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Utc={true}
												Value={model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.HandleChange}
												MaxDate={model.ToDate || DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Utc={true}
												Value={model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate == null ? null : model.FromDate}
												MaxDate={DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
									</GridContainer>
								</GridItem>
								
								<GridItem xs={3}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountFrom"
												LabelText="Amount From"
												Value={model.AmountFrom == null ? "" : model.AmountFrom}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountTo"
												LabelText="Amount To"
												Value={model.AmountTo == null ? "" : model.AmountTo}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={3}>
									<GenericTextInput Name="SwiftReferenceNumber" LabelText="Swift Reference Number" Value={model.SwiftReferenceNumber} ValueChanged={this.HandleChange} />
								</GridItem>
								<br />
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={transactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={renderTransactionColumns} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >
		);
	}
}

UnSettledWireTransfer.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(UnSettledWireTransfer, {});