import ClientHelper from "core/ClientHelper";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

const UserConstants = {
	LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
	LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
	LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
	LOGOUT: "USERS_LOGOUT",
	CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
};

var user = ClientHelper.GetUser();
const initialState = user ? { loggedIn: true, user } : { loggedIn: false };

function UserContext(state, action) {
	switch (action.type) {
		case UserConstants.LOGIN_REQUEST:
			return {
				loggingIn: true,
				user: action.user
			};
		case UserConstants.LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: action.user
			};
		case UserConstants.LOGIN_FAILURE:
			return {};
		case UserConstants.LOGOUT:
			return {
				loggedIn: false
			};
		default:
			return state ?? initialState;
	}
}

const rootReducer = combineReducers({
	UserContext
});

const store = createStore(
	rootReducer,
	applyMiddleware(
		thunkMiddleware,
	)
);
export { UserConstants, store };
