import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

import { Proxy } from "core";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui core components
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton
} from "@material-ui/core";

// custom component
import Button from "components/CustomButtons/Button.jsx";

// generic components
import { GenericTextInput, GenericNumberInput } from "views/Components/Generic";

// @material-ui icons
import { Search as SearchImg } from "@material-ui/icons";

class CommissionProfileComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {},
			dialog: {
				isOpen: false,
				dialogData: []
			}
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleClickInside = this.handleClickInside.bind(this);

		this.getCommissionProfileCallback = this.getCommissionProfileCallback.bind(this);
		this.selectCommissionProfileCallback = this.selectCommissionProfileCallback.bind(this);
		this.cancelSelectCommissionProfileCallback = this.cancelSelectCommissionProfileCallback.bind(this);
	}

	handleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleClick() {
		Proxy.POST("/bankapi/v1.0/CommissionProfile/Search", {
			Id: this.props.profileId,
			ProfileName: this.props.profileName
		}, this.getCommissionProfileCallback);
	}

	handleClickInside() {
		Proxy.POST("/bankapi/v1.0/CommissionProfile/Search",
			this.state.model,
			this.getCommissionProfileCallback);
	}

	getCommissionProfileCallback(responseData) {
		if (responseData.Item != null) {
			this.setState({
				dialog: {
					isOpen: true,
					dialogData: responseData.Item
				}
			});
		} else {
			this.setState({ dialogData: [] });
		}
	}

	selectCommissionProfileCallback(commissionProfile) {
		this.props.fillCallback(commissionProfile);
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			}
		});
	}

	cancelSelectCommissionProfileCallback() {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			}
		});
	}

	render() {
		var { buttonProps } = this.props;
		return (
			<div>
				<GridContainer>
					<GridItem xs={12} sm={5}>
						<GenericNumberInput
							Name="ProfileId"
							LabelText="Profile Id"
							ThousandSeparator=""
							Value={this.props.profileId}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={12} sm={5}>
						<GenericTextInput
							Name="ProfileName"
							LabelText="Profile Name"
							Value={this.props.profileName}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={12} sm={2}>
						<IconButton size="sm" component="span">
							<SearchImg {...buttonProps} onClick={this.handleClick} />
						</IconButton>
					</GridItem>
				</GridContainer>
				<Dialog open={this.state.dialog.isOpen}>
					<DialogTitle>Select Commission Profile</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12} sm={5}>
								<GenericNumberInput
									Name="ProfileId"
									LabelText="Profile Id"
									ThousandSeparator=""
									Value={this.state.model.ProfileId}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={12} sm={5}>
								<GenericTextInput
									Name="ProfileName"
									LabelText="Profile Name"
									Value={this.state.model.ProfileName}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={12} sm={2}>
								<IconButton size="sm" component="span">
									<SearchImg onClick={this.handleClickInside} />
								</IconButton>
							</GridItem>
						</GridContainer>
						<ReactTable
							data={this.state.dialog.dialogData.map(d => {
								return {
									Id: d.Id,
									ProfileName: d.ProfileName,
									actions: (
										<div>
											<Button
												size="sm"
												onClick={() => {
													this.selectCommissionProfileCallback(d);
												}}
											>
												Select
											</Button>
										</div>
									)
								};
							})
							}
							filterable
							columns={[
								{
									Header: "Profile Id",
									accessor: "Id",
									width: 100
								},
								{
									Header: "Profile Name",
									accessor: "ProfileName"
								},
								{
									Header: "Actions",
									accessor: "actions",
									sortable: false,
									filterable: false,
									resizable: false
								}
							]}
							defaultPageSize={10}
							showPaginationTop={false}
							showPaginationBottom={this.state.dialog.dialogData !== undefined ? Object.keys(this.state.dialog.dialogData).length > 50 : false}
							minRows={2}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							size="sm"
							onClick={this.cancelSelectCommissionProfileCallback}
						>
						Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

CommissionProfileComponent.propTypes = {
	buttonProps: PropTypes.object,
	handleChange: PropTypes.func
};

export default CommissionProfileComponent;
