import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";



class ManualKycVerificationDefinition extends React.Component {
	constructor(props) {
		super(props);
		this.initialState = {
			model: {},
			filterModel: {},
			vModel: {},
			modelList: []
		};

		this.state = {
			clientList: []
		};
		this.state = Object.assign(this.state, this.initialState);
		this.emptyObject = {};
		this.gridSortParameter = [{ id: "Status", desc: false }];
		this.sortedName = { Member: "Name" };
		this.columnsData = [
			{
				Header: "Client Name",
				accessor: "ClientName",
			},

			{
				Header: "Internal Blacklist",
				accessor: "VerifyInternalBlacklist",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			}, {
				Header: "External BlackList",
				accessor: "VerifyExternalBlacklist",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},
			{
				Header: "TransUnion",
				accessor: "VerifyTransUnion",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},
			{
				Header: "Canada Post",
				accessor: "VerifyCanadaPost",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},
			{
				Header: "Documents",
				accessor: "VerifyDocuments",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},
			{
				Header: "Wallet",
				accessor: "VerifyAccountVerification",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},
			{
				Header: "Digital ID",
				accessor: "VerifyDigitalId",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},
			{
				Header: "In Person Id",
				accessor: "VerifyInPersonId",
				Cell: d =>
					<GenericCheckInput
						Grid
						Value={d.value}
						Disabled={true}
					/>
			},

		];
		var apiUrlBase = "/amlkycapi/v1.0/KycManualApplicationVerificationDefinition/";
		this.apiList = {
			InsertOrUpdate: apiUrlBase + "InsertOrUpdate",
			Search: apiUrlBase + "Search",
			Delete: apiUrlBase + "Delete",
			ClientSearch: "/bankapi/v1.0/BankCustomer/Search"
		};
	}
	componentDidMount() {
		this.props.setAppCenterTitle && this.props.setAppCenterTitle("AML & KYC");
		this.props.setAppLeftTitle && this.props.setAppLeftTitle("Manual KYC Application Verification Definition");

		this.GetClientList();
	}
	GetClientList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(this.apiList.ClientSearch, {});
		this.setState({ clientList: result || [] });
	}
	GetFilteredClientList = () => {
		const { clientList, modelList } = this.state;

		let filtered = clientList.filter(a => {

			for (let index = 0; index < modelList.length; index++) {
				const model = modelList[index];
				if (a.Id == model.UniqueClientId) {
					return false;
				}
			}
			return true;
		});
		return filtered;
	}
	RenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;

		return this.renderItemUniqueClient;
	}
	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}
	HandleChangeFilter = (name, newValue, data) => {
		const filterModel = { ...this.state.filterModel };
		filterModel[name] = newValue === false ? null : newValue;
		this.setState({ filterModel });
	}
	SelectedRowChange = (index) => {
		const model = this.state.modelList[index];
		this.setState({ model: model });
	}
	HandleSearch = async () => {
		const { ExecuteApiPost } = this.props;
		const { filterModel } = this.state;
		var request = {
			...filterModel
		};
		var result = await ExecuteApiPost(this.apiList.Search, request);
		if (result) {
			this.setState({ modelList: result });
		}
	}
	HandleSubmit = async () => {
		this.InsertOrUpdate();
	}
	HandleUpdate = () => {
		this.InsertOrUpdate();
	}
	OperationCheck = () => {
		const { model } = this.state;

		var messages = [];
		var vModel = {};


		if ((vModel.UniqueClientId = (isNaN(model?.UniqueClientId) || model?.UniqueClientId <= 0))) messages.push("Client cannot be empty");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}

		this.setState({ vModel });
		return messages.length == 0;
	}
	HandleDelete = async () => {
		const { ExecuteApiPost } = this.props;
		const { model } = this.state;

		if (!await this.props.showConfirmMessage("warning", "warning", "KYC Manual Application Verification Definition will be delete. Are you sure?")) return;

		const request = {
			Id: model.Id
		};
		var result = await ExecuteApiPost(this.apiList.Delete, request);
		if (result > 0) {
			this.props.showMessage("success", "Success", "KYC Manual Application Verification Definition Deleted Successfully.");
			this.HandleClear();
			this.HandleSearch();
		}
	}
	HandleClear = () => {
		var defaultState = Object.assign({}, this.initialState);
		this.setState(defaultState);
	}
	InsertOrUpdate = async () => {
		if (!this.OperationCheck()) {
			return;
		}

		const { ExecuteApiPost } = this.props;
		const { model } = this.state;
		var request = {
			...model
		};
		var result = await ExecuteApiPost(this.apiList.InsertOrUpdate, request);
		if (result) {
			await this.props.showMessage("success", "Success", `KYC Manual Application Verification Definition ${result?.Id > 0 ? "Updated" : "Saved"} Successfully.`);
			this.HandleClear();
			this.HandleSearch();
		}

	}
	render() {
		const { model, filterModel, vModel, modelList, clientList } = this.state;
		const { Disabled } = this.props;

		return (
			<>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || (model?.Id > 0),
							OnClick: this.HandleSubmit
						},
						{
							Code: "Update",
							Disabled: Disabled || !model?.Id,
							OnClick: this.HandleUpdate
						},
						{
							Code: "Delete",
							Disabled: Disabled || !model?.Id,
							OnClick: this.HandleDelete
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<GenericExpansionPanel
								IsActive={true}
								Title="Filter Panel">
								<CardBody>
									<GridContainer >
										<GridItem xs={3}>
											<GenericSelectInput
												IsStatic
												StaticData={clientList}
												Name="UniqueClientId"
												LabelText="Client"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.RenderItemUniqueClient}
												Sorted={this.sortedName}
												Value={filterModel.UniqueClientId}
												ValueChanged={this.HandleChangeFilter}
												LabelMd={3} />
										</GridItem>
										<GridItem xs={9}>
											<GridContainer >
												<GridItem xs={6}>
													<GridContainer >
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyInternalBlacklist"
																LabelText={"Internal Blacklist"}
																Value={filterModel.VerifyInternalBlacklist}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyExternalBlacklist"
																LabelText={"External Blacklist"}
																Value={filterModel.VerifyExternalBlacklist}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyTransUnion"
																LabelText={"Trans Union"}
																Value={filterModel.VerifyTransUnion}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyCanadaPost"
																LabelText={"Canada Post"}
																Value={filterModel.VerifyCanadaPost}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem xs={6}>
													<GridContainer >
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyDocuments"
																LabelText={"Documents"}
																Value={filterModel.VerifyDocuments}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyAccountVerification"
																LabelText={"Wallet"}
																Value={filterModel.VerifyAccountVerification}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>

														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyDigitalId"
																LabelText={"Digital ID"}
																Value={filterModel.VerifyDigitalId}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyInPersonId"
																LabelText={"In Person ID"}
																Value={filterModel.VerifyInPersonId}
																ValueChanged={this.HandleChangeFilter} />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card>
							<GenericExpansionPanel
								IsActive={true}
								Title="Manual Kyc Verification Definition">
								<CardBody>
									<GridContainer >
										<GridItem xs={3}>
											<GenericSelectInput
												IsStatic
												StaticData={model?.Id > 0 ? clientList : this.GetFilteredClientList()}
												Name="UniqueClientId"
												LabelText="Client"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.RenderItemUniqueClient}
												Sorted={this.sortedName}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												Required={true}
												Disabled={model?.Id > 0}
												IsInvalid={vModel.UniqueClientId}
												LabelMd={3} />
										</GridItem>
										<GridItem xs={9}>
											<GridContainer >
												<GridItem xs={6}>
													<GridContainer >
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyInternalBlacklist"
																LabelText={"Internal Blacklist"}
																Value={model.VerifyInternalBlacklist}
																ValueChanged={this.HandleChange} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyExternalBlacklist"
																LabelText={"External Blacklist"}
																Value={model.VerifyExternalBlacklist}
																ValueChanged={this.HandleChange} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyTransUnion"
																LabelText={"Trans Union"}
																Value={model.VerifyTransUnion}
																ValueChanged={this.HandleChange} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyCanadaPost"
																LabelText={"Canada Post"}
																Value={model.VerifyCanadaPost}
																ValueChanged={this.HandleChange} />
														</GridItem>

													</GridContainer>
												</GridItem>
												<GridItem xs={6}>
													<GridContainer >
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyDocuments"
																LabelText={"Documents"}
																Value={model.VerifyDocuments}
																ValueChanged={this.HandleChange} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyAccountVerification"
																LabelText={"Wallet"}
																Value={model.VerifyAccountVerification}
																ValueChanged={this.HandleChange} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyDigitalId"
																LabelText={"Digital ID"}
																Value={model.VerifyDigitalId}
																ValueChanged={this.HandleChange} />
														</GridItem>
														<GridItem xs={3}>
															<GenericCheckInput
																Name="VerifyInPersonId"
																LabelText={"In Person ID"}
																Value={model.VerifyInPersonId}
																ValueChanged={this.HandleChange} />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"ManualKycVerificationDefinitionPanel"}
									Title="Manual Kyc Verification Definition List">
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={this.gridSortParameter}
										Columns={this.columnsData}
										RowSelected={this.SelectedRowChange}
									/>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</ >
		);
	}
}

ManualKycVerificationDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default ManualKycVerificationDefinition;