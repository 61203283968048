import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericCheckInput } from "views/Components/Generic";
import { ProgramCodes, IdentityType } from "views/Constants/Constant.js";
import ParameterComponent from "views/Components/ParameterComponent";
import CardBody from "components/Card/CardBody";
import { IdentificationMethod } from "views/Constants/Constant";

class CustomerInformationComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			identificationMethodIds: [],

		};

		this.maxLength20 = { maxLength: 20 };
		this.maxLength30 = { maxLength: 30 };
		this.maxLength64 = { maxLength: 64 };
		this.maxLength100 = { maxLength: 100 };
		this.marginLeft2 = { marginLeft: 2 };

		this.emptyObject = {};

		this.ParameterClientType = {
			ParameterCode: "ClientType",
			ParameterValue3: "D"
		};

		this.ParameterIdentificationMethod = {
			ParameterCode: "IdentificationMethod",
			ParameterValue2: "Active"
		};

		this.ParameterIdentityType = {
			ParameterCode: "IdentityType2",
			ParameterValue2: "PrimaryId"
		};

		this.ParameterProvincialGovermentPhotoId = {
			ParameterCode: "ProvincialGovermentPhotoId"
		};

		this.ParameterIdentitySecondary = {
			ParameterCode: "IdentityType2"
		};

		this.ParameterOccupation = {
			ParameterCode: "Occupation"
		};

		this.ParameterResidencyCountry = {
			ParameterCode: "ResidencyCountry"
		};

		this.ParameterLanguage = {
			ParameterCode: "Language"
		};

		this.ParameterYesNo = {
			ParameterCode: "YesNo"
		};

		this.ParameterGender = {
			ParameterCode: "Gender"
		};

		this.ParameterCustomerStatus = {
			ParameterCode: "CustomerStatus"
		};

		this.ParameterIntendedUse = {
			ParameterCode: "IntendedUse"
		};

		this.ParameterCreditAgency = {
			ParameterCode: "CreditAgency"
		};

		this.ParameterPrimarySourceForDualProcessId = {
			ParameterCode: "DocumentForDualProcessIdentification",
			ParameterValue2: "PrimaryDocument"
		};

		this.ParameterSecondarySourceForDualProcessId = {
			ParameterCode: "DocumentForDualProcessIdentification",
		};

		this.sortedOrderIndex = { Member: "OrderIndex" };
		this.sortedParameterDesc = { Member: "ParameterDesc" };

		this.renderItemPhoneCode = {};

		this.retiredOrUnemployedOccupationIds = [];
		this.specificIdentityTypesIds = [];

	}

	componentDidMount() {	
		
	}

	componentWillMount() {
		this.GetIdentificationMethodIds();
	}

	RenderItemPhoneCode = (d) => {
		this.renderItemPhoneCode = "+" + d.Code + " (" + d.Name + ")";
		return this.renderItemPhoneCode;
	}

	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === "" 
									 || value === "\"\"" || value === "\"\"");
	}

	GetIdentificationMethodIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "IdentificationMethod",
				ParameterValue2: "Active"			
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
				let methods = [];
				for(var i=0; i<list.length; i++) {
					let method = {
						"Id" :	list[i].Id,
						"Value" : list[i].ParameterValue
					};
					methods.push(method);	
				}
				this.setState({identificationMethodIds: methods});
				this.OnLoadedIDMethods();				
			}			
		);	
	}

	OnLoadedIDMethods() {
		this.GetRetiredOrUnemployedOccupationIds();
		this.GetSpecificIdentityTypesIds();
	}

	GetSpecificIdentityTypesIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "IdentityType2",
				ParameterValue: "ID010"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;												
				list.forEach(element => {
					this.specificIdentityTypesIds.push(element.Id);
				});	
			}
		);
	}

	GetRetiredOrUnemployedOccupationIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "Occupation"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
				list = list.filter(x=>x.ParameterValue == "Retired"
												|| x.ParameterValue == "UnemployedSocialAssistance"
												|| x.ParameterValue == "UnemploymentAssistance");
				
				list.forEach(element => {
					this.retiredOrUnemployedOccupationIds.push(element.Id);
				});	
			}
		);
	}

	render() {
		const { model, tempModel, vModel, wCheckModel, Title, ReadOnly, Disabled, ForInsert } = this.props;
		
		var IsIncludeEFT = model.Programs.includes(ProgramCodes.Bank_Programs_EFT);
		var IsIncludeET = model.Programs.includes(ProgramCodes.Bank_Programs_e_Transfer);
		var IsIncludeCDIC = model.Programs.includes(ProgramCodes.Bank_Programs_CDIC);
		var IsIncludePrepaid = model.Programs.includes("WALLET");
		var IsIncludeLender = model.Programs.includes(ProgramCodes.Lender);
		var IsFilledPhoneNumber = model.PhoneCountryCodeId > 0 && model.PhoneNumber;

		var disabledIdVerification = !(tempModel.isPersonal && (IsIncludePrepaid));
		
		model.IdentityVerifyingPersonId = ClientHelper.GetUser().Id; 
				
		return (
			<>
				<GenericExpansionPanel Title={Title}>
					<GridContainer>
						<GridItem xs={4}>
							<GenericTitle text={"Customer General Information"} />
							<>
								<GridItem>
									<GenericSelectInput
										Name="ClientTypeId"
										LabelText="Customer Type"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterClientType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.ClientTypeId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly || model.Id > 0}
										Required = {!ReadOnly}
										IsInvalid={vModel.ClientType}
										AfterFetchCallback={this.props.AfterFetchCallback} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="CustomerStatusId"
										LabelText="Customer Status"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterCustomerStatus}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.CustomerStatusId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required = {(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.CustomerStatus}/>
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="IntendedUseId"
										LabelText="Intended Use"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterIntendedUse}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.IntendedUseId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required = {(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.IntendedUse}
										RenderItem={d => `${d.ParameterValue} (${d.ParameterDesc})`}/>
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === true ? 0.4 : 1 }}>
									<GenericTextInput
										Name="CompanyLegalName"
										LabelText="Legal Name"
										Value={model.CompanyLegalName}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === true || ReadOnly}
										Required={tempModel.isPersonal === false && !ReadOnly}
										IsInvalid={vModel.CompanyLegalName}
										inputProps={this.maxLength30} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === true ? 0.4 : 1 }}>
									<GenericTextInput
										Name="BusinessIdNumber"
										LabelText="Business ID Nr. (BIN)"
										Value={model.BusinessIdNumber}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === true || ReadOnly}
										Required={tempModel.isPersonal === false && (IsIncludeCDIC || IsIncludeEFT || IsIncludeET || IsIncludeLender || IsIncludePrepaid)
																							  && !ReadOnly}
										IsInvalid={vModel.BusinessIdNumber}
										inputProps={this.maxLength30} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === true ? 0.4 : 1 }}>
									<GenericTextInput
										Name="BusinessName"
										LabelText="Business Name"
										Value={model.BusinessName}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === true || ReadOnly}
										Required={tempModel.isPersonal === false && !ReadOnly}
										IsInvalid={vModel.BusinessName}
										inputProps={this.maxLength30} />
								</GridItem>
								{IsIncludeET &&
									<>
										<GridItem style={{ opacity: tempModel.isPersonal === true ? 0.4 : 1 }}>
											<GenericTextInput
												Name="CompanyName"
												LabelText="Company Name"
												Value={model.CompanyName}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === true || ReadOnly}
												Required={tempModel.isPersonal === false && !ReadOnly}
												IsInvalid={vModel.CompanyName}
												inputProps={this.maxLength30} />
										</GridItem>
										<GridItem style={{ opacity: tempModel.isPersonal === true ? 0.4 : 1 }}>
											<GenericTextInput
												Name="RegistrationName"
												LabelText="Registration Name"
												Value={model.RegistrationName}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === true || ReadOnly}
												Required={tempModel.isPersonal === false && !ReadOnly}
												IsInvalid={vModel.RegistrationName}
												inputProps={this.maxLength30} />
										</GridItem>
										<GridItem style={{ opacity: tempModel.isPersonal === true ? 0.4 : 1 }}>
											<GenericTextInput
												Name="RetailName"
												LabelText="Retail Name"
												Value={model.RetailName}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === true || ReadOnly}
												Required={tempModel.isPersonal === false && !ReadOnly}
												IsInvalid={vModel.RetailName}
												inputProps={this.maxLength30} />
										</GridItem>
									</>
								}
								<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
									<GenericTextInput
										Name="Name"
										LabelText="First Name"
										Value={model.Name}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === false || ReadOnly}
										Required={tempModel.isPersonal === true && !ReadOnly}
										IsInvalid={vModel.FirstName}
										inputProps={this.maxLength30} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
									<GenericTextInput
										Name="MiddleName"
										LabelText="Middle Name"
										Value={model.MiddleName}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === false || ReadOnly}
										inputProps={this.maxLength30} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
									<GenericTextInput
										Name="SurName"
										LabelText="Last Name"
										Value={model.SurName}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === false || ReadOnly}
										Required={tempModel.isPersonal === true && !ReadOnly}
										IsInvalid={vModel.LastName}
										inputProps={this.maxLength30} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
									<GenericDateInput
										Name="BirthDate"
										LabelText="Date of Birth"
										Value={model.BirthDate ? DateHelper.ToDateInputValue(model.BirthDate) : ""}
										ValueChanged={this.props.handleChange}
										IncludeTime={false}
										IsPast={true}
										Utc={true}
										Disabled={tempModel.isPersonal === false || ReadOnly}
										Required={tempModel.isPersonal === true && (IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.BirthDate} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
									<GenericSelectInput
										Name="GenderId"
										LabelText="Gender"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterGender}
										DataRoot="Item"
										Required={tempModel.isPersonal === true && (IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.GenderId}
										ValueChanged={this.props.handleChange}
										IsInvalid={vModel.Gender}
										Disabled={tempModel.isPersonal === false || ReadOnly} />
								</GridItem>
								<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
									<GenericSelectInput
										Name="OccupationId"
										LabelText="Occupation"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterOccupation}
										DataRoot="Item"
										Required={tempModel.isPersonal === true && (IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Sorted={this.sortedParameterDesc}
										IsInvalid={vModel.Occupation}
										Value={model.OccupationId}
										ValueChanged={this.props.handleChange}
										Disabled={tempModel.isPersonal === false || ReadOnly} />
								</GridItem>
								{
									this.retiredOrUnemployedOccupationIds.includes(model.OccupationId) &&							
										<GridItem style={{ opacity: tempModel.isPersonal === false ? 0.4 : 1 }}>
											<GenericSelectInput
												Name="PriorOccupationId"
												LabelText="Prior Occupation"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterOccupation}
												DataRoot="Item"
												Required = {tempModel.isPersonal === true && (IsIncludeLender || IsIncludePrepaid)
																&& this.retiredOrUnemployedOccupationIds.includes(model.OccupationId) 
																&& !ReadOnly} 
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={this.sortedParameterDesc}
												IsInvalid={vModel.PriorOccupation}
												Value={model.PriorOccupationId}
												ValueChanged={this.props.handleChange} />
										</GridItem>
								}

								{
								tempModel.isPersonal &&
									<GridItem>
										<GridItem xs={8}>
											<br/>
											<b>Employer Information</b>
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="EmployerName"
												LabelText="Employer"
												Value={model.EmployerName}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === false || ReadOnly}
												inputProps={this.maxLength30}/>												
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="EmployerAddress"
												LabelText="Address"
												Id="employer-address"
												Value={model.EmployerAddress}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === false || ReadOnly}
												inputProps={this.maxLength100} />
										</GridItem>								
										<GridItem>
											<GenericTextInput
												Name="EmployerZipCode"
												LabelText="Postal Code"
												Id="employer-postcode"
												Value={model.EmployerZipCode}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === false || ReadOnly}
												inputProps={this.maxLength20} />
										</GridItem>
										<GridItem>
											<GenericSelectInput
												Name="EmployerCountryId"
												LabelText="Country"
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.EmployerCountryId}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === false || ReadOnly}	/>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												key={model.EmployerCountryId + "province"}
												Name="EmployerProvinceId"
												LabelText="Province"
												Method="POST"
												Url="/coreapi/v1.0/Province/Filter"
												Parameter={{ CountryId: model.EmployerCountryId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.EmployerProvinceId}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === false || ReadOnly}	/>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												key={"city" + model.EmployerProvinceId + ""}
												Name="EmployerCityId"
												LabelText="City"
												Method="POST"
												Url="/coreapi/v1.0/City/Filter"
												Parameter={{ ProvinceId: model.EmployerProvinceId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.EmployerCityId}
												ValueChanged={this.props.handleChange}
												Disabled={tempModel.isPersonal === false || ReadOnly}	/>
										</GridItem>
										<GridItem>
											<GridContainer>
												<GridItem xs={8}>
													<GenericSelectInput
														Name="EmployerPhoneCountryCodeId"
														LabelText="Phone Number"
														TitleLabel="Country Code"
														LabelMd={6}
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember={"Code"}
														Value={model.EmployerPhoneCountryCodeId}
														ValueChanged={this.props.handleChange}
														Sorted={this.sortedOrderIndex}
														RenderItem={this.RenderItemPhoneCode}
														Disabled={tempModel.isPersonal === false || ReadOnly}/>
												</GridItem>
												<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
													<GenericNumberInput
														IsPhone
														Name="EmployerPhoneNumber"
														InputStyle={this.marginLeft2}
														LabelMd={0}
														Value={model.EmployerPhoneNumber}
														ValueChanged={this.props.handleChange}
														MaxLength={10}
														Disabled={tempModel.isPersonal === false || ReadOnly}/>
												</GridItem>
											</GridContainer>
										</GridItem>									
									</GridItem>
								} 																										
							</>														
						</GridItem>					
						<GridItem xs={4}>
							<GenericTitle text={"Communication Information"} />
							<>
								<GridItem>
									<GenericEmailInput
										Name="Email"
										LabelText="E-Mail"
										Value={model.Email}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										inputProps={this.maxLength64}
										Required={(IsIncludePrepaid || IsIncludeLender)  && !IsFilledPhoneNumber && !ReadOnly}
										IsInvalid={vModel.Email} />
								</GridItem>
								<GridItem>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Phone Number"
												TitleLabel="Country Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.PhoneCountryCodeId}
												ValueChanged={this.props.handleChange}
												Sorted={this.sortedOrderIndex}
												RenderItem={this.RenderItemPhoneCode}
												Disabled={ReadOnly}
												Required={ (IsIncludePrepaid || IsIncludeLender) && !model.Email && !ReadOnly}
												IsInvalid={vModel.PhoneCountryCode} />
										</GridItem>
										<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
											<GenericNumberInput
												IsPhone
												Name="PhoneNumber"
												InputStyle={this.marginLeft2}
												LabelMd={0}
												Value={model.PhoneNumber}
												ValueChanged={this.props.handleChange}
												MaxLength={10}
												Disabled={ReadOnly}
												Required={ (IsIncludePrepaid || IsIncludeLender) && !model.Email && !ReadOnly}
												IsInvalid={vModel.PhoneNumber} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem>
									<GenericTextInput
										Name="AddressLine1"
										LabelText="Address Line 1"
										Id="street-address"
										Value={model.AddressLine1}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required={(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.AddressLine1}
										inputProps={this.maxLength100} />
								</GridItem>
								<GridItem>
									<GenericTextInput
										Name="AddressLine2"
										Id="street-address2"
										LabelText="Address Line 2"
										Value={model.AddressLine2}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										inputProps={this.maxLength100} />
								</GridItem>
								<GridItem>
									<GenericTextInput
										Name="ZipCode"
										LabelText="Postal Code"
										Id="postcode"
										Value={model.ZipCode}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required={(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.PostalCode}	
										inputProps={this.maxLength20} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="CountryId"
										LabelText="Country"
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.CountryId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required={(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.CountryId}
									/>
								</GridItem>
								<GridItem>
									<GenericSelectInput
										key={model.CountryId + "province"}
										Name="ProvinceId"
										LabelText="Province"
										Method="POST"
										Url="/coreapi/v1.0/Province/Filter"
										Parameter={{ CountryId: model.CountryId }}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.ProvinceId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required={(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.ProvinceId}
									/>
								</GridItem>
								<GridItem>
									<GenericSelectInput
										key={"city" + model.ProvinceId + ""}
										Name="CityId"
										LabelText="City"
										Method="POST"
										Url="/coreapi/v1.0/City/Filter"
										Parameter={{ ProvinceId: model.ProvinceId }}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.CityId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly}
										Required={(IsIncludeLender || IsIncludePrepaid) && !ReadOnly}
										IsInvalid={vModel.CityId}
									/>
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="LanguageId"
										LabelText="Preferred Language"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterLanguage}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.LanguageId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="HasTextNotificationId"
										LabelText="Text/SMS Notification"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterYesNo}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.HasTextNotificationId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="HasEmailNotificationId"
										LabelText="E-Mail Notification"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterYesNo}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.HasEmailNotificationId}
										ValueChanged={this.props.handleChange}
										Disabled={ReadOnly} />
								</GridItem>
							</>
							<>
							  {(IsIncludeLender || IsIncludePrepaid) &&							  		
									<GridItem>
										<br />
										<GenericTitle text={"Watchlist Check Results"} />
										<GridItem>
												<GenericTextInput
  													Name="SearchStatus"
  													LabelText="WatchList Status"													
													Value={wCheckModel.WatchListStatusDescription}								
  													Disabled={true}
 													inputProps={this.maxLength30} />
											</GridItem>
											<GridItem>
												<GenericTextInput
  													Name="SearchResult"
  													LabelText="Search Result"
  													Value={wCheckModel.SearchResult}									
  													Disabled={true}
 													inputProps={this.maxLength30} />
											</GridItem>											
											<GridItem>
												<GenericDateInput
  													Name="LastReviewDate"
  													LabelText="Last Review Date"
  													Value={wCheckModel.LastReviewDate 
															? DateHelper.ToMomentWithFormat(wCheckModel.LastReviewDate,"YYYY-MM-DD") 
															: ""} 	
													IncludeTime={false}
													IsPast={true}
													Utc={true}							
  													Disabled={true} />	
											</GridItem>
									</GridItem>
							  	}
							</>
						</GridItem>
						<GridItem xs={4} style={{ opacity: disabledIdVerification ? 0.4 : 1 }}>
							<GenericTitle text={"ID Verification"} />
							<>
								<GridItem>
									<GenericSelectInput
										Name="IdentificationMethodId"
										LabelText="Identification Method"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterIdentificationMethod}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.IdentificationMethodId}
										ValueChanged={this.props.handleChange}
										Disabled={disabledIdVerification || ReadOnly}
										Required={tempModel.isPersonal == true 
														&& (IsIncludeLender || IsIncludePrepaid) && !ReadOnly}									
										IsInvalid={vModel.IdentificationMethodId} />
								</GridItem>
								
								{tempModel.isPersonal 
										&& (this.state.identificationMethodIds.length > 0)
									   && (this.state.identificationMethodIds
												  .find(x=>x.Value == IdentificationMethod.InPersonIDVerification).Id 
																			== model.IdentificationMethodId) 
										&&
									<GridItem> 
										<GridItem xs={4}>
											<br/>
											<b>Primary Identification</b>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												Name="IdentityTypeId"
												LabelText="Identification Type"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterIdentityType}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={this.sortedParameterDesc}
												Value={model.IdentityTypeId}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={disabledIdVerification === false 
														&& tempModel.isPersonal 
														&& (model.IdentificationMethodId ==
																this.state.identificationMethodIds
																	.find(x=>x.Value == IdentificationMethod.InPersonIDVerification).Id)
														&& (IsIncludeLender || IsIncludePrepaid)
														&& !ReadOnly}
												IsInvalid={vModel.IdentityTypeId} />																	
										</GridItem>
										{tempModel.isPersonal &&
												this.specificIdentityTypesIds.includes(model.IdentityTypeId) &&
											<GridItem>
												<GenericSelectInput
													Name="PrimaryProvincialIdentityTypeId"
													LabelText="Provincial Id Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterProvincialGovermentPhotoId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={this.sortedParameterDesc}
													Value={model.PrimaryProvincialIdentityTypeId}
													ValueChanged={this.props.handleChange}
													Disabled={disabledIdVerification || ReadOnly}
													Required={disabledIdVerification === false 
																&& tempModel.isPersonal 														
																&& this.specificIdentityTypesIds.includes(model.IdentityTypeId)
																&& (IsIncludeLender || IsIncludePrepaid)
																&& !ReadOnly}
													IsInvalid={vModel.PrimaryProvincialIdentityTypeId} />																	
											</GridItem>
										}									
										<GridItem>
											<GenericTextInput
												Name="IdentityPlaceOfIssue"
												LabelText="ID Place of Issue"
												Value={model.IdentityPlaceOfIssue}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={disabledIdVerification === false 
														&& tempModel.isPersonal 
														&& (model.IdentificationMethodId 
																== this.state.identificationMethodIds
																		 .find(x=>x.Value == IdentificationMethod.InPersonIDVerification).Id)
														&& (IsIncludeLender || IsIncludePrepaid)
														&& !ReadOnly}
												IsInvalid={vModel.IdentityPlaceOfIssue}
												inputProps={this.maxLength30} />
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="IdentityNumber"
												LabelText="ID No."
												Value={model.IdentityNumber}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={disabledIdVerification === false 
														&& tempModel.isPersonal
														&& (IsIncludeLender || IsIncludePrepaid)
														&& (model.IdentificationMethodId == 
																this.state.identificationMethodIds
																	 .find(x=>x.Value == IdentificationMethod.InPersonIDVerification).Id)
														&& !ReadOnly}
												IsInvalid={vModel.IdentityNumber}
												inputProps={this.maxLength20} />
										</GridItem>
										<GridItem>
											<GenericDateInput
												Name="IdentityExpireDate"
												LabelText="ID Exp Date"
												Value={model.IdentityExpireDate 
															? DateHelper.ToDateInputValue(model.IdentityExpireDate) 
															: ""}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={disabledIdVerification === false 
														&& tempModel.isPersonal
														&& (IsIncludeLender || IsIncludePrepaid)
														&& (model.IdentificationMethodId == 
																this.state.identificationMethodIds
																	  .find(x=>x.Value==IdentificationMethod.InPersonIDVerification).Id)
														&& !ReadOnly}
												IsInvalid={vModel.IdentityExpireDate}
												IsFuture={true}
											/>
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="IdentityVerifiedBy"
												LabelText="Veryfied By"
												Value={model.IdentityVerifiedBy}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={disabledIdVerification === false 
														&& tempModel.isPersonal
														&& (IsIncludeLender || IsIncludePrepaid)
														&& (model.IdentificationMethodId == 
																this.state.identificationMethodIds
																	 .find(x=>x.Value == IdentificationMethod.InPersonIDVerification).Id)
														&& !ReadOnly}
												IsInvalid={vModel.IdentityVerifiedBy}
												inputProps={this.maxLength20} />
										</GridItem>	
										<GridItem>
											<GenericDateInput
												Name="IdentityVerificationDate"
												LabelText="Verification Date"
												Value={model.IdentityVerificationDate 
															? DateHelper.ToDateInputValue(model.IdentityVerificationDate) 
															: ""}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={disabledIdVerification === false 
														&& tempModel.isPersonal
														&& (IsIncludeLender || IsIncludePrepaid)
														&& (model.IdentificationMethodId == 
																this.state.identificationMethodIds
																	 .find(x=>x.Value == IdentificationMethod.InPersonIDVerification).Id)
														&& !ReadOnly}
												IsInvalid={vModel.IdentityVerificationDate}
											/>
										</GridItem>
										<GridItem xs={5}>
											<br/>
											<b>Secondary Identification</b>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												Name="SecondaryIdentityTypeId"
												LabelText="Identification Type 2"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterIdentitySecondary}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={this.sortedParameterDesc}
												Value={model.SecondaryIdentityTypeId}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}/>										
										</GridItem>
										{tempModel.isPersonal &&
											this.specificIdentityTypesIds.includes(model.SecondaryIdentityTypeId) &&
											<GridItem>
												<GenericSelectInput
													Name="SecondaryProvincialIdentityTypeId"
													LabelText="Provincial Id Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterProvincialGovermentPhotoId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={this.sortedParameterDesc}
													Value={model.SecondaryProvincialIdentityTypeId}
													ValueChanged={this.props.handleChange}
													Disabled={disabledIdVerification || ReadOnly} />																	
											</GridItem>
										}									
										<GridItem>
											<GenericTextInput
												Name="SecondaryIdentityPlaceOfIssue"
												LabelText="ID Place of Issue"
												Value={model.SecondaryIdentityPlaceOfIssue}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}									
												inputProps={this.maxLength30} />
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="SecondaryIdentityNumber"
												LabelText="ID No."
												Value={model.SecondaryIdentityNumber}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}									
												inputProps={this.maxLength20} />
										</GridItem>	
										<GridItem>
											<GenericDateInput
												Name="SecondaryIdentityExpireDate"
												LabelText="ID Exp Date"
												Value={model.SecondaryIdentityExpireDate 
															? DateHelper.ToDateInputValue(model.SecondaryIdentityExpireDate) 
															: ""}
												ValueChanged={this.props.handleChange}
												IsFuture={true}
												Disabled={disabledIdVerification || ReadOnly} />
										</GridItem>		
										<GridItem>
											<GenericTextInput
												Name="SecondaryIdentityVerifiedBy"
												LabelText="Verified By"
												Value={model.SecondaryIdentityVerifiedBy}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												inputProps={this.maxLength20} />
										</GridItem>	
										<GridItem>
											<GenericDateInput
												Name="SecondaryIdentityVerificationDate"
												LabelText="Verification Date"
												Value={model.SecondaryIdentityVerificationDate 
																	? DateHelper.ToDateInputValue(model.SecondaryIdentityVerificationDate) 
																	: ""}
												ValueChanged={this.props.handleChange}
												IsFuture={true}
												Disabled={disabledIdVerification || ReadOnly}												
											/>
										</GridItem>
									
									</GridItem>
								}		

								{tempModel.isPersonal
										&& (this.state.identificationMethodIds.length > 0)
										&& (this.state.identificationMethodIds
												.find(x=>x.Value == IdentificationMethod.CreditFileMethod).Id 
															== model.IdentificationMethodId) 
										&&
									<GridItem>
										<GridItem xs={5}>
											<br />
											<b>Credit File Information</b>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												Name="CreditAgencyId"
												LabelText="Credit Agency"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterCreditAgency}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={this.sortedParameterDesc}
												Required={tempModel.isPersonal 
													&& disabledIdVerification === false
													&& (IsIncludeLender || IsIncludePrepaid)
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.CreditFileMethod).Id)
													&& !ReadOnly}
												Value={model.CreditAgencyId}
												ValueChanged={this.props.handleChange}
												IsInvalid={vModel.CreditAgencyId}
												Disabled={disabledIdVerification || ReadOnly}/>										
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="CreditFileNumber"
												LabelText="Credit File No"
												Required={tempModel.isPersonal 
													&& disabledIdVerification === false
													&& (IsIncludeLender || IsIncludePrepaid)
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.CreditFileMethod).Id)
													&& !ReadOnly}
												Value={model.CreditFileNumber}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												IsInvalid={vModel.CreditFileNumber}
												inputProps={this.maxLength20} />
										</GridItem>
										<GridItem>
											<GenericDateInput
												Name="CreditFileCompletionDate"
												LabelText="Completion Date"
												Value={model.CreditFileCompletionDate 
																	? DateHelper.ToDateInputValue(model.CreditFileCompletionDate) 
																	: ""}
												Required={tempModel.isPersonal 
													&& disabledIdVerification === false
													&& (IsIncludeLender || IsIncludePrepaid)
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.CreditFileMethod).Id)
													&& !ReadOnly}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												IsInvalid={vModel.CreditFileCompletionDate} 
											/>
										</GridItem>

									</GridItem>
								}

								{tempModel.isPersonal 
										&& (this.state.identificationMethodIds.length > 0)
										&& (this.state.identificationMethodIds
												  .find(x=>x.Value == IdentificationMethod.DualProcessMethod).Id == 
												  			model.IdentificationMethodId) 
										&&
									<GridItem>
										<GridItem xs={5}>
											<br/>
											<b>Primary Document</b>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												Name="PrimaryDocumentId"
												LabelText="Document"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterPrimarySourceForDualProcessId}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Required={tempModel.isPersonal 
													&& disabledIdVerification === false
													&& (IsIncludeLender || IsIncludePrepaid)
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.DualProcessMethod).Id)
													&& !ReadOnly}
												Sorted={this.sortedParameterDesc}
												Value={model.PrimaryDocumentId}
												ValueChanged={this.props.handleChange}
												IsInvalid={vModel.PrimaryDocumentId}
												Disabled={disabledIdVerification || ReadOnly}/>										
										</GridItem>
										<GridItem>
											<GenericDateInput
												Name="PrimaryDocumentVerificationDate"
												LabelText="Verification Date"
												Value={model.PrimaryDocumentVerificationDate 
															? DateHelper.ToDateInputValue(model.PrimaryDocumentVerificationDate) 
															: ""}
												ValueChanged={this.props.handleChange}
												Required={tempModel.isPersonal
													&& (IsIncludeLender || IsIncludePrepaid) 
													&& disabledIdVerification === false 
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.DualProcessMethod).Id)
													&& !ReadOnly}
												Disabled={disabledIdVerification || ReadOnly}
												IsInvalid={vModel.PrimaryDocumentVerificationDate} 
											/>
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="PrimaryDocumentNumber"
												LabelText="Associated No"
												Required={tempModel.isPersonal 
													&& disabledIdVerification === false
													&& (IsIncludeLender || IsIncludePrepaid) 
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.DualProcessMethod).Id)
													&& !ReadOnly}
												Value={model.PrimaryDocumentNumber}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												IsInvalid={vModel.PrimaryDocumentNumber}
												inputProps={this.maxLength20} />
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="PrimaryDocumentReviewedBy"
												LabelText="Reviewed By"
												Value={model.PrimaryDocumentReviewedBy}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												Required={tempModel.isPersonal 
													&& disabledIdVerification === false
													&& (IsIncludeLender || IsIncludePrepaid) 
													&& (model.IdentificationMethodId == 
															this.state.identificationMethodIds
																 .find(x=>x.Value == IdentificationMethod.DualProcessMethod).Id)
													&& !ReadOnly}
												IsInvalid={vModel.PrimaryDocumentReviewedBy}
												inputProps={this.maxLength20} />
										</GridItem>
										<GridItem xs={5}>
											<br/>
											<b>Secondary Document</b>
										</GridItem>
										<GridItem>
											<GenericSelectInput
												Name="SecondaryDocumentId"
												LabelText="Document"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterSecondarySourceForDualProcessId}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={this.sortedParameterDesc}
												Value={model.SecondaryDocumentId}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}/>										
										</GridItem>
										<GridItem>
											<GenericDateInput
												Name="SecondaryDocumentVerificationDate"
												LabelText="Verification Date"
												Value={model.SecondaryDocumentVerificationDate 
																? DateHelper.ToDateInputValue(model.SecondaryDocumentVerificationDate) 
																: ""}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}												
											/>
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="SecondaryDocumentNumber"
												LabelText="Associated No"
												Value={model.SecondaryDocumentNumber}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												inputProps={this.maxLength20} />
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="SecondaryDocumentReviewedBy"
												LabelText="Reviewed By"
												Value = {model.SecondaryDocumentReviewedBy}
												ValueChanged={this.props.handleChange}
												Disabled={disabledIdVerification || ReadOnly}
												inputProps={this.maxLength20} />
										</GridItem>

									</GridItem>
								}							
								<GridItem>
									<br />
								</GridItem>			
								<GridItem>
									<GenericSelectInput
										Name="CountryOfCitizenShipId"
										LabelText="Country of Citizenship"
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.CountryOfCitizenShipId}
										ValueChanged={this.props.handleChange}
										Disabled={disabledIdVerification || ReadOnly}
									/>
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="CountryOfBirthId"
										LabelText="Country of Birth"
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.CountryOfBirthId}
										ValueChanged={this.props.handleChange}
										Disabled={disabledIdVerification || ReadOnly} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="CountryOfResidentId"
										LabelText="Residence Situation"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterResidencyCountry}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.CountryOfResidentId}
										ValueChanged={this.props.handleChange}
										Disabled={disabledIdVerification || ReadOnly} />
								</GridItem>
							</>
						</GridItem>
						{(IsIncludeET && !ForInsert) &&
							<GridItem xs={12}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" color="black" disabled={Disabled} onClick={this.props.handleNext}>Next</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						}
					</GridContainer>
				</GenericExpansionPanel>
			</>
		);
	}
}

CustomerInformationComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	tempModel: PropTypes.object,
	wCheckModel: PropTypes.object,
	handleChange: PropTypes.func,
	handleNext: PropTypes.func,
	Title: PropTypes.string,
	ReadOnly: PropTypes.bool,
	Disabled: PropTypes.bool,
	ForInsert: PropTypes.bool
};

export default withArtifex(CustomerInformationComponent, {});