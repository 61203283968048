import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ArrayHelper from "core/ArrayHelper";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { SortedDescription } from "views/Constants/Constant.Sorted";

class MenuProgramMap extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [];

		this.state = {
			model: {},
			menuDetails: []
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Menu Program Map");
		}

		this.GetMenuList();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model, menuDetails: [] };
		});
	}

	CellValueChanged = (name, value, index) => {
		var { menuDetails } = this.state;

		menuDetails[index].IsSelected = value;

		this.setState({ menuDetails });
		this.GetColumns(menuDetails);
	}

	GetMenuList = async () => {
		const { ExecuteApiPost } = this.props;

		var result = await ExecuteApiPost("/coreapi/v1.0/Menu/GetForProgramMenu", {});
		var menuList = result || [];

		ArrayHelper.SortArray(menuList, "MenuName");

		this.setState({ menuList });
	}

	GetColumns = (menuDetails) => {
		this.columns = [
			{
				Header: "Menu Code",
				accessor: "MenuCode"
			},
			{
				Header: "Menu Name",
				accessor: "MenuName"
			},
			{
				Header: "Select",
				Cell: row => (
					<GenericCheckInput
						Name="IsSelected"
						Value={menuDetails[row.index].IsSelected}
						ValueChanged={(name, value) => this.CellValueChanged(name, value, row.index)}
					/>
				)
			}
		];

		this.forceUpdate();
	}

	Search = async () => {
		var { model, menuList } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/MenuProgramMap/GetByProgramId", { Id: model.ProgramId }, null, null, () => this.Validate(false));
		var menuDetails = [];

		if (result != null) {
			menuList.map(x => {
				var isSelected = false;

				result
					.filter(y => y.MenuId == x.Id)
					.map(y => {
						isSelected = true;

						return null;
					});

				var menuName = ResourceHelper.GetForMenu(x.MenuName);
				menuName = `${!menuName ? "! " + x.MenuName : menuName} - ${x.MenuCode}`;

				menuDetails.push({
					"MenuId": x.Id,
					"MenuCode": x.MenuCode,
					"MenuName": menuName,
					"IsSelected": isSelected
				});


				return null;
			});

			model["IsProgramId"] = false;

			this.setState({ menuDetails });
			this.GetColumns(menuDetails);
		}
	}

	Submit = () => {
		const { model, menuDetails } = this.state;
		const { ExecuteApiPost } = this.props;

		ExecuteApiPost("/coreapi/v1.0/MenuProgramMap/Submit", {
			ProgramId: model.ProgramId, "MenuList": menuDetails.map(x => { return { MenuId: x.MenuId, IsSelected: x.IsSelected }; })
		}, null, null, () => this.Validate(true), null, true);
	}

	Clear = () => {
		this.setState({ model: {}, menuDetails: [] });
	}

	Validate(isSubmit) {
		var { model, menuDetails } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ProgramId) {
			errors.push("Program can not be null.");
		}
		if (isSubmit && menuDetails.length == 0) {
			errors.push("Menu list can not be null.");
		}

		model["IsProgramId"] = !model.ProgramId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, menuDetails } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Menu Program Map">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsProgramId}
												Name="ProgramId"
												LabelText="Program"
												Url="/bankapi/v1.0/Bank/ValidBankPrograms"
												Method="GET"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Sorted={SortedDescription}
												Value={model.ProgramId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													HideButton
													Data={menuDetails}
													Columns={this.columns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MenuProgramMap.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default MenuProgramMap;