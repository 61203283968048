import withStyles from "@material-ui/core/styles/withStyles";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericPage, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ClientType, ProgramCodes } from "views/Constants/Constant.js";
import { List } from "@material-ui/icons";
import { GridColumnType } from "views/Constants/Constant";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";

const styles = ({
	circleBoxGrid: {
		width: "14%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class WireTransferDashboard extends GenericPage {
	constructor(props) {
		super(props);
		this.doughnutHeight = 300;
		this.doughnutWidth = 210;
		this.isClient = ClientHelper.IsClient();
		this.initialModel = {
			UniqueClientId: ClientHelper.GetClientRowId(),
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate()
		};

		this.columns = [
			{
				Header: "ClientName",
				accessor: "ClientName"
			},

			{
				Header: "SenderName",
				accessor: "SenderName"
			},
			{
				Header: "From Wallet",
				accessor: "FromAccount"
			},
			{
				Header: "To Wallet",
				accessor: "ToAccount"
			},
			{
				Header: "Date of Request",
				accessor: "DateOfRequest",
				type: GridColumnType.Date
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective",
				type: GridColumnType.Date
			},
			{
				Header: "Beneficiary Name",
				accessor: "BeneficiaryName"
			},
			{
				Header: "Amount",
				accessor: "OriginalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Fee",
				accessor: "Fee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Sub Status",
				accessor: "SubStatus"
			},
			{
				Header: "Message Type",
				accessor: "MessageType"
			},
			{
				Header: "Transaction ID",
				accessor: "TransactionId"
			}
		];

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: true,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			},
			tooltips: { enabled: true }
		};

		this.initialDoughnutDataModel = {
			labels: ["Transactions"],
			datasets: [{
				data: [1],
				backgroundColor: "rgb(255, 99, 132)",
				hoverBackgroundColor: "rgb(255, 99, 132)"
			}],
			text: "0 \t $ 0"
		};

		this.state = {
			model: this.initialModel,
			activeIndex: 0,
			totalWire: { ...this.initialDoughnutDataModel },
			totalFee: { ...this.initialDoughnutDataModel },
			totalOnhold: { ...this.initialDoughnutDataModel },
			totalReturned: { ...this.initialDoughnutDataModel },
			totalRejected: { ...this.initialDoughnutDataModel },
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			}
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: { display: false },
			cutoutPercentage: 90,
			animation: { duration: 2000 }
		};

		this.chartColors = {
			red: "rgb(255, 99, 132)",
			orange: "rgb(255, 159, 64)",
			yellow: "rgb(255, 205, 86)",
			green: "rgb(75, 192, 192)",
			blue: "rgb(54, 162, 235)",
			purple: "rgb(153, 102, 255)",
			grey: "rgb(201, 203, 207)"
		};

		this.isClient = ClientHelper.IsClient();
		this.clientId = ClientHelper.GetClientRowId();
		this.ClientParameter = { UniqueClientId: this.isClient ? this.clientId : undefined, ClientTypes: [ClientType.Personal, ClientType.Corporate] };
		this.SortedClient = { Member: "Name" };

		this.doughnutRef = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Dashboard");
		if (setAppCenterTitle)
			setAppCenterTitle("WIRE TRANSFER");

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);
			}
		});

		if (ClientHelper.IsClient) {
			this.HandleGetList();
		}
	}

	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleBooleanChange(name, value) {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		this.setState({ model });
	}

	HandleClear() {
		this.setState({ model: this.initialModel, selected: null });
	}

	HandleGetList() {
		if (!this.Validate())
			return;

		const model = { ...this.state.model };
		this.ExecutePostRequest("/bankapi/v1.0/WireTransferDashboard/SearchWireTransfer",
			{
				UniqueClientId: this.isClient ? this.clientId : model.UniqueClientId,
				FromDate: model.FromDate,
				ToDate: model.ToDate
			},
			this.SuccessListCallback);
	}

	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return gradientList;

	}

	SuccessListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		if (responseData.Item !== null) {
			// const { totalWire, totalReturned, totalRejected, totalOnhold, totalFee } = this.state;

			var totalWire = this.GenerateDoughnutData(responseData.Item.TotalWireChartData);
			var totalReturned = this.GenerateDoughnutData(responseData.Item.TotalReturnedChartData);
			var totalRejected = this.GenerateDoughnutData(responseData.Item.TotalRejectionChartData);
			var totalOnhold = this.GenerateDoughnutData(responseData.Item.TotalOnholdChartData);
			var totalFee = this.GenerateDoughnutData(responseData.Item.TotalFeeCollectedChartData);

			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			this.setState({
				model,
				tableData: responseData.Item.TransactionList,
				totalWire, totalReturned, totalRejected, totalOnhold, totalFee
			});
		}
	}

	GenerateDoughnutData(statisticData) {
		var arrayData = [];
		var arrayLabel = [];
		var arrayColor = [];

		var colorName = "";
		var newColor = "";

		var colorNames = Object.keys(this.chartColors);
		colorName = colorNames[0];
		newColor = this.chartColors[colorName];
		arrayColor.push(newColor);
		arrayData.push(statisticData.TotalAmount);
		arrayLabel.push(statisticData.TransactionType);

		var trxChartObj = {
			labels: arrayLabel,
			datasets: [{
				data: [statisticData.Count == 0 ? 1 : statisticData.Count],
				hoverBackgroundColor: arrayColor,
				backgroundColor: arrayColor
			}],
			text: this.GetChartText(statisticData.Count, statisticData.TotalAmount)
		};
		return trxChartObj;
	}

	GetElementValue(obj, path) {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => c && c[n]);
	}

	TabIndexChanged(activeIndex) {
		this.setState({ activeIndex });
	}

	Validate() {
		const { model } = this.state;

		if (typeof model.FromDate != "object" && model.FromDate != "" && model.FromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}

		if (typeof model.ToDate != "object" && model.ToDate != "" && model.ToDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if (!model.FromDate) {
			this.ShowGenericMessage("warning", "From Date not selected", "Select From Date to continue.");
			return false;
		}

		if (!model.ToDate) {
			this.ShowGenericMessage("warning", "To Date not selected", "Select To Date to continue.");
			return false;
		}

		return true;
	}

	SumValues(...arr) {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetColumns(list, header, accessor) {

		const columns = [
			{
				Header: header,
				accessor: accessor,
				Footer: (
					<span>
						<strong>Total Transactions</strong>
					</span>
				)
			},
			{
				Header: "Transactions",
				accessor: "TransactionCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.TransactionCount)))}</strong>
					</span>
				)
			},
			{
				Header: "% of Errors",
				accessor: "TransactionErrorCountPercent",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.TransactionErrorCountPercent)))}</strong>
					</span>
				)
			},
			{
				Header: "Debit",
				accessor: "DebitCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.DebitCount)))}</strong>
					</span>
				)
			},
			{
				Header: "% of Errors",
				accessor: "DebitErrorCountPercent",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.DebitErrorCountPercent)))}</strong>
					</span>
				)
			},
			{
				Header: "Credit",
				accessor: "CreditCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.CreditCount)))}</strong>
					</span>
				)
			},
			{
				Header: "% of Errors",
				accessor: "CreditErrorCountPercent",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.CreditErrorCountPercent)))}</strong>
					</span>
				)
			},
			{
				Header: "Total",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				Footer: (
					<span>
						<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.TotalAmount)))}</strong>
					</span>
				)
			},
			{
				Header: "Total Fee",
				accessor: "TotalFeeAmount",
				type: GridColumnType.Money,
				Footer: (
					<span>
						<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.TotalFeeAmount)))}</strong>
					</span>
				)
			},
			{
				Header: "Per Transaction Fee",
				accessor: "PerTransactionFeeCount",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.PerTransactionFeeCount)))}</strong>
					</span>
				)
			}
		];
		return columns;
	}

	GetChartText(item1, item2) {
		return item1 + " \t $ " + item2
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	render() {
		const { IsLoading, Alert } = this.state;
		const { classes } = this.props;
		const { model, tableData, totalFee, totalOnhold, totalRejected, totalReturned, totalWire } = this.state;
		var isClient = ClientHelper.IsClient();

		var trxGradientList = this.getGradientList(totalWire);
		var trxBackground = totalWire;
		if (trxGradientList != undefined) {
			// @ts-ignore
			trxBackground.datasets[0].backgroundColor = trxGradientList;
			// @ts-ignore
			trxBackground.datasets[0].hoverBackgroundColor = trxGradientList;
		}

		var feeGradientList = this.getGradientList(totalFee);
		var feeBackground = totalFee;
		if (feeGradientList != undefined) {
			// @ts-ignore
			feeBackground.datasets[0].backgroundColor = feeGradientList;
			// @ts-ignore
			feeBackground.datasets[0].hoverBackgroundColor = feeGradientList;
		}

		var returnedGradientList = this.getGradientList(totalReturned);
		var returnedBackground = totalReturned;
		if (returnedGradientList != undefined) {
			// @ts-ignore
			returnedBackground.datasets[0].backgroundColor = returnedGradientList;
			// @ts-ignore
			returnedBackground.datasets[0].hoverBackgroundColor = returnedGradientList;
		}

		var rejectionGradientList = this.getGradientList(totalRejected);
		var rejectionBackground = totalRejected;
		if (rejectionGradientList != undefined) {
			// @ts-ignore
			rejectionBackground.datasets[0].backgroundColor = rejectionGradientList;
			// @ts-ignore
			rejectionBackground.datasets[0].hoverBackgroundColor = rejectionGradientList;
		}

		var onholdGradientList = this.getGradientList(totalOnhold);
		var onholdBackground = totalOnhold;
		if (onholdGradientList != undefined) {
			// @ts-ignore
			onholdBackground.datasets[0].backgroundColor = onholdGradientList;
			// @ts-ignore
			onholdBackground.datasets[0].hoverBackgroundColor = onholdGradientList;
		}

		return (
			<div>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				{this.isClient &&
					<AccountLimitToolbar Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={"WireTransfer"} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
				}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: false },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: false }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>

												<GridItem xs={3}>
													<GenericSelectInput
														LabelText="Client Name"
														Name="UniqueClientId"
														Method="POST"
														LabelMd={4}
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														All
														Disabled={isClient}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericDateInput
														Name="FromDate"
														LabelText="From Date"
														Value={model.FromDate}
														ValueChanged={this.HandleChange}
														MaxDate={model.ToDate || DateHelper.GetDateTime()}
														Utc
														IncludeTime={false}
													/>

												</GridItem>
												<GridItem xs={3}>
													<GenericDateInput
														Name="ToDate"
														LabelText="To Date"
														Value={model.ToDate}
														ValueChanged={this.HandleChange}
														MinDate={model.FromDate}
														MaxDate={DateHelper.GetDateTime()}
														Utc
														IncludeTime={false}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "16px", marginBottom: "16px" }}>

						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<Card>

									<CardHeader color="warning" icon>
										<CardIcon color="warning">
											<List />
										</CardIcon>

										<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
											<b>Transaction Summary</b>
										</h4>
									</CardHeader>

									<CardBody>
										<GridContainer justify="center">
											<GridItem className={classes.circleBoxGrid} xs={2}>
												<div className={classes.circleBox}>
													<DoughnutChart data={trxBackground} height={this.doughnutHeight} width={this.doughnutWidth} options={this.chartOptions} ref={this.doughnutRef} id="canvas" />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />Transactions
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem className={classes.circleBoxGrid} xs={2}>
												<div className={classes.circleBox}>
													<DoughnutChart data={feeBackground} height={this.doughnutHeight} width={this.doughnutWidth} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />Total Fees Collected
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem className={classes.circleBoxGrid} xs={2}>
												<div className={classes.circleBox}>
													<DoughnutChart data={onholdBackground} height={this.doughnutHeight} width={this.doughnutWidth} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />Total On-hold
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem className={classes.circleBoxGrid} xs={2}>
												<div className={classes.circleBox}>
													<DoughnutChart data={returnedBackground} height={this.doughnutHeight} width={this.doughnutWidth} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />Total Returned
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
											<GridItem className={classes.circleBoxGrid} xs={2}>
												<div className={classes.circleBox}>
													<DoughnutChart data={rejectionBackground} height={this.doughnutHeight} width={this.doughnutWidth} options={this.chartOptions} />
													<GridContainer spacing={16} justify="center" alignItems="center">
														<GridItem>
															<br />Total Rejections
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12}>
								<Card>
									<CardBody>
										<GenericGrid
											Data={tableData}
											Columns={this.columns}
											DocumentTitle={"Report List"}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>

					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

WireTransferDashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withStyles(styles)(WireTransferDashboard);