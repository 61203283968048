import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput, GenericTextInput } from "views/Components/Generic";


class Tcr0 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength2 = { maxLength: 2 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 4 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength25 = { maxLength: 25};
		this.maxLength5 = { maxLength: 5};
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}


	render() {

		const { model } = this.props;
		return (
			<div >

				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericTextInput
									Name="AccountNumber"
									LabelMd={6}
									LabelText="Wallet Number"
									inputProps={this.maxLength19}
									Value={model && model.AccountNumber}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="FloorLimitIndicator"
									LabelMd={6}
									LabelText="Floor Limit Indicator"
									inputProps={this.maxLength1}
									Value={model && model.FloorLimitIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ChargebackReferenceNumber"
									LabelMd={6}
									LabelText="Chargeback Reference Number"
									inputProps={this.maxLength6}
									Value={model && model.ChargebackReferenceNumber}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="CrbExceptionFileIndicator"
									LabelMd={6}
									LabelText="Crb Exception File Indicator"
									inputProps={this.maxLength1}
									Value={model && model.CrbExceptionFileIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="PossitiveChAuthServiceInd"
									LabelMd={6}
									LabelText="Possitive ChAuth Service Indt"
									inputProps={this.maxLength1}
									Value={model && model.PossitiveChAuthServiceInd}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="SpecialConditionIndicators"
									LabelMd={6}
									LabelText="Special Condition Indicators"
									inputProps={this.maxLength2}
									Value={model && model.SpecialConditionIndicators}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ArnFormatCode"
									LabelMd={6}
									LabelText="Arn Format Code"
									inputProps={this.maxLength1}
									Value={model && model.ArnFormatCode}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="ArnBin"
									LabelMd={6}
									LabelText="Arn Bin"
									inputProps={this.maxLength6}
									Value={model && model.ArnBin}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ArnDate"
									LabelMd={6}
									LabelText="Arn Date"
									inputProps={this.maxLength4}
									Value={model && model.ArnDate}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="ArnFilmLocator"
									LabelMd={6}
									LabelText="Arn Film Locator"
									inputProps={this.maxLength11}
									Value={model && model.ArnFilmLocator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="CentralProcessingDate"
									LabelMd={6}
									LabelText="Central Processing Date"
									inputProps={this.maxLength4}
									Value={model && model.CentralProcessingDate}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ReimbursementAttribute"
									LabelMd={6}
									LabelText="Re imbursement Attribute"
									inputProps={this.maxLength1}
									Value={model && model.ReimbursementAttribute}
									ValueChanged={this.HandleChange} />
							</GridItem>



							<GridItem xs={4}>

								<GenericTextInput
									Name="ArnCheckDigit"
									LabelMd={6}
									LabelText="Arn Check Digit"
									inputProps={this.maxLength1}
									Value={model && model.ArnCheckDigit}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AcquirerBusinessId"
									LabelMd={6}
									LabelText="Acquirer Business Id"
									inputProps={this.maxLength8}
									Value={model && model.AcquirerBusinessId}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="PurchaseDate"
									LabelMd={6}
									LabelText="Purchase Date"
									inputProps={this.maxLength4}
									Value={model && model.PurchaseDate}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="DestinationAmount"
									LabelMd={6}
									LabelText="Destination Amount"
									inputProps={this.maxLength17}
									Value={model && model.DestinationAmount}
									ValueChanged={this.HandleChange} />
									
								<GenericTextInput
									Name="DestinationCurrencyCode"
									LabelMd={6}
									LabelText="Destination Currency Code"
									inputProps={this.maxLength3}
									Value={model && model.DestinationCurrencyCode}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="SourceAmount"
									LabelMd={6}
									LabelText="Source Amount"
									inputProps={this.maxLength4}
									Value={model && model.SourceAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="SourceCurrencyCode"
									LabelMd={6}
									LabelText="Source Currency Code"
									inputProps={this.maxLength3}
									Value={model && model.SourceCurrencyCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MerchantName"
									LabelMd={6}
									LabelText="Merchant Name"
									inputProps={this.maxLength25}
									Value={model && model.MerchantName}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MerchantCity"
									LabelMd={6}
									LabelText="Merchant City"
									inputProps={this.maxLength13}
									Value={model && model.MerchantCity}
									ValueChanged={this.HandleChange} />



								<GenericTextInput
									Name="CardholderIdMethod"
									LabelMd={6}
									LabelText="Cardholder Id Method"
									inputProps={this.maxLength1}
									Value={model && model.CardholderIdMethod}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="CollectionOnlyFlag"
									LabelMd={6}
									LabelText="Collection Only Flag"
									inputProps={this.maxLength1}
									Value={model && model.CollectionOnlyFlag}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="PosEntryMode"
									LabelMd={6}
									LabelText="Pos Entry Mode"
									inputProps={this.maxLength2}
									Value={model && model.PosEntryMode}
									ValueChanged={this.HandleChange} />
							</GridItem>


							<GridItem xs={4}>

								<GenericTextInput
									Name="MerchantCountryCode"
									LabelMd={6}
									LabelText="Merchant Country Code"
									inputProps={this.maxLength3}
									Value={model && model.MerchantCountryCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MerchantCategoryCode"
									LabelMd={6}
									LabelText="Merchant Category Code"
									inputProps={this.maxLength4}
									Value={model && model.MerchantCategoryCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MerchantZipCode"
									LabelMd={6}
									LabelText="Merchant Zip Code"
									inputProps={this.maxLength5}
									Value={model && model.MerchantZipCode}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="MerchantState"
									LabelMd={6}
									LabelText="Merchant State"
									inputProps={this.maxLength5}
									Value={model && model.MerchantState}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="RequestedPaymentService"
									LabelMd={6}
									LabelText="Requested Payment Service"
									inputProps={this.maxLength1}
									Value={model && model.RequestedPaymentService}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="UsageCode"
									LabelMd={6}
									LabelText="Usage Code"
									inputProps={this.maxLength1}
									Value={model && model.UsageCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ReasonCode"
									LabelMd={6}
									LabelText="Reason Code"
									inputProps={this.maxLength2}
									Value={model && model.ReasonCode}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="SettlementFlag"
									LabelMd={6}
									LabelText="Settlement Flag"
									inputProps={this.maxLength1}
									Value={model && model.SettlementFlag}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AuthCharacteristicsInd"
									LabelMd={6}
									LabelText="Auth Characteristics Ind"
									inputProps={this.maxLength1}
									Value={model && model.AuthCharacteristicsInd}
									ValueChanged={this.HandleChange} />



								<GenericTextInput
									Name="AuthorizationCode"
									LabelMd={6}
									LabelText="Authorization Code"
									inputProps={this.maxLength6}
									Value={model && model.AuthorizationCode}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="PosTerminalCapability"
									LabelMd={6}
									LabelText="PosTerminal Capability"
									inputProps={this.maxLength1}
									Value={model && model.PosTerminalCapability}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="InternationalFeeIndicator"
									LabelMd={6}
									LabelText="International Fee Indicator"
									inputProps={this.maxLength1}
									Value={model && model.InternationalFeeIndicator}
									ValueChanged={this.HandleChange} />
							</GridItem>


						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

Tcr0.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default Tcr0;