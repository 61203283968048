import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicantInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
		this.MaritalStatusParameterCode = { ParameterCode: "LOSMaritalStatus" };
		this.OccupationParameterCode = { ParameterCode: "LOSOccupation" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.LOSPhoneGivenParameterCode = { ParameterCode: "LOSPhoneGiven" };

		this.LOSDownpaymentParameterCode = { ParameterCode: "LOSDownpayment" };
		this.LOSInterbankTimeParameterCode = { ParameterCode: "LOSInterbankTime" };
		this.LOSSalaryParameterCode = { ParameterCode: "LOSSalary" };
		this.LOSHousholdInParameterCode = { ParameterCode: "LOSHousholdIn" };
		this.LOSEmplTimeParameterCode = { ParameterCode: "LOSEmplTime" };
		this.LOSTimePerParameterCode = { ParameterCode: "LOSTimePer" };
		this.LOSAddrTimeParameterCode = { ParameterCode: "LOSAddrTime" };

		this.LOSResidentalStatusParameterCode = { ParameterCode: "LOSResidentalStatus" };
		this.LOSEduLevelParameterCode = { ParameterCode: "LOSEduLevel" };
		this.LanguageParameterCode = { ParameterCode: "Language" };
		this.CountryParameterCode = { ParameterCode: "Country" };
		this.IdentityVerifyingPersonParameterCode = { ParameterCode: "IdentityVerifyingPerson" };
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.parameterIdentityType = { ParameterCode: "IdentityType" };

		this.maxLength20 = { maxLength: 20 };
		this.maxLength100 = { maxLength: 100 };
		this.marginLeft2 = { marginLeft: 2 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength64 = { maxLength: 64 };
		this.emptyObject = {};

	}

	componentDidMount() {
	}


	render() {
		const { ApplicantModel, Title, Disabled, IsActive, Name } = this.props;
		const { isLoading, alert } = this.state;
		const model = ApplicantModel;

		return (
			<div>


				<LoadingComponent Show={isLoading} />

				<GenericExpansionPanel
					IsActive={IsActive}
					Title={Title}
					Name={Name}
					ValueChanged={this.props.HandleChangeFilterPanel}>
					<GridContainer
						direction="row"
						justify="flex-start"
						alignItems="stretch">
						<GridItem xs={12}>
							<GridContainer>
								{alert}
								<GridItem xs={12}>

									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="flex-start"
									>
										<GridItem xs={6}>
											<GenericTitle text={"General Information"} />
											<div>
												<GridItem >
													<GenericTextInput
														Name="FirstName"
														inputProps={this.ParameterMaxLength30}
														IsText
														LabelText="First Name"
														Value={model.FirstName}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem >
													<GenericTextInput
														Name="MiddleName"
														inputProps={this.ParameterMaxLength30}
														IsText
														LabelText="Middle Name"
														Value={model.MiddleName}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem >
													<GenericTextInput
														Name="LastName"
														inputProps={this.ParameterMaxLength30}
														IsText
														LabelText="Last Name"
														Value={model.LastName}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem >
													<GenericDateInput
														Name="BirthDate"
														IsPast={true}
														LabelText="Date of Birth"
														Value={model.BirthDate ? DateHelper.ToDateInputValue(model.BirthDate) : ""}
														Disabled={Disabled}
														IncludeTime={false}
													/>
												</GridItem>
											</div>
										</GridItem>
										<GridItem xs={6}>
											<GenericTitle text={"ID Verification"} />
											<div>
												<GridItem>
													<GenericSelectInput
														Name="IdentityTypeId"
														LabelText="ID Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterIdentityType}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.IdentityTypeId}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GenericTextInput
														inputProps={this.maxLength20}
														Name="IdentityNumber"
														LabelText="ID Number"
														Value={model.IdentityNumber}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GenericDateInput
														Name="IdentityExpireDate"
														LabelText="ID Exp Date"
														Value={model.IdentityExpireDate ? DateHelper.ToMoment(model.IdentityExpireDate) : ""}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GenericTextInput
														Name="IdentityPlaceOfIssue"
														LabelText="ID Place of Issue"
														Value={model.IdentityPlaceOfIssue}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryOfCitizenShipId"
														LabelText="Country of Citizenship"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.CountryParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryOfCitizenShipId}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryOfBirthId"
														LabelText="Country of Birth"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.CountryParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryOfBirthId}
														Disabled={Disabled}
													/>

												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryOfResidentId"
														LabelText="Country of Resident"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.CountryParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryOfResidentId}
														Disabled={Disabled}
													/>
												</GridItem>
											</div>
										</GridItem>
									</GridContainer>
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="flex-start"
									>
										<GridItem xs={6}>
											<GenericTitle text={"Communication Information"} />
											<div>
												<GridItem>
													<GenericEmailInput
														Name="Email"
														LabelText="E-Mail"
														inputProps={this.ParameterMaxLength64}
														Value={model.Email}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={8}>
															<GenericSelectInput
																Name="MobilePhoneCountryCodeId"
																LabelText="Phone Number"
																TitleLabel="Country Code"
																LabelMd={6}
																Method="POST"
																Url="/coreapi/v1.0/Country/GetAll"
																Parameter={this.emptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Value={model.MobilePhoneCountryCodeId}
																Disabled={Disabled}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
															/>
														</GridItem>
														<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
															<GenericNumberInput
																IsPhone
																Name="MobilePhoneNumber"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																LabelMd={0}
																Value={model.MobilePhoneNumber}
																Disabled={Disabled}
																MaxLength={10} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={8}>
															<GenericSelectInput
																Name="OfficePhoneCountryCodeId"
																LabelText="Office Phone"
																TitleLabel="Country Code"
																LabelMd={6}
																Method="POST"
																Url="/coreapi/v1.0/Country/GetAll"
																Parameter={this.emptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Value={model.OfficePhoneCountryCodeId}
																Disabled={Disabled}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
															/>
														</GridItem>
														<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
															<GenericNumberInput
																IsPhone
																Name="OfficePhoneNumber"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																LabelMd={0}
																Value={model.OfficePhoneNumber}
																Disabled={Disabled}
																MaxLength={10} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={8}>
															<GenericSelectInput
																Name="HomePhoneCountryCodeId"
																LabelText="Home Phone"
																TitleLabel="Country Code"
																LabelMd={6}
																Method="POST"
																Url="/coreapi/v1.0/Country/GetAll"
																Parameter={this.emptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Value={model.HomePhoneCountryCodeId}
																Disabled={Disabled}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
															/>
														</GridItem>
														<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
															<GenericNumberInput
																IsPhone
																Name="HomePhoneNumber"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																LabelMd={0}
																Value={model.HomePhoneNumber}
																Disabled={Disabled}
																MaxLength={10} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="HasEmailNotificationId"
														LabelText="E-Mail Notification"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.YesNoParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.HasEmailNotificationId}
														Disabled={Disabled}

													/>

												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="HasTextNotificationId"
														LabelText="Text Notification"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.YesNoParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.HasTextNotificationId}
														Disabled={Disabled}
													/>

												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="LanguageId"
														LabelText="Language"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.LanguageParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.LanguageId}
														Disabled={Disabled}
													/>

												</GridItem>
												<GridItem>
													<div></div>
												</GridItem>

											</div>

										</GridItem>
										<GridItem xs={6}>
											<GenericTitle text={"Address Information"} />
											<div>
												<GridItem>
													<GenericTextInput
														Name="Address1"
														LabelText="Address Line 1"
													//	Id="street-address"
														Value={model.Address1}
														Disabled={Disabled}
														inputProps={this.maxLength100} />
												</GridItem>
												<GridItem>
													<GenericTextInput
														Name="Address2"
														// Id="street-address2"
														LabelText="Address Line 2"
														Value={model.Address2}
														Disabled={Disabled}
														inputProps={this.maxLength100} />
												</GridItem>
												<GridItem>
													<GenericTextInput
														Name="ZipCode"
														LabelText="Postal Code / Zip Code"
												//		Id="postcode"
														Value={model.ZipCode}
														Disabled={Disabled}

														inputProps={this.maxLength20} />
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryId"
														LabelText="Country"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryId}
														Disabled={Disabled}
													/>
													<GenericSelectInput
														key={model.CountryId + "province"}
														Name="ProvinceId"
														LabelText="Province"
														Method="POST" Url="/coreapi/v1.0/Province/Filter"
														Parameter={{ CountryId: model.CountryId }} DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.ProvinceId}
														Disabled={Disabled}
													/>
													<GenericSelectInput
														key={"city" + model.ProvinceId + ""}
														Name="CityId"
														LabelText="City"
														Method="POST"
														Url="/coreapi/v1.0/City/Filter"
														Parameter={{ ProvinceId: model.ProvinceId }}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CityId}
														Disabled={Disabled}
													/>
												</GridItem>
											</div>

										</GridItem>

									</GridContainer>

								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={11}>
						</GridItem>
						<GridItem xs={1}>
							<Button size="sm" color="black" fullWidth
								onClick={this.props.NextClicked}>Next</Button>
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>
			</div >
		);
	}
}

LoanApplicantInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	ApplicantModel: PropTypes.object,
	Title: PropTypes.string,
	Disabled: PropTypes.bool,
	NextClicked: PropTypes.func,
	HandleChangeFilterPanel: PropTypes.func,
	Name: PropTypes.string,
	IsActive: PropTypes.bool
};

export default withArtifex(LoanApplicantInformation, {});