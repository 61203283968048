import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { CrudType, EmvScriptPoolInsertType, FileType, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class EmvScriptQueue extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "Header",
				accessor: "Header"
			}
		];

		this.maxLength50 = { maxLength: 50 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};
		this.FileModel = {
			UniqueClientId: "",
			ClientCustomerId: "",
			CardNumber: "",
			TemplateId: "",

		}

		this.SingleModel = {
			UniqueClientId: "",
			ClientCustomerId: "",
			CardId: "",
			TemplateId: ""
		}

		this.renderItemUniqueClient = {};

		this.state = {
			alert: null,
			model: {},
			list: [],
			FileCardData: [],
			filePostCardList: [],
			cardMessages: [],
			selected: null,
			isFileChanged: false,
			isLoading: false,
			FileModel: {},
			SingleModel: {},
			FilterModel: {},
			IsSingleCardActive: false,
			IsFileCaCardActive: false,
			IsFileCardActive: false,
			IsFileCardListDialogOpen: false,

		};
	}

	componentDidMount() {
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.props.setAppLeftTitle("EMV Script Queue");
	}

	FileModelValueChanged = (name, newValue, data) => {
		this.setState(state => {
			var FileModel = state.FileModel;

			if (name === "Customer") {
				if (newValue) {
					FileModel.ClientCustomerId = newValue.Id;
					FileModel.CustomerNumber = newValue.CustomerNumber;
				} else {
					delete FileModel.ClientCustomerId;
					delete FileModel.CustomerNumber;
				}
			}
			if (name === "UniqueClientId") {
				delete FileModel.ClientCustomerId;
				delete FileModel.CustomerNumber;
			}
			FileModel[name] = newValue;
			return { FileModel };
		});
	}
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}


	FilterValueChanged = (name, newValue, data) => {
		this.setState(state => {
			var FilterModel = state.FilterModel;
			if (name === "Customer") {
				if (newValue) {
					FilterModel.ClientCustomerId = newValue.Id;
					FilterModel.CustomerNumber = newValue.CustomerNumber;
				} else {
					delete FilterModel.ClientCustomerId;
					delete FilterModel.CustomerNumber;
				}
			}
			if (name === "UniqueClientId") {
				delete FilterModel.ClientCustomerId;
				delete FilterModel.CustomerNumber;
			}
			FilterModel[name] = newValue;
			return { FilterModel };
		});
	}

	SingleModelValueChanged = (name, newValue, data) => {

		this.setState(state => {
			var SingleModel = state.SingleModel;

			if (name === "Customer") {
				if (newValue) {
					SingleModel.ClientCustomerId = newValue.Id;
					SingleModel.CustomerNumber = newValue.CustomerNumber;
				} else {
					delete SingleModel.ClientCustomerId;
					delete SingleModel.CustomerNumber;
				}
			}
			if (name === "UniqueClientId") {
				delete SingleModel.ClientCustomerId;
				delete SingleModel.CustomerNumber;
			}



			SingleModel[name] = newValue;
			return { SingleModel };
		});
	}

	ModelValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			var SingleModel = state.SingleModel;
			var FileModel = state.FileModel;
			if (data) {
				if (name == "EmvScriptPollType") {
					if (data.ParameterValue == EmvScriptPoolInsertType.Single) {
						state.IsSingleCardActive = true;
						state.IsFileCardActive = false;
						FileModel.Disabled = true;
						SingleModel.Disabled = false;

					}
					else if (data.ParameterValue == EmvScriptPoolInsertType.File) {
						state.IsFileCardActive = true;
						state.IsSingleCardActive = false;
						SingleModel.Disabled = true;
						FileModel.Disabled = false;
					}
				}
			}
			model[name] = value;

			return { model };
		});
	}


	RowSelected = (index) => {
		const { list } = this.state;
		this.setState({ model: list[index], selected: index });
	}

	DeleteConfirm = () => {
		const { showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete_?", this.Delete);
	}

	Delete = () => {
		const { SingleModel } = { ...this.state };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/EmvScriptTemplate/DeleteEmvScriptSinglePool",
			{
				"Id": SingleModel.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.ShowMessageNode("success", "Delete transaction successfully updated.");
					this.Clear();
					this.Search();
					this.setState({ isLoading: false });
				}

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Update = () => {
		this.setState({ isLoading: true });

		if (!this.Validate()) {
			return;
		}

		const { SingleModel } = { ...this.state };

		Proxy.POST(
			"/prepaidapi/v1.0/EmvScriptTemplate/InsertEmvScriptSinglePool",
			{
				"Id": SingleModel.Id,
				"TemplateId": SingleModel.TemplateId,
				"CardId": SingleModel.CardId
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessageNode("success", "Update transaction successfully updated.");
				this.Clear();
				this.Search();
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { FileModel, SingleModel, IsSingleCardActive, IsFileCardActive,  filePostCardList } = this.state;

		if (IsSingleCardActive) {
			Proxy.POST(
				"/prepaidapi/v1.0/EmvScriptTemplate/InsertEmvScriptSinglePool",
				{
					"TemplateId": SingleModel.TemplateId,
					"CardId": SingleModel.CardId
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
						return;
					}
					else {
						this.ShowMessageNode("success", "Insert transaction successfully added.");
						this.setState({ isLoading: false });
						this.Search();
						this.Clear();
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
				}
			);
		}

		else if (IsFileCardActive) {

			if (filePostCardList.length < 1) {
				this.ShowMessageNode("warning", "Warning", "Card not found");
				return;
			}
			Proxy.POST(
				"/prepaidapi/v1.0/EmvScriptTemplate/InsertEmvScriptFilePool",
				{
					MaskedCardList: filePostCardList,
					"TemplateId": FileModel.TemplateId
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
						return;
					}
					else {
						this.ShowMessageNode("success", "Insert transaction successfully added.");
						this.setState({ isLoading: false });
						this.Search();
						this.Clear();
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
				}
			);
		}
	}


	Upload = () => {
		this.fileInputRef.current.click();
	}

	View = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.FileDownloadWithGenericResponse(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			model.File.FileName,
			model.File.FileExtension.ParameterDesc,
			{
				"FileId": model.FileId
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	Clear = () => {
		this.setState({ model: {}, FileModel: {}, SingleModel: {}, FilterModel: {}, selected: null, isFileChanged: false, IsFileCardActive: false, IsSingleCardActive: false });
	}

	FileSelect = (e) => {

		this.setState({ isLoading: true })
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { FileModel } = this.state;
		FileModel.TempFileId = FileModel.File !== undefined
			? FileModel.File.Id
			: 0;
		FileModel.File = file;
		FileModel.FileName = file.name;
		FileModel.FileSelect = true;


		const { showMessage } = this.props;

		var formData = new FormData();
		formData.append("file", FileModel.File, FileModel.FileName);
		formData.append("fileId", FileModel.TempFileId || 0);
		formData.append("isBinaryOrBase64", FileType.Binary);
		formData.append("isInsertOrUpdate", FileModel.Id == null ? CrudType.Insert : CrudType.Update);

		Proxy.FileUpload("/prepaidapi/v1.0/EmvScriptTemplate/EmvScriptPollFileCardData",
			formData,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.SubmitFileCardDataCheck();
					this.setState({ FileModel, FileCardData: responseData.Item, isFileChanged: true, IsFileCardListDialogOpen: true, isLoading: false, });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);


	}

	Validate() {

		const {  IsFileCardActive, IsSingleCardActive, IsFileCardListDialogOpen, FileModel, SingleModel } = this.state;
		const { showMessageNode } = this.props;
		var messages = [];

		if (IsFileCardActive) {
			if (!IsFileCardListDialogOpen) {
				if ((FileModel.FileName === undefined || FileModel.FileName == null)
					&& (FileModel.File === undefined || FileModel.File == null)) {
					messages.push("Upload File cannot be null.");
				}
				if (FileModel.TemplateId == undefined || FileModel.TemplateId == null
					|| FileModel.TemplateId == "")
					messages.push("Template cannot be null.");
			}
			if (FileModel.ClientCustomerId == null || FileModel.ClientCustomerId == undefined || FileModel.ClientCustomerId == "")
				messages.push("Customer Number cannot be null");

			if (FileModel.UniqueClientId == null || FileModel.UniqueClientId == undefined || FileModel.UniqueClientId == "")
				messages.push("Client Name cannot be null");
		}


		else if (IsSingleCardActive) {
			if (SingleModel.ClientCustomerId == null || SingleModel.ClientCustomerId == undefined || SingleModel.ClientCustomerId == "")
				messages.push("Customer Number cannot be null");

			if (SingleModel.UniqueClientId == null || SingleModel.UniqueClientId == undefined || SingleModel.UniqueClientId == "")
				messages.push("Client cannot be null");

			if (SingleModel.TemplateId == undefined || SingleModel.TemplateId == null || SingleModel.TemplateId == "")
				messages.push("Template cannot be null.");

			if (SingleModel.CardId == undefined || SingleModel.CardId == null || SingleModel.CardId == "")
				messages.push("Card Number cannot be null.");

		}
		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	SubmitFileCardDataCheck = () => {
		this.setState({ isLoading: true });

		const { FileModel } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/Card/CardSearch",
			{
				ClientCustomerId: FileModel.ClientCustomerId,
				CardSearchCriteria: 2
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					var cardMessages = [];
					var responseList = [];
					var filePostCardList = [];

					responseData.Item.map((i, value) => {
						responseList.push(responseData.Item[value].MaskedCardNumber);
					});


					this.state.FileCardData.map((value) => {
						if (!(responseList.includes(value.CardNumber))) {
							cardMessages.push({
								"CardNumber": value.CardNumber,
								"Description": "Card Number not found",
								"Status": "Rejected"
							});
						}
						else {
							cardMessages.push({
								"CardNumber": value.CardNumber,
								"Description": "Card Number found",
								"Status": "Success"
							});

							filePostCardList.push({
								CardNumber: value.CardNumber
							});
						}
					});

					this.setState({
						isLoading: false,
						cardMessages,
						filePostCardList
					});

				}
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	Search = () => {
		this.setState({ isLoading: true });
		const { FilterModel } = { ...this.state };
		Proxy.POST(
			"/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptSinglePool",
			{
				"TemplateId": FilterModel.TemplateId,
				"CardId": FilterModel.CardId,
				"ClientCustomerId": FilterModel.ClientCustomerId,
				"UniqueClientId": FilterModel.UniqueClientId,
				"ScriptStatusId": FilterModel.EmvScriptStatusId
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessageNode("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessageNode("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	SelectedRowChange = (index) => {

		var data = this.state.list[index];

		const { SingleModel, FileModel, model } = this.state;
		FileModel.Disabled = true;
		model.Disabled = true;
		model.IsUpdate = true;
		SingleModel.Id = data.Id;
		SingleModel.UniqueClientId = data.UniqueClientId;
		SingleModel.ClientCustomerId = data.ClientCustomerId;
		SingleModel.CardId = data.CardId;
		SingleModel.TemplateId = data.TemplateId;
		this.setState({ SingleModel, FileModel, model, IsSingleCardActive: true, IsFileCardActive: false })

	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.SingleModel };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		model[name] = newValue;
		this.setState({ SingleModel: model });
	}
	FillCustomerSingle = (customer) => {
		this.SingleModelValueChanged("Customer", customer);
	}

	FillCustomerFile = (customer) => {
		this.FileModelValueChanged("Customer", customer);
	}
	FillCustomerFilter = (customer) => {
		this.FilterValueChanged("Customer", customer);
	}
	render() {
		const { model, isLoading, IsFileCardListDialogOpen, IsFileCardActive, IsSingleCardActive, FileModel, SingleModel, FilterModel } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: model.IsUpdate,
							OnClick: this.Submit

						},
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search

						},
						{
							Code: "Update",
							Disabled: !model.IsUpdate,
							OnClick: this.Update
						},
						{
							Code: "Delete",
							Disabled: !model.IsUpdate,
							OnClick: this.Delete

						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear

						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<input
					type="file"
					key={FileModel.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />


				{this.state.alert}

				<GenericDialog open={IsFileCardListDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={this.state.cardMessages}
							Columns={[

								{
									Header: "Card Number",
									accessor: "CardNumber"
								},
								{
									Header: "Description",
									accessor: "Description"
								},
								{
									Header: "Status",
									accessor: "Status"
								},

							]}
							ShowPagination={true}
							SelectedIndex={this.state.rowIndex}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ IsFileCardListDialogOpen: false })}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ IsFileCardListDialogOpen: false, cardMessages: [], filePostCardList: [] })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>




				<Card className="no-radius" style={{ height: "90%" }}>
					<GenericExpansionPanel Title="Filter Panel" IsActive={false}>
						<CardBody>
							<GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={this.parameterUniqueClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={FilterModel.UniqueClientId}
											ValueChanged={this.FilterValueChanged}
											CanClear
											Disabled={IsClient}
											DefaultIndex={IsClient ? 0 : -1}
										/>

										<CustomerComponent
											key={FilterModel.UniqueClientId}
											LabelMd={4}
											CustomerNumber={FilterModel.CustomerNumber}
											HandleChange={this.FilterValueChanged}
											FillCallback={this.FillCustomerFilter}
											Programs={[ProgramCodes.Prepaid]}
											UniqueClientId={FilterModel.UniqueClientId}

										/>
									</GridItem>

									<GridItem xs={4}>

										<GenericSelectInput
											Name="TemplateId"
											LabelText="Template"
											Method="POST"
											Url="/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptTemplate"
											DataRoot="Item"
											KeyValueMember="TemplateId"
											TextValueMember="TemplateName"
											Value={FilterModel.TemplateId}
											Disabled={false}
											ValueChanged={this.FilterValueChanged}
										/>

										<GenericSelectInput
											key={FilterModel.ClientCustomerId}
											Name="CardId"
											LabelText="Card Number"
											Method="POST"
											Url="/prepaidapi/v1.0/Card/CardSearch"
											Parameter={{
												ClientCustomerId: FilterModel.ClientCustomerId,
												CardSearchCriteria: 2
											}}
											DataRoot="Item"
											KeyValueMember="CardId"
											TextValueMember="MaskedCardNumber"
											Value={FilterModel.CardId}
											Disabled={false}
											ValueChanged={this.FilterValueChanged}
										/>

									</GridItem>

									<GridItem xs={4}>
										<ParameterComponent
											Name="EmvScriptStatusId"
											LabelText="Emv Script Status"
											ParameterCode="EmvScriptStatus"
											Value={FilterModel.EmvScriptStatusId}
											ValueChanged={this.FilterValueChanged}
										/>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius" >
					<GenericExpansionPanel Title="Main Parameters" IsActive={true}>
						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={4}>
									<ParameterComponent Name="EmvScriptPollType" Value={model.EmvScriptPollType} LabelText="Entry Type" ParameterCode="EmvScriptTemplatePool" ValueChanged={this.ModelValueChanged} Disabled={model.Disabled} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius" style={{ height: "90%", opacity: IsFileCardActive ? 1 : 0.4 }}>
					<GenericExpansionPanel Title="File" IsActive={IsFileCardActive}>
						<CardBody>
							<GridContainer>
								<GridContainer >
									<GridItem xs={6}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={this.parameterUniqueClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={FileModel.UniqueClientId}
											ValueChanged={this.FileModelValueChanged}
											CanClear
											Disabled={IsClient}
											DefaultIndex={IsClient ? 0 : -1}
										/>

										<CustomerComponent
											key={FileModel.UniqueClientId}
											LabelMd={4}
											CustomerNumber={FileModel.CustomerNumber}
											HandleChange={this.FileModelValueChanged}
											FillCallback={this.FillCustomerFile}
											Programs={[ProgramCodes.Prepaid]}
											UniqueClientId={FileModel.UniqueClientId}
											Disabled={SingleModel.Id > 0 ? true : false}
										/>
									</GridItem>

									<GridItem xs={6}>

										<GenericSelectInput
											Name="TemplateId"
											LabelText="Template"
											Method="POST"
											Url="/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptTemplate"
											DataRoot="Item"
											KeyValueMember="TemplateId"
											TextValueMember="TemplateName"
											Value={FileModel.TemplateId}
											Disabled={FileModel.Disabled}
											ValueChanged={this.FileModelValueChanged}
										/>

										<div>
											<GenericLabel Text="Upload File" />
											<Button size="sm" onClick={this.Upload} disabled={FileModel.Disabled} >BROWSE</Button>

										</div>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius" style={{ height: "90%", opacity: IsSingleCardActive ? 1 : 0.4 }}>
					<GenericExpansionPanel Title="Individual" IsActive={IsSingleCardActive}  >
						<CardBody>
							<GridContainer>
								<GridContainer >
									<GridItem xs={6}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={this.parameterUniqueClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={SingleModel.UniqueClientId}
											ValueChanged={this.SingleModelValueChanged}
											CanClear
											Disabled={IsClient}
											DefaultIndex={IsClient ? 0 : -1}
										/>

										<CustomerComponent
											key={SingleModel.UniqueClientId}
											LabelMd={4}
											CustomerNumber={SingleModel.CustomerNumber}
											HandleChange={this.SingleModelValueChanged}
											FillCallback={this.FillCustomerSingle}
											Programs={[ProgramCodes.Prepaid]}
											UniqueClientId={SingleModel.UniqueClientId}
											Disabled={SingleModel.Id > 0 ? true : false}
										/>
									</GridItem>
									<GridItem xs={6}>
										<GenericSelectInput
											key={SingleModel.ClientCustomerId}
											Name="CardId"
											LabelText="Card Number"
											Method="POST"
											Url="/prepaidapi/v1.0/Card/CardSearch"
											Parameter={{
												ClientCustomerId: SingleModel.ClientCustomerId,
												CardSearchCriteria: 2
											}}
											DataRoot="Item"
											KeyValueMember="CardId"
											TextValueMember="MaskedCardNumber"
											Value={SingleModel.CardId}
											Disabled={false}
											ValueChanged={this.SingleModelValueChanged}
										/>

										<GenericSelectInput
											Name="TemplateId"
											LabelText="Template"
											Method="POST"
											Url="/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptTemplate"
											DataRoot="Item"
											KeyValueMember="TemplateId"
											TextValueMember="TemplateName"
											Value={SingleModel.TemplateId}
											Disabled={SingleModel.Disabled}
											ValueChanged={this.SingleModelValueChanged}
										/>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius" style={{ height: "90%" }}>

					<CardBody>
						<GridContainer>
							<DialogContent>
								<GenericGrid
									Data={this.state.list}
									Columns={[
										// {
										// 	Header: "Actions",
										// 	accessor: "Actions",
										// 	sortable: false,
										// 	filterable: false,
										// 	width: 75
										// },
										{
											Header: "Card Number",
											accessor: "CardNumber"
										},
										{
											Header: "Customer Name",
											accessor: "ClientCustomerName"
										},
										{
											Header: "Template Name",
											accessor: "TemplateName"
										},
										{
											Header: "Client Name",
											accessor: "UniqueClientName"
										},
										{
											Header: "Script Status",
											accessor: "ScriptStatusDesc"
										},
										{
											Header: "Send Date",
											accessor: "SendDate",
											type: GridColumnType.DateTime
										},
									]}
								/>
							</DialogContent>
						</GridContainer>
					</CardBody>
				</Card>


			</div>
		);

	}
}
EmvScriptQueue.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default EmvScriptQueue;