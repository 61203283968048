import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericTitle, GenericAlert, GenericGrid, GenericColorInput, GenericNumberInput, GenericCheckInput, GenericDateInput, GenericSelectInput, GenericEmailInput, GenericTextInput, GenericLabel, GenericIcon, GenericDialogActions, GenericDialog } from "views/Components/Generic";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import QrImage from "assets/img/qr.png";
import CardHeader from "components/Card/CardHeader";
import GridButton from "views/Components/GridButton.jsx";
import CampaignCouponTemplate from "views/Campaign/Components/CampaignCouponTemplate";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import { GridColumnType } from "views/Constants/Constant";

class ICouponProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {},
			couponProfileList: [],
			alert: null,
			isLoading: false,
			isCouponDialogOpen: false,
			isClient: ClientHelper.IsClient(),
		};

		this.ParameterMaxLength40 = { maxLength: 40 };
		this.ParameterMaxLength300 = { maxLength: 200 };
		this.ParameterEmpty = {};
		this.ParameterPreconditionType = { ParameterCode: "PreconditionType" };
		this.ParameterCouponDefinitionType = { ParameterCode: "CouponDefinitionType" };
		this.ParameterExecutionType = { ParameterCode: "CampaignExecutionType" };

		this.fileInputRef1 = React.createRef();
		this.fileInputRef2 = React.createRef();
		this.fileInputRef3 = React.createRef();

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.GetProfileById(row.original.Id)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Campaign Partner",
				accessor: "CampaignPartner.PartnerName"
			},
			{
				Header: "Coupon Definition",
				accessor: "CouponDefinition.ParameterDesc"
			},
			{
				Header: "Coupon Type",
				accessor: "CouponType",
				Cell: row => (
					<span>
						{row.original.IsFreeGift ? "Free Gift" : "" + row.original.IsDiscount ? "Discount" : ""}
					</span>
				)
			},
			{
				Header: "Precondition",
				accessor: "Precondition.ParameterDesc"
			},
			{
				Header: "Amount",
				accessor: "Amount"
			},
			{
				Header: "Reward",
				accessor: "Reward"
			},
			{
				Header: "Message",
				accessor: "Message"
			},
			{
				Header: "Coupon Start Date",
				accessor: "StartDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Coupon End Date",
				accessor: "ExpireDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Background Color",
				accessor: "BackgroundColor",
				Cell: row => (
					<div style={{ width: "20px", height: "20px", backgroundColor: row.value }}>
						<br />
					</div>
				)
			}
		];

	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("i-Coupon Profile");

	}
	OperationCheck = () => {
		var ErrorList = [];
		const data = this.state.model;
		const vModel = this.state.vModel;
		if (data.ProgramId == null || data.ProgramId == "") {
			vModel.ProgramId = true;
			ErrorList.push("Program must be defined!");
		} else { vModel.ProgramId = false; }
		if (data.CouponDefinitionId == null || data.CouponDefinitionId == "") {
			vModel.CouponDefinitionId = true;
			ErrorList.push("Definition Type must be defined!");
		} else { vModel.CouponDefinitionId = false; }
		if (data.ProfileName == null || data.ProfileName == "") {
			vModel.ProfileName = true;
			ErrorList.push("i-Coupon Profile Name must be defined!");
		} else { vModel.ProfileName = false; }
		if (data.CampaignPartnerId == null || data.CampaignPartnerId == "") {
			vModel.CampaignPartnerId = true;
			ErrorList.push("Campaign Partner must be defined!");
		} else { vModel.CampaignPartnerId = false; }

		if (data.StartDateTime == null || data.StartDateTime == "") {
			vModel.StartDateTime = true;
			ErrorList.push("Start Date Time must be defined!");
		} else {
			vModel.StartDateTime = false;
			data.StartDateTime = DateHelper.ToMoment(data.StartDateTime);
			if (!data.StartDateTime.isValid()) {
				ErrorList.push("Start Date Time must be valid!");
			}
		}
		if (data.ExpireDateTime == null || data.ExpireDateTime == "") {
			vModel.ExpireDateTime = true;
			ErrorList.push("Expire Date Time must be defined !.");
		} else {
			vModel.ExpireDateTime = false;
			data.ExpireDateTime = DateHelper.ToMoment(data.ExpireDateTime);
			if (!data.ExpireDateTime.isValid()) {
				ErrorList.push("Expire Date Time must be valid !.");
			}
		}
		if (data.Message == null || data.Message == "") {
			vModel.Message = true;
			ErrorList.push("Message must be defined !.");
		} else { vModel.Message = false; }
		if (data.BackgroundColor == null || data.BackgroundColor == "") {
			vModel.BackgroundColor = true;
			ErrorList.push("Color must be defined !.");
		} else { vModel.BackgroundColor = false; }
		if (data.IsFreeGift == null && data.IsDiscount == null) {
			ErrorList.push("Coupon type must be defined ! (Discount or Free Gift)");
		} else { vModel.IsFreeGift = false; vModel.IsDiscount = false; }
		if (data.IsFreeGift == false && data.IsDiscount == false) {
			ErrorList.push("Coupon type must be defined ! (Discount or Free Gift)");
		}

		this.setState({ vModel })
		if (ErrorList.length > 0) {
			this.props.showMessageNode("error", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}

	FileSelect = (e, name) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var HandleChageTemp = this.handleChange;
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			var base64 = reader.result;
			HandleChageTemp(name.concat("Name"), file.name);
			HandleChageTemp(name, base64);
		};
		reader.onerror = function (error) {
			console.log('File Error: ', error);
		};
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};

			if (name == "IsDiscount" && newValue == true) {
				temp.IsFreeGift = false;
				temp.PreconditionId = null;
				temp.Amount = null;
				temp.Reward = null;
			}
			if (name == "IsFreeGift" && newValue == true) {
				temp.IsDiscount = false;
				temp.PreconditionId = null;
				temp.Amount = null;
				temp.Reward = null;
			}
			temp[name] = newValue;
			return { model: temp };
		});
	}
	UpdateModel = () => {
		var temp = this.state.model;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CouponProfile/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", temp.Id > 0 ? "i-Coupon Updated ! " : "i-Coupon Saved ! ");
				this.SerachICouponProfiles();
				this.ClearData();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SerachICouponProfiles = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CouponProfile/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ couponProfileList: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	GetProfileById = (ProfileId) => {
		if (ProfileId == null || ProfileId == 0) {
			this.props.showMessage("error", "Error", "Profile Id required for fetching profile !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CouponProfile/GetById",
			{ Id: ProfileId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ model: responseData.Item });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.props.showMessage("error", "Error", "i-Coupon must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/campaignapi/v1.0/CouponProfile/Delete/Id?Id=" + this.state.model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "i-Coupon deleted !");
				this.ClearData();
				this.SerachICouponProfiles();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (index) => {
		const temp = this.state.couponProfileList[index];
		this.GetProfileById(temp.Id);
	}
	ClearData = () => {
		this.setState({
			model: {},
			couponProfileList: [],
			alert: null,
			isLoading: false,
			isCouponDialogOpen: false
		});
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}
	render() {
		const { Disabled } = this.props;
		const { model, couponProfileList, vModel } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled || model.Id > 0 || !this.state.isClient },
					{ Code: "Update", OnClick: this.OperationCheck, Disabled: Disabled || !model.Id > 0 || !this.state.isClient },
					{ Code: "Search", OnClick: this.SerachICouponProfiles, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
					{ Code: "Delete", OnClick: () => this.props.showConfirmMessage("warning", "Warning", "Delete this i-Coupon Profile ?", this.DeleteModel), Disabled: Disabled || !model.Id > 0 || !this.state.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text="i-Coupon Information" />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							{!this.state.isClient && (
								<GridItem xs={3} >
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemUniqueClient}
										Value={model.UniqueClientId}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={this.state.isClient}
										DefaultIndex={this.state.isClient ? 0 : -1}
									/>
								</GridItem>
							)}
							<GridItem xs={3} >
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									Name="ProgramId"
									LabelText="Program"
									Value={model.ProgramId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
									DataRoot="Item"
									Required
									IsInvalid={vModel.ProgramId}
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									LabelMd={6}
									Name="ExecutionTypeId"
									LabelText="Campaign Execution Type"
									Value={model.ExecutionTypeId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterExecutionType}
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericSelectInput
									Disabled={Disabled}
									Name="CouponDefinitionId"
									LabelText="Definition Type"
									Value={model.CouponDefinitionId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterCouponDefinitionType}
									DataRoot="Item"
									Required
									IsInvalid={vModel.CouponDefinitionId}
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericTextInput
									Disabled={Disabled}
									IsText={true}
									inputProps={this.ParameterMaxLength40}
									Name="ProfileName"
									LabelText="i-Coupon Profile Name"
									Value={model.ProfileName}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.ProfileName}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"i-Coupon"} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={1}>
								<GenericCheckInput
									LabelMd={10}
									Disabled={Disabled}
									Name="IsDiscount"
									LabelText="Discount"
									Value={model.IsDiscount || false}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericSelectInput
									LabelMd={5}
									Disabled={Disabled || !model.IsDiscount}
									Name="PreconditionId"
									LabelText="Pre-condition"
									Value={model.IsDiscount ? model.PreconditionId : null}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterPreconditionType}
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericNumberInput
									LabelMd={4}
									Disabled={Disabled || !model.IsDiscount}
									MaxLength={20}
									NoFormatting={true}
									LabelText="Amount"
									Name="Amount"
									Value={model.IsDiscount ? model.Amount : null}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericTextInput
									LabelMd={4}
									Disabled={Disabled || !model.IsDiscount}
									inputProps={this.ParameterMaxLength40}
									Name="Reward"
									LabelText="Reward"
									Value={model.IsDiscount ? model.Reward : null}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer spacing={16}>
							<GridItem xs={1}>
								<GenericCheckInput
									LabelMd={10}
									Disabled={Disabled}
									Name="IsFreeGift"
									LabelText="Free Gift"
									Value={model.IsFreeGift || false}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericSelectInput
									LabelMd={5}
									Disabled={Disabled || !model.IsFreeGift}
									Name="PreconditionId"
									LabelText="Pre-condition"
									Value={model.IsFreeGift ? model.PreconditionId : null}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterPreconditionType}
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericNumberInput
									LabelMd={4}
									Disabled={Disabled || !model.IsFreeGift}
									MaxLength={20}
									NoFormatting={true}
									LabelText="Amount"
									Name="Amount"
									Value={model.IsFreeGift ? model.Amount : null}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
							<GridItem xs={2}>
								<GenericTextInput
									LabelMd={4}
									Disabled={Disabled || !model.IsFreeGift}
									inputProps={this.ParameterMaxLength40}
									Name="Reward"
									LabelText="Reward"
									Value={model.IsFreeGift ? model.Reward : null}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
						</GridContainer>
						<hr />
						<GridContainer spacing={16}>
							<GridItem xs={3} >
								<GenericSelectInput
									Disabled={Disabled}
									Name="CampaignPartnerId"
									LabelText="Campaign Partner"
									Value={model.CampaignPartnerId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="PartnerName"
									Method="POST"
									Url="/campaignapi/v1.0/CampaignPartner/Search"
									Parameter={this.ParameterEmpty}
									DataRoot="Item"
									Required
									IsInvalid={vModel.CampaignPartnerId}
								/>
							</GridItem>
							<GridItem xs={2} >
								<GenericDateInput
									IsFuture={true}
									Name="StartDateTime"
									LabelText="Start Date"
									Value={model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.StartDateTime}
								/>
							</GridItem>
							<GridItem xs={2} >
								<GenericDateInput
									DateFormat={false}
									IncludeTime={true}
									ViewMode="time"
									Name="StartDateTime"
									LabelText="Start Time"
									Value={model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.StartDateTime}
								/>
							</GridItem>
							<GridItem xs={2} >
								<GenericDateInput
									IsFuture={true}
									Name="ExpireDateTime"
									LabelText="Expiry Date"
									Value={model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.ExpireDateTime}
								/>
							</GridItem>
							<GridItem xs={2} >
								<GenericDateInput
									DateFormat={false}
									IncludeTime={true}
									ViewMode="time"
									Name="ExpireDateTime"
									LabelText="Expiry Time"
									Value={model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.ExpireDateTime}
								/>
							</GridItem>
						</GridContainer>
						<hr />
						<GridContainer spacing={16}>
							<GridItem xs={3} >
								<GenericTextInput
									inputProps={this.ParameterMaxLength300}
									MultiLine={true}
									RowCount={4}
									Disabled={Disabled}
									Name="Message"
									LabelText="Message"
									Value={model.Message}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.Message}
								/>
							</GridItem>
							<GridItem xs={3} >
								<GenericColorInput
									Disabled={Disabled}
									Name="BackgroundColor"
									LabelText="Color"
									Value={model.BackgroundColor}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.BackgroundColor}
								/>
							</GridItem>

							<GridItem xs={1} style={{ marginTop: "20px !important", float: "right" }} >
								<Button disabled={Disabled} size="sm" onClick={() => this.setState({ isCouponDialogOpen: true })} >Preview </Button>
							</GridItem>
						</GridContainer>
						<hr />
						<GridContainer>
							<GridItem xs={1}>
								<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef1.current.click()} >Logo 1 </Button>
								<input type="file" key={model.Logo1Name + "logo1xxs"} style={{ display: "none" }} onChange={(e, name) => this.FileSelect(e, "Logo1")} ref={this.fileInputRef1} accept="image/*" />
								<b><p > {model.Logo1Name} </p></b>
								<br />
							</GridItem>
							<GridItem xs={1}>
								<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef2.current.click()} >Logo 2 </Button>
								<input type="file" key={model.Logo2Name + "logo2xxs"} style={{ display: "none" }} onChange={(e, name) => this.FileSelect(e, "Logo2")} ref={this.fileInputRef2} accept="image/*" />
								<b><p >{model.Logo2Name}</p></b>
								<br />
							</GridItem>
							<GridItem xs={1}>
								<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef3.current.click()} >Image</Button>
								<input type="file" key={model.ImageName + "image1xxs"} style={{ display: "none" }} onChange={(e, name) => this.FileSelect(e, "Image")} ref={this.fileInputRef3} accept="image/*" />
								<b><p >{model.ImageName} </p></b>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>



				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"i-Coupon Profile List"} />
					</CardHeader>
					<CardBody>
						<GridItem md={12} xs={12}>
							<GridItem>
								<GenericGrid
									Data={couponProfileList}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

				<GenericDialog open={this.state.isCouponDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="Coupon Preview" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={() => this.setState({ isCouponDialogOpen: false })}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<CampaignCouponTemplate model={model} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isCouponDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >

		);
	}
}

ICouponProfile.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(ICouponProfile, {});