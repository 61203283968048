import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicationOtherFinancial extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			alert: null
		};
		this.LOSDepositInParameterCode = { ParameterCode: "LOSDepositIn" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.LOSHousholdInParameterCode = { ParameterCode: "LOSHousholdIn" };
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");
	}


	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}


	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ otherFinancialModel: model });
	}

	DeleteModel = (model) => {

	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.otherFinancialModel || {};
			model[name] = newValue;
			return { otherFinancialModel: model };
		});
	}
	render() {
		const { Disabled, ApplicationModel } = this.props;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<GridItem md={12}>
					<GridContainer>
						<GridItem xs={12} md={6}>
							<GenericSelectInput
								LabelMd={5}
								Name="DepositSavingInterbankId"
								LabelText="Deposit/Saving with Interbank"
								Value={ApplicationModel == null ? "" : ApplicationModel.DepositSavingInterbankId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.LOSDepositInParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								LabelMd={5}
								Name="DepositSavingUsId"
								LabelText="Deposit/Saving with Us"
								Value={ApplicationModel == null ? "" : ApplicationModel.DepositSavingUsId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.LOSDepositInParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								LabelMd={5}
								Name="OtherCreditCardHeldId"
								LabelText="Other Credit Cards Held"
								Value={ApplicationModel == null ? "" : ApplicationModel.OtherCreditCardHeldId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.YesNoParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								LabelMd={5}
								Name="BorrowerOtherLoanId"
								LabelText="Are you Co-Borrower of any other loan"
								Value={ApplicationModel == null ? "" : ApplicationModel.BorrowerOtherLoanId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.YesNoParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />
							<GenericNumberInput
								LabelMd={5}
								Name="RemainingLoanAmount"
								LabelText="If you are Co-Borrower Remaining Amount of Loan"
								MaxLength={10}
								Prefix="$"
								Value={ApplicationModel.RemainingLoanAmount}
								ValueChanged={this.props.HandleChange}
								Disabled={Disabled} />
						</GridItem>
						<GridItem md={6} xs={12}>
							<GenericSelectInput
								LabelMd={5}
								Name="MonthlyApplicantNetIncomeId"
								LabelText="Total Documented Net Income (Monthly Applicant)"
								Value={ApplicationModel == null ? "" : ApplicationModel.MonthlyApplicantNetIncomeId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.LOSDepositInParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								LabelMd={5}
								Name="MonthlyHousholdNetIncomeId"
								LabelText="Total Documented Net Income (Monthly Houshold)"
								Value={ApplicationModel == null ? "" : ApplicationModel.MonthlyHousholdNetIncomeId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.LOSDepositInParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								LabelMd={5}
								Name="MonthlyTotalPaymentId"
								LabelText="Monthly Total Payment"
								Value={ApplicationModel == null ? "" : ApplicationModel.MonthlyTotalPaymentId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.LOSDepositInParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />
							<GenericSelectInput
								LabelMd={5}
								Name="HousholdIncomeId"
								LabelText="Monthly Payment as % of Monthly Houshold Income"
								Value={ApplicationModel == null ? "" : ApplicationModel.HousholdIncomeId || ""}
								ValueChanged={this.props.HandleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.LOSHousholdInParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		);
	}
}

LoanApplicationOtherFinancial.propTypes = {
	classes: PropTypes.object,
	ApplicationModel: PropTypes.object,
	HandleChange: PropTypes.func,
	ApplicationOtherFinancialModel: PropTypes.object
};
export default withArtifex(LoanApplicationOtherFinancial, {});