import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericTitle } from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant";

class ETransferInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<Card className="no-radius">
				<CardHeader >
					<GenericTitle text="e-Transfer Information" />
				</CardHeader>
				<CardBody>
					<GenericGrid
						Data={this.props.eTransferInfos}
						PageSize={5}
						HideButton={true}
						Columns={[
							{
								Header: "Program",
								accessor: "Program"
							},
							{
								Header: "Email",
								accessor: "Email"
							},
							{
								Header: "Has Direct Deposit",
								accessor: "IsHaveDDR",
								Cell: row => (
									<div>{row.value == true ? "Yes" : "No"}</div>
								),
							}
						]}
						ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
				</CardBody>
			</Card>
		);
	}
}

ETransferInformation.propTypes = {
	eTransferInfos: PropTypes.array
};

export default ETransferInformation;