import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import ParameterComponent from "views/Components/ParameterComponent";
import {
  GenericAlert,
  GenericDateInput,
  GenericGrid,
  GenericNumberInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import {
  Direction,
  GridColumnType,
  ProgramCodes,
} from "views/Constants/Constant";

class TelpaySettlementFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
			SettlementDate: DateHelper.GetDate(),
      	SettlementFileReferenceNumber: "",
            PageSize: 1_000_000,
            SourceOfRecordsId:null
      },
      headerModel: {},
      fileInfo: {
			BillPaymentNumber: null,
			NumberOfPayments: null,
			AmountOfPayments: null,
			AmountOfServiceCharges: null,
			TelPayPaymentDetails: [],
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.setAppLeftTitle("Telpay Settlement File");
    this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
  }

  handleChange = (name, newValue, data) => {
    const model = { ...this.state.model };

    model[name] = newValue;
    this.setState({ model });
  };

  GetDailyFileList = () => {
	if (!this.onDialogValidate()) return;
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/ClearingSettlement/GetTelPaySettlementFile",
      this.state.model,
      this.successDailyFileList,
      (error) => {
        this.showBasicAlert(
          "Error",
          "An error occurred during the api visit" + error,
          "error"
        );
      }
    );
  };

  onDialogValidate = () => {
	const { dialogModel } = this.state;
	const { showMessage } = this.props;

	if (!this.state.model.SettlementDate) {
		showMessage(
			"warning",
			"Settlement Date is not provided",
			"Select Settlement Date to continue."
		);
		return false;
	}	

	if (!this.state.model.SourceOfRecordsId) {
		showMessage(
			"warning",
			"Source of Records is not provided",
			"Select Source of Records to continue."
		);
		return false;
	}

	return true;
}  

  successDailyFileList = (responseData) => {

	if (!responseData.IsSucceeded) {
		this.showBasicAlert("Error", responseData.ErrorDescription, "error");
		return;
	}

   this.setState({
      fileInfo: {
			BillPaymentNumber : responseData.Item?.BillPaymentReportNumber == null || undefined ? "" : responseData.Item.BillPaymentReportNumber ,
			NumberOfPayments : responseData.Item?.NumberOfPayments == null || undefined ? "" : responseData.Item.NumberOfPayments ,
			AmountOfPayments : responseData.Item?.AmountOfPayments == null || undefined ? "" : responseData.Item.AmountOfPayments ,
			AmountOfServiceCharges : responseData.Item?.AmountOfServiceCharges == null || undefined ? "" : responseData.Item.AmountOfServiceCharges ,
			TelPayPaymentDetails: responseData.Item?.TelPayPaymentDetails == null
				? []
				: responseData.Item.TelPayPaymentDetails.legth < 1
					? []
					: responseData.Item.TelPayPaymentDetails.map((x) => {
					return {
						Id : x.Id == null || undefined ? "" : x.Id,
						UniqueClientId : x.UniqueClientId == null || undefined ? "" : x.UniqueClientId,
						ClientName : x.ClientName == null || undefined ? "" : x.ClientName,
						CustomerName : x.CustomerName == null || undefined ? "" : x.CustomerName,
                        BillerCode: x.BillerCode == null || undefined ? "" : x.BillerCode,
						BillerName : x.BillerName == null || undefined ? "" : x.BillerName,
						Amount : x.Amount == null || undefined ? "" : x.Amount,
                        TelPayTransactionReferenceNumber: x.TelPayTransactionReferenceNumber == null || undefined ? "" : x.TelPayTransactionReferenceNumber,
                        TransactionReferenceNumber: x.TransactionReferenceNumber == null || undefined ? "" : x.TransactionReferenceNumber,
                        SettlementStatus: x.SettlementStatus == null || undefined ? "" : x.SettlementStatus,
                        SourceOfRecords: x.SourceOfRecords == null || undefined ? "" : x.SourceOfRecords
					};
					}),
			}, isLoading: false,
		});
	};

  ClearModel = () => {
    this.setState({
      model: {
		  SettlementDate : DateHelper.GetDate(),
        SettlementFileDate: DateHelper.GetDate(),
        SettlementFileReferenceNumber: null,
      },
    });
  };

  showBasicAlert = (title, message, type) => {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={false}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null, isLoading: false });
  };

  render() {
    const { model, headerModel, isLoading, fileInfo } = this.state;
    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <ButtonToolbar
              ButtonList={[
                { Code: "Search", OnClick: this.GetDailyFileList, Disabled: false},
                { Code: "Clear", OnClick: this.ClearModel, Disabled: false },
              ]}
              menuId={40669}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={4}>
            <Card style={{ height: "90%" }}>
              <CardHeader style={{ marginLeft: 10 }}>
                <GenericTitle text={"File Filter"} />
              </CardHeader>
              <CardBody>
                <GridItem xs={8}>
                  <GenericDateInput
                    Utc
                    Name="SettlementDate"
                    LabelMd={6}
                    LabelText="Settlement File Date"
                    Value={
                      model.SettlementDate == null || undefined ? "" : model.SettlementDate
                    }
                    ValueChanged={this.handleChange}
                    MinDate={new Date("01-01-2022")}
                    MaxDate={ DateHelper.GetDate()}
                    IncludeTime={false}
                  />
                  <GenericNumberInput
                    NoFormatting={true}
                    Name="SettlementFileReferenceNumber"
                    LabelMd={6}
                    LabelText="Settlement File Ref No"
                    Value={model.SettlementFileReferenceNumber || ""}
                    ValueChanged={this.handleChange}
                    Disabled={false}
                  />
                </GridItem>
                <GridItem xs={12}>
                    <ParameterComponent
                        Name="SourceOfRecordsId"
                        LabelText="Source of Records"
                        Value={model.SourceOfRecordsId}
                        ParameterCode="BillPaymentSettlementSourceOfRecords"
                        ValueChanged={this.handleChange}
                    />
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={8}>
            <Card style={{ height: "90%" }}>
              <CardHeader style={{ marginLeft: 10 }}>
                <GenericTitle text={"File Information"} />
              </CardHeader>
              <CardBody>
                <GridItem xs={4}>
                  <GenericNumberInput
                    NoFormatting={true}
                    Name="BillPaymentNumber"
                    LabelMd={6}
                    LabelText="Report Number"
                    Value={ fileInfo.BillPaymentNumber || "" }
                    ValueChanged={this.handleChange}
                    Disabled={true}
                  />
                  <GenericNumberInput
                    NoFormatting={true}
                    Name="NumberOfPayments"
                    LabelMd={6}
                    LabelText="Total Number of Records"
                    Value={ fileInfo.NumberOfPayments || 0 }
                    ValueChanged={this.handleChange}
                    Disabled={true}
                  />
                  <GenericNumberInput
                    Name="AmountOfPayments"
                    LabelMd={6}
                    LabelText="Total Settled Amount"
                    Value={ fileInfo.AmountOfPayments || 0 }
                    ValueChanged={this.handleChange}
                    Disabled={true}
						  Prefix="$"
                  />
                  <GenericNumberInput
                    Name="AmountOfServiceCharges"
                    LabelMd={6}
                    LabelText="Total Service Charges"
                    Value={ fileInfo.AmountOfServiceCharges || 0 }
                    ValueChanged={this.handleChange}
                    Disabled={true}
						  Prefix="$"
                  />
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Card className="no-radius">
          <CardBody>
            <GridItem xs={12}>
              <GridItem>
                <GenericGrid
					 	PageSize={10}
					 	ShowPagination={true}
                  Data={this.state.fileInfo.TelPayPaymentDetails}
                    Columns={[
                        {
                            Header: "Client Id",
                            accessor: "UniqueClientId",
                        },
                        {
                            Header: "Client Name",
                            accessor: "ClientName",
                        },
                        {
                            Header: "Transaction Ref. No",
                            accessor: "TransactionReferenceNumber",
                        },
						{
							Header: "Telpay Ref. No",
							accessor: "TelPayTransactionReferenceNumber",
						},
                        {
                          Header: "Customer Name",
                          accessor: "CustomerName",
                        },
						{
							Header: "Biller Code",
                            accessor: "BillerCode",
						},
                        {
                          Header: "Biller Name ",
                          accessor: "BillerName",
                        },
                        {
                          Header: "Amount",
                          accessor: "Amount",
                          type: GridColumnType.Money,
                          ColumnType: GridColumnType.Money,
                        },                    
                        {
                          Header: "Settlement Status",
                          accessor: "SettlementStatus",
                        },
                        {
                            Header: "Source Of Records",
                            accessor: "SourceOfRecords",
                        },
                  ]}
                />
              </GridItem>
            </GridItem>
          </CardBody>
        </Card>
      </div>
    );
  }
}

TelpaySettlementFile.propTypes = {
 classes: PropTypes.object,
 handleOperationType: PropTypes.func,
 showQuestionMessage: func,
 setAppLeftTitle: func,
 setAppCenterTitle: func,
 Disabled: bool
};

export default withArtifex(TelpaySettlementFile, {});