import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericExpansionPanel, GenericInput } from "views/Components/Generic";

class GenericFormScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
	}

	render() {
		const { model, vModel, viewData } = this.props;
		return (
			<GenericExpansionPanel>
				<GridContainer>
					<GridItem xs={12}>
						<GridContainer>
							{
								viewData.Inputs.map((view, i) =>
									<GridItem key={i} xs={4} style={{ display: view.IsHidden ? "none" : "unset" }}>
										<GenericInput
											ViewData={view}
											IsInvalid={vModel[view.Name]}
											IsReport={viewData.IsReport}
											Value={view.DefaultValue || model[view.Name]}
											ValueChanged={this.props.handleChange}
											Model={model}
											FilterParameter={{ [view.FilterParameter]: model[view.FilterParameter] }}
											Disabled={this.props.disabled || (model.Id > 0 && view.IsUpdate === false)}
										/>
									</GridItem>
								)
							}
						</GridContainer>
					</GridItem>
				</GridContainer>
			</GenericExpansionPanel>
		);
	}
}

GenericFormScreen.propTypes = {
	checkedValues: PropTypes.array,
	model: PropTypes.object.isRequired,
	vModel: PropTypes.object,
	handleChange: PropTypes.func,
	viewData: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
};

export default GenericFormScreen;
