import { DialogContent, DialogTitle } from "@material-ui/core";
import DDTListStyle from "assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import PropTypes, { array, object } from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import DDTCustomerInfo from "./DDTCustomerInfo";

class DDTList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IsEmailValid: true,
			model: {
				FinInsId: 0,
				FinInsBranchId: 0,
				AccNumber: 0,
				DetailId: 0,
				CustomerNumber: ""
			},
			dataList: [],
			ddtModel: {
				Id: 0,
				Name: "",
				Email: "",
				Phone: {}
			},
			Phone: {
				Id: 0,
				PhoneCountryCodeId: 0,
				PhoneNumber: ""
			},
			openDialog: false,
			alert: null
		};
		this.OpenEditModal = this.OpenEditModal.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.successEditCallBack = this.successEditCallBack.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.EditDDT = this.EditDDT.bind(this);
	}

	handleChange(name, newValue, data) {
		const ddtModel = { ...this.state.ddtModel };
		var { IsEmailValid } = this.state;
		const Phone = { ...this.state.Phone };
		if (name == "PhoneCountryCodeId" || name == "PhoneNumber")
			Phone[name] = newValue;
		else
			ddtModel[name] = newValue;

		if (name == "Email") {
			IsEmailValid = newValue == "" ? true : data.IsValid;
		}

		this.setState({ ddtModel, Phone, IsEmailValid });
	}

	EditDDT() {
		const ddtModel = { ...this.state.ddtModel };
		ddtModel.Phone = this.state.Phone;
		this.setState({ ddtModel });

		if (!this.Validate())
			return;

		Proxy.POST(
			"/bankapi/v1.0/Ddt/UpdateDdtDetail",
			ddtModel,
			this.successEditCallBack,
			this.errorCallback
		);
	}

	successEditCallBack(responseData) {
		this.setState({ openDialog: false });

		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.setState({ alert: AlertHelper.CreateAlert("Success", "DDT Number Information successfully edited.", "success", this.hideAlert) });
		this.props.propGetAllData();
	}

	errorCallback(error) {
		this.handleClose();
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	Validate = () => {
		const { ddtModel, vModel } = this.state;

		var messages = [];

		vModel.Name = ddtModel.Name == null || ddtModel.Name == "";
		if (vModel.Name) messages.push("Name must be entered");

		vModel.LastName = ddtModel.LastName == null || ddtModel.LastName == "";
		if (vModel.LastName) messages.push("Last Name must be entered");

		vModel.Email = !this.state.IsEmailValid;
		if (vModel.Email) messages.push("E-Mail is invalid");

		// vModel.DateOfBirth = typeof ddtModel.DateOfBirth != "object" || ddtModel.DateOfBirth == "" || ddtModel.DateOfBirth == null;
		// if (vModel.EndDate) messages.push("Birth Date must be selected");

		var interval = DateHelper.GetDate().diff(ddtModel.DateOfBirth, "years");
		if (ddtModel.DateOfBirth && interval < 18) {
			messages.push("Date of Birth must be bigger than 18");
		}

		vModel.AddressLine1 = ddtModel.AddressLine1 == null || ddtModel.AddressLine1 == "";
		if (vModel.AddressLine1) messages.push("Address Line 1 must be entered");

		vModel.ZipCode = ddtModel.ZipCode == null || ddtModel.ZipCode == "";
		if (vModel.ZipCode) messages.push("Postal Code must be entered");

		vModel.CountryId = ddtModel.CountryId == null || ddtModel.CountryId == 0;
		if (vModel.CountryId) messages.push("Country must be selected");

		vModel.ProvinceId = ddtModel.ProvinceId == null || ddtModel.ProvinceId == 0;
		if (vModel.ProvinceId) messages.push("Province must be selected");

		vModel.CityId = ddtModel.CityId == null || ddtModel.CityId == 0;
		if (vModel.CityId) messages.push("City must be selected");

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	showErrorMessage(message) {
		this.setState({
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}

	OpenEditModal(item) {
		var ddtModel = { ...this.state.ddtModel };
		var Phone = { ...this.state.Phone };
		var model = { ...this.state.model };
		model.FinInsId = this.props.propFinInsId == null ? 0 : this.props.propFinInsId;
		model.FinInsBranchId = item.FinInsBranchId == null ? 0 : item.FinInsBranchId;
		model.AccNumber = item.AccNumber == null ? 0 : item.AccNumber;
		model.DetailId = item.Id == null ? 0 : item.Id;
		model.CustomerNumber = item.ClientCustomer == null ? "" : item.ClientCustomer.CustomerNumber;
		model.CustomerName = item.ClientCustomer == null ? "" : item.ClientCustomer.CustomerName;
		ddtModel.Id = item.Id;
		ddtModel.SIN = item.SIN == null ? "" : item.SIN;
		ddtModel.Name = item.Name == null ? "" : item.Name;
		ddtModel.MiddleName = item.MiddleName == null ? "" : item.MiddleName;
		ddtModel.LastName = item.LastName == null ? "" : item.LastName;
		ddtModel.Email = item.Email == null ? "" : item.Email;
		ddtModel.DateOfBirth = item.DateOfBirth == null ? "" : item.DateOfBirth;
		ddtModel.AddressLine1 = item.AddressLine1 == null ? "" : item.AddressLine1;
		ddtModel.AddressLine2 = item.AddressLine2 == null ? "" : item.AddressLine2;
		ddtModel.ZipCode = item.ZipCode == null ? "" : item.ZipCode;
		ddtModel.CountryId = item.CountryId == null ? "" : item.CountryId;
		ddtModel.ProvinceId = item.ProvinceId == null ? "" : item.ProvinceId;
		ddtModel.CityId = item.CityId == null ? "" : item.CityId;
		Phone.Id = item.Phone == null ? 0 : item.Phone.Id == null ? 0 : item.Phone.Id;
		Phone.PhoneCountryCodeId = item.Phone == null ? "" : item.Phone.PhoneCountryCodeId == null ? "" : item.Phone.PhoneCountryCodeId;
		Phone.PhoneNumber = item.Phone == null ? "" : item.Phone.PhoneNumber == null ? "" : item.Phone.PhoneNumber;
		this.setState({ ddtModel, openDialog: true, Phone, model });
	}

	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}

	hideAlert = () => {
		this.setState({ alert: null });
	}

	render() {
		const { classes, propDDTTotalInfo, propIsBackoffice } = this.props;

		return (
			<div>
				{this.state.alert}
				<GenericDialog open={this.state.openDialog} onBackdropClick={this.handleClose}>
					<DialogTitle  >
						<GenericLabel Text="DDT Customer Information" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<DDTCustomerInfo
							DDTModel={this.state.ddtModel}
							PhoneModel={this.state.Phone}
							handleChange={this.handleChange}
							propModel={this.state.model} />
						<GridContainer>
							<GridItem xs={6} />
							<GridItem xs={6}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={this.EditDDT}>EDIT</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.handleClose} >Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericGrid
					Data={this.props.propDDTList || []}
					Columns={[
						{
							Header: "Actions",
							accessor: "Actions",
							Cell: row => (
								<div>
									<GridButton
										tooltip="Edit"
										Icon={EditIcon}
										OnClick={() => this.OpenEditModal(row.original)} />
								</div>
							),
							sortable: false,
							filterable: false,
							width: 60,
							show: !propIsBackoffice
						},
						{
							Header: "Unique Client Id",
							accessor: "UniqueClientId",
							show: propIsBackoffice
						},
						{
							Header: "Client Name",
							accessor: "ClientName",
							show: propIsBackoffice
						},
						{
							Header: "Wallet Owner",
							accessor: "AccountOwner",
							show: false
						},
						{
							Header: "Customer Number",
							accessor: "CustomerNumber"
						},
						{
							Header: "Customer Name",
							accessor: "CustomerName"
						},
						{
							Header: "Transit Number",
							accessor: "TransitNumber"
						},
						{
							Header: "DDT Number",
							accessor: "DDTNumber"
						},
						{
							Header: "Issued Date",
							accessor: "IssuedDate",
							type: GridColumnType.Date
						},
						{
							Header: "ExpireDate",
							accessor: "ExpireDate",
							type: GridColumnType.Date
						},
						{
							Header: "Status",
							accessor: "Status"
						},
						{
							Header: "Balance",
							accessor: "Balance",
							type: GridColumnType.Money,
							ColumnType: GridColumnType.Money
						},
						{
							Header: "SIN",
							accessor: "SIN",
							width: 100
						},
						{
							Header: "First Name",
							accessor: "Name"
						},
						{
							Header: "Middle Name",
							accessor: "MiddleName"
						},
						{
							Header: "Last Name",
							accessor: "LastName"
						},
						{
							Header: "Birth Date",
							accessor: "DateOfBirth",
							type: GridColumnType.DateUtc
						},
						{
							Header: "Phone Number",
							accessor: "PhoneNumber",
						},
						{
							Header: "E-Mail",
							accessor: "Email",
							width: 160
						},
						{
							Header: "Address Line 1",
							accessor: "AddressLine1"
						},
						{
							Header: "Address Line 2",
							accessor: "AddressLine2"
						},
						{
							Header: "City",
							accessor: "City"
						},
						{
							Header: "Province",
							accessor: "Province"
						},
						{
							Header: "Postal Code",
							accessor: "ZipCode"
						},
						{
							Header: "Country",
							accessor: "Country"
						},
						{
							Header: "Auto Direct Deposit E-Mail",
							//accessor: "DirectDepositEmail"
						},
						{
							Header: "Direct Deposit Registration Status",
							accessor: "DirectDepositRegistrationStatus",
							Cell: row => (
								Formatter.FormatYesNo(row.value)
							)
						},
						{
							Header: "Direct Deposit Reference Number",
							accessor: "DirectDepositReferenceNumber"
						}
					]}
					ProgramCode={ProgramCodes.Bank_Programs_DDT} />
				<GridContainer >
					<GridItem xs={6}></GridItem>
					<GridItem xs={6}>
						<GridContainer className={classes.containerHeight} justify="flex-end">
							<GridItem xs={2} className={classes.gridBorder}>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Active</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Inactive</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Total</p>
							</GridItem>
							<GridItem xs={12} sm={3} md={3} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Client Total Balance</p>
							</GridItem>
						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
								TOTAL
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propDDTTotalInfo == null ? 0 : propDDTTotalInfo.Active == null ? 0 : typeof propDDTTotalInfo.Active == "number" ? Formatter.FormatNumber(propDDTTotalInfo.Active) : propDDTTotalInfo.Active}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propDDTTotalInfo == null ? 0 : propDDTTotalInfo.Inactive == null ? 0 : typeof propDDTTotalInfo.Inactive == "number" ? Formatter.FormatNumber(propDDTTotalInfo.Inactive) : propDDTTotalInfo.Inactive}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propDDTTotalInfo == null ? 0 : propDDTTotalInfo.Total == null ? 0 : typeof propDDTTotalInfo.Total == "number" ? Formatter.FormatNumber(propDDTTotalInfo.Total) : propDDTTotalInfo.Total}
							</GridItem>
							<GridItem xs={3} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{propDDTTotalInfo == null ? 0 : propDDTTotalInfo.Balance == null ? 0 : typeof propDDTTotalInfo.Balance == "number" ? Formatter.FormatMoney(propDDTTotalInfo.Balance) : propDDTTotalInfo.Balance}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

DDTList.propTypes = {
	classes: PropTypes.object,
	propDDTList: array,
	propDDTTotalInfo: object,
	propGetAllData: PropTypes.func,
	propFinInsId: PropTypes.number,
	propGridColumns: PropTypes.array,
	propIsBackoffice: PropTypes.bool
};

export default withArtifex(DDTList, DDTListStyle);