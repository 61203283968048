import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class eftIncomingFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: "",
				ClientId: 0,

			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: "",
				FileId: 0,
				ClientId: 0
			},
			dailyFileList: [],
			fileInfoModel: {},
			headerModel: {
				FileDate: null
			},
			transactionList: [],
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("EFT Incoming File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Incoming;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Incoming;
		this.setState({ getTransactionByFileIdModel, model });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.showErrorMessage("FileInformationId can not be null");
		else {
			model.ClientId = tempModel.ClientId;
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetTransactionList(tempModel.Id);
		}
	}

	GetDailyFileList = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetEFTOutgoingDailyFileList",
			this.state.model,
			this.successDailyFileList,
			this.errorCallback
		);

	}
	successDailyFileList = (responseData) => {
		this.hideIsLoading();
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.length < 1) {
			this.setState({
				transactionList: [], headerModel: {}, dailyFileList: []
			});
		}
		else {
			this.setState({
				transactionList: [], headerModel: {},
				dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item, isLoading: false
			});
			// this.setState({
			// 	transactionList: [], headerModel: {},
			// 	dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item.map(x => {
			// 		return {
			// 			Id: x.FileInformationId == null ? "" : x.FileInformationId,
			// 			FileName: x.FileName == null ? "" : x.FileName,
			// 			FileDate: x.FileDate == null ? "" : x.FileDate,
			// 			UniqueClientId: x.UniqueClientId == null ? "" : x.UniqueClientId,
			// 			ClientId: x.ClientId == null ? 0 : x.ClientId,
			// 			FileCrationNumber: x.FileCreationNumber == null ? "" : x.FileCreationNumber,
			// 			FileSource: x.FileSource == null ? "" : x.FileSource,
			// 			RecordCount: x.RecordCount == null ? "" : x.RecordCount,
			// 			RejectedCount: x.RejectedCount == null ? "" : x.RejectedCount,
			// 			SendStatus: x.SendStatus == null ? "" : x.SendStatus
			// 		};
			// 	}), isLoading: false
			// });
		}
	}

	GetTransactionList = (Id) => {
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.FileId = (Id == "" || Id == null) ? 0 : Id;
		getTransactionByFileIdModel.ClientId = this.state.model.ClientId == null ? null : this.state.model.ClientId;
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/GetEFTClearingFileDetailsByFileId",
			getTransactionByFileIdModel,
			this.successTransactionListCallback,
			this.errorCallback
		);
	}

	successTransactionListCallback = (responseData) => {
		var headerModel = { ...this.state.headerModel };
		var transactionList = { ...this.state.transactionList };
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null) {
			if (responseData.Item.ClearingFileHeaderDetail != null) {
				headerModel = responseData.Item.ClearingFileHeaderDetail == null ? {} : responseData.Item.ClearingFileHeaderDetail;
				headerModel.FileDate = this.state.fileInfoModel.FileDate;
			}
			else
				headerModel = { FileDate: null };

			if (responseData.Item.EFTClearingFileDetail != null && responseData.Item.EFTClearingFileDetail.length > 0) {
				transactionList = responseData.Item.EFTClearingFileDetail;
			}
			else {
				transactionList = [];
			}
		}
		this.setState({ transactionList, headerModel, isLoading: false });
	}

	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert("Error", message, "warning", this.hideAlert)
		});
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	hideIsLoading = () => { this.setState({ isLoading: false }); };

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: Direction.Incoming
			},
			dailyFileList: []
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={5}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"Main Parameters"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear />
									<GenericDateInput
										Name="FromDate"
										LabelText="From Date"
										Value={model.FromDate == null ? "" : model.FromDate}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate || DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="ToDate"
										LabelText="To Date"
										Value={model.ToDate == null ? "" : model.ToDate}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<ParameterComponent
										Name="EFTTypeId"
										LabelText="Settlement Type"
										ParameterCode="EFTType"
										Value={model == null ? "" : model.EFTTypeId || ""}
										ValueChanged={this.handleChange} />
								</GridItem>
								<br />
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={7}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericTextInput
											Name="FileCreationNumber"
											LabelText="File Creation No"
											Value={headerModel == null ? "" : headerModel.FileCreationNumber ? headerModel.FileCreationNumber : ""}
											Disabled={true}
											LabelMd={6} />
										<GenericTextInput
											Name="FileDate"
											LabelText="File Date"
											Value={headerModel?.FileDate ? Formatter.FormatDateUtc(headerModel?.FileDate) : ""}
											Disabled={true}
											LabelMd={6} />
										<GenericNumberInput
											Name="TotalCreditNumber"
											LabelText="Total Number of Credit"
											Value={headerModel == null ? "" : headerModel.TotalNumbeCredit ? headerModel.TotalNumbeCredit : ""}
											Disabled={true}
											LabelMd={6}
											DecimalScale={0} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelMd={6}
											LabelText="Total Number of Debit"
											Value={headerModel == null ? "" : headerModel.TotalNumberDebit ? headerModel.TotalNumberDebit : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericTextInput
											Name="SettlementType"
											LabelText="Settlement Type"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.SettlementType ? headerModel.SettlementType : ""}
											Disabled={true} />
									</GridItem>
									<GridItem xs={6}>
										<GenericTextInput
											Name="OriginatorId"
											LabelText="Originator ID"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.OriginatorId ? headerModel.OriginatorId : ""}
											Disabled={true} />
										<GenericDateInput
											Name="FileDate"
											LabelText="File Time"
											Value={headerModel == null ? "" : headerModel.FileDate ? DateHelper.ToDateInputValueAsUtc(headerModel.FileDate) : ""}
											DateFormat="HH:mm:ss"
											IncludeTime={false}
											LabelMd={6}
											Disabled={true} />
										<GenericNumberInput
											Name="TotalAmountCredit"
											LabelText="Total Amount of Credit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountCredit ? headerModel.TotalAmountCredit : ""}
											Prefix="$"
											Disabled={true} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelMd={6}
											LabelText="Total Amount of Debit"
											Value={headerModel == null ? "" : headerModel.TotalAmountDebit ? headerModel.TotalAmountDebit : ""}
											Prefix="$"
											Disabled={true} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Daily File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.dailyFileList}
									Columns={[
										{
											Header: "File Name",
											accessor: "FileName"
										},
										{
											Header: "File Date",
											accessor: "FileDate",
											type: GridColumnType.DateUtc
										},
										{
											Header: "File Source",
											accessor: "FileSource"
										},
										{
											Header: "Total Count",
											accessor: "TotalCount"
										},
										{
											Header: "Settled Count",
											accessor: "SettledCount"
										},
										{
											Header: "Returned Count",
											accessor: "ReturnedCount"
										},
										{
											Header: "Error Count",
											accessor: "ErrorCount"
										},
										{
											Header: "File Status",
											accessor: "SendStatus"
										},
										{
											Header: "File Type",
											accessor: "FileType"
										}
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Bank_Programs_EFT} />
								<br /> <br />
								<h5><b>Transaction List</b></h5> <br />
								<GenericGrid
									Data={this.state.transactionList}
									Columns={[
										{
											Header: "Clearing Date",
											accessor: "ClearingDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Customer Name",
											accessor: "CustomerName"
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType"
										},
										{
											Header: "Destination Institution",
											accessor: "DestinationInstitution"
										},
										{
											Header: "Destination Wallet Number",
											accessor: "DestinationAccountNumber"
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Cross Reference Number",
											accessor: "CrossReferenceNumber"
										},
										{
											Header: "Return Institution",
											accessor: "ReturnInstitution"
										},
										{
											Header: "Return Wallet Number",
											accessor: "ReturnAccountNumber"
										},
										{
											Header: "Logical Record Count",
											accessor: "LogicalRecordCount"
										},
										{
											Header: "Group Number",
											accessor: "GroupNumber"
										},
										{
											Header: "Sequence In Segment",
											accessor: "SequenceInSegment"
										},
										{
											Header: "Invalid Data Element",
											accessor: "InvalidDataElement"
										},
										{
											Header: "Stored Transaction Type",
											accessor: "StoredTransactionType"
										},
										{
											Header: "Direction",
											accessor: "Direction"
										},
										{
											Header: "Process Status",
											accessor: "ProcessStatus"
										},
										{
											Header: "Match Status",
											accessor: "MatchStatus"
										},
										{
											Header: "Validation Status",
											accessor: "ValidationStatus"
										},
										{
											Header: "Originator Short Name",
											accessor: "OriginatorShortName"
										},
										{
											Header: "Originator Long Name",
											accessor: "OriginatorLongName"
										},
										{
											Header: "Validation Description",
											accessor: "ValidationDescription"
										},
										{
											Header: "File Name",
											accessor: "FileName"
										},
										{
											Header: "Originator Sundry Information",
											accessor: "OriginatorSundryInformation"
										},
										{
											Header: "Originator DirectClearer Settlement Code",
											accessor: "OriginatorDirectClearerSettlementCode"
										},
										{
											Header: "Is Recommited",
											accessor: "IsRecommited"
										},
										{
											Header: "Item Trace No",
											accessor: "ItemTraceNo"
										},
										{
											Header: "Recommit Date",
											accessor: "RecommitDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Reject File Sent Date",
											accessor: "RejectFileSentDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Client User",
											accessor: "InsertUser"
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_EFT} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eftIncomingFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.array,
	After: PropTypes.func
};

export default withArtifex(eftIncomingFile, {});