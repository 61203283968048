import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
	...sweetAlertStyle,
	noRatingBoxStyle: {
		width: "10px",
		padding: "10px",
		border: "1px solid black",
	},
	greenRatingBoxStyle: {
		width: "10px",
		background: "limegreen",
		padding: "10px",
		border: "1px solid black",
	},
	yellowRatingBoxStyle: {
		width: "10px",
		background: "yellow",
		padding: "10px",
		border: "1px solid black",
	},
	redRatingBoxStyle: {
		width: "10px",
		background: "red",
		padding: "10px",
		border: "1px solid black",
	},
	blackRatingBoxStyle: {
		width: "10px",
		background: "black",
		padding: "10px",
		border: "1px solid black",
	},
	greenRatingStyle: {
		background: "limegreen",
		padding: "10px",
		border: "1px solid black"
	},
	yellowRatingStyle: {
		background: "yellow",
		padding: "10px",
		border: "1px solid black"
	},
	redRatingStyle: {
		background: "red",
		padding: "10px",
		border: "1px solid black"
	},
	blackRatingStyle: {
		background: "black",
		padding: "10px",
		border: "1px solid black"
	},
	grayRatingStyle: {
		background: "#e6e6e6",
		padding: "10px",
		border: "1px solid black"
	},
	noRatingStyle: {
		padding: "10px",
		border: "1px solid black"
	},
	card: {
		padding: "16px"
	},
	card2: {
		marginBottom : "24px"
	},
	goToButton: {
		height: "48px"
	},
	cardListLabel: {
		margin: "3px 4px",
		height: "28px"
	}
};

export default style;