import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ReactTable from "react-table";
import { Search, Public, OpenWith, Reply } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import { Proxy } from "core";
import moment from "moment";

import { GenericTitle, GenericNumberInput, GenericAlert, GenericCheckInput,  GenericTextInput, GenericDateInput, GenericSelectInput } from "views/Components/Generic";

class CorporateAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("My Profile / Corporate Wallet");
		this.props.setAppCenterTitle("WALLET");
	}

	render() {
		const { model } = this.props;
		return (
			<Card>
				<CardBody>
					<GridContainer spacing={16}>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<GenericTitle text={" Corporate Wallet"}/>
							<GridContainer justify="flex-start">
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GenericTextInput
										Name="Explanation"
										LabelText="Legal Name of Business"
										Value={model.LegalName || ""}
										Disabled={true} />
									<GenericTextInput
										Name="Explanation"
										LabelText="Business ID Nr. (BIN)"
										Value={model.BusinessIdNumber || ""}
										Disabled={true} />
									<GenericTextInput
										Name="Explanation"
										LabelText="Quebec Enterp. Nr (NEQ)"
										Value={model.QuebecEntNumber || ""}
										Disabled={true} />
									<GenericSelectInput
										Name="IncorporationCountryId"
										LabelText="Country of Legal Formation"
										Value={model.IncorporationCountryId || ""}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember={"Name"}
										Method="POST" Url="/coreapi/v1.0/Country/GetAll"
										Parameter={{}}
										Disabled={true}
										Sorted={{ Member: "Name" }}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<GenericTitle text={" Communication"}/>
							<GridContainer justify="flex-start">
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GenericTextInput
										LabelText="Address Line 1"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Address1}
										Disabled={true} />
									<GenericTextInput
										LabelText="Address Line 2"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Address2}
										Disabled={true} />
									<GenericTextInput
										LabelText="Province"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Provience}
										Disabled={true} />
									<GenericTextInput
										LabelText="City"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.City == null ? "" : model.CustomerAddress.City.ParameterDesc || ""}
										Disabled={true} />
									<GenericTextInput
										LabelText="Postal Code"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.ZipCode}
										Disabled={true} />
									<GenericTextInput
										LabelText="E Mail"
										Value={model.Email || ""}
										Disabled={true} />
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GridContainer>
										<GridItem xs={3} sm={3} md={3}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Business Phone"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.BusinessPhone == null ? "" : model.BusinessPhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={9} sm={9} md={9}>
											<GenericTextInput
												IsPhone
												Name="PhoneNumber"
												Value={model.BusinessPhone == null ? "" : model.BusinessPhone.PhoneNumber || ""}
												Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={8} sm={8} md={8}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Fax Phone"
												TitleLabel="Country Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.FaxPhone == null ? "" : model.FaxPhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={4} sm={4} md={4}>
											<GenericTextInput
												IsPhone
												Name="PhoneNumber"
												Value={model.FaxPhone == null ? "" : model.FaxPhone.PhoneNumber || ""}
												Disabled={true} />
										</GridItem>
									</GridContainer>
									<GenericSelectInput
										Name="LanguageId"
										LabelText="Preferred Language"
										Value={model.PreferedLanguageId || ""}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "Language", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item" Disabled={true} />
									<GenericSelectInput
										Name="SendSms"
										LabelText="Text/SMS Notification"
										Value={model.SendSms == undefined ? "" : String(model.SendSms)}
										KeyValueMember="ParameterValue"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item"
										Disabled={true} />
									<GenericSelectInput
										Name="SendEmail"
										LabelText="E-Mail Notification"
										Value={model.SendEmail == undefined ? "" : String(model.SendEmail)}
										KeyValueMember="ParameterValue"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item"
										Disabled={true} />
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

CorporateAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	model: PropTypes.object
};

export default CorporateAccount;