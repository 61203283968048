import { Dialog, DialogContent } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withRedux } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import Recaptcha from "react-recaptcha";
import { UserConstants } from "store";
import { GenericAlert, GenericCircularProgress, GenericEmailInput, GenericTextInput, GenericNumberInput } from "views/Components/Generic";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracConfirmLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			initialize: false,
			alert: null,
			isLoading: false,
			captchaLoaded: false,
			model: {
				Email: "",
				Password: "",
				CustomerNumber: "",
				IsCaptchaVerified: false
			}
		};
	}

	componentDidMount() {
		// var user = ClientHelper.GetUser();
		// if (user != null) {
		// 	this.props.history.replace("/");
		// }

		this.setState({ initialize: true });
	}

	RecaptchaVerify = (response) => {
		if (response) {
			this.handleChange("IsCaptchaVerified", true);
		}
	}
	RecaptchaLoadCallBack = () => {
		this.setState({ captchaLoaded: true });

	}
	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	PerformLogin = () => {
		var ErrorList = [];
		const { Email, Password, IsCaptchaVerified, CustomerNumber } = this.state.model;

		if (!Email || !Email.trim()) {
			ErrorList.push("Email cannot be null.");
		}

		if (!CustomerNumber || !CustomerNumber.trim()) {
			ErrorList.push("Customer Number cannot be null.");
		}
		if (!Password || !Password.trim()) {
			ErrorList.push("Password cannot be null.");
		}

		if (!IsCaptchaVerified) {
			ErrorList.push("Captcha cannot be verified.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/Login",
			{
				Email,
				Password,
				CustomerNumber
			},
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				const user = {
					Email: this.state.model.Email,
					CustomerNumber: this.state.model.CustomerNumber,
					SessionId: responseData.DCBankAccessToken,
					ForceTwoFactorAuth: responseData.ForceTwoFactorAuth,
					SessionStatus: responseData.SessionStatus,
					MustChangePwd: responseData.MustChangePwd,
					CanBeClient: responseData.CanBeClient
				};

				//Proxy SessionId kullaniyor GetUserByEmail operation icin
				localStorage.setItem("user", JSON.stringify({ SessionId: user.SessionId }));

				const { dispatch } = this.props;
				dispatch({ type: UserConstants.LOGIN_SUCCESS, user });

				this.PerformGetUser(user.SessionId, user.CanBeClient, user.ForceTwoFactorAuth);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	PerformGetUser = (SessionId, CanBeClient, ForceTwoFactorAuth) => {

		this.setState({ isLoading: true });

		Proxy.POST(
			"/coreapi/v1.0/User/GetUserByEmailWithClientInfo",
			{ Email: this.state.model.Email },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var userInfo = responseData.User;
				userInfo.SessionId = SessionId;
				userInfo.CanBeClient = CanBeClient;

				localStorage.setItem("user", JSON.stringify(userInfo));

				if (userInfo.User == undefined || ForceTwoFactorAuth) {
					var data = {};
					data.Url = window.location.href;
					data.pID = this.GetParameterByName("pID", data.Url);
					data.rID = this.GetParameterByName("rID", data.Url);
					data.src = this.GetParameterByName("src", data.Url);
					data.fiID = this.GetParameterByName("fiID", data.Url);
					data.cuID = this.GetParameterByName("cuID", data.Url);
					data.lang = this.GetParameterByName("lang", data.Url);

					if (data.pID != null && data.pID != "") { // money send
						data.IsValidUrl = true;
						data.IsMoneySend = true;
						data.IsMoneyRequest = false;
					}
					if (data.rID != null && data.rID != "") { // money request
						data.IsValidUrl = true;
						data.IsMoneySend = false;
						data.IsMoneyRequest = false;
					}

					if (data.pID != null && data.rID != null) {
						data.IsValidUrl = false;
					}
					console.log(data);

					RouteHelper.Push(this.props.history, "/InteracConfirm/Otp", "InteracData", data);

				} else {
					this.props.history.replace("/InteracConfirm");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	GetParameterByName = (name, url) => {
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	handleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.PerformLogin();
		}
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert, captchaLoaded, initialize } = this.state;
		const logo = require("assets/img/" + window.LoginLogo);

		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading || (!captchaLoaded && initialize)} />
				{alert}
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={7}>
						<form>
							<Card login>
								<CardHeader
									className={`${classes.cardHeader} ${classes.textCenter}`}
									color="white"
									style={{ height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" }}>
									<CardMedia
										className={classes.media}
										image={logo}
										style={{
											height: "75%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 10,
											backgroundSize: "100% 100%", boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.45)"
										}}
										title=""
									/>
								</CardHeader>
								<CardBody
									style={{
										marginTop: 62,
										width: "83%",
										marginLeft: "auto",
										marginRight: "auto"
									}}								>
									<GridContainer
										container
										direction="column"
										justify="flex-start"
										alignItems="stretch">
										<GridItem>


											<GenericEmailInput
												FloatRight={true}
												LabelMd={5}
												Name="Email" LabelText="E-Mail"
												Value={model.Email} ValueChanged={this.handleChange}
												EndIconName="email" AutoFocus={true} KeyPressed={this.handleKeyPress} />

											<GenericNumberInput
												FloatRight={true}
												LabelMd={5}
												NoFormatting={true}
												Name="CustomerNumber" LabelText="Customer Number"
												Value={model.CustomerNumber} ValueChanged={this.handleChange}
												KeyPressed={this.handleKeyPress} />

											<GenericTextInput
												FloatRight={true}
												LabelMd={5}
												Name="Password" LabelText="Password" IsPassword={true}
												Value={model.Password} ValueChanged={this.handleChange}
												EndIconName="lock_outline" KeyPressed={this.handleKeyPress} />

											<GridContainer
												container
												direction="row"
												justify="flex-start"
												alignItems="flex-start"											>
												<GridItem md={5}></GridItem>
												<GridItem md={7}>
													<Recaptcha
														sitekey="6LcV_PgdAAAAAB1EROQGFawj8P9NLhetW9TRQ2VW"
														render="explicit" 
														verifyCallback={this.RecaptchaVerify}
														onloadCallback={this.RecaptchaLoadCallBack} />
												</GridItem>
											</GridContainer>
											<GridContainer
												container
												direction="row"
												justify="flex-start"
												alignItems="flex-start"											>
												<GridItem md={5}></GridItem>
												<GridItem md={7}>
													<Button color="primary" size="lg" block onClick={() => this.PerformLogin()} disabled={!model.IsCaptchaVerified}>
														LOGIN
													</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracConfirmLogin.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRedux(InteracConfirmLogin, loginPageStyle);