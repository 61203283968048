import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class CDICSuspiciousApprove extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			model: {

			}
		};

		this.SubmitClick = this.SubmitClick.bind(this);
		this.FillData = this.FillData.bind(this);
		this.GetModel = this.GetModel.bind(this);
		this.ValueChanged = this.ValueChanged.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	ValueChanged(name, newValue) {
		this.setState({ [name]: newValue });
	}

	SubmitClick(workflowId, after, accessToken) {
		const { Id, CancelReasonId, CancelMemo } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/Cdic/SuspiciousApproval",
			{ TransactionId: Id, ReasonId: CancelReasonId, Memo: CancelMemo },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred", responseData.ErrorDescription);
					return;
				}

				if (after) after();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred", errorMessage);
			},
			workflowId,
			accessToken
		);
	}

	GetModel() {
		const { model } = this.state;
		return model;
	}

	FillData(data) {
		this.setState({ isLoading: true, CancelReasonId: data.CancelReasonId, CancelMemo: data.CancelMemo });
		Proxy.POST("/bankapi/v1.0/Cdic/GetByTransactionId",
			data.TransactionId,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var cdicData = responseData.Item;

				this.setState(function (state) {
					var model = state.model || {};

					model.PayeeName = cdicData.PayeeName;
					model.Memo = cdicData.Memo;
					model.TransactionType = cdicData.TransactionType;
					model.Amount = cdicData.Amount;
					model.TransactionDate = cdicData.TransactionDate;
					model.SenderAccount = cdicData.SenderAccount;
					model.ReceiverAccount = cdicData.ReceiverAccount;

					return { model, Id: data.TransactionId };
				});
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", errorMessage);
			}
		);
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={this.hideAlert} /> });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { model, isLoading, Id, CancelReasonId, CancelMemo, alert } = this.state;
		const { Disabled } = this.props;

		return (
			<>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: true, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: Id }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<ParameterComponent Name="CancelReasonId" LabelText="Cancel Reason" Value={CancelReasonId} ValueChanged={this.ValueChanged} ParameterCode={"TransactionCancelReason"} Disabled={Disabled} />
								<GenericTextInput Name="CancelMemo" LabelText="Cancel Memo" Value={CancelMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<GenericTextInput LabelText="Customer Name" Value={model.PayeeName} Disabled={true} />
								<GenericTextInput LabelText="Memo" Value={model.Memo} Disabled={true} />
								<GenericTextInput LabelText="Transaction Type" Value={model.TransactionType} Disabled={true} />
								<GenericTextInput LabelText="Amount" Value={model.Amount} Disabled={true} />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput LabelText="Transaction Date" Value={Formatter.FormatDateTime(model.TransactionDate)} Disabled={true} />
								<GenericTextInput LabelText="Sender Wallet" Value={model.SenderAccount} Disabled={true} />
								<GenericTextInput LabelText="Receiver Wallet" Value={model.ReceiverAccount} Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</>
		);
	}
}

CDICSuspiciousApprove.propTypes = {
	Disabled: PropTypes.bool
};

export default withArtifex(CDICSuspiciousApprove, {});