import { InputAdornment, withStyles } from "@material-ui/core";
import genericTextInputStyle from "assets/jss/generic/genericTextInputStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import GenericIcon from "views/Components/Generic/GenericIcon.jsx";

const endAdornmentStyle = {
	marginLeft: "-24px"
};

const fullWidthStyle = {
	fullWidth: true
};

class GenericTextInput extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			maskState: null,
			specialRegex: /[@#$]/,
			isInvalid: false,
		};

		this.handleIsText = this.handleIsText.bind(this);
		this.handleMask = this.handleMask.bind(this);
		this.escapeRegExp = this.escapeRegExp.bind(this);
		this.getRawInput = this.getRawInput.bind(this);
		this.verifyWord = this.verifyWord.bind(this);
		this.OnChange = this.OnChange.bind(this);
	}

	handleIsText(e) {
		let isTextRegex = /([^a-zA-Z ])+/;
		if (this.props.IsText && isTextRegex.test(e.target.value)) {
			e.target.value = e.target.defaultValue;
		}
	}

	handleMask(e) {
		const tempFormat = this.props.Format;

		let rawInput = this.getRawInput(e.target.value);
		let inputText = [...rawInput];
		let start = e.target.selectionStart;
		let newText = "";
		let counter = 0;

		for (let i = 0; i < tempFormat.length; i++) {
			if (inputText.length == 0) {
				if (this.props.Placeholder)
					newText += this.props.Placeholder;
				continue;
			}
			counter++;
			if (!this.state.specialRegex.test(tempFormat[i])) {
				newText += tempFormat[i];
				if (start - 1 == i) {
					start++;
				}
				continue;
			}

			let letter = inputText.shift();
			if (this.verifyWord(letter, tempFormat[i])) {
				newText += letter;
			}
			else {
				if (newText.length > 0)
					newText = e.target.defaultValue;
				break;
			}
		}

		e.target.value = newText;
		e.target.selectionStart = counter;
		e.target.selectionEnd = counter;
	}

	escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
	}

	getRawInput(value) {
		if (this.props.Placeholder) {
			value = value.replace(new RegExp("(" + this.escapeRegExp(this.props.Placeholder) + ")*", "g"), "");
		}
		let maskItems = this.props.Format.match(/[^#$@]*/g);
		let regexs = maskItems.filter((a) => { return a.length > 0; }).map((a) => { return this.escapeRegExp(a); });
		let pureValue = value.replace(new RegExp("(" + regexs.join("|") + ")*", "g"), "");
		return pureValue;
	}

	verifyWord(value, format) {
		var numberRex = new RegExp("[0-9]");
		var alphanumericRex = new RegExp("[0-9a-zA-Z]");
		const letter = value[value.length - 1];

		if (value.length <= format.length) {
			if (format[value.length - 1] == "$") { //string
				if (!numberRex.test(letter))
					return true;
			}
			if (format[value.length - 1] == "#") { //number
				if (numberRex.test(letter))
					return true;
			}
			if (format[value.length - 1] == "@") {//alphanumeric
				if (alphanumericRex.test(letter))
					return true;
			}
			if (format[value.length - 1] == value[value.length - 1]) {
				return true;
			}
		}
		return false;
	}

	OnChange(e) {
		const { Name, ValueChanged, IsText, Format, IsCompleted, Regex, DisableStateAdornment } = this.props;

		if (Format) {
			this.handleMask(e);
		}
		else if (IsText) {
			this.handleIsText(e);
		}

		if (ValueChanged) {
			let pureValue;
			if (Regex || Format) {
				pureValue = this.getRawInput(e.target.value);
			}
			ValueChanged(Name, e.target.value, pureValue);
		}
		let maskStatus = (Format ? Format.length : 0) <= e.target.value.length;

		if (Regex) {
			maskStatus = maskStatus && Regex.test(e.target.value);
		}

		if (IsCompleted) {
			IsCompleted((maskStatus && !Regex) || (maskStatus && Regex.test(e.target.value)));
		}
		if (DisableStateAdornment == undefined) {
			this.setState({ maskState: (e.target.value == "" || maskStatus) ? "" : "error" });
		}
	}
	render() {
		const { classes, LabelText, Value, MultiLine, RowCount, Disabled, BeginIconName,
			EndIconName, AutoFocus, KeyPressed, KeyUp, KeyDown, Blur, IsPassword, IconOnClick,
			Id, LabelMd, FloatRight, inputProps, BeginText, EndText,
			LabelStyle, InputStyle, IsError, Required, IsInvalid } = this.props;

		var haveStartIcon = (BeginIconName || BeginText || "") != "";
		var haveEndIcon = (EndIconName || EndText || "") != "";

		return (
			<CustomInput
				success={this.state.maskState === "success"}
				error={this.state.maskState === "error" || IsError}
				floatRight={FloatRight}
				LabelMd={LabelMd}
				LabelStyle={LabelStyle}
				InputStyle={InputStyle}
				labelText={LabelText}
				Required={Required}
				IsInvalid={IsInvalid}
				formControlProps={fullWidthStyle}
				inputProps={{
					...inputProps,
					value: Value != null ? Value : "",
					onChange: this.OnChange,
					multiline: MultiLine,
					rows: RowCount,
					disabled: Disabled,
					startAdornment: haveStartIcon ?
						<InputAdornment position="start" onClick={IconOnClick}>
							{BeginText ? BeginText :
								<GenericIcon className={IconOnClick ? classes.button : null}>{BeginIconName}</GenericIcon>
							}
						</InputAdornment>
						: null,
					endAdornment: haveEndIcon ?
						<InputAdornment style={endAdornmentStyle} position="end" onClick={IconOnClick}>
							{EndText ? EndText :
								<GenericIcon className={IconOnClick ? classes.button : null}>{EndIconName}</GenericIcon>
							}
						</InputAdornment>
						: null,
					autoFocus: AutoFocus,
					onKeyPress: KeyPressed,
					onKeyUp: KeyUp,
					onKeyDown: KeyDown,
					onBlur: (e) => {
						if (Blur) {
							Blur(e);
						}
					},
					type: IsPassword ? "password" : "text",
					id: Id
				}}
			/>
		);
	}
}

GenericTextInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.func,
	Value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	Id: PropTypes.string,
	LabelText: PropTypes.string,
	Name: PropTypes.string,
	MultiLine: PropTypes.bool,
	RowCount: PropTypes.number,
	Disabled: PropTypes.bool,
	BeginIconName: PropTypes.string,
	EndIconName: PropTypes.string,
	BeginText: PropTypes.string,
	EndText: PropTypes.string,
	AutoFocus: PropTypes.bool,
	KeyPressed: PropTypes.func,
	KeyDown: PropTypes.func,
	KeyUp: PropTypes.func,
	Blur: PropTypes.func,
	IsPassword: PropTypes.bool,
	IconOnClick: PropTypes.func,
	LabelMd: PropTypes.number,
	FloatRight: PropTypes.bool,
	inputProps: PropTypes.object,
	LabelStyle: PropTypes.object,
	InputStyle: PropTypes.object,
	IsCompleted: PropTypes.func,
	IsError: PropTypes.bool,
	IsText: PropTypes.bool,
	Placeholder: PropTypes.string,
	Format: PropTypes.string,
	tempFormat: PropTypes.string,
	Regex: PropTypes.any,
	DisableStateAdornment: PropTypes.bool,
	tooltipProps: PropTypes.object,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool
};

export default withStyles(genericTextInputStyle)(GenericTextInput);