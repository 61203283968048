import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DownloadIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericGrid } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApprovalDocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			model: {}
		};
		this.emptyObject = {};
		this.ColumnsData = [
			{
				Header: "Action",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Disabled={this.props.Disabled}
							tooltip="Download"
							Icon={DownloadIcon}
							OnClick={() => this.DownloadModel(row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Document Name",
				accessor: "DocumentType.ParameterDesc"
			},
		];

	}

	componentDidMount() {

	}
	DownloadModel = (index) => {
		this.setState({ isLoading: true });
		const { showMessage } = this.props;
		Proxy.FileDownloadWithGenericResponse(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			index.File.FileName,
			index.File.FileExtension.ParameterDesc,
			{
				"FileId": index.FileId
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}


	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	render() {
		return (
			<div>

				<GridContainer>
					{this.state.alert}

					<LoadingComponent Show={this.state.isLoading} />

					<GridItem xs={12} style={{ marginTop: 10 }}>
						<GenericGrid
							//className="-striped -highlight"
							Data={this.props.LoanApprovalDocumentsList || []}
							// filterable={false}
							Columns={this.ColumnsData}
							// defaultPageSize={50}
							// showPaginationTop={false}
							//minRows={2}
							HideButton={true}
						/>
					</GridItem>
				</GridContainer>
			</div >
		);
	}

}

LoanApprovalDocuments.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	tempModel: PropTypes.object,
	addressModel: PropTypes.object,
	HandleChange: PropTypes.func,
	ClientPartnerSaved: PropTypes.func,
	LoanApprovalDocumentsList: PropTypes.array,
	Title: PropTypes.string,
	Disabled: PropTypes.bool,
};

export default withArtifex(LoanApprovalDocuments, {});