import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon} from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions,  GenericGrid, GenericNumberInput, GenericSelectInput,  GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant.js";

class ClientSubAccountList extends React.Component {

	constructor(props) {
		super(props);
		this.isClient = ClientHelper.IsClient();
		this.isBackoffice = ClientHelper.IsBackOffice();
		let isClientVisible = this.isClient;
		this.initalModel = {
			UniqueClientId: 0
		};
		this.state = {
			searchModel: this.initalModel,
			statusAccountDialog: false,
			statusAccountModel: {},
			isLoading: false,
			remindModel: {},
			remindDialog: false

		};

		this.columns = [{
			Header: "Actions",
			accessor: "AC",
			Cell: row => (
				<div>

					<GridButton
						tooltip="Edit"
						// Disabled={this.isClient}
						Icon={EditIcon}
						OnClick={() => { this.AccountStatusUpdate(row.original); }} />

				</div>
			),
			width: 80,
			show: true //!isClientVisible

		},
		{
			Header: "UniqueClientId",
			accessor: "UniqueClientId", width: 100
		},
		{
			Header: "Client",
			accessor: "ClientName",
			width: 120

		},

		{
			Header: "SubWallet Name",
			accessor: "SubAccountName",
			width: 120,


		},
		{
			Header: "SubWallet Number",
			accessor: "SubAccountNumber",
			width: 120,
		},
		{
			Header: "Balance",
			accessor: "Balance",
			type: GridColumnType.Money,
			ColumnType: GridColumnType.Money ,width: 100
		},
		{
			Header: "Wallet Status",
			accessor: "AccountStatus", width: 100

		},
		{
			Header: "ProgramName",
			accessor: "ProgramName", width: 100

		}, {
			Header: "Insert Date",
			accessor: "InsertAccountDate",
			type: GridColumnType.DateTimeUtc,
		},
		{
			Header: "Update Date",
			accessor: "UpdateAccountDate",
			type: GridColumnType.DateTimeUtc,
		}
		];

	}
	componentDidMount() {

		const { searchModel } = this.state;
		if (!this.isBackoffice) {
			this.props.setAppCenterTitle("WALLET");
		}
		else {
			this.props.setAppCenterTitle("WALLET");
		}
		this.props.setAppLeftTitle("SubWallet List")

	} Clear = () => {
		this.setState({ searchModel: {}, list: [], selected: null, Month: [] });
	}

	AccountStatusUpdate = (value) => {
		var { remindModel } = this.state;
		if (value == null || value == 0) {
			this.props.showMessage("error", "Error", "Transaction Type information of line is invalid !");
			return;
		}
		remindModel.Id = value.Id;
		remindModel.AccountType = value.AccountStatus;
		this.setState({ remindModel, remindDialog: true });

	}
	hideDialog = () => { this.setState({ remindDialog: false }); }
	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }

	// ShowMessage = (type, title, message) => {
	// 	this.setState({
	// 		isLoading: false, cancelDialog: false, remindDialog: false,
	// 		alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
	// 	});
	// }
	confirmAccountPassiveStatus = () => {
		var { remindModel } = this.state;
		this.hideDialog();
		this.showLoading();
		remindModel.AccountStatus = false;
		Proxy.POST("/bankapi/v1.0/Account/SubAccountStatusUpdate",
			remindModel,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.hideLoading();
					return;
				}
				this.hideLoading();
				this.Search();
				this.props.showMessage("success", "Success", "Wallet Status Update successfully");

			},
			error => {
				this.props.showMessage("error", "Error", error);
			});

	}
	confirmAccountActiveStatus = () => {
		var { remindModel } = this.state;
		this.hideDialog();
		this.showLoading();
		remindModel.AccountStatus = true;
		Proxy.POST("/bankapi/v1.0/Account/SubAccountStatusUpdate",
			remindModel,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.hideLoading();

					return;
				}
				// if (value) this.props.showMessage("success", "Success", "Wallet Status Active  successfully");
				// else this.props.showMessage("success", "Success", "Wallet Status Passive successfully");
				this.hideLoading();
				this.Search();
				this.props.showMessage("success", "Success", "Wallet Status Update successfully");

			},
			error => {
				this.props.showMessage("error", "Error", error);
			});

	}

	Search = async () => {

		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.searchModel };
		if (!this.validata()) return;
		var result = await ExecuteApiPost("/bankapi/v1.0/Account/AllSubAccountList", model, null, null, null);
		this.setState({ list: result || [] });
		this.hideLoading();

	}

	validata = () => {
		const model = { ...this.state.searchModel };
		// if(this.isBackoffice &&( model.UniqueClientId==undefined || model.UniqueClientId==null || model.UniqueClientId==0 )){
		// 	this.props.showMessage("warning", "Please fill required fields!", " UniqueClientId must be defined");

		// 	return false;
		// }
		if(( model.SubAccountNumber==undefined || model.SubAccountNumber==null || model.SubAccountNumber==0 || model.SubAccountNumber=="")){
			this.props.showMessage("warning", "Please fill required fields!", " SubWalletNumber must be defined");

			return false;
		}

		return true;
	}


	ValueChanged = (name, value) => {
		this.setState(state => {
			var searchModel = state.searchModel;
			searchModel[name] = value;

			return { searchModel };
		});
	}

	showMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			),
		});
	};
	render() {
		const { Disabled } = this.props;
		const { searchModel, list, remindDialog, remindModel } = this.state;
		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: () => this.Clear(), Disabled: Disabled },
							{ Code: "Search", OnClick: () => this.Search(), Disabled: Disabled },

						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<GenericDialog open={remindDialog} maxWidth="sm" fullWidth>
							<DialogTitle>
								<b><h6>Wallet Status Update</h6></b>
							</DialogTitle>
							<DialogContent>
								<b><h6>Do you want to make this wallet Active or Passive? </h6></b>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.confirmAccountActiveStatus} disabled={remindModel.AccountType == "Active"}>Active</Button>
								<Button size="sm" onClick={this.confirmAccountPassiveStatus} disabled={remindModel.AccountType == "Inactive"} >Inactive</Button>
								<Button size="sm" onClick={() => this.setState({ remindDialog: false, remindModel: {} })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={" Main Parameters "} />
							</CardHeader>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}></p></b>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GridContainer>
											{this.isBackoffice &&

												<GridItem xs={3}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelMd={4}
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
														Value={searchModel.UniqueClientId || ""}
														ValueChanged={this.ValueChanged}
														CanClear
													/>
												</GridItem>
											}

											<GridItem xs={4}>
												<GenericNumberInput
													NoFormatting Name="SubWalletNumber"
													LabelText="Sub Wallet Number"
													Value={searchModel.SubAccountNumber}
													ValueChanged={this.ValueChanged}
													Required />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"SubWallet List"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={list}
											Columns={this.columns}
											ShowPagination={true}
											PageSize={10}
										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>

					</GridItem>
				</GridContainer>


			</div>
		);


	}
}

ClientSubAccountList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default ClientSubAccountList;
