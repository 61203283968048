
import TraceTransactionAlert from "views/Components/TraceTransactionAlert";
import React from "react";
import { ActionCodes, MenuCodes, ProgramCodes } from "views/Constants/Constant";

class TransactionTraceHelper {
	constructor(thisRef) {
		this.thisRef = thisRef;
	}

	InsertTransactionTraceWithAlert = async (transactionId, forWorkflow = false, actionCode = ActionCodes.Submit) => {
		this.transactionId = transactionId;
		return new Promise(async (resolve) => {
			var thiz = this.thisRef;
			var result = await thiz.props.ExecuteApiPost("/bankapi/v1.0/TransactionTrace/GetByTransactionId", { Id: transactionId });
			// console.log(result);

			if (!result?.ProgramCode) {
				return;
			}
			var hasTraced = result?.Id > 0;
			thiz.props.showAlert(React.createElement(TraceTransactionAlert, {
				OnTrace: this.HandleTrace,
				PromiseResolve: resolve,
				ForWorkflow: forWorkflow,
				ActionCode: actionCode,
				HasTraced: hasTraced,
				ProgramCode: result.ProgramCode,
				Reason: result.TraceReason,
				showAlert: thiz.props.showAlert
			}));
		});
	}

	InsertTransactionTraceForWorkflow = async (transactionId, reason, actionCode, programCode) => {

		var thiz = this.thisRef;
		if (!await thiz.props.showConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?")) return;

		var jsonData = JSON.stringify({
			TransactionId: transactionId,
			ProgramCode: programCode,
			Reason: reason
		});

		var menuCode = null;
		switch (programCode) {
			case ProgramCodes.Bank_Programs_EFT:
				menuCode = MenuCodes.Eft_TraceApprove;
				break;
			case ProgramCodes.Bank_Programs_e_Transfer:
				menuCode = MenuCodes.Etransfer_TraceApprove;
				break;
			// case ProgramCodes.BillPayment:
			// 	break;
			case ProgramCodes.Bank_Programs_Bank_Account:
				menuCode = MenuCodes.Wire_TraceApprove;
				break;
			default:
				break;
		}

		var requestData = {
			MenuCode: menuCode,
			ActionCode: actionCode,
			JsonData: jsonData,
			RowId: transactionId
		};

		var result = await thiz.props.ExecuteApiPost("/coreapi/v1.0/WorkflowData/InsertWorkflowData", requestData);
		if (result) {
			await thiz.props.showMessage("success", "Success", "Request successfully sent for approval");
		}
	}

	HandleTrace = async (model, forWorkflow, actionCode, programCode) => {
		if (forWorkflow) {
			await this.InsertTransactionTraceForWorkflow(this.transactionId, model.Reason, actionCode, programCode);
		} else {
			await this.InsertTransactionTrace(this.transactionId, model.Reason);
		}
	}

	InsertTransactionTrace = async (transactionId, reason, workflowId = null) => {
		var thiz = this.thisRef;
		var result = await thiz.props.ExecuteApiPost("/bankapi/v1.0/TransactionTrace/TraceTransactionByIdList", {
			TransactionIdList: [transactionId],
			Reason: reason
		}, null, null, null, null, null, workflowId);
		if (result) {
			thiz.props.showMessage("success", "Success", "Transaction Traced Successfully!");
		}
		return result;
	}

}
export default TransactionTraceHelper;