import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import FlinksAccountPreviewCard from "views/BankAccount/LinkedAccounts/FlinksAccountPreviewCard";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes } from "views/Constants/Constant";

class LinkedAccounts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			flinksAccountModel: {},
			linkedModel: {},
			customerLinkedAccounts: [],
			isFlinksLoginDialog: false,
			isLoading: false,
			flinksLoginModel: {},
			flinksAccounts: [],
			flinksResponse: {}
		};
	}



	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("Create Linked Wallet");
		this.props.setAppCenterTitle("WALLET");

		this.GetLinkedAccounts();

		var flinksHandle = this.HandleFlinksIframeResponse;
		window.addEventListener("message", function (e) {
			flinksHandle(e.data);
		});
	}
	HandleFlinksIframeResponse = (data) => {
		if (data != null) {
			// if (data.step == "INSTITUTION_SELECTED") {

			// }
			if (data.institution != null && data.loginId != null && data.requestId != null) {
				this.setState({ flinksLoginModel: data });

				this.setState({ isFlinksLoginDialog: false });
				this.ShowMessage("success", "Success", "Wallet Linked Successfully !");
				this.FlinksAccountSummaryRequest();
			}
		}
		console.log(data);
	}
	SaveFlinksModel = () => {
		var data = this.FlinksDataModelParse(this.state.flinksAccountModel);
		if (data == null || data.AccountId == null || data.AccountId == "") {
			this.ShowMessage("error", "Error", "Wallet must be selected !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerLinkedAccount/Update",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ClearData();
				this.ShowMessage("success", "Success", "Wallet saved succesfully !");
				this.GetLinkedAccounts();
			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });
			}
		);
	}
	FlinksDataModelParse = (model) => {
		var temp = {};
		const flinks = { ...this.state.flinksResponse };
		if (flinks != null) {
			temp.HttpStatusCode = flinks.HttpStatusCode;
			temp.Institution = flinks.Institution;
			temp.RequestId = flinks.RequestId;  // RequestId
			temp.Tag = flinks.Tag;
		}
		if (flinks != null && flinks.Login != null) {
			temp.Username = flinks.Login.Username;
			temp.LastRefresh = flinks.Login.LastRefresh;
			temp.Type = flinks.Login.Type;
			temp.LoginId = flinks.Login.Id; // LoginId
		}
		if (model != null) {
			temp.Title = model.Title;
			temp.AccountNumber = model.AccountNumber;
			temp.Category = model.Category;
			temp.Currency = model.Currency;
			temp.AccountId = model.Id;  // AccountId
			temp.TransitNumber = model.TransitNumber;
			temp.InstitutionNumber = model.InstitutionNumber; 
		}
		if (model != null && model.Balance != null) {
			temp.AvailableBalance = model.Balance.Available;
			temp.CurrentBalance = model.Balance.Current;
			temp.Limit = model.Balance.Limit;
		}
		if (model != null && model.Holder != null) {
			temp.Name = model.Holder.Name;
			temp.Email = model.Holder.Email;
			temp.PhoneNumber = model.Holder.PhoneNumber;
		}
		if (model != null && model.Holder != null && model.Holder.Address != null) {
			temp.CivicAddress = model.Holder.Address.CivicAddress;
			temp.City = model.Holder.Address.City;
			temp.Province = model.Holder.Address.Province;
			temp.PostalCode = model.Holder.Address.PostalCode;
			temp.Pobox = model.Holder.Address.POBox;
			temp.Country = model.Holder.Address.Country;
		}



		return temp;
	}
	FlinksAccountSummaryRequest = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/integrationapi/v1.0/Flinks/GetAccountsSummary",
			this.state.flinksLoginModel,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({
						flinksAccounts: responseData.Item.Accounts,
						flinksResponse: responseData.Item
					});
				} else {
					this.setState({ flinksAccounts: [], flinksResponse: {} });
				}
				this.ShowMessage("success", "Success", "Wallets fetched succesfully !");
				this.setState({ isLoading: false, isFlinksLoginDialog: false });
			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });
			}
		);
	}

	SelectedRowChange = (e, index) => {
		const model = this.state.flinksAccounts[index];
		this.setState({ flinksAccountModel: model });
	}
	handleChangeFlinksLogin = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.flinksLoginModel || {};
			model[name] = newValue;
			return { flinksLoginModel: model };
		});
	}
	
	GetLinkedAccounts = () => {
		//GetAllCustomerLinkedAccountByCustomerIdOp class
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerLinkedAccount/GetByCustomerId",
			{},
			responseData => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ customerLinkedAccounts: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error !", "An error occurred while requesting data " + error);
			}
		);
	}
	DeleteLinkedAccount = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Wallet must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerLinkedAccount/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Wallet deleted !");
				this.ClearData();
				this.GetLinkedAccounts();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.FlinksAccountSummaryRequest();
		}
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteLinkedAccount(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ClearData = () => {
		this.setState({
			alert: null,
			isLoading: false,
			model: {},
			isFlinksLoginDialog: false,
			flinksLoginModel: {},
			flinksAccountModel: {}
		});
	}

	HandleClickConnect = () => {
		this.setState({ isFlinksLoginDialog: true });
	};
	render() {
		const { Disabled } = this.props;
		const {  flinksAccountModel, flinksAccounts, customerLinkedAccounts } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Submit", OnClick: () => this.SaveFlinksModel(), Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<br />
				<FlinksAccountPreviewCard
					OnClickButton={this.HandleClickConnect}
					Model={flinksAccountModel}
				/>			

				<Card className="no-radius">
					<CardHeader>
						<h4><b><p style={{ paddingTop: "10px", paddingLeft: "15px" }}>
							Accounts
					</p></b></h4>
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={flinksAccounts}
							PageSize={5}
							ShowPagination={true}
							Columns={[
								{
									Header: "Actions",
									accessor: "Actions",
									Cell: row => (
										<div>
											<GridButton
												tooltip="Edit"
												Icon={EditIcon}
												Disabled={Disabled}
												OnClick={() => this.setState({ flinksAccountModel: row.original })} />
										</div>
									),
									sortable: false,
									filterable: false,
									width: 100
								},
								{
									Header: "Title",
									accessor: "Title"
								},
								{
									Header: "Wallet Number",
									accessor: "AccountNumber"
								},
								{
									Header: "Balance",
									accessor: "Balance",
									Cell: row => (
										<div>{row.original.Balance == null ? "" : row.original.Balance.Current == null || 0 ? "" : Formatter.FormatMoney(row.original.Balance.Current)}</div>
									)
								},
								{
									Header: "Category",
									accessor: "Category"
								},
								{
									Header: "Currency",
									accessor: "Currency"
								},
								{
									Header: "Name",
									accessor: "Holder.Name"
								},
								{
									Header: "Email",
									accessor: "Holder.Email"
								},
								{
									Header: "Phone",
									accessor: "Holder.PhoneNumber"
								},
								{
									Header: "Address",
									Cell: row => (
										<div>{row.original.Holder == null ? "" : row.original.Holder.Address == null ? "" :
											row.original.Holder.Address.CivicAddress + " " +
											row.original.Holder.Address.PostalCode + " " +
											row.original.Holder.Address.City + " " +
											row.original.Holder.Address.Country + " "
										}</div>
									)
								}
							]}
							RowSelected={index => {
								this.SelectedRowChange(0, index);
							}}
							ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
						/>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardHeader>
						<h4><b><p style={{ paddingTop: "10px", paddingLeft: "15px" }}>Client Linked Wallets</p></b></h4>
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={customerLinkedAccounts}
							PageSize={5}
							Columns={[
								{
									Header: "Actions",
									accessor: "Actions",
									Cell: row => (
										<div>
											<GridButton
												tooltip="Delete"
												Icon={DeleteIcon}
												Disabled={Disabled}
												OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
										</div>
									),
									sortable: false,
									filterable: false,
									width: 100
								},
								{
									Header: "Title",
									accessor: "Title"
								},
								{
									Header: "Wallet Number",
									accessor: "AccountNumber"
								},
								{
									Header: "Balance",
									accessor: "CurrentBalance",
									Cell: row => (
										<div>{row.original.CurrentBalance == null ? "" : Formatter.FormatMoney(row.original.CurrentBalance)}</div>
									)
								},
								{
									Header: "Currency",
									accessor: "Currency"
								},
								{
									Header: "Name",
									accessor: "Name"
								},
								{
									Header: "Email",
									accessor: "Email"
								},
								{
									Header: "Phone",
									accessor: "PhoneNumber"
								},
								{
									Header: "Address",
									accessor: "CivicAddress"
								}
							]}
							ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
					</CardBody>
				</Card>


				<GenericDialog open={this.state.isFlinksLoginDialog} maxWidth="md" fullWidth>
					<DialogTitle><GenericLabel Text="Create Linked Wallet" Bold={true} /></DialogTitle>
					<DialogContent>
					 	<iframe src={window.FlinksIframeUrl} id="frameFlks" width="800" height="800"></iframe>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isFlinksLoginDialog: false })}>	Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div >
		);
	}
}

LinkedAccounts.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(LinkedAccounts, {});