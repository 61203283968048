import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import GridContainer from "components/Grid/GridContainer";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, EtransferApplicationTypes, ProgramCodes } from "views/Constants/Constant";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";

class FraudCheckResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				ToDate: DateHelper.GetDate(),
				FromDate: DateHelper.GetDate(),
				ClientId: 0
			},
			getDataList: [],
			isLoading: false,
			isBackOffice: false,
			NowDate: DateHelper.GetDate(),
			clientId: 0,
			vIndividualModel: {},
			LimitToken: false
		};
		this.isClient = ClientHelper.IsClient();
		this.sortedByDate = [{ id: "Date", desc: true }];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Fraud Check Results");
		this.props.setAppCenterTitle("INTERAC E-TRANSFER");
		var model = { ...this.state.model };
		var { isBackOffice, clientId } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			clientId = ClientHelper.GetClientRowId();
		}
		this.setState({ model, isBackOffice, clientId });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	handleChange = (name, newValue) => {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}
	Validate = () => {

		const { model } = this.state;

		if (typeof model.FromDate != "object" && model.FromDate != "" && model.FromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}
		if (typeof model.ToDate != "object" && model.ToDate != "" && model.ToDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}
		if ((typeof model.FromDate == "object" && typeof model.ToDate == "object") && model.ToDate < model.FromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}
		return true;
	}
	GetData = () => {

		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/GetFraudChecks",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item == null || responseData.Item.length < 1) {
					this.setState({ getDataList: [], isLoading: false });
				} else {
					this.setState({
						getDataList: responseData.Item.TransactionList == null || undefined ? [] : responseData.Item.TransactionList, isLoading: false
					});
				}
			},
			error => {
				this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
			}
		);

	}
	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleClear = () => { this.setState({ model: { ToDate: DateHelper.GetDate(), FromDate: DateHelper.GetDate(), ClientId: this.state.clientId }, getDataList: [] }); }
	hideAlert() { this.setState({ alert: null }); }
	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ alert: null, isLoading: false }); }
	hileCancelDialog = () => { this.setState({ cancelDialog: false }); }

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, isBackOffice, NowDate, LimitToken } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				{this.isClient && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} IsUseDisableDebitLimit={false} />}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Filter Panel"}>
						<CardBody>
							<GridContainer >
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear
										Disabled={!isBackOffice} />
									<GenericDateInput
										Name="FromDate"
										LabelText="Start Date"
										Value={model.FromDate}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate || DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="ToDate"
										LabelText="End Date"
										Value={model.ToDate}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<ParameterComponent
										Name="DirectionId"
										Value={model.DirectionId}
										LabelText="Direction"
										ParameterCode="Direction"
										All
										ValueChanged={this.handleChange}
										ParameterValue2={"ETRANSFER"}
										Disabled={Disabled} />
									<GenericSelectInput
										Name="ApplicationId"
										LabelText="Application Type"
										Value={model === undefined ? "" : model.ApplicationId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Value"
										TextValueMember="Text"
										IsStatic
										StaticData={EtransferApplicationTypes || []}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Fraud Check List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.getDataList}
									Columns={[
										{
											Header: "Action",
											accessor: "Action"
										},
										{
											Header: "Date",
											accessor: "Date",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Unique Client Id",
											accessor: "ClientId",
											show: isBackOffice
										},
										{
											Header: "Client Name",
											accessor: "ClientName",
											show: isBackOffice
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType"
										},
										{
											Header: "Transaction e-Transfer Id",
											accessor: "TransactionETransferId"
										},
										{
											Header: "Direction",
											accessor: "Direction"
										},
										{
											Header: "Reason",
											accessor: "Reason"
										},
										{
											Header: "Score",
											accessor: "Score"
										},
										{
											Header: "Application",
											accessor: "ApplicationId",
											Cell: row => {
												var rowValue = "";
												EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
													if (item.Value == row.value)
														rowValue = item.Text;
												});
												return rowValue;
											}
										},
									]}
									Sorted={this.sortedByDate}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

FraudCheckResults.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(FraudCheckResults, {});