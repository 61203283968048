import { DialogContent, DialogTitle } from "@material-ui/core";
import { chartColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DocumentIcon, ProfileIcon } from "core/Icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, LOSApplicationCardStatus, LOSLoanType } from "views/Constants/Constant";
import PortfolioPerformanceChart from "views/LMS/PortfolioPerformance/PortfolioPerformanceChart";
import ApplicantDefinition from "views/LOS/ApplicantDefinition/ApplicantDefinition";
import Statement from "views/LOS/Statement/Statement";
class PortfolioPerformance extends Component {
	constructor(props) {
		super(props);
		this.UploadClick = this.UploadClick.bind(this);
		this.fileInputRef = React.createRef();
		this.emptyObject = {};
		this.FileTypeParameterCode = {
			ParameterCode: "LOSFileType"
		};
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			ApplicationsList: [],
			ApplicantList: [],
			isBrowse: false,
			isLoading: false,
			ReadOnly: true,
			selected: null,
			isLedgerCardDialogOpen: false,
			isStatementDialogOpen: false,
			PortfolioPerformanceList: [],
			modelrow: {},
			programList: [],
			totalLoansChartData: {
				labels: ["Total Loans"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			outStandingChartData: {
				labels: ["Outstanding"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			currentChartData: {
				labels: ["Current"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			pastDueChartData: {
				labels: ["Past Due"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			writeOffsChartData: {
				labels: ["Write Offs"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			interestChartData: {
				labels: ["Interest"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			feesChartData: {
				labels: ["Fees"],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
			isBorrowerDetailDialogOpen: false,
			applicantId: 0,
			isBackOffice: ClientHelper.IsBackOffice()
		};

		this.ColumnsData = [
			{
				Header: "Date",
				accessor: "Application.InsertDateTime",
				type: GridColumnType.Date
			},
			{
				Header: "Assign User",
				accessor: "AssignUserFullName"
			},
			{
				Header: "Borrower Name",
				accessor: "Application.Applicant.FullName"
			},
			{
				Header: "Application Number",
				accessor: "Application.ApplicationNumber"
			},
			{
				Header: "Partner",
				accessor: "Application.ClientPartner.LegalNameOfBusiness"
			},
			{
				Header: "Approval Status",
				accessor: "Application.ApprovalStatus.ParameterDesc"
			},
			{
				Header: "Activation Status",
				accessor: "Application.Status.ParameterDesc"
			},
			{
				Header: "Loan Type",
				accessor: "Application.LoanType.ParameterDesc"
			},
			{
				Header: "Wallet Number",
				accessor: "Application.Applicant.WalletAccount.AccountNumber"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Total Loan",
				accessor: "TotalLoan",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Total Payment",
				accessor: "TotalPayment",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Principal Amount",
				accessor: "TotalLoan",
				// accessor: "ApplicationPaymentDetail.PrincipalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Outstanding",
				accessor: "Outstanding",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Past Due",
				accessor: "PastDue",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Write Offs",
				accessor: "WriteOfBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Interest",
				accessor: "InterestBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Fees",
				accessor: "FeeBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false
			},
			{
				Header: "Payment Frequency",
				accessor: "Application.ApprovedPaymentFrequency.ParameterDesc"
			}
		];


		if (!this.state.isBackOffice) {
			this.ColumnsData.unshift(
				{
					Header: "Actions",
					accessor: "Actions",
					// @ts-ignore
					Cell: row => (
						<div>
							<GridButton
								tooltip="Profile"
								Icon={ProfileIcon}
								Disabled={this.props.Disabled}
								OnClick={() => {
									this.DialogBorrowerDetailOpen(row.original.Application.ApplicantId);
								}} />
							<GridButton
								tooltip="Ledger Card"
								Icon={DocumentIcon}
								Disabled={this.props.Disabled
									|| (row.original.Application.LoanType.ParameterValue != LOSLoanType.Loan
										&& (!row.original.ApplicationCardId || row.original.ApplicationCardStatus == LOSApplicationCardStatus.Passive))}
								OnClick={() => {
									this.SelectedRow(row.original);
								}} />
						</div>
					),
					sortable: false,
					filterable: false
				},
			);
		}


		this.InterestTypeParameterCode = {
			ParameterCode: "LOSInterestType"
		};
		this.PeriodParameterCode = {
			ParameterCode: "Period"
		};
		this.PayOptionParameterCode = {
			ParameterCode: "LOSPayOption"
		};
		this.YesNoParameterCode = {
			ParameterCode: "LOSYesNo"
		};
		this.LoanTypeParameterCode = {
			ParameterCode: "LOSLoanType"
		};
		this.LOSPaymentFrequency = {
			ParameterCode: "LOSPaymentSchedule"
		};

		this.SuccessListCallback = this
			.SuccessListCallback
			.bind(this);
	}

	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Portfolio Performance");
		this
			.props
			.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}

	SelectedRow(row) {
		if (row.Application.LoanType.ParameterValue === LOSLoanType.Loan) {
			this.setState({ modelrow: row });
			this.LedgerCardDialogOpenOrClose();
		} else {
			this.setState({ modelrow: row });
			this.StatementDialogOpenOrClose();
		}
	}

	DialogBorrowerDetailOpen = (applicantId) => {
		this.setState({ isBorrowerDetailDialogOpen: true, applicantId });
	}

	DialogBorrowerDetailClose = () => {
		this.setState({ isBorrowerDetailDialogOpen: false });
	}

	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/lmsapi/v1.0/Dashboard/GetData", this.state.model, responseData => {
			console.log("GetPortfolioPerformance ->", responseData.IsSucceeded);
			if (!responseData.IsSucceeded) {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			}
			if (responseData.Item != null) {
				console.log(responseData);
				this.SuccessListCallback(responseData);
				this.setState({
					PortfolioPerformanceList: responseData.Item.ApplicationDetailList || [],
					isLoading: false
				});
			} else {
				this.setState({ PortfolioPerformanceList: [], isLoading: false });
			}
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}


	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};
		if (name === "FromDate" && (newValue instanceof moment) && newValue > model.ToDate) {
			// @ts-ignore
			var endDate = newValue.clone();
			model["ToDate"] = endDate.add(33, "days");
		}
		if (name === "FromDate") {
			if ((newValue instanceof moment) && Math.abs(model.ToDate.diff(newValue, "days")) > 33) {
				// @ts-ignore
				endDate = newValue.clone();
				model["ToDate"] = endDate.add(33, "days");
			}
		}
		model[name] = newValue;
		this.setState({ model });

	}

	UploadClick() {
		this
			.fileInputRef
			.current
			.click();
	}

	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}

	LedgerCardDialogOpenOrClose = () => {
		if (this.state.isLedgerCardDialogOpen == false) {
			this.setState({ isLedgerCardDialogOpen: true });
		} else {
			this.setState({ isLedgerCardDialogOpen: false });
		}
	}

	StatementDialogOpenOrClose = () => {
		if (this.state.isStatementDialogOpen == false) {
			this.setState({ isStatementDialogOpen: true });
		} else {
			this.setState({ isStatementDialogOpen: false });
		}
	}

	HandleClickClearOk = () => {
		this.HideAlert();
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			}
		});
	}

	HandleClear = () => {
		this.setState({ model: {}, selected: null });
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false });
		window
			.location
			.reload();

	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({
			LimitToken: !this.state.LimitToken
		});
	}

	formatNumber = (num) => {
		return " $" + num
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	SuccessListCallback(responseData) {
		console.log("successslistcallback");
		var arrayData = [];
		var arrayData2 = [];
		var arrayData3 = [];
		var arrayData4 = [];
		var arrayData5 = [];
		var arrayData6 = [];
		var arrayData7 = [];
		var arrayLabel = [];
		var arrayLabel2 = [];
		var arrayLabel3 = [];
		var arrayLabel4 = [];
		var arrayLabel5 = [];
		var arrayLabel6 = [];
		var arrayLabel7 = [];
		var arrayColor = [];
		var arrayColor2 = [];
		var arrayColor3 = [];
		var arrayColor4 = [];
		var arrayColor5 = [];
		var arrayColor6 = [];
		var arrayColor7 = [];

		if (responseData.Item !== null) {
			var colorNames = Object.keys(chartColors);
			var i = 0;

			var colorName = colorNames[i % colorNames.length];
			var newColor = chartColors[colorName];
			arrayColor.push(newColor);
			arrayData.push(responseData.Item.TotalLoansChartData != null
				? responseData.Item.TotalLoansChartData.TotalRequestAmount
				: 0);
			arrayData.push(responseData.Item.TotalLoansChartData != null
				? responseData.Item.TotalLoansChartData.RequestAmountCount
				: 0);

			arrayLabel.push("Tran Amount");
			arrayLabel.push("Total Amount");
			if (responseData.Item.TotalLoansChartData != null) {
				var totalLoanString = responseData.Item.TotalLoansChartData.RequestAmountCount + "   "
					+ this.formatNumber(responseData.Item.TotalLoansChartData.TotalRequestAmount ? responseData.Item.TotalLoansChartData.TotalRequestAmount.toFixed(2) : 0);
			}

			var totalLoansChartDataObj = {
				labels: arrayLabel,
				datasets: [
					{
						data: arrayData,
						hoverBackgroundColor: arrayColor,
						backgroundColor: arrayColor
					}
				],
				text: responseData.Item.TotalLoansChartData != null
					? totalLoanString
					: "0"
			};

			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor2.push(newColor);
			arrayData2.push(responseData.Item.OutstandingChartData != null
				? responseData.Item.OutstandingChartData.WaitingRequestAmount
				: 0);
			arrayData2.push(responseData.Item.OutstandingChartData != null
				? responseData.Item.OutstandingChartData.WaitingTotalPerson
				: 0);

			arrayLabel2.push("Tran Amount");
			arrayLabel2.push("Total Amount");
			if (responseData.Item.OutstandingChartData != null) {
				var outStandingString = responseData.Item.OutstandingChartData.WaitingTotalPerson
					+ this.formatNumber(responseData.Item.OutstandingChartData.WaitingRequestAmount ? responseData.Item.OutstandingChartData.WaitingRequestAmount.toFixed(2) : 0);
			}

			var outStandingChartDataObj = {
				labels: arrayLabel2,
				datasets: [
					{
						data: arrayData2,
						hoverBackgroundColor: arrayColor2,
						backgroundColor: arrayColor2
					}
				],
				text: responseData.Item.OutstandingChartData != null
					? outStandingString
					: "0"
			};

			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor3.push(newColor);
			arrayData3.push(responseData.Item.CurrentChartData != null
				? responseData.Item.CurrentChartData.PaidRequestAmount
				: 0);
			arrayData3.push(responseData.Item.CurrentChartData != null
				? responseData.Item.CurrentChartData.PaidTotalPerson
				: 0);

			arrayLabel3.push("Tran Amount");
			arrayLabel3.push("Total Amount");
			if (responseData.Item.CurrentChartData != null) {
				var currentString = responseData.Item.CurrentChartData.PaidTotalPerson
					+ this.formatNumber(responseData.Item.CurrentChartData.PaidRequestAmount ? responseData.Item.CurrentChartData.PaidRequestAmount.toFixed(2) : 0);
			}

			var currentChartDataObj = {
				labels: arrayLabel3,
				datasets: [
					{
						data: arrayData3,
						hoverBackgroundColor: arrayColor3,
						backgroundColor: arrayColor3
					}
				],
				text: responseData.Item.CurrentChartData != null
					? currentString
					: "0"
			};

			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor4.push(newColor);
			arrayData4.push(responseData.Item.PastdueChartData != null
				? responseData.Item.PastdueChartData.UnPaidRequestAmount
				: 0);
			arrayData4.push(responseData.Item.PastdueChartData != null
				? responseData.Item.PastdueChartData.UnPaidTotalPerson
				: 0);

			arrayLabel4.push("Tran Amount");
			arrayLabel4.push("Total Amount");
			if (responseData.Item.PastdueChartData != null) {
				var pastDueString = responseData.Item.PastdueChartData.UnPaidTotalPerson + "   "
					+ this.formatNumber(responseData.Item.PastdueChartData.UnPaidRequestAmount ? responseData.Item.PastdueChartData.UnPaidRequestAmount.toFixed(2) : 0);
			}

			var pastDueChartDataObj = {
				labels: arrayLabel4,
				datasets: [
					{
						data: arrayData4,
						hoverBackgroundColor: arrayColor4,
						backgroundColor: arrayColor4
					}
				],
				text: responseData.Item.PastdueChartData != null
					? pastDueString
					: "0"
			};

			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor5.push(newColor);
			arrayData5.push(responseData.Item.WriteOffsChartData);

			arrayLabel5.push("Total Amount");
			if (responseData.Item.WriteOffsChartData != null) {
				var writeOffsString = this.formatNumber(responseData.Item.WriteOffsChartData.toFixed(2));
			}

			var writeOffsChartDataObj = {
				labels: arrayLabel5,
				datasets: [
					{
						data: arrayData5,
						hoverBackgroundColor: arrayColor5,
						backgroundColor: arrayColor5
					}
				],
				text: responseData.Item.WriteOffsChartData != null
					? writeOffsString
					: "0"
			};


			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor6.push(newColor);
			arrayData6.push(responseData.Item.InterestChartData != null
				? responseData.Item.InterestChartData.InterestBalance
				: 0);
			arrayLabel6.push("Total Amount");
			if (responseData.Item.InterestChartData != null) {
				var interestString = this.formatNumber(responseData.Item.InterestChartData.InterestBalance ? responseData.Item.InterestChartData.InterestBalance.toFixed(2) : 0);
			}
			var interestChartDataObj = {
				labels: arrayLabel6,
				datasets: [
					{
						data: arrayData6,
						hoverBackgroundColor: arrayColor6,
						backgroundColor: arrayColor6
					}
				],
				text: responseData.Item.InterestChartData != null
					? interestString
					: "0"
			};


			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor7.push(newColor);
			arrayData7.push(responseData.Item.FeesChartData != null
				? responseData.Item.FeesChartData.TotalSetupAmount
				: 0);

			arrayLabel7.push("Total Amount");
			if (responseData.Item.FeesChartData != null) {
				var feesString = this.formatNumber(responseData.Item.FeesChartData.TotalSetupAmount ? responseData.Item.FeesChartData.TotalSetupAmount.toFixed(2) : 0);
			}

			var feesChartDataObj = {
				labels: arrayLabel7,
				datasets: [
					{
						data: arrayData7,
						hoverBackgroundColor: arrayColor7,
						backgroundColor: arrayColor7
					}
				],
				text: responseData.Item.FeesChartData != null
					? feesString
					: "0"
			};
			/********************************************************/
			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			this.setState({
				model,
				totalLoansChartData: responseData.Item.TotalLoansChartData != null && responseData.Item.TotalLoansChartData.RequestAmountCount > 0 ? totalLoansChartDataObj : { ...this.state.totalLoansChartData, ...{ text: "0" } },
				outStandingChartData: responseData.Item.OutstandingChartData != null && responseData.Item.OutstandingChartData.WaitingTotalPerson > 0 ? outStandingChartDataObj : { ...this.state.outStandingChartData, ...{ text: "0" } },
				currentChartData: responseData.Item.CurrentChartData != null && responseData.Item.CurrentChartData.PaidTotalPerson > 0 ? currentChartDataObj : { ...this.state.currentChartData, ...{ text: "0" } },
				pastDueChartData: responseData.Item.PastdueChartData != null && responseData.Item.PastdueChartData.UnPaidTotalPerson > 0 ? pastDueChartDataObj : { ...this.state.pastDueChartData, ...{ text: "0" } },
				writeOffsChartData: responseData.Item.WriteoffsChartData != null ? writeOffsChartDataObj : { ...this.state.writeOffsChartData, ...{ text: "0" } },
				interestChartData: responseData.Item.InterestChartData != null && responseData.Item.InterestChartData.InterestBalance > 0 ? interestChartDataObj : { ...this.state.interestChartData, ...{ text: "0" } },
				feesChartData: responseData.Item.FeesChartData != null && responseData.Item.FeesChartData.TotalSetupAmount > 0 ? feesChartDataObj : { ...this.state.feesChartData, ...{ text: "0" } },
			});
		}
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	render() {
		const { ForInsert, Disabled } = this.props;
		const {
			alert,
			isLoading,
			isStatementDialogOpen,
			model,
			applicantId,
			isBorrowerDetailDialogOpen,
			isBackOffice
		} = this.state;

		var endDate = (model.FromDate instanceof moment) ? model.FromDate.clone() : "";
		return (
			<div>
				{alert}

				<LoadingComponent Show={isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Clear",
							OnClick: this.HandleClickClearOk,
							Disabled: Disabled
						}, {
							Code: "Search",
							OnClick: this.Search
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					IsPopUp={ForInsert} />
				<GenericDialog open={isBorrowerDetailDialogOpen} fullWidth maxWidth="lg">
					<DialogContent>
						<ApplicantDefinition
							ExecuteApiPost={this.props.ExecuteApiPost}
							MenuCode={this.props.MenuCode}
							ApplicantModel={{ Id: applicantId }}
							IsPopUp={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogBorrowerDetailClose}>CANCEL</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog fullScreen={false} maxWidth="lg" open={isStatementDialogOpen}>
					<DialogTitle>
						<GenericTitle text="Statement" />
					</DialogTitle>
					<DialogContent >
						<Statement
							ExecuteApiPost={this.props.ExecuteApiPost}
							ApplicantId={this.state.modelrow.Application ? this.state.modelrow.Application.ApplicantId : 0}
							ApplicationId={this.state.modelrow.Application ? this.state.modelrow.Application.Id : 0}
							IsPopUp={true}
							showMessageNode={this.ShowMessageNode}
							showValidationErrors={this.props.showValidationErrors} />
						<GenericDialogActions>
							<Button size="sm" onClick={this.StatementDialogOpenOrClose}>Close</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>

				<Card>
					<CardBody>
						<GridContainer>

							{isBackOffice && (
								<GridItem xs={2} >
									<GenericLabel
										Text="Client" />
									<GenericSelectInput
										Name="UniqueClientId"
										Method="POST"
										LabelMd={0}
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemUniqueClient}
										Value={model.UniqueClientId}
										ValueChanged={this.HandleChange}
										CanClear
										All
										Disabled={!isBackOffice}
										DefaultIndex={isBackOffice ? 0 : -1}
									/>
								</GridItem>
							)}
							<GridItem xs={2}>
								<GenericLabel
									Text="From Date" />
								<GenericDateInput
									Name="FromDate"
									LabelMd={0}
									IsFuture={false}
									//MinDate={DateHelper.GetDate().subtract(33, 'days')}
									MaxDate={DateHelper.GetDate()}
									Value={model.FromDate == null ? "" : DateHelper.ToDateInputValue(model.FromDate) || ""}
									ValueChanged={this.HandleChange}
									Utc />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="To Date" />
								<GenericDateInput
									Name="ToDate"
									LabelMd={0}
									IsFuture={false}
									MinDate={model.FromDate}
									// @ts-ignore
									MaxDate={endDate != "" ? endDate.add(33, "days") : ""}
									Value={model.ToDate == null ? "" : DateHelper.ToDateInputValue(model.ToDate) || ""}
									ValueChanged={this.HandleChange}
									Utc />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="Select Loan Type" />
								<ParameterComponent
									Name="LoanTypeId"
									LabelMd={0}
									ParameterCode="LOSLoanType"
									Value={model.LoanTypeId}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="Select Partner" />
								<GenericSelectInput
									Name="ClientPartnerId"
									LabelMd={0}
									Url="/losapi/v1.0/ClientPartner/GetAll"
									Method="POST"
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="LegalNameOfBusiness"
									Value={model.ClientPartnerId}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="Select Payment Frequency" />
								<ParameterComponent
									Name="RequestedPaymentFrequencyId"
									LabelMd={0}
									ParameterCode="LOSPaymentSchedule"
									Value={model.RequestedPaymentFrequencyId}
									ValueChanged={this.HandleChange} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<PortfolioPerformanceChart
					list={this.state.programList}
					totalLoansChartData={this.state.totalLoansChartData}
					outStandingChartData={this.state.outStandingChartData}
					currentChartData={this.state.currentChartData}
					pastDueChartData={this.state.pastDueChartData}
					writeOffsChartData={this.state.writeOffsChartData}
					interestChartData={this.state.interestChartData}
					feesChartData={this.state.feesChartData}
					leftTitle={"Programs"}
					TitleChart={"Total Loan"}
					TitleChart2={"Total Outstanding"}
					TitleChart3={"Current"}
					TitleChart4={"Past Due"}
					TitleChart5={"Write Offs"}
					TitleChart6={"Interest"}
					TitleChart7={"Fees"}
					centerTitle={"PORTFOLIO PERFORMANCE"}
					setAppLeftTitle={this.props.setAppLeftTitle}
					setAppCenterTitle={this.props.setAppCenterTitle} />


				<Card>
					<CardBody>
						<GenericExpansionPanel Title="Portfolio">
							<GridContainer>
								<GridItem xs={12}>
									<GenericGrid
										Data={this.state.PortfolioPerformanceList || []}
										Columns={this.ColumnsData}
										IsSorted={false} />
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

			</div >
		);
	}
}

PortfolioPerformance.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ApplicantSaved: PropTypes.func,
	Disabled: PropTypes.bool,
	model: PropTypes.object
};

export default withArtifex(PortfolioPerformance);