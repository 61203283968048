import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import style from "assets/jss/material-dashboard-pro-react/views/dynamicToolBarStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

class AccountLimitToolbar extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			ItemList: [
				{ Label: "Funding Wallet Balance" },
				{ Label: "Fee Wallet Balance" },
				{ Label: "Available Balance" },
				{ Label: "Daily Available Credit Limit" },
				{ Label: "Daily Available Debit Limit" }

			]
		};

		this.isStore = ClientHelper.IsStore();

		this.isCancelled = false;

		this.FetchData = this.FetchData.bind(this);
	}

	FetchData() {
		const { Program,LimitMainType, OnLoad, IsUseDisableDebitLimit } = this.props;

		Proxy.POST(
			"/bankapi/v1.0/LimitBalance/GetLimitBalance",
			{
				ProgramCode : Program,
				LimitMainType : LimitMainType || ""
			},
			responseData => {
				if (this.isCancelled) return;

				if (!responseData.IsSucceeded) {
					console.error("Error for wallet limits: ", responseData.ErrorDescription);
					if (OnLoad)
						OnLoad({});
					return;
				}

				var data = responseData.Item;
				if (data != null) {
					var list = [
						{ Label: "Funding Wallet Balance", Value: data.FundingAccountBalance },
						{ Label: "Fee Wallet Balance", Value: data.FeeAccountBalance },
						{ Label: "Daily Available Credit Limit", Value: data.AvailableCreditLimit }
					];

					if (!IsUseDisableDebitLimit) {
						list.push({ Label: "Daily Available Debit Limit", Value: data.AvailableDebitLimit });
					}

					if (this.isStore) {
						list.push({ Label: "Daily Available Store Credit Limit", Value: data.AvailableStoreCreditLimit });
						list.push({ Label: "Daily Available Store Debit Limit", Value: data.AvailableStoreDebitLimit });
					}

					this.setState({ ItemList: list });

					if (OnLoad)
						OnLoad(data);
				}
			},
			error => {
				if (this.isCancelled) return;

				console.error("Error for wallet limits: " + error);
				if (OnLoad)
					OnLoad({});
			}
		);
	}

	componentDidMount() {
		this.FetchData();
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	render() {
		const { classes, IsPopUp } = this.props;
		const { ItemList } = this.state;

		return (
			<div className={IsPopUp ? "" : classes.paddingBottom}>
				<div className={IsPopUp ? classes.normal : classes.fixed}>
					<div className={classes.main}>
						<GridContainer justify="flex-start">
							{ItemList.map((item, index) => (
								<GridItem key={index}>
									<GridContainer>
										<GridItem className={classes.appbaritemleft}>
											{item.Label}
										</GridItem>
										<GridItem >
											<Typography variant="h6" color="inherit" className={classes.appbaritemright} >
												{Formatter.FormatMoney(item.Value)}
											</Typography>
										</GridItem>
									</GridContainer>
								</GridItem>
							))}
						</GridContainer>
					</div>
				</div>
			</div>
		);
	}
}

AccountLimitToolbar.propTypes = {
	classes: PropTypes.object,
	Program: PropTypes.string,
	OnLoad: PropTypes.func,
	IsPopUp: PropTypes.bool
};

export default withStyles(style)(AccountLimitToolbar);