import { withStyles } from "@material-ui/core";
import genericVerificationComponentStyle from "assets/jss/generic/genericVerificationComponentStyle.jsx";
import cx from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { GenericIcon, GenericLabel } from "views/Components/Generic";
import GridItem from "components/Grid/GridItem";

class GenericVerificationComponent extends Component {
	getIcon = (status) => {

		if (status === "success") {
			return "fa-check-circle";
		}
		else if (status === "error") {
			return "fa-times-circle";
		}
		else if (status === "warning") {
			return "fa-exclamation-circle";
		}
		else {
			return "fa-ban";
		}
	}
	render() {
		const { Status, Label, classes } = this.props;
		var statusStr = this.getIcon(Status);
		return (
			<GridContainer
				justify="space-between"
				alignItems="stretch">
				<GridItem xs={12}>
					<GenericLabel
						Text={Label}
						TextAlign="center"
						Bold
						Display="block">
					</GenericLabel>
				</GridItem>
				<GridItem xs={12}>
					<div className={cx({
						[classes.verificationBase]: true,
						[classes.verificationFalse]: Status === "error" || Status === false,
						[classes.verificationTrue]: Status === "success" || Status === true,
						[classes.verificationWarning]: Status === "warning",
						[classes.verificationEmpty]: Status !== "success" && Status !== "error" && Status !== "warning"
					})}>
						<GenericIcon key={statusStr}>
							{statusStr}
						</GenericIcon>
					</div>
				</GridItem>
			</GridContainer>
		);

	}
}

GenericVerificationComponent.propTypes = {
	Label: PropTypes.string,
	Status: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf(["error", "success", "empty", "warning"])
	])
};

export default withStyles(genericVerificationComponentStyle)(GenericVerificationComponent);