import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DeleteIcon, UploadIcon } from "core/Icons";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import {MdesRequestTypeDefinition, Direction , GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import Select from "react-select";

class TspRequest extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			SearchEndInsertDate: DateHelper.GetLocalDateAsUtc(),
			SearchStartInsertDate: DateHelper.GetLocalDateAsUtc(),
			SearchStartUpdateDate: DateHelper.GetLocalDateAsUtc(),
			SearchEndUpdateDate: DateHelper.GetLocalDateAsUtc(),
			ClientId: 0,
			IsBankingScreen: false,
			searchIsNotSpecific: true
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			tempModel: {},
			getDataList: [],
			summaryModel: {},
			isLoading: false,
			isBackOffice: false,
			selectList: [],
			checkedValues: [],
			cancelRequestModel: {
				SelectedETransfer: {},
				cancellationReason: null
			},
			cancelDialog: false,
			vIndividualModel: {},
			LimitToken: false,
			remindModel: {},
			remindDialog: false
		};

		this.sortedByDate = [{ id: "TransactionDate", desc: true }];
		this.maxLength400 = { maxLength: 400 };
		this.isClient = ClientHelper.IsClient();
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.isBankingScreen = this.props.MenuCode == "BETOUT";
		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Tsp / Mdes Requests");

		var model = { ...this.state.model };
		var initialModel = this.initialModel;

		model.IsBankingScreen = this.props.MenuCode == "BETOUT";
		var isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			initialModel.ClientId = model.ClientId;
			this.props.setAppCenterTitle(model.IsBankingScreen ? "WALLET" : "Mdes Requests");
		}
		else {
			this.props.setAppCenterTitle("Mdes Requests");
		}
		this.RefreshToolBar();
		this.setState({ model, isBackOffice, loadingCompleted: true });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleChangeCancelModel = (name, newValue) => {
		const cancelRequestModel = { ...this.state.cancelRequestModel };
		cancelRequestModel.cancellationReason = newValue;
		this.setState({ cancelRequestModel });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetData() { this.setState(state => ({ trxGridToken: !state.trxGridToken })); this.GetSummaryData(); }

	GetSummaryData = () => {
		if (!this.Validate())
			return;

		Proxy.POST(
			"/tspapi/v1.0/MdesRequests/GetRequestByParameter",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ summaryModel: responseData.Item });
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;

		var searchIsNotSpecific = (model.ClientId == undefined )
			
		model["searchIsNotSpecific"] = searchIsNotSpecific;

		var validateStatus = true;

		if (searchIsNotSpecific) {

			if (!model.ClientId) {
				this.props.showMessage("warning", "Please fill required fields!", "Select Client to continue");
				vModel["ClientId"] = true;
				validateStatus = false;
			}

			if (typeof model.SearchStartDate != "object" && model.SearchStartDate != "" && model.SearchStartDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
				validateStatus = false;
			}
			if (typeof model.SearchEndDate != "object" && model.SearchEndDate != "" && model.SearchEndDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
				validateStatus = false;
			}
			if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object") &&
				DateHelper.ToMoment(model.SearchEndDate) < DateHelper.ToMoment(model.SearchStartDate)) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
				validateStatus = false;
			}

			if (typeof model.SearchStartUpdateDate != "object" && model.SearchStartUpdateDate != "" && model.SearchStartUpdateDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
				validateStatus = false;
			}
			if (typeof model.SearchEndUpdateDate != "object" && model.SearchEndUpdateDate != "" && model.SearchEndUpdateDate != null) {
				this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
				validateStatus = false;
			}
			if ((typeof model.SearchStartUpdateDate == "object" && typeof model.SearchEndUpdateDate == "object") &&
				DateHelper.ToMoment(model.SearchEndUpdateDate) < DateHelper.ToMoment(model.SearchStartUpdateDate)) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
				validateStatus = false;
			}

			this.setState({ model, vModel });
			if (!validateStatus)
				return false;
		}
		else {
			vModel["ClientId"] = false;
			this.setState({ model, vModel });
		}
		return true;
	}

	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.TransactionList == null || responseData.Item.TransactionList.length < 1) {
			this.setState({ summaryModel: {}, cancelRequestModel: null, getDataList: [], isLoading: false });
		} else {
			this.setState({
				cancelRequestModel: { SelectedETransfer: {}, cancellationReason: null },
				getDataList: responseData.Item.TransactionList == null ? [] : responseData.Item.TransactionList, isLoading: false
			});
		}
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			isLoading: false, cancelDialog: false, remindDialog: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleClear = () => {
		this.setState({
			model: this.initialModel,
			getDataList: [],
			summaryModel: {}
		});
	}

	hideAlert() { this.setState({ alert: null }); }
	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ alert: null, isLoading: false }); }
	hideDialog = () => { this.setState({ cancelDialog: false, remindDialog: false }); }

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Tsp / Token List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClearingSettlement/GetETransferOutgoingTransfersExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading, isBackOffice, cancelDialog, cancelRequestModel, LimitToken, remindModel, remindDialog, vModel } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Summary Information"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										CanClear
										Disabled={!isBackOffice}
										Required={model.searchIsNotSpecific == true}
										IsInvalid={vModel.ClientId} />
									<GenericDateInput
										Name="SearchStartInsertDate"
										LabelText="Start Insert Date"
										Value={model.SearchStartInsertDate}
										ValueChanged={this.handleChange}
										MaxDate={model.SearchStartInsertDate || DateHelper.GetDateTimeLocal()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="SearchEndInsertDate"
										LabelText="End Insert  Date"
										Value={model.SearchEndInsertDate}
										ValueChanged={this.handleChange}
										MinDate={model.SearchEndInsertDate}
										IncludeTime={false}
										Utc />
									<GenericTextInput
										Name="CorrelationId"
										LabelText="Correlation Id"
										Value={model.CorrelationId}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="MdesTokenDefinitionId"
										LabelText="Token Definition Id"
										Value={model.MdesTokenDefinitionId}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="MdesRequestsId"
										LabelText="Mdes Requests Id"
										Value={model.MdesRequestsId}
										ValueChanged={this.handleChange} />
									<GenericSelectInput
												Name="MdesRequestTypeDefinitionId"
												LabelText="Request Type"
												Value={model === undefined ? "" : model.MdesRequestTypeDefinitionId || ""}
												DataRoot="Item"
												All
												ValueChanged={this.handleChange}
												KeyValueMember="Value"
												TextValueMember="Text"
												IsStatic
												StaticData={MdesRequestTypeDefinition || []}
											/>
								</GridItem>
								<GridItem xs={1}></GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Request List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									PageSize={10}
									ShowPagination={false}
									Columns={[
									{
										Header: "RequestInsertDatetime",
										accessor: "Request Insert Datetime",
										type: GridColumnType.DateTime,
										sortable: false,
										filterable: false,
										width: 70
									},
									{
										Header: "Requests Id",
										accessor: "RequestId",
									},
									{
										Header: "Request Type Definition Id",
										accessor: "MdesRequestTypeDefinitionId",
									},
									{
										Header: "Correlation Id",
										accessor: "CorrelationId",
									},
									{
										Header: "Token Definition Id",
										accessor: "MdesTokenDefinitionId",
									},
									{
										Header: "Request Result Code",
										accessor: "RequestResultCode"
									},
									{
										Header: "Request Result Description",
										accessor: "RequestResultDescription"
									}				
									]}
									Sorted={this.sortedByDate}
									ProgramCode={ProgramCodes.Tsp}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									ValidateRequest={this.Validate}
									PrepareRequest={() => { return this.state.model; }}
									RequestUrl="/tspapi/v1.0/MdesRequests/GetRequestByParameter"
									RequestMethod="POST" />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

TspRequest.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(TspRequest, {});