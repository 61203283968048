import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDialog, GenericLabel, GenericDialogActions, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";
class ClientAuditLog extends React.Component {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.clientId = ClientHelper.GetClientRowId();

		this.defaultModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientId,
			isLoading: false,
			User: undefined,
			Menu: undefined,
			Action: undefined,
			StartDate: undefined,
			EndDate: undefined,
			jsonFile: undefined
		};

		this.state = {
			model: { ...this.defaultModel },
			vModel: {}
		};

		this.Validate = this.Validate.bind(this);
		this.SearchClick = this.SearchClick.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.ValueChanged = this.ValueChanged.bind(this);
		this.calogTable = React.createRef();
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Client Audit Log");
		if (setAppCenterTitle)
			setAppCenterTitle("User Admin");
	}

	Validate() {
		const { model, vModel } = this.state;

		var messages = [];

		vModel.StartDate = model.StartDate == null;
		if (vModel.StartDate) messages.push("Start Date cannot be null.");
		else {
			vModel.StartDate = typeof model.StartDate != "object";
			if (vModel.StartDate) messages.push("Start Date is invalid.");
		}

		vModel.EndDate = model.EndDate == null;
		if (vModel.EndDate) messages.push("End Date cannot be null.");
		else {
			vModel.EndDate = typeof model.EndDate != "object";
			if (vModel.EndDate) messages.push("End Date is invalid.");
		}

		if (vModel.StartDate == false && vModel.EndDate == false) {
			if (model.StartDate > model.EndDate) messages.push("Start Date cannot be after End Date.");
			else if (model.EndDate.diff(model.StartDate, "days") > 15) messages.push("End date cannot exceed 15 days from Start date.");
		}

		if (messages.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			this.setState({ vModel });
			return false;
		}

		return true;
	}

	SearchClick() {
		const { model } = this.state;

		if (!this.Validate())
			return;

		var request = {
			UniqueClientId: model.UniqueClientId,
			MenuId: model.Menu,
			ActionId: model.Action,
			UserId: model.User,
			InsertStartDate: model.StartDate,
			InsertEndDate: model.EndDate
		};

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/ClientAuditLog/SearchClientAuditLog",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var data = responseData.Item || {};
				this.setState({ Data: data });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			}
		);
	}

	ClearClick() {
		this.setState({ model: { ...this.defaultModel } });
	}

	ValueChanged(name, value, data) {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "UniqueClientId") {
				model.User = "";
			}

			if (name == "Menu") {
				model.Action = "";
			}

			return { model };
		});
	}
	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}
	ExportClick = () => {
		var calogColumns = [];
		this.calogTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				calogColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: calogColumns, ExportSelectedColumns: calogColumns });
	}
	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };


		var jsonColList = [];

		var calogColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			calogColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(calogColumns);

		var mytitle = "Client Audit Log";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			   UniqueClientId: temp.UniqueClientId,
				MenuId:temp.Menu,
				ActionId: temp.Action,
				UserId : temp.User,
				InsertStartDate:temp.StartDate,
				InsertEndDate:temp.EndDate,
				jsonFile: temp.jsonFile
		};
		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/coreapi/v1.0/ClientAuditLog/GetClientAuditLogExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	showLoading = () => { this.setState({  isLoading: true }); }
	hideLoading = () => { this.setState({  isLoading: false }); }

	render() {
		const { Disabled } = this.props;
		const { model, vModel, Data, isLoading, alert } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
					{ Code: "Search", OnClick: this.SearchClick, Disabled: Disabled },
					{ Code: "Export",	OnClick: this.ExportClick,Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={x => `${x.UniqueClientId} - ${x.Name}`}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || !this.isBackOffice}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={"User_" + model.UniqueClientId}
												Name="User"
												LabelText="User"
												Url="/coreapi/v1.0/User/Search"
												Method="POST"
												Parameter={{ UniqueClientId: model.UniqueClientId }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={x => `${x.FirstName} - ${x.LastName}`}
												Value={model.User}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={4}>

										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="Menu"
												LabelText="Menu"
												Url="/coreapi/v1.0/Menu/GetAllOpenableMenus"
												Method="GET"
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={x => `${ResourceHelper.GetForMenu(x.MainParentMenuName)} -> ${ResourceHelper.GetForMenu(x.MenuName)}`}
												Sorted={{ Member: "Name" }}
												Value={model.Menu}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.Menu}
												Name="Action"
												LabelText="Action"
												Url="/coreapi/v1.0/MenuAction/GetByMenuId"
												Method="POST"
												Parameter={model.Menu}
												DataRoot="Item"
												KeyValueMember="ActionId"
												RenderItem={x => `${x.Action.Code} - ${x.Action.Description}`}
												Sorted={{ Member: "Code" }}
												Value={model.Action}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
										<GridItem xs={4}>

										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Utc
												Required
												IsInvalid={vModel.StartDate}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Utc
												Required
												IsInvalid={vModel.EndDate}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={Data}
									Columns={
										[
											{ Header: "Unique Client Id", accessor: "UniqueClientId" },
											{ Header: "Client Name", accessor: "ClientName" },
											{ Header: "User Name", accessor: "UserName" },
											{ Header: "Menu Name", accessor: "MenuName", Cell: row => `${ResourceHelper.GetForMenu(row.original.MainParentMenuName)} -> ${ResourceHelper.GetForMenu(row.value)}` },
											{ Header: "Action Name", accessor: "ActionName" },
											{ Header: "Status", accessor: "Status" },
											{ Header: "Date", accessor: "InsertDateTime", type: GridColumnType.DateTime }
										]
									}
									Sorted={[{ id: "Date", desc: true }]}
									PageSize={10}
									ShowPagination={true}
									ref={this.calogTable}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ClientAuditLog.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default ClientAuditLog;