import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import { Proxy, withArtifex } from "core";
import { ProfileIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ClientHelper from "core/ClientHelper";
import { GenericCheckInput, GenericTitle, GenericAlert, GenericIcon, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant.js";
import AccountComponent from "views/Components/AccountComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingComponent from "views/Components/LoadingComponent";

class LimitProfiles extends React.Component {
	constructor(props) {
		super(props);

		this.emptyProfile = {
			Id: 0,
			IsRecordValid: true,
			ProfileName: null,
			DetailList: null
		}

		this.emptyDetail = {
			Index: null,
			Id: 0,
			IsRecordValid: true,
			ProfileId: 0,
			TransactionLimit: 0.00,
			AccountLimit: 0.00,
			ReviewDailyLimit: 0.00,
			HourlyLimit: 0.00,
			WeeklyLimit: 0.00,
			WeekendLimit: 0.00,
			MonthlyLimit: 0.00,
			NonReviewDailyLimit: null
		}

		this.initialModel = {
			SelectedLimitProfile: Object.assign({}, this.emptyProfile),
			SelectedTransactionLimit: Object.assign({}, this.emptyDetail),
			ProfileList: [],
			DetailList: [],
		};

		this.limitProfileColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<ToolbarButton
							color="primary"
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							onClick={() => { this.handleSelectProfile(row.original, row.index); }}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 60
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			}
		];


		if (ClientHelper.IsClient()) {
			this.emptyProfile.UniqueClientId = ClientHelper.GetClientId();
		} else {
			this.emptyProfile.UniqueClientId = null;
		}

		this.limitProfileColumns.push({
			Header: "",
			Cell: row => (<div></div>)
		});

		this.state = {
			model: Object.assign({}, this.initialModel),
			isLoading: false
		};
	};

	toArray = (value) => {
		var returnList = [];
		if (value != null && value[0]) {
			for (const key in value) {
				if (value.hasOwnProperty(key)) {
					const element = value[key];
					returnList.push(element);
				}
			}
		}
		return returnList;
	};

	componentDidMount() {
		this.props.setAppLeftTitle("Limit Profiles");
		this.props.setAppCenterTitle("FX TRANSFER");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const emptyProfile = { ...this.emptyProfile };
		if (name === "ProfileName") {
			model.SelectedLimitProfile.ProfileName = newValue;
		} else if (name == "TransactionLimit") {
			model.SelectedTransactionLimit.TransactionLimit = newValue;
		} else if (name == "AccountLimit") {
			model.SelectedTransactionLimit.AccountLimit = newValue;
		} else if (name == "ReviewDailyLimit") {
			model.SelectedTransactionLimit.ReviewDailyLimit = newValue;
		} else if (name == "HourlyLimit") {
			model.SelectedTransactionLimit.HourlyLimit = newValue;
		} else if (name == "WeeklyLimit") {
			model.SelectedTransactionLimit.WeeklyLimit = newValue;
		} else if (name == "WeekendLimit") {
			model.SelectedTransactionLimit.WeekendLimit = newValue;
		} else if (name == "MonthlyLimit") {
			model.SelectedTransactionLimit.MonthlyLimit = newValue;
		} else if (name == "NonReviewDailyLimit") {
			model.SelectedTransactionLimit.NonReviewDailyLimit = newValue;
		} else if (name == "HasNonReviewDailyLimit") {
			model.SelectedTransactionLimit.NonReviewDailyLimit = newValue ? 0 : null;
		}
		// HasNonReviewDailyLimit
		this.setState({ model });
	};

	handleClear = () => {
		this.setState({
			model: {
				SelectedLimitProfile: Object.assign({}, this.emptyProfile),
				SelectedTransactionLimit: Object.assign({}, this.emptyDetail),
				ProfileList: [],
				DetailList: [],
			}
		});
	};

	handleSearch = () => {
		this.executeSearch();
	};

	handleSelectProfile = (data, index) => {
		const model = { ...this.state.model };

		var limitProfile = Object.assign({}, data);
		var limitItemList = Object.assign({}, data.DetailList);

		model.SelectedLimitProfile = limitProfile;
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.DetailList = this.toArray(limitItemList);

		this.setState({ model });
	};

	handleSelectDetail = (data, index) => {
		const model = { ...this.state.model };


		model.SelectedTransactionLimit = Object.assign({}, data);
		model.SelectedTransactionLimit.Index = index;

		this.setState({ model });
	};

	handleAddDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionLimit);

		if (!this.validateNewDetail(newItem, model.DetailList)) {
			return;
		}

		var temporary = [];
		if (newItem.Id == 0) {
			newItem.ProfileId = model.SelectedLimitProfile.Id;
			newItem.CardTransactionLimitProfile = model.SelectedLimitProfile;

			if (newItem.Index != null) {
				for (let index = 0; index < model.DetailList.length; index++) {
					const element = model.DetailList[index];
					temporary.push(index == newItem.Index ? newItem : element);
				}
			} else {
				model.DetailList.forEach((value, index, array) => { temporary.push(value); });
				temporary.push(newItem);
			}
		} else {
			model.DetailList.forEach(element => {
				if (element.Id == newItem.Id) {
					temporary.push(newItem);
				} else {
					temporary.push(element);
				}
			});
		}
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model });
	}

	handleDeleteDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionLimit);

		var temporary = [];
		if (newItem.Id != 0) {
			newItem.IsRecordValid = false;
			model.DetailList.forEach(element => {
				if (element.Id != newItem.Id) { temporary.push(element); }
			});
		} else {
			for (let index = 0; index < model.DetailList.length; index++) {
				const element = model.DetailList[index];
				if (index != newItem.Index) { temporary.push(element); }
			}
		}
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model });
	}

	handleClearDetail = () => {
		const model = { ...this.state.model };
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		this.setState({ model });
	}

	executeSearch = () => {
		const model = { ...this.state.model };

		const request = {
			ProfileName: model.SelectedLimitProfile.ProfileName
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/bankapi/v1.0/FxLimitProfile/SearchProfile",
			request,
			responseData => {
				// model.SelectedLimitProfile = Object.assign({}, this.emptyProfile);
				model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
				model.ProfileList = responseData.Item;
				model.DetailList = [];
				this.setState({ model });
			}
		);
	};

	executeSubmit = () => {
		if (!this.validateSubmit()) {
			return;
		}

		const model = { ...this.state.model };
		var callUrl = model.SelectedLimitProfile.Id != 0 ?
			"/bankapi/v1.0/FxLimitProfile/Update" :
			"/bankapi/v1.0/FxLimitProfile/Insert";

		var requestList = [];
		if (model.SelectedLimitProfile.Id != 0) {
			var originalDetail = model.ProfileList.find(x => x.Id == model.SelectedLimitProfile.Id).DetailList;
			var originalDetailList = this.toArray(originalDetail);

			model.DetailList.forEach(element => {
				// added list
				if (element.Id == 0) { requestList.push(element); }
				// modified list
				if (element.Id != 0) { requestList.push(element); }
			});

			// deleted list
			originalDetailList.forEach(element => {
				var relatedItem = model.DetailList.find(x => x.Id == element.Id);
				if (relatedItem == null) {
					var deletedItem = Object.assign({}, element);
					deletedItem.IsRecordValid = false;
					requestList.push(deletedItem);
				}
			});
		} else {
			model.DetailList.forEach(element => {
				// added list
				requestList.push(element);
			});
		}

		const request = {
			ProfileName: model.SelectedLimitProfile.ProfileName,
			Id: model.SelectedLimitProfile != null ? model.SelectedLimitProfile.Id : null,
			IsRecordValid: true,
			DetailList: requestList
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			callUrl,
			request,
			responseData => {
				model.SelectedLimitProfile = responseData.Item;
				model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
				model.ProfileList = [];
				model.ProfileList.push(responseData.Item);
				model.DetailList = responseData.Item.DetailList;
				this.setState({ model });
			}
		);
	};

	executeDelete = () => {
		const model = { ...this.state.model };

		const request = {
			ProfileName: model.SelectedLimitProfile.ProfileName,
			Id: model.SelectedLimitProfile != null ? model.SelectedLimitProfile.Id : null,
			IsRecordValid: false
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/CardTransactionLimitProfile/Delete",
			request,
			responseData => {
				this.setState({
					model: {
						SelectedLimitProfile: Object.assign({}, this.emptyProfile),
						SelectedTransactionLimit: Object.assign({}, this.emptyDetail),
						ProfileList: [],
						DetailList: [],
					}
				});
			}
		);
	};

	validateSubmit = () => {
		var errorList = [];
		const { model } = this.state;

		// Client is optional
		// if (model.UniqueClientId == null || model.UniqueClientId == 0) {
		// 	errorList.push("Client must be selected!");
		// }

		if (model.SelectedLimitProfile == null
			|| model.SelectedLimitProfile.ProfileName == null
			|| model.SelectedLimitProfile.ProfileName === "") {
			errorList.push("Please fill Profile Name field.");
		}

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	validateNewDetail = (newItem, allDetail) => {
		var errorList = [];
		const { model } = this.state;

		//model.SelectedTransactionLimit.TransactionCodeId
		//model.SelectedTransactionLimit.LimitRate
		//model.SelectedTransactionLimit.FlatAmount

		// if (newItem.SupplierId == null) {
		// 	errorList.push("Please fill in the Supplier field.");
		// }
		// if (newItem.CountryId == null) {
		// 	errorList.push("Please fill in the Country field.");
		// }
		// if (newItem.TransferMethodId == null) {
		// 	errorList.push("Please fill in the Transfer Source field.");
		// }
		// if (newItem.TransferSourceTypeId == null) {
		// 	errorList.push("Please fill in the Transfer Method field.");
		// }

		// if (newItem.Id == 0) {
		// 	if (newItem.Index != null) {
		// 		for (let index = 0; index < allDetail.length; index++) {
		// 			const element = allDetail[index];
		// 			if (index != newItem.Index &&
		// 				element.SupplierId == newItem.SupplierId &&
		// 				element.CountryId == newItem.CountryId &&
		// 				element.TransferMethodId == newItem.TransferMethodId &&
		// 				element.TransferSourceTypeId == newItem.TransferSourceTypeId) {
		// 				errorList.push("Please fill in fields with different value.");
		// 			}
		// 		}
		// 	} else {
		// 		allDetail.forEach((value, index, array) => {
		// 			if (value.SupplierId == newItem.SupplierId &&
		// 				value.CountryId == newItem.CountryId &&
		// 				value.TransferMethodId == newItem.TransferMethodId &&
		// 				value.TransferSourceTypeId == newItem.TransferSourceTypeId) {
		// 				errorList.push("Please fill in fields with different value.");
		// 			}
		// 		});
		// 	}
		// } else {
		// 	allDetail.forEach(element => {
		// 		if (element.Id != newItem.Id &&
		// 			element.SupplierId == newItem.SupplierId &&
		// 			element.CountryId == newItem.CountryId &&
		// 			element.TransferMethodId == newItem.TransferMethodId &&
		// 			element.TransferSourceTypeId == newItem.TransferSourceTypeId) {
		// 			errorList.push("Please fill in fields with different value.");
		// 		}
		// 	});
		// }

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, alert } = this.state;
		var emptyAccountModel = { FinancialInstitutionId: 0, AccountNumber: "" };
		const dialogBorderStyle = {
			borderColor: primaryColor,
			borderWidth: "7px",
			borderStyle: "Solid"
		};


		const limitItemColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<ToolbarButton
							color="primary"
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							onClick={() => { this.handleSelectDetail(row.original, row.index); }}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 50
			},
			{
				Header: "Profile Name",
				Cell: row => (<div>{model.SelectedLimitProfile.ProfileName}</div>),
			},
			{
				Header: "Transaction Limit",
				accessor: "TransactionLimit"
			},
			{
				Header: "Wallet Limit",
				accessor: "AccountLimit"
			},
			{
				Header: "Review Daily Limit",
				accessor: "ReviewDailyLimit"
			},
			{
				Header: "Hourly Limit",
				accessor: "HourlyLimit"
			},
			{
				Header: "Weekly Limit",
				accessor: "WeeklyLimit"
			},
			{
				Header: "Weekend Limit",
				accessor: "WeekendLimit"
			},
			{
				Header: "Monthly Limit",
				accessor: "MonthlyLimit"
			},
			{
				Header: "Non Review Daily Limit",
				accessor: "NonReviewDailyLimit"
			}
		];

		return (
			<div>


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.executeSubmit,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
							ValidationFunction: this.Validate
						},
						{
							Code: "Delete",
							OnClick: this.executeDelete,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
							ValidationFunction: this.Validate
						},
						{
							Code: "Search",
							OnClick: this.handleSearch,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
							ValidationFunction: this.Validate
						},
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GridItem>
											<GenericTextInput
												Name="ProfileName"
												LabelText="Profile Name"
												Value={model.SelectedLimitProfile.ProfileName}
												ValueChanged={this.handleChange} />
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid Data={model.ProfileList} Columns={this.limitProfileColumns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<GridContainer spacing={16} alignItems="center">
									<GridItem>
										<GenericTitle text={"Limit Definition"} />
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GenericNumberInput
											Name="TransactionLimit"
											LabelText="Transaction Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.TransactionLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={3}>
										<GenericNumberInput
											Name="AccountLimit"
											LabelText="Wallet Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.AccountLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={3}>
										<GenericNumberInput
											Name="ReviewDailyLimit"
											LabelText="Review Daily Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.ReviewDailyLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={6}>
										<GenericCheckInput
											Name="HasNonReviewDailyLimit"
											LabelText="Non-review Limit Definition"
											Value={model.SelectedTransactionLimit.NonReviewDailyLimit != null}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="HourlyLimit"
											LabelText="Hourly Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.HourlyLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="WeeklyLimit"
											LabelText="Weekly Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.WeeklyLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="WeekendLimit"
											LabelText="Weekend Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.WeekendLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="NonReviewDailyLimit"
											LabelText="Daily Transaction Limit(Non-Review)"
											LabelMd={8}
											Disabled={model.SelectedTransactionLimit.NonReviewDailyLimit == null}
											Value={model.SelectedTransactionLimit.NonReviewDailyLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="MonthlyLimit"
											LabelText="Monthly Limit"
											LabelMd={6}
											Value={model.SelectedTransactionLimit.MonthlyLimit}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={4}>
										{/* empty */}
									</GridItem>
									<GridItem xs={8}>
										{/* empty */}
									</GridItem>
									<GridItem xs={8} />
									<GridItem xs={4}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button
													size="sm"
													onClick={this.handleAddDetail}>ADD</Button>
												<Button size="sm"
													disabled={model.SelectedTransactionLimit.Index == null}
													onClick={this.handleDeleteDetail}>DELETE</Button>
												<Button size="sm"
													onClick={this.handleClearDetail}>CLEAR</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<GenericGrid Data={model.DetailList} Columns={limitItemColumns} HideButton />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm} />
			)
		});
	};


}

LimitProfiles.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(LimitProfiles, {});
