import withStyles from "@material-ui/core/styles/withStyles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
	grayRatingStyle: {
		background: "gray",
		padding: 10,
		border: "1px solid black",
		textAlign: "center"
	},
	orangeRatingStyle: {
		background: primaryColor,
		padding: 10,
		color: "white",
		borderRadius: "5px"
	}
});

class FundsMovement extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {};

		this.state = {
			list: [],
			cashPercentList: [
				{
					CashIn: 100,
					CashOut: 100,
					TotalCashVolume: 100,
					Onhold: 100,
					Fees: 100
				}
			],
			cashTotalList: [
				{
					CashIn: 0,
					CashOut: 0,
					TotalCashVolume: 0,
					Onhold: 0,
					Fees: 0
				}
			],
			model: this.initialModel,
			vModel: {},
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Funds Movement");
		this.props.setAppCenterTitle("REPORTS");
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "MonthlyFilterYear" && data)
			this.setState({ MonthlyFilterYear: parseInt(data.ParameterValue) });
		if (name == "MonthlyFilterMonth" && data)
			this.setState({ MonthlyFilterMonth: parseInt(data.ParameterValue2) });
		if (name == "AnnualFilterFromYear" && data)
			this.setState({ AnnualFilterFromYear: parseInt(data.ParameterValue) });
		if (name == "AnnualFilterFromMonth" && data)
			this.setState({ AnnualFilterFromMonth: parseInt(data.ParameterValue2) });
		if (name == "AnnualFilterToYear" && data)
			this.setState({ AnnualFilterToYear: parseInt(data.ParameterValue) });
		if (name == "AnnualFilterToMonth" && data)
			this.setState({ AnnualFilterToMonth: parseInt(data.ParameterValue2) });
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: null,list:[] });
	}

	HandleGetList = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Reporting/SearchFundsMovement",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			if (responseData.Item.List.length > 0) {
				this.setState(
					{
						model,
						list: responseData.Item.List,
						cashPercentList: responseData.Item.CashMovementPercentList,
						cashTotalList: responseData.Item.CashMovementTotalList
					}
				);
			}
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetElementPercentValue = (obj, path) => {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => {
			if (c) {
				if (typeof c[n] === "number")
					return `${c[n].toFixed(1)}%`;
				return c[n];
			}
		});
	}

	GetElementAmountValue = (obj, path) => {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => {
			if (c) {
				if (typeof c[n] === "number")
					return `$${c[n].toFixed(2)}`;
				return c[n];
			}
		});
	}

	Print = () => {
		if (this.state.list.length > 0)
			this.PrintFundMovementList();
	}

	PrintFundMovementList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.list.forEach(function (listElement) {
			var keyValueObject = {};
			[
				{
					Header: "Client Name",
					accessor: "MerchantName"
				},
				{
					Header: "Closing Balance",
					accessor: "ClosingBalance"
				},
				{
					Header: "Cash In",
					accessor: "CashIn"
				},
				{
					Header: "Cash Out",
					accessor: "CashOut"
				},
				{
					Header: "Total Cash Volume",
					accessor: "TotalCashVolume"
				},
				{
					Header: "Onhold",
					accessor: "Onhold"
				},
				{
					Header: "Fees",
					accessor: "Fees"
				},
			].forEach(function (gridColumnElement) {
				var valueItem = this.GetElementAmountValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Funds Movement Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "FundMovementReport.pdf";
				a.click();
				if (this.state.cashPercentList.length > 0)
					this.PrintCashPercentList();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	PrintCashPercentList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.cashPercentList.forEach(function (listElement) {
			var keyValueObject = {};
			[
				{
					Header: "Cash In",
					accessor: "CashIn"
				},
				{
					Header: "Cash Out",
					accessor: "CashOut"
				},
				{
					Header: "Total Cash Volume",
					accessor: "TotalCashVolume"
				},
				{
					Header: "Onhold",
					accessor: "Onhold"
				},
				{
					Header: "Fees",
					accessor: "Fees"
				},
			].forEach(function (gridColumnElement) {
				var valueItem = this.GetElementPercentValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Funds Movement Cash Percent Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "FundMovementCashPercentReport.pdf";
				a.click();
				if (this.state.cashTotalList.length > 0)
					this.PrintCashTotalList();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	PrintCashTotalList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.cashTotalList.forEach(function (listElement) {
			var keyValueObject = {};
			[
				{
					Header: "Cash In",
					accessor: "CashIn"
				},
				{
					Header: "Cash Out",
					accessor: "CashOut"
				},
				{
					Header: "Total Cash Volume",
					accessor: "TotalCashVolume"
				},
				{
					Header: "Onhold",
					accessor: "Onhold"
				},
				{
					Header: "Fees",
					accessor: "Fees"
				},
			].forEach(function (gridColumnElement) {
				var valueItem = this.GetElementAmountValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Funds Movement Cash Total Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "FundMovementCashTotalReport.pdf";
				a.click();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		if (!model.DateFilter && !model.MonthlyFilter && !model.AnnualFilter) {
			this.ShowMessage("warning", "No filter not selected", "Select any filter to continue.");
			return false;
		}

		if (!model.Top) {
			vModel.Top = true;
			messages.push("Top Filter must be select");
		} else {
			vModel.Top = false;
		}

		if (model.DateFilter) {
			if (!model.FromDate) {
				vModel.FromDate = true;
				messages.push("From Date must be select");
			} else {
				vModel.FromDate = false;
			}
			if (!model.ToDate) {
				vModel.ToDate = true;
				messages.push("To Date must be select");
			} else {
				vModel.ToDate = false;
			}
		}

		if (model.MonthlyFilter) {
			if (!model.MonthlyFilterMonth) {
				vModel.Month = true;
				messages.push("Month must be select");
			} else {
				vModel.Month = false;
			}
			if (!model.MonthlyFilterYear) {
				vModel.Year = true;
				messages.push("Year must be select");
			} else {
				vModel.Year = false;
			}
		}

		if (model.AnnualFilter) {
			if (!model.AnnualFilterFromMonth) {
				vModel.FromMonth = true;
				messages.push("From Month must be select");
			} else {
				vModel.FromMonth = false;
			}
			if (!model.AnnualFilterFromYear) {
				vModel.FromYear = true;
				messages.push("From Year must be select");
			} else {
				vModel.FromYear = false;
			}
			if (!model.AnnualFilterToMonth) {
				vModel.ToMonth = true;
				messages.push("To Month must be select");
			} else {
				vModel.ToMonth = false;
			}
			if (!model.AnnualFilterToYear) {
				vModel.ToYear = true;
				messages.push("To Year must be select");
			} else {
				vModel.ToYear = false;
			}
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isLoading, model, vModel, list, cashPercentList, cashTotalList } = this.state;
		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Print", OnClick: this.Print, Disabled: Disabled || list.length == 0 },
					{ Code: "CreateReport", OnClick: this.HandleGetList, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={2} style={{ marginLeft: 12, marginTop: 27 }}>
											<GenericSelectInput
												Name="Top"
												LabelText="Top"
												IsStatic={true}
												StaticData={[{ Value: 10, Text: "10" }, { Value: 20, Text: "20" }, { Value: 30, Text: "30" }, { Value: 40, Text: "40" }, { Value: 50, Text: "50" }, { Value: 100, Text: "100" }, { Value: 200, Text: "200" }]}
												KeyValueMember="Value"
												TextValueMember="Text"
												Value={model.Top}
												ValueChanged={this.HandleChange}
												All={true}
												Required
												IsInvalid={vModel.Top} />
										</GridItem>
										<GridItem xs={2}>
											<GridItem style={{ opacity: model.MonthlyFilter || model.AnnualFilter ? 0.4 : 1 }}>
												<GenericCheckInput
													Name="DateFilter"
													LabelText="Date"
													Value={model.DateFilter}
													ValueChanged={this.HandleChange}
													Disabled={model.MonthlyFilter || model.AnnualFilter} />
											</GridItem>
											<GridItem style={{ opacity: !model.DateFilter ? 0.4 : 1, marginTop: 4 }}>
												<GenericDateInput
													Name="FromDate"
													LabelText="From"
													Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
													ValueChanged={this.HandleChange}
													Disabled={!model.DateFilter}
													Required={model.DateFilter}
													IsInvalid={vModel.FromDate} />
											</GridItem>
											<GridItem style={{ opacity: !model.DateFilter ? 0.4 : 1 }}>
												<GenericDateInput
													Name="ToDate"
													LabelText="To"
													Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
													ValueChanged={this.HandleChange}
													Disabled={!model.DateFilter}
													Required={model.DateFilter}
													IsInvalid={vModel.ToDate} />
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<GridItem
												style={{ opacity: model.DateFilter || model.AnnualFilter ? 0.4 : 1 }}>
												<GenericCheckInput
													Name="MonthlyFilter"
													LabelText="Monthly Report"
													Value={model.MonthlyFilter}
													ValueChanged={this.HandleChange}
													Disabled={model.DateFilter || model.AnnualFilter} />
											</GridItem>
											<GridItem style={{ opacity: !model.MonthlyFilter ? 0.4 : 1 }}>
												<GenericSelectInput
													Name="MonthlyFilterMonth"
													LabelText="Month"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "Month"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={{
														Member: "ParameterValue2"
													}}
													Value={model.MonthlyFilterMonth}
													ValueChanged={this.HandleChange}
													Disabled={!model.MonthlyFilter}
													Required={model.MonthlyFilter}
													IsInvalid={vModel.Month} />
											</GridItem>
											<GridItem style={{ opacity: !model.MonthlyFilter ? 0.4 : 1 }}>
												<GenericSelectInput
													Name="MonthlyFilterYear"
													LabelText="Year"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "Year"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={{
														Member: "ParameterDesc",
														Desc: true
													}}
													Value={model.MonthlyFilterYear}
													ValueChanged={this.HandleChange}
													Disabled={!model.MonthlyFilter}
													Required={model.MonthlyFilter}
													IsInvalid={vModel.Year} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem
												style={{ opacity: model.DateFilter || model.MonthlyFilter ? 0.4 : 1 }}>
												<GenericCheckInput
													Name="AnnualFilter"
													LabelText="Annual Report"
													Value={model.AnnualFilter}
													ValueChanged={this.HandleChange}
													Disabled={model.DateFilter || model.MonthlyFilter} />
											</GridItem>
											<GridContainer>
												<GridItem xs={6} style={{ opacity: !model.AnnualFilter ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="AnnualFilterFromYear"
														LabelText="From"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{
															ParameterCode: "Year"
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Sorted={{
															Member: "ParameterDesc",
															Desc: true
														}}
														Value={model.AnnualFilterFromYear}
														ValueChanged={this.HandleChange}
														Disabled={!model.AnnualFilter}
														Required={model.AnnualFilter}
														IsInvalid={vModel.FromYear} />
												</GridItem>
												<GridItem xs={6} style={{ opacity: !model.AnnualFilter ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="AnnualFilterFromMonth"
														LabelMd={1}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{
															ParameterCode: "Month"
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Sorted={{
															Member: "ParameterValue2"
														}}
														Value={model.AnnualFilterFromMonth}
														ValueChanged={this.HandleChange}
														Disabled={!model.AnnualFilter}
														Required={model.AnnualFilter}
														IsInvalid={vModel.FromMonth} />
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={6} style={{ opacity: !model.AnnualFilter ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="AnnualFilterToYear"
														LabelText="To"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{
															ParameterCode: "Year"
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Sorted={{
															Member: "ParameterDesc",
															Desc: true
														}}
														Value={model.AnnualFilterToYear}
														ValueChanged={this.HandleChange}
														Disabled={!model.AnnualFilter}
														Required={model.AnnualFilter}
														IsInvalid={vModel.ToYear} />
												</GridItem>
												<GridItem xs={6} style={{ opacity: !model.AnnualFilter ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="AnnualFilterToMonth"
														LabelMd={1}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{
															ParameterCode: "Month"
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Sorted={{
															Member: "ParameterValue2"
														}}
														Value={model.AnnualFilterToMonth}
														ValueChanged={this.HandleChange}
														Disabled={!model.AnnualFilter}
														Required={model.AnnualFilter}
														IsInvalid={vModel.ToMonth} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<GenericTitle text={"FUNDS MOVEMENT"} />
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "16px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							className={`${classes.table} ${"-striped -highlight"}`}
							data={list}
							filterable={false}
							columns={[
								{
									Header: "NO",
									accessor: "No",
									sortable: false,
									filterable: false,
									width: 50,
									Cell: row => (
										<span>
											<strong>{row.index + 1}</strong>
										</span>
									),
								},
								{
									Header: "Client Name",
									accessor: "MerchantName",
									Cell: row => (
										<span>
											<strong>{row.value}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>TOTAL</strong>
										</span>
									)
								},
								{
									Header: "Closing Balance",
									accessor: "ClosingBalance",
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.ClosingBalance)))}</strong>
										</span>
									)
								},
								{
									Header: "Cash In",
									accessor: "CashIn",
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.CashIn)))}</strong>
										</span>
									)
								},
								{
									Header: "Cash Out",
									accessor: "CashOut",
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.CashOut)))}</strong>
										</span>
									)
								},
								{
									Header: "Total Cash Volume",
									accessor: "TotalCashVolume",
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.TotalCashVolume)))}</strong>
										</span>
									)
								},
								{
									Header: "Onhold",
									accessor: "Onhold",
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.Onhold)))}</strong>
										</span>
									)
								},
								{
									Header: "Fees",
									accessor: "Fees",
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									),
									Footer: (
										<span>
											<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.Fees)))}</strong>
										</span>
									)
								}
							]}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
						/>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "36px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							data={cashPercentList}
							filterable={false}
							columns={[
								{
									sortable: false,
									filterable: false,
									width: 470,
									Cell: row => (
										<span>
											<strong>{"% of Cash Movement related selected number of Wallets"}</strong>
										</span>
									)
								},
								{
									accessor: "CashIn",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatPercent(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "CashOut",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatPercent(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "TotalCashVolume",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatPercent(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "Onhold",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatPercent(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "Fees",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatPercent(row.value)}</strong>
										</span>
									)
								}
							]}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
						/>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "36px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							data={cashTotalList}
							filterable={false}
							columns={[
								{
									sortable: false,
									filterable: false,
									width: 470,
									Cell: row => (
										<span>
											<strong>{"Total Cash Movement for All Wallet"}</strong>
										</span>
									)
								},
								{
									accessor: "CashIn",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "CashOut",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "TotalCashVolume",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "Onhold",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									)
								},
								{
									accessor: "Fees",
									sortable: false,
									filterable: false,
									Cell: row => (
										<span>
											<strong>{Formatter.FormatMoney(row.value)}</strong>
										</span>
									)
								}
							]}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
						/>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

FundsMovement.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(FundsMovement);