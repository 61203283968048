import { AppBar, Hidden, Icon, Toolbar } from "@material-ui/core";
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import cx from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { GenericSelectInput } from "views/Components/Generic";
import { MenuCodes } from "views/Constants/Constant";

function Header({ ...props }) {
	const { classes, color, headerMenuRightList, menuList} = props;

	var userInfo = ClientHelper.GetUser();

	var FullName = userInfo ? `${userInfo.FirstName ?? ""} ${userInfo.LastName ?? ""}` : "";

	var Date = DateHelper.GetDateTimeLocal().format("MMMM Do YYYY");
	var Timezone = DateHelper.GetDateTimeLocal().format("[GMT] Z");
	var MstTimezone = DateHelper.GetDateTimeMst().format("[MST] Z");
	var EstTimeZone = DateHelper.GetDateTimeEst().format("[EST] Z");

	var CustomerNumber = ClientHelper.GetClientId();
	var ClientName = userInfo?.ClientName ?? "";

	var isBackoffice = ClientHelper.IsBackOffice();

	var storeCode = userInfo?.CustomerStore?.StoreCode ?? "";
	var storeName = userInfo?.CustomerStore?.StoreName ?? "";
	var storeArea = storeCode ? storeCode + " " + storeName : "";
    
	const appBarClasses = cx({
		[" " + classes[color]]: color
	});

	const logoClasses =
		classes.logo +
		" " +
		cx({
			[classes.whiteAfter]: true
		});

	const mainLogo = require("assets/img/" + window.MainLogo);
	var brand = (
		<div className={logoClasses} style={{display: "flex", alignItems:"center",justifyContent: "center",padding:0}}>
			<a href="/" className={classes.logoNormal}>
				<img style={{ width: 100, height: 34, padding: 3 }} src={mainLogo} alt="logo" className={classes.imgNormal} />
			</a>
		</div>
	);

	if (headerMenuRightList !== undefined) {
		var myProfileHeaderIsShow = false,
			apiSandBoxIsShow = false;
		for (var headerMenuRight of headerMenuRightList) {
			if (headerMenuRight.MenuCode == MenuCodes.Header_MyProfileHeader) {
				myProfileHeaderIsShow = headerMenuRight.HasAuthority;
			} else if (headerMenuRight.MenuCode == MenuCodes.Header_ApiSandBox) {
				apiSandBoxIsShow = headerMenuRight.HasAuthority;
			}
		}
	}

	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<div style={{ display: "flex" }}>
					{brand}
					<div className={classes.headerText}>
						Welcome back <span style={{ fontSize: "14px", fontWeight: "bold" }}>{FullName}</span><br />
						{CustomerNumber} <span style={{ fontWeight: "bold" }}>{ClientName}</span><br />
						{storeArea}
					</div>
				</div>
				<div className={classes.flex}>
				</div>
				<Hidden smDown implementation="css">
					<GridContainer className={classes.smallFont} direction="row" justify="space-between">
						<GridItem className={classes.gridItem}>
							<Icon>date_range</Icon>
						</GridItem>
						<GridItem className={classes.gridItem}>
							{Date}
							<br />
							{Timezone}
							<br />
							&nbsp;{EstTimeZone}
						</GridItem>
					</GridContainer>
				</Hidden>
				<div className={classes.flex}>
				</div>
				<div style={{ minWidth: "16%", color: "black" }}>
					<Hidden smDown implementation="css">
						<GridContainer className={classes.help} alignItems="stretch" direction="column" justify="space-between">
							<GridItem className={classes.gridItem}>
								<GenericSelectInput
									LabelMd={0}
									TitleLabel="Search Menu"
									StaticData={menuList}
									IsStatic={true}
									KeyValueMember="Path"
									TextValueMember="FullPath"
									ValueChanged={props.MenuValueChanged} />
							</GridItem>
						</GridContainer>
					</Hidden>
				</div>
				{
					userInfo && userInfo.CanBeClient
					&&
					(
						<div style={{ color: "white", cursor: "pointer" }} onClick={props.onChangeUserClick}>
							<Hidden smDown implementation="css">
								<GridContainer className={classes.help} alignItems="center" direction="row" justify="space-between">
									<GridItem className={classes.gridItem}>
										<Icon>swap_vert</Icon>
									</GridItem>
									<GridItem className={classes.gridItem}>
										Change User
									</GridItem>
								</GridContainer>
							</Hidden>
						</div>
					)
				}
				{
					!isBackoffice && myProfileHeaderIsShow !== undefined
						&& myProfileHeaderIsShow
						?
						(
							<Link style={{ color: "white" }} to="/MyBankAccountProfile">
								<Hidden smDown implementation="css">
									<GridContainer className={classes.help} alignItems="center" direction="row" justify="space-between">
										<GridItem className={classes.gridItem}>
											<Icon>person</Icon>
										</GridItem>
										<GridItem className={classes.gridItem}>
											My Profile
										</GridItem>
									</GridContainer>
								</Hidden>
							</Link>
						)
						:
						(<div style={{ display: "none" }}> </div>)
				}
				<div onClick={props.logout} style={{ color: "white", cursor: "pointer" }}>
					<GridContainer className={classes.help} alignItems="center" direction="row" justify="space-between">
						<GridItem className={classes.gridItem}>
							Sign-out
						</GridItem>
					</GridContainer>
				</div>
			</Toolbar>
		</AppBar>
	);
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	headerMenuRightList: PropTypes.array,
	menuList: PropTypes.array,
	onChangeUserClick: PropTypes.func,
	MenuValueChanged: PropTypes.func
};

export default withArtifex(Header, headerStyle);