import {
	primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const createEFTRecurringStyle = {
	tooltip: {
		background: primaryColor,
		color: "#ffffff",
		fontSize: 16
	}
};

export default createEFTRecurringStyle;
