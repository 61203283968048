import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class ClientAccountSetProgram extends React.Component {
	constructor(props) {
		super(props);
		this.UniqueClientId = ClientHelper.GetClientRowId();
		this.state = {
			vModel: {},
			model: {},
			isLoading: false,
			list: []
		};
		this.isBackoffice = ClientHelper.IsBackOffice();
		this.getClientId = ClientHelper.GetClientId();
		this.getClientRowId = ClientHelper.GetClientRowId();
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle) setAppLeftTitle("Change Program Default Wallet");
		if (setAppCenterTitle) setAppCenterTitle("CLIENT");

	}
	SelectedRowChange = (index) => {
		const model = this.state.list[index];
		if (model.Id == null || model.Id == undefined)
			this.props.showMessage("warning", "File Information", "FileInformationId can not be null");
		else {
			this.setState({ model, isLoading: false });

		}
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}
	Clear = () => {
		this.setState({ vModel: {}, model: {},list:[] });
	}

	showQuestionMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={"Delete this record!"} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.Delete()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	Validate = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const model = this.state.model;
		if (this.isBackoffice) {
			vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId <= 0;
			if (vModel.UniqueClientId) ErrorList.push("Unique Client must be defined !.");
		}

		vModel.ProgramId = model.ProgramId == null || model.ProgramId <= 0;
		if (vModel.ProgramId) ErrorList.push("Program must be defined !.");
		vModel.AccountId = model.AccountId == null || model.AccountId <= 0;
		if (vModel.AccountId) ErrorList.push("Wallet must be defined !.");

		if (ErrorList.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}
	HandleDeleteButton = model => async () => {
		let confirmResult = await this.props.showConfirmMessage("warning", "Warning", "Delete this record !. Are you sure?");
		if (confirmResult) {
			this.Delete(model);
		}
	}
	Delete = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.props.showMessage("error", "Error", "Record must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Account/DeleteAccountProgramMap", { Id: this.state.model.Id },
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({
					isLoading: false,
					alert: null
				});

				this.props.showMessage("success", "Success", "Operation Successfully Completed!");
				this.Clear();

			},
			error => {
				this.setState({
					isLoading: false,
					alert: null
				});
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	Submit = () => {
		if (!this.Validate()) return;
		this.setState({ isLoading: true });
		var model = this.state.model;
		if (!this.isBackoffice) {
			model.UniqueClientId = this.getClientRowId;
			this.setState({ model });
		}
		Proxy.POST(
			"/bankapi/v1.0/Account/InsertOrUpdateAccountProgramMap",
			model,
			responseData => {
				this.setState({ isLoading: true });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.setState({ isLoading: false });
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Success", "Operation Successfully Completed!");
				this.Clear();

			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	Search = () => {
		this.setState({ isLoading: true });
		var model = this.state.model;
		if (!this.isBackoffice) {
			model.UniqueClientId = this.getClientRowId;
			this.setState({ model });
		}
		if(model.UniqueClientId == null){
			model.UniqueClientId = 0;
		}
		if(model.AccountId == null){
			model.AccountId = 0;
		}
		if(model.ProgramId == null){
			model.ProgramId = 0;
		}

		Proxy.POST(
			"/bankapi/v1.0/Account/SearchAccountProgramMap",
			model,
			responseData => {
				this.setState({ isLoading: true });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.setState({ isLoading: false });
					this.setState({ list: [] });
					return;
				}
				if (responseData.Item && responseData.Item.length > 0) {
					this.setState({ isLoading: false });
					this.setState({ list: responseData.Item });
				}
				else {
					//this.props.showMessage("warning", "Warning", "Any Record Not Found!");
					this.setState({ list: [], isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false, list: [] });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	render() {
		const { Disabled } = this.props;
		const { model, vModel, list } = this.state;
		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				{this.state.alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: () => this.Clear(), Disabled: Disabled || model == {} },
							{ Code: "Search", OnClick: () => this.Search(), Disabled: Disabled || model.Id != null },
							{ Code: "Submit", OnClick: () => this.Submit(), Disabled: Disabled || model.Id != null },
							{ Code: "Delete", OnClick: () => this.showQuestionMessage(), Disabled: Disabled || model.Id == null },
							{ Code: "Update", OnClick: () => this.Submit(), Disabled: Disabled || model.Id == null },

						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card className="no-radius">
							<CardBody>
								<br />
								<br />
								<br />
								<GridContainer spacing={16}>
									{this.isBackoffice && (<GridItem xs={4}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client Name"
											Value={model.UniqueClientId || null}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="UniqueClientId"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Method="POST"
											RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
											Parameter={{ UniqueClientId: !this.isBackoffice ? this.getClientId : null }}
											Required
											IsInvalid={vModel.UniqueClientId}
											Disabled={model.Id > 0}
										/>
									</GridItem>)}
									<GridItem xs={4}>
										<GenericSelectInput
											Name="ProgramId"
											LabelText="Program"
											Value={model.ProgramId || ""}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="Description"
											Url="/bankapi/v1.0/Bank/ValidBankProgramsForClientAccountProgramMap"
											Method="GET"
											Required
											IsInvalid={vModel.ProgramId}
											Disabled={model.Id > 0}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											key={model.UniqueClientId}
											Name="AccountId"
											LabelText="Wallet"
											Value={model.AccountId || ""}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											RenderItem={d => `${d.AccountNumber} - ${d.AccountName}`}
											Url="/bankapi/v1.0/Account/ClientAccount"
											Method="POST"
											Parameter={{ UniqueClientId: !this.isBackoffice ? this.getClientRowId : model.UniqueClientId }}
											Required
											IsInvalid={vModel.AccountId}

										/>
									</GridItem>
								</GridContainer>
								<br />
								<br />
								<br />
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={list}
											Columns={[
												// {
												// 	width: 100,
												// 	Header: "Actions",
												// 	accessor: "ED",
												// 	Cell: row => (
												// 		<div>
												// 			<GridButton
												// 				tooltip="Edit"
												// 				Icon={EditIcon}
												// 				Disabled={Disabled}
												// 				OnClick={() => this.setState({ model: { ...row.original } })} />
												// 		</div>
												// 	),
												// 	sortable: false,
												// 	filterable: false,
												// },
												{
													Header: "Unique Client Id",
													accessor: "UniqueClient.UniqueClientId",
													show: this.isBackoffice

												},
												{
													Header: "Client Name",
													accessor: "UniqueClient.ClientName",
													show: this.isBackoffice
												},
												{
													Header: "Program",
													accessor: "Program.Description",

												},
												{
													Header: "Wallet Name",
													accessor: "Account.AccountName"
												},
												{
													Header: "Wallet Number",
													accessor: "Account.AccountNumber"
												}

											]}
											RowSelected={index => {
												this.SelectedRowChange(index);
											}}
										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>


					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ClientAccountSetProgram.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default ClientAccountSetProgram;