// @ts-nocheck
import ScorcardTableImage from "assets/img/los-scorecard-value-table.png";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

const initialState = {
	model: {},
	vModel: {},
	isBrowse: false,
	parameterDatalist: [],
	isLoading: false
};
const url = "/losapi/v1.0/ApprovalParameter/";

class ApprovalParameter extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.LOSScoreCardValuesFlexSet = {
			FlexType: "LOSScoreCardValues"
		};
		this.LOSScoreCardValuesActionsFlexSet = {
			FlexType: "LOSScoreCardValuesActions"
		};
		this.LOSScoreCardValuesProfilesFlexSet = {
			FlexType: "LOSScoreCardValuesProfiles"
		};
	}

	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Approval Parameters");
		this
			.props
			.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false, state: this.initialState });
		window
			.location
			.reload();

	}
	HideAlert = () => {
		this.setState({ alert: null });
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.model;
		const { vModel } = this.state;

		if (data == null) {
			ErrorList.push("Approval parameters not defined !.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.ScoreCardValueId == null || data.ScoreCardValueId == 0) {
			vModel.ScoreCardValueId = true;
			ErrorList.push("Scorecard value must be defined.");
		}
		else {
			vModel.ScoreCardValueId = false;
		}
		if (data.LoanActionId == null || data.LoanActionId == 0) {
			vModel.LoanActionId = true;
			ErrorList.push("Action must be defined.");
		}
		else {
			vModel.LoanActionId = false;
		}
		if (data.CollectionProfileId == null || data.LoanCollectionProfileIdActionId == 0) {
			vModel.CollectionProfileId = true;
			ErrorList.push("Profile must be defined.");
		}
		else {
			vModel.CollectionProfileId = false;
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.Id > 0) {
			this.ActionUpdate();
		} else {
			this.ActionSubmit();
		}
		this.setState({ vModel });

	}

	ActionSubmit = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const model = this.state.model;

		this.Post(model, "Insert");

	}
	ActionSearch = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		this.Post(data, "Search");

	}
	ActionUpdate = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;

		this.Post(data, "Update");

	}
	ActionDelete = () => {
		var ErrorList = [];
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		if (data == null || data.Id == null || data.ScoreCardValueId == 0) {
			ErrorList.push("Any approval parameter not selected.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Select A Record From Parameter List", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.Post(data, "Delete");

	}
	ActionClear = () => {
		this.setState({ model: {}, parameterDatalist: [], alert: null, isLoading: false });
	}

	Post = (model, methodName) => {
		this.setState({ alert: null });
		this.setState({ isLoading: true });
		Proxy.POST((url + methodName), model, (responseData) => {
			switch (methodName) {
				case "Search":
					if (responseData != null && responseData.Item != null) {
						this.setState({ parameterDatalist: responseData.Item, isLoading: false });
					}
					break;
				case "Delete":
					if (responseData != null && responseData.Item != null) {
						this.ShowMessage("success", "Success", "Approval Parameter Deleted");
						this.ActionClear();
						this.setState({ isLoading: false });
					}
					this.setState({ alert: null, isLoading: false, state: this.initialState });
					this.ActionSearch();
					break;
				default:
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						this.setState({ isLoading: false });
						return;
					} else if (responseData != null && responseData.Item != null) {
						this.ShowMessage("success", "Success", "Approval Parameter Saved");
						this.setState({ model: responseData.Item, isLoading: false });
						this.ActionClear();
						this.ActionSearch();
					} else {
						this.setState({ alert: null, isLoading: false, state: this.initialState });
					}
					break;

			}
		}, this.ErrorCallback);

	}

	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};
		model[name] = newValue;
		this.setState({ model });
	}
	RowSelected = (index) => {
		const list = this.state.parameterDatalist;
		this.setState({ model: list[index] });
	}
	GetRenderItemScorecardValue = (d) => {

		return d.ParameterValue + " - " + d.ParameterValue2;
	}


	render() {
		const { model, alert, isLoading, vModel } = this.state;
		var ForInsert = !model.Id || model.Id <= 0;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				<GridItem xs={12}>
					<ButtonToolbar
						ButtonList={[
							{
								Code: "Submit",
								OnClick: this.OperationCheck,
								Disabled: !ForInsert
							}, {
								Code: "Search",
								OnClick: this.ActionSearch,
								Disabled: false
							}, {
								Code: "Update",
								OnClick: this.OperationCheck,
								Disabled: ForInsert
							}, {
								Code: "Delete",
								OnClick: this.ActionDelete,
								Disabled: ForInsert
							}, {
								Code: "Clear",
								OnClick: this.ActionClear,
								Disabled: false
							}
						]}
						menuId={this.props.menuId}
						ApprovalData={this.props.ApprovalData}
						IsPopUp={false} />

				</GridItem>
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true}>
									<GridContainer justify="flex-start" alignItems="flex-start" direction="row">
										<GridContainer direction="row">
											<GridItem>
												<GridContainer direction="column">
													<GridItem>
														<img
															style={{
																height: "50px",
																float: "right"
															}}
															src={ScorcardTableImage}
															alt=""></img>
													</GridItem>
													<GridItem><br /></GridItem>
													<GridItem></GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={3}>
												<GenericSelectInput
													key={"ScoreCardValueId"}
													Name="ScoreCardValueId"
													LabelText="Score Card Value"
													LabelMd={4}
													Method="POST"
													Url="/losapi/v1.0/ApprovalParameter/ScurecardValueSearch"
													Parameter={this.LOSScoreCardValuesFlexSet}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FlexValue4"
													Required
													IsInvalid={vModel.ScoreCardValueId}
													Value={model.ScoreCardValueId}
													ValueChanged={this.HandleChange}
													Disabled={false}
												/>
											</GridItem>
											<GridItem xs={3}>

												<GenericSelectInput
													key={"LoanActionId"}
													Name="LoanActionId"
													LabelText="Action"
													LabelMd={4}
													Method="POST"
													Url="/losapi/v1.0/ApprovalParameter/ScurecardValueSearch"
													Parameter={this.LOSScoreCardValuesActionsFlexSet}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FlexDesc"
													Required
													IsInvalid={vModel.LoanActionId}
													Value={model.LoanActionId}
													ValueChanged={this.HandleChange}
													Disabled={false}
												/>

											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													key={"CollectionProfileId"}
													Name="CollectionProfileId"
													LabelText="Profile"
													LabelMd={4}
													Method="POST"
													Url="/losapi/v1.0/ApprovalParameter/ScurecardValueSearch"
													Parameter={this.LOSScoreCardValuesProfilesFlexSet}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FlexDesc"
													Required
													IsInvalid={vModel.CollectionProfileId}
													Value={model.CollectionProfileId}
													ValueChanged={this.HandleChange}
													Disabled={false}
												/>
											</GridItem>
										</GridContainer>
									</GridContainer>

								</GenericExpansionPanel>
							</CardBody>

						</Card>
						<Card className="no-radius">

							<CardBody>
								<GenericExpansionPanel Title="Parameter List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.parameterDatalist}
												Columns
												={[
													{
														Header: "Profile Name",
														accessor: "CollectionProfile.FlexDesc"
													}, {
														Header: "Score Card Value",
														accessor: "ScoreCardValue.FlexValue4"
													}, {
														Header: "Action",
														accessor: "LoanAction.FlexDesc"
													}
												]}
												RowSelected={this.RowSelected} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div>
		);
	}

}
ApprovalParameter.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ApprovalParameter, {});