import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DueTermOfStatement } from "views/Constants/Constant.js";
import { ProgramCodes } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";

const LOSLoanTranferType = {
	Swift: "0",
	InteracteTransfer: "1",
	LinkedAccount: "2"
};
const ClientParamObj = {
	ProgramCode: ProgramCodes.Lender,
	UniqueClientId: undefined
};

class LoanApprovalFundingMethod extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			localModel: {},
			vLocalModel: {},
			ReadOnly: true,
			isSwfit: false,
			isETransfer: false,
			isLinkedAccount: false
		};
		this.MaxLen = { maxLength: 30 };
		this.loanTransferTypeParam = {};
		this.parameterLoanTransferType = { ParameterCode: "LOSLoanTranferType" };
		this.LMSPaymentDayParameterCode = { ParameterCode: "LMSPaymentDay" };
		this.maxLength20 = { maxLength: 20 };
		this.maxLength50 = { maxLength: 50 };
		this.marginLeft2 = { marginLeft: 2 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.emptyObject = {};

		this.GetClientParamObj = this.GetClientParamObj.bind(this);
	}

	componentDidMount() {


	}
	HandleChangeLoanTransfer = (name, newValue, data) => {
		if (name == "LoanTransferTypeId" && data != null) {
			if (data.ParameterValue == LOSLoanTranferType.Swift) {
				this.setState({ loanTransferTypeParam: data, isSwfit: true, isETransfer: false, isLinkedAccount: false });
			}
			if (data.ParameterValue == LOSLoanTranferType.InteracteTransfer) {
				this.setState({ loanTransferTypeParam: data, isETransfer: true, isSwfit: false, isLinkedAccount: false });
			}
			if (data.ParameterValue == LOSLoanTranferType.LinkedAccount) {
				this.setState({ loanTransferTypeParam: data, isLinkedAccount: true, isETransfer: false, isSwfit: false });
			}
		}
		const model = { ...this.state.localModel };
		model[name] = newValue;
		this.setState({ localModel: model });
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	TransferService = () => {

	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	HandleTransferLoan = () => {
		var model = this.props.model;
		const { vLocalModel } = this.state;
		if (!model.Id) {
			this.ShowMessage("warning", "Warning", "Select application to continue.");
			return false;
		}

		if (!model.TransferDate) {
			this.ShowMessage("warning", "Warning", "Enter transfer date to continue.");
			vLocalModel.TransferDate = true;
			this.setState({ vLocalModel });
			return false;
		} else {
			vLocalModel.TransferDate = false;
		}

		if (!model.LoanTransferTypeId) {
			this.ShowMessage("warning", "Warning", "Enter loan transfer type to continue.");
			vLocalModel.LoanTransferTypeId = true;
			this.setState({ vLocalModel });
			return false;
		} else {
			vLocalModel.LoanTransferTypeId = false;
		}

		if (!model.ApprovedAmount) {
			this.ShowMessage("warning", "Warning", "Enter amount to continue.");
			vLocalModel.LoanTransferTypeId = true;
			this.setState({ vLocalModel });
			return false;
		} else {
			vLocalModel.LoanTransferTypeId = false;
		}

		if (this.state.loanTransferTypeParam.ParameterValue == LOSLoanTranferType.InteracteTransfer) {
			if (!model.SecurityQuestionId) {
				this.ShowMessage("warning", "Warning", "Enter security question continue.");
				vLocalModel.SecurityQuestionId = true;
				this.setState({ vLocalModel });
				return false;
			} else {
				vLocalModel.SecurityQuestionId = false;
			}

			if (!model.SecurityQuestionAnswer) {
				this.ShowMessage("warning", "Warning", "Enter security answer continue.");
				vLocalModel.SecurityQuestionAnswer = true;
				this.setState({ vLocalModel });
				return false;
			} else {
				vLocalModel.SecurityQuestionAnswer = false;
			}
		}

		this.setState({ vLocalModel, isLoading: true });


		Proxy.POST(
			"/lmsapi/v1.0/TransferLoan/Transfer",
			{
				ApplicationId: model.Id,
				TransferDate: model.TransferDate,
				LoanTransferTypeId: model.LoanTransferTypeId,
				Amount: model.ApprovedAmount,
				SecurityQuestionId: model.SecurityQuestionId != undefined ? model.SecurityQuestionId : null,
				SecurityQuestionAnswer: model.SecurityQuestionAnswer != undefined ? model.SecurityQuestionAnswer : null,
				SecurityQuestionText: model.SecurityQuestionText != undefined ? model.SecurityQuestionText : null,
			},
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Money Transfered To Applicant, TransactionReference: " + responseData.Item.ReferenceNumber);
				this.props.TransferLoanOp(responseData.Item.ReferenceNumber);
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	GetClientParamObj() {
		ClientParamObj.ProgramCode = ProgramCodes.Bank_Programs_e_Transfer;
		ClientParamObj.UniqueClientId = ClientHelper.GetClientRowId();
		return ClientParamObj;
	}


	render() {
		const { model, vModel, isUserAdmin, Disabled, CheckerDisabled, ParentTabIndex } = this.props;
		var isSwfit = this.state.isSwfit;
		var isETransfer = this.state.isETransfer;
		var isLinkedAccount = this.state.isLinkedAccount;
		if (model.LoanTransferType != undefined) {
			if (model.LoanTransferType.Id == model.LoanTransferTypeId) {
				if (model.LoanTransferType.ParameterValue == LOSLoanTranferType.Swift) {
					isSwfit = true;
					isETransfer = false;
					isLinkedAccount = false;
				}
				if (model.LoanTransferType.ParameterValue == LOSLoanTranferType.InteracteTransfer) {
					isSwfit = false;
					isETransfer = true;
					isLinkedAccount = false;
				}
				if (model.LoanTransferType.ParameterValue == LOSLoanTranferType.LinkedAccount) {
					isSwfit = false;
					isETransfer = false;
					isLinkedAccount = true;
				}
			}
		}
		return (
			< div >
				{ParentTabIndex == 2 &&
					<GridContainer>
						{this.state.alert}


						<LoadingComponent Show={this.state.isLoading} />

						<GridItem xs={4}>
							<div>
								<GridItem>
									<GenericSelectInput
										Name="LoanTransferTypeId"
										LabelText="Loan Transfer Type"
										Method="POST"
										//IsStatic={true}
										//StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterLoanTransferType.ParameterCode)}
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterLoanTransferType}
										DataRoot="Item"
										KeyValueMember="Id"
										LabelMd={5}
										TextValueMember="ParameterDesc"
										Value={model == null ? "" : model.LoanTransferTypeId || ""}
										ValueChanged={(name, newValue, data) => { this.props.HandleChange(name, newValue, data); this.HandleChangeLoanTransfer(name, newValue, data); }}
										Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled}
										Required
										IsInvalid={this.state.vLocalModel.LoanTransferTypeId}
									/>
									<GenericDateInput
										Name="RequestedTransferDate"
										IsFuture={true}
										LabelMd={5}
										LabelText="Requested Transfer Date"
										Value={model.RequestedTransferDate ? DateHelper.ToDateInputValue(model.RequestedTransferDate) : ""}
										ValueChanged={this.props.HandleChange}
										IncludeTime={false}
										Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled} />
								</GridItem>
							</div>
						</GridItem>
						<GridItem xs={3}>
							<GenericDateInput
								Name="FirstPaymentDate"
								IsFuture={true}
								LabelMd={5}
								LabelText="First Payment Date"
								Value={model.FirstPaymentDate ? DateHelper.ToDateInputValue(model.FirstPaymentDate) : ""}
								ValueChanged={this.props.HandleChange}
								IncludeTime={false}
								Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled}
								Required
								IsInvalid={vModel.FirstPaymentDate} />
						</GridItem>
						{this.props.Title == "Secure Card" && isUserAdmin != true ?
							<GridItem xs={4}>
								<div>
									<GridItem>
										<GenericSelectInput
											Name="PaybackMethodId"
											LabelText="Payback Method"
											Method="POST"
											IsStatic={true}
											StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterLoanTransferType.ParameterCode)}
											// Url="/coreapi/v1.0/Parameter/Search"
											// Parameter={this.parameterLoanTransferType}
											DataRoot="Item"
											LabelMd={5}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model == null ? "" : model.PaybackMethodId || ""}
											ValueChanged={this.props.HandleChange}
											Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled}
										/>
									</GridItem>
									<GridItem>
										{/* 	<AccountComponent
										TitleLabel=""
										TitleLabelBranch=""
										LabelText="Linked Wallet"
										IsDCBankAccount={false}
										Model={this.props.model}
										Disabled={Disabled}
										ValueChanged={this.props.HandleChange} /> */}
										<GenericNumberInput
											Name="TransferAmount"
											Prefix="$"
											LabelText="Transfer Amount"
											LabelMd={5}
											Value={model == null ? "" : model.ApprovedAmount || ""}
											Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled} />
										<GenericDateInput
											Name="TransferDate"
											IsFuture={true}
											LabelText="Transfer Date"
											Value={model.TransferDate ? DateHelper.ToDateInputValue(model.TransferDate) : ""}
											ValueChanged={this.props.HandleChange}
											IncludeTime={false}
											LabelMd={5}
											Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled}
											Required
											IsInvalid={this.state.vLocalModel.TransferDate} />
									</GridItem>
								</div>
							</GridItem> : ""
						}
						<GridItem xs={3}>
							{isUserAdmin == false ?
								"" :
								isUserAdmin == true && this.props.model != null && isSwfit == true ?
									<SwiftComponent model={this.props.model} inputProps={this.ParameterMaxLength30} changeEvent={this.HandleChangeLoanTransfer} props={this.props} Disabled={isUserAdmin == true ? false : CheckerDisabled == true ? true : Disabled} vModel={vModel} ></SwiftComponent>
									: isUserAdmin == true && this.props.model != null && isETransfer == true ?
										<ETransferComponent model={this.props.model} inputProps={this.ParameterMaxLength30} changeEvent={this.HandleChangeLoanTransfer} Parameter={this.parameterLoanTransferType} props={this.props} Disabled={isUserAdmin == true ? false : CheckerDisabled == true ? true : Disabled} isUserAdmin={isUserAdmin} CheckerDisabled={CheckerDisabled} vModel={vModel} getClientParamObj={this.GetClientParamObj}></ETransferComponent>
										: isUserAdmin == true && this.props.model != null && isLinkedAccount == true ?
											<GridItem>
												{/* <AccountComponent
												TitleLabel=""
												TitleLabelBranch=""
												LabelText="Linked Wallet"
												IsDCBankAccount={false}
												Model={this.props.model}
												Disabled={CheckerDisabled == true ? true : Disabled}
												ValueChanged={(name, newValue, data) => (this.props.HandleChange(name, newValue, data), this.HandleChangeLoanTransfer(name, newValue, data))} />
											*/}
												<GenericNumberInput
													Name="ApprovedAmount"
													Prefix="$"
													LabelText="Transfer Amount"
													ValueChanged={this.props.HandleChange}

													Value={model == null ? "" : model.ApprovedAmount || ""}
													Disabled={true}
													Required
													IsInvalid={this.state.vLocalModel.ApprovedAmount} />
												<GenericDateInput
													Name="TransferDate"
													IsFuture={true}
													LabelText="Transfer Date"
													Value={model.TransferDate ? DateHelper.ToDateInputValue(model.TransferDate) : ""}
													ValueChanged={this.props.HandleChange}
													IncludeTime={false}
													Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled}
													Required
													IsInvalid={this.state.vLocalModel.TransferDate} />
											</GridItem>
											: ""
							}
						</GridItem>
						<GridItem xs={1}>
							{isUserAdmin == true ?
								<Button size="sm" color="black" onClick={this.HandleTransferLoan}>Transfer Loan</Button> : ""}

							{/* <GridItem>
								<Button
									justIcon
									simple
									size="sm"
									tooltip="Client Detail"
									onClick={() => {
										this.HandleTransferLoan();
									}}
								>
									<img src={ETransferIcon} />
								</Button>
							</GridItem> */}
						</GridItem>
					</GridContainer>
				}
				{(ParentTabIndex == 0 || ParentTabIndex == 1)
					&&
					<GridContainer
						direction="column"
						justify="flex-start"
						alignItems="stretch">
						{this.state.alert}

						<LoadingComponent Show={this.state.isLoading} />

						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									LabelMd={6}
									Sorted={{ Member: "Id" }}
									Name="StatementDayId"
									LabelText=" Statement Day of Month"
									Method="POST"
									//IsStatic={true}
									//StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.LMSPaymentDayParameterCode.ParameterCode)}
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.LMSPaymentDayParameterCode}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={model.StatementDayId}
									ValueChanged={this.props.HandleChange}
									Disabled={isUserAdmin == true ? Disabled : CheckerDisabled == true ? true : Disabled}
									Required
									IsInvalid={vModel.StatementDayId} />
							</GridItem>
							<GridItem xs={2}>
										<Button
											disabled={!model.Id}
											size="sm"
											color="black"
											onClick={this.props.UpdateStatementDayofMonth}>
											Update Day of Month
										</Button>
									</GridItem>
					
							<GridItem xs={4}>
								<GenericSelectInput
									LabelMd={6}
									IsStatic={true}
									StaticData={DueTermOfStatement}
									Name="LastPaymentDateAfter"
									Value={model.LastPaymentDateAfter}
									LabelText="Due Term of Statement"
									ValueChanged={this.props.HandleChange}
									KeyValueMember="Id"
									TextValueMember="Text"
									Required
									IsInvalid={vModel.LastPaymentDateAfter} />
							</GridItem>
						</GridContainer>
					</GridContainer>}
			</div >
		);
	}
}
const SwiftComponent = ({ model, Disabled, props, inputProps, changeEvent, vModel }) => (
	<div>
		<GridItem>
			<GenericTextInput
				Name="BankSwiftName"
				LabelText="Bank Name"
				IsText
				Value={model == null ? "" : model.BankSwiftName || ""}
				ValueChanged={(name, newValue, data) => { props.HandleChange(name, newValue, data); changeEvent(name, newValue, data); }}
				Disabled={Disabled}
				inputProps={inputProps} />
		</GridItem>
		<GridItem>
			<GenericTextInput
				Name="BankSwiftCode"
				LabelText="Swift Code"
				Value={model == null ? "" : model.BankSwiftCode || ""}
				ValueChanged={(name, newValue, data) => { props.HandleChange(name, newValue, data); changeEvent(name, newValue, data); }}
				Disabled={Disabled}
				inputProps={inputProps} />
		</GridItem>
		<GridItem>
			<GenericTextInput
				Name="BankSwiftBranch"
				LabelText="Bank Branch"
				Value={model == null ? "" : model.BankSwiftBranch || ""}
				ValueChanged={(name, newValue, data) => { props.HandleChange(name, newValue, data); changeEvent(name, newValue, data); }}
				Disabled={Disabled}
				inputProps={inputProps} />
		</GridItem>
		<GridItem>
			<GenericNumberInput
				NoFormatting={true}
				Name="BankSwiftAccount"
				LabelText="Payment Wallet"
				Value={model == null ? "" : model.BankSwiftAccount || ""}
				ValueChanged={(name, newValue, data) => { props.HandleChange(name, newValue, data); changeEvent(name, newValue, data); }}
				MaxLength={20}
				Disabled={Disabled} />
		</GridItem>
		<GridItem>
			<GenericDateInput
				Name="TransferDate"
				IsFuture={true}
				LabelText="Transfer Date"
				Value={model.TransferDate ? DateHelper.ToDateInputValue(model.TransferDate) : ""}
				ValueChanged={(name, newValue, data) => { props.HandleChange(name, newValue, data); changeEvent(name, newValue, data); }}
				IncludeTime={false}
				Disabled={Disabled}
				Required
				IsInvalid={vModel.TransferDate} />
		</GridItem>
	</div>
);
const ETransferComponent = ({ model, Disabled, props, inputProps, Parameter, changeEvent, isUserAdmin, CheckerDisabled, vModel, getClientParamObj }) => (
	<div>
		< GridItem>
			{/* <GenericSelectInput
				Name="ETransferTransferTypeId"
				LabelText="Transfer Type"
				Method="POST"
				Url="/coreapi/v1.0/Parameter/Search"
				Parameter={Parameter}
				DataRoot="Item"
				KeyValueMember="Id"
				LabelMd={5}
				TextValueMember="ParameterDesc"
				Value={model == null ? "" : model.LoanTransferTypeId || ""}
				ValueChanged={(name, newValue, data) => (props.HandleChange(name, newValue, data), changeEvent(name, newValue, data))}
				Disabled={Disabled}
			/> */}
			{/* <GenericNumberInput
				Name="ApprovedAmount"
				Prefix="$"
				LabelText="Transfer Amount"
				LabelMd={5}
				Value={model == null ? "" : model.ApprovedAmount || ""}
				ValueChanged={(name, newValue, data) => (props.HandleChange(name, newValue, data), changeEvent(name, newValue, data))}
				Disabled={Disabled} /> */}
			<GenericNumberInput
				Name="ApprovedAmount"
				Prefix="$"
				LabelText="Transfer Amount"
				LabelMd={6}
				Value={model == null ? "" : model.ApprovedAmount || ""}
				ValueChanged={props.HandleChange}
				Disabled={true}
				Required
				IsInvalid={vModel.ApprovedAmount} />
			<GenericDateInput
				Name="TransferDate"
				IsFuture={true}
				LabelText="Transfer Date"
				Value={model.TransferDate ? DateHelper.ToDateInputValue(model.TransferDate) : ""}
				ValueChanged={props.HandleChange}
				IncludeTime={false}
				LabelMd={6}
				Disabled={Disabled}
				Required
				IsInvalid={vModel.TransferDate} />
			<GenericSelectInput
				LabelMd={6}
				Name="SecurityQuestionId"
				LabelText="Security Question"
				Method="POST" Url="/bankapi/v1.0/BankInteracETransfer/GetSecurityQuestionsByClientIdAndProgramCode"
				DataRoot="Item"
				Parameter={getClientParamObj()}
				KeyValueMember="Id"
				TextValueMember="SecurityQuestion"
				Value={model.SecurityQuestionId}
				ValueChanged={props.HandleChange}
				Disabled={Disabled}
				Required
				IsInvalid={vModel.SecurityQuestionId}
			/>
			<GenericTextInput
				inputProps={{ maxLength: 30 }}
				LabelMd={6}
				Name="SecurityQuestionAnswer"
				LabelText="Security Question Answer"
				Value={model.SecurityQuestionAnswer || ""}
				ValueChanged={props.HandleChange}
				Disabled={Disabled}
				Required
				IsInvalid={vModel.SecurityQuestionAnswer}
			/>
		</GridItem>
	</div>
);
LoanApprovalFundingMethod.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	vModel: PropTypes.object,
	tempModel: PropTypes.object,
	addressModel: PropTypes.object,
	HandleChange: PropTypes.func,
	HandleTransferLoan: PropTypes.func,
	Disabled: PropTypes.bool,
	CheckerDisabled: PropTypes.bool,
	isUserAdmin: PropTypes.bool,
	Title: PropTypes.string,
	ParentTabIndex: PropTypes.number,
	ParameterList: PropTypes.array,
};

export default withArtifex(LoanApprovalFundingMethod, {});