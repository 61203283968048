import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import axios from "axios";


class ApiRequestTest extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			data: ""

		};
	}


	FetchDataProxyGet = () => {
		Proxy.GET(
			"/coreapi/v1.0/HeartBeat/Check",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				console.log(responseData);
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	FetchDataProxyPost = () => {
		Proxy.POST(
			"/coreapi/v1.0/HeartBeat/CheckPost",
			"test post proxy",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				console.log(responseData);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}




	VogConnectApiTestLogin = () => {
		Proxy.POST(
			"/customerintegrationapi/v1.0/Authentication/Login",
			{
				"Email": "DCBankEndUserAPIs@dcbank.ca",
				"Password": "Abc123."
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				console.log(responseData);
				this.setState({data : responseData.DCBankAccessToken});
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	VogConnectApiTestOtp = () => {
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/VerifyAuthenticatorOtp",
			{
				"Otp": "123456",
				"Email": "DCBankEndUserAPIs@dcbank.ca"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				console.log(responseData);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}




	FetchDataAxiosGet = () => {
		var url = window.ProxyUrl;
		url += "/coreapi/v1.0/HeartBeat/Check";
		axios.get(url)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			})
			.finally(function () {
			});
	}

	FetchDataAxiosPost = () => {
		var url = window.ProxyUrl;
		url += "/coreapi/v1.0/HeartBeat/CheckPost";
		axios.post(url,
			{ "request": "test axios post" }
		)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	LanguageFetchDataProxyPost = () => {
		Proxy.POST(
			"/coreapi/v1.0/Resource/GetByLanguageId",
			{},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				console.log(responseData);				
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	LanguageFetchDataAxiosPost = () => {
		var url = window.ProxyUrl;
		url += "/coreapi/v1.0/Resource/GetByLanguageId";
		axios.post(url,
			{}
		)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { tempList } = this.state;

		return (
			<div style={{ marginLeft: "20px" }}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				<br />
				<h4>Heart Beat Test </h4>
				<br />
				<GridContainer justify="flex-start">
					<GridItem style={{ float: "left" }}>
						<Button size="sm" onClick={this.FetchDataProxyGet} >Proxy Get </Button>
						<Button size="sm" onClick={this.FetchDataProxyPost} >Proxy Post </Button>
						<Button size="sm" onClick={this.FetchDataAxiosGet} >Axios Get </Button>
						<Button size="sm" onClick={this.FetchDataAxiosPost} >Axios Post </Button>
					</GridItem>
				</GridContainer>


				<GridContainer justify="flex-start">
					<GridItem style={{ float: "left" }}>
						<Button size="sm" onClick={this.LanguageFetchDataProxyPost} >Language Proxy Post </Button>
						<Button size="sm" onClick={this.LanguageFetchDataAxiosPost} >Language Axios Post </Button>
					</GridItem>
				</GridContainer>

				<GridContainer justify="flex-start">
					<GridItem style={{ float: "left" }}>
						<Button size="sm" onClick={this.VogConnectApiTestLogin} >Vog Login </Button>
						<Button size="sm" onClick={this.VogConnectApiTestOtp} >Vog OTP </Button>
					</GridItem>
				</GridContainer>

				<br />




			</div >
		);
	}
}

export default ApiRequestTest;
