import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import { ApproveIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import { RoleGroup, GridColumnType } from "views/Constants/Constant";

class SelectUserComponent extends React.Component {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.clientId = ClientHelper.GetClientId();

		this.defaultModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientId,
			RoleGroupId: undefined,
			RoleId: undefined,
			UserName: undefined
		};

		this.state = {
			isLoading: false,
			model: { ...this.defaultModel }
		};

		this.EmptyObject = {};
		this.RoleGroupParamObj = { RoleGroupId: null };

		this.ValueChanged = this.ValueChanged.bind(this);
		this.SearchClick = this.SearchClick.bind(this);
		this.ClearClick = this.ClearClick.bind(this);

		this.RenderClientSelect = this.RenderClientSelect.bind(this);
		this.GetRoleGroupObj = this.GetRoleGroupObj.bind(this);
	}

	componentDidMount() {

	}

	SearchClick() {
		const { model } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/User/GetAll",
			model,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				this.setState({ Data: responseData.Item });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
			});
	}

	ClearClick() {
		this.setState({ model: { ...this.defaultModel } });
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			return { model };
		});
	}

	RenderClientSelect(d) {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	GetRoleGroupObj() {
		const { model } = this.state;

		this.RoleGroupParamObj.RoleGroupId = model.RoleGroupId;
		return this.RoleGroupParamObj;
	}

	DefaultUserControl(_id) {
		return true;
	}

	render() {
		const { SelectUser, IsUserExists } = this.props;
		const { isLoading, model, Data } = this.state;

		const CanAddUser = IsUserExists || this.DefaultUserControl;

		return (
			<>
				<LoadingComponent Show={isLoading} />
				<GridContainer direction="row" justify="space-between">
					<GridItem>
						<ToolbarButton tooltip={"Search"} onClick={this.SearchClick} size="sm">{"Search"}</ToolbarButton>
						<ToolbarButton tooltip={"Clear"} onClick={this.ClearClick} size="sm">{"Clear"}</ToolbarButton>
					</GridItem>
				</GridContainer>
				<Card>
					<CardHeader>
						<GenericTitle text={"Filter Panel"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GridItem>
									<ParameterComponent
										Name="RoleGroupId"
										Value={model.RoleGroupId || -1}
										DefaultParamValue={this.isBackOffice ? undefined : RoleGroup.Client}
										LabelText="User Type"
										ParameterCode="RoleGroup"
										ValueChanged={this.ValueChanged}
										Disabled={!this.isBackOffice}
										All={true} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={this.EmptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderClientSelect}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.ValueChanged}
										CanClear
										Disabled={!this.isBackOffice} />
								</GridItem>
							</GridItem>
							<GridItem xs={4}>
								<GridItem>
									<GenericSelectInput
										key={model.RoleGroupId}
										Name="RoleId"
										LabelText="User Role"
										Method="POST"
										Url="/coreapi/v1.0/Role/GetRoleListByRoleGroupId"
										Parameter={this.GetRoleGroupObj()}
										DataRoot="RoleList"
										KeyValueMember="Id"
										TextValueMember="RoleName"
										Value={model.RoleId || -1}
										ValueChanged={this.ValueChanged}
										All={true} />
								</GridItem>
								<GridItem>
									<GenericTextInput
										Name="UserName"
										LabelText="User Name"
										Value={model.UserName}
										ValueChanged={this.ValueChanged}
									/>
								</GridItem>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<GenericGrid
					Data={Data}
					Columns={[
						{
							Header: "Actions",
							accessor: "Actions",
							Cell: row => (
								<div>
									<GridButton
										tooltip="Edit"
										Disabled={CanAddUser(row.original.Id)} Icon={ApproveIcon}
										OnClick={() => {
											if (SelectUser) {
												const result = SelectUser(row.original);
												if (!result) {
													this.props.showMessage("warning", "Warning", "User already selected.");
												}
											}
										}}
									/>
								</div>
							),
							width: 60,
							sortable: false,
							filterable: false,
						},
						{
							Header: "User Name",
							accessor: "UserEmail"
						},
						{
							Header: "User Role",
							accessor: "Role.RoleName"
						},
						{
							Header: "Name",
							accessor: "FirstName"
						},
						{
							Header: "Last Name",
							accessor: "LastName"
						},
						{
							Header: "ID Type",
							accessor: "IdType.ParameterDesc"
						},
						{
							Header: "ID Number",
							accessor: "IdNumber"
						},
						{
							Header: "Exp. Date of ID",
							accessor: "ExpDateOfId",
							type: GridColumnType.Date
						},
						{
							Header: "User Exp. Date",
							accessor: "UserExpDate",
							type: GridColumnType.Date
						},
						{
							Header: "User Status",
							accessor: "UserStatus.ParameterDesc"
						}
					]}
				/>
			</>
		);
	}
}

SelectUserComponent.propTypes = {
	SelectUser: PropTypes.func,
	IsUserExists: PropTypes.func,
	showMessage: PropTypes.func
};

export default withArtifex(SelectUserComponent, {});