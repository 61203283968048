import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, MenuCodes } from "views/Constants/Constant.js";

class External extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			externalModel: {},
			branchModel: {},
			alert: null,
			isLoading: false,
			CustomDisabled: null,
			vModel: {}
		};

		this.ParameterFinancialInstitutionBranch = {};
		this.Parameterwidth33 = { width: "33.6%" };
		this.Parameterwidth65 = { width: "65.7%" };
		this.ParameterFinancialInstitutionId = { FinancialInstitutionId: 46 };
		this.ParameterAccountStatus = { ParameterCode: "AccountStatus" };
		this.ParameterIntendUseOfAccount = { ParameterCode: "IntendUseOfAccount" };
		this.ParameterFeeAccountMinBalance = [{ Value: 15000, Text: "15000" }, { Value: 10000, Text: "10000" }, { Value: 5000, Text: "5000" }];
		this.ParameterEmptyObject = {};
		this.ParameterMaxLength50 = { maxLength: 50 };
		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Disabled={this.state.CustomDisabled || props.Disabled}
							Icon={EditIcon}
							OnClick={() => this.setState({ externalModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Disabled={this.state.CustomDisabled || props.Disabled}
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "FIID",
				accessor: "FinancialInstitution.FinIns"
			},
			{
				Header: "Transit",
				accessor: "FinancialInstutionBranch.TransitNo"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber",
				Cell: row => (
					props.paramGlobalModel.IsExceptClientSetup == true ? this.FilterIdentityNumber(row.value) : row.value
				)
			}

		];
	}
	ParameterFinancialInstitutionBranchFnc = () => {
		this.ParameterFinancialInstitutionBranch.FinancialInstitutionId = this.state.externalModel == null ? 0 : this.state.externalModel.FinancialInstitutionId || 0;
		return this.ParameterFinancialInstitutionBranch;
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("External Wallets");

		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a customer or create new one to add key contact person.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ externalModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedExternalAccount, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}
	OperationCheck = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const data = this.state.externalModel;
		const global = { ...this.props.paramGlobalModel };
		if (global == undefined || global.Id == undefined || global.Id == 0) {
			ErrorList.push("You must select a customer or create new one to add store.");
		}
		if (data == null) {
			return;
		}
		if (data.ProgramId) {


			if (data.AccountName == undefined || data.AccountName == "") {
				vModel.AccountName = true;
				ErrorList.push("Wallet Name type must be defined !.");
			} else { vModel.AccountName = false; }
			if (data.FinancialInstitutionId == undefined || data.FinancialInstitutionId == "") {
				vModel.FinancialInstitutionId = true;
				ErrorList.push("Financial Institution must be defined !.");
			} else { vModel.FinancialInstitutionId = false; }
			if (data.FinancialInstitutionBranchId == undefined || data.FinancialInstitutionBranchId == "") {
				vModel.FinancialInstitutionBranchId = true;
				ErrorList.push("Financial Institution Branch must be defined !.");
			} else { vModel.FinancialInstitutionBranchId = false; }
			if (data.AccountNumber == undefined || data.AccountNumber == "") {
				vModel.AccountNumber = true;
				ErrorList.push("Wallet Number must be defined !.");
			} else { vModel.AccountNumber = false; }
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	FilterIdentityNumber = (number) => {
		if (number == null) return "";
		var regexp = new RegExp("(.*)(.{4})");
		var regexResult = regexp.exec(number) || [];
		if (regexResult.length >= 2) {
			var onEk = regexp.exec(number)[1].replace(/([^ ])/g, "*");
			var sonEk = regexp.exec(number)[2];
			return onEk + sonEk;
		}
		else
			return ""; 

	}
	UpdateModel = () => {
		var temp = this.state.externalModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerSettlementAccount/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Client External Wallet Updated ! " : "Client External Wallet Saved ! ");
				this.ClearData();
				this.props.GetCustomerExternalBankAccountModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Client external wallet must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerSettlementAccount/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Client external wallet deleted !");
				this.props.GetCustomerExternalBankAccountModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerExternalAccountList[index];
		this.setState({ externalModel: model });
	}

	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ externalModel: model, branchModel: model, vModel: {} });
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.externalModel || {};
			var branch = { ...this.state.branchModel };
			model[name] = newValue;
			if (name == "FinancialInstitutionId" && data != null) {
				branch.FinancialInstitutionDescription = data.Description;
				model.BankNumber = data.FinancialInstitutionId;
			}
			if (name == "FinancialInstitutionBranchId" && data != null) {
				branch.FinancialInstitutionBranchDescription = data.Description;
				branch.FinancialInstitutionBranchAddress1 = data.Address1;
				branch.FinancialInstitutionBranchAddress2 = data.Address2;
				model.BranchNumber = data.TransitNumber;
			}
			return { externalModel: model, branchModel: branch };
		});
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { Disabled: DisabledProp, paramBankCustomerExternalAccountList, paramGlobalModel } = this.props;
		const { externalModel, isLoading, vModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;
		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />

				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeChildTab(null, 0)} > Back </Button>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => this.props.handleChangeParentTab(null, 3)} > Next </Button>
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<GenericExpansionPanel Title="External Wallets For Settlement">
						<CardBody>
							<GridItem xs={8}>
								<GridContainer>
									<GridItem xs={12} >
										<GridContainer>
											<GridItem xs={6}>
												<GenericSelectInput
													Disabled={Disabled || externalModel != null && externalModel.Id > 0}
													Name="ProgramId"
													LabelText="Program"
													Value={externalModel.ProgramId || ""}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="Description"
													Method="GET"
													Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile"
													DataRoot="Item" />
											</GridItem>
											<GridItem xs={6}>
												<GenericTextInput
													inputProps={this.ParameterMaxLength50}
													Disabled={Disabled}
													Name="AccountName"
													LabelText="Wallet Name"
													Value={externalModel.AccountName || ""}
													ValueChanged={this.handleChange}
													Required={externalModel.ProgramId ? true : false}
													IsInvalid={vModel.AccountName}

												/>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={6}>
												<GenericSelectInput
													Disabled={Disabled}
													TitleLabel="Fins. Ins. No."
													LabelMd={4}
													Name="FinancialInstitutionId"
													LabelText="Wallet No"
													Method="GET"
													Url="/bankapi/v1.0/FinancialInstitution/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FinInsId"
													Value={externalModel.FinancialInstitutionId || ""}
													ValueChanged={this.handleChange}
													Required={externalModel.ProgramId ? true : false}
													IsInvalid={vModel.FinancialInstitutionId}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Disabled={Disabled}
													TitleLabel="Transit"
													key={externalModel.FinancialInstitutionId || ""}
													Method="POST"
													LabelMd={0}
													Name="FinancialInstitutionBranchId"
													Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
													Parameter={this.ParameterFinancialInstitutionBranchFnc()}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Description"
													Value={externalModel.FinancialInstitutionBranchId || ""}
													ValueChanged={this.handleChange}
													Required={externalModel.ProgramId ? true : false}
													IsInvalid={vModel.FinancialInstitutionBranchId}
												/>
											</GridItem>
											<GridItem xs={3}>
												{(paramGlobalModel.IsExceptClientSetup == true) ? (
													<GenericTextInput
														Disabled={true}
														LabelMd={0}
														Name="AccountNumber"
														Value={this.FilterIdentityNumber(externalModel.AccountNumber)}
														ValueChanged={this.handleChange}
														Required={externalModel.ProgramId ? true : false}
														IsInvalid={vModel.AccountNumber}
													/>
												) : (
														<GenericNumberInput
															Disabled={Disabled}
															NoFormatting={true}
															LabelMd={0}
															InputStyle={{ marginLeft: 3 }}
															MaxLength={20}
															ThousandSeparator=""
															Name="AccountNumber"
															Value={externalModel.AccountNumber || ""}
															ValueChanged={this.handleChange}
															Required={externalModel.ProgramId ? true : false}
															IsInvalid={vModel.AccountNumber}
														/>
													)}

											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{externalModel.Id > 0 ? "Update" : "Add"}</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={paramBankCustomerExternalAccountList}
									PageSize={5}
									HideButton={true}
									Columns={this.CloumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}
External.propTypes = {
	classes: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerExternalAccountList: PropTypes.array,
	GetCustomerExternalBankAccountModels: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	handleChangeParentTab: PropTypes.func
};


export default withArtifex(External, {});