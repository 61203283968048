import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { OrderStatusType } from "views/Constants/Constant.js";
import DDTListStyle from "../../../assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import ChequeOrderDetailInfo from "../ChequeOrder/ChequeOrderDetailInfo";

class CreateCheque extends Component {
	constructor(props) {
		super(props);

		var user = ClientHelper.GetUser();

		this.initialModel = {
			Id: 0,
			UniqueClientId: user.UniqueClientId,
			ClientTypeId: user.UniqueClient.ClientTypeId,
			ImageData1: "",
			ImageData: ""
		};

		this.state = {
			model: this.initialModel,
			pageSelect: "",
			list: [],
			prmList: [],
			isLoading: false,
			alert: "",
			selected: null,
			pricetxt: "",
			chequemodel: {},
			openDialog: false,
			isBackOffice: false,
			chqinfomodel: {},
			totalchqinfomodel: {}
		};

		this.fileInputRef = React.createRef();
		this.fileInputRef1 = React.createRef();
	}
	componentDidMount() {
		// var tempuniq = Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Create Cheque");
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("WALLET");
		}
		this.setState({ openDialog: false });
		var isBackOffice = ClientHelper.IsBackOffice();
		this.setState({ isBackOffice });
	}
	HandleChange = (name, value, data) => {

		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	IsSubmit = () => {
		this.setState({ isLoading: true });
		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;
		var list1 = [];
		const temp = {
			Amount: model.Amount,
			AccountNumber: model.AccountNumber,
			RoutingTransit: model.RoutingTransit,
			ChequeSerialNumber: model.ChequeSerialNumber,
			PayToTheOrderOf: model.PayToTheOrderOf,
			Memo: model.Memo,
			BOFDRoutingNumber: model.BOFDRoutingNumber,
			ECEInstitutionRoutingNumber: model.ECEInstitutionRoutingNumber,
			CashLetterId: model.CashLetterId,
			BundleId: model.BundleId,
			BundleSequenceNumber: model.BundleSequenceNumber,
			EndorsingBankRoutingNumber: model.EndorsingBankRoutingNumber,
			ItemSequenceNumber: model.ItemSequenceNumber,
			ChqueAuxiliary: model.ChqueAuxiliary,
			PayorBankRoutingNumber: model.PayorBankRoutingNumber,
			PayorBankRoutingCheckDigit: model.PayorBankRoutingCheckDigit,
			ChequeAccountOnUs: model.ChequeAccountOnUs,
			ChequeAmount: model.ChequeAmount,
			CreditAuxiliary: model.CreditAuxiliary,
			PostingAccountRoutingNumber: model.PostingAccountRoutingNumber,
			CreditAccountOnUs: model.CreditAccountOnUs,
			CreditAmount: model.CreditAmount,
			DebitCreditInd: model.DebitCreditInd,
			ImageCreatorRoutingNumber: model.ImageCreatorRoutingNumber,
			FinancialInstitutionId: model.FinancialInstitutionId,
			FinancialInstitutionBranchId: model.FinancialInstitutionBranchId,
			DestinationAccountNumber: model.DestinationAccountNumber,
			SourceAccountNumber: model.SourceAccountNumber,

		};
		list1.push(model);

		// Buradaki kontrol kaldırıldı. Tekrar düzenleme yapılırken model.Id dolu ise update olduğuna dikkat edilmeli. (URL ve request datası buna göre değişiyordu)

		Proxy.POST(
			"/chequeapi/v1.0/Cheque/BmoFileCreate",
			list1,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.ShowMessage("success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Submit = () => {
		if (!this.Validate()) {
			return;
		}
		if (this.state.model.Id == 0) {

			this.ShowConfirmMessage("warning", "Warning", "Are you sure want to Add ?", this.IsSubmit);
		}
		else {
			this.ShowConfirmMessage("warning", "Warning", "Are you sure want to Edit ?", this.IsSubmit);
		}
	}
	Clear = () => {
		this.setState({ isLoading: true });
		this.setState({ model: this.initialModel, list: [], selected: null, pricetxt: "", totalchqinfomodel: {}, chqinfomodel: {} });
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}
	// PreView = () => {

	// 	this.setState({ isLoading: true });
	// 	var FileName = "";
	// 	Proxy.FileDownload(
	// 		"/chequeapi/v1.0/Cheque/DowloadFile",
	// 		FileName + "-" + Math.floor(Math.random() * Math.floor(999999)),
	// 		"pdf",
	// 		{
	// 			"Id": "40"
	// 		},
	// 		() => {
	// 			this.setState({ isLoading: false });
	// 		}
	// 	);
	// }
	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null })}
					OnConfirm={onConfirm} />
		});
	}
	Validate() {
		const { model } = this.state;

		if (!model.ChequeAmount) {
			this.ShowMessage("warning", "Cheque Amount not entered", "Enter Cheque Amount  to continue.");
			return false;
		}
		if (!model.ChequeSerialNumber) {
			this.ShowMessage("warning", "ChequeSerialNumbernot entered", "Enter ChequeSerialNumber  to continue.");
			return false;
		}
		if (!model.PayToTheOrderOf) {
			this.ShowMessage("warning", "PayToTheOrderOf not entered", "Enter PayToTheOrderOf  to continue.");
			return false;
		}
		if (!model.Memo) {
			this.ShowMessage("warning", "Memo not entered", "Enter Memo  to continue.");
			return false;
		}
		if (!model.BOFDRoutingNumber) {
			this.ShowMessage("warning", "BOFD Routing Number not entered", "Enter BOFD Routing Number to continue.");
			return false;
		}
		if (!model.ECEInstitutionRoutingNumber) {
			this.ShowMessage("warning", "ECE Institution Routing Number not entered", "Enter ECE Institution RoutingNumber to continue.");
			return false;
		}

		if (!model.CashLetterId) {
			this.ShowMessage("warning", "CashLetter Id not entered", "Enter CashLetter Id to continue.");
			return false;
		}
		if (!model.BundleId) {
			this.ShowMessage("warning", "Bundle Id not entered", "Enter Bundle Id to continue.");
			return false;
		}
		if (!model.BundleSequenceNumber) {
			this.ShowMessage("warning", "Bundle Sequence Number not entered", "Enter Bundle Sequence Number to continue.");
			return false;
		}
		if (!model.ItemSequenceNumber) {
			this.ShowMessage("warning", "Item Sequence Number not entered", "Enter Item Sequence Number to continue.");
			return false;
		}
		if (!model.ChqueAuxiliary) {
			this.ShowMessage("warning", "Chqeue Auxiliary not entered", "Enter Chqeue Auxiliary to continue.");
			return false;
		}

		if (!model.PayorBankRoutingNumber) {
			this.ShowMessage("warning", "Payor Bank Routing Number not entered", "Enter Payor Bank Routing Number to continue.");
			return false;
		}
		if (!model.ChequeAccountOnUs) {
			this.ShowMessage("warning", "Cheque Wallet On-Us not entered", "Enter Cheque Wallet On-Us to continue.");
			return false;
		}
		if (!model.CreditAuxiliary) {
			this.ShowMessage("warning", "Credit Auxiliary not entered", "Enter Credit Auxiliary to continue.");
			return false;
		}
		if (!model.PostingAccountRoutingNumber) {
			this.ShowMessage("warning", "Posting Wallet Routing Number not entered", "Enter Posting Wallet Routing Number to continue.");
			return false;
		}

		if (!model.CreditAccountOnUs) {
			this.ShowMessage("warning", "Credit Wallet On-Us not entered", "Enter Credit Wallet On-Us to continue.");
			return false;
		} if (!model.CreditAmount) {
			this.ShowMessage("warning", "Credit Amount not entered", "Enter Credit Amount to continue.");
			return false;
		} if (!model.DebitCreditInd) {
			this.ShowMessage("warning", "DebitCreditInd not entered", "Enter DebitCreditInd to continue.");
			return false;
		} if (!model.ImageCreatorRoutingNumber) {
			this.ShowMessage("warning", "Image Creator Routing Number not entered", "Enter Image Creator Routing Number to continue.");
			return false;
		}


		return true;
	}
	UploadClick = () => {
		this.fileInputRef.current.click();
	}
	UploadClick1 = () => {
		this.fileInputRef1.current.click();
	}
	FileSelect = (e) => {
		const { model } = this.state;

		e.preventDefault();
		let reader = new FileReader();
		var file = e.target.files[0];
		if (!file) return;

		reader.onloadend = () => {
			model.ImageData = reader.result;
			this.setState(state => ({
				fileModel: {
					FileName: file.name,
					File: file
				},
				model
			}));
		};
		reader.readAsDataURL(file);
	}
	FileSelect1 = (e) => {
		const { model } = this.state;
		e.preventDefault();
		let reader1 = new FileReader();
		var file1 = e.target.files[0];
		if (!file1) return;

		reader1.onloadend = () => {
			model.ImageData1 = reader1.result;
			this.setState(state => ({
				fileModel1: {
					FileName1: file1.name,
					File1: file1
				},
				model
			}));
		};
		reader1.readAsDataURL(file1);
	}
	render() {
		const { classes } = this.props;
		const { alert, model, prmList, chqinfomodel } = this.state;
		
		return (
			<div>
				<GenericDialog open={this.state.openDialog} maxWidth="md" fullWidth  >
					<DialogTitle><GenericLabel Text="Cheque Order Information" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						{<ChequeOrderDetailInfo
							chequemodel={this.state.chequemodel}
							chqinfomodel={this.state.chqinfomodel}
						/>}
					</DialogContent>
					<GenericDialogActions>
						<Button onBackdropClick={this.handleClose}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						// OnClick: this.Search
					},
					{
						Code: "Submit",
						OnClick: this.Submit,
					},
					{
						Code: "Delete",
						OnClick: () => this.ShowConfirmMessage("warning", "Warning", "Are you sure want to Delete ?", this.Delete),
						Disabled: model.Id == 0 ? chqinfomodel.OrderStatusValue != undefined ? chqinfomodel.OrderStatusValue == OrderStatusType.Approved ? true : false : true : chqinfomodel.OrderStatusValue == OrderStatusType.Approved ? true : false
					},
					{
						Code: "Clear",
						OnClick: this.Clear,
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={4}>
						<Card >
							<GenericExpansionPanel >
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput

														Name="FinancialInstitutionId"
														Method="GET"
														Url="/bankapi/v1.0/FinancialInstitution/GetAll"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														Value={model.FinancialInstitutionId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														key={model.FinancialInstitutionId}
														Name="FinancialInstitutionBranchId"
														LabelMd={0}
														Method="POST"
														Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
														Parameter={{ FinancialInstitutionId: model.FinancialInstitutionId }}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														Value={model.FinancialInstitutionBranchId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={5}>
													<GenericTextInput
														LabelText="Destination Wallet"
														Name="DestinationAccountNumber"
														Value={model.DestinationAccountNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 12 }} />
												</GridItem>
											</GridContainer>
											<GridContainer spacing={16} >
												<GridItem xs={12}>
													<GenericTextInput
														Name="SourceAccountNumber"
														LabelText="Source Wallet Number"
														Value={model.SourceAccountNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 12 }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="RoutingTransit"
														LabelText="Routing Transit"
														Value={model.RoutingTransit}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 5 }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="ChequeSerialNumber"
														LabelText="Cheque Serial Number"
														Value={model.ChequeSerialNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15, placeholder: "000000000000001" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="Memo"
														LabelText="Memo"
														Value={model.Memo}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="PayToTheOrderOf"
														LabelText="Pay To The Order Of"
														Value={model.PayToTheOrderOf}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>

												{/* <GridItem xs={12}>
													<GenericTextInput
														Name="ChequeDate"
														LabelText="Date"
														Value={model.ChequeDate}
														ValueChanged={this.HandleChange}
													/>
												</GridItem> */}
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card>
							<GenericExpansionPanel Title={"Cash Letter Header Record (10) and Bundle Header Record (20)"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>

											<GridContainer spacing={16} >

												<GridItem xs={12}>
													<GenericTextInput
														Name="ECEInstitutionRoutingNumber"
														LabelText="ECE Institution Routing Number"
														Value={model.ECEInstitutionRoutingNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 9, placeholder: "Client ID assigned by BMO>003017001 " }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="CashLetterId"
														LabelText="Cash Letter Id"
														Value={model.CashLetterId}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 8, placeholder: "12345678" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="BundleId"
														LabelText="Bundle Id"
														Value={model.BundleId}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 10, placeholder: "1234567890" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="BundleSequenceNumber"
														LabelText="Bundle Sequence Number"
														Value={model.BundleSequenceNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 4, placeholder: "1234" }}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card>
							<GenericExpansionPanel Title={"Cheque Detail Addendum A Record (Type 26) and  Cheque Detail Addendum C Record(Type 28)"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>

											<GridContainer spacing={16} >

												<GridItem xs={12}>
													<GenericTextInput
														Name="EndorsingBankRoutingNumber"
														LabelText="Endorsing Bank Routing Number"
														Value={model.EndorsingBankRoutingNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 9, placeholder: "TTTTR-001>>00109-001" }}

													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="BOFDRoutingNumber"
														LabelText="BOFD Routing Number"
														Value={model.BOFDRoutingNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 9, placeholder: "TTTTR-001 >>00109-001" }}

													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="ItemSequenceNumber"
														LabelText="Item Sequence Number"
														Value={model.ItemSequenceNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15 }}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
					<GridItem xs={4}>
						<Card>
							<GenericExpansionPanel Title={"Cheque Detail Record (Type 25)"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>

											<GridContainer spacing={16} >
												<GridItem xs={12}>
													<GenericTextInput
														Name="ChqueAuxiliary"
														LabelText="Cheque Auxiliary On-Us"
														Value={model.ChqueAuxiliary}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15, placeholder: "Micr line-Cheque Serial Number" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="PayorBankRoutingNumber"
														LabelText="Payor Bank Routing Number"
														Value={model.PayorBankRoutingNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 8, placeholder: "10009-35" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="PayorBankRoutingCheckDigit"
														LabelText="Payor Bank Routing Check Digit"
														Value={model.PayorBankRoutingCheckDigit}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 1, placeholder: "10009-35-> 2" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="ChequeAccountOnUs"
														LabelText="Cheque On-Us(Account)"
														Value={model.ChequeAccountOnUs}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 20, placeholder: "TTTTAAAAAAA/997 >>71000000001" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericNumberInput
														Name="ChequeAmount"
														LabelText="Cheque Amount"
														Value={model.ChequeAmount}
														ValueChanged={this.HandleChange}
														Prefix="$"
														inputProps={{ maxLength: 10 }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="ItemSequenceNumber"
														LabelText="Item Sequence Number"
														Value={model.ItemSequenceNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15 }}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card>
							<GenericExpansionPanel Title={" Credit/Reconciliation Record (Type 61)"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>

											<GridContainer spacing={16} >

												<GridItem xs={12}>
													<GenericTextInput
														Name="CreditAuxiliary"
														LabelText="Auxiliary On-Us"
														Value={model.CreditAuxiliary}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15, placeholder: "Cheque Serial Number" }}
													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="PostingAccountRoutingNumber"
														LabelText="Posting Wallet Routing Number"
														Value={model.PostingAccountRoutingNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 9, placeholder: "TTTTR-001 >>00109-001" }}

													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="CreditAccountOnUs"
														LabelText="Credit On-Us(Account)"
														Value={model.CreditAccountOnUs}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 20, placeholder: "TTTTAAAAAAA/997 >>00101292014/997" }}


													/>
												</GridItem>
												<GridItem xs={12}>
													<GenericNumberInput
														Name="CreditAmount"
														LabelText="Credit Amount"
														Value={model.CreditAmount}
														ValueChanged={this.HandleChange}
														Prefix="$"
														inputProps={{ maxLength: 10 }}

													/>
												</GridItem>

												<GridItem xs={12}>
													<GenericTextInput
														Name="ItemSequenceNumber"
														LabelText="Item Sequence Number"
														Value={model.ItemSequenceNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15 }}
													/>
												</GridItem>

												<GridItem xs={12}>
													<GenericNumberInput
														Name="DebitCreditInd"
														LabelText="Debit/Credit Ind"
														Value={model.DebitCreditInd}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 1, placeholder: " <2> Credit or <1>Debit" }}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>

								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card>
							<GenericExpansionPanel Title={"Image View Detail Record (Type 50)"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>

											<GridContainer spacing={16} >
												<GridItem xs={12}>
													<GenericTextInput
														Name="ImageCreatorRoutingNumber"
														LabelText="Image Creator Routing Number"
														Value={model.ImageCreatorRoutingNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 9, placeholder: "00000-123" }}
													/>
												</GridItem>

												<GridItem xs={12}>
													<GenericTextInput
														Name="ItemSequenceNumber"
														LabelText="Item Sequence Number"
														Value={model.ItemSequenceNumber}
														ValueChanged={this.HandleChange}
														inputProps={{ maxLength: 15 }}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
					<GridItem xs={4}>
						<Card className={classes.zerMarPad} style={{ height: 640 }}>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<div >
											<Button
												size="sm"
												onClick={() => this.UploadClick()}>
												Browse Rear Photo
												</Button>
											<input type="file" style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="image/*" />
										</div>
									</GridItem>
									<GridItem xs={1}></GridItem>
									<GridItem xs={3}>
										<div >
											<Button
												size="sm"
												onClick={() => this.UploadClick1()}>
												Browse Front Photo
												</Button>
											<input type="file" style={{ display: "none" }} onChange={this.FileSelect1} ref={this.fileInputRef1} accept="image/*" />
										</div>
									</GridItem>
									<GridItem xs={12}>
										<img
											style={{ marginTop: 24, height: 200, width: "100%" }}
											src={model.ImageData}
											className={classes.pictureSrc}
											alt="Rear"
										/>
										<img
											style={{ marginTop: 24, height: 200, width: "100%" }}
											src={model.ImageData1}
											className={classes.pictureSrc}
											alt="Front"
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}
CreateCheque.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default withArtifex(CreateCheque, DDTListStyle);

