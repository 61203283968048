import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicationBorrowerReview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			alert: null
		};
		this.LOSReviewPeriodParameterCode = { ParameterCode: "LOSReviewPeriod" };
		this.LOSReviewTypeParameterCode = { ParameterCode: "LOSReviewType" };
		this.LOSReviewAlertTimeParameterCode = { ParameterCode: "LOSReviewAlertTime" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };

	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");
	}


	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}


	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ otherFinancialModel: model });
	}

	DeleteModel = (model) => {

	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.otherFinancialModel || {};
			model[name] = newValue;
			return { otherFinancialModel: model };
		});
	}
	render() {
		const { Disabled, ApplicationModel } = this.props;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />


				<GridContainer
					direction="row"
					justify="flex-start"
					alignItems="stretch"
				>
					<GridItem xs={4}>
						<GridContainer
							direction="column"
						>
							<GridItem xs={12}>
								<GenericSelectInput
									Name="ReviewPeriodId"
									LabelText="Review Period"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.LOSReviewPeriodParameterCode}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={ApplicationModel.ReviewPeriodId}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
									Sorted={{ Member: "ParameterValue" }}
									DefaultIndex={2}
								/>
								<GenericSelectInput
									Name="ReviewTypeId"
									LabelText="Review Type"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.LOSReviewTypeParameterCode}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={ApplicationModel.ReviewTypeId}
									ValueChanged={this.props.HandleChange}
									DefaultIndex={0}
									Disabled={Disabled}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={4}>
						<GridContainer
							direction="column"
							justify="flex-start"
							alignItems="stretch"
						>
							<GridItem xs={12}>
								<GenericDateInput
									Name="PreviousReviewDate"
									LabelText="Previous Review Date"
									Value={ApplicationModel == null ? DateHelper.ToDateInputValue(DateHelper.GetDate()) : DateHelper.ToMoment(ApplicationModel.PreviousReviewDate) || DateHelper.ToDateInputValue(DateHelper.GetDate())}
									Disabled
									Utc
								/>
								<GenericSelectInput
									Name="ReviewAlertTimeId"
									LabelText="Review Alert Report"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.LOSReviewAlertTimeParameterCode}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={ApplicationModel.ReviewAlertTimeId}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
									DefaultIndex={2}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={4}>
						<GridContainer
							direction="column"
							justify="flex-start"
							alignItems="stretch"
						>
							<GridItem xs={12}>
								<GenericSelectInput
									LabelMd={6}
									Name="ReviewAlertNotificationId"
									LabelText="Review Alert Notification to Borrower"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.YesNoParameterCode}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={ApplicationModel.ReviewAlertNotificationId}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
									DefaultIndex={1}
								/>

								<GenericSelectInput
									LabelMd={6}
									Name="ReviewResultNotificationId"
									LabelText="Review Result Notification to Borrower"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.YesNoParameterCode}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={ApplicationModel.ReviewResultNotificationId}
									ValueChanged={this.props.HandleChange}
									Disabled={Disabled}
									DefaultIndex={1}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>

				</GridContainer>
			</GridContainer>
		);
	}
}

LoanApplicationBorrowerReview.propTypes = {
	classes: PropTypes.object,
	ApplicationModel: PropTypes.object,
	HandleChange: PropTypes.func,
	ApplicationOtherFinancialModel: PropTypes.object
};
export default withArtifex(LoanApplicationBorrowerReview, {});