import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericCheckInput,
  GenericDateInput,
  GenericDialog,
  GenericDialogActions,
  GenericExpansionPanel,
  GenericGrid,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import {
  ActionCodes,
  BankingAccountTypes,
  ClientType,
  GridColumnType,
  MenuCodes,
  ProgramCodes,
  TransactionType,
} from "views/Constants/Constant.js";

class CustomerAcvitiviteAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.isBackoffice = ClientHelper.IsBackOffice();
    let IsCallCenter =
      this.props.MenuCode === MenuCodes.ClientAccountActivities;
    let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;
    this.state = {
		model: {},
      checkedAccounts: [],
      customerAccountList: [],
		vModel: {},
      searchModel: {
        IsRequiredDate: false,
        IsRequiredStatementPeriod: false,
        IsInvalidStatementPeriod: false,
        IsInvalidFromDate: false,
        IsInvalidToDate: false,
      },
      customerTransactions: [],
      IsTxnDetailOpen: false,
      TxnDetail: {},
      isLoading: false,
      ClientTypeParameter: {},
      hasChanged: false,
      kModel: {},
    };
    this.sortName = { Member: "Name" };
    this.sortListName = [{ id: "CustomerName" }];
    this.emptyObject = {};
    this.columns = [
      {
        width: 100,
        Header: "Actions",
        accessor: "ED",
        Cell: (d) => (
          <GridButton
            tooltip="Detail"
            Icon={DetailIcon}
            Disabled={this.props.Disabled}
            OnClick={() =>
              this.setState({ IsTxnDetailOpen: true, TxnDetail: d.original })
            }
          />
        ),
        sortable: false,
        filterable: false,
        show: !IsCallCenter,
      },
      {
        Header: "Transaction Date",
        accessor: "TransactionDate",
        type: GridColumnType.DateTimeUtc,
        style: this.RenderStyle(),
      },
		{
			Header: "Settlement Date",
			accessor: "SettlementDate",
			type: GridColumnType.DateTimeUtc,
			style: this.RenderStyle(),
		 },
      {
        Header: "Merchant",
        accessor: "F43Name",
        Cell: (row) => <p>{row.value}</p>,
      },
      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
        Cell: (row) => <p>{row.value}</p>,
      },
      {
        Header: "Running Balance",
        accessor: "WalletBalance",
        type: GridColumnType.Money,
        style: this.RenderStyle(),
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Client",
        accessor: "UniqueClientName",
        width: 120,
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value ?? " - "}
          </div>
        ),
        show: IsSettlement,
      },
      {
        Header: "Channel Name",
        accessor: "ChannelType",
        Cell: (row) => (
          <p
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {" "}
            {row.value}
          </p>
        ),
      },
      {
        Header: "Received / Sent",
        accessor: "TransactionType",
        Cell: (row) => (
          <p
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? 600 : "normal",
            }}
          >
            {row.value}
          </p>
        ),
      },
      {
        Header: "Amount",
        accessor: "Amount",
        type: GridColumnType.Money,
        style: this.RenderStyle(),
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Currency",
        accessor: "SourceCurrencyCode",
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value}
          </div>
        ),
      },
      {
        Header: "From Wallet Number",
        accessor: "SourceAccountNumber",
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value ?? " - "}
          </div>
        ),
      },
      {
        width: 180,
        Header: "From Wallet",
        accessor: "SourceAccountName",
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value}
          </div>
        ),
      },

      {
        width: 180,
        Header: "To Wallet",
        accessor: "DestinationAccountName",
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value}
          </div>
        ),
      },
      {
        Header: "To Wallet Number",
        accessor: "DestinationAccountNumber",
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value ?? " - "}
          </div>
        ),
      },
      {
        Header: "Destination Wallet Owner Name",
        accessor: "DestinationCustomerName",
        Cell: (row) => (
          <div
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {row.value ?? " - "}
          </div>
        ),
      },
      {
        Header: "Reference Number",
        accessor: "ReferenceNumber",
        Cell: (row) => (
          <p
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {" "}
            {row.value}
          </p>
        ),
      },
      {
        width: 350,
        Header: "Description",
        accessor: "SummaryDescription",
        Cell: (row) => (
          <p
            style={{
              fontWeight:
                row.original.TransactionType == "Received" ? "600" : "",
            }}
          >
            {" "}
            {row.value}
          </p>
        ),
      },
    ];
  }

  componentDidMount() {
    this.props.setAppCenterTitle("CUSTOMER SYSTEM");
    this.props.setAppLeftTitle("Customer Wallet Activities");
    this.ProgramCode = ProgramCodes.Prepaid;
    this.state.searchModel.ProgramCode = ProgramCodes.Prepaid;
    this.GetClientType();
  }
  TrxFunc = (data) => {
    if (data == null) {
      return "";
    }
    if (data.ParameterValue == TransactionType.Debit) {
      return "Received";
    }
    if (data.ParameterValue == TransactionType.Credit) {
      return "Sent";
    }
    return "";
  };
  RenderAccountTypeParameter = (data) => {
    const parameter = { ...this.state.ClientTypeParameter };
    if (data == null) {
      return "";
    }
    if (data.AccountCode == null || data.AccountCode == "") {
      return data.AccountType;
    }
    if (data.AccountCode != BankingAccountTypes.PrimaryAccount) {
      return data.AccountType;
    }
    if (data.ProgramCode == null || data.ProgramCode == "") {
      return data.AccountType;
    }
    if (data.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
      return data.AccountType;
    }

    if (
      parameter.ParameterValue == ClientType.Corporate ||
      parameter.ParameterValue == ClientType.SalesAgentCorporate
    ) {
      return "Corporate Wallet";
    } else if (
      parameter.ParameterValue == ClientType.Personal ||
      parameter.ParameterValue == ClientType.SalesAgentPersonal
    ) {
      return "Individual Wallet";
    } else {
      return data.AccountType;
    }
  };
  GetClientType = () => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/BankCustomer/GetClientType",
      {},
      (responseData) => {
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        this.setState({
          ClientTypeParameter: responseData.Item,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };
  handleChangeSearchModel = (name, newValue) => {
    this.setState(function (state, props) {
      var model = state.searchModel || {};

      model[name] = newValue;
      if (name == "CheckAllAccounts" && newValue == true) {
        const list = this.state.customerAccountList;
        this.setState({ checkedAccounts: list });
      } else if (name == "CheckAllAccounts" && newValue == false) {
        this.setState({ checkedAccounts: [] });
      }
      if (name == "ClientCustomerId") {
        this.setState({ checkedAccounts: [] });
      }

      if (
        name == "ClientCustomerId" &&
        newValue !== undefined &&
        newValue != null
      ) {
        this.GetCustomerBankAccounts(true);
        this.setState({
          customerTransactions: [],
          customerAccountList: [],
          checkedAccounts: [],
        });
      } else if (
        name == "ClientCustomerId" &&
        (newValue == undefined || newValue == null)
      ) {
        this.setState({
          customerTransactions: [],
          checkedAccounts: [],
          customerAccountList: [],
        });
      }

      // if (name == "ReferenceNumber") {

      // 	let isUniqueDataSearch = newValue?.length > 0;
      // 	model.IsUniqueDataSearch = isUniqueDataSearch;

      // 	model.IsRequiredStatementPeriod = !isUniqueDataSearch;
      // 	model.IsRequiredDate = !isUniqueDataSearch;
      // 	model.IsRequiredUniqueClientId = !isUniqueDataSearch;
      // }

      if (name == "StatementPeriod" || name == "ToDate" || name == "FromDate") {
        if (
          model.StatementPeriod == undefined ||
          model.StatementPeriod == null ||
          model.StatementPeriod == ""
        ) {
          model.StatementControl = false;
          model.IsRequiredStatementPeriod = !model.IsUniqueDataSearch;
          model.IsRequiredDate = !model.IsUniqueDataSearch;

          this.setState({ searchModel: model });

          if (
            (model.ToDate == undefined ||
              model.ToDate == "" ||
              model.ToDate == null) &&
            (model.FromDate == undefined ||
              model.FromDate == "" ||
              model.FromDate == null)
          ) {
            model.IsInvalidFromDate = false;
            model.IsInvalidToDate = false;

            model.DateControl = false;
            this.setState({ searchModel: model });
          } else {
            model.StatementControl = true;
            model.DateControl = false;
            model.IsRequiredStatementPeriod = false;
          }
        } else {
          model.DateControl = true;
          model.IsInvalidFromDate = false;
          model.IsInvalidToDate = false;
          model.IsRequiredDate = false;
          this.setState({ searchModel: model });
        }
      }
      return { searchModel: model };
    });
  };
  HandleCheckCustomerAccounts = (e, x) => {
    const { checkedAccounts } = this.state;
    var temp = true;

    const items = checkedAccounts.map((item, key) => {
      if (item.Id == x.Id) {
        checkedAccounts.splice(key, 1);
        temp = false;
      }
    });

    if (checkedAccounts.length == 0 && temp) {
      checkedAccounts.push(x);
    } else if (temp) {
      checkedAccounts.push(x);
    }

    this.setState({ checkedAccounts });
  };

  IsValid = () => {
    var temp = { ...this.state.searchModel };
    var kModel = { ...this.state.kModel };

    if (
      (!temp.IsUniqueDataSearch && temp.ClientCustomerId == null) ||
      temp.ClientCustomerId == 0
    ) {
      this.ShowMessage("error", "Error", "Customer Name must be selected!");
      kModel.ClientCustomerId = true;
      this.setState({ kModel });
      return false;
    } else {
      kModel.ClientCustomerId = false;
    }
    this.setState({ kModel });

    //  if (
    //    !temp.IsUniqueDataSearch &&
    //    (temp.StatementPeriod == undefined ||
    //      temp.StatementPeriod == null ||
    //      temp.StatementPeriod == "") &&
    //    (temp.FromDate == null ||
    //      temp.FromDate == undefined ||
    //      temp.FromDate == "") &&
    //    (temp.ToDate == null || temp.ToDate == undefined || temp.ToDate == "")
    //  ) {
    //    this.props.showMessage(
    //      "warning",
    //      "Please fill required fields!",
    //      "Please select Statement Period or Date Interval"
    //    );
    //    temp.IsRequiredDate = true;
    //    temp.IsRequiredStatementPeriod = true;
    //    this.setState({ searchModel: temp });
    //    return false;
    //  } else {
    if (temp.StatementPeriod != null && temp.StatementPeriod != "") {
      temp.DateControl = true;
      temp.IsRequiredDate = false;
      temp.IsRequiredStatementPeriod = false;
      temp.IsRequiredDate = false;

      this.setState({ searchModel: temp });
    } else {
      if (
        !temp.IsUniqueDataSearch &&
        typeof temp.FromDate != "object" &&
        temp.FromDate != "" &&
        temp.FromDate != null
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "From Date is invalid"
        );
        temp.IsRequiredDate = true;
        temp.IsRequiredStatementPeriod = false;
        temp.IsInvalidFromDate = true;
        this.setState({ searchModel: temp });
        return false;
      }
      if (
        !temp.IsUniqueDataSearch &&
        typeof temp.ToDate != "object" &&
        temp.ToDate != "" &&
        temp.ToDate != null
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "To Date is invalid"
        );
        temp.IsRequiredDate = true;
        temp.IsRequiredStatementPeriod = false;
        temp.IsInvalidToDate = true;
        this.setState({ searchModel: temp });
        return false;
      }
      //   else if (
      //     (!temp.IsUniqueDataSearch && temp.ToDate == undefined) ||
      //     temp.ToDate == ""
      //   ) {
      //     this.props.showMessage(
      //       "warning",
      //       "Please fill required fields!",
      //       "To Date must be defined  "
      //     );
      //     temp.IsRequiredDate = true;
      //     temp.IsRequiredStatementPeriod = false;
      //     temp.IsInvalidToDate = true;
      //     this.setState({ searchModel: temp });
      //     return false;
      //   }
      //    else if (
      //     (!temp.IsUniqueDataSearch && temp.FromDate == undefined) ||
      //     temp.FromDate == ""
      //   ) {
      //     this.props.showMessage(
      //       "warning",
      //       "Please fill required fields!",
      //       "From Date must be defined "
      //     );
      //     temp.IsRequiredDate = true;
      //     temp.IsRequiredStatementPeriod = false;
      //     temp.IsInvalidFromDate = true;
      //     this.setState({ searchModel: temp });
      //     return false;
      //   }
      if (
        !temp.IsUniqueDataSearch &&
        typeof temp.FromDate == "object" &&
        typeof temp.ToDate == "object" &&
        temp.ToDate < temp.FromDate
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "From Date cannot be bigger than To Date"
        );
        return false;
      }

      if (
        !temp.IsUniqueDataSearch &&
        typeof temp.FromDate == "object" &&
        typeof temp.ToDate == "object"
      ) {
        var interval = moment
          .duration(temp.ToDate.diff(temp.FromDate))
          .asDays();
        if (interval > 30) {
          this.props.showMessage(
            "warning",
            "Please fill required fields!",
            "Between two dates cannot be longer than 30 days!"
          );
          return false;
        }
      }
      temp.IsRequiredStatementPeriod = !temp.IsUniqueDataSearch;
      temp.IsRequiredDate = !temp.IsUniqueDataSearch;
      temp.IsInvalidFromDate = false;
      temp.IsInvalidToDate = false;
      temp.StatementControl = true;
      this.setState({ searchModel: temp });
    }
    //  }

    return true;
  };

  PerformSearch = () => {
    if (!this.IsValid()) return;

    this.BankAccountTransactions();
  };

  GetCustomerBankAccounts = (status) => {
	const req = { ...this.state.searchModel };
    if (status == undefined) {
      if (this.IsValid() == false) return;
    } else if (status == false && this.IsValid() == false) {
      return;
    }
    this.setState({ isLoading: true, req });

    Proxy.POST(
      "/bankapi/v1.0/Account/CustomerAccountsActivitiesForBankingPortal",
      req,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error !", responseData.ErrorDescription);
          return;
        } else {
          this.setState({ customerAccountList: responseData.Item || [] });
        }
        this.setState({ isLoading: false });
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };

  BankAccountTransactions = () => {
    const temp = { ...this.state.searchModel };
	 const model = { ...this.state.model };
    var ids = [];
    this.state.checkedAccounts.forEach((element) => {
      ids.push(element.Id);
    });
    temp.AccountIds = ids;

	 this.setState({ isLoading: true, customerTransactions: [], temp });
    Proxy.POST(
      "/bankapi/v1.0/Transaction/SearchCustomerActivitiesAccountTransaction",
      temp,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error !", responseData.ErrorDescription);
          return;
        } else {
          if (responseData.Item != null) {
            this.setState({
              customerTransactions: responseData.Item || [],
            });
          }
        }
        this.setState({ isLoading: false });
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };

  SendToWorkflow = (id) => {
    var data = { Id: id };
    var jsonData = JSON.stringify(data);

    var requestData = {
      MenuCode: MenuCodes.CloseAccount,
      ActionCode: ActionCodes.Submit,
      JsonData: jsonData,
      RowId: id,
    };

    this.setState({ isLoading: true });
    Proxy.POST(
      "/coreapi/v1.0/WorkflowData/InsertWorkflowData",
      requestData,
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        this.ShowMessage(
          "success",
          "Success",
          "Request successfully sent for approval"
        );
      },
      (errorMessage) => {
        this.setState({ isLoading: false });
        this.ShowMessage(
          "error",
          "Error",
          "An error occurred while sending approve request " + errorMessage
        );
      }
    );
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };
  ShowConfirmMessage = (type, title, message, onConfirm) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={true}
          OnCancel={() => this.hideAlert()}
          OnConfirm={onConfirm}
        />
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };
  ClearData = () => {
    let searchModel = {
      IsRequiredStatementPeriod: true,
      IsRequiredDate: true,
      IsRequiredUniqueClientId: true,
		UniqueClientId: this.isBackoffice
		? null
		: this.state.searchModel.UniqueClientId,
    };
	 let model = {};
    this.setState({
      alert: null,
      isLoading: false,
      checkedAccounts: [],
      customerAccountList: [],
      searchModel,
		model,
      customerTransactions: [],
      IsTxnDetailOpen: false,
      TxnDetail: {},
    });
  };
  handleChangeFilterPanel = (panel) => (event, expanded) => {
    this.setState({
      active: expanded ? panel : -1,
    });
  };

  RenderStyle = () => {
    return {
      column: "TransactionType",
      condition: "Received",
      style: { fontWeight: "600" },
      prefix: ["+", ""],
    };
  };

  ExcelDownload = () => {
    if (!this.IsValid()) return;

    this.setState({ isLoading: true });
    const temp = { ...this.state.searchModel };
    var ids = [];
    this.state.checkedAccounts.forEach((element) => {
      ids.push(element.Id);
    });
    temp.AccountIds = ids;

    var keyValueObject = {};
    var keyvalueList = [];
    this.columns.forEach(function (gridColumnElement) {
      if (
        gridColumnElement.accessor != "ED" &&
        (gridColumnElement.show == undefined || gridColumnElement.show)
      ) {
        keyValueObject[gridColumnElement.accessor] = {
          value: gridColumnElement.accessor,
          title: gridColumnElement.Header,
          columntype: gridColumnElement.ColumnType,
          type: gridColumnElement.type,
        };
      }
    }, this);
    keyvalueList.push(keyValueObject);

    temp.jsonFile = {
      colmns: keyvalueList,
      format: "PDF",
      title: "Customer Wallet Activities Report",
    };

    Proxy.DownloadGeneratedFile(
      "/bankapi/v1.0/Transaction/ExcelDownloadCustomerActivities",
      temp,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
      },
      (errorMessage) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", errorMessage);
      },
      this.props.showMessage
    );
  };

  RenderItemClientCustomer = (d) => {
	return `${d.Name}`;
 };

  render() {
    const { Disabled } = this.props;
	 var IsClient = ClientHelper.IsClient();
    const {
      customerAccountList,
      checkedAccounts,
      searchModel,
      customerTransactions,
      TxnDetail,
      IsTxnDetailOpen,
      alert,
      kModel,
		vModel,
      model,
    } = this.state;
    var endDate =
      searchModel.FromDate instanceof moment
        ? searchModel.FromDate.clone()
        : "";

    var GridDataAccounts =
      customerAccountList == null
        ? []
        : customerAccountList.map((d) => {
            return {
              AP: (
                <div>
                  <GenericCheckInput
                    Grid={true}
                    Value={
                      checkedAccounts.filter((c) => c.Id == d.Id).length > 0
                    }
                    ValueChanged={(e) => {
                      this.HandleCheckCustomerAccounts(e, d);
                    }}
                  />
                </div>
              ),
              Id: d.Id,
              ProgramId: d.ProgramId,
              CustomerBankAccountId: d.CustomerBankAccountId,
              AccountShortName: d.AccountShortName,
              BankAccountId: d.BankAccountId,
              UniqueClientId: d.UniqueClientId,
              Balance: d.Balance,
              AccountName: d.AccountName,
              PendingBalance: d.PendingBalance,
              AccountNumber: d.AccountNumber,
              AccountNumberStr: d.AccountNumberStr,
              BranchNumber: d.BranchNumber,
              BankNumber: d.BankNumber,
              AccountTypeId: d.AccountTypeId,
              AccountType: d.AccountType,
              AccountType4: d.AccountType4,
              AccountType6: d.AccountType6,
              AccountCode: d.AccountCode,
              ProgramCode: d.ProgramCode,
              ProgramName: d.ProgramName,
              CurrencyCode: d.CurrencyCode,
              CurrencyDesc: d.CurrencyDesc,
              CurrencySymbol: d.CurrencySymbol,
              AmountDisplay: d.AmountDisplay,
              CustomerName: d.CustomerName,
              CustomerNumber: d.CustomerNumber,
            };
          });
    return (
      <div>
        <LoadingComponent Show={this.state.isLoading} />
        {alert}
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <ButtonToolbar
              ButtonList={[
                { Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
                {
                  Code: "Search",
                  OnClick: () => {
                    this.PerformSearch();
                    this.GetCustomerBankAccounts();
                  },
                  Disabled: Disabled,
                },
                {
                  Code: "Export",
                  OnClick: this.ExcelDownload,
                  Disabled: Disabled,
                },
              ]}
              menuId={this.props.menuId}
              ApprovalData={this.props.ApprovalData}
              After={this.props.After}
            />
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={" Main Parameters "} />
              </CardHeader>
              <b>
                <p style={{ paddingTop: "20px", marginLeft: "10px" }}></p>
              </b>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GridContainer>
						  <GridItem xs={3}>
                        <GenericSelectInput
                          Name="UniqueClientId"
                          LabelText="Client"
                          Method="POST"
                          Url="/bankapi/v1.0/BankCustomer/Search"
                          Parameter={{}}
                          DataRoot="Item"
                          KeyValueMember="Id"
                          RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                          Value={searchModel.UniqueClientId || ""}
                          ValueChanged={this.handleChangeSearchModel}
                          CanClear
                          All
                          Required
                          IsInvalid={searchModel.UniqueClientId}
                          Disabled={IsClient}
                          DefaultIndex={IsClient ? 0 : -1}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <GenericSelectInput
                          key={searchModel.UniqueClientId}
                          Name="ClientCustomerId"
                          LabelText="Customer Name"
                          Method="POST"
                          Url={
									searchModel.UniqueClientId
									  ? "/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
									  : ""
								 }
                          Parameter={{
									UniqueClientId: searchModel.UniqueClientId,
									ProgramCode: ProgramCodes.Prepaid,
                          }}
								  DataRoot="Item"
                          KeyValueMember="Id"
								  RenderItem={this.RenderItemClientCustomer}
                          Value={searchModel.ClientCustomerId}
                          ValueChanged={this.handleChangeSearchModel}
                          Required
                          IsInvalid={searchModel.ClientCustomerId}
                        />
                      </GridItem>

                      <GridItem xs={3}>
                        <ParameterComponent
                          Name="StatementPeriod"
                          LabelText="Statement Period"
                          Value={searchModel.StatementPeriod}
                          ValueChanged={this.handleChangeSearchModel}
                          KeyValueMember="ParameterValue2"
                          ParameterCode="StatementPeriod"
                          ParameterValue2Excludes={["31"]}
                          Required={searchModel.IsRequiredStatementPeriod}
                          IsInvalid={searchModel.IsInvalidStatementPeriod}
                          Disabled={searchModel.StatementControl}
                          Sorted={{ Member: "ParameterValue3" }}
                        />
                      </GridItem>

                      <GridItem xs={3}>
                        <GenericSelectInput
                          Name="ChannelType"
                          LabelText="Channel Name"
                          Value={searchModel.ChannelType || ""}
                          ValueChanged={this.handleChangeSearchModel}
                          KeyValueMember="ParameterValue"
                          TextValueMember="ParameterDesc"
                          Method="POST"
                          Url="/coreapi/v1.0/Parameter/Search"
                          Parameter={{ ParameterCode: "ChannelNameType" }}
                          DataRoot="Item"
                          Sorted={{ Member: "ParameterDesc" }}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <GenericSelectInput
                          Name="ShortType"
                          LabelText="Sort"
                          Value={searchModel.ShortType || ""}
                          ValueChanged={this.handleChangeSearchModel}
                          KeyValueMember="ParameterValue"
                          TextValueMember="ParameterDesc"
                          Method="POST"
                          Url="/coreapi/v1.0/Parameter/Search"
                          Parameter={{ ParameterCode: "ShortType" }}
                          DataRoot="Item"
                        />
                      </GridItem>
                    </GridContainer>
                    <GenericExpansionPanel
                      IsActive={true}
                      Title="Advance Filter"
                      AlignToRight
                    >
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Name="DebitCreditId"
                            All
                            LabelText="Received/Sent"
                            Value={searchModel.DebitCreditId || ""}
                            ValueChanged={this.handleChangeSearchModel}
                            KeyValueMember="Id"
                            TextValueMember="ParameterDesc"
                            Method="GET"
                            Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
                            DataRoot="Item"
                            RenderItem={this.TrxFunc}
                          />
                          <GenericNumberInput
                            Prefix="$ "
                            Name="MinAmount"
                            LabelText="Min. Amount"
                            Value={searchModel.MinAmount}
                            ValueChanged={this.handleChangeSearchModel}
                          />
                          <GenericNumberInput
                            NoFormatting
                            Name="ReferenceNumber"
                            LabelText="Reference Number"
                            Value={searchModel.ReferenceNumber}
                            ValueChanged={this.handleChangeSearchModel}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericDateInput
                            Name="FromDate"
                            LabelText="From Date"
                            LabelMd={4}
                            IsFuture={false}
                            MaxDate={DateHelper.GetDate()}
                            Value={
                              searchModel.FromDate == null
                                ? ""
                                : DateHelper.ToDateInputValueAsUtc(
                                    searchModel.FromDate
                                  ) || undefined
                            }
                            ValueChanged={this.handleChangeSearchModel}
                            Disabled={searchModel.DateControl}
                            Required={searchModel.IsRequiredDate}
                            IsInvalid={searchModel.IsInvalidFromDate}
                            Utc
                          />
                          <GenericNumberInput
                            Prefix="$ "
                            Name="MaxAmount"
                            LabelText="Max. Amount"
                            Value={searchModel.MaxAmount}
                            ValueChanged={this.handleChangeSearchModel}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericDateInput
                            Name="ToDate"
                            LabelText="To Date"
                            LabelMd={4}
                            IsFuture={false}
                            MinDate={searchModel.FromDate}
                            MaxDate={
                              endDate != ""
                                ? endDate.add(30, "days")
                                : undefined
                            }
                            Value={
                              searchModel.ToDate == null
                                ? ""
                                : DateHelper.ToDateInputValueAsUtc(
                                    searchModel.ToDate
                                  ) || ""
                            }
                            ValueChanged={this.handleChangeSearchModel}
                            Required={searchModel.IsRequiredDate}
                            IsInvalid={searchModel.IsInvalidToDate}
                            Disabled={searchModel.DateControl}
                            Utc
                          />
                          <GenericTextInput
                            Name="Explanation"
                            LabelText="Explanation"
                            Value={searchModel.Explanation || ""}
                            ValueChanged={this.handleChangeSearchModel}
                          />
                        </GridItem>
                      </GridContainer>
                    </GenericExpansionPanel>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Wallets"} />
              </CardHeader>
              <CardBody>
                <GridItem xs={12}>
                  <GridItem>
                    <GenericGrid
                      Data={GridDataAccounts}
                      HideButton={true}
                      Columns={[
                        {
                          Header: "Select",
                          accessor: "AP",
                          sortable: false,
                          filterable: false,
                          width: 60,
                          Footer: (
                            <GenericCheckInput
                              Grid={true}
                              Name="CheckAllAccounts"
                              LabelText=""
                              Value={searchModel.CheckAllAccounts || false}
                              ValueChanged={this.handleChangeSearchModel}
                            />
                          ),
                        },
                        {
                          Header: "Customer Name",
                          accessor: "CustomerName",
                        },
                        {
                          Header: "Customer Number",
                          accessor: "CustomerNumber",
                        },
                        {
                          Header: "Wallet Name",
                          accessor: "AccountName",
                          Footer: (
                            <span>
                              <strong>All Wallets</strong>{" "}
                            </span>
                          ),
                        },
                        {
                          Header: "Wallet Type",
                          accessor: "AccountType",
                          Cell: (row) => (
                            <div>
                              {this.RenderAccountTypeParameter(row.original)}{" "}
                            </div>
                          ),
                          Footer: (
                            <span>
                              <strong>
                                Total{"    "}{" "}
                                {customerAccountList == null
                                  ? 0
                                  : customerAccountList.length}
                              </strong>{" "}
                            </span>
                          ),
                        },
                        {
                          Header: "Program Name",
                          accessor: "ProgramName",
                        },
                        {
                          Header: "Wallet Number",
                          accessor: "AccountNumber",
                        },
                        {
                          Header: "Currency",
                          accessor: "CurrencyCode",
                        },
                        {
                          Header: "Balance",
                          accessor: "Balance",
                          Cell: (row) => (
                            <div>
                              {Formatter.FormatMoneySpecial(
                                row.original.CurrencySymbol,
                                row.value
                              )}
                            </div>
                          ),
                          // Footer: (
                          // 	<span>
                          // 		<strong>
                          // 			{Formatter.FormatMoney(customerAccountList.TotalAccountBalance)}
                          // 		</strong>
                          // 	</span>
                          // )
                        },

                        {
                          Header: "Pending Balance",
                          accessor: "PendingBalance",
                          Cell: (row) => (
                            <div>
                              {Formatter.FormatMoneySpecial(
                                row.original.CurrencySymbol,
                                row.value
                              )}
                            </div>
                          ),
                          // Footer: (
                          // 	<span>
                          // 		<strong>{Formatter.FormatMoney(customerAccountList.TotalAccountBalance)}</strong>
                          // 	</span>
                          // )
                        },
                      ]}
                      ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
                    />
                  </GridItem>
                </GridItem>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Transaction List"} />
              </CardHeader>
              <CardBody>
                <GridItem xs={12}>
                  <GridItem>
                    <GenericGrid
                      Data={customerTransactions}
                      ShowPagination={true}
                      PageSize={100}
                      Columns={this.columns}
                      IsSorted={false}
                    />
                  </GridItem>
                </GridItem>
              </CardBody>
            </Card>

            <GenericDialog open={IsTxnDetailOpen} maxWidth="md" fullWidth>
              <DialogTitle>Transaction Details</DialogTitle>
              <DialogContent>
                <p>
                  {" "}
                  Reference Number: <b>{TxnDetail.ReferenceNumber}</b>{" "}
                </p>
                <p>
                  {" "}
                  Transaction Date:{" "}
                  <b>
                    {Formatter.FormatDateUtc(TxnDetail.TransactionDate)}
                  </b>{" "}
                </p>
                <p>
                  {" "}
                  Transaction Amount:
                  <b>
                    {" "}
                    {Formatter.FormatMoneySpecial(
                      TxnDetail.CurrencySymbol,
                      TxnDetail.Amount
                    )}
                  </b>{" "}
                </p>
                <p>
                  {" "}
                  Description: <b>{TxnDetail.Description}</b>{" "}
                </p>
                <p>
                  {" "}
                  Transaction Type:{" "}
                  <b>
                    {TxnDetail.EtransferType == null
                      ? TxnDetail.TransactionType
                      : TxnDetail.EtransferType}
                  </b>{" "}
                </p>
                <p>
                  {" "}
                  From Wallet Name: <b>{TxnDetail.SourceAccountName}</b>{" "}
                </p>
                <p>
                  {" "}
                  From Wallet Number: <b>
                    {TxnDetail.SourceAccountNumber}
                  </b>{" "}
                </p>
                <p>
                  {" "}
                  To Wallet Name: <b>
                    {TxnDetail.DestinationAccountName}
                  </b>{" "}
                </p>
                <p>
                  {" "}
                  To Wallet Number: <b>
                    {TxnDetail.DestinationAccountNumber}
                  </b>{" "}
                </p>
                {/* {
							<ChequeTransactionImageDetail
							  chqtransaction={this.state.TxnDetail}/>
							  
							  } */}
              </DialogContent>
              <GenericDialogActions>
                <Button
                  size="sm"
                  onClick={() => this.setState({ IsTxnDetailOpen: false })}
                >
                  Close
                </Button>
              </GenericDialogActions>
            </GenericDialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CustomerAcvitiviteAccounts.propTypes = {
  classes: PropTypes.object,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
};

export default CustomerAcvitiviteAccounts;
