import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid,  GenericTextInput, GenericNumberInput } from "views/Components/Generic";

class FinancialInstitution extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Financial Institutio Id",
				accessor: "FinancialInstitutionId",
				width:200
			},
			{
				Header: "Description",
				accessor: "Description",

			},

		];

		this.state = { model: {} };

	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SYSTEM ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Canada Financial Institutions List");
		}
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	Search = async () => {
		const { ExecuteApiGet } = this.props;
		var result = await ExecuteApiGet("/bankapi/v1.0/FinancialInstitution/GetAll", null, null, null, null);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.FinancialInstitutionId) {
			errors.push("Financial Institution Id can not be null.");
		}
		if (!model.Description) {
			errors.push("Description can not be null.");
		}

		model["IsFinancialInstitutionId"] = !model.FinancialInstitutionId;
		model["IsDescription"] = !model.Description;
	 
		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}
	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/bankapi/v1.0/FinancialInstitution/Insert", model, null, null, this.Validate, [this.Clear], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiPost("/bankapi/v1.0/FinancialInstitution/Update", model, null, null, this.Validate, [this.Clear], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/FinancialInstitution/DeleteFinancialInstitution", { Id: model.Id }, null, null, null, [this.Clear,this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Financial Institutions">
									<GridContainer>
										<GridItem xs={3}>

											<GenericNumberInput
												NoFormatting={true}
												LabelText="Financial Institution Id"
												Name="FinancialInstitutionId"
												Value={model.FinancialInstitutionId}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												MaxLength={5}
												IsInvalid={model.IsFinancialInstitutionId}
												Required
											/></GridItem>
												<GridItem xs={3}>
											<GenericTextInput
												Required
												IsInvalid={model.IsDescription}
												Name="Description"
												LabelText="Description"
												Value={model.Description}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									 		</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													RowSelected={this.RowSelected}
													ShowPagination={true}
													PageSize={100}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FinancialInstitution.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FinancialInstitution;