import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, IdentificationMethod } from "views/Constants/Constant.js";

const styles = ({
	...sweetAlertStyle
});
class BenificialOwner extends Component {

	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			ClientCustomerId: props.ClientCustomerId
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			list: [],
			alert: "",
			isLoading: false,
			isEdit: false,
			index: -1
		};

		this.maxLength20 = { maxLength: 20 };
		this.maxLength30 = { maxLength: 30 };

		this.emptyObject = {};

		this.parameterIdentificationMethod = {
			ParameterCode: "IdentificationMethod"
		};

		this.parameterIdentityType = {
			ParameterCode: "IdentityType"
		};

		this.parameterIdentityVerifyingPerson = {
			ParameterCode: "IdentityVerifyingPerson"
		};

		this.sortedParameterDesc = { Member: "ParameterDesc" };

		this.Columns = [
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "% of Ownership",
				accessor: "OwnerShipPercentage"
			},
			{
				Header: "Identification Method",
				accessor: "IdentificationMethod1.ParameterDesc"
			},
			{
				Header: "Person Verifying",
				accessor: "IdentityVerifyingPerson1.ParameterDesc"
			},
			{
				Header: "Identification Type",
				accessor: "IdentityType1.ParameterDesc"
			},
			{
				Header: "Identification No.",
				accessor: "IdentityNumber1"
			},
			{
				Header: "Place of Issue",
				accessor: "IdentityPlaceOfIssue1"
			},
			{
				Header: "ID Exp Date",
				accessor: "IdentityExpireDate1",
				type: GridColumnType.Date
			}
		];
	}

	componentDidMount() {
		this.HandleSearch();
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		if (name == "IdentificationMethodId1" && data != null) {
			model.IdentificationMethod1 = data;
			model.MustFillIdVerification = data && (data.ParameterValue === IdentificationMethod.InPersonIDVerification || data.ParameterValue === IdentificationMethod.CreditFileMethod);
		} else if (name == "IdentityTypeId1" && data != null) {
			model.IdentityFormat1 = data.ParameterValue3;
			model.IdentityLength1 = data.ParameterValue4;
		}

		if (name == "OwnerShipPercentage" && newValue > 100) {
			return;
		}

		this.setState({ model });
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		model.MustFillIdVerification = model.IdentificationMethod1 && (model.IdentificationMethod1.ParameterValue === IdentificationMethod.InPersonIDVerification || model.IdentificationMethod1.ParameterValue === IdentificationMethod.CreditFileMethod);
		this.setState({ isEdit: true, model, index });
	}

	HandleSearch = () => {
		this.setState({ isLoading: false });
		Proxy.POST(
			"/bankapi/v1.0/CustomerCorporateAuthOwner/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	AddBenificialOwnerInfo = () => {
		if (!this.Validate()) {
			return;
		}
		this.props.showConfirmMessage("warning", "Save", "Do you want to save Beneficial Owner Information ?", () => this.AddBenificialOwnerInfoOK());
	}

	DeleteBenificialOwnerInfo = () => {
		this.props.showConfirmMessage("warning", "Delete", "Do you want to delete Beneficial Owner Information ?", () => this.DeleteBenificialOwnerInfoOK());
	}

	ClearBenificialOwnerInfo = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	AddBenificialOwnerInfoOK = () => {
		const model = { ...this.state.model };
		delete model.IdentificationMethod1;
		this.setState({ isLoading: false });
		Proxy.POST(
			"/bankapi/v1.0/CustomerCorporateAuthOwner/Update",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.props.showMessage("success", "Succeeded", "Operation is successfully!");
				this.ClearBenificialOwnerInfo();
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	DeleteBenificialOwnerInfoOK = () => {
		this.setState({ isLoading: false });
		Proxy.GET(
			"/bankapi/v1.0/CustomerCorporateAuthOwner/Delete/Id?Id=" + this.state.model.Id,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.props.showMessage("success", "Succeeded", "Deleted successfully!");
				this.ClearBenificialOwnerInfo();
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.Name = model.Name == null || model.Name.trim() == "";
		if (vModel.Name) messages.push("Name cannot be empty");

		vModel.OwnerShipPercentage = model.OwnerShipPercentage == null || model.OwnerShipPercentage == 0;
		if (vModel.OwnerShipPercentage) messages.push("% of Ownership cannot be empty");

		vModel.IdentityExpireDate1 = model.IdentityExpireDate1 == null;
		if (vModel.IdentityExpireDate1) messages.push("Identity Expire Date cannot be empty");

		vModel.IdentificationMethodId1 = model.IdentificationMethodId1 == null;
		if (vModel.IdentificationMethodId1) messages.push("Identity Method cannot be empty");

		vModel.IdentityVerifyingPersonId1 = model.MustFillIdVerification == true && model.IdentityVerifyingPersonId1 == null;
		if (vModel.IdentityVerifyingPersonId1) messages.push("Person Verifying cannot be empty");

		vModel.IdentityTypeId1 = model.IdentityTypeId1 == null;
		if (vModel.IdentityTypeId1) messages.push("Identification Type cannot be empty");

		vModel.IdentityNumber1 = model.IdentityNumber1 == null;
		if (vModel.IdentityNumber1) messages.push("Identification No. cannot be empty");

		vModel.IdentityPlaceOfIssue1 = model.IdentityPlaceOfIssue1 == null;
		if (vModel.IdentityPlaceOfIssue1) messages.push("Identification Place of Issue cannot be empty");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	render() {
		const { ReadOnly } = this.props;
		const { isLoading, model, vModel, list, alert, isEdit } = this.state;

		return (
			<>

				{alert}

				<LoadingComponent Show={isLoading} />

				<GenericExpansionPanel
					Title="Beneficial Owner Information"
					IsActive={true}>
					<GridContainer>
						<GridItem xs={4}>
							<GridItem>
								<GenericTextInput
									Name="Name"
									inputProps={{ maxLength: 20 }}
									LabelText="Name"
									Value={model.Name}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.Name} />
							</GridItem>
							<GridItem>
								<GenericNumberInput
									Name="OwnerShipPercentage"
									MaxLength={4}
									LabelText="% of Ownership"
									Value={model.OwnerShipPercentage}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.OwnerShipPercentage} />
							</GridItem>
							<GridItem>
								<GenericDateInput
									Name="IdentityExpireDate1"
									MinDate={DateHelper.GetDate()}
									LabelText="ID Expire Date"
									Value={model.IdentityExpireDate1 == null ? "" : DateHelper.ToDateInputValue(model.IdentityExpireDate1)}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.IdentityExpireDate1} />
							</GridItem>
						</GridItem>
						<GridItem xs={4}>
							<GridItem>
								<GenericSelectInput
									Name="IdentificationMethodId1"
									LabelText="Identification Method"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterIdentificationMethod}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={model.IdentificationMethodId1}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.IdentificationMethodId1} />
							</GridItem>
							{model.MustFillIdVerification &&
								<GridItem>
									<GenericSelectInput
										Name="IdentityVerifyingPersonId1"
										LabelText="Person Verifying"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterIdentityVerifyingPerson}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.IdentityVerifyingPersonId1}
										ValueChanged={this.HandleChange}
										Disabled={ReadOnly}
										Required
										IsInvalid={vModel.IdentityVerifyingPersonId1} />
								</GridItem>
							}
							<GridItem>
								<GenericSelectInput
									Name="IdentityTypeId1"
									LabelText="Identification Type"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterIdentityType}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Sorted={this.sortedParameterDesc}
									Value={model.IdentityTypeId1}
									ValueChanged={this.HandleChange}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.IdentityTypeId1} />
							</GridItem>
						</GridItem>
						<GridItem xs={4}>
							<GridItem>
								<GenericTextInput
									Name="IdentityNumber1"
									LabelText="Identification No."
									Value={model.IdentityNumber1}
									ValueChanged={this.HandleChange}
									inputProps={this.maxLength20}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.IdentityFormat1} />
							</GridItem>
							<GridItem>
								<GenericTextInput
									Name="IdentityPlaceOfIssue1"
									LabelText="ID Place of Issue"
									Value={model.IdentityPlaceOfIssue1}
									ValueChanged={this.HandleChange}
									inputProps={this.maxLength30}
									Disabled={ReadOnly}
									Required
									IsInvalid={vModel.IdentityPlaceOfIssue1} />
							</GridItem>
						</GridItem>
						<GridItem xs={8} />
						<GridItem xs={4}>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button size="sm" onClick={this.AddBenificialOwnerInfo} disabled={ReadOnly}>{!isEdit ? "ADD" : "EDIT"}</Button>
									<Button size="sm" onClick={this.DeleteBenificialOwnerInfo} disabled={ReadOnly}>DELETE</Button>
									<Button size="sm" onClick={this.ClearBenificialOwnerInfo} disabled={ReadOnly}>CLEAR</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12}>
							<br />
							<GenericGrid
								Data={list}
								Columns={this.Columns}
								RowSelected={this.RowSelected}
								SelectedIndex={this.state.index}
								HideButton={true}
							/>
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>

			</>
		);
	}
}

BenificialOwner.propTypes = {
	classes: PropTypes.object,
	showMessage: PropTypes.func.isRequired,
	showMessageNode: PropTypes.func.isRequired,
	showConfirmMessage: PropTypes.func.isRequired,
	ClientCustomerId: PropTypes.number,
	ReadOnly: PropTypes.bool
};

export default withArtifex(BenificialOwner, styles);