import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import HtmlEditorHelper from "core/HtmlEditorHelper";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import CampaignCouponTemplate from "views/Campaign/Components/CampaignCouponTemplate";
import { GenericAlert, GenericCheckInput, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CampaignEngineMethodType, CampaignExecutionType } from "views/Constants/Constant.js";


class CampaignEvaulate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			Profile: {},
			AppiledCustomerList: [],
			CampaignCriteriaList: [],
			CampaignMethodList: [],
			AppiledActionList: [],
			alert: null,
			isLoading: false,
			isDetailDialogOpen: false,
			CustomerActionModel: {}
		};

		this.ColumnsDataCriteria = [
			{
				Header: "Program",
				accessor: "CampaignProfile.Program.Description"
			},
			{
				Header: "ExecutionType",
				accessor: "CampaignProfile.ExecutionType.ParameterDesc"
			},
			{
				Header: "Profile Name",
				accessor: "CampaignProfile.ProfileName"
			},
			{
				Header: "Expression Type",
				accessor: "ExpressionType.ParameterDesc"
			},
			{
				Header: "Right Value",
				accessor: "RightValue"
			},
			{
				Header: "Property Name",
				accessor: "DataModelProperty.PropertyName"
			},
			{
				Header: "Data Model",
				accessor: "DataModelProperty.DataModelDefinition.Description"
			}
		];
		this.ColumnsDataMethod = [
			{
				Header: "Program",
				accessor: "CampaignProfile.Program.Description"
			},
			{
				Header: "ExecutionType",
				accessor: "CampaignEngineMethod.ExecutionType.ParameterDesc"
			},
			{
				Header: "Profile Name",
				accessor: "CampaignProfile.ProfileName"
			},
			{
				Header: "Method Name",
				accessor: "CampaignEngineMethod.MethodName"
			},
			{
				Header: "Method Description",
				accessor: "CampaignEngineMethod.MethodDescription"
			},
			{
				Header: "Engine Description",
				accessor: "CampaignEngineMethod.EngineDefinition.EngineDescription"
			}
		];
		this.ColumnsDataAppliedCustomer = [
			{
				Header: "Id",
				accessor: "Id"
			},
			{
				Header: "Unique Client ID",
				accessor: "UniqueClientId"
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomerNumber"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Email",
				accessor: "Email"
			},
			{
				Header: "Phone",
				accessor: "PhoneNumber"
			}
		];
		this.ColumnsDataAppliedActions = [
			{
				Header: "Message",
				accessor: "Message",
				Cell: row => (
					<div>
						<Button justIcon simple size="sm" tooltip="Edit" onClick={() => this.setState({ isDetailDialogOpen: true, CustomerActionModel: row.original })}>
							<img src={EditIcon} />
						</Button>
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Id",
				accessor: "Customer.Id"
			},
			{
				Header: "Unique Client ID",
				accessor: "Customer.UniqueClientId"
			},
			{
				Header: "Customer Number",
				accessor: "Customer.ClientCustomerNumber"
			},
			{
				Header: "Customer Name",
				accessor: "Customer.CustomerName"
			},
			{
				Header: "Customer Number",
				accessor: "Customer.CustomerNumber"
			},
			{
				Header: "Email",
				accessor: "Customer.Email"
			},
			{
				Header: "Phone",
				accessor: "Customer.PhoneNumber"
			},
			{
				Header: "Notication Type",
				accessor: "Customer.NoticationType"
			},
			{
				Header: "Method Name",
				accessor: "MethodName"
			},
			{
				Header: "Method Type",
				accessor: "MethodType"
			}
		];
		this.ParameterEvaulateCampaignDateInterval = { ParameterCode: "EvaulateCampaignDateInterval" };
		this.sortedParameterValue = { Member: "ParameterValue2" };

	}

	componentDidMount() {


	}
	FetchProfile = () => {
		var ProfileId = this.props.ProfileId;

		if (ProfileId == null || ProfileId == 0) {
			console.log("Profile Id not valid ");
			return;
		}

		const temp = this.state.model;
		temp.ProfileId = ProfileId;
		temp.ExecutionType = this.props.ExecutionType;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignProfile/Simulate",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.setState({
						Profile: responseData.Item || {},
						CampaignCriteriaList: responseData.Item.CampaignCriteriaList || [],
						CampaignMethodList: responseData.Item.CampaignMethodList || [],
						AppiledActionList: responseData.Item.AppiledActionList || [],
						AppiledCustomerList: responseData.Item.AppiledCustomerList || [],
					});
				} else {
					this.setState({
						Profile: {},
						CampaignCriteriaList: [],
						CampaignMethodList: [],
						AppiledActionList: [],
						AppiledCustomerList: []
					});
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};

			if (name == "IsRealTimeData" && newValue == true) {
				temp.EvaulateCampaignDateInterval = null;
			}
			if (name == "EvaulateCampaignDateInterval") {
				temp.IsRealTimeData = false;
			}

			temp[name] = newValue;
			return { model: temp };
		});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}



	render() {
		const { classes, ExecutionType } = this.props;
		const { Profile, CampaignCriteriaList, CampaignMethodList, AppiledActionList, CustomerActionModel, AppiledCustomerList, model } = this.state;
		return (
			<div>
				{this.state.alert}
				
				<LoadingComponent Show={this.state.isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GridContainer >
							<GridItem xs={3}>
								{((ExecutionType != null && ExecutionType != "") && ExecutionType == CampaignExecutionType.Online) && (
									<GenericSelectInput
										Name="EvaulateCampaignDateInterval"
										LabelText="Time Interval"
										Value={model.EvaulateCampaignDateInterval}
										ValueChanged={this.handleChange}
										KeyValueMember="ParameterValue2"
										Sorted={this.sortedParameterValue}  	
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterEvaulateCampaignDateInterval}
										DataRoot="Item"
									/>
								)}
								{((ExecutionType != null && ExecutionType != "") && ExecutionType == CampaignExecutionType.Batch) && (
									<GenericCheckInput
										Disabled={true}
										LabelMd={10}
										Name="IsRealTimeData"
										LabelText="Test Real Data"
										Value={true}
										ValueChanged={this.handleChange}
									/>
								)}
							</GridItem>
							<GridItem xs={1}>

							</GridItem>
							<GridItem xs={2}>
								<Button disabled={!this.props.ProfileId > 0} size="sm" onClick={() => this.FetchProfile()}>Evaulate</Button>
							</GridItem>
						</GridContainer>
						<hr />
						<GridContainer >
							<GridItem xs={2}>
								<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Applied Customer: {Profile.TotalCustomer} </p></b>
							</GridItem>
							<GridItem xs={2}>
								<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Total Action: {Profile.TotalAction} </p></b>
							</GridItem>
							<GridItem xs={2}>
								<b>	<p style={{ paddingTop: "5px", marginLeft: "5px" }}>Total Criteria: {Profile.TotalCriteria} </p></b>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Criterias {CampaignCriteriaList == null ? (0) : (CampaignCriteriaList.length)} </p></b>
						<GridItem xs={12}>
							<GenericGrid
								HideButton={true}
								Data={CampaignCriteriaList}
								Columns={this.ColumnsDataCriteria}
							/>
						</GridItem>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Methods {CampaignMethodList == null ? (0) : (CampaignMethodList.length)}  </p></b>
						<GridItem xs={12}>
							<GenericGrid
								HideButton={true}
								Data={CampaignMethodList}
								Columns={this.ColumnsDataMethod}
							/>
						</GridItem>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Applied Customers {AppiledCustomerList == null ? (0) : (AppiledCustomerList.length)}</p></b>
						<GridItem xs={12}>
							<GenericGrid
								HideButton={true}
								Data={AppiledCustomerList}
								Columns={this.ColumnsDataAppliedCustomer}
							/>
						</GridItem>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Applied Actions {AppiledActionList == null ? (0) : (AppiledActionList.length)} </p></b>
						<GridItem xs={12}>
							<GenericGrid
								HideButton={true}
								Data={AppiledActionList}
								Columns={this.ColumnsDataAppliedActions}
							/>
						</GridItem>
					</CardBody>
				</Card>


				<GenericDialog open={this.state.isDetailDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								{CustomerActionModel.MethodType == CampaignEngineMethodType.Notification && (
									<GenericLabel FontSize="16px" TextColor="black" Text="Notification Detail" Bold={true} />
								)}
								{CustomerActionModel.MethodType == CampaignEngineMethodType.ICoupon && (
									<GenericLabel FontSize="16px" TextColor="black" Text="Coupon Detail" Bold={true} />
								)}
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						{CustomerActionModel.MethodType == CampaignEngineMethodType.Notification && (
							<div>
								{CustomerActionModel.MethodName == "Sms" && (
									<div>
										<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Phone Number: {CustomerActionModel.PhoneNumber} </p></b>
										<hr />
										{CustomerActionModel.SmsContent}
										<hr /><br />
									</div>
								)}

								{CustomerActionModel.MethodName == "EMail" && (
									<div>
										<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Email: {CustomerActionModel.Email} </p></b>
										<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Subject: {CustomerActionModel.MailSubject} </p></b>
										<hr />
										<div>{HtmlEditorHelper.HtmlContentToReact(CustomerActionModel.MailContent)}</div>
										<hr /><br />
									</div>
								)}
							</div>
						)}
						{CustomerActionModel.MethodType == CampaignEngineMethodType.ICoupon && (
							<CampaignCouponTemplate model={CustomerActionModel.CouponData} />
						)}
						{CustomerActionModel.MethodType == CampaignEngineMethodType.FreeProduct && (
							<div>
								<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Email: {CustomerActionModel.Email} </p></b>
								<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Subject: {CustomerActionModel.MailSubject} </p></b>
								<hr />
								<div>{CustomerActionModel.MailContent}</div>
								<hr /><br />
							</div>
						)}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isDetailDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

CampaignEvaulate.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CampaignEvaulate, {});