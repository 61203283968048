import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericDateInput, GenericEmailInput, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

const styles = ({
	...sweetAlertStyle
});
class CDICCorporateAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			benificialOwnerInfoModel: {},
			benificialOwnerInfoList: [],
			authorizedSigningOfficerInfoModel: {},
			authorizedSigningOfficerInfoList: [],
			alert: "",
			isLoading: false,
			ownerTableLoading: false,
			officerTableLoading: false,
			IsEmailValid: true,
			Phone: {}
		};
	}

	componentDidMount() {
		this.GetOwner(this.props.propModel.Id);
		this.GetOfficer(this.props.propModel.Id);
	}

	handleChangeBenificialOwnerInfoModel = (name, newValue) => {
		const benificialOwnerInfoModel = { ...this.state.benificialOwnerInfoModel };
		benificialOwnerInfoModel[name] = newValue;
		this.setState({ benificialOwnerInfoModel });
	}

	handleChangeauthorizedSigningOfficerInfoModel = (name, newValue, data) => {
		const authorizedSigningOfficerInfoModel = { ...this.state.authorizedSigningOfficerInfoModel };
		var { IsEmailValid } = this.state;
		if (name == "Email") {
			IsEmailValid = data.IsValid;
		}
		authorizedSigningOfficerInfoModel[name] = newValue;
		this.setState({ authorizedSigningOfficerInfoModel, IsEmailValid });
	}

	handleChangePhone = (name, newValue) => {
		const Phone = { ...this.state.Phone };
		Phone[name] = newValue;
		this.setState({ Phone });
	}

	SelectedRowChange(index, type) {
		var tempModel = {};
		var Phone = { ...this.state.Phone };
		if (type == "owner") {
			tempModel = this.state.benificialOwnerInfoList[index];
			this.setState({ benificialOwnerInfoModel: tempModel });
		}
		if (type == "officer") {
			tempModel = this.state.authorizedSigningOfficerInfoList[index];
			Phone.PhoneCountryCodeId = tempModel.Phone.PhoneCountryCodeId;
			Phone.PhoneNumber = tempModel.Phone.PhoneNumber;
			this.setState({ authorizedSigningOfficerInfoModel: tempModel, Phone });
		}
	}

	GetOwner(Id) {
		this.setState({ ownerTableLoading: true, isLoading: false });
		Proxy.GET(
			"/bankapi/v1.0/Cdic/GetAllCdicCorpDetailBeneficalOwnerById/Id?Id=" + Id,
			this.successGetOwnerCallback,
			this.errorCallBack
		);
	}

	successGetOwnerCallback = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showAlert("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.length < 1) {
			this.setState({ benificialOwnerInfoList: [] });
		} else {
			this.setState({
				benificialOwnerInfoList: responseData.Item.map(owner => {
					return {
						Id: owner.Id == null || undefined ? "" : owner.Id,
						Name: owner.Name == null || undefined ? "" : owner.Name,
						OwnerPercentage: owner.OwnerPercentage == null || undefined ? "" : owner.OwnerPercentage,
						IdentificationMethodId: owner.IdentificationMethodId == null || undefined ? "" : owner.IdentificationMethodId,
						IdentificationMethod: owner.IdentificationMethod == null || undefined ? "" : owner.IdentificationMethod.ParameterDesc == null || undefined ? "" : owner.IdentificationMethod.ParameterDesc,
						IdentityTypeId: owner.IdentityTypeId == null || undefined ? "" : owner.IdentityTypeId,
						IdentityType: owner.IdentityType == null || undefined ? "" : owner.IdentityType.ParameterDesc == null || undefined ? "" : owner.IdentityType.ParameterDesc,
						IdentityNumber: owner.IdentityNumber == null || undefined ? "" : owner.IdentityNumber,
						PlaceOfIssueId: owner.PlaceOfIssueId == null || undefined ? "" : owner.PlaceOfIssueId,
						PlaceOfIssue: owner.PlaceOfIssue == null || undefined ? "" : owner.PlaceOfIssue.Name == null || undefined ? "" : owner.PlaceOfIssue.Name,
						IdentityExpireDate: owner.IdentityExpireDate == null || undefined ? null : owner.IdentityExpireDate,
						DirectorName: owner.DirectorName == null || undefined ? "" : owner.DirectorName,
					};
				})
			});
		}
	}

	AddBenificialOwnerInfo = () => {
		this.setState({
			alert: <GenericAlert Title={"Save"} Message={"Do you want to save Beneficial Owner Information ?"} Type={"info"} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.AddBenificialOwnerInfoOK()} />
		});
	}

	DeleteBenificialOwnerInfo = () => {
		this.setState({
			alert: <GenericAlert Title={"Delete"} Message={"Do you want to delete Beneficial Owner Information ?"} Type={"info"} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteBenificialOwnerInfoOK()} />
		});
	}

	ClearBenificialOwnerInfo = () => {
		this.setState({ benificialOwnerInfoModel: {} });
	}

	AddBenificialOwnerInfoOK = () => {
		this.hideAlert();
		var benificialOwnerInfoModel = { ...this.state.benificialOwnerInfoModel };
		benificialOwnerInfoModel.CustomerCdicCorpDetailId = this.props.propModel.Id;
		delete benificialOwnerInfoModel.IdentificationMethod;
		delete benificialOwnerInfoModel.IdentityType;
		delete benificialOwnerInfoModel.PlaceOfIssue;
		this.setState({ benificialOwnerInfoModel, isLoading: true });
		if (benificialOwnerInfoModel.Name == null || undefined ||
			benificialOwnerInfoModel.OwnerPercentage == null || undefined ||
			benificialOwnerInfoModel.IdentityTypeId == null || undefined ||
			benificialOwnerInfoModel.IdentityNumber == null || undefined) {
			this.showAlert("warning", "Not Null", "Benefical Owner Information(Name, Ownership,Identity Type, Identity Number) is not be null !!");
		}
		else {
			Proxy.POST(
				"/bankapi/v1.0/Cdic/InsertOrUpdateOwner",
				benificialOwnerInfoModel,
				this.successOwnerCallback,
				this.errorCallBack
			);
		}

	}
	successOwnerCallback = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showAlert("error", "Error", responseData.ErrorDescription);
			return;
		}
		else {
			if (responseData.Item == null) this.showAlert("error", "Error", "Response Data is null !!");
			else {
				this.GetOwner(this.props.propModel.Id);
				this.setState({ benificialOwnerInfoModel: responseData.Item });
				this.showAlert("success", "Save", "Saved successfully !!");
			}
		}
	}

	DeleteBenificialOwnerInfoOK = () => {
		this.hideAlert();
		this.showLoading();
		Proxy.GET(
			"/bankapi/v1.0/Cdic/DeleteOwner/Id?Id=" + this.state.benificialOwnerInfoModel.Id,
			this.successDeleteOwnerCallback,
			this.errorCallBack
		);
	}

	successDeleteOwnerCallback = () => {
		this.hideLoading();
		this.ClearBenificialOwnerInfo();
		this.GetOwner(this.props.propModel.Id);
	}

	GetOfficer = (Id) => {
		this.setState({ officerTableLoading: true, isLoading: false });
		Proxy.GET(
			"/bankapi/v1.0/Cdic/GetAllCdicCorpDetailAuthorizedSigningOfficerById/Id?Id=" + Id,
			this.successGetOfficerCallback,
			this.errorCallBack
		);
	}

	successGetOfficerCallback = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showAlert("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.length < 1) {
			this.setState({ authorizedSigningOfficerInfoList: [] });
		} else {
			this.setState({
				authorizedSigningOfficerInfoList: responseData.Item.map(officer => {
					return {
						Id: officer.Id == null || undefined ? "" : officer.Id,
						Name: officer.Name == null || undefined ? "" : officer.Name,
						TitleId: officer.TitleId == null || undefined ? "" : officer.TitleId,
						Title: officer.Title == null || undefined ? "" : officer.Title.ParameterDesc == null || undefined ? "" : officer.Title.ParameterDesc,
						Phone: officer.Phone == null || undefined ? null : officer.Phone,
						PhoneCountryCode: officer.Phone == null || undefined ? "" : officer.Phone.PhoneCountryCode == null ? "" : officer.Phone.PhoneCountryCode.Code == null ? "" : "+" + officer.Phone.PhoneCountryCode.Code,
						PhoneNumber: officer.Phone == null ? "" : officer.Phone.PhoneNumber == null ? "" : officer.Phone.PhoneNumber,
						Email: officer.Email == null || undefined ? "" : officer.Email,
						IdentificationMethodId: officer.IdentificationMethodId == null || undefined ? "" : officer.IdentificationMethodId,
						IdentificationMethod: officer.IdentificationMethod == null || undefined ? "" : officer.IdentificationMethod.ParameterDesc == null || undefined ? "" : officer.IdentificationMethod.ParameterDesc,
						IdentityTypeId: officer.IdentityTypeId == null || undefined ? "" : officer.IdentityTypeId,
						IdentityType: officer.IdentityType == null || undefined ? "" : officer.IdentityType.ParameterDesc == null || undefined ? "" : officer.IdentityType.ParameterDesc,
						IdentityNumber: officer.IdentityNumber == null || undefined ? "" : officer.IdentityNumber,
						PlaceOfIssueId: officer.PlaceOfIssueId == null || undefined ? "" : officer.PlaceOfIssueId,
						PlaceOfIssue: officer.PlaceOfIssue == null || undefined ? "" : officer.PlaceOfIssue.Name == null || undefined ? "" : officer.PlaceOfIssue.Name,
						IdentityExpireDate: officer.IdentityExpireDate == null || undefined ? null : officer.IdentityExpireDate
					};
				})
			});
		}
	}

	AddAuthorizedSigningOfficerInfo = () => {
		this.setState({
			alert: <GenericAlert Title={"Save"} Message={"Do you want to save Authorized Signing Officer Information ?"} Type={"info"} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.AddAuthorizedSigningOfficerInfoOK()} />
		});
	}

	ClearAuthorizedSigningOfficerInfo = () => {
		this.setState({ authorizedSigningOfficerInfoModel: {}, Phone: {} });
	}

	AddAuthorizedSigningOfficerInfoOK = () => {
		var authorizedSigningOfficerInfoModel = { ...this.state.authorizedSigningOfficerInfoModel };
		authorizedSigningOfficerInfoModel.CustomerCdicCorpDetailId = this.props.propModel.Id;
		delete authorizedSigningOfficerInfoModel.IdentificationMethod;
		delete authorizedSigningOfficerInfoModel.IdentityType;
		delete authorizedSigningOfficerInfoModel.PlaceOfIssue;
		delete authorizedSigningOfficerInfoModel.Title;
		authorizedSigningOfficerInfoModel.Phone = this.state.Phone;
		this.setState({ authorizedSigningOfficerInfoModel });
		if (authorizedSigningOfficerInfoModel.Name == null || undefined ||
			authorizedSigningOfficerInfoModel.Email == null || undefined ||
			authorizedSigningOfficerInfoModel.IdentityTypeId == null || undefined ||
			authorizedSigningOfficerInfoModel.IdentityNumber == null || undefined) {
			this.showAlert("warning", "Not Null", "Authorized Signing Officer Information(Name, Email,Identity Type, Identity Number) is not be null !!");
		}
		else {
			if (!this.state.IsEmailValid) {
				this.showAlert("warning", "Warning", "Email is invalid !!!");
			}
			else {
				Proxy.POST(
					"/bankapi/v1.0/Cdic/InsertOrUpdateOfficcer",
					authorizedSigningOfficerInfoModel,
					this.successOfficerCallback,
					this.errorCallBack
				);
			}
		}
	}

	successOfficerCallback = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showAlert("error", "Error", responseData.ErrorDescription);
			return;
		}
		else {
			if (responseData.Item == null) this.showAlert("error", "Error", "Response Data is null !!");
			else {
				this.GetOfficer(this.props.propModel.Id);
				this.setState({ authorizedSigningOfficerInfoModel: responseData.Item });
				this.showAlert("success", "Save", "Saved successfully !!");
			}
		}
	}

	DeleteAuthorizedSigningOfficerInfo = () => {
		this.setState({
			alert: <GenericAlert Title={"Delete"} Message={"Do you want to delete Authorized Signing Officer Information ?"} Type={"info"} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteAuthorizedSigningOfficerInfoOK()} />
		});
	}

	DeleteAuthorizedSigningOfficerInfoOK = () => {
		this.hideAlert();
		this.showLoading();
		Proxy.GET(
			"/bankapi/v1.0/Cdic/DeleteOfficer/Id?Id=" + this.state.authorizedSigningOfficerInfoModel.Id,
			this.successDeleteOfficerCallback,
			this.errorCallBack
		);
	}

	successDeleteOfficerCallback = () => {
		this.hideLoading();
		this.ClearAuthorizedSigningOfficerInfo();
		this.GetOfficer(this.props.propModel.Id);
	}

	errorCallBack = (error) => {
		this.hideLoading();
		this.showAlert("error", "Error", error);
	}

	showAlert = (type, title, message) => {
		this.setState({
			ownerTableLoading: false,
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	hideAlert = () => { this.setState({ alert: null }); }
	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false, ownerTableLoading: false, officerTableLoading: false }); }
	render() {
		const { isLoading, benificialOwnerInfoModel, benificialOwnerInfoList, authorizedSigningOfficerInfoModel, authorizedSigningOfficerInfoList, alert, Phone } = this.state;
		const { propModel } = this.props;
		return (
			<div>
				{alert}


				<LoadingComponent Show={isLoading} />

				<GridContainer>
					<GridItem xs={6}>
						<h5><b>Corporate Wallet</b></h5>
						<GenericTextInput
							Name="BusinessIdNumber"
							LabelText="Business ID Nr. (BIN)"
							Value={propModel == null || undefined ? "" : propModel.BusinessIdNumber == null || undefined ? "" : propModel.BusinessIdNumber}
							Disabled={true} />
						<GenericTextInput
							Name="LegalName"
							LabelText="Legal Name of Business"
							Value={propModel == null || undefined ? "" : propModel.LegalNameOfCompany == null || undefined ? "" : propModel.LegalNameOfCompany}
							Disabled={true} />
						<GenericDateInput
							Name="EstablishedDate"
							LabelText="ID Exp Date"
							Value={propModel == null || undefined ? "" : propModel.EstablishedDate == null || undefined ? "" : DateHelper.ToDateInputValue(propModel.EstablishedDate)}
							Disabled={true} />
					</GridItem>
					<GridItem xs={6}>
						<h5><b>Communication</b></h5>
						<GenericTextInput
							Name="Address1"
							LabelText="Address Line 1"
							Value={propModel == null || undefined ? "" : propModel.Address1 || ""}
							Disabled={true} />
						<GenericTextInput
							Name="Address2"
							LabelText="Address Line 2"
							Value={propModel == null || undefined ? "" : propModel.Address2 || ""}
							Disabled={true} />
						<GenericTextInput
							Name="Province"
							LabelText="Province"
							Value={propModel == null || undefined ? "" : propModel.Provience || ""}
							Disabled={true} />
						<GenericTextInput
							Name="City"
							LabelText="City"
							Value={propModel == null || undefined ? "" : propModel.City || ""}
							Disabled={true} />
						<GenericTextInput
							Name="ZipCode"
							LabelText="Postal Code"
							Value={propModel == null || undefined ? "" : propModel.ZipCode || ""}
							Disabled={true} />
					</GridItem>
				</GridContainer>
				<br /><br />
				<Card className="no-radius">
					<CardHeader>
						<GridContainer>
							<GridItem xs={8} >
								<GenericTitle text={"Beneficial Owner Information"} />
							</GridItem>
						</GridContainer>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<GenericTextInput
									Name="Name"
									inputProps={{ maxLength: 20 }}
									LabelText="Name"
									Value={benificialOwnerInfoModel == null || undefined ? "" : benificialOwnerInfoModel.Name == null || undefined ? "" : benificialOwnerInfoModel.Name}
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
								<GenericNumberInput
									ThousandSeparator=""
									NoFormatting={true}
									Name="OwnerPercentage"
									MaxLength={3}
									LabelText="% of Ownership"
									Value={benificialOwnerInfoModel.OwnerPercentage}
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
								<GenericSelectInput
									Name="IdentificationMethodId"
									LabelText="Identification Method"
									Value={benificialOwnerInfoModel == null || undefined ? "" : benificialOwnerInfoModel.IdentificationMethodId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "IdentificationMethod", ParameterValue: "", ParameterDesc: "" }}
									DataRoot="Item"
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
								<GenericSelectInput
									Name="IdentityTypeId"
									LabelText="Identification Type"
									Value={benificialOwnerInfoModel == null || undefined ? "" : benificialOwnerInfoModel.IdentityTypeId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "IdentityType", ParameterValue: "", ParameterDesc: "" }} DataRoot="Item"
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
							</GridItem>

							<GridItem xs={6}>
								<GenericNumberInput
									NoFormatting={true}
									ThousandSeparator=""
									Name="IdentityNumber"
									MaxLength={12}
									LabelText="Identity Number"
									Value={benificialOwnerInfoModel == null || undefined ? "" : benificialOwnerInfoModel.IdentityNumber || ""}
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
								<GenericSelectInput
									Name="PlaceOfIssueId"
									LabelText="Place of Issue"
									Value={benificialOwnerInfoModel == null || undefined ? "" : benificialOwnerInfoModel.PlaceOfIssueId || ""}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember={"Name"}
									Method="POST" Url="/coreapi/v1.0/Country/GetAll"
									Parameter={{}}
									ValueChanged={this.handleChangeBenificialOwnerInfoModel}
									Sorted={{ Member: "Name" }}
								/>
								<GenericDateInput
									Name="IdentityExpireDate"
									MinDate={DateHelper.GetDate()}
									LabelText="ID Expire Date"
									Value={benificialOwnerInfoModel == null || undefined ? "" : DateHelper.ToDateInputValue(benificialOwnerInfoModel.IdentityExpireDate || "")}
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
								<GenericTextInput
									Name="DirectorName"
									inputProps={{ maxLength: 20 }}
									LabelText="Director Name"
									Value={benificialOwnerInfoModel == null || undefined ? "" : benificialOwnerInfoModel.DirectorName == null || undefined ? "" : benificialOwnerInfoModel.DirectorName}
									ValueChanged={this.handleChangeBenificialOwnerInfoModel} />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={8} >	</GridItem>
							<GridItem xs={4}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={this.AddBenificialOwnerInfo} >{benificialOwnerInfoModel.Id == null || undefined ? "ADD" : "EDIT"}</Button>
										<Button size="sm" onClick={this.DeleteBenificialOwnerInfo} >DELETE</Button>
										<Button size="sm" onClick={this.ClearBenificialOwnerInfo} >CLEAR</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<br /><br />
				<GenericGrid
					Data={benificialOwnerInfoList}
					Columns={[
						{
							Header: "Name",
							accessor: "Name"
						},
						{
							Header: "%of Ownership",
							accessor: "OwnerPercentage"
						},
						{
							Header: "ID Method",
							accessor: "IdentificationMethod"
						},
						{
							Header: "ID Type",
							accessor: "IdentityType"
						},
						{
							Header: "ID Number",
							accessor: "IdentityNumber"
						},
						{
							Header: "Place of Issue",
							accessor: "PlaceOfIssue"
						},
						{
							Header: "ID Exp Date",
							accessor: "IdentityExpireDate",
							type: GridColumnType.Date
						},
						{
							Header: "Director Name",
							accessor: "DirectorName"
						}
					]}
					RowSelected={index => {
						this.SelectedRowChange(index, "owner");
					}}
					SelectedIndex={this.state.rowIndex}
					ProgramCode={ProgramCodes.Bank_Programs_CDIC} />
				<br /><br />
				<Card className="no-radius">
					<CardHeader>
						<GridContainer>
							<GridItem xs={8} >
								<GenericTitle text={"Authorized Signing Officer Information"} />
							</GridItem>
						</GridContainer>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<GenericTextInput
									Name="Name"
									inputProps={{ maxLength: 20 }}
									LabelText="Name"
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : authorizedSigningOfficerInfoModel.Name == null || undefined ? "" : authorizedSigningOfficerInfoModel.Name}
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
								<GenericSelectInput
									Name="TitleId"
									LabelText="Title"
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : authorizedSigningOfficerInfoModel.TitleId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "Title", ParameterValue: "", ParameterDesc: "" }}
									DataRoot="Item"
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											Name="PhoneCountryCodeId"
											LabelText="Phone Number"
											TitleLabel="Country Code"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={Phone.PhoneCountryCodeId}
											ValueChanged={this.handleChangePhone}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
											IsPhone
											LabelMd={0}
											Name="PhoneNumber"
											Value={Phone.PhoneNumber}
											ValueChanged={this.handleChangePhone}
											MaxLength={12} />
									</GridItem>
								</GridContainer>
								<GenericEmailInput
									Name="Email"
									LabelText="E-Mail"
									Value={authorizedSigningOfficerInfoModel.Email}
									EndIconName=""
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="IdentificationMethodId"
									LabelText="Identification Method"
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : authorizedSigningOfficerInfoModel.IdentificationMethodId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "IdentificationMethod", ParameterValue: "", ParameterDesc: "" }}
									DataRoot="Item"
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
								<GenericSelectInput
									Name="IdentityTypeId"
									LabelText="Identification Type"
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : authorizedSigningOfficerInfoModel.IdentityTypeId || ""}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "IdentityType", ParameterValue: "", ParameterDesc: "" }} DataRoot="Item"
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
								<GenericNumberInput
									ThousandSeparator=""
									NoFormatting={true}
									Name="IdentityNumber"
									LabelText="Identity Number"
									MaxLength={12}
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : authorizedSigningOfficerInfoModel.IdentityNumber || ""}
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
								<GenericSelectInput
									Name="PlaceOfIssueId"
									LabelText="Place of Issue"
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : authorizedSigningOfficerInfoModel.PlaceOfIssueId || ""}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember={"Name"}
									Method="POST" Url="/coreapi/v1.0/Country/GetAll"
									Parameter={{}}
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel}
									Sorted={{ Member: "Name" }}
								/>
								<GenericDateInput
									Name="IdentityExpireDate"
									MinDate={DateHelper.GetDate()}
									LabelText="ID Expire Date"
									Value={authorizedSigningOfficerInfoModel == null || undefined ? "" : DateHelper.ToDateInputValue(authorizedSigningOfficerInfoModel.IdentityExpireDate || "")}
									ValueChanged={this.handleChangeauthorizedSigningOfficerInfoModel} />
							</GridItem>
						</GridContainer>
					</CardBody>
					<GridContainer>
						<GridItem xs={8} ></GridItem>
						<GridItem xs={4}>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button size="sm" onClick={this.AddAuthorizedSigningOfficerInfo} >{authorizedSigningOfficerInfoModel.Id == null || undefined ? "ADD" : "EDIT"}</Button>
									<Button size="sm" onClick={this.DeleteAuthorizedSigningOfficerInfo} >DELETE</Button>
									<Button size="sm" onClick={this.ClearAuthorizedSigningOfficerInfo} >CLEAR</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</Card>
				<br /><br />
				<GenericGrid
					Data={authorizedSigningOfficerInfoList}
					Columns={[
						{
							Header: "Name",
							accessor: "Name"
						},
						{
							Header: "Title",
							accessor: "Title"
						},
						{
							Header: "Phone",
							accessor: "Phone",
							Cell: row => (
								<div>{row.original.PhoneCountryCode + " " + row.original.PhoneNumber}</div>
							)
						},
						{
							Header: "EMail",
							accessor: "Email"
						},
						{
							Header: "ID Method",
							accessor: "IdentificationMethod"
						},
						{
							Header: "ID Type",
							accessor: "IdentityType"
						},
						{
							Header: "ID Number",
							accessor: "IdentityNumber"
						},
						{
							Header: "Place of Issue",
							accessor: "PlaceOfIssue"
						},
						{
							Header: "ID Exp Date",
							accessor: "IdentityExpireDate",
							type: GridColumnType.Date
						}
					]}
					RowSelected={index => {
						this.SelectedRowChange(index, "officer");
					}}
					SelectedIndex={this.state.rowIndex}
					ProgramCode={ProgramCodes.Bank_Programs_CDIC} />
				<br /><br />
			</div>
		);
	}
}

CDICCorporateAccount.propTypes = {
	classes: PropTypes.object,
	propModel: PropTypes.object
};

export default withArtifex(CDICCorporateAccount, styles);