import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankTransactionStatus, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class eTransferCompletedTransfers extends Component {
	constructor(props) {
		super(props);

		this.InitialModel = {
			TransactionStatus: BankTransactionStatus.Completed,
			SearchEndDate: DateHelper.GetDateLocal(),
			SearchStartDate: DateHelper.GetDateLocal()
		};
		this.state = {
			isLoading: false,
			model: { ...this.InitialModel },
			getDataList: [],
			summaryModel: {}
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Interac e-Transfer / Completed Transfers");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}
	handleChange(name, newValue, ) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	IsValid = () => {
		var model = { ...this.state.model };
		if (typeof model.SearchStartDate != "object" && model.SearchStartDate != "" && model.SearchStartDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}
		if (typeof model.SearchEndDate != "object" && model.SearchEndDate != "" && model.SearchEndDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}
		if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object") && model.SearchEndDate < model.SearchStartDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}
		return true;
	}


	GetData() {

		if (!this.IsValid())
			return false;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetETransferByTransactionStatus",
			this.state.model,
			this.successSummaryCallback,
			this.errorCallback
		);

	}

	successSummaryCallback(responseData) {
		var summaryModel = { ...this.state.summaryModel };
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.SummaryItems == null || responseData.Item.TransactionList.length <= 0) {
			this.setState({ summaryModel: {}, getDataList: [], isLoading: false });
		} else {
			summaryModel = responseData.Item.SummaryItems;
			this.setState({
				summaryModel,
				getDataList: responseData.Item.TransactionList.map(x => {
					return {
						ClientName: x.ClientName == null ? "" : x.ClientName,
						ClientNumber: x.ClientNumber == null ? "" : x.ClientNumber,
						TransRefNoId: x.TransactionReferenceNumber == null ? "" : x.TransactionReferenceNumber,
						ResponseCode: x.ResponseCode == null ? "" : x.ResponseCode,
						NotificationStatus: x.NotificationStatus == null ? "" : x.NotificationStatus,
						TransferType: x.TransactionType == null ? "" : x.TransactionType,
						RequestDate: x.RequestDate == null ? "" : x.RequestDate,
						Amount: x.TransactionAmount == null ? "" : x.TransactionAmount,
						ReceivedDate: x.ReceivedDate == null ? "" : x.ReceivedDate,
						SenderRegistrationName: x.SenderRegistrationName == null ? "" : x.SenderRegistrationName,
						ParticipantReferenceName: x.ParticipantReferenceNumber == null ? "" : x.ParticipantReferenceNumber,
						TransferStatus: x.BankTransactionStatus == null ? "" : x.BankTransactionStatus,
						FiAccountId: x.FiAccountNumber == null ? "" : x.FiAccountNumber,
						AccountHolderName: x.AccountHolderName == null ? "" : x.AccountHolderName,
						InteracStatus: x.InteracStatus
					};
				}), isLoading: false
			});
		}
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />

		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	Clear = () => {
		this.setState({ model: this.InitialModel });
	}
	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.Clear }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">

					<GenericExpansionPanel Title={"Summary Information"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={3}>
									<GenericDateInput
										Name="SearchStartDate"
										LabelText="Date"
										Value={model == null || undefined ? "" : model.SearchStartDate ? DateHelper.ToDateInputValue(model.SearchStartDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.SearchEndDate || DateHelper.GetDateLocal()}
										IncludeTime={false} />
									<GenericDateInput
										Name="SearchEndDate"
										LabelText="To"
										Value={model == null || undefined ? "" : model.SearchEndDate ? DateHelper.ToDateInputValue(model.SearchEndDate) : ""}
										ValueChanged={this.handleChange}
										MinDate={model.SearchStartDate == null ? null : model.SearchStartDate}
										MaxDate={DateHelper.GetDateLocal()}
										IncludeTime={false} />

									<GenericSelectInput
										Name="TransactionTypeId"
										LabelText="Transaction Type"
										Value={model === undefined ? "" : model.TransactionTypeId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
									/>
								</GridItem>
								<GridItem xs={1}></GridItem>
								<GridItem xs={8}>
									<Card>
										<CardBody>
											<GridContainer>
												<GridItem xs={5}>
													<GenericTextInput
														Name="TotalNoOfCredit"
														LabelText="Total No. of Credit"
														Value={summaryModel == null ? "" : summaryModel.TotalNoOfCredit ? summaryModel.TotalNoOfCredit : ""}
														Disabled={true} />
													<GenericTextInput
														Name="TotalNoOfDebit"
														LabelText="Total No. of Debit"
														Value={summaryModel == null ? "" : summaryModel.TotalNoOfDebit ? summaryModel.TotalNoOfDebit : ""}
														Disabled={true} />
												</GridItem>
												<GridItem xs={2}></GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														Name="CreditTotal"
														LabelText="CreditTotal"
														Value={summaryModel == null ? "" : summaryModel.CreditTotal ? summaryModel.CreditTotal : ""}
														Disabled={true}
														Prefix="$" />
													<GenericNumberInput
														Name="DebitTotal"
														LabelText="Debit Total"
														Value={summaryModel == null ? "" : summaryModel.DebitTotal ? summaryModel.DebitTotal : ""}
														Disabled={true}
														Prefix="$" />
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer></CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Completed Transaction Details"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.getDataList}
									Columns={[
										{
											Header: "Unique Client Id",
											accessor: "ClientNumber"
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Trans. Ref. No (ID)",
											accessor: "TransRefNoId"
										},
										{
											Header: "ResponseCode",
											accessor: "ResponseCode"
										},
										{
											Header: "Notification Status",
											accessor: "NotificationStatus"
										},
										{
											Header: "Transfer Type",
											accessor: "TransferType"
										},
										{
											Header: "Request Date",
											accessor: "RequestDate",
											type: GridColumnType.Date
										},
										{
											Header: "Amount",
											accessor: "Amount",
											Cell: row => (
												<div> $ {row.value}</div>
											)
										},
										{
											Header: "Received Date",
											accessor: "ReceivedDate",
											type: GridColumnType.Date
										},
										{
											Header: "Sender Registration Name",
											accessor: "SenderRegistrationName"
										},
										{
											Header: "Participant Reference Name",
											accessor: "ParticipantReferenceName"
										},
										{
											Header: "Transfer Status",
											accessor: "TransferStatus"
										},
										{
											Header: "Interac Status",
											accessor: "InteracStatus"
										},
										{
											Header: "Fi Wallet Id",
											accessor: "FiAccountId"
										},
										{
											Header: "Wallet Holder Name",
											accessor: "AccountHolderName"
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eTransferCompletedTransfers.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eTransferCompletedTransfers, {});