import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { ApproveIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import AccountComponent from "views/Components/AccountComponent";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericNumberInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class CustomerComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				Programs: this.props.Programs,
				UniqueClientId: this.props.UniqueClientId,
				CustomerName: this.props.CustomerName,
				CustomerNumber: this.props.CustomerNumber,
			},
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1,
			isLoading: false
		};

		this.columns = [{
			Header: "Actions",
			accessor: "Actions",
			Cell: row => (
				<div>
					<GridButton
						tooltip="Select"
						Icon={ApproveIcon}
						OnClick={() => { this.HandleSelectModel(row.original, row.index); }} />
				</div>
			),
			sortable: false,
			filterable: false,
			resizable: false,
			width: 60
		},
		{
			Header: "Customer Number",
			accessor: "CustomerNumber"
		},
		{
			Header: "Full Name",
			accessor: "CustomerName"
		}];
	}

	componentDidMount() {
		const model = { ...this.state.model };
		model.Programs = this.props.Programs;
		model.UniqueClientId = this.props.UniqueClientId;
		model.CustomerName = this.props.CustomerName;
		model.CustomerNumber = this.props.CustomerNumber;
		if (this.props.KnownCustomer)
			this.HandleClickByKeyPressed();
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		model.Programs = this.props.Programs;
		model.UniqueClientId = this.props.UniqueClientId;
		this.setState({ model });
	}

	HandleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.HandleClickByKeyPressed();
		}
	}

	HandleClickByKeyPressed = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/ClientCustomer/SearchForComponent",
			{
				CustomerNumber: this.props.CustomerNumber,
				CustomerName: model.CustomerName,
				Programs: this.props.Programs,
				UniqueClientId: this.props.UniqueClientId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
					if (responseData.Item.length === 1) {
						this.HandleSelectModel(responseData.Item[0], 0);
					}
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleClick = () => {

		const model = { ...this.state.model };
		model.Programs = this.props.Programs;
		model.UniqueClientId = this.props.UniqueClientId;
		model.CustomerName = this.props.CustomerName;
		model.CustomerNumber = this.props.CustomerNumber;
		this.setState({ isLoading: true, model });
		Proxy.POST("/bankapi/v1.0/ClientCustomer/SearchForComponent",
			{
				CustomerNumber: this.props.CustomerNumber,
				CustomerName: this.props.CustomerName,
				Programs: this.props.Programs,
				UniqueClientId: this.props.UniqueClientId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleClearClick = () => {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			model: {},
			selected: -1
		}, () => {
			this.props.FillCallback(null);
		});
	}

	HandleClickInside = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/ClientCustomer/SearchForComponent",
			{
				AccountNumber: this.state.model.AccountNumber,
				CustomerName: this.state.model.CustomerName,
				CustomerNumber: this.state.model.CustomerNumber,
				Programs: this.props.Programs,
				UniqueClientId: this.props.UniqueClientId
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleSelectModel = (model, index) => {
		this.setState({ selected: index });
		this.props.FillCallback(model);
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1
		});
	};

	CancelSelectCallback = () => {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			model: {},
			selected: -1
		});
	};

	RowSelected = (index) => {
		const model = this.state.dialog.dialogData[index];
		this.HandleSelectModel(model, index);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, LabelMd, Disabled, ShowCustomerProgram, Required, IsInvalid } = this.props;
		const { model, dialog, isLoading } = this.state;

		if (model.CustomerName == undefined || model.CustomerName == null || model.CustomerName === "") {
			model.CustomerName = this.props.CustomerName;
		}
		var showColumns = [...this.columns];
		if (ShowCustomerProgram) {
			showColumns.push({
				Header: "Program",
				Cell: row => (
					<div>
						{row.original.Programs.toString()}
					</div>
				)
			});
		}
		return (
			<>

				<LoadingComponent Show={isLoading} />

				<GenericNumberInput
					LabelMd={LabelMd}
					Name="CustomerNumber"
					LabelText="Customer Number"
					KeyPressed={this.HandleKeyPress}
					Blur={this.HandleClickByKeyPressed}
					NoFormatting={true}
					Value={this.props.CustomerNumber}
					ValueChanged={this.props.HandleChange}
					EndIconName={!Disabled ? "search" : ""}
					ClearIconName={!Disabled ? "clear" : ""}
					IconOnClick={this.HandleClick}
					ClearOnClick={this.HandleClearClick}
					Disabled={Disabled}
					Required={Required}
					IsInvalid={IsInvalid} />

				<GenericDialog open={dialog.isOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="md" fullWidth keepMounted={true}>
					<DialogTitle><GenericLabel FontSize="16px" Text="Select Customer" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<GridContainer>
							<GridItem xs={6}>
								<GenericTextInput
									Name="CustomerName"
									LabelText="Name"
									Value={model.CustomerName}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={6}>
								<AccountComponent
									TitleLabel="FIID"
									TitleLabelBranch="Transit"
									LabelText="Wallet Wallet"
									IsDCBankAccount={true}
									Model={model}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={6}>
								<GenericNumberInput
									Name="CardNumber"
									LabelText="Card Number"
									NoFormatting={true}
									MaxLength={30}
									Value={model.CardNumber}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="CardholderName"
									IsText
									LabelText="Cardholder Name"
									Value={model.CardholderName}
									ValueChanged={this.props.HandleChange} />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="CustomerNumber"
									LabelText="Customer Number"
									NoFormatting={true}
									MaxLength={30}
									Value={model.CustomerNumber}
									ValueChanged={this.HandleChange} />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<br />
								<GenericGrid
									Data={dialog.dialogData}
									Columns={showColumns}
									RowSelected={this.RowSelected}
									SelectedIndex={this.state.selected}
									HideButton={true}
									ShowPagination={true} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickInside} >Search</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</>
		);
	}
}

CustomerComponent.propTypes = {
	CustomerNumber: PropTypes.any,
	CustomerName: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	KnownCustomer: PropTypes.bool,
	LabelMd: PropTypes.number,
	UniqueClientId: PropTypes.number,
	Programs: PropTypes.array,
	Disabled: PropTypes.bool,
	ShowCustomerProgram: PropTypes.bool,
};

CustomerComponent.defaultProps = {
	ShowCustomerProgram: false
};

export default withArtifex(CustomerComponent, {});