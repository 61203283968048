import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericNumberInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, GridColumnType, MenuCodes } from "views/Constants/Constant.js";
import moment from "moment";
import Formatter from "core/Formatter";

class CardTransaction extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			Customer: null,
			DefaultAccount: null,
			DefaultCard: null,
			CardId: null,
			ValidFrom: DateHelper.GetLocalDateAsUtc(),
			ValidTo: DateHelper.GetLocalDateAsUtc(),
			IsUniqueDataSearch: null,
			ReferenceNumber: null
		};

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				Cell: row => (<div>{Formatter.FormatDateTimeEst(row.value)}</div>)
			},
			{
				Header: "Transaction Code",
				accessor: "ChannelName"
			},

			{
				Header: "Cardholder Name",
				accessor: "CardOwnerName"
			},
			{
				Header: "Card Masked Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Available Balance",
				accessor: "WalletBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Merchant",
				accessor: "Merchant"
			},
			{
				Header: "Merchant City",
				accessor: "MerchantCity"
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode"
			},
			{
				Header: "Response Description",
				accessor: "ResponseDescription"
			},
			{
				Header: "Reversal Type",
				accessor: "ReversalType"
			},
			{
				Header: "Reversed Date Time",
				accessor: "ReversedDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Original Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money

			},
			{
				Header: "Biling Amount",
				accessor: "BillingAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money

			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money

			},
			{
				Header: "Terminal Type",
				accessor: "TerminalType"

			},
			{
				Header: "Transaction Source",
				accessor: "CardTransactionSource"

			},
			{
				Header: "Merchant Clearing Amount",
				accessor: "SettlementAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Merchant Clearing Date",
				accessor: "SettlementDate",
				Cell: row => (
					row.value ? Formatter.FormatDate(row.value) : ""
				)
			},
			{
				Header: "Error Code",
				accessor: "ErrorCode"
			},
			{
				Header: "Error Description",
				accessor: "ErrorDescription"
			},
			{
				Header: "Card Brand Name",
				accessor: "CardBrandName"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Mcc",
				accessor: "Mcc"
			},
			{
				Header: "System Settlement Status",
				accessor: "SettlementStatus"
			},
			{
				Header: "Entry Type",
				accessor: "TransactionEntryTypeDesc"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionTypeDesc"
			},
			{
				Header: "Mti",
				accessor: "Mti"
			},
			{
				Header: "Network Fee",
				accessor: "TransactionFee"
			},
			{
				Header: "Authorzation Code",
				accessor: "AuthorzationCode"
			},
			{
				Header: "Mobile Wallet Type",
				accessor: "MobileWalletType"
			}
		];

		this.state = {
			list: [],
			vModel: {},
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			}
		};
	}

	componentDidMount() {

		if (this.props.MenuCode == MenuCodes.CallCenter_CardTransaction) {
			this.props.setAppLeftTitle("Card Transaction Search");
			this.props.setAppCenterTitle("CALL CENTER");
		} else {
			this.props.setAppLeftTitle("Card Transaction Search");
			this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	};

	LimitDataLoad = data => {
		this.setState({ LimitBalanceData: data, isLoading: false });
	};
	IsValid = () => {
		var vmodel = { ...this.state.model };
		const kModel = { ...this.state.vModel };

		if (!vmodel.IsUniqueDataSearch && vmodel.ValidFrom == null || vmodel.ValidFrom == "") {
			kModel.ValidFrom = true;
			this.props.showMessage("warning", "Please fill required fields!", "Select From Date to continue!");
			this.setState({
				kModel: kModel
			});
			return false;
		}
		else {
			kModel.ValidFrom = false;
		}
		if (!vmodel.IsUniqueDataSearch && vmodel.ValidTo == null || vmodel.ValidTo == "") {
			kModel.ValidTo = true;
			this.props.showMessage("warning", "Please fill required fields!", "Select To Date to continue!");
			this.setState({
				kModel: kModel
			});
			return false;
		}
		else {
			kModel.ValidTo = false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.ValidFrom != "object" && vmodel.ValidFrom != "" && vmodel.ValidFrom != null) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.ValidTo != "object" && vmodel.ValidTo != "" && vmodel.ValidTo != null) {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && (typeof vmodel.ValidFrom == "object" && typeof vmodel.ValidTo == "object") && vmodel.ValidTo < vmodel.ValidFrom) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be greater than To Date!");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && (typeof vmodel.ValidFrom == "object" && typeof vmodel.ValidTo == "object")) {
			var interval = moment.duration(vmodel.ValidTo.diff(vmodel.ValidFrom)).asDays();
			if (interval > 30) {
				this.props.showMessage("warning", "Please fill required fields!", "Between two dates cannot be longer than 30 days!");
				return false;
			}
		}
		this.setState({
			kModel: kModel
		});
		return true;
	}
	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.DefaultAccount = newValue.Accounts[0];
				}
				const request = { CardLevelCriteria: 1, CustomerId: newValue.Id };
				Proxy.ExecuteGeneral(
					this, "POST",
					"/prepaidapi/v1.0/Card/GetListByCustomerNo",
					request,
					responseData => {
						if (responseData.Item != null && responseData.Item[0] != null) {
							model.DefaultCard = responseData.Item[0];
						} else {
							model.DefaultCard = null;
						}
						this.setState({ model: model });
					}
				);
			} else {
				model.Customer = initialModel.Customer;
				model.DefaultAccount = initialModel.DefaultAccount;
				model.DefaultCard = initialModel.DefaultCard;
			}
		} else if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		} else if (name == "ReferenceNumber") {
			model.ReferenceNumber = newValue;
			model.IsUniqueDataSearch = newValue?.length > 0;
		}
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};

	addDays = (date, days) => {
		var returnDate = new Date();
		returnDate.setDate(date.getDate() + days);
		return returnDate;
	};

	HandleSearch = () => {
		if (!this.IsValid())
			return;

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		var request = {
			Criteria: 0,
			BeginDate: model.ValidFrom,
			EndDate: model.ValidTo,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : model.UniqueClientId,
			CardId: model.CardId,
			ReferenceNumber: model.ReferenceNumber
		}
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionData/SearchCardTransactionSummary",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, vModel } = this.state;

		var endDate = (model.ValidFrom instanceof moment) ? model.ValidFrom.clone() : "";

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{
														UniqueClientId: ClientHelper.IsClient()
															? ClientHelper.GetClientId()
															: undefined
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												/>
											</GridItem>
											<GridItem>
												<CustomerComponent
													LabelMd={4}
													CustomerNumber={model.Customer === null ? null : model.Customer.CustomerNumber}
													UniqueClientId={model.UniqueClientId}
													FillCallback={customer => { this.handleChange("Customer", customer); }}
													Programs={[ProgramCodes.Prepaid]} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericDateInput
													Name="ValidFrom"
													LabelText="From Date"
													LabelMd={4}
													IsFuture={false}
													MaxDate={DateHelper.GetLocalDateAsUtc()}
													Value={model.ValidFrom == null ? "" : DateHelper.ToDateInputValue(model.ValidFrom) || undefined}
													ValueChanged={this.handleChange}
													Utc
													Required={!model.IsUniqueDataSearch}
													IsInvalid={vModel.ValidFrom}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ValidTo"
													LabelText="To Date"
													LabelMd={4}
													IsFuture={false}
													MinDate={model.ValidFrom}
													MaxDate={endDate != "" ? endDate.add(30, "days") : undefined}
													Value={model.ValidTo == null ? "" : DateHelper.ToDateInputValue(model.ValidTo) || ""}
													ValueChanged={this.handleChange}
													Utc
													Required={!model.IsUniqueDataSearch}
													IsInvalid={vModel.ValidTo}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.handleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid}
													ShowPagination={true}
													PageSize={100}
													Sorted={[{ id: "TransactionDate", desc: true }]}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CardTransaction.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardTransaction, {});
