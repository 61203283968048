import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import dualListBoxStyle from "react-dual-listbox/lib/react-dual-listbox.css";

const productRestrictionProfileStyle = theme => ({
	root: {
		fontSize: 12
	},
	...sweetAlertStyle,
	...customSelectStyle,
	...customCheckboxRadioSwitch,
	...dualListBoxStyle

});

export default productRestrictionProfileStyle;
