import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericAlert, GenericExpansionPanel, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ProgramCodes } from "views/Constants/Constant.js";

const styles = ({
	...sweetAlertStyle
});
class InventoryCheckin extends React.Component {
	constructor(props) {
		super(props);
		var userJson = localStorage.getItem("user");
		var user = userJson ? JSON.parse(userJson) : null;
		this.initialModel = {
			ClientTypeId: (user && user.UniqueClient && user.UniqueClient.ClientTypeId) || null,
			isBackOffice: true,
			checksalestatus: true,
			saleStatus: true
		};
		this.state = {
			model: this.initialModel,
			cardorderlist: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
			vModel: {}
		};
		this.HandleCheck = this.HandleCheck.bind(this);
	}
	componentDidMount() {
		var model = { ...this.state.model };
		model.isBackOffice = ClientHelper.IsBackOffice();
		if (!model.isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
			model.Name = model.UniqueClientId;
		}
		this.setState({ model });
	}

	ClientCustomerGet = () => {
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.GetClientRowId(),
			Programs: [ProgramCodes.Prepaid],
		};
		return this.parameterUniqueClientId;
	}
	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		const modelprops = { ...this.props.checkmodel };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;

		model["Id"] = modelprops.Id;
		model["InventoryStatusType"] = modelprops.CheckType;
		model["InventoryStatusTypeId"] = modelprops.CheckTypeId;
		model["CheckTypeId"] = modelprops.CheckTypeId;


		if (data != undefined || data != null) {
			if (data.ParameterValue == "S") {
				model["checksalestatus"] = false;
				model["Salestatus"] = false;
				model["CardId"] = modelprops.CardId;

			} else {
				model["Salestatus"] = true;
				model["checksalestatus"] = true;
				model["ClientCustomerId"] = null;
				model["CustomerNumber"] = null;
			}
		}
		this.setState({ model });
	}
	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}
	CheckinConfirm = () => {
		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		const { model } = this.state;
		const { showMessage } = this.props;
		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/InventoryChenkInOutUpdate",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Operation is successfully!");
				// this.Clear();
				// this.Search();
				this.props.clearlist();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while sending data", error.message);
			}
		);


	}
	Clear = () => {
		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;
		this.setState({ model: this.initialModel, list: [], selected: null, checkedValues: [], totalresult: {} });
	}
	Validate() {
		const { model } = this.state;
		var messages = [];
		const vModel = { ...this.state.vModel };

		if (!model.CheckReasonTypeId) {
			messages.push("Check Reason Type can not be null.");
			vModel.CheckReasonTypeId = true;

		} else {
			vModel.CheckReasonTypeId = false;
		}
		if (model.Salestatus == false) {
			if (!model.ClientCustomerId)
				messages.push("Client Customer can not be null.");
		}
		this.setState({ vModel });

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			this.props.showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null, isLoading: false })}
					OnConfirm={onConfirm}
				/>,
			isLoading: true
		});
	}

	FillCustomer = (customer) => {
		this.CustomerValueChanged("Customer", customer);
	}
	CustomerValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		if ( value != null && value != undefined) {

			if (name != "CustomerNumber") {
				model["ClientCustomerId"] = value.Id;
				model["CustomerNumber"] = value.CustomerNumber;
			}
		}
		else {
			model["CustomerNumber"] = null;
			model["ClientCustomerId"] = null;

		}
		this.setState({ model });
	}
	render() {
		const { model, vModel } = this.state;
		const { checkmodel } = this.props;
		return (
			<div>


				<LoadingComponent Show={this.state.isLoading} />

				{this.state.alert}

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Main Parameters"}>
								<CardBody>

									<GridContainer>
										<GridItem xs={6}  >
											<ParameterComponent
												Name="CheckReasonTypeId"
												Value={model.CheckReasonTypeId}
												LabelText="Checkout Reason"
												ParameterCode="InventoryCheckReasonType"
												IsInvalid={vModel.CheckReasonTypeId}
												Required
												ValueChanged={this.ValueChanged} />

											<GenericTextInput
												Name="CustomerStoreSafeName"
												Value={checkmodel.CustomerStoreSafeName}
												LabelText="Store/Safe"
												Disabled={true}
												ValueChanged={this.ValueChanged}

											/>
											<GenericTextInput
												Name="MaskedCardNumber"
												Value={checkmodel.MaskedCardNumber}
												LabelText="Masked Card Number"
												Disabled={true}
											>
											</GenericTextInput>
										</GridItem>

										<GridItem xs={6}  >

											<CustomerComponent
												key={model.UniqueClientId}
												LabelMd={4}
												CustomerNumber={model.CustomerNumber}
												HandleChange={this.CustomerValueChanged}
												FillCallback={this.FillCustomer}
												Programs={[ProgramCodes.Prepaid]}
												UniqueClientId={ClientHelper.GetClientRowId()}
												Disabled={model.checksalestatus == true ? true : false}
												ReadOnly={true}
											/>
											<GenericTextInput
												Name="RecordType"
												Value={checkmodel.RecordType}
												LabelText="RecordType"
												Disabled={true}>
											</GenericTextInput>
											<GenericTextInput
												Name="PlasticTypeName"
												Value={checkmodel.PlasticTypeName}
												LabelText="Product Code"
												Disabled={true}
											>
											</GenericTextInput>
										</GridItem>
										<GridContainer xs={12} justify={"center"} style={{ marginTop: "10px" }}>
											<GridItem >
												<Button size="sm" style={{ width: "100px" }} onClick={() => this.CheckinConfirm()}>
													CONFIRM
							         	</Button>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>

					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InventoryCheckin.propTypes = {
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,
	checkmodel: PropTypes.object
};

export default withArtifex(InventoryCheckin, styles);