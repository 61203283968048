import withStyles from "@material-ui/core/styles/withStyles";
import { List } from "@material-ui/icons";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
// @ts-ignore
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";

const styles = ({
	circleBoxGrid: {
		width: "14%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class PortfolioPerformanceChart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			},
			tooltips: { enabled: true }
		};
		this.getGradientList = this.getGradientList.bind(this);

	}

	componentDidMount() {
		this.props.setAppLeftTitle(this.props.leftTitle);
		this.props.setAppCenterTitle(this.props.centerTitle);

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);

			}
		});

	}

	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			// @ts-ignore
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return gradientList;

	}
	render() {
		const { classes, totalLoansChartData, outStandingChartData, pastDueChartData, writeOffsChartData, interestChartData, feesChartData, TitleChart, TitleChart2, TitleChart4, TitleChart5, TitleChart6, TitleChart7 } = this.props;

		var totalLoansGradientList = this.getGradientList(totalLoansChartData);
		var totalLoansBackground = totalLoansChartData;
		if (totalLoansGradientList != undefined) {
			totalLoansBackground.datasets[0].backgroundColor = totalLoansGradientList;
			totalLoansBackground.datasets[0].hoverBackgroundColor = totalLoansGradientList;
		}

		var outStandingGradientList = this.getGradientList(outStandingChartData);
		var outStandingBackground = outStandingChartData;
		if (outStandingGradientList != undefined) {
			outStandingBackground.datasets[0].backgroundColor = outStandingGradientList;
			outStandingBackground.datasets[0].hoverBackgroundColor = outStandingGradientList;
		}

		var pastDueGradientList = this.getGradientList(pastDueChartData);
		var pastDueBackground = pastDueChartData;
		if (pastDueGradientList != undefined) {
			pastDueBackground.datasets[0].backgroundColor = pastDueGradientList;
			pastDueBackground.datasets[0].hoverBackgroundColor = pastDueGradientList;
		}

		var writeOffsGradientList = this.getGradientList(writeOffsChartData);
		var writeOffsBackground = writeOffsChartData;
		if (writeOffsGradientList != undefined) {
			writeOffsBackground.datasets[0].backgroundColor = writeOffsGradientList;
			writeOffsBackground.datasets[0].hoverBackgroundColor = writeOffsGradientList;
		}

		var interestGradientList = this.getGradientList(interestChartData);
		var interestBackground = interestChartData;
		if (interestGradientList != undefined) {
			interestBackground.datasets[0].backgroundColor = interestGradientList;
			interestBackground.datasets[0].hoverBackgroundColor = interestGradientList;
		}

		var feesGradientList = this.getGradientList(feesChartData);
		var feesBackground = feesChartData;
		if (feesGradientList != undefined) {
			feesBackground.datasets[0].backgroundColor = feesGradientList;
			feesBackground.datasets[0].hoverBackgroundColor = feesGradientList;
		}

		return (
			<Card style={{ marginTop: "25px" }}>
				<CardHeader color="warning" icon>
					<CardIcon color="warning">
						<List />
					</CardIcon>
					<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
						<b>Portfolio Performance</b>
					</h4>
				</CardHeader>
				<CardBody>
					<GridContainer justify="center">
						<GridItem xs={2} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart
									// @ts-ignore
									id="canvas" data={totalLoansBackground} width={200} height={300}
									options={totalLoansBackground.text == "" || totalLoansBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
								<h4>{TitleChart}</h4>
							</div>
						</GridItem>
						<GridItem xs={2} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={outStandingBackground} width={200} height={300}
									options={outStandingBackground.text == "" || outStandingBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />

								<h4>{TitleChart2}</h4>
							</div>
						</GridItem>
						<GridItem xs={2} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={pastDueBackground} width={200} height={300}
									options={pastDueBackground.text == "" || pastDueBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
								<h4>{TitleChart4}</h4>
							</div>
						</GridItem>
						<GridItem xs={2} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={writeOffsBackground} width={200} height={300}
									options={writeOffsBackground.text == "" || writeOffsBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
								<h4>{TitleChart5}</h4>
							</div>
						</GridItem>
						<GridItem xs={2} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={interestBackground} width={200} height={300}
									options={interestBackground.text == "" || interestBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
								<h4>{TitleChart6}</h4>
							</div>
						</GridItem>
						<GridItem xs={2} className={classes.circleBoxGrid}>
							<div className={classes.circleBox}>
								<DoughnutChart data={feesBackground} width={200} height={300}
									options={feesBackground.text == "" || feesBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
								<h4>{TitleChart7}</h4>
							</div>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

PortfolioPerformanceChart.propTypes = {
	classes: PropTypes.object,
	columns: PropTypes.array,
	list: PropTypes.array,
	totalLoansChartData: PropTypes.object,
	outStandingChartData: PropTypes.object,
	currentChartData: PropTypes.object,
	writeOffsChartData: PropTypes.object,
	interestChartData: PropTypes.object,
	feesChartData: PropTypes.object,
	leftTitle: PropTypes.string,
	centerTitle: PropTypes.string,
	TitleChart: PropTypes.string,
	TitleChart2: PropTypes.string,
	TitleChart3: PropTypes.string,
	TitleChart4: PropTypes.string,
	TitleChart5: PropTypes.string,
	TitleChart6: PropTypes.string,
	TitleChart7: PropTypes.string,
	pastDueChartData: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

// @ts-ignore
export default withStyles(styles)(PortfolioPerformanceChart);