import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import TabsComponent from "views/Components/TabsComponentPagination";
//Steps
import DcBank from "./SettlementStep/DcBank";
import External from "./SettlementStep/External";

class Settlement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Settlement");
	}

	render() {
		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<br />
					<TabsComponent
						TabIndex={this.props.ChildTabIndex}
						handleChange={(e, a) => this.props.handleChangeChildTab(e, a, true)}
						tabList={[
							{
								tabButton: `${window.Title} Wallets`,
								tabContent: (
									<DcBank
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}
										paramBankCustomerInternalAccountList={this.props.paramBankCustomerInternalAccountList}
										GetCustomerDCBankAccountModels={this.props.GetCustomerDCBankAccountModels}
										paramBankCustomerContractList={this.props.paramBankCustomerContractList}

										handleChangeParentTab={this.props.handleChangeParentTab}
										handleChangeChildTab={this.props.handleChangeChildTab}
										Disabled={this.props.Disabled}
										FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
									/>
								)
							},
							{
								tabButton: "External Wallets",
								tabContent: (
									<External
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}
										paramBankCustomerExternalAccountList={this.props.paramBankCustomerExternalAccountList}
										GetCustomerExternalBankAccountModels={this.props.GetCustomerExternalBankAccountModels}
										paramBankCustomerContractList={this.props.paramBankCustomerContractList}
										handleChangeParentTab={this.props.handleChangeParentTab}
										handleChangeChildTab={this.props.handleChangeChildTab}
										Disabled={this.props.Disabled}
										FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
									/>
								)
							}
						]}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}


Settlement.propTypes = {
	paramCustomerProfile: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerInternalAccountList: PropTypes.array,
	paramBankCustomerExternalAccountList: PropTypes.array,
	paramBankCustomerFeeProfileList: PropTypes.array,
	paramBankCustomerLimitProfileList: PropTypes.array,
	GetCustomerExternalBankAccountModels: PropTypes.func,
	GetCustomerDCBankAccountModels: PropTypes.func,
	GetCustomerFeeProfileModels: PropTypes.func,
	GetCustomerLimitProfileModels: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	ChildTabIndex: PropTypes.number,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	paramBankCustomerContractList: PropTypes.array,
	FetchClientLimitedAuthority: PropTypes.func
};


export default withArtifex(Settlement, {});