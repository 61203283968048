import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes, { func, object } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankTransactionStatus, ProgramCodes } from "views/Constants/Constant.js";

class eftReturnedItemList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				TransactionStatus: BankTransactionStatus.Return,
				SearchEndDate: DateHelper.GetDate(),
				SearchStartDate: DateHelper.GetDate()
			},
			isLoading: false,
			getDataList: [],
			summaryModel: {},
			SelectedList: []
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);

		this.handleCheck = this.handleCheck.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleDeleteOk = this.handleDeleteOk.bind(this);
		this.successDeleteCallback = this.successDeleteCallback.bind(this);

		this.handleReSubmit = this.handleReSubmit.bind(this);
		this.handleReSubmitOk = this.handleReSubmitOk.bind(this);
		this.successReSubmitCallback = this.successReSubmitCallback.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("EFT Returned & Rejected Items");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleCheck(e, x) {
		this.setState(state => ({
			SelectedList: state.SelectedList.includes(x)
				? state.SelectedList.filter(c => c !== x)
				: [...state.SelectedList, x]
		}));
	}

	GetData() {
		if (this.state.model.SearchStartDate == null)
			this.showErrorMessage("Warning", "Date / To can not be empty !!");
		else {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/GetEFTReturnedItems",
				this.state.model,
				this.successSummaryCallback,
				this.errorCallback
			);
		}
	}

	handleDelete() {
		this.setState({
			alert: <GenericAlert
				Message="Do you want to delete the selected elements ?"
				Title="Delete"
				Type="warning"
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => this.handleDeleteOk()} />
		});
	}

	handleDeleteOk() {
		this.hideAlert();
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/DeleteEftReturnItems",
			{ DeleteList: this.state.SelectedList },
			this.successDeleteCallback,
			this.errorCallback
		);
	}
	successDeleteCallback() {
		this.GetData();
		this.setState({
			SelectedList: [],
			isLoading: false,
			alert: <GenericAlert
				Message="Selects items deleted !!"
				Title="Delete"
				Type="info"
				ShowCancel={false}
				OnConfirm={() => this.hideAlert()} />
		});
	}

	handleReSubmit() {
		this.setState({
			alert: <GenericAlert
				Message="Do you want to Re-Submit the selected elements ?"
				Title="Re-submit"
				Type="warning"
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => this.handleReSubmitOk()} />
		});
	}

	handleReSubmitOk() {
		this.hideAlert();
		if (this.state.SelectedList.length > 0) {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/ReSumbitEftReturnItems",
				{ ReSubmitList: this.state.SelectedList },
				this.successReSubmitCallback,
				this.errorCallback
			);
		}
		else
			this.showErrorMessage("Warning", "Request list not be null !!");
	}

	successReSubmitCallback() {
		this.GetData();
		this.setState({
			SelectedList: [],
			isLoading: false,
			alert: <GenericAlert
				Message="Selects items Re-Submited !!"
				Title="Delete"
				Type="info"
				ShowCancel={false}
				OnConfirm={() => this.hideAlert()} />
		});
	}

	successSummaryCallback(responseData) {
		var summaryModel = { ...this.state.summaryModel };
		var getDataList = { ...this.state.getDataList };
		if (!responseData.IsSucceeded) {
			this.showErrorMessage("Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null) {
			this.setState({ summaryModel: {}, getDataList: [], isLoading: false });
		} else {
			summaryModel = responseData.Item.SummaryItems == null ? {} : responseData.Item.SummaryItems;
			getDataList = responseData.Item.TransactionList == null || undefined || responseData.Item.TransactionList.length <= 0 ? [] : responseData.Item.TransactionList;
			this.setState({ summaryModel, getDataList, isLoading: false });
		}
	}

	errorCallback(error) {
		this.showErrorMessage("Error", "An error occurred during the api visit" + error);
	}

	showErrorMessage(title, message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Re-Submit", OnClick: this.handleReSubmit, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.handleDelete, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Summary Information"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear />
									<GenericDateInput
										Name="SearchStartDate"
										LabelText="Date"
										Value={model == null || undefined ? "" : model.SearchStartDate ? DateHelper.ToDateInputValue(model.SearchStartDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.SearchEndDate || DateHelper.GetDate()}
										IncludeTime={false} />
									<GenericDateInput
										Name="SearchEndDate"
										LabelText="To"
										Value={model == null || undefined ? "" : model.SearchEndDate ? DateHelper.ToDateInputValue(model.SearchEndDate) : ""}
										ValueChanged={this.handleChange}
										MinDate={model.SearchStartDate == null ? null : model.SearchStartDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false} />
									<GenericSelectInput
										Name="TransactionTypeId"
										LabelText="Transaction Type"
										Value={model === undefined ? "" : model.TransactionTypeId || ""}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										All
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
									/>
								</GridItem>
								<GridItem xs={1} ></GridItem>
								<GridItem xs={8}>
									<Card>
										<CardBody>
											<GridContainer>
												<GridItem xs={5}>
													<GenericTextInput
														Name="TotalNoOfCredit"
														LabelText="Total No. of Credit"
														Value={summaryModel == null ? "0" : summaryModel.TotalNoOfCredit ? summaryModel.TotalNoOfCredit : "0"}
														Disabled={true} />
													<GenericTextInput
														Name="TotalNoOfDebit"
														LabelText="Total No. of Debit"
														Value={summaryModel == null ? "0" : summaryModel.TotalNoOfDebit ? summaryModel.TotalNoOfDebit : "0"}
														Disabled={true} />
												</GridItem>
												<GridItem xs={2}></GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														Name="CreditTotal"
														LabelText="CreditTotal"
														Value={summaryModel == null ? "" : summaryModel.CreditTotal ? summaryModel.CreditTotal : "0"}
														Disabled={true}
														Prefix="$" />
													<GenericNumberInput
														Name="DebitTotal"
														LabelText="Debit Total"
														Value={summaryModel == null ? "" : summaryModel.DebitTotal ? summaryModel.DebitTotal : "0"}
														Disabled={true}
														Prefix="$" />
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer></CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction Lists"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.getDataList == null || undefined ? [] : this.state.getDataList.map(item => {
										return {
											TransactionId: item.TransactionId == null || undefined ? "" : item.TransactionId,
											Actions: (
												<div>
													<GenericCheckInput
														Grid
														Value={this.state.SelectedList.filter(c => c.TransactionId == item.TransactionId).length > 0}
														ValueChanged={(e) => {
															this.handleCheck(e, item);
														}} />

													<Button
														color='primary'
														justIcon simple
														size="sm"
														tooltip="Edit"
														onClick={() => {
															RouteHelper.Push(this.props.history, "/CreateEFT", "CreateEFT_Id", item.TransactionId);
														}}>
														<img src={EditIcon} />
													</Button>
												</div>
											),
											RecordType: item.RecordType == null || undefined ? "" : item.RecordType,
											ReturnType: item.ReturnType == null || undefined ? "" : item.ReturnType,
											FileType: item.FileType == null || undefined ? "" : item.FileType,
											UniqueClientId: item.UniqueClientId == null || undefined ? "" : item.UniqueClientId,
											ReasonCode: item.Name == null || undefined ? "" : item.Name,
											ValueDate: item.ValueDate == null || undefined ? "" : item.ValueDate,
											DestinationInstance: item.DestinationInstance == null || undefined ? "" : item.DestinationInstance,
											PayeeAccountNo: item.EMail == null || undefined ? "" : item.EMail,
											PayeeName: item.PayeeName == null || undefined ? "" : item.PayeeName,
											CrossReferenceNumber: item.CrossReferenceNumber == null || undefined ? "" : item.CrossReferenceNumber,
											ItemTraceNo: item.ItemTraceNo == null || undefined ? "" : item.ItemTraceNo,
											Amount: item.Amount == null || undefined ? "" : item.Amount
										};
									})}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: 100
										},
										{
											Header: "Rec. Type",
											accessor: "RecordType"
										},
										{
											Header: "Return Type",
											accessor: "ReturnType"
										},
										{
											Header: "File Type",
											accessor: "FileType"
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId"
										},
										{
											Header: "ReasonCode",
											accessor: "ReasonCode"
										},
										{
											Header: "Value Date",
											accessor: "ValueDate",
											Cell: row => (
												<div>{Formatter.FormatDate(String(row.value))}</div>
											)
										},
										{
											Header: "Dest. Inst.",
											accessor: "DestinationInstance"
										},
										{
											Header: "Payee Wallet No.",
											accessor: "PayeeAccountNo"
										},
										{
											Header: "Request Date",
											accessor: "RequestDate"
										},
										{
											Header: "Payee Name",
											accessor: "PayeeName"
										},
										{
											Header: "Cross Reference Number",
											accessor: "CrossReferenceNumber"
										},
										{
											Header: "Item Trace No",
											accessor: "ItemTraceNo"
										},
										{
											Header: "Amount",
											accessor: "Amount"
										},
										{
											Header: "Trans. Id",
											accessor: "TransactionId"
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_EFT} /> <br />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eftReturnedItemList.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	push: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	history: object,
	Disabled: PropTypes.bool
};

export default withArtifex(eftReturnedItemList, {});