import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericCheckInput, GenericTextInput } from "views/Components/Generic";
import { OperationType } from "views/Constants/Constant";

const styles = theme => ({
	...sweetAlertStyle
});

class CommissionFeeProfileAddOrUpdate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: this.props.model,
			operationType: OperationType.DEFAULT
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleAddToGrid = this.handleAddToGrid.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.successSubmitCallback = this.successSubmitCallback.bind(this);
		this.errorSubmitCallback = this.errorSubmitCallback.bind(this);

		this.selectCustomerCallback = this.selectCustomerCallback.bind(this);
		this.selectProfileCallback = this.selectProfileCallback.bind(this);

		this.showSuccessMessage = this.showSuccessMessage.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
	}

	handleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleAddToGrid() {
		let list = [...this.state.list, this.state.model];
		this.setState({ list });
	}

	handleSubmit() {
		Proxy.POST(
			"/bankapi/v1.0/CommissionProfile/InsertOrUpdate",
			this.state.model,
			this.successSubmitCallback,
			this.errorSubmitCallback
		);
	}

	successSubmitCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.props.handleGetList();
		this.showSuccessMessage("Saved succesfully!");
	}

	errorSubmitCallback(error) {
		this.showErrorMessage("An error occurred during the submit data. Error: " + error);
	}

	selectProfileCallback(profile) {
		const model = { ...this.state.model };
		model.ProfileId = profile.ProfileId;
		model.ProfileName = profile.ProfileName;
		this.setState({ model });
	}

	selectCustomerCallback(customer) {
		const model = { ...this.state.model };
		model.CustomerId = customer.CustomerNumber;
		model.CustomerName = customer.Name + " " + customer.SurName;
		this.setState({ model });
	}

	showSuccessMessage(message) {
		this.setState({
			alert: AlertHelper.CreateAlert("Success", message, "success", this.hideAlert)
		});
	}

	showErrorMessage(message) {
		this.setState({
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		return (
			<div>
				<GridContainer spacing={16}>
					{this.state.alert}
					<GridItem md={12} xs={12}>
						<GridContainer>
							<GridItem xs={12} sm={4} />
							<GridItem xs={12} sm={8}>
								<GridContainer justify="flex-end">
									<GridItem md={2} xs={12}>
										<Button
											size="sm"
											onClick={() => this.props.handleOperationType(OperationType.DEFAULT)}
											fullWidth
										>
											Back
										</Button>
									</GridItem>
									<GridItem md={2} xs={12}>
										<Button
											size="sm"
											onClick={this.handleSubmit}
											fullWidth
										>
											Submit
										</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<GridContainer spacing={16}>
							<GridItem md={4} xs={12}>
								<GridItem>
									<GenericTextInput
										Name="ProfileName"
										LabelText="Profile Name"
										Value={this.state.model.ProfileName}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridItem>
							<GridItem md={4} xs={12}>
								<GridItem>
									<GenericCheckInput
										Name="UseStandartRate"
										LabelText="Use Standard Rate"
										Value={this.state.model.UseStandartRate}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CommissionFeeProfileAddOrUpdate.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object,
	handleGetList: PropTypes.func,
	handleOperationType: PropTypes.func
};

export default withStyles(styles)(CommissionFeeProfileAddOrUpdate);
