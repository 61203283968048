import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import moment from "moment";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import { withArtifex } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, MenuCodes } from "views/Constants/Constant";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button.jsx";

class FeeTransactionSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {


			},
			list: [],
			isLoading: false
		};
		this.parameterValueTemp = {
			ParamterValue: "FeeType"
		};
		this.parameterFeeTypeValueTemp = {	};
		this.renderItemFeeGroup = {};
		this.parameterClient = {};
		this.trxTable = React.createRef();
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
	}
	componentDidMount() {

		let IsFeeTransaction = this.props.MenuCode === MenuCodes.Fee_Transaction;
		if (IsFeeTransaction) {
			this.props.setAppLeftTitle("Fee Transaction Detail Search");

		}
		else {
			this.props.setAppLeftTitle("Fee Transaction Search");

		}
		this.props.setAppCenterTitle("WALLET");
		this.setState({ loadingCompleted: true });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "ReferenceNumber") {
			model.IsUniqueDataSearchMode = newValue?.length > 0;
		}
		if (name == "UniqueClientId" && newValue==null) {
			model.UniqueClientId = 0;
		}
		this.setState({ model });
	}
	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}
	validate = () => {
		const { model, vModel } = this.state;
		var messages = [];
		if (!model.IsUniqueDataSearchMode && (!model.UniqueClientId || model.UniqueClientId <= 0)) {
			vModel.UniqueClientId = true;
			messages.push("Client Name cannot be null!");
		} else { vModel.UniqueClientId = false; }
		if (!model.IsUniqueDataSearchMode && !model.FromDate) {
			vModel.FromDate = true;
			messages.push("Start Date cannot be null!");
		} else { vModel.FromDate = false; }
		if (!model.IsUniqueDataSearchMode && !model.ToDate) {
			vModel.ToDate = true;
			messages.push("End Date cannot be null!");
		} else { vModel.ToDate = false; }
		if (!model.IsUniqueDataSearchMode && model.FromDate && model.ToDate && moment(model.ToDate).diff(moment(model.FromDate), "days") > 90) {
			messages.push("Difference between two dates cannot be longer than 90 days.");
		}
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		return true;
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type} OnConfirm={() => this.setState({ alert: null })}
				/>
		});
	}
	handleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
		this.setState({ isLoading: false });
	}
	handleClear = () => {
		this.setState({
			model: {},
			list: [],
			isLoading: false
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.validate())
			return;

		this.props.showConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			const { model } = this.state;

			model.IsFeeTrx = this.props.MenuCode === MenuCodes.Fee_Transaction;
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			var mytitle = "";
			if (this.props.MenuCode === MenuCodes.Fee_Transaction) { mytitle = "Fee Transactions Detail Report"; }
			else { mytitle = "Fee Transactions Report"; }

			model["jsonFile"] = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: mytitle
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/bankapi/v1.0/FeeTransaction/SearchFeeTransactionExport",
				model,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", errorMessage);
				},
				this.props.showMessage
			);
		});
	}
	GetFeeGroupValue = () => {
		this.parameterFeeTypeValueTemp.ParameterValue = this.state.model.FeeGroupValue;
		return this.parameterFeeTypeValueTemp;
	}
	RenderItemFeeGroup = (d) => {
		this.renderItemFeeGroup = `${d.ParameterValue}`;
		return this.renderItemFeeGroup;
	}
	GetClientParameter = () => {
		this.parameterClient.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterClient;
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, vModel } = this.state;
		let IsFeeTransaction = this.props.MenuCode === MenuCodes.Fee_Transaction;

		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: () => this.ExportClick(), Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client Name"
										Value={model == null ? "" : model.UniqueClientId || ""}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Name"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Method="POST"
										Parameter={{
											UniqueClientId: 0,
										}}
										Required={!model.IsUniqueDataSearchMode}
										IsInvalid={vModel.UniqueClientId}
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericDateInput
										Utc
										Name="FromDate"
										LabelText="Start Date"
										Value={model == null ? "" : model.FromDate ? model.FromDate : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate || DateHelper.GetDate()}
										IncludeTime={false}
										Required={!model.IsUniqueDataSearchMode}
										IsInvalid={vModel.FromDate}
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="FeeGroupValue"
										LabelText="Fee Group"
										Method="POST"
										Url={"/coreapi/v1.0/Parameter/FeeGroupandFeeTypeSearch"}
										DataRoot="Item"
										KeyValueMember="ParameterValue"
										// TextValueMember="ParameterValue"
										Parameter={{ParameterValue:"FeeType"}}
										Value={model.FeeGroupValue}
										RenderItem={this.RenderItemFeeGroup}
										ValueChanged={this.handleChange}
										// Sorted={{ Member: "ParameterValue" }}
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										key={[model.FeeGroupValue]}
										Name="FeeTypeValue"
										LabelMd={3}
										LabelText="Fee Type"
										Method="POST"
										Url={"/coreapi/v1.0/Parameter/FeeGroupandFeeTypeSearch"}
										Parameter={this.GetFeeGroupValue()}
										DataRoot="Item"
										KeyValueMember="ParameterValue"
										TextValueMember="ParameterValue"
										Value={model.FeeTypeValue}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
							
								
								<GridItem xs={3}>
									<GenericNumberInput
										NoFormatting Name="ReferenceNumber"
										LabelText="Reference Number"
										Value={model.ReferenceNumber}
										ValueChanged={this.handleChange} />
								</GridItem>

								<GridItem xs={3}>
									<GenericDateInput
										Utc
										Name="ToDate"
										LabelText="End Date"
										Value={model == null ? "" : model.ToDate ? model.ToDate : ""}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate == null ? null : model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false}
										Required={!model.IsUniqueDataSearchMode}
										IsInvalid={vModel.ToDate}
									/>
								</GridItem>


								{!IsFeeTransaction &&
									<GridItem xs={3}>
										<GenericSelectInput
											Name="ProgramId"
											LabelText="Program"
											Method="GET"
											Url="/bankapi/v1.0/Bank/ValidBankProgramsForAdjustment"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.ProgramId}
											ValueChanged={this.handleChange}
											Sorted={{ Member: "Description" }}
										/>
									</GridItem>
								}
								{!IsFeeTransaction &&
									<GridItem xs={3}>
										<GenericNumberInput
											NoFormatting={true}
											Name="AccountNumber"
											LabelText="Wallet Number"
											Value={model.AccountNumber}
											ValueChanged={this.handleChange}
											Disabled={Disabled}
											MaxLength={12}
											LabelMd={3}
										/>
									</GridItem>
								}
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Fee Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									ShowPagination={true}
									PageSize={10}
									IsSorted={false}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									ValidateRequest={this.validate}
									PrepareRequest={() => {
										const temp = { ...this.state.model };
										temp.IsFeeTrx = this.props.MenuCode === MenuCodes.Fee_Transaction;

										return temp;
									}}
									RequestUrl="/bankapi/v1.0/FeeTransaction/SearchFeeTransactionPaged"
									RequestMethod="POST"
									Columns={[
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Client Name",
											accessor: "UniqueClientName",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
										{
											Header: "Wallet Name",
											accessor: "AccountName",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
										{
											Header: "Wallet Number",
											accessor: "AccountNumber",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
										{
											Header: "Fee Group",
											accessor: "FeeGroup",
											Cell: row => (
												row.value ? row.value : "-"
											),
											// show: !IsFeeTransaction

										},
										{
											Header: "Fee Type",
											accessor: "FeeType",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
										{
											Header: "Fee Profile Type",
											accessor: "FeeProfile",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
										{
											Header: "Fee Profile Name",
											accessor: "ProfileName",
											Cell: row => (
												row.value ? row.value : "-"
											),
											show: IsFeeTransaction
										},

										{
											Header: "Calculate Commission",
											accessor: "CalculateCommission",
											Cell: row => (
												row.value == true ? "Yes" : "No"
											),
											show: IsFeeTransaction
										},

										{
											Header: "Sales Agent Type",
											accessor: "SalesAgentType",
											Cell: row => (
												row.value ? row.value : "-"
											),
											show: IsFeeTransaction
										},

										{
											Header: "Sales Agent Name",
											accessor: "SalesName",
											Cell: row => (
												row.value ? row.value : "-"
											),
											show: IsFeeTransaction
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Cost Amount",
											accessor: "CostAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Program",
											accessor: "ProgramDescription",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
										{
											Header: "Description",
											accessor: "Description",
											Cell: row => (
												row.value ? row.value : "-"
											),
											show: IsFeeTransaction
										},
										{
											Header: "Reference Number",
											accessor: "ReferenceNumber",
											Cell: row => (
												row.value ? row.value : "-"
											)
										},
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>


				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


			</div>
		);
	}
}

FeeTransactionSearch.propTypes = {
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};


export default withArtifex(FeeTransactionSearch, {});