
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class WaitingPaymentList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				Id: 0,
				UniqueClientId: ClientHelper.IsClient()
					? ClientHelper.GetClientRowId()
					: undefined,
				Customer: null,
				CustomerNumber: null,
				CustomerId: 0,
			},
			data: [],
			isLoading: false,
		};

		this.isBackOffice = ClientHelper.IsBackOffice();

	}
	handleChangeForCustomer = (name, newValue) => {
		const model = { ...this.state.model };

		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
				} else {
					model.CustomerNumber = null;
				}
			} else {
				model.Customer = null;
			}
		}
		this.setState({ model });
	};

	FillCustomer = (customer) => {
		this.handleChangeForCustomer("Customer", customer);
	};


	showErrorMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Error" Message={message} Type="error" OnConfirm={() => this.setState({
				alert: null,
				isLoading: false
			})} />
		});
		this.setState({ isLoading: false });
	}
	componentDidMount() {

		this.props.setAppLeftTitle("Waiting Payment List");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}

	Clear = () => {
		this.setState({ model: {}, data: [], isLoading: false });
	}
	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/Applicant/WaitingPaymentListSearch",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {

					this.setState({ data: responseData.Item });
				}
			},
			e => {

				this.setState({ isLoading: false });
			}
		);

	}
	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}





	render() {
		const { accountModel, model, data } = this.state;
		const { Disabled, classes } = this.props;
		const pagination = true;
		const paginationPageSize = 10;
		return (
			<div>
				<GridContainer spacing={8}>
					{this.state.alert}
					<LoadingComponent Show={this.state.isLoading} />

					<GridItem xs={6}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: this.Clear, Disabled: Disabled },
							{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
					</GridItem>
					<Card className="no-radius">
						<CardBody>
							<GridContainer>

								<GridItem xs={6}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear
										Disabled={!this.isBackOffice}
										DefaultIndex={ClientHelper.IsClient() ? 0 : -1} />
									<CustomerComponent
										key={model.UniqueClientId}
										CustomerNumber={
											model.Customer === null
												? null
												: model.Customer.CustomerNumber
										}
										HandleChange={this.handleChange}
										FillCallback={this.FillCustomer}
										UniqueClientId={model.UniqueClientId}
										Disabled={!model.UniqueClientId}
									/>
									<GenericDateInput
										Name="FromDate"
										LabelText="From Date"
										Value={model == null || undefined ? null : model.FromDate == null || undefined ? "" : model.FromDate}
										ValueChanged={this.handleChange}
										MaxDate={model.EndDate == null ? DateHelper.GetDateLocal() : model.EndDate}
									/>
									<GenericDateInput
										Name="EndDate"
										LabelText="End Date"
										Value={model == null || undefined ? null : model.EndDate == null || undefined ? "" : model.EndDate}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDateLocal()}
										MinDate={model.FromDate}
									/>

								</GridItem>
								<GridItem>

								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>



					<Card className="no-radius">
						<GenericExpansionPanel Title={"Waiting Payment List"}>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={data}
													Columns={[
														{
															Header: "Statement Id",
															accessor: "StatementId",
														},
														{
															Header: "Statement Date",
															accessor: "StatementDate",
															type: GridColumnType.DateTime,
															ColumnType: GridColumnType.DateTime
														},
														{
															Header: "Payment Date",
															accessor: "PaymentDate",
															type: GridColumnType.DateTime,
															ColumnType: GridColumnType.DateTime
														},
														{
															Header: "Customer Name",
															accessor: "CustomerName",

														},
														{
															Header: "Waiting Payment",
															accessor: "WaitingPayment",

														}

													]}
													PageSize={10}
													ShowPagination={true}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>
					</Card>



				</GridContainer>

			</div>
		);
	}
}
WaitingPaymentList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default withArtifex(WaitingPaymentList, {});