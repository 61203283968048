import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTitle, GenericTextInput, GenericDialog, GenericLabel, GenericRadioInput, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankTransactionStatus, Direction, EtransferApplicationTypes, GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import { DialogTitle, DialogContent } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Select from "react-select";

class eTransferIncomingTransactions extends Component {
	constructor(props) {
		super(props);
		this.isClient = ClientHelper.IsClient();
		this.initialModel = {
			SearchEndDate: DateHelper.GetDate(),
			SearchStartDate: DateHelper.GetDate(),
			DirectionParameterValue: Direction.Incoming,
			ClientId: 0,
			IsBankingScreen: false
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			tempModel: {},
			getDataList: [],
			summaryModel: {},
			isLoading: false,
			isBackOffice: false,
			selectList: [],
			checkedValues: [],
			LimitToken: false
		};

		var isBackOffice = ClientHelper.IsBackOffice();
		this.columns = [
			{
				Header: "Actions",
				accessor: "AC",
				Cell: d => (
					<div>
						<GenericCheckInput
							Grid
							Value={this.state.checkedValues.filter(c => c.TransactionEtransferId == d.original.TransactionEtransferId).length > 0}
							ValueChanged={() => { this.HandleCheck(d.original); }}
							Disabled={d.original.StatusParamValue == BankTransactionStatus.Traced}
						/>
					</div>
				),
				sortable: false,
				filterable: false,
				width: 70,
				show: !isBackOffice
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Settlement Date",
				accessor: "SettlementDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: isBackOffice
			},
			{
				Header: "Transfer Type",
				accessor: "TransactionType"
			},
			{
				Header: "Sender Name",
				accessor: "SenderName"
			},
			{
				Header: "Sender EMail",
				accessor: "CustomerEmail"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Sender Bank",
				accessor: "SenderBank",
				show: this.state.model.DirectionParameterValue == Direction.Incoming
			},
			{
				Header: "Sender Transit",
				accessor: "SenderTransit",
				show: this.state.model.DirectionParameterValue == Direction.Incoming
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Settlement Status",
				accessor: "SettlementStatus"
			},
			{
				Header: "Transfer Status",
				accessor: "BankTransactionStatus"
			},
			{
				Header: "Transaction Description",
				accessor: "TransactionMemo"
			},
			{
				Header: "Trans. Ref. No ",
				accessor: "ParticipantReferenceNumber"
			},
			{
				Header: "Interac Ref. Number",
				accessor: "TransferReferenceNumber"
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Application",
				accessor: "ApplicationId",
				Cell: row => {
					var rowValue = "";
					EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
						if (item.Value == row.value)
							rowValue = item.Text;
					});
					return rowValue;
				}
			},
			{
				Header: "Error Description",
				accessor: "ErrorDesc"
			},			
			{
				Header: "Customer Store",
				accessor: "CustomerStore"
			},
			{
				Header: "Sender Email",
				accessor: "SenderEmail",
			},
			{
			  Header: "Receiver Name",
			  accessor: "ReceiverName",
			},
			{
			  Header: "Receiver Email",
			  accessor: "ReceiverEmail",
			},
			{
			  Header: "Client Reference Number",
			  accessor: "ClientRefNo",
			},
		];
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
		this.sortedByDate = [{ id: "TransactionDate", desc: true }];
		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Interac e-Transfer / Incoming Transactions");

		var model = { ...this.state.model };
		var initialModel = this.initialModel;
		model.IsBankingScreen = this.props.MenuCode == "BETINC";
		var isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			initialModel.ClientId = model.ClientId;
			this.props.setAppCenterTitle(model.IsBankingScreen ? "WALLET" : "INTERAC E-TRANSFER");
		}
		else {
			this.props.setAppCenterTitle("INTERAC E-TRANSFER");
		}
		this.RefreshToolBar();
		this.setState({ model, isBackOffice, loadingCompleted: true });
	}
	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}
	IsValid = () => {
		var { model, vModel } = this.state;

		var searchIsNotSpecific = (model.RefNo == undefined || model.RefNo == null || model.RefNo == "") &&
			(model.InteracRefNo == undefined || model.InteracRefNo == null || model.InteracRefNo == "") &&
			(model.PayeeEmail == undefined || model.PayeeEmail == null || model.PayeeEmail == "");

		model["searchIsNotSpecific"] = searchIsNotSpecific;

		var validateStatus = true;

		if (searchIsNotSpecific) {
			if (!model.ClientId) {
				this.props.showMessage("warning", "Please fill required fields!", "Select Client to continue");
				vModel["ClientId"] = true;
				validateStatus = false;
			}

			if (model.SearchStartDate=="" || (typeof model.SearchStartDate != "object" && model.SearchStartDate != "" && model.SearchStartDate != null)) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
				validateStatus = false;
			}
			if (model.SearchEndDate==""||(typeof model.SearchEndDate != "object" && model.SearchEndDate != "" && model.SearchEndDate != null)) {
				this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
				validateStatus = false;
			}
			if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object") && model.SearchEndDate < model.SearchStartDate) {
				this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
				validateStatus = false;
			}

			if ((typeof model.SearchStartDate == "object" && typeof model.SearchEndDate == "object")) 
				{
					var diffMonths = model.SearchEndDate.diff(model.SearchStartDate, "days");
					if (diffMonths < 0)
					 {
						this.props.showMessage("warning", "Warning", "End Date must be later than Start Date");
						validateStatus = false;
					}

					if (diffMonths > 30)
					 {
						this.props.showMessage("warning", "Warning", "Difference between Start Date and End Date cannot be longer than 30 days");
						validateStatus = false;
					}
				}
			this.setState({ model, vModel });
			if (!validateStatus)
				return false;
		}
		else {
			vModel["ClientId"] = false;
			this.setState({ model, vModel });
		}
		return true;
	}

	GetData() { this.setState(state => ({ trxGridToken: !state.trxGridToken })); this.GetSummaryData(); }

	GetSummaryData = () => {
		if (!this.IsValid())
			return;

		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetEtransferOutInSummaryData",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ summaryModel: responseData.Item });
			},
			error => {
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.TransactionList == null || responseData.Item.TransactionList.length < 1) {
			this.setState({ summaryModel: {}, getDataList: [], isLoading: false });
		} else {
			var summaryModel = responseData.Item == null ? {} : responseData.Item.SummaryItems;
			this.setState({
				summaryModel,
				getDataList: responseData.Item.TransactionList == null ? [] : responseData.Item.TransactionList, isLoading: false
			});
		}
	}

	HandleCheck(trans) {
		var selectList = [...this.state.selectList];

		selectList = this.state.selectList.includes(trans.TransactionEtransferId)
			? this.state.selectList.filter(t => t !== trans.TransactionEtransferId)
			: [...this.state.selectList, trans.TransactionEtransferId];

		this.setState(state => ({
			selectList,
			checkedValues: state.checkedValues.includes(trans)
				? state.checkedValues.filter(c => c !== trans)
				: [...state.checkedValues, trans]
		}));
	}

	handleTrace = () => {
		if (this.state.selectList.length > 0)
			this.setState({
				isLoading: false,
				alert: <GenericAlert Title={"Traced"} Message={"Trace selected [" + this.state.selectList.length + "] transactions ?"} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleTraceOk(this.state.selectList)} />
			});
		else {
			this.ShowErrorMessage("Trace List must not be null !!");
		}
	}

	handleTraceOk = (IdList) => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/TraceIncomingEftByIds",
			{ TransactionEFTIds: IdList },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowErrorMessage("Error =>" + responseData.ErrorDescription);
					return;
				}
				else {
					this.setState({
						isLoading: false,
						selectList: {},
						alert: <GenericAlert Title={"Traced"} Message={"Transactions trace operation completed successfully."} Type="success" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
					});
				}
			},
			this.errorCallback
		);
	}

	ShowErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Warning"} Message={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	errorCallback(error) {
		this.hideLoading();
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleClear = () => {
		this.setState({
			model: this.initialModel,
			getDataList: [],
			summaryModel: {}
		});
	}
	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }
	hideAlert() { this.setState({ alert: null }); }

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "AC" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.IsValid())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Interac e-Transfer Incoming Transfers";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClearingSettlement/GetETransferIncomingTransfersExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading, isBackOffice, checkedValues, LimitToken, vModel } = this.state;
		return (
			<div>
				{this.state.alert}
				{this.isClient && <AccountLimitToolbar key={"_" + LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} IsUseDisableDebitLimit={false} />}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					// { Code: "Trace", OnClick: ()=>{}, Disabled: Disabled || this.state.selectList.length > 0 ? false : true },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />
				{/*EXPORT DIALOG*/}
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<Card className="no-radius">

					<GenericExpansionPanel Title={"Summary Information"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										CanClear
										Required={model.searchIsNotSpecific == true}
										IsInvalid={vModel.ClientId}
										Disabled={!isBackOffice} />
									<GenericDateInput
										Name="SearchStartDate"
										LabelText="Start Date"
										Value={model.SearchStartDate}
										ValueChanged={this.handleChange}
										MaxDate={model.SearchEndDate || DateHelper.GetDate()}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="SearchEndDate"
										LabelText="End Date"
										Value={model.SearchEndDate}
										ValueChanged={this.handleChange}
										MinDate={model.SearchStartDate == null ? null : model.SearchStartDate}
										IncludeTime={false}
										Utc />
									<GenericSelectInput
										Name="TransactionTypeId"
										LabelText="Transaction Type"
										Value={model === undefined ? "" : model.TransactionTypeId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterValue6"
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
									/>
									<GenericSelectInput
										Name="NotificationStatusId"
										LabelText="Notification Status"
										Value={model == null || undefined ? "" : model.NotificationStatusId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Url="/coreapi/v1.0/Parameter/Search"
										Method="POST"
										Parameter={{
											ParameterCode: "NotificationStatus",
											"ParameterValue": "",
											"ParameterValue2": "",
											"ParameterDesc": ""
										}}
									/>
									<GenericSelectInput
										Name="ApplicationId"
										LabelText="Application Type"
										Value={model === undefined ? "" : model.ApplicationId || ""}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Value"
										TextValueMember="Text"
										IsStatic
										StaticData={EtransferApplicationTypes || []}
									/>
									<GenericNumberInput
										NoFormatting={true}
										Name="RefNo"
										LabelText="Reference Number"
										Value={model.RefNo}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="InteracRefNo"
										LabelText="Interac Reference Number"
										Value={model.InteracRefNo}
										ValueChanged={this.handleChange} />
									<GenericNumberInput
										NoFormatting={true}
										Name="AccountNumber"
										LabelText="Wallet Number"
										Value={model.AccountNumber}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={1}></GridItem>
								<GridItem xs={7}>
									<Card>
										<CardBody>
											<GridContainer>
												<GridItem xs={6}>
													<GenericNumberInput
														Name="TotalNoOfCredit"
														LabelText="Total No. of Money Send"
														LabelMd={6}
														Value={summaryModel == null ? "" : summaryModel.TotalNoOfCredit ? summaryModel.TotalNoOfCredit : ""}
														Disabled={true}
														DecimalScale={0} />
													<GenericNumberInput
														Name="TotalNoOfDebit"
														LabelText="Total No. of Money Request"
														LabelMd={6}
														Value={summaryModel == null ? "" : summaryModel.TotalNoOfDebit ? summaryModel.TotalNoOfDebit : ""}
														Disabled={true}
														DecimalScale={0} />
												</GridItem>
												<GridItem xs={1}></GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														Name="CreditTotal"
														LabelText="Money Send Total"
														Value={summaryModel == null ? "" : summaryModel.CreditTotal ? summaryModel.CreditTotal : ""}
														Disabled={true}
														Prefix="$" />
													<GenericNumberInput
														Name="DebitTotal"
														LabelText="Money Request Total"
														Value={summaryModel == null ? "" : summaryModel.DebitTotal ? summaryModel.DebitTotal : ""}
														Disabled={true}
														Prefix="$" />
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Incoming Transfers"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									PageSize={10}
									ShowPagination={true}
									Sorted={this.sortedByDate}
									Columns={this.columns}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
									ServerSide
									LoadAtStartup={this.state.loadingCompleted}
									ValidateRequest={this.IsValid}
									PrepareRequest={() => { return this.state.model; }}
									RequestUrl="/bankapi/v1.0/ClearingSettlement/GetETransferIncomingTransfersPaged"
									RequestMethod="POST"
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eTransferIncomingTransactions.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eTransferIncomingTransactions, {});