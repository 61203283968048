import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import HtmlEditor from "core/HtmlEditorHelper";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-autocomplete-input/dist/bundle.css";
import ClientHelper from "core/ClientHelper";
import { renderToString } from "react-dom/server";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericColorInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import TemplatePreview from "./TemplatePreview";
import { CMSTemplateType } from "views/Constants/Constant.js";

class TemplateDefinition extends Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			BackgroundColor: "#fff",
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined
		};
		
		this.state = {
			alert: null,
			model: this.initialModel,
			vModel: {},
			list: [],
			isBrowse: false,
			isLoading: false,
			editorModelEmail: EditorState.createEmpty(),
			editorPropertyList: [],
			File: {},
			isTempPreviewDialogOpen: false,
			rowIndex: -1
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
		};

		this.parameterTemplateType = {
			ParameterCode: "CMSTemplateType"
		};

		this.sortedParameterDesc = { Member: "ParameterDesc" };

		this.fileInputRef1 = React.createRef();
		this.fileInputRef2 = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Template Definition");
		this.props.setAppCenterTitle("CARD MANAGEMENT SYSTEM");
		this.setState({
			editorModelEmail: HtmlEditor.EmptyEditorContent()
		});
		((this.state.editorPropertyList == null || this.state.editorPropertyList.length == 0) && this.GetDataModelPropertyList());
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		if (name == "TemplateTypeId")
			model.TemplateType = data;

		this.setState({ model });
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			state.editorModelEmail = HtmlEditor.EmptyEditorContent();
			return state;
		});
	};

	HandleSearch = () => {
		this.setState({ isLoading: true, list: [] });
		Proxy.POST(
			"/prepaidapi/v1.0/CardNotificationTemplate/Search",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			this.ErrorCallback
		);
	}

	HandleSubmit = () => {
		const { model } = this.state;

		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardNotificationTemplate/InsertOrUpdate",
			model,
			responseData => {
				this.setState({ isLoading: false, list: [] });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Operation is successfully!");
				this.HandleSearch();
			},
			this.ErrorCallback
		);
	}

	HandleDelete = () => {
		Proxy.GET(
			"/prepaidapi/v1.0/CardNotificationTemplate/Delete/Id?Id=" + this.state.model.Id,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Deleted successfully!");
				this.HandleClear();
				this.HandleSearch();
			},
			this.ErrorCallback
		);
	}
	 getFileBase64 = (file, callback) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		// Since FileReader is asynchronous,
		// we need to pass data back.
		reader.onload = () => callback(reader.result);
		// TODO: catch an error
		reader.onerror = error => { };
	};

	 imageUploadCallback = file => new Promise(
		(resolve, reject) => this.getFileBase64(
			file,
			data => resolve({ data: { link: data } })
		)
	);

	GetDataModelPropertyList = () => {
		this.setState({ isLoading: true, list: [] });
		Proxy.GET(
			"/coreapi/v1.0/NotificationTemplate/GetDataModelProperties",
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					var temp = responseData.Item.map(x => {
						return { text: x.PropertyDescription, value: x.PropertyName };
					});
					this.setState({ editorPropertyList: temp });
				}
			},
			this.ErrorCallback
		);
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	EditorStateChangeEmail = (obj) => {
		this.setState({ editorModelEmail: obj });
		HtmlEditor.EditorContentToText(this.state.editorModelEmail);
	}

	FileSelect = (e, name) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var HandleChageTemp = this.HandleChange;
		var model = this.state.model;
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			var base64 = reader.result;
			HandleChageTemp(name.concat("Name"), file.name);
			HandleChageTemp(name, base64);
		};
		reader.onerror = function (error) {
			console.log("File Error: ", error);
		};
		this.setState({ model });
	}

	RowSelected = (index) => {
		const { list } = this.state;
		const model = list[index];

		this.setState({
			model,
			editorModelEmail: HtmlEditor.HtmlToEditorContent(model.Content),
			rowIndex: index
		});
	}

	GetHTMLTemplateContent = (isPreview = false) => {
		const model = this.state.model;
		return (
			<div id="main">
				<div id="main_child_1" style={{ backgroundColor: model.BackgroundColor, display: "flex", flexDirection: "column", alignItems: "center" }}>
					<div id="main_child_2" style={{ display: "flex", flexDirection: "column", justifyContent: "left", width: "400px", height: "650px" }}>
						<img id="img_child_1" style={{ width: "100px", height: "75px", float: "left", }} src={model.Logo} />
						<img id="img_child_2" style={{ height: "225px", float: "left" }} src={model.HeaderImage} />
						<div id="text_child_1" style={{ height: "270px", backgroundColor: "white", float: "left" }}>
							<p id="p_child_1"> {isPreview ? HtmlEditor.EditorContentToText(this.state.editorModelEmail) : HtmlEditor.EditorContentToHtml(this.state.editorModelEmail)}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.HideAlertAndRefresh()} />
		});
	}

	HideAlertAndRefresh = () => {
		this.HandleClear();
		window
			.location
			.reload();

	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	IsStringNullOrEmptyOrWhiteSpace = (value) => {

		return !(typeof value === "string") || value == null || value.trim().length == 0;
	}

	Validate = () => {
		var ErrorList = [];
		const { model, vModel } = this.state;

		if (model.UniqueClientId == null || model.UniqueClientId == 0) {
			vModel.UniqueClientId = true;
			ErrorList.push("Client must be selected");
		}
		else {
			vModel.UniqueClientId = false;
		}

		if (model.TemplateTypeId == null || model.TemplateTypeId == 0) {
			vModel.TemplateTypeId = true;
			ErrorList.push("Action must be selected");
		}
		else {
			vModel.TemplateTypeId = false;
		}

		if (model.TemplateType && model.TemplateType.ParameterValue === CMSTemplateType.TransactionLimit && (model.Amount == null || model.Amount == 0)) {
			vModel.Amount = true;
			ErrorList.push("Amount must be entered");
		}
		else {
			vModel.Amount = false;
		}

		if (model.Description == null || model.Description == "") {
			vModel.Description = true;
			ErrorList.push("Description must be entered");
		}
		else {
			vModel.Description = false;
		}

		var contentText = HtmlEditor.EditorContentToHtml(this.state.editorModelEmail);
		if (this.IsStringNullOrEmptyOrWhiteSpace(contentText)) {
			ErrorList.push("Template message must be defined");
		}

		var letterContent = renderToString(this.GetHTMLTemplateContent(false));
		if (letterContent == null) {
			ErrorList.push("Template Content must be defined");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		model.Content = contentText;

		this.setState({ model, vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { model, alert, editorModelEmail, editorPropertyList, isLoading, isTempPreviewDialogOpen, vModel } = this.state;
		const { classes, Disabled, IsPopUp } = this.props;

		var ForInsert = !(model.Id > 0);
		return (

			<>
				<GenericDialog open={isTempPreviewDialogOpen} maxWidth="xl" fullWidth={true}>
					<DialogTitle>
						<GridContainer justify="space-evenly" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="Template Preview" Bold={true} />
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<TemplatePreview content={this.GetHTMLTemplateContent(true)} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isTempPreviewDialogOpen: false })}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>

				<LoadingComponent Show={isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.HandleSubmit,
							Disabled: !ForInsert
						}, {
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: false
						}, {
							Code: "Update",
							OnClick: this.HandleSubmit,
							Disabled: ForInsert
						}, {
							Code: "Delete",
							OnClick: this.HandleDelete,
							Disabled: ForInsert
						}, {
							Code: "Clear",
							OnClick: this.HandleClear,
							Disabled: false
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					IsPopUp={false} />

				<GridContainer>
					{alert}
					<GridItem xs={12} >
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true} Title="Main Parameters">
									<GridContainer justify="flex-start" alignItems="flex-start" direction="row">
										<GridContainer>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													CanClear
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.UniqueClientId}
													Disabled={this.isClient}
													DefaultIndex={this.isClient ? 0 : -1} />
											</GridItem>
											<GridItem xs={3}>
												<ParameterComponent
													Name="TemplateTypeId"
													LabelText="Action"
													LabelMd={4}
													ParameterCode={this.parameterTemplateType.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={model.TemplateTypeId}
													Required
													IsInvalid={vModel.TemplateTypeId}
													ValueChanged={this.HandleChange}
													Disabled={false} />
											</GridItem>
											{model.TemplateType && model.TemplateType.ParameterValue === CMSTemplateType.TransactionLimit &&
												<>
													<GridItem xs={3}>
														<GenericNumberInput
															Name="Amount"
															LabelMd={4}
															LabelText="Amount"
															Value={model.Amount}
															ValueChanged={this.HandleChange}
															MaxLength={10}
															Required={model.TemplateType.ParameterValue === CMSTemplateType.TransactionLimit}
															IsInvalid={vModel.Amount}
															Prefix="$" />
													</GridItem>
													<GridItem xs={3} />
												</>
											}
											<GridItem xs={6}>
												<GenericTextInput
													Disabled={false}
													IsText={true}
													Name="Description"
													LabelText="Description"
													LabelMd={2}
													Value={model.Description || ""}
													Required
													IsInvalid={vModel.Description}
													ValueChanged={this.HandleChange} />
											</GridItem>
										</GridContainer>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Template Details">
									<GridContainer justify="center" alignItems="flex-start" direction="column">
										<GridContainer>
											<GridItem xs={3} >
												<GenericColorInput
													Disabled={false}
													Name="BackgroundColor"
													LabelText="Background Color"
													Value={model.BackgroundColor}
													ValueChanged={this.HandleChange} />
											</GridItem>
										</GridContainer>
										<br />
										<GridContainer alignItems="center" justify="flex-start" direction="column">
											<GridItem xs={6}>
												<Card className="no-radius">
													<CardHeader>
														<GenericTitle text={"Content"} />
														<GridItem
															xs={5}
															style={{
																float: "right",
																textAlign: "end"
															}}>
															<Button
																disabled={false}
																size="sm"
																onClick={() => {
																	this.setState({ isTempPreviewDialogOpen: true });
																}}>
																PREVIEW
                                                </Button>

														</GridItem>
														<GridItem
															xs={5}
															style={{
																float: "right",
																textAlign: "end"
															}}>
															<Button
																disabled={false}
																size="sm"
																onClick={() => {
																	model.Logo = null;
																	model.HeaderImage = null;
																	model.BackgroundColor = "#fff";
																	this.setState({
																		model: model,
																		editorModelEmail: HtmlEditor.EmptyEditorContent()
																	});
																}}>
																CLEAR
                                                </Button>

														</GridItem>

													</CardHeader>
													<CardBody>
														<GridContainer>
															<GridItem
																xs={3}
																style={{
																	float: "left",
																	textAlign: "left"
																}}>
																{model.Logo == null ?
																	<div>
																		<Button
																			disabled={false}
																			size="sm"
																			onClick={() => this.fileInputRef1.current.click()}
																			style={{ marginTop: 8 }}>LOGO
                                                   </Button>
																		<input
																			type="file"
																			key={model.Logo}
																			style={{ display: "none" }}
																			onChange={(e, name) => this.FileSelect(e, "Logo")}
																			ref={this.fileInputRef1}
																			accept="image/*" />
																	</div>
																	:
																	<Button
																		disabled={false}
																		size="sm"
																		onClick={() => {
																			model.Logo = null;
																			this.setState({
																				model: model
																			});
																		}}
																		style={{ marginTop: 8 }}>DELETE LOGO
                                                </Button>
																}
															</GridItem>
														</GridContainer>
														<GridContainer>
															<GridItem xs={3}>
																{model.Logo &&
																	<img
																		src={model.Logo}
																		className={classes.pictureSrc}
																		alt={"LOGO"}
																	/>
																}
															</GridItem>
														</GridContainer>
														<GridContainer>
															<GridItem
																xs={3}
																style={{
																	float: "left",
																	textAlign: "left"
																}}>
																{model.HeaderImage == null ?
																	<div>
																		<Button
																			disabled={false}
																			size="sm"
																			onClick={() => this.fileInputRef2.current.click()}
																			style={{ marginTop: 8 }}
																		>IMAGE
                                                </Button>
																		<input
																			type="file"
																			key={model.HeaderImage}
																			style={{
																				display: "none"
																			}}
																			onChange={(e, name) => this.FileSelect(e, "HeaderImage")}
																			ref={this.fileInputRef2}
																			accept="image/*" />
																	</div>
																	:
																	<Button
																		disabled={false}
																		size="sm"
																		onClick={() => {
																			model.HeaderImage = null;
																			this.setState({
																				model: model
																			});
																		}}
																		style={{ marginTop: 8 }}>DELETE IMAGE
                                                </Button>
																}
															</GridItem>
														</GridContainer>
														<GridContainer>
															<GridItem xs={3}>
																{model.HeaderImage &&
																	<img
																		src={model.HeaderImage}
																		className={classes.pictureSrc}
																		alt={"Header Image"}
																	/>
																}
															</GridItem>
														</GridContainer>
														<br />
														<GridContainer>
															<GridItem xs={10}>
																<Editor
																	editorState={editorModelEmail}
																	toolbarClassName="toolbarClassName"
																	wrapperClassName="wrapperClassName"
																	editorClassName="editorClassName"
																	onEditorStateChange={this.EditorStateChangeEmail}
																	placeholder={"Write your message here..."}
																	toolbar={{ image: { urlEnabled: false, uploadEnabled: true, uploadCallback: this.imageUploadCallback.bind(this), previewImage: true } }}

																	mention={{
																		separator: " ",
																		suggestions: editorPropertyList,
																		trigger: "@"
																	}} />
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">

							<CardBody>
								<GenericExpansionPanel Title="Templates">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.list}
												Columns={[
													{
														Header: "Client",
														accessor: "UniqueClient.ClientName"
													},
													{
														Header: "Actions",
														accessor: "TemplateType.ParameterDesc"
													},
													{
														Header: "Description",
														accessor: "Description"
													}
												]}
												RowSelected={this.RowSelected}
												SelectedIndex={this.state.rowIndex} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>

			</>
		);
	}
}

TemplateDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	ExecuteApiPost: PropTypes.func,
};

export default withArtifex(TemplateDefinition, {});