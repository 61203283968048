import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import IdleTimer from "react-idle-timer";
import { GenericDialog, GenericDialogActions, GenericLabel } from "views/Components/Generic";

class IdleComponent extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			isDialogOpen: false,
			currentCount: window.IdleTimeOutCountDownSecond
		};

		this.intervalHandle = null;

		this.OnAction = this.OnAction.bind(this);
		this.OnActive = this.OnActive.bind(this);
		this.OnIdle = this.OnIdle.bind(this);
		this.CountBack = this.CountBack.bind(this);
		this.Logout = this.Logout.bind(this);
		this.CloseDialog = this.CloseDialog.bind(this);
		this.UpdateUserLastActiveDate = this.UpdateUserLastActiveDate.bind(this);
	}

	componentDidMount() {
		var lastActiveDateIso = localStorage.getItem("LastActiveDate");
		if (lastActiveDateIso != null) {
			var lastActiveDate = DateHelper.ToMoment(lastActiveDateIso);
			if (DateHelper.GetDateTime().diff(lastActiveDate, "milliseconds") > window.IdleTimeOutMilliSecond) {
				this.Logout();
			}
		}
	}

	OnAction() {
		localStorage.setItem("LastActiveDate", DateHelper.GetDateTime().toISOString());
	}

	OnActive() {
		if (this.intervalHandle) {
			clearInterval(this.intervalHandle);
			this.intervalHandle = null;
			this.UpdateUserLastActiveDate();
		}

		this.setState(state => {
			state.isDialogOpen = false;
			state.currentCount = window.IdleTimeOutCountDownSecond;
			return state;
		});
	}

	OnIdle() {
		this.setState({ isDialogOpen: true });
		this.intervalHandle = setInterval(this.CountBack, 1000);
	}

	CountBack() {
		this.setState(state => {
			if (state.currentCount <= 0)
				this.Logout();
			else
				state.currentCount--;

			return state;
		});
	}

	Logout() {
		localStorage.removeItem("user");
		window.open("/#/acc/login", "_self");
	}

	CloseDialog() {
		this.setState({ isDialogOpen: false });
	}

	componentWillUnmount() {
		if (this.intervalHandle) {
			clearInterval(this.intervalHandle);
			this.intervalHandle = null;
		}
	}

	async UpdateUserLastActiveDate() {
		const { ExecuteApiGet } = this.props;

		await ExecuteApiGet("/coreapi/v1.0/User/UpdateLastActiveDate");
	}

	render() {
		const { isDialogOpen } = this.state;

		return (
			<>
				<GenericDialog open={isDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Are you still there?" Bold />
					</DialogTitle>
					<DialogContent>
						<GenericLabel Text={"For your security, you will be logged out automatically in " + this.state.currentCount + " seconds."} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CloseDialog}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>
				<IdleTimer
					element={document}
					onActive={this.OnActive}
					onIdle={this.OnIdle}
					onAction={this.OnAction}
					debounce={250}
					timeout={window.IdleTimeOutMilliSecond} />
			</>
		);
	}
}

IdleComponent.propTypes = {
	ExecuteApiGet: PropTypes.func
};

export default withArtifex(IdleComponent, {});