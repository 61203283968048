import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";
import ApplicantDefinition from "views/LOS/ApplicantDefinition/ApplicantDefinition";
import Select from "react-select";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";

class UncompletedApplicantList extends Component {
	constructor(props) {
		super(props);

		this.columns = [

			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date
			},
			{
				Header: "FullName",
				accessor: "FullName"
			},
			{
				Header: "Unique ID",
				accessor: "ApplicantUniqueId"

			},
			{
				Header: "ID Verification Status",
				accessor: "IdVerification"
			},
			{
				Header: "Internal Blacklist Status",
				accessor: "InternalBlacklist"
			},
			{
				Header: "Watch List Verification Status",
				accessor: "WatchListVerification"
			},
			{
				Header: "Trans Union Id Verification Status",
				accessor: "TransUnionIdVerification"
			},
			{
				Header: "Credit History Verification Status",
				accessor: "CreditHistoryVerification"
			},
			{
				Header: "E-Mail",
				accessor: "Email"
			},
			{
				Header: "Loan Type",
				accessor: "ApplicantAppliedFor.LoanType.ParameterDesc"
			},
			{
				Header: "Sale Channel",
				accessor: "ApplicantAppliedFor.SaleChannel.ParameterDesc"
			}
		];
		this.appListTable = React.createRef();
		this.state = {
			model: {
				FromDate: DateHelper.GetLocalDateAsUtc(),
				ToDate: DateHelper.GetLocalDateAsUtc()
			},
			isApplicantDetailDialogOpen: false,
			isLoading: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Incomplete Applicant List");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;

			model[name] = value;

			if (name == "FromDate") {
				model["ToDate"] = undefined;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index, isApplicantDetailDialogOpen: true });
	}

	ApplicantDetaiDialogClose = () => {
		this.setState({ isApplicantDetailDialogOpen: false });
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/Applicant/GetUncompleteList", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetLocalDateAsUtc(),
				ToDate: DateHelper.GetLocalDateAsUtc()
			}, list: [], selected: null
		});
	}

	ExportClick = () => {
		var appListColumns = [];
		this.appListTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				appListColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: appListColumns, ExportSelectedColumns: appListColumns });
	}

	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });
		const temp = { ...this.state.model };


		var jsonColList = [];

		var appListColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			appListColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(appListColumns);

		var mytitle = "Incomplete Applicant List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		var request = {
			FromDate: temp.FromDate,
			ToDate: temp.ToDate,
			jsonFile: temp.jsonFile
		};
		Proxy.DownloadGeneratedFile(
			"/losapi/v1.0/Applicant/GetUncompleteListExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { model, list, selected, isApplicantDetailDialogOpen,isLoading } = this.state;
		const { history, Disabled, IsPopUp, MenuCode, ExecuteApiPost, ExecuteApiFileDownloadWithGenericResponse } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear,
						},
						{
							Code: "Export",
							Disabled: Disabled,
							OnClick: this.ExportClick,
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					IsPopUp={IsPopUp} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isApplicantDetailDialogOpen} fullWidth maxWidth="lg">
					<DialogContent>
						<ApplicantDefinition
							IsPopup
							history={history}
							ExecuteApiPost={ExecuteApiPost}
							ExecuteApiFileDownloadWithGenericResponse={ExecuteApiFileDownloadWithGenericResponse}
							MenuCode={MenuCode}
							ApplicantModel={{ Id: model.Id }}
							ApplicantId={model.Id} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ApplicantDetaiDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												IsFuture={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												IsFuture={false}
												MinDate={model.FromDate}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													ref={this.appListTable}
													PageSize={10}
													ShowPagination={true} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

UncompletedApplicantList.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	IsPopUp: PropTypes.bool,
	ApplicantModel: PropTypes.object,
	ApprovalDisabled: PropTypes.bool,
	ApplicantDefinitionDiologOpenOrClose: PropTypes.func,
	HandleChange: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
};

export default withArtifex(UncompletedApplicantList, {});