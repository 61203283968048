import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import PropTypes from "prop-types";
import React from 'react';

export default class GenericStepper extends React.PureComponent {
	render() {
		const { Steps, ActiveStep, ...rest } = this.props;
		return (
			<Stepper alternativeLabel activeStep={ActiveStep} {...rest}>
				{Steps.map((item) => {
					const { StepProps, StepLabelProps, Label } = item;
					return (
						<Step key={Label} {...StepProps}>
							<StepLabel {...StepLabelProps}>{Label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		);
	}
}
GenericStepper.propTypes = {
	ActiveStep: PropTypes.number.isRequired,
	Steps: PropTypes.arrayOf(
		PropTypes.shape(
			{
				Label: PropTypes.string.isRequired,
				StepProps: PropTypes.object,
				StepLabelProps: PropTypes.object
			})
	).isRequired
}
