import { DialogContent, DialogTitle, Slide, withStyles } from "@material-ui/core";
import kycPhotoUploadStyle from "assets/jss/material-dashboard-pro-react/views/kycPhotoUploadStyle";
import cx from "classnames";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FileHelper from "core/FileHelper";
import ExternalContainer from "layouts/ExternalContainer";
import React, { PureComponent } from "react";
import { GenericCamera, GenericCardMedia, GenericDialog, GenericDialogActions, GenericLabel, GenericStepper, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ImageHelper from "core/ImageHelper";
import DateHelper from "core/DateHelper";
import { DigitalVerificationAction, DigitalVerificationPopupUploadCode, LinkRequestType } from "views/Constants/Constant";

const ProfileMale = require("assets/img/profile-male.png");
const ProfileFemale = require("assets/img/profile-female.png");

const IdCardImage = require("assets/img/id-card.png");
const IdCardBackImage = require("assets/img/id-card-back.png");
const IdTemplate = require("assets/img/id-template.png");
const FaceTemplate = require("assets/img/face-template.png");

const initialState = {

	model: {},
	photoBase64: {
		0: null,
		1: null,
		2: null
	},
	openDialog: false,
	ActiveStep: 0,
	accessKey: null,
	cameraLabel: null,
	rotateCounter: 0,
	hasTimeout: false,
	timeoutWarn: false,
	steps: [],
	linkRequestTypeId: 0
};
class KycPhotoUpload extends PureComponent {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, initialState);
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.emptyObject = {};
		this.sortName = { Member: "Name" };
		this.gridSortParam = [{ id: "Status", desc: false }];

		this.fileInputRef1 = React.createRef();

		this.fileModifiedStatus = {
			0: false,
			1: false,
			2: false
		};
		this.timer = null;
	}

	AccessKeyExpireInterval = async () => {
		const { model, timeoutWarn, hasTimeout } = this.state;
		var requestDateTime = DateHelper.ToMoment(model.RequestDateTime);
		var minutesDiff = DateHelper.GetDateTime().diff(requestDateTime, "seconds");
		if (!hasTimeout && minutesDiff >= 1800) {
			this.setState({ hasTimeout: true });
			this.timer && clearInterval(this.timer);
			await this.props.showMessage("error", "Error", "Session expired!");
			this.props.history.push("/");
		}
		else if (!timeoutWarn && minutesDiff >= 1500) {
			this.setState({ timeoutWarn: true });
			this.props.showMessage("warning", "Warning", "Session will expire within 5 minutes!");
		}
	}
	setStateAsync = (newState) => {
		return new Promise((resolve) => this.setState(newState, resolve));
	}

	async componentDidMount() {
		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Manual KYC Application Photo Definition");
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("AML & KYC");
		}

		var steps = [
			{ Label: "Upload Identity Photo" },
			{ Label: "Upload Identity Back Photo" },
			{ Label: "Done" },
		];

		var routedModel = JSON.parse(localStorage.getItem(DigitalVerificationAction.PopupUpload));
		var code = routedModel?.Code ?? DigitalVerificationPopupUploadCode.ManualKycEmailLink;
		if (code != DigitalVerificationPopupUploadCode.IdIntroduction) {
			steps.unshift({ Label: "Upload Profile Photo" });
		}
		const { match } = this.props;
		var accessKey = match.params && match.params.key;
		if (accessKey) {
			localStorage.removeItem(DigitalVerificationAction.PopupUpload);
			await this.setStateAsync({ accessKey });
			await this.getKycManualByAccessKey(accessKey);
		}
		else if (code != DigitalVerificationPopupUploadCode.IdIntroduction && (!routedModel?.Id || code != DigitalVerificationPopupUploadCode.ManualKyc)) {
			this.props.history.push("/");
			return;
		}
		const model = { ...this.state.model };
		model.Code = code;
		!accessKey && this.GetLinkRequestTypeList();
		this.setState({ steps, model: routedModel || model });
	}

	GetLinkRequestTypeList = async () => {
		var result = await this.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: LinkRequestType.CODE }) || [];
		var kycLinkRequestTypeId = result.find(a => a.ParameterValue == LinkRequestType.DigitalIdentityVerification)?.Id;
		this.setState({ linkRequestTypeId: kycLinkRequestTypeId });
	}
	timeout = ms => new Promise(res => setTimeout(res, ms))
	getKycManualByAccessKey = async (key) => {
		var requestModel = {};

		var result = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/GetKycForUploadByAccessKey", requestModel);
		if (!result) {
			await this.props.showMessage("error", "Error", "Access timed out exceeted");
			this.props.history.push("/");
			return;
		}

		if (!result?.HasUserPhoto) {
			this.fileModifiedStatus[0] = true;
		}
		if (!result?.HasIdentityPhoto) {
			this.fileModifiedStatus[1] = true;
		}
		if (!result?.HasIdentityPhotoBack) {
			this.fileModifiedStatus[2] = true;
		}

		if (result.RequestDateTime) {
			this.timer = setInterval(this.AccessKeyExpireInterval, 10000);
		}

		await this.setStateAsync({ model: result });
	}

	handleTakePhoto = async (dataUri, base64) => {
		const model = { ...this.state.model };
		const { ActiveStep, cameraLabel } = this.state;

		var photoBase64 = { ...this.state.photoBase64 };

		let name = "Webcam.png";
		if (cameraLabel) {
			name = cameraLabel.replace(/\s/g, "_").replace(/,/g, "-") + ".jpg";
		}
		var rawFile = FileHelper.DataURLtoFile(dataUri, name);

		var blob = await ImageHelper.CompressImage(rawFile);
		var compressedBase64 = await FileHelper.BlobToBase64(blob);
		var compressedFile = FileHelper.DataURLtoFile(compressedBase64, name);
		model.File = compressedFile;
		model.FileName = name;

		this.fileModifiedStatus[ActiveStep] = true;
		photoBase64[ActiveStep] = base64;
		this.setState({ photoBase64, model });
		this.handleClose();
	}

	handleCameraError = (error) => {
		this.props.showMessage("error", "Camera Error", "Camera access errror!");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		this.setState({ model });
	}


	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}
	handleOpenCamera = () => {

		this.setState({ openDialog: true });
	}
	Transition = (props) => {
		return <Slide direction="up" {...props} />;
	}
	OpenFileSelectDialog = () => {
		this.fileInputRef1.current.click();
	}


	HandleFileSelect = async (e) => {
		let closeLoading = () => { this.setState({ isLoading: false }); };
		const { model } = this.state;
		e.preventDefault();
		let reader1 = new FileReader();
		var file1 = e.target.files[0];
		if (!file1) return;
		this.setState({ isLoading: true });
		var blob = await ImageHelper.CompressImage(file1);
		var compressedBase64 = await FileHelper.BlobToBase64(blob);
		var compressedFile = FileHelper.DataURLtoFile(compressedBase64, file1.name);
		file1 = compressedFile;
		if (((file1.size || 0) / 1024 / 1024) > 10) {
			this.props.showMessage("error", "Error", "Photo file size cannot be larger than 10mb");
			this.setState({ isLoading: false });
			return;
		}

		var imageObj = await ImageHelper.GetImageObjectFromBase64(compressedBase64);
		if (imageObj?.width < 100 || imageObj?.height < 100) {
			await this.props.showMessage("error", "Error", "Any dimension of the image cannot be under 100 pixel");
			this.setState({ isLoading: false });
			return;
		}

		model.TempFileId = model.UserPhotoId || 0;
		model.File = file1;
		model.FileName = file1.name;
		model.FileSelect = true;
		const { ActiveStep } = this.state;
		var photoBase64 = { ...this.state.photoBase64 };

		reader1.onloadend = () => {
			var rawBase64 = reader1.result.toString().split(',').pop();
			if (rawBase64 != photoBase64[ActiveStep]) {
				photoBase64[ActiveStep] = rawBase64;
				this.fileModifiedStatus[ActiveStep] = true;
			}
			this.setState({ photoBase64, isLoading: false });

		};
		reader1.onerror = closeLoading;
		reader1.onabort = closeLoading;
		reader1.readAsDataURL(file1);
	}

	handleRotateImage = async () => {
		const { ActiveStep, rotateCounter } = this.state;
		var photoBase64 = { ...this.state.photoBase64 };
		if (!photoBase64[ActiveStep]) {
			return;
		}
		this.setState({ isLoading: true });
		var imageBase64 = ImageHelper.GenerateImageFromRawBase64(photoBase64[ActiveStep]);
		var rotatedBase64 = await ImageHelper.Rotate(imageBase64, 90);
		photoBase64[ActiveStep] = FileHelper.RemoveBase64MimeTag(rotatedBase64);
		var rotatedCount = rotateCounter + 1;
		var isModifiedStatus = rotatedCount % 4 != 0;//if rotated 360 degrees, disable image update
		this.fileModifiedStatus[ActiveStep] = this.fileModifiedStatus[ActiveStep] || isModifiedStatus;

		this.setState({ photoBase64, isLoading: false, rotateCounter: rotatedCount });
	}

	handlePrevious = async () => {
		const { ActiveStep, hasTimeout } = this.state;
		if (ActiveStep == 0) {
			return;
		}
		if (hasTimeout) {
			await this.props.showMessage("error", "Error", "Session expired!");
			this.props.history.push("/");
			return;
		}
		this.setState({ ActiveStep: ActiveStep - 1, rotateCounter: 0 });
	}
	ExecuteApiPost = async (url, model) => {
		const { ExecuteApiPost } = this.props;
		const { accessKey } = this.state;
		if (accessKey) {
			model.AccessKey = accessKey;
		}
		return await ExecuteApiPost(url, model);
	}
	ExecuteApiPostForImage = async (url, model) => {
		const { accessKey } = this.state;
		return await this.ExecuteApiPost(accessKey ? "/amlkycapi/v1.0/KycManualApplication/KycShowBase64" : url, model);
	}
	handleNext = async () => {
		const { ActiveStep, photoBase64, model, accessKey, hasTimeout, steps } = this.state;
		if (steps.length - 1 == ActiveStep) {
			return;
		}

		if (hasTimeout) {
			await this.props.showMessage("error", "Error", "Session expired!");
			this.props.history.push("/");
			return;
		}

		var photoId = this.getActivePhotoId();

		if (this.fileModifiedStatus[ActiveStep] && model.Code == DigitalVerificationPopupUploadCode.ManualKycEmailLink) {
			var fileModel = {
				Id: photoId || 0,
				FileName: model.FileName || "",
				FileDataBase64: photoBase64[ActiveStep]
			};
			var requestModel = {
				AccessKey: accessKey
			};
			if (ActiveStep == 0) {
				requestModel.UserPhoto = fileModel
			} else if (ActiveStep == 1) {
				requestModel.IdentityPhoto = fileModel
			} else {
				requestModel.IdentityPhotoBack = fileModel
			}

			var result = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/UpdateByAccessKey", requestModel);
			if (result) {
				this.fileModifiedStatus[ActiveStep] = false;
			}
		}

		//var modelList = result || [];

		this.setState({ ActiveStep: ActiveStep + 1, rotateCounter: 0 });
	}

	getPlaceholderImage = () => {
		const { ActiveStep, model } = this.state;

		var step = ActiveStep;
		if (model.Code == DigitalVerificationPopupUploadCode.IdIntroduction) {
			step++;
		}
		if (step == 0) {
			return model.GenderValue == "F" ? ProfileFemale : ProfileMale;
		}
		else if (step == 1) {
			return IdCardImage;
		}
		else {
			return IdCardBackImage
		}
	}
	getActivePhotoId = (customActiveStep) => {
		const { model, ActiveStep } = this.state;
		var userPhotoId = model.Image && model.Image.UserPhotoId || model?.UserPhotoId;
		var identityPhotoId = model.Image && model.Image.IdentityPhotoId || model?.IdentityPhotoId;
		var identityPhotoBackId = model.Image && model.Image.IdentityPhotoBackId || model?.IdentityPhotoBackId;

		var activePhotoId = null;
		var step = customActiveStep || ActiveStep;
		if (model.Code == DigitalVerificationPopupUploadCode.IdIntroduction) {
			step++;
		}
		switch (step) {
			case 0:
				activePhotoId = userPhotoId;
				break;
			case 1:
				activePhotoId = identityPhotoId;
				break;
			case 2:
				activePhotoId = identityPhotoBackId;
				break;
			default:
				activePhotoId = null;
				break;
		}
		return activePhotoId;
	}
	handleMediaLoaded = (name, base64) => {
		const { photoBase64, ActiveStep } = this.state;
		var rawBase64 = base64.split(',').pop();
		photoBase64[ActiveStep] = rawBase64;
		this.setState({ photoBase64 });
	}
	OnCameraStart = (stream, cameraLabel) => {
		this.setState({ cameraLabel });

	}

	handleCloseTab = async () => {
		const { model } = this.state;
		var hasAnyModifiedImage = Object.keys((this.fileModifiedStatus || {})).map(index => this.fileModifiedStatus[index]).find(a => a == true);

		if (hasAnyModifiedImage) {
			if (model.Code == DigitalVerificationPopupUploadCode.ManualKyc) {
				await this.UploadForManualKyc();
			}
			else if (model.Code == DigitalVerificationPopupUploadCode.IdIntroduction) {
				await this.UploadForIdIntroduction();
			}
		}
		window.close();
	}


	UploadForManualKyc = async () => {
		const { model, photoBase64, linkRequestTypeId } = this.state;
		var result = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/CreateAccessKey", {
			KycManualId: model?.Id,
			LinkRequestTypeId: linkRequestTypeId
		});
		if (!result?.Id) {
			await this.props.showMessage("error", "Error", "Photo upload error. Session timeout!");
			return;
		}
		localStorage.setItem(DigitalVerificationAction.PopupUpload, result.Id);

		for (let index = 0; index < 3; index++) {

			var photoId = this.getActivePhotoId(index);

			var fileModel = {
				Id: photoId || 0,
				FileName: model.FileName || "",
				FileDataBase64: photoBase64[index]
			};

			var requestModel = {
				AccessKey: result.AccessKey
			};

			switch (index) {
				case 0:
					requestModel.UserPhoto = fileModel;
					break;
				case 1:
					requestModel.IdentityPhoto = fileModel;
					break;
				case 2:
					requestModel.IdentityPhotoBack = fileModel;
					break;
				default:
					break;
			}

			var uploadResult = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/UpdateByAccessKey", requestModel);
			if (uploadResult) {
				this.fileModifiedStatus[index] = false;
			}

		}
	}

	UploadForIdIntroduction = async () => {
		const { model, photoBase64 } = this.state;

		var requestModel = {
			DigitalIdentityVerificationIdentityTypeId: model.DigitalIdentityVerificationIdentityTypeId
		};


		requestModel.IdentityPhoto = {
			Id: this.getActivePhotoId(1) || 0,
			FileName: model.FileName || "",
			FileDataBase64: photoBase64[0]
		};

		requestModel.IdentityPhotoBack = {
			Id: this.getActivePhotoId(2) || 0,
			FileName: model.FileName || "",
			FileDataBase64: photoBase64[1]
		};


		var uploadResult = await this.ExecuteApiPost("/amlkycapi/v1.0/DigitalIdentityVerificationIdIntroduction/UploadImage", requestModel);
		if (uploadResult) {
			this.fileModifiedStatus[1] = false;
			this.fileModifiedStatus[2] = false;
			localStorage.setItem(DigitalVerificationAction.PopupUpload, JSON.stringify(uploadResult));
		}
		else {
			await this.props.showMessage("error", "Error", "An error occurred during image upload ")
		}
	}
	render() {
		const { model, openDialog, isLoading, ActiveStep, photoBase64, steps } = this.state;
		const { classes } = this.props;
		var facingMode = ActiveStep == 0 ? "front" : "back";
		var activeBase64 = photoBase64[ActiveStep];
		var activePhotoId = this.getActivePhotoId();

		var modifiedStep = ActiveStep;
		if (model.Code == DigitalVerificationPopupUploadCode.IdIntroduction) {
			modifiedStep++;
		}
		var mediaClassNames = cx({
			[classes.mediaProfile]: modifiedStep == 0,
			[classes.mediaCard]: (modifiedStep > 0),
			[classes.mediaCardPlaceholder]: !(activeBase64 || activePhotoId) && (modifiedStep > 0)
		});

		var isProfile = this.state.ActiveStep == 0;
		var imgRatio = isProfile ? 0.5 : 0.95;
		var activeOverlay = isProfile ? FaceTemplate : IdTemplate;


		return (
			<ExternalContainer Title="Digital Identity Verification" HalfWidth={false}>
				<LoadingComponent Show={isLoading} />
				<GenericDialog open={openDialog} onBackdropClick={this.handleClose} fullScreen TransitionComponent={this.Transition}>
					<DialogTitle className={classes.bgPrimary}>
						<GenericLabel Text="Take a Picture" FontSize="18px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12}>
								<GenericLabel Text={ActiveStep == 0 ? "Please take a face photo with looking as straight !" : "Please take a picture of the card in accordance with the frame !"} TextColor="black" FontSize="14px" />
								<br />
								<div>
									<GenericCamera
										OverlayRatio={imgRatio}
										OverlayImage={activeOverlay}
										Open={openDialog}
										OnCameraStart={this.OnCameraStart}
										IdealFacingMode={facingMode}
										OnTakePhoto={this.handleTakePhoto}
										OnCameraError={this.handleCameraError}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer justify="center">
					<GridItem xs={12} sm={11} md={10}>
						<Card>
							<CardHeader>
								<GenericTitle text="Photo Definition" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GenericStepper className={classes.stepper} Steps={steps} ActiveStep={ActiveStep} />
									</GridItem>
									<GridItem xs={12} >
										<GridContainer justify="center">
											<GridItem xs={12} sm={11} md={10}>
												<br />
												{steps.length - 1 != ActiveStep ?
													<>
														{model.FirstName && <GenericLabel FontSize={15} Text={`Hey, ${model.FirstName} ${model.LastName}`} />}
														<br />
														<GenericLabel FontSize={15} Text={"Please follow the steps to upload the necessary photos"} /><br /><br />
													</> :
													<GenericLabel FontSize={15} Text={model.Code == DigitalVerificationPopupUploadCode.ManualKycEmailLink ? "Thank you for uploading the necessary photos" : "Thank you for choosing the necessary photos. Please finish the process by clicking the save and close button"} />
												}
												<br />
											</GridItem>
											{steps.length - 1 != ActiveStep && <GridItem xs={12} sm={11} md={10}>
												<div className={classes.innerContainer}>
													<GenericCardMedia
														ExecuteApiPost={this.ExecuteApiPostForImage}
														className={mediaClassNames}
														Image={activeBase64}
														ImageFileId={!activeBase64 && activePhotoId || null}
														ImagePlaceholder={this.getPlaceholderImage()}
														OnLoaded={this.handleMediaLoaded}
														OnClickRotate={this.handleRotateImage}
													/>
													<GridContainer direction="column" className={classes.buttonContainer}>
														<GridItem className={classes.button} xs={6} md={2}>
															<Button
																className={classes.button}
																size="sm"
																onClick={this.handleOpenCamera}
															>
																Open Camera
															</Button>
														</GridItem>
														<GridItem className={classes.button} xs={6} md={2}>
															<Button
																className={classes.button}
																size="sm"
																onClick={this.OpenFileSelectDialog}>
																Select Photo
															</Button>
															<input type="file" style={{ display: "none" }} onChange={this.HandleFileSelect} ref={this.fileInputRef1} accept="image/png,image/jpeg" />
														</GridItem>
													</GridContainer>
												</div>
											</GridItem>}
											<GridItem xs={12} >
												<br />
												<br />
												<GridContainer justify="space-between">
													<GridItem>
														{ActiveStep > 0 &&
															<Button onClick={this.handlePrevious} size="sm" disabled={(model.Code != DigitalVerificationPopupUploadCode.IdIntroduction && !model.Id)} >Previous</Button>
														}

													</GridItem>
													<GridItem>
														{steps && ActiveStep < steps.length - 1 ?
															<Button disabled={(model.Code != DigitalVerificationPopupUploadCode.IdIntroduction && !model.Id) || (!activeBase64 && !this.getActivePhotoId())} onClick={this.handleNext} size="sm">Next</Button> :
															<Button onClick={this.handleCloseTab} size="sm">{model.Code != DigitalVerificationPopupUploadCode.ManualKycEmailLink && "Save And "}Close</Button>
														}
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</ExternalContainer>

		);
	}
}

export default withStyles(kycPhotoUploadStyle)(KycPhotoUpload);