import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericDateInput } from "views/Components/Generic";

class GenericDateFilterInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				BeginDate: props.BeginDate,
				EndDate: props.EndDate
			}
		};

		this.ValueChanged = this.ValueChanged.bind(this);
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			const { Model } = this.props;

			if (name != "BeginDate" && !Model["BeginDate"] && Object.keys(Model).length == 0) {
				model["BeginDate"] = null;
			}
			if (name != "EndDate" && !Model["EndDate"] && Object.keys(Model).length == 0) {
				model["EndDate"] = null;
			}

			this.props.ValueChanged("DateFilter", { "Name": this.props.Name, ...model });
			return { model };
		});
	}
	isValidDate = (d) => {
		return d instanceof Date && !isNaN(d);
	}
	render() {
		var { LabelText, Model, Disabled, BeginDate, EndDate, MinDate, MaxDate, Utc } = this.props;
		var ReadOnly = Disabled && (BeginDate != null && EndDate != null);

		var maxDate = 8640000000000000;
		var minDate = -8640000000000000;
		var maximumForBeginDate = new Date(maxDate);
		var minimumForEndDate = new Date(minDate);

		if (MaxDate != null && this.isValidDate(new Date(MaxDate ?? null))) {
			if (Model["EndDate"] != null && this.isValidDate(new Date(Model["EndDate"] ?? null)) && new Date(MaxDate) > new Date(Model["EndDate"])) {
				maximumForBeginDate = new Date(Model["EndDate"]);
			} else {
				maximumForBeginDate = new Date(MaxDate);
			}
		}



		if (MinDate != null && this.isValidDate(new Date(MinDate ?? null))) {
			if (Model["BeginDate"] != null && this.isValidDate(new Date(Model["BeginDate"] ?? null)) && new Date(MinDate) < new Date(Model["BeginDate"])) {
				minimumForEndDate = new Date(Model["BeginDate"]);
			}
			else {
				minimumForEndDate = new Date(MinDate);
			}
		}

		return (
			<div>
				{Model &&
					<GridContainer>
						<GridItem xs={8}>
							<GenericDateInput
								Name="BeginDate"
								LabelMd={6}
								LabelText={LabelText}
								Value={Model && Model["BeginDate"]}
								ValueChanged={this.ValueChanged}
								IncludeTime={false}
								Utc={Utc === undefined ? true : Utc}
								MinDate={MinDate}
								MaxDate={maximumForBeginDate}
								Disabled={ReadOnly} />
						</GridItem>
						<GridItem xs={4}>
							<GenericDateInput
								Name="EndDate"
								LabelMd={0}
								Value={Model && Model["EndDate"]}
								ValueChanged={this.ValueChanged}
								IncludeTime={false}
								Utc={Utc === undefined ? true : Utc}
								MinDate={minimumForEndDate}
								MaxDate={MaxDate}
								Disabled={ReadOnly} />
						</GridItem>
					</GridContainer>
				}
			</div>
		);
	}
}

GenericDateFilterInput.propTypes = {
	LabelText: PropTypes.string,
	Name: PropTypes.string,
	Model: PropTypes.object.isRequired,
	ValueChanged: PropTypes.func.isRequired,
	Disabled: PropTypes.bool,
	BeginDate: PropTypes.string,
	EndDate: PropTypes.string,
	Utc: PropTypes.bool
};

export default GenericDateFilterInput;