import withStyles from "@material-ui/core/styles/withStyles";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import KeyContactDataTable from "./KeyContactDataTable";

const styles = ({
	...customCheckboxRadioSwitch,
	...customSelectStyle,
	...sweetAlertStyle
});

class CustomerContact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			paramBankCustomerKeyContactList: [],

		};
	}

	errorDefinitionCallback = (error) => {
		this.showErrorMessage("An error occurred while requesting data" + error);
	}
	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}

	hideAlert = () => {
		this.setState({ alert: null });
	}

	componentDidMount() {
		this.GetCustomerKeyContactModels(ClientHelper.GetClientRowId());
	}

	GetCustomerKeyContactModels = (CustomerId) => {
		Proxy.GET(
			"/bankapi/v1.0/CustomerKeyContact/GetByCustomerId/Id?Id=" + CustomerId,
			this.handleCustomerKeyContactResponse,
			this.errorDefinitionCallback
		);
	}

	handleCustomerKeyContactResponse = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		} else {
			this.setState({ paramBankCustomerKeyContactList: responseData.Item || [] });
		}
	}

	render() {
		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<KeyContactDataTable
					Header={true}
					paramBankCustomerKeyContactList={this.state.paramBankCustomerKeyContactList}
				/>
			</GridContainer>
		);
	}
}

CustomerContact.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(CustomerContact);