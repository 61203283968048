import { warningColor } from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const cardDetailStyle = {
	...customSelectStyle,
	...customCheckboxRadioSwitch,
	iconbuttoncolor: {
		color: warningColor
	},
	tabsHeader: {
		background: warningColor
	},
	root: {
		flexGrow: 1,
	},
	zeroMarPad: {
		margin: 0,
		padding: 0
	},
	inputfontsize: {
		fontSize: "10px"
	},
	labelHorizontal: {
		color: "rgba(0, 0, 0, 0.50)",
		cursor: "pointer",
		display: "inline-flex",
		fontSize: "12px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		paddingTop: "39px",
		marginRight: "0",
		"@media (min-width: 992px)": {
			float: "left"
		}
	}
};

export default cardDetailStyle;
