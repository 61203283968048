import { withStyles } from "@material-ui/core";
import kycManualPhotoDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/kycManualPhotoDefinitionStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import { DialogContent, DialogTitle } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ClientHelper from "core/ClientHelper";
import { Proxy } from "core";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import { ApproveIcon, DetailIcon } from "core/Icons";
import GenericCardMedia from "views/Components/Generic/GenericCardMedia.jsx";
import { GenericDialog, GenericDialogActions, GenericLabel } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";


const galerydefault = require("assets/img/galerydefault-image.png");

class CardDesignTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      model: {
        Id: 0,
        UniqueClientId: ClientHelper.IsClient()
          ? ClientHelper.GetClientRowId()
          : undefined,
        image: "",

      },
      fileInfoModel: {},
      data: [],
      isLoading: false,
      rowIndex: -1,
      modelList: [],
      photoBase64: null,
      openDialog: false,
      imgSrc: null,
      isOpenkycUploadWindow: null
    };
    this.ParameterCardBrand = { ParameterCode: "Brand" };
    this.ParameterCardChipType = { ParameterCode: "CardChipType" };
    this.ParameterCardContactless = { ParameterCode: "CardContactlessColorType" };
    this.ParameterStatus = { ParameterCode: "CardImageStatus" };
    this.isBackOffice = ClientHelper.IsBackOffice();
    this.maxLength5 = { maxLength: 5 };
    this.trxTable = React.createRef();
    this.fileInputRef1 = React.createRef();
  }


  componentDidMount() {
    if (this.props.setAppLeftTitle) {
      this.props.setAppLeftTitle("Card Design Tool");
    }
    if (this.props.setAppCenterTitle) {
      this.props.setAppCenterTitle("Card Design");
    }

  }


  OpenFileSelectDialog = () => {
    this.fileInputRef1.current.click();
  }
  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  }
  HandleFileChange = (name, newValue, data) => {
    const model = { ...this.state.fileInfoModel };
    model[name] = newValue;
    this.setState({ model });
  };
  onTodoChange(value){
    this.setState({
      name: value
    });
  }

  ShowImage = async (selectedItem) => {
    const selectedRow = selectedItem;
    const model = { ...this.state.model };

    const { ExecuteApiPost } = this.props;
    var result = await ExecuteApiPost("/coreapi/v1.0/Image/ShowImageBase64FromImageList", { Id: selectedRow.Id });

    var cardNumber = document.getElementById("cardNumber").textContent = "5032 9334 3764 9846";
    cardNumber = document.getElementById("cardNumber").style.position = "absolute";
    cardNumber = document.getElementById("cardNumber").style.left = "20px";
    cardNumber = document.getElementById("cardNumber").style.bottom = "55px";
    cardNumber = document.getElementById("cardNumber").style.color = "white";
    cardNumber = document.getElementById("cardNumber").style.fontSize = "15px";

    var cardName = document.getElementById("cardName").textContent = "Alexander Theodore";
    cardName = document.getElementById("cardName").style.position = "absolute";
    cardName = document.getElementById("cardName").style.left = "20px";
    cardName = document.getElementById("cardName").style.bottom = "20px";
    cardName = document.getElementById("cardName").style.color = "white";
    cardName = document.getElementById("cardName").style.fontSize = "15px";

    var cardDate = document.getElementById("cardDate").textContent = "09/19";
    cardDate = document.getElementById("cardDate").style.position = "absolute";
    cardDate = document.getElementById("cardDate").style.left = "160px";
    cardDate = document.getElementById("cardDate").style.bottom = "30px";
    cardDate = document.getElementById("cardDate").style.color = "white";
    cardDate = document.getElementById("cardDate").style.fontSize = "15px";

    if (selectedRow.CardChipTypeId == 11100) {
      var documentry = document.getElementById("cardChipType").style.width = "50px";
      documentry = document.getElementById("cardChipType").style.height = "40px";
      documentry = document.getElementById("cardChipType").style.left = "20px";
      documentry = document.getElementById("cardChipType").style.bottom = "80px";
      documentry = document.getElementById("cardChipType").style.position = "absolute";
      documentry = document.getElementById("cardChipType").style.borderRadius = "5px";
      documentry = document.getElementById("cardChipType").style.backgroundColor = "white";
    }
	 else {
      var documentry = document.getElementById("cardChipType").style.width = "";
      documentry = document.getElementById("cardChipType").style.height = "";
      documentry = document.getElementById("cardChipType").style.left = "";
      documentry = document.getElementById("cardChipType").style.bottom = "";
      documentry = document.getElementById("cardChipType").style.position = "";
      documentry = document.getElementById("cardChipType").style.borderRadius = "";
      documentry = document.getElementById("cardChipType").style.backgroundColor = "";
    }

    if (selectedRow.CardBrandId == 3400) {
      var documentry2 = document.getElementById("cardBrandId").style.width = "80px";
      documentry2 = document.getElementById("cardBrandId").style.height = "37px";
      documentry2 = document.getElementById("cardBrandId").style.left = "235px";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
      documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "10px";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
    }
	 else if (selectedRow.CardBrandId == 3403) {
      var documentry2 = document.getElementById("cardBrandId").style.width = "48px";
      documentry2 = document.getElementById("cardBrandId").style.height = "48px";
      documentry2 = document.getElementById("cardBrandId").style.left = "265px";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
      documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "10px";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
    }
	 else if (selectedRow.CardBrandId == 3401) {
      var documentry2 = document.getElementById("cardBrandId").style.width = "80px";
      documentry2 = document.getElementById("cardBrandId").style.height = "55px";
      documentry2 = document.getElementById("cardBrandId").style.left = "235px";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
      documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "30px";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
    }
	 else {
      var documentry2 = document.getElementById("cardBrandId").style.width = "";
      documentry2 = document.getElementById("cardBrandId").style.height = "";
      documentry2 = document.getElementById("cardBrandId").style.left = "";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "";
      documentry2 = document.getElementById("cardBrandId").style.position = "";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "";
    }

    if (selectedRow.CardContactlessColorTypeId == 11102) {
      var documentry3 = document.getElementById("cardContactlessColorType").style.position = "absolute";
      documentry3 = document.getElementById("cardContactlessColorType").style.width = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.height = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.left = "275px";
      documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "100px";
      documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "black";
    }
	 else if (selectedRow.CardContactlessColorTypeId == 11104) {
      var documentry3 = document.getElementById("cardContactlessColorType").style.position = "absolute";
      documentry3 = document.getElementById("cardContactlessColorType").style.width = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.height = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.left = "275px";
      documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "100px";
      documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "white";
    }
	 else {
      var documentry3 = document.getElementById("cardContactlessColorType").style.position = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.width = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.height = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.left = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "";
    }

    if(selectedRow.BackgroundColorId == 11105 || selectedRow.BackgroundColorId == 11109 || selectedRow.BackgroundColorId == 11107){
      let cardName = document.getElementById("cardName").style.color = "black";
      let cardNumber = document.getElementById("cardNumber").style.color = "black";
      let cardDate = document.getElementById("cardDate").style.color = "black";
    }
	 else if(selectedRow.BackgroundColorId == 11106 || selectedRow.BackgroundColorId == 11110 || selectedRow.BackgroundColorId == 11108){
      let cardName = document.getElementById("cardName").style.color = "white";
      let cardNumber = document.getElementById("cardNumber").style.color = "white";
      let cardDate = document.getElementById("cardDate").style.color = "white";
    }

    model.StoredImage = result;
    this.setState({ fileInfoModel: selectedRow, model});
  }

  RowSelected = async (index) => {
    const selectedRow = this.state.data[index];
    const model = { ...this.state.model };

    this.setState({ fileInfoModel: selectedRow, model, rowIndex: index });
  }


  HandlePopupManualUpload = async () => {
    let model = { ...this.state.model };
    model.classes = this.props.classes;
    localStorage.setItem("CardDesign", JSON.stringify(model));
    this.popupUploadWindow = window.open("/#/out/CardDesign", "_blank", "toolbar=no,scrollbars=no,resizable=yes,top=100,left=100,width=1200,height=800");
    this.setState({ isOpenkycUploadWindow: true });
    var popupCloseAwaiter = new Promise((resolve) => {
      this.popupUploadWindow.onunload = () => {
        if (this.popupUploadWindow.location.href == "about:blank") {
          return;
        }
        var parsedId = Number.parseInt(localStorage.getItem("CardDesign"));
        if (this.popupUploadWindow?.closed && parsedId > 0) {
          resolve(parsedId);
        }
		  else {
          resolve(false);
        }
        localStorage.removeItem("CardDesign");
        this.popupUploadWindow = null;
        this.setState({ isOpenkycUploadWindow: false });
      };
    });

    var result = await popupCloseAwaiter;
    if (result) {
      this.props.showMessage("success", "Success", "Photos successfully updated!");
    }
    return result;

  }
  handleUpdate = async () => {
    this.setState({ isLoading: true });
    const { ExecuteApiPost } = this.props;
    var fileInfoModel = { ...this.state.fileInfoModel };

    switch (fileInfoModel.ChangeStatus) {
      case "Approved":
        fileInfoModel.ChangeStatus = "11111";
        break;
      case "Rejected":
        fileInfoModel.ChangeStatus = "11112";
        break;
      case "Unapproved":
        fileInfoModel.ChangeStatus = "11113";
        break;
      default:
        break;
    }

    var responseData = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/CardImageStatusUpdate", { Id: fileInfoModel.Id, ChangeStatus: fileInfoModel.ChangeStatus, UniqueClientId: fileInfoModel.UniqueClientId, Description: fileInfoModel.Description, CardImageCode: fileInfoModel.CardImageCode });

    if (responseData != null) {
      this.props.showMessage("success", "Image Status", "Image Status Updated");
      this.setState({
        isLoading: false, openDialog: false
      });
    }
	 else {
      this.props.showMessage("error", "Error", responseData.ErrorDescription);
      return;
    }
	 this.HandleSearch();
  };
  handleFileinfoChange = (name, newValue, data) => {
    const fileInfoModel = { ...this.state.fileInfoModel };
    fileInfoModel[name] = newValue;
    this.setState({ fileInfoModel });
  }
  HandleSearch = () => {
    const model = { ...this.state.model };
    this.setState({ isLoading: true });
    Proxy.POST(
      "/amlkycapi/v1.0/KycManualApplication/CardDesignToolSearch",
      model,
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          this.props.showMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ data: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  }
  HandleChangeActiveStatus = (model) => {
    this.setState({ openDialog: true, fileInfoModel: model });
  }
  DialogClose = () => {
    this.setState({ openDialog: false });
  }
  HandleClearCancel = () => {
    this.setState({ openDialog: false });
  }
  Clear = () => {
    this.setState({ model: {}, data: [], isLoading: false, rowIndex: -1 });
  }
  showDesign = () => {

    if (localStorage.getItem('CardImage1') === "null" || localStorage.getItem('CardImage2') === "null") {
      this.setState({ imgSrc: localStorage.getItem('CardImage') });
    } else {
      this.setState({ imgSrc: localStorage.getItem('CardImage1') + localStorage.getItem('CardImage2') });
    }
  }
  OpenAutodepositInsert = (customer) => {
    this.setState({ autoDepositInsertDialogOpen: true, selectedCustomer: customer });
	}


  render() {
    const { model, data, isLoading, openDialog, fileInfoModel } = this.state;
    const { Disabled, classes, ExecuteApiPost } = this.props;

    return (
      <div>
        <LoadingComponent Show={isLoading} />
        <div>
          <GenericDialog open={openDialog} maxWidth="lg" onBackdropClick={this.DialogClose} fullWidth>
            <GridContainer justify="space-between" spacing={16}>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <DialogTitle>
                      <GenericLabel Text="Card Image Status Information" Bold={true} />
                    </DialogTitle>
                    <DialogContent>
                      <GridContainer>
                        <GridItem xs={12}>
                          <GenericTextInput
                            Name="Status"
                            LabelMd={4}
                            LabelText="Current Status"
                            Value={fileInfoModel.Status}
                            IsText={true}
                            Disabled
                          />
                          <GenericSelectInput
                            Name="ChangeStatus"
                            LabelText="Status"
                            Method="POST"
                            Url="/coreapi/v1.0/Parameter/Search"
                            Parameter={this.ParameterStatus}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="ParameterDesc"
                            Value={fileInfoModel.ChangeStatus || ""}
                            ValueChanged={this.handleFileinfoChange}
                            Sorted={{ Member: "ParameterDesc" }}
                          />
                          <GenericTextInput
                            Name="Description"
                            LabelMd={4}
                            LabelText="Description"
                            Value={fileInfoModel.Description || ""}
                            IsText={true}
                            MultiLine={true}
                            RowCount={4}
                            ValueChanged={this.handleFileinfoChange}
                            inputProps={{ maxLength: 255 }}
                          />
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                        </GridItem>
                      </GridContainer>
                    </DialogContent>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GenericDialogActions>
              <Button size="sm" onClick={this.handleUpdate}>UPDATE</Button>
              <Button size="sm" onClick={this.HandleClearCancel}>CANCEL</Button>
            </GenericDialogActions>
          </GenericDialog>
        </div >
        <GridContainer>
          <GridItem xs={6}>
            <ButtonToolbar
              ButtonList={[
                { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
                { Code: "Clear", Disabled: Disabled, OnClick: this.Clear }
              ]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
          </GridItem>

          <Card className="no-radius">
            <CardBody>
              <GenericExpansionPanel Title="Main Panel">
                <GridContainer>
                  <GridItem xs={4}>
                    <GenericSelectInput
                      Name="UniqueClientId"
                      LabelText="Client"
                      Method="POST"
                      Url="/bankapi/v1.0/BankCustomer/Search"
                      Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
                      DataRoot="Item"
                      KeyValueMember="Id"
                      RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
                      Value={model.UniqueClientId || ""}
                      ValueChanged={this.HandleChange}
                      All
                      CanClear
                      Disabled={!this.isBackOffice}
                      DefaultIndex={ClientHelper.IsClient() ? 0 : -1} />
                  </GridItem>

                  <GridItem xs={2}>
                    <Button
                      size="sm"
                      color="black"
                      onClick={this.HandlePopupManualUpload}
                      disabled={model.UniqueClientId == null}
                    >
                      Manual Image Upload</Button>
                  </GridItem>
                </GridContainer>
              </GenericExpansionPanel>
            </CardBody>
          </Card>
          <Card className="no-radius">
            <GenericExpansionPanel Title={"Image List"}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer spacing={16}>
                      <GridItem xs={12}>
                        <GenericGrid
                          Data={data}
                          ref={this.trxTable}
                          PageSize={10}
                          ShowPagination={true}
                          Columns={[
                            {
                              Header: "Actions",
                              accessor: "Actions",
                              Cell: row => (
                                <div>
                                  <GridButton
                                    tooltip={"Image Status Change"}
                                    Icon={ApproveIcon}
                                    Disabled={!this.isBackOffice}
                                    OnClick={() => { this.HandleChangeActiveStatus(row.original); }} />
                                  <GridButton
                                    tooltip={"Show Image"}
                                    Icon={DetailIcon}
                                    // Disabled={!this.state.rowIndex}
                                    OnClick={() => { this.ShowImage(row.original); }} />
                                </div>
                              )
                            },
                            {
                              Header: "Client Name",
                              accessor: "ClientName"
                            },
                            {
                              Header: "Insert Date",
                              accessor: "InsertDateTime",
                              type: GridColumnType.DateTime,
                              ColumnType: GridColumnType.DateTime
                            },
                            {
                              Header: "Card Image Code",
                              accessor: "CardImageCode"
                            },
                            {
                              Header: "Emboss Font Color",
                              accessor: "BackgroundColor"
                            },
                            {
                              Header: "Card Brand",
                              accessor: "CardBrand"
                            },
                            {
                              Header: "Card Contactless Color",
                              accessor: "CardContactlessColorType"

                            },
                            {
                              Header: "Card Chip",
                              accessor: "CardChipType"

                            },
                            {
                              Header: "Status",
                              accessor: "Status"
                            },
                            {
                              Header: "Insert User",
                              accessor: "InsertUser"
                            },
                            {
                              Header: "Plastic Name",
                              accessor: "PlasticName"
                            }
                          ]}
                          RowSelected={this.RowSelected}
                          SelectedIndex={this.state.rowIndex}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </GenericExpansionPanel>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <GenericExpansionPanel IsActive={true} Title="Card Design" >
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={4}>
                            <GenericTextInput
                              Name="CardBrand"
                              LabelMd={4}
                              LabelText="Card Brand"
                              Value={fileInfoModel.CardBrand}
                              IsText={true}
                              Disabled
                            />
                            <GenericTextInput
                              Name="CardChipType"
                              LabelMd={4}
                              LabelText="Card Chip"
                              Value={fileInfoModel.CardChipType}
                              IsText={true}
                              Disabled
                            />
                            <GenericTextInput
                              Name="CardContactlessColorType"
                              LabelMd={4}
                              LabelText="Card Contactless Color"
                              Value={fileInfoModel.CardContactlessColorType}
                              IsText={true}
                              Disabled
                            />
                            <GenericTextInput
                              Name="CardImageCode"
                              LabelMd={4}
                              LabelText="Card Image Code"
                              Value={fileInfoModel.CardImageCode}
                              IsText={true}
                              Disabled
                            />
                            <GenericTextInput
                              Name="Status"
                              LabelMd={4}
                              LabelText="Status"
                              Value={fileInfoModel.Status}
                              IsText={true}
                              Disabled
                            />
                            <GenericTextInput
                              Name="BackgroundColor"
                              LabelMd={4}
                              LabelText="Emboss Font Color"
                              Value={fileInfoModel.BackgroundColor}
                              IsText={true}
                              Disabled
                            />
                            <GenericTextInput
                              Name="Description"
                              LabelText="Description"
                              Value={fileInfoModel.Description}
                              LabelMd={4}
                              IsText={true}
                              Disabled
                            />
									 <GenericTextInput
										Name="PlasticName"
										LabelText="Plastic Name"
										Value={fileInfoModel.PlasticName}
										ValueChanged={this.HandleChange}
										Disabled
									 />
                          </GridItem>
                          <GridItem xs={4}>
                            <div style={{ width: 323, height: 204 }}>
                              <GenericCardMedia
                                ExecuteApiPost={ExecuteApiPost}
                                className={classes.imageProfile}
                                ImageFileId={model.Id}
                                Image={model.StoredImage}
                                ImagePlaceholder={galerydefault}
                              />
                            </div>

                          </GridItem>
                          <GridItem xs={4}>
                            <div id="parent" style={{ position: "relative", width: 323, height: 204 }}>
                              <GenericCardMedia
                                ExecuteApiPost={ExecuteApiPost}
                                className={classes.imageProfile}
                                ImageFileId={model.Id}
                                Image={model.StoredImage}
                                ImagePlaceholder={galerydefault}
                              />
                              <div id="cardNumber"></div>
                              <div id="cardName"></div>
                              <div id="cardDate"></div>
                              <div id="cardChipType"></div>
                              <div id="cardBrandId"></div>
                              <div id="cardContactlessColorType"></div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </GenericExpansionPanel>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridContainer >
      </div >
    );
  }

}

CardDesignTool.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any
};

export default withStyles(kycManualPhotoDefinitionStyle)(CardDesignTool);