import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import  PropTypes  from "prop-types";
import React from "react";
import { GenericGrid, GenericLabel, GenericTextInput } from "views/Components/Generic";

class Include extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { classes, jsonModel, model, Disabled } = this.props;
		return (
			<div>

				<GridContainer>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleAdd}
							fullWidth
							disabled={!model || !model.Text}>
							Add
						</Button>
					</GridItem>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleDelete}
							fullWidth
							disabled={!model || !model.Text}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={3}>
						<GenericTextInput
							Name="Text"
							LabelText="Include"
							Value={model.Text}
							ValueChanged={this.props.handleChange} />
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						{jsonModel.Include &&
							<GenericLabel Bold={true} FontSize="12px" TextColor="black" Text="Incule List"></GenericLabel>
						}
						{jsonModel.Include &&
							<GenericGrid
								key={jsonModel.Include.length}
								Data={jsonModel.Include}
								Columns={[
									{
										Header: "Title",
										id: "Text",
										accessor: row => row
									}
								]}
								RowSelected={index => {
									var data = jsonModel.Include[index];
									this.props.handleChange("Text", data);
								}}
							/>
						}
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

Include.propTypes = {
	classes: PropTypes.object.isRequired,
	jsonModel: PropTypes.object,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleAdd: PropTypes.func,
	handleDelete: PropTypes.func
};

export default withArtifex(Include, {});
