import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericDateInput, GenericTitle, GenericCheckInput, GenericSelectInput } from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import moment from "moment";
import { ProgramCodes, BankingAccountTypes, ClientType, MenuCodes } from "views/Constants/Constant.js";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import CardHeader from "components/Card/CardHeader";
import Formatter from "core/Formatter";

class AccountActivityReport extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "SendDate",
				width: 200,

			},
			{
				Header: "Receiver Address",
				accessor: "PayeeEmail",

			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",

			}, {
				Header: "ACX SYS Ref Num",
				accessor: "TransferReferenceNumber",

			},
			{
				Header: "Amount",
				accessor: "Amount",
			}
		];
		this.columns1 = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				width: 200,

			}, {
				Header: "Received / Sent",
				accessor: "TransactionType",
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber",

			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",

			}, {
				Header: "ACX SYS Ref Num",
				accessor: "TransferReferenceNumber",

			},
			{
				Header: "Amount",
				accessor: "Amount",
			},
			{
				Header: "Customer/Contact Name",
				accessor: "CustomerContactName",
			},
			{
				Header: "SenderName",
				accessor: "SenderName",
			}
		];
		this.state = { model: {}, isLoading: false, checkedAccounts: [], ClientTypeParameter: {} };

	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("WALLET");
		}
		if (setAppLeftTitle && this.props.MenuCode === MenuCodes.Menu_MyAccountActivityReport) {
			setAppLeftTitle("My Wallet Activity Report");
		} else {
			if (setAppLeftTitle) setAppLeftTitle("e-Transfer Activity Report");

		}

		this.GetCustomerBankAccounts();
		this.GetClientType();
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;

		if (name == "CheckAllAccounts" && value == true) {
			const list = this.state.customerAccountList;
			this.setState({ checkedAccounts: list });
		} else if (name == "CheckAllAccounts" && value == false) {
			this.setState({ checkedAccounts: [] });
		}
		this.setState({ model });
	}
	HandleCheckCustomerAccounts = (e, x) => {

		const { checkedAccounts } = this.state;
		var temp = true;

		const items = checkedAccounts.map((item, key) => {
			if (item.Id == x.Id) {
				checkedAccounts.splice(key, 1);
				temp = false;
			}
		}
		);

		if (checkedAccounts.length == 0 && temp) { checkedAccounts.push(x); }
		else if (temp) { checkedAccounts.push(x); }

		this.setState({ checkedAccounts });

	}
	GetCustomerBankAccounts = () => {
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/Account/CustomerAccountsForBankingPortal",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
					return;
				} else {
					this.setState({ customerAccountList: responseData.Item || [] });
				}
				this.setState({ isLoading: false });

			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	RenderAccountTypeParameter = (data) => {
		const parameter = { ...this.state.ClientTypeParameter };
		if (data == null) {
			return "";
		}
		if (data.AccountCode == null || data.AccountCode == "") {
			return data.AccountType;
		}
		if (data.AccountCode != BankingAccountTypes.PrimaryAccount) {
			return data.AccountType;
		}
		if (data.ProgramCode == null || data.ProgramCode == "") {
			return data.AccountType;
		}
		if (data.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
			return data.AccountType;
		}

		if (parameter.ParameterValue == ClientType.Corporate || parameter.ParameterValue == ClientType.SalesAgentCorporate) {
			return "Corporate Wallet";
		} else if (parameter.ParameterValue == ClientType.Personal || parameter.ParameterValue == ClientType.SalesAgentPersonal) {
			return "Individual Wallet";
		} else {
			return data.AccountType;
		}

	}
	GetClientType = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetClientType",
			{},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ ClientTypeParameter: responseData.Item });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	Search = async () => {

		if (!this.Validate())
			return;
		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.model };
		var ids = [];
		this.state.checkedAccounts.forEach(element => {
			ids.push(element.Id);
			if (element.SubAccountList != null) {
				element.SubAccountList.forEach(x => {
					ids.push(x);
				});
			}
		});
		model.AccountIds = ids;

		if (this.props.MenuCode === MenuCodes.Menu_MyAccountActivityReport) {
			var result = await ExecuteApiPost("/bankapi/v1.0/Reporting/MyAccountTrxReportList", model, null, null, null);
			if (result != null) {
				this.setState({ list: result.MyAccountTrxResponseReportList || [] });
			}
		} else {
			var result1 = await ExecuteApiPost("/bankapi/v1.0/Reporting/AccountActivityReportList", model, null, null, null);
			if (result1 != null) {
				this.setState({ list: result1.AccountActivityResponseReportList || [] });
			}
		}

	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
	}

	Print = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		const temp = { ...this.state.model };

		var ids = [];
		this.state.checkedAccounts.forEach(element => {
			ids.push(element.Id);
			if (element.SubAccountList != null) {
				element.SubAccountList.forEach(x => {
					ids.push(x);
				});
			}
		});
		temp.AccountIds = ids;
		var tempurl = "";
		if (this.props.MenuCode === MenuCodes.Menu_MyAccountActivityReport) tempurl = "/bankapi/v1.0/Reporting/MyAccountTrxReportPdf";
		else tempurl = "/bankapi/v1.0/Reporting/AccountActivityReportPdf";

		Proxy.DownloadGeneratedFile(
			tempurl,
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}
	Validate = () => {
		var { model, checkedAccounts } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];


		if (!model.MonthlyFilter && typeof model.FromDate != "object" && model.FromDate != "" && !model.FromDate) {
			errors.push("From  Date is invalid");
		}

		if (!model.MonthlyFilter && typeof model.ToDate != "object" && model.ToDate != "" && !model.ToDate) {
			errors.push("To Date is invalid");
		}

		if (!model.MonthlyFilter && (typeof model.FromDate == "object" && typeof model.ToDate == "object") && model.ToDate < model.FromDate) {
			errors.push("From Date cannot be greater than To Date!");
		}

		if (!model.MonthlyFilter && (typeof model.FromDate == "object" && typeof model.ToDate == "object") && model.ToDate < model.FromDate) {
			errors.push("From Date cannot be bigger than To Date");
		}

		if (checkedAccounts.length == 0 || checkedAccounts.length > 1) {
			errors.push("There must be only one selected wallet.");
		}

		if (!model.MonthlyFilter && (typeof model.FromDate == "object" && typeof model.ToDate == "object")) {
			var interval = moment.duration(model.ToDate.diff(model.FromDate)).asDays();
			if (interval > 30) {
				errors.push("Between two dates cannot be longer than 30 days!");

			}
		}

		if (model.MonthlyFilter) {
			if (model.MonthlyFilterMonth == undefined || model.MonthlyFilterMonth == null) {
				errors.push("Month must be select!");
			}
			if (model.MonthlyFilterYear == undefined || model.MonthlyFilterYear == null) {
				errors.push("Year must be select!");
			}
			model["IsMonthlyFilterMonth"] = !model.MonthlyFilterMonth;
			model["IsMonthlyFilterYear"] = !model.MonthlyFilterMonth;
		} else {

			model["IsToDate"] = !model.ToDate;
			model["IsFromDate"] = !model.FromDate;
		}
		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}
	render() {
		const { model, list, checkedAccounts, customerAccountList } = this.state;
		const { Disabled, } = this.props;
		let IsMyAccountTrxReportList = this.props.MenuCode === MenuCodes.Menu_MyAccountActivityReport;

		var endDate = (model.FromDate instanceof moment) ? model.FromDate.clone() : "";
		var GridDataAccounts = customerAccountList == null ? [] : customerAccountList.map(d => {
			return {
				AP: (
					<div>
						<GenericCheckInput
							Grid
							Value={checkedAccounts.filter(c => c.Id == d.Id).length > 0}
							ValueChanged={(e) => {
								this.HandleCheckCustomerAccounts(e, d);
							}}
						/>
					</div>
				),
				Id: d.Id,
				ProgramId: d.ProgramId,
				CustomerBankAccountId: d.CustomerBankAccountId,
				AccountShortName: d.AccountShortName,
				BankAccountId: d.BankAccountId,
				UniqueClientId: d.UniqueClientId,
				Balance: d.Balance,
				AccountName: d.AccountName,
				PendingBalance: d.PendingBalance,
				AccountNumber: d.AccountNumber,
				AccountNumberStr: d.AccountNumberStr,
				BranchNumber: d.BranchNumber,
				BankNumber: d.BankNumber,
				AccountTypeId: d.AccountTypeId,
				AccountType: d.AccountType,
				AccountType4: d.AccountType4,
				AccountType6: d.AccountType6,
				AccountCode: d.AccountCode,
				ProgramCode: d.ProgramCode,
				ProgramName: d.ProgramName,
				CurrencyCode: d.CurrencyCode,
				CurrencyDesc: d.CurrencyDesc,
				CurrencySymbol: d.CurrencySymbol,
				AmountDisplay: d.AmountDisplay
			};
		});
		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{
							Code: "Print",
							OnClick: this.Print,
							Disabled: Disabled
						},

					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Wallet Activity Report">
									<GridContainer>
										<GridItem xs={2}>
											<GridItem style={{ opacity: model.MonthlyFilter || model.AnnualFilter ? 0.4 : 1 }}>
												<GenericCheckInput
													Name="DateFilter"
													LabelText="Date"
													Value={model.DateFilter}
													ValueChanged={this.ValueChanged}
													Disabled={model.MonthlyFilter || model.AnnualFilter} />
											</GridItem>
											<GridItem style={{ opacity: !model.DateFilter ? 0.4 : 1, marginTop: 4 }}>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													LabelMd={4}
													IsFuture={false}
													MaxDate={DateHelper.GetLocalDateAsUtc()}
													Value={model.FromDate == null ? "" : DateHelper.ToDateInputValue(model.FromDate) || undefined}
													ValueChanged={this.ValueChanged}
													Utc
													IsInvalid={model.IsFromDate}
													Required
													Disabled={!model.DateFilter}
												/>
											</GridItem>
											<GridItem style={{ opacity: !model.DateFilter ? 0.4 : 1 }}>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													LabelMd={4}
													IsFuture={false}
													MinDate={model.FromDate}
													MaxDate={endDate != "" ? endDate.add(30, "days") : undefined}
													Value={model.ToDate == null ? "" : DateHelper.ToDateInputValue(model.ToDate) || ""}
													ValueChanged={this.ValueChanged}
													Utc
													IsInvalid={model.IsToDate}
													Required
													Disabled={!model.DateFilter}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<GridItem
												style={{ opacity: model.DateFilter || model.AnnualFilter ? 0.4 : 1 }}>
												<GenericCheckInput
													Name="MonthlyFilter"
													LabelText="Monthly Report"
													Value={model.MonthlyFilter}
													ValueChanged={this.ValueChanged}
													Disabled={model.DateFilter || model.AnnualFilter} />
											</GridItem>
											<GridItem style={{ opacity: !model.MonthlyFilter ? 0.4 : 1 }}>
												<GenericSelectInput
													Name="MonthlyFilterMonth"
													LabelText="Month"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "Month"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={{
														Member: "ParameterValue2"
													}}
													Value={model.MonthlyFilterMonth}
													ValueChanged={this.ValueChanged}
													Disabled={!model.MonthlyFilter}
													IsInvalid={model.IsMonthlyFilterMonth}
													Required
												/>
											</GridItem>
											<GridItem style={{ opacity: !model.MonthlyFilter ? 0.4 : 1 }}>
												<GenericSelectInput
													Name="MonthlyFilterYear"
													LabelText="Year"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "Year"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Sorted={{
														Member: "ParameterDesc",
														Desc: true
													}}
													Value={model.MonthlyFilterYear}
													ValueChanged={this.ValueChanged}
													Disabled={!model.MonthlyFilter}
													IsInvalid={model.IsMonthlyFilterYear}
													Required
												/>
											</GridItem>
										</GridItem>


									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Wallets"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={GridDataAccounts}
											HideButton={true}
											Columns={[

												{
													Header: "Select",
													accessor: "AP",
													sortable: false,
													filterable: false,
													width: 60,
													Footer: (
														<GenericCheckInput Grid={true} Name="CheckAllAccounts" LabelText="" Value={model.CheckAllAccounts || false} ValueChanged={this.ValueChanged} />
													)
												},
												{
													Header: "Wallet Name",
													accessor: "AccountName",
													Footer: (
														<span>
															<strong>All Wallets</strong>{" "}
														</span>
													)
												},
												{
													Header: "Wallet Type",
													accessor: "AccountType",
													Cell: row => (
														<div>{this.RenderAccountTypeParameter(row.original)} </div>
													),
													Footer: (
														<span>
															<strong>Total{"    "} {customerAccountList == null ? 0 : customerAccountList.length}</strong>{" "}
														</span>
													)
												},
												{
													Header: "Program Name",
													accessor: "ProgramName"
												},
												{
													Header: "Wallet Number",
													accessor: "AccountNumber"
												},
												{
													Header: "Currency",
													accessor: "CurrencyCode"
												},
												{
													Header: "Balance",
													accessor: "Balance",
													Cell: row => (
														<div>{Formatter.FormatMoneySpecial(row.original.CurrencySymbol, row.value)}</div>
													)
												},
												{
													Header: "Pending Balance",
													accessor: "PendingBalance",
													Cell: row => (
														<div>{Formatter.FormatMoneySpecial(row.original.CurrencySymbol, row.value)}</div>
													)
												}
											]}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={IsMyAccountTrxReportList == true ? this.columns1 : this.columns}
													ShowPagination={true}
													PageSize={100}
													IsSorted={false}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

AccountActivityReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default AccountActivityReport;