import withStyles from "@material-ui/core/styles/withStyles";
import parameterComponentStyle from "assets/jss/material-dashboard-pro-react/views/parameterComponentStyle.jsx";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { GenericSelectInput } from "views/Components/Generic";

class ParameterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ParamObj: {
        ParameterCode: props.ParameterCode,
        ParameterValue: props.ParameterValue,
        ParameterValueIncludes: props.ParameterValueIncludes,
        ParameterValueExcludes: props.ParameterValueExcludes,
        ParameterValue2: props.ParameterValue2,
        ParameterValue2Includes: props.ParameterValue2Includes,
        ParameterValue2Excludes: props.ParameterValue2Excludes,
        ParameterValue3: props.ParameterValue3,
        ParameterValue3Includes: props.ParameterValue3Includes,
        ParameterValue3Excludes: props.ParameterValue3Excludes,
        ParameterValue4: props.ParameterValue4,
        ParameterValue4Includes: props.ParameterValue4Includes,
        ParameterValue4Excludes: props.ParameterValue4Excludes,
        ParameterValue5: props.ParameterValue5,
        ParameterValue5Includes: props.ParameterValue5Includes,
        ParameterValue5Excludes: props.ParameterValue5Excludes,
        ParameterValue6: props.ParameterValue6,
        ParameterValue6Includes: props.ParameterValue6Includes,
        ParameterValue6Excludes: props.ParameterValue6Excludes,
        ParameterDesc: props.ParameterDesc,
      },
    };

    this.AfterFetchCallback = this.AfterFetchCallback.bind(this);
  }

  /**
   * @param {any[]} data
   */
  AfterFetchCallback(data) {
    const { Name, DefaultParamValue, ValueChanged, KeyValueMember } =
      this.props;

    const { ParamObj } = this.state;
    const {
      ParameterValueIncludes,
      ParameterValueExcludes,
      ParameterValue2Includes,
      ParameterValue2Excludes,
      ParameterValue3Includes,
      ParameterValue3Excludes,
      ParameterValue4Includes,
      ParameterValue4Excludes,
      ParameterValue5Includes,
      ParameterValue5Excludes,
      ParameterValue6Includes,
      ParameterValue6Excludes,
    } = ParamObj;

    if (ParameterValueIncludes && ParameterValueIncludes.length > 0)
      _.remove(data, (x) =>
        ParameterValueIncludes.some((y) => y != x.ParameterValue)
      );
    if (ParameterValueExcludes && ParameterValueExcludes.length > 0)
      _.remove(data, (x) =>
        ParameterValueExcludes.some((y) => y == x.ParameterValue)
      );
    if (ParameterValue2Includes && ParameterValue2Includes.length > 0)
      _.remove(data, (x) =>
        ParameterValue2Includes.some((y) => y != x.ParameterValue2)
      );
    if (ParameterValue2Excludes && ParameterValue2Excludes.length > 0)
      _.remove(data, (x) =>
        ParameterValue2Excludes.some((y) => y == x.ParameterValue2)
      );
    if (ParameterValue3Includes && ParameterValue3Includes.length > 0)
      _.remove(data, (x) =>
        ParameterValue3Includes.some((y) => y != x.ParameterValue3)
      );
    if (ParameterValue3Excludes && ParameterValue3Excludes.length > 0)
      _.remove(data, (x) =>
        ParameterValue3Excludes.some((y) => y == x.ParameterValue3)
      );
    if (ParameterValue4Includes && ParameterValue4Includes.length > 0)
      _.remove(data, (x) =>
        ParameterValue4Includes.some((y) => y != x.ParameterValue4)
      );
    if (ParameterValue4Excludes && ParameterValue4Excludes.length > 0)
      _.remove(data, (x) =>
        ParameterValue4Excludes.some((y) => y == x.ParameterValue4)
      );
    if (ParameterValue5Includes && ParameterValue5Includes.length > 0)
      _.remove(data, (x) =>
        ParameterValue5Includes.some((y) => y != x.ParameterValue5)
      );
    if (ParameterValue5Excludes && ParameterValue5Excludes.length > 0)
      _.remove(data, (x) =>
        ParameterValue5Excludes.some((y) => y == x.ParameterValue5)
      );
    if (ParameterValue6Includes && ParameterValue6Includes.length > 0)
      _.remove(data, (x) =>
        ParameterValue6Includes.some((y) => y != x.ParameterValue6)
      );
    if (ParameterValue6Excludes && ParameterValue6Excludes.length > 0)
      _.remove(data, (x) =>
        ParameterValue6Excludes.some((y) => y == x.ParameterValue6)
      );

    if (DefaultParamValue) {
      var d = data.filter((x) => x.ParameterValue == DefaultParamValue);
      if (d && d.length > 0) ValueChanged(Name, d[0][KeyValueMember], d[0]);
    }
  }

  render() {
    const {
      classes,
      ParameterCode,
      ParameterValue,
      ParameterValue2,
      ParameterValue3,
      ParameterValue4,
      ParameterValue5,
      ParameterValue6,
      ParameterDesc,
      ...rest
    } = this.props;
    const { ParamObj } = this.state;

    return (
      <GenericSelectInput
        {...rest}
        Method="POST"
        Url="/coreapi/v1.0/Parameter/Search"
        DataRoot="Item"
        AfterFetchCallback={this.AfterFetchCallback}
        Parameter={ParamObj}
        CanClear={false}
      />
    );
  }
}

ParameterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  Name: PropTypes.string,
  LabelText: PropTypes.string,
  TitleLabel: PropTypes.string,
  Value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ValueChanged: PropTypes.func,
  Disabled: PropTypes.bool,
  ParameterCode: PropTypes.string,
  ParameterValue: PropTypes.string,
  ParameterValueIncludes: PropTypes.arrayOf(PropTypes.string),
  ParameterValueExcludes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue2: PropTypes.string,
  ParameterValue2Includes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue2Excludes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue3: PropTypes.string,
  ParameterValue3Includes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue3Excludes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue4: PropTypes.string,
  ParameterValue4Includes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue4Excludes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue5: PropTypes.string,
  ParameterValue5Includes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue5Excludes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue6: PropTypes.string,
  ParameterValue6Includes: PropTypes.arrayOf(PropTypes.string),
  ParameterValue6Excludes: PropTypes.arrayOf(PropTypes.string),
  ParameterDesc: PropTypes.string,
  DefaultParamValue: PropTypes.string,
  LabelMd: PropTypes.number,
  All: PropTypes.bool,
  Sorted: PropTypes.object,
  DefaultIndex: PropTypes.number,
  KeyValueMember: PropTypes.string,
  TextValueMember: PropTypes.string,
  Required: PropTypes.bool,
  IsInvalid: PropTypes.bool,
  RenderItem: PropTypes.func,
};

ParameterComponent.defaultProps = {
  KeyValueMember: "Id",
  TextValueMember: "ParameterDesc",
};

export default withStyles(parameterComponentStyle)(ParameterComponent);
