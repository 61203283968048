


const atmLocatorStyle = (theme) => {

	return ({

	
		toolbar: theme.mixins.toolbar,
		infoBox: {
			color: "rgb(122, 135, 142)",
			fontSize: 15.6,
			fontWeight: 600
		},
		infoBoxDescription: {
			fontSize: 11.7,
			fontWeight: 400,
			fontFamily: "Roboto,sans-serif",
			color: "rgb(122, 135, 142)"
		},
		infoBoxTitle: {
			textAlign: "left"
		},
		initialMsg: {
			textAlign: "center",
			width: "100%"
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing.unit * (3),
		},
		locationBoxWrapper: {
			height: "67vh",
			overflow: "auto"
		},
		searchBox: {
			margin: "5px 0px 5px 20px",
			display: "flex",
			[theme.breakpoints.down("sm")]: {

				margin: "5px 0px 5px 5px",
			}
		},
		distanceStyle: {
			color: "#f57f29",
		},
		search: {
			height: 32,
			padding: "9px 20px",
			marginTop: 6,
			borderRadius: 0,
			backgroundColor: "hsl(0, 0%, 44%)",
			marginLeft: -4
		},
		input: {
			width: "100%",
			"& > div > div > div > div > input": {
				height: 25
			}
		},
		locationBox: {
			height: 120,
			margin: "0px 0px 0px 22px",
			padding: 20,
			[theme.breakpoints.down("sm")]: {
				height: 140
			},
			"&:hover": {
				backgroundColor: "rgb(222, 222, 222)",
			},
			"&:nth-child(odd)": {
				backgroundColor: "rgb(233, 233, 233);"
			},
			"&:nth-child(even)": {
				backgroundColor: "rgb(255, 255, 255)"
			}
		},
		locationBoxSelected: {
			borderWidth: 2,
			borderColor: "#f57f29",
			borderStyle: "solid"
		},
	});
};

export default atmLocatorStyle;