import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class Scheduler extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			SchedulerId: 0,
			OrderId: 1,
			LastRunDate: null,
			LastRunStatus: 0,
			recurringHourList: []
		};

		this.state = {
			vModel: {},
			list: [],
			model: this.initialModel,
			isLoading: false,
			selected: null,
			Days: [
				{
					Id: 1,
					Text: "Sun",
					Status: false,
				},
				{
					Id: 2,
					Text: "Mon",
					Status: false,
				},
				{
					Id: 3,
					Text: "Tues",
					Status: false,
				},
				{
					Id: 4,
					Text: "Wed",
					Status: false,
				},
				{
					Id: 5,
					Text: "Thurs",
					Status: false,
				},
				{
					Id: 6,
					Text: "Fri",
					Status: false,
				},
				{
					Id: 7,
					Text: "Sat",
					Status: false,
				},
			]
		};
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Scheduler");
		this.props.setAppCenterTitle("System Admin");
	}

	Validate = () => {
		var ErrorList = [];
		const data = this.state.model;
		const vModel = this.state.vModel;
		//todo bad translates
		if (data == null) {
			ErrorList.push("You must select a Scheduler or create new one to add Scheduler .");
		}
		if (data != null && (data.ApplicationPath == undefined || data.ApplicationPath == "")) {
			vModel.ApplicationPath = true;
			ErrorList.push("Application Path must be defined !.");
		} else { vModel.ApplicationPath = false }
		if (data != null && (data.ApplicationName == undefined || data.ApplicationName == "")) {
			vModel.ApplicationName = true;
			ErrorList.push("Application Name must be defined !.");
		} else { vModel.ApplicationName = false; }
		if (data != null && (data.Description == undefined || data.Description == "")) {
			vModel.Description = true;
			ErrorList.push("Description must be defined !.");
		} else { vModel.Description = false; }
		if (data != null && (data.FullClassName == undefined || data.FullClassName == "")) {
			vModel.FullClassName = true;
			ErrorList.push("Full Class Name must be defined !.");
		} else { vModel.FullClassName = false }
		if (data != null && (data.MethodName == undefined || data.MethodName == "")) {
			vModel.MethodName = true;
			ErrorList.push("Method Name must be defined !.");
		} else { vModel.MethodName = false; }
		if (data != null && (data.RecurringWeekDays == undefined || data.RecurringWeekDays == "" || /[23456789]/.test(data.RecurringWeekDays) || data.RecurringWeekDays.toString().length != 7)) {
			vModel.RecurringWeekDays = true;
			ErrorList.push("Recurring Week Days must be defined !.");
		} else { vModel.RecurringWeekDays = false; }
		if (data != null && (data.SchedulerType < 0 || data.SchedulerType == undefined)) {
			vModel.SchedulerType = true;
			ErrorList.push("Scheduler Type must be defined !.");
		} else { vModel.SchedulerType = false; }
		if (data != null && (data.StartDateTime == undefined || data.StartDateTime == "")) {
			vModel.StartDateTime = true;
			ErrorList.push("Start Date must be defined !.");
		} else { vModel.StartDateTime = false; }
		if (data != null && (data.EndDateTime == undefined || data.EndDateTime == "")) {
			vModel.EndDateTime = true;
			ErrorList.push("End Date Time must be defined !.");
		} else { vModel.EndDateTime = false; }
		if (data != null && (data.RecurringType < 0 || data.RecurringType == undefined)) {
			vModel.RecurringType = true;
			ErrorList.push("Recurring Type must be defined !.");
		} else { vModel.RecurringType = false; }
		if (data != null && (data.RecurringEvery < 0 || data.RecurringEvery == undefined)) {
			vModel.RecurringEvery = true;
			ErrorList.push("Recurring Every must be defined !.");
		} else { vModel.RecurringEvery = false; }
		if (data != null && (data.RecurringLevel < 0 || data.RecurringLevel == undefined)) {
			vModel.RecurringLevel = true;
			ErrorList.push("Recurring Level must be defined !.");
		} else { vModel.RecurringLevel = false; }
		this.setState({ vModel })
		if (ErrorList.length > 0) {
			this.ShowMessage("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	handleChange = (name, value, data) => {
		const model = { ...this.state.model };
		var { recurringHourList } = this.state.model;
		model[name] = value;
		if (name == "RecurringEvery") {
			recurringHourList = [];
			for (let index = 0; index < data.Id; index++) {
				recurringHourList.push({
					Id: index,
					RecurringHour: null,
				});
			}
		}
		model.recurringHourList = recurringHourList;
		this.setState({ model });
	}

	valueChangedRecurringHour = (name, value, data, index) => {
		this.setState(state => {
			var { model } = state;
			var recurringHourList = model.recurringHourList;
			var item = recurringHourList[index];
			item[name] = value;
			return { model };
		});
	}

	handleChangeCheck = (name, value) => {
		let { Days } = this.state;
		Days.map(x => (
			x.Status = (x.Text == name ? !x.Status : x.Status)
		));
		this.setState({ Days });
	}

	handleSelectModel = (model, index) => {
		let { Days } = this.state;
		var i = 0;
		Days.map(x => {
			x.Status = model.RecurringWeekDays[i] == "1";
			i += 1;
			return null;
		}
		);
		this.setState({ Days, model, selected: index });
	}

	handleClear = () => {
		let { Days } = this.state;
		Days.map(x => (x.Status = false));
		this.setState({ model: this.initialModel, Days, vModel: {} });
	}

	handleGetList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Scheduler/GetAll",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
				else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	handleSubmit = () => {
		let { model, Days } = this.state;
		var recurringHourList = model.recurringHourList;
		var control = false;

		if (recurringHourList.length != null && recurringHourList.length > 0)
			recurringHourList.forEach(element => {
				if (element.RecurringHour == null) {
					control = true;
				}
			});

		if (control) {
			this.ShowMessage("info", "Null Control", "Recuring hour list element not be null !!!");
		}
		else {
			if (recurringHourList.length != null && recurringHourList.length > 0)
				recurringHourList.forEach(element => {
					if (recurringHourList.find(k => k.Id != element.Id && k.RecurringHour != null && k.RecurringHour.isSame(element.RecurringHour)) != null)
						control = true;
				});

			if (control) {
				this.ShowMessage("info", "Exist Time", "Recuring hour list two element not be same !!!");
			}
			else {
				let RecurringWeekDays = "";
				Days.map(x => (
					RecurringWeekDays += (x.Status == true ? "1" : "0")
				));
				model.RecurringWeekDays = RecurringWeekDays;
				this.setState({ model });
				if (!this.Validate())
					return;
				this.setState({ isLoading: true });
				Proxy.POST(
					"/coreapi/v1.0/Scheduler/InsertOrUpdate",
					model,
					(responseData) => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", "Error", responseData.ErrorDescription);
							return;
						}
						this.handleGetList();
						this.ShowMessage("success", "Success", "Operation is successfully!");
					},
					(error) => {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "An error occurred while requesting data", error.message);
					}
				);
			}
		}
	}

	handleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Scheduler/Delete",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.handleGetList();
				this.handleClear();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: AlertHelper.CreateAlert(title, message, type, () => this.setState({ alert: "" }))
		});
	}

	render() {
		const { Disabled } = this.props;
		const { Days, alert, model, list, isLoading, vModel } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Update", OnClick: this.handleSubmit, Disabled: Disabled || model === this.initialModel, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled || model === this.initialModel, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Delete", OnClick: this.handleDelete, Disabled: Disabled || model.Id == 0 }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Schedular"}>
								<CardBody>
									<GridContainer >
										<GridItem xs={6}>
											<GenericNumberInput
												Name="Id"
												LabelText="Id"
												LabelMd={4}
												Value={model.Id || ""}
												ValueChanged={this.handleChange}
												Disabled={true}
												NoFormatting={true} />
											<GenericTextInput
												Name="ApplicationPath"
												LabelText="Application Path"
												Value={model.ApplicationPath || ""}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.ApplicationPath}
											/>
											<GenericTextInput
												Name="ApplicationName"
												LabelText="Application Name"
												Value={model.ApplicationName || ""}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.ApplicationName}
											/>
											<GenericTextInput
												Name="Description"
												LabelText="Description"
												Value={model.Description || ""}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.Description}
											/>
											<GenericTextInput
												Name="ApplicationParameter"
												LabelText="Application Parameter"
												Value={model.ApplicationParameter || ""}
												ValueChanged={this.handleChange}
											/>
											<GenericTextInput
												Name="FullClassName"
												LabelText="Full Class Name"
												Value={model.FullClassName || ""}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.FullClassName}
											/>
											<GenericTextInput
												Name="MethodName"
												LabelText="Method Name"
												Value={model.MethodName || ""}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.MethodName}
											/>
											<GridContainer>
												<GridItem xs={4}>
													<GenericLabel MarginRight={105} FontSize="12px" TextColor="black" Text="Recurring Week Days"></GenericLabel>
												</GridItem>
												<GridItem xs={8}>
													{Days.map(x => {
														return (
															<GenericCheckInput
																key={x.Text}
																Name={x.Text.toString()}
																LabelText={x.Text}
																Value={x.Status}
																ValueChanged={(name, value) => this.handleChangeCheck(name, value)}
															/>
														);
													})}
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={6}>
											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 0, SchedulerType: "Exe" },
													{ Id: 1, SchedulerType: "Code" },
													{ Id: 2, SchedulerType: "Procedure" },
													{ Id: 3, SchedulerType: "DotNetCoreApp" }
												]}
												Name="SchedulerType"
												Value={model.SchedulerType}
												LabelText="Scheduler Type"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="SchedulerType"
												Required
												IsInvalid={vModel.SchedulerType}
											/>
											<GenericDateInput
												MaxDate={DateHelper.ToMoment(model.EndDateTime) || null}
												Name="StartDateTime"
												LabelText="Start Date"
												IncludeTime={true}
												Utc={true}
												ViewMode="time"
												Value={model.StartDateTime == null || undefined ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.StartDateTime}
											/>
											<GenericDateInput
												MinDate={DateHelper.ToMoment(model.StartDateTime) || null}
												Name="EndDateTime"
												LabelText="End Date"
												IncludeTime={true}
												Utc={true}
												ViewMode="time"
												Value={model.EndDateTime == null || undefined ? "" : DateHelper.ToDateInputValue(model.EndDateTime)}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.EndDateTime}
											/>
											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 0, RecurringType: "Once" },
													{ Id: 1, RecurringType: "Recurring" },
												]}
												Name="RecurringType"
												Value={model.RecurringType}
												LabelText="Recurring Type"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="RecurringType"
												Required
												IsInvalid={vModel.RecurringType}
											/>

											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 0, RecurringLevel: "Second" },
													{ Id: 1, RecurringLevel: "Minute" },
													{ Id: 2, RecurringLevel: "Daily" },
													{ Id: 3, RecurringLevel: "Weekly" },
													{ Id: 4, RecurringLevel: "Monthly" },
													{ Id: 5, RecurringLevel: "Constant" },
												]}
												Name="RecurringLevel"
												Value={model.RecurringLevel}
												LabelText="Recurring Level"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="RecurringLevel"
												Required
												IsInvalid={vModel.RecurringLevel}
											/>

											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 1, RecurringEvery: "One Times" },
													{ Id: 2, RecurringEvery: "Two Times" },
													{ Id: 3, RecurringEvery: "Three Times" },
													{ Id: 4, RecurringEvery: "Four Times" },
													{ Id: 5, RecurringEvery: "Five Times" },
													{ Id: 6, RecurringEvery: "Six Times" },
													{ Id: 7, RecurringEvery: "Seven Times" },
													{ Id: 8, RecurringEvery: "Eight Times" },
													{ Id: 9, RecurringEvery: "Nine Times" },
													{ Id: 10, RecurringEvery: "Ten Times" }
												]}
												Name="RecurringEvery"
												Value={model.RecurringEvery}
												LabelText="Recurring Every"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="RecurringEvery"
												Required
												IsInvalid={vModel.RecurringEvery}
											/>

											{model.recurringHourList && model.recurringHourList.map((item, index) => {
												return (
													<GenericDateInput
														key={index}
														LabelText={"Recurring Time " + (index + 1)}
														Name="RecurringHour"
														DateFormat={false}
														IncludeTime={true}
														Utc={true}
														ViewMode="time"
														Value={DateHelper.ToDateInputValue(item.RecurringHour)}
														ValueChanged={(name, value, data) => this.valueChangedRecurringHour(name, value, data, index)} />
												);
											})}

											<div style={{ opacity: model.Id > 0 ? "0.4" : "1" }}>
												<GenericDateInput
													Name="LastRunDate"
													LabelText="Last Run Date"
													Value={model.LastRunDate == null || undefined ? "" : Formatter.FormatDate(model.LastRunDate)}
													ValueChanged={this.handleChange}
													IncludeTime={false}
													Utc={true}
													Disabled={true} />
											</div>
											<div style={{ opacity: model.Id > 0 ? "0.4" : "1" }}>
												<GenericSelectInput
													LabelMd={4}
													IsStatic={true}
													Disabled={true}
													StaticData={[
														{ Id: 0, LastRunStatus: "Waiting" },
														{ Id: 1, LastRunStatus: "Success" },
														{ Id: 2, LastRunStatus: "Error" },
														{ Id: 3, LastRunStatus: "Retry Exceeded" },
														{ Id: 4, LastRunStatus: "In Progress" },
														{ Id: 5, LastRunStatus: "Not Supported" },
														{ Id: 6, LastRunStatus: "Finished With Exit Code1" },
													]}
													Name="LastRunStatus"
													Value={model.LastRunStatus}
													LabelText="Last Run Status"
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="LastRunStatus"
												/>
											</div>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Scheduler List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={[
												{
													Header: "Id",
													accessor: "Id"
												},
												{
													Header: "SchedulerId",
													accessor: "SchedulerId"
												},
												{
													Header: "Scheduler Type",
													accessor: "SchedulerType",
													Cell: row => (
														row.value == 0 ? "Exe" : row.value == 1 ? "Code" : row.value == 2 ? "Procedure" : row.value == 3 ? "DotNetCoreApp" : ""
													)
												},
												{
													Header: "Description",
													accessor: "Description"
												},
												{
													Header: "Application Path",
													accessor: "ApplicationPath"
												},
												{
													Header: "Application Name",
													accessor: "ApplicationName"
												},
												{
													Header: "Application Parameter",
													accessor: "ApplicationParameter"
												},
												{
													Header: "Full Class Name",
													accessor: "FullClassName"
												},
												{
													Header: "Method Name",
													accessor: "MethodName"
												},
												{
													Header: "Name",
													accessor: "Name"
												},
												{
													Header: "Start DateTime",
													accessor: "StartDateTime",
													type: GridColumnType.DateTime
												},
												{
													Header: "End DateTime",
													accessor: "EndDateTime",
													type: GridColumnType.DateTime
												},
												{
													Header: "Recurring Type",
													accessor: "RecurringType",
													Cell: row => (
														row.value == 0 ? "Once" : row.value == 1 ? "Recurring" : ""
													)
												},
												{
													Header: "Recurring Level",
													accessor: "RecurringLevel",
													Cell: row => (
														row.value == 0 ? "Second" : row.value == 1 ? "Minute" : row.value == 2 ? "Daily" : row.value == 3 ? "Weekly" : row.value == 4 ? "Monthly" : row.value == 5 ? "Constant" : ""
													)
												},
												{
													Header: "Recurring Every",
													accessor: "RecurringEvery"
												},
												{
													Header: "Recurring Week Days",
													accessor: "RecurringWeekDays",
												},
												{
													Header: "Last Run Date",
													accessor: "LastRunDate",
													type: GridColumnType.DateTime
												},
												{
													Header: "Last Run Status",
													accessor: "LastRunStatus",
													Cell: row => (
														row.value == 0 ? "Waiting" : row.value == 1 ? "Success" : row.value == 2 ? "Error" : row.value == 3 ? "Retry Exceeded" : row.value == 4 ? "In Progress" : row.value == 5 ? "Not Supported" : row.value == 6 ? "Finished With Exit Code1" : ""
													)
												}
											]}
											RowSelected={index => {
												this.handleSelectModel(list[index], index);
											}}
											SelectedIndex={this.state.rowIndex}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Scheduler.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(Scheduler, {});
