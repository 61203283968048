const ChequeManagementStyle = {	
	gridBorder: {
		borderLeft: "1px solid #808080",
		borderBottom: "1px solid #808080",
		borderTop: "1px solid #808080",
		borderRight: "1px solid #808080"
	},
	gridTextBold: {
		fontWeight:"bold"
	},
	gridTextCenter: {
		textAlign:"center"
	},
	containerHeight:{
		height:"50px",
		
	},
	pt :{
		paddingTop:"15px !important"
	}
};

export default ChequeManagementStyle;
