const MaxLength6 = {
	maxLength: 6
};

const MaxLength10 = {
	maxLength: 10
};

const MaxLength20 = {
	maxLength: 20
};

const MaxLength30 = {
	maxLength: 30
};

const MaxLength50 = {
	maxLength: 50
};

const MaxLength128 = {
	maxLength: 128
};

const MaxLength150 = {
	maxLength: 150
};

const MaxLength500 = {
	maxLength: 500
};

export {
	MaxLength6,
	MaxLength10,
	MaxLength20,
	MaxLength30,
	MaxLength50,
	MaxLength128,
	MaxLength150,
	MaxLength500
};
