import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";


const genericMultipleSelectInputStyle = {
	root: {
		border: "1px solid #e9eaec",
		"&:before": {
			border: "unset !important"
		},
		"&:after": {
			transition: "unset",
			height: 22,
			border: "1px solid gray !important",
		}
	},
	selectWidth: {
		"& > div > button": {
			width:"100%",
			padding: "0px 0px 0px 0px"
		}
	},
	selectFormControl: {
		display: 'block',
		marginBottom : "1px !important",
		margintop : "1px !important",
		paddingRight :"2px !important",
		"& > div": {
			"&:before": {
				borderBottomWidth: "1px !important",
				borderBottomColor: "#D2D2D2 !important"
			},
			marginTop: "4.2px",
			fontSize: "0.6rem",
			"&:after": {
				borderBottomColor: primaryColor + "!important"
			}
		}
	},
	selectLabel: {
		fontSize: "12px",
		position: "static",
		color: "#000 !important",
		marginRight: 5

	},
	selectLabelBold: {
		fontSize: "12px",
		position: "static",
		color: "#000 !important",
		marginRight: 5,
		fontWeight : "bold !important"

	},
	menuItem: {
		fontSize: "12px",
		paddingTop: "3px",
		paddingBottom: "3px",
	}
};

export default genericMultipleSelectInputStyle;
