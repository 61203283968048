import { Card, DialogContent, DialogTitle } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import {PinRetryIcon,CVVRetryIcon, EditIcon, VerificationIcon, PrintIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert,GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, MenuCodes } from '../../../Constants/Constant';

class CustomerCardInformation extends Component {
	constructor(props) {
		super(props);

		this.initialDialogModel = {
			IsDialogOpen: false,
			SelectedCard: null,
			OldCardStatus: "",
			IsDialogOpen2: false,
			SelectedCard2: null,
			OldCardStatus2: "",
			IsDialogOpen3: false,
			SelectedCard3: null,
			OldCardStatus3: "",
		};

		this.state = {
			dialogModel: this.initialDialogModel,
			gridButtonRightDetail: [],
			isLoading: false,
		};

		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength64 = { maxLength: 64 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.parameterCardPhysicalType = { ParameterCode: "CardPhysicalType" };

		this.parameterCardStatus = { Criteria: 1 };

		this.renderItemUniqueClient = {};

		this.renderItemCardStatus = {};

		this.renderItemOnusBin = {};

		this.parameterProduct = {};

		this.renderItemAccount = {};

		this.renderItemCardStatus = {};
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
		this.DetailButtonDisable = true;

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: (row) => (
				  <div>
					 {row.original != null &&
						row.original.CardId != null &&
						row.original.CardId != 0 && (
						  <GridButton
							 Disabled={props.Disabled || this.GetGridButtonDisabled("Modify Card Status")}
							 tooltip="Modify Card Status"
							 Icon={EditIcon}
							 OnClick={() => {
								this.handleShowDialog(row.original, row.index);
							 }}
						  />
						)}
					 {row.original != null &&
						row.original.CardId != null &&
						row.original.CardId != 0 && (
						  <GridButton
						  Disabled={props.Disabled || this.GetGridButtonDisabled("Modify Cvv Retry Count")}
							 tooltip="Modify Cvv Retry Count"
							 Icon={CVVRetryIcon}
							 OnClick={() => {
								 
								this.handleShowDialog2(row.original, row.index);
							 }
							}
						  />
						)}
					 {row.original != null &&
						row.original.CardId != null &&
						row.original.CardId != 0 && (
						  <GridButton
						  Disabled={props.Disabled || this.GetGridButtonDisabled("Modify Pin Retry Count")}
							 tooltip="Modify Pin Retry Count"
							 Icon={PinRetryIcon}
							 OnClick={() => {
								this.handleShowDialog3(row.original, row.index);
							 }}
						  />
						)}
				  </div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 120,
			 },
			{
				Header: "Card Id",
				accessor: "CardId"
			},
			{
				Header: "Card Last 4 Digit",
				accessor: "CardLast4Digit"
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "Cardholder Name",
				accessor: "CardEmbossName"
			},
			{
				Header: "Card Status",
				accessor: "CardStatus"
			},
			{
				Header: "Is Emboss Finished",
				accessor: "IsEmbossFinished",
				type: GridColumnType.YesNo
			},
			{
				Header: "Emboss Status",
				accessor: "EmbossStatus"
			},
			{
				Header: "Is Active",
				accessor: "IsActive",
				type: GridColumnType.YesNo
			}];
	}

	componentDidMount() {
		if (this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring)
			this.GetGridButtonsRight();
	}

	GetGridButtonsRight = async (action) => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALCMTCSU", "CALCMTUCC", "CALCMTUPC"]);

		this.setState({ gridButtonRightDetail });
	}

	GetGridButtonDisabled = (action) => {
		const { MenuCode } = this.props;
		if (MenuCode ===  MenuCodes.CallCenter_CardMonitoring && action === "Modify Card Status") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCMTCSU")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CardMonitoring && action === "Modify Cvv Retry Count") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCMTUCC")?.HasAuthority;
		}

		if (MenuCode === MenuCodes.CallCenter_CardMonitoring && action === "Modify Pin Retry Count") {
			return !this.state.gridButtonRightDetail.find(x => x.Code == "CALCMTUPC")?.HasAuthority;
		}
	}



	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.props.UniqueClientId;
		return this.parameterProduct;
	}

	RenderItemOnusBin = (d) => {
		this.renderItemOnusBin = `${d.Bin} - ${d.Description}`;
		return this.renderItemOnusBin;
	}

	RenderItemCardStatus = (d) => {
		this.renderItemCardStatus = `${d.Code} - ${d.Description}`;
		return this.renderItemCardStatus;
	}

	handleShowDialog = (data, index) => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen = true;
		dialogModel.SelectedCard = Object.assign({}, data);
		dialogModel.OldCardStatus = data.CardStatus;
		this.setState({ dialogModel });
	};

	handleCancelDialog = () => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen = false;
		dialogModel.IsDialogOpen2 = false;
		dialogModel.IsDialogOpen3 = false;
		dialogModel.SelectedCard = null;
		this.setState({ dialogModel });
	};

	handleSubmitDialog = () => {
		const dialogModel = { ...this.state.dialogModel };
		this.setState({ isLoading: true });

		if (dialogModel.SelectedCard == null) {
			dialogModel.IsDialogOpen = false;
			dialogModel.SelectedCard = null;
			return;
		}
		Proxy.POST(
			"/prepaidapi/v1.0/Card/UpdateStatus",
			{
				CardId: dialogModel.SelectedCard.CardId,
				CardStatusId: dialogModel.SelectedCard.Status,
			},
			(responseData) => {
				dialogModel.IsDialogOpen = false;
				dialogModel.SelectedCard = null;
				if (!responseData.IsSucceeded) {
					this.setState({ dialogModel, isLoading: false }, () => {
						this.props.showMessage("error", "ERROR", responseData.ErrorDescription);
					});
					return;
				}
				this.setState({ dialogModel, isLoading: false }, () => {
					this.props.HandleCardList(this.state.model);
					this.props.showMessage("success", "Updated successfully!");
				});
			},
			(error) => {
				this.setState({ isLoading: false }, () => {
					this.props.showMessage(
						"error",
						"An error occurred while requesting data",
						error.message
					);
				});
			}
		);
	};

	handleShowDialog2 = (data, index) => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen2 = true;
		dialogModel.SelectedCard = Object.assign({}, data);
		dialogModel.OldCardStatus = data.CardStatus;
		this.setState({ dialogModel });
	};

	handleSubmitDialog2 = () => {
		const dialogModel = { ...this.state.dialogModel };
		this.setState({ isLoading: true });

		if (dialogModel.SelectedCard == null) {
			dialogModel.IsDialogOpen = false;
			dialogModel.SelectedCard = null;
			return;
		}
		Proxy.POST(
			"/prepaidapi/v1.0/Card/UpdateCvvRetryCount",
			{
				CardId: dialogModel.SelectedCard.CardId,
				CardStatusId: dialogModel.SelectedCard.Status,
			},
			(responseData) => {
				dialogModel.IsDialogOpen = false;
				dialogModel.SelectedCard = null;
				if (!responseData.IsSucceeded) {
					this.setState({ dialogModel, isLoading: false }, () => {
						this.props.showMessage("error", "ERROR", responseData.ErrorDescription);
					});
					return;
				}
				this.setState({ dialogModel, isLoading: false }, () => {
					this.props.HandleCardList(this.state.model);
					this.props.showMessage("success", "Updated successfully!");
				});
			},
			(error) => {
				this.setState({ isLoading: false }, () => {
					this.props.showMessage(
						"error",
						"An error occurred while requesting data",
						error.message
					);
				});
			}
		);
	};

	handleShowDialog3 = (data, index) => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen3 = true;
		dialogModel.SelectedCard = Object.assign({}, data);
		dialogModel.OldCardStatus = data.CardStatus;
		this.setState({ dialogModel });
	};

	handleSubmitDialog3 = () => {
		const dialogModel = { ...this.state.dialogModel };
		this.setState({ isLoading: true });

		if (dialogModel.SelectedCard == null) {
			dialogModel.IsDialogOpen = false;
			dialogModel.SelectedCard = null;
			return;
		}
		Proxy.POST(
			"/prepaidapi/v1.0/Card/UpdatePinRetryCount",
			{
				CardId: dialogModel.SelectedCard.CardId,
				CardStatusId: dialogModel.SelectedCard.Status,
			},
			(responseData) => {
				dialogModel.IsDialogOpen = false;
				dialogModel.SelectedCard = null;
				if (!responseData.IsSucceeded) {
					this.setState({ dialogModel, isLoading: false }, () => {
						this.props.showMessage("error", "ERROR", responseData.ErrorDescription);
					});
					return;
				}
				this.setState({ dialogModel, isLoading: false }, () => {
					this.props.HandleCardList(this.state.model);
					this.props.showMessage("success", "Updated successfully!");
				});
			},
			(error) => {
				this.setState({ isLoading: false }, () => {
					this.props.showMessage(
						"error",
						"An error occurred while requesting data",
						error.message
					);
				});
			}
		);
	};

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const dialogModel = { ...this.state.dialogModel };
		if (name === "SelectedCardStatus") {
			dialogModel.SelectedCard.Status = newValue;
		} else {
			model[name] = newValue;
		}
		this.setState({ model, dialogModel });
	};
	render() {
		const { model, ReadOnly, Disabled, CardList } = this.props;
		const { dialogModel, isLoading } = { ...this.state };
		return (
			<>
				<LoadingComponent Show={isLoading} />
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={4}>
										<GenericTextInput
											Name="CustomerName"
											LabelText="Customer Name"
											inputProps={this.maxLength19}
											Value={model.CustomerName}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="CustomerNumber"
											LabelText="Customer Number"
											inputProps={this.maxLength19}
											Value={model.CustomerNumber}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="Email"
											LabelText="Email"
											inputProps={this.maxLength64}
											Value={model.Email}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="AddressLine1"
											LabelText="Address Line 1"
											inputProps={this.maxLength19}
											Value={model.AddressLine1}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="AddressLine2"
											LabelText="Address Line 2"
											inputProps={this.maxLength19}
											Value={model.AddressLine2}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
									<GridItem xs={4}>
										<GenericDateInput
											Name="BirthDate"
											LabelText="Birth Date"
											Value={model.BirthDate}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="Name"
											LabelText="Name"
											inputProps={this.maxLength19}
											Value={model.Name}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="MiddleName"
											LabelText="Middle Name"
											inputProps={this.maxLength19}
											Value={model.MiddleName}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="SurName"
											LabelText="SurName"
											inputProps={this.maxLength19}
											Value={model.SurName}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="ZipCode"
											LabelText="Zip Code"
											inputProps={this.maxLength19}
											Value={model.ZipCode}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="WalletAvailableBalance"
											LabelText="Wallet/Card Available Balance"
											inputProps={this.maxLength19}
											Value={model.WalletAvailableBalance}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="TotalCurrentTermCardTransaction"
											LabelText="Total Current Term Card Transaction"
											inputProps={this.maxLength19}
											Value={model.TotalCurrentTermCardTransaction}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="TotalPendingCardTrasaction"
											LabelText="Total Pending Card Transaction"
											inputProps={this.maxLength19}
											Value={model.TotalPendingCardTrasaction}
											Disabled={Disabled || ReadOnly}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem md={12} xs={12}>
								<br />
								<GridItem>
									<GenericTitle text={"Customer Card List"} />
								</GridItem>
								<br />
								<GenericGrid
									Data={CardList}
									Columns={this.columns}
									PageSize={10}
									ShowPagination={true}
									HideButton={true}
								/>
							</GridItem>
						</GridContainer>
						<GenericDialog open={dialogModel.IsDialogOpen} onBackdropClick={this.handleCancelDialog} maxWidth="sm" fullWidth keepMounted={true} >
							<DialogTitle>
								<GenericLabel FontSize="16px" Text="Card Status Update" Bold={true} />
							</DialogTitle>
							<DialogContent>
								<GridContainer style={{ "z-index": "2", "position": "fixed", "width": "378px", "padding": "5px" }}>
									<GridItem xs={12}>
										<GenericTextInput
											Name="CurrentlyCardStatus"
											LabelText="Currently Card Status"
											Value={dialogModel.OldCardStatus}
										/>
										<GenericSelectInput
											Name="SelectedCardStatus"
											LabelText="Card Status"
											Method="POST"
											Url="/prepaidapi/v1.0/CardStatus/GetAll"
											Parameter={{ Criteria: 0 }}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.Description}`}
											Value={dialogModel.SelectedCard == null ? null : dialogModel.SelectedCard.Status}
											ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
							</DialogContent>
							<br />	<br /><br /><br />
							<GenericDialogActions>
								<Button size="sm" onClick={this.handleSubmitDialog}>Submit</Button>
								<Button size="sm" onClick={this.handleCancelDialog}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>

						<GenericDialog open={dialogModel.IsDialogOpen2} onBackdropClick={this.handleCancelDialog} maxWidth="sm" fullWidth keepMounted={true} >
							<DialogTitle>
								<GenericLabel FontSize="16px" Text="Are you sure you want to update the card's CVV try count?" Bold={true} />
							</DialogTitle>
							<br />	<br />
							<GenericDialogActions>
								<Button size="sm" onClick={this.handleSubmitDialog2}>Submit</Button>
								<Button size="sm" onClick={this.handleCancelDialog}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>

						<GenericDialog open={dialogModel.IsDialogOpen3} onBackdropClick={this.handleCancelDialog} maxWidth="sm" fullWidth keepMounted={true} >
							<DialogTitle>
								<GenericLabel FontSize="16px" Text="Are you sure you want to update the card's PIN try count?" Bold={true} />
							</DialogTitle>
							<br />	<br />
							<GenericDialogActions>
								<Button size="sm" onClick={this.handleSubmitDialog3}>Submit</Button>
								<Button size="sm" onClick={this.handleCancelDialog}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>
					</CardBody >
				</Card >
			</>
		);
	}
}

CustomerCardInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CustomerCardInformation, {});