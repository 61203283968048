const imagine = require("assets/img/" + window.LoginPageBackground);

const pagesStyle = theme => ({
	wrapper: {
		height: "auto",
		minHeight: "100vh",
		minWidth: "auto",
		overflow: "auto",
		position: "relative",
		top: "0",
		backgroundImage: "url(" + imagine + ")",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "noRepeat"

	},
	fullPage: {
		padding: "120px 0",
		position: "relative",
		minHeight: "100vh",
		maxHeight: "1000px",
		minWidth: "620px",
		display: "flex!important",
		margin: "0",
		border: "0",
		alignItems: "center",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		height: "100%",
		[theme.breakpoints.down("sm")]: {
			minHeight: "fit-content!important",
		},
		"& footer": {
			position: "absolute",
			bottom: "0",
			width: "100%",
			border: "none !important"
		},
		"&:before": {
			backgroundColor: "rgba(0, 0, 0, 0.2)"
		},
		"&:before,&:after": {
			display: "block",
			content: "\"\"",
			position: "absolute",
			width: "100%",
			height: "100%",
			top: "0",
			left: "0",
			zIndex: "2"
		}
	}
});

export default pagesStyle;
