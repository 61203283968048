import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import StringHelper from "core/StringHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant.js";

class InterestFeeProfile extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : -1,
			IsDefaultProfile: ClientHelper.IsClient() ? false : true,
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};


		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : -1,
		};

		this.renderItemUniqueClient = {};
		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength128 = { maxLength: 128 };
		this.Columns = [
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Minimum Amount",
				accessor: "MinimumAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Interest Rate",
				accessor: "InterestRate",
				Cell: row => (<div>{Formatter.FormatPercentFee(row.value)}</div>)
			},

		];
	}

	componentDidMount() {
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.props.setAppLeftTitle("Interest Profile");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {

		this.setState({ isLoading: true });

		Proxy.POST(
			"/prepaidapi/v1.0/InterestProfile/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [] });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/InterestProfile/Insert",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Operation completed successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/InterestProfile/Update",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Operation completed successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.ShowConfirmMessage("warning", "Delete", "Are you sure to delete this record?", () => {
			this.setState({ isLoading: true, alert: "" });
			Proxy.POST("/prepaidapi/v1.0/InterestProfile/Delete",
				{ Id: this.state.model.Id },
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", responseData.ErrorDescription);
						return;
					}
					this.HandleClear();
					this.HandleSearch();
					this.ShowMessage("success", "Deleted successfully!");
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			);
		});
	}

	onlyLetterAndDigit = (str) => {
		return StringHelper.VerifiyDigitAndLetter(str);
	}

	Validate = () => {
		const { model } = this.state;
		var Messages = [];
		model.vProfileName = !model.ProfileName;
		if (model.vProfileName) Messages.push("Profile Name cannot be empty.");

		model.vInterestRate = model.InterestRate < 0 || model.InterestRate == null;
		if (model.vInterestRate) Messages.push("Interest Rate cannot be empty");

		model.vMinimumAmount = model.MinimumAmount < 0 || model.MinimumAmount == null;
		if (model.vMinimumAmount) Messages.push("Minimum Amount cannot be empty");

		model.vUniqueClientId = !model.UniqueClientId || model.UniqueClientId == null || model.UniqueClientId == -1;
		if (model.vUniqueClientId) Messages.push("Client Name cannot be empty");

		if (Messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", Messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id || Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: !model.Id || Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: !model.Id || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client Name"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={model.vUniqueClientId} />
										</GridItem>

										<GridItem xs={4}>
											<GenericTextInput
												Name="ProfileName"
												LabelText="Profile Name"
												Value={model.ProfileName}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={model.vProfileName}
												inputProps={this.maxLength40}
											/>
										</GridItem>

										<GridItem xs={4}>
											<GenericNumberInput
												MaxValue={100}
												Name="InterestRate"
												LabelText="Interest Rate"
												Suffix="%"
												MaxLength={4}
												Value={model.InterestRate}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={model.vInterestRate}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="MinimumAmount"
												LabelText="Minumum Amount"
												Value={model.MinimumAmount}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required
												IsInvalid={model.vMinimumAmount}
											/>
										</GridItem>
									</GridContainer>

								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Interest Fee Profile List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InterestFeeProfile.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(InterestFeeProfile, {});
