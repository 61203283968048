import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import HtmlEditor from "core/HtmlEditorHelper";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-autocomplete-input/dist/bundle.css";
import { renderToString } from "react-dom/server";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ClientHelper from "core/ClientHelper";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericColorInput,
  GenericDialog,
  GenericDialogActions,
  GenericExpansionPanel,
  GenericGrid,
  GenericLabel,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import ResultLetterTemplatePreview from "views/LOS/ApplicationResultLetter/ResultLetterTemplatePreview";

const url = "/losapi/v1.0/ApplicationResultLetter/";

class ApplicationResultLetterTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      model: { BackgroundColor: "#fff" },
      vModel: {},
      resultLetterTempList: [],
      isBrowse: false,
      isLoading: false,
      editorModelEmail: EditorState.createEmpty(),
      editorPropertyList: [],
      File: {},
      isTempPreviewDialogOpen: false,
      rowIndex: -1,
    };
    // preserve the initial state in a new object
    this.baseState = this.state;

    this.ParamLOSLoanType = {
      ParameterCode: "LOSLoanType",
    };
    this.ParamLOSLoanPurpose = {
      ParameterCode: "LOSLoanPurpose",
    };
    this.ParamLOSResultLetterType = {
      ParameterCode: "LOSResultLetterType",
    };
    this.fileInputRef1 = React.createRef();
    this.fileInputRef2 = React.createRef();
    this.sortedParameterDesc = { Member: "ParameterDesc" };
    this.LoanPurposeParameterCode = {
      ParameterCode: "LOSLoanPurpose",
    };
  }

  /**Begin Life cycle Methods */
  componentDidMount() {
    this.props.setAppLeftTitle("Application Result Letter Template Definition");
    this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
    this.setState({
      editorModelEmail: HtmlEditor.EmptyEditorContent(),
    });
    (this.state.editorPropertyList == null ||
      this.state.editorPropertyList.length == 0) &&
      this.GetDataModelPropertyList();
  }

  /**End life cycle Methods */

  /* Begin Standard  Methods  */

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  ShowConfirmMessage = (type, title, message, onConfirm) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={true}
          OnCancel={() => this.setState({ alert: null })}
          OnConfirm={onConfirm}
        />
      ),
    });
  };

  ShowMessageAndRefresh = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.hideAlertAndRefresh()}
        />
      ),
    });
  };

  hideAlertAndRefresh = () => {
    this.setState(function (state) {
      return this.baseState;
    });
    window.location.reload();
  };

  HideAlert = () => {
    this.setState({ alert: null });
  };

  IsStringNullOrEmptyOrWhiteSpace = (value) => {
    return (
      typeof value !== "string" || value == null || value.trim().length == 0
    );
  };

  /* End Standard  Methods  */

  /**  BEGIN Actions */
  OperationCheck = () => {
    var ErrorList = [];
    const data = this.state.model;
    const { vModel } = this.state;
    if (data == null) {
      ErrorList.push("Letter parameters not defined.");
      this.ShowMessageNode(
        "warning",
        "Please Fill Required Fields",
        ErrorList.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }
    if (data.LoanTypeId == null || data.LoanTypeId == 0) {
      vModel.LoanTypeId = true;
      ErrorList.push("Loan type must be selected.");
    } else {
      vModel.LoanTypeId = false;
    }
    if (data.LoanPurposeId == null || data.LoanPurposeId == 0) {
      vModel.LoanPurposeId = true;
      ErrorList.push("Purpose of the loan must be selected.");
    } else {
      vModel.LoanPurposeId = false;
    }
    if (
      data.LoanResultLetterTypeId == null ||
      data.LoanResultLetterTypeId == 0
    ) {
      vModel.LoanResultLetterTypeId = true;
      ErrorList.push("Letter for must be selected.");
    } else {
      vModel.LoanResultLetterTypeId = false;
    }
    if (data.Description == null || data.Description == "") {
      vModel.Description = true;
      ErrorList.push("Description must be entered.");
    } else {
      vModel.Description = true;
    }
    var contentText = HtmlEditor.EditorContentToHtml(
      this.state.editorModelEmail
    );
    if (this.IsStringNullOrEmptyOrWhiteSpace(contentText)) {
      ErrorList.push("Letter message must be defined.");
    }

    var letterContent = renderToString(this.GetHTMLLetterContent(false));
    if (letterContent == null) {
      ErrorList.push("Letter content must be defined.");
    }
    if (ErrorList.length > 0) {
      this.ShowMessageNode(
        "warning",
        "Please Fill Required Fields",
        ErrorList.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }
    this.state.model.ContentText = contentText;
    this.state.model.LoanResultLetterContent = letterContent;
    this.setState({ vModel });
    this.ActionSubmit();
  };

  ActionSubmit = () => {
    this.HideAlert();
    this.setState({ isLoading: true });
    const model = this.state.model;
    this.Post(model, "SaveLetterTemp");
  };

  ActionSearch = () => {
    //this.HideAlert();
    this.setState({ isLoading: true });
    const data = this.state.model;
    this.Post(data, "SearchLetterTemp");
  };

  ActionDelete = () => {
    var ErrorList = [];
    this.HideAlert();
    this.setState({ isLoading: true });
    const data = this.state.model;
    if (data == null || data.Id == null || data.Id == 0) {
      ErrorList.push("Any letter template not selected.");
    }

    if (ErrorList.length > 0) {
      this.ShowMessageNode(
        "warning",
        "Please Select A Record From Letter Templates List",
        ErrorList.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    this.Post(data, "DeleteLetterTemp");
  };

  ActionClear = () => {
    let model = {};
    model.BackgroundColor = "#FFFFFF";
    this.setState({
      model: model,
      resultLetterTempList: [],
      alert: null,
      isLoading: false,
      editorModelEmail: HtmlEditor.EmptyEditorContent(),
    });
    this.setState(function (state) {
      return this.baseState;
    });
    this.setState({ model, vModel: {} });
  };

  GetDataModelPropertyList = () => {
    this.HideAlert();
    this.Post(this.state.model, "GetDataModelPropertyList");
  };

  Post = (model, methodName) => {
    this.setState({ isLoading: true });
    Proxy.POST(
      url + methodName,
      model,
      (responseData) => {
        switch (methodName) {
          case "SearchLetterTemp":
            if (
              responseData != null &&
              responseData.Item != null &&
              responseData.Item.length > 0
            ) {
              this.setState({
                resultLetterTempList: responseData.Item,
                isLoading: false,
              });
            } else {
              this.setState({ alert: null, isLoading: false });
            }
            break;
          case "DeleteLetterTemp":
            this.ShowMessage("success", "Success", "Letter Template Deleted");
            this.setState({ isLoading: false });
            this.setState(function (state) {
              return this.baseState;
            });
            this.setState({ vModel: {} });
            this.ActionSearch();
            break;
          case "GetDataModelPropertyList":
            if (!responseData.IsSucceeded) {
              this.props.showMessage(
                "error",
                "Error",
                responseData.ErrorDescription
              );
              this.setState({ alert: null, isLoading: false });
              return;
            }
            if (responseData != null && responseData.Item != null) {
              var temp = responseData.Item.map((x) => {
                return { text: x.PropertyDescription, value: x.PropertyName };
              });
              this.setState({ editorPropertyList: temp });
            } else {
              this.setState({ editorPropertyList: [] });
            }
            this.setState({ alert: null, isLoading: false });
            break;
          default:
            if (responseData != null && responseData.Item != null) {
              this.ShowMessage("success", "Success", "Letter Template Saved");
              this.setState({
                model: {},
                vModel: {},
                resultLetterTempList: [],
                editorModelEmail: HtmlEditor.EmptyEditorContent(),
                isLoading: false,
              });
              this.ActionSearch();
            } else {
              this.setState(function (state) {
                return this.baseState;
              });
            }
            break;
        }
      },
      this.ErrorCallback
    );
  };

  ErrorCallback = (error) => {
    this.setState({ isLoading: false });
    this.ShowMessage("error", "Error", error);
  };
  /* END Actions */

  /** Begin Events */
  HandleChange = (name, newValue, data) => {
    const model = {
      ...this.state.model,
    };

    model[name] = newValue;
    (this.state.editorPropertyList == null ||
      this.state.editorPropertyList.length == 0) &&
      this.GetDataModelPropertyList();
    this.setState({ model });
  };

  RowSelected = (index) => {
    const list = this.state.resultLetterTempList;
    this.setState({
      model: list[index],
      editorModelEmail: HtmlEditor.HtmlToEditorContent(list[index].ContentText),
      rowIndex: index,
    });
  };

  /** End Events */

  /**Begin Editor Methods */
  EditorStateChangeEmail = (obj) => {
    this.setState({ editorModelEmail: obj });
    HtmlEditor.EditorContentToText(this.state.editorModelEmail);
  };

  /**End Editor Methods */
  /**FILE Methods */
  FileSelect = (e, name) => {
    e.preventDefault();

    var file = e.target.files[0];
    if (!file) {
      return;
    }

    var HandleChageTemp = this.HandleChange;
    var model = this.state.model;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result;
      HandleChageTemp(name.concat("Name"), file.name);
      HandleChageTemp(name, base64);
    };
    reader.onerror = function (error) {
      console.log("File Error: ", error);
    };
    this.setState({ model });
  };

  GetHTMLLetterContent = (isPreview = false) => {
    const model = this.state.model;
    return (
      <div id="main">
        <div
          id="main_child_1"
          style={{
            backgroundColor: model.BackgroundColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            id="main_child_2"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              width: "400px",
              height: "650px",
            }}
          >
            {model.LogoImage != null ? (
              <img
                id="img_child_1"
                style={{ width: "100px", height: "75px", float: "left" }}
                src={model.LogoImage}
                alt=""
              ></img>
            ) : (
              ""
            )}
            {model.HeaderImage != null ? (
              <img
                id="img_child_2"
                style={{ height: "225px", float: "left" }}
                src={model.HeaderImage}
                alt=""
              ></img>
            ) : (
              ""
            )}
            <div
              id="text_child_1"
              style={{
                height: "270px",
                backgroundColor: "white",
                float: "left",
              }}
            >
              <p id="p_child_1">
                {" "}
                {isPreview
                  ? HtmlEditor.EditorContentToText(this.state.editorModelEmail)
                  : HtmlEditor.EditorContentToHtml(this.state.editorModelEmail)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  GetFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {};
  };

  ImageUploadCallback = (file) =>
    new Promise((resolve, reject) =>
      this.GetFileBase64(file, (data) => resolve({ data: { link: data } }))
    );

  /**End File Methods */

  render() {
    const {
      model,
      alert,
      editorModelEmail,
      editorPropertyList,
      isLoading,
      isTempPreviewDialogOpen,
      vModel,
    } = this.state;
    const { classes } = this.props;

    var ForInsert = !model.Id || model.Id <= 0;
	 var IsClient = ClientHelper.IsClient();
	 
    return (
      <div>
        <GenericDialog
          open={isTempPreviewDialogOpen}
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>
            <GridContainer justify="space-evenly" alignItems="center">
              <GridItem>
                <GenericLabel
                  FontSize="16px"
                  TextColor="black"
                  Text="Result Letter Template Preview"
                  Bold={true}
                />
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent>
            <ResultLetterTemplatePreview
              content={this.GetHTMLLetterContent(true)}
            />
          </DialogContent>
          <GenericDialogActions>
            <Button
              size="sm"
              onClick={() => this.setState({ isTempPreviewDialogOpen: false })}
            >
              CLOSE
            </Button>
          </GenericDialogActions>
        </GenericDialog>

        <LoadingComponent Show={isLoading} />

        <GridItem xs={12}>
          <ButtonToolbar
            ButtonList={[
              {
                Code: "Submit",
                OnClick: this.OperationCheck,
                Disabled: !ForInsert,
              },
              {
                Code: "Search",
                OnClick: this.ActionSearch,
                Disabled: false,
              },
              {
                Code: "Update",
                OnClick: this.OperationCheck,
                Disabled: ForInsert,
              },
              {
                Code: "Delete",
                OnClick: this.ActionDelete,
                Disabled: ForInsert,
              },
              {
                Code: "Clear",
                OnClick: this.ActionClear,
                Disabled: false,
              },
            ]}
            menuId={this.props.menuId}
            ApprovalData={this.props.ApprovalData}
            IsPopUp={false}
          />
        </GridItem>
        <GridContainer>
          {alert}
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel
                  IsActive={true}
                  Title="Letter Main Parameters"
                >
                  <GridContainer
                    justify="flex-start"
                    alignItems="flex-start"
                    direction="row"
                  >
                    <GridContainer>
                      <GridItem xs={3}>
                        <GenericSelectInput
                          Name="UniqueClientId"
                          LabelText="Client"
                          Method="POST"
                          Url="/bankapi/v1.0/BankCustomer/Search"
                          Parameter={{}}
                          DataRoot="Item"
                          KeyValueMember="Id"
                          RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                          Value={model.UniqueClientId || ""}
                          ValueChanged={this.HandleChange}
                          CanClear
                          All
                          Required
                          Disabled={IsClient}
                          DefaultIndex={IsClient ? 0 : -1}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <ParameterComponent
                          Name="LoanTypeId"
                          LabelText="Loan Type"
                          LabelMd={3}
                          ParameterCode={this.ParamLOSLoanType.ParameterCode}
                          TextValueMember="ParameterDesc"
                          Value={model.LoanTypeId}
                          Required
                          IsInvalid={vModel.LoanTypeId}
                          ValueChanged={this.HandleChange}
                          Disabled={false}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <GenericSelectInput
                          Name="LoanPurposeId"
                          LabelText="Purpose Of Loan"
                          LabelMd={5}
                          key={model.LoanTypeId + "LoanPurpose"}
                          Method="POST"
                          Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
                          Parameter={{ LoanTypeId: model.LoanTypeId }}
                          DataRoot="Item"
                          KeyValueMember="Id"
                          TextValueMember="ParameterDesc"
                          Sorted={this.sortedParameterDesc}
                          Value={model.LoanPurposeId}
                          Required
                          IsInvalid={vModel.LoanPurposeId}
                          ValueChanged={this.HandleChange}
                          Disabled={false}
                        />
                      </GridItem>
                      <GridItem xs={2}>
                        <ParameterComponent
                          Name="LoanResultLetterTypeId"
                          LabelText="Letter For"
                          LabelMd={4}
                          ParameterCode={
                            this.ParamLOSResultLetterType.ParameterCode
                          }
                          TextValueMember="ParameterDesc"
                          Value={model.LoanResultLetterTypeId}
                          Required
                          IsInvalid={vModel.LoanResultLetterTypeId}
                          ValueChanged={this.HandleChange}
                          Disabled={false}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <GenericTextInput
                          Disabled={false}
                          IsText={true}
                          Name="Description"
                          LabelText="Description"
                          Value={model.Description || ""}
                          Required
                          IsInvalid={vModel.Description}
                          ValueChanged={this.HandleChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Letter Details">
                  <GridContainer
                    justify="center"
                    alignItems="flex-start"
                    direction="column"
                  >
                    <GridContainer>
                      <GridItem xs={3}>
                        <GenericColorInput
                          Disabled={false}
                          Name="BackgroundColor"
                          LabelText="Background Color"
                          Value={model.BackgroundColor}
                          ValueChanged={this.HandleChange}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer
                      alignItems="center"
                      justify="flex-start"
                      direction="column"
                    >
                      <GridItem xs={6}>
                        <Card className="no-radius">
                          <CardHeader>
                            <GenericTitle text={"Content"} />
                            <GridItem
                              xs={5}
                              style={{
                                float: "right",
                                textAlign: "end",
                              }}
                            >
                              <Button
                                disabled={false}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    isTempPreviewDialogOpen: true,
                                  });
                                }}
                              >
                                PREVIEW
                              </Button>
                            </GridItem>
                            <GridItem
                              xs={5}
                              style={{
                                float: "right",
                                textAlign: "end",
                              }}
                            >
                              <Button
                                disabled={false}
                                size="sm"
                                onClick={() => {
                                  model.LogoImage = null;
                                  model.HeaderImage = null;
                                  model.BackgroundColor = "#fff";
                                  this.setState({
                                    model: model,
                                    editorModelEmail:
                                      HtmlEditor.EmptyEditorContent(),
                                  });
                                }}
                              >
                                CLEAR
                              </Button>
                            </GridItem>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <GridItem
                                xs={3}
                                style={{
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                {model.LogoImage == null ? (
                                  <div>
                                    <Button
                                      disabled={false}
                                      size="sm"
                                      onClick={() =>
                                        this.fileInputRef1.current.click()
                                      }
                                      style={{ marginTop: 8 }}
                                    >
                                      LOGO
                                    </Button>
                                    <input
                                      type="file"
                                      key={model.LogoImage}
                                      style={{ display: "none" }}
                                      onChange={(e, name) =>
                                        this.FileSelect(e, "LogoImage")
                                      }
                                      ref={this.fileInputRef1}
                                      accept="image/*"
                                    />
                                  </div>
                                ) : (
                                  <Button
                                    disabled={false}
                                    size="sm"
                                    onClick={() => {
                                      model.LogoImage = null;
                                      this.setState({
                                        model: model,
                                      });
                                    }}
                                    style={{ marginTop: 8 }}
                                  >
                                    DELETE LOGO
                                  </Button>
                                )}
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={3}>
                                {model.LogoImage && (
                                  <img
                                    src={model.LogoImage}
                                    className={classes.pictureSrc}
                                    alt={"LOGO"}
                                  />
                                )}
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem
                                xs={3}
                                style={{
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                {model.HeaderImage == null ? (
                                  <div>
                                    <Button
                                      disabled={false}
                                      size="sm"
                                      onClick={() =>
                                        this.fileInputRef2.current.click()
                                      }
                                      style={{ marginTop: 8 }}
                                    >
                                      IMAGE
                                    </Button>
                                    <input
                                      type="file"
                                      key={model.HeaderImage}
                                      style={{
                                        display: "none",
                                      }}
                                      onChange={(e, name) =>
                                        this.FileSelect(e, "HeaderImage")
                                      }
                                      ref={this.fileInputRef2}
                                      accept="image/*"
                                    />
                                  </div>
                                ) : (
                                  <Button
                                    disabled={false}
                                    size="sm"
                                    onClick={() => {
                                      model.HeaderImage = null;
                                      this.setState({
                                        model: model,
                                      });
                                    }}
                                    style={{ marginTop: 8 }}
                                  >
                                    DELETE IMAGE
                                  </Button>
                                )}
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={3}>
                                {model.HeaderImage && (
                                  <img
                                    src={model.HeaderImage}
                                    className={classes.pictureSrc}
                                    alt="Header"
                                  />
                                )}
                              </GridItem>
                            </GridContainer>
                            <br />
                            <GridContainer>
                              <GridItem xs={10}>
                                <Editor
                                  editorState={editorModelEmail}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={
                                    this.EditorStateChangeEmail
                                  }
                                  placeholder={"Write your message here..."}
                                  toolbar={{
                                    image: {
                                      urlEnabled: false,
                                      uploadEnabled: true,
                                      uploadCallback:
                                        this.ImageUploadCallback.bind(this),
                                      previewImage: true,
                                    },
                                  }}
                                  mention={{
                                    separator: " ",
                                    suggestions: editorPropertyList,
                                    trigger: "@",
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Letter Templates">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenericGrid
                        Data={this.state.resultLetterTempList}
                        Columns={[
                          {
                            Header: "Loan Type",
                            accessor: "LoanType.ParameterDesc",
                          },
                          {
                            Header: "Purpose Of The Loan",
                            accessor: "LoanPurpose.ParameterDesc",
                          },
                          {
                            Header: "Letter For",
                            accessor: "LoanResultLetterType.ParameterDesc",
                          },
                          {
                            Header: "Description",
                            accessor: "Description",
                          },
                        ]}
                        RowSelected={this.RowSelected}
                        SelectedIndex={this.state.rowIndex}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ApplicationResultLetterTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  Disabled: PropTypes.bool,
  ExecuteApiPost: PropTypes.func,
};

export default withArtifex(ApplicationResultLetterTemplate, {});
