import { chartColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import { DocumentIcon, ETransferIcon, ProfileIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
	GenericDateInput, GenericExpansionPanel,
	GenericGrid, GenericLabel, GenericSelectInput
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import LoanCircleChart from "views/LMS/LoanChart/LoanCircleChart";
// import ApplicantDefinition from "views/LOS/ApplicantDefinition/ApplicantDefinition";
// import LedgerCard from "views/LOS/LedgerCard/LedgerCard.jsx";
// import Statement from "views/LOS/Statement/Statement";

const PaymentManagementUrl = "/lmsapi/v1.0/PaymentManagement/";
const defaultChartDataSet = [
	{
		data: [100],
		backgroundColor: "rgb(255, 159, 64)",
		hoverBackgroundColor: "rgb(255, 159, 64)"
	}
];
const initialChartContract =
	[
		{
			name: "current",
			labels: ["Current"],
			datasets: defaultChartDataSet,
			text: ""
		},
		{
			name: "thirtyDays",
			labels: ["30 Days", "Tran Amount"],
			datasets: defaultChartDataSet,
			text: ""
		},
		{
			name: "sixtyDays",
			labels: ["60 Days", "Tran Amount"],
			datasets: defaultChartDataSet,
			text: ""
		},
		{
			name: "ninetyDays",
			labels: ["90 Days", "Tran Amount"],
			datasets: defaultChartDataSet,
			text: ""
		},
		{
			name: "ninetyDaysPlus",
			labels: ["+ 90 Days", "Tran Amount"],
			datasets: defaultChartDataSet,
			text: ""
		},
		{
			name: "totalInterestFee",
			labels: ["Total Interest & Fees", "Tran Amount"],
			datasets: defaultChartDataSet,
			text: ""
		}
	];

const initialState = {
	ReadOnly: true,
	isLoading: false,
	paymentTransactionList: [],
	chartList: initialChartContract,
	model: {}

};

class PaymentManagement extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, initialState);
		//#region  Grid columns
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<Button
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							disabled={this.props.Disabled}
							onClick={() => {
							}}>
							<img src={ProfileIcon} alt="" />
						</Button>
						<Button
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							disabled={this.props.Disabled}
							onClick={() => {
								this.SelectedRow(row.original);
							}}>
							<img src={DocumentIcon} alt="" />
						</Button>
						<Button
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							disabled={this.props.Disabled}
							onClick={() => {
								this.SelectedRow(row.original);
							}}>
							<img src={ETransferIcon} alt="" />
						</Button>
					</div>
				),
				sortable: false,
				filterable: false
			},
			{
				Header: "Date",
				accessor: "Application.InsertDateTime",
				sortable: false,
				filterable: false,
				type: GridColumnType.Date
			},
			{
				Header: "Partner",
				accessor: "Application.ClientPartner.LegalNameOfBusiness",
				sortable: false,
				filterable: false
			},
			{
				Header: "Program",
				accessor: "Application.Applicant.FullName",
				sortable: false,
				filterable: false
			},
			{
				Header: "DDT Number",
				accessor: "Application.ApplicationNumber",
				sortable: false,
				filterable: false
			},
			{
				Header: "Application Owner Name",
				accessor: "Application.LoanType.ParameterDesc",
				sortable: false,
				filterable: false
			},
			{
				Header: "Total Amount",
				accessor: "Application.Applicant.WalletAccount.AccountNumber",
				sortable: false,
				filterable: false
			},
			{
				Header: "Total No. of Payment",
				accessor: "MaskedCardNumber",
				sortable: false,
				filterable: false
			},
			{
				Header: "Remaining Total Amount",
				accessor: "Application.ApprovedAmount",
				sortable: false,
				filterable: false,
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Remaining No. of Payment",
				accessor: "MaskedCardNumber",
				sortable: false,
				filterable: false
			},
			{
				Header: "Installment Amount",
				accessor: "TotalLoan",
				sortable: false,
				filterable: false,
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Payment Channel",
				accessor: "MaskedCardNumber",
				sortable: false,
				filterable: false
			},
			{
				Header: "Payment Status",
				accessor: "Application.ApprovedPaymentFrequency.ParameterDesc",
				sortable: false,
				filterable: false
			},
			{
				Header: "No. of Days Delayed",
				accessor: "Application.ApprovedPaymentFrequency.ParameterDesc",
				sortable: false,
				filterable: false
			},
			{
				Header: "Amount of Late Payment",
				accessor: "ApplicationPaymentDetail.PrincipalAmount",
				sortable: false,
				filterable: false,
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Penalty Fee",
				accessor: "Outstanding",
				sortable: false,
				filterable: false,
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
		//#endregion	

		/** ParamCodes */
		this.LMSPaymentStatus = {
			ParameterCode: "LMSPaymentStatus"
		};
		this.SetPaymentChartData = this.SetPaymentChartData.bind(this);

	}
	/** Begin Lifecycle Methods */
	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Payment Management");
		this
			.props
			.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}

	/** End Lifecycle Methods */

	/** Begin Methods */
	formatNumber = (num) => {
		return " $" + num
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	SetPaymentChartData(paymentChartData) {
		const chartList = this.state.chartList;
		if (paymentChartData !== null) {
			chartList.map(item => {
				switch (item.name) {
					case "current":
						{
							var datasets = [
								{
									data: this.GetChartDataSource(paymentChartData.CurrentChartData),
									backgroundColor: this.GetChartColor(),
									hoverBackgroundColor: this.GetChartColor()
								}
							];
							item["datasets"] = datasets;
							item["text"] = this.GetChartText(paymentChartData.CurrentChartData);
							break;
						}
					case "thirtyDays":
						{
							datasets = [
								{
									data: this.GetChartDataSource(paymentChartData.ThirtyDaysChartData),
									backgroundColor: this.GetChartColor(),
									hoverBackgroundColor: this.GetChartColor()
								}
							];
							item["datasets"] = datasets;
							item["text"] = this.GetChartText(paymentChartData.ThirtyDaysChartData);
							break;
						}
					case "sixtyDays":
						{
							datasets = [
								{
									data: this.GetChartDataSource(paymentChartData.SixtyDaysChartData),
									backgroundColor: this.GetChartColor(),
									hoverBackgroundColor: this.GetChartColor()
								}
							];
							item["datasets"] = datasets;
							item["text"] = this.GetChartText(paymentChartData.SixtyDaysChartData);
							break;
						}
					case "ninetyDays":
						{
							datasets = [
								{
									data: this.GetChartDataSource(paymentChartData.NinetyDaysChartData),
									backgroundColor: this.GetChartColor(),
									hoverBackgroundColor: this.GetChartColor()
								}
							];
							item["datasets"] = datasets;
							item["text"] = this.GetChartText(paymentChartData.NinetyDaysChartData);
							break;
						}
					case "ninetyDaysPlus":
						{
							datasets = [
								{
									data: this.GetChartDataSource(paymentChartData.NinetyDaysPlusChartData),
									backgroundColor: this.GetChartColor(),
									hoverBackgroundColor: this.GetChartColor()
								}
							];
							item["datasets"] = datasets;
							item["text"] = this.GetChartText(paymentChartData.NinetyDaysPlusChartData);
							break;
						}
					case "totalInterestFee":
						{
							datasets = [
								{
									data: this.GetChartDataSource(paymentChartData.TotalInterestFeeChartData),
									backgroundColor: this.GetChartColor(),
									hoverBackgroundColor: this.GetChartColor()
								}
							];
							item["datasets"] = datasets;
							item["text"] = this.GetChartText(paymentChartData.TotalInterestFeeChartData);
							break;
						}

					default:
						{
							item["datasets"] = defaultChartDataSet;
							item["text"] = "";
							break;
						}
				}

				return null;
			}
			);
		}
		this.setState({ chartList });

	}
	GetChartColor = () => {
		var returnColor = "rgb(255, 159, 64)";
		var colorNames = Object.keys(chartColors);
		var colorIndex = 0;
		returnColor = chartColors[colorNames[colorIndex]] || returnColor;
		return returnColor;

	}
	GetChartDataSource = (chartDataItem) => {
		var dataSource = [100];
		dataSource.push(chartDataItem != null
			? chartDataItem.RequestAmount
			: 0);
		dataSource.push(chartDataItem != null
			? chartDataItem.RequestAmountCount
			: 0);
		return dataSource;
	}
	GetChartText = (chartDataItem) => {
		var chartText = "0";
		if (chartDataItem !== null) {
			chartText = chartDataItem.RequestAmountCount + "   " + this.formatNumber(chartDataItem.RequestAmount.toFixed());
		}
		return chartText;
	}
	/** End Methods */
	/** Begin Actions */
	/** CLEAR */
	handleClear = () => {
		const chartList = initialChartContract;
		this.setState(Object.assign({}, initialState));
		this.setState({ chartList });

	}
	/** CLEAR */

	/** SEARCH */
	HandleSearch = async () => {
		const { ExecuteApiPost } = this.props;
		const { model } = this.state.model;
		var result = await ExecuteApiPost(`${PaymentManagementUrl}Search`, model);
		if (result != null) {
			this.setState({ paymentTransactionList: result.PaymentTransactionDataList || [] });
			this.SetPaymentChartData(result);
		}

	}

	/** SEARCH */
	/** End actions */

	/** Begin Events */
	SelectedRow = (row) => {

	}
	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};
		model[name] = newValue;
		this.setState({ model });
	}
	/** End Events */

	/** Begin Render */

	render() {
		const { Disabled } = this.props;
		const {
			alert,
			isLoading,
			model,
			chartList
		} = this.state;
		return (
			<div>
				{alert}
				<LoadingComponent Show={isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Clear",
							OnClick: this.handleClear,
							Disabled: Disabled
						}, {
							Code: "Search",
							OnClick: this.HandleSearch
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				{/**Filter Panel */}
				<Card>
					<CardBody>
						<GridContainer>
							{
								/* <GridItem xs={2}>
								<GenericLabel
									Text="Select Next" />
								<GenericSelectInput
									LabelMd={0}
									IsStatic={true}
									StaticData={SelectNextDayList}
									Name="SelectNextDay"
									Value={model.SelectNextDay}
									ValueChanged={this.HandleChange}
									KeyValueMember="Id"
									TextValueMember="Text" />
							</GridItem> */

							}
							<GridItem xs={2}>
								<GenericLabel
									Text="Select Program" />
								<ParameterComponent
									Name="LoanPurposeId"
									LabelMd={0}
									ParameterCode="LOSLoanPurpose"
									Value={model.LoanPurposeId}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="Select Partner" />
								<GenericSelectInput
									Name="ClientPartnerId"
									LabelMd={0}
									Url="/losapi/v1.0/ClientPartner/GetAll"
									Method="POST"
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="LegalNameOfBusiness"
									Value={model.ClientPartnerId}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="Select Payment Status" />
								<ParameterComponent
									Name="PaymentStatusId"
									LabelMd={0}
									ParameterCode={this.LMSPaymentStatus.ParameterCode}
									Value={model.PaymentStatusId}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="From Date" />
								<GenericDateInput
									Name="FromDate"
									LabelMd={0}
									IsFuture={false}
									MinDate={DateHelper.GetDate().subtract(33, "days")}
									MaxDate={DateHelper.GetDate()}
									Value={model.FromDate == null ? DateHelper.ToDateInputValue(DateHelper.GetDate()) : DateHelper.ToDateInputValue(model.FromDate) || ""}
									ValueChanged={this.HandleChange}
									Utc />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Text="To Date" />
								<GenericDateInput
									Name="ToDate"
									LabelMd={0}
									MinDate={model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IsFuture={false}
									Value={model.ToDate == null ? DateHelper.ToDateInputValue(DateHelper.GetDate()) : DateHelper.ToDateInputValue(model.ToDate) || ""}
									ValueChanged={this.HandleChange}
									Utc />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				{/**Charts */}
				<LoanCircleChart
					leftTitle={"Programs"}
					centerTitle={"PAYMENT MANAGEMENT"}
					mainTitle={"Payment Management"}
					setAppLeftTitle={this.props.setAppLeftTitle}
					setAppCenterTitle={this.props.setAppCenterTitle}
					itemList={chartList} />

				{/**List */}
				<Card>
					<CardBody>
						<GenericExpansionPanel Title="Transactions">
							<GridContainer>
								<GridItem xs={12}>
									<GenericGrid
										Data={this.state.paymentTransactionList || []}
										Columns={this.ColumnsData} />
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
			</div>
		);
	}
	/** End Render */
}

PaymentManagement.propTypes = {
	classes: PropTypes.object.isRequired,
	ExecuteApiPost: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	model: PropTypes.object
};
export default PaymentManagement;
