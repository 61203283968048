import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class InventorySafeManagement extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			UniqueClientId: ClientHelper.IsBackOffice() ? -2 : ClientHelper.GetClientRowId()
		};
		this.safecolumns = [
			{
				Header: "Client",
				accessor: "CustomerName"
			},
			{
				Header: "Store",
				accessor: "CustomerStoreName"
			},
			{
				Header: "Safe Name",
				accessor: "SafeName"
			}

		];
		this.emptyObject = {}
		this.sortedCustomerName = { Member: "Name" };
		this.sortedCustomerStore = { Member: "StoreName" };
		this.parameterCustomerStore = {};

		this.state = {
			safelist: [],
			model: { ...this.initialModel },
			selected: null,
			isLoading: false,
			IsBackOffice: false,
			isOpenDialog: false,
			detailDialog: false,
			vModel: {}
		};
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Inventory Safe Management");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, value) => {

		const model = this.state.model;
		model[name] = value;

		this.setState({ model });
	}

	RowSelected = (index) => {
		const { safelist } = this.state;

		this.setState({ model: safelist[index], selected: index });
	}
	DeleteConfirm = () => {
		const { showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", this.Delete);
	}

	GetParameterCustomerStore = () => {
		const { model } = this.state;

		this.parameterCustomerStore.Id = model.UniqueClientId;

		return this.parameterCustomerStore;
	}
	Search = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;
		model.CustomerStoreId = ClientHelper.IsStore() ? ClientHelper.GetStoreId() : model.CustomerStoreId;

		Proxy.POST(
			"/prepaidapi/v1.0/InventorySafeDefinition/Search",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ safelist: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Clear = () => {
		this.setState({ model: { ...this.initialModel }, safelist: [], selected: null });
	}
	Update = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventorySafeDefinition/Update",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Delete = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventorySafeDefinition/Delete",
			{
				"Id": model.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventorySafeDefinition/Insert",
			model
			,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Validate() {
		const { model } = { ...this.state };
		const { showMessageNode } = this.props;
		var messages = [];
		const vModel = { ...this.state.vModel };

		if (model.UniqueClientId == -2) {
			messages.push("Client can not be null.");
			vModel.UniqueClientId = true;

		} else {
			vModel.UniqueClientId = false;
		}

		if (!model.SafeName) {
			messages.push("Safe Name can not be null.");
			vModel.SafeName = true;

		} else {
			vModel.SafeName = false;
		}


		if (!model.CustomerStoreId) {
			messages.push(" Store Name can not be null.");
			vModel.CustomerStoreId = true;

		} else {
			vModel.CustomerStoreId = false;
		}
		this.setState({ vModel });

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	handleClose = () => {

		this.setState({ detailDialog: false, openCheckinDialog: false, openTransferDialog: false, openReceiveDialog: false, openOrderDialog: false });
	}
	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, selected, safecolumns, safelist, isLoading, detailDialog, IsBackOffice, vModel } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						Disabled: Disabled,
						OnClick: this.Search
					},
					{
						Code: "Submit",
						Disabled: Disabled || selected != null || ClientHelper.IsStore(),
						OnClick: this.Submit
					},
					{
						Code: "Update",
						Disabled: Disabled || selected == null || ClientHelper.IsStore(),
						OnClick: this.Update
					},
					{
						Code: "Delete",
						Disabled: Disabled || selected == null || ClientHelper.IsStore(),
						OnClick: this.DeleteConfirm
					},
					{
						Code: "Clear",
						Disabled: Disabled,
						OnClick: this.Clear
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={4}>

											<GenericSelectInput
												Disabled={!ClientHelper.IsBackOffice()}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Sorted={this.sortedCustomerName}
												Value={model.UniqueClientId}
												IsInvalid={vModel.UniqueClientId}
												Required
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="CustomerStoreId"
												LabelText="Store Name"
												Method="POST"
												Url="/bankapi/v1.0/CustomerAddress/GetNotNullStoreByCustomerId"
												Parameter={this.GetParameterCustomerStore()}
												DataRoot="Item"
												KeyValueMember="StoreId"
												TextValueMember="StoreName"
												Sorted={this.sortedCustomerStore}
												Value={model.CustomerStoreId || ClientHelper.GetStoreId()}
												IsInvalid={vModel.CustomerStoreId}
												Required
												ValueChanged={this.HandleChange}
												Disabled={ClientHelper.IsStore()}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="SafeName"
												LabelText="Safe Name"
												Value={model.SafeName}
												ValueChanged={this.HandleChange}
												IsInvalid={vModel.SafeName}
												Required
											>	</GenericTextInput>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<GenericExpansionPanel Title={"Safe List"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={safelist}
														SelectedIndex={selected}
														Columns={this.safecolumns}
														RowSelected={this.RowSelected} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InventorySafeManagement.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default InventorySafeManagement;