import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class ClientApiKeyDefinition extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			list: [],
			isLoading: false
		};

		this.emptyObject = {};

		this.maxLength21 = { maxLength: 21 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.Columns = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Url Key",
				accessor: "UrlKey"
			},
			{
				Header: "User Key",
				accessor: "UserKey"
			},
			{
				Header: "Password Key",
				accessor: "PasswordKey"
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Client Api Key Definition");
		this.props.setAppCenterTitle("CARD TRANSFER");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	GetClientKeys = async (ClientId) => {
		var clientId = parseInt(ClientId);
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/VisaDirect/GetAllApiKey", {
			UniqueClientId: clientId
		});

		if (result != null) {
			this.setState({ cardList: result || [] });
		}

	}

	SelectedRowChange = (e, index) => {
		const keys = this.state.list[index];
		const model = { ...this.state.model };
		this.setState({ model: keys });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/GetAllApiKey",
			{
				//IsMainCard: model.IsMainCard,
				//UniqueClientId: model.UniqueClientId || 0,
				//ClientCustomerId: model.ClientCustomerId,
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/AddApiKeyByClient",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
					this.HandleClear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/UpdateApiKeyByClientOp",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else{
					this.HandleSearch();
					this.HandleClear();
					if (responseData.Item !== null) {
						this.setState({ model: { ...this.initialModel }, index: -1 });
						this.props.showMessage("success", "Succeeded", "Operation is successfully!");					
					}
				}	
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/DeleteApiKeyByClientOp",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else{
					this.HandleSearch();
					this.HandleClear();
					if (responseData.Item !== null) {
						this.setState({ model: { ...this.initialModel }, index: -1 });
						this.props.showMessage("success", "Succeeded", "Operation is successfully!");					
					}
				}			
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select.");

		vModel.UrlKey = model.UrlKey == null;
		if (vModel.UniqueClientId) messages.push("Url Key must be defined.");

		vModel.UserKey = model.UserKey == null;
		if (vModel.UniqueClientId) messages.push("User Key must be defined.");

		vModel.PasswordKey = model.PasswordKey == null;
		if (vModel.UniqueClientId) messages.push("Password Key must be defined.");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, vModel, list, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: model.UniqueClientId == undefined || model.UniqueClientId == 0 },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: model.UniqueClientId == undefined || model.UniqueClientId == 0  },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Disabled={IsClient}
														DefaultIndex={IsClient ? 0 : -1}
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem xs={6}>
													<GenericTextInput
														Name="UrlKey"
														LabelText="Url Key"
														Value={model.UrlKey}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.UrlKey}
													/>
												</GridItem>
												<GridItem xs={6}>
													<GenericTextInput
														Name="UserKey"
														LabelText="User Key"
														Value={model.UserKey}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.UserKey}
													/>
												</GridItem>
												<GridItem xs={6}>
													<GenericTextInput
														Name="PasswordKey"
														LabelText="Password Key"
														Value={model.PasswordKey}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.PasswordKey}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Client Api Key List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={index => {
												this.SelectedRowChange(0, index);
											}}
											SelectedIndex={this.state.rowIndex}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</ >
		);
	}
}

ClientApiKeyDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientApiKeyDefinition, {});