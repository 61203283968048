import { DialogContent, DialogTitle } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Black from "components/Typography/Black.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericLabel, GenericNumberInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { AuthenticationMethod } from "views/Constants/Constant";

class OTPPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			alert: null,
			isLoading: false,
			isShowQRCode: false,
			model: {
				Otp: "",
				AuthenticationMethod: ""
			},
			rememberAuthValue: true,
			QRCode: "",
			OtpId: 0
		};

		this.cardHeaderStyle = { height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" };
		this.cardMediaStyle = {
			height: "100%", width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: 0,
			backgroundSize: "100% 100%"
		};
		this.codeBorderStyle = { marginBottom: 5, borderRadius: "5px 5px 5px 5px", border: "1px solid #000000", marginTop: 20, width: "65%" };
		this.mainGridItemStyle = { minWidth: "600px", maxWidth: "656px" };
		this.gridItemCenterStyle = { textAlign: "center" };
		this.imageHeightStyle = { height: 50 };
		this.gridItemButtonStyle = { width: "68%", marginLeft: "auto", marginRight: "auto" };

		this.HandleKeyPress = this.HandleKeyPress.bind(this);
		this.ValueChanged = this.ValueChanged.bind(this);
		this.HandleChangeAuthMethod = this.HandleChangeAuthMethod.bind(this);
		this.HandleChangeCheckBox = this.HandleChangeCheckBox.bind(this);

		this.Routing = this.Routing.bind(this);
		this.Verify = this.Verify.bind(this);

		this.AuthenticatorVerify = this.AuthenticatorVerify.bind(this);
		this.AuthenticatorVerifyCallback = this.AuthenticatorVerifyCallback.bind(this);
		this.ErrorAuthenticatorVerifyCallback = this.ErrorAuthenticatorVerifyCallback.bind(this);

		this.TwilioVerify = this.TwilioVerify.bind(this);
		this.TwilioVerifyCallback = this.TwilioVerifyCallback.bind(this);
		this.ErrorTwilioVerifyCallback = this.ErrorTwilioVerifyCallback.bind(this);

		this.GetQRCode = this.GetQRCode.bind(this);
		this.GetQRCodeCallback = this.GetQRCodeCallback.bind(this);
		this.ErrorGetQRCodeCallback = this.ErrorGetQRCodeCallback.bind(this);

		this.SendSMSOTP = this.SendSMSOTP.bind(this);
		this.SendSMSOTPCallback = this.SendSMSOTPCallback.bind(this);
		this.ErrorSendSMSOTPCallback = this.ErrorSendSMSOTPCallback.bind(this);

		this.Validate = this.Validate.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.ShowMessageNode = this.ShowMessageNode.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		const { model } = this.state;
		var user = ClientHelper.GetUser();
		if (user && user.AuthenticationMethod) {
			model.AuthenticationMethod = user.AuthenticationMethod.ParameterValue;
			this.setState({ model });
			if (user.AuthenticationMethod.ParameterValue == AuthenticationMethod.Twilio) {
				this.SendSMSOTP();
			}
		}
	}

	ValueChanged(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleChangeCheckBox(name, event) {
		this.setState({ [name]: event.target.checked });
	}

	HandleChangeAuthMethod(event) {
		const { model } = this.state;
		if (event.target.value === AuthenticationMethod.Google) {
			// this.GetQRCode();
		} else {
			this.SendSMSOTP();
		}
		model.AuthenticationMethod = event.target.value;
		this.setState({ model });
	}

	Validate() {
		const { model } = this.state;

		if (!model.AuthenticationMethod) {
			this.ShowMessage("warning", "Authentication Method is not selected", "Select Authentication Method to continue.");
			return false;
		}

		if (!model.Otp) {
			this.ShowMessage("warning", "Verification Code is empty", "Enter the Verification Code to continue.");
			return false;
		}

		return true;
	}

	Routing() {
		var user = ClientHelper.GetUser();
		if (user == undefined)
			this.props.history.replace("/acc/login");
		else {
			localStorage.removeItem("MenuData");
			localStorage.setItem("LastActiveDate", DateHelper.GetDateTime().toISOString());
			this.props.history.replace("/");
		}
	}

	GetQRCode() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/GetAuthenticatorRegisterLink",
			{},
			this.GetQRCodeCallback,
			this.ErrorGetQRCodeCallback
		);
	}

	GetQRCodeCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.AuthenticatorQrLink !== null) {
			this.setState({ QRCode: responseData.AuthenticatorQrLink, isShowQRCode: true });
		}
	}

	ErrorGetQRCodeCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the get qr code " + error);
	}

	SendSMSOTP() {
		var user = ClientHelper.GetUser();

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/SendSmsOtp",
			{
				MethodName: "Login",
				Email: user ? user.UserEmail : "",
				OrganizationUniqueId: window.OrganizationUniqueId
			},
			this.SendSMSOTPCallback,
			this.ErrorSendSMSOTPCallback
		);
	}

	SendSMSOTPCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.setState({ OtpId: responseData.OtpId });
	}

	ErrorSendSMSOTPCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the get qr code " + error);
	}

	Verify() {
		if (!this.Validate())
			return;

		if (this.state.model.AuthenticationMethod == AuthenticationMethod.Google) {
			this.AuthenticatorVerify();
		} else {
			this.TwilioVerify();
		}
	}

	AuthenticatorVerify() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/VerifyAuthenticatorOtp",
			{
				Otp: this.state.model.Otp,
				OrganizationUniqueId: window.OrganizationUniqueId
			},
			this.AuthenticatorVerifyCallback,
			this.ErrorAuthenticatorVerifyCallback
		);
	}

	AuthenticatorVerifyCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		var user = ClientHelper.GetUser();
		user.SessionId = responseData.DCBankAccessToken;
		localStorage.setItem("user", JSON.stringify(user));

		this.Routing();
	}

	ErrorAuthenticatorVerifyCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the authenticator verify " + error);
	}

	TwilioVerify() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/VerifySmsOtp",
			{
				OtpId: this.state.OtpId,
				Otp: this.state.model.Otp,
				OtpMethod: "Login"
			},
			this.TwilioVerifyCallback,
			this.ErrorTwilioVerifyCallback
		);
	}

	TwilioVerifyCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		var user = ClientHelper.GetUser();
		user.SessionId = responseData.DCBankAccessToken;
		localStorage.setItem("user", JSON.stringify(user));
		this.Routing();
	}

	ErrorTwilioVerifyCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the authenticator verify " + error);
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	HandleKeyPress(e) {
		if (e.key == "Enter") {
			this.Verify();
		}
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert, isShowQRCode, QRCode } = this.state;

		const logo = require("assets/img/" + window.OtpLogo);
		const googleLogo = require("assets/img/" + window.OtpGoogleLogo);
		const twilioLogo = require("assets/img/" + window.OtpTwilio);
		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading} />
				<GenericDialog open={isShowQRCode}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Two Factor Authentication" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<div>
							<p>To use an authenticator app go through the following steps:</p>
							<ol className={classes.list}>
								<li>
									<p>
										Download a two-factor authenticator app like Microsoft Authenticator for
										<a href="https://go.microsoft.com/fwlink/?Linkid=825071"> Windows Phone</a>,
										<a href="https://go.microsoft.com/fwlink/?Linkid=825072"> Android</a> and
										<a href="https://go.microsoft.com/fwlink/?Linkid=825073"> iOS</a> or
																Google Authenticator for
										<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"> Android</a> and
										<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"> iOS</a>.
									</p>
								</li>
								<li>
									<p>Scan the QR Code into your two factor authenticator app. Spaces and casing do not matter.</p>
									<div id="qrCode"></div>
									<div id="qrCodeData" data-url="@Model.AuthenticatorUri"></div>
								</li>
								<li>
									<p>
										Once you have scanned the QR code or input the key above, your two factor authentication app will provide you
										with a unique code. Enter the code in the confirmation box below.
									</p>
								</li>
							</ol>
						</div>
						{QRCode.length > 0 &&
							<GridItem>
								<img style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}
									src={QRCode}
									width="200"
									height="200"
									alt="qrcode"
								/>
							</GridItem>
						}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isShowQRCode: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				{alert}
				<GridContainer justify="left">
					<GridItem style={this.mainGridItemStyle}>
						<Card login>
							<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="white" style={this.cardHeaderStyle}>
								<CardMedia className={classes.media} image={logo} style={this.cardMediaStyle} title="" />
							</CardHeader>
							<CardBody>
								<GridContainer direction="column" justify="space-between" alignItems="center">
									<div style={{ marginTop: 40 }}>
										<Black>Select Your 2FA Authentication Method</Black>
									</div>
									<GridItem xs={12}>
										<GridContainer justify="center" alignItems="center">
											<GridItem xs={6} style={this.gridItemCenterStyle}>
												<FormControlLabel
													control={
														<Radio
															checked={model.AuthenticationMethod === AuthenticationMethod.Google}
															value={AuthenticationMethod.Google}
															name="radio-button"
															classes={{ checked: classes.radio }}
															onChange={this.HandleChangeAuthMethod}
														/>
													}
													classes={{ label: classes.radioLabel }}
													label="Google"
												/>
											</GridItem>
											<GridItem xs={6} style={this.gridItemCenterStyle}>
												<FormControlLabel
													control={
														<Radio
															checked={model.AuthenticationMethod === AuthenticationMethod.Twilio}
															value={AuthenticationMethod.Twilio}
															name="radio-button"
															classes={{ checked: classes.radio }}
															onChange={this.HandleChangeAuthMethod}
														/>
													}
													classes={{ label: classes.radioLabel }}
													label="Text 2FA"
												/>
											</GridItem>
											<GridItem xs={6} style={this.gridItemCenterStyle}>
												<img className={classes.img} style={this.imageHeightStyle} src={googleLogo} />
											</GridItem>
											<GridItem xs={6} style={this.gridItemCenterStyle}>
												<img className={classes.img} style={this.imageHeightStyle} src={twilioLogo} />
											</GridItem>
										</GridContainer>
									</GridItem>
									<div style={this.codeBorderStyle}>
										<GridContainer
											style={{ marginBottom: 5 }}
											direction="column" justify="flex-start" alignItems="center">
											<Black>Enter initial code or verification code</Black>
											<GridItem>
												<GenericNumberInput
													LabelMd={3}
													NoFormatting={true}
													ThousandSeparator=""
													Name="Otp"
													LabelText="Code"
													Value={model.Otp}
													ValueChanged={this.ValueChanged}
													AutoFocus={true}
													MaxLength={6}
													KeyPressed={this.HandleKeyPress} />
											</GridItem>
										</GridContainer>
									</div>
								</GridContainer>
							</CardBody>
							<CardFooter style={{ marginTop: "-16px" }} className={classes.justifyContentCenter}>
								<GridContainer direction="column" justify="flex-start" alignItems="stretch">
									<GridItem xs={12} style={this.gridItemButtonStyle}>
										<div>
											<Button color="primary" size="lg" block
												onClick={() => this.Verify()}
												disabled={!model.Otp || model.Otp.length < 6}>
												SUBMIT
											</Button>
										</div>
									</GridItem>
									<GridItem>
										<GridContainer direction="row" justify="flex-end" alignItems="flex-end" spacing={16}>
											<GridItem style={{ marginRight: "16%" }}>
												<Link style={{ color: window.PageMainColor }} to={"/acc/login"}> Back to login page. </Link>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

OTPPage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(OTPPage, loginPageStyle);