import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import DateHelper from "core/DateHelper";
import { GenericDateInput} from "views/Components/Generic";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";

class FeeCollection extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null
		};

		this.columns = [
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "Fee Amount Intended For Collection",
				accessor: "FeeAmountToCollect"
			},
			{
				Header: "Actual Fee Amount Collected",
				accessor: "FeeAmountCollected"
			},
			{
				Header: "Date And Time Of Collection",
				accessor: "DateTimeCollection"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Description",
				accessor: "Description"
			}
		];

		this.state = {
			list: [],
			model: this.initialModel,
			isLoading: false,
			index: -1
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Fee Collection");
		this.props.setAppCenterTitle("CARD MANAGEMENT SYSTEM");
	}

	HandleChange = (name, newValue, data) => {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	};

	HandleSearch = (name, newValue, data) => {
		Proxy.POST(
			"/bankapi/v1.0/FeeCollectionReport/GetAll",
			{StartDate: this.state.model.FromDate, EndDate: this.state.model.ToDate},
			(responseData) => {
				this.setState({isLoading: false});
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({
						list: responseData.Item,
					});
				}
			},
			(error) => {
				this.setState({isLoading: false});
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	render() {
		const { alert, model, list, isLoading, index} = this.state;
		return (
			<div>
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: () => { this.HandleSearch();}}
				]} menuId={this.props.menuId}/>
				<p></p>

				<GridItem container direction="row">
					<GridItem item xs={3}>
						<GenericDateInput
								Utc
								Name="FromDate"
								LabelText="From Date"
								Value={model.FromDate == null || model.FromDate === undefined ? "" : model.FromDate}
								ValueChanged={this.HandleChange}
								MaxDate={model.ToDate || DateHelper.GetDate()}
								IncludeTime={false} />
					</GridItem>
					<GridItem item xs={3}>
						<GenericDateInput
								Utc
								Name="ToDate"
								LabelText="To Date"
								Value={model.ToDate == null || model.ToDate === undefined ? "" : model.ToDate}
								ValueChanged={this.HandleChange}
								MinDate={model.FromDate == null ? null : model.FromDate}
								MaxDate={DateHelper.GetDate()}
								IncludeTime={false} />
					</GridItem>
				</GridItem>

				<LoadingComponent Show={isLoading} />
				{alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ShowPagination={true}
													PageSize={100}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}
FeeCollection.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};
export default withArtifex(FeeCollection, {});
