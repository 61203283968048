import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon, InformationIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import CampaignEvaulate from "views/Campaign/CampaignEvaulate/CampaignEvaulate";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import { GenericInput } from "views/Components/Generic.jsx";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { CampaignExecutionType, DaysOfMonth, DaysOfWeek, MonthsOfYear, PropertyType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";
import { GridColumnType } from "views/Constants/Constant";

class CampaignProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {},
			CampaignProfileList: [],
			CriteriaList: [],
			ActionList: [],
			DaysOfWeekList: [],
			DaysOfMonthList: [],
			MonthsOfYearList: [],
			alert: null,
			isSimulateDialogOpen: false,
			isLoading: false,
			isClient: ClientHelper.IsClient(),
		};
		this.ParameterExecutionType = { ParameterCode: "CampaignExecutionType" };
		this.CampaignMethodProfileParameter = {};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.GetProfileById(row.original.Id)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Simulate",
				accessor: "Simulate",
				Cell: row => (
					<div>
						<Button disabled={props.Disabled || !this.state.isClient} justIcon simple size="sm" tooltip="Edit" onClick={() => {
							this.setState({ isSimulateDialogOpen: true })
							this.GetProfileById(row.original.Id)
						}} >
							<img src={InformationIcon} />
						</Button>
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Campaign Type",
				accessor: "ExecutionType.ParameterDesc"
			},
			{
				Header: "Campaign Code",
				accessor: "CampaignCode"
			},
			{
				Header: "Campaign Start Date",
				accessor: "StartDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Campaign End Date",
				accessor: "ExpireDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
		];
	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Campaign Profile");

	}
	OperationCheck = () => {
		var ErrorList = [];
		const data = this.state.model;
		const vModel = this.state.vModel;
		var criteria = this.CheckCriteriaList();
		if (!criteria) {
			return false;
		}
		var action = this.CheckActionList();
		if (!action) {
			return false;
		}
		if (!data.ProfileName) {
			vModel.ProfileName = true;
			ErrorList.push("Profile Name can not be null.");
		} else { vModel.ProfileName = false; }
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	CheckActionList = () => {
		return true;
	}
	CheckCriteriaList = () => {
		var list = this.state.CriteriaList;
		var vModel = this.state.vModel;
		var ErrorList = [];

		list.forEach((element, index) => {
			if (element == null) {
				ErrorList.push("Criteria  " + this.GridRowCount(index) + " must be defined");
			}
			if (element == null || element.DataModelPropertyId == null || element.DataModelPropertyId == "") {
				vModel.DataModelPropertyId = true;
				ErrorList.push("Criteria  " + this.GridRowCount(index) + " type must be defined ");
			} else { vModel.DataModelPropertyId = false; }
			if (element == null || element.ExpressionTypeId == null || element.ExpressionTypeId == "") {
				vModel.ExpressionTypeId = true;
				ErrorList.push("Criteria Expression  " + this.GridRowCount(index) + " type must be defined ");
			} else { vModel.ExpressionTypeId = false; }
			if (element == null || element.RightValue == null || element.RightValue == "") {
				vModel.RightValue = true;
				ErrorList.push("Criteria Value  " + this.GridRowCount(index) + " must be defined ");
			} else { vModel.RightValue = false; }
			if (element == null || element.DataModelProperty == null || element.DataModelProperty.PropertyType == null || element.DataModelProperty.PropertyType.ParameterValue == null) {
				ErrorList.push("Criteria  " + this.GridRowCount(index) + " must be selected !");
			} else {
				if (element != null && element.DataModelProperty.PropertyType.ParameterValue == PropertyType.Date) {
					if (element.RightValue == null || !element.RightValue.isValid()) {
						ErrorList.push("Criteria  " + this.GridRowCount(index) + " Date must be valid ");
					}
				}
			}
			this.setState({ vModel })
			var temp = list.filter(x => x.DataModelPropertyId == element.DataModelPropertyId && x.ExpressionTypeId == element.ExpressionTypeId);
			if (temp.length > 1) {
				ErrorList.push("Criteria  " + this.GridRowCount(index) + " can not add because same criteria and expression already exist");
			}

		});

		var unique = [...new Set(list.map(a => a.DataModelPropertyId && a.ExpressionTypeId))];

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Check these fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			if (name == "IsOneTimeEvent" && newValue == true) {
				temp.IsContinuousEvent = false;
				temp.IsCustomEvent = false;
				temp.StartDateTime = null;
				temp.ExpireDateTime = null;
			}
			if (name == "IsContinuousEvent" && newValue == true) {
				temp.IsOneTimeEvent = false;
				temp.IsCustomEvent = false;
				temp.StartDateTime = null;
				temp.ExpireDateTime = null;
			}
			if (name == "IsCustomEvent" && newValue == true) {
				temp.IsContinuousEvent = false;
				temp.IsOneTimeEvent = false;
				temp.StartDateTime = null;
				temp.ExpireDateTime = null;
			}
			if (name == "ExecutionTypeId" && data != null) {
				temp.ExecutionTypeStr = data.ParameterValue;
			}
			if (name == "IsDaysOfWeek" && newValue == true) {
				temp.IsDaysOfMonth = false;
				this.setState({ DaysOfMonthList: [] });
			}
			if (name == "IsDaysOfMonth" && newValue == true) {
				temp.IsDaysOfWeek = false;
				this.setState({ DaysOfWeekList: [] });
			}
			temp[name] = newValue;
			return { model: temp };
		});
	}
	handleListItemChange = (name, newValue, key, ObjectName) => {
		if (ObjectName == "DaysOfWeek") {
			const array = this.state.DaysOfWeekList;
			const item = array.includes(key);
			if (item == false && newValue == true) {
				array.push(key);
			} else if (item == true && newValue == false) {
				var index = array.indexOf(key);
				if (index > -1) {
					array.splice(index, 1);
				}
			}
			this.setState({ DaysOfWeekList: array });
		}
		if (ObjectName == "DaysOfMonth") {
			const array = this.state.DaysOfMonthList;
			const item = array.includes(key);
			if (item == false && newValue == true) {
				array.push(key);
			} else if (item == true && newValue == false) {
				var index = array.indexOf(key);
				if (index > -1) {
					array.splice(index, 1);
				}
			}
			this.setState({ DaysOfMonthList: array });
		}
		if (ObjectName == "MonthsOfYear") {
			const array = this.state.MonthsOfYearList;
			const item = array.includes(key);
			if (item == false && newValue == true) {
				array.push(key);
			} else if (item == true && newValue == false) {
				var index = array.indexOf(key);
				if (index > -1) {
					array.splice(index, 1);
				}
			}
			this.setState({ MonthsOfYearList: array });
		}
	}
	UpdateModel = () => {
		var temp = this.state.model;
		temp.CriteriaList = this.state.CriteriaList;
		temp.ActionList = this.state.ActionList;

		temp.CriteriaList.forEach(element => {
			element.RightValue = JSON.stringify(element.RightValue);
			//element.ViewData  = null;
			//element.DataModelProperty = null;
		});

		temp.DaysOfWeek = JSON.stringify(this.state.DaysOfWeekList);
		temp.DaysOfMonth = JSON.stringify(this.state.DaysOfMonthList);
		temp.MonthsOfYear = JSON.stringify(this.state.MonthsOfYearList);

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignProfile/Update",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Campaign Profile Updated ! " : "Campaign Profile Saved ! ");
				this.ClearData();
				this.SearchCampaignProfileModel();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	EvaluateCampaign = (ProfileId) => {
		if (ProfileId == null || ProfileId == 0) {
			this.ShowMessage("error", "Error", "Profile Id required for Evaluating profile !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignCriteria/Evaluate",
			{ Id: ProfileId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	GetProfileById = (ProfileId) => {
		if (ProfileId == null || ProfileId == 0) {
			this.ShowMessage("error", "Error", "Profile Id required for fetching profile !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignProfile/GetById",
			{ Id: ProfileId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {

					if (responseData.Item.CriteriaList != null) {
						responseData.Item.CriteriaList.forEach(element => {
							element.ViewData = this.GenerateViewData(element.DataModelProperty.PropertyType.ParameterValue, element.DataModelProperty.PropertyData);
							element.RightValue = JSON.parse(element.RightValue);
							if (element.DataModelProperty.PropertyType.ParameterValue == PropertyType.Date) {
								element.RightValue = DateHelper.ToMoment(element.RightValue);
							}
						});
						this.setState({ CriteriaList: responseData.Item.CriteriaList });
					} else {
						this.setState({ CriteriaList: [] });
					}

					if (responseData.Item.ActionList != null) {
						this.setState({ ActionList: responseData.Item.ActionList });
					} else {
						this.setState({ ActionList: [] });
					}

					var temp = responseData.Item;
					if (temp != null) {
						temp.ExecutionTypeStr = temp.ExecutionType == null ? "" : temp.ExecutionType.ParameterValue || "";
					} else {
						temp = {};
						temp.ExecutionTypeStr = "";
					}
					this.setState({ model: temp });

					this.setState({ DaysOfWeekList: JSON.parse(temp.DaysOfWeek) });
					this.setState({ DaysOfMonthList: JSON.parse(temp.DaysOfMonth) });
					this.setState({ MonthsOfYearList: JSON.parse(temp.MonthsOfYear) });

				} else {
					this.setState({ model: {}, CriteriaList: [], ActionList: [] });
				}

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SearchCampaignProfileModel = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignProfile/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ CampaignProfileList: responseData.Item });
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.ShowMessage("error", "Error", "Campaign Profile must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/campaignapi/v1.0/CampaignProfile/Delete/Id?Id=" + this.state.model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Campaign Profile deleted !");
				this.ClearData();
				this.SearchCampaignProfileModel();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	GenerateViewData = (type, data) => {
		if (type == PropertyType.Select) {
			var temp = JSON.parse(data);
			temp.Name = "RightValue";
			temp.InputType = PropertyType.Select;
			return temp;
		} else if (type == PropertyType.Decimal) {
			return {
				Name: "RightValue",
				InputType: PropertyType.Decimal,
				IsRequired:true,
				Prefix: "$ ",
				MaxLength: 7
			};
		} else if (type == PropertyType.Date) {
			return {
				Name: "RightValue",
				IsRequired:true,
				InputType: PropertyType.Date
			};
		} else {
			return {
				Name: "RightValue",
				IsRequired:true,
				InputType: type
			};
		}
	}
	AddSingleCriteria = () => {

		var res = this.CheckCriteriaList();
		if (!res) {
			return false;
		}
		var list = this.state.CriteriaList;
		const temp = {
			Id: 0,
			ExpressionTypeId: 0,
			ViewData: null
		};
		list.push(temp);
		this.setState({ CriteriaList: list });
	}
	AddSingleAction = () => {

		var res = this.CheckActionList();
		if (!res) {
			return false;
		}
		var list = this.state.ActionList;
		const temp = {
			Id: 0,
			ExpressionTypeId: 0,
			ViewData: null
		};
		list.push(temp);
		this.setState({ ActionList: list });
	}
	handleChangeCriteria = (name, newValue, index, data) => {
		this.setState(function (state, props) {
			var list = this.state.CriteriaList;
			const item = list[index];
			if (item != null) {
				item[name] = newValue;
			}
			if (name == "DataModelPropertyId" && data != null && item != null && data.PropertyType != null) {
				item.DataModelProperty = { PropertyType: data.PropertyType };
				item.ViewData = this.GenerateViewData(data.PropertyType.ParameterValue, data.PropertyData);
			}
			if (item != null && name == "DataModelPropertyId") {
				item.RightValue = null;
			}
			return { CriteriaList: list };
		});
	}
	handleChangeAction = (name, newValue, index, data) => {
		this.setState(function (state, props) {
			var list = this.state.ActionList;
			const item = list[index];
			if (item != null) {
				item[name] = newValue;
			}
			return { ActionList: list };
		});
	}
	SelectedRowChange = (index) => {
		const temp = this.state.CampaignProfileList[index];
		this.GetProfileById(temp.Id);
	}
	ClearData = () => {
		this.setState({
			vModel:{},
			model: {},
			CampaignProfileList: [],
			CriteriaList: [],
			ActionList: [],
			DaysOfWeekList: [],
			DaysOfMonthList: [],
			MonthsOfYearList: [],
			alert: null,
			isSimulateDialogOpen: false,
			isLoading: false
		});
	}
	ShowQuestionMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	GridRowCount = (count) => {
		return ++count;
	}

	GetSelectParamMethodProfile = (CampaignEngineMethodId) => {
		this.CampaignMethodProfileParameter.ExecutionTypeId = this.state.model.ExecutionTypeId;
		this.CampaignMethodProfileParameter.CampaignEngineMethodId = CampaignEngineMethodId;
		return this.CampaignMethodProfileParameter;
	}
	RemoveSingleCriteria = (index) => {
		this.setState(state => {
			var CriteriaList = state.CriteriaList;
			CriteriaList.splice(index, 1);
			return { CriteriaList };
		});
	}
	RemoveSingleAction = (index) => {
		this.setState(state => {
			var ActionList = state.ActionList;
			ActionList.splice(index, 1);
			return { ActionList };
		});
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	render() {
		const { Disabled } = this.props;
		const { model, CampaignProfileList, CriteriaList, ActionList, isSimulateDialogOpen, DaysOfWeekList, DaysOfMonthList, MonthsOfYearList, vModel } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled || model.Id > 0 || !this.state.isClient },
					{ Code: "Update", OnClick: this.OperationCheck, Disabled: Disabled || !model.Id > 0 || !this.state.isClient },
					{ Code: "Search", OnClick: this.SearchCampaignProfileModel, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
					{ Code: "Delete", OnClick: () => this.ShowQuestionMessage("Delete this Campaign Profile ?"), Disabled: Disabled || !model.Id > 0 || !this.state.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Campaign Profile Information"} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							{!this.state.isClient && (
								<GridItem xs={3} >
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemUniqueClient}
										Value={model.UniqueClientId}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={this.state.isClient}
										DefaultIndex={this.state.isClient ? 0 : -1}
									/>
								</GridItem>
							)}
							<GridItem xs={3}>
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									Name="ProgramId"
									LabelText="Program"
									Value={model.ProgramId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
									DataRoot="Item"
								/>
							</GridItem>
							<GridItem xs={3}>
								<GenericTextInput
									Disabled={Disabled}
									inputProps={{ maxLength: 30 }}
									Name="ProfileName"
									LabelText="Profile Name"
									Value={model.ProfileName || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.ProfileName}
								/>
							</GridItem>
							<GridItem xs={3}>
								<GenericTextInput
									Disabled={true}
									Name="CampaignCode"
									LabelText="Campaign Code"
									Value={model.CampaignCode || ""}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
							<GridItem xs={3}>

							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Campaign Details"} />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={4}>
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									LabelMd={6}
									Name="ExecutionTypeId"
									LabelText="Campaign Execution Type"
									Value={model.ExecutionTypeId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterExecutionType}
									DataRoot="Item"
								/>
							</GridItem>
						</GridContainer>
						{model.ExecutionTypeStr == CampaignExecutionType.Batch && (
							<div>
								<GridContainer>
									<GridItem xs={1}>
										<GenericCheckInput
											LabelMd={10}
											Disabled={Disabled}
											Name="IsOneTimeEvent"
											LabelText="One Time"
											Value={model.IsOneTimeEvent || false}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									{model.IsOneTimeEvent == true && (
										<GridItem xs={2}>
											<GenericDateInput
												Name="StartDateTime"
												LabelText="Date"
												LabelMd={4}
												Value={model.IsOneTimeEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
												ValueChanged={this.handleChange}
												Disabled={Disabled || !model.IsOneTimeEvent}
											/>
										</GridItem>
									)}
								</GridContainer>
								<GridContainer>
									<GridItem xs={1}>
										<GenericCheckInput
											LabelMd={10}
											Disabled={Disabled}
											Name="IsContinuousEvent"
											LabelText="Continuous"
											Value={model.IsContinuousEvent || false}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									{model.IsContinuousEvent == true && (
										<>
											<GridItem xs={2}>
												<GenericDateInput
													Name="StartDateTime"
													LabelText="Start Date"
													LabelMd={4}
													Value={model.IsContinuousEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsContinuousEvent}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericDateInput
													Name="ExpireDateTime"
													LabelText="End Date"
													LabelMd={4}
													Value={model.IsContinuousEvent == false ? "" : model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsContinuousEvent}
												/>
											</GridItem>
										</>
									)}
								</GridContainer>
							</div>
						)}
						{model.ExecutionTypeStr == CampaignExecutionType.Online && (
							<div>
								<GridContainer>
									<GridItem xs={1}>
										<GenericCheckInput
											LabelMd={10}
											Disabled={Disabled}
											Name="IsOneTimeEvent"
											LabelText="One Time"
											Value={model.IsOneTimeEvent || false}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									{model.IsOneTimeEvent == true && (
										<>
											<GridItem xs={2}>
												<GenericDateInput
													Name="StartDateTime"
													LabelText="Date"
													LabelMd={4}
													Value={model.IsOneTimeEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsOneTimeEvent}
												/>
											</GridItem>
											<GridItem xs={1}>
												<GenericDateInput
													Name="StartDateTime"
													LabelMd={0}
													DateFormat={false}
													IncludeTime={true}
													ViewMode="time"
													Value={model.IsOneTimeEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsOneTimeEvent}
												/>
											</GridItem>
										</>
									)}
								</GridContainer>
								<GridContainer>
									<GridItem xs={1}>
										<GenericCheckInput
											LabelMd={10}
											Disabled={Disabled}
											Name="IsContinuousEvent"
											LabelText="Continuous"
											Value={model.IsContinuousEvent || false}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									{model.IsContinuousEvent == true && (
										<>
											<GridItem xs={2}>
												<GenericDateInput
													Name="StartDateTime"
													LabelText="Start Date"
													LabelMd={4}
													Value={model.IsContinuousEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsContinuousEvent}
												/>
											</GridItem>
											<GridItem xs={1}>
												<GenericDateInput
													Name="StartDateTime"
													LabelMd={0}
													DateFormat={false}
													IncludeTime={true}
													ViewMode="time"
													Value={model.IsContinuousEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsContinuousEvent}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericDateInput
													Name="ExpireDateTime"
													LabelText="End Date"
													LabelMd={4}
													Value={model.IsContinuousEvent == false ? "" : model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsContinuousEvent}
												/>
											</GridItem>
											<GridItem xs={1}>
												<GenericDateInput
													Name="ExpireDateTime"
													LabelMd={0}
													DateFormat={false}
													IncludeTime={true}
													ViewMode="time"
													Value={model.IsContinuousEvent == false ? "" : model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
													ValueChanged={this.handleChange}
													Disabled={Disabled || !model.IsContinuousEvent}
												/>
											</GridItem>
										</>
									)}
								</GridContainer>
								<GridContainer>
									<GridItem xs={1}>
										<GenericCheckInput
											LabelMd={10}
											Disabled={Disabled}
											Name="IsCustomEvent"
											LabelText="Custom Event"
											Value={model.IsCustomEvent || false}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</CardBody>
				</Card>

				{((model.ExecutionTypeStr == CampaignExecutionType.Online) && (model.IsCustomEvent == true)) && (
					<Card className="no-radius">
						<CardHeader>
							<GenericTitle text={"Advanced Definition"} />
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={2}>
									<GenericDateInput
										Name="StartDateTime"
										LabelText="Start Time"
										DateFormat={false}
										IncludeTime={true}
										ViewMode="time"
										Value={model.IsCustomEvent == false ? "" : model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
										ValueChanged={this.handleChange}
										Disabled={Disabled || !model.IsCustomEvent}
									/>
								</GridItem>
								<GridItem xs={2}>
									<GenericDateInput
										Name="ExpireDateTime"
										LabelText="End Time"
										DateFormat={false}
										IncludeTime={true}
										ViewMode="time"
										Value={model.IsCustomEvent == false ? "" : model.ExpireDateTime == null ? "" : DateHelper.ToDateInputValue(model.ExpireDateTime)}
										ValueChanged={this.handleChange}
										Disabled={Disabled || !model.IsCustomEvent}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={2}>
									<GenericCheckInput
										LabelMd={10}
										Disabled={Disabled}
										Name="IsDaysOfWeek"
										LabelText="Days of Week"
										Value={model.IsDaysOfWeek || false}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								{model.IsDaysOfWeek == true && (
									<GridItem xs={8}>
										{DaysOfWeek != null && DaysOfWeek.map(item => (
											<GenericCheckInput key={"DaysOfWeek" + item.Id} Name="Id" LabelText={item.Text} LabelMd={6}
												Value={DaysOfWeekList.includes(item.Id)}
												ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "DaysOfWeek")}
												Disabled={Disabled || model.IsDaysOfMonth}
											/>
										))}

									</GridItem>
								)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={2}>
									<GenericCheckInput
										LabelMd={10}
										Disabled={Disabled}
										Name="IsDaysOfMonth"
										LabelText="Days of Month"
										Value={model.IsDaysOfMonth || false}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								{model.IsDaysOfMonth == true && (
									<GridItem xs={8}>
										{DaysOfMonth != null && DaysOfMonth.map(item => (
											<GenericCheckInput key={"DaysOfMonth" + item.Id} Name="Id" LabelText={item.Text} LabelMd={6}
												Value={DaysOfMonthList.includes(item.Id)}
												ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "DaysOfMonth")}
												Disabled={Disabled || model.IsDaysOfWeek}
											/>
										))}
									</GridItem>
								)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={2}>
									<GenericCheckInput
										LabelMd={10}
										Disabled={Disabled}
										Name="IsMonthsOfYear"
										LabelText="Months of Year"
										Value={model.IsMonthsOfYear || false}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								{model.IsMonthsOfYear == true && (
									<GridItem xs={8}>
										{MonthsOfYear != null && MonthsOfYear.map(item => (
											<GenericCheckInput key={"MonthsOfYear" + item.Id} Name="Id" LabelText={item.Text} LabelMd={6}
												Value={MonthsOfYearList.includes(item.Id)}
												ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "MonthsOfYear")}
												Disabled={Disabled || !model.IsMonthsOfYear}
											/>
										))}
									</GridItem>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				)}


				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text="Criteria and Actions" />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={6}>
								<GridContainer justify="flex-end">
									<GridItem style={{ float: "right" }}>
										<Button disabled={Disabled} size="sm" color="black" onClick={() => this.AddSingleCriteria()} > + Criteria </Button>
									</GridItem>
									<GridItem xs={1}></GridItem>
								</GridContainer>
								{CriteriaList != null && CriteriaList.map((item, index) => (
									<GridContainer key={item.Id}>
										<GridItem xs={1} style={{ paddingLeft: "9px", marginTop: "10px" }}>
											<GenericLabel Text={"Criteria " + this.GridRowCount(index)}></GenericLabel>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												LabelMd={0}
												Disabled={Disabled}
												Name="DataModelPropertyId"
												Method="POST"
												key={model.ExecutionTypeId}
												Url="/campaignapi/v1.0/CampaignDataModelProperty/GetAllById"
												Parameter={{ Id: model.ExecutionTypeId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="PropertyDescription"
												Value={item.DataModelPropertyId}
												ValueChanged={(name, value, data) => this.handleChangeCriteria(name, value, index, data)}
												Required
												IsInvalid={vModel.DataModelPropertyId}
											/>

										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												LabelMd={0}
												Disabled={Disabled}
												Name="ExpressionTypeId"
												Value={item.ExpressionTypeId}
												ValueChanged={(name, value, data) => this.handleChangeCriteria(name, value, index, data)}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{ ParameterCode: "ExpressionType" }}
												DataRoot="Item"
												Required={item.DataModelPropertyId}
												IsInvalid={vModel.ExpressionTypeId}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericInput
												key={item.DataModelPropertyId + "e"}
												ViewData={item.ViewData}
												LabelMd={0}
												Value={item.RightValue}
												ValueChanged={(name, value, data) => this.handleChangeCriteria(name, value, index, data)}
												Model={model}
												Disabled={Disabled}
												IsInvalid={vModel[item.RightValue]}
												IsRequired={{[item.IsRequired]:true}}
											/>
										</GridItem>
										<GridItem xs={1}>
											<Button disabled={Disabled} size="sm" color="black" onClick={() => this.RemoveSingleCriteria(index)} >
												Delete
											</Button>
										</GridItem>
										<GridItem xs={1}></GridItem>
									</GridContainer>
								))}

							</GridItem>
							<GridItem xs={6}>
								<GridContainer justify="flex-end">
									<GridItem style={{ float: "right" }}>
										<Button disabled={Disabled} size="sm" color="black" onClick={() => this.AddSingleAction()} > + Action </Button>
									</GridItem>
									<GridItem xs={2}></GridItem>
								</GridContainer>
								{ActionList != null && ActionList.map((item, index) => (
									<GridContainer key={item.Id}>
										<GridItem xs={1} style={{ paddingLeft: "9px", marginTop: "10px" }}>
											<GenericLabel Text={"Action " + this.GridRowCount(index)}></GenericLabel>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												LabelMd={0}
												Disabled={Disabled}
												Name="CampaignEngineMethodId"
												Method="POST"
												key={model.ExecutionTypeId}
												Url="/campaignapi/v1.0/CampaignEngineMethod/GetAllById"
												Parameter={{ Id: model.ExecutionTypeId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="MethodDescription"
												Value={item.CampaignEngineMethodId}
												ValueChanged={(name, value, data) => this.handleChangeAction(name, value, index, data)}
											/>

										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={item.CampaignEngineMethodId + " - " + model.ExecutionTypeId + "x " + model.ProgramId}
												LabelMd={0}
												Disabled={Disabled}
												Name="MethodProfileId"
												Value={item.MethodProfileId}
												ValueChanged={(name, value, data) => this.handleChangeAction(name, value, index, data)}
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Method="POST"
												Url="/campaignapi/v1.0/CampaignNotificationProfile/FilterProfile"
												Parameter={{
													CampaignEngineMethodId: item.CampaignEngineMethodId,
													ExecutionTypeId: this.state.model.ExecutionTypeId,
													ProgramId: this.state.model.ProgramId
												}}
												DataRoot="Item"
											/>
										</GridItem>
										<GridItem xs={1} >
											<Button disabled={Disabled} size="sm" color="black" onClick={() => this.RemoveSingleAction(index)} >
												Delete
											</Button>
										</GridItem>
										<GridItem xs={2}></GridItem>
									</GridContainer>
								))}
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Campaign Profile List"} />
					</CardHeader>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={CampaignProfileList}
									PageSize={5}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>


				<GenericDialog open={isSimulateDialogOpen} fullScreen maxWidth="md" fullWidth>
					<DialogTitle><GenericLabel FontSize="16px" TextColor="black" Text="Campaign Simulate" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<CampaignEvaulate
							key={"keys" + model + " " + isSimulateDialogOpen}
							Disabled={Disabled}
							ProfileId={model.Id}
							ExecutionType={model.ExecutionTypeStr}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isSimulateDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


			</div >
		);
	}
}

CampaignProfile.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CampaignProfile, {});