import moment from "moment";
import "moment-timezone";

function GetDate() {
	return moment.utc().startOf("day");
}

function GetDateTime() {
	return moment.utc();
}

function GetDateLocal() {
	return moment().startOf("day");
}

function GetDateTimeLocal() {
	return moment();
}

function GetLocalDateAsUtc() {
	return GetLocalDateTimeAsUtc().startOf("day");
}

function GetLocalDateTimeAsUtc() {
	var dateTime = GetDateTime();
	var utcOffset = moment().utcOffset();
	return dateTime.add(utcOffset, "minutes");
}

function GetDateTimeMst(){
	return moment.tz("America/Denver");
}

function GetDateTimeEst() {
	return moment.tz("America/New_York");
}

function GetTimeZone() {
	return moment.tz.guess();
}

function GetYear() {
	return moment.utc().format("YYYY");
}

/**
 * @param {moment.MomentInput} date
 */
function ToMoment(date) {
	return moment.utc(date);
}

/**
 * @param {moment.MomentInput} date
 * @param {string} format
 */
function ToMomentWithFormat(date, format) {
	return moment.utc(date, format);
}

/**
 * @param {moment.MomentInput} date
 */
function ToDateInputValue(date) {
	// DB den date Date tipi ile geliyor
	if (moment(date, "YYYY-MM-DDTHH:mm:ss", true).isValid() || moment(date, "YYYY-MM-DDTHH:mm:ssZ", true).isValid())
		return moment.utc(date);
	return date;
}
function ToDateInputValueAsUtc(date) {
	if (
	  moment(date, "YYYY-MM-DDTHH:mm:ss", true).isValid() ||
	  moment(date, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()
	) {
	  return moment(date).utc().startOf("day");
	}
	return date;
 }
const DateHelper = {
	GetDate,
	GetDateTime,
	GetDateLocal,
	GetDateTimeLocal,
	GetLocalDateAsUtc,
	GetLocalDateTimeAsUtc,
	GetTimeZone,
	GetYear,
	ToMoment,
	ToMomentWithFormat,
	ToDateInputValue,
	ToDateInputValueAsUtc,
	GetDateTimeMst,
	GetDateTimeEst
};

export default DateHelper;