import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AddNewPayee from "views/Components/AddNewPayee";
import { GenericCheckInput, GenericDialog, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { GridColumnType, ProgramCodes, ReportTypeForWalletAccounts, WalletAutoLoadType } from "views/Constants/Constant";
import ParameterComponent from "views/Components/ParameterComponent";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class WalletAccount extends Component {
	constructor(props) {
		super(props);

		this.state = {
			vModel: {},
			isEdit: false,
			tempAccount: {},
			hasChangedPayee: false,
			rowIndex: -1,
			IsPayeePopUpOpen: false
		};

		this.parameterAccountType = {
			ParameterCode: "BankingAccountType",
			ParameterValue2: "IsClientCustomer"
		};

		this.parameterAutoLoadType = {
			ParameterCode: "AutoLoadType",
			ParameterValue2: "Wallet"
		};

		this.parameterClientCustomerPayee = {
			IsClientCustomerPayee: true,
			ProgramCode: ProgramCodes.Bank_Programs_e_Transfer
		};

		this.renderItemAccount = {};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.Columns = [
			{
				Header: "Report Type",
				accessor: "ReportType"
			},
			{
				Header: "Wallet Type",
				accessor: "BankingAccountType.ParameterDesc"
			},
			{
				Header: "Currency",
				accessor: "Currency.Code"
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Full Wallet Number",
				accessor: "IBAN"
			},
			{
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Pending Balance",
				accessor: "PendingBalance",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Total Balance",
				accessor: "TotalBalance",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Wallet Status",
				accessor: "AccountStatus.ParameterDesc"
			},
			{
				Header: "Minimum Balance",
				accessor: "MinimumBalance",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			}
		];
	}

	componentDidMount() { 
		 this.getappLeftTitle = this.props.getAppLeftTitle;
		 this.getappCenterTitle = this.props.getAppCenterTitle;
       this.getMenuCode=this.props.MenuCode;
	}

	HandleClear = () => {
		this.setState({ isEdit: false, rowIndex: -1 });
		this.props.handleClear();
	}

	Validate = () => {
		const { model } = this.props;
		const { vModel } = this.state;
		var messages = [];

		var IsFromMyAccount = model.AutoLoadType ? model.AutoLoadType.ParameterValue === WalletAutoLoadType.MyAccounts : null;
		var IsFromEFT = model.AutoLoadType ? model.AutoLoadType.ParameterValue === WalletAutoLoadType.EFT : null;
		var IsFromETransfer = model.AutoLoadType ? model.AutoLoadType.ParameterValue === WalletAutoLoadType.eTransfer : null;

		vModel.ReportTypeId = model.ReportTypeId == null;
		if (vModel.ReportTypeId) messages.push("Report Type must be select");

		vModel.BankingAccountTypeId = model.BankingAccountTypeId == null;
		if (vModel.BankingAccountTypeId) messages.push("Wallet Type must be select");

		vModel.AccountName = model.AccountName == null || model.AccountName == "";
		if (vModel.AccountName) messages.push("Wallet Name must be select");

		vModel.Currency = model.Currency == null;
		if (vModel.Currency) messages.push("Wallet Currency must be select");

		vModel.AutoLoadTypeId = model.HasAutoLoad && model.AutoLoadTypeId == null;
		if (vModel.AutoLoadTypeId) messages.push("Load From must be select");

		vModel.BeneficiaryId = model.HasAutoLoad && IsFromEFT && model.BeneficiaryId == null;
		if (vModel.BeneficiaryId) messages.push("Wallet must be select");

		vModel.ClientCustomerPayeeId = model.HasAutoLoad && IsFromETransfer && model.ClientCustomerPayeeId == null;
		if (vModel.ClientCustomerPayeeId) messages.push("Payee must be select");

		vModel.SourceAccountId = model.HasAutoLoad && IsFromMyAccount && model.SourceAccountId == null;
		if (vModel.SourceAccountId) messages.push("Wallet must be select");

		vModel.MinimumBalance = model.HasAutoLoad && (model.MinimumBalance == null || model.MinimumBalance == 0);
		if (vModel.MinimumBalance) messages.push("Minimum Balance cannot be empty");

		vModel.Amount = model.HasAutoLoad && (model.Amount == null || model.Amount == 0);
		if (vModel.Amount) messages.push("Transaction Amount cannot be empty");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	AddAccount = () => {
		const { tempAccount, isEdit } = this.state;

		if (!this.Validate()) {
			return;
		}

		var index = this.props.list.indexOf(tempAccount);
		this.props.handleAdd(index, isEdit);
	}

	DeleteAccount = () => {
		const { list } = this.props;
		var index = list.indexOf(this.state.tempAccount);
		this.props.handleDelete(index);
		this.setState({ isEdit: false });
	}

	RowSelected = (index) => {
		this.setState({ isEdit: true, tempAccount: this.props.list[index], rowIndex: index });
		this.props.selectedRowChange(index);
	}

	AddNewPayee = (payee) => {
		let { hasChangedPayee } = this.state;
		if (payee != null && payee.IsRecordValid) {
			this.props.handleChange("ClientCustomerPayeeId", payee.Id, payee);
		} else {
			this.props.handleChange("ClientCustomerPayeeId", -1, null);
		}
		this.setState({ hasChangedPayee: !hasChangedPayee });
	}

	PayeePopUpCallback = () => { this.setState({ IsPayeePopUpOpen: !this.state.IsPayeePopUpOpen }); }

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	render() {
		const { vModel, isEdit, IsPayeePopUpOpen } = this.state;
		const { model, beneficiaryList, list, ReadOnly, Disabled } = this.props;

		var IsFromMyAccount = model.AutoLoadType ? model.AutoLoadType.ParameterValue === WalletAutoLoadType.MyAccounts : null;
		var IsFromEFT = model.AutoLoadType ? model.AutoLoadType.ParameterValue === WalletAutoLoadType.EFT : null;
		var IsFromETransfer = model.AutoLoadType ? model.AutoLoadType.ParameterValue === WalletAutoLoadType.eTransfer : null;

		return (
			<>

				<GenericDialog open={IsPayeePopUpOpen} onBackdropClick={this.PayeePopUpCallback} maxWidth="lg" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Add New Payee" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<AddNewPayee
							key={"ClientCustomerPayeeId_" + this.props.ClientCustomerId}
							FillCallback={this.AddNewPayee}
							ClientCustomerId={this.props.ClientCustomerId}
							IsClientCustomerPayee={true}
							ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
							Cancel={this.PayeePopUpCallback} 
							getAppLeftTitle={this.getappLeftTitle }
							getAppCenterTitle={ this.getappCenterTitle}
							getMenuCode={ this.props.getMenuCode}
							/>
					</DialogContent>
				</GenericDialog>

				<GenericExpansionPanel Title="Wallet Accounts">
					<GridContainer>
						<GridItem xs={3}>
							<ParameterComponent
								Name="ReportTypeId"
								LabelText="Report Type"
								ParameterCode="WalletAccountsReportType"
								Value={model.ReportTypeId}
								ValueChanged={this.props.handleChange} 
								IsInvalid={vModel.ReportTypeId} />
						</GridItem>
						<GridItem xs={3}>
							<GenericSelectInput
								Name="BankingAccountTypeId"
								LabelText="Wallet Type"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.parameterAccountType}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model.BankingAccountTypeId}
								ValueChanged={this.props.handleChange}
								Required
								IsInvalid={vModel.BankingAccountTypeId} />
						</GridItem>
						<GridItem xs={2}>
							<GenericTextInput
								Name="AccountName"
								LabelText="Name"
								LabelMd={3}
								Value={model.AccountName}
								ValueChanged={this.props.handleChange}
								Required
								IsInvalid={vModel.AccountName} />
						</GridItem>
						<GridItem xs={3}>
							<GenericSelectInput
								Name="AccountCurrency"
								LabelText="Currency"
								Value={model.Currency == null ? model.CurrencyId : model.Currency.Id}
								ValueChanged={this.props.handleChange}
								KeyValueMember="Id"
								TextValueMember="Description"
								Method="GET"
								Url="/bankapi/v1.0/Currency/ValidCurrencies"
								RenderItem={d => d.Code + " - " + d.Description}
								DataRoot="Item"
								Required
								IsInvalid={vModel.Currency} />
						</GridItem>
						<GridItem xs={1}>
							<GenericCheckInput
								Name="HasAutoLoad"
								LabelText="Auto Load"
								Value={model.HasAutoLoad || false}
								ValueChanged={this.props.handleChange} />
						</GridItem>
						{model.HasAutoLoad &&
							<GridItem xs={3}>
								<GenericSelectInput
									Name="AutoLoadTypeId"
									LabelText="Load From"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterAutoLoadType}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={model.AutoLoadTypeId}
									ValueChanged={this.props.handleChange}
									Required={model.HasAutoLoad}
									IsInvalid={vModel.AutoLoadTypeId} />
							</GridItem>
						}
						{model.HasAutoLoad && IsFromEFT &&
							<GridItem xs={3}>
								<GenericSelectInput
									key={"BeneficiaryId_" + [this.props.ClientCustomerId, model.AutoLoadType]}
									Name="BeneficiaryId"
									LabelText="Wallet"
									LabelMd={2}
									IsStatic={true}
									StaticData={beneficiaryList}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={this.RenderItemAccount}
									Value={model.BeneficiaryId}
									ValueChanged={this.props.handleChange}
									Required={model.HasAutoLoad && IsFromEFT}
									IsInvalid={vModel.BeneficiaryId} />
							</GridItem>
						}
						{model.HasAutoLoad && IsFromETransfer &&
							<>
								<GridItem xs={2}>
									<GenericSelectInput
										key={"ClientCustomerPayeeId_" + [this.props.ClientCustomerId, model.AutoLoadType] + this.state.hasChangedPayee}
										Name="ClientCustomerPayeeId"
										LabelText="Payee"
										LabelMd={3}
										Method="POST"
										Url="/bankapi/v1.0/ClientCustomerPayee/Search"
										Parameter={this.parameterClientCustomerPayee}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="AliasName"
										Value={model.ClientCustomerPayeeId}
										ValueChanged={this.props.handleChange}
										Required={model.HasAutoLoad && IsFromETransfer}
										IsInvalid={vModel.ClientCustomerPayeeId} />
								</GridItem>
								<GridItem xs={1} style={{ marginTop: 2 }}>
									<GenericCheckInput
										LabelMd={10}
										LabelText="Add New"
										Value={IsPayeePopUpOpen}
										ValueChanged={this.PayeePopUpCallback} />
								</GridItem>
							</>
						}
						{model.HasAutoLoad && IsFromMyAccount &&
							<GridItem xs={3}>
								<GenericSelectInput
									Name="SourceAccountId"
									LabelText="Wallet"
									LabelMd={2}
									Method="GET"
									Url={"/bankapi/v1.0/Account/GetByCustomerId/Id?Id=" + this.props.UniqueClientId}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={this.RenderItemAccount}
									Value={model.SourceAccountId}
									ValueChanged={this.props.handleChange}
									Required={model.HasAutoLoad && IsFromMyAccount}
									IsInvalid={vModel.SourceAccountId} />
							</GridItem>
						}
						{model.HasAutoLoad &&
							<GridItem xs={3}>
								<GenericNumberInput
									Name="MinimumBalance"
									LabelText="Minimum Balance"
									Value={model.MinimumBalance}
									ValueChanged={this.props.handleChange}
									Prefix="$"
									MaxLength={12}
									Required={model.HasAutoLoad}
									IsInvalid={vModel.MinimumBalance} />
							</GridItem>
						}
						{model.HasAutoLoad &&
							<GridItem xs={2}>
								<GenericNumberInput
									Name="Amount"
									LabelText="Amount"
									LabelMd={3}
									Value={model.Amount}
									ValueChanged={this.props.handleChange}
									Prefix="$"
									MaxLength={12}
									Required={model.HasAutoLoad}
									IsInvalid={vModel.Amount} />
							</GridItem>
						}
						<GridItem xs={8}></GridItem>
						<GridItem xs={4}>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button size="sm" onClick={this.AddAccount} disabled={ReadOnly}>{isEdit ? "EDIT" : "ADD"}</Button>
									<Button size="sm" onClick={this.DeleteAccount} disabled={!isEdit || ReadOnly}>DELETE</Button>
									<Button size="sm" onClick={this.HandleClear} disabled={ReadOnly}>CLEAR</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12}>
							<br />
							<GenericGrid
								Data={list.filter(x => x.IsRecordValid)}
								Columns={this.Columns}
								RowSelected={this.RowSelected}
								SelectedIndex={this.state.rowIndex}
								HideButton={true} />
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>

			</>
		);
	}
}

WalletAccount.propTypes = {
	classes: PropTypes.object.isRequired,
	beneficiaryList: PropTypes.array,
	list: PropTypes.array,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleClear: PropTypes.func,
	handleAdd: PropTypes.func,
	handleDelete: PropTypes.func,
	selectedRowChange: PropTypes.func,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool,
	getAppLeftTitle:PropTypes.string,
	getAppCenterTitle:PropTypes.string,
	getMenuCode:PropTypes.string,
};

export default withArtifex(WalletAccount, {});