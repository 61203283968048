import defaultImage from "assets/img/logo-white.svg";
import createReportStyle from "assets/jss/material-dashboard-pro-react/views/createReportStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import DualListBox from "react-dual-listbox";
import { GenericAlert, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
	cardBodyColor: {
		backgroundColor: "darkblue"
	},
	picture: {
		width: "100px",
		height: "100px",
		overflow: "hidden",
		transition: "all 0.2s",
	},
	pictureSrc: {
		width: "100%",
		height: "100%",
		objectFit: "contain"
	}
});

class AnnouncementDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = { Id: 0, CriteriaList: [], ProgramTypeId: -1 };

		this.defaultFileModel = {
			FileName: "",
			File: null
		};

		this.state = {
			bankCustomerListOption: [],
			bankCustomerListSelected: [],
			userListOption: [],
			userListSelected: [],
			fileModel: this.defaultFileModel,
			userList: [],
			model: this.initialModel,
			isLoading: false,
			imagePreviewUrl: defaultImage
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.fileInputRef = React.createRef();

		this.parameterClient = {};

		this.maxLength250 = { maxLength: 250 };

		this.sortedDescription = { Member: "Description" };
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Announcement Definition");
		this.props.setAppCenterTitle("Notifications");
		this.HandleGetMerchantList();
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };

		if (name === "ProgramTypeId" && value == null)
			value = -1;

		model[name] = value;

		this.setState({ model }, () => {
			if (name === "ProgramTypeId") this.HandleGetMerchantList();
		});
	}

	ColumnListBoxOnChange = (selected) => {
		const { userList } = this.state;
		this.setState({
			bankCustomerListSelected: selected,
			userListOption: userList.filter(u => u.UniqueClientId == selected.find(s => s == u.UniqueClientId.toString()))
		});
	}

	ColumnListBoxOnChange2 = (selected) => {
		this.setState({
			userListSelected: selected
		});
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			return state;
		});
		this.HandleGetMerchantList();
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
	}

	UploadClick = () => {
		this.fileInputRef.current.click();
	}

	FileSelect = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		var file = e.target.files[0];

		if (!file) return;

		reader.onloadend = () => {
			this.setState({
				fileModel: {
					FileName: file.name,
					File: file
				},
				imagePreviewUrl: reader.result
			});
		};
		reader.readAsDataURL(file);
	}

	HandleGetMerchantList = () => {
		const { model } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerAnnouncement/GetAllBankCustomer",
			{
				ProgramId: model.ProgramTypeId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ bankCustomerListOption: responseData.Item.BankCustomerList, userList: responseData.Item.UserList });
				} else {
					this.setState({ bankCustomerListOption: [], userListOption: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		const { fileModel, model, bankCustomerListOption, bankCustomerListSelected, userListSelected, userList } = this.state;

		var BankCustomerList = bankCustomerListOption.filter(u => bankCustomerListSelected.find(s => s == u.UniqueClientId));
		var UserList = userList.filter(u => userListSelected.find(s => s == u.Id));

		model.CriteriaList = {
			BankCustomerList,
			UserList
		};

		if (!this.Validate())
			return;

		model.IsValidForAllCustomer = bankCustomerListOption.length === bankCustomerListSelected.length;
		model.IsValidForAllUser = userList.length === userListSelected.length;
		this.setState({ model, isLoading: true });

		var formData = new FormData();
		formData.append("file", fileModel.File, fileModel.FileName);
		formData.append("Item", JSON.stringify(this.state.model));

		Proxy.FileUpload("/bankapi/v1.0/CustomerAnnouncement/InsertOrUpdate",
			formData,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item) {
					this.ShowMessage("success", "Success", "Message is send successfully!");
					this.HandleClear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while uploading image", error.message);
			}
		);
	}

	Validate = () => {
		const { model, fileModel } = this.state;

		if (!fileModel.File) {
			this.ShowMessage("warning", "File not selected", "Please Upload the Image.");
			return false;
		}

		if (!model.Message) {
			this.ShowMessage("warning", "Message not selected", "Select Message to continue.");
			return false;
		}

		if (!model.ProgramTypeId) {
			this.ShowMessage("warning", "Program not selected", "Select Program to continue.");
			return false;
		}

		if (model.CriteriaList.UserList.length == 0) {
			this.ShowMessage("warning", "User not selected", "Select at least one user to continue.");
			return false;
		}

		return true;
	}

	ShowMessage = (type = "info", title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { classes } = this.props;
		const { alert, isLoading, model, fileModel, bankCustomerListOption, bankCustomerListSelected, userListOption, userListSelected } = this.state;
		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<GridContainer>
							<GridItem xs={4}>
								<Card className="no-radius" style={{ height: "90%" }}>
									<CardHeader>
										<GenericTitle text="Add Icon Or Picture From PC" />
									</CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={3}>
														<Button onClick={this.UploadClick} fullWidth>
															Browse
														</Button>
														<input key={fileModel.FileName} type="file" style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="image/*" />
													</GridItem>
													<GridItem xs={9}>
														<GenericSelectInput
															Name="ProgramTypeId"
															LabelText="Program"
															Method="GET"
															Url="/bankapi/v1.0/Bank/ValidBankProgramsNotification"
															DataRoot="Item"
															KeyValueMember="Id"
															TextValueMember="Description"
															Value={model.ProgramTypeId}
															ValueChanged={this.HandleChange}
															All={true}
															Sorted={this.sortedDescription} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={8}>
								<Card className="no-radius" style={{ height: "90%" }}>
									<CardHeader>
										<GenericTitle text="Add Your Message" />
									</CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={12}>
												<GenericTextInput
													inputProps={this.maxLength250}
													Name="Message"
													LabelText="Message"
													Value={model.Message}
													ValueChanged={this.HandleChange}
													MultiLine={true}
													RowCount={2} />
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs>
										<Card style={{ width: "115px", height: "115px" }} className={classes.cardBodyColor}>
											<CardBody>
												<GridContainer>
													<GridItem >
														<div>
															<div className={classes.picture}>
																<img
																	src={this.state.imagePreviewUrl}
																	className={classes.pictureSrc}
																	alt={fileModel.File ? fileModel.FileName : "..."}
																/>
															</div>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</GridItem>
									<GridItem xs={9}>
										<br />
										<div style={{ height: "99px", overflow: "hidden" }}>
											{model.Message && model.Message.split(/\r\n|\r|\n/g).map((prop, key) => {
												return (
													<p key={key}>{prop}</p>
												);
											})}
										</div>
										<br />
									</GridItem>
									<GridItem xs={1}>
										<GridContainer justify="flex-end">
											<GridItem xs={12}>
												<Button size="sm" onClick={this.HandleSubmit} fullWidth>
													Send
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Client Selection" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<DualListBox
											canFilter
											className={classes.root}
											options={bankCustomerListOption.map(i => {
												return {
													"value": i.UniqueClientId.toString(),
													"label": i.FullName
												};
											})}
											selected={bankCustomerListSelected}
											onChange={(selected) => this.ColumnListBoxOnChange(selected)}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="User Selection" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<DualListBox
											canFilter
											className={classes.root}
											options={userListOption.map(i => {
												return {
													"value": i.Id.toString(),
													"label": i.FullName
												};
											})}
											selected={userListSelected}
											onChange={(selected) => this.ColumnListBoxOnChange2(selected)}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

AnnouncementDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(AnnouncementDefinition, { ...styles, ...createReportStyle });