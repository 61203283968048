import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { LimitProfileType, GridColumnType } from "views/Constants/Constant";

class UserLimitProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			ProfileModel: {},
			LimitModelList: [],
			LimitPropList: [],
			LimitProfiles: [],
			isLoading: false
		};

		this.ClearData = this.ClearData.bind(this);
		this.DeleteRecord = this.DeleteRecord.bind(this);
		this.UpdateRecord = this.UpdateRecord.bind(this);
		this.FetchData = this.FetchData.bind(this);
		this.errorDefinitionCallback = this.errorDefinitionCallback.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.Validate = this.Validate.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("User Limit Profile");
		if (setAppCenterTitle)
			setAppCenterTitle("LIMIT PROFILES");

		this.FetchPropFromParameter();
		this.FetchAllLimitProfile();
	}

	FetchPropFromParameter = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "LimitType",
				ParameterValue: "",
				ParameterDesc: "",
				ParameterValue2: "USERLIMIT"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ LimitPropList: responseData.Item || [] });
				}

				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	FetchAllLimitProfile = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/LimitProfile/GetAll",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ LimitProfiles: responseData.Item.filter(x => x.LimitProfileType != null && x.LimitProfileType.ParameterValue == LimitProfileType.UserLimitProfile) || [] });
				}

				this.setState({ isLoading: false });
			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });
			}
		);
	}

	Validate() {
		var ErrorList = [];
		const dataLimit = this.state.LimitModelList;
		const propsLimit = this.state.LimitPropList;
		const profile = { ...this.state.ProfileModel };
		const vModel = this.state.vModel;

		if (profile.ProgramId == null || profile.ProgramId == "") {
			vModel.ProgramId = true;
			ErrorList.push("Program must be filled");
		} else { vModel.ProgramId = false; }
		if (profile.ProfileName == null || profile.ProfileName == "") {
			vModel.ProfileName = true;
			ErrorList.push("Profile Name must be filled");
		} else { vModel.ProfileName = false; }
		if (profile.ValidFrom == null || profile.ValidFrom == "") {
			vModel.ValidFrom = true;
			ErrorList.push("Valid From data must be filled");
		} else { vModel.ValidFrom = false; }
		if (profile.ValidTo == null || profile.ValidTo == "") {
			vModel.ValidTo = true;
			ErrorList.push("Valid To date must be filled");
		} else { vModel.ValidTo = false; }
		this.setState({ vModel });
		// propsLimit.forEach(element => {
		// 	const temp = dataLimit.find(x => x.LimitType != null && x.LimitType.ParameterValue == element.ParameterValue);
		// 	if (temp == null || temp.LimitAmount == null || temp.LimitAmount == "") {
		// 		if (element.ParameterValue6 != "O") {
		// 			if (element.ParameterValue5 != "C") {
		// 				ErrorList.push("Limit Amount not defined for " + element.ParameterValue3);
		// 			}
		// 		}
		// 	}
		// 	 if	(temp == null || temp.LimitCount == null || temp.LimitCount == ""){
		// 	 	ErrorList.push("Limit Count not defined for " + element.ParameterValue3);
		// 	 }
		// });

		var interval = moment.duration(DateHelper.ToMoment(profile.ValidTo).diff(DateHelper.GetDate())).asDays();
		if (interval < 30) {
			ErrorList.push("Limit profile must be valid In next 30 days ! (Check Valid Date)");
		}


		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	OperationCheck = (workflowId, after) => {
		if (this.Validate())
			this.UpdateRecord(workflowId, after);
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	handleListItemChangeLimit = (name, newValue, code) => {
		const array = this.state.LimitModelList;
		const item = array.find(x => x.LimitType != null && x.LimitType.ParameterValue == code);
		if (item == null) {
			const type = { ParameterValue: code };
			const temp = { Id: 0, LimitProfileId: this.state.ProfileModel.Id, LimitType: type };
			temp[name] = newValue;
			array.push(temp);
		} else {
			item[name] = newValue;
		}

		this.setState({ LimitModelList: array });
	}
	DeleteRecord(workflowId, after) {
		const header = { ...this.state.ProfileModel };
		if (header != null && header.Id != 0 && header.Id != null && header.Id >= 0) {
			this.setState({ isLoading: true });
			Proxy.GET(
				"/bankapi/v1.0/LimitProfile/Delete/Id?Id=" + header.Id,
				responseData => this.handleDelete(responseData, after),
				this.errorDefinitionCallback,
				workflowId
			);
		} else {
			this.ShowMessage("info", "Invalid", "Please select profile first !");
		}

	}
	UpdateRecord(workflowId, after) {
		const request = {};
		request.Profile = this.state.ProfileModel;
		request.Limit = this.state.LimitModelList;

		this.setState({ isLoading: true });
		request.Profile.LimitProfileType = { ParameterValue: LimitProfileType.UserLimitProfile };
		Proxy.POST(
			"/bankapi/v1.0/LimitProfileDetail/Update",
			request,
			responseData => this.handleUpdate(responseData, after),
			this.errorDefinitionCallback,
			workflowId
		);

	}
	FetchData(Id) {
		if (Id != null && Id != 0) {
			this.setState({ isLoading: true });
			Proxy.GET(
				"/bankapi/v1.0/LimitProfile/GetById/Id?Id=" + Id,
				this.handleResponse,
				this.errorDefinitionCallback
			);
		}
	}
	SelectedRowChange = (e, index) => {
		const model = this.state.LimitProfiles[index];
		this.FetchData(model.Id);
	}
	handleResponse(responseData) {
		this.setState({ isLoading: false });
		console.log("getall", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			if (responseData.Item != null) {
				this.setState({
					LimitModelList: responseData.Item.Limit || [],
					ProfileModel: responseData.Item.Profile || {}
				});
			}
		}
	}
	handleUpdate(responseData, after) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		}

		this.ClearData();
		// if (responseData.Item != null) {
		// 	this.setState({
		// 		LimitModelList: responseData.Item.Limit || [],
		// 		ProfileModel: responseData.Item.Profile || {}
		// 	});
		// }
		if (after) after();
		this.ShowMessage("success", "Success", "Changes Saved Sucesfully.");
		this.FetchAllLimitProfile();
	}

	handleDelete(responseData, after) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		}
		if (after) after();
		this.ShowMessage("success", "Success", "Deleted !");
		this.ClearData();
		this.FetchAllLimitProfile();
	}
	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
		this.setState({ isLoading: false });
	}
	handleChangeProfile = (name, newValue) => {
		const model = { ...this.state.ProfileModel };
		model[name] = newValue;
		this.setState({ ProfileModel: model });
	}
	ClearData() {
		var temp = { Id: 0 };
		this.setState({
			LimitModelList: [],
			ProfileModel: temp,
			selected: null,
			isLoading: false
		});
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	showConfirmMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.UpdateRecord()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	GetColumnsRowStyleColor(row) {
		if(!row){
			return;
		}

		let localDateUtc = DateHelper.GetLocalDateTimeAsUtc();
		let condition = localDateUtc >= DateHelper.ToMoment(row.ValidFrom) && 
						    localDateUtc <= DateHelper.ToMoment(row.ValidTo);


		this.columnsRowStyleColor = {
			color: !condition
				? "#FB3005"
				: ""
		};
		return this.columnsRowStyleColor;
	}

	render() {
		const { LimitModelList, LimitPropList, ProfileModel, LimitProfiles, vModel } = this.state;
		const { Disabled } = this.props;

		var data = LimitProfiles.map(d => {
			return {
				Id: d.Id,
				ProfileName: d.ProfileName || "",
				ProgramId: d.ProgramId || "",
				Program: d.Program == null ? "" : d.Program.Description || "",
				ValidFrom: d.ValidFrom || "",
				ValidTo: d.ValidTo || "",
				Actions: (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={Disabled}
							OnClick={() => this.FetchData(d.Id)} />
					</div>
				)
			};
		});
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{
						RowId: this.state.ProfileModel.Id,
						Code: "Delete", OnClick: (workflowId, after) => this.DeleteRecord(workflowId, after), Disabled: Disabled || !ProfileModel.Id > 0,
						ModelFunction: () => { return { Profile: this.state.ProfileModel, Limit: this.state.LimitModelList } },
						FillDataFromModel: model => {
							this.FetchPropFromParameter();
							this.setState({ ProfileModel: model.Profile, LimitModelList: model.Limit })
						}
					},
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{
						RowId: this.state.ProfileModel.Id,
						Code: "Submit", OnClick: (workflowId, after) => this.OperationCheck(workflowId, after), Disabled: Disabled || ProfileModel.Id > 0,
						ModelFunction: () => { return { Profile: this.state.ProfileModel, Limit: this.state.LimitModelList } },
						FillDataFromModel: model => {
							this.FetchPropFromParameter();
							this.setState({ ProfileModel: model.Profile, LimitModelList: model.Limit })
						},
						ValidationFunction: this.Validate
					},
					{
						RowId: this.state.ProfileModel.Id,
						Code: "Update", OnClick: (workflowId, after) => this.OperationCheck(workflowId, after), Disabled: Disabled || !ProfileModel.Id > 0,
						ModelFunction: () => { return { Profile: this.state.ProfileModel, Limit: this.state.LimitModelList } },
						FillDataFromModel: model => {
							this.FetchPropFromParameter();
							this.setState({ ProfileModel: model.Profile, LimitModelList: model.Limit })
						},
						ValidationFunction: this.Validate
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Profile Information"}>
						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={3}>
									<GenericTextInput Name="ProfileName" LabelText="Profile Name" Value={ProfileModel.ProfileName} ValueChanged={this.handleChangeProfile} Disabled={Disabled} Required
										IsInvalid={vModel.ProfileName} />
									<GenericDateInput Utc Name="ValidFrom" LabelText="Valid From" Value={ProfileModel.ValidFrom ? Formatter.FormatDateUtc(ProfileModel.ValidFrom) : null} ValueChanged={this.handleChangeProfile} Disabled={Disabled} Required
										IsInvalid={vModel.ValidFrom} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput Disabled={ProfileModel.Id > 0 ? true : Disabled} Name="ProgramId" LabelText="Program" Value={ProfileModel.ProgramId} ValueChanged={this.handleChangeProfile} KeyValueMember="Id" TextValueMember="Description" Method="GET" Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile" DataRoot="Item" Required
										IsInvalid={vModel.ProgramId} />
									<GenericDateInput Utc IsFuture={true} MinDate={ProfileModel.ValidFrom ? DateHelper.ToMoment(ProfileModel.ValidFrom) : null} Name="ValidTo" LabelText="Valid To" Value={ProfileModel.ValidTo ? Formatter.FormatDateUtc(ProfileModel.ValidTo) : null} ValueChanged={this.handleChangeProfile} Disabled={Disabled} Required
										IsInvalid={vModel.ValidTo} />
								</GridItem>
								<GridItem xs={3}>
								</GridItem>
								<GridItem xs={3}>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius" style={{ height: "90%" }}>
					<GenericExpansionPanel Title={"Limit Profile Definition"}>
						<CardBody>
							<GridContainer>
								{LimitPropList != null && LimitPropList.map(item => (
									(item.ParameterValue5 != null && item.ParameterValue5 == "C") ? (
										< GridItem xs={6} key={item.Id} >
											<GridContainer>
												<GridItem xs={8} style={{ paddingLeft: "9px", marginTop: "10px" }}>
													<GenericLabel Text={item.ParameterValue3}></GenericLabel>
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput MaxLength={8} Name="LimitCount" LabelText="Count" DecimalScale={0}
														Value={LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue) == null ? "" : LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue).LimitCount}
														ValueChanged={(name, value) => this.handleListItemChangeLimit(name, value, item.ParameterValue)}
														Disabled={Disabled}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									) :
										(
											< GridItem xs={6} key={item.Id} >
												<GridContainer>
													<GridItem xs={8}>
														<GenericNumberInput MaxLength={8} LabelMd={8} Name="LimitAmount" LabelText={item.ParameterValue3}
															Value={LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue) == null ? "" : LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue).LimitAmount}
															ValueChanged={(name, value) => this.handleListItemChangeLimit(name, value, item.ParameterValue)}
															Disabled={Disabled}
														/>
													</GridItem>
													<GridItem xs={4}>
														<GenericNumberInput MaxLength={8} Name="LimitCount" LabelText="Count" DecimalScale={0}
															Value={LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue) == null ? "" : LimitModelList.find(x => x.LimitType != null && x.LimitType.ParameterValue == item.ParameterValue).LimitCount}
															ValueChanged={(name, value) => this.handleListItemChangeLimit(name, value, item.ParameterValue)}
															Disabled={Disabled}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										)

								))}
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius" style={{ display: Disabled ? "none" : "normal" }}>
					<CardHeader>
						<GenericTitle text={"Defined Profiles"} />
					</CardHeader>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={data}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: 60,
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Profile Name",
											accessor: "ProfileName",
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Program",
											accessor: "Program",
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Valid From Date",
											accessor: "ValidFrom",
											type: GridColumnType.DateUtc,
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{Formatter.FormatDateUtc(row.value)}</div>),
											filterMethod: (filter, row, column) => {
												return Formatter.FormatDateUtc(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
											}
											
										},
										{
											Header: "Valid To Date",
											accessor: "ValidTo",
											type: GridColumnType.DateUtc,
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{Formatter.FormatDateUtc(row.value)}</div>),
											filterMethod: (filter, row, column) => {
												return Formatter.FormatDateUtc(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
											}
										}
									]}
									defaultPageSize={5}
									RowSelected={index => {
										if (Disabled) return;
										this.setState({ selected: index });
										this.SelectedRowChange(null, index);
									}}
									SelectedIndex={this.state.selected}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

UserLimitProfile.propTypes = {
	classes: PropTypes.object,
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool
};


export default withArtifex(UserLimitProfile, {});