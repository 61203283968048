import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import AddNewPayee from "views/Components/AddNewPayee";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import RouteHelper from "core/RouteHelper";
import { ProgramCodes, TransactionType, EtransferTransactionTypes, MenuCodes } from "views/Constants/Constant.js";
import ParameterComponent from "views/Components/ParameterComponent";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const ClientParamObj = {
	ProgramCode: ProgramCodes.Bank_Programs_Bank_Account,
	UniqueClientId: undefined
};

class EtransferFromAccount extends React.Component {
	constructor(props) {
		super(props);
		this.defaultModel = {
			DateOfFunds: DateHelper.GetLocalDateTimeAsUtc(),
			MoneyRequestExpiryDate: null,
			UniqueClientId: ClientHelper.GetClientRowId(),
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.state = {
			model: { ...this.defaultModel },
			IsUpdate: false,
			vModel: {},
			isLoading: false,
			isConfirmDialogOpen: false,
			hasChangedPayee: false,
			reloadData: false,
			IsPayeePopUpOpen: false,
			InteracCustomerGetTransferOptions: false,
			maxExpireDate: 30,
			LimitToken: false,
			isLOSMenuActive: false,
		};

		this.maxLength400 = { maxLength: 400 };
	}


	RenderClientSelect(d) {
		return `${d.Name} - ${d.IBAN}`;
	}

	componentDidMount() {
		//this.props.hideSidebar();
		var IsClient = ClientHelper.IsClient();
		this.setState({ IsClient });
		this.GetMaxExpireDate();
		if (this.props.MenuCode == MenuCodes.Menu_LmsETransfer) {
			this.props.setAppLeftTitle("e-Transfer");this.getappLeftTitle = "e-Transfer";
			this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");this.getappCenterTitle = "LOAN MANAGEMENT SYSTEM";
			this.GetTransactionTypeCreditId();
			this.setState({ isLOSMenuActive: true });
			var AccountId = RouteHelper.ReadAndClear("ETransferLos_AccountId");
			if (AccountId > 0) this.GetEtransferForLosInfo(AccountId);
		} else {
			this.props.setAppLeftTitle("e-Transfer");this.getappLeftTitle = "e-Transfer";
			this.props.setAppCenterTitle("WALLET");this.getappCenterTitle = "WALLET";
			var Id = RouteHelper.ReadAndClear("CreateEtransfer_Id");
			if (Id) {
				this.setState({ isLoading: true });
				Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetByIdRequest",
					{ Item: Id },
					responseData => {
						this.setState({ isLoading: false });

						if (!responseData.IsSucceeded) {
							this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
							return;
						}

						var etransferData = responseData.Item;
						var data = etransferData.Transaction;

						this.setState(function (state) {
							var model = state.model || {};
							var { reloadData, hasChangedPayee } = this.state;
							model.UniqueClientId = data.UniqueClientId;

							model.Id = data.Id;
							model.SourceAccountId = etransferData.SourceAccountId;
							model.PayeeId = etransferData.PayeeId;
							model.ReceiverMail = etransferData.PayeeEmail;
							model.Description = etransferData.Memo;
							model.TransactionType = data.TransactionTypeId;
							model.TransactionAmount = data.TransactionAmount;
							model.DateOfFunds = data.TransactionDate ? DateHelper.GetLocalDateTimeAsUtc() : null;
							model.MoneyRequestExpiryDate = etransferData.MoneyRequestExpiryDate ? DateHelper.GetLocalDateTimeAsUtc() : null;
							model.SecurityQuestionId = etransferData.SecurityQuestionId;
							model.IsHaveQuestion = etransferData.SecurityQuestionId != null && etransferData.SecurityQuestionId > 0;
							model.SecurityQuestionText = etransferData.SecurityQuestion;
							model.SecurityQuestionAnswer = etransferData.SecurityQuestionAnswer;
							model.ReceiverName = etransferData.PayeeName;
							model.TransactionTypeId = data.TransactionTypeId;
							model.TransactionTypeStr = data.TransactionType ? data.TransactionType.ParameterValue : "";
							model.NotificationTypeId = etransferData.NotificationTypeId;
							model.NotificationType = etransferData.NotificationType;
							model.ServiceType = etransferData.NotificationType != null ? etransferData.NotificationType.ParameterValue3 : "0";
							model.PhoneNumber = etransferData.Payee != null ? etransferData.Payee.Phone != null ? etransferData.Payee.Phone.PhoneNumber : "" : "";

							return { model, IsUpdate: true, reloadData: !reloadData, hasChangedPayee: !hasChangedPayee };
						});

						this.FetchInteracGetTransferOptions(etransferData.PayeeEmail, etransferData.Payee != null ? etransferData.Payee.Phone != null ? etransferData.Payee.Phone.PhoneNumber : "" : "", etransferData.NotificationType != null ? etransferData.NotificationType.ParameterValue3 : "0");
					},
					errorMessage => {
						this.setState({ isLoading: false });
						this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
					}
				);
			}
		}
	}
	GetEtransferForLosInfo = (AccountId) => {
		this.setState({ isLoading: true });

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetEtransferForLosAccountInfo",
			AccountId,
			responseData => {
				this.setState({ isLoading: false, isIndividualDialogOpen: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", "" + responseData.ErrorDescription);
					return;
				}
				this.setState(function (state, props) {
					var model = state.model || {};
					var responseModel = responseData.Item || {};
					model.SourceAccountId = AccountId;
					model.TransactionAmount = responseModel.AccountBalance;
					model.ClientCustomerId = responseModel.ClientCustomerId;
					model.LosDisabled = true;
					var defaultModel = this.defaultModel;
					defaultModel.SourceAccountId = AccountId;
					defaultModel.TransactionAmount = responseModel.AccountBalance;
					defaultModel.ClientCustomerId = responseModel.ClientCustomerId;
					defaultModel.LosDisabled = true;
					return { model };
				});
			},
			e => {
				this.setState({ isLoading: false, isIndividualDialogOpen: false });
				this.ShowMessage("error", "Error", "An error occurred while inserting data ");
			}
		);
	}
	GetTransactionTypeCreditId = () => {
		Proxy.GET("/bankapi/v1.0/Transaction/GetAllFinancialTransactionType",
			responseData => {
				if (!responseData.IsSucceeded) {
					return;
				}
				if (responseData.Item.length != null && responseData.Item.length > 0) {
					var defaultModel = this.defaultModel;
					var { model } = this.state;
					var txnType = responseData.Item.find(x => x.ParameterValue == EtransferTransactionTypes.Credit) || {};
					defaultModel.TransactionTypeId = txnType.Id || 0;
					defaultModel.TransactionTypeStr = EtransferTransactionTypes.Credit;
					model.TransactionTypeId = defaultModel.TransactionTypeId;
					model.TransactionTypeStr = defaultModel.TransactionTypeStr;
					this.setState({ model });
				}
			},
			e => {

			}
		);
	}
	PerformTransaction = () => {
		var ErrorList = [];
		const { model, vModel, isLOSMenuActive } = this.state;

		vModel.PayeeId = model.PayeeId == null || model.PayeeId == "" || (!model.PayeeId > 0);
		if (vModel.PayeeId)
			ErrorList.push("Contact Name must be defined!");
		vModel.ReceiverName = model.ReceiverName == null || model.ReceiverName == "";
		if (vModel.ReceiverName)
			ErrorList.push("Contact Email must be defined!");
		if (isLOSMenuActive) {
			vModel.SourceAccountId = model.SourceAccountId == null || (!model.SourceAccountId > 0);
			if (vModel.SourceAccountId)
				ErrorList.push("Wallet must be defined!");
			vModel.ClientCustomerId = model.ClientCustomerId == null || (!model.ClientCustomerId > 0);
			if (vModel.ClientCustomerId)
				ErrorList.push("Customer must be defined!");
		} else {
			vModel.SourceAccountId = model.SourceAccountId == null || (!model.SourceAccountId > 0);
			if (vModel.SourceAccountId)
				ErrorList.push("From Wallet must be defined!");
		}

		vModel.TransactionAmount = model.TransactionAmount == null || model.TransactionAmount == 0 || model.TransactionAmount <= 0;
		if (vModel.TransactionAmount)
			ErrorList.push("Amount must be defined!");
		vModel.DateOfFunds = model.DateOfFunds == null || model.DateOfFunds == "";
		if (vModel.DateOfFunds)
			ErrorList.push("Date Funds must be defined!");
		vModel.NotificationTypeId = model.NotificationTypeId == null || model.NotificationTypeId == "" || model.NotificationTypeId == 0;
		if (vModel.NotificationTypeId)
			ErrorList.push("Notification must be defined!");

		if (!model || !model.DateOfFunds)
			ErrorList.push("Date Funds cannot be null!");

		else if (typeof model.DateOfFunds != "object") {
			ErrorList.push("Date Funds is invalid!");
		}

		if (vModel.ddrEmail)
			ErrorList.push("Contact contact email cannot be null !");

		if (vModel.ddrPhone)
			ErrorList.push("Contact contact phone number cannot be null !");


		vModel.MoneyRequestExpiryDate = model.MoneyRequestExpiryDate == null || model.MoneyRequestExpiryDate == "";
		if (vModel.MoneyRequestExpiryDate)
			ErrorList.push("Transaction Exp. Date must be defined!");

		if (!model || !model.MoneyRequestExpiryDate)
			ErrorList.push("Transaction Exp. Date cannot be null!");

		else if (typeof model.MoneyRequestExpiryDate != "object") {
			ErrorList.push("Transaction Exp. Date is invalid");
		}


		vModel.TransactionTypeId = model.TransactionTypeId == null || model.TransactionTypeId == "" || model.TransactionTypeId <= 0;
		if (vModel.TransactionTypeId)
			ErrorList.push("Transaction Type must be defined!");

		if (model.TransactionTypeStr == TransactionType.Credit && this.state.InteracCustomerGetTransferOptions == false) {
			if (model.IsHaveQuestion && model.IsHaveQuestion == true) {
				vModel.SecurityQuestionId = model.SecurityQuestionId == null || model.SecurityQuestionId == "" || model.SecurityQuestionId <= 0;
				if (vModel.SecurityQuestion) ErrorList.push("Security Question is not selected.");
				else {
					vModel.SecurityQuestionAnswer = model.SecurityQuestionAnswer == null || model.SecurityQuestionAnswer == "";
					if (vModel.SecurityQuestionAnswer) ErrorList.push("Security Question Answer cannot be null.");
				}
			}
			else {
				vModel.SecurityQuestionText = model.SecurityQuestionText == null || model.SecurityQuestionText == "";
				if (vModel.SecurityQuestionText) ErrorList.push("Security Question is not defined.");
				else {
					vModel.SecurityQuestionAnswer = model.SecurityQuestionAnswer == null || model.SecurityQuestionAnswer == "";
					if (vModel.SecurityQuestionAnswer) ErrorList.push("Security Question Answer cannot be null.");
				}
			}
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	Submit = (workflowId, after) => {
		if (!this.PerformTransaction())
			return;
		this.setState({ isConfirmDialogOpen: true, workflowId, after });
	}

	Update = (workflowId, after) => {
		if (!this.PerformTransaction())
			return;
		this.setState({ isConfirmDialogOpen: true, workflowId, after });
	}

	FetchInteracGetTransferOptions = (eMail, phoneNumber, serviceType) => {
		this.setState({ isLoading: true });
		var request = {};
		var { model, vModel } = this.state;

		if (serviceType == 0) {
			vModel.ddrPhone = false;
			if (eMail == null || eMail == "") {
				vModel.ddrEmail = true;
				this.ShowMessage("error", "Error", "Contact Email cannot be null !");
				return;
			}
			else {
				vModel.ddrEmail = false;
			}
			this.setState({ vModel });
		}

		if (serviceType == 1) {
			vModel.ddrEmail = false;
			if (phoneNumber == null || phoneNumber == "") {
				vModel.ddrPhone = true;
				this.ShowMessage("error", "Error", "Contact Phone Number cannot be null !");
				return;
			}
			else {
				vModel.ddrPhone = false;
			}
			this.setState({ vModel });
		}
		request.UniqueClientId = model.UniqueClientId;
		request.DirectDepositHandle = serviceType == 0 ? eMail : phoneNumber;
		request.ServiceType = serviceType;
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetTransferOptions",
			request,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				model.HasDdr = responseData.Item;
				this.setState({ model, InteracCustomerGetTransferOptions: responseData.Item });
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	GetMaxExpireDate = () => {
		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "InteracEtransferExpireDate",
				ParameterValue: "MX"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while request " + responseData.ErrorDescription);
					return;
				}
				if (responseData.Item.length != null && responseData.Item.length > 0) {
					this.setState({ maxExpireDate: responseData.Item[0].ParameterValue2 });
					var defaultModel = this.defaultModel;
					var { model } = this.state;
					defaultModel.MoneyRequestExpiryDate = DateHelper.GetDateLocal().add((responseData.Item[0].ParameterValue2 || 30) - 1, "d");
					model.MoneyRequestExpiryDate = defaultModel.MoneyRequestExpiryDate;
					this.setState({ model });
				}
			},
			e => {
				this.props.showMessage("error", "Error", "An error occurred while inserting data ");
			}
		);
	}

	SendTransaction = () => {
		// InsertETransferFromAccountOp
		this.setState({ isLoading: true });
		var { model, workflowId, after } = this.state;
		if (this.state.isLOSMenuActive) model.IsClientcustomerTxn = true;

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/InsertETransferFromAccount",
			model,
			responseData => {
				this.setState({ isLoading: false, isIndividualDialogOpen: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", "" + responseData.ErrorDescription);
					return;
				}
				if (after) after();
				this.RefreshToolBar();
				this.ShowMessage("success", "Success", "e-Transfer Transaction Entry Successfully Saved !");
				this.setState({
					model: { ...this.defaultModel },
					EmailValid: false,
					isConfirmDialogOpen: false,
					isLoading: false,
					IsUpdate: false
				});
			},
			e => {
				this.setState({ isLoading: false, isIndividualDialogOpen: false });
				this.ShowMessage("error", "Error", "An error occurred while inserting data ");
			},
			workflowId
		);
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.model || {};

			model[name] = newValue;

			if (name == "PayeeId" && data != null) {
				model.ReceiverName = data ? data.AliasName : "";
				model.ReceiverMail = data ? data.Email : "";
				model.PhoneNumber = data ? data.Phone != null ? data.Phone.PhoneNumber : "" : "";
				if (model.PayeeId != null && model.TransactionTypeStr == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.ReceiverMail, model.PhoneNumber, model.ServiceType);
			}
			if (name == "IsHaveQuestion") {
				if (newValue == true)
					model.SecurityQuestionText = "";
				else
					model.SecurityQuestionId = 0;
			}
			if (name == "SecurityQuestionAnswer") {
				model[name] = newValue.replace(/\s/g, "");
			}
			if (name == "TransactionTypeId" && data != null) {
				model.TransactionTypeStr = data.ParameterValue;
				if (model.TransactionTypeStr == TransactionType.Debit) {
					if (model.SecurityQuestionAnswer != null)
						model.SecurityQuestionAnswer = null;
					if (model.SecurityQuestionText != null)
						model.SecurityQuestionText = null;
					if (model.SecurityQuestionId != null)
						model.SecurityQuestionId = null;
				}
				if (model.PayeeId != null && model.TransactionTypeStr == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.ReceiverMail, model.PhoneNumber, model.ServiceType);
			}

			if (name == "NotificationTypeId" && data != null) {
				model.ServiceType = data.ParameterValue3;

				if (model.PayeeId != null && model.TransactionTypeStr == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.ReceiverMail, model.PhoneNumber, model.ServiceType);
			}
			return { model: model };
		});
	}
	AddNewPayee = (payee) => {
		let { hasChangedPayee } = this.state;
		if (payee != null && payee.IsRecordValid) {
			this.handleChange("PayeeId", payee.Id, payee);
		} else {
			this.handleChange("PayeeId", -1, null);
		}
		this.setState({ hasChangedPayee: !hasChangedPayee });
	}

	PayeePopUpCallback = () => { this.setState({ IsPayeePopUpOpen: !this.state.IsPayeePopUpOpen }); }

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	ClearData = () => {
		this.setState({
			alert: null,
			isLoading: false,
			model: { ...this.defaultModel },
			isConfirmDialogOpen: false,
			IsUpdate: false
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false,
			isConfirmDialogOpen: false
		});
	}

	GetClientParamObj() {
		ClientParamObj.ProgramCode = ProgramCodes.Bank_Programs_Bank_Account;

		ClientParamObj.UniqueClientId = ClientHelper.GetClientRowId() != null ? ClientHelper.GetClientRowId() : 0;

		return ClientParamObj;
	}
	RefreshToolBar() {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	GetModel = () => {
		const { model } = this.state;
		return model;
	}

	FillData = (model) => {
		var tempModel = model;
		tempModel.DateOfFunds = DateHelper.GetLocalDateTimeAsUtc();
		this.setState({ model: tempModel, reloadData: !this.state.reloadData, hasChangedPayee: !this.state.hasChangedPayee });
	}

	GetModelId = () => {
		const { model } = this.state;
		return model.Id ?? 0;
	}
	RenderItemClientCustomer = (d) => {
		return `${d.ClientCustomerNumber} - ${d.Name}`;
	}
	render() {
		const { model, IsPayeePopUpOpen, maxExpireDate, vModel, LimitToken, IsUpdate, IsClient, isLOSMenuActive } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				{(IsClient && !isLOSMenuActive) && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={ProgramCodes.Bank_Programs_e_Transfer} IsUseDisableDebitLimit={false} />}
				<GenericDialog open={IsPayeePopUpOpen} onBackdropClick={this.PayeePopUpCallback} maxWidth="lg" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Add New Contact" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<AddNewPayee
							FillCallback={this.AddNewPayee}
							IsClientCustomerPayee={isLOSMenuActive ? true : false}
							ClientCustomerId={isLOSMenuActive ? model.ClientCustomerId : 0}
							ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
							Cancel={this.PayeePopUpCallback}
							getAppLeftTitle={this.getappLeftTitle}
	                  getAppCenterTitle={this.getappCenterTitle}
	                  getMenuCode={this.props.MenuCode}
							/>
					</DialogContent>
				</GenericDialog>

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Submit", OnClick: () => this.Submit(), Disabled: Disabled || IsUpdate, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: this.GetModelId(), ValidationFunction: this.PerformTransaction },
					{ Code: "Update", OnClick: () => this.Update(), Disabled: Disabled || !IsUpdate, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: this.GetModelId(), ValidationFunction: this.PerformTransaction }

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card>
					{!isLOSMenuActive &&
						<GenericExpansionPanel Title="Select Wallet">
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={4}>
										<GenericSelectInput
											key={this.state.reloadData + ""}
											Name="SourceAccountId"
											Disabled={Disabled || IsUpdate}
											Method="POST"
											Url="/bankapi/v1.0/BankInteracETransfer/GetFundAccounts"
											Parameter={model.UniqueClientId}
											DataRoot="Item"
											LabelText="From Wallet"
											Value={model.SourceAccountId || ""}
											KeyValueMember="Id"
											TextValueMember="AccountName"
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.SourceAccountId}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>}
					{isLOSMenuActive &&
						<GenericExpansionPanel Title="Select Customer Wallet">
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="ClientCustomerId"
											LabelText="Customer"
											Method="POST"
											Url="/bankapi/v1.0/ClientCustomer/SearchNameValue"
											Parameter={{ UniqueClientId: model.UniqueClientId, IsApplicantCustomer: true }}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemClientCustomer}
											Value={model.ClientCustomerId}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.ClientCustomerId}
											Disabled={Disabled || model.LosDisabled}
										/>
									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											key={model.ClientCustomerId + "SourceAccountId"}
											Name="SourceAccountId"
											Method="POST"
											Url="/bankapi/v1.0/BankInteracETransfer/GetCustomerAccounts"
											Parameter={model.ClientCustomerId || 0}
											DataRoot="Item"
											LabelText="Wallet"
											Value={model.SourceAccountId || ""}
											KeyValueMember="Id"
											TextValueMember="AccountName"
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.SourceAccountId}
											Disabled={Disabled || IsUpdate || model.LosDisabled || !model.ClientCustomerId}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>}
				</Card>

				{(!isLOSMenuActive || (isLOSMenuActive && model.ClientCustomerId > 0)) &&
					<Card>
						<GenericExpansionPanel Title="e-Transfer">
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={6}>
										<GridContainer justify="flex-start">
											<GridItem xs={8}>
												<GenericSelectInput
													key={model.ClientCustomerId + "PayeeId_" + this.state.hasChangedPayee}
													Name="PayeeId"
													LabelText="Contact Name"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomerPayee/Search"
													Parameter={{
														IsClientCustomerPayee: isLOSMenuActive ? true : false,
														ProgramCode: ProgramCodes.Bank_Programs_Bank_Account,
														UniqueClientId: model.UniqueClientId,
														ClientCustomerId: model.ClientCustomerId || null,
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="AliasName"
													Value={model.PayeeId}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.PayeeId}
													Disabled={Disabled || IsUpdate} />
												{model.ServiceType == 0 &&
													<GenericEmailInput
														Name="ReceiverMail"
														LabelText="Contact Email"
														Value={model.ReceiverMail}
														Disabled={true}
													/>
												}
												{model.ServiceType == 1 &&
													<GenericTextInput
														LabelMd={4}
														Name="PayeePhone"
														LabelText="Contact Phone"
														Value={model.PhoneNumber}
														Disabled={true} />
												}
												<GenericTextInput
													inputProps={this.maxLength400}
													Name="Description"
													LabelText="Transfer Description"
													Value={model.Description || ""}
													ValueChanged={this.handleChange}
													Disabled={Disabled}
												/>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterValue6"
													Value={model.TransactionTypeId}
													ValueChanged={this.handleChange}
													Disabled={Disabled || IsUpdate || isLOSMenuActive}
													Required
													IsInvalid={vModel.TransactionTypeId} />
												<GenericNumberInput
													Prefix="$ "
													MaxLength={8}
													Name="TransactionAmount"
													LabelText="Amount"
													Value={model.TransactionAmount || ""}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.TransactionAmount}
													Disabled={Disabled}
												/>
												<ParameterComponent
													LabelMd={4}
													Name="NotificationTypeId"
													Value={model.NotificationTypeId}
													LabelText="Notification Type"
													ParameterCode="NotificationChannel"
													ParameterValue2="ETRANSFER"
													ValueChanged={this.handleChange}
													Disabled={Disabled}
													Required
													IsInvalid={vModel.NotificationTypeId} />
											</GridItem>
											<GridItem xs={2} style={{ marginTop: 2 }}>
												<GenericCheckInput
													LabelMd={10}
													LabelText="Add New"
													Value={IsPayeePopUpOpen}
													ValueChanged={this.PayeePopUpCallback}
													Disabled={Disabled || IsUpdate} />
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={6}>
										<GridContainer justify="flex-start">
											<GridItem xs={8}>
												<GenericDateInput
													IsFuture={true}
													Name="DateOfFunds"
													LabelText="Date Funds"
													Value={IsUpdate ? DateHelper.GetLocalDateTimeAsUtc() : model.DateOfFunds == null ? "" : DateHelper.ToDateInputValue(model.DateOfFunds) || ""}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.DateOfFunds}
													Disabled={Disabled || IsUpdate}
												/>
											</GridItem>
											<GridItem xs={8}>
												<GenericDateInput
													IsFuture={true}
													Name="MoneyRequestExpiryDate"
													LabelText="Transaction Exp. Date"
													Value={model.MoneyRequestExpiryDate == null ? "" : DateHelper.ToDateInputValue(model.MoneyRequestExpiryDate) || ""}
													ValueChanged={this.handleChange}
													MinDate={DateHelper.GetDateLocal()}
													MaxDate={DateHelper.GetDateLocal().add(maxExpireDate, "d")}
													Required
													IsInvalid={vModel.MoneyRequestExpiryDate}
													Disabled={Disabled}
												/></GridItem>
											<GridItem xs={12}>
												{(model.TransactionTypeStr == TransactionType.Credit && this.state.InteracCustomerGetTransferOptions == false) && (
													<GridContainer style={{ marginLeft: -5, marginRight: -10 }}>
														<GridItem xs={8} style={{ paddingRight: "6px !important", paddingLeft: "4px !important" }}>
															{model.IsHaveQuestion ?
																<GenericSelectInput
																	key={this.state.reloadData + ""}
																	Name="SecurityQuestionId"
																	LabelText="Security Question"
																	Method="POST" Url="/bankapi/v1.0/BankInteracETransfer/GetSecurityQuestionsByClientIdAndProgramCode"
																	DataRoot="Item"
																	Parameter={{
																		UniqueClientId: model.UniqueClientId,
																		ProgramCode: ProgramCodes.Bank_Programs_Bank_Account
																	}}
																	KeyValueMember="Id"
																	TextValueMember="SecurityQuestion"
																	Value={model.SecurityQuestionId}
																	ValueChanged={this.handleChange}
																	Required
																	IsInvalid={vModel.SecurityQuestionId}
																	Disabled={Disabled}
																/>
																:
																<GenericTextInput
																	LabelMd={4}
																	inputProps={{ maxLength: 40 }}
																	Name="SecurityQuestionText"
																	LabelText="Security Question"
																	Value={model.SecurityQuestionText}
																	ValueChanged={this.handleChange}
																	Disabled={Disabled}
																	Required={model.TransactionTypeStr == TransactionType.Credit && this.state.InteracCustomerGetTransferOptions == false}
																	IsInvalid={vModel.SecurityQuestionText} />
															}
														</GridItem>
														<GridItem xs={4}>
															<GenericCheckInput
																Name="IsHaveQuestion"
																LabelText="Get Security Question From List"
																Value={model.IsHaveQuestion}
																ValueChanged={this.handleChange}
																Disabled={Disabled} />
														</GridItem>
														<GridItem xs={8} style={{ marginLeft: -1, paddingRight: -50 }}>
															<GenericTextInput
																inputProps={{ maxLength: 25 }}
																Name="SecurityQuestionAnswer"
																LabelText="Security Question Answer"
																Value={model.SecurityQuestionAnswer || ""}
																ValueChanged={this.handleChange}
																Required
																IsInvalid={vModel.SecurityQuestionAnswer}
																Disabled={Disabled}
															/>
														</GridItem>
													</GridContainer>
												)}</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GenericDialog open={this.state.isConfirmDialogOpen} maxWidth="lg" fullWidth>
									<DialogTitle>
										<GenericLabel Text="Transaction Confirmation" Bold={true} />
									</DialogTitle>
									<DialogContent>
										<GridItem>
											<GenericTextInput Name="" LabelText="Contact Name" Value={model.ReceiverName} Disabled={true} />
											{model.ServiceType == 0 &&
												<GenericEmailInput
													Name="ReceiverMail"
													LabelText="Contact Email"
													Value={model.ReceiverMail}
													Disabled={true}
												/>
											}
											{model.ServiceType == 1 &&
												<GenericTextInput
													LabelMd={4}
													Name="PayeePhone"
													LabelText="Contact Phone"
													Value={model.PhoneNumber}
													Disabled={true} />
											}
											<GenericDateInput Name="" LabelText="Date Funds" Value={DateHelper.ToDateInputValue(model.DateOfFunds) || ""} Disabled={true} />
											<GenericNumberInput Prefix="$ " Name="" LabelText="Transaction Amount" Value={model.TransactionAmount} MaxLength={10} Disabled={true} />
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterValue6"
												Value={model.TransactionTypeId}
												Disabled={true} />
											<GenericTextInput Name="" LabelText="Description" Value={model.Description || ""} Disabled={true} />

											<GenericLabel Text="Are you sure you want to enter this transaction ?" Bold={true} />
										</GridItem>
									</DialogContent>
									<GenericDialogActions>
										<Button size="sm" onClick={() => this.SendTransaction()}> Confirm </Button>
										<Button size="sm" onClick={() => this.setState({ isConfirmDialogOpen: false })}>	Cancel	</Button>
									</GenericDialogActions>
								</GenericDialog>

							</CardBody>
						</GenericExpansionPanel>
					</Card>
				}
			</div>
		);
	}
}

EtransferFromAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(EtransferFromAccount, {});