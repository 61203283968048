import { Dialog, DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import moment from "moment";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericTitle,
  GenericAlert,
  GenericCheckInput,
  GenericDateInput,
  GenericEmailInput,
  GenericGrid,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
} from "views/Components/Generic";
import {
  BankingAccountTypes,
  ClientType,
  Portals,
  ProgramCodes,
  GridColumnType,
} from "views/Constants/Constant";
import LoadingComponent from "views/Components/LoadingComponent";

class EftOrETransferAddNewCustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {},
      addressModel: {},
      tempModel: {
        isPersonal: false,
        isCorporate: false,
        isGenerateNew: props.ForInsert,
      },

      isEFT: false,
      pageSelect: "",
      payeeList: [],
      columnTitle1: "General Information",
      columnTitle2: "Address Information",
      isLoading: false,
      uniq: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.getappCenterTitle = "Empty1";
    this.getappLeftTitle = "Empty1";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClear = this.handleClear.bind(this);

    this.customerPayeeCallback = this.customerPayeeCallback.bind(this);
    this.successSaveCallback = this.successSaveCallback.bind(this);
    this.successSearchCallback = this.successSearchCallback.bind(this);
    this.successEditCallback = this.successEditCallback.bind(this);
    this.successDeleteCallback = this.successDeleteCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);

    this.showSuccessMessage = this.showSuccessMessage.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.showQuestionAlert = this.showQuestionAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

    this.handleClickSaveOk = this.handleClickSaveOk.bind(this);
    this.handleClickEditOk = this.handleClickEditOk.bind(this);
    this.handleClickDeleteOk = this.handleClickDeleteOk.bind(this);
    this.handleClickClearOk = this.handleClickClearOk.bind(this);
  }

  componentDidMount() {
    this.fetchCanadaPost();
    var { isEFT } = this.state;
    var model = { ...this.state.model };
    const tempModel = { ...this.state.tempModel };
    var tempuniq =
      "1" + Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
    if (this.props.setAppLeftTitle)
      this.props.setAppLeftTitle("Add New Customer");
    this.getappLeftTitle = "Add New Customer";
    if (this.props.setAppCenterTitle) {
      if (this.props.PageSelect == "eft") {
        isEFT = true;
        this.props.setAppCenterTitle("EFT SYSTEM");
        this.getappCenterTitle = "EFT SYSTEM";
        model.ProgramCode = ProgramCodes.Bank_Programs_EFT;
        this.props.setPortal(Portals.EFT);
      } else {
        isEFT = false;
        model.ProgramCode = ProgramCodes.Bank_Programs_e_Transfer;
        this.props.setAppCenterTitle("INTERAC e-TRANSFER SYSTEM");
        this.getappCenterTitle = "INTERAC e-TRANSFER SYSTEM";
        this.props.setPortal(Portals.eTransfer);
      }
    }

    this.setState({
      model,
      tempModel,
      pageSelect: this.props.PageSelect,
      isLoading: true,
      uniq: tempuniq,
      isEFT,
    });
    this.GetPayeeList();
  }

  fetchCanadaPost = () => {
    Proxy.POST(
      "/coreapi/v1.0/Parameter/Search",
      {
        ParameterCode: "CanadaPostApiKey",
        ParameterValue: "CP",
        ParameterDesc: "",
      },
      (responseData) => {
        if (!responseData.IsSucceeded) {
          console.log("Canada Post Api key not fetched !");
          return;
        }
        if (responseData.Item == null) {
          return;
        }
        if (responseData.Item[0] == null) {
          return;
        }

        var apikey = responseData.Item[0].ParameterDesc;
        console.log("KEY CP ", apikey);

        var url = window.CanadaPostAddressCompleteUrl;
        var path = url + apikey;
        var CanadaPostRequest = this.SubmitCanadaPostRequest;
        var pca;
        var addressComplete;

        var temp = this.state.addressModel;
        var CpTemp = this.addressHandle;

        const script = document.createElement("script");
        script.src = path;
        script.async = false;
        document.head.appendChild(script);

        script.onload = function () {
          pca = window.pca;
          addressComplete = window.addressComplete;
          if (pca != null) {
            var fields = [
                {
                  element: "street-address",
                  field: "Line1",
                  mode: pca.fieldMode.SEARCH,
                },
                {
                  element: "street-address2",
                  field: "Line2",
                  mode: pca.fieldMode.POPULATE,
                },
                {
                  element: "city",
                  field: "City",
                  mode: pca.fieldMode.POPULATE,
                },
                {
                  element: "state",
                  field: "ProvinceName",
                  mode: pca.fieldMode.SEARCH,
                },
                {
                  element: "postcode",
                  field: "PostalCode",
                  mode: pca.fieldMode.SEARCH,
                },
                {
                  element: "country",
                  field: "CountryName",
                  mode: pca.fieldMode.COUNTRY,
                },
              ],
              options = {
                key: apikey,
              },
              control = new pca.Address(fields, options);

            CanadaPostRequest();

            control.listen("populate", function (address) {
              console.log(address);
              temp.City = address.City;
              temp.Province = address.ProvinceName;
              temp.Country = address.CountryName;
              temp.AddressLine1 = address.Line1;
              temp.AddressLine2 = address.Line2;
              temp.ZipCode = address.PostalCode;
              CpTemp(temp);
            });
          }
        };
      },
      (e) => {
        console.log("Canada Post Api key not fetched !");
      }
    );
  };
  SubmitCanadaPostRequest = async () => {
    const model = { ...this.state.model };
    var requestdata = {
      UniqueClientId: null,
      MenuCode: this.props.MenuCode,
      AppCenterTitle: this.getappCenterTitle,
      AppLeftTitle: this.getappLeftTitle,
    };
    Proxy.POST(
      "/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
      requestdata,
      (responseData) => {
        if (responseData.Item != null) {
        }
      }
    );
    // var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
  };
  addressHandle = (address) => {
    var addressModel = { ...this.state.addressModel };
    addressModel.AddressLine1 = address.AddressLine1;
    addressModel.AddressLine2 = address.AddressLine2;
    addressModel.City = address.City;
    addressModel.Province = address.Province;
    addressModel.Country = address.Country;
    addressModel.ZipCode = address.ZipCode;
    this.setState({ addressModel });
  };

  handleAddressModel = (name, newValue) => {
    const addressModel = { ...this.state.addressModel };
    addressModel[name] = newValue;
    this.setState({ addressModel });
  };

  GetPayeeList = () => {
    Proxy.POST(
      "/bankapi/v1.0/CustomerPayee/GetAllByProgramId",
      { ProgramCode: this.props.PageSelect },
      (responseData) => {
        if (responseData.Item != null && responseData.Item.length > 0) {
          this.setState({
            isLoading: false,
            payeeList: responseData.Item,
          });
        } else {
          this.setState({
            isLoading: false,
            payeeList: [],
          });
        }
      },
      this.errorCallback
    );
  };

  SelectedRowChange = (index) => {
    var tempModel = { ...this.state.tempModel };
    var addressModel = { ...this.state.addressModel };
    const payee = this.state.payeeList[index];

    addressModel.City = payee.City;
    addressModel.Province = payee.Province;
    addressModel.Country = payee.Country;
    addressModel.AddressLine1 = payee.AddressLine1;
    addressModel.AddressLine2 = payee.AddressLine2;
    addressModel.ZipCode = payee.ZipCode;

    if (payee != null && payee.ClientTypeName != null) {
      var payeeClient = payee.ClientTypeName.charAt(0);
      if (payeeClient == ClientType.Individual) {
        tempModel.isCorparate = false;
        tempModel.isPersonal = true;
      } else {
        tempModel.isCorparate = true;
        tempModel.isPersonal = false;
      }
    }
    tempModel.isGenerateNew = true;

    this.setState({ model: payee, tempModel, rowIndex: index, addressModel });
  };

  handleChange(name, newValue, data) {
    const model = { ...this.state.model };
    const tempModel = { ...this.state.tempModel };
    const { uniq } = this.state;
    model[name] = newValue;

    if (name == "Email") {
      tempModel["IsEmailValid"] = data.IsValid;
    }

    if (name == "ClientTypeId") {
      if (data.ParameterValue == ClientType.Personal) {
        tempModel.isCorparate = false;
        tempModel.isPersonal = true;
        delete model.CompanyLegalName;
        delete model.BusinessName;
        delete model.CompanyName;
        delete model.RegistrationName;
        delete model.RetailName;
      }
      if (data.ParameterValue == ClientType.Corporate) {
        tempModel.isCorparate = true;
        tempModel.isPersonal = false;
        delete model.Name;
        delete model.MiddleName;
        delete model.SurName;
      }
    }
    var tempuniq = uniq;
    if (name == "IsAutoGenerateCustomerNumber" && newValue) {
      tempuniq = "";
      model.ClientCustomerNumber = "";
    }
    if (name == "IsAutoGenerateCustomerNumber" && !newValue) {
      var uniqueRowId = ClientHelper.GetClientRowId().toString();
      tempuniq = "1" + uniqueRowId.padStart(3, "0");
      model.ClientCustomerNumber = "";
    }

    if (name == "FinancialInstitutionId") {
      model.BankNumber = data.FinancialInstitutionId;
      model.FinancialInstitutionBranchId = null;
      model.BranchNumber = null;
    }
    if (name == "FinancialInstitutionBranchId") {
      model.BranchNumber = data.TransitNumber;
    }

    this.setState({ model, tempModel, uniq: tempuniq });
  }

  handleChangeTempModel = (name, newValue) => {
    const tempModel = { ...this.state.tempModel };
    tempModel[name] = newValue;
    if (name == "isGenerateNew") {
      if (tempModel.isGenerateNew == true) {
        this.handleClear();
        tempModel.isCorparate = null;
        tempModel.isPersonal = null;
      }
    }
    this.setState({ tempModel });
  };

  handleChangeState = (name, newValue) => {
    this.setState({ [name]: newValue });

    if (name == "isGenerateNew") {
      if (this.state.isGenerateNew == false) {
        this.handleClear();
      }
    }
  };

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  };

  handleSubmit() {
    const model = { ...this.state.model };
    const tempModel = { ...this.state.tempModel };
    if (this.props.PageSelect == "eTransfer")
      model.ProgramCode = ProgramCodes.Bank_Programs_e_Transfer;

    if (this.props.PageSelect == "eft")
      model.ProgramCode = ProgramCodes.Bank_Programs_EFT;

    model.BankingAccountTypeCode = BankingAccountTypes.DCBankAccount;
    this.setState({ model });
    var messages = [];

    if (
      (tempModel.IsEmailValid != null || model.Email != "") &&
      tempModel.IsEmailValid == false
    )
      messages.push("Email is invalid !!!");
    if (model.ClientTypeId == null)
      messages.push("Payee Type can not be null !!");
    if (model.Id) {
      if (model.PhoneCountryCodeId == null || undefined)
        messages.push("Phone Country Code is not selected.");
      if (model.PhoneNumber == null || undefined || "")
        messages.push("Phone Number can not be null.");
      if (this.props.PageSelect == "eft")
        if (
          model.FinancialInstitutionId == null ||
          undefined ||
          model.FinancialInstitutionBranchId == null ||
          undefined ||
          model.AccountNumber == null ||
          undefined ||
          ""
        )
          //ProgramCodeeft ise
          messages.push("Fill in the required fields from Payment Wallet !");
      if (this.props.PageSelect == "eTransfer") {
        //ProgramCodeeTransfer ise
        if (tempModel.IsEmailValid != null || model.Email == "")
          messages.push("Email can not be null.");
        if (
          (tempModel.IsEmailValid != null || model.Email == "") &&
          tempModel.IsEmailValid == false
        )
          messages.push("Email is invalid.");
      }
      if (messages.length > 0) {
        this.ShowMessageNode(
          "warning",
          "Please fill required fields!",
          messages.map((x, i) => <div key={i}>{x}</div>)
        );
      } else {
        this.showQuestionAlert(
          "Are you sure you want to edit this payee ?",
          "Edit"
        );
      }
    } else {
      if (model.PhoneCountryCodeId == null || undefined)
        messages.push("Phone Country Code is not selected.");
      if (model.PhoneNumber == null || undefined || "")
        messages.push("Phone Number can not be null.");

      if (tempModel.isPersonal == null && tempModel.isPersonal) {
        if (model.Name == null || model.Name == "")
          messages.push("Name can not be null.");
        if (model.SurName == null || model.SurName == "")
          messages.push("Last Name can not be null.");
        if (model.BirthDate == null)
          messages.push("Birth Date can not be null.");
      }
      if (tempModel.isCorporate == null && tempModel.isCorporate) {
        if (model.BusinessName == null || model.BusinessName == "")
          messages.push("Business Name can not be null.");
        if (model.CompanyName == null || model.CompanyName == "")
          messages.push("Company Name can not be null.");
      }

      if (this.props.PageSelect == "eft") {
        //ProgramCodeeft ise
        if (model.FinancialInstitutionId == null || undefined)
          messages.push("Financial Institution is not selected.");
        if (model.FinancialInstitutionBranchId == null || undefined)
          messages.push("Branch is not selected.");
        if (model.AccountNumber == null || undefined || "")
          messages.push("Wallet Number can not be null.");
        if (
          model.AccountNumber != null &&
          model.AccountNumber != undefined &&
          model.AccountNumber != "" &&
          model.AccountNumber.length != 12
        )
          messages.push("Wallet Number Must be 12 characters long.");
      }
      if (this.props.PageSelect == "eTransfer") {
        //ProgramCodeeTransfer ise
        if (model.Email == null || model.Email == "")
          messages.push("Email can not be null.");
        if (
          (tempModel.IsEmailValid != null || model.Email == "") &&
          tempModel.IsEmailValid == false
        )
          messages.push("Email is invalid.");
      }
      if (messages.length > 0) {
        this.ShowMessageNode(
          "warning",
          "Please fill required fields!",
          messages.map((x, i) => <div key={i}>{x}</div>)
        );
      } else {
        this.showQuestionAlert(
          "Are you sure you want to save this payee ?",
          "Save"
        );
      }
    }
  }

  handleSearch() {
    Proxy.POST(
      "/bankapi/v1.0/CustomerPayee/Search",
      this.state.model,
      this.successSearchCallback,
      this.errorCallback
    );
  }

  handleDelete() {
    if (!this.state.model.Id) {
      this.setState({
        alert: (
          <GenericAlert
            Title={"Warning"}
            MessageNode={"Please select a payee from grid."}
            Type={"warning"}
            OnConfirm={() => this.hideAlert()}
          />
        ),
      });
    } else {
      this.showQuestionAlert(
        "Are you sure you want to inactive this payee ?",
        "Delete"
      );
    }
  }

  handleClear() {
    this.showQuestionAlert(
      "This will erase the information you entered on the screen. Are you sure you want to quit this payee ?",
      "Clear"
    );
  }

  checkOld = () => {
    var interval = moment
      .duration(DateHelper.GetDate() - this.state.model.BirthDate)
      .asYears();
    if (interval < 18) {
      return false;
    }
    return true;
  };

  handleClickSaveOk() {
    this.hideAlert();
    var checkOld = true;
    if (this.state.model.BirthDate) checkOld = this.checkOld();

    if (checkOld) {
      var model = { ...this.state.model };
      var addressModel = { ...this.state.addressModel };
      model.AddressLine1 = addressModel.AddressLine1;
      model.AddressLine2 = addressModel.AddressLine2;
      model.City = addressModel.City;
      model.Province = addressModel.Province;
      model.Country = addressModel.Country;
      model.Zipcode = addressModel.Zipcode;
      Proxy.POST(
        "/bankapi/v1.0/CustomerPayee/InsertOrUpdate",
        model,
        this.successSaveCallback,
        this.errorCallback
      );
    } else {
      this.showErrorMessage("Payee age must be bigger then 18 !");
    }
  }

  handleClickEditOk() {
    this.hideAlert();
    var checkOld = true;
    if (this.state.model.BirthDate) checkOld = this.checkOld();
    if (checkOld) {
      if (this.state.model.BirthDate) this.checkOld();
      var model = { ...this.state.model };
      delete model.ClientTypeName;
      delete model.City;
      delete model.Country;
      delete model.CreateDate;
      delete model.CustomerStatus;
      delete model.IBAN;
      delete model.PhoneCountryCode;
      delete model.RegistrationStatus;
      delete model.UniqueClientId;
      this.setState({ model });
      Proxy.POST(
        "/bankapi/v1.0/CustomerPayee/InsertOrUpdate",
        model,
        this.successEditCallback,
        this.errorCallback
      );
    } else {
      this.showErrorMessage("Payee age must be bigger then 18 !");
    }
  }

  handleClickDeleteOk() {
    this.hideAlert();
    Proxy.GET(
      "/bankapi/v1.0/CustomerPayee/Delete/Id?Id=" + this.state.model.Id,
      this.successDeleteCallback,
      this.errorCallback
    );
  }

  handleClickClearOk() {
    const tempModel = { ...this.state.tempModel };
    this.hideAlert();
    delete tempModel.FinancialInstitutionName;
    delete tempModel.BranchName;
    this.setState({ model: any, tempModel, rowIndex: "", addressModel: any });
  }

  successSaveCallback(responseData) {
    if (!responseData.IsSucceeded) {
      this.showErrorMessage(responseData.ErrorDescription);
      return;
    }
    this.GetPayeeList();
    this.customerPayeeCallback(responseData);
    this.showSuccessMessage("Saved succesfully !!!");
  }

  successSearchCallback(responseData) {
    if (!responseData.IsSucceeded) {
      this.showErrorMessage(responseData.ErrorDescription);
      return;
    }

    this.customerPayeeCallback(responseData);
  }

  successEditCallback(responseData) {
    if (!responseData.IsSucceeded) {
      this.showErrorMessage(responseData.ErrorDescription);
      return;
    }
    this.GetPayeeList();
    this.customerPayeeCallback(responseData);
    this.showSuccessMessage("Edited succesfully !!!");
  }

  successDeleteCallback() {
    this.GetPayeeList();
    this.handleClickClearOk();
    this.showSuccessMessage("Payee deleted successfully !!!");
  }

  errorCallback(error) {
    this.showErrorMessage("An error occurred during the api visit" + error);
  }

  customerPayeeCallback(responseData) {
    var model = { ...this.state.model };
    var addressModel = { ...this.state.addressModel };
    var tempModel = { ...this.state.tempModel };

    if (this.props.CustomerSaved) this.props.CustomerSaved(responseData.Item);
    if (responseData.Item != null && responseData.Item.ClientCustomerNumber)
      responseData.Item.ClientCustomerNumber =
        responseData.Item.ClientCustomerNumber.substring(4);

    if (responseData.Item != null) {
      model = responseData.Item;
      addressModel.City = model.City;
      addressModel.Province = model.Province;
      addressModel.Country = model.Country;
      addressModel.AddressLine1 = model.AddressLine1;
      addressModel.AddressLine2 = model.AddressLine2;
      addressModel.ZipCode = model.ZipCode;
    }

    if (responseData.Item.PayeeNumber) tempModel.isGenerateNew = true;

    if (responseData.Item.Program) {
      if (
        responseData.Item.Program.ProgramCode == ProgramCodes.Bank_Programs_EFT
      )
        model["ProgramCode"] = ProgramCodes.Bank_Programs_EFT;
      if (
        responseData.Item.Program.ProgramCode ==
        ProgramCodes.Bank_Programs_e_Transfer
      )
        model["ProgramCode"] = ProgramCodes.Bank_Programs_e_Transfer;
    }
    this.setState({ model, tempModel, isLoading: false, addressModel });
  }

  showQuestionAlert(message, title) {
    if (title == "Save") {
      this.setState({
        isLoading: false,
        alert: (
          <GenericAlert
            Title={title}
            Message={message}
            Type="warning"
            ShowCancel={true}
            OnCancel={() => this.hideAlert()}
            OnConfirm={() => this.handleClickSaveOk()}
          />
        ),
      });
    }
    if (title == "Edit") {
      this.setState({
        alert: (
          <GenericAlert
            Title={title}
            Message={message}
            Type="warning"
            ShowCancel={true}
            OnCancel={() => this.hideAlert()}
            OnConfirm={() => this.handleClickEditOk()}
          />
        ),
      });
    }
    if (title == "Delete") {
      this.setState({
        alert: (
          <GenericAlert
            Title={title}
            Message={message}
            Type="warning"
            ShowCancel={true}
            OnCancel={() => this.hideAlert()}
            OnConfirm={() => this.handleClickDeleteOk()}
          />
        ),
      });
    }

    if (title == "Clear") {
      this.setState({
        alert: (
          <GenericAlert
            Title={title}
            Message={message}
            Type="warning"
            ShowCancel={true}
            OnCancel={() => this.hideAlert()}
            OnConfirm={() => this.handleClickClearOk()}
          />
        ),
      });
    }
  }

  showSuccessMessage(message) {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={"Success"}
          MessageNode={message}
          Type={"success"}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  }

  showErrorMessage(message) {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={"Error"}
          MessageNode={message}
          Type={"warning"}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  RefreshToolBar = () => {
    this.setState({ LimitToken: !this.state.LimitToken });
  };
  render() {
    const { classes, ForInsert, Disabled } = this.props;
    const {
      pageSelect,
      model,
      addressModel,
      tempModel,
      isLoading,
      alert,
      isEFT,
    } = this.state;

    return (
      <div>
        <LoadingComponent Show={isLoading} />

        <ButtonToolbar
          ButtonList={[
            { Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled },
            {
              Code: "Clear",
              OnClick: this.handleClear,
              Disabled: ForInsert || Disabled,
            },
            {
              Code: "Delete",
              OnClick: this.handleDelete,
              Disabled: ForInsert || Disabled,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          {alert}
          <GridItem xs={12}>
            <Card className={classes.zerMarPad}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={6}>
                    <GridContainer spacing={16}>
                      <GridItem xs={7}>
                        <GenericNumberInput
                          Name="PayeeNumber"
                          LabelText={`${window.Title} Payee No.`}
                          LabelMd={5}
                          Value={
                            model.PayeeNumber == null || undefined
                              ? ""
                              : model.PayeeNumber
                          }
                          ValueChanged={this.handleChange}
                          Disabled={tempModel.isGenerateNew}
                          EndIconName="search"
                          MaxLength={10}
                          NoFormatting={true}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <GenericCheckInput
                          Name="isGenerateNew"
                          LabelText="Generate New"
                          Value={
                            tempModel.isGenerateNew == null || undefined
                              ? false
                              : tempModel.isGenerateNew
                          }
                          ValueChanged={this.handleChangeTempModel}
                          Disabled={ForInsert || false}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer spacing={16}>
                      <GridItem xs={7}>
                        <GenericTextInput
                          inputProps={{
                            maxLength:
                              model.IsAutoGenerateCustomerNumber == null ||
                              model.IsAutoGenerateCustomerNumber == false
                                ? 7
                                : 11,
                          }}
                          key={this.state.uniq}
                          Name="ClientCustomerNumber"
                          LabelMd={5}
                          LabelText="Client Payee No."
                          Value={
                            model.ClientCustomerNumber == null || undefined
                              ? ""
                              : model.ClientCustomerNumber
                          }
                          ValueChanged={this.handleChange}
                          Disabled={
                            model.IsAutoGenerateCustomerNumber ||
                            model.Id != null
                          }
                          BeginText={
                            model.Id == null
                              ? this.state.uniq
                              : model.IsAutoGenerateCustomerNumber == null
                              ? model.ClientCustomerNumber != null
                                ? ""
                                : this.state.uniq
                              : model.IsAutoGenerateCustomerNumber == false
                              ? this.state.uniq
                              : ""
                          }
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <GenericCheckInput
                          Name="IsAutoGenerateCustomerNumber"
                          LabelText="Auto Generate"
                          Value={
                            model.IsAutoGenerateCustomerNumber == null ||
                            undefined
                              ? false
                              : model.IsAutoGenerateCustomerNumber
                          }
                          ValueChanged={this.handleChange}
                          Disabled={ForInsert || false || model.Id != null}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={6}>
                    <h5>
                      <b>{this.state.columnTitle1}</b>
                    </h5>
                    <GenericSelectInput
                      Name="ClientTypeId"
                      LabelText="Payee Type"
                      Value={
                        model.ClientTypeId == null || undefined
                          ? ""
                          : model.ClientTypeId
                      }
                      DataRoot="Item"
                      ValueChanged={this.handleChange}
                      KeyValueMember="Id"
                      TextValueMember="ParameterDesc"
                      Method="POST"
                      Url="/coreapi/v1.0/Parameter/Search"
                      Parameter={{
                        ParameterCode: "ClientType",
                        ParameterValue: "",
                        ParameterValue3: "D",
                        ParameterDesc: "",
                      }}
                    />
                    {pageSelect == "eft" && (
                      <GenericTextInput
                        Name="CompanyLegalName"
                        LabelText="Company Legal Name"
                        Value={
                          model.CompanyLegalName == null || undefined
                            ? ""
                            : model.CompanyLegalName
                        }
                        ValueChanged={this.handleChange}
                        Disabled={tempModel.isPersonal}
                      />
                    )}
                    {pageSelect == "eTransfer" && (
                      <div>
                        <GenericTextInput
                          Name="BusinessName"
                          LabelText="Business Name"
                          Value={
                            model.BusinessName == null || undefined
                              ? ""
                              : model.BusinessName
                          }
                          ValueChanged={this.handleChange}
                          Disabled={tempModel.isPersonal}
                          MaxLength="30"
                        />

                        <GenericTextInput
                          Name="CompanyName"
                          LabelText="Company Name"
                          Value={
                            model.CompanyName == null || undefined
                              ? ""
                              : model.CompanyName
                          }
                          ValueChanged={this.handleChange}
                          Disabled={tempModel.isPersonal}
                        />

                        <GenericTextInput
                          Name="RegistrationName"
                          LabelText="Registration Name"
                          Value={
                            model.RegistrationName == null || undefined
                              ? ""
                              : model.RegistrationName
                          }
                          ValueChanged={this.handleChange}
                          Disabled={tempModel.isPersonal}
                        />

                        <GenericTextInput
                          Name="RetailName"
                          LabelText="RetailName"
                          Value={
                            model.RetailName == null || undefined
                              ? ""
                              : model.RetailName
                          }
                          ValueChanged={this.handleChange}
                          Disabled={tempModel.isPersonal}
                        />
                      </div>
                    )}
                    <GenericTextInput
                      Name="Name"
                      IsText
                      LabelText="Name"
                      Value={model.Name == null || undefined ? "" : model.Name}
                      ValueChanged={this.handleChange}
                      Disabled={tempModel.isCorparate}
                    />
                    <GenericTextInput
                      Name="MiddleName"
                      IsText
                      LabelText="Middle Name"
                      Value={
                        model.MiddleName == null || undefined
                          ? ""
                          : model.MiddleName
                      }
                      ValueChanged={this.handleChange}
                      Disabled={tempModel.isCorparate}
                    />
                    <GenericTextInput
                      Name="SurName"
                      IsText
                      LabelText="Last Name"
                      Value={
                        model.SurName == null || undefined ? "" : model.SurName
                      }
                      ValueChanged={this.handleChange}
                      Disabled={tempModel.isCorparate}
                    />
                    <GenericDateInput
                      Name="BirthDate"
                      LabelText="Date of Birth"
                      Value={
                        model.BirthDate == null || undefined
                          ? ""
                          : Formatter.FormatDate(model.BirthDate)
                      }
                      ValueChanged={this.handleChange}
                      IncludeTime={false}
                      Disabled={tempModel.isCorparate}
                    />
                    <GenericEmailInput
                      Name="Email"
                      LabelText="E-Mail"
                      Value={model.Email}
                      ValueChanged={this.handleChange}
                    />
                    <GridContainer>
                      <GridItem xs={8}>
                        <GenericSelectInput
                          Name="PhoneCountryCodeId"
                          LabelText="Phone Number"
                          TitleLabel="Country Code"
                          LabelMd={6}
                          Method="POST"
                          Url="/coreapi/v1.0/Country/GetAll"
                          Parameter={{}}
                          DataRoot="Item"
                          KeyValueMember="Id"
                          TextValueMember={"Code"}
                          Value={
                            model.PhoneCountryCodeId == null || undefined
                              ? ""
                              : model.PhoneCountryCodeId
                          }
                          ValueChanged={this.handleChange}
                          Sorted={{ Member: "OrderIndex" }}
                          RenderItem={(d) => "+" + d.Code + " (" + d.Name + ")"}
                        />
                      </GridItem>
                      <GridItem
                        xs={4}
                        style={{ paddingRight: 2 + "px !important" }}
                      >
                        <GenericNumberInput
                          IsPhone
                          Name="PhoneNumber"
                          InputStyle={{ marginLeft: 2 }}
                          LabelMd={0}
                          Value={
                            model.PhoneNumber == null || undefined
                              ? ""
                              : model.PhoneNumber
                          }
                          ValueChanged={this.handleChange}
                          MaxLength={10}
                        />
                      </GridItem>
                    </GridContainer>
                    {pageSelect == "eft" && (
                      <div>
                        <GenericTextInput
                          Name="AccountName"
                          LabelText="Short Name"
                          Value={
                            model == null || undefined
                              ? ""
                              : model.AccountName == null || undefined
                              ? ""
                              : model.AccountName
                          }
                          ValueChanged={this.handleChange}
                        />
                        <GridContainer>
                          <GridItem xs={6}>
                            <GenericSelectInput
                              Name="FinancialInstitutionId"
                              LabelMd={8}
                              LabelText="Wallet Number"
                              TitleLabel="FIID"
                              Method="GET"
                              Url="/bankapi/v1.0/FinancialInstitution/GetAllFinInsOrderByBankNumber"
                              DataRoot="Item"
                              KeyValueMember="Id"
                              TextValueMember="FinInsId"
                              Value={
                                model.FinancialInstitutionId == null ||
                                undefined
                                  ? ""
                                  : model.FinancialInstitutionId
                              }
                              ValueChanged={this.handleChange}
                            />
                          </GridItem>
                          <GridItem xs={3}>
                            <GenericSelectInput
                              key={model.FinancialInstitutionId}
                              TitleLabel="Branch No."
                              LabelMd={0}
                              Name="FinancialInstitutionBranchId"
                              Method="POST"
                              Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
                              Parameter={{
                                FinancialInstitutionId:
                                  model.FinancialInstitutionId == null ||
                                  undefined
                                    ? 0
                                    : model.FinancialInstitutionId,
                              }}
                              DataRoot="Item"
                              KeyValueMember="Id"
                              TextValueMember="TransNo"
                              Value={
                                model.FinancialInstitutionBranchId == null ||
                                undefined
                                  ? ""
                                  : model.FinancialInstitutionBranchId
                              }
                              ValueChanged={this.handleChange}
                            />
                          </GridItem>
                          <GridItem
                            xs={3}
                            style={{ paddingRight: 2 + "px !important" }}
                          >
                            <GenericNumberInput
                              NoFormatting={true}
                              LabelMd={0}
                              InputStyle={{ marginLeft: 2 }}
                              Name="AccountNumber"
                              Value={
                                model.AccountNumber == null || undefined
                                  ? ""
                                  : model.AccountNumber
                              }
                              ValueChanged={this.handleChange}
                              MaxLength={12}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    )}
                  </GridItem>

                  <GridItem xs={6}>
                    <h5>
                      <b>{this.state.columnTitle2}</b>
                    </h5>
                    <GenericTextInput
                      Name="AddressLine1"
                      LabelText="Address Line 1"
                      Id="street-address"
                      Value={
                        addressModel.AddressLine1 == null || undefined
                          ? ""
                          : addressModel.AddressLine1
                      }
                      ValueChanged={this.handleAddressModel}
                    />
                    <GenericTextInput
                      Name="AddressLine2"
                      Id="street-address2"
                      LabelText="Address Line 2"
                      Value={
                        addressModel.AddressLine2 == null || undefined
                          ? ""
                          : addressModel.AddressLine2
                      }
                      ValueChanged={this.handleAddressModel}
                    />
                    <GenericTextInput
                      Name="ZipCode"
                      LabelText="Postal Code"
                      Id="postcode"
                      Value={
                        addressModel.ZipCode == null || undefined
                          ? ""
                          : addressModel.ZipCode
                      }
                      ValueChanged={this.handleAddressModel}
                    />
                    <GenericTextInput
                      Name="Province"
                      Id="state"
                      LabelText="Province"
                      Value={
                        addressModel.Province == null || undefined
                          ? ""
                          : addressModel.Province
                      }
                      ValueChanged={this.handleAddressModel}
                    />
                    <GenericTextInput
                      Name="City"
                      LabelText="City"
                      Id="city"
                      Value={
                        addressModel.City == null || undefined
                          ? ""
                          : addressModel.City
                      }
                      ValueChanged={this.handleAddressModel}
                    />
                    <GenericTextInput
                      Name="Country"
                      Id="country"
                      LabelText="Country"
                      Value={
                        addressModel.Country == null || undefined
                          ? ""
                          : addressModel.Country
                      }
                      ValueChanged={this.handleAddressModel}
                    />
                    {model.CustomerStatusId && (
                      <GenericSelectInput
                        Name="CustomerStatusId"
                        LabelText="Payee Status"
                        Value={
                          model.CustomerStatusId == null || undefined
                            ? ""
                            : model.CustomerStatusId
                        }
                        DataRoot="Item"
                        ValueChanged={this.handleChange}
                        KeyValueMember="Id"
                        TextValueMember="ParameterDesc"
                        Method="POST"
                        Url="/coreapi/v1.0/Parameter/Search"
                        Parameter={{
                          ParameterCode: "CustomerStatus",
                          ParameterValue: "",
                          ParameterValue2: "",
                          ParameterDesc: "",
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
                <br />
                <br />
              </CardBody>
            </Card>

            <br />
          </GridItem>
        </GridContainer>
        <Card
          className="no-radius"
          style={{ display: ForInsert ? "none" : "inherit" }}
        >
          <CardBody>
            <CardHeader>
              <GenericTitle text={"Payee List"} />
            </CardHeader>
            <GridItem xs={12}>
              <GridItem>
                <GenericGrid
                  Data={this.state.payeeList}
                  Columns={[
                    {
                      Header: window.Title + " Payee No.",
                      accessor: "PayeeNumber",
                    },
                    {
                      Header: "Client Payee No.",
                      accessor: "ClientCustomerNumber",
                    },
                    {
                      Header: "Payee Type",
                      accessor: "ClientTypeName",
                    },
                    {
                      Header: "Company Legal Name",
                      accessor: "CompanyLegalName",
                      show: isEFT,
                    },
                    {
                      Header: "Business Name",
                      accessor: "BusinessName",
                      show: !isEFT,
                    },
                    {
                      Header: "CompanyName",
                      accessor: "CompanyName",
                      show: !isEFT,
                    },
                    {
                      Header: "Registration Name",
                      accessor: "RegistrationName",
                      show: !isEFT,
                    },
                    {
                      Header: "Retail Name",
                      accessor: "RetailName",
                      show: !isEFT,
                    },
                    {
                      Header: "Name",
                      accessor: "Name",
                    },
                    {
                      Header: "Middle Name",
                      accessor: "MiddleName",
                    },
                    {
                      Header: "Last Name",
                      accessor: "SurName",
                    },
                    {
                      Header: "BirthDate",
                      accessor: "BirthDate",
                      type: GridColumnType.Date,
                    },
                    {
                      Header: "Email",
                      accessor: "Email",
                    },
                    {
                      Header: "Phone Number",
                      accessor: "PhoneNumber",
                      Cell: (row) => (
                        <div>
                          {"+" +
                            row.original.PhoneCountryCode +
                            " " +
                            row.original.PhoneNumber}
                        </div>
                      ),
                    },
                    {
                      Header: "Short Name",
                      accessor: "AccountName",
                      show: isEFT,
                    },
                    {
                      Header: "FIID",
                      accessor: "FID",
                      Cell: (row) => (
                        <div>
                          {row.value == null
                            ? ""
                            : row.value.toString().padStart(3, "0")}
                        </div>
                      ),
                      show: isEFT,
                    },
                    {
                      Header: "Transit Number",
                      accessor: "TransitNumber",
                      Cell: (row) => (
                        <div>
                          {row.value == null
                            ? ""
                            : row.value.toString().padStart(5, "0")}
                        </div>
                      ),
                      show: isEFT,
                    },
                    {
                      Header: "Wallet Number",
                      accessor: "AccountNumber",
                      show: isEFT,
                    },
                    {
                      Header: "Payee Name",
                      accessor: "PayeeName",
                    },
                    {
                      Header: "Address Line1",
                      accessor: "AddressLine1",
                    },
                    {
                      Header: "Address Line2",
                      accessor: "AddressLine2",
                    },
                    {
                      Header: "City",
                      accessor: "City",
                    },
                    {
                      Header: "Province",
                      accessor: "Province",
                    },
                    {
                      Header: "Postal Code",
                      accessor: "ZipCode",
                    },
                    {
                      Header: "Country",
                      accessor: "Country",
                    },
                    {
                      Header: "Status",
                      accessor: "CustomerStatus",
                    },
                    {
                      Header: "CreateDate",
                      accessor: "CreateDate",
                      type: GridColumnType.Date,
                    },
                  ]}
                  RowSelected={(index) => {
                    this.SelectedRowChange(index);
                  }}
                  SelectedIndex={this.state.rowIndex}
                />
              </GridItem>
            </GridItem>
          </CardBody>
        </Card>
      </div>
    );
  }
}

EftOrETransferAddNewCustomerComponent.propTypes = {
  tabList: PropTypes.array,
  classes: PropTypes.object.isRequired,
  PageSelect: PropTypes.any,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  CustomerSaved: PropTypes.func,
  Disabled: PropTypes.bool,
  MenuCode: PropTypes.any,
};

export default withArtifex(EftOrETransferAddNewCustomerComponent, {});
