import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ClientPartnerContractDetails extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			vModel: {},
			contractDetailModel: {},
			ClientPartnerContractDetailsList: [],
			partnerFeeTypeParam: {},
			loanTypeParam: {},
			LoanTypeDisabled: {
				loanDisabled: true,
				locDisabled: true,
				secureCardDisabled: true,
			},
			isLoading: false,
			alert: null
		};

		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };
		this.SalesChannelarameterCode = { ParameterCode: "LOSSalesChannel" };
		this.PartnerFeeTypeParameterCode = { ParameterCode: "LOSPartnerFeeType" };
		this.LOSPaymentCalculationTypeParameterCode = { ParameterCode: "LOSPaymentCalculationType" };

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({ contractDetailModel: { ...row.original } })} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Loan Type",
				accessor: "LoanType.ParameterDesc"
			},
			{
				Header: "Start Date",
				accessor: "StartDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "End Date",
				accessor: "EndDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "On-going Fee Margin",
				accessor: "OngoingFeeMargin"
			},
			{
				Header: "Setup Fee Margin",
				accessor: "SetupFeeMargin"
			},
			{
				Header: "Interest Fee Margin",
				accessor: "InterestFeeMargin"
			},
			{
				Header: "Sales Channel",
				accessor: "SalesChannel.ParameterDesc"
			},
			{
				Header: "Payment Calculation Type",
				accessor: "PaymentCalculationType.ParameterDesc"
			}

		];
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Contract And Details");
		var model = { Id: 0 };
		this.setState({ contractDetailModel: model, ClientPartnerContractDetailsList: this.props.ClientPartnerContractDetailsList });

	}
	EnableOrDisable(parameterData) {
		var parameter = parameterData;
		const disabledModel = this.state.LoanTypeDisabled;

		if (parameter.ParameterValue == "1") {//loan
			disabledModel.loanDisabled = false;
			disabledModel.locDisabled = true;
			disabledModel.secureCardDisabled = true;
		}
		if (parameter.ParameterValue == "2") {//loc
			disabledModel.locDisabled = false;
			disabledModel.loanDisabled = true;
			disabledModel.secureCardDisabled = true;
		}
		if (parameter.ParameterValue == "0") {//secure card
			disabledModel.secureCardDisabled = false;
			disabledModel.locDisabled = true;
			disabledModel.loanDisabled = true;
		}
		if (parameter.ParameterValue == "all") {
			disabledModel.secureCardDisabled = false;
			disabledModel.locDisabled = false;
			disabledModel.loanDisabled = false;
		}
		this.setState({ LoanTypeDisabled: disabledModel, loanTypeParam: parameter });
	}
	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.contractDetailModel;
		const { vModel } = this.state;
		if (data.LoanTypeId == null) {
			vModel.LoanTypeId = true;
			ErrorList.push("You must select a loan type.");
		}
		else {
			vModel.LoanTypeId = false;
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	UpdateModel = () => {
		var { ClientPartnerModel } = this.props;
		var temp = this.state.contractDetailModel;
		temp.ClientPartnerId = ClientPartnerModel.Id;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/ClientPartnerContractDetails/InsertUpdate",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.setState({ isLoading: false });
					this.props.GetClientPartnerContractDetailsModels();
					this.ShowMessage("success", "Success", temp.Id > 0 ? "Client Partner Contract Details Updated" : "Client Partner Contract Details Saved");
					this.ClearData();
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Warning", "Contract details must be selected for delete operation.");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/ClientPartnerContractDetails/Delete",
			{ Id: model.Id },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Client Partner Contract Details Deleted");
				this.ClearData();
				this.props.GetClientPartnerContractDetailsModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.ClientPartnerContractDetailsList[index];

		this.setState({ contractDetailModel: model || {}, partnerFeeTypeParam: model.PartnerFeeType || {} });
	}
	ClearData = () => {
		var model = { Id: 0 };
		var LoanTypeDisabledModel = {
			loanDisabled: true,
			locDisabled: true,
			secureCardDisabled: true,
		};
		this.setState({ contractDetailModel: model, loanDisabled: LoanTypeDisabledModel });
	}
	handleChange = (name, newValue, data) => {
		if (name == "PartnerFeeTypeId" && data != null) {
			this.setState({ partnerFeeTypeParam: data });
		}
		if (name == "LoanTypeId" && data != null) {
			var temp = this.state.contractDetailModel;
			temp.PartnerFeeMarginLoan = null;
			temp.InterestLoan = null;
			temp.PartnerFeeMarginLoc = null;
			temp.InterestLoc = null;
			temp.PartnerFeeMarginSecureCard = null;
			temp.InterestSecureCard = null;
			this.setState({ contractDetailModel: temp });
			this.EnableOrDisable(data);
		}

		this.setState(function (state, props) {
			var model = state.contractDetailModel || {};
			model[name] = newValue;
			return { contractDetailModel: model, };
		});
	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { Disabled: DisabledProp, ClientPartnerContractDetailsList } = this.props;
		const { contractDetailModel, vModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={1}>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel
							Title="Contract Details">
							<GridItem xs={12}>
								<GridContainer
									direction="row"
									justify="flex-start"
									alignItems="flex-start" >
									<GridItem xs={4}>
										<GenericSelectInput
											Name="LoanTypeId"
											LabelMd={5}
											LabelText="Loan Type"
											Value={contractDetailModel == null ? "" : contractDetailModel.LoanTypeId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.LoanTypeParameterCode}
											Disabled={Disabled}
											DataRoot="Item"
											Required
											IsInvalid={vModel.LoanTypeId} />
										<GenericDateInput
											Name="StartDate"
											IsPast={true}
											LabelMd={5}
											LabelText="Start Date"
											Value={contractDetailModel.StartDate ? DateHelper.ToDateInputValue(contractDetailModel.StartDate) : ""}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											Disabled={Disabled} />
										<GenericDateInput
											MinDate={DateHelper.ToMoment(contractDetailModel.StartDate)}
											Name="EndDate"
											IsPast={false}
											LabelMd={5}
											LabelText="End Date"
											Value={contractDetailModel.EndDate ? DateHelper.ToDateInputValue(contractDetailModel.EndDate) : ""}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											Disabled={Disabled} />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
											MaxValue={100}
											Suffix="%"
											Name="OngoingFeeMargin"
											LabelText="On-going Fee Margin"
											Value={contractDetailModel.OngoingFeeMargin}
											ValueChanged={this.handleChange}
											Disabled={Disabled} />
										<GenericNumberInput
											MaxValue={100}
											Suffix="%"
											Name="SetupFeeMargin"
											LabelText="Setup Fee Margin"
											Value={contractDetailModel.SetupFeeMargin}
											ValueChanged={this.handleChange}
											Disabled={Disabled} />
										<GenericNumberInput
											MaxValue={100}
											Suffix="%"
											Name="InterestFeeMargin"
											LabelText="Interest Fee Margin"
											Value={contractDetailModel.InterestFeeMargin}
											ValueChanged={this.handleChange}
											Disabled={Disabled} />
									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="SalesChannelId"
											LabelMd={5}
											LabelText="Channel"
											Value={contractDetailModel == null ? "" : contractDetailModel.SalesChannelId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.SalesChannelarameterCode}
											Disabled={Disabled}
											DataRoot="Item" />
										<GenericSelectInput
											Name="PaymentCalculationTypeId"
											LabelMd={5}
											LabelText="Payment Calculation Type"
											Value={contractDetailModel == null ? "" : contractDetailModel.PaymentCalculationTypeId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.LOSPaymentCalculationTypeParameterCode}
											Disabled={Disabled}
											DataRoot="Item" />
									</GridItem>
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem>
										<br />
										<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{contractDetailModel.Id > 0 ? "Update" : "Add"}</Button>
										<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel
							Title="Contract List">
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={ClientPartnerContractDetailsList}
										PageSize={5}
										HideButton={true}
										Columns={this.ColumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}
ClientPartnerContractDetails.propTypes = {
	classes: PropTypes.object,
	contractDetailModel: PropTypes.object,
	ClientPartnerModel: PropTypes.object,
	ClientPartnerContractDetailsList: PropTypes.array,
	GetClientPartnerContractDetailsModels: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	SetFile: PropTypes.func

};
export default withArtifex(ClientPartnerContractDetails, {});