import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import LoadingComponent from "views/Components/LoadingComponent";
import { EtransferEntryType, ProgramCodes } from "views/Constants/Constant";
import CreateEtransferIndividual from "views/InteraceTransfer/CreateEtransfer/CreateEtransferIndividual";

class EtransferSubmitApprove extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			model: {

			},
			IndividualModel: {

			}
		};

		this.SubmitClick = this.SubmitClick.bind(this);
		this.Nothing = this.Nothing.bind(this);
		this.FillData = this.FillData.bind(this);
		this.GetModel = this.GetModel.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	Nothing() {

	}

	SubmitClick(workflowId, after, accessToken) {
		const { Id } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/ApprovedUnsettledAndUnProcessedTransctionById",
			{ TransactionEtransferIds: [Id] },
			fileResponseData => {
				this.setState({ isLoading: false });

				if (!fileResponseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred", fileResponseData.ErrorDescription);
					return;
				}

				if (after) after();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred", errorMessage);
			},
			workflowId,
			accessToken
		);
	}

	GetModel() {
		const { model } = this.state;
		return model;
	}

	FillData(Id) {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetByIdRequest",
			{ Item: Id },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var etransferData = responseData.Item;
				var data = etransferData.Transaction;

				this.setState(function (state) {
					var model = state.model || {};
					var IndividualModel = state.IndividualModel || {};

					model.UniqueClientId = data.UniqueClientId;

					model.IsBanking = data.Program.ProgramCode != ProgramCodes.Bank_Programs_e_Transfer;

					model.EntryType = data.EntryTypeId;
					model.EntryCode = data.EntryType ? data.EntryType.ParameterValue : "";
					model.EtransferType = etransferData.PriorityTypeId;

					model.IsUpdate = true;
					IndividualModel.Id = data.Id;
					IndividualModel.PayeeId = etransferData.PayeeId;
					IndividualModel.PayeeName = etransferData.PayeeName;
					IndividualModel.PayeeEmail = etransferData.PayeeEmail;
					IndividualModel.Amount = data.TransactionAmount;
					IndividualModel.DateOfFunds = data.TransactionDate ? DateHelper.ToMoment(data.TransactionDate) : null;
					IndividualModel.MoneyReqExpDate = etransferData.MoneyRequestExpiryDate ? DateHelper.ToMoment(etransferData.MoneyRequestExpiryDate) : null;
					IndividualModel.SecurityQuestion = etransferData.SecurityQuestionId;
					IndividualModel.SecurityQuestionText = etransferData.SecurityQuestion;
					IndividualModel.SecurityQuestionAnswer = etransferData.SecurityQuestionAnswer;
					IndividualModel.TransferMemo = etransferData.Memo;
					IndividualModel.TransactionType = data.TransactionTypeId;
					IndividualModel.TransactionTypeCode = data.TransactionType ? data.TransactionType.ParameterValue : "";

					return { model, IndividualModel, Id: etransferData.Id };
				});
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", errorMessage);
			}
		);
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { model, isLoading, Id, alert } = this.state;
		const parentModel = { EntryCode: EtransferEntryType.Individual, UniqueClientId: model.UniqueClientId };

		return (
			<>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: true, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: Id }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<CreateEtransferIndividual Disabled={true} model={this.state.IndividualModel} onModelChange={this.Nothing} parentModel={parentModel} vModel={{}} isBanking={model.IsBanking} />
			</>
		);
	}
}

export default withArtifex(EtransferSubmitApprove, {});