import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class TransferToClientAccountReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {},
			isLoading: false,
			TxnDetail: {},
			IsTxnDetailOpen: false
		};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.SearchClick = this.SearchClick.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Transfer To Client Wallet Report");

		if (setAppCenterTitle)
			setAppCenterTitle("Reports");
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			return { model };
		});
	}

	ClearClick() {
		this.setState({ model: {} });
	}

	SearchClick() {
		const { showMessage } = this.props;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/TransferToClientAccountReport",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ TransactionList: responseData.Item ?? [] });
			},
			error => {
				this.setState({ isLoading: false });
				showMessage("error", "Error", error);
			}
		);
	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, alert, model, TransactionList } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
							{ Code: "Search", OnClick: this.SearchClick, Disabled: Disabled }
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card>
							<CardHeader>
								<GenericTitle text={"Filter Panel"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
											Value={model.UniqueClientId || ""}
											ValueChanged={this.ValueChanged}
											CanClear
											Disabled={Disabled}
											Required />
									</GridItem>
									<GridItem xs={3}>
										<GenericDateInput
											Name="FromDate"
											LabelText="From Date"
											Value={model.TransactionBeginDate}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											MaxDate={model.TransactionEndDate || DateHelper.GetDate()}
											Utc
											Required />
									</GridItem>
									<GridItem xs={3}>
										<GenericDateInput
											Name="ToDate"
											LabelText="To Date"
											Value={model.TransactionEndDate}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											MinDate={model.TransactionBeginDate}
											MaxDate={DateHelper.GetDate()}
											Utc
											Required />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Transaction List"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={TransactionList}
											Columns={[
												{
													Header: "From Client Name",
													accessor: "FromClient"
												},
												{
													Header: "To Client Name",
													accessor: "ToClient"
												},
												{
													Header: "Transaction Date",
													accessor: "TransactionDate",
													type: GridColumnType.DateTimeUtc,
												},
												{
													Header: "Amount",
													accessor: "Amount",
													type: GridColumnType.Money,
												},

												{
													Header: "From Wallet Number",
													accessor: "FromAccountNumber"
												},
												{
													Header: "To Wallet Number",
													accessor: "ToAccountNumber"
												},
												{
													Header: "Transaction Type",
													accessor: "TransactionType"
												},
												{
													Header: "User Email Address",
													accessor: "UserEmail"
												},

											]}
											ShowPagination={true}
											/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

}

TransferToClientAccountReport.propTypes = {
	Disabled: PropTypes.bool
};


export default TransferToClientAccountReport;