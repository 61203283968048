import PropTypes from "prop-types";
import React, { Component } from "react";
import { CardMedia, IconButton, Icon, withStyles, Drawer, Hidden } from "@material-ui/core";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";
import cx from "classnames";
const logo = require("assets/img/logo-login.svg");
class ExternalContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false
		};

		this.setDrawerState = this.setDrawerState.bind(this);

		this.drawerClasses = { paper: {} };
		this.drawerModalProps = { keepMounted: true };
	}
	setDrawerState(bool) {
		this.setState({ mobileOpen: bool });
	}
	getdrawerClasses = (classes) => {
		this.drawerClasses.paper = classes.drawer;
		return this.drawerClasses;
	}
	handleDrawerToggle = (state) => {
		const { OnClickMenu } = this.props;
		this.setState({ mobileOpen: (typeof state === "boolean") ? state : !this.state.mobileOpen });
		if (state && state.target && state.target.value !== undefined) {
			OnClickMenu && OnClickMenu(state);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const { OnDrawerChanged } = this.props;
		if (prevState.mobileOpen != this.state.mobileOpen) {
			OnDrawerChanged && OnDrawerChanged(this.state.mobileOpen);
		}
	}

	render() {
		const { Title, LeftSideItem, HeaderItem, FooterItem, children, classes, HalfWidth, Base64Logo, Subtitle } = this.props;
		const { mobileOpen } = this.state;
		return (<>
			<Hidden smUp implementation="css">
				<Drawer
					anchor="left"
					variant="temporary"
					open={mobileOpen}
					onClose={this.handleDrawerToggle}
					ModalProps={this.drawerModalProps}
					classes={this.getdrawerClasses(classes)}
				>
					<div className={classes.flex}>
						<IconButton onClick={this.handleDrawerToggle} className={classes.closeMenuButton}>
							<Icon>close</Icon>
						</IconButton>
					</div>
					{typeof LeftSideItem === "function" ? LeftSideItem() : LeftSideItem}
				</Drawer>
			</Hidden>
			<div className={cx({
				[classes.container]: true,
				[classes.containerHalf]: HalfWidth
			})}>
				<div className={classes.header}>
					<div className={classes.headerMenu}>
						{LeftSideItem &&
							<IconButton onClick={this.handleDrawerToggle} className={classes.menuButton}>
								<Icon>menu</Icon>
							</IconButton>
						}
						<CardMedia
							className={classes.mediaForLogo}
							style={{ height: "80%", marginTop: "0%"}}
							image={ Base64Logo == null ? logo.toString() : Base64Logo}
							title={ Base64Logo == null ? "DCBank" : "" }
						/>
						{HeaderItem}
					</div>
					<div style={{ textAlign: "center", 			margin: "0px auto 0px auto" }}>
						<p className={classes.headerTitle}> {Title}</p>
						<br/>
						<p className={classes.headerSubtitle}> {Subtitle}</p>
					</div>
				</div>
				<div className={classes.body}>
					{children}
				</div>
				<div className={classes.footer}>
					{typeof FooterItem === "function" ? FooterItem() : FooterItem}
					<span>
						Powered By {window.Title}
					</span>
				</div>
			</div></>
		);
	}
}

ExternalContainer.defaultProps = {
	Title: "Welcome to "+window.Title,
	Subtitle: "",
	HalfWidth: true,
	Base64Logo: null
};
ExternalContainer.propTypes = {
	Title: PropTypes.string,
	LeftSideItem: PropTypes.node,
	HeaderItem: PropTypes.node,
	FooterItem: PropTypes.node,
	OnClickMenu: PropTypes.func,
	OnDrawerChanged: PropTypes.func,
	children: PropTypes.any,
	HalfWidth: PropTypes.bool,
	classes: PropTypes.any
};
export default withStyles(externalContainerStyle)(ExternalContainer); 