import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericTextInput, GenericDialog, GenericRadioInput, GenericLabel, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import CardComponent from "views/Components/CardComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";

class MonerisSettlementReport extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: {},
			filterModel: {},
			isLoading: false
		};

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.trxTable = React.createRef();
	}


	componentDidMount() {
		this.props.setAppLeftTitle("Moneris Settlement Report");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
				model.AccountNumber1 = newValue.MaskedCardNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
				delete model.AccountNumber1;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}

	HandleClear = () => {
		this.setState({
			model: {}
		});
	};
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}
	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true })
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/MonerisSettlement/Search",
			model,
			responseData => {
				this.setState({
					isLoading: false,
					model,
					list: responseData.Item
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		const { showMessage } = this.props;

		if (!this.state.ExportReportType) {
			showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Moneris Settlement Report";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/prepaidapi/v1.0/MonerisSettlement/MonerisSettlementReportExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}


	render() {
		const { alert, model, list, isLoading } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.HandleClear
						},
						{  
							Code: "Export", 
							Disabled: false,
							OnClick: this.ExportClick
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}

				/>


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericDateInput
														Utc
														Name="FromDate"
														LabelText="From Date"
														Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
														ValueChanged={this.HandleChange}
													/>
													<GenericDateInput
														Utc
														Name="ToDate"
														LabelText="To Date"
														Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
														ValueChanged={this.HandleChange}
														MinDate={model.ToDate}
													/>
												</GridItem>
												<GridItem xs={6}>
													<GenericTextInput
														Name="FileName"
														LabelMd={4}
														LabelText="File Name"
														IsText={true}
														Value={model.FileName}
														ValueChanged={this.HandleChange}
													/>
													<GenericTextInput
														Name="MaskedCardNumber"
														LabelMd={4}
														Format={"@@@@"}
														LabelText="Card Last Four Digit"
														IsText={true}
														Value={model.MaskedCardNumber}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer spacing={16}>
					<GridItem xs={12}>

						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												ref={this.trxTable}
												Columns={
													[
														{
															Header: "Settlement Date",
															accessor: "SettlementDate",
															type: GridColumnType.DateTime
														},
														{
															Header: "File Name",
															accessor: "FileName"
														},
														{
															Header: "Message Type Indicator",
															accessor: "MessageTypeIndicator"
														},
														{
															Header: "Settlement Reference Number",
															accessor: "ExternalReferenceNumber"
														},
														{
															Header: "Transaction Reference Number",
															accessor: "TransactionReferenceNumber"
														}, {
															Header: "Masked Card Number",
															accessor: "MaskedCardNumber"
														},
														{
															Header: "Settlement Amount",
															accessor: "SettlementAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money,
														},
														{
															Header: "OriginalAmount",
															accessor: "OriginalAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Processing Fee",
															accessor: "ProcessingFee",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money,
														},
														{
															Header: "Currency",
															accessor: "CurrencyDesc"
														},

														{
															Header: "Gateway Settlement Flag",
															accessor: "GatewaySettlementFlag"
														},
														{
															Header: "Currency Code",
															accessor: "CurrencyCode"
														},
														{
															Header: "Acquirer Network Id",
															accessor: "AcquirerNetworkId"
														},
														{
															Header: "Issuer Network Id",
															accessor: "IssuerNetworkId"
														},

														{
															Header: "Card Acceptor Name",
															accessor: "CardAcceptorName"
														},
														{
															Header: "Post Condition Code",
															accessor: "PostConditionCode"
														},
														{
															Header: "Terminal Identification",
															accessor: "TerminalIdentification"
														},
														{
															Header: "Trace Number",
															accessor: "TraceNumber"
														},
														{
															Header: "Issuer Acqueirer Flag Desc",
															accessor: "IssuerAcqueirerFlagDesc"
														},
														{
															Header: "Match Status Desc",
															accessor: "MatchStatusDesc"
														},
														{
															Header: "Direction Desc",
															accessor: "DirectionDesc"
														},
													]
												}
												ShowPagination={true}
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

MonerisSettlementReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default MonerisSettlementReport;
