const genericVerificationComponentStyle = {
	verificationBase: {
		margin: "2px auto -2px auto",
		borderRadius: 50,
		padding: 10,
		fontSize: 26,
		color: "white",
		width: 46
	},
	verificationTrue: {
		backgroundColor: "green"
	},
	verificationFalse: {
		backgroundColor: "red"
	},
	verificationEmpty: {
		backgroundColor: "black"
	},
	verificationWarning: {
		backgroundColor: "orange"
	}
};

export default genericVerificationComponentStyle;