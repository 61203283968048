import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericEmailInput, GenericTextInput } from "views/Components/Generic";

const styles = ({
	...sweetAlertStyle
});

class CDICPersonalAccount extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { propModel } = this.props;
		return (
			<GridContainer>
				<GridItem xs={6}>
					<h5><b> Individual Wallet</b></h5>
					<GenericTextInput
						Name="Title"
						LabelText="Title"
						Value={propModel == null || undefined ? "" : propModel.Title == null || undefined ? "" : propModel.Title}
						Disabled={true} />
					<GenericTextInput
						Name="Gender"
						LabelText="Gender"
						Value={propModel == null || undefined ? "" : propModel.Gender == null || undefined ? "" : propModel.Gender}
						Disabled={true} />
					<GenericTextInput
						Name="Name"
						LabelText="First Name"
						Value={propModel == null || undefined ? "" : propModel.Name || ""}
						Disabled={true} />
					<GenericTextInput
						Name="MidName"
						LabelText="Middle Name"
						Value={propModel == null || undefined ? "" : propModel.MidName || ""}
						Disabled={true} />
					<GenericTextInput
						Name="Surname"
						LabelText="Last Name"
						Value={propModel == null || undefined ? "" : propModel.Surname || ""}
						Disabled={true} />
					<GenericDateInput
						Name="DateOfBirth"
						LabelText="Date Of Birth"
						Value={propModel == null || undefined ? "" : DateHelper.ToDateInputValue(propModel.DateOfBirth || "")}
						Disabled={true} />
					<GenericTextInput
						Name="Occupation"
						LabelText="Occupation"
						Value={propModel == null || undefined ? "" : propModel.Occupation || ""}
						Disabled={true} />
					<GenericTextInput
						Name="MartialStatus"
						LabelText="Martial Status"
						Value={propModel == null || undefined ? "" : propModel.MartialStatus || ""}
						Disabled={true} />
					<GenericTextInput
						Name="CountryOfCitizenShip"
						LabelText="Country of Citizenship"
						Value={propModel == null || undefined ? "" : propModel.CountryOfCitizenShip || ""}
						Disabled={true} />
					<GenericTextInput
						Name="CountryOfBirth"
						LabelText="Country of Birth"
						Value={propModel == null || undefined ? "" : propModel.CountryOfBirth || ""}
						Disabled={true} />
					<GenericTextInput
						Name="CountryOfResident"
						LabelText="Country of Resident"
						Value={propModel == null || undefined ? "" : propModel.CountryOfResident || ""}
						Disabled={true} />
				</GridItem>
				<GridItem xs={6}>
					<h5><b>Communication</b></h5>
					<GenericTextInput
						Name="Address1"
						LabelText="Address Line 1"
						Value={propModel == null || undefined ? "" : propModel.Address1 || ""}
						Disabled={true} />
					<GenericTextInput
						Name="Address2"
						LabelText="Address Line 2"
						Value={propModel == null || undefined ? "" : propModel.Address2 || ""}
						Disabled={true} />
					<GenericTextInput
						Name="Province"
						LabelText="Provience"
						Value={propModel == null || undefined ? "" : propModel.Provience || ""}
						Disabled={true} />
					<GenericTextInput
						Name="City"
						LabelText="City"
						Value={propModel == null || undefined ? "" : propModel.City || ""}
						Disabled={true} />
					<GenericTextInput
						Name="ZipCode"
						LabelText="Postal Code"
						Value={propModel == null || undefined ? "" : propModel.ZipCode || ""}
						Disabled={true} />
					<GenericTextInput
						IsPhone
						Name="PhoneNumber"
						LabelText="Phone Number"
						Value={propModel == null || undefined ? "" : propModel.PhoneNumber || ""}
						Disabled={true} />
					<GenericEmailInput
						Name="Email"
						LabelText="E-Mail"
						Value={propModel.Email}
						EndIconName=""
						Disabled={true} />
				</GridItem>
			</GridContainer>
		);
	}
}

CDICPersonalAccount.propTypes = {
	classes: PropTypes.object,
	propModel: PropTypes.object
};

export default withArtifex(CDICPersonalAccount, styles);