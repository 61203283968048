import { InputAdornment, withStyles } from "@material-ui/core";
import genericNumberInputStyle from "assets/jss/generic/genericNumberInputStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Formats } from "core/TextFormats";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import GenericIcon from "views/Components/Generic/GenericIcon.jsx";

function NumberFormatCustom(props) {
	const { IsPhone, inputRef, onChange, noFormatting, ...other } = props;
	var format = props.format;
	if (IsPhone != undefined) {
		format = Formats.Phone;
	}
	return (
		<NumberFormat
			{...other}
			format={format}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: noFormatting ? values.value : values.floatValue,
					},
				});
			}}
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	onChange: PropTypes.func.isRequired,
	noFormatting: PropTypes.bool,
	IsPhone: PropTypes.bool
};

class GenericNumberInput extends React.PureComponent {


	render() {
		const { classes, LabelText, Name, Value, ValueChanged, Disabled, BeginIconName, ClearOnClick,
			EndIconName, ClearIconName, AutoFocus, KeyPressed, KeyUp, KeyDown, Blur, Prefix, Suffix,
			ThousandSeparator, DecimalSeparator, MaxLength, IconOnClick, AllowNegative,
			DecimalScale, FixedDecimalScale, NoFormatting, LabelMd, inputProps, BeginText, EndText, ClearText,
			LabelStyle, InputStyle, MaxValue, Format, IsPhone, FloatRight, Required, IsInvalid } = this.props;

		var haveStartIcon = (BeginIconName || BeginText || "") != "";
		var haveEndIcon = (EndIconName || EndText || "") != "";
		var haveClearIcon = (ClearIconName || ClearText || "") != "";

		// var temptooltip = { ...tooltipProps };
		// if (!tooltipProps) {
		// 	temptooltip["isOpen"] = false;
		// }

		var endActions = [];
		if (haveEndIcon) {
			var endText = (EndText ? EndText : <GenericIcon className={IconOnClick ? classes.button : null}>{EndIconName}</GenericIcon>);
			endActions.push(<InputAdornment key={endActions.length} style={{ marginLeft: endActions.length * -24 }} position="end" onClick={IconOnClick}>{endText}</InputAdornment>);
		}

		if (haveClearIcon) {
			var clearText = (ClearText ? ClearText : <GenericIcon className={ClearOnClick ? classes.button : null}>{ClearIconName}</GenericIcon>);
			endActions.push(<InputAdornment key={endActions.length} style={{ marginLeft: endActions.length * -24 }} position="end" onClick={ClearOnClick}>{clearText}</InputAdornment>);
		}

		return (
			//<Tooltip className={classes.tooltip} {...temptooltip} background={blackColor} >
			<CustomInput
				floatRight={FloatRight}
				LabelMd={LabelMd}
				LabelStyle={LabelStyle}
				InputStyle={InputStyle}
				labelText={LabelText}
				Required={Required}
				IsInvalid={IsInvalid}
				formControlProps={{ fullWidth: true }}
				inputProps={{
					...inputProps,
					startAdornment: haveStartIcon ?
						<InputAdornment position="start" onClick={IconOnClick}>
							{BeginText ? BeginText :
								<GenericIcon className={IconOnClick ? classes.button : null}>{BeginIconName}</GenericIcon>
							}
						</InputAdornment>
						: null,
					endAdornment: <div>{endActions}</div>,
					value: Value != null ? Value : "",

					onChange: e => {
						if (ValueChanged)
							ValueChanged(Name, e.target.value);
					},
					disabled: Disabled,
					inputComponent: NumberFormatCustom,
					inputProps: {
						thousandSeparator: ThousandSeparator,
						decimalSeparator: DecimalSeparator,
						decimalScale: DecimalScale,
						fixedDecimalScale: FixedDecimalScale,
						allowNegative: AllowNegative,
						disabled: Disabled,
						autoFocus: AutoFocus,
						onKeyPress: KeyPressed,
						onKeyUp: KeyUp,
						onKeyDown: KeyDown,
						prefix: Prefix,
						suffix: Suffix,
						format: NoFormatting ? (e => e) : Format,
						noFormatting: NoFormatting,
						IsPhone: IsPhone,
						isAllowed: values => {
							const { floatValue, value } = values;

							if (NoFormatting) {
								return MaxLength == null || value.length <= MaxLength;
							}

							if (floatValue == null) {
								return true;
							}

							if (MaxValue != null) {
								return floatValue <= MaxValue;
							}

							return MaxLength == null || String(floatValue).length <= MaxLength;
						}
					},
					onBlur: (e) => {
						if (Blur) {
							Blur(e);
						}

					}, // Focus fix
				}}
			/>
			//	</Tooltip>
		);
	}
}

GenericNumberInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.func,
	Value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	LabelText: PropTypes.string,
	Name: PropTypes.string,
	Disabled: PropTypes.bool,
	BeginIconName: PropTypes.string,
	EndIconName: PropTypes.string,
	ClearIconName: PropTypes.string,
	BeginText: PropTypes.string,
	EndText: PropTypes.string,
	ClearText: PropTypes.string,
	AutoFocus: PropTypes.bool,
	KeyPressed: PropTypes.func,
	KeyDown: PropTypes.func,
	KeyUp: PropTypes.func,
	Blur: PropTypes.func,
	ThousandSeparator: PropTypes.string,
	DecimalSeparator: PropTypes.string,
	Prefix: PropTypes.string,
	Suffix: PropTypes.string,
	MaxLength: PropTypes.number,
	IconOnClick: PropTypes.func,
	ClearOnClick: PropTypes.func,
	AllowNegative: PropTypes.bool,
	DecimalScale: PropTypes.number,
	FixedDecimalScale: PropTypes.bool,
	NoFormatting: PropTypes.bool,
	LabelMd: PropTypes.number,
	FloatRight: PropTypes.bool,
	inputProps: PropTypes.object,
	LabelStyle: PropTypes.object,
	InputStyle: PropTypes.object,
	MaxValue: PropTypes.number,
	Format: PropTypes.string,
	IsPhone: PropTypes.bool,
	tooltipProps: PropTypes.object,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool
};

GenericNumberInput.defaultProps = {
	ThousandSeparator: ",",
	DecimalSeparator: ".",
	AllowNegative: false,
	DecimalScale: 2,
	FixedDecimalScale: true
};

export default withStyles(genericNumberInputStyle)(GenericNumberInput);