import React from "react";
import { GenericAlert } from "views/Components/Generic";

function CreateGenericAlert(title, message, type, onConfirm, showCancel, onCancel) {
	return (
		<GenericAlert
			Title={title}
			Message={message}
			Type={type}
			OnConfirm={onConfirm}
			ShowCancel={showCancel}
			OnCancel={onCancel}
		/>
	);
}

function CreateAlert(title, message, type, onConfirm) {
	return CreateGenericAlert(title, message, type, onConfirm, false, null);
}

function CreateQuestionAlert(title, message, type, onConfirm, onCancel) {
	return CreateGenericAlert(title, message, type, onConfirm, true, onCancel);
}

const AlertHelper = {
	CreateGenericAlert,
	CreateAlert,
	CreateQuestionAlert
};

export default AlertHelper;