import { DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DetailIcon, EditIcon } from "core/Icons";
import PropTypes, { bool, func } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericPage, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant";
import MessageDetail from "views/WireTransfer/CreateWireTransfer/MessageDetail";


class OutgoingWireTransfers extends GenericPage {

	constructor(props) {


		super(props);

		this.IsBackOffice = ClientHelper.IsBackOffice();
		this.sortedName = { Member: "Name" };
		this.emptyObject = {};
		this.renderItemUniqueClient = {};
		this.renderItemAccount = {};

		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName",
			},
			{
				Header: "Swift Reference Number",
				accessor: "SwiftReferenceNumber",
			},
			{
				Header: "Transaction Reference Number",
				accessor: "TransactionReferenceNumber",
			},
			{
				Header: "Source Wallet Number",
				accessor: "FromAccAccountNumber"
			},
			{
				Header: "To Wallet Number",
				accessor: "ToAccountNumber"
			},

			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "SubStatus",
				accessor: "SubStatus",
				show: this.IsBackOffice
			},

			{
				Header: "Date of Request",
				accessor: "DateRequest",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Currency",
				accessor: "CurrencyName"
			},
			{
				Header: "Remittance",
				accessor: "Remittance"
			},
			{
				Header: "Beneficiary Identifier",
				accessor: "BeneficiaryIdentifier"
			},

			{
				Header: "Swift Benefiary Wallet Number",
				accessor: "BeneficiaryAccountNumber"
			},
			{
				Header: "Swift Beneficiary Name",
				accessor: "BeneficiaryName"
			},
			{
				Header: "Bank Opearation Code",
				accessor: "BankOperationCode"
			},
			{
				Header: "Sender Wallet Number",
				accessor: "SenderAccountNumber"
			},
			{
				Header: "Sender Swift Code",
				accessor: "SenderSwiftCode"
			},
			{
				Header: "Sender Name",
				accessor: "SenderName"
			},
			{
				Header: "Sender Address",
				accessor: "SenderAddress"
			},
			{
				Header: "Sender Additional Information",
				accessor: "SenderAdditionalInformation"
			},
			{
				Header: "Ordering Institution Swift Code",
				accessor: "OrderingInstitutionSwiftCode"
			},
			{
				Header: "Ordering Institution Party Identifier",
				accessor: "OrderingInstitutionPartyIdentifier"
			},
			{
				Header: "Ordering Institution Party Address",
				accessor: "OrderingInstitutionAddress"
			},
			{
				Header: "Intermediary Name",
				accessor: "IntermediaryName"
			},
			{
				Header: "Beneficiary Bank Party Identifier",
				accessor: "BeneficiaryBankPartyIdentifier"
			},
			{
				Header: "Details Of Charges",
				accessor: "DetailsOfCharges"
			},
			{
				Header: "Sender Party Identifier Wallet",
				accessor: "WireTransferMessageSenderParty.Account"
			},
			{
				Header: "Sender Party Identifier Code",
				accessor: "WireTransferMessageSenderParty.Code"
			},
			{
				Header: "Sender Party Identifier CountryCode",
				accessor: "WireTransferMessageSenderParty.CountryCode"
			},
			{
				Header: "Sender Party Identifier Identifier",
				accessor: "WireTransferMessageSenderParty.Identifier"
			},
			{
				Header: "Beneficiary Company Name",
				accessor: "CompanyName"
			},
			{
				Header: "Country",
				accessor: "CountryFormat"
			}

		];



		this.defaultMessageModel = {
			CustomerName: null,
			AccountNumber: null,
			DateOfRequest: null,
			CreationDate: null,
			ValueDate: null,
			BeneficiaryCompanyName: null,
			BeneficiaryFirstName: null,
			BeneficiaryLastName: null,
			BeneficiaryAddress: null,
			BeneficiaryAddress2: null,
			CustomerAddress: null,
			BeneficiaryCountry: null,
			BeneficiaryCity: null,
			BeneficiaryProvince: null,
			BeneficiaryPostalCode: null,
			BeneficiaryBankCountry: null,
			BeneficiaryBankPostalCode: null,
			BeneficiaryBankInstitution: null,
			Remittance: null,
			BeneficiaryAccountNumber: null,
			IntermediarySwiftCode: null,
			Amount: null,
			Currency: null,
			PurposeOfWire: null,
			Reference: null,
			BeneficiaryBank: null,
			BeneficiaryBankAddress: null,
			BeneficiaryBankAddress2: null,
			BeneficiaryBankCity: null,
			BeneficiaryBankProvince: null,
			TransitNumber: null,
		};

		this.defaultModel = {
			ClientId: null,
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			DirectionParamValue: Direction.Incoming,
			MessageTypeId: null,
			SubStatusId: null,
			StatusId: null,
			AmountFrom: null,
			AmountTo: null,
			StatusParamCode: null,
			AccountId: null,
			UniqueClientId: null,
			SwiftReferenceNumber: null,
			TransactionReferenceNumber: null
		};

		this.state = {
			model: { ...this.defaultModel },
			subStatusList: [],
			tempSubStatusList: [],
			transactionList: [],
			approvalTransactionList: [],
			isLoading: false,
			isBackOffice: false,
			messageDetailModel: {},
			IsDomestic: {},
			detailDialog: false,
			approvalDialog: false,
			matchDialog: false
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		var { isBackOffice } = this.state;
		var model = { ...this.state.model };

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			this.columns.splice(1, 1);
		}
		this.setState({ model, isBackOffice });
		this.props.setAppLeftTitle("Wire Incoming Transfers");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	HandleChange(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "StatusId") {
				if (newValue == -1) {
					model.StatusParamCode = null;
				}
				else if (data && data.ParameterValue == null) {
					model.StatusParamCode = null;
				}
				else if (data) {
					model.StatusParamCode = data.ParameterValue;
				}
			}
			return { model };
		});
	}


	HandleMessageChange(name, newValue) {
		const { messageDetailModel } = this.state;
		messageDetailModel[name] = newValue;
		this.setState({ messageDetailModel });
	}

	GetData() {
		this.ExecutePostRequest(
			"/bankapi/v1.0/WireTransfer/TransactionSearch",
			this.state.model,
			responseData => {
				if (responseData.Item == null || responseData.Item.length < 1) {
					responseData.Item = [];
				}
				var approvalTransactionList = [];
				var transactionList = [];
				responseData.Item.forEach(element => {
					if (element.IsApproval) {
						approvalTransactionList.push(element);
					} else {
						transactionList.push(element);
					}
				});
				this.setState({ transactionList, approvalTransactionList });
			}
		);
	}

	ClearModel() {
		const model = { ...this.state.model };
		var clientId = this.state.isBackOffice ? null : model.ClientId;
		var newModel = { ...this.defaultModel };
		newModel.ClientId = clientId;
		this.setState({ model: newModel, transactionList: [], approvalTransactionList: [] });
	}



	HandleApproval() {
		const { messageDetailModel, model } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/ApproveIncomingWireTransfer",
			{
				TransactionWireTransferId: messageDetailModel.TransferId,
				TransferMessage: messageDetailModel,
				UniqueClientId: model.UniqueClientId,
				AccountId: model.AccountId
			},
			responseData => { this.CloseDialog(this.GetData); });
	}

	HandleReject() {
		const { messageDetailModel } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/RejectIncomingWireTransfer",
			{
				IsCatmData: false,
				TransactionWireTransferId: messageDetailModel.TransferId
			},
			responseData => { this.CloseDialog(this.GetData); });
	}

	HandleUnSettled() {
		const { messageDetailModel } = this.state;
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/RejectIncomingWireTransfer",
			{
				IsCatmData: true,
				TransactionWireTransferId: messageDetailModel.TransferId
			},
			responseData => { this.CloseDialog(this.GetData); });
	}


	HandleClose() {
		this.CloseDialog();
	}

	CloseDialog(func = null) {

		this.defaultModel.FromDate = this.state.model.FromDate;
		this.defaultModel.ToDate = this.state.model.ToDate;
		if (func != null) {
			this.setState({ detailDialog: false, approvalDialog: false, matchDialog: false, model: this.defaultModel, messageDetailModel: this.defaultMessageModel }, func);
		} else {
			this.setState({ detailDialog: false, approvalDialog: false, matchDialog: false, model: this.defaultModel, messageDetailModel: this.defaultMessageModel });
		}
	}

	HandleOpenDetail(templateId, transferId, isApproval) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/SearchMessage",
			{ Id: templateId },
			responseData => {
				const tempModel = { ...responseData.Item };
				tempModel.TransferId = transferId;
				tempModel.TempOrderingInstitutionSwiftCode = tempModel.OrderingInstitutionSwiftCode;
				this.setState({
					messageDetailModel: tempModel,
					detailDialog: true,
					approvalDialog: isApproval
				});
			});
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	}

	GetRenderItemAccount = (x) => {
		this.renderItemAccount = `${x.AccountNumber} - ${x.AccountName}`;
		return this.renderItemAccount;
	}

	HandleMatchDialog = () => {
		this.setState({ matchDialog: false });
	}

	render() {
		const { Disabled, classes } = this.props;
		const { IsLoading, Alert } = this.state;
		const { model, isBackOffice, messageDetailModel, matchDialog, detailDialog, approvalDialog, approvalTransactionList, transactionList } = this.state;

		const renderColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={DetailIcon}
							tooltip="Message Detail"
							Disabled={Disabled}
							OnClick={() => this.HandleOpenDetail(row.original.MessageId, 0, false)} />
						<GridButton
							Icon={EditIcon}
							tooltip="BSO Approval"
							Disabled={Disabled}
							OnClick={() => this.HandleOpenDetail(row.original.MessageId, row.original.WireTransferId, true)} />
					</div>
				)
			}, ...this.columns];


		const renderTransactionColumns = [
			{

				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={DetailIcon}
							tooltip="Message Detail"
							Disabled={Disabled}
							OnClick={() => this.HandleOpenDetail(row.original.MessageId, 0, false)} />
					</div>
				)
			},
			{
				Header: "Benefeciary Name",
				accessor: "BankBeneficiaryName"
			},
			{
				Header: "Beneficiary Wallet Number",
				accessor: "BankBeneficaryAccountNumber"

			}, ...this.columns];
		return (
			<div >
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<GenericDialog open={detailDialog} maxWidth="xl" onBackdropClick={this.HandleClose}>
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Transaction Details</b></h4>
						<br />
						<MessageDetail
							CustomerName={messageDetailModel.CustomerName}
							AccountNumber={messageDetailModel.AccountNumber}
							DateOfRequest={messageDetailModel.DateOfRequest}
							CreationDate={messageDetailModel.CreationDate}
							ValueDate={messageDetailModel.ValueDate}
							BeneficiaryName={messageDetailModel.BeneficiaryName}
							BeneficiaryAddress={messageDetailModel.BeneficiaryAddress}
							BeneficiaryAddress2={messageDetailModel.BeneficiaryAddress2}
							CustomerAddress={messageDetailModel.CustomerAddress}
							BeneficiaryCountry={messageDetailModel.BeneficiaryCountry}
							BeneficiaryCity={messageDetailModel.BeneficiaryCity}
							BeneficiaryProvince={messageDetailModel.BeneficiaryProvince}
							BeneficiaryPostalCode={messageDetailModel.BeneficiaryPostalCode}
							BeneficiaryBankCountry={messageDetailModel.BeneficiaryBankCountry}
							BeneficiaryBankPostalCode={messageDetailModel.BeneficiaryBankPostalCode}
							BeneficiaryBankInstitution={messageDetailModel.BeneficiaryBankInstitution}
							Remittance={messageDetailModel.Remittance}
							BeneficiaryAccountNumber={messageDetailModel.BeneficiaryAccountNumber}
							IntermediarySwiftCode={messageDetailModel.IntermediarySwiftCode}
							Amount={messageDetailModel.Amount}
							Currency={messageDetailModel.Currency}
							PurposeOfWire={messageDetailModel.PurposeOfWire}
							Reference={messageDetailModel.Reference}
							BeneficiaryBank={messageDetailModel.BeneficiaryBank}
							BeneficiaryBankAddress={messageDetailModel.BeneficiaryBankAddress}
							BeneficiaryBankAddress2={messageDetailModel.BeneficiaryBankAddress2}
							BeneficiaryBankCity={messageDetailModel.BeneficiaryBankCity}
							BeneficiaryBankProvince={messageDetailModel.BeneficiaryBankProvince}
							TransitNumber={messageDetailModel.TransitNumber}
							BeneficiaryIdentifier={messageDetailModel.BeneficiaryIdentifier}
							BeneficiaryBankPartyIdentifier={messageDetailModel.BeneficiaryBankPartyIdentifier}
							OrderingInstitutionSwiftCode={messageDetailModel.OrderingInstitutionSwiftCode}
							TempOrderingInstitutionSwiftCode={messageDetailModel.TempOrderingInstitutionSwiftCode}

							SenderName={messageDetailModel.SenderName}
							SenderAccountNumber={messageDetailModel.SenderAccountNumber}
							SenderSwiftCode={messageDetailModel.SenderSwiftCode}
							SenderAddress={messageDetailModel.SenderAddress}
							SenderAdditionalInformation={messageDetailModel.SenderAdditionalInformation}

							OrderingInstitutionPartyIdentifier={messageDetailModel.OrderingInstitutionPartyIdentifier}
							OrderingInstitutionAddress={messageDetailModel.OrderingInstitutionAddress}

							LastUser={messageDetailModel.LastUser}
							SubStatus={messageDetailModel.SubStatus}

							ValueChanged={this.HandleMessageChange}
							Disabled={true} />
						<br />
					</DialogContent>
					<GenericDialogActions>
						{approvalDialog &&
							<Button size="sm" onClick={() => this.setState({ matchDialog: true })}>
								Approve
							</Button>
						}
						{approvalDialog &&
							<Button size="sm" onClick={this.HandleReject}>
								Reject
							</Button>
						}
						{approvalDialog &&
							<Button size="sm" onClick={this.HandleUnSettled}>
								Old System
							</Button>
						}
						<Button size="sm" onClick={this.HandleClose}>
							Close
						</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={matchDialog} maxWidth="md" fullWidth >

					<DialogContent className={classes.dialog}>
						<GridContainer xs={12}>
							<GridItem xs={8}>
								<GenericSelectInput
									Name="UniqueClientId"
									LabelText="Client"
									Method="POST"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Parameter={this.emptyObject}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									RenderItem={this.GetRenderItemUniqueClient}
									Sorted={this.sortedName}
									Value={model.UniqueClientId}
									ValueChanged={this.HandleChange}
								/>

								<GenericSelectInput
									key={model.UniqueClientId}
									Name="AccountId"
									LabelText="Wallet"
									Method="POST"
									Url="/bankapi/v1.0/Account/GetAllAccountByUniqueClient"
									Parameter={{
										UniqueClientId: model.UniqueClientId
									}}
									DataRoot="Item"
									KeyValueMember="AccountId"
									TextValueMember="AccountNumber"
									RenderItem={this.GetRenderItemAccount}
									Sorted={{ Member: "AccountNumber" }}
									Value={model.AccountId}
									ValueChanged={this.HandleChange}
								/>
							</GridItem>
							<GridItem style={{ visibility: "hidden" }}>
								<GenericTextInput
									LabelText="Empty"
								/>

								<GenericTextInput
									LabelText="Empty"
								/>
								<GenericTextInput
									LabelText="Empty"
								/>
								<GenericTextInput
									LabelText="Empty"
								/>

							</GridItem>

							<GenericLabel Text={"Are you sure you want to enter this transaction ?"} Bold={true} FontSize="20ox"
							/>

						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleApproval}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ matchDialog: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>




				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={true}>
							<GridContainer>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericSelectInput
												Name="ClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.ClientId || ""}
												ValueChanged={this.HandleChange}
												All
												CanClear
												Disabled={!isBackOffice} />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Utc={true}
												Value={model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.HandleChange}
												MaxDate={model.ToDate || DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Utc={true}
												Value={model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate == null ? null : model.FromDate}
												MaxDate={DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<ParameterComponent
												All
												Name="StatusId"
												Value={model.StatusId}
												LabelText="Status"
												ParameterCode="WireStatus"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												key={"wiresubstatus_" + model.StatusId}
												Name="SubStatusId"
												Value={model.SubStatusId}
												LabelText="Sub-Status"
												ParameterCode="WireSubStatus"
												ParameterValue2={model.StatusParamCode}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput Name="TransactionReferenceNumber" LabelText="Transaction Reference Number" Value={model.TransactionReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem>
										
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountFrom"
												LabelText="Amount From"
												Value={model.AmountFrom == null ? "" : model.AmountFrom}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountTo"
												LabelText="Amount To"
												Value={model.AmountTo == null ? "" : model.AmountTo}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput Name="SwiftReferenceNumber" LabelText="Swift Reference Number" Value={model.SwiftReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<br />
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"BSO Approval List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={approvalTransactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={renderColumns}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
									ShowPagination={true}

								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={transactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={renderTransactionColumns}
									ShowPagination={true}

								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >
		);
	}
}

OutgoingWireTransfers.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(OutgoingWireTransfers, {});