import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput, GenericTextInput } from "views/Components/Generic";


class Tcr7 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};
		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength2 = { maxLength: 2 };
		this.maxLength30 = { maxLength: 30 };
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}


	render() {

		const { model } = this.props;
		return (
			<div >

				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericTextInput
									Name="TransactionType"
									LabelMd={6}
									LabelText="Transaction Type"
									inputProps={this.maxLength2}
									Value={model && model.TransactionType}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="CardSequenceNumber"
									LabelMd={6}
									LabelText="Card Sequence Number"
									inputProps={this.maxLength3}
									Value={model && model.CardSequenceNumber}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="TerminalTransactionDate"
									LabelMd={6}
									LabelText="TerminalTransactionDate"
									inputProps={this.maxLength12}
									Value={model && model.TerminalTransactionDate}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="TerminalCapabilityProfile"
									LabelMd={6}
									LabelText="Terminal Capability Profile"
									inputProps={this.maxLength6}
									Value={model && model.TerminalCapabilityProfile}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="TerminalCountryCode"
									LabelMd={6}
									LabelText="Terminal Country Code"
									inputProps={this.maxLength3}
									Value={model && model.TerminalCountryCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="TerminalSerialNumber"
									LabelMd={6}
									LabelText="Terminal Serial Number"
									inputProps={this.maxLength8}
									Value={model && model.TerminalSerialNumber}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="UnpredictableNumber"
									LabelMd={6}
									LabelText="Unpredictable Number"
									inputProps={this.maxLength4}
									Value={model && model.UnpredictableNumber}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="AppTransactionCounter"
									LabelMd={6}
									LabelText="AppTransaction Counter"
									inputProps={this.maxLength4}
									Value={model && model.AppTransactionCounter}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AppInterchangeProfile"
									LabelMd={6}
									LabelText="App Interchange Profile"
									inputProps={this.maxLength4}
									Value={model && model.AppInterchangeProfile}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="MarketSpecificAuthDataIndicator"
									LabelMd={6}
									LabelText="Market Specific Auth Data Indicator"
									inputProps={this.maxLength12}
									Value={model && model.MarketSpecificAuthDataIndicator}
									ValueChanged={this.HandleChange} />
							</GridItem>



							<GridItem xs={4}>

								<GenericTextInput
									Name="Cryptogram"
									LabelMd={6}
									LabelText="Cryptogram"
									inputProps={this.maxLength16}
									Value={model && model.Cryptogram}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerApplicationData2"
									LabelMd={6}
									LabelText="Issuer Application Data2"
									inputProps={this.maxLength2}
									Value={model && model.IssuerApplicationData2}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="IssuerApplicationData3"
									LabelMd={6}
									LabelText="Issuer Application Data3"
									inputProps={this.maxLength2}
									Value={model && model.IssuerApplicationData3}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="TerminalVerificationResults"
									LabelMd={6}
									LabelText="Terminal Verification Results"
									inputProps={this.maxLength10}
									Value={model && model.TerminalVerificationResults}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="IssuerApplicationData4"
									LabelMd={6}
									LabelText="Issuer Application Data4"
									inputProps={this.maxLength8}
									Value={model && model.IssuerApplicationData4}
									ValueChanged={this.HandleChange} />

							</GridItem>


							<GridItem xs={4}>

								<GenericNumberInput
									Name="CryptogramAmount"
									LabelMd={6}
									LabelText="Cryptogram Amount"
									inputProps={this.maxLength17}
									Value={model && model.CryptogramAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerApplicationData8"
									LabelMd={6}
									LabelText="Issuer Application Data8"
									inputProps={this.maxLength2}
									Value={model && model.IssuerApplicationData8}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerApplicationData9"
									LabelMd={6}
									LabelText="Issuer Application Data9"
									inputProps={this.maxLength16}
									Value={model && model.IssuerApplicationData9}
									ValueChanged={this.HandleChange} />



								<GenericTextInput
									Name="IssuerApplicationData1"
									LabelMd={6}
									LabelText="Issuer Application Data1"
									inputProps={this.maxLength2}
									Value={model && model.IssuerApplicationData1}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerApplicationData17"
									LabelMd={6}
									LabelText="Issuer Application Data17"
									inputProps={this.maxLength2}
									Value={model && model.IssuerApplicationData17}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerApplicationData18"
									LabelMd={6}
									LabelText="Issuer Application Data18"
									inputProps={this.maxLength30}
									Value={model && model.IssuerApplicationData18}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="FormFactorIndicator"
									LabelMd={6}
									LabelText="Form Factor Indicator"
									inputProps={this.maxLength8}
									Value={model && model.FormFactorIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerScript1Results"
									LabelMd={6}
									LabelText="Issuer Script1 Results"
									inputProps={this.maxLength10}
									Value={model && model.IssuerScript1Results}
									ValueChanged={this.HandleChange} />
							</GridItem>


						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

Tcr7.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default Tcr7;