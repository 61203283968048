import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { chartColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { DynamicComponent } from "core";
import { ApproveIcon, DeleteIcon, DetailIcon, ProfileIcon } from "core/Icons";
import ResourceHelper from "core/ResourceHelper";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import { Chart } from "react-chartjs-2";
import AmlKycAlertChart from "views/AmlKyc/AmlKycAlert/AmlKycAlertChart.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import ParameterComponent from "views/Components/ParameterComponent";
import { CanadaPostIdVerificationStatus, ClientType, GridColumnType } from "views/Constants/Constant";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName } from "views/Constants/Constant.Sorted";
import UserDefinition from "views/UserAdmin/UserDefinition";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const chartStyles = ({
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	},
	circleBoxGrid: {
		width: "14%",
		textAlign: "center"
	}
});

class AmlKycAlert extends React.Component {
	constructor(props) {
		super(props);

		this.chartDataContentTotal = {
			labels: ["Total Issues"],
			datasets: [
				{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}
			],
			text: ""
		};
		this.chartDataContentProgram1 = {
			labels: ["Program 1"],
			datasets: [
				{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}
			],
			text: ""
		};
		this.chartDataContentProgram2 = {
			labels: ["Program 2"],
			datasets: [
				{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}
			],
			text: ""
		};
		this.chartDataContentProgram3 = {
			labels: ["Program 3"],
			datasets: [
				{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}
			],
			text: ""
		};
		this.chartDataContentOther = {
			labels: ["Other Issues"],
			datasets: [
				{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}
			],
			text: ""
		};
		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			}
		};

		this.columnSearchDetail = [];
		this.columnsAmlDetail = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="User Detail"
							Icon={ProfileIcon}
							OnClick={() => this.UserDetailDialogOpen(row.original.UserId)} />
					</div>
				),
				width: 50
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Transaction Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			}
		];
		this.columnsKycDetail = [];

		this.state = {
			model: {},
			kycScenarioLogData: {
				MenuAction: {
					Menu: {
					}
				}
			},
			searchDetail: [],
			graphicDetail: [],
			transactionDetail: [],
			kycScenarioLogDetail: [],
			chartDataTotal: this.chartDataContentTotal,
			chartDataProgram1: this.chartDataContentProgram1,
			chartDataProgram2: this.chartDataContentProgram2,
			chartDataProgram3: this.chartDataContentProgram3,
			chartDataOther: this.chartDataContentOther,
			titleChartProgram1: "",
			titleChartProgram2: "",
			titleChartProgram3: "",
			selected: null,
			isKycApproveDialogOpen: false,
			isKycDetailDialogOpen: false,
			isCanadaPostVerificationScreenDialogOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Alert Management");
		}

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);

			}
		});

		this.GetColumnsSearchDetail();
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = {};

			if (name == "AmlKycId") {
				model[name] = value;
				model["IsAml"] = value != null
					? value == "Aml"
					: undefined;

				if (model.IsAml == false) {
					this.GetColumnsKycDetail();
				}

				return {
					model, searchDetail: [], graphicDetail: [], transactionDetail: [],
					chartDataTotal: this.chartDataContentTotal,
					chartDataProgram1: this.chartDataContentProgram1,
					chartDataProgram2: this.chartDataContentProgram2,
					chartDataProgram3: this.chartDataContentProgram3,
					chartDataOther: this.chartDataContentOther,
					selected: null
				};
			}

			model = state.model;
			model[name] = value;
			if (name === "ClientTypeId") {
				model["IsCorporate"] = data != null
					? data.ParameterValue == ClientType.Corporate
					: undefined;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { searchDetail } = this.state;

		this.setState({ transactionDetail: searchDetail[index].TransactionDetail, selected: index });
	}

	GetColumnsSearchDetail = () => {
		const { model } = this.state;
		const { history } = this.props;

		this.columnSearchDetail = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Scenario Detail"
							Icon={DetailIcon}
							OnClick={() => {
								RouteHelper.Push(history, "/AMLKYCScenario", "AmlKycScenarioScenarioDetail", {
									IsAml: model.IsAml,
									ScenarioCode: row.original.Code
								});
							}} />
					</div>
				),
				width: 50
			},
			{
				Header: "Scenario Description",
				accessor: "Description"
			},
			{
				Header: "Program",
				accessor: "Program"
			},
			{
				Header: "Number of Transaction",
				accessor: "TransactionCount"
			}
		];

		this.forceUpdate();
	}

	GetColumnsKycDetail = () => {
		this.columnsKycDetail = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={ApproveIcon}
							OnClick={() => this.KycApproveDialogOpen(row.original.KycScenarioLog, row.original.CanadaPostVerification)} />
						<GridButton
							Icon={DeleteIcon}
							OnClick={() => this.KycRejectConfirm(row.original.KycScenarioLog.Id)} />
						<GridButton
							Icon={DetailIcon}
							OnClick={() => this.KycDetailDialogOpen(row.original.KycScenarioLog.Id)} />
					</div>
				),
				width: 150,
				sortable: false,
				filterable: false,
			},
			{
				Header: "Menu",
				accessor: "KycScenarioLog.MenuAction.Menu.Name",
				Cell: row => (
					<div>{ResourceHelper.GetForMenu(row.value)}</div>
				)
			},
			{
				Header: "Action",
				accessor: "KycScenarioLog.MenuAction.Action.Description"
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			}
		];
	}

	GetRenderItemUniqueClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	KycApproveDialogOpen = (kycScenarioLogData, canadaPostVerification) => {
		if (canadaPostVerification != null
			&& canadaPostVerification.VerificationStatus != null
			&& canadaPostVerification.VerificationStatus.ParameterValue != CanadaPostIdVerificationStatus.Cleared) {
			var { model } = this.state;

			model.CanadaPostVerificationId = canadaPostVerification.Id;

			this.setState({ model });
			this.CanadaPostVerificationScreenDialogOpen();

			return;
		}

		kycScenarioLogData.ApprovalData = {
			"JsonData": kycScenarioLogData.JsonData,
			"ActionCode": kycScenarioLogData.MenuAction.Action.Code,
			"ActionName": kycScenarioLogData.MenuAction.Action.Description,
			"ApprovalStatus": "KYC",
			"ApprovalStatusCode": "KYC",
			"CurrentStep": null,
			"KycId": 0,
			"MenuCode": kycScenarioLogData.MenuAction.Menu.Code,
			"MenuId": kycScenarioLogData.MenuAction.MenuId,
			"MenuNameCode": kycScenarioLogData.MenuAction.Menu.Name,
			"MenuPath": kycScenarioLogData.MenuAction.Menu.Path,
			"ScreenId": kycScenarioLogData.MenuAction.Menu.ScreenId,
			"SubStatus": ""
		};

		this.setState({ kycScenarioLogData, isKycApproveDialogOpen: true });
	}

	KycDetailDialogOpen = async (kycScenarioLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycScenarioLog/GetDetailById", { KycScenarioLogId: kycScenarioLogId });

		if (result != null) {
			this.setState({ kycScenarioLogDetail: result, isKycDetailDialogOpen: true });
		}
	}

	CanadaPostVerificationScreenDialogOpen = () => {
		this.setState({ isCanadaPostVerificationScreenDialogOpen: true });
	}

	UserDetailDialogOpen = (userId) => {
		this.setState({ userId, isUserDetailDialogOpen: true });
	}

	KycApproveDialogClose = () => {
		this.setState({ isKycApproveDialogOpen: false });
		this.Search();
	}

	KycDetailDialogClose = () => {
		this.setState({ isKycDetailDialogOpen: false });
	}

	CanadaPostVerificationScreenDialogClose = () => {
		this.setState({ isCanadaPostVerificationScreenDialogOpen: false });
	}

	UserDetailDialogClose = () => {
		this.setState({ isUserDetailDialogOpen: false });
	}

	KycRejectConfirm = (kycScenarioLogId) => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;

		showConfirmMessage("warning", "Warning", "Are you sure want to reject ?", () => ExecuteApiPost("/amlkycapi/v1.0/KycScenarioLog/Delete", { Id: kycScenarioLogId }, null, null, null, [this.Search], true));
	}

	CanadaPostVerificationScreenOpen = () => {
		const { model } = this.state;
		const { history } = this.props;

		RouteHelper.Push(history, "/CanadaPostVerification", "CanadaPostVerificationFromAmlKycAlert", {
			CanadaPostVerificationId: model.CanadaPostVerificationId
		});
	}


	Search = async () => {
		if (!this.Validate()) {
			return;
		}

		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`${model.IsAml ? "/amlkycapi/v1.0/AmlScenarioLog/Search" : "/amlkycapi/v1.0/KycScenarioLog/Search"}`, model);
		var searchDetail = [], graphicDetail = [];

		if (result != null) {
			searchDetail = result.SearchDetail || [];
			graphicDetail = result.GraphicDetail || [];

			var arrayData1 = [];
			var arrayData2 = [];
			var arrayData3 = [];
			var arrayData4 = [];
			var arrayData5 = [];
			var arrayLabel1 = [];
			var arrayLabel2 = [];
			var arrayLabel3 = [];
			var arrayLabel4 = [];
			var arrayLabel5 = [];
			var arrayColor1 = [];
			var arrayColor2 = [];
			var arrayColor3 = [];
			var arrayColor4 = [];
			var arrayColor5 = [];
			var colorNames = Object.keys(chartColors);
			var i = 0;
			var colorName = colorNames[i % colorNames.length];
			var newColor = chartColors[colorName];

			arrayColor1.push(newColor);
			arrayData1.push(graphicDetail[0].TransactionCount);
			arrayLabel1.push("Total Issues");

			var chartDataTotal = {
				labels: arrayLabel1,
				datasets: [
					{
						data: arrayData1,
						hoverBackgroundColor: arrayColor1,
						backgroundColor: arrayColor1
					}
				],
				text: graphicDetail[0].TransactionCount
			};

			arrayColor2.push(newColor);
			arrayData2.push(graphicDetail[0].TransactionCount - graphicDetail[1].TransactionCount);
			arrayData2.push(graphicDetail[1].TransactionCount);

			arrayLabel2.push("Other Issues");
			arrayLabel2.push(graphicDetail[1].Program);

			var chartDataProgram1 = {
				labels: arrayLabel2,
				datasets: [
					{
						data: arrayData2,
						hoverBackgroundColor: arrayColor2,
						backgroundColor: arrayColor2
					}
				],
				text: graphicDetail[1].TransactionCount
			};
			var titleChartProgram1 = graphicDetail[1].Program;

			arrayColor3.push(newColor);
			arrayData3.push(graphicDetail[0].TransactionCount - graphicDetail[2].TransactionCount);
			arrayData3.push(graphicDetail[2].TransactionCount);

			arrayLabel3.push("Other Issues");
			arrayLabel3.push(graphicDetail[2].Program);

			var chartDataProgram2 = {
				labels: arrayLabel3,
				datasets: [
					{
						data: arrayData3,
						hoverBackgroundColor: arrayColor3,
						backgroundColor: arrayColor3
					}
				],
				text: graphicDetail[2].TransactionCount
			};
			var titleChartProgram2 = graphicDetail[2].Program;

			arrayColor4.push(newColor);
			arrayData4.push(graphicDetail[0].TransactionCount - graphicDetail[3].TransactionCount);
			arrayData4.push(graphicDetail[3].TransactionCount);

			arrayLabel4.push("Other Issues");
			arrayLabel4.push(graphicDetail[3].Program);

			var chartDataProgram3 = {
				labels: arrayLabel4,
				datasets: [
					{
						data: arrayData4,
						hoverBackgroundColor: arrayColor4,
						backgroundColor: arrayColor4
					}
				],
				text: graphicDetail[3].TransactionCount
			};
			var titleChartProgram3 = graphicDetail[3].Program;

			arrayColor5.push(newColor);
			arrayData5.push(graphicDetail[4].TransactionCount == 0 ? 100 : graphicDetail[4].TransactionCount);
			arrayLabel5.push("Other Issues");

			var chartDataOther = {
				labels: arrayLabel5,
				datasets: [
					{
						data: arrayData5,
						hoverBackgroundColor: arrayColor5,
						backgroundColor: arrayColor5
					}
				],
				text: graphicDetail[4].TransactionCount
			};
		}

		this.setState({
			searchDetail, graphicDetail, transactionDetail: [],
			chartDataTotal, chartDataProgram1, chartDataProgram2, chartDataProgram3, chartDataOther,
			titleChartProgram1, titleChartProgram2, titleChartProgram3, selected: null
		});
		this.GetColumnsSearchDetail();
	}

	Clear = (clickClearButton = false) => {
		var model = {};

		if (!clickClearButton) {
			const { AmlKycId, IsAml } = this.state.model;

			model.AmlKycId = AmlKycId;
			model.IsAml = IsAml;
		}

		this.setState({
			model, searchDetail: [], graphicDetail: [], transactionDetail: [],
			selected: null
		});
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.AmlKycId) {
			errors.push("AML or KYC can not be null.");
		}
		if (model.IsAml == true
			&& !model.ClientTypeId) {
			errors.push("Client type can not be null.");
		}
		if (model.LastPeriodId
			&& (model.FromDate || model.ToDate)) {
			errors.push("You can choose last or date filter same time.");
		}

		model["IsAmlKycId"] = !model.AmlKycId;
		model["IsClientTypeId"] = model.IsAml == true && !model.ClientTypeId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, kycScenarioLogData, searchDetail, transactionDetail, graphicDetail, kycScenarioLogDetail,
			chartDataTotal, chartDataProgram1, chartDataProgram2, chartDataProgram3, chartDataOther,
			titleChartProgram1, titleChartProgram2, titleChartProgram3, selected, userId,
			isKycApproveDialogOpen, isKycDetailDialogOpen, isCanadaPostVerificationScreenDialogOpen, isUserDetailDialogOpen } = this.state;
		const { Disabled } = this.props;
		var kycDetailIndex = 0;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: () => this.Clear(true)
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isKycApproveDialogOpen} fullScreen onBackdropClick={this.KycApproveDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<DynamicComponent
							Disabled
							route={kycScenarioLogData.MenuAction.Menu.Path}
							screenId={kycScenarioLogData.MenuAction.Menu.ScreenId}
							menuId={kycScenarioLogData.MenuAction.MenuId}
							MenuCode={kycScenarioLogData.MenuAction.Menu.Code}
							ApprovalData={kycScenarioLogData.ApprovalData}
							After={this.KycApproveDialogClose} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.KycApproveDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isKycDetailDialogOpen} onBackdropClick={this.KycDetailDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="KYC Scenario Log Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						{
							kycScenarioLogDetail.map(x => {
								kycDetailIndex++;
								return (
									<GridContainer>
										<GenericTextInput
											Disabled
											key={kycDetailIndex}
											LabelText={x.DetailItem}
											Value={x.DetailItemStatus} />
									</GridContainer>
								);
							})
						}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.KycDetailDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isCanadaPostVerificationScreenDialogOpen} onBackdropClick={this.CanadaPostVerificationScreenDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Canada Post Verification"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GenericLabel
							Bold
							Text="Canada post verification status is not clear. If you want to verify by Barcode, click the 'Canada Post Verification's button." />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CanadaPostVerificationScreenOpen}>Canada Post Verification</Button>
						<Button size="sm" onClick={this.CanadaPostVerificationScreenDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isUserDetailDialogOpen} fullScreen onBackdropClick={this.UserDetailDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<UserDefinition
							Disabled
							Id={userId} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.UserDetailDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridItem xs={4}>
									<GenericSelectInput
										Required
										IsStatic
										IsInvalid={model.IsAmlKycId}
										Name="AmlKycId"
										LabelText="AML or KYC"
										StaticData={[{ Id: "Aml", Name: "AML" }, { Id: "Kyc", Name: "KYC" }]}
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.AmlKycId}
										ValueChanged={this.ValueChanged} />
								</GridItem>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}  >
											<ParameterComponent
												Disabled={model.IsAml == false}
												Required={model.IsAml == true}
												IsInvalid={model.IsClientTypeId}
												Name="ClientTypeId"
												LabelText="Client Type"
												ParameterCode="ClientType"
												ParameterValue2="C"
												Value={model.ClientTypeId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												All
												Disabled={model.IsAml == false}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemUniqueClient}
												Sorted={SortedName}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Name="LastPeriodId"
												LabelText="Last"
												ParameterCode="AmlKycLastPeriod"
												Value={model.LastPeriodId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												IncludeTime={false}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ display: graphicDetail.length == 0 || graphicDetail[0].TransactionCount == 0 ? "none" : "inherit" }}>
							<CardBody>
								<GridContainer direction="column" justify="flex-start" alignItems="stretch">
									<AmlKycAlertChart
										chartDataTotal={chartDataTotal}
										chartDataProgram1={chartDataProgram1}
										chartDataProgram2={chartDataProgram2}
										chartDataProgram3={chartDataProgram3}
										chartDataOther={chartDataOther}
										titleChartProgram1={titleChartProgram1}
										titleChartProgram2={titleChartProgram2}
										titleChartProgram3={titleChartProgram3} />
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Pending" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={searchDetail}
													Columns={this.columnSearchDetail}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ display: model.IsAml == null ? "none" : "inherit" }}>
							<CardHeader>
								<GenericTitle text="Transaction Detail" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={transactionDetail}
													Columns={model.IsAml ? this.columnsAmlDetail : model.IsAml == null ? [] : this.columnsKycDetail}
													SelectedIndex={selected} />
											</GridItem>
										</GridContainer>
									</GridItem>"
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

AmlKycAlert.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(chartStyles)(AmlKycAlert);