import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class ClientPartnerAddress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			addressModel: {
				CountryId: null,
				CityId: null,
				ProvinceId: null
			},
			ClientPartnerAddressList: [],
			isLoading: false,
			alert: null,
			CustomDisabled: null
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.ParameterAddressType = {
			ParameterCode: "AddressType"
		};
		this.ParameterAddressTypeIndv = {
			ParameterCode: "AddressType",
			ParameterValue3: "P"
		};
		this.ParameterAddressVerifiedStatusType = {
			ParameterCode: "LOSVerifiedStatus"
		};
		this.ParameterAddressVerifiedMethodType = {
			ParameterCode: "LOSAddressMeth"
		};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({
								addressModel: {
									...row.original
								}
							})} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			}, {
				Header: "Address Type",
				accessor: "AddressType.ParameterDesc"
			}, {
				Header: "Address Line 1",
				accessor: "Address1"
			}, {
				Header: "Address Line 2",
				accessor: "Address2"
			}, {
				Header: "Postal Code",
				accessor: "ZipCode"
			}, {
				Header: "City",
				accessor: "City.Name"
			}, {
				Header: "Province / State",
				accessor: "Province.Name"
			}, {
				Header: "Country",
				accessor: "Country.Name"
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Address");	this.getappLeftTitle = "Address";

		var model = {
			Id: 0,
			UniqueClientId: 0
		};
		this.setState({ addressModel: model, ClientPartnerAddressList: this.props.ClientPartnerAddressList });

		this.LoadCanadaPost();
		// this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedAddress,
		// ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}
	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	LoadCanadaPost() {

		var parent = this;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/coreapi/v1.0/Parameter/Search", {
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			}, responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);

				// @ts-ignore
				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;

				var pca;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;
				var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document
					.head
					.appendChild(script);
				script.onload = function () {
					// @ts-ignore
					pca = window.pca;
					if (pca != null) {
						var fields = [
							{
								element: "street-address",
								field: "Line1",
								mode: pca.fieldMode.SEARCH
							}, {
								element: "street-address2",
								field: "Line2",
								mode: pca.fieldMode.POPULATE
							}, {
								element: "postcode",
								field: "PostalCode",
								mode: pca.fieldMode.SEARCH
							}
						],
							options = {
								key: apikey
							},
							control = new pca.Address(fields, options);
							CanadaPostRequest();

						control.listen("populate", function (address) {
							CanadaPostSubmit(address);
							parent.setState(state => {

								state.addressModel.Address1 = address.Line1;
								state.addressModel.Address2 = address.Line2;
								state.addressModel.ZipCode = address.PostalCode;

								return state;
							});
						});
					}
				};
			}, e => {
				console.log("Canada Post Api key not fetched !");
			}));
	}
	SubmitCanadaPostAddressModel = (model) => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/coreapi/v1.0/Country/CanadaPost", model, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var temp = {
					...this.state.addressModel
				};
				//var address = { ...this.state.model };
				if (responseData.Item != null) {
					temp.CountryId = responseData.Item.CountryId;
					temp.ProvinceId = responseData.Item.ProvinceId;
					temp.CityId = responseData.Item.CityId;
				} else {
					temp.CountryId = 0;
					temp.ProvinceId = 0;
					temp.CityId = 0;
				}
				this.setState({ addressModel: temp });
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	} 
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.props.getappCenterTitle,
			AppLeftTitle:this.props.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.addressModel;
		const { vModel } = this.state;
		if (data == null) {
			ErrorList.push("Address model not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.AddressTypeId == null || data.AddressTypeId == "") {
			vModel.AddressTypeId = true;
			ErrorList.push("Address type must be defined.");
		}
		else {
			vModel.AddressTypeId = false;
		}
		if (data.Address1 == null || data.Address1 == "") {
			vModel.Address1 = true;
			ErrorList.push("Address1 must be defined.");
		}
		else {
			vModel.Address1 = false;
		}
		if (data.ZipCode == null || data.ZipCode == "") {
			vModel.ZipCode = true;
			ErrorList.push("Zip code must be defined.");
		}
		else {
			vModel.ZipCode = false;
		}
		if (data.CityId == null || data.CityId == "") {
			vModel.CityId = true;
			ErrorList.push("City must be defined.");
		}
		else {
			vModel.CityId = false;
		}
		if (data.ProvinceId == null || data.ProvinceId == "") {
			vModel.ProvinceId = true;
			ErrorList.push("Province must be defined.");
		}
		else {
			vModel.ProvinceId = false;
		}
		if (data.CountryId == null || data.CountryId == "") {
			vModel.CountryId = true;
			ErrorList.push("Country must be defined.");
		}
		else {
			vModel.CountryId = false;
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	UpdateModel = () => {
		var { ClientPartnerModel } = this.props;

		var temp = this.state.addressModel;
		temp.ClientPartnerId = ClientPartnerModel.Id;

		this.setState({ isLoading: true });
		Proxy.POST("/losapi/v1.0/ClientPartnerAddress/InsertUpdate", temp, responseData => {
			if (!responseData.IsSucceeded) {
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			}
			this.ShowMessage("success", "Success", temp.Id > 0
				? "Client Partner Address Updated"
				: "Client Partner Address Saved");
			this.ClearData();
			this
				.props
				.GetClientPartnerAddressModels();
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Warning", "Client address must be selected for delete operation.");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST("/losapi/v1.0/ClientPartnerAddress/Delete", {
			Id: model.Id
		}, responseData => {
			if (!responseData.IsSucceeded) {
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			}
			this.ShowMessage("success", "Success", "Client Partner Address Deleted");
			this.ClearData();
			this
				.props
				.GetClientPartnerAddressModels();
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.ClientPartnerAddressList[index];
		this.setState({
			addressModel: model || {}
		});
	}
	ClearData = () => {
		var model = {
			Id: 0
		};
		this.setState({ addressModel: model });
	}
	handleChange = (name, newValue, data) => {
		this
			.setState(function (state, props) {
				var model = state.addressModel || {};
				model[name] = newValue;
				return { addressModel: model };
			});
	}
	handleChangeCP = (data) => {
		if (this.state.addressModel != null && this.state.addressModel.AddressTypeId) {
			data.AddressTypeId = this.state.addressModel.AddressTypeId;
			data.Location = this.state.addressModel.Location;
		}
		this.setState({ addressModel: data });
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert
				Title="Delete"
				Message={message}
				Type="warning"
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	render() {
		const { Disabled: DisabledProp, ClientPartnerAddressList } = this.props;
		const { addressModel, vModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<div>
				{this.state.alert}


				<LoadingComponent Show={this.state.isLoading} TaskPoolDomain={this.loadTaskPool} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Address">
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="AddressTypeId"
											LabelText="Address Type"
											Value={addressModel == null
												? ""
												: addressModel.AddressTypeId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterAddressType}
											Disabled={Disabled}
											DataRoot="Item"
											Required
											IsInvalid={vModel.AddressTypeId} />

										<GenericTextInput
											Disabled={Disabled}
											Name="StoreName"
											Id="StoreName"
											LabelText="Store Name"
											Value={addressModel == null
												? ""
												: addressModel.StoreName || ""}
											ValueChanged={this.handleChange} />

										<GenericTextInput
											Disabled={Disabled}
											Name="StoreCode"
											Id="StoreCode"
											LabelText="Store Code"
											Value={addressModel == null
												? ""
												: addressModel.StoreCode || ""}
											ValueChanged={this.handleChange} />

									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Disabled={Disabled}
											Name="Address1"
											Id="street-address"
											LabelText="Address Line 1"
											Value={addressModel == null
												? ""
												: addressModel.Address1 || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.Address1} />
										<GenericTextInput
											Disabled={Disabled}
											Name="Address2"
											Id="street-address2"
											LabelText="Address Line 2"
											Value={addressModel == null
												? ""
												: addressModel.Address2 || ""}
											ValueChanged={this.handleChange} />
										<GenericSelectInput
											Name="CountryId"
											LabelText="Country"
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Name"
											Value={addressModel == null
												? ""
												: addressModel.CountryId || ""}
											ValueChanged={this.handleChange}
											Disabled={Disabled}
											Required
											IsInvalid={vModel.CountryId} />

									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											key={addressModel.CountryId + "province"}
											Name="ProvinceId"
											LabelText="Province"
											Method="POST"
											Url="/coreapi/v1.0/Province/Filter"
											Parameter={{
												CountryId: addressModel.CountryId
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Name"
											Value={addressModel == null
												? ""
												: addressModel.ProvinceId || ""}
											ValueChanged={this.handleChange}
											Disabled={Disabled}
											Required
											IsInvalid={vModel.ProvinceId} />
										<GenericSelectInput
											key={"city" + addressModel.ProvinceId + ""}
											Name="CityId"
											LabelText="City"
											Method="POST"
											Url="/coreapi/v1.0/City/Filter"
											Parameter={{
												ProvinceId: addressModel.ProvinceId
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Name"
											Value={addressModel == null
												? ""
												: addressModel.CityId || ""}
											ValueChanged={this.handleChange}
											Disabled={Disabled}
											Required
											IsInvalid={vModel.CityId} />
										<GenericTextInput
											Disabled={Disabled}
											Name="ZipCode"
											Id="postcode"
											LabelText="Postal Code"
											Value={addressModel == null
												? ""
												: addressModel.ZipCode || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.ZipCode} />
									</GridItem>
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button
											disabled={Disabled}
											size="sm"
											color="black"
											onClick={() => this.OperationCheck()}>{addressModel.Id > 0
												? "Update"
												: "Add"}</Button>
										<Button
											disabled={Disabled}
											size="sm"
											color="black"
											onClick={() => this.ClearData()}>
											Clear
                              </Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Address List">
							<GridItem xs={12}>
								<GenericGrid
									Data={ClientPartnerAddressList}
									PageSize={5}
									HideButton={true}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}} />
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
			</div>

		);
	}
}
ClientPartnerAddress.propTypes = {
	classes: PropTypes.object,
	addressModel: PropTypes.object,
	ClientPartnerModel: PropTypes.object,
	ClientPartnerAddressList: PropTypes.array,
	GetClientPartnerAddressModels: PropTypes.func,
	getappLeftTitle:PropTypes.string,
	getappCenterTitle:PropTypes.string,
};
export default withArtifex(ClientPartnerAddress, {});