import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import {
	GenericDateInput,
	GenericExpansionPanel,
	GenericGrid,
	GenericSelectInput,
	GenericTextInput
} from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";
import { DeleteIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";

class ExpiredTransactionsManualRelease extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={DeleteIcon}
							OnClick={() => this.TransactionReleaseConfirm(row.original.Id)} 
							/>
					</div>
				),
				width: 150,
				sortable: false,
				filterable: false,
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName",
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
			},
			{
				Header: "Card Type",
				accessor: "CardType",
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber",
			},
			{
				Header: "CardId",
				accessor: "CardId",
			},
			{
				Header: "WalletAccountId",
				accessor: "WalletAccountId",
			},
			{
				Header: "Available Credit Limit",
				accessor: "AvailableCreditLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transaction Date Time",
				accessor: "TransactionDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode",
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType",
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode",
			},
			{
				Header: "Billing Amount",
				accessor: "BillingAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Billing Currency Code",
				accessor: "BillingCurrencyCode",
			},
			{
				Header: "F4",
				accessor: "F4",
			},
			{
				Header: "F49",
				accessor: "F49",
			},
			{
				Header: "F6",
				accessor: "F6",
			},
			{
				Header: "F51",
				accessor: "F51",
			},
			{
				Header: "Is Reversed",
				accessor: "IsReversed",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Reversal Type",
				accessor: "ReversalType",
			},
			{
				Header: "Reversed Date Time",
				accessor: "ReversedDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Txn Description",
				accessor: "TxnDescription",
			},
			{
				Header: "Settlement Status",
				accessor: "SettlementStatus",
			},
			{
				Header: "Settlement Date",
				accessor: "SettlementDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Mti",
				accessor: "Mti",
			},
			{
				Header: "Transaction Entry Type",
				accessor: "TransactionEntryType",
			},
			{
				Header: "F43Name",
				accessor: "F43Name",
			},
			{
				Header: "F43City",
				accessor: "F43City",
			},
			{
				Header: "Has Partial Reversal",
				accessor: "HasPartialReversal",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Has Incremental Authorization",
				accessor: "HasIncrementalAuthorization",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Incremental Authorization Count",
				accessor: "IncrmentalAuthorizationCount",
			},
			{
				Header: "Last Incremental Authorization Date Time",
				accessor: "LastIncrementalAuthorizationDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Transaction Category Code",
				accessor: "TransactionCategoryCode",
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "StatementId",
				accessor: "StatementId",
			},
			{
				Header: "Card Transaction Source",
				accessor: "CardTransactionSource",
			}
		];

		this.emptyObject = {};
		this.validateProperties = [
			// { ReferenceNumber: "Reference Number" }
		];

		this.state = {
			model: {},
		};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SYSTEM ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Expired Transactions Manual Release");
		}
	}

	TransactionReleaseConfirm = (transactionId) => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;

		showConfirmMessage("warning", "Warning", "Do you want to release this transaction ?", () => ExecuteApiPost("/bankapi/v1.0/Transaction/TransactionReleaseById", { TransactionId: transactionId }, null, null, null, [this.Search], true));
	}

	ValueChanged = (name, value) => {
		this.setState((state) => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	};

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(
			"/bankapi/v1.0/Transaction/ExpiredTransactionsList",
			model,
			model,
			this.validateProperties
		);

		this.setState({ list: result || [] });
	};

	Clear = () => {
		this.setState({ model: {}, list: [] });
	};

	render() {
		const { Disabled } = this.props;
		const { model, list } = this.state;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search,
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear,
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Notification Report">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
												TextValueMember="Name"
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged}
												CanClear
												All
											/>
											{/* <GenericDateInput
												Utc
												Name="FromDate"
												LabelText="Start Date"
												Value={model.StartDate}
												ValueChanged={this.ValueChanged}
												Required={true}
											/> */}
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="ReferenceNumber"
												LabelText="Reference Number"
												Value={model.ReferenceNumber}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={list}
									Columns={this.columns}
									ShowPagination={true}
									PageSize={100}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ExpiredTransactionsManualRelease.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
};

export default ExpiredTransactionsManualRelease;
