import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import dualListBoxStyle from 'react-dual-listbox/lib/react-dual-listbox.css';
const createReportStyle = theme => ({
	root: {
		fontSize: 12
	},
	...customSelectStyle,
	...customCheckboxRadioSwitch,
	...dualListBoxStyle

});

export default createReportStyle;
