import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericGrid, GenericNumberInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";

class HolidayDefinition extends Component {

	constructor(props) {
		super(props);

		this.state = {
			vModel: {},
			model: {},
			ClientInfo: {},
			isLoading: false,
			isClearDialogOpen: false,
			IsConfirm: false,
			saveConfirmDialog: false,
			holidayList: [],
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("National Holidays Definition");
		this.props.setAppCenterTitle("SYSTEM ADMIN");
	}

	handleChange = (name, newValue) => {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "FilterDate") {
				model.MonthId = 0;
				model.HolidayYear = 0;
			}
			return { model };
		});
	}

	GetAllHolidayDefinition = () => {
		this.showLoading();
		Proxy.POST("/coreapi/v1.0/HolidayDaysDefinition/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					if (responseData.Item != null && responseData.Item.length > 0) {
						this.setState({ isLoading: false, holidayList: responseData.Item || [] });
					}
					else {
						this.setState({ isLoading: false, holidayList: [] });
					}
				}
			},
			error => {
				this.ShowMessage("error", "Error", error);
			});
	}

	SubmitClick = () => {
		var model = { ...this.state.model };
		if (!this.Validate(false))
			return;
		this.setState({
			isLoading: false,
			alert: <GenericAlert
				Title={"Info"}
				Message={"Are you sure create new holiday definition ?"}
				Type={"info"}
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => {
					Proxy.POST("/coreapi/v1.0/HolidayDaysDefinition/Insert",
						this.state.model,
						responseData => {
							if (!responseData.IsSucceeded) {
								this.ShowMessage("error", "Error", "Holiday definition create error : " + responseData.ErrorDescription);
								return;
							}
							model.Id = responseData.Item;
							this.setState({ model });
							this.ShowMessage("success", "Success", "Holiday definition create successfully !!!");
							this.GetAllHolidayDefinition();
						},
						error => {
							this.ShowMessage("error", "Error", error);
						});
				}
				} />
		});
	}

	UpdateClick = () => {
		if (!this.Validate(false))
			return;
		this.setState({
			isLoading: false,
			alert: <GenericAlert
				Title={"Info"}
				Message={"Are you sure update this holiday definition ?"}
				Type={"info"}
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => {
					Proxy.POST("/coreapi/v1.0/HolidayDaysDefinition/Update",
						this.state.model,
						responseData => {
							if (!responseData.IsSucceeded) {
								this.ShowMessage("error", "Error", "Holiday definition update error : " + responseData.ErrorDescription);
								return;
							}
							this.GetAllHolidayDefinition();
							this.ShowMessage("success", "Success", "Holiday definition update successfully !!!");
						},
						error => {
							this.ShowMessage("error", "Error", error);
						});
				}
				} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} /> });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.holidayList[index];
		var model = { ...this.state.model };
		model = tempModel;
		delete model.FilterDate;
		delete model.FilterExplanation;
		delete model.MonthId;
		delete model.HolidayYear;
		this.setState({ model });
	}

	closeSaveConfirmDialog = () => {
		this.setState({ isLoading: false, alert: null, saveConfirmDialog: false })
	}



	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ alert: null, isLoading: false }); }
	hideCreateTemplate = () => { this.setState({ isCreateTemplate: false }); }
	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	ShowMessageNode = (type, title, message) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} /> });
	}


	ClearScreen = () => {
		this.setState({ model: {}, alert: null, isLoading: false, holidayList: [], vModel: {} });
	}

	Validate = () => {
		var model = { ...this.state.model };
		var vModel = this.state.vModel;
		var messages = [];
		if (model.Date == null) {
			vModel.Date = true;
			messages.push("Date can not be null.");
		}
		else { vModel.Date = false; }
		if (model.Explanation == null || model.Explanation == "") {
			vModel.Explanation = true;
			messages.push("Explanation can not be null.");
		}
		else { vModel.Explanation = false }

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		return true;
	}

	handleDelete = () => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert
				Title={"Info"}
				Message={"Are you sure delete this holiday definition ?"}
				Type={"info"}
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => {
					Proxy.POST("/coreapi/v1.0/HolidayDaysDefinition/Delete",
						{ Id: this.state.model.Id },
						responseData => {
							if (!responseData.IsSucceeded) {
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
								return;
							}
							this.ClearScreen();
							this.GetAllHolidayDefinition();
							this.ShowMessage("success", "Success", "Holiday definition deleted successfully !!!");
						},
						error => {
							this.ShowMessage("error", "Error", error);
						});
				}
				} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, alert, vModel } = this.state;
		return (
			<div>
				{alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetAllHolidayDefinition, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearScreen, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: model.Id == null ? false : true },
					{ Code: "Update", OnClick: this.UpdateClick, Disabled: model.Id == null ? true : false },
					{ Code: "Delete", OnClick: this.handleDelete, Disabled: model.Id == null ? true : false }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<br />
				<GridContainer>
					<GridItem xs={6} >
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<h4 ><b>Create Panel</b></h4>
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Name="Date"
									LabelText="Date"
									Value={model.Date == null || undefined ? "" : Formatter.FormatDate(model.Date)}
									ValueChanged={this.handleChange}
									IncludeTime={false}
									Required
									IsInvalid={vModel.Date}
								/>
								<GenericTextInput
									Name="Explanation"
									LabelText="Explanation"
									Value={model.Explanation}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.Explanation}
								/>
								<GridContainer>
									<GridItem xs={4}  ></GridItem>
									<GridItem xs={8} >
										<GenericCheckInput
											Name="AnnualRecurring"
											LabelText="Annual Recurring"
											IsTextLeft
											Value={model.AnnualRecurring}
											ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6} >
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<h4 style={{ color: "black" }}><b>Filter Panel</b></h4>
							</CardHeader>
							<CardBody>
								<GenericNumberInput
									NoFormatting={true}
									LabelText="Holiday Year"
									Name="HolidayYear"
									Value={model.HolidayYear}
									ValueChanged={this.handleChange}
									MaxLength={4}
									Disabled={model.FilterDate == null ? false : true} />
								<ParameterComponent
									Name="MonthId"
									LabelText="Holiday Month"
									ParameterCode="Month"
									Value={model.MonthId}
									ValueChanged={this.handleChange}
									Sorted={{ Member: "ParameterValue2" }}
									Disabled={model.FilterDate == null ? false : true} />
								<GenericDateInput
									Name="FilterDate"
									LabelText="Date"
									Value={model.FilterDate == null || undefined ? "" : model.FilterDate}
									ValueChanged={this.handleChange}
									IncludeTime={false} />
								<GenericTextInput
									Name="FilterExplanation"
									LabelText="Explanation"
									Value={model.FilterExplanation}
									ValueChanged={this.handleChange} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<Card>
					<CardHeader>
						<h4 style={{ color: "black" }}><b>Holiday List</b></h4>
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={this.state.holidayList}
							Columns={[
								{
									Header: "Holiday Date",
									accessor: "Date",
									type: GridColumnType.Date
								},
								{
									Header: "Explanation",
									accessor: "Explanation"
								},
								{
									Header: "Recurring",
									accessor: "AnnualRecurring",
									Cell: row => (
										<div>{row.value == true ? "Yes" : "No"}</div>
									)
								}

							]}
							RowSelected={index => {
								this.SelectedRowChange(index);
							}}
							SelectedIndex={this.state.rowIndex}
						/>
					</CardBody>
				</Card>
			</div>
		);
	}
}

HolidayDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	model: PropTypes.object
};

export default withArtifex(HolidayDefinition, {});