import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericDialog, GenericDialogActions, GenericRadioInput, GenericLabel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, GridColumnType } from "views/Constants/Constant.js";
import moment from "moment";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";

class CardTspInformationList extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			ValidFrom: DateHelper.GetLocalDateAsUtc(),
			ValidTo: DateHelper.GetLocalDateAsUtc(),
			CardTokenNumber: null,
			CorrelationId: null
		};

		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},

			{
				Header: "Correlation Id",
				accessor: "CorrelationId"
			},
			{
				Header: "Interbank Card Association Id",
				accessor: "InterbankCardAssociationId"
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "Token Unique Reference",
				accessor: "TokenUniqueReference"
			},
			{
				Header: "Pan Unique Reference",
				accessor: "PanUniqueReference"
			},
			{
				Header: "Imei",
				accessor: "Imei"
			},
			{
				Header: "Msisdn",
				accessor: "Msisdn"
			},
			{
				Header: "Is Succeeded",
				accessor: "IsSucceeded",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Error Code",
				accessor: "ErrorCode"
			},
			{
				Header: "Error Description",
				accessor: "ErrorDescription"
			},
			{
				Header: "Is Activated",
				accessor: "IsActivated",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Activation DateTime",
				accessor: "ActivationDateTime",
				type: GridColumnType.Datetime,
				ColumnType: GridColumnType.Datetime

			},
			{
				Header: "Is Activation Message Sent",
				accessor: "IsActivationMessageSent",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Activation Message Sent DateTime",
				accessor: "ActivationMessageSentDateTime",
				type: GridColumnType.Datetime,
				ColumnType: GridColumnType.Datetime
			},
			{
				Header: "Tsp Status",
				accessor: "TspStatus"
			},
			{
				Header: "Wallet Holder Name",
				accessor: "AccountHolderName"
			},
			{
				Header: "Product Configuration Id",
				accessor: "ProductConfigurationId"
			},
			{
				Header: "Decision",
				accessor: "Decision"
			},
			{
				Header: "Number Of Activation Attempts",
				accessor: "NumberOfActivationAttempts"
			},
			{
				Header: "Number Of Active Tokens",
				accessor: "NumberOfActiveTokens"
			},
			{
				Header: "Address Line1",
				accessor: "AddressLine1"
			},
			{
				Header: "Address Line2",
				accessor: "AddressLine2"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Country Subdivision",
				accessor: "CountrySubdivision"
			},
			{
				Header: "Postal Code",
				accessor: "PostalCode"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "Token Assurance Level",
				accessor: "TokenAssuranceLevel"
			},
			{
				Header: "Device Name",
				accessor: "DeviceName"
			},
			{
				Header: "Serial Number",
				accessor: "SerialNumber"
			},
			{
				Header: "Form Factor",
				accessor: "FormFactor"
			},
			{
				Header: "Payment Types",
				accessor: "PaymentTypes"
			},
			{
				Header: "Is Deactivated",
				accessor: "IsDeactivated",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Deactivation DateTime",
				accessor: "DeactivationDateTime",
				type: GridColumnType.Datetime,
				ColumnType: GridColumnType.Datetime
			},
			{
				Header: "Terms And Conditions Accepted Timestamp",
				accessor: "TermsAndConditionsAcceptedTimestamp"
			},
			{
				Header: "Token Requestor",
				accessor: "TokenRequestor"
			},
			{
				Header: "Recommended Decision",
				accessor: "RecommendedDecision"
			},
			{
				Header: "Device Score",
				accessor: "DeviceScore"
			},
			{
				Header: "Wallet Score",
				accessor: "AccountScore"
			},
			{
				Header: "Phone Number Score",
				accessor: "PhoneNumberScore"
			},
			{
				Header: "Wallet Life Time",
				accessor: "AccountLifeTime"
			},
			{
				Header: "Recommendation Reasons",
				accessor: "RecommendationReasons"
			},
			{
				Header: "Insert DateTime",
				accessor: "InsertDateTime"
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Update DateTime",
				accessor: "UpdateDateTime"
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			}
		];

		this.tspInformationList = React.createRef();

		this.state = {
			list: [],
			vModel: {},
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Tsp Information List");
		this.props.setAppCenterTitle("DIGITAL WALLET");
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	};

	LimitDataLoad = data => {
		this.setState({ LimitBalanceData: data, isLoading: false });
	};
	
	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		} else {
			model[name] = newValue;
		}
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};

	HandleSearch = () => {

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		var request = {
			FromDate: model.ValidFrom,
			ToDate: model.ValidTo,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : model.UniqueClientId,
			CorrelationId: model.CorrelationId,
			CardTokenNumber: model.CardTokenNumber
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/Prepaid/CardTspInformationSearch",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};



	ExportClick = () => {
		var tspInformationColumns = [];
		this.tspInformationList.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				tspInformationColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: tspInformationColumns, ExportSelectedColumns: tspInformationColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });
		const temp = { ...this.state.model };
		var jsonColList = [];
		var tspInformationColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			tspInformationColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(tspInformationColumns);

		var mytitle = "Card Tsp Information List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			jsonFile: temp.jsonFile,
			FromDate: temp.ValidFrom,
			ToDate: temp.ValidTo,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : temp.UniqueClientId,
			CorrelationId: temp.CorrelationId,
			CardTokenNumber: temp.CardTokenNumber
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/prepaidapi/v1.0/Prepaid/CardTspInformationListExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, vModel } = this.state;

		var endDate = (model.ValidFrom instanceof moment) ? model.ValidFrom.clone() : "";

		return (
			<div>
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: Disabled,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
						{
							Code: "Export",
							OnClick: this.ExportClick,
							Disabled: Disabled
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{
														UniqueClientId: ClientHelper.IsClient()
															? ClientHelper.GetClientId()
															: undefined
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												/>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
													Name="ValidFrom"
													LabelText="From Date"
													LabelMd={4}
													IsFuture={false}
													MaxDate={DateHelper.GetLocalDateAsUtc()}
													Value={model.ValidFrom == null ? "" : DateHelper.ToDateInputValue(model.ValidFrom) || undefined}
													ValueChanged={this.handleChange}
													Utc
													IsInvalid={vModel.ValidFrom}
												/>
										</GridItem>
										<GridItem xs={4}>
												<GenericDateInput
													Name="ValidTo"
													LabelText="To Date"
													LabelMd={4}
													IsFuture={false}
													MinDate={model.ValidFrom}
													MaxDate={endDate != "" ? endDate.add(30, "days") : undefined}
													Value={model.ValidTo == null ? "" : DateHelper.ToDateInputValue(model.ValidTo) || ""}
													ValueChanged={this.handleChange}
													Utc
													IsInvalid={vModel.ValidTo}
												/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="CorrelationId"
												LabelText="Correlation Id"
												Value={model.CorrelationId}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="CardTokenNumber"
												LabelText="Card Token Number"
												Value={model.CardTokenNumber}
												ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid}
													ShowPagination={true}
													PageSize={100}
													ref={this.tspInformationList}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CardTspInformationList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardTspInformationList, {});
