import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericExpansionPanel, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class FrequentlyAskedQuestionView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("Support");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("FAQ");
		}
	}

	ProgramChanged = (name, value) => {
		this.Search(value);
	}

	Search = async (programId) => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;

		this.setState({ list: [] });

		var result = await ExecuteApiPost("/bankapi/v1.0/FrequentlyAskedQuestion/Search", { ProgramId: programId });
		var list = [], key = 0;
		model.ProgramId = programId;

		this.setState({ isLoading: true });

		if (result != null) {
			result.map(x => {
				list.push(
					<GenericExpansionPanel Title={x.Question} IsActive={false} key={key}>
						<GridItem style={{ marginLeft: "10px" }}>
							<GenericLabel
								Text={x.Answer} />
						</GridItem>
					</GenericExpansionPanel>
				);
				key++;

				return null;
			});
		}

		this.setState({ model, list, isLoading: false });
	}

	render() {
		const { model, list, isLoading } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="ProgramId"
											LabelText="Program"
											DefaultIndex={0}
											Url="/bankapi/v1.0/Bank/ValidBankPrograms"
											Method="GET"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.ProgramId}
											ValueChanged={this.ProgramChanged} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												{
													list
												}
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FrequentlyAskedQuestionView.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func
};

export default FrequentlyAskedQuestionView;