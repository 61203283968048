import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

const style = {
	grid: {
		//  margin: "0 -15px",
		margin: "0 -5px",
		width: "calc(100% + 10px)"
		// '&:before,&:after':{
		//   display: 'table',
		//   content: '" "',
		// },
		// '&:after':{
		//   clear: 'both',
		// }
	}
};

function GridContainer({ ...props }) {
	const { classes, children, className, ...rest } = props;
	return (
		<Grid container {...rest} className={classes.grid + " " + (className || "")}>
			{children}
		</Grid>
	);
}

export default withStyles(style)(GridContainer);
