import style from "assets/jss/material-dashboard-pro-react/views/CreateWireBeneficiaryTemplateStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericPage, GenericExpansionPanel } from "views/Components/Generic";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import BeneficiaryDetail from "../CreateWireTransfer/BeneficiaryDetail.jsx";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar.jsx";
import ClientHelper from "core/ClientHelper.js";
import { ProgramCodes } from "views/Constants/Constant.js";
import StringHelper from "core/StringHelper.js";
import {Proxy} from "core";
class CreateWireBeneficiaryTemplate extends GenericPage {

	constructor(props) {
		super(props);
		this.isClient = ClientHelper.IsClient();
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.defaultTemplateModel = {
			rowIndex: null,
			TemplateId: null,
			Alias: "",
			TemplateName: "",
			CompanyName: "",
			Name: "",
			MiddleName: "",
			LastName: "",
			AddressLine1: "",
			AddressLine2: "",
			ZipCode: "",
			CityId: null,
			City: null,
			ProvinceId: null,
			Province: null,
			CountryId: null,
			Country: "",
			CurrencyId: null,
			Currency: null,
			BankName: null,
			BankInstitution: null,
			BankTransitNumber: null,
			BankBranchName: null,
			BankFinInsId: null,
			BankFinIns: null,
			BankBranchId: null,
			BankBranch: null,
			AccountNumber: null,
			BankSwiftCode: null,
			BankAddressLine1: null,
			BankAddressLine2: null,
			BankZipCode: null,
			BankCityId: null,
			BankCity: null,
			BankProvinceId: null,
			BankProvince: null,
			BankCountryId: null,
			BankCountry: null,
			BankCityName: null,
			BankProvinceName: null,
			FIID: null,
			TransitNumber: null,
			BankCountryName: null,
			IsDomestic: null,
			InsertDateTime: null
		};
		this.ClearModel = { ...this.defaultTemplateModel };

		this.state = {
			model: this.defaultTemplateModel,
			list: [],
			ClientInfo: {}
		};

		this.TemplateColumns = [
			{
				Header: "Template Name",
				accessor: "TemplateName"
			},
			{
				Header: "Company Name",
				accessor: "CompanyName"
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "Address Line 1",
				accessor: "AddressLine1"
			},
			{
				Header: "AddressLine2",
				accessor: "AddressLine2"
			},
			{
				Header: "Zip Code",
				accessor: "ZipCode"
			},
			{
				Header: "City",
				accessor: "City.Name"
			},
			{
				Header: "Province",
				accessor: "Province.Name"
			},
			{
				Header: "Country",
				accessor: "Country.Name"
			},
			{
				Header: "Beneficiary Bank",
				Cell: row => (<span>{row.original.BankFinIns != null ? row.original.BankFinIns.Description : row.original.BankName}</span>)
			},
			{
				Header: "Transit Number",
				Cell: row => (<span>{row.original.BankBranch != null ? row.original.BankBranch.TransitNumber : row.original.BankTransitNumber}</span>)
			},
			{
				Header: "FIID",
				Cell: row => (<span>{row.original.BankFinIns != null ? row.original.BankFinIns.FinIns : ""}</span>)
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Bank Swift Code",
				accessor: "BankSwiftCode"
			},
			{
				Header: "Address Line 1",
				accessor: "BankAddressLine1"
			},
			{
				Header: "Bank Address Line 2",
				accessor: "BankAddressLine2"
			},
			{
				Header: "Zip Code",
				accessor: "BankZipCode"
			},
			{
				Header: "City",
				accessor: "BankCity.Name"
			},
			{
				Header: "Province",
				accessor: "BankProvince.Name"
			},
			{
				Header: "Bank Country",
				accessor: "BankCountry.Name"
			}
		];
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		this.props.setAppLeftTitle("List of Templates");this.getappLeftTitle = "List of Templates";
		this.props.setAppCenterTitle("WIRE TRANSFER");this.getappCenterTitle = "WIRE TRANSFER";

		this.FetchCanadaPost();
	}

	FetchCanadaPost() {
		this.ExecutePostRequest("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			},
			responseData => {
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);
				var FillBankAddressFields = this.FillBankAddressFields;
				var FillBeneficiaryAddressFields = this.FillBeneficiaryAddressFields;
				// @ts-ignore
				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;

				var pca;
				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					// @ts-ignore
					pca = window.pca;
					if (pca != null) {
						var fields = [
							{ element: "bank-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "bank-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "bank-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];
						var options = {
							key: apikey
						};
						var control = new pca.Address(fields, options);

						control.listen("populate", function (address) {
							FillBankAddressFields(address);
						});

						var fields2 = [
							{ element: "beneficiary-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "beneficiary-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "beneficiary-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];

						var control2 = new pca.Address(fields2, options);
						CanadaPostRequest();

						control2.listen("populate", function (address) {
							FillBeneficiaryAddressFields(address);
						});
					}
				};
			});
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.getMenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	FillBankAddressFields(request, continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireCountry/CanadaPost",
			request,
			responseData => {
				const { model } = this.state;
				if (responseData.Item != null) {
					model.BankCountryId = responseData.Item.CountryId;
					model.BankProvinceId = responseData.Item.ProvinceId;
					model.BankCityId = responseData.Item.CityId;
					model.IsDomestic = model.CountryIso2 == "CA";
				} else {
					model.BankCountryId = 0;
					model.BankProvinceId = 0;
					model.BankCityId = 0;
				}
				model.BankAddressLine1 = request.Line1;
				model.BankAddressLine2 = request.Line2;
				model.BankZipCode = request.PostalCode;
				this.setState({ model }, continueWith);
			}
		);
	}

	FillBeneficiaryAddressFields(request, continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireCountry/CanadaPost",
			request,
			responseData => {
				const { model } = this.state;
				if (responseData.Item != null) {
					model.CountryId = responseData.Item.CountryId;
					model.ProvinceId = responseData.Item.ProvinceId;
					model.CityId = responseData.Item.CityId;
				} else {
					model.CountryId = 0;
					model.ProvinceId = 0;
					model.CityId = 0;
				}
				model.AddressLine1 = request.Line1;
				model.AddressLine2 = request.Line2;
				model.ZipCode = request.PostalCode;
				this.setState({ model }, continueWith);
			}
		);
	}

	ValueChanged(name, newValue, data) {
		const { model } = this.state;

		if (name == "CurrencyId" && this.defaultTemplateModel.CurrencyId == null) {
			this.defaultTemplateModel.CurrencyId = newValue;
		}
		if (name == "Alias" && newValue != null) {
			var check = this.onlyLetterAndDigit(newValue);
			if (check == true)
				return true;
		}
		model[name] = newValue;
		if (name == "BankCountryId" && data != null) {
			model.IsDomestic = data.ISO2 == "CA";
			model.ProvinceId = null;
			model.CityId = null;
		}
		if (name == "BankCountryId" && newValue == null) {
			model.BankCityId = null;
			model.BankProvinceId = null;
		}
		if (name == "CountryId" && newValue == null) {
			model.CityId = null;
			model.ProvinceId = null;
		}
		this.setState({ model });
	}

	SearchClick(continueWith = null) {

		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/Search",
			this.state.model,
			(responseData) => {
				this.setState({ list: responseData.Item }, continueWith);
			});
	}

	ClearClick() {
		this.setState({ model: { ...this.ClearModel } });

	}
	InsertUpdateClick() {
		if (!this.Validate())
			return;

		const { model } = this.state;
		model.IsTemplate = true;
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/InsertOrUpdate",
			model,
			(responseData) => {
				if (responseData.Item !== null) {
					const { model } = this.state;
					if (responseData.Item !== null) {
						model.TemplateId = responseData.Item.Id;
						this.setState({ model }, () => {
							this.ShowGenericMessage("success", "Template", "Template Submitted.", false, this.SearchClick);
						});
					}
				}
			});
	}

	UpdateClick() {
		if (!this.Validate())
			return;

		const { model } = this.state;
		if (model.TemplateId == null)
			return;

		model.IsTemplate = true;
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/InsertOrUpdate",
			model,
			(responseData) => {
				if (responseData.Item !== null) {
					const { model } = this.state;
					if (responseData.Item !== null) {
						model.TemplateId = responseData.Item.Id;
						this.setState({ model }, () => {
							this.ShowGenericMessage("success", "Template", "Template Submitted.", false, this.SearchClick);
						});
					}
				}
			});
	}

	SelectedRowChange(index) {
		const { list } = this.state;
		var tempModel = { ...list[index] };
		tempModel.IsDomestic = tempModel.BankCountry != null && tempModel.BankCountry.ISO2 == "CA";
		if (tempModel.IsDomestic) {
			tempModel.BankName = null;
			tempModel.BankBranchName = null;
		} else {
			tempModel.BankFinIns = null;
			tempModel.BankBranchId = null;
		}
		tempModel.CurrencyId = this.defaultTemplateModel.CurrencyId;
		tempModel.rowIndex = index;
		this.setState({ model: { ...tempModel } });
	}

	DeleteClick() {
		this.ShowGenericMessage("warning", "Are you sure?", "The selected item will be deleted, are you sure?", true, this.DeleteItem);
	}

	Validate() {
		const { model } = this.state;

		var messages = [];

		const isIndividual = model.CompanyName == null || model.CompanyName == "";
		if (isIndividual) {
			if (model.Name == null || model.Name == "") messages.push("Name can not be null.");
			if (model.LastName == null || model.LastName == "") messages.push("LastName can not be null.");
		}
		if (model.AddressLine1 == null || model.AddressLine1 == "") messages.push("Address Line 1 can not be null.");
		// if (Template.AddressLine2 == null || Template.AddressLine2 == "") messages.push("Address Line 2 can not be null.");
		if (model.ZipCode == null || model.ZipCode == "") messages.push("ZipCode can not be null.");
		if (model.CityId == null) messages.push("City can not be null.");
		if (model.ProvinceId == null) messages.push("Provice can not be null.");
		if (model.CountryId == null || model.CountryId == "") messages.push("Country can not be null.");

		if (model.BankCountryId == null || model.BankCountryId < 1) messages.push("Bank Country can not be null.");
		var accountIsEmpty = model.AccountNumber == null || model.AccountNumber == "";
		var ibanIsEmpty = model.BankInstitution == null || model.BankInstitution == "";
		if (accountIsEmpty && ibanIsEmpty) {
			messages.push("Wallet Number or IBAN can not be null. Please fill Wallet Number or IBAN field.");
		}

		if (model.BankZipCode == null || model.BankZipCode == "") messages.push("Bank ZipCode can not be null.");
		if (model.IsDomestic && !accountIsEmpty) {
			if (model.BankFinInsId == null || model.BankFinInsId < 1) messages.push("FIID can not be null.");
			if (model.BankBranchId == null || model.BankBranchId < 1) messages.push("Branch can not be null.");
		} else if (!accountIsEmpty) {
			if (model.BankName == null || model.BankName == "") messages.push("Bank Name can not be null.");
		}
		// if (model.IsDomestic) {
		// 	if (model.BankTransitNumber == null || model.BankTransitNumber == "") messages.push("Bank Transit Number can not be null.");
		// }
		if (model.BankSwiftCode == null || model.BankSwiftCode == "") messages.push("Swift Code can not be null.");

		if (model.BankCityId == null || model.BankCityId == 0) messages.push("Bank City can not be null.");
		if (model.BankProvinceId == null || model.BankProvinceId == 0) messages.push("Bank Province can not be null.");
		if (model.BankAddressLine1 == null || model.BankAddressLine1 == "") messages.push("Bank Address Line 1 can not be null.");
		// if (model.BankAddressLine2 == null || model.BankAddressLine2 == "") messages.push("Bank Address Line 2 can not be null.");

		if (model.Alias) {
			var check = this.onlyLetterAndDigit(model.Alias);
			if (check == true)
				messages.push("Remittance Information contains invalid character!");
		}

		if (messages.length > 0) {
			this.ShowGenericMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}
	onlyLetterAndDigit = (str) => {
		return StringHelper.VerifiyDigitAndLetterForWire(str);
	}

	DeleteItem() {
		const { model } = this.state;

		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/Delete", { "TemplateId": model.TemplateId },
			(responseData) => {
				if (responseData.Item !== null) {
					const { model } = this.state;
					if (responseData.Item !== null) {
						model.TemplateId = responseData.Item.Id;
						this.setState({ model: { ...this.defaultTemplateModel } }, () => {
							this.ShowGenericMessage("success", "Template", "Selected item deleted.", false, this.SearchClick);
						});
					}
				}
			});
	}
	render() {
		const { IsLoading, Alert } = this.state;
		const { model, list } = this.state;
		const { Disabled } = this.props;
		const canDelete = model.TemplateId != null && model.TemplateId != 0;
		return (
			<div>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				{this.isClient &&
					<AccountLimitToolbar Program={ProgramCodes.Bank_Programs_Bank_Account} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
				}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.InsertUpdateClick, Disabled: Disabled },
					{ Code: "Update", OnClick: this.InsertUpdateClick, Disabled: Disabled },
					{ Code: "Search", OnClick: this.SearchClick, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.DeleteClick, Disabled: !canDelete }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<GenericExpansionPanel>
						<CardBody>
							<BeneficiaryDetail
								ValueChanged={this.ValueChanged}
								CompanyName={model.CompanyName}
								Name={model.Name}
								MiddleName={model.MiddleName}
								LastName={model.LastName}
								AddressLine1={model.AddressLine1}
								AddressLine2={model.AddressLine2}
								ZipCode={model.ZipCode}
								CityId={model.CityId}
								ProvinceId={model.ProvinceId}
								CountryId={model.CountryId}
								IsDomestic={model.BankCountry != null && model.BankCountry.ISO2 == "CA"}
								BankCountryId={model.BankCountryId}
								BankFinInsId={model.BankFinInsId}
								BankName={model.BankName}
								BankBranchId={model.BankBranchId}
								BankTransitNumber={model.BankTransitNumber}
								AccountNumber={model.AccountNumber}
								BankSwiftCode={model.BankSwiftCode}
								BankAddressLine1={model.BankAddressLine1}
								BankAddressLine2={model.BankAddressLine2}
								BankZipCode={model.BankZipCode}
								BankCityId={model.BankCityId}
								BankProvinceId={model.BankProvinceId}
								BankInstitution={model.BankInstitution}
								Alias={model.Alias}
								Disabled={false}
								CurrencyId={model.CurrencyId}
								IsCurrencyVisible={true}>
							</BeneficiaryDetail>
						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<GridContainer spacing={16}>
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={list}
									Columns={this.TemplateColumns}
									RowSelected={this.SelectedRowChange}
									SelectedIndex={model.rowIndex}
									DocumentTitle={"Report List"}></GenericGrid>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div>

		);
	}
}
CreateWireBeneficiaryTemplate.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(CreateWireBeneficiaryTemplate, style);
