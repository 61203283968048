import withStyles from "@material-ui/core/styles/withStyles";
import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

function Footer({ ...props }) {
	const { classes, fluid, white } = props;

	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white
	});

	return (
		<footer className={classes.footer}>
			<div className={container}>
				<div className={classes.left}></div>
				<p className={classes.right}></p>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
	fluid: PropTypes.bool,
	white: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
