import withStyles from "@material-ui/core/styles/withStyles";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import pagesRoutes from "routes/pages.jsx";

class Pages extends React.Component {
	componentDidMount() {
		document.body.style.overflow = "unset";
	}

	render() {
		const { classes } = this.props;
		
		return (
			<div>
				<div className={classes.wrapper}>
					<div className={classes.fullPage}>
						<Switch>
							{pagesRoutes.map((prop, key) => {
								if (prop.redirect) {
									return (
										<Redirect from={prop.path} to={prop.pathTo} key={key} />
									);
								}
								return (
									<Route
										path={prop.path}
										component={prop.component}
										key={key}
									/>
								);
							})}
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

Pages.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
