import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/jss/material-dashboard-pro-react/views/prepaidInventoryTransferBetweenStoresStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";

class CreditCardComponent extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	handleChange(e) {
		e.preventDefault();
		if (this.props.handleChange) {
			this.props.handleChange();
		}
	}

	handleBlur(e) {
		e.preventDefault();
		if (this.props.handleBlur) {
			this.props.handleBlur();
		}
	}

	render() {

		const { classes } = this.props;

		return (
			<GridContainer spacing={16} justify="flex-start">
				<GridItem xs={12} sm={3}>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel htmlFor="payeeAccountBankCode-required">Payee/Payor Wallet</InputLabel>
						<Select
							value={this.props.bankCode}
							onChange={this.handleChange}
							name="payeeAccountBankCode"
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
						</Select>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={3}>
					<TextField
						name="payeeAccountType2"
						className="form-input"
						onChange={this.handleChange}
						value={this.props.secCode}
					/>
				</GridItem>
				<GridItem xs={12} sm={3}>
					<TextField
						name="payeeAccountType3"
						className="form-input"
						onChange={this.handleChange}
						value={this.props.thiCode}
					/>
				</GridItem>
				<GridItem xs={12} sm={3}>
					<TextField
						name="payeeAccountType4"
						className="form-input"
						onChange={this.handleChange}
						value={this.props.lastCode}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

CreditCardComponent.propTypes = {
	bankCode: PropTypes.string,
	lastCode: PropTypes.string,
	secCode: PropTypes.string,
	thiCode: PropTypes.string,
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
	classes: PropTypes.object.isRequired
};

export default withStyles(style)(CreditCardComponent);