import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes, { object } from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput } from "views/Components/Generic";
import { Direction, ProgramCodes } from "views/Constants/Constant.js";
class ReconciliationEFTTab extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { EFTOutgoingSummary, EFTIncomingSummary } = this.props;
		return (
			<GridContainer>
				<br /><br />
				{/* Outgoing */}
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Outgoing File Value</b>
								<GenericNumberInput
									Name="TotalAmountCredit"
									LabelText="Total Amount Credit"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.TotalAmountCredit ? EFTOutgoingSummary.TotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="TotalAmountDebit"
									LabelText="Total Amount Debit"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.TotalAmountDebit ? EFTOutgoingSummary.TotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<b>Response File Value</b>
								<GenericNumberInput
									Name="ResponseTotalAmountCredit"
									LabelText="Response Total Amount Credit"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseTotalAmountCredit ? EFTOutgoingSummary.ResponseTotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountDebit"
									LabelText="Response Total Amount Debit"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseTotalAmountDebit ? EFTOutgoingSummary.ResponseTotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountRejected"
									LabelText="Response Total Amount Rejected"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseTotalAmountRejected ? EFTOutgoingSummary.ResponseTotalAmountRejected : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountError"
									LabelText="Response Total Amount Error"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseTotalAmountError ? EFTOutgoingSummary.ResponseTotalAmountError : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountPending"
									LabelText="Response Total Amount Pending"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseTotalAmountPending ? EFTOutgoingSummary.ResponseTotalAmountPending : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAmount"
									LabelText="Total Amount"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.TotalAmount ? EFTOutgoingSummary.TotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseFileAmount"
									LabelText="Total Response File Amount"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.TotalResponseFileAmount ? EFTOutgoingSummary.TotalResponseAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="FileGap"
									LabelText="Gap"
									Value={EFTOutgoingSummary.TotalAmount - EFTOutgoingSummary.TotalResponseAmount}
									Disabled={true}
									LabelMd={6}
									Prefix="$"
									EndIconName="blur_on"
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_EFT, Direction.Outgoing)}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Number of Outgoing Transactions</b>
								<GenericNumberInput
									Name="NumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.NumberCreditTransaction ? EFTOutgoingSummary.NumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="NumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.NumberDebitTransaction ? EFTOutgoingSummary.NumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<b>Number of Response Transactions</b>
								<GenericNumberInput
									Name="ResponseNumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseNumberCreditTransaction ? EFTOutgoingSummary.ResponseNumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseNumberDebitTransaction ? EFTOutgoingSummary.ResponseNumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberRejectedTransaction"
									LabelText="Number of Rejected Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseNumberRejectedTransaction ? EFTOutgoingSummary.ResponseNumberRejectedTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberErrorTransaction"
									LabelText="Number of Error Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseNumberErrorTransaction ? EFTOutgoingSummary.ResponseNumberErrorTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberPendingTransaction"
									LabelText="Number of Pending Transaction"
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.ResponseNumberPendingTransaction ? EFTOutgoingSummary.ResponseNumberPendingTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalNumberTransaction"
									LabelText="Total Number of Trans."
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.TotalNumberTransaction ? EFTOutgoingSummary.TotalNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseNumberTransaction"
									LabelText="Total Response Number of Trans."
									Value={EFTOutgoingSummary == null ? 0 : EFTOutgoingSummary.TotalResponseNumberTransaction ? EFTOutgoingSummary.TotalResponseNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionGap"
									LabelText="Gap"
									Value={EFTOutgoingSummary.TotalNumber - EFTOutgoingSummary.TotalResponseNumber}
									EndIconName="blur_on"
									LabelMd={6}
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_EFT, Direction.Outgoing)}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				{/* Incoming */}
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Incoming File Value</b>
								<GenericNumberInput
									Name="TotalAmountCredit"
									LabelText="Total Amount Credit"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.TotalAmountCredit ? EFTIncomingSummary.TotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="TotalAmountDebit"
									LabelText="Total Amount Debit"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.TotalAmountDebit ? EFTIncomingSummary.TotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<b>Response File Value</b>
								<GenericNumberInput
									Name="ResponseTotalAmountCredit"
									LabelText="Response Total Amount Credit"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseTotalAmountCredit ? EFTIncomingSummary.ResponseTotalAmountCredit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountDebit"
									LabelText="Response Total Amount Debit"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseTotalAmountDebit ? EFTIncomingSummary.ResponseTotalAmountDebit : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountRejected"
									LabelText="Response Total Amount Rejected"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseTotalAmountRejected ? EFTIncomingSummary.ResponseTotalAmountRejected : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountError"
									LabelText="Response Total Amount Error"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseTotalAmountError ? EFTIncomingSummary.ResponseTotalAmountError : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
								<GenericNumberInput
									Name="ResponseTotalAmountPending"
									LabelText="Response Total Amount Pending"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseTotalAmountPending ? EFTIncomingSummary.ResponseTotalAmountPending : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalAmount"
									LabelText="Total Amount"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.TotalAmount ? EFTIncomingSummary.TotalAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseFileAmount"
									LabelText="Total Response File Amount"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.TotalResponseFileAmount ? EFTIncomingSummary.TotalResponseAmount : 0}
									Disabled={true}
									LabelMd={6}
									Prefix="$" />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="FileGap"
									LabelText="Gap"
									Value={EFTIncomingSummary.TotalAmount - EFTIncomingSummary.TotalResponseAmount}
									Disabled={true}
									LabelMd={6}
									Prefix="$"
									EndIconName="blur_on"
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_EFT, Direction.Incoming)}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<b>Number of Incoming Transactions</b>
								<GenericNumberInput
									Name="NumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.NumberCreditTransaction ? EFTIncomingSummary.NumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="NumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.NumberDebitTransaction ? EFTIncomingSummary.NumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<b>Number of Response Transactions</b>
								<GenericNumberInput
									Name="ResponseNumberCreditTransaction"
									LabelText="Number of Credit Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseNumberCreditTransaction ? EFTIncomingSummary.ResponseNumberCreditTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberDebitTransaction"
									LabelText="Number of Debit Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseNumberDebitTransaction ? EFTIncomingSummary.ResponseNumberDebitTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberRejectedTransaction"
									LabelText="Number of Rejected Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseNumberRejectedTransaction ? EFTIncomingSummary.ResponseNumberRejectedTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberErrorTransaction"
									LabelText="Number of Error Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseNumberErrorTransaction ? EFTIncomingSummary.ResponseNumberErrorTransaction : 0}
									Disabled={true}
									LabelMd={6} />
								<GenericNumberInput
									Name="ResponseNumberPendingTransaction"
									LabelText="Number of Pending Transaction"
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.ResponseNumberPendingTransaction ? EFTIncomingSummary.ResponseNumberPendingTransaction : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalNumberTransaction"
									LabelText="Total Number of Trans."
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.TotalNumberTransaction ? EFTIncomingSummary.TotalNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TotalResponseNumberTransaction"
									LabelText="Total Response Number of Trans."
									Value={EFTIncomingSummary == null ? 0 : EFTIncomingSummary.TotalResponseNumberTransaction ? EFTIncomingSummary.TotalResponseNumber : 0}
									Disabled={true}
									LabelMd={6} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="TransactionGap"
									LabelText="Gap"
									Value={EFTIncomingSummary.TotalNumber - EFTIncomingSummary.TotalResponseNumber}
									EndIconName="blur_on"
									LabelMd={6}
									IconOnClick={() => this.props.openDialog(ProgramCodes.Bank_Programs_EFT, Direction.Incoming)}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridContainer >
		);
	}
}

ReconciliationEFTTab.propTypes = {
	classes: PropTypes.object,
	EFTIncomingSummary: object,
	EFTOutgoingSummary: object,
	openDialog: PropTypes.func
};

export default ReconciliationEFTTab;