import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import StringHelper from "core/StringHelper";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CanadaPostAddressComponent from "views/Components/CanadaPostAddressComponent";
import { GenericAlert, GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class CRARegister extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: this.GetDefaultModel(),
			phone: this.GetDefaultPhoneModel(),
			address: {},
			list: [],
			vModel: {},
			isLoading: false,
			alert: null,
			gridButtonRightDetail: []
		};

		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.Columns = [
			{
				Header: "First Name",
				accessor: "FirstName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Last Name",
				accessor: "LastName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Phone",
				accessor: "PhoneDescription",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Email",
				accessor: "EmailAddress",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "SIN",
				accessor: "SIN",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "FIID",
				accessor: "FinancialInstitution",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Transit",
				accessor: "FinancialInstitutionBranch",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Wallet Number",
				accessor: "WalletNumber",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Zip Code",
				accessor: "Address.ZipCode",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			},
			{
				Header: "Send Status",
				accessor: "ApplicationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ApplicationStatusId)}>{row.value}</div>)
			}
		];
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle) setAppLeftTitle("Direct Deposit for Canada Revenue Agency");
		if (setAppCenterTitle) setAppCenterTitle("CALL CENTER");

		this.GetGridButtonsRight();
	}

	GetDefaultModel = () => {
		// return {};

		return {
			ApplicationChannelId: 107963,
			UniqueClientId: 6
		};
	}

	GetDefaultPhoneModel=() =>{
		
	return {
		PhoneCountryCodeId: 38

	};
	}

	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status != null && status != 0
				? "#FB3005"
				: ""
		};

		return this.columnsRowStyleColor;
	}

	HandleChangeModel = (name, value, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = value;
			return { model: temp };
		});
	}

	HandleChangePhone = (name, value, data) => {
		this.setState(function (state, props) {
			var temp = state.phone || {};
			temp[name] = value;
			return { phone: temp };
		});
	}

	HandleChangeAddress = (name, value, data) => {
		this.setState(function (state, props) {
			var temp = state.address || {};

			if (name == "CountryId") {
				temp.ProvinceId = 0;
				temp.CityId = 0;
			}
			if (name == "ProvinceId") {
				temp.CityId = 0;
			}

			temp[name] = value;
			return { address: temp };
		});
	}

	HandleRowSelect = (index) => {
		let item = this.state.list[index];
		let phone = item.Phone || {};
		let address = item.Address || {};

		this.setState({ model: item, phone, address });
	}

	HandleRowDelete = (index) => {
		this.HandleRowSelect(index);
		this.HandleDelete();
	}

	HandleClear = () => {
		this.setState({ model: this.GetDefaultModel(), phone: this.GetDefaultPhoneModel(), address: {}, list: [] });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });

		let { model, phone, address } = this.state;

		model.Phone = phone;
		model.Address = address;

		Proxy.POST(
			"/bankapi/v1.0/CRA/Search",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [] });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });

		let { model, phone, address } = this.state;

		model.Phone = phone;
		model.Address = address;

		Proxy.POST(
			"/bankapi/v1.0/CRA/Register",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.ShowMessage("success", "Success", "Operation is successfull!");
				this.HandleClear();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.ShowMessage("error", "Error", "You must select a CRA Application to delete!");
			return;
		}

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/CRA/Delete",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.ShowMessage("success", "Success", "Operation is successfull!");
				this.HandleClear();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, address, vModel } = this.state;
		const errors = [];

		if (!model.ApplicationChannelId) {
			errors.push("Application Channel is required.");
			vModel.ApplicationChannelId = true;
		} else {
			vModel.ApplicationChannelId = false;
		}

		if (!model.UniqueClientId) {
			errors.push("Client is required.");
			vModel.UniqueClientId = true;
		} else {
			vModel.UniqueClientId = false;
		}

		if (!model.FirstName) {
			errors.push("First Name is required.");
			vModel.FirstName = true;
		} else {
			vModel.FirstName = false;
		}

		if (!model.LastName) {
			errors.push("Last Name is required.");
			vModel.LastName = true;
		} else {
			vModel.LastName = false;
		}

		if (!model.EmailAddress) {
			errors.push("Email Address is required.");
			vModel.EmailAddress = true;
		} else if (!StringHelper.VerifyEmail(model.EmailAddress)) {
			errors.push("Email Address is invalid.");
			vModel.EmailAddress = true;
		} else {
			vModel.EmailAddress = false;
		}

		if (!model.DateOfBirth) {
			errors.push("DOB (Date of Birth) is required.");
			vModel.DateOfBirth = true;
		} else {
			vModel.DateOfBirth = false;
		}

		if (!model.SIN) {
			errors.push("SIN is required.");
			vModel.SIN = true;
		} else {
			vModel.SIN = false;
		}

		if (!model.AccountNumber && !model.WalletNumber) {
			errors.push("Wallet Number or Wallet Number is required.");
			vModel.AccountNumber = true;
			vModel.WalletNumber = true;
		}
		else if (model.AccountNumber && (!model.FinancialInstitutionId || !model.FinancialInstitutionBranchId)) {
			errors.push("FIID and Transit Numbers are required if supplying the Wallet Number.");
			vModel.AccountNumber = false;
			vModel.WalletNumber = false;
			vModel.FinancialInstitutionId = true;
			vModel.FinancialInstitutionBranchId = true;
		} else {
			vModel.AccountNumber = false;
			vModel.WalletNumber = false;
			vModel.FinancialInstitutionId = false;
			vModel.FinancialInstitutionBranchId = false;
		}

		if (!address?.ZipCode) {
			errors.push("Zip Code is required.");
			vModel.ZipCode = true;
		} else {
			vModel.ZipCode = false;
		}

		this.setState({ vModel });

		if (errors.length > 0) {
			this.ShowMessageNode("warning", "Check these fields!", errors.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CRAREGEDIT", "CRAREGDELE"]);

		this.setState({ gridButtonRightDetail });
	}

	ShowMessage = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	ShowConfirmDelete = (onConfirm) => {
		this.ShowConfirmMessage("warning", "Warning", "Delete this CRA Application?", onConfirm);
	}

	render() {
		const { Disabled } = this.props;
		const { model, phone, address, list, isLoading, alert, vModel, gridButtonRightDetail } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled },
					{ Code: "Delete", OnClick: () => this.ShowConfirmDelete(this.HandleDelete), Disabled: Disabled || !(model.Id > 0) },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="CRA Application Details">
								<CardBody>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="ApplicationChannelId"
												LabelText="Application Channel"
												Value={model.ApplicationChannelId}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.ApplicationChannelId}

												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{ ParameterCode: "ApplicationChannel" }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												RenderItem={d => `${d.ParameterValue} ${d.ParameterDesc}`}
											/>

											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.UniqueClientId}

												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={d => `${d.Name} - ${d.UniqueClientId}`}
											/>

											<GenericTextInput
												Name="FirstName"
												LabelText="First Name"
												Value={model.FirstName || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.FirstName}
											/>

											<GenericTextInput
												Name="MiddleName"
												LabelText="Middle Name"
												Value={model.MiddleName || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
											/>

											<GenericTextInput
												Name="LastName"
												LabelText="Last Name"
												Value={model.LastName || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.LastName}
											/>

											<GenericEmailInput
												Name="EmailAddress"
												LabelText="Email Address"
												Value={model.EmailAddress || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.EmailAddress}
											/>

											<GridContainer>
												<GridItem xs={8}>
													<GenericSelectInput
														Name="PhoneCountryCodeId"
														LabelText="Phone Number"
														Value={phone.PhoneCountryCodeId  || ""}
														ValueChanged={this.HandleChangePhone}
														Disabled={Disabled}
														CanClear

														LabelMd={6}
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember={"Code"}
														Sorted={{ Member: "OrderIndex" }}
														RenderItem={d => `+${d.Code} (${d.Name})`}
													/>
												</GridItem>
												<GridItem xs={4} >
													<GenericNumberInput
														Name="PhoneNumber"
														Value={phone.PhoneNumber || ""}
														ValueChanged={this.HandleChangePhone}
														Disabled={Disabled}

														IsPhone
														MaxLength={10}
														LabelMd={0}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="DateOfBirth"
												LabelText="DOB (Date Of Birth)"
												Value={model.DateOfBirth ? DateHelper.ToDateInputValue(model.DateOfBirth) : ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.DateOfBirth}
												IncludeTime={false}
												Utc
											/>

											<GenericNumberInput
												Name="SIN"
												LabelText="SIN"
												Value={model.SIN || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.SIN}
												MaxLength={9}
												NoFormatting
											/>

											<GenericSelectInput
												Name="FinancialInstitutionId"
												LabelText="FIID (Bank ID)"
												Value={model.FinancialInstitutionId}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												IsInvalid={vModel.FinancialInstitutionId}

												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
											/>

											<GenericSelectInput
												key={"bankid" + model.FinancialInstitutionId}
												Name="FinancialInstitutionBranchId"
												LabelText="Transit (Branch ID)"
												Value={model.FinancialInstitutionBranchId}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												IsInvalid={vModel.FinancialInstitutionBranchId}

												Method="POST"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
												Parameter={{ FinancialInstitutionId: model.FinancialInstitutionId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransNo"
											/>

											<GenericNumberInput
												Name="AccountNumber"
												LabelText="Wallet Number"
												Value={model.AccountNumber || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												IsInvalid={vModel.AccountNumber}
												NoFormatting
											/>

											<GenericNumberInput
												Name="WalletNumber"
												LabelText="Wallet Number"
												Value={model.WalletNumber || ""}
												ValueChanged={this.HandleChangeModel}
												Disabled={Disabled}
												IsInvalid={vModel.WalletNumber}
												NoFormatting
											/>
										</GridItem>
										<GridItem xs={4}>
											<CanadaPostAddressComponent
												addressModel={address || {}}
												handleChange={this.HandleChangeAddress}
												vModel={vModel}
												Disabled={Disabled}
												GridXs={4}
												GridCount={0}
												isAddressOptional={false}
												isCityOptional={false}
												isProvinceOptional={false}											
												isCountryOptional={false}

												// isZipCodeOptional={false}
												zipCodeLabelText="Postal Code"
											/>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="CRA Applications" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={[
												{
													Header: "Actions",
													accessor: "Actions",
													Cell: row => (
														<div>
															<GridButton
																tooltip="Edit"
																Icon={EditIcon}
																Disabled={Disabled || !gridButtonRightDetail.find(x => x.Code == "CRAREGEDIT")?.HasAuthority}
																OnClick={() => this.HandleRowSelect(row.index)} />
															<GridButton
																tooltip="Delete"
																Icon={DeleteIcon}
																Disabled={Disabled || !gridButtonRightDetail.find(x => x.Code == "CRAREGDELE")?.HasAuthority}
																OnClick={() => this.ShowConfirmDelete(() => this.HandleRowDelete(row.index))} />
														</div>
													),
													sortable: false,
													filterable: false,
													width: 100
												},
												...this.Columns
											]}
											RowSelected={this.HandleRowSelect} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CRARegister.propTypes = {};

export default withArtifex(CRARegister, {});
