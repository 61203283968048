import DateHelper from "core/DateHelper";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";

/**
 * @param {number} number
 */
function FormatNumber(number) {
	if (number == null) return "-";
	if (typeof number != "number") return number;
	return <NumberFormat thousandSeparator="," value={number} displayType="text" />;
}

/**
 * @param {number} number
 */
function FormatDecimal(number) {
	if (number == null) return "-";
	if (typeof number != "number") return number;
	return <NumberFormat thousandSeparator="," decimalScale={2} fixedDecimalScale value={number} displayType="text" />;
}

/**
 * @param {number} number
 */
function FormatMoney(number) {
	return FormatMoneySpecial("$", number);
}

/**
 * @param {string} symbol
 * @param {number} number
 */
function FormatMoneySpecial(symbol, number) {
	if (number == null) return "-";
	if (typeof number != "number") return number;
	return <NumberFormat thousandSeparator="," decimalScale={2} fixedDecimalScale prefix={symbol} value={number} displayType="text" />;
}

/**
 * @param {moment.MomentInput} date
 */
function FormatDate(date) {
	if (date == null) return "-";
	return FormatDateUtcLocal(date, "DD/MMM/YY");
}

/**
 * @param {number} date
 */
function FormatTxnDate(date) {
	if (date == null) return "-";
	var parsedDate = moment(String(date), "YYYYMMDD");
	return parsedDate.format("DD/MMM/YY");
}

/**
 * @param {moment.MomentInput} date
 */
function FormatDateTime(date) {
	if (date == null) return "-";
	return FormatDateUtcLocal(date, "DD/MMM/YY hh:mm:ss A");
}

/**
 * @param {moment.MomentInput} date
 */
function FormatDateTimeUtc(date) {
	if (date == null) return "-";
	return DateHelper.ToMoment(date).format("DD/MMM/YY hh:mm:ss A");
	
}

/**
 * @param {moment.MomentInput} date
 */
function FormatTime(date) {
	if (date == null) return "-";
	return FormatDateUtcLocal(date, "hh:mm:ss A");
}

function FormatTimeUtc(date) {
	if (date == null) return "-";
	return FormatDateUtc(date, "hh:mm:ss A");
}

/**
 * @param {string|number} value
 * @param {number} padNumber
 * @param {string} padItem
 */
function PadLeft(value, padNumber, padItem) {
	if (value == null || value == "") return "";
	if (typeof (value) == "string" || typeof (value) == "number") {
		var v = String(value);
		return v.padStart(padNumber, padItem);
	}
	else {
		return "Value type is undefined.";
	}
}

/**
 * @param {string|number} value
 * @param {number} padNumber
 * @param {string} padItem
 */
function PadRight(value, padNumber, padItem) {
	if (value == null || value == "") return "";
	if (typeof (value) == "string" || typeof (value) == "number") {
		var v = String(value);
		return v.padEnd(padNumber, padItem);
	}
	else {
		return "Value type is undefined.";
	}
}

/**
 * @param {number} number
 */
function FormatPercent(number) {
	if (number == null) return "-";
	if (typeof number != "number") return number;
	return <NumberFormat decimalScale={1} suffix="%" value={number} displayType="text" />;
}

/**
 * @param {number} number
 */
function FormatPercentFee(number) {
	if (number == null) return "-";
	if (typeof number != "number") return number;
	return <NumberFormat decimalScale={2} suffix="%" value={number} displayType="text" />;
}

/**
 * @param {moment.MomentInput} date
 * @param {string} format
 */
function FormatDateUtcLocal(date, format) {
	return DateHelper.ToMoment(date).local().format(format);
}

/**
 * @param {moment.MomentInput} date
 * @param {string} format
 */
function FormatDateUtc(date, format = "DD/MMM/YY") {
	return DateHelper.ToMoment(date).format(format);
}

/**
 * @param {boolean} value
 */
function FormatYesNo(value) {
	return value == true
		? "Yes"
		: "No";
}

function FormatEstToUtc(date) {
	moment(date, "MM/DD/YYYY HH:mm").utc().format("MM/DD/YYYY HH:mm");
}

function FormatDateTimeEst(date) {
	if (date == null) return "-";
	return DateHelper.ToMoment(date).tz("America/New_York").format("DD/MMM/YY hh:mm:ss A");

}

const Formatter = {
	FormatNumber,
	FormatDecimal,
	FormatMoney,
	FormatMoneySpecial,
	FormatDate,
	FormatTxnDate,
	FormatDateTime,
	FormatTime,
	FormatTimeUtc,
	PadLeft,
	PadRight,
	FormatPercent,
	FormatDateUtcLocal,
	FormatDateUtc,
	FormatDateTimeUtc,
	FormatPercentFee,
	FormatYesNo,
	FormatEstToUtc,
	FormatDateTimeEst
};

export default Formatter;