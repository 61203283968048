import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, MenuCodes } from "views/Constants/Constant.js";

class EftPendingBalanceSearch extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.clientRowId = ClientHelper.GetClientRowId();
		this.clientId = ClientHelper.GetClientId();
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.isSalesPerson = ClientHelper.IsSalesPerson();

		this.initialModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientRowId,
			MainSearchItemId: -1,
			StartDate: null,
			EndDate: null
		};
		

		this.state = {
			model: this.initialModel,
			isLoading: false,
			mainSearchItem: this.props.MainSearchItem,
			selected: null,
			LimitToken: false,
			suspiciousDialog: false,
			gridButtonRightDetail: [],
			data: [],
		};

		this.trxTable = React.createRef();

		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.sortParamDesc = { Member: "ParameterDesc" };
		this.trxSorted = [{ "id": "TransactionDate", "desc": true }];
		this.renderItemStoreName = {};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("PROGRAM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("EFT Pending Balance");
		}
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel });
	}

	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}


	ShowMessage = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} /> });
	}

	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/EftPendingBalanceSearch",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {

					this.setState({ data: responseData.Item });
				}
			},
			e => {

				this.setState({ isLoading: false });
			}
		);

	}
	ClearSuspiciousDialog = () => {
		this.setState({ suspiciousDialog: false, sdCancelMemo: "", sdCancelReasonId: "", sdTransactionId: null });
	}
	RenderItemStoreName = (d) => {
		this.renderItemStoreName = `${d.StoreName} - (${d.StoreCode})`;
		return this.renderItemStoreName;
	}
	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model,data} = this.state;

		var IsClient = !this.isSalesPerson && this.isClient;
		var IsCallCenter = this.props.MenuCode === MenuCodes.CallCenterEFTTransactions;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Name="ReferenceNumber"
												LabelText="Reference Number"
												Value={model.ReferenceNumber}
												ValueChanged={this.handleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="DirectionId"
												LabelText="Direction"
												Value={model.DirectionId}
												ParameterCode="Direction"
												ParameterValue2="ETRANSFER" // Burası None gelmesin diye bu şekilde
												ValueChanged={this.handleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: IsClient ? this.clientId : undefined }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												Disabled={Disabled || IsClient} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="TransactionStatusId"
												LabelText="Transaction Status"
												All
												ParameterCode="BankTransactionStatus"
												ParameterValueExcludes={["IC"]} // Hide Incoming
												Value={model.TransactionStatusId}
												ValueChanged={this.handleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionTypeId}
												ValueChanged={this.handleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="StartDate"
												LabelText="Start Date"
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Utc />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="EndDate"
												LabelText="End Date"
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Utc />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="EftProcessorId"
												LabelText="Eft Processor"
												Value={model.EftProcessorId}
												ParameterCode="EftProcessor"
												ValueChanged={this.handleChange}
												Disabled={Disabled} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<CardHeader>
									<GridContainer spacing={16} justify="space-between" alignItems="center">
										<GridItem>
											<CardHeader>
												<h4><b>EFT Pending Balance List</b></h4>
											</CardHeader>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<GenericGrid

									Data={data}
									Columns={[
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.DateTime,
											ColumnType: GridColumnType.DateTime
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId"
										},
										{
											Header: "Unique Client Name",
											accessor: "UniqueClientName"
										},
										{
											Header: "Direction",
											accessor: "Direction",

										},
										{
											Header: "Wallet Name",
											accessor: "AccountName",

										},
										{
											Header: "Transaction Status",
											accessor: "TransactionStatus"
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType"
										},
										{
											Header: "EFT Transaction Type",
											accessor: "EFTTransactionType",

										},
										{
											Header: "Amount",
											accessor: "Amount",

										},
										{
											Header: "Description",
											accessor: "Description"
										},
										{
											Header: "Reference Number",
											accessor: "ReferenceNumber"
										},
										{
											Header: "Release Date",
											accessor: "ReleaseDate",
											type: GridColumnType.DateTime,
											ColumnType: GridColumnType.DateTime
										},
										{
											Header: "Settlement Date",
											accessor: "SettlementDate",
											type: GridColumnType.DateTime,
											ColumnType: GridColumnType.DateTime
										},
										{
											Header: "Processor",
											accessor: "PendingReason"
										}
									]}
									PageSize={10}
									ShowPagination={true}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

EftPendingBalanceSearch.propTypes = {
	classes: PropTypes.object
};

export default EftPendingBalanceSearch;
