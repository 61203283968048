

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";

class IdIntroduction extends React.Component {

	constructor(props) {
		super(props);
		this.initialState = {
			model: {},
			vModel: {},
			modelList: [],
			filterModel: {},
			visibilityModel: {},
			isOpenkycUploadWindow: false,
			isIdentityTypeAddOpen: false,
			InsertedIdentityType: null,
			verificationResultModel: null,
			identityTypeModel: {}
		};
		this.state = Object.assign({
			identityTypeList: [],
			tempIdentityTypeList: []
		}, this.initialState);
		this.emptyObject = {};
		this.gridSortParameter = [{ id: "Name", desc: false }];
		this.placeholderNA = "-";
		this.columnsData = [
			{
				Header: "Third Party Identity Type",
				accessor: "Name",
				Cell: row => row.value
			},
			{
				Header: "Country",
				accessor: "Country.Name",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "Province",
				accessor: "Province.Name",
				Cell: row => row.value || this.placeholderNA
			}
		];

	}

	componentDidMount() {
		this.props.setAppCenterTitle && this.props.setAppCenterTitle("AML & KYC");
		this.props.setAppLeftTitle && this.props.setAppLeftTitle("Identity Type");

	}

	ValueChanged = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	HandleSearch = async () => {
		const { ExecuteApiPost } = this.props;
		const { filterModel } = this.state;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/DigitalIdentityVerificationIdentityDocument/Search", filterModel);

		this.setState({ modelList: result || [] });

	}
	HandleClear = () => {
		var defaultState = Object.assign({}, this.initialState);
		this.setState(defaultState);
	}

	ValueChangedFilter = (name, newValue, data) => {
		const filterModel = { ...this.state.filterModel };

		filterModel[name] = newValue;
		this.setState({ filterModel });
	}
	HandleEditDigitalIdentityType = (identityTypeModel) => async () => {
		this.setState({ identityTypeModel });
	}
	RenderIdentityTypeList = (a) => {
		return (a.Name + (a.HasRecognized ? "*" : ""));
	}
	render() {
		const { model, vModel, identityTypeModel, modelList, filterModel, verificationResultModel, isOpenkycUploadWindow, isIdentityTypeAddOpen, identityTypeList } = this.state;
		const { Disabled } = this.props;

		return (
			<>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled || isOpenkycUploadWindow,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: Disabled || isOpenkycUploadWindow,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<GenericExpansionPanel
								IsActive={true}
								Title="Filter Panel">
								<CardBody>
									<GridContainer >
										<GridItem xs={3}>
											<GenericSelectInput
												Name="DigitalIdentityTypeId"
												LabelText="Third Party Identity Type"
												Method="POST"
												Url="/amlkycapi/v1.0/DigitalIdentityVerificationIdentityType/Search"
												DataRoot="Item"
												LabelMd={5}
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.DigitalIdentityTypeId}
												ValueChanged={this.ValueChangedFilter}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="CountryId"
												LabelText="Country"
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.CountryId}
												ValueChanged={this.ValueChangedFilter}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={filterModel.CountryId + "province"}
												Name="ProvinceId"
												LabelText="Province"
												Method="POST" Url="/coreapi/v1.0/Province/Filter"
												Parameter={{ CountryId: filterModel.CountryId }} DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.ProvinceId}
												ValueChanged={this.ValueChangedFilter}
												Disabled={Disabled} />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"DigitalIDVerificationIdentityTypeList"}
									Title="Digital ID Verification Identity Type List">
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={this.gridSortParameter}
										Columns={this.columnsData} />
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</ >
		);
	}
}

IdIntroduction.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default IdIntroduction;