import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
	GenericSelectInput,
	GenericDateInput,
	GenericExpansionPanel,
	GenericRadioInput,
	GenericGrid,
	GenericDialog,
	GenericAlert,
	GenericDialogActions,
	GenericLabel,
	GenericTitle,
	GenericNumberInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import Button from "components/CustomButtons/Button.jsx";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Formatter from "core/Formatter";

class WireEftTransactionSearch extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientRowId()
				: undefined,
			IsMainCard: true,
			ProgramCode: "",
			FromDate: DateHelper.GetLocalDateAsUtc(),
			ToDate: DateHelper.GetLocalDateAsUtc(),
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {},
		};

		this.emptyObject = {};

		this.maxLength6 = { maxLength: 6 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientId()
				: undefined,
		};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Client",
				accessor: "ClientName",
				width: 150
			},
			{
				Header: "Organization Unique Id",
				accessor: "OrganizationUniqueId",
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
			},
			{
				Header: "Source Wallet Number",
				accessor: "SourceAccountNumber",
			},
			{
				Header: "Destination Wallet Number",
				accessor: "DestinationAccountNumber",
			},
			{
				Header: "Beneficiary Name",
				accessor: "BankName",
			},
			{
				Header: "Financial Institution",
				accessor: "FinancialInstitution",
			},
			{
				Header: "Transit",
				accessor: "Transit",
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
			},
			{
				Header: "Transaction Creation Date Time",
				accessor: "TransactionDate",
				Cell: (row) => <div>{Formatter.FormatDateTimeEst(row.value)}</div>,
			},
			{
				Header: "Purpose Of Wire",
				accessor: "PurposeOfWire",
			},
		];

		this.sortParamValue = { Member: "ParameterValue" };

		this.trxTable = React.createRef();
	}

	showMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			),
		});
	};
	setValueToState = (name, value) => {
		this.setState({ [name]: value });
	};
	showLoading() {
		this.setState({ isLoading: true });
	}

	hideLoading() {
		this.setState({ isLoading: false });
	}
	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach((c) => {
			if (
				c.accessor != "Actions" &&
				c.accessor != "ED" &&
				(c.show == undefined || c.show)
			) {
				trxColumns.push({
					value: c.accessor,
					title: c.Header,
					columntype: c.ColumnType,
					type: c.type,
				});
			}
		}, this);

		this.setState({
			isDownloadDialogOpen: true,
			GridKeyValueList: trxColumns,
			ExportSelectedColumns: trxColumns,
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false,
		});
	};

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage(
				"warning",
				"Export Report Type not selected",
				"Select Export Report Type to continue"
			);
			return;
		}

		if (
			this.state.ExportReportType == "PDF" &&
			this.state.ExportSelectedColumns.length > 15
		) {
			this.props.showMessage(
				"warning",
				"PDF document cannot exceed 15 columns",
				"PDF document cannot exceed 15 columns"
			);
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		this.props.showConfirmMessage(
			"question",
			"Long Process Warning",
			"This process may take a while. Continue ?",
			() => {
				this.hideAlert();
				const { model } = this.state;
				model.IsClientBalanceScreen = true;

				// Add jsonFile
				var jsonColList = [];

				var trxColumns = {};
				this.state.ExportSelectedColumns.map((c) => {
					trxColumns[c.value] = {
						value: c.value,
						title: c.title,
						columntype: c.columntype,
						type: c.type,
					};
				});
				jsonColList.push(trxColumns);

				model["jsonFile"] = {
					colmns: jsonColList,
					format: this.state.ExportReportType,
					title: "Switch Settlement Aggregate Report",
				};

				this.setState({ isLoading: true });
				Proxy.DownloadGeneratedFile(
					"/lmsapi/v1.0/FeeAndCodeBasedTransaction/DownloadFeeRevenueReport",
					model,
					(responseData) => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.props.showMessage(
								"error",
								"Error",
								responseData.ErrorDescription
							);
							return;
						}
					},
					(errorMessage) => {
						this.setState({ isLoading: false });
						this.props.showMessage("error", "Error", errorMessage);
					},
					this.props.showMessage
				);
			}
		);
	};

	openExportDialog = () => {
		this.setState({
			isDownloadDialogOpen: true,
		});
	};

	closeExportDialog = () => {
		this.setState({
			isDownloadDialogOpen: false,
		});
	};

	componentDidMount() {
		this.props.setAppLeftTitle("Wire EFT Transaction Search");
		this.props.setAppCenterTitle("WIRE TRANSFER");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	};

	ValueChangedForExport = (name, value) => {
		this.setState({ [name]: value });
	};

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			isEdit: false,
			index: -1,
		});
	};

	HandleSearch = () => {
		if (!this.Validate()) {
			return;
		}

		const model = { ...this.state.model };
		model.ProgramCode = this.ProgramCode;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/SearchBackofficeOutgoingEftWireTransaction",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage(
						"error",
						"Error",
						responseData.ErrorDescription
					);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};
	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		var errorList = [];

		vModel.FromDate = !model.FromDate;
		if (vModel.FromDate) errorList.push("From Date cannot be empty!");

		vModel.ToDate = !model.ToDate;
		if (vModel.ToDate) errorList.push("To Date cannot be empty!");

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}

		return true;
	};

	GetModel = () => {
		return this.state.model;
	};

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
						{
							Code: "Export",
							OnClick: () => this.ExportClick(),
							Disabled: Disabled,
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Disabled={IsClient}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
												TextValueMember="Name"
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												All
												DefaultIndex={IsClient ? 0 : -1}
											/>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="Start Date"
												Value={
													model.FromDate
														? DateHelper.ToDateInputValue(model.FromDate)
														: ""
												}
												ValueChanged={this.HandleChange}
											/>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="End Date"
												Value={
													model.ToDate
														? DateHelper.ToDateInputValue(model.ToDate)
														: ""
												}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GenericDialog
						open={this.state.isDownloadDialogOpen}
						fullWidth={true}
					>
						<DialogTitle>
							<GenericLabel
								Text="Select Report Type"
								FontSize="20px"
								Bold={true}
							/>
						</DialogTitle>
						<DialogContent style={{ height: 300 }}>
							<GenericRadioInput
								Name="ExportReportType"
								LabelText="Report Type"
								IsStatic={true}
								StaticData={[
									{ Value: "PDF", Text: "PDF" },
									{ Value: "CSV", Text: "CSV" },
									{ Value: "EXCEL", Text: "EXCEL" },
								]}
								KeyValueMember="Value"
								TextValueMember="Text"
								Value={this.state.ExportReportType}
								ValueChanged={this.ValueChangedForExport}
							/>
							<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
							<GridItem style={{ marginTop: 20 }}>
								<Select
									value={this.state.ExportSelectedColumns}
									onChange={(value) =>
										this.setState({ ExportSelectedColumns: value })
									}
									isMulti
									name="Columns"
									getOptionLabel={(d) => d.title}
									options={this.state.GridKeyValueList}
									className="basic-multi-select"
									classNamePrefix="select"
								/>
							</GridItem>
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={this.ExcelDownload}>
								Download
							</Button>
							<Button
								size="sm"
								onClick={() => this.setState({ isDownloadDialogOpen: false })}
							>
								Cancel
							</Button>
						</GenericDialogActions>
					</GenericDialog>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={4}></GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={true}
											ref={this.trxTable}
											PageSize={100}
											IsSorted={true}
											Sorted={[{ id: "TransactionDate", desc: true }]}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

WireEftTransactionSearch.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
};

export default withArtifex(WireEftTransactionSearch, {});
