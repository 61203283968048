import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DocumentIcon, EditIcon, InformationIcon, MoveToLegalIcon, ProfileIcon, SignOutIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, LOSLoanType } from "views/Constants/Constant";
import LedgerCard from "views/LOS/LedgerCard/LedgerCard.jsx";

class CollectionPool extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isEdit: false,
			isLoading: false,
			rowIndex: -1,
			isAssignCollectorDialogOpen: false,
			collectorModel: {
				ApplicationId: null,
				CollectionPoolId: null
			},
			isCallInfoEntryDialogOpen: false,
			callInfoModel: {
				CollectionPoolId: null
			},
			isPaymentDialogOpen: false,
			paymentModel: {},
			isLedgerCardDialogOpen: false,
			LedgerCardList: [],
			modelrow: {},
			IsAdmin: null
		};

		this.emptyObject = {};

		this.sortedNumber = [{ id: "ApplicationId", desc: false }];

		this.parameterCallStatus = {
			ParameterCode: "LOSCallStatus"
		};

		this.parameterInterviewee = {
			ParameterCode: "LOSInterviewee"
		};

		this.parameterInterviewResult = {
			ParameterCode: "LOSInterviewResult"
		};

		this.parameterPayOption = {
			ParameterCode: "LOSPayOption"
		};

		this.parameterYesNo = {
			ParameterCode: "LOSYesNo"
		};
		this.parameterUser = {
			UniqueClientId: this.state.model.UniqueClientId
		};

		this.renderItemCollectionUser = {};
		this.renderItemUser = {};

		this.parameterCollector = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength128 = { maxLength: 128 };
		this.maxLength30 = { maxLength: 30 };
		this.marginLeft2 = { marginLeft: 2 };

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "AP",
				Cell: d => (
					<>
						<GridButton
							tooltip="Go to Applicant"
							Icon={ProfileIcon}
							OnClick={() => { RouteHelper.Push(this.props.history, "/CreateApplication", "ApplicationData", { ApplicationId: d.original.ApplicationId, ApplicantId: d.original.ApplicantId }); }}
							Disabled={d.original.ApplicantId === 0} />
						<GridButton
							tooltip="Open the Ledger Card"
							Icon={DocumentIcon}
							// OnClick={() => { this.LedgerCardDialogOpenOrClose(d.original.Application); }}
							OnClick={() => { RouteHelper.Push(this.props.history, "/lmsPaymentAdmin", "loanApproval_Id", d.original.ApplicationId); }}
							Disabled={d.original.ApplicationId === 0} />
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.RowSelected(d.index)}
							Disabled={d.original.Id === 0} />
						<GridButton
							tooltip="Assign Case to Collector"
							Icon={SignOutIcon}
							OnClick={() => { this.OnClickAssignCaseToCollector(d.original); }} />
						<GridButton
							tooltip="Call Information Entry"
							Icon={InformationIcon}
							OnClick={() => { this.OnClickCallInfo(d.original); }}
							Disabled={d.original.ApplicationId === 0} />
						<GridButton
							tooltip="Move to Legal Follow Up"
							Icon={MoveToLegalIcon}
							OnClick={() => { this.OnClickMoveLegalFollowUp(d.original); }}
							Disabled={d.original.ApplicationId === 0} />
					</>
				),
				sortable: false,
				filterable: false,
				width: 175
			},
			{
				Header: "Collector",
				accessor: "CollectionParameter.User.UserName",
				width: 175
			},
			{
				Header: "Application Number",
				accessor: "Application.ApplicationNumber",
				width: 175
			},
			{
				Header: "Date",
				accessor: "LastPaymentDate",
				type: GridColumnType.Date,
				width: 120
			},
			{
				Header: "Partner",
				accessor: "ClientPartner.LegalNameOfBusiness",
				width: 175
			},
			{
				Header: "Customer Name",
				accessor: "Applicant.FullName",
				width: 175
			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Remaining Total",
				accessor: "TotalWaitingAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "No. of Delayed",
				accessor: "NumberOfDelayDays"
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Collection Transactions");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");

		this.GetUnpaidAmountColumns();
		this.GetCollectionParameter();
	}

	GetUnpaidAmountColumns = () => {
		const unpaidAmountColumns = [];

		this.setState({ isLoading: true });
		Proxy.GET(
			"/losapi/v1.0/CollectionPool/GetAllDelayDayRange",
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null && responseData.Item.length > 0) {
					responseData.Item.map(x => {
						unpaidAmountColumns.push(
							{
								Header: x.FlexValue4,
								accessor: x.FlexDesc,
								type: GridColumnType.Money,
								ColumnType: GridColumnType.Money,
								width: 140
							});
						return null;
					});
				}
				this.ColumnsData = [...this.ColumnsData, ...unpaidAmountColumns];
				this.ColumnsData.push(
					{
						Header: "Promise Date",
						accessor: "CollectionPoolCall.PromiseDate",
						type: GridColumnType.Date
					},
					{
						Header: "Promise Status",
						accessor: "CollectionPoolCall.PromiseDate",
						Cell: row => (
							<div>{row.original.CollectionPoolCall ? row.value != null ? "Yes" : "No" : ""} </div>
						)
					},
					{
						Header: "Call Status",
						accessor: "CollectionPoolCall.CallStatus.ParameterDesc"
					});
				this.setState({ writeOffDelayDay: responseData.Item[responseData.Item.length - 1] });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	LedgerCardDialogOpenOrClose = (row) => {
		if (this.state.isLedgerCardDialogOpen == false) {
			this.setState({ isLedgerCardDialogOpen: true, modelrow: row });
		}
		else {
			this.setState({ isLedgerCardDialogOpen: false, modelrow: {} });
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	};

	HandleChangeCollector = (name, newValue, data) => {
		const collectorModel = { ...this.state.collectorModel };
		collectorModel[name] = newValue;
		this.setState({ collectorModel });
	};

	HandleChangeCallInfo = (name, newValue, data) => {
		const callInfoModel = { ...this.state.callInfoModel };
		callInfoModel[name] = newValue;
		this.setState({ callInfoModel });
	};

	HandleChangePayment = (name, newValue, data) => {
		const paymentModel = { ...this.state.paymentModel };
		paymentModel[name] = newValue;
		this.setState({ paymentModel });
	};

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			isEdit: false,
			rowIndex: -1
		});
	};

	GetCollectionParameter = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/losapi/v1.0/CollectionParameter/GetByUserId",
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ IsAdmin: responseData.Item.IsAdmin });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSearch = () => {
		let { list } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/CollectionPool/Search",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				list = responseData.Item.map(item => {
					let obj = {
						Id: item.Id,
						ApplicationId: item.ApplicationId,
						Application: item.Application,
						ApplicantId: item.ApplicantId,
						Applicant: item.Applicant,
						CollectionParameterId: item.CollectionParameterId,
						CollectionParameter: item.CollectionParameter,
						ClientPartnerId: item.ClientPartnerId,
						ClientPartner: item.ClientPartner,
						CollectionPoolCallId: item.CollectionPoolCallId,
						CollectionPoolCall: item.CollectionPoolCall,
						LastPaymentDate: item.LastPaymentDate,
						LatePaymentPeriodId: item.LatePaymentPeriodId,
						NumberOfDelayDays: item.NumberOfDelayDays,
						PrincipalAmount: item.PrincipalAmount,
						WaitingAmount: item.WaitingAmount,
						TotalAmount: item.TotalAmount,
						TotalWaitingAmount: item.TotalWaitingAmount,
					};

					for (var i = 0; i < item.DelayDayAmounts.length; i++) {
						let delayDayAmount = item.DelayDayAmounts[i];
						obj[delayDayAmount.Key] = delayDayAmount.Value;
					}

					return obj;
				});
				this.setState({ list });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	};

	OnClickAssignCaseToCollector = (model) => {
		if (model) {
			var collectorModel = { ...this.state.collectorModel };
			collectorModel.ApplicationId = model.ApplicationId;
			collectorModel.CollectionPoolId = model.Id;
			this.parameterCollector.LoanTypeId = model.Application.LoanTypeId;
			this.parameterCollector.ClientPartnerId = model.Application.ClientPartnerId;
			this.parameterCollector.ApprovedAmount = model.Application.ApprovedAmount;
			this.parameterCollector.TotalAmount = model.TotalAmount;
			this.parameterCollector.NumberOfDelayDays = model.NumberOfDelayDays;
			this.setState({ isAssignCollectorDialogOpen: true, collectorModel });
		}
	}

	OnClickCallInfo = (model) => {
		if (model.Id > 0) {
			var callInfoModel = { ...this.state.callInfoModel };
			callInfoModel.CollectionPoolId = model.Id;

			this.setState({ isLoading: true });
			Proxy.GET(
				"/losapi/v1.0/CollectionPoolCall/GetByPoolId/Id?Id=" + model.Id,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					callInfoModel = {
						CollectionPoolId: model.Id
					};
					if (responseData.Item != null) {
						callInfoModel = {
							Id: responseData.Item.Id,
							CollectionPoolId: responseData.Item.CollectionPoolId,
							CallStatusId: responseData.Item.CallStatusId,
							IntervieweeId: responseData.Item.IntervieweeId,
							InterviewResultId: responseData.Item.InterviewResultId,
							RequestedCallDate: responseData.Item.RequestedCallDate,
							PersonName: responseData.Item.PersonName,
							PromiseDate: responseData.Item.PromiseDate,
							CollectorNote: responseData.Item.CollectorNote
						};
					}
					this.setState({ isCallInfoEntryDialogOpen: true, callInfoModel });
				},
				error => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			);
		} else {
			callInfoModel = {
				ApplicationId: model.ApplicationId
			};
			this.setState({ isCallInfoEntryDialogOpen: true, callInfoModel });
		}
	}

	OnClickMoveLegalFollowUp = (model) => {
		if (model) {
			this.setState({ isLoading: true });

			Proxy.POST(
				"/lmsapi/v1.0/LoanLedgerCard/GetPaymentCloseCaseByApplicationId",
				{
					ApplicationId: model.ApplicationId,
					MenuCode: this.props.MenuCode
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					if (responseData.Item != null) {
						this.setState({ paymentModel: responseData.Item, isPaymentDialogOpen: true, isLoading: false });
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "An error occurred while requesting data", error.message);
				}
			);
		}
	}

	DialogAssignCollectorSubmit = () => {
		if (!this.AssignCollectorValidate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/CollectionPool/AssignCaseToCollector",
			this.state.collectorModel,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.setState({ isAssignCollectorDialogOpen: false, collectorModel: {} });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	DialogAssignCollectorClose = () => {
		this.setState({ isAssignCollectorDialogOpen: false });
	}

	DialogCallInfoSubmit = () => {
		if (!this.CallInfoSubmitValidate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/CollectionPoolCall/InsertOrUpdate",
			this.state.callInfoModel,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.DialogCallInfoClose();
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	DialogCallInfoClose = () => {
		this.setState({ isCallInfoEntryDialogOpen: false, callInfoModel: {} });
	}

	DialogPaymentCloseCase = () => {
		this.setState({ isLoading: true });

		Proxy.POST(
			"/lmsapi/v1.0/LoanEarlyPayment/MakeEarlyPayment",
			this.state.paymentModel,
			(responseData) => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ alert: null, isPaymentDialogOpen: false }, () => {
					this.HandleClear();
					this.HandleSearch();
				});

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	DialogPaymentMoveLegalUp = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/lmsapi/v1.0/LoanEarlyPayment/MoveLegalFollowUp",
			this.state.paymentModel,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ alert: null, isPaymentDialogOpen: false }, () => {
					this.HandleClear();
					this.HandleSearch();
				});
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	DialogPaymentClose = () => {
		this.setState({ isPaymentDialogOpen: false });
	}

	AssignCollectorValidate = () => {
		const { collectorModel, IsAdmin } = this.state;

		if (!collectorModel.CollectionParameterId) {
			this.ShowMessage("warning", "Warning", "Select the collector to continue.");
			return false;
		}

		if (!IsAdmin && !collectorModel.Reason) {
			this.ShowMessage("warning", "Warning", "Enter the reason to continue.");
			return false;
		}

		return true;
	};

	CallInfoSubmitValidate = () => {
		const { callInfoModel } = this.state;

		if (!callInfoModel.CallStatusId) {
			this.ShowMessage("warning", "Warning", "Select the call status to continue.");
			return false;
		}

		if (!callInfoModel.RequestedCallDate) {
			this.ShowMessage("warning", "Warning", "Enter the requested call date to continue.");
			return false;
		}
		if (!callInfoModel.IntervieweeId) {
			this.ShowMessage("warning", "Warning", "Select the interviewee to continue.");
			return false;
		}

		if (!callInfoModel.PersonName) {
			this.ShowMessage("warning", "Warning", "Enter the person name to continue.");
			return false;
		}

		if (!callInfoModel.PromiseDate) {
			this.ShowMessage("warning", "Warning", "Enter the promise date to continue.");
			return false;
		}

		if (!callInfoModel.CollectorNote) {
			this.ShowMessage("warning", "Warning", "Enter the callector note to continue.");
			return false;
		}

		return true;
	};

	RenderItemCollectionUser = d => {
		this.renderItemCollectionUser = `${d.User.UserName} - ${d.User.FirstName} ${d.User.LastName}`;
		return this.renderItemCollectionUser;
	};

	RenderItemUser = d => {
		this.renderItemUser = `${d.UserName} - ${d.FirstName} ${d.LastName}`;
		return this.renderItemUser;
	};

	RowSelected = index => {
		this.setState({ isEdit: true, model: this.state.list[index], rowIndex: index });
	};

	GetCollectorParameter = () => {
		this.parameterCollector.IsAdmin = this.state.IsAdmin;
		return this.parameterCollector;
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};

	render() {
		const { Disabled, ForInsert } = this.props;
		const { alert, model, list, isLoading, rowIndex, collectorModel, isLedgerCardDialogOpen, isAssignCollectorDialogOpen, callInfoModel, isCallInfoEntryDialogOpen, paymentModel, isPaymentDialogOpen, IsAdmin } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				<GenericDialog open={isAssignCollectorDialogOpen} maxWidth="sm" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Assign Case to Collector" FontSize="20px" Bold />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={9} style={{ position: "fixed", top: "auto", left: "auto", width: 555, zIndex: 1, margin: "-5px -1px" }}>
								<GenericSelectInput
									Name="CollectionParameterId"
									LabelText="Collector"
									Method="POST"
									Url="/losapi/v1.0/CollectionParameter/GetAllForComponent"
									Parameter={this.GetCollectorParameter()}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={this.RenderItemCollectionUser}
									Value={collectorModel.CollectionParameterId}
									ValueChanged={this.HandleChangeCollector} />
							</GridItem>
							{!IsAdmin &&
								<GridItem xs={12} style={{ marginTop: 32 }}>
									<GenericTextInput
										Name="Reason"
										LabelText="Reason"
										RowCount={2}
										MultiLine={true}
										Value={collectorModel.Reason}
										ValueChanged={this.HandleChangeCollector} />
								</GridItem>
							}
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogAssignCollectorSubmit}>Submit</Button>
						<Button size="sm" onClick={this.DialogAssignCollectorClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog fullScreen open={isLedgerCardDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="Ledger Card" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<LedgerCard
							model={this.state.modelrow}
							ApplicationId={model.Id}
							IsPopUp={true}
						/>
						<GenericDialogActions>
							<Button size="sm" onClick={this.LedgerCardDialogOpenOrClose}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>

				<GenericDialog open={isCallInfoEntryDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Call Information Entry" FontSize="20px" Bold />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={6} style={{ marginTop: 10 }}>
								<Card style={{ height: "99%" }}>
									<CardHeader color="warning" icon>
										<CardIcon color="warning">
											<p style={{ color: "white" }}>
												<b>Loan Details</b>
											</p>
										</CardIcon>
									</CardHeader>
									<CardBody style={{ paddingTop: 16 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="RequestedAmount"
													LabelText="Requested Amount"
													LabelMd={5}
													MaxLength={10}
													Prefix="$"
													Value={model.Application ? model.Application.RequestedAmount : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="ApprovedAmount"
													LabelText="Approved Amount"
													LabelMd={5}
													MaxLength={10}
													Prefix="$"
													Value={model.Application ? model.Application.ApprovedAmount : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="PaymentAmount"
													LabelText="Payment Amount"
													LabelMd={5}
													MaxLength={10}
													Prefix="$"
													Value={model.Application ? model.Application.PaymentAmount : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={6} style={{ marginTop: 10 }}>
								<Card style={{ height: "99%" }}>
									<CardHeader color="warning" icon>
										<CardIcon color="warning">
											<p style={{ color: "white" }}>
												<b>Payments</b>
											</p>
										</CardIcon>
									</CardHeader>
									<CardBody style={{ paddingTop: 16 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="PrincipalAmount"
													LabelText="Principal Amount"
													LabelMd={5}
													MaxLength={10}
													Prefix="$"
													Value={model.Application ? model.Application.PrincipalAmount : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													Name="TotalSetupAmount"
													LabelText="Total Setup Amount"
													LabelMd={5}
													MaxLength={10}
													Prefix="$"
													Value={model.Application ? model.Application.TotalSetupAmount : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="PaymentOptionFeeId"
													LabelText="Fee Payment Type"
													LabelMd={5}
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterPayOption}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.Application ? model.Application.PaymentOptionFeeId : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="SavingsPlanId"
													LabelText={"Savings Plan"}
													LabelMd={5}
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterYesNo}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.Application ? model.Application.SavingsPlanId : ""}
													Disabled={true} />
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12} style={{ marginTop: 10 }}>
								<hr />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="FirstName"
									inputProps={this.maxLength30}
									IsText
									LabelText="First Name"
									Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.FirstName : "" : ""}
									Disabled={true} />
							</GridItem>
							<GridItem xs={6}>
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											Name="MobilePhoneCountryCodeId"
											LabelText="Phone Number"
											TitleLabel="Country Code"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={this.emptyObject}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.MobilePhoneCountryCodeId : "" : ""}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											Disabled={true} />
									</GridItem>
									<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
										<GenericNumberInput
											IsPhone
											Name="MobilePhoneNumber"
											Format="(###) ### ####"
											InputStyle={this.marginLeft2}
											LabelMd={0}
											Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.MobilePhoneNumber : "" : ""}
											MaxLength={10}
											Disabled={true} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="MiddleName"
									inputProps={this.maxLength30}
									IsText
									LabelText="Middle Name"
									Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.MiddleName : "" : ""}
									Disabled={true} />
							</GridItem>
							<GridItem xs={6}>
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											Name="OfficePhoneCountryCodeId"
											LabelText="Office Phone"
											TitleLabel="Country Code"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={this.emptyObject}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.OfficePhoneCountryCodeId : "" : ""}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											Disabled={true} />
									</GridItem>
									<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
										<GenericNumberInput
											IsPhone
											Name="OfficePhoneNumber"
											Format="(###) ### ####"
											InputStyle={this.marginLeft2}
											LabelMd={0}
											Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.OfficePhoneNumber : "" : ""}
											MaxLength={10}
											Disabled={true} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="LastName"
									inputProps={this.maxLength30}
									IsText
									LabelText="Last Name"
									Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.LastName : "" : ""}
									Disabled={true} />
							</GridItem>
							<GridItem xs={6}>
								<GenericDateInput
									Name="BirthDate"
									IsPast={true}
									LabelText="Date of Birth"
									Value={model.Application ? model.Application.Applicant ? model.Application.Applicant.BirthDate ? DateHelper.ToDateInputValue(model.Application.Applicant.BirthDate) : "" : "" : ""}
									IncludeTime={false}
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<hr />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									LabelText="Application Number"
									Value={model.Application ? model.Application.ApplicationNumber : ""}
									Disabled={true} />
							</GridItem>
							<GridItem xs={6}>
								<GenericNumberInput
									LabelText="Amount"
									Value={model.TotalWaitingAmount}
									Disabled={true}
									MaxLength={10}
									Prefix="$" />
							</GridItem>
							<GridItem xs={6}>
								<GenericNumberInput
									NoFormatting={true}
									LabelText="No of Delay Day"
									Value={model.NumberOfDelayDays}
									Disabled={true}
									MaxLength={10} />
							</GridItem>
							<GridItem xs={12}>
								<hr />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="CallStatusId"
									LabelText="Call Status"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterCallStatus}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={callInfoModel.CallStatusId}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
							<GridItem xs={6}>
								<GenericDateInput
									Name="RequestedCallDate"
									LabelText="Requested Call Date"
									IncludeTime={true}
									ViewMode="time"
									Value={callInfoModel.RequestedCallDate ? DateHelper.ToDateInputValue(callInfoModel.RequestedCallDate) : ""}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="IntervieweeId"
									LabelText="Interviewee"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterInterviewee}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={callInfoModel.IntervieweeId}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="PersonName"
									LabelText="Name of The Person"
									Value={callInfoModel.PersonName}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
							<GridItem xs={6}>
								<GenericSelectInput
									Name="InterviewResultId"
									LabelText="Interview Result"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.parameterInterviewResult}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={callInfoModel.InterviewResultId}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
							<GridItem xs={6}>
								<GenericDateInput
									Name="PromiseDate"
									LabelText="Promise Date"
									Value={callInfoModel.PromiseDate ? DateHelper.ToDateInputValue(callInfoModel.PromiseDate) : ""}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="CollectorNote"
									LabelText="Collector Call Note"
									LabelMd={2}
									RowCount={2}
									MultiLine={true}
									Value={callInfoModel.CollectorNote}
									ValueChanged={this.HandleChangeCallInfo} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DialogCallInfoSubmit}>Submit</Button>
						<Button size="sm" onClick={this.DialogCallInfoClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isPaymentDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="PAYMENT" FontSize="20px" Bold />
					</DialogTitle>
					<DialogContent>
						<ParameterComponent
							Disabled
							Name="LoanTypeId"
							LabelText="Loan Type"
							ParameterCode="LOSLoanType"
							Value={paymentModel.LoanTypeId}
							ValueChanged={this.HandleChangePayment} />
						{paymentModel.LoanType == LOSLoanType.Loan &&
							<>
								<GenericNumberInput
									Disabled
									Name="TotalPayment"
									LabelText="Total Payment"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.TotalPayment}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="TotalPaymentPeriod"
									LabelText="Total Payment Period"
									NoFormatting={true}
									Value={paymentModel.TotalPaymentPeriod}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="Remaining Late Payment Amount"
									LabelText="Remaining Late Payment Amount"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.RemainingLatePaymentAmount}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="RemainingPenaltyAmount"
									LabelText="Remaining Penalty Amount"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.RemainingPenaltyAmount}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="RemainingWaitingInstallmentAmount"
									LabelText="Remain Installment Amount"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.RemainingWaitingInstallmentAmount}
									ValueChanged={this.HandleChangePayment} />
							</>
						}
						<GenericNumberInput
							Disabled
							Name="RemainingPayment"
							LabelText="Remaining Payment"
							MaxLength={10}
							Prefix="$"
							Value={paymentModel.RemainingPayment}
							ValueChanged={this.HandleChangePayment} />
						{paymentModel.LoanType == LOSLoanType.Loan &&
							<>
								<GenericNumberInput
									Disabled
									Name="RemainingPeriod"
									LabelText="Remaining Period"
									NoFormatting={true}
									Value={paymentModel.RemainingPeriod}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="EarlyPaymentAmount"
									LabelText="Early Payment Amount"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.EarlyPaymentAmount}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="EarlyPaymentDiscount"
									LabelText="Early Payment Discount"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.EarlyPaymentDiscount}
									ValueChanged={this.HandleChangePayment} />
								<GenericNumberInput
									Disabled
									Name="TotalEarlyPayment"
									LabelText="Total Early Payment"
									MaxLength={10}
									Prefix="$"
									Value={paymentModel.TotalEarlyPayment}
									ValueChanged={this.HandleChangePayment} />
							</>
						}
						{paymentModel.LoanType != LOSLoanType.Loan &&
							<>
								<GenericNumberInput
									Disabled
									Name="NumberOfDelayDays"
									LabelText="Number of Delay Days"
									NoFormatting={true}
									Value={paymentModel.NumberOfDelayDays} />
							</>
						}
						<br />
						<br />
						<GenericLabel Text="With this process, the system will create a reverse record and close the case. The dept record is transferred to the legal follow-up wallet." Bold />
					</DialogContent>
					<GenericDialogActions>
						{/* <Button size="sm" onClick={this.DialogPaymentCloseCase}>Close Case</Button> */}
						<Button size="sm" onClick={this.DialogPaymentMoveLegalUp}>Move to Legal Follow Up</Button>
						<Button size="sm" onClick={this.DialogPaymentClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				{alert}

				<ButtonToolbar
					ButtonList={[
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
						{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					IsPopUp={ForInsert}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="ApplicantId"
												LabelText="Applicant"
												Method="POST"
												Url="/losapi/v1.0/Applicant/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullName"
												Value={model.ApplicantId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										{IsAdmin &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="UserId"
													LabelText="Collector"
													Method="POST"
													Url="/losapi/v1.0/CollectionParameter/GetAllUser"
													Parameter={this.parameterUser}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUser}
													Value={model.UserId}
													ValueChanged={this.HandleChange} />
											</GridItem>
										}
										<GridItem xs={3}>
											<GenericSelectInput
												Name="ClientPartnerId"
												LabelText="Partner"
												Method="POST"
												Url="/losapi/v1.0/ClientPartner/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="LegalNameOfBusiness"
												Value={model.ClientPartnerId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={3}>
											<ParameterComponent
												Name="LoanTypeId"
												LabelText="Loan Type"
												ParameterCode="LOSLoanType"
												Value={model.LoanTypeId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.HandleChange} Utc
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.HandleChange}
												Utc />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Transaction List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.ColumnsData}
												RowSelected={this.RowSelected}
												SelectedIndex={rowIndex}
												HideButton={true}
												Sorted={this.sortedNumber} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

CollectionPool.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CollectionPool, {});
