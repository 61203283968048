import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericCheckInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class CardFinancialAdjustment extends React.Component {
	constructor(props) {
		super(props);

		this.emptyObject = {};
		this.parameterApplicantId = {};
		this.parameterUniqueClientId = {};

		this.renderItemApplication = {};
		this.renderItemUniqueClient = {};

		this.state = {
			model: {
				ApplicantId: 0,
				UniqueClientId: 0,
				IsCashAdvance: false
			}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("Loan Management System");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Card Financial Adjustment");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (!value) {
				if (name == "UniqueClientId" || name == "ApplicantId") {
					model[name] = 0;
				}
			}

			if (name == "UniqueClientId") {
				model.ApplicantId = 0;
			}
			else if (name == "ApplicantId") {
				model.ApplicationId = null;
				model.CardId = null;
				model.CardFinancialId = null;
				model.CatmCardId = null;
			}
			else if (name == "CardId") {
				model.CardFinancialId = data?.CardFinancialId;
				model.CatmCardId = data?.CatmCardId;
			}

			return { model };
		});
	}

	RenderItemApplication = (d) => {
		this.renderItemApplication = `${d.ApplicationNumber}`;

		return this.renderItemApplication;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;

		return this.renderItemUniqueClient;
	}

	GetParameterApplicantId = () => {
		const { model } = this.state;

		this.parameterApplicantId.Id = model.ApplicantId;
		this.parameterApplicantId.ApplicantId = model.ApplicantId;

		return this.parameterApplicantId;
	}

	GetParameterUniqueClientId = () => {
		const { model } = this.state;

		this.parameterUniqueClientId.Id = model.UniqueClientId;

		return this.parameterUniqueClientId;
	}

	Submit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { model } = this.state;
		const { ExecuteApiPost, showConfirmMessage } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/Statement/CheckCardFinancialAdjustmentTranactionAlreadySent", model);

		if (result != null) {
			if (result) {
				var confirmResult = await showConfirmMessage("warning", "Warning", "The same transaction has been sent to the card today. Are you sure you want to send it again?");

				if (confirmResult) {
					this.SubmitConfirm();
				}
			} else {
				this.SubmitConfirm();
			}
		}
	}

	SubmitConfirm = () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		ExecuteApiPost("/lmsapi/v1.0/Statement/SaveCardFinancialAdjustment", model, null, null, null, null, true);
	}

	Clear = () => {
		this.setState({
			model: {
				ApplicantId: 0,
				UniqueClientId: 0,
				IsCashAdvance: false
			}
		});
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.UniqueClientId || model.UniqueClientId == 0) {
			errors.push("Client cannot be empty.");
		}
		if (!model.ApplicantId || model.ApplicantId == 0) {
			errors.push("Applicant cannot be empty.");
		}
		if (!model.ApplicationId) {
			errors.push("Application cannot be empty.");
		}
		if (!model.CardId) {
			errors.push("Applicant's card cannot be empty.");
		}
		if (!model.TransactionCode) {
			errors.push("Transaction code cannot be empty.");
		}
		if (!model.Amount) {
			errors.push("Amount cannot be empty.");
		}
		if (!model.Description) {
			errors.push("Description cannot be empty.");
		}

		model["IsUniqueClientId"] = !model.UniqueClientId || model.UniqueClientId == 0;
		model["IsApplicantId"] = !model.ApplicantId || model.ApplicantId == 0;
		model["IsApplicationId"] = !model.ApplicationId;
		model["IsCardId"] = !model.CardId;
		model["IsTransactionCode"] = !model.TransactionCode;
		model["IsAmount"] = !model.Amount;
		model["IsDescription"] = !model.Description;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Card Financial Adjustment">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsUniqueClientId}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												Required
												IsInvalid={model.IsCardId}
												key={model.ApplicantId}
												Name="CardId"
												LabelText="Applicant's Card"
												Url="/lmsapi/v1.0/PaymentAdmin/DomantCardStatusTrackingGetCard"
												Method="POST"
												Parameter={this.GetParameterApplicantId()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="CardNumber"
												Value={model.CardId}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsDescription}
												Name="Description"
												LabelText="Description"
												Value={model.Description}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsApplicantId}
												key={model.UniqueClientId}
												Name="ApplicantId"
												LabelText="Applicant"
												Url="/losapi/v1.0/Applicant/GetByUniqueClientId"
												Method="POST"
												Parameter={this.GetParameterUniqueClientId()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ApplicantName"
												Value={model.ApplicantId}
												ValueChanged={this.ValueChanged} />
											<GenericSelectInput
												Required
												IsInvalid={model.IsTransactionCode}
												Name="TransactionCode"
												LabelText="Transaction Code"
												Url="/lmsapi/v1.0/Statement/SearchAdjustableBankTransactionCode"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="TransactionCode"
												TextValueMember="TransactionName"
												Value={model.TransactionCode}
												ValueChanged={this.ValueChanged} />
											<GenericCheckInput
												Name="IsCashAdvance"
												LabelText="Is Cash Advance"
												Value={model.IsCashAdvance}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsApplicationId}
												key={model.ApplicantId}
												Name="ApplicationId"
												LabelText="Application"
												Url="/losapi/v1.0/Application/ListByApplicantId"
												Method="POST"
												Parameter={this.GetParameterApplicantId()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemApplication}
												Value={model.ApplicationId}
												ValueChanged={this.ValueChanged} />
											<GenericNumberInput
												Required
												IsInvalid={model.IsAmount}
												Name="Amount"
												LabelText="Amount"
												Prefix="$"
												Value={model.Amount}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardFinancialAdjustment.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default CardFinancialAdjustment;