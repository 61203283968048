import { FormControlLabel, Radio, withStyles } from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import genericRadioInputStyle from "assets/jss/generic/genericRadioInputStyle.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

class GenericRadioInput extends Component {

	constructor(props) {
		super(props);

		this.state = {
			Data: [],
			isLoading: false
		};

		this.FetchDataCallback = this.FetchDataCallback.bind(this);
		this.ErrorCallback = this.ErrorCallback.bind(this);
	}

	componentDidMount() {
		const { Method, Url, Parameter, IsStatic, StaticData } = this.props;

		if (IsStatic) {
			this.setState({ Data: StaticData });
			return;
		}

		this.setState({ isLoading: true });

		if (Method == "GET") {
			Proxy.GET(Url, this.FetchDataCallback, this.ErrorCallback);
		}
		else if (Method == "POST") {
			Proxy.POST(Url, Parameter, this.FetchDataCallback, this.ErrorCallback);
		}
		else {
			this.ErrorCallback("Method is invalid.");
		}
	}

	FetchDataCallback(response) {
		const { DataRoot } = this.props;

		this.setState({ Data: response[DataRoot], isLoading: false });
	}

	ErrorCallback(error) {
		this.setState({ isLoading: false });
	}

	render() {

		const { classes, LabelText, Name, Value, ValueChanged, KeyValueMember, TextValueMember, Disabled, IsFormControl, id, LabelMd, LabelStyle, success, error, labelProps } = this.props;
		const { Data } = this.state;
		const labelClasses = classNames({
			[" " + classes.labelRootError]: error,
			[" " + classes.labelRootSuccess]: success && !error
		});

		const DataMap = Data.map(d => {
			return (
				<FormControlLabel key={d[KeyValueMember]}
					control={
						<Radio
							checked={d[KeyValueMember] == Value}
							value={d[KeyValueMember]}
							name={Name}
							icon={
								<FiberManualRecord className={classes.radioUnchecked} />
							}
							checkedIcon={
								<FiberManualRecord className={classes.radioChecked} />
							}
							classes={{ checked: classes.radio }}
							onChange={e => {
								if (ValueChanged)
									ValueChanged(Name, e.target.value, d);
							}}
							disabled={Disabled}
						/>
					}
					classes={{
						label: classes.radioLabel
					}}
					label={d[TextValueMember]}
				/>
			);
		});
		const CaseWhenNotFormControl = (
		<div style={{ margin: 4 }}>
			<label className={classes.label}>{LabelText}</label>
			<br />
			{DataMap}
		</div>);
		const CaseWhenFormControl = (
			<FormControl className={classes.formControl} style={{width: "100%" }}>
			<GridContainer
				direction="row" justify="flex-start"
				alignItems="flex-end" style={{ paddingRight: 2, paddingTop: 2, paddingBottom: 2, marginTop: 4 }}>
				{LabelText != undefined && LabelText != "" &&
					<GridItem
						style={ LabelStyle}
						xs={LabelMd == -1 ? 0 : (LabelMd == undefined ? 4 : LabelMd)}
					>
						{LabelText !== undefined ? (
							<InputLabel
								className={classes.labelRoot + " " + labelClasses}
								htmlFor={id}
								{...labelProps}
							>
								{LabelText}
							</InputLabel>
						) : null}
					</GridItem>
				}

				<GridItem xs={LabelMd == -1 ? 0 : (LabelMd == undefined ? 8 : 12 - LabelMd)}>
					<div className={classes.FormControlRadio}>
						{DataMap}
					</div>
				</GridItem>
			</GridContainer>
		</FormControl >

		);
		return IsFormControl ? CaseWhenFormControl:	CaseWhenNotFormControl ;
			
	}
}

GenericRadioInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.func,
	Value: PropTypes.any,
	Name: PropTypes.string,
	LabelText: PropTypes.string,
	KeyValueMember: PropTypes.string,
	TextValueMember: PropTypes.string,
	Method: PropTypes.string,
	Url: PropTypes.string,
	Parameter: PropTypes.object,
	DataRoot: PropTypes.string,
	IsStatic: PropTypes.bool,
	StaticData: PropTypes.array,
	Disabled: PropTypes.bool,
	IsFormControl: PropTypes.bool,
	LabelMd: PropTypes.number,
	LabelStyle: PropTypes.object,
	formControlProps: PropTypes.object,
	labelProps: PropTypes.object,
};

export default withStyles(genericRadioInputStyle)(GenericRadioInput);