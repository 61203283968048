import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ProgramCodes, GridColumnType } from "views/Constants/Constant";

class Errors extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
		};

		this.state = {
			checkedValues: [],
			list: [],
			model: this.initialModel,
			countModel: {},
			isLoading: false,
			LimitToken: false
		};

		this.HandleCheck = this.HandleCheck.bind(this);
		this.HandleChange = this.HandleChange.bind(this);
		this.HandleChangeItemCount = this.HandleChangeItemCount.bind(this);
		this.HandleClear = this.HandleClear.bind(this);

		this.HandleGetList = this.HandleGetList.bind(this);
		this.SuccessListCallback = this.SuccessListCallback.bind(this);
		this.ErrorListCallback = this.ErrorListCallback.bind(this);

		this.GetItemCountCallback = this.GetItemCountCallback.bind(this);
		this.SuccessItemCountCallback = this.SuccessItemCountCallback.bind(this);
		this.ErrorItemCountCallback = this.ErrorItemCountCallback.bind(this);

		this.HandleSubmit = this.HandleSubmit.bind(this);
		this.SuccessSendCallback = this.SuccessSendCallback.bind(this);
		this.ErrorSendCallback = this.ErrorSendCallback.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		this.GetItemCountCallback();

		if (setAppLeftTitle)
			setAppLeftTitle("Error Transactions");
		if (setAppCenterTitle)
			setAppCenterTitle("Interac e-Transfer");

		this.RefreshToolBar();
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleCheck(e, x) {
		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}

	HandleClear() {
		this.setState({ model: this.initialModel });
	}

	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "MainSearchItemId")
			this.initialModel[name] = value;
		this.setState({ model });
	}

	HandleChangeItemCount(name, value) {
		const countModel = { ...this.state.countModel };
		countModel[name] = value;
		this.setState({ countModel });
	}

	HandleGetList() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/SearchETransferTransactions",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	ErrorListCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	GetItemCountCallback() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetETransferTransactionCountByStatus",
			{},
			this.SuccessItemCountCallback,
			this.ErrorItemCountCallback
		);
	}

	SuccessItemCountCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.HandleChangeItemCount("RejectedFiles", String(responseData.RejectedFiles));
		this.HandleChangeItemCount("ErrorCount", String(responseData.ErrorCount));
	}

	ErrorItemCountCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	HandleSubmit(model) {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/",
			model,
			this.SuccessSendCallback,
			this.ErrorSendCallback
		);
	}

	SuccessSendCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.showSuccessMessage("Succeeded!");
	}

	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, checkedValues, isLoading, model, list, LimitToken } = this.state;
		return (
			<div>
				<AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} />


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model === this.initialModel },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Issues, Errors"}>
								<CardBody>
									<h5></h5>
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Name="RejectedFiles"
												LabelText="Rejected Files"
												Value={this.state.countModel.RejectedFiles}
												ValueChanged={this.HandleChange}
												Disabled />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="ErrorCount"
												LabelText="Errors"
												Value={this.state.countModel.ErrorCount}
												ValueChanged={this.HandleChange}
												Disabled />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="MainSearchItemId"
													LabelText="Main Search Item"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "MainSearchItem",
														ParameterValue: "E",
														ParameterValue3: "eTransfer"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.MainSearchItemId}
													ValueChanged={this.HandleChange}
													Disabled={true}
													DefaultIndex={0}
													All={true}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="PriorityTypeId" Value={model.PriorityTypeId}
													LabelText="e-Transfer Type" ParameterCode="EtransferType"
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionTypeId}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionSourceId"
													LabelText="Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "TransactionSource"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionSourceId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													Name="PayeeName"
													LabelText="Customer Name"
													Value={model.PayeeName}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="PayeeEmail"
													LabelText="Customer E-Mail"
													Value={model.PayeeEmail}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="TransactionAmount"
													LabelText="Amount"
													Value={model.TransactionAmount}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="FileName"
													LabelText="File Name"
													Value={model.FileName}
													ValueChanged={this.HandleChange} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													Name="ClientReferenceNumber"
													LabelText="Transaction ID"
													Value={model.ClientReferenceNumber}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="StoreId"
													LabelText="Store"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "CustomerStore"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.StoreId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
													ValueChanged={this.HandleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Errors Total"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div style={{ opacity: !row.original.IsEditable ? 0.4 : 1 }}>
																	<GenericCheckInput
																		Grid
																		Value={checkedValues.filter(c => c.Id == row.original.Id).length > 0}
																		ValueChanged={(e) => {
																			this.HandleCheck(e, row.original);
																		}}
																		Disabled={!row.original.IsEditable} />

																	<GridButton
																		tooltip="Edit"
																		Disabled={!row.original.IsEditable}
																		Icon={EditIcon}
																		OnClick={() => { RouteHelper.Push(this.props.history, "/CreateEtransfer", "CreateEtransfer_Id", row.original.Id); }} />
																</div>
															),
															sortable: false,
															filterable: false,
															width: 100
														},
														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateTime
														},
														{
															Header: "Transaction ID",
															accessor: "ClientReferenceNumber"
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource.ParameterDesc"
														},
														{
															Header: "Program",
															accessor: "Program.Description"
														},
														{
															Header: "Transaction Type",
															accessor: "TransactionType.ParameterDesc"
														},
														{
															Header: "Settlement Type",
															accessor: "PriorityType.ParameterDesc"
														},
														{
															Header: "Customer Number",
															accessor: "ClientNumber"
														},
														{
															Header: "Name of Customer",
															accessor: "ClientName"
														},
														{
															Header: "Amount",
															accessor: "TransactionAmount",
															type: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "FeeAmount",
															type: GridColumnType.Money
														},
														{
															Header: "Transaction Status",
															accessor: "TransactionStatus.ParameterDesc"
														},
														{
															Header: "Transaction Sub-Status",
															accessor: "TransactionCodeName"
														},

														{
															Header: "Customer E-Mail",
															accessor: "EMailAddress"
														},
														{
															Header: "Error Code",
															accessor: "ErrorCode.ParameterDesc"
														},
														{
															Header: "File Id",
															accessor: "FileId"
														},
														{
															Header: "File Name",
															accessor: "FileName"
														}
													]}
													ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Errors.propTypes = {
	classes: PropTypes.object
};

export default Errors;
