

const kycManualDigitalVerificationStatusPanelStyle = {
	mainWrapper: {
		width: "100%",
	},
	mediaCard: {
		margin: "auto",
		height: 200,
		width: 300,
		borderRadius: 25,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2
	},
	mediaCardScoreOnly: {
		height: 320,
		width: 475,
	},
	mediaPreview: {
		margin: "auto",
		height: 550,
		borderRadius: 25,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2
	},
	mediaCardPlaceholder: {
		backgroundSize: "contain !important",
	},
	mediaProfile: {
		width: "70%",
		margin: "auto",
		height: 200,
		borderRadius: 20,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2,
	},
	cardHeader: {
		margin: "0px 0px 5px 40px"
	},
	profileHeader: {
		margin: "0px 0px 5px 40px"
	}

};

export default kycManualDigitalVerificationStatusPanelStyle;
