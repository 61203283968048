import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericCheckInput, GenericCardMedia } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, GridColumnType, CrudType, FileCode, FileType  } from "views/Constants/Constant.js";
import Button from "components/CustomButtons/Button.jsx";
import ImageHelper from "core/ImageHelper";
import FileHelper from "core/FileHelper";
import { withStyles } from "@material-ui/core";
import iframeKeyDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/iframeKeyDefinitionStyle.jsx";


const LogoHere = require("assets/img/logo.png");
class IframeKeyDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			photoBase64: null,
		};

		this.fileInputRef1 = React.createRef();

		this.columns = [
			{
				Header: "Is Active",
				accessor: "IsActive",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Tracking Id",
				accessor: "EncryptedApiKey"
			},
			{
				Header: "Insert DateTime",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Update DateTime",
				accessor: "UpdateDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			}
		];

		this.state = {
			list: [],
			model: this.initialModel,
			isLoading: false,
			vModel: {},
			IsInsertOp: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Iframe Key Definition");
		this.props.setAppCenterTitle("SYSTEM ADMIN");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {}, index: -1, IsInsertOp: false, photoBase64: null });
	};

	IsValid = (operationType) => {
		const { model, vModel } = this.state;
		var errorList = [];
		const { showValidationErrors } = this.props;

		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == "";
		if (vModel.UniqueClientId) {
			errorList.push("Client cannot be empty.");
		}

		vModel.EncryptedApiKey = (model.EncryptedApiKey == null || model.EncryptedApiKey == "") && operationType != "Insert";
		if (vModel.EncryptedApiKey) {
			errorList.push("Api Key cannot be empty.");
		}

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	HandleSearch = () => {

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/SearchIframeApiKeyList",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({
						model,
						list: responseData.Item,
					});
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	HandleInsertOrUpdate = (operationType) => {

		if (!this.IsValid(operationType))
			return;

		const { photoBase64 } = this.state;
		const model = { ...this.state.model };

		model.OperationType = operationType;
		model.Base64Logo = photoBase64;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/InsertOrUpdateIframeApiKey",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Succeeded", "Operation is successfully!");
				this.setState({
					model: { ...this.initialModel },
					isLoading: false,
					index: -1
				});
				this.handleClear();
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	RowSelected = (index) => {
		let { model, list, photoBase64 } = this.state;
		model = list[index];
		photoBase64 = model.Base64Logo;

		this.setState({ model, index, IsInsertOp: true, photoBase64 });
	}

	OpenFileSelectDialog = () => {
		this.fileInputRef1.current.click();
	}

	HandleFileSelect = async (e) => {

		const { model } = this.state;
		e.preventDefault();
		var file1 = e.target.files[0];
		if (!file1) return;
		this.setState({ isLoading: true });
		console.log(file1);

		if (((file1.size || 0) / 1024 / 1024) > 10) {
			this.props.showMessage("error", "Error", "Photo file size cannot be larger than 10mb");
			this.setState({ isLoading: false });
			return;
		}

		var blob = await ImageHelper.CompressImage(file1);//Start Compression if over 500kb
		var compressedBase64 = await FileHelper.BlobToBase64(blob);
		var compressedFile = FileHelper.DataURLtoFile(compressedBase64, file1.name);

		model.TempFileId = model.UserPhotoId || 0;
		model.File = compressedFile;
		model.FileName = file1.name;
		model.FileSelect = true;

		this.setState({ photoBase64: compressedBase64, isLoading: false });
	}

	handleRotateImage = async () => {
		const { photoBase64, model } = this.state;
		if (!photoBase64) {
			return;
		}
		this.setState({ isLoading: true });
		var imageBase64 = ImageHelper.GenerateImageFromRawBase64(photoBase64);
		var rotatedBase64 = await ImageHelper.Rotate(imageBase64, 90);
		var rotatedFile = FileHelper.DataURLtoFile(rotatedBase64, model.FileName);
		model.File = rotatedFile;
		this.setState({ photoBase64: rotatedBase64, model, isLoading: false });
	}

	render() {
		const { Disabled, showConfirmMessageInDelete, ExecuteApiPost, classes } = this.props;
		const { alert, model, list, isLoading, index, vModel, IsInsertOp, photoBase64 } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled || model.Id > 0,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || model.Id > 0,
							OnClick: () => this.HandleInsertOrUpdate("Insert")
						},
						{
							Code: "Update",
							Disabled: Disabled || model.Id === 0,
							OnClick: () => this.HandleInsertOrUpdate("Update")
						},
						{
							Code: "Delete",
							Disabled: Disabled || model.Id === 0,
							OnClick: () => this.HandleInsertOrUpdate("Delete")
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.handleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>

				<GridContainer>
					<GridItem xs={12}>
						<Card>
								<CardBody>
									<GenericExpansionPanel>
										<GridContainer>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{
														UniqueClientId: ClientHelper.IsClient()
															? ClientHelper.GetClientId()
															: undefined
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
													IsInvalid={vModel.UniqueClientId}
													Required />
											</GridItem>
											<GridItem xs={4}>
												<GenericTextInput
													LabelText="Api Key"
													Name="EncryptedApiKey"
													Value={model.EncryptedApiKey}
													ValueChanged={this.handleChange}
													IsInvalid={vModel.EncryptedApiKey}
													Disabled={IsInsertOp}
													Required />
											</GridItem>
											<GridItem xs={4}>
												<GenericCheckInput
													LabelText="Is Active"
													Name="IsActive"
													Value={model.IsActive}
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridContainer>
									</GenericExpansionPanel>
								</CardBody>
						</Card>
						<GridContainer>
							<GridItem xs={12}>
								<Card>
									<GenericExpansionPanel IsActive={true} Title="Logo Definition" >
										<CardBody>
											<GridContainer direction="row">
												<GridItem xs={2}>
													<GenericCardMedia
														className={classes.mediaProfileForLogo}
														Image={photoBase64}
														ImagePlaceholder={ LogoHere }
														OnClickRotate={this.handleRotateImage}
													/>
												</GridItem>
												<GridItem xs={2}>
													<Button
														size="sm"
														onClick={this.OpenFileSelectDialog}>
														Select Photo
													</Button>
													<input type="file" style={{ display: "none" }} onChange={this.HandleFileSelect} ref={this.fileInputRef1} accept="image/png,image/jpeg" />
												</GridItem>
											</GridContainer>
										</CardBody>
									</GenericExpansionPanel>
								</Card>
							</GridItem>
						</GridContainer>

						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ShowPagination={true}
													RowSelected={this.RowSelected}
													SelectedIndex={index}
													PageSize={100}
													ProgramCode={ProgramCodes.Prepaid}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

IframeKeyDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withStyles(iframeKeyDefinitionStyle)(IframeKeyDefinition);