import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { ApproveIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import PropTypes, { bool, func } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericPage, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes, TransactionTraceStatus } from "views/Constants/Constant";

class WireTraceTransactionSearch extends GenericPage {

	constructor(props) {
		super(props);

		this.IsBackOffice = ClientHelper.IsBackOffice();
		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						{
							<GridButton
								tooltip="Complete Review"
								Disabled={this.props.Disabled || row.original.TraceStatusParameterValue == TransactionTraceStatus.Completed || !this.state.gridButtonRightDetail.find(x => x.Code == "WIRETRACECOMPLETE")?.HasAuthority}
								Icon={ApproveIcon}
								OnClick={this.GetHandleTraceApprove(row.original)} />
						}
					</div>
				)
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId",
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
			},
			{
				Header: "SenderName",
				accessor: "SenderName",
			},
			{
				Header: "Trace Status",
				accessor: "TraceStatus"
			},
			{
				Header: "Trace Reject Reason",
				accessor: "TraceRejectReason"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
			},
			{
				Header: "Client User",
				accessor: "InsertUser",
			},
			{
				Header: "Source Wallet Number",
				accessor: "FromAccAccountNumber"
			},
			{
				Header: "To Wallet Number",
				accessor: "ToAccountNumber"
			},
			{
				Header: "To IBAN",
				accessor: "ToIBAN"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "SubStatus",
				accessor: "SubStatus",
				show: this.IsBackOffice
			},
			{
				Header: "MessageType",
				accessor: "MessageType"
			},
			{
				Header: "Date of Request",
				accessor: "DateRequest",
				type: GridColumnType.Date
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective",
				type: GridColumnType.Date
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Currency",
				accessor: "CurrencyName"
			},
			{
				Header: "Remittance",
				accessor: "Remittance"
			},
			{
				Header: "Purpose Of Wire",
				accessor: "PurposeOfWire"
			}
		];
		this.DetailButtonDisable = true;
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.defaultModel = {
			ClientId: null,
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			MessageTypeId: null,
			SubStatusId: null,
			StatusId: null,
			AmountFrom: null,
			AmountTo: null,
			StatusParamCode: null,
			ReferenceNumber: null,
			TraceStatusId: null,
			DirectionParamValue: null
		};

		this.state = {
			model: { ...this.defaultModel },
			subStatusList: [],
			tempSubStatusList: [],
			transactionList: [],
			isLoading: false,
			IsDomestic: {},
			detailDialog: false,
		};
	}


	componentDidMount() {
		super.componentDidMount();
		this.GetGridButtonsRight();

		this.props.setAppLeftTitle("Wire Trace Transactions");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}
	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["WIRETRACECOMPLETE"]);
		this.setState({ gridButtonRightDetail });
	}

	GetHandleTraceApprove = (model) => async () => {
		if (!await this.props.showConfirmMessage("warning", "Warning", "Are you sure you want to save trace transaction as completed?")) return;

		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/TransactionTrace/ApproveTracedTransactionById", { Id: model.TransactionId });
		if (result) {
			this.GetData();
			this.props.showMessage("success", "Success", "Transaction Trace successfully saved as completed!");
		}
	}

	HandleChange(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "StatusId") {
				if (newValue == -1) {
					model.StatusParamCode = null;
				}
				else if (data && data.ParameterValue == null) {
					model.StatusParamCode = null;
				}
				else if (data) {
					model.StatusParamCode = data.ParameterValue;
				}
			}
			return { model };
		});
	}

	GetData() {

		var requestObj = {
			TracedTransactionOnly: true,
			...this.state.model
		};
		this.ExecutePostRequest(
			"/bankapi/v1.0/WireTransfer/SearchWireTraceTransaction",
			requestObj,
			responseData => {
				if (responseData.Item == null || responseData.Item.length < 1) {
					responseData.Item = [];
				}
				this.setState({ transactionList: responseData.Item });
			}
		);
	}

	ClearModel() {
		this.setState({ model: { FromDate: DateHelper.GetDateLocal(), ToDate: DateHelper.GetDateLocal() } });
	}

	render() {
		const { Disabled } = this.props;
		const { IsLoading, Alert } = this.state;
		const { model } = this.state;
		return (
			<div>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={true}>
							<GridContainer>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericSelectInput
												Name="ClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.ClientId || ""}
												ValueChanged={this.HandleChange}
												All
												CanClear />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.HandleChange}
												MaxDate={model.ToDate || DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate == null ? null : model.FromDate}
												MaxDate={DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												All
												Name="TraceStatusId"
												LabelText="Trace Status"
												ParameterCode="TransactionTraceStatus"
												Value={model.TraceStatusId}
												ValueChanged={this.HandleChange} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<ParameterComponent
												All
												Name="StatusId"
												Value={model.StatusId}
												LabelText="Status"
												ParameterCode="WireStatus"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												key={"wiresubstatus_" + model.StatusId}
												Name="SubStatusId"
												Value={model.SubStatusId}
												LabelText="Sub-Status"
												ParameterCode="WireSubStatus"
												ParameterValue2={model.StatusParamCode}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												Name="MessageTypeId"
												Value={model.MessageTypeId}
												LabelText="Message Type"
												ParameterCode="WireMessageType"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												All
												Name="DirectionParamValue"
												LabelText="Direction"
												Value={model.DirectionParamValue}
												KeyValueMember="ParameterValue"
												ParameterCode="Direction"
												ParameterValue2="ETRANSFER" // Burası None gelmesin diye bu şekilde
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountFrom"
												LabelText="Amount From"
												Value={model.AmountFrom == null ? "" : model.AmountFrom}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountTo"
												LabelText="Amount To"
												Value={model.AmountTo == null ? "" : model.AmountTo}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<br />
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.transactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={this.columns}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

WireTraceTransactionSearch.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(WireTraceTransactionSearch, {});

