import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import DDTListStyle from "assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { OrderStatusType, GridColumnType } from "views/Constants/Constant";
import ChequeOrderDetailInfo from "../ChequeOrder/ChequeOrderDetailInfo";
import LoadingComponent from "views/Components/LoadingComponent";

class ChequeManagement extends Component {
	constructor(props) {
		super(props);

		var user = ClientHelper.GetUser();
		this.initialModel = {
			Id: 0,
			UniqueClientId: user.UniqueClientId,
			// ClientTypeId: user.UniqueClient.ClientTypeId
		};

		this.state = {
			model: this.initialModel,
			pageSelect: "",
			list: [],
			prmList: [],
			isLoading: false,
			alert: "",
			selected: null,
			pricetxt: "",
			chequemodel: {},
			openDialog: false,
			isBackOffice: false,
			chqinfomodel: {},
			totalchqinfomodel: {},
			IsUpdate: false,
			vModel: {}
		};
	}
	componentDidMount() {
		// var tempuniq = Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Order Cheque");
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("WALLET");
		}
		this.setState({ openDialog: false });

		this.GetParameter();
		var { isBackOffice, IsUpdate } = this.state;

		isBackOffice = ClientHelper.IsBackOffice();
		IsUpdate = false;

		this.setState({ isBackOffice, IsUpdate });

	}



	handleChange = (name, value, data) => {

		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });

		if (name === "QuantityTypeId") {
			model["Price"] = data.ParameterValue2;
			this.setState({ model, pricetxt: data.ParameterValue2 });

		}

	}

	IsSubmit = () => {

		this.setState({ isLoading: true, alert: null });

		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;

		Proxy.POST(
			model.Id === 0
				? "/chequeapi/v1.0/Cheque/Insert"

				: "/chequeapi/v1.0/Cheque/Update",

			model.Id === 0 ? model : {
				ChequeTypeId: model.ChequeTypeId,
				Id: model.Id,
				Price: model.Price,
				QuantityTypeId: model.QuantityTypeId,
				ShipAttentionToId: model.ShipAttentionToId,
				SignatureTypeId: model.SignatureTypeId,
				UniqueClientId: model.UniqueClientId,

			}
			,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}


				this.ShowMessage("success", "Operation is successfully!");

				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);

	}

	Submit = () => {

		if (!this.Validate()) {
			return;
		}

		this.ShowConfirmMessage("warning", "Warning", "Are you sure you want to give a cheque order ?", this.IsSubmit)


	}
	UpdateDialog = () => {
		if (!this.Validate()) {
			return;
		}

		this.ShowConfirmMessage("warning", "Warning", "Are you sure you want to update the cheque order ?", this.IsSubmit)

	}
	Delete = () => {
		this.setState({ isLoading: true });

		Proxy.GET(
			"/chequeapi/v1.0/Cheque/Delete/Id?Id=" + this.state.model.Id,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				this.ShowMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	Search = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;

		Proxy.POST(
			"/chequeapi/v1.0/Cheque/SearchChequeOrderList",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ list: responseData.Item.ChequeOrderList || [], isLoading: false, totalchqinfomodel: responseData.Item.ChequeDetailInfo });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	OpenEditModal = () => {
		Proxy.POST(
			"/chequeapi/v1.0/Cheque/ChequeOrderDetailInfo",
			{
				Id: this.state.model.UniqClntId,
				ClientTypeId: this.state.model.ClientTypeId
			},

			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var chqinfomodel = this.state.chqinfomodel;
				this.setState({ chequemodel: responseData.Item, isLoading: false, openDialog: true, chqinfomodel });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
		// this.setState({
		// 	openDialog: true
		// });
	}

	GetParameter = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;

		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{

				ParameterCode: "QuantityType",
				"ParameterValue": "",
				"ParameterValue3": "",
				"ParameterDesc": ""
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ prmList: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Clear = () => {
		this.setState({ model: this.initialModel, list: [], selected: null, pricetxt: "", totalchqinfomodel: {}, chqinfomodel: {}, IsUpdate: false, isLoading: false });
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}

	// PreView = () => {

	// 	this.setState({ isLoading: true });
	// 	var FileName = "";
	// 	Proxy.FileDownload(
	// 		"/chequeapi/v1.0/Cheque/DowloadFile",
	// 		FileName + "-" + Math.floor(Math.random() * Math.floor(999999)),
	// 		"pdf",
	// 		{
	// 			"Id": "40"
	// 		},
	// 		() => {
	// 			this.setState({ isLoading: false });
	// 		}
	// 	);
	// }

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null })}
					OnConfirm={onConfirm}
				/>,
			isLoading: false
		});
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	Validate() {
		const { model, list } = this.state;
		const vModel = { ...this.state.vModel };
		var ErrorList = [];

		if (!model.QuantityTypeId) {
			ErrorList.push("Quantity Type must be defined");
			vModel.QuantityTypeId = true;  
 
		} else {
			vModel.QuantityTypeId = false; 
		}
	
		if (!model.Price || model.Price == "") {
			ErrorList.push( "Price  not must be defined");
			vModel.Price = true;  
	 
		} else {
			vModel.Price = false;  
		}
		if (!model.ShipAttentionToId) {
			ErrorList.push(  "ShipAttentionTo must be defined");
			vModel.ShipAttentionToId = true; 
		 
		} else {
			vModel.ShipAttentionToId = false; 
		}
		if (!model.SignatureTypeId) {
			ErrorList.push( "  Signature Type must be defined");
			vModel.SignatureTypeId = true;  
		 
		} else {
			vModel.SignatureTypeId = false; 
		}
		if (!model.ChequeTypeId) {
			ErrorList.push( "Cheque Type must be defined");
			vModel.ChequeTypeId = true; 
			 
		} else {
			vModel.ChequeTypeId = false; 
		}

		if (ErrorList.length > 0) {
			this.setState({ vModel });
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}


		this.setState({ vModel });
		return true;
	}

	render() {
		const { classes } = this.props;
		const { isLoading, alert, model, list, selected, prmList, pricetxt, totalchqinfomodel, chqinfomodel, IsUpdate, vModel } = this.state;

		var mapData = prmList.map(d => {
			return {
				Id: d.Id,
				ParameterCode: d.ParameterCode,
				ParameterValue: d.ParameterValue,
				ParameterValue2: d.ParameterValue2
			};
		});
		return (
			<div>
				<GenericDialog open={this.state.openDialog} maxWidth="md" fullWidth onBackdropClick={this.handleClose}>
					<DialogTitle><GenericLabel Text="Cheque Order Information" FontSize="20px" Bold={true} /></DialogTitle>
					<DialogContent >
						{<ChequeOrderDetailInfo
							chequemodel={this.state.chequemodel}
							chqinfomodel={this.state.chqinfomodel}
						/>}

					</DialogContent>
					<GenericDialogActions>
						<Button onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>



				<LoadingComponent Show={this.state.isLoading} />


				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.Search
					},

					{
						Code: "Submit",
						OnClick: this.Submit,
						Disabled: IsUpdate == true ? true : false,
					},
					{
						Code: "Update",
						Disabled: IsUpdate == true ? chqinfomodel.OrderStatusValue != undefined ? chqinfomodel.OrderStatusValue == OrderStatusType.Approved ? true : false : false : true,
						OnClick: this.UpdateDialog

					},
					{
						Code: "Delete",
						OnClick: () => this.ShowConfirmMessage("warning", "Warning", "Are you sure want to Delete ?", this.Delete),
						Disabled: model.Id == 0 ? chqinfomodel.OrderStatusValue != undefined ? chqinfomodel.OrderStatusValue == OrderStatusType.Approved ? true : false : true : chqinfomodel.OrderStatusValue == OrderStatusType.Approved ? true : false

					},
					{
						Code: "Clear",
						OnClick: this.Clear,

					}, {
						Code: "Preview",
						OnClick: this.OpenEditModal,
						Disabled: chqinfomodel.SignatureTypeId === undefined ? true : false

					},
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card className={classes.zerMarPad}>
					<GenericExpansionPanel>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<GridContainer spacing={16} >
										<GridItem xs={4}>
											{<GenericSelectInput
												Name="QuantityTypeId"
												IsStatic={true}
												StaticData={mapData}
												LabelText="Select Quantity"
												Value={model.QuantityTypeId || ""}
												KeyValueMember="Id"
												TextValueMember="ParameterValue"
												IsInvalid={vModel.QuantityTypeId}
												Required
												ValueChanged={this.handleChange}
											/>}

											<GenericNumberInput
												Name="Price"
												LabelText="Price"
												Value={pricetxt == null ? "0" : pricetxt ? pricetxt : "0"}
												Disabled={true}
												IsInvalid={vModel.Price}
												Required
												Prefix="$" />
										</GridItem>

										<GridItem xs={4}>
											<GenericSelectInput
												Name="ShipAttentionToId"
												LabelText="Ship Attention To"
												Value={model.ShipAttentionToId == null ? "" : model.ShipAttentionToId}
												DataRoot="Item"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="Name"
												Url="/bankapi/v1.0/CustomerKeyContact/GetByUniqueClientId"
												Method="POST"
												// Disabled={!isBackOffice}
												Parameter={{
													UniqueClientId: this.initialModel.UniqueClientId,
												}}
												IsInvalid={vModel.ShipAttentionToId}
												Required

											/>
											<ParameterComponent
												Name="SignatureTypeId"
												Value={model.SignatureTypeId}
												LabelText="Signature Type"
												ParameterCode="SignatureType"
												ValueChanged={this.handleChange}
												IsInvalid={vModel.SignatureTypeId}
												Required
											/>
											<ParameterComponent
												Name="ChequeTypeId"
												Value={model.ChequeTypeId}
												LabelText="Cheque Type"
												ParameterCode="ChequeType"
												ValueChanged={this.handleChange}
												IsInvalid={vModel.ChequeTypeId}
												Required
											/>
										</GridItem>


									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Cheque Orders"} />
						</CardHeader>
						<GridItem xs={12}>
							<GenericGrid
								Data={list}
								SelectedIndex={selected}
								Columns={[
									{
										Header: "Date",
										accessor: "ChequeOrderDate",
										type: GridColumnType.Date
									}, {
										Header: "Quantity",
										accessor: "QuantityType"
									}, {
										Header: "Price",
										accessor: "Price",
										type: GridColumnType.Money
									},
									{
										Header: "Signature Type",
										accessor: "SignatureType"
									},
									{
										Header: "Ship Attention to",
										accessor: "ShipAttentionTo"
									},
									{
										Header: "Cheque Type",
										accessor: "ChequeType"
									},
									{
										Header: "Order Status",
										accessor: "OrderStatus",

									}
								]}
								RowSelected={index => {
									var chqinfomodel = {
										SignatureTypeId: list[index].SignatureTypeId,
										ChequeTypeId: list[index].ChequeTypeId,
										OrderStatusValue: list[index].OrderStatusValue
									};
									this.setState({ model: list[index], selected: index, pricetxt: list[index].Price, chqinfomodel, IsUpdate: true });
								}} />

						</GridItem>
						<GridContainer >
							<GridItem xs={6} />
							<GridItem xs={6}>
								<GridContainer className={classes.containerHeight} justify="flex-end">
									<GridItem xs={2} className={classes.gridBorder}>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Waiting</p>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Approved</p>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Total</p>
									</GridItem>
									{<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Quantity</p>
									</GridItem>}
									{<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Price</p>
									</GridItem>}
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
										TOTAL
							</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalWaiting == null || undefined ? 0 : typeof totalchqinfomodel.TotalWaiting == "number" ? Formatter.FormatNumber(totalchqinfomodel.TotalWaiting) : totalchqinfomodel.TotalWaiting}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalApproved == null || undefined ? 0 : typeof totalchqinfomodel.TotalApproved == "number" ? Formatter.FormatNumber(totalchqinfomodel.TotalApproved) : totalchqinfomodel.TotalApproved}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.Total == null || undefined ? 0 : typeof totalchqinfomodel.Total == "number" ? Formatter.FormatNumber(totalchqinfomodel.Total) : totalchqinfomodel.Total}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalQuantity == null || undefined ? 0 : typeof totalchqinfomodel.TotalQuantity == "number" ? Formatter.FormatNumber(totalchqinfomodel.TotalQuantity) : totalchqinfomodel.TotalQuantity}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										($)
								{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalPrice == null || undefined ? 0 : typeof totalchqinfomodel.TotalPrice == "number" ? Formatter.FormatNumber(totalchqinfomodel.TotalPrice) : totalchqinfomodel.TotalPrice}
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</div >
		);
	}
}

ChequeManagement.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(ChequeManagement, DDTListStyle);