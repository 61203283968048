import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericTextInput, GenericDialog, GenericRadioInput, GenericLabel, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import DateHelper from "core/DateHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";

class VisaSettlementRefundsReport extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: {},
			filterModel: {},
			isLoading: false
		};

		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Settlement Refunds Report");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({
			model: {}
		});
	};

	HandleSearch = () => {
		const model = { ...this.state.model };
		const { showMessage } = this.props;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/prepaidapi/v1.0/VisaClearingTransaction/VisaSettlementRefundsSearch",
			model,
			(responseData) => {
			  this.setState({ isLoading: false });
 
			  if (!responseData.IsSucceeded) {
				 showMessage("error", "Error", responseData.ErrorDescription);
				 return;
			  }
			  this.setState({
				list: responseData.Item ?? [],
			  });
			},
			(error) => {
			  this.setState({ isLoading: false });
			  showMessage("error", "Error", error);
			}
		 );
	};

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		const { showMessage } = this.props;

		if (!this.state.ExportReportType) {
			showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Settlement Refunds Report";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/prepaidapi/v1.0/VisaClearingTransaction/VisaSettlementRefundsSearchExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	render() {
		const { alert, model, list, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.HandleClear
						},
						{  
							Code: "Export", 
							Disabled: false,
							OnClick: this.ExportClick
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericDateInput
														Utc
														Name="FromDate"
														LabelText="From Date"
														Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
														ValueChanged={this.HandleChange}
													/>
													<GenericDateInput
														Utc
														Name="ToDate"
														LabelText="To Date"
														Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
														ValueChanged={this.HandleChange}
														MinDate={model.ToDate}
													/>
												</GridItem>
												<GridItem xs={6}>
													<GenericTextInput
														Name="FileName"
														LabelMd={4}
														LabelText="File Name"
														IsText={true}
														Value={model.FileName}
														ValueChanged={this.HandleChange}
													/>
													<GenericTextInput
														Name="CardLastFourDigit"
														LabelMd={4}
														Format={"@@@@"}
														LabelText="Card Last Four Digit"
														IsText={true}
														Value={model.CardLastFourDigit}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												ref={this.trxTable}
												Columns={
													[
														{
															Header: "BIN",
															accessor: "BIN"
														},
														{
															Header: "Settlement Date",
															accessor: "SettlementDate",
															type: GridColumnType.DateTime
														},
														{
															Header: "Masked Card Number",
															accessor: "MaskedCardNumber"
														},
														{
															Header: "Merchant Name",
															accessor: "MerchantName"
														},
														{
															Header: "Merchant Country",
															accessor: "MerchantCountry"
														},
														{
															Header: "Mti",
															accessor: "TransactionType"
														},
														{
															Header: "Billing Amount",
															accessor: "BillingAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money,
														},
														{
															Header: "Settlement Amount",
															accessor: "SettlementAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money,
														},
														{
															Header: "Transaction Reference Number",
															accessor: "TransactionReferenceNumber"
														}, 
														{
															Header: "Matched Transaction Reference Number",
															accessor: "MatchedTransactionReferenceNumber"
														},
														{
															Header: "Matched Date",
															accessor: "MatchedDateTime",
															type: GridColumnType.DateTime
														},
														{
															Header: "Retrieval Reference Number",
															accessor: "RetrievalReferenceNumber"
														},
														{
															Header: "Settlement Reference Number",
															accessor: "ExternalReferenceNumber"
														},
														{
															Header: "Trace Number",
															accessor: "TraceNumber"
														},
														{
															Header: "Response Code",
															accessor: "ResponseCode"
														},
														{
															Header: "Acquier Id",
															accessor: "AcquierId"
														},
														{
															Header: "CardAcceptor Id",
															accessor: "CardAcceptorID"
														},
														{
															Header: "Card Acceptor Terminal Id",
															accessor: "CardAcceptorTerminalID"
														},													
														{
															Header: "File Name",
															accessor: "FileName"
														}
													]
												}
												ShowPagination={true}
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>									
			</div>
		);
	}
}

VisaSettlementRefundsReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default VisaSettlementRefundsReport;
