import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { BankTransactionStatus, GridColumnType, MenuCodes, ProgramCodes } from "views/Constants/Constant.js";

class EftIncomingTransactions extends React.Component {
	constructor(props) {
		super(props);

		this.clientRowId = ClientHelper.GetClientRowId();
		this.isBackOffice = ClientHelper.IsBackOffice();

		this.initialModel = {
			TransactinDateFrom: DateHelper.GetDate(),
			TransactinDateTo: DateHelper.GetDate(),
			UniqueClientId: this.isBackOffice ? undefined : this.clientRowId,
			ReferenceNumber: null,
			MenuCode: props.MenuCode
		};

		this.state = {
			model: { ...this.initialModel },
			countModel: {},
			isLoading: false,
			checkedValues: [],
			selectList: [],
		};

		this.trxTable = React.createRef();

		this.trxSorted = [{ "id": "TransactionDate", "desc": true }];
	}

	componentDidMount() {
		const { MenuCode, setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle) {

			if (this.props.MenuCode == MenuCodes.Eft_BackofficeEftIncoming ||
				this.props.MenuCode == MenuCodes.Eft_Transaction_Incoming) {
				setAppLeftTitle("Incoming Transactions");
			} else {
				setAppLeftTitle("Incoming EFT Transactions");
			}
		}

		if (setAppCenterTitle) {
			if (MenuCode == MenuCodes.Menu_Backoffice_DDT_Transactions_IncomingEftTransactions ||
				MenuCode == MenuCodes.Menu_Client_DDT_Transactions_IncomingEftTransactions ||
				MenuCode == MenuCodes.Menu_SubClientIncomingEftTransactions)
				setAppCenterTitle("DDT MANAGEMENT");
			else
				setAppCenterTitle("EFT SYSTEM");
		}


		this.setState({ loadingCompleted: true });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	HandleChange = (name, value) => {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = value;
			if (name == "FinancialInstitutionId") {
				model.FinancialInstitutionBranchId = null;
			}
			return { model };
		});
	}

	HandleCheck(trans) {
		var selectList = [...this.state.selectList];

		selectList = this.state.selectList.includes(trans.TransactionEftId)
			? this.state.selectList.filter(t => t !== trans.TransactionEftId)
			: [...this.state.selectList, trans.TransactionEftId];

		this.setState(state => ({
			selectList,
			checkedValues: state.checkedValues.includes(trans)
				? state.checkedValues.filter(c => c !== trans)
				: [...state.checkedValues, trans]
		}));
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetData = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	Validate = () => {
		return true;
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "EFT Incoming Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/BankBmoEFT/SearchEFTIncomingTransactionExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	handleTrace = () => {
		if (this.state.selectList.length > 0)
			this.setState({
				isLoading: false,
				alert: <GenericAlert Title={"Traced"} Message={"Trace selected [" + this.state.selectList.length + "] transactions ?"} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleTraceOk(this.state.selectList)} />
			});
		else {
			this.ShowErrorMessage("Trace List must not be null !!");
		}
	}

	handleTraceOk = (IdList) => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/TraceIncomingEftByIds",
			{ TransactionEFTIds: IdList },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowErrorMessage("Error =>" + responseData.ErrorDescription);
					return;
				}
				else {
					this.setState({
						isLoading: false,
						selectList: {},
						alert: <GenericAlert Title={"Traced"} Message={"Transactions trace operation completed successfully."} Type="success" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
					});
				}
			},
			this.ErrorSendCallback
		);
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowErrorMessage("An error occurred while requesting data =>" + error.message);
	}

	ErrorSendCallback = (error) => {
		this.ShowErrorMessage("An error occurred while sending data =>" + error.message);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Warning"} Message={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}
	showLoading = () => {
		this.setState({ isLoading: true, alert: null });
	}

	hideLoading = () => {
		this.setState({ isLoading: false });
	}

	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model } = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Trace", OnClick: this.handleTrace, Disabled: Disabled || this.state.selectList.length > 0 ? false : true },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.HandleChange}
													CanClear
													All
													Disabled={!this.isBackOffice}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="PriorityTypeId"
													Value={model.PriorityTypeId}
													LabelText="EFT Type"
													ParameterCode="EFTType"
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionTypeId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="FinancialInstitutionId"
													LabelText="Financial Institution"
													Method="GET"
													Url="/bankapi/v1.0/FinancialInstitution/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${String(d.FinancialInstitutionId ?? "").padStart(3, "0")} - ${d.Description}`}
													Value={model.FinancialInstitutionId}
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
													All={true}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericNumberInput
													Name="Amount"
													LabelText="Amount"
													Value={model.Amount}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="FileName"
													LabelText="File Name"
													Value={model.FileName}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="TransactinDateFrom"
													LabelText="From Date"
													Value={model.TransactinDateFrom}
													ValueChanged={this.HandleChange}
													MaxDate={model.TransactinDateTo || DateHelper.GetDate()}
													Utc
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="TransactinDateTo"
													LabelText="To Date"
													Value={model.TransactinDateTo}
													ValueChanged={this.HandleChange}
													MinDate={model.TransactinDateFrom}
													Utc
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													key={model.FinancialInstitutionId}
													Name="FinancialInstitutionBranchId"
													LabelText="Transit"
													Method="POST"
													Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
													Parameter={{ FinancialInstitutionId: model.FinancialInstitutionId }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${String(d.TransitNumber ?? "").padStart(4, "0")} - ${d.Description}`}
													Value={model.FinancialInstitutionBranchId || -1}
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
													Sorted={{ Member: "TransitNumber" }}
													All={true}
												/>
											</GridItem>
											<GridItem >
												<GenericNumberInput
													NoFormatting={true}
													Name="AccountNumber"
													LabelText="Wallet Number"
													Value={model.AccountNumber}
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
													MaxLength={12}
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
											</GridItem>
											<GridItem xs={4} />
											<GridItem xs={4}>
												<GridContainer justify="flex-end">
													<GridItem xs={3}>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ref={this.trxTable}
													PageSize={10}
													ShowPagination={true}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div>
																	<GenericCheckInput
																		Grid
																		Value={this.state.checkedValues.filter(c => c.TransactionEftId == row.original.TransactionEftId).length > 0}
																		ValueChanged={() => { this.HandleCheck(row.original); }}
																		Disabled={row.original.StatusParamValue == BankTransactionStatus.Traced}
																	/>
																</div>
															),
															sortable: false,
															filterable: false,
															width: 60,
															show: !this.isBackOffice
														},
														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateTimeUtc
														},
														{
															Header: "Settlement Date",
															accessor: "SettlementDate",
															type: GridColumnType.DateTimeUtc
														},
														{
															Header: "Unique Client Id",
															accessor: "ClientNumber",
															show: this.isBackOffice,
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Client Name",
															accessor: "ClientName",
															show: this.isBackOffice,
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Originator Long Name",
															accessor: "OriginatorLongName",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Originator Short Name",
															accessor: "OriginatorShortName",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transaction Type",
															accessor: "TransactionType",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Customer Wallet Number",
															accessor: "AccountNumber",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Amount",
															accessor: "Amount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "FeeAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Transaction Status",
															accessor: "TransactionStatus",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "EFT Type",
															accessor: "EftTransactionType",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transit Number",
															accessor: "TransitNumber",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Customer Number",
															accessor: "CustomerNumber",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "File ID",
															accessor: "FileId",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "File Name",
															accessor: "FileName",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Client User",
															accessor: "InsertUser",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Transaction Reference Number",
															accessor: "ReferenceNumber",
															Cell: row => row.value ?? "-"
														},
														{
															Header: "Store",
															accessor: "CustomerStore",
															width: 150
														}
													]}
													Sorted={this.trxSorted}
													ProgramCode={ProgramCodes.Bank_Programs_EFT}
													ServerSide
													LoadAtStartup={this.state.loadingCompleted}
													ValidateRequest={this.Validate}
													PrepareRequest={() => { return this.state.model; }}
													RequestUrl="/bankapi/v1.0/BankBmoEFT/SearchEFTIncomingTransactionPaged"
													RequestMethod="POST"
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>

							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

EftIncomingTransactions.propTypes = {
	classes: PropTypes.object
};

export default EftIncomingTransactions;
