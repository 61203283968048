import React from "react";
// used for making the prop types of this component
import { PropTypes } from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { Doughnut as DoughnutChart, Chart } from "react-chartjs-2";
import {
	chartGradientColors
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = ({
	circleBoxGrid: {
		width: "100%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class ExecutiveSummaryTab extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration:2000
        }
		};
		this.getGradientList = this.getGradientList.bind(this);

	}

	componentDidMount() {
		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);

			}
		});

	}
	
	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById('canvas') != null) {
			var ctx = document.getElementById('canvas').getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400)
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin)
						gradient.addColorStop(1, chartGradientColors[0].end)
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin)
						gradient.addColorStop(1, chartGradientColors[i].end)
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return

	}
	render() {
		const { classes, columns, list, txnCountChartData, txnTypeChartData, feeChartData } = this.props;
		
		var transactionGradientList = this.getGradientList(txnCountChartData);
		var transactionBackground = txnCountChartData;
		if (transactionGradientList != undefined) {
			transactionBackground.datasets[0].backgroundColor = transactionGradientList;
			transactionBackground.datasets[0].hoverBackgroundColor = transactionGradientList;
		}
		var creditDebitGradientList = this.getGradientList(txnTypeChartData);
		var creditDebitBackground = txnTypeChartData;
		if (creditDebitGradientList != undefined) {
			creditDebitBackground.datasets[0].backgroundColor = creditDebitGradientList;
			creditDebitBackground.datasets[0].hoverBackgroundColor = creditDebitGradientList;
		}
		var feeGradientList = this.getGradientList(feeChartData);
		var feeBackground = feeChartData;
		if (feeGradientList != undefined) {
			feeBackground.datasets[0].backgroundColor = feeGradientList;
			feeBackground.datasets[0].hoverBackgroundColor = feeGradientList;
		}
		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={4} className={classes.circleBoxGrid}>
									<div className={classes.circleBox}>
										<DoughnutChart
											id='canvas'
											data={transactionBackground} width={250} height={250} options={this.chartOptions} />
											<GridContainer spacing={16} justify="center" alignItems="center">
												<GridItem>
													<br />{<h4 style={{ fontWeight: "bold" }}>Transactions</h4>}
												</GridItem>
											</GridContainer>
									</div>
								</GridItem>
								<GridItem xs={4} className={classes.circleBoxGrid}>
									<div className={classes.circleBox}>
										<DoughnutChart data={creditDebitBackground} width={250} height={250} options={this.chartOptions} />
										<GridContainer spacing={16} justify="center" alignItems="center">
											<GridItem>
												<br />{<h4 style={{ fontWeight: "bold" }}>Credit & Debit</h4>}
											</GridItem>
										</GridContainer>
									</div>
								</GridItem>
								<GridItem xs={4} className={classes.circleBoxGrid}>
									<div className={classes.circleBox}>
										<DoughnutChart data={feeBackground} width={250} height={250} options={this.chartOptions} />
										<GridContainer spacing={16} justify="center" alignItems="center">
											<GridItem>
												<br />{<h4 style={{ fontWeight: "bold" }}>Total Fees</h4>}
											</GridItem>
										</GridContainer>
									</div>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12}>
					<Card>
						<CardBody>
							<ReactTable
								style={{
									margin: "12px",
									border: "1px solid black"
								}}
								className={`${classes.table} ${"-striped -highlight"}`}
								data={list}
								filterable={false}
								columns={columns}
								showPaginationTop={false}
								showPaginationBottom={false}
								minRows={1}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

ExecutiveSummaryTab.propTypes = {
	classes: PropTypes.object,
	columns: PropTypes.array,
	list: PropTypes.array,
	txnCountChartData: PropTypes.object,
	txnTypeChartData: PropTypes.object,
	feeChartData: PropTypes.object
};

export default withStyles(styles)(ExecutiveSummaryTab);