import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
	...sweetAlertStyle
});
class Transfer extends React.Component {
	constructor(props) {
		super(props);



		var userJson = localStorage.getItem("user");
		var user = userJson ? JSON.parse(userJson) : null;
		this.initialModel = {
			isBackOffice: true
		};
		this.state = {
			model: this.initialModel,
			transferlist: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
			vModel:{}
		};


		this.HandleCheck = this.HandleCheck.bind(this);
		this.columns = [

			{
				Header: "From Case",
				accessor: "FromCase"
			},
			{
				Header: "To Case",
				accessor: "ToCase"
			},
			{
				Header: "Package No",
				accessor: "PackageNo"
			},
			{
				Header: "Card Id",
				accessor: "CardId"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Record Type",
				accessor: "RecordType"
			},
			{
				Header: "ProductName",
				accessor: "ProductName"
			},
			{
				Header: "PlasticType",
				accessor: "PlasticType"
			},

		];
	}

	componentDidMount() {
		const { model } = { ...this.state }
		model.isBackOffice = ClientHelper.IsBackOffice();
		if (!model.isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
			model.Name = model.UniqueClientId;

		}

		this.setState({ model });
	}
	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;
		if (name === "FromInventorySafeDefinitionId") model["FromInventorySafeDefinitionName"] = data.CustomerStoreSafeName;
		if (name === "ToInventorySafeDefinitionId") model["ToInventorySafeDefinitionName"] = data.CustomerStoreSafeName;
		this.setState({ model });
	}
	HandleCheck = (e, x) => {
		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}
	Submit = (item) => {

		if (!this.Validate()) {
			return;
		}
		var tempArray = [];
		item.map(x => {
			var temp = {};
			temp.PackageNo = x.InventoryOrder.PackageNo;
			temp.RecordType = x.InventoryOrder.ReceiveRecordType.ParameterDesc;
 			temp.ProductName = x.InventoryOrder.Product.ProductName;
			temp.ClientName = x.InventorySafeDefinition.UniqueClient.ClientName;
			temp.FromCase = this.props.transferitem[0].InventorySafeDefinition.CustomerStore.StoreName + " / " + this.props.transferitem[0].InventorySafeDefinition.SafeName;
			temp.ToCase = this.state.model.ToInventorySafeDefinitionName;
			temp.FromInventorySafeDefinitionId = this.props.transferitem[0].InventorySafeDefinition.Id;
			temp.ToInventorySafeDefinitionId = this.state.model.ToInventorySafeDefinitionId;
			temp.Id = x.Id
			temp.PlasticType = x.InventoryOrder.Product.PlasticType.PlasticName;
			temp.MaskedCardNumber = x.MaskedCardNumber;
			temp.CardId = x.CardId
			tempArray.push(temp);
		});
		this.setState({ transferlist: tempArray });
	}
	Validate() {
		const { model } = this.state;
		const { showMessageNode } = this.props;
		var messages = [];
		const vModel = { ...this.state.vModel };
		if (model.ToInventorySafeDefinitionName == null) {
			messages.push("To Store / Safe  can not be null.");
			vModel.ToInventorySafeDefinitionName = true;
		} else{
			vModel.ToInventorySafeDefinitionName = false;
		}
		this.setState({ vModel });

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			this.props.showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}
	TransferConfrim = (item) => {
		if (item.length > 0) {
			this.setState({ isLoading: true, alert: null });
			Proxy.POST(
				"/prepaidapi/v1.0/InventoryOrder/TransferStoreSafeUpdate",
				item,
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.props.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					var listItems = [];
					if (responseData.Item != null) {
						this.setState({ isLoading: false });
					}
					this.props.showMessage("success", " Operation completed successfully!!");
					this.Clear();
					this.props.clearlist();

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "An error occurred while requesting data", error.message);
				}
			);
		}
		else {
			this.props.showMessage("error", "Error", "Please  Select Item Transfer List!");
			this.setState({ isLoading: false });
		}
	}
	Search = () => {
	}
	Update = () => {
	}
	Delete = () => {
	}
	Clear = () => {
		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;
		this.setState({ model: this.initialModel, list: [], selected: null, checkedValues: [], transferlist: [], totalresult: {} });
	}
	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.props.showMessage("error", "An error occurred while sending data", error.message);
	}



	render() {
		const { model, transferlist, selected, checkedValues,vModel } = this.state;
		const { Disabled, transferitem, transfermodel } = this.props;

		return (
			<div>

				<LoadingComponent Show={this.state.isLoading} />

				{this.state.alert}

				<GridContainer spacing={16}>
					<GridItem xs={12}>

						<Card className="no-radius">
							<CardBody>
								<GridContainer>

									<GridItem xs={1}    >
										<Button size="sm" style={{ "line-height": "10px", "margin-left": "2px" }} onClick={() => this.Submit(transferitem)}>
											SUBMIT
							         	</Button>
									</GridItem>
									<GridItem xs={1}>
										<Button size="sm" style={{ "line-height": "10px", "margin-left": "19px" }} onClick={() => this.Search()} disabled={true}>
											SEARCH
							         	</Button>
									</GridItem>
									<GridItem xs={1}>
										<Button size="sm" style={{ "line-height": "10px", "margin-left": "38px" }} onClick={() => this.Update()} disabled={true}>
											UPDATE
							         	</Button>
									</GridItem>

									<GridItem xs={1}>
										<Button size="sm" style={{ "line-height": "10px", "margin-left": "56px" }} onClick={() => this.Delete()} disabled={true}>
											DELETE
							         	</Button>
									</GridItem>
									<GridItem xs={1}>
										<Button size="sm" style={{ "line-height": "10px", "margin-left": "72px" }} onClick={() => this.Clear()}>
											CLEAR
							         	</Button>
									</GridItem>

								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Main Parameters"}>
								<CardBody>

									<GridContainer>
										<GridItem xs={5}  >
											<GenericTextInput
												Name="From Store/Safe"
												Value={transferitem[0].InventorySafeDefinition.CustomerStore.StoreName + " / " + transferitem[0].InventorySafeDefinition.SafeName}
												LabelText="From Store/Safe "
												// ValueChanged={this.ValueChanged}
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={5}>
											<GenericSelectInput
												Name="ToInventorySafeDefinitionId"
												LabelText="To Store/Safe"
												Method="POST"
												Url="/prepaidapi/v1.0/InventoryOrder/GetAllStoreBySafe"
												DataRoot="Item"
												KeyValueMember="SafeId"
												TextValueMember="CustomerStoreSafeName"
												Value={model.ToInventorySafeDefinitionId}
												Parameter={transfermodel}
												IsInvalid={vModel.ToInventorySafeDefinitionName}
												Required
												ValueChanged={this.ValueChanged}
												Sorted={{ Member: "CustomerStoreSafeName" }}
											/>
										</GridItem>

									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius" >
							<GenericExpansionPanel Title={"Transfers"} >
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={transferlist}
														Columns={this.columns}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridContainer xs={12} justify={"center"} style={{ marginTop: "10px" }}>
											<GridItem >
												<Button size="sm" onClick={() => this.TransferConfrim(transferlist)}>
													CONFIRM
							         	</Button>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Transfer.propTypes = {
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,
	transferitem: PropTypes.array
};

export default withArtifex(Transfer, styles);