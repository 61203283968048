import { Tooltip, withStyles, Zoom } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";

class ToolbarButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tooltipOpen: false
		};
	}

	handleOpen(value) {
		this.setState({ tooltipOpen: value });
	}

	render() {
		const { tooltip, onClick, onMouseEnter, onMouseLeave, placement, ...rest } = this.props;
		const { tooltipOpen } = this.state;

		return (
			<Tooltip title={tooltip} TransitionComponent={Zoom} open={tooltipOpen} placement={placement}>
				<Button {...rest}
					onMouseEnter={() => {
						if (onMouseEnter)
							onMouseEnter();
						this.handleOpen(true);
					}}
					onMouseLeave={() => {
						if (onMouseLeave)
							onMouseLeave();
						this.handleOpen(false);
					}}
					onClick={() => {
						if (onClick)
							onClick();
						this.handleOpen(false);
					}}>
				</Button>
			</Tooltip >
		);
	}
}

ToolbarButton.propTypes = {
	tooltip: PropTypes.string,
	placement: PropTypes.oneOf([
		"bottom-end", "bottom-start", "bottom", "left-end",
		"left-start", "left", "right-end", "right-start",
		"right", "top-end", "top-start", "top"]),
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	classes: PropTypes.object.isRequired,
	children: PropTypes.node,
	color: PropTypes.oneOf([
		"primary",
		"black",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"white",
		"twitter",
		"facebook",
		"google",
		"linkedin",
		"pinterest",
		"youtube",
		"tumblr",
		"github",
		"behance",
		"dribbble",
		"reddit",
		"transparent"
	]),
	size: PropTypes.oneOf(["sm", "lg"]),
	simple: PropTypes.bool,
	round: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	block: PropTypes.bool,
	link: PropTypes.bool,
	justIcon: PropTypes.bool,
	className: PropTypes.string,
	muiClasses: PropTypes.object
};

export default withStyles({})(ToolbarButton);