import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericGrid, GenericInput, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { AmlReviewType, ExpressionType, GridColumnType, PropertyType, WarningMessage } from "views/Constants/Constant";
import { MaxLength500 } from "views/Constants/Constant.MaxLength";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName, SortedParameterValue, SortedPropertyName } from "views/Constants/Constant.Sorted";

class AmlKycScenario extends React.Component {
	constructor(props) {
		super(props);

		this.columnsAml = [
			{
				Header: "Code",
				accessor: "Code"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Review Type",
				accessor: "ReviewType"
			},
			{
				Header: "Program",
				accessor: "ProgramDescription"
			},
			{
				Header: "Class Definition",
				accessor: "ClassDefinitionDescription"
			},
			{
				Header: "Last Period",
				accessor: "LastPeriod"
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "From Date",
				accessor: "FromDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "To Date",
				accessor: "ToDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "From Time",
				accessor: "FromTime",
				type: GridColumnType.Time
			},
			{
				Header: "To Time",
				accessor: "ToTime",
				type: GridColumnType.Time
			}
		];

		this.columnsKyc = [
			{
				Header: "Code",
				accessor: "Code"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "Program",
				accessor: "ProgramDescription"
			},
			{
				Header: "Class Definition",
				accessor: "ClassDefinitionDescription"
			},
			{
				Header: "From Date",
				accessor: "FromDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "To Date",
				accessor: "ToDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "From Time",
				accessor: "FromTime",
				Cell: row => (
					<div>{row.value == null ? null : DateHelper.ToMoment(row.value).format("hh:mm A")} </div>
				)
			},
			{
				Header: "To Time",
				accessor: "ToTime",
				Cell: row => (
					<div>{row.value == null ? null : DateHelper.ToMoment(row.value).format("hh:mm A")} </div>
				)
			},
			{
				Header: "Client",
				accessor: "VerificationUniqueClientName"
			}
		];

		this.validateSearchProperties = [{ "AmlKycId": "AML or KYC" }];

		this.state = {
			model: {
				CallerModuleId: 0,
				ProgramId: null,
				VerificationList: []
			},
			list: [],
			criteriaList: []
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Scenario Definition");
		}

		this.GetCallerModule();
		this.GetExpressionType();
		this.GetKycVerificationMethodDefinitions();

		var amlKycScenarioScenarioDetail = RouteHelper.ReadAndClear("AmlKycScenarioScenarioDetail");

		if (amlKycScenarioScenarioDetail != null) {
			this.GetByScenarioCode(amlKycScenarioScenarioDetail.IsAml, amlKycScenarioScenarioDetail.ScenarioCode);
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			var callerModuleList = state.callerModuleList;
			model[name] = value;

			if (name == "AmlKycId") {
				model.IsAml = value == "Aml";

				if (value == null) {
					delete model["IsAml"];
				}

				model.CallerModuleId = 0;
				var callerModule = callerModuleList.filter(x => x.Value == value);

				if (callerModule.length == 1) {
					model.CallerModuleId = callerModule[0].Id;
				}

				model.ProgramId = null;
				model.ClassDefinitionId = undefined;
				model.Description = undefined;
				model.ReviewTypeId = undefined;
				model.UniqueClientId = undefined;
				model.LastPeriodId = undefined;
			} else if (name == "ProgramId") {
				model.ProgramId = value || null;
				model.ClassDefinitionId = undefined;

				return { model, criteriaList: [] };
			} else if (name == "FromDate") {
				model.ToDate = null;
			} else if (name == "ReviewTypeId") {
				if (data != null) {
					model.ReviewTypeIsPeriodic = data.ParameterValue == AmlReviewType.Periodic;
				} else {
					model.ReviewTypeIsPeriodic = undefined;
					model.UniqueClientId = undefined;
					model.LastPeriodId = undefined;
				}
			} else if (name === "Verify" && !value) {
				for (var index in model.VerificationList) {
					model.VerificationList[index].IsSelected = false;
				}
			} else if (name == "ClassDefinitionId") {
				if (value != null) {
					this.GetPropertyDefinition();
				}

				return { model, criteriaList: [] };
			}

			return { model };
		});

		if (name == "AmlKycId") {
			this.setState({ list: [], criteriaList: [] });
		}
	}

	ValueChangedDeleteButton = (index) => {
		this.setState(state => {
			var criteriaList = state.criteriaList;

			criteriaList.splice(index, 1);

			return { criteriaList };
		});
	}

	ValueChangedCriteria = (name, value, data, index) => {
		this.setState(state => {
			var criteriaList = state.criteriaList;
			var item = criteriaList[index], i;

			if (name == "PropertyDefinitionId") {
				if (value == null) {
					criteriaList.splice(index, 1);

					return { criteriaList };
				}

				if (data != null && item != null && data.PropertyType != null) {
					const { showMessage } = this.props;

					if (data.PropertyDependencyId != null && criteriaList.filter(x => x.PropertyDefinitionId == data.PropertyDependencyId).length == 0) {
						showMessage("warning", "Warning", `You cannot select this property. You must before add ${data.PropertyDependency.Description} which is dependency property.`);

						return;
					}

					if (state.propertyDefinitionList.filter(x => x.PropertyDependencyId == data.PropertyDefinitionId) != 0) {
						for (i = 0; i < criteriaList.length; i++) {
							if (i != index && criteriaList[i].PropertyDefinitionId == data.PropertyDefinitionId) {
								showMessage("warning", "Warning", "You cannot select this property. It's dependency property and added before.");

								return;
							}
						}
					}

					if (criteriaList.filter(x => x.PropertyDefinitionId == data.PropertyDefinitionId) != 0 && data.PropertyDependencyId != null) {
						showMessage("warning", "Warning", "You cannot select this property. It has a dependency property and added before.");

						return;
					}

					item.DataModelProperty = {
						PropertyType: data.PropertyType
					};
					item.ViewData = this.GenerateViewData(data);
				}

				if (!item) {
					item = {};
				}

				item.Value = null;
				item.ExpressionTypeId = undefined;
				item.PropertyName = data.PropertyName;
				item.PropertyDependencyId = data.PropertyDependencyId;
			} else if (name == "Value") {
				if (value == null) {
					item[name] = null;

					return { criteriaList };
				}

				for (i = 0; i < criteriaList.length; i++) {
					if (i != index && criteriaList[i].PropertyDependencyId == criteriaList[index].PropertyDefinitionId) {
						criteriaList[i].ViewData.Key = data.Id;

						var propertyName = criteriaList[index].PropertyName;
						var parameter = {};
						parameter[propertyName] = data.Id;
						criteriaList[i].ViewData["Parameter"] = parameter;

						break;
					}
				}
			}

			item[name] = value;

			return { criteriaList };
		});
	}

	ValueCheckChanged = (name, index) => {
		this.setState(state => {
			var model = state.model;

			if (name.includes("Verification")) {
				model.VerificationList[index].IsSelected = !model.VerificationList[index].IsSelected;
			}

			return { model };
		});
	}

	SetDataFromOtherScreen = (isAml, data) => {
		var model = data, criteriaList = [];

		model.VerificationList = isAml ? [] : data.VerificationListAll;
		model.ClassDefinitionId = data.ClassDefinitionId;
		model.AmlKycId = isAml ? "Aml" : "Kyc";
		model.IsAml = isAml;
		model.Id = data.AmlKycScenarioId;
		model.FromDate = data.FromDate == null ? null : Formatter.FormatDateUtc(data.FromDate);
		model.ToDate = data.ToDate == null ? null : Formatter.FormatDateUtc(data.ToDate);
		model.FromTime = data.FromTime == null ? null : DateHelper.ToMoment(data.FromTime).format("hh:mm A");
		model.ToTime = data.ToTime == null ? null : DateHelper.ToMoment(data.ToTime).format("hh:mm A");

		this.GetPropertyDefinition(data.ClassDefinitionId);

		if (isAml) {
			model.ReviewTypeIsPeriodic = data.LastPeriodId != null;
		} else {
			model.Verify = data.VerificationList && data.VerificationList.length != 0;
		}

		data.CriteriaList && data.CriteriaList.map(x => {
			criteriaList.push({
				Id: x.AmlKycScenarioCriteriaId,
				PropertyDefinitionId: x.PropertyDefinitionId,
				ExpressionTypeId: x.ExpressionTypeId,
				Value: x.Value,
				DataModelProperty: {
					PropertyType: x.PropertyType
				},
				ViewData: this.GenerateViewData(x)
			});

			return null;
		});

		this.setState({ model, criteriaList, selected: 0 });
	}

	RowSelected = (index) => {
		const { AmlKycId, IsAml, VerificationList } = this.state.model, { list } = this.state;
		var model = { VerificationList: VerificationList }, criteriaList = [], listItem = list[index];

		model.ClassDefinitionId = listItem.ClassDefinitionId;

		this.setState({ model });

		model.AmlKycId = AmlKycId;
		model.IsAml = IsAml;
		model.Id = listItem.AmlKycScenarioId;
		model.ProgramId = listItem.ProgramId;
		model.Description = listItem.Description;
		model.FromDate = listItem.FromDate == null ? null : Formatter.FormatDateUtc(listItem.FromDate);
		model.ToDate = listItem.ToDate == null ? null : Formatter.FormatDateUtc(listItem.ToDate);
		model.FromTime = listItem.FromTime == null ? null : DateHelper.ToMoment(listItem.FromTime).format("hh:mm A");
		model.ToTime = listItem.ToTime == null ? null : DateHelper.ToMoment(listItem.ToTime).format("hh:mm A");

		this.GetPropertyDefinition(listItem.ClassDefinitionId);

		if (IsAml) {
			model.ReviewTypeId = listItem.ReviewTypeId;
			model.LastPeriodId = listItem.LastPeriodId;
			model.UniqueClientId = listItem.UniqueClientId;
			model.ReviewTypeIsPeriodic = listItem.LastPeriodId != null;
		} else {
			model.VerificationList.map(x => {
				x.IsSelected = listItem.VerificationList.includes(x.Id);

				return null;
			});
			model.VerificationUniqueClientId = listItem.VerificationUniqueClientId;
		}

		listItem.CriteriaList.map(x => {
			criteriaList.push({
				Id: x.AmlKycScenarioCriteriaId,
				PropertyDefinitionId: x.PropertyDefinitionId,
				ExpressionTypeId: x.ExpressionTypeId,
				Value: x.Value,
				DataModelProperty: {
					PropertyType: x.PropertyType
				},
				ViewData: this.GenerateViewData(x, listItem.CriteriaList)
			});

			return null;
		});

		this.setState({ model, criteriaList, selected: index });
	}

	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	GetRenderItemPropertyDefinition = (x) => {
		var render = `${x.Description}`;

		if (x.PropertyPath != null) {
			render += ` - ${x.PropertyPath}`;
		}

		return render;
	}

	GetParameterCallerModuleId = () => {
		const { model } = this.state;
		var result = {
			CallerModuleId: model.CallerModuleId
		};

		return result;
	}

	GetParameterClassDefinitionId = () => {
		const { model } = this.state;
		var result = {
			Id: model.ClassDefinitionId
		};

		return result;
	}

	GetParameterProgramIdAndCallerModuleId = () => {
		const { model } = this.state;
		var result = {
			ProgramId: model.ProgramId,
			CallerModuleId: model.CallerModuleId
		};

		return result;
	}

	GetCriteriaIndex = (index) => {
		index = index + 1;

		return index;
	}

	GenerateViewData = (data, criteriaListFromApi = null) => {
		var propertyType = data.PropertyType.ParameterValue;
		var propertyData = data.PropertyData;

		if (propertyType == PropertyType.Select) {
			var temp = JSON.parse(propertyData);
			if (temp == null) {
				temp = {};
			} else if (data.PropertyDependencyId != null) {
				var { criteriaList } = this.state;

				if (criteriaListFromApi != null) {
					criteriaList = criteriaListFromApi;
				}

				var dependencyProperty = null;

				for (var index = 0; index < criteriaList.length; index++) {
					if (criteriaList[index].PropertyDefinitionId == data.PropertyDependencyId) {
						dependencyProperty = criteriaList[index];

						break;
					}
				}

				if (dependencyProperty == null) {
					return {};
				}

				temp.key = data.Id || dependencyProperty.Value;

				var propertyName = dependencyProperty.PropertyName;
				var parameter = {};
				parameter[propertyName] = data.Id || dependencyProperty.Value || 0;
				temp["Parameter"] = parameter;
			}

			temp.Name = "Value";
			temp.InputType = PropertyType.Select;
			temp.CanClear = false;

			return temp;
		}

		if (propertyType == PropertyType.Decimal) {
			return {
				Name: "Value",
				InputType: PropertyType.Decimal,
				Prefix: "$ ",
				MaxLength: 7
			};
		}

		if (propertyType == PropertyType.Date) {
			return {
				Name: "Value",
				InputType: PropertyType.Date,
				IncludeTime: false,
				Utc: true,
				IsFormatDate: true
			};
		}

		if (propertyType == PropertyType.Int) {
			return {
				Name: "Value",
				InputType: PropertyType.Number,
				NoFormatting: true
			};
		}

		return {
			Name: "Value",
			InputType: propertyType
		};
	}

	GetPropertyDefinition = async (classDefinitonId) => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/PropertyDefinition/SearchByClassDefinitionId", { Id: model.ClassDefinitionId || classDefinitonId });

		this.setState({ propertyDefinitionList: result || [] });
	}

	GetCallerModule = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "CallerModule" });
		var callerModuleList = [];

		if (result != null) {
			result
				.map(x => {
					callerModuleList.push({
						Id: x.Id,
						Value: x.ParameterValue
					});

					return null;
				});
		}

		this.setState({ callerModuleList });
	}

	GetExpressionType = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "ExpressionType" });
		var expressionTypeList = [], expressionTypeEqualList = [];

		if (result != null) {
			result
				.filter(x => x.ParameterValue == ExpressionType.Equal)
				.map(x => {
					expressionTypeEqualList.push(x);

					return null;
				});
			result
				.filter(x => x.ParameterValue != ExpressionType.Like)
				.map(x => {
					expressionTypeList.push(x);

					return null;
				});
		}

		this.setState({ expressionTypeList, expressionTypeEqualList });
	}

	GetKycVerificationMethodDefinitions = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycScenarioVerificationMethodDefinition/Search", {});
		var verificationList = result || [];
		var { model } = this.state;
		model.VerificationList = [];

		verificationList.map(x => {
			model.VerificationList.push(x);

			return null;
		});

		this.setState({ model });
	}

	GetByScenarioCode = async (isAml, scenarioCode) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`/amlkycapi/v1.0/${isAml ? "Aml" : "Kyc"}Scenario/GetByScenarioCode`, { ScenarioCode: scenarioCode });

		this.SetDataFromOtherScreen(isAml, result || {});
	}

	AddCriteria = () => {
		var { criteriaList } = this.state;

		criteriaList.push({
			Id: 0,
			ExpressionTypeId: 0,
			ViewData: null
		});

		this.setState({ criteriaList });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`/amlkycapi/v1.0/${model.IsAml ? "Aml" : "Kyc"}Scenario/Search`, model, model, this.validateSearchProperties);

		model.IsProgramId = false;
		model.IsClassDefinitionId = false;
		model.IsDescription = false;
		model.IsReviewTypeId = false;
		model.IsUniqueClientId = false;
		model.IsLastPeriodId = false;

		this.setState({ model, list: result || [] });
	}

	SubmitOrUpdate(actionName) {
		const { model, criteriaList } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			AmlKycScenarioId: model.Id,
			ReviewTypeId: model.ReviewTypeId,
			ProgramId: model.ProgramId,
			ClassDefinitionId: model.ClassDefinitionId,
			Description: model.Description,
			FromDate: model.FromDate,
			ToDate: model.ToDate,
			FromTime: model.FromTime,
			ToTime: model.ToTime,
			LastPeriodId: model.LastPeriodId,
			UniqueClientId: model.UniqueClientId,
			VerificationUniqueClientId: model.VerificationUniqueClientId,
			CriteriaList: criteriaList.map(x => {
				return {
					AmlKycScenarioCriteriaId: x.Id,
					PropertyDefinitionId: x.PropertyDefinitionId,
					ExpressionTypeId: x.ExpressionTypeId,
					Value: x.Value
				};
			}),
			VerificationList: model.VerificationList.filter(x => x.IsSelected).map(x => { return x.Id; })
		};

		ExecuteApiPost(`/amlkycapi/v1.0/${model.IsAml ? "AmlScenario/" : "KycScenario/"}${actionName}`, request, null, null, this.Validate, [this.Clear, this.Search], true);
	}

	Clear = (clickClearButton = false) => {
		const { VerificationList } = this.state.model;
		var model = { VerificationList };

		if (!clickClearButton) {
			const { AmlKycId, IsAml } = this.state.model;

			model.AmlKycId = AmlKycId;
			model.IsAml = IsAml;
		} else {
			model.CallerModuleId = 0;
			model.ProgramId = null;
		}

		model.VerificationList.map(x => {
			x.IsSelected = false;

			return null;
		});

		this.setState({ model, list: [], criteriaList: [], selected: null });
	}

	Validate = () => {
		var { model, criteriaList } = this.state;
		const { showValidationErrors } = this.props;
		const message = WarningMessage.Empty;
		var errors = [];

		if (!model.AmlKycId) {
			errors.push(`AML or KYC ${message}`);
		}
		if (!model.ProgramId) {
			errors.push(`Program ${message}`);
		}
		if (!model.ClassDefinitionId) {
			errors.push(`Class definition ${message}`);
		}
		if (!model.Description) {
			errors.push(`Description ${message}`);
		}
		if (model.FromDate != null && model.FromDate != "" && !model.ToDate) {
			errors.push(`Between date ${message}`);
		}
		if (!model.FromDate && model.ToDate != "" && model.ToDate != null) {
			errors.push(`Between date ${message}`);
		}
		if (model.FromTime != null && model.FromTime != "" && !model.ToTime) {
			errors.push(`Between hour ${message}`);
		}
		if (!model.FromTime && model.ToTime != "" && model.ToTime != null) {
			errors.push(`Between hour ${message}`);
		}
		if (model.IsAml
			&& !model.ReviewTypeId) {
			errors.push(`Review type ${message}`);
		}
		if (model.ReviewTypeIsPeriodic
			&& !model.UniqueClientId) {
			errors.push(`Client ${message}`);
		}
		if (model.ReviewTypeIsPeriodic
			&& !model.LastPeriodId) {
			errors.push(`Last ${message}`);
		}
		if (model.IsAml) {
			if (criteriaList.length == 0
				|| criteriaList.filter(x => x.PropertyDefinitionId == null || x.ExpressionTypeId == null || x.Value == null).length != 0) {
				errors.push(`Criteria ${message}`);
			}
		} else if (model.IsAml == false) {
			if ((criteriaList.length == 0
				|| criteriaList.filter(x => x.PropertyDefinitionId == null || x.ExpressionTypeId == null || x.Value == null).length != 0)
				&& model.VerificationList.filter(x => x.IsSelected).length == 0) {
				errors.push("Criteria and verifications cannot be empty at the same time.");
			} else if (model.VerificationUniqueClientId
				&& model.VerificationList.filter(x => x.IsSelected).length == 0) {
				errors.push("Verifications cannot be empty while the client is not empty.");
			}
		}

		model["IsAmlKycId"] = !model.AmlKycId;
		model["IsProgramId"] = !model.ProgramId;
		model["IsClassDefinitionId"] = !model.ClassDefinitionId;
		model["IsDescription"] = !model.Description;
		model["IsReviewTypeId"] = model.IsAml && !model.ReviewTypeId;
		model["IsUniqueClientId"] = model.ReviewTypeIsPeriodic && !model.UniqueClientId;
		model["IsLastPeriodId"] = model.ReviewTypeIsPeriodic && !model.LastPeriodId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, expressionTypeList, expressionTypeEqualList, criteriaList, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => this.SubmitOrUpdate("Insert")
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => this.SubmitOrUpdate("Update")
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost(`${model.IsAml ? "/amlkycapi/v1.0/AmlScenario/Delete" : "/amlkycapi/v1.0/KycScenario/Delete"}`, { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: () => this.Clear(true)
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridItem xs={4}>
									<GenericSelectInput
										Required
										IsStatic
										IsInvalid={model.IsAmlKycId}
										Name="AmlKycId"
										LabelText="AML or KYC"
										StaticData={[{ Id: "Aml", Name: "AML" }, { Id: "Kyc", Name: "KYC" }]}
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.AmlKycId}
										ValueChanged={this.ValueChanged} />
								</GridItem>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Program" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GenericSelectInput
											Required
											IsInvalid={model.IsProgramId}
											key={model.AmlKycId}
											Name="ProgramId"
											LabelText="Program"
											Url="/coreapi/v1.0/ClassDefinitionProgramMap/SearchProgram"
											Method="POST"
											Parameter={this.GetParameterCallerModuleId()}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.ProgramId}
											ValueChanged={this.ValueChanged} />
										<GenericDateInput
											Utc
											IsFuture
											Name="FromDate"
											LabelText="Between Date"
											IncludeTime={false}
											Value={model.FromDate}
											ValueChanged={this.ValueChanged} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											Required
											IsInvalid={model.IsClassDefinitionId}
											key={model.ProgramId}
											Name="ClassDefinitionId"
											LabelText="Class Definition"
											Url="/coreapi/v1.0/ClassDefinitionProgramMap/SearchClassDefinition"
											Method="POST"
											Parameter={this.GetParameterProgramIdAndCallerModuleId()}
											DataRoot="Item"
											KeyValueMember="ClassDefinitionId"
											TextValueMember="ClassDefinitionName"
											Value={model.ClassDefinitionId}
											ValueChanged={this.ValueChanged} />
										<GenericDateInput
											Utc
											Name="ToDate"
											LabelText="And"
											LabelMd={4}
											IncludeTime={false}
											MinDate={model.FromDate}
											Value={model.ToDate}
											ValueChanged={this.ValueChanged} />
									</GridItem>
									<GridItem xs={3}>
										<GenericTextInput
											Required
											IsInvalid={model.IsDescription}
											Name="Description"
											LabelText="Description"
											inputProps={MaxLength500}
											Value={model.Description}
											ValueChanged={this.ValueChanged} />
										<GenericDateInput
											Utc
											IncludeTime
											Name="FromTime"
											LabelText="Between Hour"
											DateFormat={false}
											ViewMode="time"
											Value={model.FromTime}
											ValueChanged={this.ValueChanged} />
									</GridItem>
									<GridItem xs={3} >
										<GridContainer>
											<GridItem xs={12} style={{ visibility: "hidden" }}>
												<GenericTextInput
													LabelText="Empty" />
											</GridItem>
										</GridContainer>
										<GenericDateInput
											Utc
											IncludeTime
											Name="ToTime"
											LabelText="And"
											LabelMd={4}
											DateFormat={false}
											ViewMode="time"
											Value={model.ToTime}
											ValueChanged={this.ValueChanged} />
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={3} style={{ display: model.IsAml ? "inherit" : "none" }}>
										<ParameterComponent
											Required={model.IsAml}
											IsInvalid={model.IsReviewTypeId}
											Name="ReviewTypeId"
											LabelText="Review Type"
											ParameterCode="AmlReviewType"
											Value={model.ReviewTypeId}
											ValueChanged={this.ValueChanged} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Criteria" />
							</CardHeader>
							<CardBody >
								<GridContainer>
									<GridItem xs={10}>
										{
											model.ReviewTypeIsPeriodic
												?
												(
													<GridContainer>
														<GridItem xs={4}>
															<GenericSelectInput
																Required={model.ReviewTypeIsPeriodic}
																IsInvalid={model.IsUniqueClientId}
																Name="UniqueClientId"
																LabelText="Client"
																Url="/bankapi/v1.0/BankCustomer/Search"
																Method="POST"
																Parameter={ParameterEmptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={this.GetRenderItemClient}
																Sorted={SortedName}
																Value={model.UniqueClientId}
																ValueChanged={this.ValueChanged} />
														</GridItem>
														<GridItem xs={4}>
															<ParameterComponent
																Required={model.ReviewTypeIsPeriodic}
																IsInvalid={model.IsLastPeriodId}
																Name="LastPeriodId"
																LabelText="Last"
																ParameterCode="AmlKycLastPeriod"
																Value={model.LastPeriodId}
																ValueChanged={this.ValueChanged} />
														</GridItem>
													</GridContainer>
												)
												: null
										}
										<GridContainer justify="flex-end">
											<GridItem style={{ float: "right" }}>
												<Button
													disabled={model.ProgramId == null || model.ClassDefinitionId == null}
													size="sm"
													color="black"
													onClick={this.AddCriteria}>
													+ Criteria
													</Button>
											</GridItem>
										</GridContainer>
										{
											criteriaList && criteriaList.map((item, index) => {
												return (
													<GridContainer key={index}>
														<GridItem xs={1} style={{ marginTop: "5px" }}>
															<Button
																size="sm"
																color="black"
																onClick={() => this.ValueChangedDeleteButton(index)}>
																Delete
															</Button>
														</GridItem>
														<GridItem xs={1} style={{ marginTop: "10px" }}>
															<GenericLabel
																Text={"Criteria " + this.GetCriteriaIndex(index)} />
														</GridItem>
														<GridItem xs={3}>
															<GenericSelectInput
																key={model.ClassDefinitionId}
																Name="PropertyDefinitionId"
																LabelMd={0}
																Url="/coreapi/v1.0/PropertyDefinition/SearchByClassDefinitionId"
																Method="POST"
																Parameter={this.GetParameterClassDefinitionId()}
																DataRoot="Item"
																KeyValueMember="PropertyDefinitionId"
																RenderItem={this.GetRenderItemPropertyDefinition}
																Sorted={SortedPropertyName}
																Value={item.PropertyDefinitionId}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
														<GridItem xs={3} style={{ display: !item.IsUniqueClientId ? "initial" : "none" }}>
															<GenericSelectInput
																IsStatic
																Name="ExpressionTypeId"
																LabelText="Expression Type"
																StaticData={expressionTypeList}
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={SortedParameterValue}
																Value={item.ExpressionTypeId}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
														<GridItem xs={3} style={{ display: item.IsUniqueClientId ? "initial" : "none" }} >
															<GenericSelectInput
																IsStatic
																Name="ExpressionTypeId"
																LabelText="Expression Type"
																StaticData={expressionTypeEqualList}
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={SortedParameterValue}
																Value={item.ExpressionTypeId}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
														<GridItem xs={3}>
															<GenericInput
																key={item.PropertyDefinitionId}
																LabelMd={0}
																ViewData={item.ViewData}
																Model={model}
																Value={item.Value}
																ValueChanged={(name, value, data) => this.ValueChangedCriteria(name, value, data, index)} />
														</GridItem>
													</GridContainer>
												);
											})
										}
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ display: model.IsAml == false ? "inherit" : "none" }}>
							<CardHeader>
								<GenericTitle text="KYC Verifications" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={10}>
										{
											model.VerificationList && model.VerificationList.map(x => {
												return (
													<GenericCheckInput
														key={x.Index}
														Name={"Verification" + x.Index}
														LabelText={x.MethodDefinition.Description}
														Value={model.VerificationList[x.Index].IsSelected}
														ValueChanged={() => this.ValueCheckChanged("Verification", x.Index)} />
												);
											})
										}
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={3}>
										<GenericSelectInput
											All
											Name="VerificationUniqueClientId"
											LabelText="Client"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Method="POST"
											Parameter={ParameterEmptyObject}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.GetRenderItemClient}
											Sorted={SortedName}
											DefaultIndex={-1}
											Value={model.VerificationUniqueClientId}
											ValueChanged={this.ValueChanged} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ display: model.IsAml == null ? "none" : "inherit" }}>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={model.IsAml ? this.columnsAml : this.columnsKyc}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem >
				</GridContainer >
			</div >
		);
	}
}

AmlKycScenario.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default AmlKycScenario;