import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { PropTypes } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class SchedulerQueue extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			StartDateTime: DateHelper.GetDateTime().startOf("day"),
			EndDateTime: DateHelper.GetDateTime().endOf("day")
		};

		this.state = {
			vModel: {},
			list: [],
			model: { ...this.initialModel },
			isLoading: false,
			Days: [
				{
					Id: 1,
					Text: "Sun",
					Status: false,
				},
				{
					Id: 2,
					Text: "Mon",
					Status: false,
				},
				{
					Id: 3,
					Text: "Tues",
					Status: false,
				},
				{
					Id: 4,
					Text: "Wed",
					Status: false,
				}, ,
				{
					Id: 5,
					Text: "Thurs",
					Status: false,
				},
				{
					Id: 6,
					Text: "Fri",
					Status: false,
				},
				{
					Id: 7,
					Text: "Sat",
					Status: false,
				},
			]
		};

		this.emptyObject = {};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Scheduler Queue List");
		this.props.setAppCenterTitle("System Admin");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue || 0;
		this.setState({ model });
	}

	HandleGetList = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Scheduler/GetQueue",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
				else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		if (!model.StartDateTime) {
			vModel.StartDateTime = true;
			this.ShowMessage("warning", "Warning", "Start Date Time can not be empty!");
			return;
		} else { vModel.StartDateTime = false; }
		if (!model.EndDateTime) {
			vModel.EndDateTime = true;
			this.ShowMessage("warning", "Warning", "End Date Time can not be empty!");
			return;
		} else { vModel.EndDateTime = false; }
		this.setState({ vModel })
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {} });
	}

	render() {
		const { classes, Disabled } = this.props;
		const { Days, alert, model, list, isLoading, staticSelectSchedulerType, vModel } = this.state;
		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Get", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Scheduler Queue">
								<CardBody>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="SchedulerDetailId"
												LabelMd={4}
												LabelText="Scheduler Detail"
												Method="POST"
												Url="/coreapi/v1.0/Scheduler/GetAllDetail"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.SchedulerDetailId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 1, SchedulerType: 'Exe' },
													{ Id: 2, SchedulerType: 'Code' },
													{ Id: 3, SchedulerType: 'Procedure' },
													{ Id: 4, SchedulerType: 'DotNetCoreApp' }
												]}
												Name="SchedulerType"
												Value={model.SchedulerType}
												LabelText="Scheduler Type"
												ValueChanged={this.HandleChange}
												KeyValueMember="Id"
												TextValueMember="SchedulerType" />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												MaxDate={DateHelper.ToMoment(model.EndDateTime) || null}
												Name="StartDateTime"
												LabelText="Start Date"
												IncludeTime={true}
												Utc={true}
												ViewMode="time"
												Value={model.StartDateTime == null || undefined ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.StartDateTime}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												MinDate={DateHelper.ToMoment(model.StartDateTime) || null}
												Name="EndDateTime"
												LabelText="End Date"
												IncludeTime={true}
												Utc={true}
												ViewMode="time"
												Value={model.EndDateTime == null || undefined ? "" : DateHelper.ToDateInputValue(model.EndDateTime)}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.EndDateTime}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 1, RecurringType: 'Once' },
													{ Id: 2, RecurringType: 'Recurring' },
												]}
												Name="RecurringType"
												Value={model.RecurringType}
												LabelText="Recurring Type"
												ValueChanged={this.HandleChange}
												KeyValueMember="Id"
												TextValueMember="RecurringType" />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												LabelMd={4}
												IsStatic={true}
												StaticData={[
													{ Id: 1, RunStatus: 'Waiting' },
													{ Id: 2, RunStatus: 'Success' },
													{ Id: 3, RunStatus: 'Error' },
													{ Id: 4, RunStatus: 'Retry Exceeded' },
													{ Id: 5, RunStatus: 'In Progress' },
													{ Id: 6, RunStatus: 'Not Supported' },
													{ Id: 7, RunStatus: 'Finished With Exit Code1' },
												]}
												Name="RunStatus"
												Value={model.RunStatus}
												LabelText="Run Status"
												ValueChanged={this.HandleChange}
												KeyValueMember="Id"
												TextValueMember="RunStatus" />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Scheduler Queue List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={[
												{
													Header: "Id",
													accessor: "Id"
												},
												{
													Header: "Scheduler Id",
													accessor: "SchedulerId"
												},
												{
													Header: "Scheduler Detail Id",
													accessor: "SchedulerDetailId"
												},
												{
													Header: "Scheduler Type",
													accessor: "SchedulerType",
													Cell: row => (
														row.value == 0 ? "Exe" : row.value == 1 ? "Code" : row.value == 2 ? "Procedure" : row.value == 3 ? "DotNetCoreApp" : ""
													)
												},
												{
													Header: "Description",
													accessor: "Description"
												},
												{
													Header: "Application Path",
													accessor: "ApplicationPath"
												},
												{
													Header: "Application Name",
													accessor: "ApplicationName"
												},
												{
													Header: "Application Parameter",
													accessor: "ApplicationParameter"
												},
												{
													Header: "Full Class Name",
													accessor: "FullClassName"
												},
												{
													Header: "Method Name",
													accessor: "MethodName"
												},
												{
													Header: "Start DateTime",
													accessor: "StartDateTime",
													type: GridColumnType.DateTime
												},
												{
													Header: "End DateTime",
													accessor: "EndDateTime",
													type: GridColumnType.DateTime
												},
												{
													Header: "Queue DateTime",
													accessor: "QueueDateTime",
													type: GridColumnType.DateTime
												},
												{
													Header: "Result Message",
													accessor: "ResultMessage"
												},
												{
													Header: "Retry Count",
													accessor: "RetryCount"
												},
												{
													Header: "Recurring Type",
													accessor: "RecurringType",
													Cell: row => (
														row.value == 0 ? "Once" : row.value == 1 ? "Recurring" : ""
													)
												},
												{
													Header: "Run Status",
													accessor: "RunStatus",
													Cell: row => (
														row.value == 0 ? "Waiting" : row.value == 1 ? "Success" : row.value == 2 ? "Error" : row.value == 3 ? "Retry Exceeded" : row.value == 4 ? "In Progress" : row.value == 5 ? "Not Supported" : row.value == 6 ? "Finished With Exit Code1" : ""
													)
												}
											]}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

SchedulerQueue.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(SchedulerQueue, {});
