import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class ChequeImageDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	render() {
		const { chqinfomodel } = this.props;
		/* height: 160px; */
		return (

			<div>
				<GridContainer spacing={16}>
					<GridItem xs={12}>

						{<Card login>

							<GridItem>
								<GenericGrid
									Data={chqinfomodel}
									Columns={[
										{
											Header: "ARecord.RecordType",
											accessor: "ARecord.RecordType"
										},
										{
											Header: "ARecord.ARecordNumber",
											accessor: "ARecord.ARecordNumber"
										},
										{
											Header: "ARecord.BOFDRoutingNumber",
											accessor: "ARecord.BOFDRoutingNumber"
										},
										{
											Header: "ARecord.BOFDBusinessDate",
											accessor: "ARecord.BOFDBusinessDate",
											type: GridColumnType.Date
										},
										{
											Header: "ARecord.BOFDItemSequenceNumber",
											accessor: "ARecord.BOFDItemSequenceNumber"
										},
										{
											Header: "ARecord.TruncationIndicator",
											accessor: "ARecord.TruncationIndicator"
										},
										{
											Header: "CRecord.RecordType",
											accessor: "CRecord.RecordType"
										},
										{
											Header: "CRecord.CRecordNumber",
											accessor: "CRecord.CRecordNumber"
										},
										{
											Header: "CRecord.EndorsingBankRoutingNumber",
											accessor: "CRecord.EndorsingBankRoutingNumber"
										},
										{
											Header: "CRecord.BOFDBusinessDate",
											accessor: "CRecord.BOFDBusinessDate",
											type: GridColumnType.Date
										},
										{
											Header: "CRecord.EndorsingBankItemSequenceNumber",
											accessor: "CRecord.EndorsingBankItemSequenceNumber"
										},
										{
											Header: "CRecord.TruncationIndicator",
											accessor: "CRecord.TruncationIndicator"
										},
										{
											Header: "ImageViewData.RecordType",
											accessor: "ImageViewData.RecordType"
										},
										{
											Header: "ImageViewData.ECEInstitutionRoutingNumber",
											accessor: "ImageViewData.ECEInstitutionRoutingNumber"
										},
										{
											Header: "ImageViewData.BundleBusinessDate",
											accessor: "ImageViewData.BundleBusinessDate",
											type: GridColumnType.Date
										},
										{
											Header: "ImageViewData.ECEInstitutionItemSequenceNumber",
											accessor: "ImageViewData.ECEInstitutionItemSequenceNumber"
										},
										{
											Header: "ImageViewData.ClippingOrigin",
											accessor: "ImageViewData.ClippingOrigin"
										},
										{
											Header: "ImageViewData.LengthofImageReferenceKey",
											accessor: "ImageViewData.LengthofImageReferenceKey"
										},
										{
											Header: "ImageViewData.LengthofImageData",
											accessor: "ImageViewData.LengthofImageData"
										},
										{
											Header: "ImageViewDetail.RecordType",
											accessor: "ImageViewDetail.RecordType"
										},
										{
											Header: "ImageViewDetail.ImageIndicator",
											accessor: "ImageViewDetail.ImageIndicator"
										},
										{
											Header: "ImageViewDetail.ImageCreatorRoutingNumber",
											accessor: "ImageViewDetail.ImageCreatorRoutingNumber"
										},
										{
											Header: "ImageViewDetail.ImageCreatorDate",
											accessor: "ImageViewDetail.ImageCreatorDate",
											type: GridColumnType.Date
										},
										{
											Header: "ImageViewDetail.ImageViewFormatIndicator",
											accessor: "ImageViewDetail.ImageViewFormatIndicator"
										},
										{
											Header: "ImageViewDetail.ImageViewCompressionAlgorithmId",
											accessor: "ImageViewDetail.ImageViewCompressionAlgorithmId"
										},
										{
											Header: "ImageViewDetail.ViewSideIndicator",
											accessor: "ImageViewDetail.ViewSideIndicator"
										},
										{
											Header: "ImageViewDetail.ViewDescriptor",
											accessor: "ImageViewDetail.ViewDescriptor"
										},
										{
											Header: "ImageViewDetail.DigitalSignatureIndicator",
											accessor: "ImageViewDetail.DigitalSignatureIndicator"
										},

									]}
									RowSelected={index => {

										this.setState({ FileId: chqinfomodel[index].FileId, selected: index });
									}} />
							</GridItem>
						</Card>

						}

					</GridItem>
					<GridItem xs={12}>

						{<Card login>
							<GenericExpansionPanel Title={"Cheque  Front and Fear Images"}>

								<CardBody>
									<div style={{ padding: "15px" }}>

										<img
											src={chqinfomodel == null ? null : "data:image/png;base64, " + chqinfomodel[0].ImageViewData.ImageData}
											style={{ width: "100%", height: "200px" }} />

									</div>
									<div style={{ padding: "15px" }}>
										<img
											src={chqinfomodel == null ? null : "data:image/png;base64, " + chqinfomodel[1].ImageViewData.ImageData}
											style={{ width: "100%", height: "200px" }}
										/>

									</div>
								</CardBody>
							</GenericExpansionPanel>
						</Card>}

					</GridItem>
				</GridContainer>


			</div>
		);
	}
}

ChequeImageDetail.propTypes = {
	classes: PropTypes.object,
	chqinfomodel: PropTypes.array,
	handleChange: PropTypes.func,
	PhoneModel: PropTypes.object,
	propModel: PropTypes.object
};

export default withArtifex(ChequeImageDetail, {});