// @ts-nocheck
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ClearingDisputeStatus, ClearingFeeFunctionCode, ClearingFeeType, Direction, PgmCodeType } from 'views/Constants/Constant';


class DisputeFeeDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1,
			isLoading: false,
			model: {},
			isEditDisputeFee: true,
			isDisputeFeeDialogOpen: true,
			DisputeModel: {}
		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };




		this.IssuerAcquirerFlag = "";
	}

	componentDidMount() {

	}

	ValueChanged = (name, newValue, data) => {
		this.props.onModelChange(model => {
			if (name == "DirectionId") {
				model.Direction = data.ParameterDesc;
			}
			if (name == "TransactionTypeId") {
				model.TransactionType = data.ParameterDesc;
			}
			if (name == "FeeTypeId") {
				model.FeeType = data.ParameterDesc;
				model.FeeTypeValue=data.ParameterValue;
			}

			model[name] = newValue;
			return model;
		});
	}

	HandleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.HandleClickByKeyPressed();
		}
	}

	HandleClearClick = () => {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			model: {},
			selected: -1
		}, () => {
			this.props.FillCallback(null);
		});
	}

	HandleSelectModel = (model, index) => {
		this.setState({ selected: index });
		this.props.FillCallback(model); this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1
		});
	};


	RowSelected = (index) => {
		const model = this.state.dialog.dialogData[index];
		this.HandleSelectModel(model, index);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	CloseSelectCallback = () => {
		this.setState({ isChipDialogOpen: false, isPdsDialogOpen: false });
	}


	render() {
		const { PgmCode, model } = this.props;
		const { isLoading } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				<GridContainer>

					<GridItem xs={12} md={12}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12} md={6}>
										<ParameterComponent
											LabelMd={6}
											Name="FeeTypeId"
											LabelText="Fee Type"
											ParameterCode="ClearingFeeType"
											Value={model.FeeTypeId}
											Disabled={model.FeeTypeId && model.Id ? true : false}
											ValueChanged={this.ValueChanged}

										/>
										<ParameterComponent
									   	key={model.FeeTypeValue}
											LabelMd={6}
											Name="DirectionId"
											LabelText="Direction"
											ParameterCode="Direction"
											ParameterValue3="PREPAID"
											ParameterValue={model.FeeTypeValue == ClearingFeeType.SystemFee ? Direction.Incoming : null}
											Value={model.DirectionId}
											ValueChanged={this.ValueChanged}
											Disabled={model.FeeTypeValue == ClearingFeeType.SystemFee ? true : false}
											DefaultIndex={model.FeeTypeValue == ClearingFeeType.SystemFee ?0 : -1}
										/>
										<ParameterComponent
											LabelMd={6}
											Name="TransactionTypeId"
											LabelText="Transaction Type"
											ParameterCode="TransactionType"
											Value={model.TransactionTypeId}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>

									</GridItem>

								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>
				<GridContainer>

					<GridItem xs={12} md={3}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Fee"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="FeeId"
											LabelText="Fee Id"
											Value={model.FeeId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="FeeDate"
											LabelText="Fee Date"
											Value={model.FeeDate}
											ValueChanged={this.ValueChanged}
										/>
										<GenericSelectInput
											LabelMd={6}
											Name="FeeReasonCodeId"
											LabelText="Fee Reason"
											Method="POST"
											Url="/prepaidapi/v1.0/ClearingDispute/GetParameters"
											Parameter={{
												ParameterCode: "FeeReasonCode",
												ParameterValue2: PgmCode,
												ParameterValue3: PgmCodeType.Visa == PgmCode ? null : ClearingFeeFunctionCode.FunctionCodeFee
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.FeeReasonCodeId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>

										<ParameterComponent
											key={model.Direction}
											LabelMd={6}
											Name="FeeStatusId"
											LabelText="Fee Status"
											ParameterCode={model.DirectionValue == Direction.Outgoing ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											Value={model.FeeStatusId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} md={3}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Fee Return"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="FeeReturnId"
											LabelText="Fee Return Id"
											Value={model.FeeReturnId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="FeeReturnDate"
											LabelText="Fee Return Date"
											Value={model.FeeReturnDate}
											ValueChanged={this.ValueChanged}
										/>
										<GenericSelectInput
											LabelMd={6}
											Name="FeeReturnReasonCodeId"
											LabelText="Fee Return Reason"
											Method="POST"
											Url="/prepaidapi/v1.0/ClearingDispute/GetParameters"
											Parameter={{
												ParameterCode: "FeeReasonCode",
												ParameterValue2: PgmCode,
												ParameterValue3: PgmCodeType.Visa == PgmCode ? null : ClearingFeeFunctionCode.FunctionCodeFeeReturn
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.FeeReturnReasonCodeId}
											ValueChanged={this.ValueChanged}
										/>

										<ParameterComponent
											key={model.Direction}
											LabelMd={6}
											Name="FeeReturnStatusId"
											LabelText="Fee Status"
											ParameterCode={model.DirectionValue == Direction.Incoming ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											Value={model.FeeReturnStatusId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} md={3}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Fee ReSubmission"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="FeeResubmissionId"
											LabelText="Fee Resubmission Id"
											Value={model.FeeResubmissionId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="FeeResubmissionDate"
											LabelText="Fee Resubmission Date"
											Value={model.FeeResubmissionDate}
											ValueChanged={this.ValueChanged}
										/>
										<GenericSelectInput
											LabelMd={6}
											Name="FeeResubmissionReasonCodeId"
											LabelText="Fee Resubmission Reason Code"
											Method="POST"
											Url="/prepaidapi/v1.0/ClearingDispute/GetParameters"
											Parameter={{
												ParameterCode: "FeeReasonCode",
												ParameterValue2: PgmCode,
												ParameterValue3: PgmCodeType.Visa == PgmCode ? null : ClearingFeeFunctionCode.FunctionCodeFeeResubmission
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.FeeResubmissionReasonCodeId}
											ValueChanged={this.ValueChanged}
										/>


										<ParameterComponent
											key={model.Direction}
											LabelMd={6}
											Name="FeeResubmissionStatusId"
											LabelText="Fee Status"
											ParameterCode={model.DirectionValue == Direction.Outgoing ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											Value={model.FeeResubmissionStatusId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} md={3}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Fee Arbitration"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="FeeArbitrationId"
											LabelText="Fee Arbitration Id"
											Value={model.FeeArbitrationId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="FeeArbitrationDate"
											LabelText="Fee Arbitration Date"
											Value={model.FeeArbitrationDate}
											ValueChanged={this.ValueChanged}
										/>
										<GenericSelectInput
											LabelMd={6}
											Name="FeeArbitrationReasonCodeId"
											LabelText="Fee Arbitration Reason"
											Method="POST"
											Url="/prepaidapi/v1.0/ClearingDispute/GetParameters"
											Parameter={{
												ParameterCode: "FeeReasonCode",
												ParameterValue2: PgmCode,
												ParameterValue3: PgmCodeType.Visa == PgmCode ? null : ClearingFeeFunctionCode.FunctionCodeFeeArbitrationReturn
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.FeeArbitrationReasonCodeId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>

										<ParameterComponent
											key={model.Direction}
											LabelMd={6}
											Name="FeeArbitrationStatusId"
											LabelText="Fee Status"
											ParameterCode={model.DirectionValue == Direction.Incoming ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											Value={model.FeeArbitrationStatusId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>


			</>
		);
	}
}

DisputeFeeDetail.propTypes = {
	initialModel: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool,
	IsDisputeDialogOpen: PropTypes.any,
	classes: PropTypes.object.isRequired,
	PgmCode: PropTypes.any
};

export default withArtifex(DisputeFeeDetail, {});