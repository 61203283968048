import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericTitle,
  GenericNumberInput,
  GenericCheckInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";
import { GridColumnType } from "views/Constants/Constant.js";
class ClientHighApiProfileDefinition extends Component {
  constructor(props) {
    super(props);
    this.initialModel = {
      Id: 0,
      UniqueClientId: null,
    };
    this.state = {
      model: this.initialModel,
      vModel: {},
      list: [],
      isLoading: false,
    };
    this.emptyObject = {};
    this.columns = [
      {
        Header: "Is Active",
        accessor: "IsActive",
        type: GridColumnType.YesNo,
        ColumnType: GridColumnType.YesNo,
      },
      {
        Header: "Client Name",
        accessor: "ClientName",
      },
      {
        Header: "Real Time Interval In Minute",
        accessor: "RealTimeIntervalInMinute",
      },
      {
        Header: "Priority Interval In Minute",
        accessor: "PriorityIntervalInMinute",
      },
      {
        Header: "Last Real Time Run Date",
        accessor: "LastRealTimeRunDate",
        type: GridColumnType.DateTime,
        ColumnType: GridColumnType.DateTime,
      },
      {
        Header: "Last Priority Run Date",
        accessor: "LastPriorityRunDate",
        type: GridColumnType.DateTime,
        ColumnType: GridColumnType.DateTime,
      },
      {
        Header: "Insert DateTime",
        accessor: "InsertDateTime",
        type: GridColumnType.DateTime,
        ColumnType: GridColumnType.DateTime,
      },
      {
        Header: "Insert User",
        accessor: "InsertUser",
      },
      {
        Header: "Update DateTime",
        accessor: "UpdateDateTime",
        type: GridColumnType.DateTime,
        ColumnType: GridColumnType.DateTime,
      },
      {
        Header: "Update User",
        accessor: "UpdateUser",
      },
    ];
  }
  componentDidMount() {
    this.props.setAppLeftTitle("Client High Api Profile Definition");
    this.props.setAppCenterTitle("SYSTEM ADMIN");
  }
  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };
  HandleClear = () => {
    this.setState({
      model: { ...this.initialModel },
      vModel: {},
      isEdit: false,
      index: -1,
    });
  };
  HandleSearch = () => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Bank/GetClientHighApiProfileList",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ list: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  HandleInsertOrUpdate = (operationType) => {
    if (!this.IsValid(operationType)) return;
    const model = { ...this.state.model };
    model.OperationType = operationType;
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Bank/InsertOrUpdateClientHighApiProfile",
      model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        this.props.showMessage(
          "success",
          "Succeeded",
          "Operation is successfully!"
        );
        this.setState({
          model: { ...this.initialModel },
          isLoading: false,
          index: -1,
        });
        this.HandleClear();
        this.HandleSearch();
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };
  IsValid = (operationType) => {
    const { model, vModel } = this.state;
    var errorList = [];
    const { showValidationErrors } = this.props;
    vModel.UniqueClientId =
      model.UniqueClientId == null ||
      model.UniqueClientId == "" ||
      model.UniqueClientId == undefined ||
      model.UniqueClientId <= 0;
    if (vModel.UniqueClientId) {
      errorList.push("Client cannot be empty.");
    }
    vModel.RealTimeIntervalInMinute =
      model.RealTimeIntervalInMinute == null ||
      model.RealTimeIntervalInMinute == undefined ||
      model.RealTimeIntervalInMinute <= 0;
    if (vModel.RealTimeIntervalInMinute) {
      errorList.push("Real Time Interval In Minute cannot be empty.");
    }
    vModel.PriorityIntervalInMinute =
      model.PriorityIntervalInMinute == null ||
      model.PriorityIntervalInMinute == undefined ||
      model.PriorityIntervalInMinute <= 0;
    if (vModel.PriorityIntervalInMinute) {
      errorList.push("Priority Interval In Minute cannot be empty.");
    }
    if (errorList.length > 0) {
      showValidationErrors(errorList);
      return false;
    }
    return true;
  };
  RowSelected = (index) => {
    let { list } = this.state;
    const tempModel = list[index];
    if (list[index].CardRequestId) {
      tempModel.Id = list[index].Id;
    }
    this.setState({ isEdit: true, model: tempModel, index });
  };
  render() {
    const { Disabled, showConfirmMessageInDelete, ExecuteApiPost } = this.props;
    const { alert, model, list, isLoading, index, vModel } = this.state;
    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
            {
              Code: "Submit",
              Disabled: Disabled || model.Id > 0,
              OnClick: () => this.HandleInsertOrUpdate("Insert"),
            },
            {
              Code: "Update",
              Disabled: Disabled || model.Id === 0,
              OnClick: () => this.HandleInsertOrUpdate("Update"),
            },
            {
              Code: "Delete",
              Disabled: Disabled || model.Id === 0,
              OnClick: () =>
                showConfirmMessageInDelete(() =>
                  ExecuteApiPost(
                    "/bankapi/v1.0/Bank/InsertOrUpdateClientHighApiProfile",
                    { Id: model.Id, OperationType: "Delete" },
                    null,
                    null,
                    null,
                    [this.HandleClear, this.HandleSearch],
                    true
                  )
                ),
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{
                          UniqueClientId: ClientHelper.IsClient()
                            ? ClientHelper.GetClientId()
                            : undefined,
                        }}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.HandleChange}
                        CanClear
                        All
                        Disabled={ClientHelper.IsClient()}
                        DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
                        IsInvalid={vModel.UniqueClientId}
                        Required
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericNumberInput
                        Name="RealTimeIntervalInMinute"
                        LabelText="Real Time Interval In Minute"
                        Value={model.RealTimeIntervalInMinute}
                        ValueChanged={this.HandleChange}
                        NoFormatting={true}
								Required
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericNumberInput
                        Name="PriorityIntervalInMinute"
                        LabelText="Priority Interval In Minute"
                        Value={model.PriorityIntervalInMinute}
                        ValueChanged={this.HandleChange}
                        NoFormatting={true}
								Required
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericCheckInput
                        LabelText="Is Active"
                        Name="IsActive"
                        Value={model.IsActive}
                        ValueChanged={this.HandleChange}
								Required
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"API List"} />
              </CardHeader>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GenericGrid
                      Data={list}
                      Columns={this.columns}
                      RowSelected={this.RowSelected}
                      SelectedIndex={index}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
ClientHighApiProfileDefinition.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
};
export default withArtifex(ClientHighApiProfileDefinition, {});