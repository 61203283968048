import { FormControl, InputLabel } from "@material-ui/core";
import genericMultipleSelectInputStyle from "assets/jss/generic/genericMultipleSelectInputStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import withArtifex from "core/withArtifex";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";


const groupStyles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
};

const customStyles = {
	control: (base) => (
		{
			...base,
			height: "25px",
			"minHeight": "25px",
			"fontSize": "12px",
			"border": "1px solid rgb(233, 234, 236)",
			"borderRadius": "unset",
			"&:active": {
				"border": "1px solid rgb(233, 234, 236) !important", boxShadow: "unset !important"
			},
			"fontWeight": "400"
		}),
	valueContainer: (base) => (
		{
			...base,
			height: "25px",
			"minHeight": "25px",
			"fontSize": "12px",
			"fontWeight": "400",

			"&:active": {
				background: "unset",
				"border": "1px solid rgb(233, 234, 236) !important",
				boxShadow: "unset !important"
			}
		}),
	indicatorsContainer: (base) => (
		{
			...base,
			height: "25px",
			"minHeight": "25px",
			"fontSize": "12px",
			"fontWeight": "400",
			"&:active": {
				"border": "1px solid rgb(233, 234, 236) !important", boxShadow: "unset !important"
			}
		}),
	option: (base) => (
		{
			...base,
			"fontSize": "12px",
			"fontWeight": "400",
			"&:active": {
				"border": "1px solid rgb(233, 234, 236) !important", boxShadow: "unset !important"
			}
		}),
	container: (base,) => ({
		...base
	})
};

class GenericMultipleSelectInput extends Component {

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: false
		};
	}

	componentDidMount() {
		const { Method, Url, Parameter, IsStatic } = this.props;

		if (IsStatic) {
			return;
		}

		this.setState({ isLoading: true });

		if (Method == "GET") {
			Proxy.GET(Url, this.FetchDataCallback, this.ErrorCallback);
		}
		else if (Method == "POST") {
			Proxy.POST(Url, Parameter, this.FetchDataCallback, this.ErrorCallback);
		}
		else {
			this.ErrorCallback();
		}
	}

	HandleChange = (selected) => {
		const { Name, ValueChanged, } = this.props;

		this.setState({ selected });
		if (ValueChanged) {
			ValueChanged(Name, selected);
		}
	}

	FetchDataCallback = (response) => {
		const { DataRoot, Sorted, AfterFetchCallback } = this.props;
		var data = response[DataRoot] || [];

		if (Sorted) {
			data.sort((a, b) => {
				var nameA = a[Sorted.Member];
				if (nameA && nameA.toUpperCase) {
					nameA = nameA.toUpperCase();
				}

				var nameB = b[Sorted.Member];
				if (nameB && nameB.toUpperCase) {
					nameB = nameB.toUpperCase();
				}

				if (nameA < nameB) {
					return Sorted.Desc ? 1 : -1;
				}
				if (nameA > nameB) {
					return Sorted.Desc ? -1 : 1;
				}
				return 0;
			});
		}

		this.setState({ data, isLoading: false });

		if (AfterFetchCallback) {
			AfterFetchCallback(data);
		}
	}

	ErrorCallback = () => {
		this.setState({ isLoading: false });
	}
	GetStyles = () => {
		const { Required, IsInvalid, Disabled } = this.props;

		var borderColor = "#e9eaec";
		if (IsInvalid) {
			borderColor = window.InvalidFieldColor;
		}
		else if (Required) {
			borderColor = window.RequiredFieldColor;
		}

		return {
			control: (base) => (
				{
					...base,
					width: 335,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					border: `1px solid ${borderColor}`,
					borderRadius: "unset",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					},
					fontWeight: "400"
				}),
			valueContainer: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						background: "unset",
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important",
						padding: "unset"
					},
					padding: "unset"
				}),
			indicatorsContainer: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					}
				}),
			option: (base) => (
				{
					...base,
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					}
				}),
			container: (base) => ({
				...base,
			}),
			menu: (base) => ({
				...base,
				zIndex: "50"
			}),
			dropdownButton: (base, isOpen, width) => {
				let style = base;
				if (Disabled) {
					style = {
						...style,
						opacity: "0.7 !important",
						"&:before": {
							borderColor: "transparent !important",
							borderBottom: "unset"
						}
					};
				}
				return style;
			}
		};
	}
	Map(data) {
		if (data == null || data.length == 0)
			return;

		const { KeyValueMember, TextValueMember, RenderItem } = this.props;
		var returnData = data.map(d => {
			return { value: d[KeyValueMember], label: RenderItem ? RenderItem(d) : d[TextValueMember], isDisabled: d.isDisabled };
		});

		return returnData;
	}

	HandleMenu = (isOpen) => () => {
		const { Name, HandleChangeMenu } = this.props;
		if (HandleChangeMenu) {
			HandleChangeMenu(Name, isOpen);
		}

	}
	render() {
		const { classes, LabelText, Name, Disabled, StaticData, LabelMd, TitleLabel, LabelStyle, SelectStyle, HandleChange, DisableGroup, Selected } = this.props;
		const { data } = this.state;

		var lastData = this.Map(StaticData || data) || [];
		const formatGroupLabel = () => (
			<div style={groupStyles}>
				<span>{(TitleLabel == null || TitleLabel == "") ? LabelText : TitleLabel}</span>
			</div>
		);

		const groupedOptions = (DisableGroup ?
			this.Map(StaticData || data) :
			[
				{
					label: (TitleLabel == null || TitleLabel == "") ? LabelText : TitleLabel,
					options: this.Map(StaticData || data),
				}
			]
		);


		var titleLabel = TitleLabel ? TitleLabel : "Select One";
		return (
			<FormControl fullWidth className={classes.selectFormControl}>
				<GridContainer
					direction="row" justify="flex-start"
					alignItems="flex-end" style={{ padding: 2 }}>
					{
						LabelText != undefined && LabelText != "" &&
						<GridItem xs={LabelMd == undefined ? 4 : (LabelMd == -1 ? 0 : LabelMd)} style={LabelStyle}>
							<InputLabel htmlFor={Name} className={classes.selectLabel} >
								{LabelText}
							</InputLabel>
						</GridItem>
					}
					<GridItem xs={LabelMd == undefined ? 8 : (LabelMd == -1 ? 0 : 12 - LabelMd)} style={SelectStyle}>
						<div className={classes.selectWidth}>
							<ReactMultiSelectCheckboxes
								onMenuOpen={this.HandleMenu(true)}
								onMenuClose={this.HandleMenu(false)}
								formatGroupLabel={formatGroupLabel}
								placeholder={titleLabel}
								isDisabled={Disabled}
								styles={this.GetStyles()}
								options={lastData != null && lastData.length > 0 ? groupedOptions : lastData}
								value={Selected || this.state.selected}
								onChange={(e, targetChild) => {
									if (HandleChange != null || undefined) {
										HandleChange(Name, e, targetChild);
									}
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										primary: "black",
										primary25: "rgb(246, 246, 246)",
										primary50: "rgb(246, 246, 246)",
										primary75: "rgb(246, 246, 246)"
									},
								})}
							/>
						</div>
					</GridItem>
				</GridContainer>
			</FormControl>
		);
	}
}

GenericMultipleSelectInput.propTypes = {
	classes: PropTypes.object.isRequired,
	HandleChange: PropTypes.any,
	ValueChanged: PropTypes.any,
	Value: PropTypes.any,
	Name: PropTypes.string,
	LabelText: PropTypes.string,
	KeyValueMember: PropTypes.string,
	TextValueMember: PropTypes.string,
	Method: PropTypes.string,
	Url: PropTypes.string,
	Parameter: PropTypes.object,
	DataRoot: PropTypes.string,
	IsStatic: PropTypes.bool,
	StaticData: PropTypes.array,
	Disabled: PropTypes.bool,
	DefaultIndex: PropTypes.number,
	Sorted: PropTypes.object,
	AfterFetchCallback: PropTypes.any,
	Selected: PropTypes.array,
	RenderItem: PropTypes.func,
	All: PropTypes.bool,
	LabelMd: PropTypes.number,
	TitleLabel: PropTypes.string,
	LabelStyle: PropTypes.object,
	SelectStyle: PropTypes.object,
	DisableGroup: PropTypes.bool,
	HandleChangeMenu: PropTypes.func
};

export default withArtifex(GenericMultipleSelectInput, genericMultipleSelectInputStyle);
