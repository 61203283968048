import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericDateInput } from "views/Components/Generic";
import { GridColumnType, MenuCodes } from "views/Constants/Constant.js";

class SwitchReport extends React.Component {
	constructor(props) {
		super(props);

		let IsSwitchReport = this.props.MenuCode == MenuCodes.Menu_SwitchReport;

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "ClientName",
				accessor: "ClientName",
				show: !IsSwitchReport
			},	{
				Header: "CustomerName",
				accessor: "CustomerName",
				show: !IsSwitchReport
			},
			{
				Header: "MaskedCardNumber",
				accessor: "MaskedCardNumber",
				show: !IsSwitchReport
			},
			{
				Header: "CardTokenNumber",
				accessor: "CardTokenNumber",
				show: !IsSwitchReport
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode",
				show: IsSwitchReport
			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				show: IsSwitchReport
			},
			{
				Header: "Total Count",
				accessor: "Count",
				show: IsSwitchReport
			}
		];

		this.emptyObject = {};

		this.sortedName = { Member: "Name" };
		this.sortedCode = { Member: "Code" };

		this.state = {
			model: {},
			Month: [],
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}
		if (this.props.MenuCode == MenuCodes.Menu_SwitchReport) {
			setAppLeftTitle("Switch Report");
		}else{
			setAppLeftTitle("Renewal Report");
		}
		this.SetYearList();
	}

	SetYearList = () => {
		var currentYear = parseInt(DateHelper.GetYear());
		var listYear = [];
		var listAge = [];

		for (var i = 2019; i <= currentYear; i++) {
			listYear.push({
				"Year": i
			});
		}
		for (var i = 18; i <= 100; i++) {
			listAge.push({
				"Age": i
			});
		}
		this.setState({ listAge, listYear });
	}
	handleListItemChange = (name, newValue, key, ObjectName) => {
		var model = { ...this.state.model };
		const array = this.state.Month;
		const item = array.includes(key);
		if (item == false && newValue == true) {
			array.push(key);
		} else if (item == true && newValue == false) {
			var index = array.indexOf(key);
			if (index > -1) {
				array.splice(index, 1);
			}
		}
		model["Month"] = array;
		this.setState({ Month: array, model });

	}


	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}
	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		if( this.props.MenuCode == MenuCodes.Menu_SwitchReport)
		{
			var result = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/SwitchReport", model, null, null, null);

			this.setState({ list: result || [] });
		}
		else
		{
			var result1 = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/RenewalReport", model, null, null, null);
			this.setState({ list: result1 || [] });
		}

	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, Month: [] });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ParentMenuId
			|| model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (!model.ActionId) {
			errors.push("Action can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}



	render() {
		const { model, list } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
							<GenericExpansionPanel Title="Main Panel">
									<GridContainer>

										<GridItem xs={3}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.ValueChanged}
												MaxDate={model.ToDate || DateHelper.GetLocalDateAsUtc()}
												Disabled={Disabled}
												Utc />
										</GridItem>

										<GridItem xs={3}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												MinDate={model.FromDate || DateHelper.GetLocalDateAsUtc()}
												MaxDate={DateHelper.GetLocalDateAsUtc()}
												Utc />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

SwitchReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object

};
export default withArtifex(SwitchReport, {});
