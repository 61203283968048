// @ts-nocheck
import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes, { any } from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ChargeBack, ClearingDisputeStatus, ClearingIssuerFlag, PgmCodeType } from "views/Constants/Constant.js";
import DisputeAction from "views/PPaid/Clearing/Dispute/DisputeAction";
import DisputeFeeDetail from "views/PPaid/Clearing/Dispute/DisputeFeeDetail";


class Dispute extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1,
			isLoading: false,
			model: {},
			isAddDisputeFee: true,
			isDisputeFeeDialogOpen: false,
			isDisputeActionDialogOpen: false,
			feeModel: {},
			feeList: [],
			selectedFeeIndex: -1,
			DisputeActionData: [],

		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };


		this.RepresentmentReasonDisable = false;
		this.RepresentmentStatusDisable = false;

		this.RetriavalRequestReasonDisable = false;
		this.retriavalRequestStatusDisable = false;

		this.RetriavalResponseReasonDisable = false;
		this.RetriavalResponseStatusDisable = false;

		this.ChargebackReasonDisable = false;
		this.ChargebackStatusDisable = false;

		this.SecondChargebackReasonDisable = false;
		this.SecondChargebackStatusDisable = false;


		this.IssuerAcquirerFlag = "";
	}

	componentDidMount() {
		if (this.props.KnownCustomer)
			this.HandleClickByKeyPressed();
	}

	ValueChanged = (name, newValue, data) => {
		this.props.onModelChange(model => {
			if (name == "IssuerAcquirerFlagId") {
				if (data.ParameterValue == ClearingIssuerFlag.Issuer) {
					this.IssuerAcquirerFlag = ClearingIssuerFlag.Issuer;
				}
				else if (data.ParameterValue == ClearingIssuerFlag.Acquirer) {
					this.IssuerAcquirerFlag = ClearingIssuerFlag.Acquirer;
				}
			}
			model[name] = newValue;
			return model;
		});
	}

	RowSelected = (index) => {
		const data = this.props.model.ClearingDisputeFeeData[index];
		this.props.onFeeModelChange(x => {
			x = data;
			return x;
		});
		this.setState({ isAddDisputeFee: false, selectedFeeIndex: index });
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	CloseSelectCallback = () => {
		this.setState({ isChipDialogOpen: false, isPdsDialogOpen: false });
	}

	AddOrUpdateDisputeFee = () => {

		this.setState({
			isDisputeFeeDialogOpen: true
		});
	}
	ClearDisputeFee = () => {

		this.setState({ isAddDisputeFee: true });
		this.props.onFeeModelClear();
	}

	DeleteDispute = () => {
		if (this.state.selectedFeeIndex != -1) {
			var data = this.props.model.ClearingDisputeFeeData[this.state.selectedFeeIndex];
			if (data && !data.Id)
				this.props.onFeeModelDelete(this.state.selectedFeeIndex);
		}
	}

	HandleClickFeeDispute = () => {

		if (!this.ValidateFeeModel()) return false;

		if (this.state.isAddDisputeFee) {
			this.props.onFeeModelPush(model => {
				return model;
			});
		}
		else {
			this.props.onFeeModelEdit(this.state.selectedFeeIndex);
		}
		this.props.onFeeModelClear();
		this.setState({ isDisputeFeeDialogOpen: false, feeModel: {}, isAddDisputeFee: true });
	}



	CancelSelectCallback = () => {
		this.setState({
			isDisputeFeeDialogOpen: false, isAddDisputeFee: true, isDisputeActionDialogOpen: false
		});
		this.props.onFeeModelClear();
		this.props.onActionModelClose();
	};


	CheckDisableParameter = (PgmCode) => {
		if (PgmCode == PgmCodeType.Visa) {

			this.RepresentmentReasonDisable = true;
			this.RepresentmentStatusDisable = true;

			this.SecondChargebackReasonDisable = true;
			this.SecondChargebackStatusDisable = true;
		}
	}

	ValidateFeeModel = () => {

		const disputeFeeModel = this.props.disputeFeeModel;

		if (disputeFeeModel.FeeStatusId) {
			if (!disputeFeeModel.FeeReasonCodeId) {
				this.props.showMessage("warning", "Warning", "Fee information  can not be empty");
				return false;
			}
		}

		if (disputeFeeModel.FeeReturnStatusId) {
			if (!disputeFeeModel.FeeStatusId) {
				this.props.showMessage("warning", "Warning", "Fee information  can not be empty");
				return false;
			}
			if (!disputeFeeModel.FeeReturnReasonCodeId) {
				this.props.showMessage("warning", "Warning", "Fee Return Reason Code  can not be empty");
				return false;
			}
		}

		if (disputeFeeModel.FeeResubmissionStatusId) {
			if (!disputeFeeModel.FeeReturnStatusId) {
				this.props.showMessage("warning", "Warning", "Fee Return information  can not be empty");
				return false;
			}
			if (!disputeFeeModel.FeeResubmissionReasonCodeId) {
				this.props.showMessage("warning", "Warning", "Fee Resubmission Reason Code  cannot be empty");
				return false;
			}
		}

		if (disputeFeeModel.FeeArbitrationStatusId) {
			if (!disputeFeeModel.FeeResubmissionStatusId) {
				this.props.showMessage("warning", "Warning", "Fee ReSubmission Status information cannot be empty");
				return false;
			}
			if (!disputeFeeModel.FeeArbitrationReasonCodeId) {
				this.props.showMessage("warning", "Warning", "Fee Arbitration Reason Code cannot be empty");
				return false;
			}
		}


		return true;
	}



	render() {
		const { PgmCode, model, isActionDialogOpen } = this.props;
		const { isLoading, isAddDisputeFee } = this.state;

		this.IssuerAcquirerFlag = model.IssuerAcquirerFlag;
		this.CheckDisableParameter(PgmCode);

		return (
			<>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />

				<GenericDialog open={this.state.isDisputeFeeDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="xl" fullWidth keepMounted={true}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Fee Detail" Bold={true} />
					</DialogTitle>

					<DialogContent>
						{
							this.state.isDisputeFeeDialogOpen &&
							<DisputeFeeDetail

								DisputeModel={model}
								PgmCode={PgmCode}
								model={this.props.disputeFeeModel}
								onModelChange={this.props.onFeeModelChange}
							/>
						}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
						<Button size="sm" onClick={this.HandleClickFeeDispute}>Submit</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={isActionDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="lg" fullWidth keepMounted={true}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Dispute Actions" Bold={true} />
					</DialogTitle>

					<DialogContent>
						<DisputeAction
							DisputeActionData={this.props.ActionData}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GridContainer>

					<GridItem xs={12}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={4}>
										<ParameterComponent
											LabelMd={6}
											Name="IssuerAcquirerFlagId"
											LabelText="Issuer Acquirer Flag"
											ParameterCode="IssuerAcquirerFlag"
											Value={model.IssuerAcquirerFlagId}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<GenericTextInput
											LabelMd={6}
											Name="ARN"
											LabelText="ARN"
											Value={model.ARN}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<ParameterComponent
											LabelMd={6}
											Name="PgmCodeId"
											LabelText="Pgm Code"
											ParameterCode="PgmCode"
											Value={model.PgmCodeId}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<ParameterComponent
											LabelMd={6}
											Name="DisputeStatusId"
											LabelText="Dispute Status"
											Value={model.DisputeStatusId}
											ParameterCode="ClearingDisputeStatus"
											ValueChanged={this.ValueChanged}
											DefaultIndex={!model.DisputeStatusId ? 0 : null}
										/>

									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											LabelMd={6}
											Name="CardNumber"
											LabelText="Masked Card Number"
											Value={model.CardNumber}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<GenericNumberInput
											LabelMd={6}
											Name="SettlementAmount"
											LabelText="Settlement Amount"
											Value={model.SettlementAmount}
											ValueChanged={this.ValueChanged}
										/>
										<GenericTextInput
											LabelMd={6}
											Name="SettlementCurrency"
											LabelText="Settlement Currency"
											Value={model.SettlementCurrency}
											ValueChanged={this.ValueChanged}
										/>
										<GenericNumberInput
											LabelMd={6}
											Name="DisputeAmount"
											LabelText="Dispute Amount"
											Value={model.DisputeAmount}
											ValueChanged={this.ValueChanged}
										/>
									</GridItem>
									<GridItem xs={4}>

										<GenericDateInput
											LabelMd={6}
											Name="StartDate"
											LabelText="Start Date"
											Value={model.StartDate}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<GenericTextInput
											LabelMd={6}
											Name="StartUser"
											LabelText="Start User"
											Value={model.StartUser}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="ResolveDate"
											LabelText="Resolve Date"
											Value={model.ResolveDate}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>
										<GenericTextInput
											LabelMd={6}
											Name="ResolveUser"
											LabelText="Resolve User"
											Value={model.ResolveUser}
											ValueChanged={this.ValueChanged}
											Disabled={true}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>


				<GridContainer>

					<GridItem xs={4}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Retriaval Request"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="RetrievalRequestId"
											LabelText="Retrieval Request Id"
											Value={model.RetrievalRequestId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="RetrievalRequestDate"
											LabelText="Retrieval Request Date"
											Value={model.RetrievalRequestDate}
											ValueChanged={this.ValueChanged}
										/>
										<ParameterComponent
											LabelMd={6}
											Name="RetrievalRequestReasonCodeId"
											LabelText="Retrieval Request Reason Code"
											ParameterCode="RetrievalRequestReasonCode"
											ParameterValue2={PgmCode}
											Value={model.RetrievalRequestReasonCodeId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>
										<ParameterComponent
											key={model.IssuerAcquirerFlag}
											LabelMd={6}
											Name="RetrievalRequestStatusId"
											LabelText="Retrieval Request Status"
											ParameterCode={this.IssuerAcquirerFlag == ClearingIssuerFlag.Issuer ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											Value={model.RetrievalRequestStatusId}
											ValueChanged={this.ValueChanged}
											Disabled={false}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={4}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Retriaval Response"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="RetrievalResponseId"
											LabelText="Retrieval Response Id"
											Value={model.RetrievalResponseId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="RetrievalResponseDate"
											LabelText="Retrieval Response Date"
											Value={model.RetrievalResponseDate}
											ValueChanged={this.ValueChanged}
										/>
										<ParameterComponent
											key={model.IssuerAcquirerFlag}
											LabelMd={6}
											Name="RetrievalResponseStatusId"
											LabelText="Retrieval Response Status"
											ParameterCode={this.IssuerAcquirerFlag == ClearingIssuerFlag.Issuer ? ClearingDisputeStatus.ClearingDisputeReceiverStatus : ClearingDisputeStatus.ClearingDisputeSenderStatus}
											Value={model.RetrievalResponseStatusId}
											ValueChanged={this.ValueChanged}
										/>
										<GridItem style={{ visibility: "hidden" }}>
											<GenericTextInput
												LabelText="Empty"
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={4}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Chargeback"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="ChargebackId"
											LabelText="Chargeback Id"
											Value={model.ChargebackId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="ChargebackDate"
											LabelText="Chargeback Date"
											Value={model.ChargebackDate}
											ValueChanged={this.ValueChanged}
										/>

										<GenericSelectInput
											LabelMd={6}
											Name="ChargebackReasonCodeId"
											LabelText="Chargeback Reason Code"
											Method="POST"
											Url="/prepaidapi/v1.0/ClearingDispute/GetParameters"
											Parameter={{
												ParameterCode: "ChargebackReasonCode",
												ParameterValue2: PgmCode,
												ParameterValue3: PgmCode == PgmCodeType.Visa ? any : ChargeBack.FunctionCodeFirstChargeBack
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.ChargebackReasonCodeId}
											ValueChanged={this.ValueChanged}
										/>

										<ParameterComponent
											key={model.IssuerAcquirerFlag}
											LabelMd={6}
											Name="ChargebackStatusId"
											LabelText="Chargeback Status"
											ParameterCode={this.IssuerAcquirerFlag == ClearingIssuerFlag.Issuer ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											Value={model.ChargebackStatusId}
											ValueChanged={this.ValueChanged}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>
				<GridContainer>
					<GridItem xs={4}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Representment"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="RepresentmentId"
											LabelText="Representment Id"
											Value={model.RepresentmentId}
											ValueChanged={this.ValueChanged}
										/>

										<GenericDateInput
											LabelMd={6}
											Name="RepresentmentDate"
											LabelText="Representment Date"
											Value={model.RepresentmentDate}
											ValueChanged={this.ValueChanged}
										/>

										<ParameterComponent
											LabelMd={6}
											Name="RepresentmentReasonCodeId"
											LabelText="Representment Reason Code"
											ParameterCode="RepresentmentReasonCode"
											ParameterValue2={PgmCode}
											Value={model.RepresentmentReasonCodeId}
											ValueChanged={this.ValueChanged}
											Disabled={this.RepresentmentReasonDisable}
										/>

										<ParameterComponent
											key={model.IssuerAcquirerFlag}
											LabelMd={6}
											Name="RepresentmentStatusId"
											LabelText="Representment Status"
											ParameterCode={this.IssuerAcquirerFlag == ClearingIssuerFlag.Issuer ? ClearingDisputeStatus.ClearingDisputeReceiverStatus : ClearingDisputeStatus.ClearingDisputeSenderStatus}
											Value={model.RepresentmentStatusId}
											ValueChanged={this.ValueChanged}
											Disabled={this.RepresentmentStatusDisable}
										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={4}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Second Chargeback"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="SecondChargebackId"
											LabelText="Second Chargeback Id"
											Value={model.SecondChargebackId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="SecondChargebackDate"
											LabelText="Second Chargeback Date"
											Value={model.SecondChargebackDate}
											ValueChanged={this.ValueChanged}
										/>
										<GenericSelectInput
											LabelMd={6}
											Name="SecondChargebackReasonCodeId"
											LabelText="Second Chargeback Reason Code"
											Method="POST"
											Url="/prepaidapi/v1.0/ClearingDispute/GetParameters"
											Parameter={{
												ParameterCode: "ChargebackReasonCode",
												ParameterValue2: PgmCode,
												ParameterValue3: ChargeBack.FunctionCodeSecondChargeBack
											}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.SecondChargebackReasonCodeId}
											ValueChanged={this.ValueChanged}
											Disabled={this.SecondChargebackReasonDisable}

										/>
										<ParameterComponent
											key={model.IssuerAcquirerFlag}
											LabelMd={6}
											Name="SecondChargebackStatusId"
											LabelText="Second Chargeback Status"
											Value={model.SecondChargebackStatusId}
											ParameterCode={this.IssuerAcquirerFlag == ClearingIssuerFlag.Issuer ? ClearingDisputeStatus.ClearingDisputeSenderStatus : ClearingDisputeStatus.ClearingDisputeReceiverStatus}
											ValueChanged={this.ValueChanged}
											Disabled={this.SecondChargebackStatusDisable}

										/>

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={4}>
						<Card>
							<CardHeader>
								<GenericTitle text={"Fraud"} />
							</CardHeader>
							<CardBody>
								<GridContainer >
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={6}
											Name="FraudId"
											LabelText="Fraud Id"
											Value={model.FraudId}
											ValueChanged={this.ValueChanged}
										/>
										<GenericDateInput
											LabelMd={6}
											Name="FraudDate"
											LabelText="Fraud Date"
											Value={model.FraudDate}
											ValueChanged={this.ValueChanged}
										/>

										<ParameterComponent
											LabelMd={6}
											Name="FraudStatusId"
											LabelText="Fraud Status"
											Value={model.FraudStatusId}
											ParameterCode={ClearingDisputeStatus.ClearingDisputeSenderStatus}
											ValueChanged={this.ValueChanged}
											Disabled={false}

										/>
										<GridItem style={{ visibility: "hidden" }}>
											<GenericTextInput
												LabelText="Empty"
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>


				</GridContainer>
				<Card className="no-radius">
					<GenericExpansionPanel Title="Fee List" IsActive={true}>
						<CardBody>
							<GridContainer xs={12}>
								<GridContainer justify="flex-end">
									<GridItem>
										{isAddDisputeFee &&
											< Button size="sm" onClick={this.AddOrUpdateDisputeFee} >Add Fee</Button>
										}
										{!isAddDisputeFee &&
											<Button size="sm" onClick={this.AddOrUpdateDisputeFee} >Edit Fee</Button>
										}
										<Button size="sm" onClick={this.ClearDisputeFee}>CLEAR</Button>
										{!isAddDisputeFee && this.props.model.ClearingDisputeFeeData.filter(x => x.Id > 0).length == 0 &&
											<Button size="sm" onClick={this.DeleteDispute}>Delete Fee</Button>
										}
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={this.props.model.ClearingDisputeFeeData}
											Columns={[
												{
													Header: "Fee Type",
													accessor: "FeeType"
												},
												{
													Header: "Direction",
													accessor: "Direction"
												},
												{
													Header: "Transaction Type",
													accessor: "TransactionType"
												},

											]
											}
											RowSelected={this.RowSelected}
											HideButton={true} />
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>


			</>
		);
	}
}

Dispute.propTypes = {
	initialModel: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool,
	IsDisputeDialogOpen: PropTypes.any,
	classes: PropTypes.object,
	PgmCode: PropTypes.any,
	IssuerAcquirerFlag: PropTypes.any
};

export default withArtifex(Dispute, {});