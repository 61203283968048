import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponentPagination";
import InternalScorecardLoan from "views/LOS/Scorecard/InternalScorecardLoan";
import InternalScorecardTab from "views/LOS/Scorecard/InternalScorecardTab.jsx";
import InternalScorecardVerification from "views/LOS/Scorecard/InternalScorecardVerification";


const initialState = {
	model: {
		scorecardProfileList: [],
		personalTabQuestionList: [],
		incomeTabQuestionList: [],
		networthTabQuestionList: [],
		loanTabQuestionList: [],
		otherTabQuestionList: [],
		answerValueList: [],
		otherScorecardAnswerValueList: [],
		QuestionAnswerKeyValueList: [],
		OtherScorecardKeyValueList: [],
		ScorecardProfileDocumentList: [],
		ScorecardVendorList: [],
		ScorecardVendorLevelList: [],
		LoanOtherCrediscorerows: []
	},
	vModel: {},
	isLoading: false,
	ReadOnly: false

};
class InternalScorecard extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };
		this.LoanPurposeParameterCode = { ParameterCode: "LOSLoanPurpose" };

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getLoanScorecardProfileDetail = this.getLoanScorecardProfileDetail.bind(this);
		this.HandleClickClearOk = this.HandleClickClearOk.bind(this);
		this.HandleClear = this.HandleClear.bind(this);
		this.ShowConfirmMessage = this.ShowConfirmMessage.bind(this);
		this.ShowMessageAndRefresh = this.ShowMessageAndRefresh.bind(this);
		this.hideAlertAndRefresh = this.hideAlertAndRefresh.bind(this);
		this.getLoanScorecardDocumentModels = this.getLoanScorecardDocumentModels.bind(this);
		this.handleDocumentAdd = this.handleDocumentAdd.bind(this);
		this.getCreditScoreVendorLevelList = this.getCreditScoreVendorLevelList.bind(this);
		this.getCreditScoreVendorList = this.getCreditScoreVendorList.bind(this);
		this.MakeGridArray = this.MakeGridArray.bind(this);

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Scoring Parameters");
		this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");

	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this definition?", () => this.HandleClickClearOk());
	}
	HandleClickClearOk = (error) => {
		this.setState({ model: initialState, alert: "" });
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "", loading: false })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	handleSubmit() {

		var ErrorList = [];
		const data = this.state.model;

		const { vModel } = this.state;
		if (data == null) {
			ErrorList.push("Scorecard not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.LoanTypeId == null || data.LoanTypeId == "") {
			ErrorList.push("Loan type must be defined.");
			vModel.LoanTypeId = true;
		} else {
			vModel.LoanTypeId = false;
		}
		if (data.Name == null || data.Name == "") {
			ErrorList.push("Name must be defined.");
			vModel.Name = true;
		} else {
			vModel.Name = false;
		}
		if (data.LoanPurposeId == null || data.LoanPurposeId == "") {
			ErrorList.push("Loan purpose must be defined.");
			vModel.LoanPurposeId = true;
		} else {
			vModel.LoanPurposeId = false;
		}
		if (data.ScorecardProfileTemplateId == null || data.ScorecardProfileTemplateId == "") {
			ErrorList.push("Scorecard template must be defined.");
			vModel.ScorecardProfileTemplateId = true;
		} else {
			vModel.ScorecardProfileTemplateId = false;
		}
		if (data.PersonalTabMaxPoint != 0 && (data.PersonalTabMaxPoint == null || data.PersonalTabMaxPoint == "")) {
			ErrorList.push("Personal tab maximum point must be defined.");
			vModel.PersonalTabMaxPoint = true;
		} else {
			vModel.PersonalTabMaxPoint = false;
		}
		if (data.IncomeTabMaxPoint != 0 && (data.IncomeTabMaxPoint == null || data.IncomeTabMaxPoint == "")) {
			ErrorList.push("Income tab maximum point must be defined.");
			vModel.IncomeTabMaxPoint = true;
		} else {
			vModel.IncomeTabMaxPoint = false;
		}
		if (data.NetworthTabMaxPoint != 0 && (data.NetworthTabMaxPoint == null || data.NetworthTabMaxPoint == "")) {
			ErrorList.push("Net worth tab maximum point must be defined.");
			vModel.NetworthTabMaxPoint = true;
		} else {
			vModel.NetworthTabMaxPoint = false;
		}
		if (data.OtherTabMaxPoint != 0 && (data.OtherTabMaxPoint == null || data.OtherTabMaxPoint == "")) {
			ErrorList.push("Other tab maximum point must be defined.");
			vModel.OtherTabMaxPoint = true;
		} else {
			vModel.OtherTabMaxPoint = false;
		}
		if (data.LoanTabMaxPoint != 0 && (data.LoanTabMaxPoint == null || data.LoanTabMaxPoint == "")) {
			ErrorList.push("Loan tab maximum point must be defined.");
			vModel.LoanTabMaxPoint = true;
		} else {
			vModel.LoanTabMaxPoint = false;
		}

		if (!vModel.PersonalTabMaxPoint) {
			var personalScore = this.getTotalScoreFromTab(this.state.model.personalTabQuestionList);
			if (this.state.model.PersonalTabMaxPoint < personalScore) {
				ErrorList.push("Total score is bigger from section maximum point at personal tab, total score: " + personalScore);
				vModel.PersonalTabMaxPoint = true;
			} else {
				vModel.PersonalTabMaxPoint = false;
			}
		}

		if (!vModel.IncomeTabMaxPoint) {
			var incomeScore = this.getTotalScoreFromTab(this.state.model.incomeTabQuestionList);
			if (this.state.model.IncomeTabMaxPoint < incomeScore) {
				ErrorList.push("Total score is bigger from section maximum point at income tab, total score: " + incomeScore);
				vModel.IncomeTabMaxPoint = true;
			} else {
				vModel.IncomeTabMaxPoint = false;
			}
		}

		if (!vModel.NetworthTabMaxPoint) {
			var networthScore = this.getTotalScoreFromTab(this.state.model.networthTabQuestionList);
			if (this.state.model.NetworthTabMaxPoint < networthScore) {
				ErrorList.push("Total score is bigger from section maximum point at networth tab, total score: " + networthScore);
				vModel.NetworthTabMaxPoint = true;
			} else {
				vModel.NetworthTabMaxPoint = false;
			}
		}

		if (!vModel.OtherTabMaxPoint) {
			var otherScore = this.getTotalScoreFromTab(this.state.model.otherTabQuestionList);
			console.log(otherScore);
			if (this.state.model.OtherTabMaxPoint < otherScore) {
				ErrorList.push("Total score is bigger from section maximum point at other tab, total score: " + otherScore);
				vModel.OtherTabMaxPoint = true;
			} else {
				vModel.OtherTabMaxPoint = false;
			}
		}

		if (!vModel.LoanTabMaxPoint) {
			var loanScore = this.getTotalScoreFromTab(this.state.model.loanTabQuestionList);
			var otherVendorMaxScore = this.getTotalScoreFromOtherVendor();
			if (this.state.model.LoanTabMaxPoint < loanScore + otherVendorMaxScore) {
				ErrorList.push("Total score is bigger from section maximum point at loan tab, total score: " + (loanScore + otherVendorMaxScore));
				vModel.LoanTabMaxPoint = true;
			} else {
				vModel.LoanTabMaxPoint = false;
			}
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ isLoading: true });

		const model = this.state.model;
		var id = model.Id;
		Proxy.POST(
			"/losapi/v1.0/LoanScorecardProfile/InsertUpdate",
			model,
			responseData => {
				console.log("InsertUpdateLoanScorecardProfile ->", responseData.IsSucceeded);
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.Id = responseData.Item.Id;
					this.setState({ model });

					if (id > 0) {
						this.ShowMessageAndRefresh("success", "Success", "The Item Updated");
					}
					else {
						this.ShowMessageAndRefresh("success", "Success", "The Item Created");
					}


				} else {
					const modelUpdate = this.state.model;
					modelUpdate.scorecardProfileList = [];
					this.setState({ model: modelUpdate, isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	getTotalScoreFromTab(questionList) {
		const answerKeyValueList = this.state.model.answerValueList;
		var totalScore = 0;
		if (answerKeyValueList == undefined)
			return 0;
		for (var i = 0; i < questionList.length; ++i) {
			var questionItem = questionList[i];
			var maxPoint = -200;
			for (var j = 0; j < questionItem.AnswerList.length; ++j) {
				var answerItem = questionItem.AnswerList[j];
				var value = isNaN(Number(answerKeyValueList[questionItem.QuestionId + "-" + answerItem.Id])) == false ? Number(answerKeyValueList[questionItem.QuestionId + "-" + answerItem.Id]) : 0;
				if (value > maxPoint)
					maxPoint = value;
			}
			totalScore = totalScore + maxPoint;
		}
		return totalScore;

	}
	getTotalScoreFromOtherVendor() {
		const answerKeyValueList = this.state.model.otherScorecardAnswerValueList;
		const questionList = this.state.model.LoanOtherCrediscorerows;
		if (answerKeyValueList == undefined || questionList == undefined)
			return 0;
		var max1 = 0, max2 = 0, max3 = 0;
		for (var i = 0; i < questionList.length; ++i) {
			var questionItem = questionList[i];
			for (var j = 0; j < questionItem.length; ++j) {
				var answerItem = questionItem[j];
				var value = isNaN(Number(answerKeyValueList[answerItem.Id])) == false ? Number(answerKeyValueList[answerItem.Id]) : 0;
				if (value > max1) {
					max3 = max2;
					max2 = max1;
					max1 = value;
				}
				else if (value > max2) {
					max3 = max2;
					max2 = value;
				}
				else if (value > max3)
					max3 = value;
			}
		}
		return max1 + max2 + max3;

	}
	handleGenerateNew() {
		var initialModel = initialState;
		initialModel["isGenerateNew"] = true;
		this.setState({ model: initialModel, alert: null });
		return;

	}
	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "isGenerateNew" && newValue == true) {
			this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this profile?", () => this.handleGenerateNew());
			return;
		}
		if (name == "ScorecardProfileTemplateId") {
			this.getScorecardProfileDetail(newValue);
		}
		if (name == "LoanScoreCardProfileId") {
			this.getLoanScorecardProfileDetail(newValue);
		}

		this.setState({ model });
	}
	handleChangeParentTab = (event, active, tab) => {
		if (!tab) {
			this.setState({ ParentTabIndex: active });
		}

	}

	handleAnswerChange = (name, newValue) => {
		var valueList = this.state.model.answerValueList || [];
		var model = this.state.model;
		valueList[name] = newValue;
		if (model.QuestionAnswerKeyValueList == null)
			model.QuestionAnswerKeyValueList = [];

		var newKeyValueList = model.QuestionAnswerKeyValueList.filter(function (item) {
			return item.Key !== name;
		});
		newKeyValueList.push({ Key: name, Value: newValue });
		model.QuestionAnswerKeyValueList = newKeyValueList;

		model.answerValueList = valueList;
		this.setState({ model });
	}
	handleOtherScorecardAnswerChange = (name, newValue) => {
		var valueList = this.state.model.otherScorecardAnswerValueList || [];
		var model = this.state.model;
		valueList[name] = newValue;
		if (model.OtherScorecardKeyValueList == null)
			model.OtherScorecardKeyValueList = [];

		var newKeyValueList = model.OtherScorecardKeyValueList.filter(function (item) {
			return item.Key !== name;
		});
		newKeyValueList.push({ Key: name, Value: newValue });
		model.OtherScorecardKeyValueList = newKeyValueList;

		model.otherScorecardAnswerValueList = valueList;
		this.setState({ model });
	}
	getLoanScorecardProfileDetail(loanscorecardid) {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/LoanScorecardProfile/GetById",
			{ Id: loanscorecardid },
			responseData => {
				console.log("getLoanScorecardProfileDetail ->", responseData.IsSucceeded);
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					const personalTabList = responseData.Item.QuestionAnswerList.filter(word => word.ScorecardTab.ParameterValue == "personal");
					const incomeTabList = responseData.Item.QuestionAnswerList.filter(word => word.ScorecardTab.ParameterValue == "income");
					const networthTabList = responseData.Item.QuestionAnswerList.filter(word => word.ScorecardTab.ParameterValue == "networth");
					const loanTabList = responseData.Item.QuestionAnswerList.filter(word => word.ScorecardTab.ParameterValue == "loan");
					const otherTabList = responseData.Item.QuestionAnswerList.filter(word => word.ScorecardTab.ParameterValue == "other");
					var answerValueList = [];
					var otherScorecardAnswerValueList = [];
					responseData.Item.QuestionAnswerKeyValueList.forEach(function (element) {
						answerValueList[element.Key] = element.Value;
					});
					responseData.Item.OtherScorecardKeyValueList.forEach(function (element) {
						otherScorecardAnswerValueList[element.Key] = element.Value;
					});
					var model = responseData.Item;
					model.answerValueList = answerValueList;
					model.otherScorecardAnswerValueList = otherScorecardAnswerValueList;
					model.scorecardProfileList = responseData.Item.QuestionAnswerList || [];
					model.personalTabQuestionList = personalTabList || [];
					model.incomeTabQuestionList = incomeTabList || [];
					model.networthTabQuestionList = networthTabList || [];
					model.loanTabQuestionList = loanTabList || [];
					model.otherTabQuestionList = otherTabList || [];
					this.getCreditScoreVendorList();
					this.setState({ model });

				} else {
					var modelUpdate = this.state.model;
					modelUpdate.scorecardProfileList = [];
					modelUpdate.personalTabQuestionList = [];
					modelUpdate.incomeTabQuestionList = [];
					modelUpdate.networthTabQuestionList = [];
					modelUpdate.loanTabQuestionList = [];
					modelUpdate.otherTabQuestionList = [];

					this.setState({ model: modelUpdate });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	getScorecardProfileDetail(profileId) {
		this.setState({ isLoading: true });
		const model = this.state.model;
		Proxy.POST(
			"/losapi/v1.0/ScorecardProfile/GetById",
			{ ProfileId: profileId },
			responseData => {
				console.log("getScorecardProfileDetail ->", responseData.IsSucceeded);
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					const personalTabList = responseData.Item.filter(word => word.ScorecardTab.ParameterValue == "personal");
					const incomeTabList = responseData.Item.filter(word => word.ScorecardTab.ParameterValue == "income");
					const networthTabList = responseData.Item.filter(word => word.ScorecardTab.ParameterValue == "networth");
					const loanTabList = responseData.Item.filter(word => word.ScorecardTab.ParameterValue == "loan");
					const otherTabList = responseData.Item.filter(word => word.ScorecardTab.ParameterValue == "other");

					model.scorecardProfileList = responseData.Item || [];
					model.personalTabQuestionList = personalTabList || [];
					model.incomeTabQuestionList = incomeTabList || [];
					model.networthTabQuestionList = networthTabList || [];
					model.loanTabQuestionList = loanTabList || [];
					model.otherTabQuestionList = otherTabList || [];
					model.ScorecardProfileTemplateId = profileId;
					this.setState({
						model
					});
					this.getCreditScoreVendorList();
				} else {
					model.scorecardProfileList = [];
					model.personalTabQuestionList = [];
					model.incomeTabQuestionList = [];
					model.networthTabQuestionList = [];
					model.loanTabQuestionList = [];
					model.otherTabQuestionList = [];

					this.setState({ model });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	getCreditScoreVendorLevelList(ScorecardVendorList) {
		const model = this.state.model;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{ ParameterCode: "LOSScoreLevel" },
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.ScorecardVendorLevelList = responseData.Item || [];
					this.setState({
						model
					});
					this.MakeGridArray(ScorecardVendorList, model.ScorecardVendorLevelList);

				} else {
					model.ScorecardVendorLevelList = [];
					this.setState({ model });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	getCreditScoreVendorList() {
		this.setState({ isLoading: true });

		const model = this.state.model;
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{ ParameterCode: "LOSScoreVendor" },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.ScorecardVendorList = responseData.Item || [];
					this.getCreditScoreVendorLevelList(model.ScorecardVendorList);
					this.setState({
						model
					});
				} else {
					model.ScorecardVendorList = [];
					this.setState({ model });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	getLoanScorecardDocumentModels() {
		this.setState({ isLoading: true });
		const model = this.state.model;
		Proxy.POST(
			"/losapi/v1.0/LoanScorecardProfileDocument/GetById",
			{ LoanScorecardProfileId: model.Id },
			responseData => {
				console.log("getLoanScorecardDocumentModels ->", responseData.IsSucceeded);
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.ScorecardProfileDocumentList = responseData.Item || [];
					this.setState({
						model
					});
				} else {
					model.ScorecardProfileDocumentList = [];
					this.setState({ model });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	MakeGridArray(ScorecardVendorList, ScorecardVendorLevelList) {
		this.setState({ isLoading: true });

		var columns = [];
		var rows = [];
		columns.push({ Id: 0, Text: "Score Level", Bold: true });

		ScorecardVendorList != undefined && ScorecardVendorList.map((vendor, i) => {
			columns.push({ Id: 0, Text: vendor.ParameterDesc, Bold: true });

			return null;
		});
		rows.push(columns);
		ScorecardVendorLevelList != undefined && ScorecardVendorLevelList.map((level, i) => {
			columns = [];
			columns.push({ Id: 0, Text: level.ParameterDesc, Bold: false });
			ScorecardVendorList != undefined && ScorecardVendorList.map((vendor, j) => {
				columns.push({ Id: level.Id + "-" + vendor.Id, Text: "", Bold: false });

				return null;
			});
			rows.push(columns);

			return null;
		});
		const model = this.state.model;
		model.LoanOtherCrediscorerows = rows;
		this.setState({ isLoading: false, model });
	}
	handleDocumentAdd(documentModel) {
		const model = this.state.model;
		model.ScorecardProfileDocumentList.push(documentModel);
		this.setState({ model });
	}
	render() {
		const { model, isLoading, alert, vModel } = this.state;
		var ForInsert = !model.Id || model.Id <= 0;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.handleSubmit, Disabled: !ForInsert },
					{ Code: "Update", OnClick: this.handleSubmit, Disabled: ForInsert },
					{ Code: "Clear", OnClick: this.HandleClear }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true}>
									<GridContainer
										justify="flex-start"
										alignItems="flex-start"
										direction="column"
									>
										<GridContainer>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="LoanScoreCardProfileId"
													LabelText="Score Card Profile"
													Method="POST"
													Url="/losapi/v1.0/LoanScorecardProfile/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Name"
													Value={model.Id}
													ValueChanged={this.handleChange}
													Disabled={model.isGenerateNew}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericCheckInput
													Name="isGenerateNew"
													LabelText="Add New Scorecard"
													Value={model.isGenerateNew}
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={3}>
												<GenericTextInput
													key="Name"
													Name="Name"
													LabelText="Name"
													Value={model.Name == null || undefined ? "" : model.Name}
													ValueChanged={this.handleChange}
													Disabled={(!model.Id || model.Id <= 0) && !model.isGenerateNew}
													Required={true}
													IsInvalid={vModel.Name}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="LoanTypeId"
													LabelText="Loan Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LoanTypeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.LoanTypeId}
													ValueChanged={this.handleChange}
													Disabled={(!model.Id || model.Id <= 0) && !model.isGenerateNew}
													Required={true}
													IsInvalid={vModel.LoanTypeId}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="LoanPurposeId"
													key={model.LoanTypeId + "LoanPurpose"}
													LabelText="Purpose Of Loan"
													Method="POST" Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
													Parameter={{ LoanTypeId: model.LoanTypeId }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.LoanPurposeId}
													ValueChanged={this.handleChange}
													Disabled={(!model.Id || model.Id <= 0) && !model.isGenerateNew}
													Required={true}
													IsInvalid={vModel.LoanPurposeId}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													LabelMd={5}
													Name="ScorecardProfileTemplateId"
													LabelText="Scorecard Template"
													Method="GET"
													Url="/losapi/v1.0/ScorecardProfileTemplate/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Name"
													Value={model.ScorecardProfileTemplateId}
													ValueChanged={this.handleChange}
													Disabled={(!model.Id || model.Id <= 0) && !model.isGenerateNew}
													Required={true}
													IsInvalid={vModel.ScorecardProfileTemplateId}
												/>
											</GridItem>
										</GridContainer>

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridItem xs={12}>
									<TabsComponent
										TabIndex={this.state.ParentTabIndex}
										handleChange={(e, a) => this.handleChangeParentTab(e, a, true)}
										tabList={[
											{
												tabButton: "Personal",
												tabContent: (
													<GridItem>

														<InternalScorecardTab
															QuestionList={this.state.model.personalTabQuestionList}
															HandleChange={this.handleChange}
															HandleAnswerChange={this.handleAnswerChange}
															AnswerValueList={this.state.model.answerValueList}
															ApplicationModel={this.state.model}
															ApplicationDocumentList={undefined}
															Disabled={!model.Id || model.Id <= 0}
														/>
														<GridItem xs={2} style={{ float: "right" }}>
															<GridContainer
																direction="column"
																justify="flex-start"
																alignItems="stretch"
															>
																<GridItem xs={12}>
																	<GenericLabel
																		Text="Section Maximum Point"
																		TextAlign="center"
																		Bold
																		Display="block">
																	</GenericLabel>
																</GridItem>
																<GridItem xs={12}>
																	<GenericNumberInput
																		NoFormatting
																		Name={"PersonalTabMaxPoint"}
																		LabelText=""
																		LabelMd={0}
																		Value={model.PersonalTabMaxPoint}
																		ValueChanged={this.handleChange}
																		Required={true}
																		IsInvalid={vModel.PersonalTabMaxPoint}
																	></GenericNumberInput>
																</GridItem>

															</GridContainer>
														</GridItem>

													</GridItem>
												)
											},
											{
												tabButton: "Income",
												tabContent: (
													<GridItem>
														<InternalScorecardTab
															QuestionList={this.state.model.incomeTabQuestionList}
															HandleChange={this.handleChange}
															HandleAnswerChange={this.handleAnswerChange}
															AnswerValueList={this.state.model.answerValueList}
															ApplicationModel={this.state.model}
															ApplicationDocumentList={undefined}
															Disabled={!model.Id || model.Id <= 0}
														/>
														<GridItem xs={2} style={{ float: "right" }}>
															<GridContainer
																direction="column"
																justify="flex-start"
																alignItems="stretch"
															>
																<GridItem xs={12}>
																	<GenericLabel
																		Text="Section Maximum Point"
																		TextAlign="center"
																		Bold
																		Display="block">
																	</GenericLabel>
																</GridItem>
																<GridItem xs={12}>
																	<GenericNumberInput
																		NoFormatting
																		Name={"IncomeTabMaxPoint"}
																		LabelText=""
																		LabelMd={0}
																		Value={model.IncomeTabMaxPoint}
																		ValueChanged={this.handleChange}
																		Required={true}
																		IsInvalid={vModel.IncomeTabMaxPoint}
																	></GenericNumberInput>
																</GridItem>

															</GridContainer>
														</GridItem>
													</GridItem>
												)
											},
											{
												tabButton: "Net Worth",
												tabContent: (
													<GridItem>
														<InternalScorecardTab
															QuestionList={this.state.model.networthTabQuestionList}
															HandleChange={this.handleChange}
															HandleAnswerChange={this.handleAnswerChange}
															AnswerValueList={this.state.model.answerValueList}
															ApplicationModel={this.state.model}
															ApplicationDocumentList={undefined}
															Disabled={!model.Id || model.Id <= 0}
														/>
														<GridItem xs={2} style={{ float: "right" }}>
															<GridContainer
																direction="column"
																justify="flex-start"
																alignItems="stretch"
															>
																<GridItem xs={12}>
																	<GenericLabel
																		Text="Section Maximum Point"
																		TextAlign="center"
																		Bold
																		Display="block">
																	</GenericLabel>
																</GridItem>
																<GridItem xs={12}>
																	<GenericNumberInput
																		NoFormatting
																		Name={"NetworthTabMaxPoint"}
																		LabelText=""
																		LabelMd={0}
																		Value={model.NetworthTabMaxPoint}
																		ValueChanged={this.handleChange}
																		Required={true}
																		IsInvalid={vModel.NetworthTabMaxPoint}
																	></GenericNumberInput>
																</GridItem>

															</GridContainer>
														</GridItem>
													</GridItem>
												)
											},
											{
												tabButton: "Other",
												tabContent: (
													<GridItem>

														<InternalScorecardTab
															QuestionList={this.state.model.otherTabQuestionList}
															HandleChange={this.handleChange}
															HandleAnswerChange={this.handleAnswerChange}
															AnswerValueList={this.state.model.answerValueList}
															ApplicationModel={this.state.model}
															ApplicationDocumentList={undefined}
															Disabled={model.Id <= 0}
														/>
														<GridItem xs={2} style={{ float: "right" }}>
															<GridContainer
																direction="column"
																justify="flex-start"
																alignItems="stretch"
															>
																<GridItem xs={12}>
																	<GenericLabel
																		Text="Section Maximum Point"
																		TextAlign="center"
																		Bold
																		Display="block">
																	</GenericLabel>
																</GridItem>
																<GridItem xs={12}>
																	<GenericNumberInput
																		NoFormatting
																		Name="OtherTabMaxPoint"
																		LabelText=""
																		LabelMd={0}
																		Value={model.OtherTabMaxPoint}
																		ValueChanged={this.handleChange}
																		Required={true}
																		IsInvalid={vModel.OtherTabMaxPoint}
																	></GenericNumberInput>
																</GridItem>

															</GridContainer>
														</GridItem>

													</GridItem>
												)
											},
											{
												tabButton: "Loan",
												tabContent: (
													<GridItem>
														<InternalScorecardLoan
															QuestionList={this.state.model.loanTabQuestionList}
															HandleChange={this.handleChange}
															HandleAnswerChange={this.handleAnswerChange}
															HandleOtherScorecardAnswerChange={this.handleOtherScorecardAnswerChange}
															AnswerValueList={this.state.model.answerValueList}
															OtherScorecardAnswerValueList={this.state.model.otherScorecardAnswerValueList}
															ApplicationModel={this.state.model}
															ApplicationDocumentList={undefined}
															Disabled={!model.Id || model.Id <= 0}
															LoanOtherCrediscorerows={this.state.model.LoanOtherCrediscorerows}
															ScorecardVendorList={this.state.model.ScorecardVendorList}
															ScorecardVendorLevelList={this.state.model.ScorecardVendorLevelList}
														/>
														<GridItem xs={2} style={{ float: "right" }}>
															<GridContainer
																direction="column"
																justify="flex-start"
																alignItems="stretch"
															>
																<GridItem xs={12}>
																	<GenericLabel
																		Text="Section Maximum Point"
																		TextAlign="center"
																		Bold
																		Display="block">
																	</GenericLabel>
																</GridItem>
																<GridItem xs={12}>
																	<GenericNumberInput
																		NoFormatting
																		Name={"LoanTabMaxPoint"} LabelText=""
																		LabelMd={0}
																		Value={model.LoanTabMaxPoint}
																		ValueChanged={this.handleChange}
																		Required={true}
																		IsInvalid={vModel.LoanTabMaxPoint}
																	></GenericNumberInput>
																</GridItem>

															</GridContainer>
														</GridItem>
													</GridItem>
												)
											},
											{
												tabButton: "Verification",
												tabContent: (
													<GridItem>
														<InternalScorecardVerification
															GetLoanScorecardDocumentModels={this.getLoanScorecardDocumentModels}
															LoanScorecardDocumentList={this.state.model.ScorecardProfileDocumentList}
															LoanScorecardProfileModel={this.state.model}
															HandleDocumentAdd={this.handleDocumentAdd}
															Disabled={!model.Id || model.Id <= 0}
															HandleChange={this.handleChange}
														/>
													</GridItem>
												)
											}

										]}
									/>
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>

				</GridContainer>
			</div>
		);
	}
}

InternalScorecard.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(InternalScorecard, {});