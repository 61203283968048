import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel } from "views/Components/Generic";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class BulkApplicationDelivery extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "File Name",
				accessor: "FileName"
			}
		];

		this.columnsSubmitDetail = [
			{
				Header: "First Name",
				accessor: "FirstName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSucceeded)}>{row.value}</div>)
			},
			{
				Header: "Last Name",
				accessor: "LastName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSucceeded)}>{row.value}</div>)
			},
			{
				Header: "e-Mail Address",
				accessor: "EmailAddress",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSucceeded)}>{row.value}</div>)
			},
			{
				Header: "Is Succeeded",
				accessor: "IsSucceeded",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSucceeded)}>{Formatter.FormatYesNo(row.value)}</div>)
			},
			{
				Header: "Error Description",
				accessor: "ErrorDescription",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.IsSucceeded)}>{row.value}</div>)
			}
		];

		this.columnsRowStyleColor = {};

		this.fileCode = "LenderBulkApplication";

		this.state = {
			model: {},
			isSubmitDetailDialogOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Bulk Application Delivery");
		}

		this.Search();
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	GetColumnsRowStyleColor(isSucceeded) {
		this.columnsRowStyleColor = {
			color: !isSucceeded
				? "#FB3005"
				: "#387A5C"
		};

		return this.columnsRowStyleColor;
	}

	SubmitDetailDialogOpen = () => {
		this.setState({ isSubmitDetailDialogOpen: true });
	}

	SubmitDetailDialogClose = () => {
		this.setState({ isSubmitDetailDialogOpen: false });
	}

	Search = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/LosOutService/Search", {});

		this.setState({ list: result || [] });
	}

	Submit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { model } = this.state;
		const { ExecuteApiFileUpload } = this.props;
		var formData = new FormData();
		formData.append("file", model.File, model.FileName);

		var result = await ExecuteApiFileUpload("/lmsapi/v1.0/LosOutService/Submit", formData, [this.Search]);

		if (result) {
			this.setState({ listSubmitDetail: result }, () => this.SubmitDetailDialogOpen());
		}
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;

		model.File = file;
		model.FileName = file.name;

		this.setState({ model });
	}

	Validate() {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (model.FileName == null
			&& model.File == null) {
			errors.push("Bulk application delivery file cannot be empty.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, listSubmitDetail, selected, isSubmitDetailDialogOpen } = this.state;
		const { Disabled, ExecuteApiFileDownloadWithGenericResponse } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled || model.File == null,
							OnClick: this.Submit
						},
						{
							Code: "Upload",
							Disabled: Disabled,
							OnClick: this.Upload
						},
						{
							Code: "View",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: model.FileId }, model.FileName, model.FileExtension)
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept=".csv" />
				<GenericDialog open={isSubmitDetailDialogOpen} fullScreen onBackdropClick={this.SubmitDetailDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Process Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={listSubmitDetail}
							Columns={this.columnsSubmitDetail} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitDetailDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Bulk Application Delivery">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list}
														Columns={this.columns}
														SelectedIndex={selected}
														RowSelected={this.RowSelected}
														IsSorted={false} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

BulkApplicationDelivery.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileUpload: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default BulkApplicationDelivery;