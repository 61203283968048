import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BankFileStatus, Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class eftOutgoingFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: ""
			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			dailyFileList: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			isLoading: false,
			SettlementType: ""
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("EFT Outgoing File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Outgoing;
		this.setState({ getTransactionByFileIdModel, model });
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		var { SettlementType } = this.state;
		if (name == "EFTTypeId")
			SettlementType = data != null ? data.ParameterDesc : "";
		model[name] = newValue;
		this.setState({ model, SettlementType });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.showBasicAlert("Error", "FileInformationId can not be null", "error");
		else {
			model.ClientId = tempModel.ClientId;
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetTransactionList(tempModel.Id);

		}
	}

	GetDailyFileList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetEFTOutgoingDailyFileList",
			this.state.model,
			this.successDailyFileList,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	successDailyFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		this.setState({
			transactionList: [], headerModel: {},
			dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item, isLoading: false
		});
	}

	GetTransactionList = (Id) => {
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.FileId = Id;
		getTransactionByFileIdModel.ClientId = this.state.model.ClientId == null ? null : this.state.model.ClientId;
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/GetEFTClearingFileDetailsByFileId",
			getTransactionByFileIdModel,
			this.successTransactionListCallback,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	successTransactionListCallback = (responseData) => {
		var headerModel = { ...this.state.headerModel };
		var transactionList = { ...this.state.transactionList };
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData.Item != null) {
			if (responseData.Item.ClearingFileHeaderDetail != null) {
				headerModel = responseData.Item.ClearingFileHeaderDetail == null ? {} : responseData.Item.ClearingFileHeaderDetail;
				headerModel.FileDate = this.state.fileInfoModel.FileDate;
			}
			else
				headerModel = {};

			if (responseData.Item.EFTClearingFileDetail != null || responseData.Item.EFTClearingFileDetail.length > 0) {
				transactionList = responseData.Item.EFTClearingFileDetail;
			}
			else {
				transactionList = [];
			}
		}
		this.setState({ transactionList, headerModel, isLoading: false });
	}

	CreateFile = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/GenerateEftOutgoingFile",
			this.state.model,
			this.successCreateFile,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);

	}

	successCreateFile = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData.GenerateEftOutgoingFileResponseItemList != null && responseData.GenerateEftOutgoingFileResponseItemList.length > 0) {
			var messages = responseData.GenerateEftOutgoingFileResponseItemList.map((x, i) => <div key={i}>{"Processor : " + x.EftProcessor + "  Success Count : " + x.SuccessCount + "Error Count : " + x.ErrorCount}</div>);
			this.showBasicAlert("Success", messages, "success");
			this.GetDailyFileList();
		}
		else
			this.showBasicAlert("Warning", "File could not be created because there is no transaction !!", "warning");
	}

	RollBack = () => {
		if (this.state.fileInfoModel.SendStatusParamValue == BankFileStatus.Pending) {
			this.showConfirmAlert("Warning", "Are you sure to rollback this file ?", "warning", () => {
				if (this.state.fileInfoModel == null || this.state.fileInfoModel == undefined)
					this.showBasicAlert("Warning", "First, select a row in Daily File List.", "warning");
				else {
					if (this.state.fileInfoModel.Id == null || this.state.fileInfoModel.Id == undefined)
						this.showBasicAlert("Warning", "Selected row has not FileInformationId value !!!", "warning");
					else {
						Proxy.POST(
							"/bankapi/v1.0/BankBmoEFT/RollbackEftOutgoingFile",
							{ FileInformationId: this.state.fileInfoModel.Id },
							responseData => {
								if (!responseData.IsSucceeded)
									this.showBasicAlert("Error", "Rollback Error : " + responseData.ErrorDescription + "!!!", "error");
								else {
									this.showBasicAlert("Success", "Rollback operation is successfully completed.", "success");
									this.GetDailyFileList();
								}
							},
							error => {
								this.showBasicAlert("Error", "Rollback Error : " + error + "!!!", "error");
							}
						);
					}
				}
			});
		}
		else
			this.showBasicAlert("Warning", "File Send Status must be Pending !!!", "warning");
	}

	Send = () => {
		if (this.state.fileInfoModel.SendStatusParamValue == BankFileStatus.Pending) {
			this.showConfirmAlert("Warning", "Are you sure to send this file ?", "warning", () => {
				if (this.state.fileInfoModel == null || this.state.fileInfoModel == undefined)
					this.showBasicAlert("Warning", "First, select a row in Daily File List.", "warning");
				else {
					if (this.state.fileInfoModel.Id == null || this.state.fileInfoModel == undefined)
						this.showBasicAlert("Warning", "Selected row has not FileInformationId value !!!", "warning");
					else {
						this.hideAlert();
						this.showLoading();
						Proxy.POST(
							"/bankapi/v1.0/BankBmoEFT/SendEftOutgoingFile",
							{ FileInformationId: this.state.fileInfoModel.Id },
							responseData => {
								if (!responseData.IsSucceeded)
									this.showBasicAlert("Error", "Send operation error : " + responseData.ErrorDescription + "!!!", "error");
								else {
									this.showBasicAlert("Success", "Send operation is successfully completed.", "success");
									this.GetDailyFileList();
								}
							},
							error => {
								this.showBasicAlert("Error", "Send operation error : " + error + "!!!", "error");
							}
						);
					}
				}
			});
		}
		else
			this.showBasicAlert("Warning", "File Send Status must be Pending !!!", "warning");

	}

	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	showConfirmAlert = (title, message, type, onConfirm) => {
		this.setState({ isLoading: false, alert: AlertHelper.CreateQuestionAlert(title, message, type, onConfirm, this.hideAlert) });
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	showLoading = () => { this.setState({ alert: null, isLoading: true }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDateTime(), ToDate: DateHelper.GetDateTime(),
			}
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "CreateFile", OnClick: this.CreateFile, Disabled: Disabled },
					{ Code: "Rollback", OnClick: this.RollBack, Disabled: Disabled },
					{ Code: "Send", OnClick: this.Send, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={3}>
						{/* <Card style={{ height: "90%" }}>							
							<CardBody> */}
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" File Filter"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
								<GenericDateInput
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
								<ParameterComponent
									Name="FileEFTTypeId"
									LabelText="Settlement Type"
									ParameterCode="EFTType"
									Value={model.FileEFTTypeId}
									ValueChanged={this.handleChange} />
								<ParameterComponent
									Name="EftProcessorFileId"
									LabelText="Processor"
									ParameterCode="EftProcessor"
									Value={model.EftProcessorFileId}
									ValueChanged={this.handleChange} />
							</CardBody>
						</Card>

						<br /> <br />
						{/* </CardBody>
						</Card> */}
					</GridItem>
					<GridItem xs={3}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" Create File "} />
							</CardHeader>
							<CardBody>
								<ParameterComponent
									Name="EFTTypeId"
									LabelText="Settlement Type"
									ParameterCode="EFTType"
									Value={model.EFTTypeId}
									ValueChanged={this.handleChange}
									All />
								<ParameterComponent
									Name="EftProcessorId"
									LabelText="Processor"
									ParameterCode="EftProcessor"
									Value={model.EftProcessorId}
									ValueChanged={this.handleChange}
									All /> <br />
							</CardBody>
						</Card>

					</GridItem>
					<GridItem xs={6}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileDate"
											LabelMd={6}
											LabelText="File Date"
											Value={headerModel == null ? "" : headerModel.FileDate ? Formatter.FormatDateUtc(headerModel.FileDate) : ""}
											Disabled={true}
											DateFormat="DD/MMM/YY"
											Utc />
										<GenericNumberInput
											Name="TotalCreditNumber"
											LabelMd={6}
											LabelText="Total Number of Credit"
											Value={headerModel == null ? "" : headerModel.TotalNumbeCredit ? headerModel.TotalNumbeCredit : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelText="Total Number of Debit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalNumberDebit ? headerModel.TotalNumberDebit : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericTextInput
											Name="OriginatorId"
											LabelText="Originator ID"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.OriginatorId ? headerModel.OriginatorId : ""}
											Disabled={true} />
									</GridItem>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileDate"
											LabelText="File Time"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.FileDate ? Formatter.FormatTime(headerModel.FileDate) : ""}
											DateFormat="hh:mm:ss A"
											IncludeTime={false}
											Disabled={true} />
										<GenericNumberInput
											Name="TotalAmountCredit"
											LabelText="Total Amount of Credit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountCredit ? headerModel.TotalAmountCredit : ""}
											Prefix="$"
											Disabled={true} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelText="Total Amount of Debit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountDebit ? headerModel.TotalAmountDebit : ""}
											Prefix="$"
											Disabled={true} />

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Daily File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.dailyFileList}
									Columns={[
										{
											Header: "File Name",
											accessor: "FileName"
										},
										{
											Header: "File Date",
											accessor: "FileDate",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Eft Processor",
											accessor: "EftProcessor"
										},
										{
											Header: "Send Status",
											accessor: "SendStatus"
										},
										{
											Header: "Total Count",
											accessor: "TotalCount"
										},
										{
											Header: "Sent Count",
											accessor: "SentCount"
										},
										{
											Header: "Settled Count",
											accessor: "SettledCount"
										},
										{
											Header: "Rejected Count",
											accessor: "RejectedCount"
										},
										{
											Header: "Returned Count",
											accessor: "ReturnedCount"
										},
										{
											Header: "Error Count",
											accessor: "ErrorCount"
										},
										{
											Header: "File Creation Number",
											accessor: "FileCreationNumber"
										},
										{
											Header: "Destination Data Center Code",
											accessor: "DestinationDateCenterCode"
										},
										{
											Header: "File Type",
											accessor: "FileType"
										}
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Bank_Programs_EFT} />
								<br /> <br />
								<h5><b>Transaction List</b></h5> <br />
								<GenericGrid
									Data={this.state.transactionList}
									Columns={[
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Clearing Date",
											accessor: "ClearingDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Customer Name",
											accessor: "CustomerName"
										},
										{
											Header: "Settlement Date",
											accessor: "SettlementDate",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Transaction Type",
											accessor: "TransactionType"
										},
										{
											Header: "Destination Institution",
											accessor: "DestinationInstitution"
										},
										{
											Header: "Destination Wallet Number",
											accessor: "DestinationAccountNumber"
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Eft Processor",
											accessor: "EftProcessor"
										},
										{
											Header: "Return Institution",
											accessor: "ReturnInstitution"
										},
										{
											Header: "Return Wallet Number",
											accessor: "ReturnAccountNumber"

										},
										{
											Header: "Institution For Returns",
											accessor: "ReturnInstitution"
										},
										{
											Header: "Wallet For Returns",
											accessor: "ReturnAccountNumber"
										},
										{
											Header: "Logical Record Count",
											accessor: "LogicalRecordCount"
										},
										{
											Header: "Originating Direct Clearer UserId",
											accessor: "OriginatingDirectClearerUserId"
										},
										{
											Header: "Cross Reference Number",
											accessor: "CrossReferenceNumber"
										},
										{
											Header: "Group Number",
											accessor: "GroupNumber"
										},
										{
											Header: "Sequence InSegment",
											accessor: "SequenceInSegment"
										},
										{
											Header: "Invalid Data Element",
											accessor: "InvalidDataElement"
										},
										{
											Header: "Stored Transaction Type",
											accessor: "StoredTransactionType"
										},
										{
											Header: "Direction",
											accessor: "Direction"
										},
										{
											Header: "Process Status",
											accessor: "ProcessStatus"
										},
										{
											Header: "Match Status",
											accessor: "MatchStatus"
										},
										{
											Header: "Validation Status",
											accessor: "ValidationStatus"
										},
										{
											Header: "Originator Short Name",
											accessor: "OriginatorShortName"
										},
										{
											Header: "Originator Long Name",
											accessor: "OriginatorLongName"
										},
										{
											Header: "Validation Description",
											accessor: "ValidationDescription"
										},
										{
											Header: "File Name",
											accessor: "FileName"
										},
										{
											Header: "Originator Sundry Information",
											accessor: "OriginatorSundryInformation"
										},
										{
											Header: "Originator Direct Clearer Settlement Code",
											accessor: "OriginatorDirectClearerSettlementCode"
										},
										{
											Header: "IsRecommited",
											accessor: "IsRecommited"
										},
										{
											Header: "Item Trace No",
											accessor: "ItemTraceNo"
										},
										{
											Header: "Recommit Date",
											accessor: "RecommitDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Reject File Sent Date",
											accessor: "RejectFileSentDate",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Client User",
											accessor: "InsertUser"
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_EFT} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eftOutgoingFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(eftOutgoingFile, {});