import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput, GenericTextInput } from "views/Components/Generic";



class Tcr1 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};


		this.maxLength1 = { maxLength: 1 };
		this.maxLength2 = { maxLength: 2 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength50 = { maxLength: 50 };
		this.maxLength25 = { maxLength: 25 };
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	render() {

		const { model } = this.props;
		return (
			<div >

				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericTextInput
									Name="IssuerWorkstationBin"
									LabelMd={6}
									LabelText="Issuer Workstation Bin"
									inputProps={this.maxLength6}
									Value={model && model.IssuerWorkstationBin}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AcquirerWorkstationBin"
									LabelMd={6}
									LabelText="Acquirer Workstation Bin"
									inputProps={this.maxLength6}
									Value={model && model.AcquirerWorkstationBin}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ChargebackReferenceNumber"
									LabelMd={6}
									LabelText="Chargeback Reference Number"
									inputProps={this.maxLength6}
									Value={model && model.ChargebackReferenceNumber}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="DocumentIndicator"
									LabelMd={6}
									LabelText="Document Indicator"
									inputProps={this.maxLength1}
									Value={model && model.DocumentIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="MemberMessageText"
									LabelMd={6}
									LabelText="Member Message Text"
									inputProps={this.maxLength50}
									Value={model && model.MemberMessageText}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="SpecialConditionIndicators"
									LabelMd={6}
									LabelText="Special Condition Indicators"
									inputProps={this.maxLength2}
									Value={model && model.SpecialConditionIndicators}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="FeeProgramIndicator"
									LabelMd={6}
									LabelText="Fee Program Indicator"
									inputProps={this.maxLength3}
									Value={model && model.FeeProgramIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="IssuerCharge"
									LabelMd={6}
									LabelText="Issuer Charge"
									inputProps={this.maxLength1}
									Value={model && model.IssuerCharge}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="CardAcceptorId"
									LabelMd={6}
									LabelText="Card Acceptor Id"
									inputProps={this.maxLength15}
									Value={model && model.CardAcceptorId}
									ValueChanged={this.HandleChange} />
							</GridItem>



							<GridItem xs={4}>



								<GenericTextInput
									Name="TerminalId"
									LabelMd={6}
									LabelText="TerminalId"
									inputProps={this.maxLength8}
									Value={model && model.TerminalId}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="NationalReimbursementFee"
									LabelMd={6}
									LabelText="National Reimbursement Fee"
									inputProps={this.maxLength17}
									Value={model && model.NationalReimbursementFee}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="MailPhoneEcommPayInd"
									LabelMd={6}
									LabelText="Mail Phone Ecomm Pay Ind"
									inputProps={this.maxLength1}
									Value={model && model.MailPhoneEcommPayInd}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="SpecialChargebackIndicator"
									LabelMd={6}
									LabelText="Special Chargeback Indicator"
									inputProps={this.maxLength1}
									Value={model && model.SpecialChargebackIndicator}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="InterfaceTraceNumber"
									LabelMd={6}
									LabelText="Interface Trace Number"
									inputProps={this.maxLength6}
									Value={model && model.InterfaceTraceNumber}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AccountSelection"
									LabelMd={6}
									LabelText="Wallet Selection"
									inputProps={this.maxLength12}
									Value={model && model.AccountSelection}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="Cashback"
									LabelMd={6}
									LabelText="Cashback"
									inputProps={this.maxLength17}
									Value={model && model.Cashback}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ChipConditionCode"
									LabelMd={6}
									LabelText="Chip Condition Code"
									inputProps={this.maxLength1}
									Value={model && model.ChipConditionCode}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="PosEnvironment"
									LabelMd={6}
									LabelText="Pos Environment"
									inputProps={this.maxLength1}
									Value={model && model.PosEnvironment}
									ValueChanged={this.HandleChange} />
							</GridItem>


							<GridItem xs={4}>

								<GenericTextInput
									Name="UnattendedAcceptTermInd"
									LabelMd={6}
									LabelText="Unattended Accept Term Ind"
									inputProps={this.maxLength1}
									Value={model && model.UnattendedAcceptTermInd}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="PrepaidCardIndicator"
									LabelMd={6}
									LabelText="Prepaid Card Indicator"
									inputProps={this.maxLength1}
									Value={model && model.PrepaidCardIndicator}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ServiceDevelopmentField"
									LabelMd={6}
									LabelText="Service Development Field"
									inputProps={this.maxLength1}
									Value={model && model.ServiceDevelopmentField}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="AvsResponseCode"
									LabelMd={6}
									LabelText="Avs Response Code"
									inputProps={this.maxLength1}
									Value={model && model.AvsResponseCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AuthorizationSourceCode"
									LabelMd={6}
									LabelText="Authorization Source Code"
									inputProps={this.maxLength1}
									Value={model && model.AuthorizationSourceCode}
									ValueChanged={this.HandleChange} />
								<GenericTextInput
									Name="PurchaseIdentifierFormat"
									LabelMd={6}
									LabelText="Purchase Identifier Format"
									inputProps={this.maxLength1}
									Value={model && model.PurchaseIdentifierFormat}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AccountSelection"
									LabelMd={6}
									LabelText="Wallet Selection"
									inputProps={this.maxLength1}
									Value={model && model.AccountSelection}
									ValueChanged={this.HandleChange} />


								<GenericTextInput
									Name="InstallmentPaymentCount"
									LabelMd={6}
									LabelText="Installment Payment Count"
									inputProps={this.maxLength2}
									Value={model && model.InstallmentPaymentCount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="PurchaseIdentifier"
									LabelMd={6}
									LabelText="Purchase Identifier"
									inputProps={this.maxLength25}
									Value={model && model.PurchaseIdentifier}
									ValueChanged={this.HandleChange} />
							</GridItem>


						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

Tcr1.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default Tcr1;