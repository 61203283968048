import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import tabComponentStyle from "../../assets/jss/material-dashboard-pro-react/views/tabComponentStyle";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

class TabsComponentPagination extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { classes, tabList, TabIndex, handleChange } = this.props;
		const tabButtons = (
			<TabList style={{ fontSize: "12px", fontWeight: 500, lineHeight: "20px", color: "inherit" }}>
				{tabList.filter(x => !x.Hidden).map((prop, key) => {
					return (
						<Tab key={key}>{prop.tabButton}</Tab>
					);
				})}
			</TabList>
		);
		const tabContent = (
			<div>
				{tabList.filter(x => !x.Hidden).map((prop, key) => {
					return (
						<TabPanel key={key}>
							{prop.tabContent}
						</TabPanel>
					);
				})}
			</div>
		);
		return (
			<Tabs
				selectedIndex={TabIndex}
				onSelect={tabIndex => handleChange(null, tabIndex)}
			>
				{tabButtons}
				{tabContent}
			</Tabs >
		);
	}
}

TabsComponentPagination.propTypes = {
	classes: PropTypes.object.isRequired,
	tabList: PropTypes.array
};

export default withStyles(tabComponentStyle)(TabsComponentPagination);