import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericExpansionPanel, GenericNumberInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class BillIndividual extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customerToken: "0"
		};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
	}

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;

			if (name == "NewClient") {
				if (newValue) {
					model.IsCustomerPopupOpen = true;
					this.setState({ isCustomerAdded: false });
				}
			}

			if (name == "TransactionType")
				model.TransactionTypeCode = data.ParameterValue;
			return model;
		});
	}

	addCustomerDialogClose() {
		this.ValueChanged("IsCustomerPopupOpen", false);
	}

	render() {

		const { model, Disabled ,vModel} = this.props;
		return (
			<div>

				<LoadingComponent Show={this.state.isLoading} />

				<Card className="no-radius" style={{ height: "90%", opacity: Disabled ? 0.4 : 1 }}>

					<GenericExpansionPanel Title="Individual Transaction">
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GenericTextInput
										LabelMd={4}
										LabelText="Payee Wallet Number"
										Name="AccountNumber"
										Value={model.AccountNumber == null || undefined ? "" : model.AccountNumber}
										ValueChanged={this.ValueChanged}
										inputProps={{ maxLength: 30 }}
										Disabled={Disabled}
										Required
										IsInvalid={vModel.AccountNumber}
									/>
									<GenericNumberInput
										LabelMd={4} Name="Amount" LabelText="Amount"
										Value={model.Amount} ValueChanged={this.ValueChanged}
										MaxLength={10} Disabled={Disabled || model.Id} Prefix="$"
										Required
										IsInvalid={vModel.Amount} />

								</GridItem>

								<GridItem xs={6}>
									<GenericTextInput LabelMd={4} Name="BillDescription" LabelText="Bill Description" Value={model.BillDescription} ValueChanged={this.ValueChanged} Disabled={Disabled} inputProps={{ maxLength: 150 }} />
								</GridItem>

							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
			</div>
		);
	}
}

BillIndividual.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillIndividual;