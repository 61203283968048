import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "error/ErrorBoundary";
import React from "react";
import { connect } from "react-redux";

export default function withRedux(HocComponent, styles) {
	function Hoc(props) {
		return (
			<ErrorBoundary location={props.location}>
				<HocComponent  {...props}></HocComponent>
			</ErrorBoundary>
		);
	}

	function mapStateToProps(state) {
		return state;
	}

	const connectedPage = connect(mapStateToProps)(Hoc);
	return withStyles(styles)(connectedPage);
}